import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SessionService } from 'src/app/shared/services/session.service';
import { CobranzaOnlineApiService } from '../cobranza-online-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
//import { RutValidator } from 'ng9-rut';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';

@Component({
  selector: 'app-cobranza-online-publicar',
  templateUrl: './cobranza-online-publicar.component.html',
  styleUrls: ['./cobranza-online-publicar.component.scss']
})
export class CobranzaOnlinePublicarComponent implements OnInit {

  public hasUserAccess = false;
  private access: string[] = [ESystemAccess.COBRANZA_ONLINE_PUBLICAR, ESystemProfileList.WEB];

  public rut: string | undefined = "";
  show = false;

  noToken = false;
  public tempFile: any;

  minEndDate: Date | null = null;
  maxStartDate: Date | null = null;

  publishForm: FormGroup = this.fb.group({
    company_rut: new FormControl('', [Validators.required, gtpRutValidator()]),
    company_name: new FormControl('', Validators.required),
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    emails: this.fb.array([
    ]),
    phone: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]+$/)]),
    description: new FormControl('', Validators.required),
    amount: new FormControl(null, [Validators.required, Validators.min(0)]),
    emission_date: new FormControl('', Validators.required),
    due_date: new FormControl('', Validators.required),
    document_url: new FormControl(''),
    doc_type: new FormControl('Factura', Validators.required),
    dte_type: new FormControl('33'),
    ndoc: new FormControl('', Validators.required),
    creditor_rut: new FormControl('', [Validators.required, gtpRutValidator()]),
    cosigners: this.fb.array([]),
    provider_id: new FormControl(''),
    pdf: new FormControl('', Validators.required),
  });

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private _session: SessionService,
    private _apiService: CobranzaOnlineApiService,
    private _spinner: NgxSpinnerService,
    private _alertsService: AlertService,
    private _router: Router,
    //private rutValidator: RutValidator,
  ) { }

  ngOnInit(): void {
    console.log(".")
    this.checkAccess();
  }

  validateNumber(event: KeyboardEvent): void {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  makeApiCalls() {
    // Llamar a hasToken
    this._spinner.show();
    const idOrganizacion = this._session.getOrganization();
    const idUsuario = this._session.getUserInfo().username;

    this._apiService.checkToken(idOrganizacion, idUsuario).subscribe(
      (res) => {
        console.log(res);
        if(res.body && res.body.hasToken) {
          // nada
        } else {
          this.noToken = true;
        }
      },
      (error) => {
        this.noToken = true;
      }
    )

    this._apiService.getRut(idOrganizacion, idUsuario).subscribe((rutResponse) => {
      if(rutResponse.statusCode && rutResponse.statusCode == 420){
        this._spinner.hide();
        this._alertsService.error('Error al obtener tus datos, chequea tener tus credenciales de cobranza online actualizadas');
        this.rut = undefined;
      } else {
        this._spinner.hide();
        this.show = true;
        this.rut = rutResponse.body.rut;  
      }
    })
  }
  
  handleUpload(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.tempFile = reader?.result?.toString().split(',')[1];
      console.log(this.tempFile);
    };
  }

  checkAccess() {
    const userAccess = this._session.getUserAccess();
    const getUserProfileList = this._session.getUserProfileList();
    console.log(userAccess);
    console.log(getUserProfileList);

    if (this._session.getUserAccess().includes(this.access[0]) && this._session.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
      this.makeApiCalls();
    }
  }

  goTocredenciales() {
    this._router.navigate(['cobranza-online/onboarding'])
  }

  get emails(): FormArray {
    return this.publishForm.get('emails') as FormArray;
  }

  get cosigners(): FormArray {
    return this.publishForm.get('cosigners') as FormArray;
  }

  addEmail() {
    this.emails.push(this.fb.control('', [Validators.required, Validators.email]));
  }

  addCosigner() {
    this.cosigners.push(this.fb.control(''));
  }

  back() {
    this.location.back();
  }

  onStartDateChange(event: any) {
    const startDate = event.target.value;
    this.minEndDate = startDate;
    console.log(this.minEndDate);
    // this.publishForm.get('due_date')?.enable();
  }

  onEndDateChange(event: any) {
    const endDate = event.target.value;
    this.maxStartDate = endDate;
    console.log(this.maxStartDate);
  }

  isInvalid(controlName: string): boolean {
    const control = this.publishForm.get(controlName);
    return control ? control.invalid && (control.touched || control.dirty) : false;
  }

  onSubmit() {
    // Mapear
    // Agregar providerId
    // agregar dte type

    if (this.publishForm.valid) {
      const formData = this.publishForm.value;
      
      let mappedData = {
        idOrganizacion: "",
        idUsuario: "",
        requestOrigin: "",
        rut: this.rut,

        morosidad: {
          company_rut: formData.company_rut,
          company_name: formData.company_name,
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          emails: formData.emails,
          phone: formData.phone,
          description: formData.description,
          amount: formData.amount,
          emission_date: formData.emission_date,
          due_date: formData.due_date,
          document_url: formData.document_url,
          doc_type: formData.doc_type,
          dte_type: formData.dte_type,
          ndoc: formData.ndoc,
          creditor_rut: formData.creditor_rut,
          cosigners: formData.cosigners,
          provider_id: formData.provider_id,
          pdf: this.tempFile
        }
      };

      // agregar dte type
      // mappedData.morosidad.dte_type = this.getDteType(mappedData.morosidad.doc_type);

      // Agregar providerId
      mappedData.morosidad.provider_id = "gestionatupyme"
      
      // documentUrl en null
      mappedData.morosidad.document_url = "null"

      // agregar idOrganizacion, idUsuario y reqOrigin
      mappedData.idOrganizacion = this._session.getOrganization();
      mappedData.idUsuario = this._session.getUserInfo().username;
      mappedData.requestOrigin = "WEB";

      //llamar API
      this._spinner.show();
      this._apiService.postMorosidad(mappedData).subscribe(
        (res) => {
          console.log(res);
          this._spinner.hide();
          // Alert service success
          this._alertsService.success("Morosidad ingresada con éxito")
          this._router.navigate(['cobranza-online/buscar']);
        },
        (error) => {
          // Alert service error
          console.log(error);
          this._spinner.hide();
          this._alertsService.error("Error al ingresar morosidad");
        }
      );
    } else {
      // Alert service: datos invalidos
      this._alertsService.error("Datos inválidos en el formulario");
    }
  }

  getDteType(doc_type: string) {
    if(doc_type == "Factura") {
      return "33"
    } else {
      return ""
    }
  }
}