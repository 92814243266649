import { AfterViewInit, Component, Inject, Input } from '@angular/core';
//import { SimpleModalComponent } from "ngx-simple-modal";
import { take, map } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmModel {
  title: string;
  errors: AdvertenciaModel[];
  textTimer: number;
}

export interface AdvertenciaModel {
  Seccion: string;
  Campo: string;
  Detalle: string;
}

export interface AdvertenciaResponseModel {
  file: string;
  confirm: boolean;
}

@Component({
  selector: 'app-list-errors-dialog-workflow',
  templateUrl: './list-errors-dialog-workflow.component.html',
  styleUrls: ['./list-errors-dialog-workflow.component.scss']
})
export class ListErrorsDialogWorkflowComponent implements AfterViewInit {
  @Input() title: string = "";
  @Input() errors: AdvertenciaModel[] = [];
  @Input() textTimer: number = 0;
  public formulario!: FormGroup;
  public result: AdvertenciaResponseModel = { file: '', confirm: false };

  confirmData: ConfirmModel = {
    title: "",
    errors: [],
    textTimer: 0
  };

  // timer: Observable<number>;
  public counter$: Observable<number> = new Observable;

  public counter = 0;


  /*constructor(private formBuilder: UntypedFormBuilder) {
    super();
    this.initForm();
  }*/

  constructor(public dialogRef: MatDialogRef<ListErrorsDialogWorkflowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel,private formBuilder: UntypedFormBuilder) {
      this.confirmData = data;
      this.initForm();

      timer(0, 1000).pipe(
        // Take values until the specified duration is reached
        take(this.confirmData.textTimer + 1),
        // Subtract the emitted values from the duration to get the countdown
        map((value) => this.confirmData.textTimer - value)
      ).subscribe((counter) => {
        this.counter = counter;

        // Close the dialog when the countdown reaches zero
        if (counter === 0) {
          // this.close();
        }
      });
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.result = { file: reader.result?.toString() || '', confirm: true };
     
    };
  }

  close() {
    this.dialogRef.close(this.result); // validar
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.confirmData.textTimer > 0) {
      this.counter$ = await timer(0, 1000).pipe(
        take(this.textTimer),
        map(() => {
          return --this.textTimer
        }));
    }
  }

  submit() {
    if (this.formulario.invalid) {
      return;
    }
    this.close();
  }

  initForm(): void {
    this.formulario = this.formBuilder.group({
      file: [null]
    })
  }

  cerrar() {
    this.result = { file: '', confirm: false };
    this.close();
  }

}
