import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-informe-cte',
  templateUrl: './informe-cte.component.html',
  styleUrls: ['./informe-cte.component.scss']
})
export class InformeCteComponent implements OnInit, OnDestroy {

  @Input() informeTributario: any[] = [];
  public header: any[] = ['nombre', 'rut', 'date',];
  public dataTableSociosRepresentantes: any[] = [];
  public dataTableVentas: any[] = [];
  public dataTableBalances: any[] = [];
  private subscriptions: Subscription[] = [];
  public headerVentas: string[] = [
    'ventas',
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic'
  ];

  constructor() { }

  ngOnInit(): void {
   }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
