<ng-container *ngIf="validaEstado()">
    <section class="overflow-auto d-flex justify-content-end align-items-center" [class.open]="validaEstado() ? 'display' : 'close'"
             (click)="close()">
      <div (click)="$event.stopPropagation()" class="container p-5 my-auto mx-auto">
        <div class="row">

          <button class="close" type="button" (click)="close()"><i class="fa fa-times"></i></button>

          <div class="col-12">
            <app-alert-message *ngFor="let message of messages; let i = index"
                [message]="message.text"
                [isError]="message.isError"
                [isSuccess]="message.isSuccess"
                (close)="removeMessage(i)"
            ></app-alert-message>
          </div>
          
          <div class="col-12">
            <div class="row tamano-container overflow-auto">
              <div class="col-sm-12 col-md-9 my-3 text-center my-auto" *ngIf="urlPreview">
                  <span class="badge badge-warning text-wrap px-4">
                    <h5><b>La siguiente imagen es solo una previsualización</b></h5>
                  </span>
              </div>
              <div class="col-sm-12 col-md-3 my-3" *ngIf="idTransaccion && tipoReporte">
                <button class="btn btn-primary float-right" (click)="descargarPdf()">Descargar</button>
              </div>
              <div class="col-sm-12" *ngIf="urlPreview">
                <iframe class="frame-pdf" [src]="urlPreview | safe" frameborder="0"></iframe>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
</ng-container>