import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SessionService } from './session.service';
import { IListFormularioF29Model, IFormularioF29ReporteModel, ReporteF29, IFormularioF29FullReporteModel, DatosFiltrados } from 'src/app/models/formulario-f29/formulario-f29.model';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FormularioF29Service {
  private servicio: string = environment.backend.formularioF29.servicio;
  private endpoint: string = environment.backend.formularioF29.url;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService
  ) { }

  buscarReportes(rut: string, periodo: string | null): Observable<IListFormularioF29Model> {
    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarReportes');
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    params = params.append("calculado",  true); // Indica que busca los completados y procesados
    if(periodo){
      params = params.append("periodo", periodo);
    }
    return this.http.get<IListFormularioF29Model>(`${this.endpoint}obtener`, { params });
  }

  buscarReportesCant(rut: string, cant: number): Observable<IListFormularioF29Model> {
    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarReportes');
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    params = params.append("calculado",  true); // Indica que busca los completados y procesados
    if(cant){
      params = params.append("cantPeriodo", cant);
    }
    return this.http.get<IListFormularioF29Model>(`${this.endpoint}obtener`, { params });
  }


  obtenerReporte(rut: string, periodo: string, mes: string): Observable<IFormularioF29ReporteModel> {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarTX');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    params = params.append("periodo", periodo);
    params = params.append("mes", mes);
    return this.http.get<IFormularioF29ReporteModel>(`${this.endpoint}obtener`, { params });
  }

  obtenerReporteFiltrado(datos: IFormularioF29FullReporteModel[], periodos: any[], f29Limpio: boolean = false, rutLimpio: string = ''): any {
    const datosFiltrados: any[] = [];
    periodos.forEach(periodoElement => {
      let meses = false;
      if((periodoElement && periodoElement != '') || periodos.length == 0){ 
        const reportePeriodo: DatosFiltrados = {
          periodo: periodoElement,
          datosReporte: [],
          totalVentasNetasMiles: 0,
          totalVentasNetasUF: 0,
          totalComprasNetasMiles: 0,
          totalComprasNetasUF: 0,
          totalMargenMiles: 0,
          totalPorcVentasMiles: 0,
          totalPostergado: 0,
          totalVarVentas: 0,
          mesesEnPeriodo: 0,
          mesesEnReporte: [],
          rut: '',
          nombreRazonSocial: ''
        }
        datos.forEach(element => {
          let reporte: any = null;
          if(f29Limpio){
            reporte = element && Object.keys(element).length > 0 ? element : null;
          } else {
            reporte = element?.Formulario29Reporte?.Reporte;
          }

          if((periodos.length == 0 || periodoElement == reporte?.periodo.toString()) && (reporte?.calculado && !(Object.keys(reporte?.calculado).length === 0))){
            meses = true;
            const datosCalculado = {
              mes: reporte?.calculado.mes,
              ventasNetasMiles: reporte?.calculado.ventasNetasMilesPesos,
              ventasNetasUF: reporte?.calculado.ventasNetasUf,
              comprasNetasMiles: reporte?.calculado.comprasNetasMilesPesos,
              comprasNetasUf: reporte?.calculado.comprasNetasUf,
              margenMiles: reporte?.calculado.margenMiles,
              porc_notascred_ventas: reporte?.calculado.porc_notascred_ventas,
              fechaPago: reporte?.calculado.fechaPago,
              tipoDeclaracion: reporte?.calculado.tipoDeclaracion,
              nombreMes: reporte?.calculado.mesPalabra,
              postergado: reporte?.calculado.postergacionIVA,
              variacion: reporte?.calculado.variacion,
              date: reporte?.periodo.toString() + '-' + ((reporte?.mes) < 10 ? '0' + (reporte?.mes) : (reporte?.mes)) + '-' + '02',
            }
            reportePeriodo.totalVentasNetasMiles += parseInt(reporte?.calculado.ventasNetasMilesPesos)  || 0;
            reportePeriodo.totalVentasNetasUF += parseInt(reporte?.calculado.ventasNetasUf)  || 0;
            reportePeriodo.totalComprasNetasMiles += parseInt(reporte?.calculado.comprasNetasMilesPesos)  || 0;
            reportePeriodo.totalComprasNetasUF += parseInt(reporte?.calculado.comprasNetasUf)  || 0;
            reportePeriodo.totalMargenMiles += parseInt(reporte?.calculado.margenMiles)  || 0;
            reportePeriodo.totalPorcVentasMiles += Number(reporte?.calculado.porc_notascred_ventas || 0);
            reportePeriodo.totalPostergado += Number(reporte?.calculado.postergacionIVA || 0);
            reportePeriodo.rut = f29Limpio ? (rutLimpio || '') : (element.Formulario29Reporte.DatosBasicosSolicitud.Rut + '-' + element.Formulario29Reporte.DatosBasicosSolicitud.DV || '');
            reportePeriodo.nombreRazonSocial = reporte?.calculado.nombreRazonSocial || '';
            reportePeriodo.mesesEnPeriodo += 1;
            reportePeriodo.mesesEnReporte.push(reporte?.calculado.mes)
            reportePeriodo.datosReporte.push(datosCalculado)
          }
        });
        if(meses){
          datosFiltrados.push(reportePeriodo);
        }
      }
    });
    return datosFiltrados;
  }

  revisionMeses(reporte: DatosFiltrados[]): DatosFiltrados[]{
    reporte.reverse();
    let ultimaVenta: number = 0;
    reporte.forEach((elemento: DatosFiltrados) => {
      for(let i = 1; i <= 12; i++){
        if(!elemento.mesesEnReporte.includes(i)){
          const datosCalculado = {
            mes: i,
            ventasNetasMiles: '',
            ventasNetasUF: '',
            comprasNetasMiles: '',
            comprasNetasUf: '',
            margenMiles: '',
            porc_notascred_ventas: '',
            fechaPago: '',
            tipoDeclaracion: '',
            postergado: '',
            variacion: '',
            date: elemento.periodo + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02',
            nombreMes: i==1?'Enero':i==2?'Febrero':i==3?'Marzo':i==4?'Abril':i==5?'Mayo':i==6?'Junio':i==7?'Julio':i==8?'Agosto':i==9?'Septiembre':i==10?'Octubre':i==11?'Noviembre':i==12?'Diciembre':''
          }
          elemento.datosReporte.push(datosCalculado);
        }
      }
      // Ordena por mes
      elemento.datosReporte.sort(((a, b) => a.mes - b.mes));

      // Calculo variacion
      let totalVariacion = 0;

      for (let i = 0; i < elemento.datosReporte.length; i++) {
        if (elemento.datosReporte[i].variacion !== ''){
          if (i===0){ //Enero
            const variacion = ultimaVenta !== 0 ?
              ((elemento.datosReporte[i].ventasNetasMiles - ultimaVenta) / ultimaVenta) * 100 : 0;
            // si la variacion es infinito o NaN, se deja en 0
            const variacionValidada = variacion === Infinity || isNaN(variacion) ? 0 : variacion;
            elemento.datosReporte[i].variacion = variacionValidada;
            totalVariacion += variacionValidada;
          } else {
            const variacion = elemento.datosReporte[i - 1].ventasNetasMiles !== 0 && elemento.datosReporte[i - 1].ventasNetasMiles !== '' ?
              ((elemento.datosReporte[i].ventasNetasMiles - elemento.datosReporte[i - 1].ventasNetasMiles) / elemento.datosReporte[i - 1].ventasNetasMiles) * 100 : 0;
            const variacionValidada = variacion === Infinity || isNaN(variacion) ? 0 : variacion;
            elemento.datosReporte[i].variacion = variacionValidada;
            totalVariacion += variacionValidada;
          }
        }

        if (i === 11){ //Diciembre
          ultimaVenta = elemento.datosReporte[i].ventasNetasMiles;
        }
      }
      elemento.totalVarVentas = totalVariacion;

    });

    reporte.reverse();
    return reporte;
  }

  public procesarReport(rut: string, form: any, validar: boolean = true): Observable<any> {
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "crearReporte",
      validar: validar,
      ...form
    }
    return this.http.post<IListFormularioF29Model>(this.endpoint + "crear", payload)
  }

  public calcularVentas(rut: string, reporte: ReporteF29, validar: boolean = true): Observable<any> {
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "calcular",
      validar: validar,
      f29: reporte
    }
    return this.http.post<IFormularioF29ReporteModel>(this.endpoint + "crear", payload)
  }

  public createReport(rut: string, form: any): Observable<any> { // Modificar con folio
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "consultar",
      ...form
    }
    return this.http.post<IFormularioF29ReporteModel>(this.endpoint + "crear", payload)
  }

  public descargarReport(rut: string, form: any): Observable<any> { // NO FUNCIONAL, ES COPIA DE procesarReport
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "procesarCarpetaReporte",
      ...form
    }
    return this.http.post<IFormularioF29ReporteModel>(this.endpoint + "crear", payload)
  }


}
