import { Component, Input, OnInit } from '@angular/core';
import { ChartUtils } from 'src/app/enum/ChartUtils';
import { CustomChartModel } from 'src/app/models/charts/custom-chart/custom-chart.model';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-custom-bars',
  templateUrl: './custom-bars.component.html',
  styleUrls: ['./custom-bars.component.scss', '../../reporte/reporte-viewer/reporte-viewer.component.scss']
})
export class CustomBarsComponent implements OnInit {

  @Input() riesgo: number = 0;
  public textRiegos: string = '';
  public chartUtils: CustomChartModel[] = ChartUtils;
  constructor(private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.textRiegos = this.utilsService.setRiesgo(this.riesgo);
  }

}
