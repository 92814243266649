import { Component, Input, OnInit } from '@angular/core';
//import { SimpleModalService } from 'ngx-simple-modal';
import { Articulos, CertificadoDeEstatutos, IEmpresaEnUnDiaCreateReportResponse } from 'src/app/interface/IEmpresaEnUnDiaCreateReportResponse';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-estatutos-viewer',
  templateUrl: './estatutos-viewer.component.html',
  styleUrls: ['./estatutos-viewer.component.scss']
})
export class EstatutosViewerComponent implements OnInit {
  @Input() content!: IEmpresaEnUnDiaCreateReportResponse;
  @Input() showHeader: boolean = true; 
  public certificado: CertificadoDeEstatutos = {
    RutSociedad: '',
    RazonSocial: '',
    FechaConstitucion: '',
    FechaEmisionCertificado: '',
    Capital: 0,
    CodigoVerificacion: '',
    Domicilio: '',
    Objeto: '',
    Comparecientes: [],
    Socios: [],
    Articulos: {
      Duracion: '',
      DelCapitalSocial: '',
      DeLaResponsabilidadDeSocios: '',
      DeLaAdministracion: '',
      DeLasUtilidadesPerdidasRetirosGastos: '',
      DelArbitraje: '',
      DeLaLiquidacion: '',
      DeLosPoderesALosAdministradores: '',
      JuntasAccionistas: '',
    },
    Facultades: [],
  };
  public informacionAdicionalTable: any;
  public selectedIndex = 0;
  public articulos: Articulos[] = [];
  public details: any = {
    duracion: false,
    DelCapitalSocial: false,
    DeLaResponsabilidadDeSocios: false,
    DeLaAdministracion: false,
    DeLasUtilidadesPerdidasRetirosGastos: false,
    DelArbitraje: false,
    DeLaLiquidacion: false,
    DeLosPoderesALosAdministradores: false
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(/*private simpleModalService: SimpleModalService*/) { }

  ngOnInit(): void {
    // TODO: this.content is null -> alert.warn
    if (this.content) {
      for (const certificado of this.content.EmpresaEnUnDiaReporte.Certificados) {
        if ("CertificadoDeEstatutos" in certificado) {
          this.certificado = certificado.CertificadoDeEstatutos!;
          this.loadInformacionAdicionalTable();
        }
      }
    }
    this.articulos.push(this.certificado?.Articulos);
    
    
  }

  public setIndex(index: number): void {
    this.selectedIndex = index;
  }

  public loadInformacionAdicionalTable(): void {
    this.informacionAdicionalTable = [
      {
          title: "Comparecientes",
          total: this.certificado.Comparecientes?.length || 0,
          dataTargetTag: 'nav-comparecientes'
      },
      {
          title: "Socios",
          total: this.certificado.Socios?.length || 0,
          dataTargetTag: 'nav-socios'
      },
      {
          title: "Facultades / Poderes",
          total: this.certificado.Facultades?.length || 0,
          dataTargetTag: 'nav-facultades'
      },
      {
          title: "Artículos",
          total: Object.keys(this.certificado.Articulos)?.length || 0,
          dataTargetTag: 'nav-articulos'
      }
    ]
  }

  public showDeatils(valKey: string) {
    this.details[valKey] = !this.details[valKey];
  }

}
