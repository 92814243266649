import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { listadoReport } from 'src/app/models/utils/utils.model';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';
import { AlertService } from '../../_alert';

@Component({
  selector: 'app-informe-leasing-sbif',
  templateUrl: './informe-leasing-sbif.component.html',
  styleUrls: ['./informe-leasing-sbif.component.scss']
})
export class InformeLeasingSbifComponent implements OnInit, OnDestroy {
  @Input() informeNuevo: boolean = false;
  @Input() idTransaccion: string = '';
  @Input() stepper!: number;
  @Input() rut: string = '';
  public dataPersonas: any[] = [];
  public seeContent: boolean = false;
  public personBox: any[] = [];
  public boxCheck: any[] = [];
  public showContent: boolean = false;
  public informe: any[] = [];
  public informeSbif: any[] = [];
  public informeLeasing: any[] = [];
  public subscriptions: Subscription[] = [];
  public listadoReport: any = listadoReport;
  public documento!: any;
  public objectKeys = Object.keys;
  public fecha: any = {
    tipo: '',
    dato: ''
  };

  constructor(
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private solicitudCreditoService: SolicitudCreditoService,
    @Inject(DOCUMENT) private document: Document) {
    this.documento = this.document;
  }

  ngOnInit(): void {
    this.obtenerFlujo();
  }

  /**
   * @description
   * Metodo que permite obtener en que paso del flujo se encuentra la transaccion
   */
  obtenerFlujo() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.spinner.hide();
      const pasosCompleted = resp.stages.filter((e: any) => e.completed);
      const cantidad = pasosCompleted.length;
      const pasoActual = Number(pasosCompleted[cantidad - 1].stageID) + 2;

      if (pasoActual < this.stepper) {
        this.alertService.error(`Debe completar el paso N° ${pasoActual} para poder continuar`);
        this.solicitudCreditoService.setStep(pasoActual);
      } else {        
        if (pasoActual >= this.stepper) {
          this.searchTx(pasoActual);
        }
      }
    }));
  }

  /**
   * @description
   * Metodo que permite obtener la fecha de la transaccion
   * @param obj 
   */
  setFecha(obj: any) {
    const fechaSbif = obj?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Sbif?.DatosBasicosSolicitud?.FechaReporte || '';
    const fechaLeasing = obj?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Leasing?.DatosBasicosSolicitud?.FechaReporte || '';
    fechaSbif ? (this.fecha.dato = this.solicitudCreditoService.formatDate(fechaSbif), this.fecha.tipo = 'fecha') :
      fechaLeasing ? (this.fecha.dato = this.solicitudCreditoService.formatDate(fechaLeasing), this.fecha.tipo = 'fecha') :
        (this.fecha.dato = 'Sin información', this.fecha.tipo = 'text');
  }

  /**
   * @description
   * Metodo que permite ver los datos de la transaccion
   */
  searchTx(pasoActual: number) {
    this.spinner.show();
    this.solicitudCreditoService.searchSolicitudesCreditoID(this.idTransaccion, this.rut).subscribe(resp => {
      this.spinner.hide();
      
      pasoActual > this.stepper ?
      // Asignamos los datos de la transaccion a los informes de leasing y sbif
      (this.seeContent = true,
        this.setFecha(resp),
        this.informeLeasing = this.solicitudCreditoService.getInformeLeasing(resp)[0],
        this.informeSbif = this.solicitudCreditoService.setInformeSbif(resp),
        this.listadoPersonas(resp))
        // Obtener informe de leasing sbif
        : this.getInformeLeasingSbif(resp)
        

    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    });
  }

  /**
   * @description
   * Metodo que busca el historico de una transaccion
   * @param obj
   * @param respuesta
   */
  getInformeLeasingSbif(obj: any) {
    this.informe = obj;
    this.buscarInformeHistorico(obj);
  }

  /**
   * @description
   * Metodo para obtener el listado de socios y empresa
   * @param obj 
   */
  listadoPersonas(obj: any) {
    this.solicitudCreditoService.orderInformeComercial(obj).subscribe(resp => {
      this.dataPersonas = resp;
    });
  }

  /**
   * @description
   * Metodo para buscar el historico de un informe
   * @param obj 
   */
  buscarInformeHistorico(obj: any) {
    this.spinner.show();
    this.solicitudCreditoService.obtenerEmpresaSocios(obj).subscribe(response => {
      this.subscriptions.push(
        this.solicitudCreditoService.searchInformeLeasingSbif(this.idTransaccion, response).subscribe(resp => {
          this.seeContent = true;
          this.spinner.hide();
          this.informeLeasing = this.solicitudCreditoService.getInformeLeasing(resp)[0];
          this.informeSbif = this.solicitudCreditoService.setInformeSbif(resp);
          this.setFecha(resp);
          this.listadoPersonas(resp);
        }, (error: any) => {
          this.spinner.hide();
          this.alertService.error(error.error.message || 'Ocurrio un Error');
        }));
    }, (error: any) => {
      this.spinner.hide();
      this.alertService.error(error.error.message || 'Ocurrio un Error');
    });

  }

  /**
   * @description
   * Metodo que permite ver el detalle del informe sbif y leasing de la transaccion
   */
  verInforme() {
    if (this.fecha.tipo === 'text') {
      this.alertService.error('Debe obtener un informe primero');
    } else {
      this.showContent = !this.showContent;
      this.seeContent = true;
    }
  }

  /**
   * @description
   * Metodo que agrega o eliminar una empresa o socio de la lista de empresas o socios
   * @param item 
   * @param e 
   * @param tipo 
   */
  onFirstCheckboxChange(item: any, e: any, tipo: string) {
    if (e.target.checked) {
      this.boxCheck.push({
        Rut: item.Rut.replace(/\./g, ''),
        type: tipo,
        Nombre: item.Nombre ? item.Nombre : item.NombreORazonSocial
      });

    } else {
      this.boxCheck = this.boxCheck.filter(f => f.Rut.replace(/\./g, '') !== item.Rut.replace(/\./g, ''));
      this.personBox = this.personBox.filter(f => f.Rut.replace(/\./g, '') !== item.Rut.replace(/\./g, ''));
    }
  }

  /**
   * @description
   * Metodo para eliminar un archivo de la lista de archivos
   * @param element 
   */
  reset(element: string) {
    (<HTMLInputElement>this.documento.getElementById(element)).value = "";
    this.personBox = this.personBox.filter((e: any) => e.id !== element);
  }

  /**
   * @description
   * Metodo que permite agregar un archivo a la lista de archivos
   * @param event 
   * @param person 
   * @param tipoDeuda 
   * @param id 
   */
  handleUpload(event: any, person: any, tipoDeuda: string, id: string) {

    let archivo: any = '';

    if (event.target.files && event.target.files[0]) {
      const name = event.target.files[0].name;
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        archivo = reader.result;
        const {
          Nombre,
          Rut,
          type
        } = this.boxCheck.filter(f => f.Rut === person.Rut)[0];

        if (this.personBox.filter(f => f.id === id).length === 0) {
          this.personBox.push({
            Nombre: Nombre,
            Rut: Rut,
            [tipoDeuda === 'sbif' ? 'fileSbif' : 'fileLeasing']: archivo.split(',')[1],
            fileName: name,
            tipoDeuda: tipoDeuda,
            type: type,
            id: id
          });
        } else {
          const persona = this.personBox.filter(f => f.id === id)[0];
          persona[tipoDeuda === 'sbif' ? 'fileSbif' : 'fileLeasing'] = archivo.split(',')[1];
          persona.fileName = name;
        }
      }
    }

  }

  /**
   * @description
   * Metodo para verificar si una persona tiene un archivo cargado para el tipo de deuda
   * @param person 
   * @param tipoDeuda 
   * @returns 
   */
  seePersonExist(person: any, tipoDeuda: string) {
    const exist = this.personBox.filter(f => f.Rut === person.Rut &&
      f.tipoDeuda === tipoDeuda);
    return exist.length > 0 ? exist[0] : false;

  }

  /**
   * @description
   * Metodo para avanzar en el proceso del informe
   */
  changeStep() {
    this.solicitudCreditoService.setStep('next');
  }

  /**
   * @description
   * Metodo para enviar la consulta para los archivos sbif y leasing
   */
  onSubmit(): void {
    if (this.personBox.length === 0) {
      this.alertService.error(`Debe Seleccionar uno o varios items de la columna izquierda
      y cargar al menos un archivo para obtener un informe`);
    } else {
      if (this.personBox.length > 0) {
        const joinPerson: any[] = [];

        this.personBox.filter(g => !g.send).forEach(e => {
          const exist = joinPerson.filter(f => f.Rut === e.Rut);
          if (exist.length === 0) {
            joinPerson.push(e);
          } else {
            const index = joinPerson.indexOf(exist[0]);
            if (joinPerson[index].tipoDeuda === 'sbif') {
              joinPerson[index]['fileLeasing'] = e['fileLeasing'];
            } else {
              joinPerson[index]['fileSbif'] = e['fileSbif'];
            }
          }
        });


        if (joinPerson.length > 0) {
          this.spinner.show();
          this.subscriptions.push(
            this.solicitudCreditoService.crearInformeSbifLeasing(this.idTransaccion, joinPerson).subscribe(resp => {
              this.spinner.hide();
              this.obtenerInformeLeasingSbif(joinPerson, resp);
            }, (error: any) => {
              this.alertService.error(error.error.message || 'Ocurrio un Error');
              this.spinner.hide();
            }));
        }
      } else {
        this.alertService.error(`Debe Seleccionar uno o varios items de la columna izquierda
        y cargar al menos un archivo para obtener un informe`);
      }
    }
  }

  /**
   * @description
   * Metodo para obtener el informe de leasing y sbif 
   * @param joinPerson 
   * @param obj 
   */
  async obtenerInformeLeasingSbif(joinPerson: any, obj: any): Promise<void> {
    joinPerson.forEach((element: any) => {
      // verificar si el usuario principal cuenta con el IdTransaccion sino lo sacamos de la lista
      if (element.type === 'primary') {
        element.idTransaccion = obj?.SolicitudDeCredito.Reportes?.leasingSbif?.DatosBasicosSolicitud?.IDTransaccion || '';
        element.idTransaccion ||
          (joinPerson = joinPerson.filter((f: any) => f.Rut !== element.Rut));
      } else {
        if (obj?.SolicitudDeCredito.Socios.length > 0) {
          // verificar si el usuario socio cuenta con el IdTransaccion sino lo sacamos de la lista
          const socio = obj?.SolicitudDeCredito.Socios.find((f: any) => f.Rut === element.Rut);
          element.idTransaccion = socio.Reportes?.leasingSbif?.IDTransaccion;
          element.idTransaccion ||
            (joinPerson = joinPerson.filter((f: any) => f.Rut !== element.Rut));
        }
      }
    });

    this.spinner.show();
    // Realizamos la peticion para obtener el informe de leasing y sbif de los usuarios que tengan idTransaccion
    const promiseResp = await this.solicitudCreditoService.getInforme(this.idTransaccion, joinPerson);
    this.spinner.hide();
    if (promiseResp) {
      this.personBox.forEach(e => e.send = true);
      this.bloquearSeleccionados();
      this.getInformeLeasingSbif(promiseResp);
    } else {
      this.alertService.error(promiseResp?.error?.message || 'Ocurrio un Error');
    }
  }

  /**
   * @description
   * Metodo para verificar si el usuario ya envió el informe y por tipo de deuda 
   * @param person 
   * @param tipoDeuda 
   * @returns 
   */
  checkUserIfSend(person: any, tipoDeuda: string): boolean {
    return this.personBox.find(f => f.Rut === person.Rut && f.tipoDeuda === tipoDeuda)?.send ? true : false;
  }

  /**
   * @description
   * Metodo para bloquear los checkbox de los socios que ya fueron enviados 
   */
  bloquearSeleccionados(): void {
    this.personBox = this.personBox.filter(e => e.send);
  }

  /**
   * Metodo para verificar si el usuario ya envió el informe 
   * @param rut 
   * @returns boolean
   */
  checkUserBloq(rut: string): boolean {
    if (this.personBox.length > 0) {
      if (this.personBox.find(e => e.Rut === rut.replace(/\./g, '') && e.send)) {
        return true
      }
    }
    return false
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
