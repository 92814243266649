import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';

enum GruposServiceActions {
  CREATE_GRUPO = "crearGrupo",
  ASIGNAR_USUARIO_GRUPO = "asignarUsuarioAGrupo",
  BORRAR_USUARIO_GRUPO = "borrarUsuarioDeGrupo",
  BORRAR_GRUPO = "borrarGrupo",
  LISTAR_GRUPOS = "listarGruposUsuarios",
}

@Injectable({
  providedIn: 'root'
})
export class GruposService {
  private endpoint: string = environment.backend.usuarios;
  private userPool: string = environment.cognitoConfig.userPoolId;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService
  ) { }

  crearGrupo(groupName: string): Observable<any> {
    const payload: any = {
      userName: this._sessionService.getUserInfo().username,
      groupName: groupName,
      organization: this._sessionService.getOrganization(),
      operation: GruposServiceActions.CREATE_GRUPO
    }
    return this.http.post<any>(this.endpoint, payload);
  }

  asignarUsuarioAGrupo(users: string[], groupName: string): Observable<any> {
    const payload: any = {
      userName: this._sessionService.getUserInfo().username,
      userPoolID: this.userPool,
      userToAssign: users,
      groupName: groupName,
      organization: this._sessionService.getOrganization(),
      operation: GruposServiceActions.ASIGNAR_USUARIO_GRUPO
    }
    return this.http.post<any>(this.endpoint, payload);
  }

  borrarUsuarioDeGrupo(users: string[], groupName: string): Observable<any> {
    const payload: any = {
      userPoolID: this.userPool,
      userName: this._sessionService.getUserInfo().username,
      userToDelete: users,
      groupName: groupName,
      organization: this._sessionService.getOrganization(),
      operation: GruposServiceActions.BORRAR_USUARIO_GRUPO
    }
    return this.http.post<any>(this.endpoint, payload);
  }

  borrarGrupo(groupName: string): Observable<any> {
    const payload: any = {
      userName: this._sessionService.getUserInfo().username,
      groupName: groupName,
      organization: this._sessionService.getOrganization(),
      operation: GruposServiceActions.BORRAR_GRUPO
    }
    return this.http.post<any>(this.endpoint, payload);
  }

  listarGruposUsuarios(): Observable<any> {
    const payload: any = {
      userName: this._sessionService.getUserInfo().username,
      organization: this._sessionService.getOrganization(),
      operation: GruposServiceActions.LISTAR_GRUPOS
    }
    return this.http.post<any>(this.endpoint, payload);
  }

}
