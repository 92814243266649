<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<ng-container *ngIf="hasUserAccess">

    <div *ngIf="showBuscador">
        <div class="container__form">
            <div class="col-md-6">
                <div class="block">
                    <form [formGroup]="rutForm" (ngSubmit)="submitRut()">
                        <div class="form-horizontal form-bordered">
                            <div class="form-group" [ngClass]="{'has-error': activateRutHelper}">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="col-md-3 control-label" for="example-text-input">Rut</label>
                                        <div class="col-md-6">
                                            <input formControlName="rut" type="text" class="form-control" (input)="changeRut()" formatRut required>
                                            <span *ngIf="activateRutHelper" class="help-block">Rut no valido</span>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="consultacredencialesSinc">
                                        <div class="col-sm-12 mt-3">
                                            <label class="col-md-3 control-label" for="claveSii">{{credencialesSinc ? 'ClaveSii (opcional)' : 'ClaveSii'}}</label>
                                            <div class="col-md-6">
                                                <input formControlName="claveSii" id="claveSii" type="password" class="form-control">
                                                <div class="w-100">
                                                    <app-error [control]="rutForm.get('claveSii')"></app-error>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <label class="col-md-3 control-label" title="Sincronizar credenciales">Sincronizar Credenciales</label>
                                                <div class="col-md-8 px-0">
                                                    <div class="col-sm-12">
                                                        <label class="switch switch-primary">
                                                            <input type="checkbox" formControlName="sincronizar">
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <span 
                                                            class="d-inline-block mb-3 text-muted cursor-pointer cursor-pointer-info"
                                                            >
                                                            Al sincronizar credenciales se da acceso a nivel de organización.
                                                            <i class="fa fa-exclamation-circle text-warning" aria-hidden="true"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </ng-container>
                                </div>
                            </div>
                            <div class="form-group form-actions container__actions">
                                <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i>
                                    Crear Reporte</button>
                                <button type="button" (click)="clearForm()" class="btn btn-sm btn-warning"><i class="fa fa-repeat"></i>
                                    Limpiar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!showBuscador">
        <div class="container-gtp no-print">
            <div class="panel panel-default">
                <div class="panel-body">
                    <div class="btn-option">
                        <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                        <div>
                            <div class="dropdown">
                               <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                 Descargar PDF
                               </button>
                               <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                 <button class="dropdown-item" [printTitle]='"Tesoreria_"+(rut | rutFormat)+"_"+today'
                                 [useExistingCss]="true" printSectionId="print-section" ngxPrint (mouseenter)="onHoverCompleta()" >Vista Completa</button>
                                 <button class="dropdown-item" [printTitle]='"Tesoreria_"+(rut | rutFormat)+"_"+today'
                                 [useExistingCss]="true" printSectionId="print-section" ngxPrint (mouseenter)="onHoverResumida()" >Vista Resumida</button>
                               </div>
                             </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="print-section">
            <app-reporte-tesoreria-view [rut]="rut" [reporteFullContent]="reporte" [isResumida]="isResumida" [printClick]="printClick.asObservable()"></app-reporte-tesoreria-view>
        </div>
    </div>
    
</ng-container>    

