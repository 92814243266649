<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>
<div *ngIf="hasUserAccess && !showReport">
    <div class="container__form">
        <div class="col-md-6">
            <div class="block">
                <form [formGroup]="vehiculoReportForm" (ngSubmit)="requestReport()">
                    <div class="form-horizontal form-bordered">
                        <div class="form-group" [ngClass]="{'has-error': activateRutHelper}">
                            <label class="col-md-3 control-label">Rut</label>
                            <div class="col-md-6">
                                <input formControlName="rut" type="text" class="form-control" formatRut required>
                                <span *ngIf="activateRutHelper" class="help-block">Rut no valido</span>
                            </div>
                        </div>
                        <div class="form-group form-actions container__actions">
                            <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i>
                                Crear Reporte</button>
                            <button type="button" (click)="clearForm()" class="btn btn-sm btn-warning"><i
                                    class="fa fa-repeat"></i>
                                Limpiar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showReport">
    <div class="container-gtp">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="btn-option">
                    <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                    <button [printTitle]='"Vehiculos_"+(rut | rutFormat)+"_"+today'
                    [useExistingCss]="true" printSectionId="print-section" ngxPrint class="btn btn-primary">Descargar PDF</button>
                </div>
            </div>
        </div>
    </div>

    <div id="print-section">
        <app-vehiculos-viewer [content]='vehiculoReportDataSource'></app-vehiculos-viewer>
    </div>
</div>