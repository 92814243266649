<div class="" [ngClass]="{'container-gtp': showHeader}">
    <ng-container *ngIf="showHeader">
        <img class="banner-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt=""/>
        <h2 class="text-center">REPORTE PODER JUDICIAL</h2>
        <h5 class="text-center"><span
            class="badge bg-secondary text-center">{{content.poderJudicialReporte.datosBasicosSolicitud.Rut |
            uppercase | number : '1.0-0'}}-{{content.poderJudicialReporte.datosBasicosSolicitud.DV | uppercase}}</span></h5>
        </ng-container>
    <div class="row">
        <div class="alert alert-warning alert-font text-center" role="alert">
            Para conocer si hay actualizaciones a las causas informadas deberá ir al link donde podrá consultar directamente en el poder judicial la posible actualización
            </div>
        <div class="col-sm-12">
           <div class="judicial">
            <ul class="nav nav-tabs">
                <li class="active">
                    <button type="button" class="btn btn-secondary" (click)='contentShow = "civiles"; selectedCivilIndex = 0'>Civiles <span
                        class="badge bg-secondary">{{totalCivil}}</span><img
                        [src]="totalCivil > 0 ? 'assets/img/rojo.png' : 'assets/img/verde.png'"
                        width="18"
                        height="18" alt="" /></button>  
                </li>
                <li>
                    <button type="button" class="btn btn-secondary"  (click)='contentShow = "laboral"; selectedLaboralIndex = 0'>Laborales <span
                            class="badge bg-secondary">{{totalLaboral}}</span><img
                            [src]="totalLaboral > 0 ? 'assets/img/rojo.png' : 'assets/img/verde.png'"
                            width="18"
                            height="18" alt="" /></button>
                </li>
                <li>
                    <button type="button" class="btn btn-secondary" (click)='contentShow = "cobranza"; selectedCobranzaIndex = 0'>Cobranza Laboral <span
                        class="badge bg-secondary">{{totalCobranza}}</span><img
                        [src]="totalCobranza > 0 ? 'assets/img/rojo.png' : 'assets/img/verde.png'"
                        width="18"
                        height="18" alt="" /></button>
                </li>
                <li>
                    <button type="button" class="btn btn-secondary" (click)='contentShow = "tributario"'>Tributario <span
                        class="badge bg-secondary">{{totalTributario}}</span><img
                        [src]="totalTributario > 0 ? 'assets/img/rojo.png' : 'assets/img/verde.png'"
                        width="18" height="18" alt="" /></button> 
                </li>
              </ul>
                <!-- <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-secondary">Civiles <span
                            class="badge bg-secondary">{{totalCivil}}</span><img
                            [src]="totalCivil > 0 ? 'assets/img/rojo.png' : 'assets/img/verde.png'"
                            width="18"
                            height="18" alt="" /></button>
                    <button type="button" class="btn btn-secondary">Laborales <span
                            class="badge bg-secondary">{{totalLaboral}}</span><img
                            [src]="totalLaboral > 0 ? 'assets/img/rojo.png' : 'assets/img/verde.png'"
                            width="18"
                            height="18" alt="" /></button>
                    <button type="button" class="btn btn-secondary">Cobranza Laboral <span
                            class="badge bg-secondary">{{totalCobranza}}</span><img
                            [src]="totalCobranza > 0 ? 'assets/img/rojo.png' : 'assets/img/verde.png'"
                            width="18"
                            height="18" alt="" /></button>
                    <button type="button" class="btn btn-secondary">Tributario <span
                            class="badge bg-secondary">{{totalTributario}}</span><img
                            [src]="totalTributario > 0 ? 'assets/img/rojo.png' : 'assets/img/verde.png'"
                            width="18" height="18" alt="" /></button>
                </div> -->
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div *ngIf="hasCivil" class="tab-pane fade" [ngClass]="[contentShow === 'civiles'? 'showContent in active': 'd-none']">
                <div id="civiles">
                    <div class="card textos-campo">
                        <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-info-circle"
                                style="font-size:28px;">&nbsp;</i>CIVILES <p class="d-inline fecha_size" *ngIf="fromConsolidado">({{content?.poderJudicialReporte?.datosBasicosSolicitud?.FechaReporte | date: 'dd-MM-yyyy'}})</p>
                                <a type="button" class="btn btn-primary float-right no-print" href="https://oficinajudicialvirtual.pjud.cl/includes/sesion-consultaunificada.php" target="_blank">Revisar en Poder Judicial</a>
                            </h5>
                        <div class="card-body">
                            <table class="table table-hover table-borderless">
                                <tbody>
                                    <tr>
                                        <th width="4%" nowrap="nowrap" scope="row"></th>
                                        <th width="24%">Rol</th>
                                        <th width="24%">Fecha</th>
                                        <th width="24%">Caratulado</th>
                                        <th width="24%">Tribunal</th>
                                    </tr>
                                    <tr *ngFor="let causa of content.poderJudicialReporte.poderJudicial.Civil">
                                        <th nowrap="nowrap" scope="row"><a
                                                style="color: #0d6efd; text-decoration: underline; cursor: pointer;"
                                                (click)="selectCivilCase(causa.rol); selectedCivilIndex = 0"><i class="fa fa-search"></i></a>
                                        </th>
                                        <td>{{causa.rol}}</td>
                                        <td>{{causa.fecha}}</td>
                                        <td>{{causa.caratulado}}</td>
                                        <td>{{causa.tribunal}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="civilCaseSelected">
                    <div class="col-sm-12">
                        <div class="card">
                            <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-info-circle"
                                    style="font-size:28px;">&nbsp;</i>DETALLE CAUSA CIVIL</h5>
                            <div class="card-body"> <br>
                                <br>
                                <table class="table table-hover table-borderless">
                                    <tbody>
                                        <tr>
                                            <td width="9%"><strong>ROL:</strong> {{civilCaseSelected.rol}}</td>
                                            <td width="7%"><strong>Fecha Ingreso:</strong> {{civilCaseSelected.fecha}}
                                            </td>
                                            <td width="10%">{{civilCaseSelected.caratulado}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Estado Adm.</strong>: {{civilCaseSelected.details["Est. Adm.:"]}}</td>
                                            <td><strong>Procedimiento:</strong> {{civilCaseSelected.details["Proc.:"]}}
                                            </td>
                                            <td><strong>Ubicación:</strong> {{civilCaseSelected.details["Ubicación:"]}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Estado Procedimiento:</strong>
                                                {{civilCaseSelected.details["Estado Proc.:"]}}</td>
                                            <td><strong>Etapa:</strong> {{civilCaseSelected.details["Etapa:"]}}</td>
                                            <td><strong>Tribunal:</strong> {{civilCaseSelected.details["Tribunal:"]}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <br>
                                <table class="table table-hover table-borderless">
                                    <tbody>
                                        <tr>
                                            <td width="34%"><strong>Historia Causa Cuaderno</strong></td>
                                            <td width="66%"><strong>Información notificaciones receptor:</strong></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="form-group">
                                                    <select class="form-control form-control-sm"
                                                        id="exampleFormControlSelect1">
                                                        <option>1- Principal</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <h2><a href="#"><i class="fa fa-folder"></i></a></h2>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> -->
                                <div class="bd-example">
                                    <nav>
                                        <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                                            <div *ngFor="let buttonInfo of navTabsCivil; let i = index">
                                                <a class="nav-link active" id="{{buttonInfo.dataTargetTag}}-tab"
                                                    [ngClass]="{'active': selectedCivilIndex === i }"
                                                    data-bs-toggle="tab" data-toggle="tab" style="cursor: pointer;"
                                                    role="tab" data-target="#{{buttonInfo.dataTargetTag}}"
                                                    [attr.aria-controls]="buttonInfo.dataTargetTag"
                                                    (click)="setIndex('civil', i)"
                                                    aria-selected="true">{{buttonInfo.title}}
                                                    <!-- <span class="badge bg-secondary">{{buttonInfo.total}}</span> -->
                                                </a>
                                            </div>
                                        </div>
                                    </nav>
                                    <div class="tab-content" id="nav-tabContent">
                                        <div class="tab-pane" [ngClass]="{'active show': selectedCivilIndex === 0 }"
                                            id="nav-historia" role="tabpanel" aria-labelledby="nav-historia-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="3%" align="center" scope="col">Folio</th>
                                                        <th width="8%" align="center">Anexo</th>
                                                        <th width="10%" align="center">Etapa</th>
                                                        <th width="10%" align="center">Trámite</th>
                                                        <th width="25%" align="center">Desc. Trámite</th>
                                                        <th width="13%" align="center">Fecha trámite</th>
                                                        <th width="14%" align="center">Foja</th>
                                                        <th width="17%" align="center">Georreferencia</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let historia of civilCaseSelected.details.Historia">
                                                        <td align="left">{{historia["Folio"]}}</td>
                                                        <td align="left"></td>
                                                        <td align="left">{{historia["Etapa"]}}</td>
                                                        <td align="left">{{historia["Trámite"]}}</td>
                                                        <td align="left">{{historia["Desc. Trámite"]}}</td>
                                                        <td align="left">{{historia["Fec. Trámite"]}}</td>
                                                        <td align="left">{{historia["Foja"]}}</td>
                                                        <td align="left"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane" [ngClass]="{'active show': selectedCivilIndex === 1 }"
                                            id="nav-litigantes" role="tabpanel" aria-labelledby="nav-litigantes-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th align="left" scope="col">participante</th>
                                                        <th align="left">Rut</th>
                                                        <th align="left">Persona</th>
                                                        <th align="left">Nombre o Razón Social</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let litigante of civilCaseSelected.details.Litigantes">
                                                        <td align="left" scope="row">{{litigante["Participante"]}}</td>
                                                        <td align="left" scope="row">{{litigante["Rut"] | rutFormat }}</td>
                                                        <td align="left" scope="row">{{litigante["Nombre o Razón Social"]}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane" [ngClass]="{'active show': selectedCivilIndex === 2 }"
                                            id="nav-notificaciones" role="tabpanel"
                                            aria-labelledby="nav-notificaciones-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="13%" align="center" scope="col">Rol</th>
                                                        <th width="13%" align="center" scope="col">Est. Notif.</th>
                                                        <th width="13%" align="center" scope="col">Tipo Notif.</th>
                                                        <th width="13%" align="center" scope="col">Fecha trámite</th>
                                                        <th width="13%" align="center" scope="col">Tipo Part.</th>
                                                        <th width="13%" align="center" scope="col">Nombre</th>
                                                        <th width="13%" align="center" scope="col">Trámite</th>
                                                        <th width="13%" align="center" scope="col">Obs. Fallida</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let notificacion of civilCaseSelected.details.Notificationes">
                                                        <td align="left" scope="row">{{notificacion["ROL"]}}</td>
                                                        <td align="left" scope="row">{{notificacion["Esti. Notif."]}}
                                                        </td>
                                                        <td align="left" scope="row">{{notificacion["Tipo Notif."]}}
                                                        </td>
                                                        <td align="left" scope="row">{{notificacion["Fecha Trámite"]}}
                                                        </td>
                                                        <td align="left" scope="row">{{notificacion["Tipo Part."]}}</td>
                                                        <td align="left" scope="row">{{notificacion["Nombre"]}}</td>
                                                        <td align="left" scope="row">{{notificacion["Trámite"]}}</td>
                                                        <td align="left" scope="row"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane" [ngClass]="{'active show': selectedCivilIndex === 3 }"
                                            id="nav-escritos" role="tabpanel" aria-labelledby="nav-escritos-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="13%" align="center" scope="col">Doc.</th>
                                                        <th width="13%" align="center" scope="col">Anexo</th>
                                                        <th width="13%" align="center" scope="col">Fecha de ingreso</th>
                                                        <th width="13%" align="center" scope="col">Tipo Escritoe</th>
                                                        <th width="13%" align="center" scope="col">Solicitante</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane" [ngClass]="{'active show': selectedCivilIndex === 4 }"
                                            id="nav-exhortos" role="tabpanel" aria-labelledby="nav-exhortos-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="13%" align="center" scope="col">Rol Origen</th>
                                                        <th width="13%" align="center" scope="col">Tipo Exhorto.</th>
                                                        <th width="13%" align="center" scope="col">Rol Destino</th>
                                                        <th width="13%" align="center" scope="col">Fecha Ordena Exhorto
                                                        </th>
                                                        <th width="13%" align="center" scope="col">Fecha Ingreso
                                                            Exhorto.
                                                        </th>
                                                        <th width="13%" align="center" scope="col">Tribunal Destino</th>
                                                        <th width="13%" align="center" scope="col">Estado Exhorto</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="hasLaboral" class="tab-pane fade" [ngClass]="[contentShow === 'laboral'? 'showContent in active': 'd-none']">
                <!-- inicio laborales -->
                <div id="laborales">
                    <div class="card textos-campo">
                        <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-info-circle"
                                style="font-size:28px;">&nbsp;</i>LABORALES <p class="d-inline fecha_size" *ngIf="fromConsolidado">({{content?.poderJudicialReporte?.datosBasicosSolicitud?.FechaReporte | date: 'dd-MM-yyyy'}})</p>
                                <a type="button" class="btn btn-primary float-right no-print" href="https://oficinajudicialvirtual.pjud.cl/includes/sesion-consultaunificada.php" target="_blank">Revisar en Poder Judicial</a>
                            </h5>
                        <div class="card-body">
                            <table class="table table-hover table-borderless">
                                <tbody>
                                    <tr>
                                        <th width="1%" nowrap="nowrap" scope="row"></th>
                                        <th width="11%">Rit</th>
                                        <th width="25%">Tribunal</th>
                                        <th width="44%">Caratulado</th>
                                        <th width="9%">Fecha Ingreso</th>
                                        <th width="10%">Estado Causa</th>

                                    </tr>
                                    <tr *ngFor="let causa of content.poderJudicialReporte.poderJudicial.Laboral">
                                        <th nowrap="nowrap" scope="row"><a
                                                style="color: #0d6efd; text-decoration: underline; cursor: pointer;"
                                                (click)="selectLaboralCase(causa.rit); selectedLaboralIndex = 0"><i class="fa fa-search"></i></a>
                                        </th>
                                        <td>{{causa.rit}}</td>
                                        <td>{{causa.tribunal}}</td>
                                        <td>{{causa.caratulado}}</td>
                                        <td>{{causa["fecha ingreso"]}}</td>
                                        <td>{{causa["estado causa"]}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="laboralCaseSelected">
                    <div class="col-sm-12">
                        <div class="card">
                            <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-info-circle"
                                    style="font-size:28px;">&nbsp;</i>DETALLE CAUSA LABORAL</h5>
                            <div class="card-body">
                                <br>
                                <br>
                                <table class="table table-hover table-borderless">
                                    <tbody>
                                        <tr>
                                            <td width="9%"><strong>RIT: </strong>{{laboralCaseSelected.details["RIT :"]}}</td>
                                            <td width="7%">{{laboralCaseSelected.caratulado}}</td>
                                            <td width="10%"><strong>Fecha Ingreso: </strong>{{laboralCaseSelected.details["F. Ing.:"]}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>RUC.: </strong>{{laboralCaseSelected.details["RUC :"]}}</td>
                                            <td><strong>Procedimiento: </strong>{{laboralCaseSelected.details["Proc.:"]}}</td>
                                            <td><strong>Forma Inicio: </strong>{{laboralCaseSelected.details["Forma Inicio:"]}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Est. Adm.: </strong>{{laboralCaseSelected.details["Est. Adm.: "]}}</td>
                                            <td><strong>Etapa: </strong>{{laboralCaseSelected.details["Etapa:"]}}</td>
                                            <td><strong>Estado Proc: </strong>{{laboralCaseSelected.details["Estado Proc.:"]}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Tribunal: </strong>{{laboralCaseSelected.details["Tribunal:"]}}</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                                <table class="table table-hover table-borderless">
                                    <tbody>
                                        <tr>
                                            <td width="24%"><strong>Listado de Archivos de Audios de Audicencia</strong>
                                            </td>
                                            <td width="76%">
                                                <h3><i class="fa fa-music"></i></h3>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="bd-example">
                                    <nav>
                                        <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                                            <div *ngFor="let buttonInfo of navTabsLaboral; let i = index">
                                                <a class="nav-link" id="{{buttonInfo.dataTargetTag}}-tab"
                                                    [ngClass]="{'active': selectedLaboralIndex === i }"
                                                    data-bs-toggle="tab"
                                                    data-toggle="tab" style="cursor: pointer;" role="tab"
                                                    data-target="#{{buttonInfo.dataTargetTag}}"
                                                    [attr.aria-controls]="buttonInfo.dataTargetTag"
                                                    (click)="setIndex('laboral', i)" aria-selected="true">{{buttonInfo.title}}
                                                    <!-- <span class="badge bg-secondary">{{buttonInfo.total}}</span> -->
                                                </a>
                                                <!-- <a class="nav-link active" id="nav-movimientos-tab" data-bs-toggle="tab"
                                                    href="#nav-movimientos" role="tab" aria-controls="nav-movimientos"
                                                    aria-selected="true">Movimientos</a>
                                                <a class="nav-link" id="nav-litigantes2-tab" data-bs-toggle="tab"
                                                    href="#nav-litigantes2" role="tab" aria-controls="nav-litigantes2"
                                                    aria-selected="false">Litigantes</a>
                                                <a class="nav-link" id="nav-notificaciones2-tab" data-bs-toggle="tab"
                                                    href="#nav-notificaciones2" role="tab"
                                                    aria-controls="nav-notificaciones2"
                                                    aria-selected="false">Notificaciones</a>
                                                <a class="nav-link" id="nav-diligencias-tab" data-bs-toggle="tab"
                                                    href="#nav-diligencias" role="tab" aria-controls="nav-diligencias"
                                                    aria-selected="true">Diligencias</a>
                                                <a class="nav-link" id="nav-liquidacion-tab" data-bs-toggle="tab"
                                                    href="#nav-liquidacion" role="tab" aria-controls="nav-liquidacion"
                                                    aria-selected="true">liquidación</a>
                                                <a class="nav-link" id="nav-materias-tab" data-bs-toggle="tab"
                                                    href="#nav-materias" role="tab" aria-controls="nav-materias"
                                                    aria-selected="true">Materias</a>
                                                <a class="nav-link" id="nav-escritos-pendientes-tab"
                                                    data-bs-toggle="tab" href="#nav-escritos-pendientes" role="tab"
                                                    aria-controls="nav-escritos-pendientes"
                                                    aria-selected="true">Escritos
                                                    Pendientes</a> -->
                                            </div>
                                        </div>
                                    </nav>
                                    <div class="tab-content" id="nav-tabContent">
                                        <div class="tab-pane" [ngClass]="{'active show': selectedLaboralIndex === 0 }" id="nav-movimientos" role="tabpanel"
                                            aria-labelledby="nav-movimientos-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="3%" align="center" scope="col">Folio</th>
                                                        <th width="8%" align="center">Doc.</th>
                                                        <th width="10%" align="center">Anexos</th>
                                                        <th width="10%" align="center">Etapa</th>
                                                        <th width="25%" align="center">Trámite</th>
                                                        <th width="13%" align="center">Desc. Trámite</th>
                                                        <th width="13%" align="center">Fecha Trámite</th>
                                                        <th width="13%" align="center">Estado</th>
                                                        <th width="13%" align="center">Georref.</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let movimiento of laboralCaseSelected.details.Movimientos">
                                                        <td align="left">{{movimiento["Folio"]}}</td>
                                                        <td align="left"></td>
                                                        <td align="left"></td>
                                                        <td align="left">{{movimiento["Etapa"]}}</td>
                                                        <td align="left">{{movimiento["Trámite"]}}</td>
                                                        <td align="left">{{movimiento["Desc. Trámite"]}}</td>
                                                        <td align="left">{{movimiento["Fec. Trámite"]}}</td>
                                                        <td align="left">{{movimiento["Estado"]}}</td>
                                                        <td align="left"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane" [ngClass]="{'active show': selectedLaboralIndex === 1 }" id="nav-litigantes2" role="tabpanel"
                                            aria-labelledby="nav-litigantes2-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="3%" align="center" scope="col">Est.</th>
                                                        <th width="8%" align="center">Abog. Defensor</th>
                                                        <th width="10%" align="center">Sujeto</th>
                                                        <th width="10%" align="center">Rut</th>
                                                        <th width="25%" align="center">Persona</th>
                                                        <th width="13%" align="center">Nombre o Razón Social</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let litigante of laboralCaseSelected.details.Litigantes">
                                                        <td align="left"><i class="fa fa-check"></i></td>
                                                        <td align="left">{{litigante["Abog. Defensor"]}}</td>
                                                        <td align="left">{{litigante["Sujeto"]}}</td>
                                                        <td align="left">{{litigante["Rut"] | rutFormat}}</td>
                                                        <td align="left">{{litigante["Persona"]}}</td>
                                                        <td align="left">{{litigante["Nombre o Razón Social"]}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane" [ngClass]="{'active show': selectedLaboralIndex === 2 }" id="nav-notificaciones2" role="tabpanel"
                                            aria-labelledby="nav-notificaciones2-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="13%" align="center" scope="col">Estado Notif.</th>
                                                        <th width="13%" align="center" scope="col">RIT</th>
                                                        <th width="13%" align="center" scope="col">RUC</th>
                                                        <th width="13%" align="center" scope="col">Fecha trámite</th>
                                                        <th width="13%" align="center" scope="col">Tipo Parte</th>
                                                        <th width="13%" align="center" scope="col">Nombre</th>
                                                        <th width="13%" align="center" scope="col">Trámite</th>
                                                        <th width="13%" align="center" scope="col">Obs. Fallida</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let notificacion of laboralCaseSelected.details.Notificationes">
                                                        <td align="left" scope="row">{{notificacion["Estado Notif."]}}</td>
                                                        <td align="left" scope="row">{{notificacion["RIT."]}}</td>
                                                        <td align="left" scope="row">{{notificacion["RUC"]}}</td>
                                                        <td align="left" scope="row">{{notificacion["Fecha Trámite"]}}</td>
                                                        <td align="left" scope="row">{{notificacion["Tipo Part."]}}</td>
                                                        <td align="left" scope="row">{{notificacion["Nombre"]}}</td>
                                                        <td align="left" scope="row">{{notificacion["Trámite"]}}</td>
                                                        <td align="left" scope="row">{{notificacion["Obs. Fallida"]}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane" [ngClass]="{'active show': selectedLaboralIndex === 3 }" id="nav-diligencias" role="tabpanel"
                                            aria-labelledby="nav-diligencias-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="13%" align="center" scope="col">Doc. Ida</th>
                                                        <th width="13%" align="center" scope="col">Doc. Vta.</th>
                                                        <th width="13%" align="center" scope="col">Estado Diligencia
                                                        </th>
                                                        <th width="13%" align="center" scope="col">RIT</th>
                                                        <th width="13%" align="center" scope="col">RUC</th>
                                                        <th width="13%" align="center" scope="col">Tipo Diligencia</th>
                                                        <th width="13%" align="center" scope="col">Referencia</th>
                                                        <th width="13%" align="center" scope="col">Fecha Trámite</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane" [ngClass]="{'active show': selectedLaboralIndex === 4 }" id="nav-liquidacion" role="tabpanel"
                                            aria-labelledby="nav-liquidacion-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="13%" align="center" scope="col">Liquidación</th>
                                                        <th width="13%" align="center" scope="col">Rut</th>
                                                        <th width="13%" align="center" scope="col">Nombre</th>
                                                        <th width="13%" align="center" scope="col">Monto Líquido</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane" [ngClass]="{'active show': selectedLaboralIndex === 5 }" id="nav-materias" role="tabpanel"
                                            aria-labelledby="nav-materias-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="13%" align="center" scope="col">Código</th>
                                                        <th width="13%" align="center" scope="col">Glosa de Materia</th>
                                                        <th width="13%" align="center" scope="col">Estado</th>
                                                        <th width="13%" align="center" scope="col">Fecha Término</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let materia of laboralCaseSelected.details.Materias">
                                                        <td align="left" scope="row">{{materia["Código"]}}</td>
                                                        <td align="left" scope="row">{{materia["Glosa de Materia"]}}</td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        
                                        <div class="tab-pane" [ngClass]="{'active show': selectedLaboralIndex === 6 }" id="nav-escritos-pendientes" role="tabpanel"
                                            aria-labelledby="nav-escritos-pendientes-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="13%" align="center" scope="col">Doc.</th>
                                                        <th width="13%" align="center" scope="col">Anexo</th>
                                                        <th width="13%" align="center" scope="col">Fecha Ing.</th>
                                                        <th width="13%" align="center" scope="col">Referencia</th>
                                                        <th width="13%" align="center" scope="col">Solicitante</th>
                                                        <th width="13%" align="center" scope="col">Tipo Ingreso</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"><i class="fa fa-ban"></i></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="hasCobranza" class="tab-pane fade" [ngClass]="[contentShow === 'cobranza'? 'showContent in active': 'd-none']">
                <!-- inicio Cobranza laboral -->
                <div id="cobranza-laboral">
                    <div class="card textos-campo">
                        <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-info-circle"
                                style="font-size:28px;">&nbsp;</i>COBRANZA LABORAL <p class="d-inline fecha_size" *ngIf="fromConsolidado">({{content?.poderJudicialReporte?.datosBasicosSolicitud?.FechaReporte | date: 'dd-MM-yyyy'}})</p>
                                <a type="button" class="btn btn-primary float-right no-print" href="https://oficinajudicialvirtual.pjud.cl/includes/sesion-consultaunificada.php" target="_blank">Revisar en Poder Judicial</a>
                            </h5>
                        <div class="card-body">
                            <table class="table table-hover table-borderless">
                                <tbody>
                                    <tr>
                                        <th width="1%" nowrap="nowrap" scope="row"></th>
                                        <th width="11%">Rit</th>
                                        <th width="11%">Ruc</th>
                                        <th width="25%">Tribunal</th>
                                        <th width="44%">Caratulado</th>
                                        <th width="9%">Fecha Ingreso</th>
                                        <th width="10%">Estado Procesal</th>

                                    </tr>
                                    <tr *ngFor="let causa of content.poderJudicialReporte.poderJudicial.Cobranza">
                                        <th nowrap="nowrap" scope="row"><a
                                                style="color: #0d6efd; text-decoration: underline; cursor: pointer;"
                                                (click)="selectCobranzaCase(causa.rit); selectedCobranzaIndex = 0"><i class="fa fa-search"></i></a>
                                        </th>
                                        <td>{{causa.rit}}</td>
                                        <td>{{causa.ruc}}</td>
                                        <td>{{causa.tribunal}}</td>
                                        <td>{{causa.caratulado}}</td>
                                        <td>{{causa["fecha ingreso"]}}</td>
                                        <td>{{causa["estado causa"]}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="cobranzaCaseSelected">
                    <div class="col-sm-12">
                        <div class="card">
                            <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-info-circle"
                                    style="font-size:28px;">&nbsp;</i>DETALLE COBRANZA LABORAL</h5>
                            <div class="card-body"> <br>
                                <br>
                                <table class="table table-hover table-borderless">
                                    <tbody>
                                        <tr>
                                            <td width="9%"><strong>RIT:</strong> {{cobranzaCaseSelected.details["RIT:"]}}</td>
                                            <td width="7%">{{cobranzaCaseSelected.details["Juez Asignado:"]}}</td>
                                            <td width="10%"><strong>Fecha Ingreso:</strong>
                                                {{cobranzaCaseSelected.details["Fecha Ing.:"]}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>RUC.</strong>: {{cobranzaCaseSelected.details["RUC :"]}}</td>
                                            <td><strong>Est. Adm.:</strong> {{cobranzaCaseSelected.details["Est.Adm.:"]}}</td>
                                            <td><strong>Proc:</strong> {{cobranzaCaseSelected.details["Proc.:"]}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Forma Inicio.:</strong> {{cobranzaCaseSelected.details["Forma Inicio.:"]}}</td>
                                            <td><strong>Estado Proc.:</strong> {{cobranzaCaseSelected.details["Estado Proc.:"]}}</td>
                                            <td><strong>Etapa:</strong> {{cobranzaCaseSelected.details["Etapa:"]}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Título Ejec.:</strong> </td> <!-- TODO: ?? -->
                                            <td><strong>Juez Asignado</strong>. {{cobranzaCaseSelected.details["Juez Asignado:"]}}</td>
                                            <td><strong>Tribunal:</strong> {{cobranzaCaseSelected.details["Tribunal:"]}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <br>
                                <table class="table table-hover table-borderless">
                                    <tbody>
                                        <tr>
                                            <td width="34%"><strong>Historia Causa Cuaderno</strong></td>
                                            <td width="66%"><strong>Información notificaciones receptor:</strong></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="form-group">
                                                    <select name="exampleFormControlSelect"
                                                        class="form-control form-control-sm" id="exampleFormControlSelect2">
                                                        <option>1- Principal</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <h2><a href="#"><i class="fa fa-folder"></i></a></h2>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> -->
                                <div class="bd-example">
                                    <nav>
                                        <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                                            <div *ngFor="let buttonInfo of navTabsCobranza; let i = index">
                                                <a class="nav-link active" id="{{buttonInfo.dataTargetTag}}-tab"
                                                    [ngClass]="{'active': selectedCobranzaIndex === i }"
                                                    data-bs-toggle="tab" data-toggle="tab" style="cursor: pointer;"
                                                    role="tab" data-target="#{{buttonInfo.dataTargetTag}}"
                                                    [attr.aria-controls]="buttonInfo.dataTargetTag"
                                                    (click)="setIndex('cobranza', i)"
                                                    aria-selected="true">{{buttonInfo.title}}
                                                    <!-- <span class="badge bg-secondary">{{buttonInfo.total}}</span> -->
                                                </a>
                                            </div>
                                        </div>
                                    </nav>
                                    <div class="tab-content" id="nav-tabContent">
                                        <div class="tab-pane" [ngClass]="{'active show': selectedCobranzaIndex === 0 }"
                                            id="nav-historia3" role="tabpanel" aria-labelledby="nav-historia3-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="3%" align="center" scope="col">Doc.</th>
                                                        <th width="8%" align="center">Anexo</th>
                                                        <th width="10%" align="center">Etapa</th>
                                                        <th width="10%" align="center">Trámite</th>
                                                        <th width="25%" align="center">Desc. Trámite</th>
                                                        <th width="13%" align="center">Estado Firma</th>
                                                        <th width="14%" align="center">Fecha Trámite</th>
                                                        <th width="17%" align="center">Georreferencia</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let historia of cobranzaCaseSelected.details.Historia">
                                                        <td align="left"></td>
                                                        <td align="left"></td>
                                                        <td align="left">{{historia["Etapa"]}}</td>
                                                        <td align="left">{{historia["Trámite"]}}</td>
                                                        <td align="left">{{historia["Desc. Trámite"]}}</td>
                                                        <td align="left">{{historia["Estado Firma"]}}</td>
                                                        <td align="left">{{historia["Fec. Trámite"]}}</td>
                                                        <td align="left">{{historia["Cuaderno"]}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane" [ngClass]="{'active show': selectedCobranzaIndex === 1 }"
                                            id="nav-litigantes3" role="tabpanel" aria-labelledby="nav-litigantes3-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th align="left" scope="col">Sujeto</th>
                                                        <th align="left">Rut</th>
                                                        <th align="left">Persona</th>
                                                        <th align="left">Nombre o Razón Social</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let litigante of cobranzaCaseSelected.details.Litigantes">
                                                        <td align="left">{{litigante["Participante"]}}</td>
                                                        <td align="left">{{litigante["Rut"] | rutFormat}}</td>
                                                        <td align="left">{{litigante["Persona"]}}</td>
                                                        <td align="left">{{litigante["Nombre o Razón Social"]}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane" [ngClass]="{'active show': selectedCobranzaIndex === 2 }"
                                            id="nav-notificaciones3" role="tabpanel"
                                            aria-labelledby="nav-notificaciones3-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="13%" align="center" scope="col">Tipo Notif.</th>
                                                        <th width="13%" align="center" scope="col">Est. Notif.</th>
                                                        <th width="13%" align="center" scope="col">Fecha Notif.</th>
                                                        <th width="13%" align="center" scope="col">Fecha Trámite</th>
                                                        <th width="13%" align="center" scope="col">Trámite.</th>
                                                        <th width="13%" align="center" scope="col">Tip. Part.</th>
                                                        <th width="13%" align="center" scope="col">Nombre</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let notificacion of cobranzaCaseSelected.details.Notificationes">
                                                        <td align="left" scope="row">{{notificacion["Tipo Notif."]}}
                                                        </td>
                                                        <td align="left" scope="row">{{notificacion["Esti. Notif."]}}
                                                        </td>
                                                        <td align="left" scope="row">{{notificacion["Fec. Notif"]}}</td>
                                                        <td align="left" scope="row">{{notificacion["Fecha Trámite"]}}
                                                        </td>
                                                        <td align="left" scope="row">{{notificacion["Trámite"]}}</td>
                                                        <td align="left" scope="row">{{notificacion["Tipo Part."]}}</td>
                                                        <td align="left" scope="row">{{notificacion["Nombre"]}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane" [ngClass]="{'active show': selectedCobranzaIndex === 3 }"
                                            id="nav-diligencias3" role="tabpanel"
                                            aria-labelledby="nav-diligencias3-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="13%" align="center" scope="col">Estado Diligencia.
                                                        </th>
                                                        <th width="13%" align="center" scope="col">RIT</th>
                                                        <th width="13%" align="center" scope="col">RUC</th>
                                                        <th width="13%" align="center" scope="col">Tipo Diligencia</th>
                                                        <th width="13%" align="center" scope="col">Fecha Trámite</th>
                                                        <th width="13%" align="center" scope="col">Destinatario</th>
                                                        <th width="13%" align="center" scope="col">Responsable</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane" [ngClass]="{'active show': selectedCobranzaIndex === 4 }"
                                            id="nav-liquidacion3" role="tabpanel"
                                            aria-labelledby="nav-liquidacion3-tab">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="13%" align="center" scope="col">Liquidación</th>
                                                        <th width="13%" align="center" scope="col">Fecha Liquidación
                                                        </th>
                                                        <th width="13%" align="center" scope="col">Cuaderno</th>
                                                        <th width="13%" align="center" scope="col">Estado</th>
                                                        <th width="13%" align="center" scope="col">Monto Líquido</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane" [ngClass]="{'active show': selectedCobranzaIndex === 5 }"
                                            id="nav-deuda-actual" role="tabpanel"
                                            aria-labelledby="nav-deuda-actual-tab">

                                            <table class="table table-hover table-borderless">
                                                <tbody>
                                                    <tr>
                                                        <td colspan="2">
                                                            <h6><strong>DETALLE LIQUIDACIÓN LABORAL C-15-2021:</strong>
                                                            </h6>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td width="9%"><strong>Demandante:</strong> </td>
                                                        <td width="7%"><strong>Demandado:</strong> </td>

                                                    </tr>
                                                    <tr>
                                                        <td><strong>RUT Demandante:</strong></td>
                                                        <td><strong>RUT Demandado:</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td><strong>Representante DDO:</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td><strong>Rut Representante DDO:</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Monto Deuda Aproximado:</strong> $0.-</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <td colspan="7">
                                                            <h6><strong>TOTAL LIQUIDACIÓN:</strong></h6>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th width="13%" align="center" scope="col">Saldo Deuda</th>
                                                        <th width="13%" align="center" scope="col">Porcentaje Reajuste
                                                        </th>
                                                        <th width="13%" align="center" scope="col">Monto Reajuste</th>
                                                        <th width="13%" align="center" scope="col">Subtotal Reajustado
                                                        </th>
                                                        <th width="13%" align="center" scope="col">Porcentaje Interés
                                                        </th>
                                                        <th width="13%" align="center" scope="col">Monto Interés</th>
                                                        <th width="13%" align="center" scope="col">Total Actualizado
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                    </tr>
                                                </tbody>
                                            </table><br>
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <td colspan="9">
                                                            <h6><strong>PRESTACIONES:</strong></h6>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th width="13%" align="center" scope="col">Prestación</th>
                                                        <th width="13%" align="center" scope="col">Fecha Prestación</th>
                                                        <th width="13%" align="center" scope="col">Monto Nominal</th>
                                                        <th width="13%" align="center" scope="col">Fecha Actualización
                                                        </th>
                                                        <th width="13%" align="center" scope="col">Porcentaje Reajuste
                                                        </th>
                                                        <th width="13%" align="center" scope="col">Monto Reajuste</th>
                                                        <th width="13%" align="center" scope="col">Porcentaje Interés
                                                        </th>
                                                        <th width="13%" align="center" scope="col">Monto Interés</th>
                                                        <th width="13%" align="center" scope="col">Total Actualizado
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                    </tr>
                                                    <tr>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row"></td>
                                                        <td align="left" scope="row">Total Actualizado:</td>
                                                        <td align="left" scope="row">$0.-</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- fin cobranza laboral -->
            </div>

            <!-- inicio Tributario -->
            <div id="Tributario" class="tab-pane fade" [ngClass]="[contentShow === 'tributario' && totalTributario > 0? 'showContent in active': 'd-none']">
                <div class="card textos-campo">
                    <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-info-circle"
                            style="font-size:28px;">&nbsp;</i>TRIBUTARIO <p class="d-inline fecha_size" *ngIf="fromConsolidado">({{content?.poderJudicialReporte?.datosBasicosSolicitud?.FechaReporte | date: 'dd-MM-yyyy'}})</p>
                            <a type="button" class="btn btn-primary float-right no-print" href="https://oficinajudicialvirtual.pjud.cl/includes/sesion-consultaunificada.php" target="_blank">Revisar en Poder Judicial</a>
                        </h5>
                </div>
            
            <!-- fin tributario -->
            <div class="pageBreak"></div>
            <br>
            <br>
            <br>
            <br>
            <hr>
            <p>La información presentada en estos análisis proviene de fuentes consideradas altamente confiables. Sin
                embargo, dada la posibilidad de error humano o mecánico, no se garantiza la exactitud o integridad de la
                información y, pot tanto, no se hace responsable de errores u omisiones, como tampoco de las
                consecuencias
                asociadas con el empleo de esa información.</p>
            <hr>
            <table width="100%" border="0">
                <tbody>
                    <tr>
                        <td align="center">
                            <div class="container-gtp">
                                <img src="https://chart.googleapis.com/chart?chs=99x100&cht=qr&chl=https://www.gestionatupyme.cl"
                                    width="99" height="100" alt="" />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br>
        </div>
    </div>
</div>