<div class="container__form solicitud-credito-box">
    <div class="panel panel-default border-0">
        <div class="panel-body">
            <div class="btn-option">
                <div>
                    <button class="btn btn-primary" (click)="showLits()">Volver</button>
                </div>
            </div>
            <div class="container-gtp">
                <img class="banner-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt=""/>
                <h2 class="text-center mt-3">Solicitud de credito</h2>
                <h5 class="text-center"><span class="badge bg-secondary text-center">{{rut | rutFormat}}</span>
                    <h3 class="text-center mt-3" *ngIf="stepContent">
                        {{tipoSolicitud === 'lineaDeCredito'? 'Línea de crédito': tipoSolicitud === 'renegociacion'? 'Renegociación': 'Venta Nueva'}} 
                        <span *ngIf="showNumSol"> - N° Solicitud {{numeroSolicitud}}</span> 
                    </h3>
                </h5>
            </div>
            <div *ngIf="!tipoSolicitudContent">
                <p class="subTitle text-center">
                    Listado de Solicitudes
                </p>

                <div class="text-right" *ngIf="hasUserAccess">
                    <button type="button" class="btn btn-sm btn-success" (click)="tipoSolicitudContent = true;">
                        <i class="fa fa-plus"></i> Nueva
                    </button>
                </div>
                <ng-container *ngIf="dataTable.length > 0">
                    <app-dynamic-colunm-table [data]='dataTable' [header]='header' [divider]="'normal'"
                        [groupHeader]='"listadoSolicitud"' [paginationExcluir]="false"
                        (funcional)='procesarEvent($event);'
                        [contentEditable]="hasUserAccess" [clasess]="'general table-bordered'">
                    </app-dynamic-colunm-table>
                </ng-container>
            </div>

            <div class="row mt-5" *ngIf="tipoSolicitudContent && !stepContent">
                <div class="col-sm-6 mx-auto">
                    <div class="row">
                        <div class="col-sm-6 mx-auto">
                            <label for="staticEmail" class="pt-2">Tipo de solicitud</label>
                            <select class="form-control" [(ngModel)]="tipoSolicitud">
                                <ng-container *ngFor="let item of tipoSolicitudSel">
                                    <option [value]="item.modelo">{{item.model_view_name}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12 mx-auto text-center">
                            <button type="button" class="btn btn-sm btn-primary" (click)="setTipoSolicitud()">
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'d-none': !stepContent}">

                <div id="stepper1" class="bs-stepper mt-5">
                    <div class="row1">
                        <div class="bs-stepper-header pb-4">
                            <div class="step" data-target="#step1" disabled>
                                <button class="step-trigger">
                                    <span class="bs-stepper-circle">1</span>
                                    <span class="bs-stepper-label">Información General </span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#step2" disabled>
                                <button class="step-trigger">
                                    <span class="bs-stepper-circle">2</span>
                                    <span class="bs-stepper-label">Condición de Ventas </span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#step3" disabled>
                                <button class="step-trigger">
                                    <span class="bs-stepper-circle">3</span>
                                    <span class="bs-stepper-label">Datos Internos</span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#step4" disabled>
                                <button class="step-trigger">
                                    <span class="bs-stepper-circle">4</span>
                                    <span class="bs-stepper-label"> Carpeta Tributaria</span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#step5" disabled>
                                <button class="step-trigger">
                                    <span class="bs-stepper-circle">5</span>
                                    <span class="bs-stepper-label"> Informe Comercial Deudas</span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#step6" disabled>
                                <button class="step-trigger">
                                    <span class="bs-stepper-circle">6</span>
                                    <span class="bs-stepper-label">  Deuda SBIF y Leasing</span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#step7" disabled>
                                <button class="step-trigger">
                                    <span class="bs-stepper-circle">7</span>
                                    <span class="bs-stepper-label">  Riesgos </span>
                                </button>
                            </div>
                        </div>
                        <div class="bs-stepper-content col-12">
                            <div id="step1" class="content" role="tabpanel">
                                <div *ngIf="currentStep === 0">
                                    <ng-container *ngIf="stepContent">
                                        <app-informacion-cliente [stepper]="1" [idTransaccion]="idTransaccion" [rut]="rut" [proceso]='true'></app-informacion-cliente>
                                    </ng-container>
                                </div>
                            </div>
                            <div id="step2" class="content" role="tabpanel">
                                <div *ngIf="currentStep === 1">
                                    <ng-container *ngIf="stepContent">
                                        <app-resumen-condiciones-venta [stepper]="2" [rut]="rut" [idTransaccion]="idTransaccion" [tipoSolicitud]="tipoSolicitud"></app-resumen-condiciones-venta>
                                    </ng-container>
                                </div>
                            </div>
                            <div id="step3" class="content" role="tabpanel">
                                <div *ngIf="currentStep === 2">
                                    <ng-container *ngIf="stepContent">
                                        <app-upload-informe [stepper]="3" [step]="false" [idTransaccion]="idTransaccion" [rut]="rut"></app-upload-informe>
                                    </ng-container>
                                </div>
                            </div>
                            <div id="step4" class="content" role="tabpanel">
                                <div *ngIf="currentStep === 3">
                                    <ng-container *ngIf="stepContent">
                                        <app-upload-informe [stepper]="4" [step]="true" [idTransaccion]="idTransaccion" [rut]="rut"></app-upload-informe>
                                    </ng-container>
                                </div>
                            </div>
                            <div id="step5" class="content" role="tabpanel">
                                <div *ngIf="currentStep === 4">
                                    <ng-container *ngIf="stepContent">
                                        <app-informe-empresa [stepper]="5" [idTransaccion]="idTransaccion" [rut]="rut"></app-informe-empresa>
                                    </ng-container>
                                </div>
                            </div>
                            <div id="step6" class="content" role="tabpanel">
                                <div *ngIf="currentStep === 5">
                                    <ng-container *ngIf="stepContent">
                                        <app-informe-leasing-sbif [stepper]="6" [idTransaccion]="idTransaccion" [rut]="rut" [informeNuevo]="true"></app-informe-leasing-sbif>
                                    </ng-container>
                                </div>
                            </div>
                            <div id="step7" class="content" role="tabpanel">
                                <div *ngIf="currentStep === 6">
                                    <ng-container *ngIf="stepContent">
                                        <app-generar-reporte [stepper]="7" [idTransaccion]="idTransaccion" [rut]="rut" [tipoSolicitud]="tipoSolicitud"></app-generar-reporte>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <button class="btn btn-primary" (click)="stepper.previous()">
    Prev
</button>

<button class="btn btn-primary" (click)="stepper.next()">
    Next
</button> -->