import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CamposPersonalizadosService } from 'src/app/shared/services/campos-personalizados.service';
import { AlertService } from 'src/app/components/_alert';
import { environment as env } from 'src/environments/environment';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { catchError, map } from 'rxjs/operators';


@Component({
  selector: 'app-campos-personalizados',
  templateUrl: './campos-personalizados.component.html',
  styleUrls: ['./campos-personalizados.component.scss']
})
export class CamposPersonalizadosComponent implements OnInit {

  public listaCampos: any[] = [];
  public camposFiltrado: any[] = [];
  public currentItem = env.initItemPerPage;
  public currentPage = 0;
  public inputText: string = '';
  public seeTable: boolean = true;
  public seeForm: boolean = false;
  public seeFormCrear: boolean = false;
  public formularioForm!: UntypedFormGroup;
  private subscriptions: any[] = [];
  public campoSeleccionado: any = null;
  public objectKeys = Object.keys;
  public allowedValuesList: any[] = [];
  public allowedValuesOriginal: any = {};
  public modelos: any[] = [];
  private access: string[] = [ESystemAccess.CAMPOS_PERSONALIZADOS, ESystemProfileList.WEB, ESystemAccess.CAMPOS_PERSONALIZADOS_BUSCAR];
  public typeOriginal: string = '';
  public formatOriginal: string = '';
  public hasUserAccess = false;
  public tipoRemplace: any = {
    "String": "Texto",
    "Number": "Numero",
    "Date": "Fecha",
    "Boolean": "True/False"
  }
  public order = '';
  public campoOrder = '';

  public groupConsulting: string[] = ['listarModelos', 'listarSeccionWeb'];
  public seccionesWeb: any = {};
  public errores: any[] = [];
  
  @ViewChildren("modelosCampo") modelosCampo: QueryList<ElementRef> | undefined;
  @ViewChildren("seccionesCampo") seccionesCampo: QueryList<ElementRef> | undefined;

  constructor(
    private camposPersonalizadosService: CamposPersonalizadosService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private utilsService: UtilsService,
    private _sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    if (((this._sessionService.getUserAccess().includes(this.access[0]) || this._sessionService.getUserAccess().includes(this.access[2])) && this._sessionService.getUserProfileList().includes(this.access[1]))) {
      this.hasUserAccess = true;
      this.obtenerCamposPersonalizados();
    }

  }

  permisosCrear(): boolean {
    if (this._sessionService.getUserAccess().includes(this.access[0])){
      return true
    }
    return false
  }

  obtenerCamposPersonalizados(): void {
    this.spinner.show();
    this.camposPersonalizadosService.obtenerContrato().subscribe(resp => {
      this.listaCampos = resp?.CamposPersonalizadosReporte?.Reporte?.CamposPersonalizados?.Attributos || [];
      this.addTipo();
      this.listaCampos.sort((a,b) => (a.nameAttribute > b.nameAttribute) ? 1 : ((b.nameAttribute > a.nameAttribute) ? -1 : 0));
      this.filtrarObjeto();
      this.spinner.hide();
    }, (error) => {
      this.alertService.error(error?.error?.message || 'Ocurrio un error al obtener los campos personalizados.');
      this.spinner.hide();
    });
  }

  addTipo(): void {
    this.listaCampos?.forEach(element => {
      element.tipo = (this.tipoRemplace?.[element?.typeAttibute]) || element.typeAttibute;
    });
  }

  filtrarObjeto(): void {
    this.currentPage = 0;
    this.camposFiltrado = this.inputText != '' ? this.listaCampos.filter((item: any) => 
    item?.nameAttribute?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.tipo?.toUpperCase().includes(this.inputText.toUpperCase())) : this.listaCampos;
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      this.camposFiltrado.sort((a, b) => {
        const textA = a[campo].toString();
        const textB = b[campo].toString();
        return textA.localeCompare(textB);
      });
    } else {
      this.camposFiltrado.sort((a, b) => {
        const textA = a[campo].toString();
        const textB = b[campo].toString();
        return (-1 * textA.localeCompare(textB));
      });
    }
  }

  editarCampo(campo: any): void {
    this.campoSeleccionado = campo;

    for (const key in this.campoSeleccionado.allowedValues) {
      const value = this.campoSeleccionado.allowedValues[key];
      this.allowedValuesList.push({ value: value, index: parseInt(key) });
    }

    this.allowedValuesOriginal = JSON.parse(JSON.stringify(this.campoSeleccionado.allowedValues)) || {};
    this.typeOriginal = this.campoSeleccionado?.typeAttibute;
    this.formatOriginal = this.campoSeleccionado?.formatAttribute;
    this.seeForm = true;
    this.seeFormCrear = false;
    this.seeTable = false;
    this.obtenerDatosDisponibles();
    this.initFormEditar();
  }

  deshacer(): void {
    if((this.formularioForm.get('typeAttibute')?.value === this.typeOriginal || this.campoSeleccionado?.typeAttibute === this.typeOriginal) && this.formularioForm.get('formatAttribute')?.value === this.formatOriginal){
      this.allowedValuesList = [];
      for (const key in this.allowedValuesOriginal) {
        const value = this.allowedValuesOriginal[key];
        this.allowedValuesList.push({ value: value, index: parseInt(key) });
      }
    } else {
      this.allowedValuesList = [];
    }
  }

  volverTabla(): void {
    this.seeForm = false;
    this.seeFormCrear = false;
    this.seeTable = true;
    this.formularioForm.reset();
    this.campoSeleccionado = null;
    this.allowedValuesList = [];
    this.allowedValuesOriginal = {};
    this.typeOriginal = '';
    this.formatOriginal = '';
    this.modelos = [];
    this.seccionesWeb = {};
    this.errores = [];
    this.subscriptions = [];
    this.limpiarErrores();
    this.obtenerCamposPersonalizados();
  }

  initFormEditar(): void {
    this.formularioForm = this.formBuilder.group({});
    this.formularioForm.addControl('enabled', new UntypedFormControl(this.campoSeleccionado?.enabled || false, [Validators.required]));
    this.formularioForm.addControl('formatAttribute', new UntypedFormControl(this.campoSeleccionado?.formatAttribute || ''));

    if(this.campoSeleccionado?.typeAttibute === 'Date' && this.campoSeleccionado?.formatAttribute && this.campoSeleccionado?.defaultValue){
      if(this.campoSeleccionado?.formatAttribute === 'DD-MM-YYYY'){
        const [day, month, year] = this.campoSeleccionado?.defaultValue?.split("-");
        this.formularioForm.addControl('defaultValue', new UntypedFormControl(`${year}-${month}-${day}` || ''));
      } else if(this.campoSeleccionado?.formatAttribute === 'DD-MM-YY'){
        const [day, month, year] = this.campoSeleccionado?.defaultValue?.split("-");
        const fourDigitYear = "20" + year;
        this.formularioForm.addControl('defaultValue', new UntypedFormControl(`${fourDigitYear}-${month}-${day}` || ''));
      } else if(this.campoSeleccionado?.formatAttribute === 'MM-DD-YY'){
        const [month, day, year] = this.campoSeleccionado?.defaultValue?.split("-");
        const fourDigitYear = "20" + year;
        this.formularioForm.addControl('defaultValue', new UntypedFormControl(`${fourDigitYear}-${month}-${day}` || ''));
      }
    }
    else if(this.campoSeleccionado?.typeAttibute === 'Boolean'){
      this.formularioForm.addControl('defaultValue', new UntypedFormControl(this.campoSeleccionado?.defaultValue?.toString() || ''));
    } else {
      this.formularioForm.addControl('defaultValue', new UntypedFormControl((this.campoSeleccionado?.defaultValue || this.campoSeleccionado?.defaultValue == '0') ? this.campoSeleccionado?.defaultValue : ''));
    }

    this.formularioForm.addControl('section', new UntypedFormControl(this.campoSeleccionado?.section || ''));
    this.formularioForm.addControl('creditRequestActive', new UntypedFormControl(this.campoSeleccionado?.creditRequestActive || false, [Validators.required]));

    if(this.campoSeleccionado?.enabled === false) {
      this.formularioForm.get('creditRequestActive')?.disable();
    } else if(this.campoSeleccionado?.enabled === true) {
      this.formularioForm.get('creditRequestActive')?.enable();
    }

    this.eventForm();
  }

  eventForm() {
    this.subscriptions.push(this.formularioForm.get('typeAttibute')?.valueChanges.subscribe(value => {
      this.limpiarType();
    }));
    this.subscriptions.push(this.formularioForm.get('formatAttribute')?.valueChanges.subscribe(value => {
      this.limpiarFormat();
    }));
    this.subscriptions.push(this.formularioForm.get('enabled')?.valueChanges.subscribe(value => {
      if(value === true){
        this.formularioForm.get('creditRequestActive')?.enable();
      } else {
        this.formularioForm.get('creditRequestActive')?.setValue(false);
        this.formularioForm.get('creditRequestActive')?.disable();
      }
    }));
  }

  limpiarType(): void {
    this.formularioForm.get('formatAttribute')?.setValue('');
    this.formularioForm.get('defaultValue')?.setValue('');
    this.deshacer();
  }

  limpiarFormat(): void {
    this.formularioForm.get('defaultValue')?.setValue('');
    this.deshacer();
  }

  onSubmitEditar(): void {
    this.limpiarErrores();
    if (!this.formularioForm.valid) {
      this.utilsService.validateAllFormFields(this.formularioForm);
      this.alertService.error('Complete los campos para crear/actualizar el servicio.');
      return;
    }
    const formTemp = this.formularioForm.getRawValue();

    if((this.campoSeleccionado?.typeAttibute !== 'String' && this.campoSeleccionado?.typeAttibute !== 'Boolean') && formTemp?.formatAttribute === '') {
      this.marcarError("formatAttribute", true);
      this.alertService.error('Debe indicar el formato del atributo.');
      return;
    }

    if(!formTemp?.defaultValue && this.allowedValuesList.length > 0){
      this.marcarError("defaultValue", true);
      this.alertService.error('Si hay valores permitidos debe indicar la opción por defecto.');
      return;
    }

    // Validacion y transformacion tipo Date
    if(formTemp.defaultValue && this.campoSeleccionado?.typeAttibute === 'Date'){
      const defaultV: string = formTemp.defaultValue || '';
      const fecha = new Date(defaultV);
      if(formTemp?.formatAttribute === 'DD-MM-YYYY'){
        let month = '' + (fecha.getMonth() + 1);
        let day = '' + (fecha.getDate() + 1);
        const year = fecha.getFullYear();

        if (month.length < 2) 
          month = '0' + month;
        if (day.length < 2) 
          day = '0' + day;

        formTemp.defaultValue = [day, month, year].join('-');
      }
      else if(formTemp?.formatAttribute === 'DD-MM-YY'){
        let month = '' + (fecha.getMonth() + 1);
        let day = '' + (fecha.getDate() + 1);
        const year = fecha.getFullYear().toString().slice(-2);

        if (month.length < 2) 
          month = '0' + month;
        if (day.length < 2) 
          day = '0' + day;

        formTemp.defaultValue = [day , month, year].join('-');
      }
      else if(formTemp?.formatAttribute === 'MM-DD-YY'){
        let month = '' + (fecha.getMonth() + 1);
        let day = '' + (fecha.getDate() + 1);
        const year = fecha.getFullYear().toString().slice(-2);

        if (month.length < 2) 
          month = '0' + month;
        if (day.length < 2) 
          day = '0' + day;

        formTemp.defaultValue = [month, day, year].join('-');
      }
    }

    const allowedValuesFinal: any = {};
    const vacio = this.allowedValuesList.findIndex(e => e.value === '');
    if(vacio >= 0){
      this.marcarError("allowedValues" + vacio, true);
      this.alertService.error('No pueden existir valores vacios en valores permitidos.');
      return
    }

    const duplicates: any = this.hasDuplicates(this.allowedValuesList, 'value');
    if(duplicates?.hasDups === true){
      
      duplicates?.duplicates.forEach((element: any) => {
        this.marcarError("allowedValues" + element, true);
      });

      this.alertService.error('No pueden existir valores repetidos.');
      return;
    }

    this.allowedValuesList.forEach((element, index) => {
      allowedValuesFinal[index + 1] = element.value;
    });

    if(Object.keys(allowedValuesFinal).length > 0 && formTemp?.defaultValue !== '' && (!(allowedValuesFinal?.[formTemp?.defaultValue]) && allowedValuesFinal?.[formTemp?.defaultValue] !== 0)){
      this.alertService.error('La opción por defecto debe ser alguna de las opciones permitidos.');
      return
    }

    let models: any[] = [];
    if(formTemp?.creditRequestActive === true){
      if(this.modelosCampo){
        this.modelosCampo?.forEach(element => {
          if(element?.nativeElement?.checked && element?.nativeElement?.value){
            models.push(element?.nativeElement?.value);
          }
        })
      }
    } else {
      models = this.campoSeleccionado.models || [];
    }

    const webSections: any[] = [];
    if(this.seccionesCampo){
      this.seccionesCampo?.forEach(element => {
        if(element?.nativeElement?.checked && element?.nativeElement?.value){
          webSections.push(element?.nativeElement?.value);
        }
      })
    }

    // Transformacion de datos a Number
    if(this.campoSeleccionado?.typeAttibute === 'Number'){
      Object.keys(allowedValuesFinal).forEach(key => {
        allowedValuesFinal[key] = Number(allowedValuesFinal[key]);
      });
      if(Object.keys(allowedValuesFinal).length === 0){
        formTemp.defaultValue = Number(formTemp.defaultValue);
      }
    }

    // Transformacion de datos a Boolean
    if(this.campoSeleccionado?.typeAttibute === 'Boolean'){
      if(formTemp.defaultValue === 'true'){
        formTemp.defaultValue = true;
      } else if (formTemp.defaultValue === 'false'){
        formTemp.defaultValue = false;
      }
    }

    const request: any = this.campoSeleccionado;
    request.allowedValues = allowedValuesFinal;
    request.creditRequestActive = formTemp?.creditRequestActive;

    if(formTemp?.defaultValue || formTemp?.defaultValue == '0'){
      request.defaultValue = formTemp?.defaultValue;
    }
    else {
      if ('defaultValue' in request) {
        delete request.defaultValue;
      }
    }
    request.enabled = formTemp?.enabled;
    request.section = formTemp?.section;
    request.formatAttribute = formTemp?.formatAttribute;
    request.models = models;
    request.webSections = webSections;

    this.spinner.show();
    this.camposPersonalizadosService.crearContrato([request]).subscribe(resp => {
      this.alertService.success(`Se ha actualizado correctamente el campo "${this.campoSeleccionado?.nameAttribute}".`);
      this.spinner.hide();
      this.volverTabla();
    }, (error) => {
      this.alertService.error(error?.error?.message || 'Ocurrio un error al actualizar el campo.');
      this.spinner.hide();
    });


  }

  setError(reporte: string, error: string): void {
    this.errores.push({
      id: reporte,
      msg: error
    })
  }

  setResponse(service: string, response: any): void {
    if (service === 'listarModelos'){
      this.modelos = response || [];
    }
    else if (service === 'listarSeccionWeb'){
      if(response && Object.keys(response).length > 0) {
        for (const key in response) {
          if (response[key].userAccessID) {
            const userAccessIDs = response[key].userAccessID;
            const allAccessIDsValid = userAccessIDs.every((id: string) => this._sessionService.getUserAccess().includes(id));
            if (allAccessIDsValid) {
              this.seccionesWeb[key] = {
                ...response[key]
              };
            }
          }
        }
      } else {
        this.seccionesWeb = {};
      }
    }
  }

  getServices(service: string): any {
    const objeto: any = {
      'listarModelos': () => {
        return this.camposPersonalizadosService.listarModelos()
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Ocurrio un error al obtener los modelos disponibles.'), of(null))));
      },
      'listarSeccionWeb': () => {
        return this.camposPersonalizadosService.listarSeccionWeb()
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Ocurrio un error al obtener las secciones disponibles.'), of(null))));
      }
    };
    return objeto[service]();
  }

  async obtenerDatosDisponibles(): Promise<void> {
    const apiServices: any = [];

    if (this.groupConsulting.length > 0) {
      for await (const value of this.groupConsulting) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
      }
      this.spinner.hide();
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  validaSeccion(seccion: string): boolean {
    const valido = this.campoSeleccionado?.webSections?.some((e: string) => e === seccion)
    if(valido)
      return true

    return false
  }

  validaModelo(modelo: string): boolean {
    const valido = this.campoSeleccionado?.models?.some((e: string) => e === modelo)
    if(valido)
      return true

    return false
  }

  validaCreditRequest(): boolean {
    return this.formularioForm.get('creditRequestActive')?.value || false;
  }

  agregarValor(): void {
    this.allowedValuesList.push({value: ''})
  }

  agregarValorEditar(): void {
    let nextIndex = 1;
    for (let i = 0; i < this.allowedValuesList.length; i++) {
      const item = this.allowedValuesList[i];
      if (item.index === nextIndex) {
        nextIndex++;
      } else {
        break;
      }
    }
    this.allowedValuesList.push({ value: '', index: nextIndex });
    this.allowedValuesList.sort((a, b) => Number(a.index) - Number(b.index));
  }

  quitarValor(key: any, value: any): void {
    if(this.campoSeleccionado?.allowedValues?.[key]){
      delete this.campoSeleccionado?.allowedValues?.[key];
    }
  }

  quitarValorNuevo(index: any): void {
    this.allowedValuesList.splice(index, 1);

  }

  crearEntidad(): void {
    this.seeFormCrear = true;
    this.seeForm = false;
    this.seeTable = false;
    this.obtenerDatosDisponibles();
    this.initFormCrear();
  }

  initFormCrear(): void {
    this.formularioForm = this.formBuilder.group({});
    this.formularioForm.addControl('nameAttribute', new UntypedFormControl('', [Validators.required]));
    this.formularioForm.addControl('enabled', new UntypedFormControl(false, [Validators.required]));
    this.formularioForm.addControl('typeAttibute', new UntypedFormControl('', [Validators.required]));
    this.formularioForm.addControl('formatAttribute', new UntypedFormControl(''));
    this.formularioForm.addControl('defaultValue', new UntypedFormControl(''));
    this.formularioForm.addControl('section', new UntypedFormControl(''));
    this.formularioForm.addControl('creditRequestActive', new UntypedFormControl({value: false, disabled: true}, [Validators.required]));


    this.eventForm();
  }

  validaTypeDate(): boolean {
    return this.formularioForm.get('typeAttibute')?.value === 'Date' || false;
  }

  validaTypeNumber(): boolean {
    return this.formularioForm.get('typeAttibute')?.value === 'Number' || false;
  }

  validaTypeBoolean(): boolean {
    return this.formularioForm.get('typeAttibute')?.value === 'Boolean' || false;
  }

  validaTypeFormat(): boolean {
    return (
      (this.formularioForm.get('typeAttibute')?.value && this.formularioForm.get('formatAttribute')?.value) || 
      (this.formularioForm.get('typeAttibute')?.value === 'String' && this.formularioForm.get('formatAttribute')?.value === '') 
      ) || false;
  }

  validaTypeFormatEdit(): boolean {
    return (
      (this.campoSeleccionado?.typeAttibute && this.formularioForm.get('formatAttribute')?.value) || 
      (this.campoSeleccionado?.typeAttibute === 'String' && this.formularioForm.get('formatAttribute')?.value === '') 
      ) || false;
  }

  validaTypeFormatBooleanEdit(): boolean {
    return this.campoSeleccionado?.typeAttibute === 'Boolean' || false;
  }

  retornarType(): string {
    return this.formularioForm.get('typeAttibute')?.value || '';
  }

  retornarFormat(): string {
    return this.formularioForm.get('formatAttribute')?.value || '';
  }

  onSubmitCrear(): void {
    this.limpiarErrores();
    if (!this.formularioForm.valid) {
      this.utilsService.validateAllFormFields(this.formularioForm);
      this.alertService.error('Complete los campos para crear/actualizar el servicio.');
      return;
    }
    const formTemp = this.formularioForm.getRawValue();

    const existeName = this.listaCampos.some(e => e?.nameAttribute?.toUpperCase() === formTemp?.nameAttribute?.toUpperCase());
    if(existeName){
      this.marcarError("nameAttribute", true);
      this.alertService.error('El nombre del atributo ya existe en la lista de atributos.');
      return
    }

    if((formTemp?.typeAttibute !== 'String' && formTemp?.typeAttibute !== 'Boolean') && formTemp?.formatAttribute === '') {
      this.marcarError("formatAttribute", true);
      this.alertService.error('Debe indicar el formato del atributo.');
      return;
    }

    if(!formTemp?.defaultValue && this.allowedValuesList.length > 0){
      this.marcarError("defaultValue", true);
      this.alertService.error('Si hay valores permitidos debe indicar la opción por defecto.');
      return;
    }

    // Validacion y transformacion tipo Date
    if(formTemp.defaultValue && formTemp?.typeAttibute === 'Date'){
      const defaultV: string = formTemp?.defaultValue || '';
      const fecha = new Date(defaultV);
      if(formTemp?.formatAttribute === 'DD-MM-YYYY'){
        let month = '' + (fecha.getMonth() + 1);
        let day = '' + (fecha.getDate() + 1);
        const year = fecha.getFullYear();

        if (month.length < 2) 
          month = '0' + month;
        if (day.length < 2) 
          day = '0' + day;

        formTemp.defaultValue = [day, month, year].join('-');
      }
      else if(formTemp?.formatAttribute === 'DD-MM-YY'){
        let month = '' + (fecha.getMonth() + 1);
        let day = '' + (fecha.getDate() + 1);
        const year = fecha.getFullYear().toString().slice(-2);

        if (month.length < 2) 
          month = '0' + month;
        if (day.length < 2) 
          day = '0' + day;

        formTemp.defaultValue = [day , month, year].join('-');
      }
      else if(formTemp?.formatAttribute === 'MM-DD-YY'){
        let month = '' + (fecha.getMonth() + 1);
        let day = '' + (fecha.getDate() + 1);
        const year = fecha.getFullYear().toString().slice(-2);

        if (month.length < 2) 
          month = '0' + month;
        if (day.length < 2) 
          day = '0' + day;

        formTemp.defaultValue = [month, day, year].join('-');
      }
    }

    const allowedValuesFinal: any = {};
    const vacio = this.allowedValuesList.findIndex(e => e.value === '');
    if(vacio >= 0){
      this.marcarError("allowedValues" + vacio, true);
      this.alertService.error('No pueden existir valores vacios en valores permitidos.');
      return
    }

    const duplicates: any = this.hasDuplicates(this.allowedValuesList, 'value');
    if(duplicates?.hasDups === true){

      duplicates?.duplicates.forEach((element: any) => {
        this.marcarError("allowedValues" + element, true);
      });

      this.alertService.error('No pueden existir valores repetidos.');
      return;
    }

    this.allowedValuesList.forEach((element, index) => {
      allowedValuesFinal[index + 1] = element.value;
    });
    if(Object.keys(allowedValuesFinal).length > 0 && formTemp?.defaultValue !== '' && (!(allowedValuesFinal?.[formTemp?.defaultValue]) && allowedValuesFinal?.[formTemp?.defaultValue] !== 0)){
      this.marcarError("defaultValue", true);
      this.alertService.error('La opción por defecto debe ser alguna de las opciones permitidos.'); 
      return
    }

    const models: any[] = [];
    if(this.modelosCampo){
      this.modelosCampo?.forEach(element => {
        if(element?.nativeElement?.checked && element?.nativeElement?.value){
          models.push(element?.nativeElement?.value);
        }
      })
    }

    const webSections: any[] = [];
    if(this.seccionesCampo){
      this.seccionesCampo?.forEach(element => {
        if(element?.nativeElement?.checked && element?.nativeElement?.value){
          webSections.push(element?.nativeElement?.value);
        }
      })
    }

    // Transformacion de datos a Number
    if(formTemp?.typeAttibute === 'Number'){
      Object.keys(allowedValuesFinal).forEach(key => {
        allowedValuesFinal[key] = Number(allowedValuesFinal[key]);
      });
      if(Object.keys(allowedValuesFinal).length === 0){
        formTemp.defaultValue = Number(formTemp.defaultValue);
      }
    }

    // Transformacion de datos a Boolean
    if(formTemp?.typeAttibute === 'Boolean'){
      if(formTemp.defaultValue === 'true'){
        formTemp.defaultValue = true;
      } else if (formTemp.defaultValue === 'false'){
        formTemp.defaultValue = false;
      }
    }

    const request: any = {
      allowedValues: {},
      models: [],
      typeAttibute: "",
      defaultValue: "",
      section: "",
      creditRequestActive: false,
      nameAttribute: "",
      formatAttribute: "",
      enabled: false
    };
    request.allowedValues = allowedValuesFinal;
    request.models = models;
    request.webSections = webSections;
    request.typeAttibute = formTemp?.typeAttibute;

    if(formTemp?.defaultValue || formTemp?.defaultValue == '0'){
      request.defaultValue = formTemp?.defaultValue;
    }
    else {
      if ('defaultValue' in request) {
        delete request.defaultValue;
      }
    }

    //request.defaultValue = formTemp?.defaultValue;
    request.section = formTemp?.section;
    request.creditRequestActive = formTemp?.creditRequestActive;
    request.nameAttribute = formTemp?.nameAttribute;
    request.formatAttribute = formTemp?.formatAttribute;
    request.enabled = formTemp?.enabled;

    this.spinner.show();
    this.camposPersonalizadosService.crearContrato([request]).subscribe(resp => {
      this.alertService.success(`Se ha actualizado correctamente el campo "${request.nameAttribute}".`);
      this.spinner.hide();
      this.volverTabla();
    }, (error) => {
      this.alertService.error(error?.error?.message || 'Ocurrio un error al actualizar el campo.');
      this.spinner.hide();
    });


  }

  marcarError(id: string, isError: boolean): void {
    const input = document.getElementById(id) as HTMLElement;
    if(input){
      if(isError === true){
        if ( !input.classList.contains('input-error') ){
          input.classList.add('input-error');
        }
      } else {
        if ( input.classList.contains('input-error') ){
          input.classList.remove('input-error');
        }
      }
    }
  }

  limpiarErrores(): void {
    this.marcarError('formatAttribute', false);
    this.marcarError('defaultValue', false);
    this.marcarError('nameAttribute', false);
    this.allowedValuesList.forEach((element, index) => {
      this.marcarError('allowedValues'+index, false)
    });
  }

  hasDuplicates(array: any[], key: string) {
    const duplicates: any[] = [];
    array.forEach((item, index) => {
        array.forEach((_item, _index) => {
            if (_index !== index && _item[key] === item[key]) {
                if (!duplicates.includes(index) && !duplicates.includes(_index)) {
                    duplicates.push(index, _index);
                }
            }
        });
    });
    const hasDups: boolean = duplicates.length > 0;
    return { hasDups, duplicates };
  }

  removeInvalidChars(event: any, isCrear: boolean, index: any = null): void {
    let type: string = '';
    if(isCrear){
      type = this.formularioForm.get('typeAttibute')?.value;
    } else {
      type = this.campoSeleccionado?.typeAttibute;
    }
    const inputChar: string = event.data || '';
    const valorActual = event?.target?.value || '';

    if(type === 'Number' && this.formularioForm.get('formatAttribute')?.value === '0'){
      const pattern = "^[0-9]";
      const validChars = new RegExp(pattern);
      if (!validChars.test(inputChar)) {
        if(index === null){
          this.formularioForm.get('defaultValue')?.setValue(valorActual.replace(/[^0-9]/g, ""))
        } else {
          const input: any = document.getElementById('allowedValues' + index)
          if(input){
            const valor = valorActual.replace(/[^0-9]/g, "")
            input.value = valor;
            this.allowedValuesList[index].value = valor;
          }
        }
      }
    } else if (type === 'Number' && this.formularioForm.get('formatAttribute')?.value === '0,0') {
      const patternNumber = "^[0-9]";
      const validCharsNumber = new RegExp(patternNumber);
      if ((!validCharsNumber.test(inputChar)) && inputChar !== ',') {
        if(index === null){
          this.formularioForm.get('defaultValue')?.setValue(valorActual.replace(inputChar, ""))
        } else {
          const input: any = document.getElementById('allowedValues' + index)
          if(input){
            const valor = valorActual.replace(inputChar, "");
            input.value = valor;
            this.allowedValuesList[index].value = valor;
          }
        }
      } else {
        if (!/^\d+(,\d*)?$/.test(valorActual)) {
          if(index === null){
            this.formularioForm.get('defaultValue')?.setValue(valorActual.slice(0, -1))
          } else {
            const input: any = document.getElementById('allowedValues' + index)
            if(input){
              const valor = this.allowedValuesList[index].value = valorActual.slice(0, -1);
              input.value = valor;
              this.allowedValuesList[index].value = valor;
            }
          }
        }
      }
    } else if (type === 'Number' && this.formularioForm.get('formatAttribute')?.value === '0.0') {
      const patternNumber = "^[0-9]";
      const validCharsNumber = new RegExp(patternNumber);
      if ((!validCharsNumber.test(inputChar)) && inputChar !== '.') {
        if(index === null){
          this.formularioForm.get('defaultValue')?.setValue(valorActual.replace(inputChar, ""))
        } else {
          const input: any = document.getElementById('allowedValues' + index)
          if(input){
            const valor = valorActual.replace(inputChar, "");
            input.value = valor;
            this.allowedValuesList[index].value = valor;
          }
        }
      } else {
        if (!/^\d+(.\d*)?$/.test(valorActual)) {
          if(index === null){
            this.formularioForm.get('defaultValue')?.setValue(valorActual.slice(0, -1))
          } else {
            const input: any = document.getElementById('allowedValues' + index)
            if(input){
              const valor = this.allowedValuesList[index].value = valorActual.slice(0, -1);
              input.value = valor;
              this.allowedValuesList[index].value = valor;
            }
          }
        }
      }
    } else if (type === 'String' && valorActual.length > 200) {
      if(index === null){
        this.formularioForm.get('defaultValue')?.setValue(valorActual.slice(0, 200))
      } else {
        const input: any = document.getElementById('allowedValues' + index)
        if(input){
          const valor = this.allowedValuesList[index].value = valorActual.slice(0, 200);
          input.value = valor;
          this.allowedValuesList[index].value = valor;
        }
      }

    }

  }

  validaName(event: any): void {
    const inputChar: string = event.data || '';
    const valorActual = event?.target?.value || '';
    const pattern = /^[\w\d-_ ]+$/;
    const validChars = new RegExp(pattern);
    if (!validChars.test(inputChar) && valorActual.length <= 200) {
      this.formularioForm.get('nameAttribute')?.setValue(valorActual.slice(0, -1));
    } else if (valorActual.length > 200) {
      this.formularioForm.get('nameAttribute')?.setValue(valorActual.slice(0, 100));
    }
  }

  validaSection(event: any): void {
    const inputChar: string = event.data || '';
    const valorActual = event?.target?.value || '';
    const pattern = /^[\w\d-_ ]+$/;
    const validChars = new RegExp(pattern);
    if (!validChars.test(inputChar) && valorActual.length <= 200) {
      this.formularioForm.get('section')?.setValue(valorActual.slice(0, -1));
    } else if (valorActual.length > 200) {
      this.formularioForm.get('section')?.setValue(valorActual.slice(0, 100));
    }
  }

}
