import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SessionService } from 'src/app/shared/services/session.service';
import { SolicitudCreditoServiceWorkflow } from 'src/app/shared/services/solicitud-credito-workflow.service';
import { AlertService } from '../../_alert';
import { RUT } from 'src/app/shared/utils/rut';
import { parse, eval as expresEval } from 'expression-eval';
import { ModalCredencialesService } from 'src/app/shared/services/modal-credenciales.service';
import { ESystemAccess } from 'src/app/enum/EAccess';

@Component({
  selector: 'app-consumo-servicio-workflow',
  templateUrl: './consumo-servicio-workflow.component.html',
  styleUrls: ['./consumo-servicio-workflow.component.scss']
})
export class ConsumoServicioWorkflowComponent implements OnInit, OnDestroy {
  public now = Date.now();
  @ViewChildren("checkboxesbuscar") checkboxesBuscar: QueryList<ElementRef> | undefined;
  @ViewChildren("checkboxesactualizar") checkboxesActualizar: QueryList<ElementRef> | undefined;
  @ViewChildren("checkboxesactualizartodo") checkboxesActualizarTodo: QueryList<ElementRef> | undefined;
  @ViewChildren("checkboxesbuscartodo") checkboxesBuscarTodo: QueryList<ElementRef> | undefined;

  @Input() step: boolean = false;
  @Input() idTransaccion: string = '';
  @Input() rut: string = '';
  @Input() stepper!: number;
  @Output() operacionNuevo = new EventEmitter<boolean>();
  public formulario!: UntypedFormGroup;
  private subscriptions: Subscription[] = [];

  private access: string[] = [ESystemAccess.CLIENTES_USER_ADMIN, ESystemAccess.CLIENTES_CREDS_ADMIN];

  public objectKeys = Object.keys;
  public servicioForm!: UntypedFormGroup;

  // indice opcion crear o buscar historico
  public indexServicioSelected: number | undefined = undefined;

  // key del elemento seleccionado
  public selectedItemKey: string = '';

  //lista de servicios disponibles
  public serviciosDisponibles: any[] = [];
  public serviciosDisponiblesOriginal: any[] = [];

  //elemento entre los servicios seleccionado
  public selectedItem: any = {};

  //datos ingresados guardados
  public serviciosBodySave: any = {};

  //servicios seleccionados
  public serviciosAEnviar: any[] = [];

  //lista de archivos
  public listaTempFile: any = {};

  //campos que la app tiene los datos -> se puede cambiar a utils
  public camposInternos: any = {
    'rut': this.rut,
    'idOrganizacion': this._sessionService.getOrganization(),
    'idUsuario': this._sessionService.getUserInfo().username,
    'requestOrigin': "WEB"
  };

  //boolean para control de seccion de resultados
  public seeResults: boolean = false;

  // resultados de ejecucion
  public bodyResults: any = {};

  // Resultado de obtener flujo (Modelos) 
  public bodyModelos: any = {};

  public secciones: any[] = [];

  public itemCredencialSelected: any = null;
  public itemCredencialSelectedType: string = '';


  constructor(
    private _sessionService: SessionService,
    public alertService: AlertService,
    private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalCredencialesService,
    ) { }

  ngOnInit(): void {
    this.operacionNuevo.emit(false);
    this.obtenerFlujo();
    this.initForm()
    this.camposInternos.rut = RUT.applyBackendFormat(this.rut);
  }

  modalCredenciales(itemCredencial: any): void {
    this.itemCredencialSelected = [itemCredencial?.credsName];
    this.itemCredencialSelectedType = itemCredencial?.type || '';
    this.modalService.open();
  }

  modalCredencialesLista(servicioKey: string): void {
    const servicio = this.serviciosAEnviar.find((e: any) => e.servicio === servicioKey);

    if(!servicio) {
      return
    }

    let credencialesFiltradas: any[] = [];
    const servicioIndex = this.serviciosDisponibles.findIndex((e: any) => e.key === servicioKey);
    if(servicioIndex >= 0){
      if(servicio.index >= 0){
        if (this.serviciosDisponibles[servicioIndex]?.values?.[servicio.index]?.infoVariables?.passSyncStatus?.length > 0){
          credencialesFiltradas = this.serviciosDisponibles[servicioIndex]?.values?.[servicio.index]?.infoVariables?.passSyncStatus.filter((e: any) => 
            e.type !== 'CRED.ORG')
        }
      }
    }
    

    if(credencialesFiltradas.length === 0){
      this.alertService.error(`No se lograron recuperar las credenciales para configurar.`);
      return
    }

    const valorInicial = credencialesFiltradas[0].type;
    const hayValorDiferente = credencialesFiltradas.some(e => e.type !== valorInicial);

    if(hayValorDiferente){
      this.alertService.error(`Las credenciales obtenidas son de diferente tipo.`);
      return
    }

    const credenciales = credencialesFiltradas.map(e => e.credsName);
    
    this.itemCredencialSelected = credenciales;
    this.itemCredencialSelectedType = valorInicial || '';
    this.modalService.open();

  }

  validaPermisosAdmin(): boolean {
    if (this._sessionService.getUserAccess().includes(this.access[0]))
      return true;
      
    return false;
  }

  validaPermisosUser(): boolean {
    if (this._sessionService.getUserAccess().includes(this.access[1]) || this._sessionService.getUserAccess().includes(this.access[0]))
      return true;
      
    return false;
  }

  volverModalCredenciales(): void {
    this.indexServicioSelected = undefined;
    this.selectedItemKey = '';
    this.serviciosDisponibles = [];
    this.serviciosDisponiblesOriginal = [];
    this.selectedItem = {};
    this.serviciosBodySave = {};
    this.serviciosAEnviar = [];
    this.listaTempFile = {};
    this.seeResults = false;
    this.bodyResults = {};
    this.bodyModelos = {};
    this.secciones = [];
    this.itemCredencialSelected = null;
    this.itemCredencialSelectedType = '';
    this.limpiarCheckedTodos();
    this.obtenerFlujo();
    this.initForm()
    this.camposInternos.rut = RUT.applyBackendFormat(this.rut);
  }

  // Actualiza datos guardados a partir del resultado del servicio
  actualizaBodySave(){
    this.serviciosBodySave = [];
    if(this.bodyResults.request){
      this.bodyResults.request.forEach((element: any) => {
        if(element.values?.length === 1){
          const objeto: any = {}
          element.values[0]?.inputVars?.forEach((elementInput: any) => {
            if(elementInput.display){
              if(elementInput.typeAttribute === 'base64'){
                const objetoFile: any = {}
                objetoFile[elementInput.key] = {file: elementInput.value, name: elementInput?.file?.inputName};
                this.listaTempFile[element.key] = objetoFile;
                objeto['name'] = elementInput?.file?.inputName;
                objeto[elementInput.key] = elementInput?.file?.inputName;
              } else {
                objeto[elementInput.key] = elementInput.value;
              }
            }
          });
          const indexArray = this.serviciosDisponibles?.findIndex(elemento => elemento.key === element.key);
          if(indexArray >= 0){
            const index = this.serviciosDisponibles[indexArray]?.values?.findIndex((elementoValues: any) => 
              elementoValues.type === element.values[0].type && elementoValues.uri === element.values[0].uri);
            this.serviciosDisponibles[indexArray];
            objeto['index'] = index;
          }
          this.serviciosBodySave[element.key] = objeto;
        }
      });
    }
  }

  obtenerFlujo() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      let paso: any;
      let pasoAnterior: any;
      resp.stages.forEach((e: any) => {
        if(Number(e.stageID) === Number(this.stepper + 2)){
          paso = e;
        } else if (e.stageID === '2'){
          pasoAnterior = e;
        }
      });
      if(pasoAnterior && (!pasoAnterior?.completed && pasoAnterior?.optional === false)){ // Necesita completar un paso previo
        const pasosCompleted = resp.stages.filter((e: any) => e.completed);
        const cantidad = pasosCompleted.length;
        const ultimoPasoCompletado = Number(pasosCompleted[cantidad - 1].stageID);
        let step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado + 1));
        if (!step[0]?.step){
          step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado));
        }
        this.alertService.error(`Solicitud de Crédito - Debe completar el paso N° ${Number(step[0]?.step)} para poder continuar`);
        this.solicitudCreditoService.setStep(Number(step[0]?.step));
      } else {
        // Se valida si esta completado o en progreso para obtener los datos de la transaccion
        if(paso && (paso?.completed || paso?.inProgress)){
          this.subscriptions.push(
            this.solicitudCreditoService.syncUserCredentials(this.rut, this.idTransaccion)
            .subscribe((res) => {
              this.subscriptions.push(this.solicitudCreditoService.obtenerTransaccion(this.rut, this.idTransaccion).subscribe(resp => {
              if(resp.SolicitudDeCredito?.Stage3Response?.ContratoServicios && resp.SolicitudDeCredito?.Stage4Response){
                this.serviciosDisponibles = resp.SolicitudDeCredito?.Stage3Response?.ContratoServicios;
                this.serviciosDisponiblesOriginal = JSON.parse(JSON.stringify(this.serviciosDisponibles));
                this.bodyResults = resp.SolicitudDeCredito?.Stage4Response;
                this.secciones = this.obtenerSecciones('balanceManual', 'crear');
                this.actualizaBodySave();
                this.seeResults = true;
                this.escondeMuestraHeader(true);
              } else {
                this.alertService.error('Solicitud de Crédito - No se encontraron servicios disponibles');
              }
              this.spinner.hide();
              const asincronosKeys = this.obtenerRequestAsincrono();
              this.validaAsincronos(asincronosKeys);
              }, (error: any) => {
                this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar datos del flujo de credito');
                this.spinner.hide();
              }));
            }, (error: any) => {
              this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error en sync credentials del flujo de credito');
              this.spinner.hide();    
            }));
        } else { // Primera vez en la vista
          this.solicitudCreditoService.serviciosDisponibles(this.rut, this.idTransaccion, true).subscribe(resp => {
            if(resp.SolicitudDeCredito?.Stage3Response?.ContratoServicios){
              this.serviciosDisponibles = resp.SolicitudDeCredito?.Stage3Response?.ContratoServicios;
              this.serviciosDisponiblesOriginal = JSON.parse(JSON.stringify(this.serviciosDisponibles));
              this.secciones = this.obtenerSecciones('balanceManual', 'crear');
            } else {
              this.alertService.error('Solicitud de Crédito - No se encontraron servicios disponibles');
            }
            this.spinner.hide();
          }, (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los servicios disponibles');
            this.spinner.hide();
          });
        }
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar datos del flujo de credito');
      this.spinner.hide();
    }));
  }

  // Obtiene las secciones para el reporte y tipo de reporte indicado (balances manuales)
  
  obtenerSecciones(reporteIndex: string, tipoReporte: string): any[] { // Validar
    const seccionesFiltradas: any[] = [];

    const contratoReporte = this.serviciosDisponibles.find(e => e?.key === reporteIndex);

    if(contratoReporte){

      const indexTipoReporte = contratoReporte?.values?.findIndex((e: any) => e?.type === tipoReporte);

      if(indexTipoReporte >= 0){
        
        contratoReporte?.values[indexTipoReporte]?.inputVars?.forEach((element: any) => {
          if(element?.display == true && element.section && !seccionesFiltradas.some(e => e.key === element.section)){
            seccionesFiltradas.push({key: element.section, displayName: element.displaySection})
          }
        });
      }
    }
    
   return seccionesFiltradas;
  }

  // Calculos para balance manual
  obtenerTotalSeccion(seccion: string): number{
    let suma = 0;
    this.selectedItem.inputVars.forEach((e: any) => {
      if(e.section === seccion){
        suma += Number(this.servicioForm.get([e.key])?.value)
      }
    })
    return suma;
  }

  initForm(): void {
    this.formulario = this.formBuilder.group({
      file: [null]
    })
  }

  changeStep() {
    this.solicitudCreditoService.setStep('next');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  // Guarda los datos del archivo subido
  handleUpload(event: any, servicioKey: string, itemKey: any): void{
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if(this.listaTempFile[servicioKey]){
        this.listaTempFile[servicioKey][itemKey] = {file: reader?.result?.toString().split(',')[1], name: event.target.value};
      } else {
        this.listaTempFile[servicioKey] = {};
        this.listaTempFile[servicioKey] = {[itemKey]: {file: reader?.result?.toString().split(',')[1], name: event.target.value}};
      }
    };
    reader.readAsDataURL(file);

  }

  // Valida si se ingresan los datos al formulario y los guarda en un objeto temporal
  onSubmit(){
    if (!this.servicioForm.valid) {
      this.solicitudCreditoService.validateAllFormFields(this.servicioForm);
      this.alertService.error('Solicitud de Crédito - Debe completar los datos necesarios para guardar correctamente');
      return;
    }

    const formTemp = this.servicioForm.getRawValue();
    const form: any = {};
    const servicio: any = this.selectedItemKey;

    Object.keys(formTemp).forEach((control: any) => {
      const item = this.selectedItem.inputVars
        .filter((e: any) => e.key === control)[0];

      if (item) {
        if(item.typeAttribute === "[]" && (typeof formTemp[control] == "string")){
          form[item.key] = formTemp[control].split(',');
        } else if(item.typeAttribute === "Number") {
          form[item.key] = Number(formTemp[control]);
        } else {
          form[item.key] = formTemp[control]
        }
      }
    });
    this.serviciosBodySave[servicio] = form;
    this.serviciosBodySave[servicio]['index'] = this.indexServicioSelected;
    
    // Se valida si el checkbox esta marcado y se valida si esta guardado la key en servicios a enviar para agregarla
    const checkbox = document.getElementById('checkbox-' + this.selectedItemKey) as HTMLInputElement;
    const indexAEnviar = this.serviciosAEnviar.findIndex(elemento => elemento.servicio === servicio);
    if(checkbox && checkbox?.checked && indexAEnviar < 0){
      this.serviciosAEnviar.push({servicio: servicio, index: this.indexServicioSelected});
    }
  }

  // Crea el formulario
  crearForm(){
    this.servicioForm = this.formBuilder.group({});
    if(this.selectedItem.inputVars){
      this.selectedItem.inputVars.forEach((element: any) => {
        if(element.display){
          if (this.serviciosBodySave[this.selectedItemKey]) {
            this.servicioForm.addControl(element.key, new UntypedFormControl(this.serviciosBodySave[this.selectedItemKey][element.key], Validators.required));
          } else {
            this.servicioForm.addControl(element.key, new UntypedFormControl(element.value, Validators.required));
          }
        }
      });
    }

  }

  //Validacion de asincronos y consulta
  consultaAsincronos(request: any){
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.seeResults = false;
      this.spinner.show();
      this.bodyModelos = resp;
      const asincronos = JSON.parse(JSON.stringify(resp.stages[3].asincronos));
      const requestAsincrono: any[] = [];

      request.forEach((element: any) => {
        let jsonAsincrono: any = {};
        const indice = asincronos.findIndex((elemento: any) => elemento.name == element.key && elemento?.type?.indexOf(element?.values[0]?.type) > -1); // agregar validacion por type con element?.values[0]?.type
        if(indice >= 0 && Object.keys(this.bodyResults[element.key]?.reporte).length > 0 && this.bodyResults[element.key]?.errors?.hassError === false && this.bodyResults[element.key]?.reporte !== 'Reporte con Errores'){
          if (asincronos[indice]?.services[0]?.completed && asincronos[indice]?.services[0]?.name == element.key){ // validar con completed false para probar
            const indiceServicio2 = this.serviciosDisponibles?.findIndex((elemento: any) => 
              elemento.key == asincronos[indice].services[1].name);      
            if(indiceServicio2 >= 0){
              jsonAsincrono = JSON.parse(JSON.stringify(this.serviciosDisponibles[indiceServicio2]));
              jsonAsincrono.values[0].inputVars = [];
              this.serviciosDisponibles[indiceServicio2]?.values[0]?.inputVars?.forEach((elementValue: any) => {

                if(this.camposInternos[elementValue.key]){
                  const valor = JSON.parse(JSON.stringify(elementValue));
                  valor.value = this.camposInternos[elementValue.key];
                  jsonAsincrono.values[0].inputVars.push(valor);
                } else {
                  if(elementValue.xtract !== ''){
                    const valor = JSON.parse(JSON.stringify(elementValue));
                    if(this.bodyResults?.xtractInfo?.[elementValue.xtract]){
                      valor.value = this.bodyResults?.xtractInfo?.[elementValue.xtract];//this.getProp(this.bodyResults, elementValue.xtract);
                    }
                    jsonAsincrono.values[0].inputVars.push(valor);
                  } else {
                    const valor = JSON.parse(JSON.stringify(elementValue));
                    valor.value = elementValue.value || '';
                    jsonAsincrono.values[0].inputVars.push(valor);
                  }
                }
              });
              requestAsincrono.push(jsonAsincrono);
            }
          } 

        }
      });

      if(requestAsincrono.length > 0){
        this.seeResults = false;
        this.spinner.show();
        this.solicitudCreditoService.consultarServicios(requestAsincrono, this.idTransaccion, this.rut)
        .subscribe(resp => {

          if(resp.SolicitudDeCredito?.Stage4Response){
            this.bodyResults = resp.SolicitudDeCredito?.Stage4Response;
            this.actualizaBodySave();
          }
          this.seeResults = true;
          this.spinner.hide();
          const asincronosKeys = this.obtenerRequestAsincrono();
          this.validaAsincronos(asincronosKeys);
        }, (error: any) => {
          this.seeResults = true;
          this.spinner.hide();
          const msg = error.error.message ? error.error.message : 'Solicitud de Crédito - Ocurrio un error al consultar servicios';
          this.alertService.error(msg);
          this.seeResults = true;
          this.spinner.hide();
        })
      } else {
        this.seeResults = true;
        this.spinner.hide();
      }

    }, (error: any) => {
      this.seeResults = true;
      this.spinner.hide();
      const msg = error.error.message ? error.error.message : 'Solicitud de Crédito - Ocurrio un error al recuperar datos del flujo de credito';
      this.alertService.error(msg);
    }));
  }

  validarSincCredOrg(servicioKey: string, index: number): boolean { // debe indicar en el caso de que existan cred.org que esten sincronizados
    const servicioIndex = this.serviciosDisponibles.findIndex((e: any) => e.key === servicioKey);
    if(servicioIndex >= 0 && index >= 0){
      if(this.serviciosDisponibles?.[servicioIndex]?.values?.[index]?.infoVariables?.passSyncStatus 
        && this.serviciosDisponibles?.[servicioIndex]?.values?.[index]?.infoVariables?.passSyncStatus.length > 0){
          const credencialesFiltradasCredOrg = this.serviciosDisponibles?.[servicioIndex]?.values?.[index]?.infoVariables?.passSyncStatus.filter((e: any) => 
            e.type === 'CRED.ORG')
          if(credencialesFiltradasCredOrg.length > 0){
            const anyFalse = credencialesFiltradasCredOrg?.some((e: any) => e.status === false );
            if(anyFalse === true)
              return false;
            else 
              return true;
          }
      }
    }
  
    return true;
  }

  validarSincCredNoOrg(servicioKey: string, index: number): boolean {
    const servicioIndex = this.serviciosDisponibles.findIndex((e: any) => e.key === servicioKey);
    if(servicioIndex >= 0 && index >= 0){
      if(this.serviciosDisponibles?.[servicioIndex]?.values?.[index]?.infoVariables?.passSyncStatus 
        && this.serviciosDisponibles?.[servicioIndex]?.values?.[index]?.infoVariables?.passSyncStatus.length > 0){
          const credencialesFiltradasNoCredOrg = this.serviciosDisponibles[servicioIndex]?.values?.[index]?.infoVariables?.passSyncStatus.filter((e: any) => 
            e.type !== 'CRED.ORG')
          
          if(credencialesFiltradasNoCredOrg.length > 0){
            const anyTrue = credencialesFiltradasNoCredOrg?.some((e: any) => e.status === true );
            if(anyTrue === true)
              return true;
            else 
              return false;
          }
      }
    }
  
    return true;
  }

  // Valida si se llenaron los datos para los servicios a consultar
  async consultarServicios(){
    let request: any = {};
    for await (const elemento of this.serviciosAEnviar) {
      if (!this.serviciosBodySave[elemento.servicio] && this.existenVariablesEditables(elemento.servicio, elemento.index)) {
        this.alertService.error('Solicitud de Crédito - Debe completar los datos necesarios para realizar la consulta.');
        return
      } else if(!this.serviciosBodySave[elemento.servicio] && !this.existenVariablesEditables(elemento.servicio, elemento.index)){
        // si no hay variables editables se crea objeto vacio
        request[elemento.servicio] = {}
      }
    }

    for await (const servicio of this.serviciosAEnviar) {
      if(!this.validarEstadoCampo(servicio)){
        this.alertService.error('Solicitud de Crédito - Debe completar los datos necesarios para realizar la consulta.');
        return
      }
    }

    for await (const servicio of this.serviciosAEnviar) {
      if(!this.validarSincCredNoOrg(servicio?.servicio, servicio?.index)){
        this.alertService.error('Solicitud de Crédito - Se han detectado servicios seleccionados no enrolados, debe configurar las credenciales para consultar.');
        return
      }
    }

    for await (const servicio of this.serviciosAEnviar) {
      if(!this.validarSincCredOrg(servicio?.servicio, servicio?.index)){
        this.alertService.error('Solicitud de Crédito - Debe configurar los proveedores para consultar por los servicios seleccionados.');
        return
      }
    }
    
    request = this.crearObjetoServicios(request);
    
    this.spinner.show();
    this.solicitudCreditoService.consultarServicios(request, this.idTransaccion, this.rut)
    .subscribe(resp => {
      if(resp?.SolicitudDeCredito?.Stage4Response){
        this.bodyResults = resp?.SolicitudDeCredito?.Stage4Response;
        this.actualizaBodySave();
        this.consultaAsincronos(request); // cambiar a this.serviciosDisponibles
      }
      this.seeResults = true;
      this.resetCheck();
      this.serviciosAEnviar = [];
      this.escondeMuestraHeader(true);
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      const msg = error.error.message ? error.error.message : 'Solicitud de Crédito - Ocurrio un error al consultar los servicios';
      this.alertService.error(msg);
    })
  }

  resetCheck(): void{
    const checkboxes = document.querySelectorAll('input[type=checkbox]')
    if(checkboxes.length > 0){
      checkboxes.forEach((elementCheck: any)  => {
        elementCheck.checked = false;
      });
    }
  }

  // Crea objeto con los datos ingresados por el usuario mas los datos del sistema
  crearObjetoServicios(request: any): any{
    const servicioAConsultar: any[] = [];
    this.serviciosDisponibles.forEach(servicio => {
      const index = this.serviciosAEnviar.findIndex((elemento) => elemento.servicio === servicio.key);
      const values: any[] = [];
      if(index >= 0){
        const value = {
          name: servicio.values[this.serviciosAEnviar[index].index]?.name,
          type: servicio.values[this.serviciosAEnviar[index].index]?.type,
          api: servicio.values[this.serviciosAEnviar[index].index]?.api,
          uri: servicio.values[this.serviciosAEnviar[index].index]?.uri,
          description: servicio.values[this.serviciosAEnviar[index].index]?.description,
          inputVars: [] as any[]
        }
        const indexOriginal = this.serviciosDisponiblesOriginal.findIndex((elemento) => elemento.key === servicio.key);
        const inputVars: any[] = [];
        servicio.values[this.serviciosAEnviar[index].index]?.inputVars.forEach((element: any, indexValues: number) => {
          if(this.serviciosBodySave[servicio.key]){
            const elemento = element;
            elemento.value = this.serviciosBodySave[servicio.key][element.key];
            inputVars.push(elemento);
          }
          if(!element.display && element.displayDependency === ''){
            const elemento = element;
            const valueOriginal = this.serviciosDisponiblesOriginal[indexOriginal]?.values[this.serviciosAEnviar[index].index]?.inputVars[indexValues]?.value;
            elemento.value = this.camposInternos[element.key]? this.camposInternos[element.key] : (valueOriginal || '');
            inputVars.push(elemento);
          } else if(!element.display && element.displayDependency !== '' && this.validaDependenciaNoVisible(element, servicio.key)){
            const elemento = element;
            const valueOriginal = this.serviciosDisponiblesOriginal[indexOriginal]?.values[this.serviciosAEnviar[index].index]?.inputVars[indexValues]?.value;
            elemento.value = valueOriginal || '';
            inputVars.push(elemento);
          } else if(!element.display && element.displayDependency !== '' && !this.validaDependenciaNoVisible(element, servicio.key)){
            const elemento = element;
            elemento.value = '';
            inputVars.push(elemento);
          } else if(element.display && this.listaTempFile[servicio.key] && this.listaTempFile[servicio.key][element.key]){
            const elemento = element;
            elemento.value = this.listaTempFile[servicio.key][element.key].file;
            elemento.file.inputName = this.serviciosBodySave[servicio.key][element.key]
            inputVars.push(elemento);
          }
        });

        if(inputVars.length > 0){
          value.inputVars = inputVars.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.key === value.key
            ))
          )
        } else {
          value.inputVars = inputVars;
        }
        if(value.inputVars.length > 0){
          values.push(value)
          servicioAConsultar.push({key: servicio.key, values: values});
        }

      }

    });
    request = servicioAConsultar;
    return request;
  }

  // Valida si existen variables editables por el usuario
  existenVariablesEditables(key: string, indexServicio: number | any): boolean{
    let inputVars: any;

    this.serviciosDisponibles.forEach((elemento: any) => {
      if(elemento.key === key){
        inputVars = elemento.values[indexServicio]?.inputVars;
      }
    });

    if(inputVars && inputVars.length > 0){
      return inputVars.some((element: any) => element.display === true);
    }
    return false;
  }

  // Obtiene el nombre del archivo subido y se usa para mostrarlo por pantalla
  obtenerNombreArchivo(selectedItemKey: string, itemKey: string): string | null{ // VALIDAR
    if(this.listaTempFile[selectedItemKey] && this.listaTempFile[selectedItemKey][itemKey] && this.listaTempFile[selectedItemKey][itemKey].name){
      return this.listaTempFile[selectedItemKey][itemKey].name;
    }else {
      return null;
    }
  }

  // Valida si tiene los datos de entrada para indicarlo en el cuadro de resumen 
  validarEstadoCampo(servicio:any): boolean {
    if(this.existenVariablesEditables(servicio.servicio, servicio.index)){
      // hay variables editables
      if(this.serviciosBodySave[servicio.servicio]){
        let isEmpty = true;
        Object.values(this.serviciosBodySave[servicio.servicio]).forEach((x: any)=>{
          if((x === null || x === '')){
            isEmpty = false;
          }
        })
        return isEmpty;
      } else {
        return false;
      }
    } else {
      // no hay variables editables
      return true;
    }
  }

  // Valida si tiene los datos de entrada
  validarEstadoCampoPrincipal(servicioKey: string): boolean {
    const servicio = this.serviciosAEnviar.find((e: any) => e.servicio === servicioKey);
    if(servicio){
      if(this.existenVariablesEditables(servicio.servicio, servicio.index)){
        // hay variables editables
        if(this.serviciosBodySave[servicio.servicio]){
          let isEmpty = true;
          Object.values(this.serviciosBodySave[servicio.servicio]).forEach((x: any)=>{
            if((x === null || x === '')){
              isEmpty = false;
            }
          })
          return isEmpty;
        } else {
          return false;
        }
      } else {
        // no hay variables editables
        return true;
      }
    }
    return true;
  }

  // Valida si es servicio esta sincronizado, si hay alguno que no se mostrara como no sincronizado
  validarEstadoSincronizacion(servicioKey: string): string {
    const servicio = this.serviciosAEnviar.find((e: any) => e.servicio === servicioKey);
    if(servicio){
      const servicioIndex = this.serviciosDisponibles.findIndex((e: any) => e.key === servicioKey);
      if(servicioIndex >= 0 && servicio.index >= 0){
        if (this.serviciosDisponibles[servicioIndex]?.values?.[servicio.index]?.infoVariables?.passSyncStatus?.length > 0){
          const credencialesFiltradasNoCredOrg = this.serviciosDisponibles[servicioIndex]?.values?.[servicio.index]?.infoVariables?.passSyncStatus.filter((e: any) => 
            e.type !== 'CRED.ORG')
          
          if(credencialesFiltradasNoCredOrg.length > 0){
            const anyTrue = credencialesFiltradasNoCredOrg?.some((e: any) => e.status === true );
            if(anyTrue === true)
              return 'trueNoOrg';
            else 
              return 'falseNoOrg';
          }

          const credencialesFiltradasCredOrg = this.serviciosDisponibles[servicioIndex]?.values?.[servicio.index]?.infoVariables?.passSyncStatus.filter((e: any) => 
            e.type === 'CRED.ORG')
          if(credencialesFiltradasCredOrg.length > 0){
            const anyFalse = credencialesFiltradasCredOrg?.some((e: any) => e.status === false );
            if(anyFalse === true)
              return 'falseOrg';
            else 
              return 'trueOrg';
          }

        }
      }
    }
    return '';
  }

  validarServiciosAEnviar(servicioKey: string): boolean {
    const servicio = this.serviciosAEnviar.find((e: any) => e.servicio === servicioKey);
    if(servicio){
      return true;
    }
    return false;
  }

  // Obtiene el nombre del servicio para el cuadro de resumen
  obtenerNombreServicioFromObject(servicio:any): string {
    const index = this.serviciosDisponibles.findIndex(elemento => elemento.key === servicio.servicio);

    return this.serviciosDisponibles[index].values[servicio.index].name;
    
  }

  // Elimina datos guardados del body si se selecciona otro servicio (crear - editar), si es una consulta que no solicita datos de entrada se agrega directamente
  cambiaDatosEnvio(selectedItemKey: any, indexSelect: any = '', tipo: any = ''): void{
    if(this.serviciosBodySave[selectedItemKey] && this.selectedItem?.inputVars){
      this.selectedItem.inputVars[this.serviciosBodySave[selectedItemKey].index].value = '';
      delete this.serviciosBodySave[selectedItemKey];
    }
    if(this.listaTempFile[selectedItemKey]){
      delete this.listaTempFile[selectedItemKey];
    }
    // Si no tiene que mostrar algo lo agrega directamente
    if(indexSelect !== ''){
      const checkboxActualizar = document.getElementById('checkboxactualizar-' + selectedItemKey) as HTMLInputElement;
      const checkboxBuscar = document.getElementById('checkboxbuscar-' + selectedItemKey) as HTMLInputElement;
      if(tipo !== ''){
        if(tipo === 'crear' && (checkboxActualizar?.checked || checkboxBuscar?.checked)){
          checkboxActualizar.checked = true;
          checkboxBuscar.checked = false;
        } else if (tipo='buscarHistorico' && (checkboxActualizar?.checked || checkboxBuscar?.checked)){
          checkboxActualizar.checked = false;
          checkboxBuscar.checked = true;
        }

      }
      if(!this.existenVariablesEditables(selectedItemKey, indexSelect)){
        const index = this.serviciosAEnviar.findIndex(x => x.servicio === selectedItemKey);
        if(index >= 0){
          this.serviciosAEnviar.splice(index, 1);
        }
        this.serviciosBodySave[selectedItemKey] = {index: indexSelect};
        if(checkboxActualizar?.checked || checkboxBuscar?.checked){
          this.serviciosAEnviar.push({servicio: selectedItemKey, index: indexSelect});
        }
      } else {
        const index = this.serviciosAEnviar.findIndex(x => x.servicio === selectedItemKey);
        if(index >= 0){
          this.serviciosAEnviar.splice(index, 1);
        }
        if(checkboxActualizar?.checked || checkboxBuscar?.checked){
          this.serviciosAEnviar.push({servicio: selectedItemKey, index: indexSelect});
        }
      }
    }
  }

  // Logica de cambio de servicio seleccionado, por ejemplo, entre reporte basico y reporte judicial
  cambiarItemSeleccionado(){
    if(this.serviciosBodySave[this.selectedItemKey]){
      const index = this.serviciosDisponibles.findIndex(x => x.key === this.selectedItemKey);
      this.selectedItem = this.serviciosDisponibles[index].values[this.serviciosBodySave[this.selectedItemKey].index]
      this.indexServicioSelected = this.serviciosBodySave[this.selectedItemKey].index;
      this.selectedItem.inputVars.forEach((element: any) => {
        if(this.serviciosBodySave[this.selectedItemKey][element.key]){
          element.value = this.serviciosBodySave[this.selectedItemKey][element.key];
        }
      });
    } else {
      if(this.selectedItemKey && this.indexServicioSelected !== undefined){
        const index = this.serviciosDisponibles.findIndex(x => x.key === this.selectedItemKey);
        this.selectedItem = this.serviciosDisponibles[index].values[this.indexServicioSelected];
      } else {
        this.selectedItem = {};
        const select = document.getElementById('select-' + this.selectedItemKey) as HTMLInputElement;
        if(select){
          select.value = "";
        }
        
      }
    }
  }

  // Obtiene el nombre del servicio para el cuadro respuesta
  obtenerNombreServicioFromResp(servicio:any): string {
    const index = this.serviciosDisponibles.findIndex(elemento => elemento.key === servicio.key);
    return this.serviciosDisponibles[index].name;
    
  }

  // Obtiene el detalle del error del servicio
  obtenerDetalleErrorFromResp(servicio:any): string {
    const request = this.bodyResults.request.find((e:any) => e.key === servicio.key)
    const type = request.values[0].type
    const asincronoKey = this.servicioEsAsincrono(servicio.key, type); // ,type
    if(asincronoKey !== ''){
      if(servicio?.value?.errors?.hassError || servicio?.value?.reporte === 'Reporte con Errores'){
        return servicio?.value?.errors?.details?.message;

      } else if( !servicio?.value?.fechaReporte || servicio?.value?.fechaReporte === 'Sin Información' ){ // VALIDA VACIO
        return "Sin información histórica disponible";

      } else if(this.bodyResults[asincronoKey]?.errors?.hassError){
        return this.bodyResults[asincronoKey]?.errors?.details?.message;

      } else if(this.bodyResults[asincronoKey]?.reporte === 'Reporte con Errores'){
        return 'Reporte con Errores';

      } else if(!this.bodyResults[asincronoKey]){
        return 'No se pudo obtener el detalle del error asincrono';

      } else if( !this.bodyResults[asincronoKey]?.fechaReporte || this.bodyResults[asincronoKey]?.fechaReporte === 'Sin Información' ){ // VALIDA VACIO
        return "Sin información histórica disponible";

      }  else if( this.bodyResults[asincronoKey]?.reporte === 'Reporte en Generacion' ){ // VALIDA EN GENERACION
        return "Reporte en Generacion";

      } 
      else {
        return 'No se pudo obtener el detalle del error';
      }
    } else {

      if(servicio?.value?.errors?.details?.message){
        return servicio?.value?.errors?.details?.message
      } else if( !servicio?.value?.fechaReporte || servicio?.value?.fechaReporte === 'Sin Información' ){ // VALIDA VACIO
        return "Sin información histórica disponible";
      }
    }
    return 'No se pudo obtener el detalle del error';
    
  }

  // Obtiene el estado del servicio de respuesta
  obtenerEstadoRespServicio(servicio:any): string {
    const request = this.bodyResults.request.find((e:any) => e.key === servicio.key)
    const type = request.values[0].type
    const asincronoKey = this.servicioEsAsincrono(servicio.key, type); // , type
    if(asincronoKey !== ''){
      if( (!servicio?.value?.errors?.hassError || servicio?.value?.reporte === 'Reporte con Errores') && (!servicio?.value?.fechaReporte || servicio?.value?.fechaReporte === 'Sin Información') ) { // VALIDA VACIO
        return 'vacio';
      }
      if(this.bodyResults[asincronoKey] && !this.bodyResults[asincronoKey]?.errors?.hassError && !servicio?.value?.errors?.hassError && this.bodyResults[asincronoKey]?.reporte !== 'Reporte con Errores' && servicio?.value?.reporte !== 'Reporte con Errores'){
        if( !this.bodyResults[asincronoKey]?.fechaReporte || this.bodyResults[asincronoKey]?.fechaReporte === 'Sin Información' ) { // VALIDA VACIO          
          return 'vacio';
        } else if (this.bodyResults[asincronoKey]?.reporte === 'Reporte en Generacion') {
          return 'generacion';
        }
        return 'ok';
      } else {
        if(this.bodyResults[asincronoKey]?.reporte === 'Reporte con Errores' || servicio?.value?.reporte === 'Reporte con Errores') {
          return 'error-datos';
        }
        return 'error';
      }
    } else {
      if(typeof(servicio?.value?.errors?.hassError) === 'boolean'){
        if(servicio?.value?.errors?.hassError === true){
          return 'error';
        }
        if(servicio?.value?.reporte === 'Reporte con Errores'){
          return 'error-datos';
        }
        if(servicio?.value?.errors?.hassError === false && servicio?.value?.reporte !== 'Reporte con Errores'){
          if( !servicio?.value?.fechaReporte || servicio?.value?.fechaReporte === 'Sin Información' ) { // VALIDA VACIO
            return 'vacio';
          }
          return 'ok';
        }
      }
    }
    return 'error';
  }

  // Obtiene el estado del servicio de respuesta
  obtenerEstadoRespServicioBack(servicio:any, servicioKey: string): string {
    const request = this.bodyResults.request.find((e:any) => e.key === servicioKey)
    const type = request.values[0].type
    const asincronoKey = this.servicioEsAsincrono(servicioKey, type); //, type
    if(asincronoKey !== ''){
      if( !servicio?.fechaReporte || servicio?.fechaReporte === 'Sin Información' ) { // VALIDA VACIO
        return 'vacio';
      }
      if(this.bodyResults[asincronoKey] && !this.bodyResults[asincronoKey]?.errors?.hassError && !servicio?.errors?.hassError && this.bodyResults[asincronoKey]?.reporte !== 'Reporte con Errores' && servicio?.reporte !== 'Reporte con Errores'){
        if( !this.bodyResults[asincronoKey]?.fechaReporte || this.bodyResults[asincronoKey]?.fechaReporte === 'Sin Información' ) { // VALIDA VACIO
          return 'vacio';
        } else if (this.bodyResults[asincronoKey]?.reporte === 'Reporte en Generacion') { // VALIDA EN GENERACION
          return 'generacion';
        }
        return 'ok';
      } else {
        /*if(this.bodyResults[asincronoKey]?.reporte === 'Reporte con Errores' || servicio?.value?.reporte === 'Reporte con Errores') {
          return 'error-datos';
        }*/
        return 'error';
      }
    } else {
      if(typeof(servicio?.errors?.hassError) === 'boolean' || servicio?.value?.reporte === 'Reporte con Errores'){
        if(servicio?.errors?.hassError === true){
          return 'error';
        }
        /*if(servicio?.value?.reporte === 'Reporte con Errores'){
          return 'error-datos';
        }*/
        if(servicio?.errors?.hassError === false && servicio?.value?.reporte !== 'Reporte con Errores'){
          if( !servicio?.fechaReporte || servicio?.fechaReporte === 'Sin Información' ) { // VALIDA VACIO
            return 'vacio';
          }
          return 'ok';
        }
      }
    }
    return 'error';
  }

  obtieneFecha(servicio: any): string {
    if(servicio?.value?.fechaReporte && servicio?.value?.fechaReporte !== 'Sin Información'){
      /*if(Date.parse(servicio?.value?.fechaReporte)){
        return servicio?.value?.fechaReporte;
      } else {
        //servicio.value.fechaReporte = "12-07-2024 16:52:44"
        const fecha1 = servicio?.value?.fechaReporte.split(' ');
        if(fecha1[0]){
          const fecha2 = fecha1[0].split('-');
          if(fecha2[2]){
            const fecha = new Date(fecha2[2], (Number(fecha2[1]) - 1), fecha2[0]).toISOString();
            return fecha; 
          }
        }
        return ''
      }*/
      if(servicio?.value?.fechaReporte.includes('T')){
        return servicio?.value?.fechaReporte;
      } else {
        const fecha1 = servicio?.value?.fechaReporte.split(' ');
        if(fecha1[0]){
          const fecha2 = fecha1[0].split('-');
          if(fecha2[2]){
            const fecha = new Date(fecha2[2], (Number(fecha2[1]) - 1), fecha2[0]).toISOString();
            return fecha; 
          }
        }
        return ''
      }
    } else {
      return '';
    }
  }

  // Esconde o muestra desplegable card principal
  clickHeader(): void{
    const elementoHTML = document.getElementById("collapsable-card-body");
    const botonlHTML = document.getElementById("icon-left-card");
    const botondHTML = document.getElementById("icon-down-card");
    if (elementoHTML && botonlHTML && botondHTML){
      elementoHTML.hidden = !elementoHTML.hidden;
      botondHTML.hidden = !botondHTML.hidden;
      botonlHTML.hidden = !botonlHTML.hidden;

    }
  }

  // Esconde o muestra desplegable card principal, dependiente de una entrada
  escondeMuestraHeader(entrada: boolean): void{
    const elementoHTML = document.getElementById("collapsable-card-body");
    const botonlHTML = document.getElementById("icon-left-card");
    const botondHTML = document.getElementById("icon-down-card");
    if (elementoHTML && botonlHTML && botondHTML){
      elementoHTML.hidden = entrada;
      botondHTML.hidden = entrada;
      botonlHTML.hidden = !entrada;

    }
  }

  // Funcion que borra servicio de Resultados
  borrarServicio(servicioKey: any): void{
    this.spinner.show();
    if(typeof(servicioKey) === 'string'){
      this.subscriptions.push(this.solicitudCreditoService.borrarReportePrincipal(servicioKey, this.idTransaccion, this.rut).subscribe(resp => {
        this.alertService.success(resp?.body?.message || 'Solicitud de Credito - Reporte borrado exitosamente');

        const type = this.bodyResults.request.find((e:any) => e.key === servicioKey).values?.[0]?.type || '';

        if(this.bodyResults[servicioKey]){
          delete this.bodyResults[servicioKey];
        }
        if(this.serviciosBodySave[servicioKey]){
          delete this.serviciosBodySave[servicioKey];
        }

        if(type){
          const asincronoKey = this.servicioEsAsincrono(servicioKey, type);
          if(asincronoKey){
            if(this.bodyResults[asincronoKey]){
              delete this.bodyResults[asincronoKey];
            }
            if(this.serviciosBodySave[asincronoKey]){
              delete this.serviciosBodySave[asincronoKey];
            }
          }
        }

        this.seeResults = true;
        if(!this.validaBorrado(servicioKey)){
          this.alertService.error('Solicitud de Crédito - Ocurrio un error al validar el borrado');
        }
        this.spinner.hide();
      }, (error: any) => {
        this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al intentar borrar el servicio');
        this.spinner.hide();
      }));
      
    } else {
      this.spinner.hide();
    }
    
  }

  // Valida si viene el reporte en la respuesta
  validaBorrado(key: string): boolean{
    if(!this.bodyResults[key]){
      return true;
    }
    return false;
  }

  // Busca el request en los resultados y envia nuevamente servicio indicado
  reconsultar(servicioKey: any): void{
    const request = this.bodyResults?.request?.find((e:any) => e.key === servicioKey)
    if(request){
      const type = request.values[0]?.type
      const asincronoKey = this.servicioEsAsincrono(servicioKey, type);
      if(asincronoKey === ''){
        if(this.bodyResults.request){
          this.spinner.show();
          this.solicitudCreditoService.consultarServicios([request], this.idTransaccion, this.rut)
          .subscribe(resp => {
            if(resp.SolicitudDeCredito?.Stage4Response){
              this.bodyResults = resp.SolicitudDeCredito?.Stage4Response;
              this.actualizaBodySave();
            }
            this.seeResults = true;
            this.spinner.hide();
          }, (error: any) => {
            this.spinner.hide();
            const msg = error.error.message ? error.error.message : 'Solicitud de Crédito - Ocurrio un error al consultar los servicios';
            this.alertService.error(msg);
          })
        }
      } else {
        this.reconsultarAsincronos(servicioKey, asincronoKey);
      }
    } else {
      this.alertService.error('Solicitud de Crédito - Ocurrio un error al recuperar datos a consultar');
      this.spinner.hide();
    }
  }

  bodyReconsultarAsincronos(resp: any, servicioKey: string, asincronoKey: string): any[]{
    const asincronos = JSON.parse(JSON.stringify(resp.stages[3].asincronos));
    const requestAsincrono: any[] = [];
    let jsonAsincrono: any = {};

    const request = this.bodyResults.request.find((e:any) => e.key === servicioKey)
    const type = request.values[0].type

    const indice = asincronos.findIndex((elemento: any) => elemento.name == servicioKey && elemento?.type?.indexOf(type) > -1); // && element.type === type
    if(indice >= 0){

      if (asincronos[indice]?.services[0]?.completed && asincronos[indice]?.services[0]?.name == servicioKey){ // true && ==servicioKey
        const indiceServicio2 = this.serviciosDisponibles?.findIndex((elemento: any) => 
          elemento.key == asincronoKey);  

        if(indiceServicio2 >= 0){
          jsonAsincrono = JSON.parse(JSON.stringify(this.serviciosDisponibles[indiceServicio2]));
          jsonAsincrono.values[0].inputVars = [];
          this.serviciosDisponibles[indiceServicio2]?.values[0]?.inputVars?.forEach((elementValue: any) => {

          if(this.camposInternos[elementValue.key]){
            const valor = JSON.parse(JSON.stringify(elementValue));
            valor.value = this.camposInternos[elementValue.key];
            jsonAsincrono.values[0].inputVars.push(valor);
          } else {
            if(elementValue.xtract !== ''){
              const valor = JSON.parse(JSON.stringify(elementValue));
              //valor.value = this.getProp(this.bodyResults, elementValue.xtract);
              if(this.bodyResults?.xtractInfo?.[elementValue.xtract]){
                valor.value = this.bodyResults?.xtractInfo?.[elementValue.xtract];//this.getProp(this.bodyResults, elementValue.xtract);
              }
              jsonAsincrono.values[0].inputVars.push(valor);
            }
          }
          });
          requestAsincrono.push(jsonAsincrono);
        }
      } 

    }
    return requestAsincrono;
  }

  reconsultarAsincronos(servicioKey: string, asincronoKey: string): void{
    if(this.bodyResults[servicioKey]?.errors?.hassError === true || this.bodyResults[servicioKey]?.reporte === 'Reporte con Errores'){
      const request = this.bodyResults.request.filter((e: any) => e.key === servicioKey);
      if(request.length > 0){
        this.spinner.show();
        this.solicitudCreditoService.consultarServicios(request, this.idTransaccion, this.rut)
        .subscribe(resp => {
          if(resp.SolicitudDeCredito?.Stage4Response){
            this.bodyResults = resp.SolicitudDeCredito?.Stage4Response;
            this.actualizaBodySave();

            this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
              this.bodyModelos = resp;

              const requestAsincrono: any[] = this.bodyReconsultarAsincronos(resp, servicioKey, asincronoKey);
        
              if(requestAsincrono.length > 0){
                this.spinner.show();
                this.solicitudCreditoService.consultarServicios(requestAsincrono, this.idTransaccion, this.rut)
                .subscribe(resp => {
                  if(resp.SolicitudDeCredito?.Stage4Response){
                    this.bodyResults = resp.SolicitudDeCredito?.Stage4Response;  
                  }
                  this.spinner.hide();
                  //const asincronosKeys = this.obtenerRequestAsincrono();
                  this.validaAsincronos([asincronoKey]);
                }, (error: any) => {
                  this.spinner.hide();
                  const msg = error.error.message ? error.error.message : 'Solicitud de Crédito - Ocurrio un error al consultar servicios';
                  this.alertService.error(msg);
                  this.spinner.hide();
                })
              } else {
                this.spinner.hide();
              }
        
            }, (error: any) => {
              this.spinner.hide();
              const msg = error.error.message ? error.error.message : 'Solicitud de Crédito - Ocurrio un error al recuperar datos del flujo de credito';
              this.alertService.error(msg);
            }));
          }
          this.seeResults = true;
          this.spinner.hide();
        }, (error: any) => {
          this.spinner.hide();
          const msg = error.error.message ? error.error.message : 'Solicitud de Crédito - Ocurrio un error al consultar servicios';
          this.alertService.error(msg);
        })
      } else {
        this.alertService.error('Solicitud de Crédito - Ocurrio un error al recuperar recuperar datos para la consulta');
        this.spinner.hide();
      }

    }
    if(!this.bodyResults[servicioKey]?.errors?.hassError && (this.bodyResults[asincronoKey]?.errors?.hassError || !this.bodyResults[asincronoKey] || this.bodyResults[asincronoKey]?.reporte === 'Reporte con Errores')){
      this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
        this.spinner.show();
        this.bodyModelos = resp;
        const requestAsincrono: any[] = this.bodyReconsultarAsincronos(resp, servicioKey, asincronoKey);

        if(requestAsincrono.length > 0){
          this.spinner.show();
          this.solicitudCreditoService.consultarServicios(requestAsincrono, this.idTransaccion, this.rut)
          .subscribe(resp => {
            if(resp.SolicitudDeCredito?.Stage4Response){
              this.bodyResults = resp.SolicitudDeCredito?.Stage4Response;  
            }
            this.spinner.hide();
            this.validaAsincronos([asincronoKey]);
          }, (error: any) => {
            this.spinner.hide();
            const msg = error.error.message ? error.error.message : 'Solicitud de Crédito - Ocurrio un error al consultar servicios';
            this.alertService.error(msg);
            this.spinner.hide();
          })
        } else {
          this.spinner.hide();
        }
  
      }, (error: any) => {
        this.spinner.hide();
        const msg = error.error.message ? error.error.message : 'Solicitud de Crédito - Ocurrio un error al recuperar datos del flujo de credito';
        this.alertService.error(msg);
      }));
    }
  }

  // Valida si es asincrono, devuelve la key del servicio asincrono
  servicioEsAsincrono(servicioKey: any, type: string): string{ //, type: string
    const asincronos = this.bodyModelos.stages[3].asincronos;
    const indice = asincronos.findIndex((elemento: any) => elemento.name == servicioKey && elemento?.type?.indexOf(type) > -1); // agregar validacion por type elemento.type === type
    if(indice >= 0){
      return asincronos[indice].services[1].name;
    }
    return '';
  }
  

  // Valida si es visible
  servicioIsVisible(servicioKey: any): boolean{
    const indice = this.serviciosDisponibles.findIndex((elemento: any) => elemento.key == servicioKey);
    if(indice >= 0){
      return this.serviciosDisponibles[indice].display;
    }
    return false;
  }

  
  validaErrores(): boolean {
    let valido = true;
    for (const key in this.bodyResults) {
      this.bodyResults[key].errors?.hassError === true || this.bodyResults[key]?.reporte === 'Reporte con Errores' ? valido = false : null;
    }
    return valido; 
  }

  // Valida si la etapa esta completa y permite avanzar a la siguiente etapa
  avanzarFlujo(): void{
    let valido = true;
    const resultados = JSON.parse(JSON.stringify(this.bodyResults))
    if(resultados.request){
      delete resultados.request;
    }

    for(const servicio of Object.keys(resultados)){
      if(servicio !== 'xtractInfo'){
        if(this.obtenerEstadoRespServicioBack(resultados[servicio], servicio) === 'error' || this.obtenerEstadoRespServicioBack(resultados[servicio], servicio) === 'generacion'){
          valido = false;
        }
      }
    }
    if(valido){
      this.spinner.show();
      this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
        this.bodyModelos = resp;
        if(this.bodyModelos?.stages[Number(this.stepper) + 1]?.optional === false){
          if(this.bodyModelos?.stages[Number(this.stepper) + 1]?.completed){
            this.solicitudCreditoService.setStep('next');
          } else {
            this.spinner.hide();
            this.alertService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
          }
        } else {
          this.solicitudCreditoService.setStep('next');
        }
      }, (error: any) => {
        this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar datos del flujo de credito');
        this.spinner.hide();
      }));
    } else {
      this.alertService.error('Solicitud de Crédito - Se encuentran servicios consultados con errores o en generacion');
    }
  }

  // Valida si el campo es obligatorio para indicarlo en la vista
  validaObligatorio(key: string): boolean{
    let servicio = this.bodyModelos?.stages[3]?.sincronos.filter((e: any) => e.name === key);
    if(servicio.length === 0){
      servicio = this.bodyModelos?.stages[3]?.asincronos.filter((e: any) => e.name === key);
    }
    if(servicio[0] && !servicio[0].optional && !servicio[0].completed){
      return true;
    }
    return false;
  }

  // Valida si el campo tiene una dependencia a otro y valida si se cumple, y agrega el dato al form o lo quita si no cumple la condicion
  validaDependencia(item: any): boolean{
    const condicion = item.displayDependency;
    const key = item.key;
    const value = item.value;
    if(condicion !== ''){
      const condicionParsed = parse(condicion);
      const formTemp = this.servicioForm.getRawValue(); // objeto necesario para la validacion con datos desde la api
      const str =  expresEval(condicionParsed, {formTemp}); //condicion que viene con la variable formTemp desde back para evaluar
      if(str === true && !this.servicioForm.get(key)){
        if (this.serviciosBodySave[this.selectedItemKey]) {
          this.servicioForm.addControl(key, new UntypedFormControl(this.serviciosBodySave[this.selectedItemKey][key] || '', Validators.required));
        } else {
          this.servicioForm.addControl(key, new UntypedFormControl(value || '', Validators.required));
        }
      }
      if(!str && this.servicioForm.get([key])){
        this.servicioForm.removeControl(key);
      }
      return str;
    } else {
      return false;
    }
  }

  // Valida si el campo tiene una dependencia a otro y valida si se cumple, y agrega el dato al form o lo quita si no cumple la condicion
  validaDependenciaNoVisible(item: any, servicioKey: string): boolean{
    const condicion = item.displayDependency;
    const key = item.key;
    const value = item.value;
    if(condicion !== ''){
      const condicionParsed = parse(condicion);
      const formTemp = this.serviciosBodySave[servicioKey]; // objeto necesario para la validacion con datos desde la api
      const str =  expresEval(condicionParsed, {formTemp}); //condicion que viene con la variable formTemp desde back para evaluar
      return str;
    } else {
      return false;
    }
  }

  agregaServicioPredeterminado(form: any, indiceServicio: number, servicio: string): void {
    const valor: any = {};
    valor.index = indiceServicio;

    form.forEach((element: any) => {
      if(element.typeAttribute === "[]"){
        valor[element.key] = element.value.split(',');
      } else {
        if(element.displayDependency !== ''){
          const condicion = element.displayDependency;
          if(condicion !== ''){
            const condicionParsed = parse(condicion);
            const formTemp = valor; // objeto necesario para la validacion con datos desde la api
            const str =  expresEval(condicionParsed, {formTemp}); //condicion que viene con la variable formTemp desde back para evaluar

            if(str === true){
              valor[element.key] = element.value;
            }
          }
        } else {
          valor[element.key] = element.value;
        }
      }
    });
      
    this.serviciosBodySave[servicio] = valor; 
  }

  quitarServicioPredeterminado(servicio: string, indexServicio: number): void{
    const index = this.serviciosAEnviar.findIndex(x => x.servicio === servicio);
      if(index >= 0){
        this.serviciosAEnviar.splice(index, 1);
      }
      if(this.serviciosBodySave[servicio]){
        delete this.serviciosBodySave[servicio];
      }
      if(this.listaTempFile[servicio]){
        delete this.listaTempFile[servicio];
      }
      if(this.serviciosDisponibles[indexServicio].key === servicio){
        if(this.serviciosDisponibles[indexServicio].values){
          this.serviciosDisponibles[indexServicio].values.forEach((element: any) => {
            element.inputVars.forEach((y: any) => {
              if(y.display){
                y.value = "";
              }
            })
          });
        }
      }


      this.crearForm();
  }

  existeBodyGuardado(servicio: string, indiceServicio: number): boolean {
    if(indiceServicio >= 0){
      const respuesta = this.serviciosBodySave[servicio]?.index === indiceServicio ? true: false;
      return respuesta;
    }
    return false;
  }

  mostrarOcultarServicio(servicioKey: string): void {
    if(this.selectedItemKey !== servicioKey){
      this.selectedItemKey = servicioKey;
      if(!this.serviciosBodySave[servicioKey]){
        this.selectedItem = {};
        this.indexServicioSelected = undefined;
      }
      this.cambiarItemSeleccionado();
      this.crearForm();
    } else {
      this.selectedItemKey = '';
      this.indexServicioSelected = undefined;
      this.selectedItem = {}
    }
  }

  buscarServicio(event: any, servicio: string, indexServicio: number): void{
    const checkboxActualizar = document.getElementById('checkboxactualizar-' + servicio) as HTMLInputElement;
    if(checkboxActualizar){
      checkboxActualizar.checked = false;
    }

    if (event?.target?.checked){
      const indiceServicio = this.serviciosDisponibles[indexServicio].values.findIndex((elemento: any) => elemento.type === 'buscarHistorico');
      
      if( !this.existeBodyGuardado(servicio, indiceServicio) ){
        this.quitarServicioPredeterminado(servicio, indexServicio);
    
        if(indiceServicio >= 0){
          const form = this.serviciosDisponiblesOriginal[indexServicio].values[indiceServicio].inputVars.filter((e: any) => e.display === true || e.displayDependency !== '');
          this.agregaServicioPredeterminado(form, indiceServicio, servicio);
          this.serviciosAEnviar.push({servicio: servicio, index: indiceServicio});
        }
      } else {
        this.serviciosAEnviar.push({servicio: servicio, index: indiceServicio});
      }

      const result = this.serviciosDisponibles[indexServicio].values.filter((elemento: any) => elemento.type === 'buscarHistorico'); 
      if(!this.existenVariablesEditables(servicio, indiceServicio) && result.length == 1) {
        this.selectedItemKey = '';
      } else if(this.existenVariablesEditables(servicio, indiceServicio) || result.length > 1) {
        this.selectedItemKey = servicio;
        this.cambiarItemSeleccionado();
        this.crearForm();
      } else {
        this.selectedItemKey = servicio;
        this.selectedItem = {};
        this.indexServicioSelected = undefined;
      }
    } else {
      this.quitarServicioPredeterminado(servicio, indexServicio);
      this.selectedItemKey = '';
      this.selectedItem = {};
      this.indexServicioSelected = undefined;
    }

  }

  actualizarServicio(event: any, servicio: string, indexServicio: number): void{
    const checkboxBuscar = document.getElementById('checkboxbuscar-' + servicio) as HTMLInputElement;
    if(checkboxBuscar){
      checkboxBuscar.checked = false;
    }

    if (event?.target?.checked){
      const indiceServicio = this.serviciosDisponibles[indexServicio].values.findIndex((elemento: any) => elemento.type === 'crear');
      if( !this.existeBodyGuardado(servicio, indiceServicio) ){ // si hay mas de una opcion indiceServicio tendria que ser un array
        this.quitarServicioPredeterminado(servicio, indexServicio);
    
        if(indiceServicio >= 0){
          const form = this.serviciosDisponiblesOriginal[indexServicio].values[indiceServicio].inputVars.filter((e: any) => e.display === true || e.displayDependency !== '');
          this.agregaServicioPredeterminado(form, indiceServicio, servicio);
          this.serviciosAEnviar.push({servicio: servicio, index: indiceServicio});
        }
      } else {
        this.serviciosAEnviar.push({servicio: servicio, index: indiceServicio});
      }

      const result = this.serviciosDisponibles[indexServicio].values.filter((elemento: any) => elemento.type === 'crear'); 
      if(!this.existenVariablesEditables(servicio, indiceServicio) && result.length == 1) {
        this.selectedItemKey = '';
      } else if(this.existenVariablesEditables(servicio, indiceServicio) || result.length > 1) {
        this.selectedItemKey = servicio;
        this.cambiarItemSeleccionado();
        this.crearForm();
      } else {
        this.selectedItemKey = servicio;
        this.selectedItem = {};
        this.indexServicioSelected = undefined;
      }
    } else {
      this.quitarServicioPredeterminado(servicio, indexServicio);
      this.selectedItemKey = '';
      this.selectedItem = {};
      this.indexServicioSelected = undefined;
    }

  }

  actualizarTodos(event: any): void{
    const checkboxBuscar = document.getElementById('checkbox-buscar-todos') as HTMLInputElement;
    if(checkboxBuscar && checkboxBuscar.checked){
      checkboxBuscar.checked = false;
    }

    this.checkboxesBuscar?.forEach(element => {
      if(element?.nativeElement?.checked){
        element.nativeElement.click()
      }
    })

    this.checkboxesActualizar?.forEach(element => {
      if(!(event?.target?.checked === element?.nativeElement?.checked)){
        element.nativeElement.click()
      }
    })
    this.indexServicioSelected= undefined;
    this.selectedItemKey = '';
  }

  buscarTodos(event: any): void{
    const checkboxActualizar = document.getElementById('checkbox-actualizar-todos') as HTMLInputElement;
    if(checkboxActualizar && checkboxActualizar.checked){
      checkboxActualizar.checked = false;
    }

    this.checkboxesActualizar?.forEach(element => {
      if(element?.nativeElement?.checked){
        element.nativeElement.click()
      }
    })

    this.checkboxesBuscar?.forEach(element => {
      if(!(event?.target?.checked === element?.nativeElement?.checked)){
        element.nativeElement.click()
      }
    })
    this.indexServicioSelected= undefined;
    this.selectedItemKey = '';
  }

  onChangeSelect(event: any, servicio: any): void{
    const i = event?.target?.value;
    if(i === ''){
      this.indexServicioSelected = undefined;
      this.selectedItem = {};
      this.crearForm();
      this.cambiaDatosEnvio(this.selectedItemKey);
    } else {
      this.indexServicioSelected = i;
      const index: any = this.indexServicioSelected;
      this.selectedItem = servicio.values[index];
      this.cambiaDatosEnvio(this.selectedItemKey, i, servicio.values[i].type);
      this.crearForm();
    }
  }

  obtenerDescripcion(servicioKey: string): string {
    const sincrono = this.bodyModelos?.stages[3]?.sincronos?.find((e: any) => e.name === servicioKey);
    if(sincrono){
      return sincrono?.description || '';
    }
    const asincrono = this.bodyModelos?.stages[3]?.asincronos?.find((e: any) => e.name === servicioKey);
    if(sincrono){
      return asincrono?.description || '';
    }
    return '';
  }

  visibilidadSelect(servicio: any): boolean {
    //this.selectedItem
    if(this.selectedItem && Object.keys(this.selectedItem).length <= 0){
      return false;
    }
    if(servicio.key === this.selectedItemKey){
      const elementos = servicio.values.filter((e: any) => e?.type === this.selectedItem.type);
      if(elementos.length <= 1){
        return false;
      }
    }
    return true;
  }

  limpiarCheckedTodos(): void {
    this.checkboxesBuscarTodo?.forEach(element => {
      if(element?.nativeElement?.checked === true){
        element.nativeElement.checked = false;
      }
    });
    this.checkboxesActualizarTodo?.forEach(element => {
      if(element?.nativeElement?.checked === true){
        element.nativeElement.checked = false;
      }
    });
  }

  validaTypeConsulta(type: string, values: any[]): boolean {
    if(values && values.length > 0 && type) {
      const dato = values.find(e => e && e?.type === type);
      if(dato) {
        return true;
      }
    }
    return false;
  }

  obtenerRequestAsincrono(): any[] {
    const asincronos: any[] = [];
    if(this.bodyResults && Object.keys(this.bodyResults).length > 0){
      for (const key in this.bodyResults){
        if(this.bodyResults[key].reporte === 'Reporte en Generacion'){
          asincronos.push(key);
        }
      }
    }
    return asincronos;
  }

  //keysAConsultar -> lista de keys de reporte a llamar
  validaAsincronos(keysAConsultar: any[], ejecuciones: number = 25): void { // agregar validadores de this.bodyResults y manejar aca spinner
    this.spinner.show();
    const asincronos: any[] = this.bodyModelos.stages[3].asincronos || []; // definicion de asincronos
    const servicioAConsultar: any[] = []; // request servicios a consultar
    if(this.bodyResults && Object.keys(this.bodyResults).length > 0 && ejecuciones > 0 && keysAConsultar.length > 0){
      const request: any[] = this.bodyResults?.request || [];
      for (const key in this.bodyResults){
        const validaAsincrono: any =  asincronos.find(e => e?.services[1]?.name === key); 
        if(this.bodyResults[key].reporte === 'Reporte en Generacion' && keysAConsultar.includes(key) && validaAsincrono){ // validar que sea asincrono posicion 1 (0, 1)
          const requestKey: any = request.find(e => e?.key === key);
          if(requestKey && Object.keys(requestKey).length > 0){
            servicioAConsultar.push(requestKey);
          }
        }
      }
      if(servicioAConsultar.length > 0){
        this.consultarAsincronos(servicioAConsultar, ejecuciones, keysAConsultar);
      } else {
        this.spinner.hide();
      }
    } else {
      this.spinner.hide();
    }
  }

  consultarAsincronos(servicioAConsultar: any[], ejecuciones: number, keysAConsultar: any[]): void {
    this.solicitudCreditoService.consultarServicios(servicioAConsultar, this.idTransaccion, this.rut)
    .subscribe(resp => {
      if(resp.SolicitudDeCredito?.Stage4Response){
        this.bodyResults = resp.SolicitudDeCredito?.Stage4Response;
        this.actualizaBodySave();
      }
      this.seeResults = true;
      setTimeout(() => { // delay de 1000 ms
        this.validaAsincronos(keysAConsultar, ejecuciones - 1); // validar
      }, 8000);
    }, (error: any) => { // termina el ciclo y hay error de este tipo
      const msg = error.error.message ? error.error.message : 'Solicitud de Crédito - Ocurrio un error al consultar servicios';
      this.alertService.error(msg);
      this.seeResults = true;
      this.spinner.hide();
    });
  }

}