<div class="container-gtp mb-5">
    <img class="banner-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt=""/>
    <h2 class="text-center pt-3">Renta</h2>
    <h5 class="text-center">
        <span class="badge bg-secondary text-center">
            <span *ngIf="rut">
                {{rut | rutFormat | uppercase}}
            </span>
        </span>
    </h5>
    <div class="break-box">
        <div class="row">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Información Renta
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Rut</th>
                                            <td [attr.colspan]="reporte.length">
                                                <span *ngIf="rut">
                                                    {{rut | rutFormat | uppercase}}
                                                </span>
                                            </td>
                                        </tr>
                                        <ng-container *ngFor="let itemHeader of headerInformacionRenta">
                                            <tr>
                                                <th width="30%">{{itemHeader.text}}</th>
                                                <td *ngFor="let item of reporte"
                                                    [attr.width]="70/reporte.length + '%'">
                                                    <span *ngIf="item.calculadoRenta">
                                                        {{item.calculadoRenta[itemHeader.value]}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Renta Fija
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of reporte">
                                                <ng-container *ngIf="data.calculadoRenta">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculadoRenta.fecha || ''}}</span></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0 fw-bolder">Sueldos y Salarios</th>
                                            <th class="border-0 fw-bolder" *ngFor="let item of reporte">Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemHeader of headerRentaFija">
                                            <tr>
                                                <th width="30%">{{itemHeader.text}}</th>
                                                <td *ngFor="let item of reporte"
                                                    [attr.width]="70/reporte.length + '%'">
                                                    <span *ngIf="item.calculadoRenta">
                                                        {{item.calculadoRenta[itemHeader.value] | number: '1.0-0'}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Renta Variable
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of reporte">
                                                <ng-container *ngIf="data.calculadoRenta">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculadoRenta.fecha || ''}}</span></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0 fw-bolder">Honorarios</th>
                                            <th class="border-0 fw-bolder" *ngFor="let item of reporte">Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemHeader of headerRentaVariable">
                                            <tr>
                                                <th width="30%">{{itemHeader.text}}</th>
                                                <td *ngFor="let item of reporte"
                                                    [attr.width]="70/reporte.length + '%'">
                                                    <span *ngIf="item.calculadoRenta">
                                                        {{item.calculadoRenta[itemHeader.value] | number: '1.0-0'}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Rentas por participación en sociedades
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of reporte">
                                                <ng-container *ngIf="data.calculadoRenta">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculadoRenta.fecha || ''}}</span></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0 fw-bolder"></th>
                                            <th class="border-0 fw-bolder" *ngFor="let item of reporte">Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemHeader of headerRentaParticipacion">
                                            <tr>
                                                <th width="30%">{{itemHeader.text}}</th>
                                                <td *ngFor="let item of reporte"
                                                    [attr.width]="70/reporte.length + '%'">
                                                    <span *ngIf="item.calculadoRenta">
                                                        {{item.calculadoRenta[itemHeader.value] | number: '1.0-0'}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Otros Ingresos Persibidos
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of reporte">
                                                <ng-container *ngIf="data.calculadoRenta">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculadoRenta.fecha || ''}}</span></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0 fw-bolder"></th>
                                            <th class="border-0 fw-bolder" *ngFor="let item of reporte">Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemHeader of headerIngresosPersibidos">
                                            <tr>
                                                <th width="30%">{{itemHeader.text}}</th>
                                                <td *ngFor="let item of reporte"
                                                    [attr.width]="70/reporte.length + '%'">
                                                    <span *ngIf="item.calculadoRenta">
                                                        {{item.calculadoRenta[itemHeader.value] | number: '1.0-0'}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Total Renta
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of reporte">
                                                <ng-container *ngIf="data.calculadoRenta">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculadoRenta.fecha || ''}}</span></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0 fw-bolder"></th>
                                            <ng-container *ngFor="let item of reporte">
                                                <th class="border-0 fw-bolder">Monto</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemHeader of headerTotalRentaLiq">
                                            <tr>
                                                <th width="30%">{{itemHeader.text}}</th>
                                                <td *ngFor="let item of reporte"
                                                    [attr.width]="70/reporte.length + '%'">
                                                    <span *ngIf="item.calculadoRenta">
                                                        {{item.calculadoRenta[itemHeader.value] | number: '1.0-0'}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of reporte">
                                                <ng-container *ngIf="data.calculadoRenta">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculadoRenta.fecha || ''}}</span></th>
                                                    <th class="border-0 py-1 text-center"></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0 fw-bolder"></th>
                                            <ng-container *ngFor="let item of reporte">
                                                <th class="border-0 fw-bolder">Monto</th>
                                                <th class="border-0 fw-bolder">%</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemHeader of headerTotalRenta">
                                            <tr>
                                                <th width="30%">{{itemHeader.text}}</th>

                                                <ng-container *ngFor="let item of reporte">
                                                    <td>
                                                        <span *ngIf="item.calculadoRenta">
                                                            {{item.calculadoRenta[itemHeader.value] | number: '1.0-0'}}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="item.calculadoRenta">
                                                            {{ item.calculadoRenta[itemHeader.porc] * 100 | number: '1.0-0'}}
                                                        </span>
                                                    </td>
                                                </ng-container>

                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>