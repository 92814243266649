<div [ngClass]="{'container-gtp mb-5': showHeader}">
    <ng-container *ngIf="showHeader">
        <img class="banner-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt=""/>
        <h2 class="text-center pt-3">REPORTE FINANCIERO</h2>
        <h5 class="text-center">
            <span class="badge bg-secondary text-center">
                <span *ngIf="rut">
                    {{rut | rutFormat | uppercase}}
                </span>
            </span>
        </h5>
    </ng-container>
    <div class="break-box">
        <div class="row">
            <!-- TABLA Informacion Balance -->
            <div class="mt-1 col-sm-12">
                <div class="card textos-campo">
                    <h5 class="card-header list-group-item cuadro-color">Información Balance</h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th>Rut</th>
                                            <td colspan="2">
                                                <span *ngIf="rut">
                                                    {{rut | rutFormat | uppercase}}
                                                </span>
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="validaNombre()">
                                            <tr>
                                                <th>Nombre o Razón Social</th>
                                                <td colspan="2">
                                                    <span *ngIf="rut">
                                                        {{nombre}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="validarInformacionBalance()">
                                            <ng-container *ngFor="let item of headerBalance">
                                                <tr>
                                                    <th width="30%">{{balanceReporteFullInformacionBalance[item]}}</th>
                                                    <ng-container *ngIf="validarInformacionBalance()">
                                                        <td *ngFor="let data of [informacionBalance[0]]" colspan="2">
                                                            <span *ngIf="data.calculado">
                                                                {{data.calculado[item]}}
                                                            </span>
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="validarInformacionBalance()">
                                            <ng-container *ngFor="let item of headerBalance2">
                                                <tr>
                                                    <th width="30%">{{balanceReporteFullInformacionBalance[item]}}</th>
                                                    <td *ngFor="let data of informacionBalance">
                                                        <span *ngIf="data.calculado">
                                                            {{data.calculado[item]}}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- TABLA Resumen Financiero -->

            <div class="mt-1 col-sm-12" *ngIf="validarInformacionBalance()">
                <div class="card textos-campo">
                    <h5 class="card-header list-group-item cuadro-color">Resumen Financiero</h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of informacionBalance">
                                                <ng-container *ngIf="data.calculado">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of headerResumenFinanciero">
                                            <tr>
                                                <th width="30%">{{balanceReporteFullResumenFinanciero[item]}}</th>
                                                <td *ngFor="let data of informacionBalance"
                                                    [attr.width]="70/informacionBalance.length + '%'">
                                                    <span *ngIf="data.calculado">
                                                        <ng-template #percent>
                                                            {{data.calculado[item] | number: '1.0-2'}}
                                                        </ng-template>

                                                        <ng-container *ngIf="!getPercents(item); else percent">
                                                            {{data.calculado[item] | number: '1.0-0'}}
                                                        </ng-container>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="break-box">
        <div class="row">
            <!-- TABLA Activos -->
            <div class="mt-1 col-sm-12" *ngIf="validarInformacionBalance()">
                <div class="card textos-campo">
                    <h5 class="card-header list-group-item cuadro-color">
                        Activos
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">                                
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of informacionBalance">
                                                <ng-container *ngIf="data.calculado">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                                    <th class="border-0 py-1 fw-bolder"></th>
                                                </ng-container>
                                                
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0 text-center"></th>
                                            <ng-container *ngFor="let item of informacionBalance">
                                                <th class="border-0 fw-bolder" *ngIf="!(item.calculado) || !(item.calculado?.moneda) || item.calculado?.moneda.toUpperCase() === 'PESOS'">Monto en $</th>
                                                <th class="border-0 fw-bolder" *ngIf="item.calculado?.moneda && item.calculado?.moneda.toUpperCase() === 'DOLARES'">Monto en US $</th>
                                                <th class="border-0 fw-bolder">Valor %</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of headerResumenActivos">
                                            <tr>
                                                <th width="30%"><span
                                                        [ngClass]="{'fw-bolder text-uppercase': getStrongTitle(item[0])}">{{balanceReporteFullActivos[item[0]]}}</span>
                                                </th>
                                                <ng-container *ngFor="let data of informacionBalance">
                                                    <td>
                                                        <span *ngIf="data.calculado">
                                                            {{data.calculado[item[0]] | number: '1.0-0'}}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="data.calculado">
                                                            {{data.calculado[item[1]] | number: '1.0-2'}}
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- TABLA Pasivos -->

            <div class="mt-1 col-sm-12" *ngIf="validarInformacionBalance()">
                <div class="card textos-campo">
                    <h5 class="card-header list-group-item cuadro-color">Pasivos</h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of informacionBalance">
                                                <ng-container *ngIf="data.calculado">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                                    <th class="border-0 py-1 fw-bolder"></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0"></th>
                                            <ng-container *ngFor="let item of informacionBalance">
                                                <th class="border-0 fw-bolder" *ngIf="!(item.calculado) || !(item.calculado?.moneda) || item.calculado?.moneda.toUpperCase() === 'PESOS'">Monto en $</th>
                                                <th class="border-0 fw-bolder" *ngIf="item.calculado?.moneda && item.calculado?.moneda.toUpperCase() === 'DOLARES'">Monto en US $</th>
                                                <th class="border-0 fw-bolder">Valor %</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of headerResumenPasivos">
                                            <tr>
                                                <th width="30%"><span
                                                        [ngClass]="{'fw-bolder text-uppercase': getStrongTitle(item[0])}">{{balanceReporteFullPasivos[item[0]]}}</span>
                                                </th>
                                                <ng-container *ngFor="let data of informacionBalance">
                                                    <td>
                                                        <span *ngIf="data.calculado">
                                                            {{data.calculado[item[0]] | number: '1.0-0'}}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="data.calculado">
                                                            {{data.calculado[item[1]] | number: '1.0-2'}}
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="break-box">
        <div class="row">
            <!-- TABLA Estado de Resultados -->
            <div class="mt-1 col-sm-12" *ngIf="validarInformacionBalance()">
                <div class="card textos-campo">
                    <h5 class="card-header list-group-item cuadro-color">Estado de Resultados</h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of informacionBalance">
                                                <ng-container *ngIf="data.calculado">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                                    <th class="border-0 py-1 fw-bolder"></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0"></th>
                                            <ng-container *ngFor="let item of informacionBalance">
                                                <th class="border-0 fw-bolder" *ngIf="!(item.calculado) || !(item.calculado?.moneda) || item.calculado?.moneda.toUpperCase() === 'PESOS'">Monto en $</th>
                                                <th class="border-0 fw-bolder" *ngIf="item.calculado?.moneda && item.calculado?.moneda.toUpperCase() === 'DOLARES'">Monto en US $</th>
                                                <th class="border-0 fw-bolder">Valor %</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of headerResumenEstadoResultados">
                                            <tr>
                                                <th width="30%">{{balanceReporteFullEstadoResultados[item[0]]}}</th>
                                                <ng-container *ngFor="let data of informacionBalance">
                                                    <td>
                                                        <span *ngIf="data.calculado">
                                                            {{data.calculado[item[0]] | number: '1.0-0'}}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="data.calculado">
                                                            {{data.calculado[item[1]] | number: '1.0-2'}}
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- TABLA Indicadores Financieros -->

            <div class="mt-1 col-sm-12" *ngIf="validarInformacionBalance()">
                <div class="card textos-campo">
                    <h5 class="card-header list-group-item cuadro-color">Indicadores Financieros</h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of informacionBalance">
                                                <ng-container *ngIf="data.calculado">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of headerResumenIndicadoresFinancieros">
                                            <tr>
                                                <th width="30%">
                                                    <span
                                                        [ngClass]="{'fw-bolder text-uppercase': getStrongTitle(item)}">
                                                        {{balanceReporteFullIndicadoresFinancieros[item]}}
                                                    </span>
                                                </th>
                                                <td *ngFor="let data of informacionBalance"
                                                    [attr.width]="70/informacionBalance.length + '%'">
                                                    <span *ngIf="data.calculado">
                                                        <ng-template #percent>
                                                            {{ (!isNaN(data.calculado[item]) ? data.calculado[item] : '') | number: '1.0-2'}}
                                                        </ng-template>

                                                        <ng-container *ngIf="!getPercents(item); else percent">
                                                            {{data.calculado[item] | number: '1.0-0'}}
                                                        </ng-container>

                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="break-box">
        <div class="row">
            <div class="mt-1 col-12" *ngIf="informacionVentas.length > 0">
                <div class="mb-3" *ngFor="let table of informacionVentas">
                    <div class="card textos-campo">
                        <h5 class="card-header list-group-item cuadro-color">Ventas {{table.date}}</h5>
                        <div class="card-body">
                            <div class="table-responsive my-3">
                                <table class="table table-condensed">
                                    <thead>
                                        <tr>
                                            <th class="border-0" *ngFor="let colunm of headerCarpetaTributaria">
                                                <span class="fw-bolder"> {{ventasPeriodo[colunm]}} </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let value of table.group; let i = index">
                                            <tr>
                                                <td *ngFor="let row of headerCarpetaTributaria; let e = index">
                                                    <ng-template #dateContent>
                                                        <div [ngSwitch]="row">
                                                            <ng-container *ngSwitchCase="'tipoDeclaracion'">
                                                                <span class="text-capitalize"> {{value[row]}}</span>
                                                            </ng-container>

                                                            <ng-container *ngSwitchCase="'fechaPago'">

                                                                {{value[row] | date: 'dd-MM-YYYY'}}
                                                            </ng-container>

                                                            <ng-container *ngSwitchDefault>
                                                                <span class="text-capitalize"> {{value[row] |
                                                                    date:'LLLL'}}</span>
                                                            </ng-container>
                                                        </div>

                                                    </ng-template>

                                                    <ng-container *ngIf="e > 0 && (row !== 'fechaPago' 
                                            && row !== 'tipoDeclaracion'); else dateContent">
                                                        {{value[row] | number:'1.0-2'}}
                                                    </ng-container>

                                                </td>
                                            </tr>
                                        </ng-container>

                                        <tr class='group'>
                                            <td *ngFor="let row of headerCarpetaTributaria; let f = index">
                                                <ng-template #totalRows>
                                                    <ng-container
                                                        *ngIf="row !== 'fechaPago'  && row !== 'tipoDeclaracion'">
                                                        <span class="fw-bolder"> Total </span>
                                                    </ng-container>
                                                </ng-template>
                                                <ng-container
                                                    *ngIf='f > 0 && row !== "fechaPago" && row !== "tipoDeclaracion"; else totalRows'>
                                                    <ng-container *ngFor="let item of table.total">
                                                        {{item[row] | number:'1.0-2'}}
                                                    </ng-container>
                                                </ng-container>
                                            </td>
                                        </tr>
                                        <tr class='group'>
                                            <td *ngFor="let row of headerCarpetaTributaria; let f = index">
                                                <ng-template #totalRows>
                                                    <ng-container
                                                        *ngIf="row !== 'fechaPago' && row !== 'tipoDeclaracion'">
                                                        <span class="fw-bolder"> Promedio </span>
                                                    </ng-container>
                                                </ng-template>
                                                <ng-container *ngIf='f > 0 && row !== "fechaPago"; else totalRows'>
                                                    <ng-template #varPromedio>
                                                        <ng-container *ngFor="let item of table.promedio">
                                                            {{item[row] | number:'1.0-2'}}
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-container
                                                        *ngIf="row !== 'var' && row !== 'porcVentas'; else varPromedio">
                                                        <ng-container *ngFor="let item of table.promedio">
                                                            {{item[row] | number:'1.0-0'}}
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </td>
                                        </tr>
                                        <tr class='group'>
                                            <td *ngFor="let row of headerCarpetaTributaria; let f = index">
                                                <ng-template #totalRows>
                                                    <ng-container
                                                        *ngIf="row !== 'fechaPago' && row !== 'tipoDeclaracion'">
                                                        <span class="fw-bolder"> Proyección </span>
                                                    </ng-container>
                                                </ng-template>
                                                <ng-container *ngIf='f > 0 && row !== "fechaPago"; else totalRows'>
                                                    <ng-template #varProyeccion>
                                                        <ng-container *ngFor="let item of table.proyeccion">
                                                            {{item[row] | number:'1.0-2'}}
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-container
                                                        *ngIf="row !== 'var' && row !== 'porcVentas'; else varProyeccion">
                                                        <ng-container *ngFor="let item of table.proyeccion">
                                                            {{item[row] | number:'1.0-0'}}
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- GRAFICOS -->
    <div class="break-box">
        <div class="row mb-5">
            <!-- Evolución Indicadores de Rentabilidad -->
            <div class="col-md-6" *ngIf="rentabilidadGraph && rentabilidadGraph.serie">
                <app-line-bars [serie]='rentabilidadGraph.serie' [title]='rentabilidadGraph.title'
                    [yAxix]="rentabilidadGraph.yAxix" [categorias]='rentabilidadGraph.categorias'
                    [plotOptions]="rentabilidadGraph.plotOptions"></app-line-bars>
            </div>
            <!-- Evolución Indicadores de Liquidez -->
            <div class="col-md-6" *ngIf="liquidezGraph && liquidezGraph.serie">
                <app-line-bars [serie]='liquidezGraph.serie' [title]='liquidezGraph.title' [yAxix]="liquidezGraph.yAxix"
                    [categorias]='liquidezGraph.categorias' [plotOptions]="liquidezGraph.plotOptions"></app-line-bars>
            </div>
        </div>

        <div class="row mb-5">
            <!-- Evolución Indicadores de Endeudamiento y Cobertura -->
            <div class="col-md-6" *ngIf="coberturaGraph && coberturaGraph.serie">
                <app-line-bars [serie]='coberturaGraph.serie' [title]='coberturaGraph.title'
                    [yAxix]="coberturaGraph.yAxix" [categorias]='coberturaGraph.categorias'
                    [plotOptions]="coberturaGraph.plotOptions"></app-line-bars>
            </div>
            <!-- Evolución Indicadores de eficiencia -->
            <div class="col-md-6" *ngIf="eficienciaGraph && eficienciaGraph.serie">
                <app-line-bars [serie]='eficienciaGraph.serie' [title]='eficienciaGraph.title'
                    [yAxix]="eficienciaGraph.yAxix" [categorias]='eficienciaGraph.categorias'
                    [plotOptions]="eficienciaGraph.plotOptions"></app-line-bars>
            </div>
        </div>
        <div class="row mb-5">
            <!-- Estructura Financiera -->
            <div class="col-12" *ngIf="estructuraFinancieraGraph && estructuraFinancieraGraph.serie">
                <div class="graph-estructura-financiera">
                    <app-line-bars [serie]='estructuraFinancieraGraph.serie' [title]='estructuraFinancieraGraph.title'
                        [yAxix]="estructuraFinancieraGraph.yAxix" [categorias]='estructuraFinancieraGraph.categorias'
                        [plotOptions]="estructuraFinancieraGraph.plotOptions"></app-line-bars>
                </div>
            </div>
        </div>

        <div class="row mb-5">
            <!-- Ventas Netas -->
            <div class="col-md-6" *ngIf="ventasNetasGraph">
                <div class="container-graph">
                    <div [chart]="ventasNetasGraph"></div>
                </div>
            </div>
            <!-- Compras Netas -->
            <div class="col-md-6" *ngIf="comprasNetasGraph">
                <div class="container-graph">
                    <div [chart]="comprasNetasGraph"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="break-box graph-compra-venta">
        <div class="row mb-5">
            <!-- Compras Netas y Ventas Netas -->
            <div class="col-12" *ngIf="comprasVentasNetasGraph">
                <div [chart]="comprasVentasNetasGraph"></div>
            </div>
        </div>
    </div>

    <!-- Errores -->
    <div class="break-box" *ngIf="informacionErrores.length > 0">
        <div class="row">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="card-header-error list-group-item cuadro-color" (click)="clickHeaderError()">
                        Detalle Errores
                        <i class="fa fa-angle-left pull-right mr-2" id="icon-l-error" hidden></i>
                        <i class="fa fa-angle-down pull-right mr-2" id="icon-d-error"></i>
                    </h5>
                    <div class="card-body size-error" id="collapsable-error">
                        <div class="col-12">

                            <ul class="list-group list-group-light">
                                <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center my-2 list-group-error" *ngFor="let item of informacionErrores">
                                  <div>
                                    <div class="fw-bold"><b>{{ item.tipo || '' }}</b></div>
                                    <div class="text-muted">{{ item.message || '' }}</div>
                                    <div class="text-muted"><b>Mes:</b>{{ item.mes }} <b>Periodo:</b> {{item.periodo}}</div>
                                  </div>
                                  <span class="badge rounded-pill badge-danger" *ngIf="item.error">Error</span>
                                </li>
                              </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>