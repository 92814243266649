import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
//import { rutValidate, RutValidator } from 'ng9-rut';
import { rutValidate } from 'rut-helpers';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-solicitud-credito-consultar-workflow',
  templateUrl: './solicitud-credito-consultar-workflow.component.html',
  styleUrls: ['./solicitud-credito-consultar-workflow.component.scss']
})
export class SolicitudCreditoConsultarWorkflowComponent implements OnInit, OnDestroy {
  
  public activateRutHelper = false;
  private access: string[] = [
    ESystemAccess.FLUJO_CREDITO_BUSCAR, 
    ESystemProfileList.WEB, 
    ESystemAccess.FLUJO_CREDITO_BUSCAR_ADMIN,
    ESystemAccess.FLUJO_CREDITO,
    ESystemAccess.FLUJO_CREDITO_ADMIN
  ];
  public hasUserAccess = false;
  public hasInformeAccess: boolean = false;
  public solicitudCredito: boolean = false;
  public rut: string = '';
  private subscriptions: Subscription[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private _sessionService: SessionService,
    private fb: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title) {

  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);

    if ((this._sessionService.getUserAccess().includes(this.access[0]) || 
          this._sessionService.getUserAccess().includes(this.access[2]) || 
          this._sessionService.getUserAccess().includes(this.access[3]) ||
          this._sessionService.getUserAccess().includes(this.access[4]) )
        && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }

  }

  public closeSolicitudCredito(): void {
    this.solicitudCredito = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
