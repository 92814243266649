import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { BoletinConcursalService } from 'src/app/shared/services/boletin-concursal.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { IBoletinConcursalCreateReportResponse } from 'src/app/interface/IBoletinConcursalCreateReportResponse';
import { RUT } from 'src/app/shared/utils/rut';
import { PdfGeneratorService } from 'src/app/shared/services/pdf-generator.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

interface BoletinConcursalReportFormValues {
  rut: string;
}
@Component({
  selector: 'app-boletin-consursal-consultar',
  templateUrl: './boletin-consursal-consultar.component.html',
  styleUrls: ['./boletin-consursal-consultar.component.scss']
})
export class BoletinConcursalConsultarComponent implements OnInit {
  public activateRutHelper = false;
  // TODO: I need to update the system access for this view
  private access: string[] = [ESystemAccess.REPORTE_BOLETINCONCURSAL, ESystemProfileList.WEB];
  public hasUserAccess = false;

  public boletinConcursalReportForm: UntypedFormGroup;
  public boletinConcursalReportDataSource: IBoletinConcursalCreateReportResponse | null = null;
  public showReport = false;

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private _boletinConcursalService: BoletinConcursalService,
    private _sessionService: SessionService,
    private _generatePDFService: PdfGeneratorService,
    private formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {
    this.boletinConcursalReportForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]]
    }) as FormGroupTyped<BoletinConcursalReportFormValues>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
  }

  onChanges(): void {
    this.boletinConcursalReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  public createReport(): void {
    const { rut } = this.boletinConcursalReportForm.value;

    if (!rutValidate(rut)) {
      return;
    }

    this.spinner.show();
    this._boletinConcursalService.createReport(RUT.applyBackendFormat(rut))
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (Object.keys(data).length === 0) {
            this.alertService.warn("Rut sin Información");
          } else {
            this.boletinConcursalReportDataSource = data;
            this.clearForm();
            this.showReport = true;
          }
        },
        ({ error }) => {
          this.alertService.error(error.message);
          this.spinner.hide();
        }
      )
  }  

  public async downloadReportSelected(): Promise<void> {
    const transactionId = this.boletinConcursalReportDataSource?.boletinConcursalReporte.datosBasicosSolicitud.IDTransaccion || '';
    const tipoReporte = 'boletinConcursal';

    const contentPDF = await this._generatePDFService.downloadReportSelected(transactionId, tipoReporte);
    if (!contentPDF) {
      this.alertService.warn("No se pudo descargar el archivo PDF");
      this.spinner.hide();
      return;
    }

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', contentPDF.URLReporte);
    link.setAttribute('download', `file.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public closeReport(): void {
    this.boletinConcursalReportDataSource = null;
    this.showReport = true;
  }

  public clearForm(): void {
    this.boletinConcursalReportForm.reset();
    this.activateRutHelper = false;
  }

}
