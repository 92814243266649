import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SessionService } from './session.service';


@Injectable({
  providedIn: 'root'
})
export class TesoreriaService {
  private servicio: string = environment.backend.tesoreria.servicio;
  private endpoint: string = environment.backend.tesoreria.url;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService,
  ) { }

  buscarReportes(rut: string): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("rut", rut);
    params = params.append("requestOrigin", "WEB");
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "listarReportes");
    return this.http.get<any>(`${this.endpoint}obtener`, { params });
  }

  buscarTX(idTransaccion: string): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("id_transaccion", idTransaccion);
    params = params.append("requestOrigin", "WEB");
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "buscarTX");
    return this.http.get<any>(`${this.endpoint}obtener`, { params });
  }

  crearReporte(rut: string, claveSii: string = ''): Observable<any> {
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      claveSii: claveSii,
      servicio: this.servicio,
      uri: "crearReporte",
    }
    return this.http.post<any>(`${this.endpoint}crear`, payload);
  }
  
}
