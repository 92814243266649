import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-template-detail-modal',
  templateUrl: './template-detail-modal.component.html',
  styleUrls: ['./template-detail-modal.component.scss']
})
export class TemplateDetailModalComponent {
  
  
  constructor(
    public dialogRef: MatDialogRef<TemplateDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  onClose() {
    this.dialogRef.close();
  }
}
