import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reporte-licitacion-view',
  templateUrl: './reporte-licitacion-view.component.html',
  styleUrls: ['./reporte-licitacion-view.component.scss']
})
export class ReporteLicitacionViewComponent implements OnInit {
  @Input() dataLicitacion: any = {};
  @Input() codigoLicitacion: string = '';
  public objectKeys = Object.keys;
  constructor() { }

  ngOnInit(): void {
    //console.log("this.dataLicitacion", this.dataLicitacion)
    /*if(this.dataLicitacion && Object.keys(this.dataLicitacion).length > 0){

    }*/
  }

  validaEstructura(obj: any): boolean {
    if(obj?.Cantidad && obj?.Listado && obj?.Listado.length > 0){
      return true;
    }
    return false;
  }

  esObjeto(obj: any): boolean {
    // Verifica que no sea null, que sea un objeto y que no sea un array
    return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
  }

  esArray(arr: any): boolean {
    return Array.isArray(arr);
  }

}
