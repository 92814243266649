import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { SessionService } from 'src/app/shared/services/session.service';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';

@Component({
  selector: 'app-reporte-panel-riesgo',
  templateUrl: './reporte-panel-riesgo.component.html',
  styleUrls: ['./reporte-panel-riesgo.component.scss']
})
export class ReportePanelRiesgoComponent implements OnInit {
  public url: string = environment.backend.quicksightPanelRiesgo;
  public hasUserAccess = false;
  //private access: string[] = [ESystemAccess.REPORTE_MALLA_SOCIETARIA_BUSCAR, ESystemProfileList.WEB];
  constructor(
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private _sessionService: SessionService
    ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    /*if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }*/
    const organization: string = this._sessionService.getOrganization();
    if (organization && (organization === 'DESARROLLO' || organization === 'SOPORTE')) {
      this.hasUserAccess = true;
    }
    
    //this.hasUserAccess = true;
  }

}
