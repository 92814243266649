import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SolicitudCreditoServiceWorkflow } from 'src/app/shared/services/solicitud-credito-workflow.service';
import { AlertService } from 'src/app/components/_alert';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-modulo-archivos',
  templateUrl: './modulo-archivos.component.html',
  styleUrls: ['./modulo-archivos.component.scss']
})
export class ModuloArchivosComponent implements OnInit {
  @Input() closed: boolean = true;
  @Input() idTransaccion: string = '';
  @Input() rut: string = '';
  public listaArchivos: any[] = [];
  public listaArchivosDelete: string[] = [];
  public verModulo: boolean = false;
  public messages: { text: string, isError: boolean, isSuccess: boolean }[] = [];

  public fileName: boolean = false;
  public formulario!: UntypedFormGroup;
  private tempFile!: any;
  private archivoName: string = '';

  constructor(
    public alertService: AlertService,
    private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private spinner: NgxSpinnerService,
    private utilsService: UtilsService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.obtenerLista(true);
    if(!this.closed){
      this.initForm()
    }
  }

  initForm(): void {
    this.formulario = this.formBuilder.group({
      file: [null]
    })
  }

  cambiarVerModulo(): void {
    this.verModulo = !this.verModulo;
  }

  validarTipoArchivo(type: string, name: string): boolean {
    if (type && name) {
      const extension = name?.split('.')?.pop()?.toLowerCase() || '';
      switch (extension) {
        case 'xlsx':
          if (type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return false;
          } else {
            return true;
          }
        case 'pdf':
          if (type !== 'application/pdf') {
            return false;
          } else {
            return true;
          }
        case 'xls':
          if (type !== 'application/vnd.ms-excel') {
            return false;
          } else {
            return true;
          }
        case 'csv':
          if (type !== 'text/csv' && type !== 'application/vnd.ms-excel') {
            return false;
          } else {
            return true;
          }
        case 'txt':
          if (type !== 'text/plain') {
            return false;
          } else {
            return true;
          }
        case 'png':
          if (type !== 'image/png') {
            return false;
          } else {
            return true;
          }
        case 'jpeg':
        case 'jpg':
          if (type !== 'image/jpeg') {
            return false;
          } else {
            return true;
          }
        default:
          return false;
      }
    }
    return false;
  }

  handleUpload(event: any) {
    const file = event.target.files[0];

    if(!file && !file?.type && !file?.name) {
      return;
    }

    if(!this.validarTipoArchivo(file?.type, file?.name)) {
      this.addMessage('Solicitud de Crédito - La extensión del archivo es inválida', true, false);
      this.tempFile = {};
      this.archivoName = '';
      return;
    }
    
    if(file.size <= 5000000){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.tempFile = reader?.result?.toString().split(',')[1];
      };
      this.archivoName = file.name || '';
    } else {
      this.addMessage('Solicitud de Crédito - El tamaño maximo del archivo debe ser de 5mb', true, false);
      this.tempFile = {};
      this.archivoName = '';
    }
  }

  submit() {
    const contenidoArchivo = this.tempFile.toString();
    if(contenidoArchivo && this.archivoName) {
      this.guardarArchivo(this.archivoName, contenidoArchivo);
    } else {
      this.addMessage('Solicitud de Crédito - No se logró recuperar el archivo o es inválido', true, false);
      return;
    }
  }

  obtenerLista(inicial: boolean = false): void {
    if(!inicial){
      this.spinner.show();
    }
    this.solicitudCreditoService.listarArchivosAdjuntos(this.idTransaccion, this.rut).subscribe(resp => {
      this.listaArchivos = resp || [];
      if(!inicial){
        this.spinner.hide();
      }
    }, (error: any) => {
      this.addMessage(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los archivos adjuntos', true, false);
      if(!inicial){
        this.spinner.hide();
      }
    });
  }

  agregarBorrarArchivo(event: any, nombre: string): void {
    if(event?.target?.checked === true) {
      const elemento = this.listaArchivosDelete.find(e => nombre && e === nombre);
      if(!elemento) {
        this.listaArchivosDelete.push(nombre);
      }
    }

    if(event?.target?.checked === false) {
      const index = this.listaArchivosDelete.findIndex(e => nombre && e === nombre);
      if(index >= 0) {
        this.listaArchivosDelete.splice(index, 1);
      }
    }
  }

  borrarListado(): void {
    if(this.listaArchivosDelete.length > 0){
      this.spinner.show();
      this.solicitudCreditoService.borrarArchivoAdjunto(this.idTransaccion, this.rut, this.listaArchivosDelete).subscribe(resp => {
        this.addMessage(resp?.message || 'Solicitud de Credito - Archivo(s) borrado(s) exitosamente', false, true);
        this.spinner.hide();
        this.listaArchivosDelete = [];
        this.obtenerLista();
      }, (error: any) => {
        this.addMessage(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al borrar los archivos seleccionados', true, false);
        this.spinner.hide();
        this.obtenerLista();
      });
    }
  }

  guardarArchivo(nombre: string, archivo: string): void {
    if(nombre && archivo){
      this.spinner.show();
      this.solicitudCreditoService.guardarArchivoAdjunto(this.idTransaccion, this.rut, nombre, archivo).subscribe(resp => {
        this.addMessage(resp?.message || 'Solicitud de Credito - Archivo cargado exitosamente', false, true);
        this.spinner.hide();
        this.obtenerLista();
      }, (error: any) => {
        this.addMessage(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al intentar cargar el archivo', true, false);
        this.spinner.hide();
        this.obtenerLista();
      });
    }
  }

  addMessage(mensaje: string, isError: boolean, isSuccess: boolean) : void {
    this.messages.push({ text: mensaje, isError: isError, isSuccess: isSuccess });
  }

  removeMessage(index: number) {
    this.messages.splice(index, 1);
  }

}
