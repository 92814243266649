import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { IBoletinConcursalCreateReportResponse } from 'src/app/interface/IBoletinConcursalCreateReportResponse';
import { IEmpresaEnUnDiaCreateReportResponse } from 'src/app/interface/IEmpresaEnUnDiaCreateReportResponse';
import { IPoderJudicialGetReportReponse } from 'src/app/interface/IPoderJudicialGetReportReponse';
import { IReportBienesRaices } from 'src/app/models/bienes-raices/bienes-raices.model';
import { IReporteBasico } from 'src/app/models/reporte-buscar/reporte-buscar.model';
import { ICarpetaTributariaElectronicaReporteModel } from 'src/app/models/reporte-full/reporte-full.model';
import { IReporteVehiculos } from 'src/app/models/vehiculos/vehiculos.model';
import { IReporteConsolidado } from 'src/app/pages/reporte-consolidado/reporte-consolidado.component';
import { RUT } from 'src/app/shared/utils/rut';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReporteService } from 'src/app/shared/services/reporte.service';
import { ReporteJudicialService } from 'src/app/shared/services/reporte-judicial.service';
import { BoletinConcursalService } from 'src/app/shared/services/boletin-concursal.service';
import { SbifLeasingService } from 'src/app/shared/services/sbif-leasing.service';
import { ReporteFullService } from 'src/app/shared/services/reporte-full.service';
import { BienesRaicesService } from 'src/app/shared/services/bienes-raices.service';
import { VehiculosService } from 'src/app/shared/services/vehiculos.service';
import { EmpresaEnUnDiaService } from 'src/app/shared/services/empresa-en-un-dia.service';
import { AlertService } from '../_alert';
import { catchError, delay, map, retryWhen, switchMap, take } from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';
import { AntecedentesConsolidadoComponent } from './antecedentes-consolidado/antecedentes-consolidado.component';
import { CredencialesService } from 'src/app/shared/services/credenciales.service';

@Component({
  selector: 'app-reporte-consolidado-view',
  templateUrl: './reporte-consolidado-view.component.html',
  styleUrls: ['./reporte-consolidado-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReporteConsolidadoViewComponent implements OnInit {
  
  @ViewChild('antecedentesConsolidadoComponent') antecedentesConsolidadoComponent!: AntecedentesConsolidadoComponent;
  @Output() updateReporteGeneral = new EventEmitter<IReporteConsolidado[]>();
  @Output() showReporteGeneral = new EventEmitter<boolean>();
  @Input() rut: string = '';
  @Input() informeComercial: string = '';
  public hasError: boolean = false;
  public reporteConsolidado: IReporteConsolidado[] | any = [];

  // Variables para el reporte basico
  public objReporteBasico: IReporteBasico | null = null;

  // Variables para el reporte judicial
  public objReporteJudicial: IPoderJudicialGetReportReponse | null = null;

  // Variables para el reporte boletin concursal
  public objReporteBoletinConcursal: IBoletinConcursalCreateReportResponse | null = null;

  // Variables para el reporte deuda sbif
  public objReporteDeudaSbif: any | null = null;

  // Variables para el reporte financiero
  public objReporteFinanciero: ICarpetaTributariaElectronicaReporteModel | null = null;

  // Variables para el reporte bienes raices
  public objReporteBienesRaices: IReportBienesRaices | null = null;

  // Variables para el reporte vehiculos
  public objReporteVehiculos: IReporteVehiculos | null = null;

  // Variables para el reporte empresa en un dia
  public objReporteEmpresaDia: IEmpresaEnUnDiaCreateReportResponse | null = null;
  public showAllContent: boolean = false;
  public objectKeys = Object.keys;
  public vendor: any = {display: '', key: ''};

  constructor(
    private reporteService: ReporteService,
    private reporteJudicialService: ReporteJudicialService,
    private boletinConcursalService: BoletinConcursalService,
    private sbifLeasingService: SbifLeasingService,
    private reporteFullService: ReporteFullService,
    private bienesRaicesService: BienesRaicesService,
    private vehiculosService: VehiculosService,
    private empresaEnUnDiaService: EmpresaEnUnDiaService,
    private alertService: AlertService,
    private _credencialesService: CredencialesService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

  setReporteConsolidado(reporte: IReporteConsolidado[]): void {
    this.reporteConsolidado = reporte;
    this.hasError = false;
    this.vendor = {display: '', key: ''};
    this.updateAllReports();

    if(reporte.find((e: IReporteConsolidado) => e.id === 'reporteBasico' && (e.update || e.obj === null)) && this.informeComercial === '11'){
      this.spinner.show();
      this._credencialesService.buscarSecretosInformeComercial()
      .subscribe(
        (data) => {
          if(this.validarTipoRut(this.rut.replace("-", ""))){
            this.validacionSecretos('empresa', data?.services || []);
          } else {
            this.validacionSecretos('persona', data?.services || []);
          }
          this.spinner.hide();
          if(this.vendor.key !== '') {
            const indiceBasico = this.reporteConsolidado.findIndex((e: IReporteConsolidado) => e.id === 'reporteBasico');
            if(indiceBasico >= 0){
              this.reporteConsolidado[indiceBasico].vendor = this.vendor?.display || '';
            }
            this.llamadaServicios(reporte);
          } else {
            this.updateReporteGeneral.emit(this.reporteConsolidado);
            this.showReporteGeneral.emit(false);
            this.hasError = true;
            return;
          }
        },
        ({ error }) => {
          this.alertService.error(error?.error?.message || error?.message || 'Ha ocurrido un error inesperado al consultar las credenciales configuradas');
          this.spinner.hide();
          this.updateReporteGeneral.emit(this.reporteConsolidado);
          this.showReporteGeneral.emit(false);
          this.hasError = true;
          return;
        }
      )
    } else {
      this.llamadaServicios(reporte);
    }

  }

  async llamadaServicios(reporte: IReporteConsolidado[]): Promise<void> {
    let apiServices: any = [];
    this.reporteConsolidado = reporte;
    this.hasError = false;
    this.updateAllReports();

    for await (const element of reporte) {
      if (element.update || element.obj === null) {
        if (element.id !== 'reporteFinanciero' && element.id !== 'empresaDia')
          apiServices.push(this.getServices(element.id))
      }
    }

    if (apiServices.length > 0) {
      this.spinner.show();
      forkJoin(apiServices).subscribe(() => {
        this.spinner.hide();
        if(this.reporteConsolidado.find((e: IReporteConsolidado) => e.error !== '' && e.obj === null)){
          this.updateReporteGeneral.emit(this.reporteConsolidado);
          this.showReporteGeneral.emit(false);
          this.hasError = true;
        } else {
          this.updateReporteConsolidado()
        }
      });
    }
  }

  getServices(service: string): any {
    const retryTime = 28000;
    const timesRetry = 2;
    const objeto: any = {
      'reporteBasico': () => {
        const tipoOperacion = this.informeComercial || '10';
        let count = 0;
        return this.reporteService.createReport(this.rut, tipoOperacion, undefined, tipoOperacion === '11' ? this.vendor.key : undefined).pipe(
          retryWhen(errors => errors.pipe(delay(retryTime), take(timesRetry),
            switchMap(err => {
              if (count < 1) {
                count++;
                return of(null);
              }
              return throwError(err);
            })))).pipe(
              map((resp) => {
                this.setResponse(service, resp);
                this.reporteFullService.reporteBasicoUpdateObjSubject.next({
                  report: 'reporteBasico',
                  obj: resp
                });
              })
            )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Error Inesperado'), of(null))));
      },
      'reporteFinanciero': () => {
        // opcion no habilitada
      },
      'bienesRaices': () => {
        let count = 0;
        return this.bienesRaicesService.createReport(this.rut).pipe(
          retryWhen(errors => errors.pipe(delay(retryTime), take(timesRetry),
            switchMap(err => {
              if (count < 1) {
                count++;
                return of(null);
              }
              return throwError(err);
            })))).pipe(
              map(resp => {
                this.setResponse(service, resp);
              })
            )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Error Inesperado'), of(null))));
      },
      'vehiculos': () => {
        let count = 0;
        return this.vehiculosService.createReport(this.rut)
          .pipe(
            retryWhen(errors => errors.pipe(delay(retryTime), take(timesRetry),
              switchMap(err => {
                if (count < 1) {
                  count++;
                  return of(null);
                }
                return throwError(err);
              }))))
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Error Inesperado'), of(null))));
      },
      'empresaDia': () => {
        // opcion no habilitada
      },
      'boletinConcursal': () => {
        let count = 0;
        return this.boletinConcursalService.createReport(this.rut)
          .pipe(
            retryWhen(errors => errors.pipe(delay(retryTime), take(timesRetry),
              switchMap(err => {
                if (count < 1) {
                  count++;
                  return of(null);
                }
                return throwError(err);
              }))))
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
              this.reporteFullService.reporteBasicoUpdateObjSubject.next({
                report: 'boletinConcursal',
                obj: resp
              });
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Error Inesperado'), of(null))));
      },
      'deudaSbif': () => {
        let count = 0;
        return this.sbifLeasingService.createReport(this.rut)
          .pipe(
            retryWhen(errors => errors.pipe(delay(retryTime), take(timesRetry),
              switchMap(err => {
                if (count < 1) {
                  count++;
                  return of(null);
                }
                return throwError(err);
              }))))
          .pipe(
            map((resp: any) => {
              this.setResponse(service, resp?.DeudaSistemaFinancieroReporte);
              this.reporteFullService.reporteBasicoUpdateObjSubject.next({
                report: 'deudaSbif',
                obj: resp?.DeudaSistemaFinancieroReporte
              });
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Error Inesperado'), of(null))));
      },
      'reporteJudicial': () => {
        return this.reporteJudicialService.createReport(this.rut, 'true').pipe(
          map(resp => {
            this.setResponse(service, resp);
            this.reporteFullService.reporteBasicoUpdateObjSubject.next({
              report: 'reporteJudicial',
              obj: resp
            });
          })
        )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Error Inesperado'), of(null))));
      }
    };
    return objeto[service]();
  }

  updateReporteConsolidado(): void {
    this.updateReporteGeneral.emit(this.reporteConsolidado);
    this.updateAllReports();
  }

  setError(reporte: string, error: string): void {
    this.reporteConsolidado.find((e: IReporteConsolidado) => e.id === reporte).error = error;
    this.reporteConsolidado.find((e: IReporteConsolidado) => e.id === reporte).obj = null;
  }

  setResponse(service: string, response: any): void {
    this.reporteConsolidado.find((e: IReporteConsolidado) => e.id === service).error = '';
    this.reporteConsolidado.find((e: IReporteConsolidado) => e.id === service).obj = response;
  }

 async updateAllReports(): Promise<any> {
  this.objReporteBasico = null;
  this.objReporteFinanciero = null;
  this.objReporteBienesRaices = null;
  this.objReporteVehiculos = null;
  this.objReporteEmpresaDia = null;
  this.objReporteBoletinConcursal = null;
  this.objReporteDeudaSbif = null;
  this.objReporteJudicial = null;

    this.showAllContent = false;
    const keyValues = [
      'reporteBasico',
      'reporteFinanciero',
      'bienesRaices',
      'vehiculos',
      'empresaDia',
      'boletinConcursal',
      'deudaSbif',
      'reporteJudicial'
    ];

    const objeto: any = {
      'reporteBasico': (obj: IReporteBasico) => {
        this.objReporteBasico = obj;
      },
      'reporteFinanciero': (obj: ICarpetaTributariaElectronicaReporteModel) => {
        this.objReporteFinanciero = obj;
      },
      'bienesRaices': (obj: IReportBienesRaices) => {
        this.objReporteBienesRaices = obj;
      },
      'vehiculos': (obj: IReporteVehiculos) => {
        this.objReporteVehiculos = obj;
      },
      'empresaDia': (obj: IEmpresaEnUnDiaCreateReportResponse) => {
        this.objReporteEmpresaDia = obj;
      },
      'boletinConcursal': (obj: IBoletinConcursalCreateReportResponse) => {
        this.objReporteBoletinConcursal = obj;
      },
      'deudaSbif': (obj: any) => {
        this.objReporteDeudaSbif = obj;//?.DeudaSistemaFinancieroReporte;

        if (this.antecedentesConsolidadoComponent) {
          this.antecedentesConsolidadoComponent.obtenerLastDeudaSbif();
        }     
      },
      'reporteJudicial': (obj: IPoderJudicialGetReportReponse) => {
        this.objReporteJudicial = obj;
      }
    };
        
    for await (const element of keyValues) {
      const objTemp = this.reporteConsolidado.find((e: any) => e.id === element);
      objTemp ? objeto[element](objTemp.obj) : false;
    }
    this.showAllContent = true;
  }

  obtenerActualizaciones(): void {
    if (this.antecedentesConsolidadoComponent) {
      this.antecedentesConsolidadoComponent.obtenerLastDeudaSbif();
    }  
  }

  private validarTipoRut(rut: string) { // true es empresa, false es natural
    const body = rut.slice(0, -1);
    if (Number(body) > 50000000) {
      return true;
    }
    return false;
  }

  validacionSecretos(tipo: string, services: any[]) {
    if(services && services.length > 0) {
      const servicesPosibles = ["INFORME_COMERCIAL_TRANSUNION", "MALLA_SOCIETARIA_TRANSUNION", "ICOM_COBRANZA_ONLINE"];
      if(tipo === 'empresa') {
        servicesPosibles.push("ICOM_EQUIFAX");
      } else {
        servicesPosibles.push("PLAT_EQUIFAX");
      }

      const servicesFiltrados = services.filter((service: any) => servicesPosibles.includes(service?.name) && service?.configurationStatus === true);
      if(!(servicesFiltrados && servicesFiltrados.length > 0)){
        this.vendor = {display: '', key: ''};
        this.alertService.error("No posee proveedores de informe comercial definidos, para configurar ir a Configuraciones → Administración de Credenciales → Organizacion, luego de esto se debe desmarcar el checkbox de Informe Comercial");
        return;
      }

      if(servicesFiltrados.length === 1 && servicesFiltrados[0].name === servicesPosibles[3]) {
        this.vendor = {display: servicesFiltrados[0].displayName, key: servicesFiltrados[0].name};

      } else if (servicesFiltrados.length === 2 && servicesFiltrados.some(e => e.name === servicesPosibles[0]) && servicesFiltrados.some(e => e.name === servicesPosibles[1])) { // INFORME_COMERCIAL_TRANSUNION
        const informeComercialTransunion = servicesFiltrados.find((service: any) => service.name === servicesPosibles[0]);
        this.vendor = {display: informeComercialTransunion.displayName, key: informeComercialTransunion.name};

      } else if (servicesFiltrados.length === 2 && servicesFiltrados.some(e => e.name === servicesPosibles[2]) && servicesFiltrados.some(e => e.name === servicesPosibles[1])) { // ICOM_COBRANZA_ONLINE
        const informeComercialTransunion = servicesFiltrados.find((service: any) => service.name === servicesPosibles[2]);
        this.vendor = {display: informeComercialTransunion.displayName, key: informeComercialTransunion.name};

      } else if (servicesFiltrados.length === 2 && 
        (servicesFiltrados.some(e => e.name === servicesPosibles[3])) && 
        (servicesFiltrados.some(e => e.name === servicesPosibles[0] || e.name === servicesPosibles[1] || e.name === servicesPosibles[2]))
      ) {
          
        const informeComercial = servicesFiltrados.find((service: any) => (service.name === servicesPosibles[3]));
        this.vendor = {display: informeComercial.displayName, key: informeComercial.name};

      } else {

        this.vendor = {display: '', key: ''};
        if(servicesFiltrados.some(e => e.name === servicesPosibles[3])){
          this.alertService.error("Posee mas de 1 proveedor configurado, ir a modulo Configuraciones → Administración de Credenciales → Organizacion y dejar solo 1 activo");
        } else if (servicesFiltrados.some(e => e.name === servicesPosibles[0]) && servicesFiltrados.some(e => e.name === servicesPosibles[1]) && servicesFiltrados.some(e => e.name === servicesPosibles[2])) {
          this.alertService.error("Posee mas de 1 proveedor configurado, ir a modulo Configuraciones → Administración de Credenciales → Organizacion y dejar solo 1 activo");
        } else if (servicesFiltrados.some(e => e.name === servicesPosibles[0]) || servicesFiltrados.some(e => e.name === servicesPosibles[1]) || servicesFiltrados.some(e => e.name === servicesPosibles[2])) {
          this.alertService.error("No posee proveedores de informe comercial definidos, para configurar ir a Configuraciones → Administración de Credenciales → Organizacion, luego de esto se debe desmarcar el checkbox de Informe Comercial");
        } else {
          this.alertService.error("Posee mas de 1 proveedor configurado, ir a modulo Configuraciones → Administración de Credenciales → Organizacion y dejar solo 1 activo");
        }
        
      }

    } else {
      this.vendor = {display: '', key: ''};
      this.alertService.error("No posee proveedores de informe comercial definidos, para configurar ir a Configuraciones → Administración de Credenciales → Organizacion, luego de esto se debe desmarcar el checkbox de Informe Comercial");
    }
  }
}
