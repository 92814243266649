import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService, Spinner } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { listadoReport } from 'src/app/models/utils/utils.model';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';
import { environment as env } from 'src/environments/environment';
import { AlertService } from '../../_alert';

@Component({
  selector: 'app-malla-societaria',
  templateUrl: './malla-societaria.component.html',
  styleUrls: ['./malla-societaria.component.scss']
})
export class MallaSocietariaComponent implements OnInit, OnDestroy {

  @Input() resumen: boolean = false;
  @Input() informe: any = [];
  @Input() statusTX: string = '';
  public data = ['Jul-21', 'Jun-21', '202108', '202109'];
  public dataSocios: any[] = [];
  public dataEmpresa: any[] = [];
  public showDicom: boolean = false;
  public showReport: boolean = false;
  public showReportCompleto: boolean = false;
  public informeSbif: any[] = [];
  public informeLeasing: any[] = [];
  public informeSocios: any[] = [];
  public subscriptions: Subscription[] = [];
  public listadoReport: any = listadoReport;
  public objectKeys = Object.keys;
  public listTest: any[] = [];
  public currentPage = 0;
  public currentItem = env.initItemPerPage;

  constructor(private solicitudCreditoService: SolicitudCreditoService,
    private spinner: NgxSpinnerService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.setDeudas(this.informe);
    this.setSocios(this.informe);
    if (this.resumen) {
      this.setInformeSbif(this.informe);
      this.setInformeLeasign(this.informe);
    }
  }

  setDeudas(informe: any): void {
    this.subscriptions.push(this.solicitudCreditoService.getInformeDeudas(informe).subscribe(resp => {
      this.dataEmpresa = resp;
    }));
  }

  setInformeSbif(informe: any): void {
    this.informeSbif = this.solicitudCreditoService.setInformeSbif(informe);
  }

  setInformeLeasign(informe: any): void {
    if (this.statusTX !== 'CLOSED') {
      const keyValues = ['NumerodeAcreedores', 'PlazoPromedio(Mes)'];
      const result: any = [];
      const data = informe.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Leasing?.Reporte?.Deudas;
      const dataResult = data ? this.solicitudCreditoService.agruparObjetosByKey(data, 'Item') : [];
      const periodos = data ? [...new Set(data.map((item: any) => item.Periodo))] : [];

      if (periodos?.length > 0 && Object.keys(dataResult)?.length > 0) {
        result.push({
          periodos: periodos,
          data: dataResult
        });
      }

      if (result.length > 0) {
        for (const item of Object.keys(result[0].data)) {
          for (const key of result[0].data[item]) {
            const periodo = this.solicitudCreditoService.obtenerPeriodo(key.Periodo)
            this.spinner.show();
            keyValues.includes(key.Item) ? key.valorMiles = key.Valor :
              this.subscriptions.push(this.solicitudCreditoService.calcularUF(periodo).subscribe(async resp => {
                this.spinner.hide();
                key.valorMiles = resp ? resp.valores[0].valor * key.Valor : 0;
              }, (error: any) => {
                this.spinner.hide();
                this.alertService.error(error.error.message || 'Ocurrio un Error');
              }));
          }
        }
      }
      this.informeLeasing = result;
      this.solicitudCreditoService.ResumenObjSubject.next({ data: { leasing: this.informeLeasing }, origin: 'leasing' })
    } else {
      const objetoLeasingReduce = this.informe?.SolicitudDeCredito?.TablasInformesCalculados?.datosCalculados || [];
      this.informeLeasing = objetoLeasingReduce.length > 0 ? objetoLeasingReduce.find((e: any) => Object.keys(e)[0] === 'leasing')?.leasing || [] : [];
    }
  }

  obtenerLastValorDeudaSbif(key: string, property: string, segments?: string[]): string {
    let objTemp: any[] = [];
    const objDeudaSbif = this.informe?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Sbif;
    if (segments) {
      if (objDeudaSbif) {
        objTemp = objDeudaSbif?.Reporte?.[[segments[0]][0]]?.[[segments[1]][0]]?.filter(
          (e: any) => e.Item === key);
      }
    }

    if (objDeudaSbif) {
      let obj = this.solicitudCreditoService.obtenerUltimoObj({
        [key]: !segments ? this.informe?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Sbif?.Reporte?.[key] : objTemp
      });

      return obj[0][0][key][property] || '';
    }
    return '';
  }

  obtenerFecha() {
    return this.obtenerLastValorDeudaSbif('LineasDeCreditoDisponible', 'Periodo') || '';
  }

  setNumber(num: string): number {
    return Number(num);
  }

  setSocios(informe: any): void {
    this.solicitudCreditoService.orderDataCarpetaTributaria(informe).subscribe(resp => {
      if (resp.length > 0) {
        this.informeSocios = resp[0].sociosRepresentante?.find((e: any) => e.tipoPersona === 'socios').data || [];
      }
    });
  }

  filterDataLeasing(data: any, field: string, periodo: string): string {
    return data[field].find((e: any) => e.Periodo === periodo && e.Item === field).Valor || '';
  }

  filterDataLeasingClp(data: any, field: string, periodo: string): string {
    return data[field].find((e: any) => e.Periodo === periodo && e.Item === field).valorMiles || '';
  }

  sumDataLeasing(data: any, periodo: string, property: string): number {
    const keyValues = ['TotalDeudaDirecta', 'TotalDeudaIndirecta'];
    let sum = 0;
    keyValues.forEach((el: string) => {
      sum += Number(data[el].find((e: any) => e.Periodo === periodo)?.[property]) || 0;
    })
    return sum;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
