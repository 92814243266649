<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>
<div *ngIf="hasUserAccess" class="container__form">
    <div class="col-md-8">
        <div class="block">
            <div class="form-horizontal form-bordered">
                <form [formGroup]="userCreateForm" novalidate>
                    <div class="form-group">
                        <label class="col-md-3 control-label">Username:</label>
                        <div class="col-md-9">
                            <input formControlName="username" type="text" class="form-control" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-md-3 control-label">Nombre:</label>
                        <div class="col-md-9">
                            <input formControlName="firstName" type="text" class="form-control" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-md-3 control-label">Apellido:</label>
                        <div class="col-md-9">
                            <input formControlName="givenName" type="text" class="form-control col-md-3" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-md-3 control-label">Teléfono:</label>
                        <div class="col-md-9">
                            <input formControlName="phoneNumber" type="text" class="form-control col-md-3" required>
                        </div>
                    </div>
                </form>
                <div class="form-group" *ngIf="isLoaded">
                    <div class="row">
                        <label class="col-md-3 control-label ml-3">Permisos :</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-sm-12 col-lg-8 mt-4">
                            <div class="table-resposive px-3">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="font-size-table" scope="col" rowspan="2">Nombre</th>
                                            <th class="font-size-table text-center" scope="col">Crear</th>
                                            <th class="font-size-table text-center" scope="col">Buscar</th>
                                            <th class="font-size-table text-center" scope="col">Administrar</th>
                                        </tr>
                                        <tr>
                                            <th class="font-size-table text-center" scope="col">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        Seleccionar Todo
                                                    </div>
                                                    <div class="col-sm-12 mt-1">
                                                        <input #checkboxestodos type="checkbox" (click)="selectAll($event, 'Crear')">
                                                    </div>
                                                </div>
                                            </th>
                                            <th class="font-size-table text-center" scope="col">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        Seleccionar Todo
                                                    </div>
                                                    <div class="col-sm-12 mt-1">
                                                        <input #checkboxestodos type="checkbox" (click)="selectAll($event, 'Buscar')">
                                                    </div>
                                                </div>
                                            </th>
                                            <th class="font-size-table text-center" scope="col">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        Seleccionar Todo
                                                    </div>
                                                    <div class="col-sm-12 mt-1">
                                                        <input #checkboxestodos type="checkbox" (click)="selectAll($event, 'Administrar')">
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let groupItem of accessGroups; let i = index">
                                            <td class="font-size-table">{{groupItem.display}}</td>
                                            <td class="text-center align-middle">
                                                <ng-container *ngFor="let accessItem of groupItem.accesos; let j = index">
                                                    <input type="checkbox" *ngIf="accessItem.type === 'Crear'" [(ngModel)]="accessGroups[i].accesos[j].active">
                                                </ng-container>
                                            </td>
                                            <td class="text-center align-middle">
                                                <ng-container *ngFor="let accessItem of groupItem.accesos; let j = index">
                                                    <input type="checkbox" *ngIf="accessItem.type === 'Buscar'" [(ngModel)]="accessGroups[i].accesos[j].active">
                                                </ng-container>
                                            </td>
                                            <td class="text-center align-middle">
                                                <ng-container *ngFor="let accessItem of groupItem.accesos; let j = index">
                                                    <input type="checkbox" *ngIf="accessItem.type === 'Administrar'" [(ngModel)]="accessGroups[i].accesos[j].active">
                                                </ng-container>
                                            </td>                                    
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group form-actions container__actions">
                    <!-- <div class="col-md-9 col-md-offset-3"> -->
                    <button type="button" (click)="goBack()" class="btn btn-sm btn-default">Volver</button>
                    <button type="submit" class="btn btn-sm btn-primary" (click)="editUser()"><i
                            class="fa fa-angle-right formulario"></i> Guardar</button>
                    <button type="button" (click)="clearForm()" class="btn btn-sm btn-warning"><i
                            class="fa fa-repeat formulario"></i>
                        Limpiar</button>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <div>