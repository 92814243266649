<div class="container">
    <!-- Table Header -->
    <div class="row">
        <div class="col-12">
          <div class="row align-items-center" style="width: 100%;">
              <!-- Button on the left -->
              <div class="col-auto">
                <button class="btn btn-primary" (click)="back()">Volver</button>
              </div>
          
              <!-- Spacer for centering the h2 -->
              <div class="col text-center">
                <h2>Administración de procesos</h2>
              </div>
          </div>
        </div>
      </div>

      <!-- Card Table Info -->
    <div class="row mt-5" *ngIf="detail">
        <div class="col-12 d-flex justify-content-center">
          <div class="table-responsive" style="max-width: 316px;">
            <table class="table table-bordered bg-white">
              <tbody>
                <tr>
                  <th class="bg-primary">ID Cartera</th>
                  <td>{{detail.id}}</td>
                </tr>
                <tr>
                  <th class="bg-primary">Nombre Cartera</th>
                  <td>{{detail.name}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

<div class="row mt-5">
    <div class="col-12">
      <h3>Procesos Asignados</h3>
      <table class="table table-bordered bg-white" *ngIf="batchConfigs.length > 0">
        <thead>
          <tr class="bg-primary">
            <th>Modelo</th>
            <th>Frecuencia</th>
            <!-- <th>Query</th> -->
            <th>Destino</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let process of paginatedProcesses">
            <td>{{ process?.batchTemplate?.name ? process?.batchTemplate?.name : process?.batchTemplate?.tag }}</td>
            <td>{{ process?.frequency?.description ? process?.frequency?.description : process?.frequency?.name }}</td>
            <!-- <td>{{ process?.mapper?.name }}</td> -->
            <td>{{ process?.destination?.type }}</td>
            <td class="text-center">
              <!-- <button class="btn btn-danger" (click)="removeCartera(cartera.id)"> -->
                <i class="fa fa-trash text-danger" style="cursor: pointer;" (click)="removeConfig(process)"></i>
            <!-- </button> -->
            </td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="batchConfigs.length < 1">
        <p class="text-indo">No se encuentran proceso asociados a esta cartera</p>
      </div>

      <!-- Pagination Controls -->
      <nav aria-label="Process pagination" *ngIf="batchConfigs.length > 0">
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="prevPage()" aria-label="Previous">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages">
            <a class="page-link" (click)="nextPage()" aria-label="Next">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>


  <div class="row mt-5">

    <div class="col-12" *ngIf="showAssign">
          <h3>Asignar Proceso</h3>

            <!-- stepper.component.html -->
            <div class="stepper-container">
                <mat-horizontal-stepper [linear]="true" [selectedIndex]="stepperIndex" #stepper>
                <mat-step label="Paso 1" [stepControl]="modeloControl">
                    <div class="col-12 d-flex justify-content-center mt-3">
                        <h3><strong>Modelo</strong></h3>
                    </div>
                    <div class="col-12 d-flex justify-content-center mt-3">
                        <mat-radio-group [(ngModel)]="modeloSelected" class="d-flex flex-column" [formControl]="modeloControl" >
                            <mat-radio-button *ngFor="let modelo of modelos" [value]="modelo">{{modelo.batchTemplate?.name}} <i class="fa fa-info-circle text-info" aria-hidden="true" style="cursor: pointer;" (click)="openTemplateDetail(modelo)"></i></mat-radio-button>
                          </mat-radio-group>
                    </div>
                    <div class="col-12 d-flex justify-content-center mt-3">
                        <button *ngIf="modeloSelected" mat-button matStepperNext class="btn btn-primary" (click)="setFrequencies()">Next</button>
                    </div>
                </mat-step>
                <mat-step label="Paso 2" [editable]="false" [stepControl]="frecuenciaControl">
                    <div class="col-12 d-flex justify-content-center">
                        <h3><strong>Frecuencia</strong></h3>
                    </div>
                    <div class="col-12 d-flex justify-content-center mt-3">
                        <mat-radio-group [(ngModel)]="frecuenciaSelected" class="d-flex flex-column" [formControl]="frecuenciaControl">
                            <mat-radio-button *ngFor="let frecuencia of frecuencias" [value]="frecuencia">{{frecuencia.description ? frecuencia.description : frecuencia.name}}</mat-radio-button>
                          </mat-radio-group>
                    </div>
                    <div class="col-12 d-flex justify-content-center mt-3">
                        <button mat-button matStepperPrevious class="btn btn-secondary">Back</button>
                        <button *ngIf="frecuenciaSelected" mat-button matStepperNext class="btn btn-primary ml-3">Next</button>
                    </div>
                </mat-step>
                <mat-step label="Paso 3" [editable]="false" [stepControl]="queryControl">
                    <div class="col-12 d-flex justify-content-center">
                        <h3><strong>Formato Extracción Resultados</strong></h3>
                    </div>
                    <div class="col-12 d-flex justify-content-center mt-3">
                        <mat-radio-group [(ngModel)]="querySelected" class="d-flex flex-column" [formControl]="queryControl">
                            <mat-radio-button *ngFor="let query of queries" [value]="query">{{query.name}} <i class="fa fa-info-circle text-info" aria-hidden="true" style="cursor: pointer;" (click)="openQueryDetail(query.id)"></i></mat-radio-button>
                            <div *ngIf="queries.length == 0">No hay queries disponibles para este modelo</div>
                          </mat-radio-group>
                    </div>
                    <div class="col-12 d-flex justify-content-center mt-3">
                        <button mat-button matStepperPrevious class="btn btn-secondary">Back</button>
                        <button *ngIf="querySelected" matStepperNext  mat-button (click)="asignConfig()" class="btn btn-primary ml-3">Finish</button>
                    </div>
                </mat-step>
                <!-- <mat-step label="Paso 4">
                    <div class="col-12 d-flex justify-content-center">
                        <h3><strong>Destino</strong></h3>
                    </div>
                    <div class="col-12 d-flex justify-content-center mt-3">
                        <mat-radio-group [(ngModel)]="destinoSelected" class="d-flex flex-column">
                            <mat-radio-button *ngFor="let destino of destinos" [value]="destino">{{destino.type}}</mat-radio-button>
                          </mat-radio-group>
                    </div>
                    <div class="col-12 d-flex justify-content-center mt-3">
                        <button mat-button matStepperPrevious class="btn btn-secondary">Back</button>
                        <button *ngIf="destinoSelected" matStepperNext  mat-button (click)="asignConfig()" class="btn btn-primary ml-3">Finish</button>
                    </div>
                </mat-step> -->
                <mat-step label="Fin" [editable]="false">

                  <div *ngIf="isAsigning">
                    <div class="col-12 d-flex justify-content-center mt-3">
                      <h3>Asignando proceso ...</h3>
                    </div>
                  </div>

                  <div *ngIf="!isAsigning">
                    <div class="col-12 d-flex justify-content-center mt-3">
                      <i style="font-size: 145px;" class="fa fa-check-circle-o text-primary" aria-hidden="true"></i>
                    </div>
                    <div class="col-12 d-flex justify-content-center mt-3">
                      <h3>Proceso Asignado correctamente</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-center mt-3">
                      <button class="btn btn-primary" (click)="restartStepper()">Asignar otro proceso</button>
                    </div>
                  </div>
                </mat-step>
                </mat-horizontal-stepper>
            </div>
  
    </div>
  </div>
</div>  