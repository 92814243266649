import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BackendAuthService } from './backend-auth.service';
import { SessionService } from './session.service';
import { IPoderJudicialCreateReportResponse } from 'src/app/interface/IPoderJudicialCreateReportResponse';
import { IPoderJudicialSearchReportResponse } from 'src/app/interface/IPoderJudicialSearchReportResponse';
import { IPoderJudicialGetReportReponse } from 'src/app/interface/IPoderJudicialGetReportReponse';

interface ICreateReport {
  rut: string;
  idUsuario: string;
  idOrganizacion: string;
  requestOrigin: string;
  servicio: string;
  uri: string;
  tipo: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ReporteJudicialService {
  private endpoint: string = environment.backend.poderJudicial.url;
  private servicio: string = environment.backend.poderJudicial.servicio;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService,
    private _backendAuthService: BackendAuthService
  ) { }

  public createReport(rut: string, tipo: string): Observable<IPoderJudicialCreateReportResponse | null> {

    const payload: ICreateReport = {
      rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: 'poderJudicial',
      tipo: !tipo? ['DDO.']: ['DDO.', 'DTE.']
    };

    return this.http.post<IPoderJudicialCreateReportResponse>(this.endpoint + "crear", payload);
  }

  public getReport(idTransaccion: string): Observable<IPoderJudicialGetReportReponse> {

    let params = new HttpParams();
    params = params.append("id_transaccion", idTransaccion);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarTXPoderJudicial');
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');

    return this.http.get<IPoderJudicialGetReportReponse>(this.endpoint + "obtener", { params });
  }

  public searchReportsList(rut: string): Observable<IPoderJudicialSearchReportResponse[]> {

    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarPoderJudicial');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');

    return this.http.get<IPoderJudicialSearchReportResponse[]>(this.endpoint + "obtener", { params });
  }

  judicialReportLastUpdated(rut: string): Observable<IPoderJudicialGetReportReponse> {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'poderJudicialSearchUpdated');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    return this.http.get<IPoderJudicialGetReportReponse>(`${this.endpoint}obtener`, { params });
  }
}
