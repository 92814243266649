<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<div class="col-sm-12 mt-5" *ngIf="hasUserAccess">
    <div class="row d-flex justify-content-center">

        <div class="col-sm-12 col-lg-4">
            <div class="card my-4 rounded">
                <div class="card-header bg-primary">
                    <div class="col-12 p-1">
                        <div class="row">
                            <div class="col-5">
                                <h4>Usuarios</h4>
                            </div>
                            <div class="col-7">
                                <i type="button" class="fa fa-search float-right" aria-hidden="true" *ngIf="!showSearch1" (click)="showSearch1 = true"></i>
                                <div class="input-group" *ngIf="showSearch1">
                                        <input id="table-1-search" type="text" class="form-control" name="searchTerm"
                                        title="Ingrese texto para filtrar usuarios"
                                        placeholder="Filtrar usuarios"
                                        [(ngModel)]="inputText1" 
                                        (keyup)="filtrarObjetoNoAsignado()"
                                    >
                                    <button 
                                        class="btn btn-outline-primary" 
                                        type="button" 
                                        (click)="clearInput1()"
                                    >
                                        <i class="fa fa-times-circle text-white" aria-hidden="true"></i>
                                    </button>                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 px-0">
                    <table class="table table-bordered mb-0">
                        <tbody>
                            <tr>
                                <td class="font-size-table">
                                    <div class="form-check form-check-inline p-2">
                                        <input class="form-check-input cursor-pointer mr-4" 
                                                type="checkbox"
                                                (click)="seleccionarTodos($event, 'noAsignados')"
                                                id="seleccionarTodosNoAsignados"
                                            >
                                        <label class="form-check-label">Seleccionar Todos</label>

                                    </div>
                                    <div class="float-right cursor-pointer mr-2" title="Ordenar usuarios" (click)="cambioOrderNoAsignado()">
                                        <i class="fa fa-long-arrow-up mr-1" aria-hidden="true"></i>
                                        <i class="fa fa-long-arrow-down" aria-hidden="true"></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 px-0 overflow-auto max-height mb-0">
                    <div class="table-resposive">
                        <table class="table table-bordered">
                            <tbody>
                                <tr *ngFor="let usuario of listadoUsuariosNoAsignadosFiltrado; let i = index">
                                    <td class="font-size-table cursor-pointer bg-hover" (click)="checkNoAsignado(i)">
                                        <div class="form-check form-check-inline p-2">
                                            <input class="form-check-input mx-4" 
                                                type="checkbox"
                                                [(ngModel)]="listadoUsuariosNoAsignadosFiltrado[i].checked"
                                            >
                                            <label class="form-check-label font-weight-normal cursor-pointer">{{usuario.usuario}}</label>
                                        </div>                                                            
                                    </td>                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-lg-2 mt-5">
            <div class="row">
                <div class="col-12 d-flex align-items-center">
                    <button type="button" class="btn btn-success mx-auto" (click)="agregar()">Agregar <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                </div>
                <div class="col-12 d-flex align-items-center">
                    <button type="button" class="btn btn-danger mx-auto" (click)="quitar()"><i class="fa fa-arrow-left" aria-hidden="true"></i> Quitar</button>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-lg-4">
            <div class="card my-4 rounded">
                <div class="card-header bg-primary">
                    <div class="col-12 p-1">
                        <div class="row">
                            <div class="col-5">
                                <h4>Usuarios Asignados</h4>
                            </div>
                            <div class="col-7">
                                <i type="button" class="fa fa-search float-right" aria-hidden="true" *ngIf="!showSearch2" (click)="showSearch2 = true"></i>
                                <div class="input-group" *ngIf="showSearch2">
                                        <input id="table-1-search" type="text" class="form-control" name="searchTerm"
                                        title="Ingrese texto para filtrar usuarios"
                                        placeholder="Filtrar usuarios"
                                        [(ngModel)]="inputText2" 
                                        (keyup)="filtrarObjetoAsignado()"
                                    >
                                    <button 
                                        class="btn btn-outline-primary" 
                                        type="button" 
                                        (click)="clearInput2()"
                                    >
                                        <i class="fa fa-times-circle text-white" aria-hidden="true"></i>
                                    </button>                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 px-0">
                    <table class="table table-bordered mb-0">
                        <tbody>
                            <tr>
                                <td class="font-size-table">
                                    <div class="form-check form-check-inline p-2">
                                        <input class="form-check-input cursor-pointer mr-4" 
                                                type="checkbox"
                                                (click)="seleccionarTodos($event, 'asignados')"
                                                id="seleccionarTodosAsignados"
                                            >
                                        <label class="form-check-label">Seleccionar Todos</label>
                                    </div>
                                    <div class="float-right cursor-pointer mr-2" title="Ordenar usuarios" (click)="cambioOrderAsignado()">
                                        <i class="fa fa-long-arrow-up mr-1" aria-hidden="true"></i>
                                        <i class="fa fa-long-arrow-down" aria-hidden="true"></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 px-0 overflow-auto max-height mb-0">
                    <div class="table-resposive">
                        <table class="table table-bordered">
                            <tbody>
                                <tr *ngFor="let usuario of listadoUsuariosAsignadosFiltrado; let i = index">
                                    <td class="font-size-table cursor-pointer bg-hover" (click)="checkAsignado(i)">
                                        <div class="form-check form-check-inline p-2">
                                            <input class="form-check-input mx-4" 
                                                type="checkbox"
                                                [(ngModel)]="listadoUsuariosAsignadosFiltrado[i].checked"
                                            >
                                            <label class="form-check-label font-weight-normal cursor-pointer">{{usuario.usuario}}</label>
                                        </div>
                                    </td>                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
