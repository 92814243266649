<div class="card shadow-lg  mt-3">
    <h5 class="card-header list-group-item cuadro-color"
        (click)="clickHeader()"
    >
        Configurar Consultas
        <i class="fa fa-angle-left pull-right mr-2" id="icon-left-card" hidden></i>
        <i class="fa fa-angle-down pull-right mr-2" id="icon-down-card"></i>
    </h5>
    <div class="card-body p-5" id="collapsable-card-body">
        <div class="row">
            <div class="col-sm-12 mb-3">
                <div class="px-4">
                    <span class="float-right mx-1 text-center">
                        Actualizar
                        <br>
                        Todos
                    </span>
                    <span class="float-right mx-2 text-center">
                        Buscar
                        <br>
                        Todos
                    </span>
                </div>
            </div>

            <div class="col-sm-12 mb-3">
                <div class="px-4">
                    <label class="switch switch-primary float-right mx-2">
                        <input #checkboxesactualizartodo class="checkbox-actualizar-todos" type="checkbox" id="checkbox-actualizar-todos"
                            (click)="actualizarTodos($event)"
                        >
                        <span></span>
                    </label>
                    <label class="switch switch-primary float-right mx-2">
                        <input #checkboxesbuscartodo class="checkbox-buscar-todos" type="checkbox" id="checkbox-buscar-todos"
                            (click)="buscarTodos($event)"
                        >
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 mb-3">
                <div class="container-servicios p-0 overflow-auto">
                    <ul class="list-group list-group-servicios">
                        <ng-container *ngFor="let servicio of serviciosDisponibles; let servicioiIndex = index">
                            <ng-container *ngIf="servicio.display">
                                <li  [ngClass]="{'active': selectedItemKey === servicio.key && objectKeys(selectedItem).length > 0 && existenVariablesEditables(selectedItemKey, indexServicioSelected)}"
                                    class="list-group-item list-group-item-action"
                                    >
                                    <div class="row">
                                        <div class="col-sm-7">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <i class="fa fa-angle-right pull-left mr-3 mt-2 curser-pointer" *ngIf="!(selectedItemKey === servicio.key && objectKeys(selectedItem).length > 0 && existenVariablesEditables(selectedItemKey, indexServicioSelected))" 
                                                        (click)='mostrarOcultarServicio(servicio.key)'></i>
                                                    <i class="fa fa-angle-down pull-left mr-3 mt-2" *ngIf="selectedItemKey === servicio.key && objectKeys(selectedItem).length > 0 && existenVariablesEditables(selectedItemKey, indexServicioSelected)" 
                                                        (click)="mostrarOcultarServicio(servicio.key)"></i>
                                                    <h5 class="d-inline font-weight-bold curser-pointer" (click)='mostrarOcultarServicio(servicio.key)'>
                                                        {{servicio.name}}
                                                    </h5>
                                                </div>
                                                <div class="col-sm-12">
                                                    <h5 class="font-italic ml-3">{{obtenerDescripcion(servicio.key) || ''}}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-5">

                                            <label class="switch switch-primary float-right min-height-switch mx-2" title="Actualizar reporte">
                                                <ng-container *ngIf="validaTypeConsulta('crear', servicio?.values)">
                                                    <input #checkboxesactualizar class="checkbox-actualizar" type="checkbox" id="checkboxactualizar-{{servicio.key}}"
                                                        (click)="actualizarServicio($event, servicio.key, servicioiIndex)"
                                                    >
                                                    <span></span>
                                                </ng-container>
                                            </label>

                                            <label class="switch switch-primary float-right min-height-switch mx-2" title="Buscar reporte">
                                                <ng-container *ngIf="validaTypeConsulta('buscarHistorico', servicio?.values)">
                                                    <input #checkboxesbuscar class="checkbox-buscar" type="checkbox" id="checkboxbuscar-{{servicio.key}}"
                                                        (click)="buscarServicio($event, servicio.key, servicioiIndex);"
                                                    >
                                                    <span></span>
                                                </ng-container>
                                            </label>

                                            <span class="badge badge-pill badge-warning float-right mx-2" *ngIf="validaObligatorio(servicio.key)">Obligatorio</span>

                                            <ng-container *ngIf="validarServiciosAEnviar(servicio.key)">
                                                <i class="fa fa-check-circle fa-lg text-success float-right mx-2" aria-hidden="true" *ngIf="validarEstadoCampoPrincipal(servicio.key)" title="Datos de entrada completos"></i>
                                                <span class="badge badge-pill badge-danger text-truncate float-right mx-2" *ngIf="!validarEstadoCampoPrincipal(servicio.key)" title="Completar datos de entrada">Completar Datos</span>
                                            </ng-container>

                                            <ng-container *ngIf="validarServiciosAEnviar(servicio.key)">

                                                <ng-container [ngSwitch]="validarEstadoSincronizacion(servicio.key)">
                                                    <ng-container *ngSwitchCase="'trueNoOrg'">
                                                        <ng-container *ngIf="validaPermisosUser(); else elsePermisosUser">
                                                            <span class="badge badge-pill badge-success text-truncate curser-pointer float-right mx-2" (click)="modalCredencialesLista(servicio.key)">Enrolado</span>
                                                        </ng-container>
    
                                                        <ng-template #elsePermisosUser>
                                                            <span class="badge badge-pill badge-success text-truncate curser-pointer float-right mx-2" title="Credenciales sincronizadas">Enrolado</span>
                                                        </ng-template>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'falseNoOrg'">
                                                        <ng-container *ngIf="validaPermisosUser(); else elsePermisosUser">
                                                            <span class="badge badge-pill badge-danger text-truncate curser-pointer float-right mx-2" (click)="modalCredencialesLista(servicio.key)">No Enrolado</span>
                                                        </ng-container>
    
                                                        <ng-template #elsePermisosUser>
                                                            <span class="badge badge-pill badge-danger text-truncate curser-pointer float-right mx-2" title="Credenciales no configuradas, favor tome contacto con el administrador">No Enrolado</span>
                                                        </ng-template>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'trueOrg'">
                                                        <span class="badge badge-pill badge-success text-truncate curser-pointer float-right mx-2" title="Credenciales sincronizadas">Enrolado</span>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'falseOrg'">
                                                        <span class="badge badge-pill badge-danger text-truncate curser-pointer float-right mx-2" title="Proveedor no configurado, para configurar ir a sección: Configuraciones → Administración de Credenciales → Organizacion">No Enrolado</span>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                    
                                                    </ng-container>
                                                </ng-container>
                                                
                                            </ng-container>

                                        </div>
                                    </div>
                                </li>
    
                                <div class="card p-3 card-formulario" *ngIf="selectedItemKey === servicio.key && objectKeys(selectedItem).length > 0 && existenVariablesEditables(selectedItemKey, indexServicioSelected)">
                                    <div class="card-body">
                                    
                                        <select class="form-control my-3" id="select-{{servicio.key}}" 
                                            [ngClass]="{'d-none': !visibilidadSelect(servicio)}"
                                            (change)="onChangeSelect($event, servicio)">
                                            <option value="">
                                                Seleccione...
                                            </option>
                                            <ng-container *ngFor="let element of servicio.values; let i = index">
                                                <ng-container *ngIf="selectedItem.type === element.type">
                                                    <option value="{{i}}"
                                                        [selected]="i == serviciosBodySave[selectedItemKey]?.index"
                                                    >
                                                        {{element.name}}
                                                    </option>
                                                </ng-container>
                                            </ng-container>
                                        </select>
                                        
                                        <form (ngSubmit)="onSubmit()" [formGroup]="servicioForm" *ngIf="objectKeys(selectedItem).length > 0">
                                            <p>{{selectedItem.description || ''}}</p>
            
                                            <ng-template #sinVariables>
                                                <div class="col-12">
                                                    No se necesitan datos de entrada.
                                                </div>
                                            </ng-template>

                                            <ng-container *ngIf="selectedItem?.infoVariables && selectedItem?.infoVariables?.passSyncStatus?.length > 0">

                                                <div class="row">
                                                    <ng-container *ngFor="let item of selectedItem?.infoVariables?.passSyncStatus">

                                                        <div class="form-group col-sm-12 col-md-6 my-4">

                                                            <label>{{item.displayName}}</label>
                                                            

                                                            <ng-container *ngIf="item?.type === 'RUT'">

                                                                <ng-container *ngIf="validaPermisosUser(); else elsePermisosUser">

                                                                    <span class="badge badge-danger curser-pointer ml-2" (click)="modalCredenciales(item)" *ngIf="item.status === false">Inactivo</span>

                                                                    <span class="badge badge-success curser-pointer ml-2" (click)="modalCredenciales(item)" *ngIf="item.status === true">Activo</span>

                                                                </ng-container>
                                                                <ng-template #elsePermisosUser>

                                                                    <span class="badge badge-danger curser-pointer ml-2" title="Credenciales no configuradas, favor tome contacto con el administrador" *ngIf="item.status === false">Inactivo</span>

                                                                    <span class="badge badge-success curser-pointer ml-2" title="Para configurar credenciales, favor tome contacto con el administrador" *ngIf="item.status === true">Activo</span>

                                                                
                                                                </ng-template>

                                                            </ng-container>

                                                            <ng-container *ngIf="item?.type === 'CRED.ORG'">

                                                                <span class="badge badge-danger curser-pointer ml-2" *ngIf="item.status === false">Inactivo</span>

                                                                <span class="badge badge-success curser-pointer ml-2" *ngIf="item.status === true">Activo</span>
                                                                
                                                            </ng-container>
                                                            
                                                        </div>                                                   

                                                    </ng-container>
                                                </div>

                                            </ng-container>
            
                                            <ng-container *ngIf="existenVariablesEditables(selectedItemKey, indexServicioSelected); else sinVariables">
                                                <div class="row">

                                                    <ng-template #balanceManualTemplate>

                                                        <ng-container *ngFor="let item of selectedItem.inputVars">

                                                            <ng-container *ngIf="!item.section || item?.section === ''">

                                                                <div class="form-group col-sm-12 col-md-6" *ngIf="(item.display && (item.typeAttribute === 'String' || item.typeAttribute === 'Number' || item.typeAttribute === 'Password' || item.typeAttribute === 'Date') && objectKeys(item.allowedValues).length === 0 && item.displayDependency === '') || (validaDependencia(item) && item.display && (item.typeAttribute === 'String' || item.typeAttribute === 'Number' || item.typeAttribute === 'Password' || item.typeAttribute === 'Date') && objectKeys(item.allowedValues).length === 0)">
                                                                    <label>{{item.displayName}}</label>
                                                                    <input type="{{item.typeAttribute === 'String' ? 'text' : item.typeAttribute}}" class="form-control" [formControlName]="item.key">
                                                                    <div class="w-100">
                                                                        <app-error [control]="servicioForm.get([item.key])"></app-error>
                                                                    </div>
                                                                </div>
                            
                                                                <div class="form-group col-sm-12 col-md-6" *ngIf="(item.display && (item.typeAttribute === 'String' || item.typeAttribute === 'Number' || item.typeAttribute === '[]') && objectKeys(item.allowedValues).length > 0 && item.displayDependency === '') || (item.display && (item.typeAttribute === 'String' || item.typeAttribute === 'Number' || item.typeAttribute === '[]') && objectKeys(item.allowedValues).length > 0 && validaDependencia(item))">
                                                                    <label>{{item.displayName}}</label>
                                                                    <select class="form-control text-capitalize"
                                                                        [formControlName]="item.key">
                                                                        <option value="" selected>Seleccione...</option>
                                                                        <ng-container *ngFor="let elemento of objectKeys(item.allowedValues); let j = index">
                                                                            <option value="" class="text-capitalize"
                                                                                [attr.selected]="item.defaultValue === elemento ? true : null"
                                                                                [value]="item.allowedValues[elemento]"
                                                                                [attr.type]="item.typeAttribute === 'String' ? 'text' : item.typeAttribute">
                                                                                {{elemento}}
                                                                            </option>
                                                                        </ng-container>
                                                                    </select>
                                                                    <div class="w-100">
                                                                        <app-error [control]="servicioForm.get([item.key])"></app-error>
                                                                    </div>
                                                                </div>
                        
                                                                <div class="form-group col-sm-12 col-md-6" *ngIf="(item.display && item.typeAttribute === 'base64' && objectKeys(item.allowedValues).length === 0 && item.displayDependency === '') || (item.display && item.typeAttribute === 'base64' && objectKeys(item.allowedValues).length === 0 && validaDependencia(item))">
                                                                    <label class="col-12 pl-0">{{item.displayName}}</label>
                                                                    <div class="col-12 pl-0">
                                                                        <label class="uploadbutton mx-auto">
                                                                            <input class="input-file" id="{{item.key}}" type="file" [formControlName]="item.key"
                                                                                name="{{item.key}}" accept="{{item.file?.inputType}}" (change)="handleUpload($event, servicio.key, item.key)" />
                                                                            <div class="button btn">Archivo
                                                                            </div>
                                                                            <div class='input-file-text'
                                                                            [ngClass]="{'text-success': obtenerNombreArchivo(selectedItemKey, item.key)}">
                                                                                {{ obtenerNombreArchivo(selectedItemKey, item.key) || 'Subir Archivo'}}
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                    <div class="w-100">
                                                                        <app-error [control]="servicioForm.get([item.key])"></app-error>
                                                                    </div>
                                                                </div>

                                                            </ng-container>
                                                            
                                                        </ng-container>
                                                        

                                                        <ng-container *ngFor="let seccion of secciones">

                                                            <div class="col-sm-12 my-2">
                                                                <accordion [isAnimated]="true" class="m-0">
                                                                    <accordion-group [isOpen]="false" panelClass="m-0" #accBalanceManualGroup>
                                                                        <span accordion-heading>
                                                                            <i class="fa" [ngClass]="{'fa-angle-down': accBalanceManualGroup?.isOpen, 'fa-angle-right': !accBalanceManualGroup?.isOpen}"></i>
                                                                            {{seccion.displayName}}
                                                                            <span class="badge badge-info float-right">Total {{seccion.displayName}}: {{obtenerTotalSeccion(seccion.key) | number: '1.0-2'}}</span>
                                                                        </span>


                                                                        <div class="row">
                                                                            <ng-container *ngFor="let item of selectedItem.inputVars">

                                                                                <ng-container *ngIf="item.section === seccion.key && item.section !== ''">

                                                                                    <div class="form-group col-sm-12 col-md-6" *ngIf="(item.display && (item.typeAttribute === 'String' || item.typeAttribute === 'Number' || item.typeAttribute === 'Password' || item.typeAttribute === 'Date') && objectKeys(item.allowedValues).length === 0 && item.displayDependency === '') || (validaDependencia(item) && item.display && (item.typeAttribute === 'String' || item.typeAttribute === 'Number' || item.typeAttribute === 'Password' || item.typeAttribute === 'Date') && objectKeys(item.allowedValues).length === 0)">
                                                                                        <label>{{item.displayName}}</label>
                                                                                        <input type="{{item.typeAttribute === 'String' ? 'text' : item.typeAttribute}}" class="form-control" [formControlName]="item.key">
                                                                                        <div class="w-100">
                                                                                            <app-error [control]="servicioForm.get([item.key])"></app-error>
                                                                                        </div>
                                                                                    </div>
                                                
                                                                                    <div class="form-group col-sm-12 col-md-6" *ngIf="(item.display && (item.typeAttribute === 'String' || item.typeAttribute === 'Number' || item.typeAttribute === '[]') && objectKeys(item.allowedValues).length > 0 && item.displayDependency === '') || (item.display && (item.typeAttribute === 'String' || item.typeAttribute === 'Number' || item.typeAttribute === '[]') && objectKeys(item.allowedValues).length > 0 && validaDependencia(item))">
                                                                                        <label>{{item.displayName}}</label>
                                                                                        <select class="form-control text-capitalize"
                                                                                            [formControlName]="item.key">
                                                                                            <option value="" selected>Seleccione...</option>
                                                                                            <ng-container *ngFor="let elemento of objectKeys(item.allowedValues); let j = index">
                                                                                                <option value="" class="text-capitalize"
                                                                                                    [attr.selected]="item.defaultValue === elemento ? true : null"
                                                                                                    [value]="item.allowedValues[elemento]"
                                                                                                    [attr.type]="item.typeAttribute === 'String' ? 'text' : item.typeAttribute">
                                                                                                    {{elemento}}
                                                                                                </option>
                                                                                            </ng-container>
                                                                                        </select>
                                                                                        <div class="w-100">
                                                                                            <app-error [control]="servicioForm.get([item.key])"></app-error>
                                                                                        </div>
                                                                                    </div>
                                            
                                                                                    <div class="form-group col-sm-12 col-md-6" *ngIf="(item.display && item.typeAttribute === 'base64' && objectKeys(item.allowedValues).length === 0 && item.displayDependency === '') || (item.display && item.typeAttribute === 'base64' && objectKeys(item.allowedValues).length === 0 && validaDependencia(item))">
                                                                                        <label class="col-12 pl-0">{{item.displayName}}</label>
                                                                                        <div class="col-12 pl-0">
                                                                                            <label class="uploadbutton mx-auto">
                                                                                                <input class="input-file" id="{{item.key}}" type="file" [formControlName]="item.key"
                                                                                                    name="{{item.key}}" accept="{{item.file?.inputType}}" (change)="handleUpload($event, servicio.key, item.key)" />
                                                                                                <div class="button btn">Archivo
                                                                                                </div>
                                                                                                <div class='input-file-text'
                                                                                                [ngClass]="{'text-success': obtenerNombreArchivo(selectedItemKey, item.key)}">
                                                                                                    {{ obtenerNombreArchivo(selectedItemKey, item.key) || 'Subir Archivo'}}
                                                                                                </div>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div class="w-100">
                                                                                            <app-error [control]="servicioForm.get([item.key])"></app-error>
                                                                                        </div>
                                                                                    </div>

                                                                                </ng-container>
                                                                                
                                                                            </ng-container>
                                                                        </div>
                                    
                                                                    </accordion-group>
                                                                </accordion>
                                                            </div>

                                                        </ng-container>

                                                    </ng-template>
                                                    

                                                    <ng-container *ngIf="!(servicio.key === 'balanceManual' && selectedItem?.type === 'crear') else balanceManualTemplate">

                                                        <ng-container *ngFor="let item of selectedItem.inputVars">
        
                                                            <div class="form-group col-sm-12 col-md-6" *ngIf="(item.display && (item.typeAttribute === 'String' || item.typeAttribute === 'Number' || item.typeAttribute === 'Password' || item.typeAttribute === 'Date') && objectKeys(item.allowedValues).length === 0 && item.displayDependency === '') || (validaDependencia(item) && item.display && (item.typeAttribute === 'String' || item.typeAttribute === 'Number' || item.typeAttribute === 'Password' || item.typeAttribute === 'Date') && objectKeys(item.allowedValues).length === 0)">
                                                                <label>{{item.displayName}}</label>
                                                                <input type="{{item.typeAttribute === 'String' ? 'text' : item.typeAttribute}}" class="form-control" [formControlName]="item.key">
                                                                <div class="w-100">
                                                                    <app-error [control]="servicioForm.get([item.key])"></app-error>
                                                                </div>
                                                            </div>
                        
                                                            <div class="form-group col-sm-12 col-md-6" *ngIf="(item.display && (item.typeAttribute === 'String' || item.typeAttribute === 'Number' || item.typeAttribute === '[]') && objectKeys(item.allowedValues).length > 0 && item.displayDependency === '') || (item.display && (item.typeAttribute === 'String' || item.typeAttribute === 'Number' || item.typeAttribute === '[]') && objectKeys(item.allowedValues).length > 0 && validaDependencia(item))">
                                                                <label>{{item.displayName}}</label>
                                                                <select class="form-control text-capitalize"
                                                                    [formControlName]="item.key">
                                                                    <option value="" selected>Seleccione...</option>
                                                                    <ng-container *ngFor="let elemento of objectKeys(item.allowedValues); let j = index">
                                                                        <option value="" class="text-capitalize"
                                                                            [attr.selected]="item.defaultValue === elemento ? true : null"
                                                                            [value]="item.allowedValues[elemento]"
                                                                            [attr.type]="item.typeAttribute === 'String' ? 'text' : item.typeAttribute">
                                                                            {{elemento}}
                                                                        </option>
                                                                    </ng-container>
                                                                </select>
                                                                <div class="w-100">
                                                                    <app-error [control]="servicioForm.get([item.key])"></app-error>
                                                                </div>
                                                            </div>
                    
                                                            <div class="form-group col-sm-12 col-md-6" *ngIf="(item.display && item.typeAttribute === 'base64' && objectKeys(item.allowedValues).length === 0 && item.displayDependency === '') || (item.display && item.typeAttribute === 'base64' && objectKeys(item.allowedValues).length === 0 && validaDependencia(item))">
                                                                <label class="col-12 pl-0">{{item.displayName}}</label>
                                                                <div class="col-12 pl-0">
                                                                    <label class="uploadbutton mx-auto">
                                                                        <input class="input-file" id="{{item.key}}" type="file" [formControlName]="item.key"
                                                                            name="{{item.key}}" accept="{{item.file?.inputType}}" (change)="handleUpload($event, servicio.key, item.key)" />
                                                                        <div class="button btn">Archivo
                                                                        </div>
                                                                        <div class='input-file-text'
                                                                        [ngClass]="{'text-success': obtenerNombreArchivo(selectedItemKey, item.key)}">
                                                                            {{ obtenerNombreArchivo(selectedItemKey, item.key) || 'Subir Archivo'}}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div class="w-100">
                                                                    <app-error [control]="servicioForm.get([item.key])"></app-error>
                                                                </div>
                                                            </div>
                    
                                                        </ng-container>

                                                    </ng-container>
                                                    
                                                </div>
            
                                                <div class="row">
                                                    <div class="col-12 text-center">
                                                        <button type="submit" class="btn btn-sm btn-primary">
                                                            Guardar
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </form>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ul>
                </div>
            </div>
            <!-- 
                <div class="col-sm-12 col-md-3 mb-3">
                    <div class="card">
                        <div class="card-body">
                            <h3 class="mb-3 font-weight-bold">Servicios a Consultar</h3>
                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let servicio of serviciosAEnviar">
                                    {{ obtenerNombreServicioFromObject(servicio) || '' }}
                                    <span class="badge badge-pill badge-success text-truncate" *ngIf="validarEstadoCampo(servicio)" title="Ok">Ok</span>
                                    <span class="badge badge-pill badge-danger text-truncate" *ngIf="!validarEstadoCampo(servicio)" title="Completar Datos de entrada">Completar Datos de entrada</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            -->
            <div class="col-12 d-flex justify-content-center mt-3" *ngIf="serviciosAEnviar.length > 0">
                <button class="btn btn-sm btn-primary" (click)="consultarServicios()">
                    Consultar
                </button>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="seeResults">
    <div class="row justify-content-md-center my-5">
        <div class="col-md-12 col-lg-6">
            <div class="card shadow-lg">
                <h5 class="card-header list-group-item cuadro-color">
                    Resultados Consulta
                </h5>
                <div class="card-body">
                    <ul class="list-group">
                        <ng-container *ngFor="let servicio of bodyResults | keyvalue">
                            <li class="list-group-item"
                                id="resultado-{{servicio.key}}"
                                *ngIf="servicio.key !== 'request' && servicioIsVisible(servicio.key)"
                            >
                                {{ obtenerNombreServicioFromResp(servicio) || '' }}
                                <span class="badge badge-info badge-nofloat mx-2" *ngIf="obtenerEstadoRespServicio(servicio) !== 'error' && obtieneFecha(servicio) !== ''">
                                    {{obtieneFecha(servicio) | date: 'dd-MM-YYYY'}}
                                </span>
                                <span class="badge badge-pill badge-warning badge-nofloat"
                                    title="{{obtenerDetalleErrorFromResp(servicio)}}" *ngIf="obtenerEstadoRespServicio(servicio) === 'generacion'">
                                    <i class="fa fa-exclamation" aria-hidden="true"></i>
                                </span>
                                <span class="badge badge-pill badge-warning badge-nofloat"
                                    title="{{obtenerDetalleErrorFromResp(servicio)}}" *ngIf="obtenerEstadoRespServicio(servicio) === 'vacio'">
                                    <i class="fa fa-exclamation" aria-hidden="true"></i>
                                </span>
                                <span class="badge badge-pill badge-success badge-nofloat" *ngIf="obtenerEstadoRespServicio(servicio) === 'ok'">
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                </span>
                                <span class="badge badge-pill badge-danger badge-nofloat" 
                                    title="{{obtenerDetalleErrorFromResp(servicio)}}" *ngIf="obtenerEstadoRespServicio(servicio) === 'error' || obtenerEstadoRespServicio(servicio) === 'error-datos'">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </span>
                                <button class="btn float-right" (click)="borrarServicio(servicio.key)"><i class="fa fa-trash text-danger"></i></button>
                                <button class="btn float-right btn-primary btn-sm mx-4" 
                                    *ngIf="obtenerEstadoRespServicio(servicio) === 'error'" 
                                    (click)="reconsultar(servicio.key)"
                                >
                                    Reconsultar
                                </button>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div class="col-12 d-flex justify-content-center mt-3">
    <button class="btn btn-sm btn-primary" (click)="avanzarFlujo()" [disabled]="!validaErrores()">
        Continuar
    </button>
</div>

<app-modal-credenciales [credencial]="itemCredencialSelected" [rut]="rut" [tipoCredencial]="itemCredencialSelectedType" (actualizaVista)="volverModalCredenciales()"></app-modal-credenciales>