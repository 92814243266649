import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { InactivityService } from 'src/app/shared/services/inactivity.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { UsuariosService } from 'src/app/shared/services/usuarios.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

  constructor(
    public alertService: AlertService,
    private _sessionService: SessionService,
    private _usuarioService: UsuariosService,
    private inactivityService: InactivityService,
    private spinner: NgxSpinnerService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if(!this._sessionService.getOrganization()){
      this.router.navigate(['/organizacion']);
    } else {
      this.spinner.show();
      this._usuarioService.getAccessForUser(this._sessionService.getUserInfo().email)
        .subscribe(
          (data) => {
            this._sessionService.setUserAccess(data.availableAccess);
            this._sessionService.setUSerProfileList(data.profilesList);
            this.spinner.hide();
          },
          ({ error }) => {
            this.alertService.error(error.message);
          }
        );

    }

  }

}
