import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-paginator-by-items',
  templateUrl: './paginator-by-items.component.html',
  styleUrls: ['./paginator-by-items.component.scss']
})
export class PaginatorByItems {

  public items: number[] = env.itemPerPageOptions;
  public currentItem = env.initItemPerPage;
  @Output() itemsPerPage = new EventEmitter<any>();
  @Input() itemsPerPageInput!: any | null;
  @Input() itemsOptions!: any | null;

  ngOnInit(): void {
    if(this.itemsPerPageInput){
      this.currentItem = this.itemsPerPageInput;
    }
    if(this.itemsOptions){
      this.items = this.itemsOptions;
    }
    
  }

  onChange(target: any): void {
    this.currentItem = target.value;
    this.itemsPerPage.emit(target.value);
  }

}
