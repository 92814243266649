import { Component, Input, OnInit } from '@angular/core';
import { MallaSocietariaService } from 'src/app/shared/services/malla-societaria.service';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { from, of, Subscription } from 'rxjs';
import { catchError, filter, map, mergeMap, toArray } from 'rxjs/operators';

import * as Highcharts from "highcharts";
import networkgraph from "highcharts/modules/networkgraph";
networkgraph(Highcharts);
import sankey from "highcharts/modules/sankey";
sankey(Highcharts);
import organization from "highcharts/modules/organization";
organization(Highcharts);
import exporting from "highcharts/modules/exporting";
exporting(Highcharts);
import accessibility from "highcharts/modules/accessibility";
import { ReporteService } from 'src/app/shared/services/reporte.service';
accessibility(Highcharts);


@Component({
  selector: 'app-reporte-malla-societaria',
  templateUrl: './reporte-malla-societaria.component.html',
  styleUrls: ['./reporte-malla-societaria.component.scss']
})
export class ReporteMallaSocietariaComponent implements OnInit {
  // Informacion recibida
  @Input() informacionMalla: any;
  @Input() informacionCliente: any;

  // Indica si se puede presionar un nodo para buscar a los hijos
  public editable: boolean = false;
  
  @Input() statusTX: string = '';

  // Indica si en la informacion que se agrega viene el grafico completo o se debe mapear
  @Input() recibeGrafico: boolean = false;
  @Input() informacionGrafico: any = null;

  private subscriptions: Subscription[] = [];

  constructor(
    private mallaSocietariaService: MallaSocietariaService,
    private alertsService: AlertService,
    private spinner: NgxSpinnerService,
    private solicitudCreditoService: SolicitudCreditoService,
    private reporteBasicoService: ReporteService
  ){

  }

  public optionsGrafico: any = {
    chart: {
      type: "organization",
      height: 350,
      width: 1800,
      inverted: true,
      events: {
        load: function(this: any) {
          const 
            series = this.series[0];
          
          let newHeight = 350;
          let newWidth = 800;

          if(series?.nodeColumns?.[1]?.length > 0){
            newHeight = 350;
            newWidth = series.options.nodeWidth * (series.nodeColumns[1].length  * 1.25);
          }
          
          if(series?.nodeColumns?.[2]?.length > 0){
            newHeight = 500;
            newWidth = series.options.nodeWidth * (series.nodeColumns[2].length * 1.25);
          }
  
          this.update({
            chart: {
              height: newHeight,
              width: newWidth
            }
          });

        }
      },
    },
    title:{
      text:''
    },
    accessibility: {
      typeDescription:
        "Este grafico muestra los socios y sociedades de un rut."
    },
    series: [
      {
        name: "Malla Societaria",
        cursor: "pointer",
        events: {
          click: (event: any) => {
            if(event?.point?.level === 1 && event?.point?.linksFrom?.length === 0 && this.editable === true){
              this.crearHijoMalla(event.point.rut, event.point.id);
            } else if (event?.point?.level === 1 && event?.point?.linksFrom?.length > 0 && this.editable === true){
              this.quitarHijosMalla(event.point.id);
            }
          }
        },
        keys: ["from", "to"],
        data: [],
        levels: [
          {
            level: 0,
            color: "silver",
            borderRadius: 25,
            dataLabels: {
              nodeFormat : `<div class="row">
              <div class="col-sm-12 text-center">{point.nombre}</div>
              <div class="col-sm-12 text-center">{point.displayRut}</div>
              </div>`
            },
          },
          {
            level: 1,
            borderRadius: 25,
            dataLabels: {
              nodeFormat : `<div class="row">
              <div class="col-sm-12 text-center">{point.entidad}</div>
              <div class="col-sm-12 text-center">{point.nombre}</div>
              <div class="col-sm-12 text-center">{point.displayRut}</div>
              </div>`,
              style: {
                fontSize: '10px'
              }
            },
          },
          {
            level: 2,
            borderRadius: 25,
            dataLabels: {
              nodeFormat : `<div class="row">
              <div class="col-sm-12 text-center">{point.entidad}</div>
              <div class="col-sm-12 text-center">{point.nombre}</div>
              <div class="col-sm-12 text-center">{point.displayRut}</div>
              </div>`,
              style: {
                fontSize: '10px'
              }
            },
          }
        ],
        nodes: [
          {
            id: "principal",
            title: null,
            rut: '',
            displayRut: '',
            name: "",
            nombre: "",
            color: "#419dc0",
            info: `Rut Principal`,
            height: 50,
            fechaInicio: '',
            fechaTermino: '',
            sociedadDisueltaPorAbsorcion: ''
          },
        ],
        colorByPoint: false,
        color: "#007ad0",
        borderColor: "white",
        nodeWidth: 140,
      }
    ],
      
    tooltip: {
      outside: true,
      useHTML: true,
      formatter: function (this: any) {
        let texto: string = `<div class="row">`;
        if(this.point.info) {
          texto = texto + `<div class="col-sm-12 text-center">${this.point.info}</div>`;
        }
        if(this.point.fechaInicio) {
          texto = texto + `<div class="col-sm-12 text-center">Inicio Act: ${this.point.fechaInicio}</div>`
        }
        if(this.point.fechaTermino) {
          texto = texto + `<div class="col-sm-12 text-center">Termino Giro: ${this.point.fechaTermino}</div>`
        }
        if(this.point.sociedadDisueltaPorAbsorcion) {
          texto = texto + `<div class="col-sm-12 text-center">Sociedad Disuelta por Absorción: ${this.point.sociedadDisueltaPorAbsorcion}</div>`
        }
        texto = texto + `</div>`;

        return texto;
      }
    },
  
    exporting: {
      allowHTML: true,
      sourceWidth: 1920,
      sourceHeight: 1080
    }
    
  }

  public formatearRUT(rut: string, dv: string) : string {
    let rutFormateado = '';
    // Agregar puntos al RUT
    if(rut !== '' && dv !== ''){
      rutFormateado = rut?.replace(/^(\d{1,3})(\d{3})(\d{3})$/, '$1.$2.$3') || '';
      // Agregar el dígito verificador al RUT formateado
      rutFormateado += "-" + (dv || '');
    }
  
    return rutFormateado;
  }

  quitarHijosMalla(id: string): void {
    if(id){
      const nodos: any[] = this.optionsGrafico?.series?.[0]?.nodes || [];
      const data: any[] = this.optionsGrafico?.series?.[0]?.data || [];

      const updatedNodos = nodos.filter(item => !(item?.id?.includes(id) && item?.id !== id));
      const updatedData = data.filter(item => !(item?.[1]?.includes(id) && item?.[1] !== id));
  
      if(this.optionsGrafico?.series?.[0]?.nodes)
        this.optionsGrafico.series[0].nodes = updatedNodos;
      if(this.optionsGrafico?.series?.[0]?.data)
        this.optionsGrafico.series[0].data = updatedData;

      this.guardarGrafico();
      this.obtenerFechasYRenderizar(this.optionsGrafico, false)
    }
  }

  crearHijoMalla(rut: string, id: string): void {
    this.spinner.show();
    this.subscriptions.push(this.mallaSocietariaService.crearReporte(rut).subscribe(resp => {
      if(resp?.MallaSocietariaReporte?.Reporte?.mallaSocietaria){
        this.updateMalla(resp?.MallaSocietariaReporte?.Reporte?.mallaSocietaria, rut, id);
      } else {
        this.spinner.hide();
      }
    }, (error: any) => {
        this.alertsService.error(error?.error?.message || 'Ocurrio un Error al obtener la malla societaria');
        this.spinner.hide();
    }));
  }

  updateMalla(respMalla: any, rut: string, id: string): void {
    if(respMalla && rut){
      const nodos: any[] = this.optionsGrafico?.series?.[0]?.nodes || [];
      const data: any[] = this.optionsGrafico?.series?.[0]?.data || [];

      if(respMalla?.respuestaMalla?.listadoSocios?.length > 0){
        respMalla?.respuestaMalla?.listadoSocios?.forEach((element: any, index: number) => {
          if(index < 10){
            nodos.push({
              id: (id + '-socio-' + element?.socio?.rut),
              title: null,
              entidad: 'Socio',
              name: (id + '-socio-' + element?.socio?.rut),
              nombre: element?.socio?.nombreRazonSocial || '',
              rut: element?.socio?.rut + "-" + element?.socio?.dv,
              displayRut: this.formatearRUT(element?.socio?.rut, element?.socio?.dv),
              layout: "vertical",
              color: "#d76034",
              height: 60,
              info: `Socio ${element?.socio?.nombreRazonSocial}<br>
              Vinculado a ${rut}`,
              fechaInicio: '',
              fechaTermino: '',
              sociedadDisueltaPorAbsorcion: ''
            });
            data.push([(id), (id + '-socio-' + element?.socio?.rut)]);
          }
        });
      }

      if(respMalla?.respuestaMalla?.listadoParticipaciones?.length > 0){
        respMalla?.respuestaMalla?.listadoParticipaciones?.forEach((element: any, index: number) => {
          if(index < 10){
            nodos.push({
              id: (id + '-sociedad-' + element?.participacion?.rut),
              title: null,
              entidad: 'Sociedad',
              name: (id + '-sociedad-' + element?.participacion?.rut),
              nombre: element?.participacion?.nombreRazonSocial || '',
              rut: element?.participacion?.rut + "-" + element?.participacion?.dv,
              displayRut: this.formatearRUT(element?.participacion?.rut, element?.participacion?.dv),
              layout: "vertical",
              color: "#d734ab",
              height: 60,
              info: `Sociedad ${element?.participacion?.nombreRazonSocial}<br>
              Vinculado a ${rut}`,
              fechaInicio: '',
              fechaTermino: '',
              sociedadDisueltaPorAbsorcion: ''
            });
            data.push([(id), (id + '-sociedad-' + element?.participacion?.rut)]);
          }
        });
      }
  
      if(this.optionsGrafico?.series?.[0]?.nodes)
        this.optionsGrafico.series[0].nodes = nodos;
      if(this.optionsGrafico?.series?.[0]?.data)
        this.optionsGrafico.series[0].data = data;

      this.guardarGrafico();
      this.spinner.hide();
      this.obtenerFechasYRenderizar(this.optionsGrafico)
    }
  }

  mapeoGrafico(): void {
    const nodos: any[] = this.optionsGrafico?.series?.[0]?.nodes || [];
    const data: any[] = this.optionsGrafico?.series?.[0]?.data || [];

    if(nodos.length > 0){
      const indexPrincipal = nodos.findIndex(e => e.id === 'principal');
      if (indexPrincipal >= 0){
        nodos[indexPrincipal].name = 'principal';
        nodos[indexPrincipal].nombre = this.informacionMalla?.respuestaMalla?.detalleRutConsultado?.nombreRazonSocial || '';
        nodos[indexPrincipal].rut = this.informacionMalla?.respuestaMalla?.detalleRutConsultado?.rut 
                                      + "-" + 
                                      this.informacionMalla?.respuestaMalla?.detalleRutConsultado?.dv;
        nodos[indexPrincipal].displayRut = this.formatearRUT(this.informacionMalla?.respuestaMalla?.detalleRutConsultado?.rut, this.informacionMalla?.respuestaMalla?.detalleRutConsultado?.dv);
        nodos[indexPrincipal].info = "Rut Principal " + this.informacionMalla?.respuestaMalla?.detalleRutConsultado?.nombreRazonSocial;
      }
    }

    if(this.informacionMalla?.respuestaMalla?.listadoSocios?.length > 0){
      this.informacionMalla?.respuestaMalla?.listadoSocios?.forEach((element: any, index: number) => {
        if(index < 10){
          nodos.push({
            id: ('socio-' + element?.socio?.rut),
            title: null,
            entidad: 'Socio',
            name: ('socio-' + element?.socio?.rut),
            nombre: element?.socio?.nombreRazonSocial || '',
            rut: element?.socio?.rut + "-" + element?.socio?.dv,
            displayRut: this.formatearRUT(element?.socio?.rut, element?.socio?.dv),
            layout: "vertical",
            color: "#d76034",
            height: 60,
            info: `Socio ${element?.socio?.nombreRazonSocial}`,
            fechaInicio: '',
            fechaTermino: '',
            sociedadDisueltaPorAbsorcion: ''
          });
          data.push(['principal', ('socio-' + element?.socio?.rut)]);
        }
      });
    }

    if(this.informacionMalla?.respuestaMalla?.listadoParticipaciones?.length > 0){
      this.informacionMalla?.respuestaMalla?.listadoParticipaciones?.forEach((element: any, index: number) => {
        if(index < 10){
          nodos.push({
            id: ('sociedad-' + element?.participacion?.rut),
            title: null,
            entidad: 'Sociedad',
            name: ('sociedad-' + element?.participacion?.rut),
            nombre: element?.participacion?.nombreRazonSocial || '',
            rut: element?.participacion?.rut + "-" + element?.participacion?.dv,
            displayRut: this.formatearRUT(element?.participacion?.rut, element?.participacion?.dv),
            layout: "vertical",
            color: "#d734ab",
            height: 60,
            info: `Sociedad ${element?.participacion?.nombreRazonSocial}`,
            fechaInicio: '',
            fechaTermino: '',
            sociedadDisueltaPorAbsorcion: ''
          });
          data.push(['principal', ('sociedad-' + element?.participacion?.rut)]);
        }
      });
    }

    if(this.optionsGrafico?.series?.[0]?.nodes)
      this.optionsGrafico.series[0].nodes = nodos;
    if(this.optionsGrafico?.series?.[0]?.data)
      this.optionsGrafico.series[0].data = data;
  }

  ngOnInit() {
    this.statusTX !== 'CLOSED' ? this.editable = true : this.editable = false;

    if(!this.informacionGrafico && this.editable){
      this.mapeoGrafico();
      this.guardarGrafico();
      this.obtenerFechasYRenderizar(this.optionsGrafico)
    } else {
      // Los datos ya vienen preparados para crear la tabla
      if(this.optionsGrafico?.series?.[0]?.nodes)
        this.optionsGrafico.series[0].nodes = this.informacionGrafico?.nodes || [];
      if(this.optionsGrafico?.series?.[0]?.data)
        this.optionsGrafico.series[0].data = this.informacionGrafico?.data || [];
      this.obtenerFechasYRenderizar(this.optionsGrafico, false)
    }
  }

  obtenerFechasYRenderizar(opcionesGrafico: any, obtenerFechas: boolean = true): void {
    if(obtenerFechas){
      const indexPrincipal = opcionesGrafico?.series[0]?.nodes.findIndex((e: any) => e?.id === 'principal');
      if(indexPrincipal >= 0) { // reporte basico principal
        const fechaInicio = this.procesarStringFecha(this.informacionCliente?.SolicitudDeCredito?.Reportes?.basicReport?.InformacionBasica?.AnoInicioActividades) || '';
        const fechaTermino = 
          this.informacionCliente?.SolicitudDeCredito?.Reportes?.basicReport?.InformacionBasica?.TerminoGiro === true ? 
          (this.procesarStringFecha(this.informacionCliente?.SolicitudDeCredito?.Reportes?.basicReport?.InformacionBasica?.FechaTerminoGiro)) : '';
        opcionesGrafico.series[0].nodes[indexPrincipal].fechaInicio = fechaInicio;
        opcionesGrafico.series[0].nodes[indexPrincipal].fechaTermino = fechaTermino;
        if(this.informacionCliente?.SolicitudDeCredito?.Reportes?.basicReport?.InformacionBasica?.sociedadDisueltaPorAbsorcion) {
          opcionesGrafico.series[0].nodes[indexPrincipal].sociedadDisueltaPorAbsorcion = this.procesarStringFecha(this.informacionCliente?.SolicitudDeCredito?.Reportes?.basicReport?.InformacionBasica?.sociedadDisueltaPorAbsorcion);
        }
      }

      const nodosConFechas = opcionesGrafico?.series[0]?.nodes.filter((e: any) => e?.id !== 'principal' && (e?.fechaInicio !== '' || e?.fechaTermino !== ''));
      const elementosAConsultar = new Set();

      opcionesGrafico?.series[0]?.nodes.forEach((e: any) => {
        if (e?.id !== 'principal' && e?.fechaInicio === '' && e?.fechaTermino === '') {
          const nodo = nodosConFechas.find((element: any) => element?.rut === e.rut);
          if(nodo){
            e.fechaInicio = nodo.fechaInicio;
            e.fechaTermino = nodo.fechaTermino;
            if(nodo?.sociedadDisueltaPorAbsorcion){
              e.sociedadDisueltaPorAbsorcion = nodo.sociedadDisueltaPorAbsorcion;
            }
          } else {
            elementosAConsultar.add(e.rut);
          }
        }
      });

      const elementosUnicos: any[] = Array.from(elementosAConsultar) || [];
      
      if(elementosUnicos.length > 0){
        this.buscarReportes(elementosUnicos, opcionesGrafico);
      } else {
        this.optionsGrafico = opcionesGrafico;
        this.guardarGrafico();
        Highcharts.chart('container', this.optionsGrafico);
      }
    } else {
      this.optionsGrafico = opcionesGrafico;
      this.guardarGrafico();
      Highcharts.chart('container', this.optionsGrafico);
    }
  }

  buscarReportes(apiServicesReportes: string[], opcionesGrafico: any): void {
    let count = 0;

    this.spinner.show();
    from(apiServicesReportes).pipe( 
      mergeMap(
        (service: any) => 
          this.reporteBasicoService.createReport(
            service,
            '10',
            true
          )
          .pipe(
            catchError(error => {
              //console.error(`Error en la consulta del rut: ${service}`);
              return of(null);
            }),
            map(response => {
              if (response !== null) {
                count++;
              }
              return {
                rut: service,
                response: response
              };
            }),
          ),
          10
        ), 
        filter(response => response !== null),
      toArray()
    ).subscribe(responses => {
      if (count === 0) {
        this.alertsService.error('Ha ocurrido un error al consultar la información de todos los ruts de malla societaria');
        this.optionsGrafico = opcionesGrafico;
        this.guardarGrafico();
        Highcharts.chart('container', this.optionsGrafico);
        this.spinner.hide();
      } else if (count < apiServicesReportes.length) {
        this.alertsService.error('Ha ocurrido un error al consultar la información de algunos ruts de malla societaria');
      }
      if(count > 0){
        this.mapeoDatosRutsMalla(responses, opcionesGrafico);
      }
    }, error => {
      if (count > 0) {
        this.alertsService.error('Ha ocurrido un error al consultar la información de algunos ruts de malla societaria');
        this.optionsGrafico = opcionesGrafico;
        this.guardarGrafico();
        Highcharts.chart('container', this.optionsGrafico);
        this.spinner.hide();
      } else {
        this.alertsService.error('Ha ocurrido un error al consultar la información de todos los ruts de malla societaria');
        this.optionsGrafico = opcionesGrafico;
        this.guardarGrafico();
        Highcharts.chart('container', this.optionsGrafico);
        this.spinner.hide();
      }
    });
  }

  mapeoDatosRutsMalla(reportes: any[], opcionesGrafico: any): void {
    opcionesGrafico?.series[0]?.nodes.forEach((element: any) => {
      if(element?.id !== 'principal'){
        const datosRut = reportes.find(e => e?.rut === element?.rut);
        if(datosRut){ // datos reporte basico
          element.fechaInicio = this.procesarStringFecha(datosRut?.response?.InformacionBasica?.AnoInicioActividades) || '';
          element.fechaTermino = datosRut?.response?.InformacionBasica?.TerminoGiro === true ? 
          (this.procesarStringFecha(datosRut?.response?.InformacionBasica?.FechaTerminoGiro)) : '';
          element.sociedadDisueltaPorAbsorcion = datosRut?.response?.InformacionBasica?.sociedadDisueltaPorAbsorcion ? 
          (this.procesarStringFecha(datosRut?.response?.InformacionBasica?.sociedadDisueltaPorAbsorcion)) : '';
        }
      }
    });

    this.optionsGrafico = opcionesGrafico;
    this.guardarGrafico();
    Highcharts.chart('container', this.optionsGrafico);
    this.spinner.hide();
  }

  guardarGrafico(): void {
    const grafico = {
      datosGraficos:{
        nodes: this.optionsGrafico.series[0].nodes,
        data: this.optionsGrafico.series[0].data
      }
    }
    this.solicitudCreditoService.setObjResumenNew(grafico, 'datosGraficos').subscribe(resp => {
    });
  }

  procesarStringFecha(input: string) {
    if (input === undefined || input === null) {
      return "";
    }
  
    if (typeof input !== "string") {
      return "";
    }
  
    // Validar si es una fecha en formato "yyyy-mm-dd"
    const fechaRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (fechaRegex.test(input)) {
      const [year, month, day] = input.split("-");
      return `${day}-${month}-${year}`;
    }
  
    // Validar si es una fecha en formato "yyyy-mm-ddThh:mm:ss"
    const fechaHoraRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    if (fechaHoraRegex.test(input)) {
      const [datePart] = input.split("T");
      const [year, month, day] = datePart.split("-");
      return `${day}-${month}-${year}`;
    }
  
    return input;
  }

}
