import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalPdfViewerService } from '../../shared/services/modal-pdf-viewer.service';
import { PdfGeneratorService } from '../../shared/services/pdf-generator.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-modal-pdf-viewer',
  templateUrl: './modal-pdf-viewer.component.html',
  styleUrls: ['./modal-pdf-viewer.component.scss']
})
export class ModalPdfViewerComponent implements OnInit {

  @Input() urlPreview: string = '';
  @Input() urlPDF: string = '';
  @Input() idTransaccion: string = '';
  @Input() tipoReporte: string = '';
  @Input() rut: string = '';
  @Input() today: string = '';
  private subscriptions: Subscription[] = [];
  public objectKeys = Object.keys;
  public messages: { text: string, isError: boolean, isSuccess: boolean }[] = [];

  constructor(
    private modalService: ModalPdfViewerService,
    private pdfGeneratorService: PdfGeneratorService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  validaEstado(): boolean{
    const estado = this.modalService.watch();
    return estado;
  }

  async descargarPdf(): Promise<void> {

    if(this.urlPDF) {
      this.openPDF(this.urlPDF);
    } else {
      if(this.idTransaccion && this.tipoReporte) {
        this.spinner.show();
        try {
          const contentPDF = await this.pdfGeneratorService._getPDF(this.idTransaccion, 'flujoCredito').toPromise();
  
          if(contentPDF?.URLReporte) {
            this.openPDF(contentPDF?.URLReporte);
          } else {
            this.addMessage('Solicitud de Crédito - No se logró recuperar el pdf generado', true, false);
          }
          this.spinner.hide();

        } catch (error) {
          this.addMessage('Solicitud de Crédito - Ocurrio un error al intentar recuperar el pdf', true, false);
          this.spinner.hide();
        }
      }
    }

  }

  openPDF(url: string): void {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', "FlujoDeCredito" + (this.rut ? `_${this.rut}_` : '') + (this.today ? `_${this.today}_` : '') + ".pdf");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  addMessage(mensaje: string, isError: boolean, isSuccess: boolean) : void {
    this.messages.push({ text: mensaje, isError: isError, isSuccess: isSuccess });
  }

  removeMessage(index: number) {
    this.messages.splice(index, 1);
  }

}
