import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdministracionCarteraApiService } from '../../services/administracion-cartera-api.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-query-detail-modal',
  templateUrl: './query-detail-modal.component.html',
  styleUrls: ['./query-detail-modal.component.scss']
})
export class QueryDetailModalComponent {

  /**
   *
   */

  textValue: string = '';
  constructor(
    public dialogRef: MatDialogRef<QueryDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    private _apiService: AdministracionCarteraApiService,
    private _spinner: NgxSpinnerService,
  ) {
    this.getDetail(data.id);
  }


  getDetail(id: string) {
    this._spinner.show();
    this._apiService.getMapperDetail(id).subscribe((res: any) => {
      this._spinner.hide();
      if(res?.queries) {
        this.textValue = this.getQueryValues(res.queries);
      }
    });
  }
  
  getQueryValues(queries: any[]) {
    let values = '';
    queries.forEach(query => {
      if(query.details) {
        values += `${query.details}\n`;
      }
    });

    return values;
  }

  onClose() {
    this.dialogRef.close();
  }
}
