import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BackendAuthService } from './backend-auth.service';
import { catchError } from 'rxjs/operators';
import { CognitoService } from './cognito.service';
import { InactivityService } from './inactivity.service';
import { environment as env } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class AuthInterceptorService implements HttpInterceptor {
    private urlCalculadora: string = env.backend.calculadoraUF.url;

    constructor(
        private _backendAuthService: BackendAuthService,
        private _cognitoService: CognitoService,
        private inactivityService: InactivityService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token: string = localStorage.getItem('idToken') || '';
        let request = req;
        if (token) {
            request = req.clone({
                setHeaders: {
                    'Authorization': this._backendAuthService.getUserAuthToken(),
                    'Content-Type': 'application/json',
                }
            });
        } else {
            request = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });   
        }

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    this.inactivityService.onStopWatching();
                    this._cognitoService.logout();
                }

                return throwError(err);
            })
        );
    }

}