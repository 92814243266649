<ng-container *ngIf="informacionCliente">
    <ng-container *ngIf="statusTX === 'CLOSED'">
        <ng-template [ngTemplateOutlet]="estadoAprobacionTemplate"></ng-template>
    </ng-container>

    <div class="row mt-3">
        <div class="col-sm-12 mb-3">
            <div class="card">
                <h5 class="card-header list-group-item cuadro-color">
                    Información del Cliente</h5>

                <div class="card-body">

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="table-responsive">
                                <table class="table" *ngFor="let item of informe">
                                    <tbody>
                                        <tr>
                                            <th colspan="1" width="40%">Razón Social:</th>
                                            <td colspan="4" width="60%">
                                                <div class="form-control" disabled>
                                                    {{item.Reportes?.basicReport?.InformacionBasica?.NombreORazonSocial}}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">
                                                <div>
                                                    Rut:
                                                </div>
                                            </th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{item.Reportes?.basicReport?.InformacionBasica.Rut | rutFormat}}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Dirección:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{item.Reportes?.basicReport?.InformacionBasica.Direccion}}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Actividad Economica:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{item.Reportes?.basicReport?.Riesgo?.ActividadEconomica}}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Iniciación de Actividades:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{item.Reportes?.basicReport?.InformacionBasica?.AnoInicioActividades
                                                    | date:'dd-MM-yyyy'}}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Cant. Empleados:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{item.Reportes?.basicReport?.AntecedentesFinancieros?.NumeroEmpleados
                                                    | number: '1.0-0'}}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Tipo de Sociedad:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{item.Reportes?.basicReport?.InformacionBasica?.TipoSociedad}}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Malla Societaria:</th>
                                            <td colspan="4">
                                                <div class="form-control text-uppercase" disabled>
                                                    <ng-container *ngIf="item.Socios?.length > 0">
                                                        {{item.Socios[0]?.Service}}
                                                    </ng-container>

                                                </div>
                                            </td>
                                        </tr>
                                        <ng-container
                                            *ngIf="item.Reportes?.carpetaTributariaElectronica?.CarpetaTributariaElectronicaReporte?.Reporte?.representantes?.length > 0">


                                            <ng-container
                                                *ngFor="let representante of item.Reportes?.carpetaTributariaElectronica?.CarpetaTributariaElectronicaReporte?.Reporte?.representantes; let i = index">
                                                <tr>
                                                    <th colspan="1">Representante Legal {{i + 1}}:</th>
                                                    <td colspan="4">
                                                        <div class="form-control" disabled>
                                                            {{representante.nombreRazonSocial}}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-container>

                                        </ng-container>
                                        <tr>
                                            <th colspan="1">Tamaño de la empresa:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{item.Reportes?.basicReport?.AntecedentesFinancieros?.TamanoEmpresaDescripcion}}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Subsegmento:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{item.Reportes?.basicReport?.AntecedentesFinancieros?.TamanoSubsegmento}}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Rango Facturación:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{item.Reportes?.basicReport?.AntecedentesFinancieros?.TamanoEmpresaRango}}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Causas judiciales
                                                (Cantidad de causas laborales, civiles o cobranza) Activas:
                                            </th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{getTotal((item.Reportes?.poderJudicial?.Totales?.nCausasCivil) ||
                                                    0,
                                                    (item.Reportes?.poderJudicial?.Totales?.nCausasCobranza) || 0,
                                                    (item.Reportes?.poderJudicial?.Totales?.nCausasLaboral) || 0)}}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Número de Vehiculos:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{ item.Reportes?.basicReport?.AntecedentesFinancieros?.NumeroVehiculos ? 
                                                        (item.Reportes?.basicReport?.AntecedentesFinancieros?.NumeroVehiculos | number: '1.0-0') : 'Sin información' }}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Valor de Vehiculos:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{ item.Reportes?.basicReport?.AntecedentesFinancieros?.ValorVehiculos ? 
                                                        (item.Reportes?.basicReport?.AntecedentesFinancieros?.ValorVehiculos | number: '1.0-0') : 'Sin información' }}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Número de Bienes Raices:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{ item.Reportes?.basicReport?.AntecedentesFinancieros?.NumeroBienesRaices ? 
                                                        (item.Reportes?.basicReport?.AntecedentesFinancieros?.NumeroBienesRaices | number: '1.0-0') : 'Sin información' }}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Valor de Bienes Raices:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{ item.Reportes?.basicReport?.AntecedentesFinancieros?.ValorBienesRaices ? 
                                                        (item.Reportes?.basicReport?.AntecedentesFinancieros?.ValorBienesRaices | number: '1.0-0') : 'Sin información' }}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="table-responsive">
                                <table class="table" *ngFor="let item of informe">
                                    <tbody>
                                        <tr>
                                            <th colspan="1" width="40%">Report al (Mes - año):</th>
                                            <td colspan="4" width="60%">
                                                <div class="form-control text-capitalize" disabled>
                                                    <!-- {{
                                                        item.Reportes?.leasingSbif?.Reporte?.Sbif?.Reporte?.Periodo
                                                    }} -->
                                                    {{obtenerFecha() | date:'LLL'}}
                                                    {{obtenerFecha() | date:'yyyy'}}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Deuda vigente Bancarios y leasing M$:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{setNumber(lastValuesDeudaBancaria.deudaVigenteSbif)
                                                    + (setNumber(lastValuesDeudaBancaria.deudaVigenteLeasing) / 1000) |
                                                    number: '1.0-0'}} </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Deuda morosa Bancarios y leasing M$:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    {{setNumber(lastValuesDeudaMorosaBancaria.impagosSbif)
                                                    + (setNumber(lastValuesDeudaMorosaBancaria.impagosLeasing) / 1000) |
                                                    number: '1.0-0'}}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Total Impago Empresa (Dicom) M$:</th>
                                            <td colspan="4">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6 pl-0">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text" disabled>#</span>
                                                                </div>
                                                                <div class="form-control border-left-0" disabled>
                                                                    {{item.Reportes?.basicReportInformeComercial?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad
                                                                    | number:'1.0-0'}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 pr-0">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text" disabled>$</span>
                                                                </div>
                                                                <div class="form-control border-left-0" disabled>
                                                                    {{item.Reportes?.basicReportInformeComercial?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto
                                                                    | number:'1.0-0'}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Total Impagos Socios (Dicom) M$:</th>
                                            <td colspan="4">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6 pl-0">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text" disabled>#</span>
                                                                </div>
                                                                <div class="form-control border-left-0" disabled>
                                                                    {{setNumber(obtenerMorosidadSocios('TotalImpagosCantidad')) | number:'1.0-0'}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 pr-0">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text" disabled>$</span>
                                                                </div>
                                                                <div class="form-control border-left-0" disabled>
                                                                    {{setNumber(obtenerMorosidadSocios('TotalImpagosMonto')) | number:'1.0-0'}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">LC bancarias disponibles M$:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    <ng-container *ngIf="lastValuesDeudaSbig">
                                                        {{setNumber(lastValuesDeudaSbig.lcBancarias) | number:'1.0-0'}}
                                                    </ng-container>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colspan="1">Crédito contingentes M$:</th>
                                            <td colspan="4">
                                                <div class="form-control" disabled>
                                                    <ng-container *ngIf="lastValuesDeudaSbig">
                                                        {{setNumber(lastValuesDeudaSbig.creditosContingentes) |
                                                        number:'1.0-0'}}
                                                    </ng-container>
                                                </div>
                                            </td>
                                        </tr>
                                        <ng-container
                                            *ngIf="informacionCliente.SolicitudDeCredito?.DatosBasicosSolicitud?.Modelo === 'lineaDeCredito'">
                                            <tr>
                                                <th colspan="1">
                                                    <div class="pt-3">
                                                        LC Solicitada M$:
                                                    </div>
                                                </th>
                                                <td colspan="4">
                                                    <div type="text" class="form-control" disabled>
                                                        {{(setNumber(obtenerValorCamposPersonalizados('LC Solicitada'))/1000)
                                                        |
                                                        number:'1.0-0'}}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th colspan="1">LC Calculada M$:</th>
                                                <td colspan="4">
                                                    <div class="form-control" disabled>
                                                        {{(setNumber(obtenerValorRulesEngine('lineaCreditoAprobada'))/1000)
                                                        |
                                                        number:'1.0-0'}}
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="informacionCliente.SolicitudDeCredito?.DatosBasicosSolicitud?.Modelo !== 'renegociacion'">
                                            <tr>
                                                <th colspan="1">LC por Flota M$:</th>
                                                <td colspan="4">
                                                    <div class="form-control" disabled>
                                                        {{(!obtenerValorRulesEngine('lineaCreditoFlota') && obtenerValorRulesEngine('lineaCreditoFlota') != '0') ? 
                                                            ('Sin Información') : ((obtenerValorRulesEngine('lineaCreditoFlota'))
                                                            |
                                                            number:'1.0-0')
                                                        }}
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="row mt-3">
        <div class="col-sm-6">
            <div class="card">
                <h5 class="card-header list-group-item cuadro-color">RIESGO</h5>
                <div class="card-body box-riesgo">
                    <div class="table-responsive">
                        <table class="table table-hover" *ngFor="let item of informeRiesgo">
                            <tbody>
                                <tr>
                                    <th colspan="1" width="40%">Riesgo Industria:</th>
                                    <td colspan="4" width="60%" class="text-center">
                                        <div class="form-control" disabled>
                                            {{item.riesgoIndustria}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="1">Protestos internos vigentes:</th>
                                    <td colspan="4">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-6 pl-0">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" disabled>#</span>
                                                        </div>
                                                        <div class="form-control border-left-0" disabled>
                                                            {{item.protestosInternosVigentesCantidad}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 pr-0">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" disabled>$</span>
                                                        </div>
                                                        <div class="form-control border-left-0" disabled>
                                                            {{item.protestosInternosVigentesMonto}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="1">Morosidades internas:</th>
                                    <td colspan="4" class="text-center">
                                        <div class="form-control" disabled>{{(setNumber(item.morosidadesInternas)/1000)
                                            | number: '1.0-0'}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="1">Morosidades internas +30:</th>
                                    <td colspan="4" class="text-center">
                                        <div class="form-control" disabled>
                                            {{setNumber(item.morosidadesInternasMayores30)/1000 | number: '1.0-0'}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="5">
                                        <div class="mx-auto dynamic-colunm mb-5">
                                            <app-custom-bars [riesgo]="item.score"></app-custom-bars>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th colspan="5">
                                        <app-pie-chart [value]="item.score"></app-pie-chart>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6">
            <div class="card">
                <h5 class="card-header list-group-item cuadro-color">Antecedentes Salfa</h5>
                <div class="card-body table-responsive">
                    <table class="table mb-0" *ngFor="let item of informe">
                        <tbody>
                            <tr>
                                <th width='30%'>Antigüedad Salfa</th>
                                <td width='70%'>
                                    <div class="form-control" disabled>
                                        {{obtenerValorRulesEngine('antiguedadSalfa') | date: 'dd-MM-YYYY' }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Cliente Nuevo/Antiguo</th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{obtenerValorRulesEngine('clienteNuevoAntiguo')}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><i class="promedio">X</i> mensual venta Salfa (No seriado) M$</th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{setNumber(obtenerValorRulesEngine('promMensualVentaSalfaNoSeriado'))/1000 | number:'1.0-0'}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><i class="promedio">X</i> mensual venta Salfa (Seriado) M$</th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{setNumber(obtenerValorRulesEngine('promMensualVentaSalfaSeriado'))/1000 | number:'1.0-0'}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Comportamiento de Pago</th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{obtenerValorRulesEngine('comportamientoDePago')}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Plazo promedio de venta últimos 4 meses</th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{obtenerValorRulesEngine('condicionDePago') | number:'1.0-0'}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Promedio DSO *</th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{setNumber(obtenerValorRulesEngine('promedioDSO')) | number: '1.0-2'}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Promedio DSO (Días)</th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{setNumber(obtenerValorRulesEngine('promedioDSODias')) | number: '1.0-2'}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Deuda Actual M$</th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{(setNumber(obtenerValorRulesEngine('deudaActual')) / 1000) | number:'1.0-0'}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Deuda Vencida M$</th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{(setNumber(obtenerValorRulesEngine('deudaVencida')) / 1000) | number:'1.0-0'}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Frecuencia cliente</th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{obtenerValorRulesEngine('frecuenciaCliente')}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Grupo Economico</th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{obtenerValorRulesEngine('grupoEconomico')}}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12 mt-3">
            <ng-template #closeStatus>
                <app-condiciones-ventas [condicionVenta]="informacionCliente" [tipoSolicitud]="tipoSolicitud">
                </app-condiciones-ventas>
            </ng-template>

            <ng-container *ngIf="statusTX !== 'CLOSED' && validEdition; else closeStatus">
                <app-resumen-condiciones-venta [condicionVenta]="informacionCliente" [tipoSolicitud]="tipoSolicitud"
                [rut]="rut" [idTransaccion]="idTransaccion">
                </app-resumen-condiciones-venta>
            </ng-container>

        </div>
    </div>

    <ng-container *ngIf="statusTX !== 'CLOSED'">
        <ng-template [ngTemplateOutlet]="estadoAprobacionTemplate"></ng-template>
    </ng-container>

    <div class="mt-3">
        <p class="subTitle text-center w-100">
            Línea de Credito M$
        </p>

        <div class="mt-1 w-100">
            <app-linea-credito [tipoSolicitud]="tipoSolicitud" [informe]="informacionCliente" [statusTX]="statusTX"
                [montoAprobado]="montoAprobado">
            </app-linea-credito>
        </div>
    </div>
</ng-container>

<ng-template #estadoAprobacionTemplate>
    <div class="row mt-3">
        <div class="col-sm-12">
            <div class="card">
                <h5 class="card-header list-group-item cuadro-color">Estado Aprobación</h5>
                <div class="card-body pt-3">
                    <form [formGroup]="estadoAprobacionForm" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="col-sm-6">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th width='50%' colspan="1">
                                                <div class="pt-3">Estado Aprobación</div>
                                            </th>
                                            <td width='50%' colspan="2">
                                                <select class="form-control" formControlName="estadoAprobacion">
                                                    <ng-container *ngFor="let item of estadoAprobacionSel">
                                                        <option [value]="item.value">{{item.text}}</option>
                                                    </ng-container>
                                                </select>
                                                <app-error [control]="estadoAprobacionForm.get('estadoAprobacion')">
                                                </app-error>
                                            </td>
                                        </tr>
                                        <tr
                                            *ngIf="estadoAprobacionForm.controls['estadoAprobacion'].value && estadoAprobacionForm.controls['estadoAprobacion'].value !== 'rechazado'">
                                            <th colspan="1">Área Comercial</th>
                                            <td colspan="2">
                                                <ng-template #areaComercial>
                                                    <span class="form-control disabled" disabled>
                                                        {{estadoAprobacionForm.controls['areaComercial'].value}}
                                                    </span>
                                                </ng-template>
                                                
                                                <ng-container *ngIf="statusTX !== 'CLOSED'; else areaComercial">
                                                    <textarea class="form-control" formControlName="areaComercial"></textarea>
                                                    <app-error [control]="estadoAprobacionForm.get('areaComercial')">
                                                    </app-error>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-sm-6">
                                <!-- ------------------------------------------------------ -->
                                <table class="table">
                                    <tbody>
                                        <tr
                                            *ngIf="estadoAprobacionForm.controls['estadoAprobacion'].value && estadoAprobacionForm.controls['estadoAprobacion'].value !== 'rechazado' && tipoSolicitud !== 'lineaDeCredito'">
                                            <th colspan="1">Garantia</th>
                                            <td colspan="2">
                                                <select class="form-control" formControlName="garantia">
                                                    <ng-container *ngFor="let item of garantiaSel">
                                                        <option [value]="item.value">{{item.text}}</option>
                                                    </ng-container>
                                                </select>
                                                <app-error [control]="estadoAprobacionForm.get('garantia')"></app-error>
                                            </td>
                                        </tr>
                                        <tr
                                            *ngIf="estadoAprobacionForm.controls['estadoAprobacion'].value === 'aprobadoCondiciones' && tipoSolicitud !== 'lineaDeCredito'">
                                            <th colspan="1">Documentacion</th>
                                            <td colspan="2">
                                                <select class="form-control" formControlName="documentacion">
                                                    <ng-container *ngFor="let item of documentacionSel">
                                                        <option [value]="item.value">{{item.text}}</option>
                                                    </ng-container>
                                                </select>
                                                <app-error [control]="estadoAprobacionForm.get('documentacion')">
                                                </app-error>
                                            </td>
                                        </tr>
                                        <tr
                                            *ngIf="estadoAprobacionForm.controls['estadoAprobacion'].value === 'aprobadoCondiciones' && tipoSolicitud !== 'lineaDeCredito'">
                                            <th colspan="1">Pie</th>
                                            <td colspan="2">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">#</span>
                                                    </div>
                                                    <input type="text" class="form-control border-left-0"
                                                        formControlName="pie">
                                                </div>
                                                <app-error [control]="estadoAprobacionForm.get('pie')"></app-error>
                                            </td>
                                        </tr>
                                        <tr
                                            *ngIf="estadoAprobacionForm.controls['estadoAprobacion'].value === 'aprobadoCondiciones' && tipoSolicitud !== 'lineaDeCredito'">
                                            <th colspan="1">Plazo</th>
                                            <td colspan="2">
                                                <input type="text" class="form-control" formControlName="plazo">
                                                <app-error [control]="estadoAprobacionForm.get('plazo')"></app-error>
                                            </td>
                                        </tr>
                                        <tr
                                            *ngIf="estadoAprobacionForm.controls['estadoAprobacion'].value && estadoAprobacionForm.controls['estadoAprobacion'].value !== 'rechazado' && tipoSolicitud === 'lineaDeCredito'">
                                            <th colspan="1">Monto Aprobado</th>
                                            <td colspan="2">
                                                <input type="text" class="form-control" formControlName="montoAprobado"
                                                    mask="separator" thousandSeparator="."
                                                    (input)="sendMontoAprobado()">
                                                <app-error [control]="estadoAprobacionForm.get('montoAprobado')">
                                                </app-error>
                                            </td>
                                        </tr>
                                        <tr *ngIf="estadoAprobacionForm.controls['estadoAprobacion'].value">
                                            <th colspan="1">Comentarios/Glosa</th>
                                            <td colspan="2">
                                                <ng-template #comentario>
                                                    <span class="form-control disabled" disabled>
                                                        {{estadoAprobacionForm.controls['comentariosGlosa'].value}}
                                                    </span>
                                                </ng-template>
                                                
                                                <ng-container *ngIf="statusTX !== 'CLOSED'; else comentario">
                                                    <textarea class="form-control" formControlName="comentariosGlosa"></textarea>
                                                </ng-container> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <ng-container *ngIf="validarDetalleGrande4()">
                                <div class="col-sm-12 font-detalle">
                                    *Para cupo mayor agregar CTE
                                </div>
                            </ng-container>
                            

                        </div>

                        <div class="row" *ngIf="statusTX !== 'CLOSED'">
                            <div class="col-sm-4 mx-auto mt-5">
                                <div class="form-group form-actions container__actions">
                                    <button type="submit" class="btn btn-sm btn-primary">Cerrar Solicitud</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>