import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-dynamic-colunm-table',
  templateUrl: './dynamic-colunm-table.component.html',
  styleUrls: ['./dynamic-colunm-table.component.scss']
})
export class DynamicColunmTableComponent implements OnInit {
  @Output() funcional = new EventEmitter<any>();
  @Input() data: any[] = [];
  @Input() header: any[] = [];
  @Input() divider: string = '';
  @Input() groupHeader: string = '';
  @Input() headerName: any = {};
  @Input() extraHeader: string[] = [];
  @Input() groupExtraHeader: string[] = [];
  @Input() contentEditable: boolean = false;
  @Input() excluir: string[] = [];
  @Input() icon: boolean = false;
  @Input() iconExcluir: string = '';
  @Input() clasess: string = '';
  @Input() paginationExcluir: boolean = false;
  public valDivider: string = '';
  public result: any[] = [];
  public colunms: any = [];
  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public showRow: boolean = false;

  constructor(private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.setHeader();
    this.data = this.divider === 'group' ? this.dividerInGroup(this.data) : this.divider === 'divider' ? this.dividerRow(this.data) : this.data;
    this.headerName = Object.assign(this.utilsService.setGroupHeader(this.groupHeader));
  }

  setHeader() {
    // if (this.initColunm) this.colunms.unshift(this.initColunm);
    if (this.header.length === 0) {
      for (const item of Object.keys(this.data[0])) {
        if (!this.excluir.includes(item)) {
          this.header.push(item);
        }
      }
    }
    this.header.forEach((element, i) => {
      this.colunms.splice(i + 1, 0, element);
    });
    // if (this.endColunm) this.colunms.splice(this.colunms.length, 0, this.endColunm);
  }

  dividerRow(data: any[]) {
    const result: any[] = [];
    var groups = new Set(data.map(item => item.date.slice(0, 4)));
    groups.forEach(g =>
      result.push({
        date: g.slice(0, 4),
        group: data.filter(i => i.date.slice(0, 4) === g.slice(0, 4)),
        total: this.createNewObject(data.filter(i => i.date.slice(0, 4) === g.slice(0, 4)))
      }
      ));
    return result;
  }

  dividerInGroup(data: any[]) {
    const result: any[] = [];

    var groups = new Set(data.map(item => item.date.slice(0, 4)));
    groups.forEach(g =>
      result.push({
        date: g.slice(0, 4),
        group: this.dividerGroup(data.filter(i => i.date.slice(0, 4) === g.slice(0, 4))),
        total: this.createNewObject(data.filter(i => i.date.slice(0, 4) === g.slice(0, 4))),
        promedio: this.createNewObject(data.filter(i => i.date.slice(0, 4) === g.slice(0, 4)), 'promedio')
      }
      ));
    return result;
  }

  sumProperties(items: any, prop: string) {
    const sum = items.reduce((a: any, b: any) => {
      return !isNaN(Number(b[prop])) ? (!isNaN(Number(a)) ? a : 0) + Number(b[prop]) : a;
    }, 0);
    return sum;
  }

  createNewObject(obj: any[], type?: string) {

    const newObj: any[] = [];
    if (obj.length > 0) {
      for (const keyObj of Object.keys(obj[0])) {
        if (!type) {
          newObj.push({ [keyObj]: this.sumProperties(obj, keyObj) });
        } else {
          const valuesKey = ['compras', 'diferencia', 'var', 'ventas'];
          newObj.push({
            [keyObj]: this.sumProperties(obj, keyObj) / obj.filter(e =>
              e[valuesKey[0]] !== 0 || 
              e[valuesKey[1]] !== 0 || 
              e[valuesKey[2]] !== 0 || 
              e[valuesKey[3]] !== 0).length
          });
        }
      }
    }
    return newObj;
  }

  dividerGroup(obj: any[]) {
    const groups: any = [{ group1: [], group2: [], total: [] }];
    let count: number = 1;
    obj.forEach((element: any, i: number) => {
      groups[0]['group' + count].push(element);
      if (i === 5) {
        count++;
      }
    });

    groups[0]['total'].push({
      item1: this.createNewObject(groups[0].group1),
      item2: this.createNewObject(groups[0].group2)
    });

    return groups;

  }

  funcionalReport(obj: any, type: string) {
    this.funcional.emit({ obj: obj, type: type });
  }

  toggleIdRow(id: string): void {
    let element = document.getElementById(id) as HTMLElement;
    element.classList.contains('d-none') ? element.classList.remove('d-none') : element.classList.add('d-none');
  }


}
