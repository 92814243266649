<div class="panel panel-default border-0 not-print">
    <div class="panel-body">
        <div class="btn-option">

            <button *ngIf="showReportes" [printTitle]='"FlujoDeCredito_"+(rut | rutFormat)+"_"+today'
                [useExistingCss]="true" printSectionId="print-section" ngxPrint id="printButton"
                class="d-none">Descargar</button>
            <button class="btn btn-primary float-right" (click)="descargarPDF()">Descargar PDF</button>
        </div>
    </div>
</div>

<div class="col-sm-12"i d="print-section">
    <div class="break-box">
    
        <div class="row p-4">
        
            <div class="col-sm-12">
        
                <div class="row">
                    <!--Periodo-->
                    <div class="col-sm-6 col-lg-3 px-cuadros my-1">
                        <a class="widget widget-hover-effect1 no-print">
                            <div class="widget-extra" style="background-color: #5AAFE6;">
                                <h4 class="widget-content-light text-center">Periodo Ultima consulta</h4>
                            </div>
                            <div class="widget-simple overflow-auto">
                                <h1 class="widget-content text-right animation-pullDown" style="font-size: 20px;">
                                    <strong style="color: #5AAFE6;" *ngIf="datosCabecera?.fechaReporte">{{datosCabecera?.fechaReporte || ''}}</strong><br>
                                </h1>
                            </div>
                        </a>
                
                        <div class="card border-0 d-none">
                            <div class="card-header">
                                <h4 class="text-white">Periodo Ultima consulta</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h1 class="text-right" style="font-size: 20px;">
                                            <strong style="color: #5AAFE6;">{{datosCabecera?.fechaReporte || ''}}</strong><br>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <!--Antiguedad-->
                    <div class="col-sm-6 col-lg-3 px-cuadros my-1">
                        <a class="widget widget-hover-effect1 no-print">
                            <div class="widget-extra" style="background-color: #5AAFE6;">
                                <h4 class="widget-content-light text-center">Antigüedad Empresa</h4>
                            </div>
                            <div class="widget-simple overflow-auto">
                                <h1 class="widget-content text-right animation-pullDown" style="font-size: 20px;">
                                    <strong style="color: #5AAFE6;" *ngIf="datosCabecera?.antiguedadEmpresa">{{((datosCabecera?.antiguedadEmpresa | number: '1.0-2') + ' años') || ''}}</strong><br>
                                </h1>
                            </div>
                        </a>
                
                        <div class="card border-0 d-none">
                            <div class="card-header">
                                <h4 class="text-white">Antigüedad Empresa</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h1 class="text-right" style="font-size: 20px;">
                                            <strong style="color: #5AAFE6;">{{((datosCabecera?.antiguedadEmpresa | number: '1.0-2') + ' años') || ''}}</strong><br>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <!--Actividad-->
                    <div class="col-sm-6 col-lg-3 px-cuadros my-1">
                        <a class="widget widget-hover-effect1 no-print">
                            <div class="widget-extra" style="background-color: #5AAFE6;">
                                <h4 class="widget-content-light text-center">Actividad</h4>
                            </div>
                            <div class="widget-simple overflow-auto">
                                <h1 class="widget-content text-right animation-pullDown" style="font-size: 18px;">
                                    <strong style="color: #5AAFE6;" *ngIf="datosCabecera?.actividad">{{datosCabecera?.actividad || ''}}</strong><br>
                                </h1>
                            </div>
                        </a>
            
                        <div class="card border-0 d-none">
                            <div class="card-header">
                                <h4 class="text-white">Actividad</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h1 class="text-right" style="font-size: 18px;">
                                            <strong style="color: #5AAFE6;">{{datosCabecera?.actividad || ''}}</strong><br>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!--Indicador de Riesgo-->
                    <div class="col-sm-6 col-lg-3 px-cuadros my-1">
                        <a class="widget widget-hover-effect1 no-print">
                            <div class="widget-extra" style="background-color: #5AAFE6;">
                                <h4 class="widget-content-light text-center">Indicador de Riesgo Equifax</h4>
                            </div>
                            <div class="widget-simple overflow-auto pointer-cursor" (click)="abrirModalTitular()">
                                <h1 class="widget-content text-right animation-pullDown" style="font-size: 20px;">
                                    <strong style="color: #5AAFE6;" *ngIf="datosCabecera?.indRiesgo">{{datosCabecera?.indRiesgo || ''}}</strong><br>
                                </h1>
                            </div>
                        </a>
                
                        <div class="card border-0 d-none">
                            <div class="card-header">
                                <h4 class="text-white">Indicador de Riesgo Equifax</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h1 class="text-right" style="font-size: 20px;">
                                            <strong style="color: #5AAFE6;">{{datosCabecera?.indRiesgo || ''}}</strong><br>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!--Demandas-->
                    <div class="col-sm-6 col-lg-3 px-cuadros my-1">
                        <a class="widget widget-hover-effect1 no-print">
                            <div class="widget-extra" style="background-color: #4C93C2;">
                                <h4 class="widget-content-light text-center">Demandas</h4>
                            </div>
                            <div class="widget-simple overflow-auto">
                                <h1 class="widget-content text-right animation-pullDown" style="font-size: 20px;">
                                    <strong style="color: #4C93C2;" *ngIf="datosCabecera?.demandas">{{datosCabecera?.demandas || ''}}</strong><br>
                                </h1>
                            </div>
                        </a>
                
                        <div class="card border-0 d-none">
                            <div class="card-header">
                                <h4 class="text-white">Demandas</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h1 class="text-right" style="font-size: 20px;">
                                            <strong style="color: #4C93C2;">{{datosCabecera?.demandas || ''}}</strong><br>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!--Compras sobre ventas ultimos 12 meses-->
                    <div class="col-sm-6 col-lg-3 px-cuadros my-1">
                        <a class="widget widget-hover-effect1 no-print">
                            <div class="widget-extra" style="background-color: #4C93C2;">
                                <h4 class="widget-content-light text-center">Compras sobre ventas ultimos 12 meses</h4>
                            </div>
                            <div class="widget-simple overflow-auto">
                                <h1 class="widget-content text-right animation-pullDown" style="font-size: 20px;">
                                    <strong style="color: #4C93C2;" *ngIf="datosCabecera?.comprasSobreVentas12m || datosCabecera?.comprasSobreVentas12m == 0">
                                        {{datosCabecera?.comprasSobreVentas12m || 0 | number: '1.0-2'}}%
                                    </strong><br>
                                </h1>
                            </div>
                        </a>
    
                        <div class="card border-0 d-none">
                            <div class="card-header">
                                <h4 class="text-white">Compras sobre ventas ultimos 12 meses</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h1 class="text-right" style="font-size: 20px;">
                                            <strong style="color: #4C93C2;">
                                                {{datosCabecera?.comprasSobreVentas12m || 0 | number: '1.0-2'}}%
                                            </strong><br>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!--Ventas Promedio Ultimos 12 meses $MM-->
                    <div class="col-sm-6 col-lg-3 px-cuadros my-1">
                        <a class="widget widget-hover-effect1 no-print">
                            <div class="widget-extra" style="background-color: #4C93C2;">
                                <h4 class="widget-content-light text-center">Ventas Promedio Ultimos 12 meses $M</h4>
                            </div>
                            <div class="widget-simple overflow-auto">
                                <h1 class="widget-content text-right animation-pullDown" style="font-size: 20px;">
                                    <strong style="color: #4C93C2;" *ngIf="datosCabecera?.ventasPromedios12m || datosCabecera?.ventasPromedios12m == 0">
                                        ${{datosCabecera?.ventasPromedios12m || 0 | number: '1.0-0'}}
                                    </strong><br>
                                </h1>
                            </div>
                        </a>
                        
                        <div class="card border-0 d-none">
                            <div class="card-header">
                                <h4 class="text-white">Ventas Promedio Ultimos 12 meses $M</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h1 class="text-right" style="font-size: 20px;">
                                            <strong style="color: #4C93C2;">
                                                ${{datosCabecera?.ventasPromedios12m || 0 | number: '1.0-0'}}
                                            </strong><br>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!--Ventas Promedio Ultimos 12 meses $MM-->
                    <div class="col-sm-6 col-lg-3 px-cuadros my-1">
                        <a class="widget widget-hover-effect1 no-print">
                            <div class="widget-extra" style="background-color: #4C93C2;">
                                <h4 class="widget-content-light text-center">Ventas Promedio Ultimos 12 meses $M</h4>
                            </div>
                            <div class="widget-simple overflow-auto">
                                <h1 class="widget-content text-right animation-pullDown" style="font-size: 20px;">
                                    <strong style="color: #4C93C2;" *ngIf="datosCabecera?.ventasPromedios12m || datosCabecera?.ventasPromedios12m == 0">
                                        ${{datosCabecera?.ventasPromedios12m || 0 | number: '1.0-0'}}
                                    </strong><br>
                                </h1>
                            </div>
                        </a>
                        
                        <div class="card border-0 d-none">
                            <div class="card-header">
                                <h4 class="text-white">Ventas Promedio Ultimos 12 meses $M</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h1 class="text-right" style="font-size: 20px;">
                                            <strong style="color: #4C93C2;">
                                                ${{datosCabecera?.ventasPromedios12m || 0 | number: '1.0-0'}}
                                            </strong><br>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
        
            <!--Tabla principal-->
            <div class="col-sm-12" *ngIf="f29Tablas && f29Tablas.length > 0; else ivaNoData">
                <div class="block full min-height-block">
                    <div class="row py-3" *ngFor="let elemento of f29Tablas; let i = index" [attr.data-index]="i" [attr.saltoLinea]="i%2===0 && i!==0 ? true : null">
                        <div class="col-sm-12">
                            <div class="table-responsive px-1">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="border-0 cuadro-color" colspan="100%" style="font-size: 14px !important;">
                                                Ventas {{ elemento.periodo }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Mes</th>
                                            <th>Ventas Netas M$</th>
                                            <th>Ventas Netas UF</th>
                                            <th>Compras Netas M$</th>
                                            <th>Compras Netas UF</th>
                                            <th>Margen M$</th>
                                            <th>% NC / Ventas</th>
                                            <th>Fecha Pago</th>
                                            <th>Tipo Declaración</th>
                                            <th>Postergado</th>
                                            <th>Variación % Ventas</th>
                                        </tr>
                                        <tr *ngFor="let item of elemento.datosReporte"><th>{{item.nombreMes}}</th>
                                            <td><span>{{ item.ventasNetasMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.ventasNetasUF | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.comprasNetasMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.comprasNetasUf | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.margenMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.porc_notascred_ventas | number:'1.0-2'}}</span></td>
                                            <td class="text-nowrap"><span>{{ item.fechaPago}}</span></td>
                                            <td><span>{{ item.tipoDeclaracion}}</span></td>
                                            <td><span>{{ item.postergado | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.variacion | number:'1.0-2'}}</span></td>
                                        </tr>
                                        <tr><th>Totales</th>
                                            <td><span>{{ elemento.totalVentasNetasMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalVentasNetasUF | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalComprasNetasMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalComprasNetasUF | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalMargenMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalPorcVentasMiles | number:'1.0-2'}}</span></td>
                                            <td></td>
                                            <td></td>
                                            <td><span>{{ elemento.totalPostergado | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalVarVentas | number:'1.0-2'}}</span></td>
                                        </tr>
                                        <tr><th>Promedio</th>
                                            <td><span>{{ (elemento.totalVentasNetasMiles/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalVentasNetasUF/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalComprasNetasMiles/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalComprasNetasUF/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalMargenMiles/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalPorcVentasMiles/elemento.mesesEnPeriodo) | number:'1.0-2'}}</span></td>
                                            <td></td>
                                            <td></td>
                                            <td><span>{{ (elemento.totalPostergado/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalVarVentas/elemento.mesesEnPeriodo) | number:'1.0-2'}}</span></td>
                                        </tr>
                                        <tr><th>Proyección</th>
                                            <td><span>{{ ((elemento.totalVentasNetasMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalVentasNetasUF/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalComprasNetasMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalComprasNetasUF/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalMargenMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalPorcVentasMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-2'}}</span></td>
                                            <td></td>
                                            <td></td>
                                            <td><span>{{ ((elemento.totalPostergado/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalVarVentas/elemento.mesesEnPeriodo)*12) | number:'1.0-2'}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--<div class="card textos-campo border-0" *ngIf="showReportes">
                                <h5 class="container-fluid card-header list-group-item cuadro-color">
                                    Ventas {{ elemento.periodo }}
                                </h5>
                                <div class="card-body">
                                    <div class="col-12">
                                    </div>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #ivaNoData>
                <div class="col-12">
                </div>
            </ng-template>
            
            
            <!--Informes comerciales $MM-->
            <div class="col-sm-12 col-md-6 full-size-print">
                <div class="block full size-boxes-3">
                    <div class="block-title mb-3">
                        <h2>Informes comerciales $M</h2>
                    </div>
                    <div class="row px-4">
                        <div class="col-sm-12 p-2">
                            <table class="table table-hover shadow">
                                <tbody>
                                    <tr>
                                        <th class="font-size-cell estadisticos-cell" scope="row" width="50%">Protestos</th>
                                        <td class="font-size-cell">
                                           {{validarValor(informesComerciales?.protestos) ? ('$' + (informesComerciales?.protestos | number: '1.0-2')) : ''}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-sm-12 p-2">
                            <table class="table table-hover shadow">
                                <tbody>
                                    <tr>
                                        <th class="font-size-cell estadisticos-cell" scope="row" width="50%">Morosidad</th>
                                        <td class="font-size-cell">
                                            {{validarValor(informesComerciales?.morosidad) ? ('$' + (informesComerciales?.morosidad | number: '1.0-2')) : ''}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-sm-12 p-2">
                            <table class="table table-hover shadow">
                                <tbody>
                                    <tr>
                                        <th class="font-size-cell estadisticos-cell" scope="row" width="50%">Infracciones laborales</th>
                                        <td class="font-size-cell">
                                            {{validarValor(informesComerciales?.infraccionesLaborales) ? ('$' + (informesComerciales?.infraccionesLaborales | number: '1.0-2')) : ''}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-sm-12 p-2">
                            <table class="table table-hover shadow">
                                <tbody>
                                    <tr>
                                        <th class="font-size-cell estadisticos-cell" scope="row" width="50%">Financiera vigente</th>
                                        <td class="font-size-cell">
                                            {{validarValor(informesComerciales?.financieraVigente) ? ('$' + (informesComerciales?.financieraVigente | number: '1.0-2')) : ''}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-sm-12 p-2">
                            <table class="table table-hover shadow">
                                <tbody>
                                    <tr>
                                        <th class="font-size-cell estadisticos-cell" scope="row" width="50%">Financiera Deteriorada</th>
                                        <td class="font-size-cell">
                                            {{validarValor(informesComerciales?.financieraDeteriorada) ? ('$' + (informesComerciales?.financieraDeteriorada | number: '1.0-2')) : ''}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="col-sm-12 col-md-6 full-size-print">
                <div class="block full min-height-block size-boxes-3">
                    <div class="table-responsive table-entidad">
                        <table id="example-datatable" class="table table-hover table-vcenter table-condensed table-bordered">
                            <thead>
                                <tr>
                                    <th class="bg-blue text-center">Meses</th>
                                    <th class="bg-blue text-center">Notas de Credito</th>
                                    <th class="bg-blue text-center">IVA sobre Ventas</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="objectKeys(tablaIvaSobreVentas).length > 0; else elseIva">
                                    <tr>
                                        <th>Ultimos 12m</th>
                                        <td class="text center">{{validarValor(tablaIvaSobreVentas?.notasMeses12) ? ((tablaIvaSobreVentas?.notasMeses12 | number: '1.0-2') + '%') : ''}}</td>
                                        <td class="text center">{{validarValor(tablaIvaSobreVentas?.ivaMeses12) ? ((tablaIvaSobreVentas?.ivaMeses12 | number: '1.0-2') + '%') : ''}}</td>
                                    </tr>
                                    <tr>
                                        <th>Ultimos 6m</th>
                                        <td class="text center">{{validarValor(tablaIvaSobreVentas?.notasMeses6) ? ((tablaIvaSobreVentas?.notasMeses6 | number: '1.0-2') + '%') : ''}}</td>
                                        <td class="text center">{{validarValor(tablaIvaSobreVentas?.ivaMeses6) ? ((tablaIvaSobreVentas?.ivaMeses6 | number: '1.0-2') + '%') : ''}}</td>
                                    </tr>
                                    <ng-container *ngIf="tablaIvaSobreVentas.listaMeses && tablaIvaSobreVentas?.listaMeses.length > 0">
                                        <ng-container *ngFor="let number of [0,1,2,3,4,5]">
                                            <tr>
                                                <th>{{tablaIvaSobreVentas?.listaMeses[number].mes || ''}}</th>
                                                <td class="text center">{{validarValor(tablaIvaSobreVentas?.listaMeses[number].valueNotas) ? ((tablaIvaSobreVentas?.listaMeses[number].valueNotas | number: '1.0-2') + '%') : ''}}</td>
                                                <td class="text center">{{validarValor(tablaIvaSobreVentas?.listaMeses[number].valueIva) ? ((tablaIvaSobreVentas?.listaMeses[number].valueIva | number: '1.0-2') + '%') : ''}}</td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-template #elseIva>
                                    <tr>
                                        <td colspan="100%">Sin información</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    
            <div class="col-sm-12 full-size-print">
                <div class="block full min-height-block">
                    <div class="table-responsive table-entidad">
                        <table id="example-datatable" class="table table-hover table-vcenter table-condensed table-bordered">
                            <thead>
                                <tr>
                                    <th class="bg-blue text-center" colspan="6">TGR Deuda Total {{(totalTGR || totalTGR == 0) ? ('$'):('')}}</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="totalTGR || totalTGR == 0">
                                <tr>
                                    <td class="text-center" colspan="6">{{totalTGR | number: '1.0-2'}}</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th class="bg-blue text-center">Folios</th>
                                    <th class="bg-blue text-center">Fecha de Vcto</th>
                                    <th class="bg-blue text-center">Total Deuda $</th>
                                    <th class="bg-blue text-center">Convenio</th>
                                    <th class="bg-blue text-center">Cuotas</th>
                                    <th class="bg-blue text-center">Cartola Fiscal</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="tablaDeudaMorosaTGR.length > 0; else elseDeudaMorosa">
                                    <ng-container *ngFor="let item of tablaDeudaMorosaTGR">
                                        <tr>
                                            <td class="text-center">{{(item?.folio) ? item?.folio : ''}}</td>
                                            <td class="text-center">{{item?.fechaVencimiento || ''}}</td><!--{{- | date: 'dd-MM-yyyy'}}-->
                                            <td class="text-center">{{(item?.totalDeuda || item?.totalDeuda == 0) ? (item?.totalDeuda | number: '1.0-2') : ''}}</td><!--{{- | number: '1.0-2'}}-->
                                            <td class="text-center">{{(item?.covenio) ? item?.covenio : ''}}</td>
                                            <td class="text-center">{{(item?.cuotas) ? item?.cuotas : ''}}</td>
                                            <td class="text-center">{{(item?.cartola) ? item?.cartola : ''}}</td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                                <ng-template #elseDeudaMorosa>
                                    <tr>
                                        <td colspan="100%">Sin información</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        
            <div class="col-sm-12 col-md-6 full-size-print">
                <div class="block full min-height-block">
                    <div class="block-title">
                        <h2>Listado de Socios</h2>
                    </div>
                    <div class="table-responsive table-entidad">
                        <table id="example-datatable" class="table table-hover table-vcenter table-condensed table-bordered">
                            <thead>
                                <tr>
                                    <th class="bg-blue">Rut socio</th>
                                    <th class="bg-blue">Nombre</th>
                                    <th class="bg-blue">Fecha Vinculación</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="tablaSocios.length > 0; else elseSocios">
                                    <ng-container *ngFor="let item of tablaSocios">
                                        <tr class="pointer-cursor" (click)="abrirModal(item.entidad, item.rut)">
                                            <td>{{item.rut | rutFormat}}</td>
                                            <td>{{item.nombre}}</td>
                                            <td>{{item.fechaVinculacion | date: 'dd-MM-yyyy'}}</td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                                <ng-template #elseSocios>
                                    <tr>
                                        <td colspan="100%">Sin información</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        
            <div class="col-sm-12 col-md-6 full-size-print">
                <div class="block full min-height-block">
                    <div class="block-title">
                        <h2>Ventas cruzadas $M</h2>
                    </div>
                    <div class="table-responsive table-entidad">
                        <table id="example-datatable" class="table table-hover table-vcenter table-condensed table-bordered">
                            <thead>
                                <tr>
                                    <th class="bg-blue text-center pointer-cursor" colspan="4" (click)="abrirModalCompraVenta()">
                                        Ventas cruzadas $M
                                        <i class="fa fa-info-circle pointer-cursor ml-1 not-print" aria-hidden="true" title="Para mas información revisar el modulo Libro Compra Venta"></i>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="bg-blue text-center">Rut</th>
                                    <th class="bg-blue text-center">Deudor</th>
                                    <th class="bg-blue text-center">Compra</th>
                                    <th class="bg-blue text-center">Ventas</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="tablaVentaCruzada.length > 0; else elseVentaCruzada">
                                    <ng-container *ngFor="let item of tablaVentaCruzada">
                                        <tr class="pointer-cursor" (click)="abrirModalCompraVenta()" title="Para mas información revisar el modulo Libro Compra Venta">
                                            <td>{{item?.rut | rutFormat}}</td>
                                            <td>{{item?.nombre || ''}}</td>
                                            <td>{{validarValor(item?.compras) ? ('$' + (item?.compras | number: '1.0-2')) : ''}}</td>
                                            <td>{{validarValor(item?.ventas) ? ('$' + (item?.ventas | number: '1.0-2')) : ''}}</td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                                <ng-template #elseVentaCruzada>
                                    <tr>
                                        <td colspan="100%">Sin información</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    
            <div class="col-sm-12 my-3 full-size-print">
                <div class="block full min-height-block">
                    <div class="table-responsive table-entidad">
                      <table class="table table-hover table-bordered border">
                        <thead>
                          <tr>
                            <th scope="col" colspan="5" class="text-center py-1 bg-primary font-head-size">Factoring con que opera</th>
                          </tr>
                          <tr>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Rut</th>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Nombre</th>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1" colspan="3">Monto Cesiones en M$</th>
                          </tr>
                          <tr>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1">3 Meses</th>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1">6 Meses</th>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1">12 Meses</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-template #sinDatosFactoring>
                            <tr class="height-row">
                              <td colspan="5">Sin información</td>
                            </tr>
                          </ng-template>
          
                          <ng-container *ngIf="factoring.length > 0 else sinDatosFactoring">
                            <ng-container *ngFor="let item of factoring; let i = index">
                              <tr>
                                <td>{{item.CESIONARIO | rutFormat}}</td>
                                <td>{{item.rz_cesionario}}</td>
                                <td>{{item.monto3Meses !== ''? (item.monto3Meses | number: '1.0-0'): item.monto3Meses}}</td>
                                <td>{{item.monto6Meses !== ''? (item.monto6Meses | number: '1.0-0'): item.monto6Meses}}</td>
                                <td>{{item.Monto12Meses !== ''? (item.Monto12Meses | number: '1.0-0'): item.Monto12Meses}}</td>
                              </tr>
                            </ng-container>
                          </ng-container>
          
                        </tbody>
                      </table>
                    </div>
                </div>
              </div>
        
        </div>
        
    </div>
    
    <div class="row py-4 px-5">
    
        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak" (click)="show('anexos')">
            Anexos
        </h3>
        
        <div class="col-sm-12 container-desplegables" id="anexos">
            <div class="row p-5">
                <div class="col-sm-12">
                      
                    <accordion [isAnimated]="true">
                      
                        <!--DICOM-->
                        <ng-container *ngIf="consolidadoTitular && objectKeys(consolidadoTitular).length > 0">
                            <accordion-group heading="Dicom" [isOpen]="false" panelClass="mb-4">
                                <div class="col-sm-12">
                                    <app-ant-consolidado [rut]="rut" [reporteFull]="consolidadoTitular">
                                    </app-ant-consolidado>
                                </div>
                            </accordion-group>
                        </ng-container>
    
                        <!-- poder judicial -->
                        <ng-container *ngIf="reportePoderJudicial">
                            <accordion-group heading="Poder judicial" [isOpen]="false" panelClass="mb-4">
                                <div class="col-sm-12">
                                    <app-poder-judicial-report-viewer [content]="reportePoderJudicial" [showHeader]="false" [fromConsolidado]="true">
                                    </app-poder-judicial-report-viewer>
                                </div>
                            </accordion-group>
                        </ng-container>
    
                        <!-- deuda sbif -->
                        <ng-container *ngIf="reporteDeudaSbif">
                            <accordion-group heading="Deuda Sbif" [isOpen]="false" panelClass="mb-4">
                                <div class="col-sm-12">
                                    <app-deuda-leasing-sbif-viewer [content]='reporteDeudaSbif' [typeTX]="false" [showHeader]="false">
                                    </app-deuda-leasing-sbif-viewer>
                                </div>
                            </accordion-group>
                        </ng-container>
                      
                    </accordion>
    
                </div>
            </div>
        </div>
    
        <div class="col-12 my-2 not-print" *ngIf="showArchivos">
            <app-modulo-archivos [idTransaccion]="idTransaccion" [rut]="rut" [closed]="closed"></app-modulo-archivos>
        </div>    
    
    </div>
</div>


<app-modal-consolidado-workflow [rut]="rutSeleccionado" [idTransaccion]="idTransaccion" [dataServicios]="reportesSeleccionado" [informeComercial]="informeComercial" [default]="modalDefault" [plantilla]="plantillaAntecedentes" [descargar]="true"></app-modal-consolidado-workflow>
