<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<ng-container *ngIf="hasUserAccess">
    
    <div class="row">

        <ng-container *ngIf="seeTable">

            <div class="col-md-9 mx-auto my-4">

                <div class="col-12 text-right" *ngIf="permisosCrear()">
                    <button type="button" class="btn btn-primary" (click)="crearEntidad()">Crear</button>
                </div>

                <div class="col-12">

                    <ng-container *ngIf="listaCampos.length > 0">
                        <div class="card text-center my-4">
                            <div class="card-header">
                                <div class="my-0 row d-flex px-4">
        
                                    <div class="col-xs-3 col-sm-auto mr-auto p-2">
                                        <app-paginator-by-items [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                                    </div>
        
                                    <div class="col-xs-3 col-sm-auto p-2">
                                        <div class="input-group mb-3">
                                            <input id="table-complete-search" type="text" 
                                                class="form-control" name="searchTerm" [(ngModel)]="inputText" 
                                                (keyup)="filtrarObjeto()" placeholder="Filtrar tabla"
                                            >
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <div class="table-responsive">
                                    <table class="table table-bordered table-hover mb-0">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="text-center font-size cursor-pointer min-width-th"
                                            (click)="cambioDeCampo('enabled')">
                                                <span class="text-wrap">
                                                    Habilitado
                                                </span>
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                            <th scope="col" class="text-center font-size cursor-pointer min-width-th"
                                            (click)="cambioDeCampo('nameAttribute')">
                                                <span class="text-wrap">
                                                    Nombre
                                                </span>
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                            <th scope="col" class="text-center font-size cursor-pointer min-width-th"
                                            (click)="cambioDeCampo('tipo')">
                                                <span class="text-wrap">
                                                    Tipo
                                                </span>
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                            <th scope="col" class="text-center font-size cursor-pointer min-width-th-lg"
                                            (click)="cambioDeCampo('creditRequestActive')">
                                                <span class="text-wrap">
                                                    Activo en F. Credito
                                                </span>
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let item of camposFiltrado | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">

                                                <ng-container *ngIf="permisosCrear(); else elseTabla">

                                                    <tr  class="cursor-pointer" (click)="editarCampo(item)">
                                                        <td>
                                                            <ng-container *ngIf="item.enabled === true; else elseEnabled">
                                                                <i class="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>
                                                            </ng-container>
                                                            <ng-template #elseEnabled>
                                                                <i class="fa fa-times-circle fa-lg text-danger" aria-hidden="true"></i>
                                                            </ng-template>
                                                        </td>
            
                                                        <td scope="row" class="font-size text-wrap">{{item.nameAttribute}}</td>
            
                                                        <ng-container *ngIf="item.enabled === true; else elseEnableRows">
                                                            <td scope="row" class="font-size">{{item.tipo}}</td>
                                                            <td>
                                                                <ng-container *ngIf="item.creditRequestActive === true; else elseCredit">
                                                                    <i class="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>
                                                                </ng-container>
                                                                <ng-template #elseCredit>
                                                                    <i class="fa fa-times-circle fa-lg text-danger" aria-hidden="true"></i>
                                                                </ng-template>
                                                            </td>
                                                        </ng-container>
                                                        <ng-template #elseEnableRows>
                                                            <td colspan="2"></td>
                                                        </ng-template>
                                                    </tr>

                                                </ng-container>

                                                <ng-template #elseTabla>

                                                    <tr>
                                                        <td>
                                                            <ng-container *ngIf="item.enabled === true; else elseEnabled">
                                                                <i class="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>
                                                            </ng-container>
                                                            <ng-template #elseEnabled>
                                                                <i class="fa fa-times-circle fa-lg text-danger" aria-hidden="true"></i>
                                                            </ng-template>
                                                        </td>
            
                                                        <td scope="row" class="font-size text-wrap">{{item.nameAttribute}}</td>
            
                                                        <ng-container *ngIf="item.enabled === true; else elseEnableRows">
                                                            <td scope="row" class="font-size">{{item.typeAttibute}}</td>
                                                            <td>
                                                                <ng-container *ngIf="item.creditRequestActive === true; else elseCredit">
                                                                    <i class="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>
                                                                </ng-container>
                                                                <ng-template #elseCredit>
                                                                    <i class="fa fa-times-circle fa-lg text-danger" aria-hidden="true"></i>
                                                                </ng-template>
                                                            </td>
                                                        </ng-container>
                                                        <ng-template #elseEnableRows>
                                                            <td colspan="2"></td>
                                                        </ng-template>
                                                    </tr>
                                                    
                                                </ng-template>
                                                
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="card-footer text-muted">
                                <div class="p-2">
                                    <div id="pagination">
                                        <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                                            <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>
    
            </div>

        </ng-container>

        <ng-container *ngIf="seeForm">

            <div class="col-md-9 mx-auto my-4">

                <div class="card w-100 mx-auto border-0 rounded">
    
                    <div class="card-body p-5">
    
                        <div class="row">
    
                            <div class="col-sm-12 mb-4 px-0">
                                <button type="button" class="btn btn-primary" (click)="volverTabla()">Volver</button>
                            </div>
    
                            <div class="col-sm-12">
                                <div class="col-sm-12 my-1 px-5">
                                    
                                    <form [formGroup]="formularioForm" (ngSubmit)="onSubmitEditar()">
                                        <div class="form-row">
                                            <div class="form-group col-md-10 mx-auto">
                                                <label for="nameAttribute" title="Nombre del atributo" class="cursor-pointer"
                                                    >Nombre del atributo:</label>
                                                <span class="ml-3">{{campoSeleccionado?.nameAttribute || '-'}}</span>
                                            </div>

                                            <div class="form-group col-md-10 mx-auto">
                                                <label for="enabled" title="Habilitado" class="cursor-pointer"
                                                    >Habilitado</label>
                                                <div>
                                                    <label class="switch switch-primary">
                                                        <input type="checkbox" formControlName="enabled">
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-10 mx-auto">
                                                <label for="typeAttibute" title="Tipo de Atributo"
                                                    class="cursor-pointer">
                                                    Tipo de Atributo:
                                                </label>
                                                <span class="ml-3">{{tipoRemplace[campoSeleccionado?.typeAttibute] || campoSeleccionado?.typeAttibute || '-'}}</span>
                                            </div>

                                            <ng-container *ngIf="campoSeleccionado?.typeAttibute === 'Date'"> 
                                                <div class="form-group col-md-10 mx-auto">
                                                    <label for="formatAttribute" title="Formato del atributo"
                                                        class="cursor-pointer">
                                                        Formato del Atributo <span class="text-danger">*</span>
                                                    </label>
                                                    <select class="form-control" id="formatAttribute" formControlName="formatAttribute">
                                                        <option value="">Seleccione una opción</option>
                                                        <option value="DD-MM-YYYY">DD-MM-YYYY</option>
                                                        <option value="DD-MM-YY">DD-MM-YY</option>
                                                        <option value="MM-DD-YY">MM-DD-YY</option>
                                                    </select>
                                                    <div class="w-100 mb-2">
                                                        <app-error [control]="formularioForm.get('formatAttribute')"
                                                        ></app-error>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            
                                            <ng-container *ngIf="campoSeleccionado?.typeAttibute === 'Number'"> 
                                                <div class="form-group col-md-10 mx-auto">
                                                    <label for="formatAttribute" title="Formato del atributo"
                                                        class="cursor-pointer">
                                                        Formato del Atributo <span class="text-danger">*</span>
                                                    </label>
                                                    <select class="form-control" id="formatAttribute" formControlName="formatAttribute">
                                                        <option value="">Seleccione una opción</option>
                                                        <option value="0">0</option>
                                                        <option value="0.0">0.0</option>
                                                        <!--<option value="0,0">0,0</option>-->
                                                    </select>
                                                    <div class="w-100 mb-2">
                                                        <app-error [control]="formularioForm.get('formatAttribute')"
                                                        ></app-error>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </form>

                                    <div class="form-row">
                                        <ng-container *ngIf="campoSeleccionado?.typeAttibute === 'String' || campoSeleccionado?.typeAttibute === 'Number'">
                                            <div class="form-group col-md-10 mx-auto">
                                                <label for="allowedValues" title="Valores Permitidos" class="cursor-pointer"
                                                    >Valores Permitidos</label>
                                                <div>
                                                    <div class="col-md-10 mx-auto mb-2">
                                                        <button type="button" class="btn btn-warning btn-sm" title="Devuelve los valores permitos a su estado inicial">
                                                            <i class="fa fa-undo mx-2" aria-hidden="true" (click)="deshacer()"></i>
                                                        </button>
                                                    </div>                                        
                                                    <div class="col-md-10 mx-auto allowed-size">
                                                        <div class="table-responsive">
                                                            <table class="table table-bordered table-hover border mb-3">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" class="font-size">Opción</th>
                                                                        <th scope="col" class="font-size">Valor</th>
                                                                        <th scope="col" class="text-center">
                                                                            <button type="button" class="btn btn-success btn-mas" (click)="agregarValorEditar()" title="Agregar Valor">
                                                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                                                </button>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>        
                                                                    <ng-container *ngFor="let valor of allowedValuesList; let i = index">
                                                                        <tr>
                                                                            <td class="font-size">{{valor.index}}</td>
                                                                            <td class="font-size"><input 
                                                                                type="text" 
                                                                                class="form-control" 
                                                                                [id]="'allowedValues' + i"
                                                                                [(ngModel)]="allowedValuesList[i].value"
                                                                                (input)="removeInvalidChars($event, false, i)"
                                                                            ></td>
                                                                            <td class="text-center">
                                                                                <button type="button" class="btn btn-danger btn-mas" (click)="quitarValorNuevo(i)" title="Quitar Valor">
                                                                                    <i class="fa fa-minus" aria-hidden="true"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
        
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <form [formGroup]="formularioForm" (ngSubmit)="onSubmitEditar()">
                                        <div class="form-row">

                                            <ng-container *ngIf="validaTypeFormatEdit()">
                                                <div class="form-group col-md-10 mx-auto">
                                                    <ng-container *ngIf="allowedValuesList.length > 0; else elseDefault">
                                                        <label for="defaultValue" title="Opción por defecto" class="cursor-pointer"
                                                        >Opción por defecto <span class="text-danger">*</span></label>
                                                    </ng-container>
                                                    <ng-template #elseDefault>
                                                        <label for="defaultValue" title="Valor por defecto" class="cursor-pointer"
                                                        >Valor por defecto</label>
                                                    </ng-template>
                                                    <input 
                                                        type="{{(campoSeleccionado?.typeAttibute === 'String' || campoSeleccionado?.typeAttibute === 'Number') ? 'text' : campoSeleccionado?.typeAttibute}}" 
                                                        class="form-control" 
                                                        id="defaultValue" 
                                                        [placeholder]="allowedValuesList.length > 0 ? 'Opción por defecto' : 'Valor por defecto'"
                                                        formControlName="defaultValue"
                                                        (input)="removeInvalidChars($event, false)">
                                                    <div class="w-100 mb-2">
                                                        <app-error 
                                                            [control]="formularioForm.get('defaultValue')"
                                                            [validarValid]="campoSeleccionado?.typeAttibute === 'Number' ? true : false"
                                                            [formato]="retornarFormat()"
                                                        ></app-error>
                                                    </div>
                                                </div>
                                            </ng-container>
    
                                            <ng-container *ngIf="validaTypeFormatBooleanEdit()">
                                                <div class="form-group col-md-10 mx-auto">
                                                    <label for="defaultValue" title="Valor por defecto"
                                                        class="cursor-pointer">
                                                        Valor por defecto
                                                    </label>
                                                    <select class="form-control" id="defaultValue" formControlName="defaultValue">
                                                        <option value="">Seleccione una opción</option>
                                                        <option value="true">True</option>
                                                        <option value="false">False</option>
                                                    </select>
                                                    <div class="w-100 mb-2">
                                                        <app-error [control]="formularioForm.get('defaultValue')"
                                                        ></app-error>
                                                    </div>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="objectKeys(seccionesWeb).length > 0">
                                                <div class="form-group col-md-10 mx-auto">
                                                    <label for="sectionWeb" title="Secciones Web" class="cursor-pointer"
                                                        >Secciones Web</label>
                                                    <div>   
                                            
                                                        <ng-container *ngFor="let itemSeccion of objectKeys(seccionesWeb)">
                                            
                                                            <div class="form-check">
                                                                <input #seccionesCampo type="checkbox" 
                                                                    class="form-check-input" 
                                                                    [id]="itemSeccion" 
                                                                    [name]="itemSeccion" 
                                                                    [value]="itemSeccion"
                                                                    [checked]="validaSeccion(itemSeccion)"
                                                                >
                                                                <label class="form-check-label ml-5 cursor-pointer" [for]="itemSeccion">
                                                                    {{seccionesWeb[itemSeccion]?.displayName || itemSeccion || ''}}
                                                                </label>
                                                            </div>
                                            
                                                        </ng-container>
                                            
                                                    </div>
                                                </div>
                                            </ng-container>

                                            <div class="col-md-10 mx-auto mt-4">
                                                <h4 class="font-weight-bold">
                                                    Flujo de Crédito
                                                </h4>
                                                <hr/>
                                            </div>
        
                                            <div class="form-group col-md-10 mx-auto mt-3 px-5">
                                                <label for="creditRequestActive" title="Activo en Flujo de Credito" class="cursor-pointer"
                                                    >Activo</label>
                                                <div>
                                                    <label class="switch switch-primary">
                                                        <input type="checkbox" formControlName="creditRequestActive">
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>

                                            <ng-container *ngIf="validaCreditRequest()">
                                                <div class="form-group col-md-10 mx-auto px-5">
                                                    <label for="section" title="Nombre Sección" class="cursor-pointer"
                                                        >Nombre Sección</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        id="section" 
                                                        placeholder="Nombre Sección"
                                                        formControlName="section" 
                                                        maxlength="100"
                                                        (input)="validaSection($event)"
                                                    >
                                                    <div class="w-100 mb-2">
                                                        <app-error [control]="formularioForm.get('section')"
                                                        ></app-error>
                                                    </div>
                                                </div>
                                            </ng-container>

                                        </div>
                                    </form>

                                    <div class="form-row">
                                        <ng-container *ngIf="validaCreditRequest()">
                                            <div class="form-group col-md-10 mx-auto px-5">
                                                <label for="models" title="Modelos" class="cursor-pointer"
                                                    >Modelos</label>
                                                <div>   
    
                                                    <ng-container *ngFor="let itemModelo of modelos">
    
                                                        <div class="form-check">
                                                            <input #modelosCampo type="checkbox" 
                                                                class="form-check-input" 
                                                                [id]="itemModelo?.modeloId" 
                                                                [name]="itemModelo?.modeloId" 
                                                                [value]="itemModelo?.modeloId"
                                                                [checked]="validaModelo(itemModelo?.modeloId)"
                                                                [title]="itemModelo?.descripcion"
                                                            >
                                                            <label class="form-check-label ml-5" [title]="itemModelo?.descripcion" [for]="itemModelo?.modeloId">
                                                                {{itemModelo?.displayName || itemModelo?.modeloId || ''}}
                                                            </label>
                                                        </div>
    
                                                    </ng-container>
    
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>

                                </div>
                                <div class="col-sm-12 my-1 text-center">                                        
                                    <button class="btn btn-primary mx-2" (click)="onSubmitEditar()">Guardar</button>
                                </div>
                            </div>
    
                        </div>

                    </div>
    
                </div>

            </div>

        </ng-container>

        <ng-container *ngIf="seeFormCrear">

            <div class="col-md-9 mx-auto my-4">

                <div class="card w-100 mx-auto border-0 rounded">
    
                    <div class="card-body p-5">
    
                        <div class="row">
    
                            <div class="col-sm-12 mb-4 px-0">
                                <button type="button" class="btn btn-primary" (click)="volverTabla()">Volver</button>
                            </div>
    
                            <div class="col-sm-12">
                                <div class="col-sm-12 my-1 px-5">
                                    
                                    <form [formGroup]="formularioForm" (ngSubmit)="onSubmitCrear()">

                                        <div class="form-row">
                                            <div class="form-group col-md-10 mx-auto">
                                                <label for="nameAttribute" title="Nombre del atributo" class="cursor-pointer"
                                                    >Nombre <span class="text-danger">*</span></label>
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    id="nameAttribute" 
                                                    placeholder="Nombre del atributo"
                                                    formControlName="nameAttribute"
                                                    (input)="validaName($event)"
                                                >
                                                <div class="w-100 mb-2">
                                                    <app-error [control]="formularioForm.get('nameAttribute')"
                                                    ></app-error>
                                                </div>
                                            </div>
    
                                            <div class="form-group col-md-10 mx-auto">
                                                <label for="enabled" title="Habilitado" class="cursor-pointer"
                                                    >Habilitado</label>
                                                <div>
                                                    <label class="switch switch-primary">
                                                        <input type="checkbox" formControlName="enabled">
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>
    
                                            <div class="form-group col-md-10 mx-auto">
                                                <label for="typeAttibute" title="Tipo de Atributo"
                                                    class="cursor-pointer">
                                                    Tipo de Atributo <span class="text-danger">*</span>
                                                </label>
                                                <select class="form-control" id="typeAttibute" formControlName="typeAttibute">
                                                    <option value="">Seleccione una opción</option>
                                                    <option value="String">Texto</option>
                                                    <option value="Date">Fecha</option>
                                                    <option value="Number">Numero</option>
                                                    <option value="Boolean">True/False</option>
                                                </select>
                                                <div class="w-100 mb-2">
                                                    <app-error [control]="formularioForm.get('typeAttibute')"
                                                    ></app-error>
                                                </div>
                                            </div>
                                            
                                            <ng-container *ngIf="validaTypeDate()"> 
                                                <div class="form-group col-md-10 mx-auto">
                                                    <label for="formatAttribute" title="Formato del atributo"
                                                        class="cursor-pointer">
                                                        Formato del Atributo <span class="text-danger">*</span>
                                                    </label>
                                                    <select class="form-control" id="formatAttribute" formControlName="formatAttribute">
                                                        <option value="">Seleccione una opción</option>
                                                        <option value="DD-MM-YYYY">DD-MM-YYYY</option>
                                                        <option value="DD-MM-YY">DD-MM-YY</option>
                                                        <option value="MM-DD-YY">MM-DD-YY</option>
                                                    </select>
                                                    <div class="w-100 mb-2">
                                                        <app-error [control]="formularioForm.get('formatAttribute')"
                                                        ></app-error>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            
                                            <ng-container *ngIf="validaTypeNumber()"> 
                                                <div class="form-group col-md-10 mx-auto">
                                                    <label for="formatAttribute" title="Formato del atributo"
                                                        class="cursor-pointer">
                                                        Formato del Atributo <span class="text-danger">*</span>
                                                    </label>
                                                    <select class="form-control" id="formatAttribute" formControlName="formatAttribute">
                                                        <option value="">Seleccione una opción</option>
                                                        <option value="0">0</option>
                                                        <option value="0.0">0.0</option>
                                                        <!--<option value="0,0">0,0</option>-->
                                                    </select>
                                                    <div class="w-100 mb-2">
                                                        <app-error [control]="formularioForm.get('formatAttribute')"
                                                        ></app-error>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>

                                    </form>

                                    <ng-container *ngIf="validaTypeFormat() && !validaTypeBoolean() && !validaTypeDate()">
                                        <div class="form-row">
                                            <div class="form-group col-md-10 mx-auto">
                                                <label for="allowedValues" title="Valores Permitidos" class="cursor-pointer"
                                                    >Valores Permitidos</label>
                                                <div>                                                        
                                                    <div class="col-md-10 mx-auto allowed-size">
                                                        <div class="table-responsive">
                                                            <table class="table table-bordered table-hover border mb-3">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" class="font-size">Opción</th>
                                                                        <th scope="col" class="font-size">Valor</th>
                                                                        <th scope="col" class="text-center">
                                                                            <button type="button" class="btn btn-success btn-mas" (click)="agregarValor()" title="Agregar Valor">
                                                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                                                </button>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>        
                                                                    <ng-container *ngFor="let valor of allowedValuesList; let i = index">
                                                                        <tr>
                                                                            <td class="font-size">{{i + 1}}</td>
                                                                            <td class="font-size"><input 
                                                                                type="text" 
                                                                                class="form-control" 
                                                                                [id]="'allowedValues' + i"
                                                                                [(ngModel)]="allowedValuesList[i].value"
                                                                                (input)="removeInvalidChars($event, true, i)"
                                                                            ></td>
                                                                            <td class="text-center">
                                                                                <button type="button" class="btn btn-danger btn-mas" (click)="quitarValorNuevo(i)" title="Quitar Valor">
                                                                                    <i class="fa fa-minus" aria-hidden="true"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>    
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <form [formGroup]="formularioForm" (ngSubmit)="onSubmitCrear()">

                                        <div class="form-row">
                                            <ng-container *ngIf="validaTypeFormat() && !validaTypeBoolean()">
                                                <div class="form-group col-md-10 mx-auto">
                                                    <ng-container *ngIf="allowedValuesList.length > 0; else elseDefault">
                                                        <label for="defaultValue" title="Opción por defecto" class="cursor-pointer"
                                                        >Opción por defecto <span class="text-danger">*</span></label>
                                                    </ng-container>
                                                    <ng-template #elseDefault>
                                                        <label for="defaultValue" title="Valor por defecto" class="cursor-pointer"
                                                        >Valor por defecto</label>
                                                    </ng-template>
                                                    <input 
                                                        type="{{(retornarType() === 'String' || retornarType() === 'Number') ? 'text' : retornarType()}}" 
                                                        class="form-control" 
                                                        id="defaultValue"
                                                        [placeholder]="allowedValuesList.length > 0 ? 'Opción por defecto' : 'Valor por defecto'"
                                                        formControlName="defaultValue"
                                                        (input)="removeInvalidChars($event, true)">
                                                    <div class="w-100 mb-2">
                                                        <app-error 
                                                            [control]="formularioForm.get('defaultValue')"
                                                            [validarValid]="retornarType() === 'Number' ? true : false"
                                                            [formato]="retornarFormat()"
                                                        ></app-error>
                                                    </div>
                                                </div>
                                            </ng-container>
    
                                            <ng-container *ngIf="validaTypeBoolean()">
                                                <div class="form-group col-md-10 mx-auto">
                                                    <label for="defaultValue" title="Valor por defecto"
                                                        class="cursor-pointer">
                                                        Valor por defecto
                                                    </label>
                                                    <select class="form-control" id="defaultValue" formControlName="defaultValue">
                                                        <option value="">Seleccione una opción</option>
                                                        <option value="true">True</option>
                                                        <option value="false">False</option>
                                                    </select>
                                                    <div class="w-100 mb-2">
                                                        <app-error [control]="formularioForm.get('defaultValue')"
                                                        ></app-error>
                                                    </div>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="objectKeys(seccionesWeb).length > 0">
                                                <div class="form-group col-md-10 mx-auto">
                                                    <label for="sectionWeb" title="Secciones Web" class="cursor-pointer"
                                                        >Secciones Web</label>
                                                    <div>   
                                            
                                                        <ng-container *ngFor="let itemSeccion of objectKeys(seccionesWeb)">
                                            
                                                            <div class="form-check">
                                                                <input #seccionesCampo type="checkbox" 
                                                                    class="form-check-input" 
                                                                    [id]="itemSeccion" 
                                                                    [name]="itemSeccion" 
                                                                    [value]="itemSeccion"
                                                                >
                                                                <label class="form-check-label ml-5 cursor-pointer" [for]="itemSeccion">
                                                                    {{seccionesWeb[itemSeccion]?.displayName || itemSeccion || ''}}
                                                                </label>
                                                            </div>
                                            
                                                        </ng-container>
                                            
                                                    </div>
                                                </div>
                                            </ng-container>

                                            <div class="col-md-10 mx-auto mt-4">
                                                <h4 class="font-weight-bold">
                                                    Flujo de Crédito
                                                </h4>
                                                <hr/>
                                            </div>
    
                                            <div class="form-group col-md-10 mx-auto mt-2 px-5">
                                                <label for="creditRequestActive" title="Activo en Flujo de Credito" class="cursor-pointer"
                                                    >Activo</label>
                                                <div>
                                                    <label class="switch switch-primary">
                                                        <input type="checkbox" formControlName="creditRequestActive">
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </div>
    
                                            <ng-container *ngIf="validaCreditRequest()">
                                                <div class="form-group col-md-10 mx-auto px-5">
                                                    <label for="section" title="Nombre Sección" class="cursor-pointer"
                                                        >Nombre Sección</label>
                                                    <input 
                                                        type="text" 
                                                        class="form-control" 
                                                        id="section" 
                                                        placeholder="Nombre Sección"
                                                        formControlName="section" 
                                                        maxlength="100"
                                                        (input)="validaSection($event)"
                                                    >
                                                    <div class="w-100 mb-2">
                                                        <app-error [control]="formularioForm.get('section')"
                                                        ></app-error>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>


                                    </form>

                                    <ng-container *ngIf="validaCreditRequest()">
                                        <div class="form-row">
                                            <div class="form-group col-md-10 mx-auto px-5">
                                                <label for="models" title="Modelos" class="cursor-pointer"
                                                    >Modelos</label>
                                                <div>   
    
                                                    <ng-container *ngFor="let itemModelo of modelos">
    
    
                                                        <div class="form-check">
                                                            <input #modelosCampo type="checkbox" 
                                                                class="form-check-input"
                                                                [id]="itemModelo?.modeloId" 
                                                                [name]="itemModelo?.modeloId" 
                                                                [value]="itemModelo?.modeloId"
                                                                [title]="itemModelo?.descripcion"
                                                            >
                                                            <label class="form-check-label ml-5" [title]="itemModelo?.descripcion" [for]="itemModelo?.modeloId">
                                                                {{itemModelo?.displayName || itemModelo?.modeloId || ''}}
                                                            </label>
                                                        </div>
    
    
                                                    </ng-container>
    
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="col-sm-12 my-1 text-center">                                        
                                        <button class="btn btn-primary mx-2" (click)="onSubmitCrear()">Guardar</button>
                                    </div>
                                </div>
    
                            </div>

                        </div>
    
                    </div>

                </div>
            </div>

        </ng-container>
        
    </div>

</ng-container>