<div class="card w-100 mx-auto rounded-0 modulo-financiero" *ngIf="!showReport">
    <div class="card-header py-1">
        <div class="row d-flex align-items-center flex-nowrap">
            <h5 class="card-ventas mr-auto p-2 ml-4 mb-0">Carpeta Tributaria</h5>
            <button (click)="crearBoton()" class="btn btn-primary mr-3 p-2">Nuevo</button>
        </div>
    </div>
    <div class="card-body overflow-auto p-1">
        <div *ngIf="hasUserAccess && searchReportDataSource.length > 0 && !showReport">
            <div class="table-responsive table-hover table-bordered">
                <table id="example-datatable" class="table table-vcenter table-condensed table-bordered">
                    <thead>
                        <tr>
                            <th class="text-center py-1">ID Transaccion</th>
                            <th class="text-center py-1">Fecha</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let report of searchReportDataSource | orderBy: 'FechaReporte': 'desc'" (click)="displayReport(report?.IDTransaccion)">
                            <td class="text-center">{{report?.IDTransaccion}}</td>
                            <td class="text-center">
                                {{report?.FechaReporte | date: 'dd-MM-YYYY'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf="searchReportDataSource.length == 0 && textoSinInfo != ''">
            <div class="container d-flex justify-content-center align-items-center">
                <p>{{textoSinInfo}}</p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showReport">
    <div class="container-gtp no-print">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="d-flex">
                    <button (click)="closeReport()" class="btn btn-primary mr-auto p-2">Volver</button>
                    <a *ngIf="pdfDescargaCTE" type="button" class="btn btn-primary p-2 mx-2" [attr.href]="pdfDescargaCTE" target="_blank">
                        Descargar CTE</a>
                    <button *ngIf="showReport" [printTitle]='"ReporteFinanciero_"+(rut | rutFormat)+"_"+today'
                     [useExistingCss]="true" printSectionId="print-section" ngxPrint class="btn btn-primary p-2 mx-2">
                     Descargar PDF</button>
                </div>
            </div>
        </div>
    </div>
    <div id="print-section">
        <app-reporte-full-viewer [reporteFullContent]="reporteFullContent" [informacionErrores]="informacionErrores" [nombreBasico]="nombreBasico"></app-reporte-full-viewer>
    </div>
</div>