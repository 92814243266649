import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OfertasApiService } from '../services/ofertas-api.service';
import { OfferType } from '../models/OfertaFinanciera';
import { of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { SessionService } from 'src/app/shared/services/session.service';
import { IUserInfoCognito } from 'src/app/shared/services/cognito.service';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { DatePipe } from '@angular/common';
import { AlertService } from 'src/app/components/_alert';
import { Location } from '@angular/common';

@Component({
  selector: 'app-new-edit-oferta',
  templateUrl: './new-edit-oferta.component.html',
  styleUrls: ['./new-edit-oferta.component.scss']
})
export class NewEditOfertaComponent implements OnInit {
  
  public verticalesContract: OfferType[] = [];
  public users: string[] = [];
  public filteredUsers: Observable<string[]> = of([]);
  public showUsers = false;
  public offerId: string | null = null;
  public label = "Crear";
  public disableEdit = false;
  
  // Cambiar nombres del form
  offerFilterForm = new FormGroup({
    titleInput: new FormControl('', Validators.required),
    userInput: new FormControl('', Validators.required),
    amountStartInput: new FormControl(''),
    amountEndInput: new FormControl(''),
    rateInput: new FormControl('', Validators.required),
    dueDateInput: new FormControl('', Validators.required),
    descriptionDetailInput: new FormControl('', Validators.required),
    descriptionIntroInput: new FormControl('', Validators.required),
    typeCombo: new FormControl('', Validators.required),
    expirationInput: new FormControl('', Validators.required)
  });

  constructor(
    private _apiService: OfertasApiService,
    private _spinner: NgxSpinnerService,
    private _sessionService: SessionService,
    private _router: Router,
    private _route: ActivatedRoute,
    private datePipe: DatePipe,
    public _alertService: AlertService,
    private location: Location
  ) { }

  ngOnInit(): void {    
    this.offerId = this._route.snapshot.paramMap.get('id');

    this._spinner.show();
    this.filteredUsers = this.offerFilterForm.get("userInput")!.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    // Obtener contrato maestro de verticales
    this._apiService.obtenerContratoVerticales().subscribe(
      (contract) => {
        this.verticalesContract = contract;
        console.log(this.verticalesContract)
        this._spinner.hide();
      },
      (error) => {
        this._spinner.hide();
        this._alertService.error('Error al intentar obtener el listado de verticales financieras');
      }
    )
    // Obtener usuarios sincronizados a la vertical
    this._apiService.getUsuariosSincronizados().subscribe(
      (response) => {
        this.users = response[0].map((item: any) => item.id_organizacion_usuario.toString());
      },
      (error) => {
        this._alertService.error('Error al intentar obtener el listado de usuarios');
      }
    )

    if(this.offerId) {
      this._spinner.show();
      this.label = "Editar";
      this._apiService.getOfertaById(this.offerId).subscribe(
        (response) => {
          this._spinner.hide();
          this._setOfferValues(response);
        },
        (error) => {
          this._spinner.hide();
          this._alertService.error('Error al intentar obtener los datos de la oferta');
        }
      );
    }
  }

  private _setOfferValues(offer: any) {
    console.log(offer)
    this.offerFilterForm.patchValue({
      titleInput: offer.title,
      userInput: offer.idOrganizacionUsuario,
      amountStartInput: offer.amount.start, // Example numeric value
      amountEndInput: offer.amount.end,   // Example numeric value
      rateInput: offer.rate,          // Example numeric value
      dueDateInput: offer.dueDate, // Example date string
      descriptionDetailInput: offer.description.body,
      descriptionIntroInput: offer.description.introduction,
      typeCombo: offer.type,
      expirationInput: formatDate(new Date(offer.clientExpTime * 1000), 'yyyy-MM-dd', 'en')
    });
    if(offer.accepted) this.offerFilterForm.disable();
    
  }

  getFormattedDate(clientExpTime: number): string | null{
    const date = new Date(clientExpTime * 1000);

    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }

  private _filter(value: string): string[] {
    if(value == "" || value == undefined || value == null){
      return [];
    }
    const filterValue = value.toLowerCase();

    return this.users.filter(option => option.toLowerCase().includes(filterValue)).slice(0, 5);
  }

  selectOrg(event: string) {
    this.offerFilterForm.get("userInput")!.setValue(event);
    this.filteredUsers = this.offerFilterForm.get("userInput")!.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  onBlur(event: Event): void {
    setTimeout(() => {
      const input = (event.target as HTMLInputElement).value;
      if (!this.users.some(option => option === input)) {
        this.offerFilterForm.get("userInput")?.setValue('');
      }
    }, 1000)
  }

  submit() {
    
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo();
    const idUsuario = userInfo.email
    const idOrganizacion = this._sessionService.getOrganization();
    const formValues = this.offerFilterForm.value;
    
    // Generar body de la oferta a crear
    const requestBody = {
      "idUsuario": idUsuario,
      "idOrganizacion":idOrganizacion,
      "requestOrigin": "WEB",
      "offer": {
        "idOferta": this.offerId,
        "title": formValues.titleInput,
        "description": {
          "introduction": formValues.descriptionIntroInput,
          "body": formValues.descriptionDetailInput
        },
        "type": formValues.typeCombo,
        "amount": {
          "start": formValues.amountStartInput,
          "end": formValues.amountEndInput
        },
        "rate": formValues.rateInput,
        "dueDate": formValues.dueDateInput,
        "accepted": false,
        "idOrganizacionUsuario": formValues.userInput,
        "idOrganizacionCliente": idOrganizacion,
        "clientExpTime": this.dateToUnixTimestamp(formValues.expirationInput!),
        "deleted": false
      }
    }
    
    this._spinner.show();
    this._apiService.crearOferta(requestBody).subscribe(
      (response) => {
        this._spinner.hide();
        // Volver al listado
        this._router.navigate([`/ofertas-financieras`])
      },
      (error) => {
        this._spinner.hide();
        // toaster con error
        this._alertService.error('Error al intentar obtener crear o editar la oferta');
      }
    )
  }

  back() {
    this.location.back();
  }

  dateToUnixTimestamp(dateString: string): number {
    // Split the date string into year, month, and day components
    const [year, month, day] = dateString.split('-').map(Number);
  
    // Create a new Date object using the components
    const date = new Date(year, month - 1, day); // Month is zero-based in Date constructor
  
    // Get the Unix timestamp (in milliseconds) and convert it to seconds
    const unixTimestamp = date.getTime() / 1000;

    return unixTimestamp;
  } 
}
