import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  @Input() value: number = 0;
  public chart: Chart = new Chart;
  public color: string = '';


  ngOnInit(): void {
    this.color = this.setColor();
    
    this.chart = new Chart({
      chart: {
        renderTo: 'container',
        height: 200,
        plotBackgroundColor: '',
        plotBackgroundImage: '',
        plotBorderWidth: 0,
        margin: [0,0,0,15],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
        plotShadow: false,
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: ``
      },
      pane: {
        center: ['50%', '75%'],
        size: '50%',
        startAngle: -90,
        endAngle: 90,
      },
      yAxis: [{
        lineWidth: 0,
        min: 0,
        max: 90,
        minorTickLength: 0,
        tickLength: 0,
        tickWidth: 0,
        labels: {
          enabled: false
        },
        pane: 0,

      }],
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
            distance: -50,
            style: {
              fontWeight: 'bold',
              color: 'red',
              textShadow: '0px 1px 2px black'
            }
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%']
        },
        gauge: {
          dataLabels: {
            enabled: false
          },
          dial: {
            radius: '100%'
          }
        }
      },
      series: [{
        type: 'pie',
        name: 'Riesgo',
        innerSize: '50%',
        data: [
          ['Alto', 9],
          ['Alto', 9],
          ['Medio Alto', 9],
          ['Medio Alto', 9],
          ['Medio', 9],
          ['Medio', 9],
          ['Medio Bajo', 9],
          ['Medio Bajo', 9],
          ['Bajo', 9],
          ['Bajo', 9],
        ],
        colors: ['#e62e2d', '#e62e2d', '#ed9720', '#ed9720', '#fdfe01',
          '#fdfe01', '#8ffa7e', '#8ffa7e', '#53af50', '#53af50'],
      }, {
        type: 'gauge',
        data: [this.setRange(this.value)],
      }],
    });
  }

  setRange(value: number): number {
    const round = Math.round(value);
    switch (true) {
      case (round >= 900 && round <= 999 ):
        return 80;
      case (round >= 800 && round <= 899 ):
        return 73;
      case (round >= 700 && round <= 799 ):
        return 63;
      case (round >= 600 && round <= 699 ):
        return 56;
      case (round >= 500 && round <= 599 ):
        return 49;
      case (round >= 400 && round <= 499 ):
        return 42;
      case (round >= 300 && round <= 399 ):
        return 34;
      case (round >= 200 && round <= 299 ):
        return 26;
      case (round >= 100 && round <= 199 ):
        return 18;
      default:
        return 10;
    }
  }

  setColor(): string {
    switch (true) {
      case (this.value <= 199):
        return 'alto';
      case (this.value >= 200 && this.value <= 399):
        return 'medioAlto';
      case (this.value >= 400 && this.value <= 599):
        return 'medio';
      case (this.value >= 600 && this.value <= 799):
        return 'medioBajo';
      default:
        return 'bajo';
    }
  }

}
