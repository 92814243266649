<div class="container">
    <!-- Table Header -->
    <div class="row">
      <div class="col-12">
        <div class="row align-items-center" style="width: 100%;">
            <!-- Button on the left -->
            <div class="col-auto">
              <button class="btn btn-primary" (click)="back()">Volver</button>
            </div>
        
            <!-- Spacer for centering the h2 -->
            <div class="col text-center">
              <h2>Editar cartera</h2>
            </div>
        </div>
      </div>
    </div>
  
    <!-- Card Table Info -->
    <div class="row mt-5" *ngIf="detail">
      <div class="col-12 d-flex justify-content-center">
        <div class="table-responsive" style="max-width: 316px;">
          <table class="table table-bordered bg-white">
            <tbody>
              <tr>
                <th class="bg-primary">ID Cartera</th>
                <td>{{detail.id}}</td>
              </tr>
              <tr>
                <th class="bg-primary">Nombre Cartera</th>
                <td>
                  <input type="text" [formControl]="nameControl" style="width: 100%">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>


    <div class="card text-center my-4">

        <form [formGroup]="formularioArchivo">
            <div class="row">
                <div class="col-12">
                    <div class="form-group ml-2 my-5">
            
                        <div class="col">
                            <label class="uploadbutton">
                                <input class="input-file" formControlName="file" type="file" name="file" accept=".csv"
                                    (change)="handleUpload($event)" id="file-csv-carga-automatica"/>
                                <!-- <div class="button btn">Nueva Carga</div> -->
                                <!-- <div class='input-file-text' [ngClass]="{'text-success': formularioArchivo.controls.file.value}">
                                    {{formularioArchivo.controls.file.value || 'Subir Archivo'}}
                                </div> -->
                            </label>
                            <div (click)="submitArchivo()" class="upload-file btn bg-warning" *ngIf="formularioArchivo.controls.file.value">
                                <span class="material-icons">upload</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <a 
                        id="descargaejemplo"
                        class="text-muted"
                        title="Descargar archivo"
                        href="../assets/files/ejemploCrearCartera.csv"
                        download
                    >
                        Descargar archivo de ejemplo
                        <i class="fa fa-download text-success align-middle mx-2" 
                            aria-hidden="true"
                        ></i>
                    </a>
                </div>
                <div class="col-12">
                    <span 
                        class="d-inline-block mb-3 text-muted cursor-pointer cursor-pointer-info" 
                        title="Presione para obtener mas información"
                        style="cursor:pointer;"
                        >
                        Archivo de carga debe ser csv con separador ; y tener un máximo de 100 registros
                        <i class="fa fa-exclamation-circle text-warning" aria-hidden="true"></i>
                    </span>
                </div>
            </div>

        </form>

      </div>
      <button class="btn btn-primary mt-5" style="max-width: 150px;" (click)="submitArchivo()">Enviar</button>
</div>