<div class="card">
    <h5 class="card-header list-group-item cuadro-color">
        Condición de Ventas</h5>
    <div class="card-body custom-body">
        <ng-container *ngIf="fieldsReady">

            <form [formGroup]="condicionesVentasForm">
                <div class="row">

                    <ng-container *ngFor="let field of camposPersonalizados">
                        <ng-container *ngIf="validarIfExist(field) && excludeFields(field)">
                            <div class="col-sm-6" *ngIf="searchDoubleFields(field).length !== 3">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <ng-template #montoConintereses>
                                            <ng-template #textoConInteres>
                                                Monto Total con Intereses
                                            </ng-template>
                                            
                                            <ng-container *ngIf="filterByControl(field)?.nameAttribute !== 'Monto Total Interes'; else textoConInteres">
                                                {{filterByControl(field)?.nameAttribute}}
                                            </ng-container>
                                        </ng-template>
                                        <ng-container
                                            *ngIf="tipoSolicitud !== 'ventaNueva'; else montoConintereses">
                                            {{filterByControl(field)?.nameAttribute}}
                                        </ng-container>
                                    </div>
                                    <ng-container [ngSwitch]="searchDoubleFields(field).length">
                                        <ng-template [ngSwitchCase]="0">
                                            <div class="col-sm-8">
                                                <ng-template #selectTypeFieldFirst>
                                                    <ng-container [ngSwitch]="filterByControl(field)?.typeAttibute">
                                                        <ng-template [ngSwitchCase]="'String'">
                                                            <div class="form-control text-capitalize" disabled>
                                                                {{condicionesVentasForm.get(field)?.value}}
                                                            </div>
                                                        </ng-template>
                                                        <ng-template ngSwitchDefault>
                                                            <ng-template #TasaMensual>
                                                                <div class="input-group min-colunm">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text">%</span>
                                                                    </div>
                                                                    <div class="form-control" disabled>
                                                                        {{condicionesVentasForm.get(field)?.value |
                                                                        number:
                                                                        '1.0-2'}}
                                                                    </div>
                                                                </div>
                                                            </ng-template>

                                                            <ng-container
                                                                *ngIf="field !== 'TasaMensual'; else TasaMensual">
                                                                <div class="form-control text-capitalize" disabled>
                                                                    {{condicionesVentasForm.get(field)?.value | number:
                                                                    '1.0-2'}}
                                                                </div>
                                                            </ng-container>
                                                        </ng-template>
                                                    </ng-container>
                                                </ng-template>

                                                <ng-container
                                                    *ngIf="objectKeys(filterByControl(field)?.allowedValues).length > 0; else selectTypeFieldFirst">
                                                    <div class="text-capitalize form-control" disabled>
                                                        {{findTextCamposPersonalizados(field)}}
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="2">
                                            <div class="col-sm-4 pr-0">
                                                <div class="form-control" disabled>
                                                    {{condicionesVentasForm.get(filterByControl(searchDoubleFields(field)[0])?.nameAttribute)?.value}}
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="text-capitalize form-control" disabled>
                                                    {{findTextCamposPersonalizados(field)}}
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="4">
                                            <div class="col-sm-4 pr-0">
                                                <div class="input-group min-colunm">
                                                    <div class="input-group-prepend">
                                                        <span
                                                            class="input-group-text">{{searchDoubleFields(field)[0]}}</span>
                                                    </div>
                                                    <ng-template #selectTypeFieldSecond>

                                                        <ng-container
                                                            [ngSwitch]="filterByControl(searchDoubleFields(field)[1])?.typeAttibute">
                                                            <ng-template [ngSwitchCase]="'String'">
                                                                <div class="form-control" disabled>
                                                                    {{condicionesVentasForm.get(searchDoubleFields(field)[1])?.value}}
                                                                </div>
                                                            </ng-template>
                                                            <ng-template ngSwitchDefault>
                                                                <div class="form-control" disabled>
                                                                    {{condicionesVentasForm.get(searchDoubleFields(field)[1])?.value
                                                                    |
                                                                    number: '1.0-0'}}
                                                                </div>
                                                            </ng-template>
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-container
                                                        *ngIf="objectKeys(filterByControl(searchDoubleFields(field)[1])?.allowedValues).length > 0; else selectTypeFieldSecond">
                                                        <div class="form-control text-capitalize" disabled>
                                                            {{
                                                            findTextCamposPersonalizados(searchDoubleFields(field)[1])
                                                            }}
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="input-group min-colunm">
                                                    <div class="input-group-prepend">
                                                        <span
                                                            class="input-group-text">{{searchDoubleFields(field)[2]}}</span>
                                                    </div>
                                                    <ng-template #selectTypeFieldThird>
                                                        <ng-container
                                                            [ngSwitch]="filterByControl(searchDoubleFields(field)[3])?.typeAttibute">
                                                            <ng-template [ngSwitchCase]="'String'">
                                                                <div class="form-control" disabled>
                                                                    {{condicionesVentasForm.get(searchDoubleFields(field)[3])?.value}}
                                                                </div>
                                                            </ng-template>
                                                            <ng-template ngSwitchDefault>
                                                                <div class="form-control" disabled>
                                                                    {{condicionesVentasForm.get(searchDoubleFields(field)[3])?.value
                                                                    | number: '1.0-0'}}
                                                                </div>
                                                            </ng-template>
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-container
                                                        *ngIf="objectKeys(filterByControl(searchDoubleFields(field)[3])?.allowedValues).length > 0; else selectTypeFieldThird">
                                                        <div class="form-control" disabled>
                                                            {{
                                                            findTextCamposPersonalizados(searchDoubleFields(field)[3])
                                                            }}
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </form>
        </ng-container>
    </div>
</div>