import { Component, EventEmitter, OnInit, Output, SecurityContext } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { SessionService } from 'src/app/shared/services/session.service';
import { rutValidate } from 'rut-helpers';
import { RUT } from 'src/app/shared/utils/rut';
import { environment as env } from 'src/environments/environment';
import { TesoreriaService } from 'src/app/shared/services/tesoreria.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { Subject } from 'rxjs';

interface RutFormValue {
  rut: string;
}

@Component({
  selector: 'app-reporte-tesoreria-buscar',
  templateUrl: './reporte-tesoreria-buscar.component.html',
  styleUrls: ['./reporte-tesoreria-buscar.component.scss']
})
export class ReporteTesoreriaBuscarComponent implements OnInit {
  private access: string[] = [ESystemAccess.TESORERIA_BUSCAR, ESystemProfileList.WEB];
  public showBuscador: boolean = true;
  public showList: boolean = false;
  public showReporte: boolean = false;

  public rutForm!: UntypedFormGroup;
  public activateRutHelper = false;
  public rut: string = '';
  public hasUserAccess = false;
  public today: string = '';
  public idTransaccionEntrada: string = '';

  public listaReportes: any[] = [];
  public listaReportesFiltrado: any[] = [];
  public reporte: any = null;

  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public inputText = '';
  public order = '';
  public campoOrder = '';

  public mapeoEstados: any = {
    "IN-PROGRESS": "En Progreso",
    "COMPLETED": "Completado",
    "ERROR": "Error"
  }

  public isResumida = false;
  printClick: Subject<void> = new Subject<void>()

  constructor(
    private formBuilder: UntypedFormBuilder,
    public alertService: AlertService,
    private _sessionService: SessionService,
    private spinner: NgxSpinnerService,
    private tesoreriaService: TesoreriaService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private sanitizer: DomSanitizer
  ) {
    this.rutForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
    }) as FormGroupTyped<RutFormValue>;

    this.cambiosEnUrl();
    this.onChanges();
  }

  ngOnInit(): void {
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
      //this.datosPorUrl();
    }
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.setToday();
  }

  onPrintClick() {
    this.printClick.next();
  }

  onHoverCompleta() {
    this.isResumida = false
  }

  onHoverResumida() {
    this.isResumida = true
  }

  cambiosEnUrl(): void {
    this.route.queryParamMap.subscribe((val: any) => {
      const rut = val?.params?.['rut'];
      const idTx = val?.params?.['idTransaccion'];
      this.datosPorUrl(rut, idTx)
    });
  }

  datosPorUrl(rut: string | null, idTransaccion: string | null): void {
    const rutSeguro = this.sanitizer.sanitize(SecurityContext.HTML, rut);
    const idTransaccionSeguro = this.sanitizer.sanitize(SecurityContext.HTML, idTransaccion);

    if(!rutSeguro){
      return
    }

    if(!rutValidate(rutSeguro)){
      this.alertService.error('Rut detectado inválido.');
      return
    }

    this.rut = rutSeguro;
    if(idTransaccionSeguro){
      this.idTransaccionEntrada = idTransaccionSeguro;
    }
    this.consultaBuscarReportes(rutSeguro);
  }

  filtrarObjeto(): void {
    this.currentPage = 0;
    this.listaReportesFiltrado = this.inputText != '' ? this.listaReportes.filter((item: any) => 
    item?.IDTransaccion?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.EstadoTransaccionMapeada?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.FechaFormatted?.toUpperCase().includes(this.inputText.toUpperCase())) : this.listaReportes;
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      if(campo === 'FechaReporte'){
        this.listaReportesFiltrado.sort((a, b) => {
          const fechaA: any = new Date(a.FechaReporte);
          const fechaB: any = new Date(b.FechaReporte); 
          return fechaA - fechaB;
        });
      } else {
        this.listaReportesFiltrado.sort((a, b) => {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return textA.localeCompare(textB);
        });
      }
    } else {
      if(campo === 'FechaReporte'){
        this.ordenarDescFecha();
      } else {
        this.listaReportesFiltrado.sort((a, b) => {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return (-1 * textA.localeCompare(textB));
        });
      }
    }
  }

  ordenarDescFecha(): void {
    this.listaReportesFiltrado.sort((a, b) => {
      const fechaA: any = new Date(a.FechaReporte);
      const fechaB: any = new Date(b.FechaReporte); 
      return fechaB - fechaA;
    }); 
  } 

  onChanges(): void {
    this.rutForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  actualizarTabla(): void {
    if(this.rut){
      this.consultaBuscarReportes(this.rut);
    }
  }

  consultaBuscarReportes(rut: string): void {
    this.spinner.show();

    this.tesoreriaService.buscarReportes(RUT.applyBackendFormat(rut)).subscribe(
      (data: any) => {
        if(data && data.length > 0){
          this.listaReportes = data.map((e: any) => {
            const fecha = new Date(e?.DatosBasicosSolicitud?.FechaReporte);
            const dia = fecha.getDate().toString().padStart(2, '0');
            const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
            const anio = fecha.getFullYear().toString();
            const hora = fecha.toLocaleTimeString('es-ES', {hour12: false}).substring(0, 8);
            const fechaFormateada = `${dia}-${mes}-${anio} ${hora}`;
            return {
              EstadoTransaccion: e?.DatosBasicosSolicitud?.EstadoTransaccion,
              EstadoTransaccionMapeada: e?.DatosBasicosSolicitud?.EstadoTransaccion ? this.mapeoEstados[e.DatosBasicosSolicitud.EstadoTransaccion] : e?.DatosBasicosSolicitud?.EstadoTransaccion,
              IDTransaccion: e?.DatosBasicosSolicitud?.IDTransaccion,
              FechaReporte: e?.DatosBasicosSolicitud?.FechaReporte,
              FechaFormatted: fechaFormateada
            }
          });

          this.filtrarObjeto();
          this.ordenarDescFecha();

          this.showBuscador = false;
          this.showList = true;
          this.showReporte = false;

          if(this.idTransaccionEntrada) {
            this.validaTransaccionEntrada();
          } else {
            this.spinner.hide()
          }

        } else {
          this.alertService.error('No se han encontrado resultados para el rut indicado.');
          this.spinner.hide()
        }
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al obtener la lista de reportes.');
        this.spinner.hide();
    });
  }

  validaTransaccionEntrada(): void {
    const itemReporte = this.listaReportes.find(e => e.IDTransaccion === this.idTransaccionEntrada);
    if(itemReporte){
      this.displayReport(this.idTransaccionEntrada);
    } else {
      this.alertService.error('No se ha encontrado la transacción indicada en la lista de reportes.');
      this.spinner.hide()
    }

  }

  submitRut(): void {
    const { rut } = this.rutForm.value;
    this.rut = rut;

    if (this.rutForm.valid) {
      this.consultaBuscarReportes(rut);
    } else {
      this.alertService.warn("Rut no valido");
    }  
  }

  clearForm(): void {
    this.rutForm.reset();
    this.activateRutHelper = false;
  }

  volverBuscador(): void {
    this.clearForm();
    this.reporte = null;
    this.listaReportes = [];
    this.showList = false;
    this.showReporte = false;
    this.showBuscador = true;
    this.idTransaccionEntrada = '';
  }

  volverLista(): void {
    this.reporte = null;
    this.showList = true;
    this.showReporte = false;
    this.showBuscador = false;
    this.idTransaccionEntrada = '';
  }

  displayReport(idTransaccion: string): void {
    if(idTransaccion){
      this.spinner.show();

      this.tesoreriaService.buscarTX(idTransaccion).subscribe(
        (data: any) => {
          if(data && data?.TesoreriaReporte?.Reporte){
            this.reporte = data;
            this.showBuscador = false;
            this.showList = false;
            this.showReporte = true;
          } else {
            this.alertService.error('Ha ocurrido un error al obtener el reporte.');
          }
          this.spinner.hide()
        },
        ({ error }) => {
          this.alertService.error(error.message || 'Ha ocurrido un error al obtener el reporte.');
          this.spinner.hide();
      });
    }
  }

  setToday() {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }
  
}
