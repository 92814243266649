import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/components/_alert';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { rutValidate } from 'rut-helpers';
import { SessionService } from 'src/app/shared/services/session.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { RUT } from 'src/app/shared/utils/rut';
import { MallaSocietariaService } from 'src/app/shared/services/malla-societaria.service';

interface mallaFormValues {
  rut: string;
}

@Component({
  selector: 'app-reporte-malla-societaria-consultar',
  templateUrl: './reporte-malla-societaria-consultar.component.html',
  styleUrls: ['./reporte-malla-societaria-consultar.component.scss']
})
export class ReporteMallaSocietariaConsultarComponent implements OnInit {
  public objectKeys = Object.keys;

  private access: string[] = [ESystemAccess.REPORTE_MALLA_SOCIETARIA, ESystemAccess.REPORTE_MALLA_SOCIETARIA_BUSCAR, ESystemProfileList.WEB];
  public hasUserAccess = false;

  public searchMallaForm: UntypedFormGroup;
  public activateRutHelper = false;
  public today: string = '';
  public rut: string = '';
  public fechaReporte: string = '';
  public showReporte: boolean = false;
  public seeMalla: boolean = false;

  private subscriptions: Subscription[] = [];

  public dataOrg: any = {};

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private _sessionService: SessionService,
    private mallaSocietariaService: MallaSocietariaService,

  ) {
    this.searchMallaForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
    }) as FormGroupTyped<mallaFormValues>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if ((this._sessionService.getUserAccess().includes(this.access[0]) || this._sessionService.getUserAccess().includes(this.access[1])) && this._sessionService.getUserProfileList().includes(this.access[2])) {
      this.hasUserAccess = true;
    }
    this.setToday();
  }

  validaPermisoCrear(): boolean{
    if (this._sessionService.getUserAccess().includes(this.access[0])){
      return true
    }
    return false
  }

  validaPermisoBuscar(): boolean{
    if (this._sessionService.getUserAccess().includes(this.access[1])){
      return true
    }
    return false
  }

  setToday() {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  onChanges(): void {
    this.searchMallaForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  public buscarReporte(): void {
    const { rut } = this.searchMallaForm.value;
    this.rut = rut;
    if (this.searchMallaForm.valid) {
      if(this.validaPermisoBuscar()){
        this.searchReport(this.rut);
      } else {
        this.showReporte = true;
        this.seeMalla = true;
      }

    } else {
      this.alertService.warn("Rut no valido");
    }
  }

  public searchReport(rut: string): void {
    this.spinner.show();
    this.seeMalla = false;
    this.subscriptions.push(this.mallaSocietariaService.buscarActualizado(rut).subscribe(
      (data: any) => {
        this.showReporte = true;
        this.fechaReporte = data?.MallaSocietariaReporte?.DatosBasicosSolicitud?.FechaReporte || '';
        if(data?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla){
          this.dataOrg = data?.MallaSocietariaReporte?.Reporte?.mallaSocietaria || {};
          this.seeMalla = true;
          this.spinner.hide();
        } else {
          if(this.validaPermisoCrear()){
            this.crearReport(this.rut);
          } else {
            this.alertService.error('No existe información histórica del rut y no posee permisos para crear un reporte');
            this.backToSearchForm();
            this.spinner.hide();
          }
        }
      },
      ({ error }) => {
        this.alertService.error(error?.message || 'Error al buscar el reporte del rut indicado')
        this.showReporte = true;
        this.seeMalla = true;
        this.spinner.hide();
      }));
  }

  public crearReport(rut: string): void {
    this.spinner.show();
    this.seeMalla = false;
    this.subscriptions.push(this.mallaSocietariaService.crearReporte(rut).subscribe(
      (data: any) => {
        this.fechaReporte = data?.MallaSocietariaReporte?.DatosBasicosSolicitud?.FechaReporte || '';
        if(data?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla){
          this.dataOrg = data?.MallaSocietariaReporte?.Reporte?.mallaSocietaria || {};
          this.seeMalla = true;
        } else {
          this.alertService.error('No se logró recuperar la información de socios y sociedades');
          this.seeMalla = true;
        }
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error?.message || 'Error al actualizar el reporte del rut indicado')
        this.seeMalla = true;
        this.spinner.hide();
      }));
  }

  public clearForm(): void {
    this.searchMallaForm.reset();
    this.activateRutHelper = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public backToSearchForm(): void {
    this.dataOrg = {};
    this.fechaReporte = '';
    this.showReporte = false;
    this.seeMalla = false;
  }

}
