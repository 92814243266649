import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { SessionService } from 'src/app/shared/services/session.service';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { AlertService } from 'src/app/components/_alert';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { rutValidate } from 'rut-helpers';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { RUT } from 'src/app/shared/utils/rut';
import { FormularioF29Service } from 'src/app/shared/services/formulario-f29.service';
import { ReporteF29, IFormularioF29ReporteModel } from 'src/app/models/formulario-f29/formulario-f29.model';



@Component({
  selector: 'app-formulario-f29-consultar',
  templateUrl: './formulario-f29-consultar.component.html',
  styleUrls: ['./formulario-f29-consultar.component.scss']
})
export class FormularioF29ConsultarComponent implements OnInit {
  @Input() rut: string | any;
  private access: string[] = [ESystemAccess.REPORTE_F29, ESystemProfileList.WEB];
  public showoptions = false;
  public showContent = false;
  public hasUserAccess = false;
  public reportF29ConsultarForm: FormGroup;
  public reportF29FormSend!: FormGroup;
  public activateRutHelper = false;
  public today: string = '';
  public tempFile: any;
  public reporteFullContent!: any | null;
  private subscriptions: Subscription[] = [];
  public valueTab: string | undefined = 'Folio';
  public showReport: boolean = false;
  @Output() actualizarReporte = new EventEmitter<string>();

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private fb: UntypedFormBuilder,
    private _sessionService: SessionService,
    private utilsService: UtilsService,
    private formularioF29Service: FormularioF29Service
  ) {
    this.reportF29ConsultarForm = this.fb.group({
      rut: ['', [Validators.required, gtpRutValidator()]]
    }) as FormGroupTyped<any>;
    this.onChanges();
  }

  ngOnInit(): void {
    this.setToday();
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
    this.showoptions = true;
    this.utilsService.changeSpanExtra(' > Ventas');
    this.initForm();
  }

  public closeReport(): void {
    this.reporteFullContent = null;
    this.showContent = false;
    this.showoptions = false;
    this.showReport = false;
    this.actualizarReporte.emit(this.rut); // Se indica el rut para que el componente padre actualice la informacion
    this.utilsService.changeSpanExtra('');

  }

  public clearForm(): void {
    this.reportF29ConsultarForm.reset();
    this.activateRutHelper = false;
  }

  onChanges(): void {
    this.subscriptions.push(this.reportF29ConsultarForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    }));
  }

  initForm(): void {
    this.reportF29FormSend = this.fb.group({});
    const form = this.reportF29FormSend;

    this.valueTab === 'PDF' ?
      (
        form.addControl('pdf', new UntypedFormControl('', [Validators.required])),
        form.addControl('validar', new UntypedFormControl(true, [Validators.required]))
      )
      : this.valueTab === 'Folio' ?
      (
        form.addControl('folio', new UntypedFormControl('', [Validators.required])),
        form.addControl('mes', new UntypedFormControl('', [Validators.required])),
        form.addControl('periodo', new UntypedFormControl('', [Validators.required]))
      ) :
        (
          form.addControl('codigoCte', new UntypedFormControl('', [Validators.required])),
          form.addControl('claveCte', new UntypedFormControl('', [Validators.required]))
        );
    
  }

  submit(): void {
    if (!this.reportF29FormSend.valid) {
      this.utilsService.validateAllFormFields(this.reportF29FormSend);
      return;
    }
    const form = this.reportF29FormSend.getRawValue();
    const service = this.valueTab === 'PDF' ? 'procesarReport' :
      this.valueTab === 'Folio' ? 'createReport' : 'descargarReport';

    let validar: boolean = true;
    
    if (this.valueTab === 'PDF') {
      form.pdf = this.tempFile;
      validar = form.validar;
    }
    this.spinner.show();
    this.formularioF29Service[service](RUT.applyBackendFormat(this.rut), form, validar).subscribe(
      (response: any) => {
        this.spinner.hide();
        const formularioResponse = response?.Formulario29Reporte.Reporte;
        this.calcularFormulario(formularioResponse, validar);
      },
      (error: any) => {
        this.spinner.hide();
        this.alertService.error(error.error.message || 'Ocurrio un problema');
      }
    );
  }

  calcularFormulario(formulario: ReporteF29, validar: boolean = true):void {
    this.spinner.show();
    this.formularioF29Service.calcularVentas(RUT.applyBackendFormat(this.rut), formulario, validar).subscribe(
      (response: IFormularioF29ReporteModel) => {
        let periodo = '';
        if(response.Formulario29Reporte.Reporte.periodo){
          periodo = response.Formulario29Reporte.Reporte.periodo;
        }
        this.obtenerInformeCompleto(periodo);
      },
      (error: any) => {
        this.spinner.hide();
        this.alertService.error(error.error.message);
      }
    );
  }


  obtenerInformeCompleto(periodo: string): void{
    this.subscriptions.push(this.formularioF29Service.buscarReportes(RUT.applyBackendFormat(this.rut), periodo).subscribe(
      (data: any) => {
        if (!(data.length > 0)){
          this.alertService.warn('Sin Información.');
          this.spinner.hide();
        } else {
          this.reporteFullContent = [];
          this.reporteFullContent = this.formularioF29Service.obtenerReporteFiltrado(data, [periodo]);
          this.reporteFullContent = this.formularioF29Service.revisionMeses(this.reporteFullContent);
          if(this.reporteFullContent.length > 0){
            this.spinner.hide();
            this.showContent = true;
            this.showoptions = false;
            this.showReport = true;
          } else {
            this.alertService.warn('Sin Información.');
            this.spinner.hide();
          }
        }
        
      },
      ({ error }) => {
        this.alertService.error(error.message || "Ha ocurrido un error inesperado.");
        this.spinner.hide();
      }));
    
  }

  Limpiar(): void {
    this.reportF29FormSend.reset();
    this.reportF29FormSend?.get('validar')?.setValue(true);
    this.activateRutHelper = false;
  }

  onSelect(data: TabDirective): void {
    this.valueTab = data.heading;
    this.tempFile = null;
    this.initForm();
  }

  handleUpload(event: any):void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.tempFile = reader?.result?.toString().split(',')[1];
    };
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

}
