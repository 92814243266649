<div class="card w-100 mx-auto rounded-0 modulo-financiero" *ngIf="!showReport">
    <div class="card-header py-1">
        <div class="row d-flex align-items-center">
            <h5 class="card-ventas mr-auto p-2 ml-4 mb-0">Ventas</h5>
            <button (click)="crearBoton()" class="btn btn-primary mr-3 p-2">Nuevo</button>
        </div>
    </div>
    <div class="card-body">
        <div class="mx-5" *ngIf="hasUserAccess && searchReportDataSource.length > 0 && !showReport">
            <div class="container lista-tamano overflow-auto">
                <div *ngFor="let item of searchReportDataSource" class="form-check mx-2">
                    <input #checkboxes class="form-check-input" type="checkbox" id="f29-{{item}}" (change)="onCheckChange($event)" value="{{item}}">
                    <label class="form-check-label mx-5" for="f29-{{item}}">
                        <span class="text-nowrap">{{item}}</span>
                    </label>
                </div>
            </div>
            <div class="form-group form-actions container__actions container-options-buscar alineamiento-botones mt-3">
                <button class="btn btn-sm btn-primary mx-2 button-tamano" (click)="displayReport()"><i class="fa fa-angle-right"></i>
                    Ver</button>
                <button type="button" (click)="limpiarCheckbox()" class="btn btn-sm btn-warning mx-2 button-tamano"><i class="fa fa-repeat"></i>
                    Limpiar</button>
            </div>
        </div>
        <div *ngIf="searchReportDataSource.length == 0 && textoSinInfo != ''">
            <div class="container d-flex justify-content-center align-items-center">
                <p>{{textoSinInfo}}</p>
            </div>
        </div>
    </div>
</div>


<div *ngIf="showReport">
    <div class="container-gtp no-print">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="btn-option">
                    <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                    <div>
                        <button *ngIf="showReport" [printTitle]='"Ventas_"+(rut | rutFormat)+"_"+today'
                         [useExistingCss]="true" printSectionId="print-section" ngxPrint class="mr-2 btn btn-primary">
                         Descargar PDF</button>
                         <button *ngIf="showReport" id="descargarExcel" class="btn btn-primary" (click)="appinformef29view.descargarExcel()">
                         Descargar Excel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="print-section">
        <app-informe-f29-view #appinformef29view [reporteFullContent]="reporteFullContent" [rut]="rut"></app-informe-f29-view>
    </div>
</div>