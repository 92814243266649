import { Injectable } from '@angular/core';
import { forkJoin, timer } from 'rxjs';
import { mergeMap, take, map, takeWhile, finalize } from 'rxjs/operators'
import { AlertService } from 'src/app/components/_alert';
import { TesoreriaService } from './tesoreria.service';
import { EmpresaEnUnDiaService } from './empresa-en-un-dia.service';
import { CesionFacturasService } from './cesion-facturas.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AsincronoService {
  constructor(
    private alertService: AlertService,
    private tesoreriaService: TesoreriaService,
    private empresaEnUnDiaService: EmpresaEnUnDiaService,
    private cesionFacturasService: CesionFacturasService,
    private router: Router
  ) { }

  llamadaTesoreria(idTransaccion: string, rut: string): void {
    const maxRetries = 44;
    let idTXValue: string | null = null;
    let messageError: string = '';

    timer(0, 30000)
    .pipe(
      take(maxRetries),
      mergeMap(() => this.tesoreriaService.buscarTX(idTransaccion)),
      map(response => 
        { 
          const message = response?.TesoreriaReporte?.DatosBasicosSolicitud;
          let isSatisfactory = false;
          if(message && message?.EstadoTransaccion === 'COMPLETED' && message?.SubEstadoTransaccion === 'COMPLETED'){
            idTXValue = idTransaccion;
            isSatisfactory = true
          } else if (message && message?.EstadoTransaccion === 'ERROR'){
            isSatisfactory = true
            if(message?.SubEstadoTransaccion === 'PROCESS'){
              messageError = 'Ha ocurrido un error durante el procesamiento del reporte de tesorería.';
            } else if (message?.SubEstadoTransaccion === 'PASSWORD') {
              messageError = 'Ha ocurrido un error durante el procesamiento del reporte de tesorería, las credenciales no son válidas para acceder al Sii.';
            } else if (message?.SubEstadoTransaccion === 'RUT-VALIDATION') {
              messageError = 'Ha ocurrido un error durante el procesamiento del reporte de tesorería, Rut no es válido.';
            }
          } else {
            isSatisfactory = false;
          }

          return { response: response, isSatisfactory: isSatisfactory }
          
        }),
      takeWhile(({ response, isSatisfactory }) => !isSatisfactory),
      finalize(() => {
        //Termina la ejecucion ira a identificar si existe this.idTXValue
      })
    )
    .subscribe(
      response => {
        // Llega al ultimo pero debe estar esta definicion
      },
      error => {
        this.alertService.error(error?.error?.message || 'Ha ocurrido un error al obtener datos del reporte de tesorería.');
      },
      () => {
        if(!idTXValue){
          if(messageError != '') {
            this.alertService.error(messageError || 'Ha ocurrido un error al obtener datos del reporte de tesorería.');
          }
        } else {
          const variables: any = {
            rut: rut,
            idTransaccion: idTXValue
          }
          this.alertService.success(`Se ha ejecutado correctamente la creación del reporte de tesorería ${idTXValue}`, {autoClose: true, buttonRedirect: true, componente: 'reporte-tesoreria/buscar', arguments: variables});
        }
      }
    );
  }


  llamadaEmpresaEnUnDia(idTransaccion: string, rut: string): void {
    const maxRetries = 20;
    let idTXValue: string | null = null;
    let messageError: string = '';

    timer(0, 15000)
    .pipe(
      take(maxRetries),
      mergeMap(() => this.empresaEnUnDiaService.getReport(idTransaccion)),
      map(response => 
        {  
          const datosSolicitud = response?.EmpresaEnUnDiaReporte?.DatosBasicosSolicitud;
          const certificados = response?.EmpresaEnUnDiaReporte?.Certificados || [];
          let isSatisfactory = false;
          if(datosSolicitud && datosSolicitud?.EstadoTransaccion === 'COMPLETED' && datosSolicitud?.SubEstadoTransaccion === 'COMPLETED' && certificados.length > 0){
            idTXValue = idTransaccion;
            isSatisfactory = true
          } else if (datosSolicitud && datosSolicitud?.EstadoTransaccion === 'ERROR'){
            isSatisfactory = true
            messageError = 'Empresa en un día - Ha ocurrido un error en la generación del reporte.';
          } else {
            isSatisfactory = false;
          }

          return { response: response, isSatisfactory: isSatisfactory }
          
        }),
      takeWhile(({ response, isSatisfactory }) => !isSatisfactory),
      finalize(() => {
        //Termina la ejecucion ira a identificar si existe this.idTXValue
      })
    )
    .subscribe(
      response => {
        // Llega al ultimo pero debe estar esta definicion
      },
      error => {
        this.alertService.error(error?.error?.message || 'Ha ocurrido un error al obtener datos del reporte de empresa en un dia.');
      },
      () => {
        if(!idTXValue){
          this.alertService.error(messageError || 'Ha ocurrido un error al obtener datos del reporte de empresa en un dia.');
        } else {
          const variables: any = {
            rut: rut,
            idTransaccion: idTXValue
          }
          this.alertService.success(`Se ha ejecutado correctamente la creación del reporte de empresa en un dia ${idTXValue}`, {autoClose: true, buttonRedirect: true, componente: 'empresa-en-un-dia/buscar', arguments: variables});
        }
      }
    );
  }

  llamadaCreacionCesionFacturas(rut: string, idTransaccionCedente: string, idTransaccionCesionario: string, fechaInicio: string, fechaFin: string): void {
    
    const maxRetries = 20;
    const retryInterval = 15000; // Intervalo de 15 segundos
    let messageError1: string = '';
    let messageError2: string = '';

    let isSatisfactory1 = false;
    let isSatisfactory2 = false;
  
    timer(0, retryInterval)
      .pipe(
        take(maxRetries),
        mergeMap(() => forkJoin([
          this.cesionFacturasService.obtenerReportePorId(idTransaccionCedente),
          this.cesionFacturasService.obtenerReportePorId(idTransaccionCesionario)
        ])),
        map(([response1, response2]: any[]) => {
          const datosSolicitud1 = response1?.CesionFacturasReporte?.DatosBasicosSolicitud || null;
          const datosSolicitud2 = response2?.CesionFacturasReporte?.DatosBasicosSolicitud || null;

          if (datosSolicitud1 && datosSolicitud1?.EstadoTransaccion === 'COMPLETED' && datosSolicitud1?.SubEstadoTransaccion === 'COMPLETED') {
            isSatisfactory1 = true;
          } else if (datosSolicitud1 && datosSolicitud1?.EstadoTransaccion === 'ERROR') {
            isSatisfactory1 = true;
            messageError1 = 'Cesión de Facturas - Ha ocurrido un error en la generación del reporte de cesión de facturas.';
          }

          if (datosSolicitud2 && datosSolicitud2?.EstadoTransaccion === 'COMPLETED' && datosSolicitud2?.SubEstadoTransaccion === 'COMPLETED') {
            isSatisfactory2 = true;
          } else if (datosSolicitud2 && datosSolicitud2?.EstadoTransaccion === 'ERROR') {
            isSatisfactory2 = true;
            messageError2 = 'Cesión de Facturas - Ha ocurrido un error en la generación del reporte de cesión de facturas.';
          }

          return { response1, response2, isSatisfactory1, isSatisfactory2 };
        }),
        takeWhile(({ isSatisfactory1, isSatisfactory2 }) => !isSatisfactory1 || !isSatisfactory2)
      )
      .subscribe(
        ({ response1, response2, isSatisfactory1, isSatisfactory2 }) => {
          // NO SE EJECUTA
        },
        error => {
          this.alertService.error(error?.error?.message || 'Ha ocurrido un error al obtener datos del reporte de cesión de facturas.');
        },
        () => {
          console.log("suscripción", isSatisfactory1, isSatisfactory2)
          if(isSatisfactory1 && isSatisfactory2){
            if(messageError1 != '' || messageError2 != ''){
              this.alertService.error(messageError1 || messageError2 || 'Ha ocurrido un error al obtener datos del reporte de cesión de facturas.');
            } else {
              this.actualizarDTECesionFacturas(rut, fechaInicio, fechaFin);
            }
          } else {
            this.alertService.error(messageError1 || messageError2 || 'Ha ocurrido un error al obtener datos del reporte de cesión de facturas.');
          }
        }
      );
  }

  actualizarDTECesionFacturas(rut: string, fechaInicio: string, fechaFin: string): void {
    this.cesionFacturasService.actualizarDTE(rut, fechaInicio, fechaFin).subscribe(
      (data: any) => {
        this.lladamaObtenerDTECesionFacturas(rut, fechaInicio, fechaFin);
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al actualizar el reporte de cesión de facturas.');
    });

  }

  lladamaObtenerDTECesionFacturas(rut: string, fechaInicio: string, fechaFin: string): void {
    const maxRetries = 20;
    let done: boolean = false;
    let messageError: string = '';

    timer(0, 15000)
    .pipe(
      take(maxRetries),
      mergeMap(() => this.cesionFacturasService.obtenerEstadoDTE(rut, fechaInicio, fechaFin)),
      map(response => 
        {  
          let isSatisfactory = false;
          if(response?.estado && response?.estado === 'completado'){
            done = true;
            isSatisfactory = true
          } else if (response?.estado && response?.estado === 'error'){
            isSatisfactory = true
            messageError = 'Cesión de Facturas - Ha ocurrido un error al actualizar el reporte de cesión de facturas.';
          } else {
            isSatisfactory = false;
          }

          return { response: response, isSatisfactory: isSatisfactory }
          
        }),
      takeWhile(({ response, isSatisfactory }) => !isSatisfactory),
      finalize(() => {
        //Termina la ejecucion ira a identificar si existe this.idTXValue
      })
    )
    .subscribe(
      response => {
        // Llega al ultimo pero debe estar esta definicion
      },
      error => {
        this.alertService.error(error?.error?.message || 'Ha ocurrido un error al obtener datos del reporte de cesión de facturas.');
      },
      () => {
        if(!done){
          this.alertService.error(messageError || 'Ha ocurrido un error al obtener datos del reporte de cesión de facturas.');
        } else {
          let message = `Se ha ejecutado correctamente la creación del reporte de cesión de facturas, haga click aquí para ir al modulo de monitoreo`;
          if (this.router.url === '/cesion-facturas/monitoreo') {
            message = `Se ha ejecutado correctamente la creación del reporte de cesión de facturas, refresque el modulo web o vuelva a buscar para ver los registros actualizados.`;
          }
          this.alertService.success(message, {autoClose: true, buttonRedirect: true, componente: 'cesion-facturas/monitoreo'});
        }
      }
    );
  }

}
