import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SessionService } from 'src/app/shared/services/session.service';
import { CobranzaOnlineApiService } from '../cobranza-online-api.service';
import { AlertService } from 'src/app/components/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { Router } from '@angular/router';


interface PlanOption {
  id: string;
  price: string;
  description: string;
}

@Component({
  selector: 'app-cobranza-online-onboarding',
  templateUrl: './cobranza-online-onboarding.component.html',
  styleUrls: ['./cobranza-online-onboarding.component.scss']
})
export class CobranzaOnlineOnboardingComponent implements OnInit {

  tycPublicacion = "https://docs.google.com/document/d/1MU07J9KAfXm2F_YUHAuwWShGYcs9lgKwZz-CbWa2diE/edit?usp=sharing"


  newSelected = false;
  existingSelected = false;

  planOptions: PlanOption[] = [ 
    {
      id: '3',
      price: '3 UF', description: 'publicación de hasta 25 nuevos documentos morosos mensuales'
    },
    {
      id: '7',
      price: '7 UF', description: 'publicación de hasta 100 nuevos documentos morosos mensuales'
    },
    {
      id: '10',
      price: '10 UF', description: 'publicaciones libres de documentos morosos'
    },
  ]

  existingForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  }); 

  newForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    repeatPassword: ['', [Validators.required, Validators.minLength(6)]],
    company_rut: ['', [Validators.required, gtpRutValidator()]],
    company_name: ['', Validators.required],
    contact_first_name: ['', Validators.required],
    contact_last_name: ['', Validators.required],
    phone: ['', Validators.required],
    // planOption: ['', Validators.required],
    terms: ['', Validators.requiredTrue]
  });
  selectedPlan: PlanOption | null | undefined = null;
  missingPlan = false;

  constructor(
    private fb: FormBuilder,
    private sessionService: SessionService,
    private apiService: CobranzaOnlineApiService,
    public alertService: AlertService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onNewSelected() {
    console.log("select")
    this.newSelected = true;
    this.existingSelected = false;
  }

  onExistingSelected() {
    this.newSelected = false;
    this.existingSelected = true;
  }

  unSelect() {
    this.newSelected = false;
    this.existingSelected = false;
  }


  // public rutValidate(event: any) {
  //   if (!rutValidate(event)) {
  //     // invalid character, prevent input
  //     event.preventDefault();
  //   }
  // }

  onSubmitExisting() {
    console.log("submit new")
    if (this.existingForm.valid) {
      const formData = this.existingForm.value;
      console.log(formData);

      const idUsuario = this.sessionService.getUserInfo().email;
      const idOrganizacion = this.sessionService.getOrganization();
      const requestOrigin = "WEB"

      const userData = {
        email: formData.email, 
        password: formData.password, 
        existing: true
      }

      const body = {
        idUsuario, idOrganizacion, requestOrigin, userData
      }

      this.createToken(body);
    } else {
      this.existingForm.markAllAsTouched();
      // Object.keys(this.existingForm.controls).forEach((controlName: any) => {
      //   this.existingForm.controls[controlName].markAsTouched();
      // });
      this.alertService.error("Datos inválidos en el formulario");
    }
  }

  onPlanChange(event: any) {
    console.log(event.target.value)
    this.selectedPlan = this.findById(event.target.value);
  }
  findById(id: string): { id: string, price: string, description: string } | undefined {
    return this.planOptions.find(plan => plan.id === id);
  }

  onSubmitNew() { 
    const formData = this.newForm.value;
    if (this.newForm.valid && formData.password == formData.repeatPassword && this.selectedPlan) {

      const idUsuario = this.sessionService.getUserInfo().email;
      const idOrganizacion = this.sessionService.getOrganization();
      const requestOrigin = "WEB"

      const userData = {
        email: formData.email,
        password: formData.password,
        company_rut: formData.company_rut,
        company_name: formData.company_name,
        contact_first_name: formData.contact_first_name,
        contact_last_name: formData.contact_last_name,
        phone: formData.phone,
        country: "CL",
        provider: "gestionatupyme",
        service: "publicar",
        new: true
      }

      const planString = this.selectedPlan.price + " " + this.selectedPlan.description

      const body = {
        idUsuario, idOrganizacion, requestOrigin, userData, selectedPlan: planString
      }

      this.createToken(body);
    } else {
      this.newForm.markAllAsTouched();
      // Object.keys(this.newForm.controls).forEach(controlName => {
      //   this.newForm.controls[controlName].markAsTouched();
      // });
      if(!this.selectedPlan) {
        this.missingPlan = true
      } else {
        this.missingPlan = false
      }
      this.alertService.error("Datos inválidos en el formulario");
    }
  }


  createToken(body: any) {
    this.spinner.show();
    this.apiService.createToken(body).subscribe(
      (response: any) => {
        this.spinner.hide();
        if(response.error) {
          //alert error
          this.alertService.error("Error al cargar las credenciales");
        } else if(response.statusCode && response.statusCode != 200) {
          //alert error
          if(response.statusCode = 422) {
            this.alertService.error("Error al cargar las credenciales, verifica que tu contraseña estar ingresando datos válidos o prueba nuevamente más tarde");
            window.scrollTo({ top: 0, behavior: 'smooth' });
            // this.alertService.error(response.body.message[0].message);
          } else {
            this.alertService.error("Error al cargar las credenciales, verifica que tu contraseña estar ingresando datos válidos o prueba nuevamente más tard");
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }else {
          //alert success
          this.alertService.success("Credenciales cargadas correctamente");   
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setTimeout(() => {
            this.router.navigate(['/cobranza-online/buscar']);
          }, 3000)
        }
      },
      (error) => {
        this.spinner.hide();
        //alert error
        this.alertService.error("Error al cargar las credenciales");
      }
    );
  }
}
