import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GruposService } from 'src/app/shared/services/grupos.service';
import { AlertService } from '../_alert';
import { UsuariosService } from 'src/app/shared/services/usuarios.service';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-grupos-asignacion-page',
  templateUrl: './grupos-asignacion-page.component.html',
  styleUrls: ['./grupos-asignacion-page.component.scss']
})
export class GruposAsignacionPageComponent implements OnInit {
  private access: string[] = [ESystemAccess.CLIENTES_USER_ADMIN, ESystemProfileList.WEB];
  public hasUserAccess = false;

  @Input() grupo: any = {};
  public listadoUsuariosNoAsignados: any[] = [];
  public listadoUsuariosAsignados: any[] = [];
  public listadoUsuariosNoAsignadosFiltrado: any[] = [];
  public listadoUsuariosAsignadosFiltrado: any[] = [];

  public inputText1: string = '';
  public inputText2: string = '';
  public showSearch1: boolean = false;
  public showSearch2: boolean = false;
  public order1 = '';
  public order2 = '';

  constructor(
    private spinner: NgxSpinnerService,
    private gruposService: GruposService,
    public alertService: AlertService,
    private _usuariosService: UsuariosService,
    private _sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
      
      if(this.grupo && Object.keys(this.grupo).length > 0) {
        this.obtencionListados(this.grupo);
      }
    }
  }

  obtencionListados(grupo: any) {
    this.listadoUsuariosAsignados = [];

    this.spinner.show()
    this._usuariosService.getUsersForGroup()
    .subscribe(
      (data) => {
        if(data && data.length > 0) {
          const usuariosTotales = data.map((item: any) => item.userName);
          for(const usuario of grupo?.usuarios) {
            this.listadoUsuariosAsignados.push({usuario: usuario, checked: false});
          }
      
          const listadoUsuariosNoAsignados = usuariosTotales.filter((item: any) => !this.listadoUsuariosAsignados.find((usuario) => usuario.usuario === item));
          for(const elemento of listadoUsuariosNoAsignados) {
            this.listadoUsuariosNoAsignados.push({usuario: elemento, checked: false});
          }
      
          this.filtrarObjetoAsignado();
          this.filtrarObjetoNoAsignado();

        } else {
          this.alertService.error('No se encontraron usuarios');
        }
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error?.error?.message || error?.message || 'Ha ocurrido un error al obtener usuarios');
        this.spinner.hide();
      }
    );
  }

  filtrarObjetoNoAsignado() {
    this.listadoUsuariosNoAsignadosFiltrado = this.inputText1 != '' ? this.listadoUsuariosNoAsignados.filter((item: any) => 
    item?.usuario?.toUpperCase().includes(this.inputText1.toUpperCase())
    ) : this.listadoUsuariosNoAsignados;
  }

  filtrarObjetoAsignado() {
    this.listadoUsuariosAsignadosFiltrado = this.inputText2 != '' ? this.listadoUsuariosAsignados.filter((item: any) => 
    item?.usuario?.toUpperCase().includes(this.inputText2.toUpperCase())
    ) : this.listadoUsuariosAsignados;
  }

  checkNoAsignado(index: number): void {
    this.listadoUsuariosNoAsignadosFiltrado[index].checked = !this.listadoUsuariosNoAsignadosFiltrado[index].checked;
  }

  checkAsignado(index: number): void {
    this.listadoUsuariosAsignadosFiltrado[index].checked = !this.listadoUsuariosAsignadosFiltrado[index].checked;
  }

  limpiarSeleccionaTodos(idHtml: string): void {
    if(idHtml){
      const elemento: HTMLInputElement = document.getElementById(idHtml) as HTMLInputElement;
      if(elemento && elemento.checked === true){
        elemento.click();
      }
    }
  }

  agregar(): void {
    const usuariosSeleccionados = this.listadoUsuariosNoAsignadosFiltrado.filter((item: any) => item.checked === true);

    if(usuariosSeleccionados.length > 0) {
      this.spinner.show();
      const usuariosString = usuariosSeleccionados.map((item: any) => item?.usuario || '');
      this.gruposService.asignarUsuarioAGrupo(usuariosString, this.grupo?.nombre)
      .subscribe(
        response => {
            this.listadoUsuariosAsignados = [...this.listadoUsuariosAsignados, ...usuariosSeleccionados];
            this.listadoUsuariosNoAsignados = this.listadoUsuariosNoAsignados.filter((item: any) => item.checked === false);
            for(const usuario of this.listadoUsuariosAsignados) {
              usuario.checked = false;
            }
            this.listadoUsuariosAsignadosFiltrado = this.listadoUsuariosAsignados;
            this.listadoUsuariosNoAsignadosFiltrado = this.listadoUsuariosNoAsignados;
            this.clearInput1();
            this.clearInput2();
            this.limpiarSeleccionaTodos('seleccionarTodosNoAsignados');
            this.alertService.success(response?.message || 'User Access - Usuario(s) agregado(s) exitosamente a grupo.');
            this.spinner.hide();
        }, error => {
          this.alertService.error(error?.error?.message || error?.message || 'Ha ocurrido un error al asignar los usuarios al grupo');
          this.spinner.hide();
        });
    }
  }

  quitar(): void {
    const usuariosSeleccionados = this.listadoUsuariosAsignadosFiltrado.filter((item: any) => item.checked === true);

    if(usuariosSeleccionados.length > 0) {
      const usuariosString = usuariosSeleccionados.map((item: any) => item?.usuario || '');
      this.spinner.show();
      this.gruposService.borrarUsuarioDeGrupo(usuariosString, this.grupo?.nombre)
      .subscribe(
        response => {
            this.listadoUsuariosNoAsignados = [...this.listadoUsuariosNoAsignados, ...usuariosSeleccionados];
            this.listadoUsuariosAsignados = this.listadoUsuariosAsignados.filter((item: any) => item.checked === false);
            for(const usuario of this.listadoUsuariosNoAsignados) {
              usuario.checked = false;
            }
            this.listadoUsuariosAsignadosFiltrado = this.listadoUsuariosAsignados;
            this.listadoUsuariosNoAsignadosFiltrado = this.listadoUsuariosNoAsignados;
            this.clearInput1();
            this.clearInput2();
            this.limpiarSeleccionaTodos('seleccionarTodosAsignados');
            this.alertService.success(response?.message || 'User Access - Usuario(s) eliminado(s) exitosamente.');
            this.spinner.hide();
        }, error => {
          this.alertService.error(error?.error?.message || error?.message || 'Ha ocurrido un error al eliminar los usuarios del grupo');
          this.spinner.hide();
        });
    }
    
  }

  seleccionarTodos(event: any, id: string): void {
    if(id === 'noAsignados') {
      for(const usuario of this.listadoUsuariosNoAsignados) {
        if(event?.target?.checked === true){
          usuario.checked = true;
        } else if(event?.target?.checked === false){
          usuario.checked = false;
        }
      }
    } else if(id === 'asignados') {
      for(const usuario of this.listadoUsuariosAsignados) {
        if(event?.target?.checked === true){
          usuario.checked = true;
        } else if(event?.target?.checked === false){
          usuario.checked = false;
        }
      }
    }
  }

  clearInput1(): void {
    this.showSearch1 = false;
    this.inputText1 = ''; 
    this.filtrarObjetoNoAsignado();
  }

  clearInput2(): void {
    this.showSearch2 = false;
    this.inputText2 = ''; 
    this.filtrarObjetoAsignado();
  }

  cambioOrderAsignado(): void{
    if(this.order1 === '' || this.order1 === 'desc'){
      this.order1 = 'asc';
    } else if(this.order1 === 'asc'){
      this.order1 = 'desc';
    }
    this.ordenarAsignados();
  }

  ordenarAsignados(): void {
    if(this.order1 === '' || this.order1 === 'asc'){
      this.listadoUsuariosAsignadosFiltrado.sort((a, b) => {
        const textA = a['usuario'].toString();
        const textB = b['usuario'].toString();
        return textA.localeCompare(textB);
      });
    } else {
      this.listadoUsuariosAsignadosFiltrado.sort((a, b) => {
        const textA = a['usuario'].toString();
        const textB = b['usuario'].toString();
        return (-1 * textA.localeCompare(textB));
      });
    }
  }


  cambioOrderNoAsignado(): void{
    if(this.order2 === '' || this.order2 === 'desc'){
      this.order2 = 'asc';
    } else if(this.order2 === 'asc'){
      this.order2 = 'desc';
    }
    this.ordenarNoAsignados();
  }

  ordenarNoAsignados(): void {
    if(this.order2 === '' || this.order2 === 'asc'){
      this.listadoUsuariosNoAsignadosFiltrado.sort((a, b) => {
        const textA = a['usuario'].toString();
        const textB = b['usuario'].toString();
        return textA.localeCompare(textB);
      });
    } else {
      this.listadoUsuariosNoAsignadosFiltrado.sort((a, b) => {
        const textA = a['usuario'].toString();
        const textB = b['usuario'].toString();
        return (-1 * textA.localeCompare(textB));
      });
    }
  }

}
