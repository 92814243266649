import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-line-bars',
  templateUrl: './line-bars.component.html',
  styleUrls: ['./line-bars.component.scss']
})
export class LineBarsComponent implements OnInit {

  @Input() title: string = '';
  @Input() categorias: any[] = [];
  @Input() serie: any[] = [];
  @Input() yAxix: any[] = [];
  @Input() plotOptions: object = {};
  @Input() typeChart: string = '';
  public chart: Chart = new Chart;


  constructor() { }

  ngOnInit(): void {
    this.chart = new Chart({
      title: {
        text: this.title
      },
      plotOptions: this.plotOptions,
      yAxis: this.yAxix,
      xAxis: this.categorias,
      series: this.serie
    });
  }

}
