import { Injectable } from '@angular/core';
import { IUserInfoCognito } from './cognito.service';

export enum ESessionKeys {
  USER_INFO = "userInfo",
  ORGANIZATION = "organization",
  USER_ACCESS = "userAccess",
  PROFILE_LIST = "profileList",
  ACCES_TOKEN = "accessToken",
  ID_TOKEN = "idToken",
  APP_VERSION = "appVersion",
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  //El idToken se levanta del localStorage únicamente en verifyToken del cognitoService
  public setCurrentIdToken(idToken: string) {
    localStorage.setItem(ESessionKeys.ID_TOKEN, idToken);
    window.dispatchEvent(new Event('storage'));
  }

  public getCurrentIdToken(): string | null {
    return localStorage.getItem(ESessionKeys.ID_TOKEN);
  }

  public getUserInfo(): IUserInfoCognito {
    const contentUserInfo = localStorage.getItem(ESessionKeys.USER_INFO);
    return JSON.parse(contentUserInfo!) as IUserInfoCognito;
  }

  public hasManyOrganizations(): boolean {
    return this.getUserInfo()?.organizations?.length > 1 || false;
  }

  public removeSelectedOrganization(): void {
    localStorage.setItem(ESessionKeys.ORGANIZATION, '');
    window.dispatchEvent(new Event('storage'));
  }

  public selectAnOrganization(selectedOrganization: string): void {
    const contentUserInfo = localStorage.getItem(ESessionKeys.USER_INFO);
    const userInfo = JSON.parse(contentUserInfo!) as IUserInfoCognito;
    localStorage.setItem(ESessionKeys.ORGANIZATION, userInfo.organizations.filter((org) => org === selectedOrganization)[0])
    window.dispatchEvent(new Event('storage'));
  }

  public getOrganization(): string {
    return localStorage.getItem(ESessionKeys.ORGANIZATION)!;
  }

  public setUserAccess(userListAccess: string[]): void {
    localStorage.setItem(ESessionKeys.USER_ACCESS, JSON.stringify(userListAccess))
    window.dispatchEvent(new Event('storage'));
  }

  public setUSerProfileList(userProfileList: string[]): void {
    localStorage.setItem(ESessionKeys.PROFILE_LIST, JSON.stringify(userProfileList))
    window.dispatchEvent(new Event('storage'));
  }

  public getUserAccess(): string[] {
    return JSON.parse(localStorage.getItem(ESessionKeys.USER_ACCESS)!);
  }

  public getUserProfileList(): string[] {
    return JSON.parse(localStorage.getItem(ESessionKeys.PROFILE_LIST)!);
  }

  public clearSession() {
    localStorage.clear();
    window.dispatchEvent(new Event('storage'));
  }

  // get appVersion
  public getAppVersion(): string | null {
    return localStorage.getItem(ESessionKeys.APP_VERSION);
  }

  // set appVersion
  public setAppVersion(version: string) {
    localStorage.setItem(ESessionKeys.APP_VERSION, version);
    window.dispatchEvent(new Event('storage'));
  }
}
