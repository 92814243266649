<div class="container-gtp mb-5">
    <img class="banner-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt=""/>
    <h2 class="text-center pt-3">COMPRA Y VENTA</h2>
    <h5 class="text-center">
        <span class="badge bg-secondary text-center">
            <span *ngIf="rut">
                {{rut | rutFormat | uppercase}}
            </span>
        </span>
    </h5>

    <div class="row p-4">
        <div class="container-gtp d-flex justify-content-center mx-0 w-100 no-print">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <button class="btn btn-outline-primary btn-seleccion mx-1" [ngClass]="{'active': seeCompras}" (click)="cambioCompras()">
                    Compras
                </button>
                <button class="btn btn-outline-primary btn-seleccion mx-1" [ngClass]="{'active': seeVentas}" (click)="cambioVentas()">
                    Ventas
                </button>
                <button class="btn btn-outline-primary btn-seleccion mx-1" [ngClass]="{'active': seeNC}" (click)="cambioNC()">
                    Notas de Crédito
                </button>
                <button class="btn btn-outline-primary btn-seleccion mx-1" [ngClass]="{'active': seeSeciones}" (click)="cambioSecciones()" *ngIf="getCesionAutorizacion()">
                    Cesiones
                </button>
            </div>
        </div>
    </div>

    <ng-container *ngIf="seeVentas || seeCompras">

        <div class="row p-4">

            <fieldset class="col-sm-12" style="margin-bottom: 20px;">
                <form [formGroup]="filtroForm">
                    <div class="form-group col-sm-12">
                        <div class="col-sm-12 col-lg-4">
                            <label class="col-sm-12 control-label" for="entidadRut">
                                {{seeVentas && !seeCompras && !seeNC ? 'Cliente': ''}}
                                {{!seeVentas && seeCompras && !seeNC ? 'Proveedor': ''}}
                                {{!seeVentas && !seeCompras && seeNC ? 'Cliente': ''}}
                            </label>
                            <ng-container *ngIf="!showSpinnerRut">
                                <select id="entidadRut" name="entidadRut" class="form-control" formControlName="entidadRut"
                                    style="width: 100%;">
                                    <option value="">Todos</option>
                                    <option *ngFor="let element of listaRuts" [ngValue]="element.rut">
                                    {{ element.nombre }}
                                    </option>
                                </select>
                            </ng-container>
                            <ng-container *ngIf="showSpinnerRut">
                                <div class="spinner-border text-primary mt-2 mx-auto no-print" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </ng-container>
                        </div>

                        <div class="col-sm-12 col-lg-3">
                            <label class="col-sm-12 control-label" for="selectPeriodos">Rango Fechas</label>
                            <select class="form-control" id="selectPeriodos" formControlName="selectPeriodos">
                                <option value="">Seleccionar</option>
                                <option value="1">1 mes</option>
                                <option value="3">3 meses</option>
                                <option value="6">6 meses</option>
                                <option value="12">Ultimo año</option>
                                <option value="24">Ultimos dos años</option>
                                <option value="36">Ultimos tres años</option>
                            </select>
                        </div>
                    </div>
                </form>
            </fieldset>

            <ng-container *ngIf="seeVentas">
                <div class="col-sm-6 col-lg-4">
                    <a class="widget widget-hover-effect1 no-print">
                        <div class="widget-extra" style="background-color: #5AAFE6;">
                            <h4 class="widget-content-light text-center"><strong>Monto</strong> Total</h4>
                        </div>
                        <div class="widget-simple">
                            <div class="widget-icon pull-left animation-fadeIn" style="background-color: #5AAFE6;">
                                <i class="fa fa-calculator"></i>
                            </div>
                            <h1 class="widget-content text-right animation-pullDown" style="font-size: 25px;">
                                <strong style="color: #5AAFE6;">${{datosGeneralesVenta?.montoTotal | currency:'CLP':'':'1.0-0' }}</strong><br>
                            </h1>
                        </div>
                    </a>

                    <div class="card border-0 d-none">
                        <div class="card-header">
                            <h4 class="text-white"><strong class="text-white">Monto</strong> Total</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h1 class="text-right" style="font-size: 25px;">
                                        <strong style="color: #5AAFE6;">${{datosGeneralesVenta?.montoTotal | currency:'CLP':'':'1.0-0' }}</strong><br>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-sm-6 col-lg-4">
                    <a class="widget widget-hover-effect1 no-print">
                        <div class="widget-extra" style="background-color: #4C93C2;">
                            <h4 class="widget-content-light text-center"><strong>Cantidad</strong> Clientes</h4>
                        </div>
                        <div class="widget-simple">
                            <div class="widget-icon pull-left animation-fadeIn" style="background-color: #4C93C2;">
                                <i class="fa fa-users"></i>
                            </div>
                            <h1 class="widget-content text-right animation-pullDown" style="font-size: 25px;">
                                <strong style="color: #4C93C2;">{{datosGeneralesVenta?.cantidadClientes | number:'1.0-0' }}</strong><br>
                            </h1>
                        </div>
                    </a>

                    <div class="card border-0 d-none">
                        <div class="card-header">
                            <h4 class="text-white"><strong class="text-white">Cantidad</strong> Clientes</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h1 class="text-right" style="font-size: 25px;">
                                        <strong style="color: #4C93C2;">{{datosGeneralesVenta?.cantidadClientes | number:'1.0-0' }}</strong><br>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-sm-6 col-lg-4">
                    <a class="widget widget-hover-effect1 no-print">
                        <div class="widget-extra" style="background-color: #4C93C2;">
                            <h4 class="widget-content-light text-center"><strong>Monto</strong> Promedio</h4>
                        </div>
                        <div class="widget-simple">
                            <div class="widget-icon pull-left animation-fadeIn" style="background-color: #4C93C2;">
                                <i class="fa fa-money"></i>
                            </div>
                            <h1 class="widget-content text-right animation-pullDown" style="font-size: 25px;">
                                <strong style="color: #4C93C2;">${{datosGeneralesVenta?.montoPromedio | currency:'CLP':'':'1.0-0' }}</strong><br>
                            </h1>
                        </div>
                    </a>

                    <div class="card border-0 d-none">
                        <div class="card-header">
                            <h4 class="text-white"><strong class="text-white">Monto</strong> Promedio</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h1 class="text-right" style="font-size: 25px;">
                                        <strong style="color: #4C93C2;">${{datosGeneralesVenta?.montoPromedio | currency:'CLP':'':'1.0-0' }}</strong><br>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </ng-container>

            <ng-container *ngIf="seeCompras">
                <div class="col-sm-6 col-lg-4">
                    <a class="widget widget-hover-effect1 no-print">
                        <div class="widget-extra" style="background-color: #5AAFE6;">
                            <h4 class="widget-content-light text-center"><strong>Monto</strong> Total</h4>
                        </div>
                        <div class="widget-simple">
                            <div class="widget-icon pull-left animation-fadeIn" style="background-color: #5AAFE6;">
                                <i class="fa fa-calculator"></i>
                            </div>
                            <h1 class="widget-content text-right animation-pullDown" style="font-size: 25px;">
                                <strong style="color: #5AAFE6;">${{datosGeneralesCompra?.montoTotal | currency:'CLP':'':'1.0-0' }}</strong><br>
                            </h1>
                        </div>
                    </a>

                    <div class="card border-0 d-none">
                        <div class="card-header">
                            <h4 class="text-white"><strong class="text-white">Monto</strong> Total</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h1 class="text-right" style="font-size: 25px;">
                                        <strong style="color: #5AAFE6;">${{datosGeneralesCompra?.montoTotal | currency:'CLP':'':'1.0-0' }}</strong><br>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-sm-6 col-lg-4">
                    <a class="widget widget-hover-effect1 no-print">
                        <div class="widget-extra" style="background-color: #4C93C2;">
                            <h4 class="widget-content-light text-center"><strong>Cantidad</strong> Proveedores</h4>
                        </div>
                        <div class="widget-simple">
                            <div class="widget-icon pull-left animation-fadeIn" style="background-color: #4C93C2;">
                                <i class="fa fa-building"></i>
                            </div>
                            <h1 class="widget-content text-right animation-pullDown" style="font-size: 25px;">
                                <strong style="color: #4C93C2;">{{datosGeneralesCompra?.cantidadProveedores | number:'1.0-0' }}</strong><br>
                            </h1>
                        </div>
                    </a>

                    <div class="card border-0 d-none">
                        <div class="card-header">
                            <h4 class="text-white"><strong class="text-white">Cantidad</strong> Proveedores</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h1 class="text-right" style="font-size: 25px;">
                                        <strong style="color: #4C93C2;">{{datosGeneralesCompra?.cantidadProveedores | number:'1.0-0' }}</strong><br>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-sm-6 col-lg-4">
                    <a class="widget widget-hover-effect1 no-print">
                        <div class="widget-extra" style="background-color: #4C93C2;">
                            <h4 class="widget-content-light text-center"><strong>Monto</strong> Promedio</h4>
                        </div>
                        <div class="widget-simple">
                            <div class="widget-icon pull-left animation-fadeIn" style="background-color: #4C93C2;">
                                <i class="fa fa-money"></i>
                            </div>
                            <h1 class="widget-content text-right animation-pullDown" style="font-size: 25px;">
                                <strong style="color: #4C93C2;">${{datosGeneralesCompra?.montoPromedio | currency:'CLP':'':'1.0-0' }}</strong><br>
                            </h1>
                        </div>
                    </a>

                    <div class="card border-0 d-none">
                        <div class="card-header">
                            <h4 class="text-white"><strong class="text-white">Monto</strong> Promedio</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h1 class="text-right" style="font-size: 25px;">
                                        <strong style="color: #4C93C2;">${{datosGeneralesCompra?.montoPromedio | currency:'CLP':'':'1.0-0' }}</strong><br>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </ng-container>
            
        </div>


        <ng-container *ngIf="seeVentas || seeCompras">
            <div class="row break-box">
                <div class="col-md-12">
                    <div class="block full">
                        <div class="block-title mb-3">
                            <h2><strong>Estadisticos</strong></h2>
                        </div>
                        <div class="row px-4">
                            <ng-container *ngFor="let item of objectKeys(estadisticosGenerales)">
                                <div class="col-sm-12 col-md-6 p-2">
                                    <ng-container *ngIf="getPercents(item); else elsePorcentaje">
                                        <table class="table table-hover shadow">
                                            <tbody>
                                                <tr>
                                                <th class="font-size-cell estadisticos-cell" scope="row" width="50%">{{estadisticosGenerales[item].name}}</th>
                                                <td class="font-size-cell">
                                                    {{estadisticosGenerales[item].value | number: '1.0-2'}}
                                                    {{estadisticosGenerales[item].value ? '%' : ''}}
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </ng-container>
                                    <ng-template #elsePorcentaje>
                                        <ng-container *ngIf="getMontos(item); else elseDinero">
                                            <table class="table table-hover shadow">
                                                <tbody>
                                                    <tr>
                                                    <th class="font-size-cell estadisticos-cell" scope="row" width="50%">{{estadisticosGenerales[item].name}}</th>
                                                    <td class="font-size-cell">
                                                        {{estadisticosGenerales[item].value ? '$' : ''}}
                                                        {{estadisticosGenerales[item].value | currency:'CLP':'':'1.0-0' }}
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </ng-container>
                                        <ng-template #elseDinero>
                                            <table class="table table-hover shadow">
                                                <tbody>
                                                    <tr>
                                                    <th class="font-size-cell estadisticos-cell" scope="row" width="50%">{{estadisticosGenerales[item].name}}</th>
                                                    <td class="font-size-cell">
                                                        {{estadisticosGenerales[item].value | number: '1.0-0' }}
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!--tabla-->
        <ng-container *ngIf="seeVentas">
            <div class="block full break-box">
                <div class="block-title">
                    <h2><strong>Tabla</strong> Clientes</h2>
                </div>
        
                <div class="table-responsive table-entidad">
                    <div class="d-flex justify-content-between mb-2">
                        <select class="form-control no-print pagination-custom" [(ngModel)]="currentItem" (ngModelChange)="cambioPaginacion()">
                            <option *ngFor="let element of paginationOptions" [ngValue]="element">
                                {{ element }}
                            </option>
                        </select>
    
                        <button type="button" class="btn btn-success no-print" (click)="descargarRegistros('VENTA')">Descargar Registros</button>
                    </div>

                    <table id="example-datatable" class="table table-vcenter table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center bg-primary" style="color:#fff; font-size: 13px;">Tipo de Venta</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Tipo Documento</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">RUT Cliente</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Razón Social</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Folio</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Documento</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Recepción</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Acuse</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Reclamo</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Estado</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Forma Pago</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Total</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Cesión</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Factoring</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="paginaActual.length > 0; else noPage">
                                <tr *ngFor="let report of paginaActual | paginate:{itemsPerPage: currentItem, currentPage: currentPage, totalItems: cantidadRegistrosVenta}">
                                    <td class="text-center">{{report?.TipoVenta}}</td>
                                    <td>{{report?.tipoDocumentoMap || ''}}</td>
                                    <td>{{report?.Rutcliente ? (report?.Rutcliente | rutFormat) : ''}}</td>
                                    <td>{{report?.RazonSocial}}</td>
                                    <td>{{report?.Folio}}</td>
                                    <td>{{(report?.FechaDocto) ? (esFormatoFecha(report?.FechaDocto) ? (cambiarFormatoFecha(report?.FechaDocto)) : (report?.FechaDocto | date: 'dd-MM-yyyy')) : '' }}</td>
                                    <td>{{(report?.FechaRecepcion) ? (esFormatoFechaHora(report?.FechaRecepcion) ? (cambiarFormatoFechaHora(report?.FechaRecepcion)) : (report?.FechaRecepcion | date: 'dd-MM-yyyy H:mm:ss')) : '' }}</td>
                                    <td>{{(report?.FechaAcuseRecibo) ? (esFormatoFechaHora(report?.FechaAcuseRecibo) ? (cambiarFormatoFechaHora(report?.FechaAcuseRecibo)) : (report?.FechaAcuseRecibo | date: 'dd-MM-yyyy H:mm:ss')) : '' }}</td>
                                    <td>{{(report?.FechaReclamo) ? (esFormatoFecha(report?.FechaReclamo) ? (cambiarFormatoFecha(report?.FechaReclamo)) : (report?.FechaReclamo | date: 'dd-MM-yyyy')) : '' }}</td>
                                    <td>{{getEstadoVenta(report) || ''}}</td>
                                    <td>{{getFormaPago(report) || ''}}</td>
                                    <td>${{(report?.Montototal || report?.Montototal == 0) ? (report?.Montototal | currency:'CLP':'':'1.0-0') : '' }}</td>
                                    <td>{{report?.cesionFacturas?.FCH_CESION ? (report?.cesionFacturas?.FCH_CESION | date: 'dd-MM-yyyy') : ''}}</td>
                                    <td>{{report?.cesionFacturas?.RZ_CESIONARIO || ''}}</td>
                                </tr>
                            </ng-container>
                            <ng-template #noPage>
                                <tr>
                                    <td colspan="100%">
                                        Sin información
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 p-2 mb-3 no-print">
                    <div id="pagination">
                        <div class="col-sm-12 col-md-7 float-right">
                            <pagination-controls (pageChange)="getPage($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!--tabla-->
        <ng-container *ngIf="seeCompras">
            <div class="block full break-box">
                <div class="block-title">
                    <h2><strong>Tabla</strong> Proveedores</h2>
                </div>
        
                <div class="table-responsive table-entidad">
                    <div class="d-flex justify-content-between mb-2">
                        <select class="form-control no-print pagination-custom" [(ngModel)]="currentItem" (ngModelChange)="cambioPaginacion()">
                            <option *ngFor="let element of paginationOptions" [ngValue]="element">
                                {{ element }}
                            </option>
                        </select>
    
                        <button type="button" class="btn btn-success no-print" (click)="descargarRegistros('COMPRA')">Descargar Registros</button>
                    </div>
                    
                    <table id="example-datatable" class="table table-vcenter table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center bg-primary" style="color:#fff; font-size: 13px;">Tipo de Compra</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Tipo Documento</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">RUT Proveedor</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Razón Social</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Folio</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Documento</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Recepción</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Acuse</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="paginaActual.length > 0; else noPage">
                                <tr *ngFor="let report of paginaActual | paginate:{itemsPerPage: currentItem, currentPage: currentPage, totalItems: cantidadRegistrosCompra}">
                                    <td class="text-center">{{report?.TipoCompra}}</td>
                                    <td>{{report?.tipoDocumentoMap || ''}}</td>
                                    <td>{{report?.RUTProveedor ? (report?.RUTProveedor | rutFormat) : ''}}</td>
                                    <td>{{report?.RazonSocial}}</td>
                                    <td>{{report?.Folio}}</td>
                                    <td>{{(report?.FechaDocto) ? (esFormatoFecha(report?.FechaDocto) ? (cambiarFormatoFecha(report?.FechaDocto)) : (report?.FechaDocto | date: 'dd-MM-yyyy')) : '' }}</td>
                                    <td>{{(report?.FechaRecepcion) ? (esFormatoFechaHora(report?.FechaRecepcion) ? (cambiarFormatoFechaHora(report?.FechaRecepcion)) : (report?.FechaRecepcion | date: 'dd-MM-yyyy H:mm:ss')) : '' }}</td>
                                    <td>{{(report?.FechaAcuse) ? (esFormatoFechaHora(report?.FechaAcuse) ? (cambiarFormatoFechaHora(report?.FechaAcuse)) : (report?.FechaAcuse | date: 'dd-MM-yyyy H:mm:ss')) : '' }}</td>
                                    <td>${{(report?.MontoTotal || report?.MontoTotal == 0) ? (report?.MontoTotal | currency:'CLP':'':'1.0-0') : '' }}</td>
                                </tr>
                            </ng-container>
                            <ng-template #noPage>
                                <tr>
                                    <td colspan="100%">
                                        Sin información
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 p-2 mb-3 no-print">
                    <div id="pagination">
                        <div class="col-sm-12 col-md-7 float-right">
                            <pagination-controls (pageChange)="getPage($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="seeVentas">
            <div class="row break-box">
                <div class="col-sm-12">
                    <div class="block full min-height-block">
                        <div class="block-title">
                            <h2><strong>Evolutivo</strong> Ventas
                            </h2>
                        </div>
                        <div class="col-12 col-md-12 mb-4 no-print">
                            <div class="row">
                                <div class="col-12">
                                    <div class="container float-right d-flex justify-content-end container-size">
                                        <button class="btn btn-outline-primary mx-1" [ngClass]="{'active': !tipoGraficoEvolutivoVentas}" (click)="tipoGraficoEvolutivoVentas = false">
                                            Lineas
                                        </button>
                                        <button class="btn btn-outline-primary mx-1" [ngClass]="{'active': tipoGraficoEvolutivoVentas}" (click)="tipoGraficoEvolutivoVentas = true">
                                            Barras
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [chart]="graphEvVentaL" class="graphContent" *ngIf="!tipoGraficoEvolutivoVentas"></div>
                        <div [chart]="graphEvVentaB" class="graphContent" *ngIf="tipoGraficoEvolutivoVentas"></div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="block full min-height-block">
                        <div class="block-title">
                            <h2><strong>Ventas</strong> por Cliente</h2>
                        </div>
                        <div class="col-12 col-md-12 mb-4 no-print">
                            <div class="row">
                                <div class="col-12">
                                    <div class="container float-right d-flex justify-content-end container-size">
                                        <button class="btn btn-outline-primary mx-1" [ngClass]="{'active': !tipoGraficoDistribucionVentas}" (click)="tipoGraficoDistribucionVentas = false">
                                            Barras
                                        </button>
                                        <button class="btn btn-outline-primary mx-1" [ngClass]="{'active': tipoGraficoDistribucionVentas}" (click)="tipoGraficoDistribucionVentas = true">
                                            Torta
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [chart]="graphDisVentaB" class="graphContent" *ngIf="!tipoGraficoDistribucionVentas"></div>
                        <div [chart]="graphDisVentaP" class="graphContent" *ngIf="tipoGraficoDistribucionVentas"></div>
                    </div>
                </div>
            </div>
        </ng-container>
    
        <ng-container *ngIf="seeCompras">
            <div class="row break-box">
                <div class="col-sm-12">
                    <div class="block full min-height-block">
                        <div class="block-title">
                            <h2><strong>Evolutivo</strong> Compras</h2>
                        </div>
                        <div class="col-12 col-md-12 mb-4 no-print">
                            <div class="row">
                                <div class="col-12">
                                    <div class="container float-right d-flex justify-content-end container-size">
                                        <button class="btn btn-outline-primary mx-1" [ngClass]="{'active': !tipoGraficoEvolutivoCompras}" (click)="tipoGraficoEvolutivoCompras = false">
                                            Lineas
                                        </button>
                                        <button class="btn btn-outline-primary mx-1" [ngClass]="{'active': tipoGraficoEvolutivoCompras}" (click)="tipoGraficoEvolutivoCompras = true">
                                            Barras
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [chart]="graphEvCompraL" class="graphContent" *ngIf="!tipoGraficoEvolutivoCompras"></div>
                        <div [chart]="graphEvCompraB" class="graphContent" *ngIf="tipoGraficoEvolutivoCompras"></div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="block full min-height-block">
                        <div class="block-title">
                            <h2><strong>Evolutivo</strong> por Proveedor</h2>
                        </div>
                        <div class="col-12 col-md-12 mb-4 no-print">
                            <div class="row">
                                <div class="col-12">
                                    <div class="container float-right d-flex justify-content-end container-size">
                                        <button class="btn btn-outline-primary mx-1" [ngClass]="{'active': !tipoGraficoDistribucionCompras}" (click)="tipoGraficoDistribucionCompras = false">
                                            Barras
                                        </button>
                                        <button class="btn btn-outline-primary mx-1" [ngClass]="{'active': tipoGraficoDistribucionCompras}" (click)="tipoGraficoDistribucionCompras = true">
                                            Torta
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [chart]="graphDisCompraB" class="graphContent" *ngIf="!tipoGraficoDistribucionCompras"></div>
                        <div [chart]="graphDisCompraP" class="graphContent" *ngIf="tipoGraficoDistribucionCompras"></div>
                    </div>
                </div>
            </div>
        </ng-container>

    
        <ng-container *ngIf="seeVentas">
            <div class="row">
                <div class="col-md-6">
                    <div class="block full">
                        <div class="block-title">
                            <h2><strong>Ventas</strong> por Año</h2>
                        </div>
                        <div [chart]="graphDisVentaPorAnio" class="graphContent"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="block full">
                        <div class="block-title">
                            <h2><strong>Ventas</strong> por Tipo</h2>
                        </div>
                        <div [chart]="graphDisTipoVenta" class="graphContent"></div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="seeCompras">
            <div class="row">
                <div class="col-md-6">
                    <div class="block full">
                        <div class="block-title">
                            <h2><strong>Compras</strong> por Año</h2>
                        </div>
                        <div [chart]="graphDisCompraPorAnio" class="graphContent"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="block full">
                        <div class="block-title">
                            <h2><strong>Compras</strong> por Tipo</h2>
                        </div>
                        <div [chart]="graphDisTipoCompra" class="graphContent"></div>
                    </div>
                </div>
            </div>
        </ng-container>
    

        <ng-container *ngIf="seeVentas">
            <div class="row">
                <div class="col-md-12">
                    <div class="block full">
                        <div class="block-title">
                            <h2><strong>Top 10</strong> Clientes</h2>
                        </div>
                        <div class="table-responsive">
                            <table id="example-datatable" class="table table-vcenter table-condensed table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-center bg-primary" style="color:#fff; font-size: 13px;">N°</th>
                                        <th class="bg-primary" style="color:#fff; font-size: 13px;">Cliente</th>
                                        <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of tablaTop10Clientes; let i = index">
                                        <td class="text-center">{{i + 1}}</td>
                                        <td>{{item?.nombre}}</td>
                                        <td>${{(item?.monto) ? (item?.monto | currency:'CLP':'':'1.0-0') : '' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="seeCompras">
            <div class="row">
                <div class="col-md-12">
                    <div class="block full">
                        <div class="block-title">
                            <h2><strong>Top 10</strong> Proveedores</h2>
                        </div>
                        <div class="table-responsive">
                            <table id="example-datatable" class="table table-vcenter table-condensed table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-center bg-primary" style="color:#fff; font-size: 13px;">N°</th>
                                        <th class="bg-primary" style="color:#fff; font-size: 13px;">Proveedor</th>
                                        <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of tablaTop10Proveedores; let i = index">
                                        <td class="text-center">{{i + 1}}</td>
                                        <td>{{item?.nombre}}</td>
                                        <td>${{(item?.monto) ? (item?.monto | currency:'CLP':'':'1.0-0') : '' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>


        <!--tablas Boletas Ventas-->
        <ng-container *ngIf="seeVentas && seeBoletas">
            <div class="block full">
                <div class="block-title">
                    <h2><strong>Boletas</strong></h2>
                </div>
        
                <div class="table-responsive table-entidad">

                    <table id="example-datatable" class="table table-vcenter table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center bg-primary" style="color:#fff; font-size: 13px;">Nombre</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Cantidad</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Exento</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Neto</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Iva</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="tablaBoletas.length > 0; else noPage">
                                <tr *ngFor="let report of tablaBoletas">
                                    <td class="text-center">{{report?.nombre}}</td>
                                    <td>{{(report?.totalDocumentos || report?.totalDocumentos == 0) ? (report?.totalDocumentos | number:'1.0-0') : ''}}</td>
                                    <td>{{(report?.montoExtento || report?.montoExtento == 0) ? (report?.montoExtento | currency:'CLP':'':'1.0-0') : ''}}</td>
                                    <td>{{(report?.montoNeto || report?.montoNeto == 0) ? (report?.montoNeto | currency:'CLP':'':'1.0-0') : ''}}</td>
                                    <td>{{(report?.montoIva || report?.montoIva == 0) ? (report?.montoIva | currency:'CLP':'':'1.0-0') : ''}}</td>
                                    <td>{{(report?.montoTotal || report?.montoTotal == 0) ? (report?.montoTotal | currency:'CLP':'':'1.0-0') : ''}}</td>
                                </tr>
                            </ng-container>
                            <ng-template #noPage>
                                <tr>
                                    <td colspan="100%">
                                        Sin información
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="block full">
                <div class="block-title">
                    <h2><strong>Comprobantes</strong> Electrónicos</h2>
                </div>
        
                <div class="table-responsive table-entidad">

                    <table id="example-datatable" class="table table-vcenter table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center bg-primary" style="color:#fff; font-size: 13px;">Nombre</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Cantidad</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Exento</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Neto</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Iva</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="tablaComprobantesElectronicos.length > 0; else noPage">
                                <tr *ngFor="let report of tablaComprobantesElectronicos">
                                    <td class="text-center">{{report?.nombre}}</td>
                                    <td>{{(report?.totalDocumentos || report?.totalDocumentos == 0) ? (report?.totalDocumentos | number:'1.0-0') : ''}}</td>
                                    <td>{{(report?.montoExtento || report?.montoExtento == 0) ? (report?.montoExtento | currency:'CLP':'':'1.0-0') : ''}}</td>
                                    <td>{{(report?.montoNeto || report?.montoNeto == 0) ? (report?.montoNeto | currency:'CLP':'':'1.0-0') : ''}}</td>
                                    <td>{{(report?.montoIva || report?.montoIva == 0) ? (report?.montoIva | currency:'CLP':'':'1.0-0') : ''}}</td>
                                    <td>{{(report?.montoTotal || report?.montoTotal == 0) ? (report?.montoTotal | currency:'CLP':'':'1.0-0') : ''}}</td>
                                </tr>
                            </ng-container>
                            <ng-template #noPage>
                                <tr>
                                    <td colspan="100%">
                                        Sin información
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="block full">
                <div class="block-title">
                    <h2><strong>Otras</strong> Ventas</h2>
                </div>
        
                <div class="table-responsive table-entidad">

                    <table id="example-datatable" class="table table-vcenter table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center bg-primary" style="color:#fff; font-size: 13px;">Nombre</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Cantidad</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Exento</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Neto</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Iva</th>
                                <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="tablaOtrasVentas.length > 0; else noPage">
                                <tr *ngFor="let report of tablaOtrasVentas">
                                    <td class="text-center">{{report?.nombre}}</td>
                                    <td>{{(report?.totalDocumentos || report?.totalDocumentos == 0) ? (report?.totalDocumentos | number:'1.0-0') : ''}}</td>
                                    <td>{{(report?.montoExtento || report?.montoExtento == 0) ? (report?.montoExtento | currency:'CLP':'':'1.0-0') : ''}}</td>
                                    <td>{{(report?.montoNeto || report?.montoNeto == 0) ? (report?.montoNeto | currency:'CLP':'':'1.0-0') : ''}}</td>
                                    <td>{{(report?.montoIva || report?.montoIva == 0) ? (report?.montoIva | currency:'CLP':'':'1.0-0') : ''}}</td>
                                    <td>{{(report?.montoTotal || report?.montoTotal == 0) ? (report?.montoTotal | currency:'CLP':'':'1.0-0') : ''}}</td>
                                </tr>
                            </ng-container>
                            <ng-template #noPage>
                                <tr>
                                    <td colspan="100%">
                                        Sin información
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>

        </ng-container>

        <ng-container *ngIf="errores.length > 0">
            <div class="row no-print">
                <div class="col-md-12">
                    <div class="block full">
                        <div class="block-title">
                            <h2>Detalle Errores</h2>
                        </div>
                        <div class="size-error">
                            <div class="col-12">
                                <ul class="list-group list-group-light">
                                    <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center my-2 list-group-error" *ngFor="let item of errores">
                                      <div>
                                        <div class="text-muted">{{ item.msg || '' }}</div>
                                        <div class="text-muted"><b>Mes:</b> {{ item.mes }} <b>Periodo:</b> {{item.periodo}}</div>
                                      </div>
                                      <span class="badge rounded-pill badge-danger">Error</span>
                                    </li>
                                  </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

    </ng-container>

    <ng-container *ngIf="seeNC">
        <app-nc-section [errores]="errores" [rut]="rut" [periodoInicial]="periodoInicial" [hasBackLogic]="hasBackLogic" (backReport)="logicaRetorno()"></app-nc-section>
    </ng-container>

    <ng-container *ngIf="seeSeciones">
        <app-cesion-section [errores]="errores" [rut]="rut" [hasBackLogic]="hasBackLogic" (backReport)="logicaRetorno()"></app-cesion-section>
    </ng-container>
    
</div>