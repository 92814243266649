import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { IBoletinConcursalCreateReportResponse } from 'src/app/interface/IBoletinConcursalCreateReportResponse';
import { IEmpresaEnUnDiaCreateReportResponse } from 'src/app/interface/IEmpresaEnUnDiaCreateReportResponse';
import { IPoderJudicialGetReportReponse } from 'src/app/interface/IPoderJudicialGetReportReponse';
import { IReportBienesRaices } from 'src/app/models/bienes-raices/bienes-raices.model';
import { IReporteBasico } from 'src/app/models/reporte-buscar/reporte-buscar.model';
import { ICarpetaTributariaElectronicaReporteModel } from 'src/app/models/reporte-full/reporte-full.model';
import { IReporteVehiculos } from 'src/app/models/vehiculos/vehiculos.model';
import { IReporteConsolidado } from 'src/app/pages/reporte-consolidado/reporte-consolidado.component';
import { balanceReporteFullResumenFinanciero } from 'src/app/models/utils/utils.model';
import { ChartType } from 'angular-google-charts';
import { Subscription } from 'rxjs';
import { isNumber } from 'lodash';
import { Chart } from 'angular-highcharts';

interface IChart {
  title: string;
  type: ChartType;
  data: any[][];
  columns: string[];
  options: any[];
  width: number;
  height: number;
}

@Component({
  selector: 'app-ant-consolidado',
  templateUrl: './ant-consolidado.component.html',
  styleUrls: ['./ant-consolidado.component.scss']
})
export class AntConsolidadoComponent implements OnInit {

  @Output() actualizarOtherReports = new EventEmitter<boolean>();

  @Input() rut: string = '';
  @Input() reporteFull: any = '';
  public informeComercial: string = '';
  // Variable para el reporte financiero
  public objReporteFinanciero: ICarpetaTributariaElectronicaReporteModel | null = null;
  // Variable para el reporte deuda sbif
  public objReporteDeudaSbif: any | null = null;
  // Variable para el reporte basico
  public objReporteBasico: IReporteBasico | null = null;
  // Variable para el reporte judicial
  public objReporteJudicial: IPoderJudicialGetReportReponse | null = null;
  // Variable para el reporte boletin concursal
  public objReporteBoletinConcursal: IBoletinConcursalCreateReportResponse | null = null;
  // Variable para el reporte empresa en un dia
  public objReporteEmpresaDia: IEmpresaEnUnDiaCreateReportResponse | null = null;
  public chart: IChart;
  public reporteConsolidado: IReporteConsolidado[] | any = [];
  public mallaSocietaria: string = '';
  public totalJudicial: number = 0;
  // Variable para el resumen deuda sbif
  public objResumenDeudaSbif: any = {};
  // Variable para el reporte boletin concursal

  // Variable para el reporte bienes raices
  public objReporteBienesRaices: IReportBienesRaices | null = null;

  // Variable para el reporte vehiculos
  public objReporteVehiculos: IReporteVehiculos | null = null;

  public sinInformeText: string = 'Reporte Basico - Sin Informe Comercial';
  public showInformacionBoletinComercialLaboral: boolean = false;
  public showDetalleMorosidadesYProtestos: boolean = false;
  public showInformacionSectorialyDeMercado: boolean = false;
  public showConsultaRut: boolean = false;
  public periodoDeuda: string = '';
  public graficaSinInformacion: boolean = false;
  public balanceReporteFullResumenFinanciero: typeof balanceReporteFullResumenFinanciero | any = balanceReporteFullResumenFinanciero;
  public informacionBalance: any;
  public headerResumenFinanciero: string[] = [
    'periodo',
    'ingresosdelaExplotacion',
    'utilidadneta',
    'utilidadVentas',
    'razonCorriente',
    'permanenciaCtasporCobrar',
    'permanenciadeInventarios',
    'totalactivos',
    'totalactivosfijos',
    'totalpasivoexigible',
    'totalpatrimonio',
    'leverage',
    'ebitda',
    'ebitdagf'
  ];

  public objectKeys = Object.keys;
  public riesgoIndustriaCalculado = "";
  public riesgoIndustriaCalculadoValue = 0;
  public graph: any = {};
  private subscriptions: Subscription[] = [];

  public isPersonaNatural: boolean = false;
  public telefonos: any[] = [];
  public direccion: string = '';
  public reporteConInformeComercial: boolean = false;

  constructor(
  ) {
    this.chart = {
      title: "Ventas expresadas en millones de UF",
      type: ChartType.ColumnChart,
      data: [],
      columns: ['Topping', 'Ventas'],
      options: [],
      width: 490,
      height: 250
    }
  }

  ngOnInit(): void {
    this.getReportes();
  }

  getReportes(): void {
    this.chart = this.reporteFull?.chart;
    this.graficaSinInformacion = this.reporteFull?.reporteBasico?.chartData?.graficaSinInformacion || false;
    this.informacionBalance = this.reporteFull?.informacionBalance;
    this.informacionBalance = this.reporteFull?.informacionBalance;
    this.mallaSocietaria = this.reporteFull?.mallaSocietaria;
    this.objReporteBasico = this.reporteFull?.objReporteBasico;
    this.objReporteBoletinConcursal = this.reporteFull?.objReporteBoletinConcursal; // trae el largo listo
    this.objReporteFinanciero = this.reporteFull?.objReporteFinanciero;
    this.objResumenDeudaSbif = this.reporteFull?.objResumenDeudaSbif?.objResumenDeudaSbif;
    this.periodoDeuda = this.reporteFull?.objResumenDeudaSbif?.periodoDeuda;
    if(this.reporteFull?.reporteBasico?.graph){
      this.graph = new Chart(this.reporteFull?.reporteBasico?.graph);
    }
    this.riesgoIndustriaCalculado = this.reporteFull?.reporteBasico?.objRiesgoIndustrialCalculado?.riesgoIndustriaCalculado;
    this.riesgoIndustriaCalculadoValue = this.reporteFull?.reporteBasico?.objRiesgoIndustrialCalculado?.riesgoIndustriaCalculadoValue;
    this.showInformacionSectorialyDeMercado = this.reporteFull?.reporteBasico?.showInformacionSectorialyDeMercado;
    this.totalJudicial = this.reporteFull?.totalJudicial;
    this.informeComercial = this.reporteFull?.informeComercial;

    if(this.objReporteBasico?.InformacionBasica?.TipoReporte !== this.sinInformeText) {
      this.reporteConInformeComercial = true;
    } else {
      this.reporteConInformeComercial = false;
    }

    if(this.objReporteBasico?.InformacionBasica?.Rut) {
      let rut = this.objReporteBasico?.InformacionBasica?.Rut || '';
      if(rut.includes('.')) {
        rut = rut.replace(/\./g, '');
      }
      if(rut.includes('-')) {
        rut = rut.split('-')[0];
      }
      this.isPersonaNatural = Number(rut) > 50000000 ? false : true;
      this.getTelefonos();
      this.getDireccion();
    }
  }

  public getDireccion(): void {
    if(this.isPersonaNatural) {
      if(this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Direcciones && this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Direcciones.length > 0) {
        const direccionesParticulares: any[] = this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Direcciones.filter((direccion: any) => direccion.TipoDireccion === 'PARTICULAR');
        if(direccionesParticulares && direccionesParticulares.length > 0) {
          const direccionPReciente = direccionesParticulares.sort((a: any, b: any) => (a.FechaDireccion < b.FechaDireccion ? 1 : -1))[0];
          if(direccionPReciente) {
            this.direccion = `${direccionPReciente.CalleyNumero}${direccionPReciente.Comuna ? ', ' + direccionPReciente.Comuna : ''}${direccionPReciente.Ciudad ? ', ' + direccionPReciente.Ciudad : ''}`;
            return;
          }
        }
      }
      this.direccion = this.objReporteBasico?.InformacionBasica?.Direccion || '';
    } else {
      if(this.objReporteBasico?.InformacionBasica?.Direccion) {
        this.direccion = this.objReporteBasico?.InformacionBasica?.Direccion || '';
      }
    }
  }

  public getTelefonos(): void {
    if(this.isPersonaNatural) {
      if(this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Telefonos && this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Telefonos.length > 0) {
        if(this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Telefonos.length <= 2) {
          this.telefonos = this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Telefonos || [];
        } else {
          this.telefonos = this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Telefonos
          .sort((a: any, b: any) => (new Date(a.FechaTelefono).getTime() < new Date(b.FechaTelefono).getTime() ? 1 : -1)).slice(0, 2);
        }
      }
    } else {
      if(this.objReporteBasico?.ContactabilidadComercial?.Telefonos && this.objReporteBasico?.ContactabilidadComercial?.Telefonos.length > 0) {
        if(this.objReporteBasico?.ContactabilidadComercial?.Telefonos.length <= 2) {
          this.telefonos = this.objReporteBasico?.ContactabilidadComercial?.Telefonos || [];
        } else {
          this.telefonos = this.objReporteBasico?.ContactabilidadComercial?.Telefonos
          .sort((a: any, b: any) => (new Date(a.FechaTelefono).getTime() < new Date(b.FechaTelefono).getTime() ? 1 : -1)).slice(0, 2);
        }
      }
    }
  }

  setClasificacionPosition(value: string): number {
    if (!value) return 0;
    return Math.round((Number(value) * 100) / 1000);
  }

  getPercents(title: string): boolean {
    const valuesKeys = [
      'razonCorriente',
      'testAcido',
      'variacionenActivos',
      'variacionenPasivoExigible',
      'utilidadVentas',
      'ventaTotalIngresos',
      'roe',
      'roa',
      'dfebitda',
      'ebitdagf',
      'leverage',
      'dfPatrimonio',
      'variacionenVentas',
      'variacionenPatrimonio'
    ];
    return valuesKeys.includes(title);
  }

  sumarTotalesDeudas([...arr]): number | string {
    return this.objResumenDeudaSbif ? arr.reduce((acc, curr) => acc + this.objResumenDeudaSbif[curr], 0) : 'Sin información';
  }

  validarIfInformation(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }

  ngAfterViewInit(): void {
    this.actualizarOtherReports.emit(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  validaObjetoSocios(): boolean{
    if(this.objReporteFinanciero && this.objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.socios?.length > 0){
      return true;
    } else {
      return false;
    }
  }

  validaObjetoAntecedentes(): boolean{
    if(this.objReporteFinanciero && this.objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.nombre){
      return true;
    } else {
      return false;
    }
  }

  validaObjetoSociosFecha(): any{
    if(this.objReporteFinanciero && this.objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.socios?.length > 0){
      return (this.objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.FechaReporte || '');
    } else {
      return (this.objReporteBasico?.InformacionBasica?.FechaReporte || '');
    }
  }

  obtenerPrincipal(): boolean {
    if(this.objReporteBoletinConcursal?.boletinConcursalReporte?.principal && isNumber(this.objReporteBoletinConcursal?.boletinConcursalReporte?.principal)){
      if(this.objReporteBoletinConcursal?.boletinConcursalReporte?.principal > 0){
        return true;
      }
    }
    return false;
  }

  obtenerRepresentantes(): boolean {
    if(this.objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.representantes){
      if(this.objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.representantes.length > 0){
        return true;
      }
    }
    return false;
  }

  formatFechaEmailPN(dateNumber: any): string {
    // Convertir el número a una cadena
    if(!dateNumber) {
      return '';
    }
    const dateString = dateNumber.toString();
  
    // Verificar si la cadena tiene exactamente 8 caracteres y solo contiene dígitos
    if (/^\d{8}$/.test(dateString)) { // formato es YYYYMMDD
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6, 8);
      return `${day}-${month}-${year}`;
    } else {
      return '';
    }
  }

}
