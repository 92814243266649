<div class="panel panel-default border-0 not-print">
    <div class="panel-body">
        <div class="btn-option">
            <button *ngIf="showReportes" [printTitle]='"FlujoDeCredito_"+(rut | rutFormat)+"_"+today'
                [useExistingCss]="true" printSectionId="print-section" ngxPrint
                class="btn btn-primary float-right">Descargar PDF</button>
        </div>
    </div>
</div>

<div class="break-box">

    <div class="row p-4">
    
        <div class="col-sm-12" [ngClass]="{'col-lg-3': objectKeys(tablaVentasCompras).length > 0}">
    
            <div class="row">
                <!--Periodo-->
                <div class="col-sm-6 px-cuadros my-1" [ngClass]="objectKeys(tablaVentasCompras).length > 0 ? 'col-md-6 col-lg-12' : 'col-lg-3'">
                    <a class="widget widget-hover-effect1 no-print">
                        <div class="widget-extra" style="background-color: #5AAFE6;">
                            <h4 class="widget-content-light text-center">Periodo Ultima consulta</h4>
                        </div>
                        <div class="widget-simple overflow-auto">
                            <h1 class="widget-content text-right animation-pullDown" style="font-size: 20px;">
                                <strong style="color: #5AAFE6;" *ngIf="datosCabecera?.fechaReporte">{{datosCabecera?.fechaReporte || ''}}</strong><br>
                            </h1>
                        </div>
                    </a>
            
                    <div class="card border-0 d-none">
                        <div class="card-header">
                            <h4 class="text-white">Periodo Ultima consulta</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h1 class="text-right" style="font-size: 20px;">
                                        <strong style="color: #5AAFE6;">{{datosCabecera?.fechaReporte || ''}}</strong><br>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <!--Antiguedad-->
                <div class="col-sm-6 px-cuadros my-1" [ngClass]="objectKeys(tablaVentasCompras).length > 0 ? 'col-md-6 col-lg-12' : 'col-lg-3'">
                    <a class="widget widget-hover-effect1 no-print">
                        <div class="widget-extra" style="background-color: #5AAFE6;">
                            <h4 class="widget-content-light text-center">Antigüedad Empresa</h4>
                        </div>
                        <div class="widget-simple overflow-auto">
                            <h1 class="widget-content text-right animation-pullDown" style="font-size: 20px;">
                                <strong style="color: #5AAFE6;" *ngIf="datosCabecera?.antiguedadEmpresa">{{((datosCabecera?.antiguedadEmpresa | number: '1.0-2') + ' años') || ''}}</strong><br>
                            </h1>
                        </div>
                    </a>
            
                    <div class="card border-0 d-none">
                        <div class="card-header">
                            <h4 class="text-white">Antigüedad Empresa</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h1 class="text-right" style="font-size: 20px;">
                                        <strong style="color: #5AAFE6;">{{((datosCabecera?.antiguedadEmpresa | number: '1.0-2') + ' años') || ''}}</strong><br>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <!--Dirección-->
                <div class="col-sm-6 px-cuadros my-1" [ngClass]="objectKeys(tablaVentasCompras).length > 0 ? 'col-md-6 col-lg-12' : 'col-lg-3'">
                    <a class="widget widget-hover-effect1 no-print">
                        <div class="widget-extra" style="background-color: #5AAFE6;">
                            <h4 class="widget-content-light text-center">Dirección</h4>
                        </div>
                        <div class="widget-simple overflow-auto">
                            <h1 class="widget-content text-right animation-pullDown" style="font-size: 18px;">
                                <strong style="color: #5AAFE6;" *ngIf="datosCabecera?.direccion">{{datosCabecera?.direccion || ''}}</strong><br>
                            </h1>
                        </div>
                    </a>
            
                    <div class="card border-0 d-none">
                        <div class="card-header">
                            <h4 class="text-white">Dirección</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h1 class="text-right" style="font-size: 18px;">
                                        <strong style="color: #5AAFE6;">{{datosCabecera?.direccion || ''}}</strong><br>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                <!--Actividad-->
                <div class="col-sm-6 px-cuadros my-1" [ngClass]="objectKeys(tablaVentasCompras).length > 0 ? 'col-md-6 col-lg-12' : 'col-lg-3'">
                    <a class="widget widget-hover-effect1 no-print">
                        <div class="widget-extra" style="background-color: #5AAFE6;">
                            <h4 class="widget-content-light text-center">Actividad</h4>
                        </div>
                        <div class="widget-simple overflow-auto">
                            <h1 class="widget-content text-right animation-pullDown" style="font-size: 18px;">
                                <strong style="color: #5AAFE6;" *ngIf="datosCabecera?.actividad">{{datosCabecera?.actividad || ''}}</strong><br>
                            </h1>
                        </div>
                    </a>
        
                    <div class="card border-0 d-none">
                        <div class="card-header">
                            <h4 class="text-white">Actividad</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h1 class="text-right" style="font-size: 18px;">
                                        <strong style="color: #5AAFE6;">{{datosCabecera?.actividad || ''}}</strong><br>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <!--Tabla principal-->
        <div class="col-sm-12 col-lg-9" *ngIf="objectKeys(tablaVentasCompras).length > 0">
            <div class="block full min-height-block">
                <div class="table-responsive table-entidad">
                    <table id="example-datatable" class="table table-hover table-vcenter table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center bg-blue" rowspan="2">Miles $</th>
                                <th class="bg-blue text-center" colspan="2">{{tablaVentasCompras?.totalesInformacionFinanciera?.totalAnterior?.anio || ''}}</th>
                                <th class="bg-blue text-center" rowspan="2">Posterga</th>
                                <th class="bg-blue text-center" colspan="2">{{tablaVentasCompras?.totalesInformacionFinanciera?.totalActual?.anio || ''}}</th>
                                <th class="bg-blue text-center" rowspan="2">Posterga</th>
                            </tr>
                            <tr>
                                <th class="bg-blue text-center">Compras</th>
                                <th class="bg-blue text-center">Ventas</th>
                                <th class="bg-blue text-center">Compras</th>
                                <th class="bg-blue text-center">Ventas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let item of tablaVentasCompras?.informacionFinancieraTable">
                                <tr>
                                    <td class="text-center">{{item?.anterior?.mes || item?.actual?.mes || ''}}</td>
                                    <td class="text-right">{{validarValor(item?.anterior?.compras) ? (item?.anterior?.compras | number: '1.0-0') : ''}}</td>
                                    <td class="text-right">{{validarValor(item?.anterior?.ventas) ? (item?.anterior?.ventas | number: '1.0-0') : ''}}</td>
                                    <td class="text-right">{{item?.anterior?.posterga || '' | number: '1.0-0'}}</td>
                                    <td class="text-right">{{validarValor(item?.actual?.compras) ? (item?.actual?.compras | number: '1.0-0') : ''}}</td>
                                    <td class="text-right">{{validarValor(item?.actual?.ventas) ? (item?.actual?.ventas | number: '1.0-0') : ''}}</td>
                                    <td class="text-right">{{item?.actual?.posterga || '' | number: '1.0-0'}}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                        <thead>
                            <tr>
                                <td class="bg-blue">
                                    Total
                                </td>
                                <td class="bg-blue text-right">
                                    {{tablaVentasCompras?.totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.compras || 0 | number: '1.0-0'}}
                                </td>
                                <td class="bg-blue text-right">
                                    {{tablaVentasCompras?.totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.ventas || 0 | number: '1.0-0'}}
                                </td>
                                <td class="bg-blue text-right">
                                    {{tablaVentasCompras?.totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.posterga || '' | number: '1.0-0'}}
                                </td>
                                <td class="bg-blue text-right">
                                    {{tablaVentasCompras?.totalesInformacionFinanciera?.totalActual?.TotalAnio?.compras || 0 | number: '1.0-0'}}
                                </td>
                                <td class="bg-blue text-right">
                                    {{tablaVentasCompras?.totalesInformacionFinanciera?.totalActual?.TotalAnio?.ventas || 0 | number: '1.0-0'}}
                                </td>
                                <td class="bg-blue text-right">
                                    {{tablaVentasCompras?.totalesInformacionFinanciera?.totalActual?.TotalAnio?.posterga || '' | number: '1.0-0'}}
                                </td>
                            </tr>
                            <tr>
                                <td class="bg-blue">
                                    Promedio
                                </td>
                                <td class="bg-blue text-right">
                                    {{tablaVentasCompras?.totalesInformacionFinanciera?.totalAnterior?.TotalProm?.compras || 0 | number: '1.0-0'}}
                                </td>
                                <td class="bg-blue text-right">
                                    {{tablaVentasCompras?.totalesInformacionFinanciera?.totalAnterior?.TotalProm?.ventas || 0 | number: '1.0-0'}}
                                </td>
                                <td class="bg-blue text-right">
                                    {{tablaVentasCompras?.totalesInformacionFinanciera?.totalAnterior?.TotalProm?.posterga || '' | number: '1.0-0'}}
                                </td>
                                <td class="bg-blue text-right">
                                    {{tablaVentasCompras?.totalesInformacionFinanciera?.totalActual?.TotalProm?.compras || 0 | number: '1.0-0'}}
                                </td>
                                <td class="bg-blue text-right">
                                    {{tablaVentasCompras?.totalesInformacionFinanciera?.totalActual?.TotalProm?.ventas || 0 | number: '1.0-0'}}
                                </td>
                                <td class="bg-blue text-right">
                                    {{tablaVentasCompras?.totalesInformacionFinanciera?.totalActual?.TotalProm?.posterga || '' | number: '1.0-0'}}
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    
        <!--Indicador de Riesgo-->
        <div class="col-sm-6 col-lg-3 px-cuadros my-1">
            <a class="widget widget-hover-effect1 no-print">
                <div class="widget-extra" style="background-color: #4C93C2;">
                    <h4 class="widget-content-light text-center">Indicador de Riesgo Equifax</h4>
                </div>
                <div class="widget-simple overflow-auto pointer-cursor" (click)="abrirModalTitular()">
                    <h1 class="widget-content text-right animation-pullDown" style="font-size: 20px;">
                        <strong style="color: #4C93C2;" *ngIf="datosCabecera?.indRiesgo">{{datosCabecera?.indRiesgo || ''}}</strong><br>
                    </h1>
                </div>
            </a>
    
            <div class="card border-0 d-none">
                <div class="card-header">
                    <h4 class="text-white">Indicador de Riesgo Equifax</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <h1 class="text-right" style="font-size: 20px;">
                                <strong style="color: #4C93C2;">{{datosCabecera?.indRiesgo || ''}}</strong><br>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <!--Demandas-->
        <div class="col-sm-6 col-lg-3 px-cuadros my-1">
            <a class="widget widget-hover-effect1 no-print">
                <div class="widget-extra" style="background-color: #4C93C2;">
                    <h4 class="widget-content-light text-center">Demandas</h4>
                </div>
                <div class="widget-simple overflow-auto">
                    <h1 class="widget-content text-right animation-pullDown" style="font-size: 20px;">
                        <strong style="color: #4C93C2;" *ngIf="datosCabecera?.demandas">{{datosCabecera?.demandas || ''}}</strong><br>
                    </h1>
                </div>
            </a>
    
            <div class="card border-0 d-none">
                <div class="card-header">
                    <h4 class="text-white">Demandas</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <h1 class="text-right" style="font-size: 20px;">
                                <strong style="color: #4C93C2;">{{datosCabecera?.demandas || ''}}</strong><br>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <!--Compras sobre ventas ultimos 12 meses-->
        <div class="col-sm-6 col-lg-3 px-cuadros my-1">
            <a class="widget widget-hover-effect1 no-print">
                <div class="widget-extra" style="background-color: #4C93C2;">
                    <h4 class="widget-content-light text-center">Compras sobre ventas ultimos 12 meses</h4>
                </div>
                <div class="widget-simple overflow-auto">
                    <h1 class="widget-content text-right animation-pullDown" style="font-size: 20px;">
                        <strong style="color: #4C93C2;" *ngIf="datosCabecera?.comprasSobreVentas12m || datosCabecera?.comprasSobreVentas12m == 0">
                            {{datosCabecera?.comprasSobreVentas12m || 0 | number: '1.0-2'}}%
                        </strong><br>
                    </h1>
                </div>
            </a>
    
            <div class="card border-0 d-none">
                <div class="card-header">
                    <h4 class="text-white">Compras sobre ventas ultimos 12 meses</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <h1 class="text-right" style="font-size: 20px;">
                                <strong style="color: #4C93C2;">
                                    {{datosCabecera?.comprasSobreVentas12m || 0 | number: '1.0-2'}}%
                                </strong><br>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <!--Ventas Promedio Ultimos 12 meses $MM-->
        <div class="col-sm-6 col-lg-3 px-cuadros my-1">
            <a class="widget widget-hover-effect1 no-print">
                <div class="widget-extra" style="background-color: #4C93C2;">
                    <h4 class="widget-content-light text-center">Ventas Promedio Ultimos 12 meses $M</h4>
                </div>
                <div class="widget-simple overflow-auto">
                    <h1 class="widget-content text-right animation-pullDown" style="font-size: 20px;">
                        <strong style="color: #4C93C2;" *ngIf="datosCabecera?.ventasPromedios12m || datosCabecera?.ventasPromedios12m == 0">
                            ${{datosCabecera?.ventasPromedios12m || 0 | number: '1.0-0'}}
                        </strong><br>
                    </h1>
                </div>
            </a>
            
            <div class="card border-0 d-none">
                <div class="card-header">
                    <h4 class="text-white">Ventas Promedio Ultimos 12 meses $M</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <h1 class="text-right" style="font-size: 20px;">
                                <strong style="color: #4C93C2;">
                                    ${{datosCabecera?.ventasPromedios12m || 0 | number: '1.0-0'}}
                                </strong><br>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <!--Informes comerciales $MM-->
        <div class="col-sm-12 col-md-6 col-lg-4 full-size-print">
            <div class="block full size-boxes-3">
                <div class="block-title mb-3">
                    <h2>Informes comerciales $M</h2>
                </div>
                <div class="row px-4">
                    <div class="col-sm-12 p-2">
                        <table class="table table-hover shadow">
                            <tbody>
                                <tr>
                                    <th class="font-size-cell estadisticos-cell" scope="row" width="50%">Protestos</th>
                                    <td class="font-size-cell">
                                       {{validarValor(informesComerciales?.protestos) ? ('$' + (informesComerciales?.protestos | number: '1.0-2')) : ''}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-sm-12 p-2">
                        <table class="table table-hover shadow">
                            <tbody>
                                <tr>
                                    <th class="font-size-cell estadisticos-cell" scope="row" width="50%">Morosidad</th>
                                    <td class="font-size-cell">
                                        {{validarValor(informesComerciales?.morosidad) ? ('$' + (informesComerciales?.morosidad | number: '1.0-2')) : ''}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-sm-12 p-2">
                        <table class="table table-hover shadow">
                            <tbody>
                                <tr>
                                    <th class="font-size-cell estadisticos-cell" scope="row" width="50%">Infracciones laborales</th>
                                    <td class="font-size-cell">
                                        {{validarValor(informesComerciales?.infraccionesLaborales) ? ('$' + (informesComerciales?.infraccionesLaborales | number: '1.0-2')) : ''}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-sm-12 p-2">
                        <table class="table table-hover shadow">
                            <tbody>
                                <tr>
                                    <th class="font-size-cell estadisticos-cell" scope="row" width="50%">Financiera vigente</th>
                                    <td class="font-size-cell">
                                        {{validarValor(informesComerciales?.financieraVigente) ? ('$' + (informesComerciales?.financieraVigente | number: '1.0-2')) : ''}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-sm-12 p-2">
                        <table class="table table-hover shadow">
                            <tbody>
                                <tr>
                                    <th class="font-size-cell estadisticos-cell" scope="row" width="50%">Financiera Deteriorada</th>
                                    <td class="font-size-cell">
                                        {{validarValor(informesComerciales?.financieraDeteriorada) ? ('$' + (informesComerciales?.financieraDeteriorada | number: '1.0-2')) : ''}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="col-sm-12 col-md-6 col-lg-4 full-size-print"><!--col-sm-12 col-md-6-->
            <div class="block full min-height-block size-boxes-3">
                <div class="table-responsive table-entidad">
                    <table id="example-datatable" class="table table-hover table-vcenter table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center bg-blue" rowspan="2">$M</th>
                                <th class="bg-blue text-center" colspan="2">Balances(DAIR)</th>
                            </tr>
                            <tr>
                                <th class="bg-blue text-center">{{tablaBalance?.anioAnterior?.anio || ''}}</th>
                                <th class="bg-blue text-center">{{tablaBalance?.anioActual?.anio || ''}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="objectKeys(tablaBalance).length > 0; else elseBalance">
                                <tr>
                                    <td class="text-center">Ventas</td>
                                    <td class="text-center">{{validarValor(tablaBalance?.anioAnterior?.ventas) ? ('$' + (tablaBalance?.anioAnterior?.ventas | number: '1.0-0')) : ''}}</td>
                                    <td class="text-center">{{validarValor(tablaBalance?.anioActual?.ventas) ? ('$' + (tablaBalance?.anioActual?.ventas | number: '1.0-0')) : ''}}</td>
                                </tr>
                                <tr>
                                    <td class="text-center">Utilidad</td>
                                    <td class="text-center">{{validarValor(tablaBalance?.anioAnterior?.utilidad) ? ('$' + (tablaBalance?.anioAnterior?.utilidad | number: '1.0-0')) : ''}}</td>
                                    <td class="text-center">{{validarValor(tablaBalance?.anioActual?.utilidad) ? ('$' + (tablaBalance?.anioActual?.utilidad | number: '1.0-0')) : ''}}</td>
                                </tr>
                                <tr>
                                    <td class="text-center">Patrimonio</td>
                                    <td class="text-center">{{validarValor(tablaBalance?.anioAnterior?.patrimonio) ? ('$' + (tablaBalance?.anioAnterior?.patrimonio | number: '1.0-0')) : ''}}</td>
                                    <td class="text-center">{{validarValor(tablaBalance?.anioActual?.patrimonio) ? ('$' + (tablaBalance?.anioActual?.patrimonio | number: '1.0-0')) : ''}}</td>
                                </tr>
                                <tr>
                                    <td class="text-center">Act Fijo</td>
                                    <td class="text-center">{{validarValor(tablaBalance?.anioAnterior?.actFijo) ? ('$' + (tablaBalance?.anioAnterior?.actFijo | number: '1.0-0')) : ''}}</td>
                                    <td class="text-center">{{validarValor(tablaBalance?.anioActual?.actFijo) ? ('$' + (tablaBalance?.anioActual?.actFijo | number: '1.0-0')) : ''}}</td>
                                </tr>
                            </ng-container>
                            <ng-template #elseBalance>
                                <tr>
                                    <td colspan="100%">Sin información</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    
        <div class="col-sm-12 col-md-6 col-lg-4 full-size-print">
            <div class="block full min-height-block size-boxes-3">
                <div class="table-responsive table-entidad">
                    <table id="example-datatable" class="table table-hover table-vcenter table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th class="bg-blue text-center">Meses</th>
                                <th class="bg-blue text-center">Notas de Credito</th>
                                <th class="bg-blue text-center">IVA sobre Ventas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="objectKeys(tablaIvaSobreVentas).length > 0; else elseIva">
                                <tr>
                                    <th>Ultimos 12m</th>
                                    <td class="text center">{{validarValor(tablaIvaSobreVentas?.notasMeses12) ? ((tablaIvaSobreVentas?.notasMeses12 | number: '1.0-2') + '%') : ''}}</td>
                                    <td class="text center">{{validarValor(tablaIvaSobreVentas?.ivaMeses12) ? ((tablaIvaSobreVentas?.ivaMeses12 | number: '1.0-2') + '%') : ''}}</td>
                                </tr>
                                <tr>
                                    <th>Ultimos 6m</th>
                                    <td class="text center">{{validarValor(tablaIvaSobreVentas?.notasMeses6) ? ((tablaIvaSobreVentas?.notasMeses6 | number: '1.0-2') + '%') : ''}}</td>
                                    <td class="text center">{{validarValor(tablaIvaSobreVentas?.ivaMeses6) ? ((tablaIvaSobreVentas?.ivaMeses6 | number: '1.0-2') + '%') : ''}}</td>
                                </tr>
                                <ng-container *ngIf="tablaIvaSobreVentas.listaMeses && tablaIvaSobreVentas?.listaMeses.length > 0">
                                    <ng-container *ngFor="let number of [0,1,2,3,4,5]">
                                        <tr>
                                            <th>{{tablaIvaSobreVentas?.listaMeses[number].mes || ''}}</th>
                                            <td class="text center">{{validarValor(tablaIvaSobreVentas?.listaMeses[number].valueNotas) ? ((tablaIvaSobreVentas?.listaMeses[number].valueNotas | number: '1.0-2') + '%') : ''}}</td>
                                            <td class="text center">{{validarValor(tablaIvaSobreVentas?.listaMeses[number].valueIva) ? ((tablaIvaSobreVentas?.listaMeses[number].valueIva | number: '1.0-2') + '%') : ''}}</td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <ng-template #elseIva>
                                <tr>
                                    <td colspan="100%">Sin información</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    
        <div class="col-sm-12 col-md-6">
            <div class="block full min-height-block">
                <div class="table-responsive table-entidad">
                    <table id="example-datatable" class="table table-hover table-vcenter table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th class="bg-blue text-center" colspan="2">Deuda Morosa TGR</th>
                            </tr>
                            <tr>
                                <th class="bg-blue text-center">Formulario</th>
                                <th class="bg-blue text-center">Deuda Total M$</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="tablaDeudaMorosaTGR.length > 0; else elseDeudaMorosa">
                                <ng-container *ngFor="let item of tablaDeudaMorosaTGR">
                                    <tr>
                                        <td class="text-center">{{item?.name || ''}}</td>
                                        <td class="text-center">{{validarValor(item.value) ? ('$' + (item.value | number: '1.0-0')) : ''}}</td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <ng-template #elseDeudaMorosa>
                                <tr>
                                    <td colspan="100%">Sin información</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    
        <div class="col-sm-12 col-md-6 full-size-print">
            <div class="block full min-height-block">
                <div class="block-title">
                    <h2>Listado de Socios</h2>
                </div>
                <div class="table-responsive table-entidad">
                    <table id="example-datatable" class="table table-hover table-vcenter table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th class="bg-blue">Rut socio</th>
                                <th class="bg-blue">Nombre</th>
                                <th class="bg-blue">Fecha Vinculación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="tablaSocios.length > 0; else elseSocios">
                                <ng-container *ngFor="let item of tablaSocios">
                                    <tr class="pointer-cursor" (click)="abrirModal(item.entidad, item.rut)">
                                        <td>{{item.rut | rutFormat}}</td>
                                        <td>{{item.nombre}}</td>
                                        <td>{{item.fechaVinculacion | date: 'dd-MM-yyyy'}}</td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <ng-template #elseSocios>
                                <tr>
                                    <td colspan="100%">Sin información</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    
        <div class="col-sm-12 col-md-6 full-size-print">
            <div class="block full min-height-block">
                <div class="block-title">
                    <h2>Ventas cruzadas $M</h2>
                </div>
                <div class="table-responsive table-entidad">
                    <table id="example-datatable" class="table table-hover table-vcenter table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th class="bg-blue text-center pointer-cursor" colspan="4" (click)="abrirModalCompraVenta()">
                                    Ventas cruzadas $M
                                    <i class="fa fa-info-circle pointer-cursor ml-1 not-print" aria-hidden="true" title="Para mas información revisar el modulo Libro Compra Venta"></i>
                                </th>
                            </tr>
                            <tr>
                                <th class="bg-blue text-center">Rut</th>
                                <th class="bg-blue text-center">Deudor</th>
                                <th class="bg-blue text-center">Compra</th>
                                <th class="bg-blue text-center">Ventas</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="tablaVentaCruzada.length > 0; else elseVentaCruzada">
                                <ng-container *ngFor="let item of tablaVentaCruzada">
                                    <tr class="pointer-cursor" (click)="abrirModalCompraVenta()" title="Para mas información revisar el modulo Libro Compra Venta">
                                        <td>{{item?.rut | rutFormat}}</td>
                                        <td>{{item?.nombre || ''}}</td>
                                        <td>{{validarValor(item?.compras) ? ('$' + (item?.compras | number: '1.0-2')) : ''}}</td>
                                        <td>{{validarValor(item?.ventas) ? ('$' + (item?.ventas | number: '1.0-2')) : ''}}</td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <ng-template #elseVentaCruzada>
                                <tr>
                                    <td colspan="100%">Sin información</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    
    </div>
    
</div>

<div class="row p-4">
    <ng-container *ngIf="graphDsCliente != null && graphDsCliente.length > 0">
        <div class="col-sm-12 full-size-print">
            <div class="block full min-height-block">
                <div class="block-title">
                    <h2>% de Ventas por Tipo de Deudor, últimos 6 meses</h2>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-10 d-flex mx-auto justify-content-center">
                        <div [chart]="graphTipoDeudores" class="graphContent"></div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="col-12 my-2 not-print" *ngIf="showArchivos">
        <app-modulo-archivos [idTransaccion]="idTransaccion" [rut]="rut" [closed]="closed"></app-modulo-archivos>
    </div>    

</div>

<app-modal-consolidado-workflow [rut]="rutSeleccionado" [idTransaccion]="idTransaccion" [dataServicios]="reportesSeleccionado" [informeComercial]="informeComercial" [default]="modalDefault" [plantilla]="plantillaAntecedentes"></app-modal-consolidado-workflow>
