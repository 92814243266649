import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-estados-financieros',
  templateUrl: './estados-financieros.component.html',
  styleUrls: ['./estados-financieros.component.scss']
})
export class EstadosFinancierosComponent implements OnInit, OnDestroy {
  @Input() tipoSolicitud: string = '';
  @Input() informacionCliente: any;
  public headerCarpetaTributaria: string[] = ['date', 'ventas', 'compras', 'diferencia', 'cod.524', 'fechaPago', 'cod.91', 'posterga'];
  public infoBalance: any[] = [];
  public infoCarpetaTributaria: any[] = [];
  public subscriptions: Subscription[] = [];
  public objFlujosFuturos: any[] = [];
  public objFlujosHistoricos: any[] = [];
  public titlesFlujosFuturos: string[] = [];
  public titlesFlujosHistoricos: string[] = [];
  public currentPage = 0;
  public currentItem = env.initItemPerPage;


  constructor(private solicitudCreditoService: SolicitudCreditoService) { }

  ngOnInit(): void {
    this.setInfoBalance(this.informacionCliente);
    this.setCarpetaTributaria(this.informacionCliente);
    this.obtenerFechaFlujosFuturos();
    this.obtenerFlujosHistoricos();
  }

  setInfoBalance(informe: any) {
    this.infoBalance = informe.SolicitudDeCredito?.Reportes?.['rulesEngine-reporte']?.Reporte || [];
  }

  setCarpetaTributaria(informe: any) {
    const objTemp: any[] = [];
    const fechasKeys: any[] = [];

    const penultimo = this.obtenerValorRulesEngine('Penultimoano');
    const ultimo = this.obtenerValorRulesEngine('Ultimoano');

    if (penultimo) {
      fechasKeys.push(this.setNumber(penultimo).toFixed(0));
    }

    if (ultimo) {
      fechasKeys.push(this.setNumber(ultimo).toFixed(0));
    }

    if (fechasKeys.length > 0) {
      informe?.SolicitudDeCredito?.Reportes?.calculoVentasBalances?.Ventas
        .map((item: any) => {
          if (fechasKeys.includes(String(item.periodo))) {
            objTemp.push({
              date: item.periodo + '-' + ((item.mes) < 10 ? '0' + (item.mes) : (item.mes)) + '-' + '02',
              ventas: item.calculado?.ventasNetasMilesPesos || 0,
              compras: item.calculado?.comprasNetasMilesPesos || 0,
              diferencia: item.calculado?.margenMiles || 0,
              'cod.91': (item.codigos?.['91'] && Number(item.codigos?.['91']) > 0)  ? Number(item.codigos?.['91']) / 1000 : 0,
              fechaPago: this.solicitudCreditoService.formatDate(item.calculado?.fechaPago) || '',
              'cod.524': (item.codigos?.['524'] && Number(item.codigos?.['524']) > 0)  ? Number(item.codigos?.['524']) / 1000 : 0,
              posterga: (item.calculado?.postergacionIVA && Number(item.calculado?.postergacionIVA) > 0)  ? Number(item.calculado?.postergacionIVA) / 1000 : 0,
            });
          }
        });

      fechasKeys.map((item: any) => {
        for (let i = 1; i <= 12; i++) {
          if (objTemp.find((e: any) => e.date === item + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02') === undefined) {
            objTemp.push({
              date: item + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02',
              ventas: 0,
              compras: 0,
              diferencia: 0,
              'cod.91': 0,
              fechaPago: '',
              'cod.524': 0,
              posterga: 0
            });
          }
        }
      });
      //this.infoCarpetaTributaria = this.calcularVariacion(objTemp.sort((a, b) => (a.date > b.date ? 1 : -1)));
      this.infoCarpetaTributaria = objTemp.sort((a, b) => (a.date > b.date ? 1 : -1));
      // this.solicitudCreditoService.ResumenObjSubject.next({carpetaTributaria: this.infoCarpetaTributaria})
    }
  }

  obtenerValorRulesEngine(key: string): string {
    if (this.informacionCliente?.SolicitudDeCredito?.Reportes?.['rulesEngine-reporte']?.Reporte) {
      if (Object.keys(this.informacionCliente?.SolicitudDeCredito?.Reportes?.['rulesEngine-reporte']?.Reporte).length > 0) {
        return this.informacionCliente?.SolicitudDeCredito?.Reportes?.['rulesEngine-reporte']?.Reporte
          .find((e: any) => e.name === key)?.value?.trim() || '';
      }
      return '';
    }
    return '';
  }

  obtenerValorCamposPersonalizadosAutomatic(key: string): any[] {
    if (this.informacionCliente?.SolicitudDeCredito?.Reportes?.camposPersonalizadosAutomatic) {
      if (this.informacionCliente?.SolicitudDeCredito?.Reportes?.camposPersonalizadosAutomatic?.SolicitudCredito?.length > 0) {
        return this.informacionCliente?.SolicitudDeCredito?.Reportes?.camposPersonalizadosAutomatic?.SolicitudCredito
          .find((e: any) => e.nameAttribute === key)?.values || [];
      }
      return [];
    }
    return [];
  }

  getDatosFlujos(informe: any, key: string, key2: string, keyValue: string) {
    const result: string[] = [];
    const objResponse = this.obtenerValorCamposPersonalizadosAutomatic(key);
    const objResponse2 = this.obtenerValorCamposPersonalizadosAutomatic(key2);

    if (objResponse.length > 0) {
      //Obtener posicion de la key
      const objTemp = [];
      var idx = objResponse.indexOf(keyValue);
      while (idx != -1) {
        objTemp.push(idx);
        idx = objResponse.indexOf(keyValue, idx + 1);
      }
      //Obtener valores de la key
      if (objTemp.length > 0 && objResponse2.length > 0) {
        //encontrar valor de la posicion en el array y evitar duplicados
        objTemp.forEach(e => {
          if (objResponse2[e] && (result.indexOf(objResponse2[e]) === -1 && objResponse2[e] !== '')) {
            result.push(objResponse2[e]);
          }
        });
      }
      return result;
    }
    return result;
  }

  setNumber(num: string): number {
    return Number(num);
  }

  obtenerFechaFlujosFuturos() {

    let objetoFlujoFuturoReduce: any[] = [];
    let objetoTempFlujoFuturoReduce: any[] = [];
    const objTemp: any[] = [];
    const objResponse = this.obtenerValorCamposPersonalizadosAutomatic('item');
    const keyValue = 'Saldo_Actual';
    const keysValues = ['Importe ML', 'Periodo_Vencimiento', 'Unidad Negocio'];
    const objImporte = this.convertirMiles(this.obtenerValorCamposPersonalizadosAutomatic(keysValues[0]));
    const objPeriodoVencimiento = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[1]);
    const objUnidadNegocio = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[2]);
    const keysObjs = [objImporte, objPeriodoVencimiento, objUnidadNegocio];
    const earlyPeriodoVencimiento = new Date(new Date().getFullYear(), new Date().getMonth(), 2);
    
    if (objResponse.length > 0) {
      //Obtener posicion de la key Saldo Actual

      let idx = objResponse.indexOf(keyValue);
      while (idx != -1) {
        objTemp.push({ position: idx });
        idx = objResponse.indexOf(keyValue, idx + 1);
      }      
      //Obtener valores de la key

      if (objTemp.length > 0) {
        keysValues.forEach((e: any, i: number) => {
          objTemp.forEach((f: any) => {
            f[e.replace(' ', '')] = keysObjs[i][f.position] || '';
            if (e.replace(' ', '') === 'UnidadNegocio') {
              if (this.titlesFlujosFuturos.indexOf(f.UnidadNegocio) === -1) {
                this.titlesFlujosFuturos.push(f.UnidadNegocio);
              }
            }
          });
        });
      }

      if (objTemp.length > 0) {

        objetoFlujoFuturoReduce = Object.values(objTemp.reduce((acc, curr) => {
          acc[curr.Periodo_Vencimiento] = acc[curr.Periodo_Vencimiento] || {
            Periodo_Vencimiento: this.setFechaPeriodo(curr.Periodo_Vencimiento),
            [this.quitarEspacios(curr.UnidadNegocio)]: 0
          };

          if (acc[curr.Periodo_Vencimiento][this.quitarEspacios(curr.UnidadNegocio)]) {
            acc[curr.Periodo_Vencimiento][this.quitarEspacios(curr.UnidadNegocio)] += +Number(curr.ImporteML) || 0;
          } else {
            acc[curr.Periodo_Vencimiento][this.quitarEspacios(curr.UnidadNegocio)] = 0;
            acc[curr.Periodo_Vencimiento][this.quitarEspacios(curr.UnidadNegocio)] += +Number(curr.ImporteML);
          }

          return acc;
        }, {}));        

        if (objetoFlujoFuturoReduce.length > 0) {
          let sum = 0;

          for (let i = 0; i < 12; i++) {

            const periodoVencimiento = new Date(new Date(earlyPeriodoVencimiento).setMonth(new Date(earlyPeriodoVencimiento).getMonth() + i));            
            const resp = objetoFlujoFuturoReduce.find((e: any) => new Date(e.Periodo_Vencimiento).getTime() === new Date(periodoVencimiento).getTime());
            
            if (!resp) {
              objetoTempFlujoFuturoReduce.push({
                Periodo_Vencimiento: periodoVencimiento,
              });
            } else {
              objetoTempFlujoFuturoReduce.push(resp)
            }
            sum = 1;
          }
        }
      }
    }
    this.objFlujosFuturos = objetoTempFlujoFuturoReduce;
  }

  obtenerFlujosHistoricos() {
    let objetoFlujoHistoricoReduce: any[] = [];
    let tempoFlujoFuturo: any[] = [];
    const objTemp: any[] = [];
    const objResponse = this.obtenerValorCamposPersonalizadosAutomatic('item');
    const keyValue = 'Fact';
    const keysValues = ['Importe ML', 'Periodo', 'Unidad Negocio'];
    const objImporte = this.convertirMiles(this.obtenerValorCamposPersonalizadosAutomatic(keysValues[0]));
    const objPeriodo = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[1]);
    const objUnidadNegocio = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[2]);
    const keysObjs = [objImporte, objPeriodo, objUnidadNegocio];
    const actualDate = new Date().getFullYear();
    const periodosDate = [actualDate - 1, actualDate];    

    if (objResponse.length > 0) {
      //Obtener posicion de la key Fact
      let idx = objResponse.indexOf(keyValue);
      while (idx != -1) {
        objTemp.push({ position: idx });
        idx = objResponse.indexOf(keyValue, idx + 1);
      }

      //Obtener valores de la key
      if (objTemp.length > 0) {
        keysValues.forEach((e: any, i: number) => {
          objTemp.forEach((f: any) => {
            f[e.replace(' ', '')] = e.replace(' ', '') !== 'UnidadNegocio' ? (keysObjs[i][f.position] || '') : keysObjs[i][f.position] || '';
            if (e.replace(' ', '') === 'UnidadNegocio') {
              if (this.titlesFlujosHistoricos.indexOf(f.UnidadNegocio) === -1) {
                this.titlesFlujosHistoricos.push(f.UnidadNegocio);
              }
            }
          });
        });
      }

      // Agrupar por periodo
      objetoFlujoHistoricoReduce = Object.values(objTemp.reduce((acc, curr) => {
        acc[curr.Periodo] = acc[curr.Periodo] || {
          date: this.setFechaPeriodo(curr.Periodo),
          [this.quitarEspacios(curr.UnidadNegocio)]: 0
        };

        if (acc[curr.Periodo][this.quitarEspacios(curr.UnidadNegocio)]) {
          acc[curr.Periodo][this.quitarEspacios(curr.UnidadNegocio)] += +Number(curr.ImporteML) || 0;
        } else {
          acc[curr.Periodo][this.quitarEspacios(curr.UnidadNegocio)] = 0;
          acc[curr.Periodo][this.quitarEspacios(curr.UnidadNegocio)] += +Number(curr.ImporteML);
        }
        return acc;
      }, {}));

      // Eliminar periodos vacios
      objetoFlujoHistoricoReduce = objetoFlujoHistoricoReduce.filter((e: any) => Object.keys(e).length > 1);
      // Agregar peridos faltantes
      periodosDate.forEach((e: any) => {

        for (let i = 1; i <= 12; i++) {
          const periodo = this.setFechaPeriodo(e + '' + (i));
          const resp = objetoFlujoHistoricoReduce.find((e: any) => e.date.getTime() === periodo.getTime());
          if (!resp) {
            tempoFlujoFuturo.push({
              date: periodo.getFullYear() + '-' + (periodo.getMonth() + 1) + '-' + periodo.getDate(),
            });
          } else {
            tempoFlujoFuturo.push(resp);
          }
        }
      });
    }

    if (tempoFlujoFuturo.length > 0) {
      // Agregar propiedad fecha al principio del arreglo
      this.titlesFlujosHistoricos.unshift('date');

      // Agregar propiedades falntantes al objeto
      this.objFlujosHistoricos = tempoFlujoFuturo.map((e: any) => {

        const newsPropertys: any = {};
        for (const key of this.titlesFlujosHistoricos) {
          if (!e.hasOwnProperty(this.quitarEspacios(key)) && key !== 'date') {
            newsPropertys[this.quitarEspacios(key)] = 0;
          }
        }

        const newObject = {
          ...e,
          date: new Date(e.date).getFullYear() + '-' + ((new Date(e.date).getMonth() + 1 <= 9 ?
            '0' + (new Date(e.date).getMonth() + 1) : new Date(e.date).getMonth() + 1)) + '-' + '02',
          ...newsPropertys
        }
        return newObject;
      });
    }
  }

  quitarEspacios(str: any): any {
    // if (typeof str === 'string') {
    //   return str.replace(/\s/g, '_');
    // } else {
    //   return str.map((e: any) => e.replace(/\s/g, '_'));
    // }
    return str;
  }

  setFechaPeriodo(fecha: string): Date {
    const year = fecha.substring(0, 4);
    const month = fecha.substring(4, 6);
    const day = '02';
    return new Date(Number(year), (Number(month) - 1), Number(day));
  }

  setFechaPeriodoFuturo(fecha: string, numero: number): string {
    const fechaTemp = new Date(fecha);
    fechaTemp.setMonth(fechaTemp.getMonth() + numero);
    return fechaTemp.toISOString().split('T')[0];
  }

  calcularVariacion(obj: any[]) {
    //sumar en cada mes la venta anterior con la actual y actualizar la variacion
    for (let i = 0; i < obj.length; i++) {
      if (i > 0) {
        const variacion = obj[i].ventas !== 0 ?
          ((obj[i].ventas - obj[i - 1].ventas) / obj[i].ventas) * 100 : 0;
        obj[i].var = variacion;
      }
    }
    return obj;
  }

  convertirMiles(obj: number[]): number[] {
    if (obj.length > 0) {
      return obj.map((e: number) => {
        return e / 1000;
      });
    }
    return [];
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }
}
