import { Component, Input, OnInit } from '@angular/core';
import { CertificadoDeVigencia, IEmpresaEnUnDiaCreateReportResponse } from 'src/app/interface/IEmpresaEnUnDiaCreateReportResponse';

@Component({
  selector: 'app-vigencia-viewer',
  templateUrl: './vigencia-viewer.component.html',
  styleUrls: ['./vigencia-viewer.component.scss']
})
export class VigenciaViewerComponent implements OnInit {
  @Input() content!: IEmpresaEnUnDiaCreateReportResponse;
  @Input() showHeader: boolean = true; 
  public certificado: CertificadoDeVigencia = {
    Declaracion: '',
    RutSociedad: '',
    RazonSocial: '',
    FechaConstitucion: '',
    FechaEmisionCertificado: '',
    CodigoVerificacion: '',
  };

  constructor() { }

  ngOnInit(): void {
    // TODO: this.content is null -> alert.warn
    if (this.content) {
      for (const certificado of this.content.EmpresaEnUnDiaReporte.Certificados) {
        if ("CertificadoDeVigencia" in certificado) {
          this.certificado = certificado.CertificadoDeVigencia!;
        }
      }
    }
  }

}
