import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SessionService } from './session.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CesionFacturasService {
  private servicio: string = environment.backend.cesionFacturas.servicio;
  private endpoint: string = environment.backend.cesionFacturas.url;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService
  ) { }

  obtenerPagina(rut: string, pagina: number, nElementos: number, filtros: any): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "registros");

    if(pagina || pagina === 0) {
      params = params.append("pagina", pagina);
    }

    if(nElementos || nElementos === 0) {
      params = params.append("registrosPagina", nElementos);
    }

    if(filtros && Object.keys(filtros).length > 0) {
      for (const clave in filtros){
        params = params.append(clave, filtros[clave]);
      }
    }

    return this.http.get<any>(`${this.endpoint}obtener`, { params });
  }

  obtenerReportePorId(id: string): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("id_transaccion", id);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "buscarTX");

    return this.http.get<any>(`${this.endpoint}obtener`, { params });
  }

  crearReporte(rut: string, tipo: string, claveSii: string = '', isClaveOrg: boolean = true): Observable<any> {
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      claveSii: claveSii,
      servicio: this.servicio,
      tipo: tipo,
      isClaveOrg: isClaveOrg,
      uri: "crearReporte/async",
    }
    return this.http.post<any>(`${this.endpoint}crear`, payload);
  }

  actualizarDTE(rut: string, fechaInicio: string = '', fechaFin: string = ''): Observable<any> {
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "estadoCesiones",
    }
    
    if(fechaInicio)
      payload.fechaInicio = fechaInicio;
    if(fechaFin)
      payload.fechaFin = fechaFin;

    return this.http.post<any>(`${this.endpoint}crear`, payload);
  }

  actualizarDTEUnitario(rut: string, rutVendedor: string, folio: string, tipoDoc: string): Observable<any> {
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      folio: folio,
      tipoDoc: tipoDoc,
      rutVendedor: rutVendedor,
      servicio: this.servicio,
      uri: "estadoCesion",
    }

    return this.http.post<any>(`${this.endpoint}crear`, payload);
  }

  obtenerEstadoDTE(rut: string, fechaInicio: string, fechaFin: string): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("rut", rut);
    params = params.append("fechaInicio", fechaInicio);
    params = params.append("fechaFin", fechaFin);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "estadoCesion");

    return this.http.get<any>(`${this.endpoint}obtener`, {params});
  }

  actualizarEstadoPagado(rut: string, folio: string, tipoDoc: string, vendedor: string): Observable<any> {
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "pago",
      tipoDoc: tipoDoc,
      rutVendedor: vendedor,
      folio: folio
    }

    return this.http.post<any>(`${this.endpoint}crear`, payload);
  }

  obtenerEstados(): Observable<any> { // falta por definir en backend - validar*
    let params = new HttpParams(); 
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "estadosDte"); 

    return this.http.get<any>(`${this.endpoint}obtener`, {params});
  }

  obtenerResumen(rut: string, pagina: number, nElementos: number): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "resumen"); 

    if(pagina || pagina === 0) {
      params = params.append("pagina", pagina);
    }

    if(nElementos || nElementos === 0) {
      params = params.append("registrosPagina", nElementos);
    }

    return this.http.get<any>(`${this.endpoint}obtener`, {params});
  }

  obtenerFactorizacionDeudor(rut: string, pagina: number, nElementos: number, filtros: any): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "factorizacion/deudor"); 

    if(pagina || pagina === 0) {
      params = params.append("pagina", pagina);
    }

    if(nElementos || nElementos === 0) {
      params = params.append("registrosPagina", nElementos);
    }

    if(filtros && Object.keys(filtros).length > 0) {
      for (const clave in filtros){
        params = params.append(clave, filtros[clave]);
      }
    }

    return this.http.get<any>(`${this.endpoint}obtener`, {params});
    
  }

  obtenerFactorizacionFactoring(rut: string, pagina: number, nElementos: number, filtros: any): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "factorizacion/factoring"); 

    if(pagina || pagina === 0) {
      params = params.append("pagina", pagina);
    }

    if(nElementos || nElementos === 0) {
      params = params.append("registrosPagina", nElementos);
    }

    if(filtros && Object.keys(filtros).length > 0) {
      for (const clave in filtros){
        params = params.append(clave, filtros[clave]);
      }
    }

    return this.http.get<any>(`${this.endpoint}obtener`, {params});

  }

  obtenerListDeudor(rut: string, filtros: any): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "listadoDeudores");

    if(filtros && Object.keys(filtros).length > 0) {
      for (const clave in filtros){
        params = params.append(clave, filtros[clave]);
      }
    }

    return this.http.get<any>(`${this.endpoint}obtener`, {params});

  }

  obtenerListFactoring(rut: string, filtros: any): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "listadoCesionarios"); 

    if(filtros && Object.keys(filtros).length > 0) {
      for (const clave in filtros){
        params = params.append(clave, filtros[clave]);
      }
    }

    return this.http.get<any>(`${this.endpoint}obtener`, {params});

  }

}
