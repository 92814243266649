<div class="modal-content">
    <div class="modal-header">
        <h4>{{confirmData.title || 'Confirm'}}</h4>
    </div>
    <form (ngSubmit)="submit()" [formGroup]="modalRutForm">
    <div class="modal-body" [ngClass]="confirmData.dynamicClass? confirmData.dynamicClass: ''">
        <p>
            {{confirmData.message || 'Ingresar dato'}}
        </p>
            <div class="form-group">
                <input formControlName="rut" type="text" class="form-control" formatRut required>
                <app-error [control]="modalRutForm.get('rut')">
                </app-error>
            </div>
       
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="closeModal()" *ngIf="!confirmData.hideCancelmButtom">
            {{confirmData.textBoton && confirmData.textBoton.length > 0? confirmData.textBoton[1]: 'Cancel'}}
        </button>
        <button type="submit" class="btn btn-primary">
            {{confirmData.textBoton && confirmData.textBoton.length > 0? confirmData.textBoton[0]: 'Continuar'}}
        </button>
    </div>
</form>
</div>