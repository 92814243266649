<div class="content-header content-header-media">
    <div class="header-section">
        <h1>BIENVENIDO<br><small>www.informax.cl</small></h1>
    </div>
</div>
<div class="row">
    <div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
        <div class="block block-alt-noborder">
            <article>
                <p>
                    Bienvenido a Informax en este Módulo ud podrá consultar reportes de Riesgo de sus clientes y
                    proveedores para minimizar el riesgo de crédito y comercial.
                </p>
                <p>
                    Conozca la información de la empresa a evaluar, en que mercado esta inserto, rangos de venta y
                    patrimonio, fecha de iniciación de actividades, número de empresas que componen el mercado y
                    participación relativa de la empresa en el mercado, información de comportamiento comercial y muchos
                    más.
                </p>
            </article>
        </div>
    </div>
</div>
