import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-solicitudes-consultar',
  templateUrl: './solicitudes-consultar.component.html',
  styleUrls: ['./solicitudes-consultar.component.scss']
})
export class SolicitudesConsultarComponent {
  public title = 'Solicitudes';
  public body: string[] = [`
  En este Módulo Ud. podrá solicitar que nuestro equipo de expertos
  confeccione un reporte financiero completo con una opinión de
  crédito según el análisis del cliente o proveedor también podrá

  solicitar que lo apoyemos con el vaciado de la información financiera
  para que tu puedas realizar el análisis con los datos ya procesados.
  `];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);

  }

}
