import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IReporteVehiculos, IVehiculosModel } from 'src/app/models/vehiculos/vehiculos.model';
import { environment } from '../../../environments/environment';
import { SessionService } from './session.service';

export interface ICreateReportVehiculosParams {
  rut: string;
  idOrganizacion: string;
  idUsuario: string;
  servicio: string;
  uri: string;
  requestOrigin: string;
}

@Injectable({
  providedIn: 'root'
})
export class VehiculosService {
  private endpoint: string = environment.backend.vehiculos.url;
  private servicio: string = environment.backend.vehiculos.servicio;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService) { }

  public createReport(rut: string) {

    const payload: ICreateReportVehiculosParams = {
      rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      servicio: this.servicio,
      uri: 'vehiculos',
      requestOrigin: "WEB"
    }

  return this.http.post(`${this.endpoint}crear`, payload);
  }
  
  buscarReportes(rut: string): Observable<IVehiculosModel[]> {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarVehiculos');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    return this.http.get<IVehiculosModel[]>(`${this.endpoint}obtener`, { params });
  }

  obtenerReporte(rut: string, idTransaccion: string): Observable<IReporteVehiculos> {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarVehiculos');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    params = params.append("id_transaccion", idTransaccion);
    return this.http.get<IReporteVehiculos>(`${this.endpoint}obtener`, { params });
  }

  obtenerLastUpdate(rut: string): Observable<IReporteVehiculos> {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'vehiculosSearchUpdated');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    return this.http.get<IReporteVehiculos>(`${this.endpoint}obtener`, { params });
  }

}
