import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IBoletinConcursalCreateReportResponse } from 'src/app/interface/IBoletinConcursalCreateReportResponse';
import { BackendAuthService } from './backend-auth.service';
import { SessionService } from './session.service';
import { IBoletinConcursalSearchReportResponse } from 'src/app/interface/IBoletinConcursalSearchReportResponse';
import { IBoletinConcursalGetReportResponse } from 'src/app/interface/IBoletinConcursalGetReportResponse';
import { Observable } from 'rxjs';

interface ICreateReport {
  rut: string;
  idUsuario: string;
  idOrganizacion: string;
  requestOrigin: string;
  servicio: string;
  uri: string;
}

@Injectable({
  providedIn: 'root'
})
export class BoletinConcursalService {
  private endpoint: string = environment.backend.boletinConcursal.url;
  private servicio: string = environment.backend.boletinConcursal.servicio;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService,
    private _backendAuthService: BackendAuthService
  ) { }

  public createReport(rut: string): Observable<IBoletinConcursalCreateReportResponse> {
    const payload: ICreateReport = {
      rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "boletinConcursal"
    };

    return this.http.post<IBoletinConcursalCreateReportResponse>(this.endpoint + "crear", payload);
  }

  public getReport(idTransaccion: string): Observable<IBoletinConcursalGetReportResponse> {

    let params = new HttpParams();
    params = params.append("id_transaccion", idTransaccion);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarTXBoletinConcursal');
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');

    return this.http.get<IBoletinConcursalGetReportResponse>(this.endpoint + "obtener", { params });
  }

  public searchReportsList(rut: string): Observable<IBoletinConcursalSearchReportResponse[]> {

    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarBoletinConcursal');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');

    return this.http.get<IBoletinConcursalSearchReportResponse[]>(this.endpoint + "obtener", { params });
  }

  boletinConcursalReportLastUpdated(rut: string): Observable<IBoletinConcursalGetReportResponse> {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'boletinconcursalSearchUpdated');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    return this.http.get<IBoletinConcursalGetReportResponse>(`${this.endpoint}obtener`, { params });
  }
}
