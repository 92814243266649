<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<ng-container *ngIf="hasUserAccess">

    <ng-container *ngIf="seeTable">
    
        <button type="button" class="btn btn-success float-right" (click)="verCargar()">Cargar</button>
    
        <div class="card text-center my-4">
        
            <div class="card-header">
                <div class="my-0 row d-flex px-4">
        
                    <div class="col-xs-3 col-sm-auto mr-auto p-2">
                        <app-paginator-by-items title="Cantidad de filas a mostrar en la tabla" [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                    </div>
        
                    <div class="col-xs-3 col-sm-auto p-2">
    
                        <div class="row">
    
                            <div class="col-10 py-0">
                                <div class="input-group mb-3">
                                    <input id="table-complete-search" type="text" class="form-control" name="searchTermUsuario"
                                        title="Ingrese texto para filtrar tabla"
                                        placeholder="Filtrar tabla"
                                        [(ngModel)]="inputText" 
                                        (keyup)="filtrarObjeto()"
                                    >
                                </div>
                            </div>
                            
                            <div class="col-2">
                                <button 
                                    type="button" 
                                    class="btn btn-info float-right refresh-button" 
                                    (click)="actualizarTabla()"
                                    title="Actualizar tabla"
                                >
                                    <i class="fa fa-refresh" aria-hidden="true"></i>
                                </button>
                            </div>
    
                        </div>
    
                    </div>
                    
                </div>
            </div>
        
            <div class="table-responsive">
                <table class="table table-hover border my-0">
                    <thead>
                        <tr>
                        <th scope="col" class="text-center align-middle font-size cursor-pointer"
                            (click)="cambioDeCampo('IDTransaccion')"
                            rowspan="2"
                        >
                            ID Transacción
                            <div class="float-right">
                                <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                            </div>
                        </th>
                        <th scope="col" class="text-center align-middle font-size cursor-pointer"
                            (click)="cambioDeCampo('FechaReporteFormatted')"
                            rowspan="2"
                        >
                            Fecha Carga
                            <div class="float-right">
                                <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                            </div>
                        </th>
                        <th scope="col" class="text-center align-middle font-size cursor-pointer min-width-th"
                            (click)="cambioDeCampo('mensaje')"
                            rowspan="2"
                        >
                            Estado
                            <div class="float-right">
                                <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                            </div>
                        </th>
                        <th scope="col" class="text-center align-middle font-size cursor-pointer"
                            rowspan="2"
                        >
                            Archivo Resultado
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let elemento of tablaFiltrado | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                            <tr>
        
                                <td class="font-size">
                                    {{ elemento.IDTransaccion || '' }}
                                </td>
        
                                <td class="font-size">
                                    {{ elemento.FechaReporteFormatted || '' }}
                                </td>
        
                                <td class="font-size">
                                    <ng-container *ngIf="elemento.EstadoTransaccion === 'COMPLETED' && elemento.SubEstadoTransaccion === 'COMPLETED'">
                                        <i class="fa fa-check-circle-o text-success" [title]="elemento.mensaje" aria-hidden="true"></i>
                                    </ng-container>
                                    <ng-container *ngIf="elemento.EstadoTransaccion === 'PROCESSING'">
                                        <i class="fa fa-clock-o text-warning" [title]="elemento.mensaje" aria-hidden="true"></i>
                                    </ng-container>
                                    <ng-container *ngIf="elemento.EstadoTransaccion === 'ERROR' || (elemento.EstadoTransaccion === 'COMPLETED' && elemento.SubEstadoTransaccion === 'ERROR')">
                                        <i class="fa fa-exclamation-circle text-danger" [title]="elemento.mensaje" aria-hidden="true"></i>
                                    </ng-container>
                                </td>
        
                                <td class="font-size">
                                    <i class="fa fa-download text-success cursor-pointer align-middle mx-2" 
                                        title="Descargar archivo" 
                                        aria-hidden="true" 
                                        (click)="obtenerArchivo(elemento)"
                                    ></i>
                                    <a [id]="elemento.IDTransaccion" style="display: none;"></a>
                                </td>
        
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        
            <div class="card-footer text-muted">
                <div class="p-2">
                    <div id="pagination">
                        <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                            <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    
    </ng-container>
    
    <ng-container *ngIf="seeUpload">
    
        <button type="button" class="btn btn-success float-right" (click)="volverTabla()">Ver Tabla</button>
    
        <div class="card text-center my-4">
    
            <div class="card-header">
    
                <div class="my-0 row d-flex px-4">
    
                    <span class="font-size">Carga de archivo</span>
                    
                </div>
    
            </div>
    
            <form [formGroup]="formularioArchivo">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group ml-2 my-5">
                
                            <label class="uploadbutton">
                                <input class="input-file" formControlName="file" type="file" name="file" accept=".csv"
                                    (change)="handleUpload($event)" id="file-csv-carga-automatica"/>
                                <div class="button btn">Nueva Carga</div>
                                <div class='input-file-text' [ngClass]="{'text-success': formularioArchivo.controls.file.value}">
                                    {{formularioArchivo.controls.file.value || 'Subir Archivo'}}
                                </div>
                            </label>
                            <div (click)="submitArchivo()" class="upload-file btn bg-warning" *ngIf="formularioArchivo.controls.file.value">
                                <span class="material-icons">upload</span>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-12">
                        <a 
                            id="descargaejemplo"
                            class="text-muted"
                            title="Descargar archivo"
                            href="../assets/files/ejemploCargaMasiva.csv"
                            download
                        >
                            Descargar archivo de ejemplo
                            <i class="fa fa-download text-success align-middle mx-2" 
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                    <div class="col-12">
                        <span 
                            class="d-inline-block mb-3 text-muted cursor-pointer cursor-pointer-info" 
                            (click)="mostrarModal()"
                            title="Presione para obtener mas información"
                            >
                            Archivo de carga debe ser csv con separador ; y tener un máximo de 100 registros
                            <i class="fa fa-exclamation-circle text-warning" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>

            </form>
        </div>
    
    </ng-container>
    
</ng-container>    

