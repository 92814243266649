<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>
<div *ngIf="hasUserAccess && searchVehiculosDataSource.length === 0">
    <div class="container__form">
        <div class="col-md-6">
            <div class="block">
                <form [formGroup]="searchVehiculosForm" (ngSubmit)="buscarReporte()">
                    <div class="form-horizontal form-bordered">
                        <div class="form-group" [ngClass]="{'has-error': activateRutHelper}">
                            <label class="col-md-3 control-label" for="example-text-input">Rut</label>
                            <div class="col-md-6">
                                <input formControlName="rut" type="text" class="form-control" formatRut required>
                                <span *ngIf="activateRutHelper" class="help-block">Rut no valido</span>
                            </div>
                        </div>
                        <div class="form-group form-actions container__actions">
                            <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i>
                                Buscar Reporte</button>
                            <button type="button" (click)="clearForm()" class="btn btn-sm btn-warning"><i class="fa fa-repeat"></i>
                                Limpiar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="searchVehiculosDataSource.length > 0 && !showReport">
        
    <div class="row">
        <div class="col-md-9 mx-auto my-4">
            <div class="col-12">

                <div class="row container__back-button">
                    <button (click)="backToSearchForm()" class="btn btn-primary ml-4">Volver</button>
                </div>

                <h2>
                    <strong>Resultados de la Busqueda para: </strong>
                    rut <span class="text-capitalize">{{rut | rutFormat}}</span>
                </h2>

                <div class="card text-center my-4">

                    <div class="card-header">
                        <div class="my-0 row d-flex px-4">

                            <div class="col-xs-3 col-sm-auto mr-auto p-2">
                                <app-paginator-by-items [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                            </div>

                            <div class="col-xs-3 col-sm-auto p-2">

                                <div class="row">

                                    <div class="col-10 py-0">
                                        <div class="input-group mb-3">
                                            <input id="table-complete-search" type="text" 
                                                class="form-control" name="searchTerm" [(ngModel)]="inputText" 
                                                (keyup)="filtrarObjeto()" placeholder="Filtrar tabla"
                                            >
                                        </div>
                                    </div>

                                    <div class="col-2">
                                        <button 
                                            type="button" 
                                            class="btn btn-info float-right refresh-button" 
                                            (click)="actualizarTabla()"
                                            title="Actualizar tabla"
                                        >
                                            <i class="fa fa-refresh" aria-hidden="true"></i>
                                        </button>
                                    </div>

                                </div>
                                
                            </div>

                        </div>
                    </div>

                    <div class="col-xs-3 col-sm-auto">
                        <div class="table-responsive">
                            <table id="example-datatable" class="table table-bordered table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-center font-size cursor-pointer"
                                        (click)="cambioDeCampo('IDTransaccion')">
                                            Id Transaccion
                                            <div class="float-right">
                                                <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                            </div>
                                        </th>
                                        <th scope="col" class="text-center font-size cursor-pointer"
                                        (click)="cambioDeCampo('EstadoTransaccionMapeada')">
                                            Estado
                                            <div class="float-right">
                                                <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                            </div>
                                        </th>
                                        <th scope="col" class="text-center font-size cursor-pointer"
                                        (click)="cambioDeCampo('FechaReporte')">
                                            Fecha
                                            <div class="float-right">
                                                <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let report of listaReportesFiltrado | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                                        <td class="text-center cursor-pointer" (click)="displayReport(report?.IDTransaccion)">
                                            {{report?.IDTransaccion}}
                                        </td>
                                        <td class="text-center cursor-pointer" (click)="displayReport(report?.IDTransaccion)">
                                            <span class="label"
                                            [ngClass]="{'label-danger' : report?.EstadoTransaccion === 'ERROR', 'label-success' : report?.EstadoTransaccion === 'COMPLETED', 'label-warning' : report?.EstadoTransaccion === 'IN-PROGRESS'}"
                                            >{{report?.EstadoTransaccionMapeada}}</span>
                                        </td>
                                        <td class="text-center cursor-pointer" (click)="displayReport(report?.IDTransaccion)">
                                            {{report?.FechaFormatted}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="card-footer text-muted">
                        <div class="p-2">
                            <div id="pagination">
                                <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                                    <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</ng-container>

<div *ngIf="showReport">
    <div class="container-gtp">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="btn-option">
                    <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                    <button [printTitle]='"Vehiculos_"+(rut | rutFormat)+"_"+today'
                    [useExistingCss]="true" printSectionId="print-section" ngxPrint class="btn btn-primary">Descargar PDF</button>
                </div>
            </div>
        </div>
    </div>
    <div id="print-section">
        <app-vehiculos-viewer [content]='vehiculosContent'></app-vehiculos-viewer>
    </div>
</div>