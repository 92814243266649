import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';
import { AlertService } from '../../_alert';

@Component({
  selector: 'app-informacion-cliente',
  templateUrl: './informacion-cliente.component.html',
  styleUrls: ['./informacion-cliente.component.scss']
})
export class InformacionClienteComponent implements OnInit, OnDestroy {

  @Input() proceso: boolean = true;
  @Input() rut: string = '';
  @Input() idTransaccion!: string;
  @Input() stepper!: number;
  @Input() modelos: any[] = [];
  @Output() showStepper = new EventEmitter<boolean>();
  public informacionBasicaJudicial: any[] = [];
  public header: any[] = ['numSolicitud', 'status', 'date', 'search', 'edit'];
  public dataTable: any[] = [];
  public data: any[] = [];
  public informacionBoletinConcursal: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private solicitudCreditoService: SolicitudCreditoService,
    private spinner: NgxSpinnerService,
    public alertService: AlertService) { }

  ngOnInit(): void {
    this.getBasicJudicial();
  }

  continuar() {
    this.solicitudCreditoService.setStep('next');
  }

  showStepp() {
    this.showStepper.emit(true);
  }

  getTotal(civil: string, cobranza: string, laboral: string): number {
    return Number(civil) + Number(cobranza) + Number(laboral);
  }

  getBasicJudicial() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.searchSolicitudesCreditoID(this.idTransaccion, this.rut).subscribe(async resp => {
      this.spinner.hide();
      if (resp) {
        const reporte = resp.SolicitudDeCredito.Reportes;
        await this.addDates(reporte);
        this.informacionBasicaJudicial.push(reporte);
        this.setBoletinConcursal(reporte);
      }

    }, (error: any) => {
      this.alertService.error(error.SolicitudDeCredito?.Reportes?.basicReport?.message || 'Ocurrio un Error');
      this.spinner.hide();
    }));
  }

  updateBasicReport() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.updateBasicReport(this.rut, this.idTransaccion).subscribe(async resp => {
      this.spinner.hide();
      if (resp) {
        this.informacionBasicaJudicial = [];
        const reporte = resp.SolicitudDeCredito.Reportes;
        await this.addDates(reporte);
        this.informacionBasicaJudicial.push(reporte);
      }
    }, (error: any) => {
      this.alertService.error(error.message);
      this.spinner.hide();
    }));
  }

  updatePoderJudicial() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.updatePoderJudicial(this.rut, this.idTransaccion).subscribe(async resp => {
      this.spinner.hide();
      if (resp) {
        this.informacionBasicaJudicial = [];
        const reporte = resp.SolicitudDeCredito.Reportes;
        await this.addDates(reporte);
        this.informacionBasicaJudicial.push(reporte);
      }
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    }));
  }

  updateBoletinConcursal() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.updateBoletinConcursal(this.rut, this.idTransaccion).subscribe(async resp => {
      this.spinner.hide();
      if (resp) {
        this.informacionBasicaJudicial = [];
        const reporte = resp.SolicitudDeCredito.Reportes;
        await this.addDates(reporte);
        this.setBoletinConcursal(reporte);
        this.informacionBasicaJudicial.push(reporte);
      }
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    }));
  }

  setBoletinConcursal(reporte: any): void {
    this.informacionBoletinConcursal = Object.keys(reporte.boletinConcursal.Reporte).length > 0 && !reporte.boletinConcursal.hasErrors? true : false;
  }

  async addDates(reporte: any) {
    reporte.fechaInformacionBasica = this.solicitudCreditoService.formatDate(this.solicitudCreditoService.getNested(reporte.basicReport, 'InformacionBasica', 'FechaReporte'));
    reporte.inicioActividades = this.solicitudCreditoService.formatDate(this.solicitudCreditoService.getNested(reporte.basicReport, 'InformacionBasica', 'AnoInicioActividades'));
    reporte.fechaJudcial = this.solicitudCreditoService.formatDate(this.solicitudCreditoService.getNested(reporte.poderJudicial, 'DatosBasicosSolicitud', 'FechaReporte'));
    reporte.fechaBoletinConcursal = this.solicitudCreditoService.formatDate(this.solicitudCreditoService.getNested(reporte.boletinConcursal, 'DatosBasicosSolicitud', 'FechaReporte'));  
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });    
  }
}
