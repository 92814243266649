<div class="panel panel-default border-0 not-print">
    <div class="panel-body">
        <div class="btn-option">
            <button *ngIf="showReportes && stepper === 5" [printTitle]='"FlujoDeCredito_"+(rut | rutFormat)+"_"+today'
                [useExistingCss]="true" printSectionId="print-section" ngxPrint
                class="btn btn-primary float-right">Descargar PDF</button>
        </div>
    </div>
  </div>
  
  <div class="not-print">
  
    <ul class="nav nav-tabs d-flex justify-content-center border-0 mt-3">
      <li><button id="button-balance-automaticos" (click)="scroll('balance-automaticos', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
          <i class="fa fa-bar-chart" aria-hidden="true"></i>
          Balance Automáticos
        </button>
      </li>
      <li><button id="button-deudores" (click)="scroll('deudores', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
          <i class="fa fa-users"></i>
          Deudores
        </button>
      </li>
      <li><button id="button-iva" (click)="scroll('iva', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
          <i class="fa fa-percent"></i>
          IVA
        </button>
      </li>
      <li><button id="button-linea" (click)="scroll('linea', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
          <i class="fa fa-list-alt"></i>
          Línea / Operación Propuesta
        </button>
      </li>
    </ul>
  
  </div>
  
  
  <div class="col-sm-12" id="print-section">
    <div class="row p-3">
      <div class="col-sm-12">
          <div class="row">
              <h3 class="col-12 my-2 mb-4 cuadro-title p-3 rounded-header">
                  Ficha Comité de Crédito
              </h3>
              <form [formGroup]="servicioFichaForm">
  
                <ng-template #fichaCabecera>
  
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Nombre</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.nombreComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Fecha</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.fechaComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Giro</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.giroComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Ejecutivo Atención</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.ejecutivoComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Rut</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.rutComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Canal Entrada</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.canalEntradaComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Sucursal Contratante</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.sucursalComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label pointer-cursor" (click)="abrirModalTitular()">Dicom Empresa</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.dicomEmpresa?.value || servicioFichaForm?.controls?.dicomEmpresa?.value == 0 ? (servicioFichaForm?.controls?.dicomEmpresa?.value | number: '1.0-2') : ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Enrolado</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.enroladoComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Fecha inicio Act SII</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.inicioSiiComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                </ng-template>
  
                <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else fichaCabecera">
  
                  <div class="col-sm-12 col-md-8">
                      <div class="form-group row">
                          <label for="nombre-comite" class="col-12 col-form-label">Nombre</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="nombre-comite" formControlName="nombreComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('nombreComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="fecha-comite" class="col-12 col-form-label">Fecha</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="fecha-comite" formControlName="fechaComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('fechaComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
        
                  <div class="col-sm-12 col-md-8">
                      <div class="form-group row">
                          <label for="giro-comite" class="col-12 col-form-label">Giro</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="giro-comite" formControlName="giroComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('giroComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="ejecutivo-comite" class="col-12 col-form-label">Ejecutivo Atención</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="ejecutivo-comite" formControlName="ejecutivoComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('ejecutivoComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
        
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="rut-comite" class="col-12 col-form-label">Rut</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="rut-comite" formControlName="rutComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('rutComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="canalAdmision-comite" class="col-12 col-form-label">Canal Entrada</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="canalAdmision-comite" formControlName="canalEntradaComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('canalEntradaComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="sucursal-comite" class="col-12 col-form-label">Sucursal Contratante</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="sucursal-comite" formControlName="sucursalComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('sucursalComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="dicomEmpresa-comite" class="col-12 col-form-label pointer-cursor" (click)="abrirModalTitular()">Dicom Empresa</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="dicomEmpresa-comite" formControlName="dicomEmpresa">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('dicomEmpresa')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="enrolado-comite" class="col-12 col-form-label">Enrolado</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="enrolado-comite" formControlName="enroladoComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('enroladoComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="inicioSii-comite" class="col-12 col-form-label">Fecha inicio Act SII</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="inicioSii-comite" formControlName="inicioSiiComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('inicioSiiComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
  
                </ng-container>
  
              </form>
          </div>
      </div>
    </div>
    
    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak" (click)="show('informacion-sociedad')">
      I. Información Sociedad
    </h3>
    <div class="container-desplegables" id="informacion-sociedad">
      <div class="row p-5">
        <div class="col-sm-12 col-lg-8">
          <div class="table-responsive" *ngIf="showReportes">
            <table class="table table-hover table-bordered border">
                <thead>
                  <tr>
                    <th scope="col" colspan="5" class="text-center py-1 bg-primary font-head-size">Composición de la sociedad</th>
                  </tr>
                  <tr>
                    <th scope="col" class="py-1 bg-primary font-head-size">Socio</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Rut</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">%</th>
                  </tr>
                </thead>
                <tbody>
  
                  <ng-template #sociosNoEditable>
  
                    <ng-container *ngFor="let item of sociosComposicion; let i = index">
                      <tr class="height-row pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">
                        <td>{{sociosComposicion[i].socio}}</td>
                        <td>{{sociosComposicion[i].rut | rutFormat}}</td>
                        <td>{{stringToFloat(sociosComposicion[i].porcentaje) | number: '1.0-2'}}</td>
                      </tr>
                    </ng-container>
  
                  </ng-template>
  
                  <ng-template #sinDatosSocios>
                    <tr class="height-row">
                      <td colspan="5">Sin información</td>
                    </tr>
                  </ng-template>
  
                  <ng-container *ngIf="sociosComposicion.length > 0 else sinDatosSocios">
                    
                    <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else sociosNoEditable">
                      
                      <ng-container *ngFor="let item of sociosComposicion; let i = index">
                        <tr class="height-row">
                          <td class="pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">{{sociosComposicion[i].socio}}</td>
                          <td class="pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">{{sociosComposicion[i].rut | rutFormat}}</td>
                          <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='socio-porcentaje{{i}}' name='socio-porcentaje{{i}}' [(ngModel)]="sociosComposicion[i].porcentaje"></td>
                        </tr>
                      </ng-container>
  
                    </ng-container>
  
  
                  </ng-container>
                  
                </tbody>
            </table>
          </div>
      </div>
      
      <div class="col-sm-12 col-lg-4">
        <div class="table-responsive" *ngIf="showReportes">
          <table class="table table-hover table-bordered border">
              <thead>
                <tr>
                  <th scope="col" colspan="4" class="text-center py-1 bg-primary font-head-size">Informes Comerciales</th>
                </tr>
                <tr>
                  <th scope="col" class="py-1 bg-primary font-head-size">N°</th>
                  <th scope="col" class="py-1 bg-primary font-head-size">Monto M$</th>
                  <th scope="col" class="py-1 bg-primary font-head-size">Motivo</th>
                </tr>
              </thead>
              <tbody>
  
                <ng-template #sinDatosSociosInforme>
                  <tr class="height-row">
                    <td colspan="4">Sin información</td>
                  </tr>
                </ng-template>
  
                <ng-container *ngIf="sociosInformes.length > 0 else sinDatosSociosInforme">
  
                  <ng-container *ngFor="let item of sociosInformes; let i = index">
                    <tr class="height-row pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">
                      <td>{{sociosInformes[i].numero | number: '1.0-0'}}</td>
                      <td>{{sociosInformes[i].monto | number: '1.0-2'}}</td>
                      <td>{{sociosInformes[i].motivo}}</td>
                    </tr>
                  </ng-container>
                  
                </ng-container>
  
              </tbody>
            </table>
          </div>
        </div>
  
        <div class="col-sm-12 col-lg-8">
          <div class="table-responsive" *ngIf="showReportes">
            <table class="table table-hover table-bordered border">
              <thead>
                <tr>
                  <th scope="col" class="py-1 bg-primary font-head-size">Empresas Relacionadas</th>
                  <th scope="col" class="py-1 bg-primary font-head-size">Rut</th>
                  <th scope="col" class="py-1 bg-primary font-head-size">%</th>
                </tr>
              </thead>
              <tbody>
  
                <ng-template #empresasRelacionadasComposicionNoEditable>
  
                  <ng-container *ngFor="let item of empresasRelacionadasComposicion; let i = index">
                    <tr class="height-row pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">
                      <td>{{empresasRelacionadasComposicion[i].socio}}</td>
                      <td>{{empresasRelacionadasComposicion[i].rut | rutFormat}}</td>
                      <td>{{stringToFloat(empresasRelacionadasComposicion[i].porcentaje) | number: '1.0-2'}}</td>
                    </tr>
                  </ng-container>
  
                </ng-template>
  
                <ng-template #sinDatosEmpresas>
                  <tr class="height-row">
                    <td colspan="5">Sin información</td>
                  </tr>
                </ng-template>
  
                <ng-container *ngIf="empresasRelacionadasComposicion.length > 0 else sinDatosEmpresas">
  
                  <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else empresasRelacionadasComposicionNoEditable">
                    
                    <ng-container *ngFor="let item of empresasRelacionadasComposicion; let i = index">
                      <tr class="height-row">
                        <td class="pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">{{empresasRelacionadasComposicion[i].socio}}</td>
                        <td class="pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">{{empresasRelacionadasComposicion[i].rut | rutFormat}}</td>
                        <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='empresa-porcentaje{{i}}' name='empresa-porcentaje{{i}}' [(ngModel)]="empresasRelacionadasComposicion[i].porcentaje"></td>
                      </tr>
                    </ng-container>
  
                  </ng-container>
                  
                </ng-container>
  
              </tbody>
            </table>
          </div>
        </div>
  
        <div class="col-sm-12 col-lg-4">
          <div class="table-responsive" *ngIf="showReportes">
              <table class="table table-hover table-bordered border">
                <thead>
                  <tr>
                    <th scope="col" class="py-1 bg-primary font-head-size">N°</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Monto M$</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Motivo</th>
                  </tr>
                </thead>
                <tbody>
  
                  <ng-template #sinDatosEmpresasInforme>
                    <tr class="height-row">
                      <td colspan="4">Sin información</td>
                    </tr>
                  </ng-template>
  
                  <ng-container *ngIf="empresasRelacionadasInformes.length > 0 else sinDatosEmpresasInforme">
                    <ng-container *ngFor="let item of empresasRelacionadasInformes; let i = index">
                      <tr class="height-row pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">
                        <td>{{empresasRelacionadasInformes[i].numero | number: '1.0-0'}}</td>
                        <td>{{empresasRelacionadasInformes[i].monto | number: '1.0-2'}}</td>
                        <td>{{empresasRelacionadasInformes[i].motivo}}</td>
                      </tr>
                    </ng-container>
                  </ng-container>
  
                </tbody>
              </table>
            </div>
          </div>
      </div>
    </div>
  
    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('deudores', 'button-deudores')">
      Deudores
    </h3>
    <div class="container-desplegables" id="deudores">
        <div class="row p-5">
          <div class="col-sm-12">
            <form [formGroup]="servicioDeudoresForm">
  
              <ng-template #fichaCabeceraDeudores>
  
                <div class="col-sm-12">
                  <div class="form-group row">
                      <label class="col-4 col-form-label">Cliente</label>
                      <div class="col-8">
                        <span>{{servicioDeudoresForm?.controls?.clienteComite?.value || ''}}</span>
                      </div>
                    </div>
                </div>
  
                <div class="col-sm-12">
                  <div class="form-group row">
                      <label class="col-4 col-form-label">Rut</label>
                      <div class="col-8">
                        <span>{{servicioDeudoresForm?.controls?.rutClienteComite?.value || ''}}</span>
                      </div>
                    </div>
                </div>
  
                <div class="col-sm-12">
                  <div class="form-group row">
                      <label class="col-4 col-form-label">Fecha</label>
                      <div class="col-8">
                        <span>{{servicioDeudoresForm?.controls?.fechaClienteComite?.value || ''}}</span>
                      </div>
                    </div>
                </div>
  
              </ng-template>
              
  
              <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else fichaCabeceraDeudores">
  
                <div class="col-sm-12">
                  <div class="form-group row">
                      <label for="cliente-comite" class="col-4 col-form-label">Cliente</label>
                      <div class="col-8">
                        <input type="text" class="form-control" id="cliente-comite" formControlName="clienteComite">
                        <div class="w-100">
                          <app-error [control]="servicioDeudoresForm.get('clienteComite')"></app-error>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group row">
                        <label for="rut-cliente-comite" class="col-4 col-form-label">Rut</label>
                        <div class="col-8">
                          <input type="text" class="form-control" id="rut-cliente-comite" formControlName="rutClienteComite">
                          <div class="w-100">
                            <app-error [control]="servicioDeudoresForm.get('rutClienteComite')"></app-error>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group row">
                      <label for="fecha-cliente-comite" class="col-4 col-form-label">Fecha</label>
                      <div class="col-8">
                        <input type="text" class="form-control" id="fecha-cliente-comite" formControlName="fechaClienteComite">
                        <div class="w-100">
                          <app-error [control]="servicioDeudoresForm.get('fechaClienteComite')"></app-error>
                        </div>
                      </div>
                    </div>
                </div>
  
              </ng-container>
  
            </form>
          </div>
  
          <div class="col-sm-12 my-3">
            <div class="table-responsive" *ngIf="showReportes">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">N</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Nombre</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Rut</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" colspan="3">Monto (M$)</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" colspan="2">Informes Comerciales</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size">D</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Rango SII</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Web</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Historico Cancelado MM$</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Dias promedio de mora</th>
                  </tr>
                  <tr>
                    <th scope="col" class="py-1 bg-primary font-head-size">Facturas</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Factura a Financiar</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Cheques</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">N°</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Monto M$</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">M$</th>
                  </tr>
                </thead>
                <tbody>
  
                  <ng-template #deudoresNoEditable>
  
                    <ng-container *ngFor="let item of deudores; let i = index">
                      <tr class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">
                        <td>{{(i + 1)}}</td>
                        <td>{{deudores[i].nombre}}</td>
                        <td>{{deudores[i].rut | rutFormat}}</td>
                        <td>{{deudores[i].facturas | number: '1.0-2'}}</td>
                        <td>{{stringToFloat(deudores[i].facturaFinanciar) | number: '1.0-2'}}</td>
                        <td>{{stringToFloat(deudores[i].cheques) | number: '1.0-2'}}</td>
                        <td>{{deudores[i].numero | number: '1.0-0'}}</td>
                        <td>{{deudores[i].monto | number: '1.0-2'}}</td>
                        <td>{{deudores[i].miles | number: '1.0-2'}}</td>
                        <td>{{deudores[i].rangoSii}}</td>
                        <td>{{deudores[i].web}}</td>
                        <td>{{stringToFloat(deudores[i].histCancelado) | number: '1.0-2'}}</td>
                        <td>{{stringToFloat(deudores[i].diasPromedio) | number: '1.0-2'}}</td>
                      </tr>
                    </ng-container>
  
                  </ng-template>
  
                  <ng-template #sinDatosDeudores>
                    <tr class="height-row">
                      <td colspan="13">Sin información</td>
                    </tr>
                  </ng-template>
                    
                  <ng-container *ngIf="deudores.length > 0 else sinDatosDeudores">
  
                    <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else deudoresNoEditable">
                      
                      <ng-container *ngFor="let item of deudores; let i = index">
                        <tr>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{(i + 1)}}</td>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].nombre}}</td>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].rut | rutFormat}}</td>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].facturas | number: '1.0-2'}}</td>
                          <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='deudor-facturaFinanciar{{i}}' name='deudor-facturaFinanciar{{i}}' [(ngModel)]="deudores[i].facturaFinanciar"></td>
                          <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='deudor-cheques{{i}}' name='deudor-cheques{{i}}' [(ngModel)]="deudores[i].cheques"></td>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].numero | number: '1.0-0'}}</td>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].monto | number: '1.0-2'}}</td>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].miles | number: '1.0-2'}}</td>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].rangoSii}}</td>
                          <td><input type="text" class="form-control" id='deudor-web{{i}}' name='deudor-web{{i}}' [(ngModel)]="deudores[i].web"></td>
                          <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='deudor-histCancelado{{i}}' name='deudor-histCancelado{{i}}' [(ngModel)]="deudores[i].histCancelado"></td>
                          <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='deudor-diasPromedio{{i}}' name='deudor-diasPromedio{{i}}' [(ngModel)]="deudores[i].diasPromedio"></td>
                        </tr>
                      </ng-container>
  
                    </ng-container>
  
                  </ng-container>
  
                </tbody>
              </table>
            </div>
          </div>
  
          <div class="col-sm-12 my-3">
            <div class="table-responsive" *ngIf="showReportes">
              <table class="table table-hover table-bordered border">
                <thead>
                  <tr>
                    <th scope="col" colspan="5" class="text-center py-1 bg-primary font-head-size">Factoring con que opera</th>
                  </tr>
                  <tr>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Rut</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Nombre</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1" colspan="3">Monto Cesiones en M$</th>
                  </tr>
                  <tr>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1">3 Meses</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1">6 Meses</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1">12 Meses</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-template #sinDatosFactoring>
                    <tr class="height-row">
                      <td colspan="5">Sin información</td>
                    </tr>
                  </ng-template>
  
                  <ng-container *ngIf="factoring.length > 0 else sinDatosFactoring">
                    <ng-container *ngFor="let item of factoring; let i = index">
                      <tr>
                        <td>{{item.CESIONARIO | rutFormat}}</td>
                        <td>{{item.rz_cesionario}}</td>
                        <td>{{item.monto3Meses !== ''? (item.monto3Meses | number: '1.0-0'): item.monto3Meses}}</td>
                        <td>{{item.monto6Meses !== ''? (item.monto6Meses | number: '1.0-0'): item.monto6Meses}}</td>
                        <td>{{item.Monto12Meses !== ''? (item.Monto12Meses | number: '1.0-0'): item.Monto12Meses}}</td>
                      </tr>
                    </ng-container>
                  </ng-container>
  
                </tbody>
              </table>
            </div>
          </div>
  
          <div class="col-sm-12 my-3">
            <div class="table-responsive" *ngIf="showReportes">
              <table class="table table-hover border">
                <thead>
                  <tr>
                    <th scope="col" colspan="5" class="text-center py-1 bg-primary font-head-size">Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ng-template #observacionesNoEditable>
  
                        <div [innerHTML]="agregarSaltosDeLinea(observaciones.text)"></div>
  
                      </ng-template>
  
                      <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else observacionesNoEditable">
  
                        <textarea class="form-control" id="deudores-observaciones" name="deudores-observaciones" [(ngModel)]="observaciones.text" maxlength="500"></textarea>
                      
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
  
  
        </div>
    </div>
    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak" (click)="show('iva', 'button-iva')">
      II. Información Financiera
    </h3>
    <div class="container-desplegables" id="iva">

      <ng-container *ngIf="f29Tablas && f29Tablas.length > 0; else ivaNoData">
        <div class="row p-5" *ngFor="let elemento of f29Tablas; let i = index" [attr.data-index]="i" [attr.saltoLinea]="i%2===0 && i!==0 ? true : null">
          <div class="col-sm-12">
              <div class="card textos-campo" *ngIf="showReportes">
                  <h5 class="container-fluid card-header list-group-item cuadro-color">
                      Ventas {{ elemento.periodo }}
                  </h5>
                  <div class="card-body">
                      <div class="col-12">
                          <div class="table-responsive">
                              <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th class="border-0"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                        <th>Mes</th>
                                        <th>Ventas Netas M$</th>
                                        <th>Ventas Netas UF</th>
                                        <th>Compras Netas M$</th>
                                        <th>Compras Netas UF</th>
                                        <th>Margen M$</th>
                                        <th>% NC / Ventas</th>
                                        <th>Fecha Pago</th>
                                        <th>Tipo Declaración</th>
                                        <th>Postergado</th>
                                        <th>Variación % Ventas</th>
                                      </tr>
                                      <tr *ngFor="let item of elemento.datosReporte"><th>{{item.nombreMes}}</th>
                                        <td><span>{{ item.ventasNetasMiles | number:'1.0-1'}}</span></td>
                                        <td><span>{{ item.ventasNetasUF | number:'1.0-1'}}</span></td>
                                        <td><span>{{ item.comprasNetasMiles | number:'1.0-1'}}</span></td>
                                        <td><span>{{ item.comprasNetasUf | number:'1.0-1'}}</span></td>
                                        <td><span>{{ item.margenMiles | number:'1.0-1'}}</span></td>
                                        <td><span>{{ item.porc_notascred_ventas | number:'1.0-2'}}</span></td>
                                        <td class="text-nowrap"><span>{{ item.fechaPago}}</span></td>
                                        <td><span>{{ item.tipoDeclaracion}}</span></td>
                                        <td><span>{{ item.postergado | number:'1.0-1'}}</span></td>
                                        <td><span>{{ item.variacion | number:'1.0-2'}}</span></td>
                                      </tr>
                                      <tr><th>Totales</th>
                                        <td><span>{{ elemento.totalVentasNetasMiles | number:'1.0-1'}}</span></td>
                                        <td><span>{{ elemento.totalVentasNetasUF | number:'1.0-1'}}</span></td>
                                        <td><span>{{ elemento.totalComprasNetasMiles | number:'1.0-1'}}</span></td>
                                        <td><span>{{ elemento.totalComprasNetasUF | number:'1.0-1'}}</span></td>
                                        <td><span>{{ elemento.totalMargenMiles | number:'1.0-1'}}</span></td>
                                        <td><span>{{ elemento.totalPorcVentasMiles | number:'1.0-2'}}</span></td>
                                        <td></td>
                                        <td></td>
                                        <td><span>{{ elemento.totalPostergado | number:'1.0-1'}}</span></td>
                                        <td><span>{{ elemento.totalVarVentas | number:'1.0-2'}}</span></td>
                                      </tr>
                                      <tr><th>Promedio</th>
                                        <td><span>{{ (elemento.totalVentasNetasMiles/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                        <td><span>{{ (elemento.totalVentasNetasUF/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                        <td><span>{{ (elemento.totalComprasNetasMiles/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                        <td><span>{{ (elemento.totalComprasNetasUF/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                        <td><span>{{ (elemento.totalMargenMiles/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                        <td><span>{{ (elemento.totalPorcVentasMiles/elemento.mesesEnPeriodo) | number:'1.0-2'}}</span></td>
                                        <td></td>
                                        <td></td>
                                        <td><span>{{ (elemento.totalPostergado/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                        <td><span>{{ (elemento.totalVarVentas/elemento.mesesEnPeriodo) | number:'1.0-2'}}</span></td>
                                      </tr>
                                      <tr><th>Proyección</th>
                                        <td><span>{{ ((elemento.totalVentasNetasMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                        <td><span>{{ ((elemento.totalVentasNetasUF/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                        <td><span>{{ ((elemento.totalComprasNetasMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                        <td><span>{{ ((elemento.totalComprasNetasUF/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                        <td><span>{{ ((elemento.totalMargenMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                        <td><span>{{ ((elemento.totalPorcVentasMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-2'}}</span></td>
                                        <td></td>
                                        <td></td>
                                        <td><span>{{ ((elemento.totalPostergado/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                        <td><span>{{ ((elemento.totalVarVentas/elemento.mesesEnPeriodo)*12) | number:'1.0-2'}}</span></td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </ng-container>
      <ng-template #ivaNoData>
        <div class="row p-5">
          <div class="col-12">
            <span>
              <h4>No se encuentran datos en los servicios consultados.</h4>
            </span>
          </div>
        </div>
      </ng-template>
      
    </div>
  
    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('balance-automaticos', 'button-balance-automaticos')"
      [ngClass]="{'pagebreak': dataCargada}"
    >
      Balance Automatico
    </h3>
    <div class="container-desplegables" id="balance-automaticos">
      <div class="row p-5">
        <div class="col-12">
          <app-balance-automatico-demo-workflow *ngIf="dataCargada" #appinformef22view [reporteFullContent]="dataFiltrada" [rut]="rut"></app-balance-automatico-demo-workflow>
          <span *ngIf="!dataCargada">
            <h4>No se encuentran datos en los servicios consultados.</h4>
          </span>
        </div>
      </div>
    </div>
  
    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak" (click)="show('linea', 'button-linea')">
      III. Línea / Operación Propuesta
    </h3>
  
    <div class="container-desplegables" id="linea">
      <div class="row p-5">
        <ng-container *ngIf="showReportes && objectKeys(lineaOperacionPropuesta).length > 0">
  
          <ng-template #lineaNoEditable>
  
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-sm table-hover table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" rowspan="2">Producto</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" colspan="4">Financiamiento Propuesto (M$)</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" rowspan="2">%</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" rowspan="2">Plazo (dias)</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" colspan="3" rowspan="1">Colocacion (M$)</th>
                      </tr>
                      <tr>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Linea Vigente</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Linea Propuesta</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Op. Puntual Vigente</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Op. Puntual Propuesta</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Vigente</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Propuesta</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Cancelada</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Facturas</th>
                        <td>{{lineaOperacionPropuesta.lineaVigente.facturas | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.lineaPropuesta.facturas | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualVigente.facturas | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualPropuesta.facturas | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.porcentaje.facturas | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.plazoDias.facturas | number: '1.0-0'}}</td>
                        <td>{{lineaOperacionPropuesta.vigente.facturas | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.propuesta.facturas | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.cancelada.facturas | number: '1.0-2'}}</td>
                      </tr>
                      <tr>
                        <th>Confirming</th>
                        <td>{{lineaOperacionPropuesta.lineaVigente.confirming | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.lineaPropuesta.confirming | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualVigente.confirming | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualPropuesta.confirming | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.porcentaje.confirming | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.plazoDias.confirming | number: '1.0-0'}}</td>
                        <td>{{lineaOperacionPropuesta.vigente.confirming | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.propuesta.confirming | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.cancelada.confirming | number: '1.0-2'}}</td>
                      </tr>
                      <tr>
                        <th>Contrato Serviu</th>
                        <td>{{lineaOperacionPropuesta.lineaVigente.contratoServiu | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.lineaPropuesta.contratoServiu | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualVigente.contratoServiu | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualPropuesta.contratoServiu | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.porcentaje.contratoServiu | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.plazoDias.contratoServiu | number: '1.0-0'}}</td>
                        <td>{{lineaOperacionPropuesta.vigente.contratoServiu | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.propuesta.contratoServiu | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.cancelada.contratoServiu | number: '1.0-2'}}</td>
                      </tr>
                      <tr>
                        <th>Credito Directo</th>
                        <td>{{lineaOperacionPropuesta.lineaVigente.creditoDirecto | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.lineaPropuesta.creditoDirecto | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualVigente.creditoDirecto | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualPropuesta.creditoDirecto | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.porcentaje.creditoDirecto | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.plazoDias.creditoDirecto | number: '1.0-0'}}</td>
                        <td>{{lineaOperacionPropuesta.vigente.creditoDirecto | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.propuesta.creditoDirecto | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.cancelada.creditoDirecto | number: '1.0-2'}}</td>
                      </tr>
                      <tr>
                        <th class="bg-primary">Total Propuesta</th>
                        <td>{{calcularTotalLineaVigente() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalLineaPropuesta() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalOpPuntualVigente() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalOpPuntualPropuesta() || 0 | number: '1.0-2'}}</td>
                        <th class="bg-primary" colspan="2">Total</th>
                        <td>{{calcularTotalVigente() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalPropuesta() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalCancelada() || 0 | number: '1.0-2'}}</td>
                      </tr>
                    </tbody>
                  </table>
              </div>
            </div>
          
            <div class="col-md-12 col-lg-8">
              <div class="row">
                  <div class="col-12 ml-4">
                      <div class="row">
                          <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                              <span class="span-linea">Vencimiento</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border">
                            <span>{{lineaOperacionPropuesta.vencimiento | date: 'dd-MM-yyyy'}}</span>
                          </div>
                      </div>
                  </div>
                  <div class="col-12 ml-4">
                    <div class="row d-flex flex-row-reverse">
                        <div class="col-sm-6 col-lg-3 border">
                          <span>{{calcularOpPuntualM() | number: '1.0-2'}}</span>
                        </div>
                        <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                            <span class="span-linea">Op. Puntual M$</span>
                        </div>
                      </div>
                  </div>
                  <div class="col-12 ml-4">
                      <div class="row">
                          <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                              <span class="span-linea">Total Linea M$</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border">
                            <span>{{calcularTotalLineaM() | number: '1.0-2'}}</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                              <span class="span-linea">Riesgo Total M$</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border">
                            <span>{{calcularRiesgoTotalM() | number: '1.0-2'}}</span>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
          
            <div class="col-12">
              <div class="row">
                  <div class="col-12 ml-4 mt-4 border pl-0">
                      <div class="col-sm-12 col-md-6 col-lg-3 border text-center bg-primary font-head-size">
                          <span class="span-linea">Presentacion Cliente</span>
                      </div>
                      <div class="col-12 pb-4">
  
                        <div class="mt-5" [innerHTML]="agregarSaltosDeLinea(lineaOperacionPropuesta.presentacionCliente)"></div>
    
                      </div>
                  </div>
              </div>
            </div>
            
          </ng-template>
          
          <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else lineaNoEditable">
            
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-sm table-hover table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" rowspan="2">Producto</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" colspan="4">Financiamiento Propuesto (M$)</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" rowspan="2">%</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" rowspan="2">Plazo (dias)</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" colspan="3" rowspan="1">Colocacion (M$)</th>
                      </tr>
                      <tr>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Linea Vigente</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Linea Propuesta</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Op. Puntual Vigente</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Op. Puntual Propuesta</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Vigente</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Propuesta</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Cancelada</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Facturas</th>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigentefacturas0' name='linea-lineaVigentefacturas0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestafacturas0' name='linea-lineaPropuestafacturas0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigentefacturas0' name='linea-opPuntualVigentefacturas0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestafacturas0' name='linea-opPuntualPropuestafacturas0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajefacturas0' name='linea-porcentajefacturas0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiasfacturas0' name='linea-plazoDiasfacturas0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigentefacturas0' name='linea-vigentefacturas0' [(ngModel)]="lineaOperacionPropuesta.vigente.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestafacturas0' name='linea-propuestafacturas0' [(ngModel)]="lineaOperacionPropuesta.propuesta.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladafacturas0' name='linea-canceladafacturas0' [(ngModel)]="lineaOperacionPropuesta.cancelada.facturas"></td>
                      </tr>
                      <tr>
                        <th>Confirming</th>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigenteconfirming0' name='linea-lineaVigenteconfirming0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestaconfirming0' name='linea-lineaPropuestaconfirming0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigenteconfirming0' name='linea-opPuntualVigenteconfirming0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestaconfirming0' name='linea-opPuntualPropuestaconfirming0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajeconfirming0' name='linea-porcentajeconfirming0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiasconfirming0' name='linea-plazoDiasconfirming0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigenteconfirming0' name='linea-vigenteconfirming0' [(ngModel)]="lineaOperacionPropuesta.vigente.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestaconfirming0' name='linea-propuestaconfirming0' [(ngModel)]="lineaOperacionPropuesta.propuesta.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladaconfirming0' name='linea-canceladaconfirming0' [(ngModel)]="lineaOperacionPropuesta.cancelada.confirming"></td>
                      </tr>
                      <tr>
                        <th>Contrato Serviu</th>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigentecontratoServiu0' name='linea-lineaVigentecontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestacontratoServiu0' name='linea-lineaPropuestacontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigentecontratoServiu0' name='linea-opPuntualVigentecontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestacontratoServiu0' name='linea-opPuntualPropuestacontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajecontratoServiu0' name='linea-porcentajecontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiascontratoServiu0' name='linea-plazoDiascontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigentecontratoServiu0' name='linea-vigentecontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.vigente.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestacontratoServiu0' name='linea-propuestacontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.propuesta.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladacontratoServiu0' name='linea-canceladacontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.cancelada.contratoServiu"></td>
                      </tr>
                      <tr>
                        <th>Credito Directo</th>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigentecreditoDirecto0' name='linea-lineaVigentecreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestacreditoDirecto0' name='linea-lineaPropuestacreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigentecreditoDirecto0' name='linea-opPuntualVigentecreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestacreditoDirecto0' name='linea-opPuntualPropuestacreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajecreditoDirecto0' name='linea-porcentajecreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiascreditoDirecto0' name='linea-plazoDiascreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigentecreditoDirecto0' name='linea-vigentecreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.vigente.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestacreditoDirecto0' name='linea-propuestacreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.propuesta.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladacreditoDirecto0' name='linea-canceladacreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.cancelada.creditoDirecto"></td>
                      </tr>
                      <tr>
                        <th class="bg-primary">Total Propuesta</th>
                        <td>{{calcularTotalLineaVigente() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalLineaPropuesta() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalOpPuntualVigente() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalOpPuntualPropuesta() || 0 | number: '1.0-2'}}</td>
                        <th class="bg-primary" colspan="2">Total</th>
                        <td>{{calcularTotalVigente() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalPropuesta() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalCancelada() || 0 | number: '1.0-2'}}</td>
                      </tr>
                    </tbody>
                  </table>
              </div>
            </div>
          
            <div class="col-md-12 col-lg-8">
              <div class="row">
                  <div class="col-12 ml-4">
                      <div class="row">
                          <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                              <span class="span-linea">Vencimiento</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border">
                            <input type="date" class="form-control form-control-sm p-0" id='linea-vencimiento0' name='linea-vencimiento0' [(ngModel)]="lineaOperacionPropuesta.vencimiento">
                          </div>
                      </div>
                  </div>
                  <div class="col-12 ml-4">
                    <div class="row d-flex flex-row-reverse">
                        <div class="col-sm-6 col-lg-3 border">
                          <span>{{calcularOpPuntualM() | number: '1.0-2'}}</span>
                        </div>
                        <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                            <span class="span-linea">Op. Puntual M$</span>
                        </div>
                      </div>
                  </div>
                  <div class="col-12 ml-4">
                      <div class="row">
                          <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                              <span class="span-linea">Total Linea M$</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border">
                            <span>{{calcularTotalLineaM() | number: '1.0-2'}}</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                              <span class="span-linea">Riesgo Total M$</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border">
                            <span>{{calcularRiesgoTotalM() | number: '1.0-2'}}</span>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
          
            <div class="col-12">
              <div class="row">
                  <div class="col-12 ml-4 mt-4 border pl-0">
                      <div class="col-sm-12 col-md-6 col-lg-3 border text-center bg-primary font-head-size">
                          <span class="span-linea">Presentacion Cliente</span>
                      </div>
                      <div class="col-12 pb-4">
                        <textarea class="form-control mt-4" id="linea-presentacionCliente0" name="linea-presentacionCliente0" [(ngModel)]="lineaOperacionPropuesta.presentacionCliente" maxlength="500"></textarea>
                      </div>
                  </div>
              </div>
            </div>
  
          </ng-container>
  
        </ng-container>
      </div>
    </div>

    <div class="col-12 my-2 not-print" *ngIf="showArchivos">
      <app-modulo-archivos [idTransaccion]="idTransaccion" [rut]="rut" [closed]="closed"></app-modulo-archivos>
    </div>
    
  </div>
  
  <ng-container *ngIf="plantillaNoEditable === false">
    <ng-template #botonFinalizar>
      <div class="not-print">
        <ng-container *ngIf="validaUsuarioAdmin()">
          <div class="col-12 d-flex justify-content-center pt-4">
            <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="volverEtapaPrevia()">
              Devolver Etapa Previa
            </button>
            <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="continuar()">
              Continuar
            </button>
          </div>
        </ng-container>
      </div>
    </ng-template>
    
    <ng-container *ngIf="stepper === 4 else botonFinalizar">
    
      <div class="col-12 d-flex justify-content-center pt-4">
        <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="guardarFicha()">
          Guardar
        </button>
        <button class="btn btn-sm btn-primary mx-2 rounded-header" [disabled]="objectKeys(fichaGuardada).length > 0 ? null : true" (click)="avanzarFlujo()">
          Continuar
        </button>
      </div>
    
    </ng-container>
  </ng-container>
  
  
  <app-modal-consolidado-workflow [rut]="rutSeleccionado" [idTransaccion]="idTransaccion" [dataServicios]="reportesSeleccionado" [informeComercial]="informeComercial" [default]="modalDefault" [plantilla]="plantillaAntecedentes" [descargar]="true"></app-modal-consolidado-workflow>
  