<div class="row p-4">
    <fieldset class="col-sm-12" style="margin-bottom: 20px;">
        <form [formGroup]="filtroForm">
            <div class="form-group col-sm-12">
                <div class="col-sm-12 col-lg-3 my-2">
                    <label class="col-sm-12 control-label" for="fechaInicio">Fecha desde</label>
                    <input type="date" class="form-control" id="fechaInicio" formControlName="fechaInicio" [min]="min" [max]="max">
                </div>
                <div class="col-sm-12 col-lg-3 my-2">
                    <label class="col-sm-12 control-label" for="fechaFin">Fecha hasta</label>
                    <input type="date" class="form-control" id="fechaFin" formControlName="fechaFin" [min]="min" [max]="max">
                </div>
                <!--Rut Deudor-->
                <div class="col-sm-12 col-lg-4 my-2">
                    <label class="col-sm-12 control-label" for="rutDeudor">
                        Deudor
                    </label>
                    <ng-container *ngIf="!showSpinnerRutDeudor">
                        <select id="rutDeudor" name="rutDeudor" class="form-control" formControlName="rutDeudor"
                            style="width: 100%;">
                            <option value="">Todos</option>
                            <option *ngFor="let element of listaRutDeudor" [ngValue]="element.rut">
                                {{ element.nombre }}
                            </option>
                        </select>
                    </ng-container>
                    <ng-container *ngIf="showSpinnerRutDeudor">
                        <div class="spinner-border text-primary mt-2 mx-auto no-print" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </ng-container>
                </div>
                <!--Rut Factoring-->
                <div class="col-sm-12 col-lg-4 my-2">
                    <label class="col-sm-12 control-label" for="rutCesionario">
                        Factoring
                    </label>
                    <ng-container *ngIf="!showSpinnerRutCesionario">
                        <select id="rutCesionario" name="rutCesionario" class="form-control" formControlName="rutCesionario"
                            style="width: 100%;">
                            <option value="">Todos</option>
                            <option *ngFor="let element of listaRutCesionario" [ngValue]="element.rut">
                                {{ element.nombre }}
                            </option>
                        </select>
                    </ng-container>
                    <ng-container *ngIf="showSpinnerRutCesionario">
                        <div class="spinner-border text-primary mt-2 mx-auto no-print" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </form>
    </fieldset>
</div>

<div class="block full break-box">
    <div class="block-title">
        <h2><strong>Tabla</strong> Cesión Facturas</h2>
    </div>

    <div class="table-responsive table-entidad">
        <div class="d-flex justify-content-between mb-2">
            <select class="form-control no-print pagination-custom" [(ngModel)]="currentItemCesion" (ngModelChange)="cambioPaginacion('CESION')">
                <option *ngFor="let element of paginationOptions" [ngValue]="element">
                    {{ element }}
                </option>
            </select>
        </div>
        
        
        <table id="example-datatable" class="table table-vcenter table-condensed table-bordered">
            <thead>
                <tr>
                    <th class="text-center bg-primary" style="color:#fff; font-size: 13px;">Rut Deudor</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Razón Social</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Folio</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Documento</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Tipo Documento</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Cesión</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Factoring</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Total</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Vencimiento</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Nota de Crédito</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="paginaActualCesion.length > 0 && paginaActualCesion[0]; else noDataTable">
                    <tr *ngFor="let report of paginaActualCesion | paginate:{id: 'pagination-cesion', itemsPerPage: currentItemCesion, currentPage: currentPageCesion, totalItems: cantidadRegistrosCesion}">
                        <ng-container *ngTemplateOutlet="cesionTemplate; context: { $implicit: {report: report} }"></ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div class="col-12 p-2 mb-3 no-print" *ngIf="paginaActualCesion.length > 0 && paginaActualCesion[0]">
        <div id="pagination">
            <div class="col-sm-12 col-md-7 float-right">
                <pagination-controls id="pagination-cesion" (pageChange)="getPage($event, 'CESION')"></pagination-controls>
            </div>
        </div>
    </div>
</div>

<!--Tabla Factorización por deudor-->
<div class="block full break-box">
    <div class="block-title">
        <h2><strong>Tabla</strong> Factorización por deudor</h2>
    </div>

    <div class="table-responsive table-entidad">
        <div class="d-flex justify-content-between mb-2">
            <select class="form-control no-print pagination-custom" [(ngModel)]="currentItemFactDeudor" (ngModelChange)="cambioPaginacion('FACTORIZACION-DEUDOR')">
                <option *ngFor="let element of paginationOptions" [ngValue]="element">
                    {{ element }}
                </option>
            </select>
        </div>
        
        
        <table id="example-datatable" class="table table-vcenter table-condensed table-bordered">
            <thead>
                <tr>
                    <th class="text-center bg-primary" style="color:#fff; font-size: 13px;">Rut Deudor</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Razón Social</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Facturas</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;"># Documentos</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="paginaActualFactDeudor.length > 0 && paginaActualFactDeudor[0]; else noDataTable">
                    <tr *ngFor="let report of paginaActualFactDeudor | paginate:{id: 'pagination-fact-deudor', itemsPerPage: currentItemFactDeudor, currentPage: currentPageFactDeudor, totalItems: cantidadRegistrosFactDeudor}">
                        <ng-container *ngTemplateOutlet="factorizacionTemplate; context: { $implicit: {report: report} }"></ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div class="col-12 p-2 mb-3 no-print" *ngIf="paginaActualFactDeudor.length > 0 && paginaActualFactDeudor[0]">
        <div id="pagination">
            <div class="col-sm-12 col-md-7 float-right">
                <pagination-controls id="pagination-fact-deudor" (pageChange)="getPage($event, 'FACTORIZACION-DEUDOR')"></pagination-controls>
            </div>
        </div>
    </div>
</div>

<!--Tabla Factorización por factoring-->
<div class="block full break-box">
    <div class="block-title">
        <h2><strong>Tabla</strong> Factorización por factoring</h2>
    </div>

    <div class="table-responsive table-entidad">
        <div class="d-flex justify-content-between mb-2">
            <select class="form-control no-print pagination-custom" [(ngModel)]="currentItemFactFactoring" (ngModelChange)="cambioPaginacion('FACTORIZACION-FACTORING')">
                <option *ngFor="let element of paginationOptions" [ngValue]="element">
                    {{ element }}
                </option>
            </select>
        </div>
        
        
        <table id="example-datatable" class="table table-vcenter table-condensed table-bordered">
            <thead>
                <tr>
                    <th class="text-center bg-primary" style="color:#fff; font-size: 13px;">Rut Factoring</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Razón Factoring</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Facturas</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;"># Documentos</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="paginaActualFactFactoring.length > 0 && paginaActualFactFactoring[0]; else noDataTable">
                    <tr *ngFor="let report of paginaActualFactFactoring | paginate:{id: 'pagination-fact-factoring', itemsPerPage: currentItemFactFactoring, currentPage: currentPageFactFactoring, totalItems: cantidadRegistrosFactFactoring}">
                        <ng-container *ngTemplateOutlet="factorizacionTemplate; context: { $implicit: {report: report} }"></ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div class="col-12 p-2 mb-3 no-print" *ngIf="paginaActualFactFactoring.length > 0 && paginaActualFactFactoring[0]">
        <div id="pagination">
            <div class="col-sm-12 col-md-7 float-right">
                <pagination-controls id="pagination-fact-factoring" (pageChange)="getPage($event, 'FACTORIZACION-FACTORING')"></pagination-controls>
            </div>
        </div>
    </div>
</div>

<hr class="my-5 no-print"/>

<div class="block full break-box">
    <div class="block-title">
        <h2><strong>Cuadro</strong> Resumen</h2>
    </div>

    <div class="table-responsive table-entidad">
        <div class="d-flex justify-content-between mb-2">
            <select class="form-control no-print pagination-custom" [(ngModel)]="currentItemFactoring" (ngModelChange)="cambioPaginacion('FACTORING')">
                <option *ngFor="let element of paginationOptions" [ngValue]="element">
                    {{ element }}
                </option>
            </select>
        </div>
        
        <table id="example-datatable" class="table table-vcenter table-condensed table-bordered table-hover">
            <thead>
                <tr>
                  <th scope="col" colspan="5" class="text-center bg-primary" style="color:#fff; font-size: 13px;">Factoring con que opera</th>
                </tr>
                <tr>
                  <th scope="col" class="text-center bg-primary" style="color:#fff; font-size: 13px;" rowspan="2">Rut</th>
                  <th scope="col" class="text-center bg-primary" style="color:#fff; font-size: 13px;" rowspan="2">Nombre</th>
                  <th scope="col" class="text-center bg-primary" style="color:#fff; font-size: 13px;" rowspan="1" colspan="3">Monto Cesiones en M$</th>
                </tr>
                <tr>
                  <th scope="col" class="text-center bg-primary" style="color:#fff; font-size: 13px;" rowspan="1">3 Meses</th>
                  <th scope="col" class="text-center bg-primary" style="color:#fff; font-size: 13px;" rowspan="1">6 Meses</th>
                  <th scope="col" class="text-center bg-primary" style="color:#fff; font-size: 13px;" rowspan="1">12 Meses</th>
                </tr>
              </thead>
            <tbody>
                <ng-container *ngIf="paginaActualFactoring.length > 0 && paginaActualFactoring[0]; else noDataTable">
                    <tr *ngFor="let report of paginaActualFactoring | paginate:{id: 'pagination-factoring',itemsPerPage: currentItemFactoring, currentPage: currentPageFactoring, totalItems: cantidadRegistrosFactoring}">
                        <td class="cursor-pointer" (click)="seeDetails(report?.rut)">{{report?.rut ? (report?.rut | rutFormat) : ''}}</td>
                        <td class="cursor-pointer" (click)="seeDetails(report?.rut)">{{report?.nombre || ''}}</td>
                        <td class="cursor-pointer" (click)="seeDetails(report?.rut)">{{report?.meses?.['3'] || report?.meses?.['3'] == 0 ? (report?.meses?.['3'] | number:'1.0-0') : ''}}</td>
                        <td class="cursor-pointer" (click)="seeDetails(report?.rut)">{{report?.meses?.['6'] || report?.meses?.['6'] == 0 ? (report?.meses?.['6'] | number:'1.0-0') : ''}}</td>
                        <td class="cursor-pointer" (click)="seeDetails(report?.rut)">{{report?.meses?.['12'] || report?.meses?.['12'] == 0 ? (report?.meses?.['12'] | number:'1.0-0') : ''}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div class="col-12 p-2 mb-3 no-print" *ngIf="paginaActualFactoring.length > 0 && paginaActualFactoring[0]">
        <div id="pagination">
            <div class="col-sm-12 col-md-7 float-right">
                <pagination-controls id="pagination-factoring" (pageChange)="getPage($event, 'FACTORING')"></pagination-controls>
            </div>
        </div>
    </div>
</div>

<div class="block full break-box" *ngIf="rutDetalle">
    <div class="block-title">
        <h2><strong>Tabla</strong> Cesión Facturas para {{rutDetalle | rutFormat}}</h2>
    </div>

    <div class="table-responsive table-entidad">
        <div class="d-flex justify-content-between mb-2">
            <select class="form-control no-print pagination-custom" [(ngModel)]="currentItemCesionDetalle" (ngModelChange)="cambioPaginacion('CESION-DETALLE')">
                <option *ngFor="let element of paginationOptions" [ngValue]="element">
                    {{ element }}
                </option>
            </select>
        </div>
        
        
        <table id="example-datatable" class="table table-vcenter table-condensed table-bordered">
            <thead>
                <tr>
                    <th class="text-center bg-primary" style="color:#fff; font-size: 13px;">Rut Deudor</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Razón Social</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Folio</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Documento</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Tipo Documento</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Cesión</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Factoring</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Total</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Vencimiento</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Nota de Crédito</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="paginaActualCesionDetalle.length > 0 && paginaActualCesionDetalle[0]; else noDataTable">
                    <tr *ngFor="let report of paginaActualCesionDetalle | paginate:{id: 'pagination-cesion-detalle', itemsPerPage: currentItemCesionDetalle, currentPage: currentPageCesionDetalle, totalItems: cantidadRegistrosCesionDetalle}">
                        <ng-container *ngTemplateOutlet="cesionTemplate; context: { $implicit: {report: report} }"></ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div class="col-12 p-2 mb-3 no-print" *ngIf="paginaActualCesionDetalle.length > 0 && paginaActualCesionDetalle[0]">
        <div id="pagination">
            <div class="col-sm-12 col-md-7 float-right">
                <pagination-controls id="pagination-cesion-detalle" (pageChange)="getPage($event, 'CESION-DETALLE')"></pagination-controls>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="errores.length > 0">
    <div class="row no-print">
        <div class="col-md-12">
            <div class="block full">
                <div class="block-title">
                    <h2>Detalle Errores</h2>
                </div>
                <div class="size-error">
                    <div class="col-12">
                        <ul class="list-group list-group-light">
                            <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center my-2 list-group-error" *ngFor="let item of errores">
                              <div>
                                <div class="text-muted">{{ item.msg || '' }}</div>
                                <div class="text-muted"><b>Mes:</b> {{ item.mes }} <b>Periodo:</b> {{item.periodo}}</div>
                              </div>
                              <span class="badge rounded-pill badge-danger">Error</span>
                            </li>
                          </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #noDataTable>
    <tr>
        <td colspan="100%">
            Sin información
        </td>
    </tr>
</ng-template>

<ng-template #cesionTemplate let-elemento>

    <td>{{elemento?.report?.DEUDOR ? (elemento?.report?.DEUDOR | rutFormat) : ''}}</td>
    <td>{{elemento?.report?.nombreDeudor || ''}}</td>
    <td>{{elemento?.report?.FOLIO_DOC || ''}}</td>
    <td>{{elemento?.report?.FCH_EMIS_DTE ? (elemento?.report?.FCH_EMIS_DTE | date: 'dd-MM-yyyy') : ''}}</td>
    <td>{{elemento?.report?.NOMBRE_DOC || ''}}</td>
    <td>{{elemento?.report?.FCH_CESION ? (elemento?.report?.FCH_CESION | date: 'dd-MM-yyyy') : ''}}</td>
    <td>{{elemento?.report?.RZ_CESIONARIO || ''}}</td>
    <td>{{elemento?.report?.MNT_TOTAL || elemento?.report?.MNT_TOTAL == 0 ? ('$' + (elemento?.report?.MNT_TOTAL | number:'1.0-0')) : ''}}</td>
    <td>{{elemento?.report?.FCH_VENCIMIENTO ? (elemento?.report?.FCH_VENCIMIENTO | date: 'dd-MM-yyyy') : ''}}</td>
    <td>{{elemento?.report?.tieneNotaCredito == true ? 'Si' : 'No'}}</td>
    
</ng-template>

<ng-template #factorizacionTemplate let-elemento>

    <td>{{elemento?.report?.rut ? (elemento?.report?.rut | rutFormat) : ''}}</td>
    <td>{{elemento?.report?.nombre || ''}}</td>
    <td>{{elemento?.report?.montoTotal || elemento?.report?.montoTotal == 0 ? (elemento?.report?.montoTotal | number:'1.0-0') : ''}}</td>
    <td>{{elemento?.report?.cantDocumentos || elemento?.report?.cantDocumentos == 0 ? (elemento?.report?.cantDocumentos | number:'1.0-0') : ''}}</td>
    
</ng-template>