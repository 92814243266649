import { Component, OnInit, SecurityContext } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { EmpresaEnUnDiaService } from 'src/app/shared/services/empresa-en-un-dia.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { rutValidate } from 'rut-helpers';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { RUT } from 'src/app/shared/utils/rut';
import { DatosBasicosSolicitud } from 'src/app/interface/IEmpresaEnUnDiaSearchReportResponse';
import { IEmpresaEnUnDiaCreateReportResponse } from 'src/app/interface/IEmpresaEnUnDiaCreateReportResponse';
import { PdfGeneratorService } from 'src/app/shared/services/pdf-generator.service';
import { environment as env } from 'src/environments/environment';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ResultSearchModel } from 'src/app/models/reportes/reportes.model';
import { StatusTX } from 'src/app/enum/EStatusTX';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';

interface EmpresaEnUnDiaReportFormValues {
  rut: string;
  informeComercial: boolean;
}

@Component({
  selector: 'app-empresa-en-un-dia-buscar',
  templateUrl: './empresa-en-un-dia-buscar.component.html',
  styleUrls: ['./empresa-en-un-dia-buscar.component.scss']
})
export class EmpresaEnUnDiaBuscarComponent implements OnInit {
  public activateRutHelper = false;
  private access: string[] = [ESystemAccess.REPORTE_EMPRESAENUNDIA_BUSCAR, ESystemProfileList.WEB];
  public hasUserAccess = false;

  public empresaEnUnDiaReportForm: FormGroup;
  public showReport = false;
  public searchReportDataSource: DatosBasicosSolicitud[] = [];
  public listaReportesFiltrado: any[] = [];

  public empresaEnUnDiaReportDataSource: IEmpresaEnUnDiaCreateReportResponse | null = null;

  public hasCertificadoVigencia = false;
  public hasCertificadoEstatutos = false;
  public hasCertificadoAnotaciones = false;

  public showCertificadoVigencia = false;
  public showCertificadoEstatutos = false;
  public showCertificadoAnotaciones = true;

  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public resultSearch: ResultSearchModel = new ResultSearchModel();
  public statusTX = StatusTX;

  public inputText = '';
  public order = '';
  public campoOrder = '';
  public mapeoEstados: any = {
    "IN-PROGRESS": "En Progreso",
    "COMPLETED": "Completado",
    "ERROR": "Error"
  }

  public idTransaccionEntrada: string = '';

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private _empresaEnUnDiaService: EmpresaEnUnDiaService,
    private _generatePDFService: PdfGeneratorService,
    private _sessionService: SessionService,
    private formBuilder: UntypedFormBuilder,
    private utilsService: UtilsService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private sanitizer: DomSanitizer
  ) {
    this.empresaEnUnDiaReportForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
      informeComercial: [false, Validators.requiredTrue]
    }) as FormGroupTyped<EmpresaEnUnDiaReportFormValues>;

    this.cambiosEnUrl();
    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
  }

  cambiosEnUrl(): void {
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])){
      this.route.queryParamMap.subscribe((val: any) => {
        const rut = val?.params?.['rut'];
        const idTx = val?.params?.['idTransaccion'];
        this.datosPorUrl(rut, idTx)
      });
    }
  }

  datosPorUrl(rut: string | null, idTransaccion: string | null): void {
    const rutSeguro = this.sanitizer.sanitize(SecurityContext.HTML, rut);
    const idTransaccionSeguro = this.sanitizer.sanitize(SecurityContext.HTML, idTransaccion);

    if(!rutSeguro){
      return
    }

    if(!rutValidate(rutSeguro)){
      this.alertService.error('Rut detectado inválido.');
      return
    }

    //this.rut = rutSeguro;
    if(idTransaccionSeguro){
      this.idTransaccionEntrada = idTransaccionSeguro;
    }
    this.consultaBuscarReportes(rutSeguro);
  }

  onChanges(): void {
    this.empresaEnUnDiaReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  filtrarObjeto(): void {
    this.currentPage = 0;
    this.listaReportesFiltrado = this.inputText != '' ? this.searchReportDataSource.filter((item: any) => 
    item?.IDTransaccion?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.EstadoTransaccionMapeada?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.FechaFormatted?.toUpperCase().includes(this.inputText.toUpperCase())) : this.searchReportDataSource;
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      if(campo === 'FechaReporte'){
        this.listaReportesFiltrado.sort((a, b) => {
          const fechaA: any = new Date(a.FechaReporte);
          const fechaB: any = new Date(b.FechaReporte);
          return (this.utilsService.dateIsValid(fechaA) ? fechaA : new Date("1000")) - (this.utilsService.dateIsValid(fechaB) ? fechaB : new Date("1000"));
        });
      } else {
        this.listaReportesFiltrado.sort((a, b) => {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return textA.localeCompare(textB);
        });
      }
    } else {
      if(campo === 'FechaReporte'){
        this.ordenarDescFecha();
      } else {
        this.listaReportesFiltrado.sort((a, b) => {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return (-1 * textA.localeCompare(textB));
        });
      }
    }
  }

  ordenarDescFecha(): void {
    this.listaReportesFiltrado.sort((a, b) => {
      const fechaA: any = new Date(a.FechaReporte);
      const fechaB: any = new Date(b.FechaReporte); 
      return (this.utilsService.dateIsValid(fechaB) ? fechaB : new Date("1000")) - (this.utilsService.dateIsValid(fechaA) ? fechaA : new Date("1000"));
    }); 
  }
  
  actualizarTabla(): void {
    if(this.resultSearch.rut){
      this.consultaBuscarReportes(this.resultSearch.rut);
    }
  }

  consultaBuscarReportes(rut: string): void {
    this.spinner.show();
    this._empresaEnUnDiaService.searchReportsList(RUT.applyBackendFormat(rut), 'CERTIFICADOS')
    .subscribe(
      (data) => {
        this.searchReportDataSource = [];
        if(data && data.length > 0) {
          for(const e of data){
            if(e?.['EmpresaEnUnDiaReporte']?.['DatosBasicosSolicitud'] && Object.keys(e?.['EmpresaEnUnDiaReporte']?.['DatosBasicosSolicitud']).length > 0){
              const item = e['EmpresaEnUnDiaReporte']['DatosBasicosSolicitud'];
              const newItem = {
                ...item,
                FechaFormatted: this.utilsService.formatDateHMS(item?.['FechaReporte']),
                EstadoTransaccionMapeada: item?.['EstadoTransaccion'] ? this.mapeoEstados[item?.['EstadoTransaccion']] : item?.['EstadoTransaccion'],
              }
              this.searchReportDataSource.push(newItem);
            }
          }
        }
        
        if(this.searchReportDataSource.length === 0) {
          this.alertService.warn("Rut sin Información");
          this.spinner.hide();
        } else {

          this.filtrarObjeto();
          this.ordenarDescFecha();

          let razon = '';

          for (const objKey of Object.keys(data[0])) {
            const valor = this.utilsService.getNested(data[0], objKey, 'NombreORazonSocial');
            razon = valor ? valor : razon;
          }

          this.resultSearch = new ResultSearchModel(razon, rut);
          
          if(this.idTransaccionEntrada) {
            this.validaTransaccionEntrada();
          } else {
            this.spinner.hide();
          }
        }
        this.clearForm();
      },
      ({error}) => {
        this.alertService.error(error.message || 'Ocurrio un error al obtener el listado de reportes');
        this.spinner.hide();
      }
    )
  }

  validaTransaccionEntrada(): void {
    this.spinner.show();
    const itemReporte = this.searchReportDataSource.find(e => e.IDTransaccion === this.idTransaccionEntrada);
    if(itemReporte){
      this.displayReport(this.idTransaccionEntrada);
    } else {
      this.alertService.error('No se ha encontrado la transacción indicada en la lista de reportes.');
      this.spinner.hide()
    }

  }

  public searchReport(): void {
    const { rut } = this.empresaEnUnDiaReportForm.value;

    if (!rutValidate(rut)) {
      return;
    }
    this.consultaBuscarReportes(rut);
    
  }

  public displayReport(idTransaccion: string): void {
    this.spinner.show();
    this._empresaEnUnDiaService.getReport(idTransaccion)
    .subscribe(
      (data) => {
        this.empresaEnUnDiaReportDataSource = data;
        this._validateIfContainsCertificados();
        this.spinner.hide();
      },
      ({error}) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    )
  }

  public showAnotacionesReport(): void {
    this.showCertificadoAnotaciones = true;
    this.showCertificadoVigencia = false;
    this.showCertificadoEstatutos = false;
  }

  public showVigenciaReport(): void {
    this.showCertificadoAnotaciones = false;
    this.showCertificadoVigencia = true;
    this.showCertificadoEstatutos = false;
  }

  public showEstatutosReport(): void {
    this.showCertificadoAnotaciones = false;
    this.showCertificadoVigencia = false;
    this.showCertificadoEstatutos = true;
  }

  public async downloadReportSelected(): Promise<void> {
    const transactionId = this.empresaEnUnDiaReportDataSource!.EmpresaEnUnDiaReporte.DatosBasicosSolicitud.IDTransaccion;

    let tipoReporte = '';
    if (this.showCertificadoAnotaciones) {
      tipoReporte = 'empresaEnUnDia-anotaciones';
    } else if (this.showCertificadoEstatutos) {
      tipoReporte = 'empresaEnUnDia-estatutos';
    } else if (this.showCertificadoVigencia) {
      tipoReporte = 'empresaEnUnDia-vigencia';
    }

    const contentPDF = await this._generatePDFService.downloadReportSelected(transactionId, tipoReporte);
    if (!contentPDF) {
      this.alertService.warn("No se pudo descargar el archivo PDF");
      return;
    }

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', contentPDF.URLReporte);
    link.setAttribute('download', `file.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  private _validateIfContainsCertificados() {
    if (this.empresaEnUnDiaReportDataSource && this.empresaEnUnDiaReportDataSource?.EmpresaEnUnDiaReporte?.Certificados && this.empresaEnUnDiaReportDataSource?.EmpresaEnUnDiaReporte?.Certificados.length > 0) {
      for (const certificado of this.empresaEnUnDiaReportDataSource.EmpresaEnUnDiaReporte.Certificados) {
        // TODO: I need to add an enum
        if ("CertificadoDeVigencia" in certificado) {
          this.hasCertificadoVigencia = true;
        }
        if ("CertificadoDeAnotaciones" in certificado) {
          this.hasCertificadoAnotaciones = true;
        }
        if ("CertificadoDeEstatutos" in certificado) {
          this.hasCertificadoEstatutos = true;
        }
      }
    }
  }

  public backToSearchForm(): void {
    this.searchReportDataSource = [];
    this.idTransaccionEntrada = '';
  }

  public closeReport(): void {
    this.empresaEnUnDiaReportDataSource = null;
    this.hasCertificadoVigencia = false;
    this.hasCertificadoAnotaciones = false;
    this.hasCertificadoEstatutos = false;
    this.idTransaccionEntrada = '';
  }

  public clearForm(): void {
    this.empresaEnUnDiaReportForm.reset();
    this.activateRutHelper = false;
  }

}
