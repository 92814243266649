import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-detalle-reporte-modal',
  templateUrl: './detalle-reporte-modal.component.html',
  styleUrls: ['./detalle-reporte-modal.component.scss']
})
export class DetalleReporteModalComponent {
  @Input() rut = '';
  @Input() reporte: any;
  @Input() reportType = '';

  constructor(
    public dialogRef: MatDialogRef<DetalleReporteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.rut = data.rut;
    this.reportType = data.reportType
    console.log(data.reporte)
    this.reporte = this.mapReporte(data.reporte);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  mapReporte(reporteInput: any) {
    if(this.reportType == 'tesoreria') {
      return this.mapTesoreria(reporteInput)
    } else if (this.reportType == 'lcv') {
      return reporteInput
    }
  }

  // Mapea el reporte de flujo de credito para el formato que debe recibir tesoreria-view
  mapTesoreria(reporteFlujo: any): any {
    const reporteView = {
      TesoreriaReporte: {
        Reporte: reporteFlujo.reporte.reporteTesoreria
      }
    }
    return reporteView
  }

  close() {
    this.dialogRef.close();
  }

  descargarPDF(): void {
    const element = document.getElementById('printButtonModal');
    // if(this.accordion?.groups && this.accordion?.groups.length > 0){
    //   for (const group of this.accordion.groups){
    //     group.isOpen = true;
    //   }
    // }
    setTimeout(() => {
      if(element){
        element.click();
      } else {
        console.error('Elemento no encontrado');
      }
    }, 500);
  }
}
