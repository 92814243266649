import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
//import { rutValidate, RutValidator } from 'ng9-rut';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { PdfGeneratorService } from 'src/app/shared/services/pdf-generator.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { RUT } from 'src/app/shared/utils/rut';
import { IDeudaSistemaFinancieroReporteModel, SbifLeasingService } from 'src/app/shared/services/sbif-leasing.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

interface sbifLeasingReportFormValues {
  rut: string;
}

@Component({
  selector: 'app-deuda-sbif-leasing-buscar',
  templateUrl: './deuda-sbif-leasing-buscar.component.html',
  styleUrls: ['./deuda-sbif-leasing-buscar.component.scss']
})
export class DeudaSbifLeasingBuscarComponent implements OnInit {
  public activateRutHelper = false;
  private access: string[] = [ESystemAccess.REPORTE_DEUDASBIF_LEASING_BUSCAR, ESystemProfileList.WEB];
  public hasUserAccess = false;
  public rut: string = '';
  public today: string = '';
  public sbifLeasingReportForm: FormGroup;
  public sbifLeasingDataSource: any | null = null;
  public showReport = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private sbifLeasingService: SbifLeasingService,
    private _sessionService: SessionService,
    private _generatePDFService: PdfGeneratorService,
    //private rutValidator: RutValidator,
    private formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {
    this.sbifLeasingReportForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]]
    }) as FormGroupTyped<sbifLeasingReportFormValues>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
    this.setToday();
  }

  onChanges(): void {
    this.sbifLeasingReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  public searchReport(): void {
    const { rut } = this.sbifLeasingReportForm.value;
    this.rut = rut;
    
    if (!rutValidate(rut)) {
      return;
    }

    this.spinner.show();
    this.sbifLeasingService.searchReport(RUT.applyBackendFormat(rut))
      .subscribe(
        (data) => {
          this.spinner.hide();
          Object.keys(data).length > 0? (
          this.sbifLeasingDataSource = data,
          this.clearForm()
          ) : 
          this.alertService.warn("Rut sin Información");
        },
        ( error) => {
          this.alertService.error(error.error.message);
          this.spinner.hide();
        }
      )
  }

  public closeReport(): void {
    this.sbifLeasingDataSource = null;
    this.showReport = true;
  }

  public clearForm(): void {
    this.sbifLeasingReportForm.reset();
    this.activateRutHelper = false;
  }

  setToday() {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
