<ng-template #cargaCte>
    <div class="card mt-3">
        <h5 class="card-header list-group-item cuadro-color">
            <div class="d-flex">
                <div class="flex-fill">
                    <p class="pt-3"> Datos Internos (
                        <ng-template #isText>
                            {{fecha.dato}}
                        </ng-template>

                        <ng-container *ngIf="fecha.tipo === 'fecha'; else isText">
                            al {{fecha.dato | date: 'dd-MM-YYYY'}}
                        </ng-container>)
                    </p>
                </div>
            </div>
        </h5>
        <div class="card-body">
            <form [formGroup]="formulario">
                <div class="form-group" *ngIf="!fileName; else fileUpload1">

                    <label class="uploadbutton">
                        <input class="input-file" formControlName="file" type="file" name="file" accept=".csv"
                            (change)="handleUpload($event)" />
                        <div class="button btn">Nueva Carga</div>
                        <div class='input-file-text' [ngClass]="{'text-success': formulario.controls.file.value}">
                            {{formulario.controls.file.value || 'Subir Archivo'}}
                        </div>
                    </label>
                    <div (click)="submit()" class="upload-file btn bg-warning" *ngIf="formulario.controls.file.value">
                        <span class="material-icons">upload</span>
                    </div>
                </div>
                <ng-template class="file-upload" #fileUpload1>
                    Su archivo fue subido con exito
                </ng-template>
            </form>
            <div class="justify-content-center row">
                <div class="col-sm-6 mx-auto mt-5" *ngIf="fecha.tipo !== 'text'">
                    <fieldset>
                        <legend> Observaciones</legend>
                        <ng-template #sinAdvertencias>
                            Sin observaciones
                        </ng-template>

                        <div class="pb-5">
                            <ng-container *ngIf="advertencias && advertencias.length > 0; else sinAdvertencias">
                                Se {{advertencias.length > 1? 'encontraron': 'encontro'}}
                                <strong> {{advertencias.length}} </strong> {{advertencias.length > 1? 'problemas':
                                'problema'}}
                                en el archivo
                            </ng-container>
                        </div>

                        <div class="form-group form-actions container__actions">
                            <button type="submit" class="btn btn-sm btn-primary"
                                (click)="changeStep()">Continuar</button>
                            <button type="button" class="btn btn-sm btn-warning" (click)="showErrors()" 
                            *ngIf="advertencias && advertencias.length > 0">Ver
                                Errores</button>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="step; else cargaCte">
    <div class="card mt-3">
        <h5 class="card-header list-group-item cuadro-color">
            <div class="d-flex">
                <div class="flex-fill">
                    <p class="pt-3"> Informe Carpeta Tributaria (
                        <ng-template #isText>
                            {{fecha.dato}}
                        </ng-template>

                        <ng-container *ngIf="fecha.tipo === 'fecha'; else isText">
                            al {{fecha.dato | date: 'dd-MM-YYYY'}}
                        </ng-container>)
                    </p>
                </div>
                <div class="flex-fill">
                    <button class="btn btn-primary float-right" (click)="showContent()">{{seeContent? 'Ocultar':
                        'Ver'}}</button>
                </div>
            </div>
        </h5>
        <div class="card-body">
            <ng-container *ngIf="!seeContent; else contentCTE">
                <form [formGroup]="formulario">
                    <div class="form-group" *ngIf="!fileName; else fileUpload1">
                        <label class="uploadbutton">
                            <input class="input-file" formControlName="file" type="file" name="file" accept=".pdf"
                                (change)="handleUpload($event)" />
                            <div class="button btn">Cargar Nueva CTE</div>
                            <div class='input-file-text' [ngClass]="{'text-success': formulario.controls.file.value}">
                                {{formulario.controls.file.value || 'Subir Archivo'}}
                            </div>
                        </label>
                        <div (click)="submit()" class="upload-file btn bg-warning"
                            *ngIf="formulario.controls.file.value">
                            <span class="material-icons">upload</span>
                        </div>
                    </div>
                </form>

                <ng-template class="file-upload" #fileUpload1>
                    Su archivo fue subido con exito
                </ng-template>
            </ng-container>

            <ng-template #contentCTE>
                <app-informe-cte [informeTributario]="informeTributario"></app-informe-cte>
            </ng-template>

            <div class="row">
                <div class="col-sm-6 mx-auto mt-5">
                    <div class="form-group form-actions container__actions">
                        <ng-container *ngIf="fecha.tipo !== 'text'">
                            <button type="button" class="btn btn-sm btn-primary"
                                (click)="changeStep()">Continuar</button>
                        </ng-container>
                        <button type="button" class="btn btn-sm btn-warning" (click)="changeStep()">Omitir paso</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>