import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { CredencialesService } from 'src/app/shared/services/credenciales.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { environment as env } from 'src/environments/environment';
import { AlertService } from '../_alert';
import { AccessDialogComponent } from 'src/app/components/access-dialog/access-dialog.component';
//import { SimpleModalService } from 'ngx-simple-modal';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-carga-masiva-cred',
  templateUrl: './carga-masiva-cred.component.html',
  styleUrls: ['./carga-masiva-cred.component.scss']
})
export class CargaMasivaCredComponent implements OnInit {
  private access: string[] = [ESystemAccess.CLIENTES_CREDS_ADMIN, ESystemProfileList.WEB];
  public hasUserAccess = false;

  public formularioArchivo!: UntypedFormGroup;
  public fileName: boolean = false;
  private tempFile!: any;

  public seeTable: boolean = true; 
  public seeUpload: boolean = false; 

  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public inputText: string = '';
  public order = '';
  public campoOrder = '';
  public tablaFiltrado: any[] = [];
  public tabla: any[] = [];

  constructor(
    private _sessionService: SessionService,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private alertsService: AlertService,
    private credencialesService: CredencialesService,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
      
      this.obtenerLista();
    }
  }

  obtenerLista(): void {
    this.spinner.show();
    this.credencialesService.listarCargasMasivas().subscribe(
      (data: any) => {
        this.tabla = data || [];
        this.mapeoEstados();
        this.mapeoFechas();
        this.filtrarObjeto();
        this.order = 'asc';
        this.campoOrder = 'FechaReporteFormatted';
        this.cambioDeCampo('FechaReporteFormatted');
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertsService.error(error.message || 'Ha ocurrido un error al obtener el listado.');
        this.spinner.hide();
    });
  }

  mapeoFechas(): void {
    this.tabla.forEach(element => {
      if(element?.FechaReporte){
        const dateString = element.FechaReporte;
        const year = dateString.substr(0,4);
        const month = dateString.substr(5,2);
        const day = dateString.substr(8,2);
        const hour = dateString.substr(11,2);
        const minute = dateString.substr(14,2);

        element.FechaReporteFormatted = ["" + day, "" + month, "" + year].join('-') + [" " + hour, "" + minute].join(':');
      }
    });
  }

  mapeoEstados(): void {
    this.tabla.forEach(element => {
      if(element.EstadoTransaccion === 'ERROR'){
        if(element.SubEstadoTransaccion === 'ERROR'){
          element.mensaje = 'Error'
        }
        if(element.SubEstadoTransaccion === 'VALIDATIONS'){
          element.mensaje = 'Errores de validaciones'
        }
      }
      if(element.EstadoTransaccion === 'COMPLETED' && element.SubEstadoTransaccion === 'ERROR'){
        element.mensaje = 'Terminado con errores'
      }
      if(element.EstadoTransaccion === 'COMPLETED' && element.SubEstadoTransaccion === 'COMPLETED'){
        element.mensaje = 'Completado'
      }
      if(element.EstadoTransaccion === 'PROCESSING'){
        element.mensaje = 'Procesando'
      }
    });
  }


  filtrarObjeto(){
    this.currentPage = 0;
    this.tablaFiltrado = this.inputText != '' ? this.tabla.filter((item: any) => 
    item?.IDTransaccion?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.mensaje?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.FechaReporteFormatted?.toUpperCase().includes(this.inputText.toUpperCase())) : this.tabla;
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  // Ordena las columnas en caso de null o undefined se asigna -1 para dejarlos primeros
  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      this.tablaFiltrado.sort((a, b) => {
        if(campo === 'FechaReporteFormatted'){
          let newDateA: any = null;
          let newDateB: any = null;

          if(a?.FechaReporteFormatted){
            const dateFullA = a?.FechaReporteFormatted?.split(" ");
            const dateA = dateFullA?.[0]?.split("-");
            const hoursA = dateFullA?.[1]?.split(":");
            newDateA = new Date(dateA[2], dateA[1]-1, dateA[0], hoursA[0], hoursA[1]);
          } else {
            newDateA = new Date("1000");
          }

          if(b?.FechaReporteFormatted){
            const dateFullB = b?.FechaReporteFormatted?.split(" ");
            const dateB = dateFullB?.[0]?.split("-");
            const hoursB = dateFullB?.[1]?.split(":");
            newDateB = new Date(dateB[2], dateB[1]-1, dateB[0], hoursB[0], hoursB[1]);
          } else {
            newDateB = new Date("1000");
          }

          return newDateA - newDateB;
          
        } else {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return textA.localeCompare(textB);
        }
      });
    } else {
      this.tablaFiltrado.sort((a, b) => {
        if(campo === 'FechaReporteFormatted'){

          let newDateA: any = null;
          let newDateB: any = null;

          if(a?.FechaReporteFormatted){
            const dateFullA = a?.FechaReporteFormatted?.split(" ");
            const dateA = dateFullA?.[0]?.split("-");
            const hoursA = dateFullA?.[1]?.split(":");
            newDateA = new Date(dateA[2], dateA[1]-1, dateA[0], hoursA[0], hoursA[1]);
          } else {
            newDateA = new Date("1000");
          }

          if(b?.FechaReporteFormatted){
            const dateFullB = b?.FechaReporteFormatted?.split(" ");
            const dateB = dateFullB?.[0]?.split("-");
            const hoursB = dateFullB?.[1]?.split(":");
            newDateB = new Date(dateB[2], dateB[1]-1, dateB[0], hoursB[0], hoursB[1]);
          } else {
            newDateB = new Date("1000");
          }

          return newDateB - newDateA;
          
        } else {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return (-1 * textA.localeCompare(textB));
        }
      });
    }
  }

  descargarArchivo(idTransaccion: string, base64String: string): void {
    const button: any = document.getElementById(idTransaccion);
    if(button){
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      button.href = url;
      button.download = idTransaccion + '.csv';
      button.click();
      URL.revokeObjectURL(url);
    }
  }

  // Consulta para descargar archivo
  obtenerArchivo(elemento: any): void {
    const idTransaccion = elemento?.IDTransaccion;
    if(idTransaccion) {
      this.spinner.show();
      this.credencialesService.obtenerArchivoCargaMasiva(idTransaccion).subscribe(
        (data: any) => {
          if(data?.ArchivoResultado){
            this.descargarArchivo(idTransaccion, data?.ArchivoResultado);
          } else {
            this.alertsService.error('Ha ocurrido un error intentar rescatar el archivo.');
          }
          this.spinner.hide();
        },
        ({ error }) => {
          this.alertsService.error(error.message || 'Ha ocurrido un error intentar descargar el archivo.');
          this.spinner.hide();
      });

    }
  }

  verCargar(): void {
    this.seeTable = false;
    this.seeUpload = true;
    this.initFormArchivo()
  }

  volverTabla(): void {
    this.seeTable = true;
    this.seeUpload = false;
    this.formularioArchivo.reset();
    this.obtenerLista();
  }

  initFormArchivo(): void {
    this.formularioArchivo = this.formBuilder.group({
      file: [null]
    })
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    if(file.size <= 1000000){
      // falta validacion tipo de dato
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.tempFile = reader?.result?.toString().split(',')[1];
      };
    } else {
      this.alertsService.error('El tamaño maximo del archivo debe ser de 1mb');
      this.tempFile = {};
    }
  }

  submitArchivo() {
    this.spinner.show();
    if(typeof(this.tempFile) === 'string' && this.tempFile?.length > 0){
      this.spinner.show();
      this.credencialesService.iniciarCargaMasivaCreds(this.tempFile).subscribe(
        (data: any) => {
          this.alertsService.success(
            data?.IDTransaccion ? `Se ha iniciado correctamente la carga del archivo. ID Transacción: ${data?.IDTransaccion}` :
            'Se ha iniciado correctamente la carga del archivo.'
            );
          this.spinner.hide();
          this.volverTabla()
        },
        ({ error }) => {
          this.alertsService.error(
            error.message || (
              error?.IDTransaccion ? `Ha ocurrido un error intentar iniciar la carga el archivo. ID Transacción: ${error?.IDTransaccion}` :
              'Ha ocurrido un error intentar iniciar la carga el archivo.'
              )
          );
          this.spinner.hide();
      });
    } else {
      this.alertsService.error('Solicitud de Crédito - Ocurrio un error con el archivo.');
      this.spinner.hide();
    }
  }

  actualizarTabla(): void {
    this.obtenerLista();
  }

  mostrarModal(): void {
    const lista: any[] = [
      { value: 'idOrganizacion : Es el ID de la organización que está realizando la carga' },
      { value: 'rut: Rut al cual pertenecen las credenciales' },
      { value: 'idUsuario: ID de usuario que esta realizando la carga' },
      { value: 'servicio: Servicio al cual pertenecen las credenciales, pueden ser:', 
        lista: [
          'Servicio Impuestos internos: Se envía SII',
          'Clave única: Se envía CLAVE_UNICA'
        ]
      },
      { value: 'user: Es el usuario que acompaña a la credencial. En los servicios SII y CLAVE_UNICA es el rut' },
      { value: 'pass: La credencial a almacenar' },
      { value: 'orgAccess: Indica si las credenciales pueden ser utilizadas por la organización que las esta creando, se debe enviar: true' },
    ];

    this.dialog.open(AccessDialogComponent, {
      data: {
        title: 'Ejemplo archivo de carga',
        urlImage: '../assets/img/image-carga-masiva.png',
        subtitle: 'Las columnas del archivo son:',
        lista: lista,
        textTimer: env.inactivity.countdown + 1
      }
    });

    /*this.simpleModalService.addModal(AccessDialogComponent, {
      title: 'Ejemplo archivo de carga',
      urlImage: '../assets/img/image-carga-masiva.png',
      subtitle: 'Las columnas del archivo son:',
      lista: lista
    }).subscribe(() => {
  
    });*/
  }

}
