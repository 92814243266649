<div class="panel panel-default border-0 not-print">
    <div class="panel-body">
        <div class="btn-option">
            <button *ngIf="showReportes && stepper === 5" [printTitle]='"FlujoDeCredito_"+(rut | rutFormat)+"_"+today'
                [useExistingCss]="true" printSectionId="print-section" ngxPrint
                class="btn btn-primary float-right">Descargar PDF</button>
        </div>
    </div>
  </div>
  
  <div class="not-print">
  
    <ul class="nav nav-tabs d-flex justify-content-center border-0 mt-3">
      <li><button id="button-balance-manuales" (click)="scroll('balance-manuales', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
          <i class="fa fa-line-chart" aria-hidden="true"></i>
          Balance Manuales
        </button>
      </li>
      <li><button id="button-balance-automaticos" (click)="scroll('balance-automaticos', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
          <i class="fa fa-bar-chart" aria-hidden="true"></i>
          Balance Automáticos
        </button>
      </li>
      <li><button id="button-deudores" (click)="scroll('deudores', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
          <i class="fa fa-users"></i>
          Deudores
        </button>
      </li>
      <li><button id="button-iva" (click)="scroll('iva', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
          <i class="fa fa-percent"></i>
          IVA
        </button>
      </li>
      <li><button id="button-carga-financiera" (click)="scroll('carga-financiera', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
          <i class="fa fa-file-text-o"></i>
          Carga Financiera
        </button>
      </li>
      <li><button id="button-linea" (click)="scroll('linea', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
          <i class="fa fa-list-alt"></i>
          Línea / Operación Propuesta
        </button>
      </li>
    </ul>
  
  </div>
  
  
  <div class="col-sm-12" id="print-section">
    <div class="row p-3">
      <div class="col-sm-12">
          <div class="row">
              <h3 class="col-12 my-2 mb-4 cuadro-title p-3 rounded-header">
                  Ficha Comité de Crédito
              </h3>
              <form [formGroup]="servicioFichaForm">
  
                <ng-template #fichaCabecera>
  
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Nombre</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.nombreComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Fecha</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.fechaComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Giro</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.giroComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Ejecutivo Atención</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.ejecutivoComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Rut</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.rutComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Canal Entrada</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.canalEntradaComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Sucursal Contratante</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.sucursalComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Pagina Web</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.paginaWebComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Enrolado</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.enroladoComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Fecha inicio Act SII</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.inicioSiiComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                </ng-template>
  
                <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else fichaCabecera">
  
                  <div class="col-sm-12 col-md-8">
                      <div class="form-group row">
                          <label for="nombre-comite" class="col-12 col-form-label">Nombre</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="nombre-comite" formControlName="nombreComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('nombreComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="fecha-comite" class="col-12 col-form-label">Fecha</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="fecha-comite" formControlName="fechaComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('fechaComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
        
                  <div class="col-sm-12 col-md-8">
                      <div class="form-group row">
                          <label for="giro-comite" class="col-12 col-form-label">Giro</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="giro-comite" formControlName="giroComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('giroComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="ejecutivo-comite" class="col-12 col-form-label">Ejecutivo Atención</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="ejecutivo-comite" formControlName="ejecutivoComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('ejecutivoComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
        
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="rut-comite" class="col-12 col-form-label">Rut</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="rut-comite" formControlName="rutComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('rutComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="canalAdmision-comite" class="col-12 col-form-label">Canal Entrada</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="canalAdmision-comite" formControlName="canalEntradaComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('canalEntradaComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="sucursal-comite" class="col-12 col-form-label">Sucursal Contratante</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="sucursal-comite" formControlName="sucursalComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('sucursalComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
        
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="paginaWeb-comite" class="col-12 col-form-label">Pagina Web</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="paginaWeb-comite" formControlName="paginaWebComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('paginaWebComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="enrolado-comite" class="col-12 col-form-label">Enrolado</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="enrolado-comite" formControlName="enroladoComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('enroladoComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="inicioSii-comite" class="col-12 col-form-label">Fecha inicio Act SII</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="inicioSii-comite" formControlName="inicioSiiComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('inicioSiiComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
  
                </ng-container>
  
              </form>
          </div>
      </div>
    </div>
    
    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak" (click)="show('informacion-sociedad')">
      I. Información Sociedad
    </h3>
    <div class="container-desplegables" id="informacion-sociedad">
      <div class="row p-5">
        <div class="col-sm-12 col-lg-8">
          <div class="table-responsive" *ngIf="showReportes">
            <table class="table table-hover table-bordered border">
                <thead>
                  <tr>
                    <th scope="col" colspan="5" class="text-center py-1 bg-primary font-head-size">Composición de la sociedad</th>
                  </tr>
                  <tr>
                    <th scope="col" class="py-1 bg-primary font-head-size">Socio</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Rut</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">%</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">FS</th>
                  </tr>
                </thead>
                <tbody>
  
                  <ng-template #sociosNoEditable>
  
                    <ng-container *ngFor="let item of sociosComposicion; let i = index">
                      <tr class="height-row pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">
                        <td>{{sociosComposicion[i].socio}}</td>
                        <td>{{sociosComposicion[i].rut | rutFormat}}</td>
                        <td>{{stringToFloat(sociosComposicion[i].porcentaje) | number: '1.0-2'}}</td>
                        <td>{{sociosComposicion[i].fs}}</td>
                      </tr>
                    </ng-container>
  
                  </ng-template>
  
                  <ng-template #sinDatosSocios>
                    <tr class="height-row">
                      <td colspan="5">Sin información</td>
                    </tr>
                  </ng-template>
  
                  <ng-container *ngIf="sociosComposicion.length > 0 else sinDatosSocios">
                    
                    <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else sociosNoEditable">
                      
                      <ng-container *ngFor="let item of sociosComposicion; let i = index">
                        <tr class="height-row">
                          <td class="pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">{{sociosComposicion[i].socio}}</td>
                          <td class="pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">{{sociosComposicion[i].rut | rutFormat}}</td>
                          <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='socio-porcentaje{{i}}' name='socio-porcentaje{{i}}' [(ngModel)]="sociosComposicion[i].porcentaje"></td>
                          <td><input type="text" class="form-control" title="Ingrese si o no" id='socio-fs{{i}}' name='socio-fs{{i}}' [(ngModel)]="sociosComposicion[i].fs"></td>
                        </tr>
                      </ng-container>
  
                    </ng-container>
  
  
                  </ng-container>
                  
                </tbody>
            </table>
          </div>
      </div>
      
      <div class="col-sm-12 col-lg-4">
        <div class="table-responsive" *ngIf="showReportes">
          <table class="table table-hover table-bordered border">
              <thead>
                <tr>
                  <th scope="col" colspan="4" class="text-center py-1 bg-primary font-head-size">Informes Comerciales</th>
                </tr>
                <tr>
                  <th scope="col" class="py-1 bg-primary font-head-size">N°</th>
                  <th scope="col" class="py-1 bg-primary font-head-size">Monto M$</th>
                  <th scope="col" class="py-1 bg-primary font-head-size">Motivo</th>
                </tr>
              </thead>
              <tbody>
  
                <ng-template #sinDatosSociosInforme>
                  <tr class="height-row">
                    <td colspan="4">Sin información</td>
                  </tr>
                </ng-template>
  
                <ng-container *ngIf="sociosInformes.length > 0 else sinDatosSociosInforme">
  
                  <ng-container *ngFor="let item of sociosInformes; let i = index">
                    <tr class="height-row pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">
                      <td>{{sociosInformes[i].numero | number: '1.0-0'}}</td>
                      <td>{{sociosInformes[i].monto | number: '1.0-2'}}</td>
                      <td>{{sociosInformes[i].motivo}}</td>
                    </tr>
                  </ng-container>
                  
                </ng-container>
  
              </tbody>
            </table>
          </div>
        </div>
  
        <div class="col-sm-12 col-lg-8">
          <div class="table-responsive" *ngIf="showReportes">
            <table class="table table-hover table-bordered border">
              <thead>
                <tr>
                  <th scope="col" class="py-1 bg-primary font-head-size">Empresas Relacionadas</th>
                  <th scope="col" class="py-1 bg-primary font-head-size">Rut</th>
                  <th scope="col" class="py-1 bg-primary font-head-size">%</th>
                  <th scope="col" class="py-1 bg-primary font-head-size">FS</th>
                </tr>
              </thead>
              <tbody>
  
                <ng-template #empresasRelacionadasComposicionNoEditable>
  
                  <ng-container *ngFor="let item of empresasRelacionadasComposicion; let i = index">
                    <tr class="height-row pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">
                      <td>{{empresasRelacionadasComposicion[i].socio}}</td>
                      <td>{{empresasRelacionadasComposicion[i].rut | rutFormat}}</td>
                      <td>{{stringToFloat(empresasRelacionadasComposicion[i].porcentaje) | number: '1.0-2'}}</td>
                      <td>{{empresasRelacionadasComposicion[i].fs}}</td>
                    </tr>
                  </ng-container>
  
                </ng-template>
  
                <ng-template #sinDatosEmpresas>
                  <tr class="height-row">
                    <td colspan="5">Sin información</td>
                  </tr>
                </ng-template>
  
                <ng-container *ngIf="empresasRelacionadasComposicion.length > 0 else sinDatosEmpresas">
  
                  <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else empresasRelacionadasComposicionNoEditable">
                    
                    <ng-container *ngFor="let item of empresasRelacionadasComposicion; let i = index">
                      <tr class="height-row">
                        <td class="pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">{{empresasRelacionadasComposicion[i].socio}}</td>
                        <td class="pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">{{empresasRelacionadasComposicion[i].rut | rutFormat}}</td>
                        <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='empresa-porcentaje{{i}}' name='empresa-porcentaje{{i}}' [(ngModel)]="empresasRelacionadasComposicion[i].porcentaje"></td>
                        <td><input type="text" class="form-control" title="Ingrese si o no" id='empresa-fs{{i}}' name='empresa-fs{{i}}' [(ngModel)]="empresasRelacionadasComposicion[i].fs"></td>
                      </tr>
                    </ng-container>
  
                  </ng-container>
                  
                </ng-container>
  
              </tbody>
            </table>
          </div>
        </div>
  
        <div class="col-sm-12 col-lg-4">
          <div class="table-responsive" *ngIf="showReportes">
              <table class="table table-hover table-bordered border">
                <thead>
                  <tr>
                    <th scope="col" class="py-1 bg-primary font-head-size">N°</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Monto M$</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Motivo</th>
                  </tr>
                </thead>
                <tbody>
  
                  <ng-template #sinDatosEmpresasInforme>
                    <tr class="height-row">
                      <td colspan="4">Sin información</td>
                    </tr>
                  </ng-template>
  
                  <ng-container *ngIf="empresasRelacionadasInformes.length > 0 else sinDatosEmpresasInforme">
                    <ng-container *ngFor="let item of empresasRelacionadasInformes; let i = index">
                      <tr class="height-row pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">
                        <td>{{empresasRelacionadasInformes[i].numero | number: '1.0-0'}}</td>
                        <td>{{empresasRelacionadasInformes[i].monto | number: '1.0-2'}}</td>
                        <td>{{empresasRelacionadasInformes[i].motivo}}</td>
                      </tr>
                    </ng-container>
                  </ng-container>
  
                </tbody>
              </table>
            </div>
          </div>
      </div>
    </div>
  
    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('deudores', 'button-deudores')">
      Deudores
    </h3>
    <div class="container-desplegables" id="deudores">
        <div class="row p-5">
          <div class="col-sm-12">
            <form [formGroup]="servicioDeudoresForm">
  
              <ng-template #fichaCabeceraDeudores>
  
                <div class="col-sm-12">
                  <div class="form-group row">
                      <label class="col-4 col-form-label">Cliente</label>
                      <div class="col-8">
                        <span>{{servicioDeudoresForm?.controls?.clienteComite?.value || ''}}</span>
                      </div>
                    </div>
                </div>
  
                <div class="col-sm-12">
                  <div class="form-group row">
                      <label class="col-4 col-form-label">Rut</label>
                      <div class="col-8">
                        <span>{{servicioDeudoresForm?.controls?.rutClienteComite?.value || ''}}</span>
                      </div>
                    </div>
                </div>
  
                <div class="col-sm-12">
                  <div class="form-group row">
                      <label class="col-4 col-form-label">Fecha</label>
                      <div class="col-8">
                        <span>{{servicioDeudoresForm?.controls?.fechaClienteComite?.value || ''}}</span>
                      </div>
                    </div>
                </div>
  
              </ng-template>
              
  
              <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else fichaCabeceraDeudores">
  
                <div class="col-sm-12">
                  <div class="form-group row">
                      <label for="cliente-comite" class="col-4 col-form-label">Cliente</label>
                      <div class="col-8">
                        <input type="text" class="form-control" id="cliente-comite" formControlName="clienteComite">
                        <div class="w-100">
                          <app-error [control]="servicioDeudoresForm.get('clienteComite')"></app-error>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group row">
                        <label for="rut-cliente-comite" class="col-4 col-form-label">Rut</label>
                        <div class="col-8">
                          <input type="text" class="form-control" id="rut-cliente-comite" formControlName="rutClienteComite">
                          <div class="w-100">
                            <app-error [control]="servicioDeudoresForm.get('rutClienteComite')"></app-error>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group row">
                      <label for="fecha-cliente-comite" class="col-4 col-form-label">Fecha</label>
                      <div class="col-8">
                        <input type="text" class="form-control" id="fecha-cliente-comite" formControlName="fechaClienteComite">
                        <div class="w-100">
                          <app-error [control]="servicioDeudoresForm.get('fechaClienteComite')"></app-error>
                        </div>
                      </div>
                    </div>
                </div>
  
              </ng-container>
  
            </form>
          </div>
  
          <div class="col-sm-12 my-3">
            <div class="table-responsive" *ngIf="showReportes">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">N</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Nombre</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Rut</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" colspan="3">Monto (M$)</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" colspan="2">Informes Comerciales</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size">D</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Rango SII</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Web</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Historico Cancelado MM$</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Dias promedio de mora</th>
                  </tr>
                  <tr>
                    <th scope="col" class="py-1 bg-primary font-head-size">Facturas</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Factura a Financiar</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Cheques</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">N°</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Monto M$</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">M$</th>
                  </tr>
                </thead>
                <tbody>
  
                  <ng-template #deudoresNoEditable>
  
                    <ng-container *ngFor="let item of deudores; let i = index">
                      <tr class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">
                        <td>{{(i + 1)}}</td>
                        <td>{{deudores[i].nombre}}</td>
                        <td>{{deudores[i].rut | rutFormat}}</td>
                        <td>{{deudores[i].facturas | number: '1.0-2'}}</td>
                        <td>{{stringToFloat(deudores[i].facturaFinanciar) | number: '1.0-2'}}</td>
                        <td>{{stringToFloat(deudores[i].cheques) | number: '1.0-2'}}</td>
                        <td>{{deudores[i].numero | number: '1.0-0'}}</td>
                        <td>{{deudores[i].monto | number: '1.0-2'}}</td>
                        <td>{{deudores[i].miles | number: '1.0-2'}}</td>
                        <td>{{deudores[i].rangoSii}}</td>
                        <td>{{deudores[i].web}}</td>
                        <td>{{stringToFloat(deudores[i].histCancelado) | number: '1.0-2'}}</td>
                        <td>{{stringToFloat(deudores[i].diasPromedio) | number: '1.0-2'}}</td>
                      </tr>
                    </ng-container>
  
                  </ng-template>
  
                  <ng-template #sinDatosDeudores>
                    <tr class="height-row">
                      <td colspan="13">Sin información</td>
                    </tr>
                  </ng-template>
                    
                  <ng-container *ngIf="deudores.length > 0 else sinDatosDeudores">
  
                    <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else deudoresNoEditable">
                      
                      <ng-container *ngFor="let item of deudores; let i = index">
                        <tr>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{(i + 1)}}</td>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].nombre}}</td>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].rut | rutFormat}}</td>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].facturas | number: '1.0-2'}}</td>
                          <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='deudor-facturaFinanciar{{i}}' name='deudor-facturaFinanciar{{i}}' [(ngModel)]="deudores[i].facturaFinanciar"></td>
                          <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='deudor-cheques{{i}}' name='deudor-cheques{{i}}' [(ngModel)]="deudores[i].cheques"></td>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].numero | number: '1.0-0'}}</td>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].monto | number: '1.0-2'}}</td>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].miles | number: '1.0-2'}}</td>
                          <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].rangoSii}}</td>
                          <td><input type="text" class="form-control" id='deudor-web{{i}}' name='deudor-web{{i}}' [(ngModel)]="deudores[i].web"></td>
                          <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='deudor-histCancelado{{i}}' name='deudor-histCancelado{{i}}' [(ngModel)]="deudores[i].histCancelado"></td>
                          <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='deudor-diasPromedio{{i}}' name='deudor-diasPromedio{{i}}' [(ngModel)]="deudores[i].diasPromedio"></td>
                        </tr>
                      </ng-container>
  
                    </ng-container>
  
                  </ng-container>
  
                </tbody>
              </table>
            </div>
          </div>
  
          <div class="col-sm-12 my-3">
            <div class="table-responsive" *ngIf="showReportes">
              <table class="table table-hover table-bordered border">
                <thead>
                  <tr>
                    <th scope="col" colspan="5" class="text-center py-1 bg-primary font-head-size">Factoring con que opera</th>
                  </tr>
                  <tr>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Rut</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Nombre</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1" colspan="3">Monto Cesiones en M$</th>
                  </tr>
                  <tr>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1">3 Meses</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1">6 Meses</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1">12 Meses</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-template #sinDatosFactoring>
                    <tr class="height-row">
                      <td colspan="5">Sin información</td>
                    </tr>
                  </ng-template>
  
                  <ng-container *ngIf="factoring.length > 0 else sinDatosFactoring">
                    <ng-container *ngFor="let item of factoring; let i = index">
                      <tr>
                        <td>{{item.CESIONARIO | rutFormat}}</td>
                        <td>{{item.rz_cesionario}}</td>
                        <td>{{item.monto3Meses !== ''? (item.monto3Meses | number: '1.0-0'): item.monto3Meses}}</td>
                        <td>{{item.monto6Meses !== ''? (item.monto6Meses | number: '1.0-0'): item.monto6Meses}}</td>
                        <td>{{item.Monto12Meses !== ''? (item.Monto12Meses | number: '1.0-0'): item.Monto12Meses}}</td>
                      </tr>
                    </ng-container>
                  </ng-container>
  
                </tbody>
              </table>
            </div>
          </div>
  
          <div class="col-sm-12 my-3">
            <div class="table-responsive" *ngIf="showReportes">
              <table class="table table-hover border">
                <thead>
                  <tr>
                    <th scope="col" colspan="5" class="text-center py-1 bg-primary font-head-size">Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ng-template #observacionesNoEditable>
  
                        <div [innerHTML]="agregarSaltosDeLinea(observaciones.text)"></div>
  
                      </ng-template>
  
                      <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else observacionesNoEditable">
  
                        <textarea class="form-control" id="deudores-observaciones" name="deudores-observaciones" [(ngModel)]="observaciones.text" maxlength="500"></textarea>
                      
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
  
  
        </div>
    </div>
    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak" (click)="show('iva', 'button-iva')">
      II. Información Financiera
    </h3>
    <div class="container-desplegables" id="iva">
      <div class="row p-5">
        <div class="col-sm-12">
          <div class="table-responsive" *ngIf="showReportes">
            <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col" class="py-1 bg-primary font-head-size" colspan="3">Índices Último Balance</th>
                    <th scope="col" class="py-1 bg-primary font-head-size" colspan="2">Venta Promedio Mensual M$</th>
                    <th scope="col" class="text-center py-1 bg-primary font-head-size" colspan="2" rowspan="2">Morosidades Protestos</th>
                  </tr>
                  <tr>
                    <th scope="col" class="py-1 bg-primary font-head-size">Patrimonio M$</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Leverage</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Utilidad M$</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Ene-Dic</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Ene a la fecha</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{{informacionFinancieraCabecera.dic || ''}}</th>
                    <th>{{informacionFinancieraCabecera.dic || ''}}</th>
                    <th>{{informacionFinancieraCabecera.dic || ''}}</th>
                    <th>{{informacionFinancieraCabecera.anioAnterior || ''}}</th>
                    <th>{{informacionFinancieraCabecera.anioActual || ''}}</th>
                    <th>N°</th>
                    <th>Monto M$</th>
                  </tr>
                  <tr>
                    <td>{{ informacionFinancieraCabecera.patrimonio | number: '1.0-2' }}</td>
                    <td>{{ informacionFinancieraCabecera.leverage | number: '1.0-2' }}</td>
                    <td>{{ informacionFinancieraCabecera.utilidad | number: '1.0-2' }}</td>
                    <td>{{ totalesInformacionFinanciera?.totalAnterior?.TotalProm?.ventas | number: '1.0-2' }}</td>
                    <td>{{ totalesInformacionFinanciera?.totalActual?.TotalProm?.ventas | number: '1.0-2' }}</td>
                    <td class="pointer-cursor" (click)="abrirModalTitular()">{{ informacionFinancieraCabecera.totalImpagoCant | number: '1.0-0' }}</td>
                    <td class="pointer-cursor" (click)="abrirModalTitular()">{{ informacionFinancieraCabecera.totalImpagoMonto | number: '1.0-2' }}</td>
                  </tr>
                </tbody>
            </table>
          </div>
        </div>
    
        <div class="col-sm-12">
          <div class="table-responsive" *ngIf="showReportes && totalesInformacionFinanciera && informacionFinancieraTable.length > 0">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col" class="text-center bg-primary font-head-size">Año</th>
                  <ng-container *ngIf="totalesInformacionFinanciera.totalAnterior.anio">
                    <th scope="col" class="text-center bg-primary font-head-size" colspan="5">{{totalesInformacionFinanciera.totalAnterior.anio}}</th>
                    <th scope="col" class="text-center bg-primary font-head-size" rowspan="2">Posterga</th>
                  </ng-container>
                  <ng-container *ngIf="totalesInformacionFinanciera.totalActual.anio">
                    <th scope="col" class="text-center bg-primary font-head-size" colspan="5">{{totalesInformacionFinanciera.totalActual.anio}}</th>
                    <th scope="col" class="text-center bg-primary font-head-size" rowspan="2">Posterga</th>
                  </ng-container>
                </tr>
                <tr>
                  <th scope="col" class="text-center bg-primary font-head-size">Meses</th>
                  <ng-container *ngIf="totalesInformacionFinanciera.totalAnterior.anio">
                    <th scope="col" class="text-center bg-primary font-head-size">Ventas (M$)</th>
                    <th scope="col" class="text-center bg-primary font-head-size">Compras (M$)</th>
                    <th scope="col" class="text-center bg-primary font-head-size">Diferencia (M$)</th>
                    <th scope="col" class="text-center bg-primary font-head-size">Fecha Pago</th>
                    <th scope="col" class="text-center bg-primary font-head-size">Tipo Declaración</th>
                  </ng-container>
                  
                  <ng-container *ngIf="totalesInformacionFinanciera.totalActual.anio">
                    <th scope="col" class="text-center bg-primary font-head-size">Ventas (M$)</th>
                    <th scope="col" class="text-center bg-primary font-head-size">Compras (M$)</th>
                    <th scope="col" class="text-center bg-primary font-head-size">Diferencia (M$)</th>
                    <th scope="col" class="text-center bg-primary font-head-size">Fecha Pago</th>
                    <th scope="col" class="text-center bg-primary font-head-size">Tipo Declaración</th>               
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of informacionFinancieraTable; let i = index">
                  <tr>
                    <td>{{item?.anterior?.mes || item?.actual?.mes || ''}}</td>
                    <td>{{item?.anterior?.ventas | number: '1.0-2'}}</td>
                    <td>{{item?.anterior?.compras | number: '1.0-2'}}</td>
                    <td>{{item?.anterior?.diferencia | number: '1.0-2'}}</td>
                    <td>{{item?.anterior?.fechaPago || ''}}</td>
                    <td>{{item?.anterior?.tipoDeclaracion || ''}}</td>
                    <td>{{item?.anterior?.posterga | number: '1.0-2'}}</td>
                    <td>{{item?.actual?.ventas| number: '1.0-2'}}</td>
                    <td>{{item?.actual?.compras| number: '1.0-2'}}</td>
                    <td>{{item?.actual?.diferencia| number: '1.0-2'}}</td>
                    <td>{{item?.actual?.fechaPago || ''}}</td>
                    <td>{{item?.actual?.tipoDeclaracion || ''}}</td>
                    <td>{{item?.actual?.posterga | number: '1.0-2'}}</td>
                  </tr>
                  <ng-container *ngIf="i === 5">
                    <tr>
                      <th class="bg-primary">Total Sem.</th>
                      <td class="bg-primary">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem1?.ventas | number: '1.0-2'}}</td>
                      <td class="bg-primary">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem1?.compras | number: '1.0-2'}}</td>
                      <td class="bg-primary">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem1?.dif | number: '1.0-2'}}</td>
                      <td class="bg-primary"></td>
                      <td class="bg-primary"></td>
                      <td class="bg-primary">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem1?.posterga |  number: '1.0-2'}}</td>
                      <td class="bg-primary">{{totalesInformacionFinanciera?.totalActual?.TotalSem1?.ventas | number: '1.0-2'}}</td>
                      <td class="bg-primary">{{totalesInformacionFinanciera?.totalActual?.TotalSem1?.compras | number: '1.0-2'}}</td>
                      <td class="bg-primary">{{totalesInformacionFinanciera?.totalActual?.TotalSem1?.dif | number: '1.0-2'}}</td>
                      <td class="bg-primary"></td>
                      <td class="bg-primary"></td>
                      <td class="bg-primary">{{totalesInformacionFinanciera?.totalActual?.TotalSem1?.posterga |  number: '1.0-2'}}</td>
                    </tr>
                  </ng-container>
    
                </ng-container>
                <tr>
                  <th class="bg-primary">Total Sem.</th>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalAnterior?.TotalSem2?.ventas | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalAnterior?.TotalSem2?.compras | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalAnterior?.TotalSem2?.dif | number: '1.0-2' }}</td>
                  <td class="bg-primary"></td>
                  <td class="bg-primary"></td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalAnterior?.TotalSem2?.posterga | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalActual?.TotalSem2?.ventas | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalActual?.TotalSem2?.compras | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalActual?.TotalSem2?.dif | number: '1.0-2' }}</td>
                  <td class="bg-primary"></td>
                  <td class="bg-primary"></td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalActual?.TotalSem2?.posterga | number: '1.0-2' }}</td>
                </tr>
                <tr>
                  <th class="bg-primary">Total Año</th>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.ventas | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.compras | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.dif | number: '1.0-2' }}</td>
                  <td class="bg-primary"></td>
                  <td class="bg-primary"></td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.posterga | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalActual?.TotalAnio?.ventas | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalActual?.TotalAnio?.compras | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalActual?.TotalAnio?.dif | number: '1.0-2' }}</td>
                  <td class="bg-primary"></td>
                  <td class="bg-primary"></td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalActual?.TotalAnio?.posterga | number: '1.0-2' }}</td>
                </tr>
                <tr>
                  <th class="bg-primary">Promedio</th>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalAnterior?.TotalProm?.ventas | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalAnterior?.TotalProm?.compras | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalAnterior?.TotalProm?.dif | number: '1.0-2' }}</td>
                  <td class="bg-primary"></td>
                  <td class="bg-primary"></td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalAnterior?.TotalProm?.posterga | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalActual?.TotalProm?.ventas | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalActual?.TotalProm?.compras | number: '1.0-2' }}</td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalActual?.TotalProm?.dif | number: '1.0-2' }}</td>
                  <td class="bg-primary"></td>
                  <td class="bg-primary"></td>
                  <td class="bg-primary">{{ totalesInformacionFinanciera?.totalActual?.TotalProm?.posterga | number: '1.0-2' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
    </div>
  
    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('balance-manuales', 'button-balance-manuales')"
      [ngClass]="{'pagebreak': dataManualCargada}"
    >
      Balance Manual
    </h3>
    <div class="container-desplegables" id="balance-manuales">
      <div class="row p-5">
        <div class="col-12">
          <app-balance-automatico-demo-workflow *ngIf="dataManualCargada" #appinformef22viewManual [reporteFullContent]="balanceManual" [rut]="rut"></app-balance-automatico-demo-workflow>
          <span *ngIf="!dataManualCargada">
            <h4>No se encuentran datos en los servicios consultados.</h4>
          </span>
        </div>
      </div>
    </div>
  
    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('balance-automaticos', 'button-balance-automaticos')"
      [ngClass]="{'pagebreak': dataCargada}"
    >
      Balance Automatico
    </h3>
    <div class="container-desplegables" id="balance-automaticos">
      <div class="row p-5">
        <div class="col-12">
          <app-balance-automatico-demo-workflow *ngIf="dataCargada" #appinformef22view [reporteFullContent]="dataFiltrada" [rut]="rut"></app-balance-automatico-demo-workflow>
          <span *ngIf="!dataCargada">
            <h4>No se encuentran datos en los servicios consultados.</h4>
          </span>
        </div>
      </div>
    </div>
  
    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak" (click)="show('linea', 'button-linea')">
      III. Línea / Operación Propuesta
    </h3>
  
    <div class="container-desplegables" id="linea">
      <div class="row p-5">
        <ng-container *ngIf="showReportes && objectKeys(lineaOperacionPropuesta).length > 0">
  
          <ng-template #lineaNoEditable>
  
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-sm table-hover table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" rowspan="2">Producto</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" colspan="4">Financiamiento Propuesto (M$)</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" rowspan="2">%</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" rowspan="2">Plazo (dias)</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" colspan="3" rowspan="1">Colocacion (M$)</th>
                      </tr>
                      <tr>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Linea Vigente</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Linea Propuesta</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Op. Puntual Vigente</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Op. Puntual Propuesta</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Vigente</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Propuesta</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Cancelada</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Facturas</th>
                        <td>{{lineaOperacionPropuesta.lineaVigente.facturas | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.lineaPropuesta.facturas | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualVigente.facturas | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualPropuesta.facturas | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.porcentaje.facturas | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.plazoDias.facturas | number: '1.0-0'}}</td>
                        <td>{{lineaOperacionPropuesta.vigente.facturas | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.propuesta.facturas | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.cancelada.facturas | number: '1.0-2'}}</td>
                      </tr>
                      <tr>
                        <th>Cheques</th>
                        <td>{{lineaOperacionPropuesta.lineaVigente.cheques | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.lineaPropuesta.cheques | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualVigente.cheques | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualPropuesta.cheques | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.porcentaje.cheques | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.plazoDias.cheques | number: '1.0-0'}}</td>
                        <td>{{lineaOperacionPropuesta.vigente.cheques | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.propuesta.cheques | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.cancelada.cheques | number: '1.0-2'}}</td>
                      </tr>
                      <tr>
                        <th>Confirming</th>
                        <td>{{lineaOperacionPropuesta.lineaVigente.confirming | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.lineaPropuesta.confirming | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualVigente.confirming | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualPropuesta.confirming | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.porcentaje.confirming | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.plazoDias.confirming | number: '1.0-0'}}</td>
                        <td>{{lineaOperacionPropuesta.vigente.confirming | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.propuesta.confirming | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.cancelada.confirming | number: '1.0-2'}}</td>
                      </tr>
                      <tr>
                        <th>Contrato Serviu</th>
                        <td>{{lineaOperacionPropuesta.lineaVigente.contratoServiu | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.lineaPropuesta.contratoServiu | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualVigente.contratoServiu | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualPropuesta.contratoServiu | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.porcentaje.contratoServiu | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.plazoDias.contratoServiu | number: '1.0-0'}}</td>
                        <td>{{lineaOperacionPropuesta.vigente.contratoServiu | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.propuesta.contratoServiu | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.cancelada.contratoServiu | number: '1.0-2'}}</td>
                      </tr>
                      <tr>
                        <th>Credito Directo</th>
                        <td>{{lineaOperacionPropuesta.lineaVigente.creditoDirecto | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.lineaPropuesta.creditoDirecto | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualVigente.creditoDirecto | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.opPuntualPropuesta.creditoDirecto | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.porcentaje.creditoDirecto | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.plazoDias.creditoDirecto | number: '1.0-0'}}</td>
                        <td>{{lineaOperacionPropuesta.vigente.creditoDirecto | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.propuesta.creditoDirecto | number: '1.0-2'}}</td>
                        <td>{{lineaOperacionPropuesta.cancelada.creditoDirecto | number: '1.0-2'}}</td>
                      </tr>
                      <tr>
                        <th class="bg-primary">Total Propuesta</th>
                        <td>{{calcularTotalLineaVigente() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalLineaPropuesta() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalOpPuntualVigente() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalOpPuntualPropuesta() || 0 | number: '1.0-2'}}</td>
                        <th class="bg-primary" colspan="2">Total</th>
                        <td>{{calcularTotalVigente() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalPropuesta() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalCancelada() || 0 | number: '1.0-2'}}</td>
                      </tr>
                    </tbody>
                  </table>
              </div>
            </div>
          
            <div class="col-md-12 col-lg-8">
              <div class="row">
                  <div class="col-12 ml-4">
                      <div class="row">
                          <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                              <span class="span-linea">Vencimiento</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border">
                            <span>{{lineaOperacionPropuesta.vencimiento | date: 'dd-MM-yyyy'}}</span>
                          </div>
                      </div>
                  </div>
                  <div class="col-12 ml-4">
                    <div class="row d-flex flex-row-reverse">
                        <div class="col-sm-6 col-lg-3 border">
                          <span>{{calcularOpPuntualM() | number: '1.0-2'}}</span>
                        </div>
                        <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                            <span class="span-linea">Op. Puntual M$</span>
                        </div>
                      </div>
                  </div>
                  <div class="col-12 ml-4">
                      <div class="row">
                          <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                              <span class="span-linea">Total Linea M$</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border">
                            <span>{{calcularTotalLineaM() | number: '1.0-2'}}</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                              <span class="span-linea">Riesgo Total M$</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border">
                            <span>{{calcularRiesgoTotalM() | number: '1.0-2'}}</span>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
          
            <div class="col-12">
              <div class="row">
                  <div class="col-12 ml-4 mt-4 border pl-0">
                      <div class="col-sm-12 col-md-6 col-lg-3 border text-center bg-primary font-head-size">
                          <span class="span-linea">Presentacion Cliente</span>
                      </div>
                      <div class="col-12 pb-4">
  
                        <div class="mt-5" [innerHTML]="agregarSaltosDeLinea(lineaOperacionPropuesta.presentacionCliente)"></div>
    
                      </div>
                  </div>
              </div>
            </div>
            
          </ng-template>
          
          <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else lineaNoEditable">
            
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-sm table-hover table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" rowspan="2">Producto</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" colspan="4">Financiamiento Propuesto (M$)</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" rowspan="2">%</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" rowspan="2">Plazo (dias)</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1" colspan="3" rowspan="1">Colocacion (M$)</th>
                      </tr>
                      <tr>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Linea Vigente</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Linea Propuesta</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Op. Puntual Vigente</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Op. Puntual Propuesta</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Vigente</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Propuesta</th>
                        <th scope="col" class="text-center bg-primary font-head-size py-1">Cancelada</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Facturas</th>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigentefacturas0' name='linea-lineaVigentefacturas0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestafacturas0' name='linea-lineaPropuestafacturas0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigentefacturas0' name='linea-opPuntualVigentefacturas0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestafacturas0' name='linea-opPuntualPropuestafacturas0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajefacturas0' name='linea-porcentajefacturas0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiasfacturas0' name='linea-plazoDiasfacturas0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigentefacturas0' name='linea-vigentefacturas0' [(ngModel)]="lineaOperacionPropuesta.vigente.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestafacturas0' name='linea-propuestafacturas0' [(ngModel)]="lineaOperacionPropuesta.propuesta.facturas"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladafacturas0' name='linea-canceladafacturas0' [(ngModel)]="lineaOperacionPropuesta.cancelada.facturas"></td>
                      </tr>
                      <tr>
                        <th>Cheques</th>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigentecheques0' name='linea-lineaVigentecheques0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.cheques"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestacheques0' name='linea-lineaPropuestacheques0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.cheques"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigentecheques0' name='linea-opPuntualVigentecheques0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.cheques"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestacheques0' name='linea-opPuntualPropuestacheques0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.cheques"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajecheques0' name='linea-porcentajecheques0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.cheques"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiascheques0' name='linea-plazoDiascheques0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.cheques"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigentecheques0' name='linea-vigentecheques0' [(ngModel)]="lineaOperacionPropuesta.vigente.cheques"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestacheques0' name='linea-propuestacheques0' [(ngModel)]="lineaOperacionPropuesta.propuesta.cheques"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladacheques0' name='linea-canceladacheques0' [(ngModel)]="lineaOperacionPropuesta.cancelada.cheques"></td>
                      </tr>
                      <tr>
                        <th>Confirming</th>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigenteconfirming0' name='linea-lineaVigenteconfirming0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestaconfirming0' name='linea-lineaPropuestaconfirming0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigenteconfirming0' name='linea-opPuntualVigenteconfirming0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestaconfirming0' name='linea-opPuntualPropuestaconfirming0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajeconfirming0' name='linea-porcentajeconfirming0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiasconfirming0' name='linea-plazoDiasconfirming0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigenteconfirming0' name='linea-vigenteconfirming0' [(ngModel)]="lineaOperacionPropuesta.vigente.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestaconfirming0' name='linea-propuestaconfirming0' [(ngModel)]="lineaOperacionPropuesta.propuesta.confirming"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladaconfirming0' name='linea-canceladaconfirming0' [(ngModel)]="lineaOperacionPropuesta.cancelada.confirming"></td>
                      </tr>
                      <tr>
                        <th>Contrato Serviu</th>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigentecontratoServiu0' name='linea-lineaVigentecontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestacontratoServiu0' name='linea-lineaPropuestacontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigentecontratoServiu0' name='linea-opPuntualVigentecontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestacontratoServiu0' name='linea-opPuntualPropuestacontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajecontratoServiu0' name='linea-porcentajecontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiascontratoServiu0' name='linea-plazoDiascontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigentecontratoServiu0' name='linea-vigentecontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.vigente.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestacontratoServiu0' name='linea-propuestacontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.propuesta.contratoServiu"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladacontratoServiu0' name='linea-canceladacontratoServiu0' [(ngModel)]="lineaOperacionPropuesta.cancelada.contratoServiu"></td>
                      </tr>
                      <tr>
                        <th>Credito Directo</th>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigentecreditoDirecto0' name='linea-lineaVigentecreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestacreditoDirecto0' name='linea-lineaPropuestacreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigentecreditoDirecto0' name='linea-opPuntualVigentecreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestacreditoDirecto0' name='linea-opPuntualPropuestacreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajecreditoDirecto0' name='linea-porcentajecreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiascreditoDirecto0' name='linea-plazoDiascreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigentecreditoDirecto0' name='linea-vigentecreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.vigente.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestacreditoDirecto0' name='linea-propuestacreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.propuesta.creditoDirecto"></td>
                        <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladacreditoDirecto0' name='linea-canceladacreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.cancelada.creditoDirecto"></td>
                      </tr>
                      <tr>
                        <th class="bg-primary">Total Propuesta</th>
                        <td>{{calcularTotalLineaVigente() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalLineaPropuesta() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalOpPuntualVigente() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalOpPuntualPropuesta() || 0 | number: '1.0-2'}}</td>
                        <th class="bg-primary" colspan="2">Total</th>
                        <td>{{calcularTotalVigente() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalPropuesta() || 0 | number: '1.0-2'}}</td>
                        <td>{{calcularTotalCancelada() || 0 | number: '1.0-2'}}</td>
                      </tr>
                    </tbody>
                  </table>
              </div>
            </div>
          
            <div class="col-md-12 col-lg-8">
              <div class="row">
                  <div class="col-12 ml-4">
                      <div class="row">
                          <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                              <span class="span-linea">Vencimiento</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border">
                            <input type="date" class="form-control form-control-sm p-0" id='linea-vencimiento0' name='linea-vencimiento0' [(ngModel)]="lineaOperacionPropuesta.vencimiento">
                          </div>
                      </div>
                  </div>
                  <div class="col-12 ml-4">
                    <div class="row d-flex flex-row-reverse">
                        <div class="col-sm-6 col-lg-3 border">
                          <span>{{calcularOpPuntualM() | number: '1.0-2'}}</span>
                        </div>
                        <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                            <span class="span-linea">Op. Puntual M$</span>
                        </div>
                      </div>
                  </div>
                  <div class="col-12 ml-4">
                      <div class="row">
                          <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                              <span class="span-linea">Total Linea M$</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border">
                            <span>{{calcularTotalLineaM() | number: '1.0-2'}}</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border text-center bg-primary font-head-size">
                              <span class="span-linea">Riesgo Total M$</span>
                          </div>
                          <div class="col-sm-6 col-lg-3 border">
                            <span>{{calcularRiesgoTotalM() | number: '1.0-2'}}</span>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
          
            <div class="col-12">
              <div class="row">
                  <div class="col-12 ml-4 mt-4 border pl-0">
                      <div class="col-sm-12 col-md-6 col-lg-3 border text-center bg-primary font-head-size">
                          <span class="span-linea">Presentacion Cliente</span>
                      </div>
                      <div class="col-12 pb-4">
                        <textarea class="form-control mt-4" id="linea-presentacionCliente0" name="linea-presentacionCliente0" [(ngModel)]="lineaOperacionPropuesta.presentacionCliente" maxlength="500"></textarea>
                      </div>
                  </div>
              </div>
            </div>
  
          </ng-container>
  
        </ng-container>
      </div>
    </div>
  
    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak" (click)="show('carga-financiera', 'button-carga-financiera')">
      Carga Financiera
    </h3>
    <div class="container-desplegables" id="carga-financiera">
      <div class="row p-5">
        <div class="col-sm-12">
          <div class="table-responsive" *ngIf="showReportes">
  
            <ng-template #cargaFinancieraNoEditable>
  
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col" class="py-1 bg-primary font-head-size" colspan="1">Nombre Cliente</th>
                    <th scope="col" class="py-1 font-head-size" colspan="3">
                      {{nombreClienteCargaFinanciera}}
                    </th>
                  </tr>
                  <tr>
                    <th scope="col" class="py-1 bg-primary font-head-size">Fecha de Curse</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Institucion</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Tipo de Credito</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Credito Original M$</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Deuda Actual M$</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Cuota M$</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">N° Cuotas por pagar</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">N° Cuotas Morosas</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Ultimo Vencimiento</th>
                    <th scope="col" class="py-1 bg-primary font-head-size">Garantias Vigentes</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of cargaFinanciera; let i = index">
                    <tr>
                      <td>{{cargaFinanciera[i].fechaCurse | date: 'dd-MM-yyyy'}}</td>
                      <td>{{cargaFinanciera[i].institucion}}</td>
                      <td>{{cargaFinanciera[i].tipoCredito}}</td>
                      <td>{{stringToFloat(cargaFinanciera[i].creditoOriginal) | number: '1.0-2'}}</td>
                      <td>{{stringToFloat(cargaFinanciera[i].deudaActual) | number: '1.0-2'}}</td>
                      <td>{{stringToFloat(cargaFinanciera[i].cuota) | number: '1.0-2'}}</td>
                      <td>{{cargaFinanciera[i].nCuotasPorPagar | number: '1.0-0'}}</td>
                      <td>{{cargaFinanciera[i].nCuotasMorosas | number: '1.0-0'}}</td>
                      <td>{{cargaFinanciera[i].ultimoVencimiento | date: 'dd-MM-yyyy'}}</td>
                      <td>{{cargaFinanciera[i].garantiasVigentes}}</td>
                    </tr>
                  </ng-container>
                  <tr>
                    <th colspan="3">TOTAL</th>
                    <td>{{calcularTotalCreditoOriginal() || 0 | number: '1.0-2'}}</td>
                    <td>{{calcularTotalDeudaActual() || 0 | number: '1.0-2'}}</td>
                    <td>{{calcularTotalCuota() || 0 | number: '1.0-2'}}</td>
                    <td>{{calcularTotalNCuotasPorPagar() || 0 | number: '1.0-0'}}</td>
                    <td>{{calcularTotalNCuotasMorosas() || 0 | number: '1.0-0'}}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
  
            </ng-template>
  
            <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else cargaFinancieraNoEditable">
  
              <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" class="py-1 bg-primary font-head-size" colspan="2">Nombre Cliente</th>
                      <th scope="col" class="py-1 font-head-size" colspan="3">
                        <input type="text" class="form-control" id='carga-nombreClienteCargaFinanciera' name='carga-nombreClienteCargaFinanciera' [(ngModel)]="nombreClienteCargaFinanciera">
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" class="py-1 bg-primary font-head-size text-center">
                        <button type="button" class="btn btn-success btn-mas" (click)="addRowCargaFinanciera()" title="Agregar Fila">
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                      </th>
                      <th scope="col" class="py-1 bg-primary font-head-size">Fecha de Curse</th>
                      <th scope="col" class="py-1 bg-primary font-head-size">Institucion</th>
                      <th scope="col" class="py-1 bg-primary font-head-size">Tipo de Credito</th>
                      <th scope="col" class="py-1 bg-primary font-head-size">Credito Original M$</th>
                      <th scope="col" class="py-1 bg-primary font-head-size">Deuda Actual M$</th>
                      <th scope="col" class="py-1 bg-primary font-head-size">Cuota M$</th>
                      <th scope="col" class="py-1 bg-primary font-head-size">N° Cuotas por pagar</th>
                      <th scope="col" class="py-1 bg-primary font-head-size">N° Cuotas Morosas</th>
                      <th scope="col" class="py-1 bg-primary font-head-size">Ultimo Vencimiento</th>
                      <th scope="col" class="py-1 bg-primary font-head-size">Garantias Vigentes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let item of cargaFinanciera; let i = index">
                      <tr>
                      <td class="text-center">
                        <button type="button" class="btn btn-danger btn-mas" (click)="deleteRowCargaFinanciera(i)" title="Quitar Fila">
                        <i class="fa fa-minus" aria-hidden="true"></i>
                        </button>
                      </td>
                      <td><input type="date" class="form-control form-control-xs" id='carga-fechaCurse{{i}}' name='carga-fechaCurse{{i}}' [(ngModel)]="cargaFinanciera[i].fechaCurse"></td>
                      <td><input type="text" class="form-control" id='carga-institucion{{i}}' name='carga-institucion{{i}}' [(ngModel)]="cargaFinanciera[i].institucion"></td>
                      <td><input type="text" class="form-control" id='carga-tipoCredito{{i}}' name='carga-tipoCredito{{i}}' [(ngModel)]="cargaFinanciera[i].tipoCredito"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='carga-creditoOriginal{{i}}' name='carga-creditoOriginal{{i}}' [(ngModel)]="cargaFinanciera[i].creditoOriginal"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='carga-deudaActual{{i}}' name='carga-deudaActual{{i}}' [(ngModel)]="cargaFinanciera[i].deudaActual"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='carga-cuota{{i}}' name='carga-cuota{{i}}' [(ngModel)]="cargaFinanciera[i].cuota"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero" id='carga-nCuotasPorPagar{{i}}' name='carga-nCuotasPorPagar{{i}}' [(ngModel)]="cargaFinanciera[i].nCuotasPorPagar"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero" id='carga-nCuotasMorosas{{i}}' name='carga-nCuotasMorosas{{i}}' [(ngModel)]="cargaFinanciera[i].nCuotasMorosas"></td>
                      <td><input type="date" class="form-control form-control-xs" id='carga-ultimoVencimiento{{i}}' name='carga-ultimoVencimiento{{i}}' [(ngModel)]="cargaFinanciera[i].ultimoVencimiento"></td>
                      <td><input type="text" class="form-control" id='carga-garantiasVigentes{{i}}' name='carga-garantiasVigentes{{i}}' [(ngModel)]="cargaFinanciera[i].garantiasVigentes"></td>
                      </tr>
                    </ng-container>
                    <tr>
                      <th colspan="4">TOTAL</th>
                      <td>{{calcularTotalCreditoOriginal() || 0 | number: '1.0-2'}}</td>
                      <td>{{calcularTotalDeudaActual() || 0 | number: '1.0-2'}}</td>
                      <td>{{calcularTotalCuota() || 0 | number: '1.0-2'}}</td>
                      <td>{{calcularTotalNCuotasPorPagar() || 0 | number: '1.0-0'}}</td>
                      <td>{{calcularTotalNCuotasMorosas() || 0 | number: '1.0-0'}}</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
              </table>
  
            </ng-container>
  
          </div>
        </div>
  
        <ng-container *ngIf="showReportes">
  
          <div class="col-sm-12">
            <table class="table table-hover table-bordered">
              <tbody>
                <tr>
                  <td><b>Fecha de curse: </b>Es la fecha en que fue tomado el crédito con el banco.</td>
                </tr>
                <tr>
                  <td><b>Tipo de Credito: </b>Aquí se debe decir si es un crédito en cuotas (mensuales o annuales), crédito a plazo fijo, Boleta de garantía o carta de crédito, línea de capital de trabajo, Leasing, etc.</td>
                <tr>
                  <td><b>Credito Original: </b>Es el monto por el cual fue tomado el crédito.</td>
                </tr>
                <tr>
                  <td><b>Deuda Actual: </b>Es lo que queda por pagar del crédito.</td>
                </tr>
                <tr>
                  <td><b>Cuota: </b>Es lo que paga mensual o anualmente según el tipo de crédito.</td>
                </tr>
                <tr>
                  <td><b>Cuotas Faltantes: </b>Son las cuotas que quedan por pagar.</td>
                </tr>
                <tr>
                  <td><b>Vencimiento: </b>Es la fecha en que el crédito se termina, es cuando se paga la última cuota.</td>
                </tr>
                <tr>
                  <td><b>Garantias Vigentes: </b>Se debe indicar si el crédito fue tomado solo con avales o con alguna prenda o hipoteca, de ser una de las dos ultimas indicar el valor en M$.</td>
                </tr>
              </tbody>
            </table>
          </div>
  
        </ng-container>
        
      </div>
    </div>

    <div class="col-12 my-2 not-print" *ngIf="showArchivos">
      <app-modulo-archivos [idTransaccion]="idTransaccion" [rut]="rut" [closed]="closed"></app-modulo-archivos>
    </div>
    
  </div>
  
  <ng-container *ngIf="plantillaNoEditable === false">
    <ng-template #botonFinalizar>
      <div class="not-print">
        <ng-container *ngIf="validaUsuarioAdmin()">
          <div class="col-12 d-flex justify-content-center pt-4">
            <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="volverEtapaPrevia()">
              Devolver Etapa Previa
            </button>
            <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="continuar()">
              Continuar
            </button>
          </div>
        </ng-container>
      </div>
    </ng-template>
    
    <ng-container *ngIf="stepper === 4 else botonFinalizar">
    
      <div class="col-12 d-flex justify-content-center pt-4">
        <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="guardarFicha()">
          Guardar
        </button>
        <button class="btn btn-sm btn-primary mx-2 rounded-header" [disabled]="objectKeys(fichaGuardada).length > 0 ? null : true" (click)="avanzarFlujo()">
          Continuar
        </button>
      </div>
    
    </ng-container>
  </ng-container>
  
  
  <app-modal-consolidado-workflow [rut]="rutSeleccionado" [idTransaccion]="idTransaccion" [dataServicios]="reportesSeleccionado" [informeComercial]="informeComercial" [default]="modalDefault" [plantilla]="plantillaAntecedentes"></app-modal-consolidado-workflow>
  