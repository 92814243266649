import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-balance-manual-input-view',
  templateUrl: './balance-manual-input-view.component.html',
  styleUrls: ['./balance-manual-input-view.component.scss']
})
export class BalanceManualInputViewComponent implements OnInit {
  public objectKeys = Object.keys;
  @Input() valores: any[] = [];
  @Input() arrayContrato: any[] = [];
  @Input() contratoResultados: any = {};
  constructor() { }

  ngOnInit(): void {
  }

  esNumeroOStringNumerico(valor: any): boolean {
    if (typeof valor === "number") {
      return true;
    }

    if (typeof valor === "string" && valor.trim() !== "") {
      return /^[0-9]+$/.test(valor);
    }

    return false;
  }

  validaVariables(variables: any[], nivelKey: string): boolean {
    let respuesta: boolean = true;
    if(variables && variables.length === 1 && nivelKey) {
      const variable = variables[0] || [];
      if(nivelKey === variable?.[0]?.key){
        respuesta = false
      }
    } 
    return respuesta;
  }

  validaCampo(campo: any[], cantVariables: number): boolean {
    let respuesta: boolean = true;
    if(campo && campo.length > 0) {
      for (const property in this.contratoResultados) {
        if(this.contratoResultados[property]?.nivel1){
          if(this.contratoResultados[property]?.nivel1 === campo?.[0]?.key && cantVariables === 1) return false;
        }
        if(this.contratoResultados[property]?.nivel2){
          if(this.contratoResultados[property]?.nivel2 === campo?.[0]?.key && cantVariables === 1) return false;
        }
        if(this.contratoResultados[property]?.nivel3){
          if(this.contratoResultados[property]?.nivel3 === campo?.[0]?.key && cantVariables === 1) return false;
        }
      }
      // logica para quitar campos vacios
      if(campo.filter((e, index) => index > 0).every((e, index) => !e?.valor || e?.valor === '' || e?.valor === 0 || e?.valor === '0')) {
        return false;
      }
    } 
    return respuesta;
  }

  toggleContent(event: any, id: string): void{
    let estadoClosed: boolean | null = null;
    if(event?.target?.tagName && event?.target?.tagName.toUpperCase() === 'TH') {
      if(event?.target?.parentElement) {
        const parentElement: any = event?.target?.parentElement;
        if ( parentElement.classList.contains('closed') ){
          parentElement.classList.remove('closed');
          estadoClosed = false;
        } else {
          parentElement.classList.add('closed');
          estadoClosed = true;
        }
      }
    } else if(event?.target?.tagName && event?.target?.tagName.toUpperCase() === 'TR') {
      const element: any = event?.target;
      if ( element.classList.contains('closed') ){
        element.classList.remove('closed');
        estadoClosed = false;
      } else {
        element.classList.add('closed');
        estadoClosed = true;
      }

    }

    if(estadoClosed != null){
      const elementosHTML: any = document.querySelectorAll(`[id^="${id}"]`);
      for (const elementoHTML of elementosHTML) {
        if(elementoHTML){
          if(estadoClosed === true) {
            elementoHTML.classList.contains('d-none') ? null : elementoHTML.classList.add('d-none');
          } else {
            elementoHTML.classList.contains('d-none') ? elementoHTML.classList.remove('d-none') : null;
            elementoHTML.classList.contains('closed') ? elementoHTML.classList.remove('closed') : null;
          }
        }
      }
    }
  }

  obtenerNombreMes(mes: number): string {
    const meses = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    // Verificar que el número esté entre 1 y 12
    if (mes && mes >= 1 && mes <= 12) {
        return meses[mes - 1];
    } else {
        return "";
    }
  }

  obtenerDatosColumna(index: number): string {
    if(index >= 0){
      const tipo: string = this.valores[index]?.tipo || '';
      const periodo: string = this.valores[index]?.periodo.toString() || '';
      const mesInicio: number = this.valores[index]?.mesInicio || '';
      const mesFin: number = this.valores[index]?.mesFin || '';
      return `${tipo} ${periodo} ${this.obtenerNombreMes(mesInicio)} - ${this.obtenerNombreMes(mesFin)}`;
    }
    return '';
  }

  obtenerValorTotal(nivelKey: string, objeto: any): any {
    if(nivelKey && objeto && Object.keys(objeto).length > 0){
      const valor: any = (objeto?.balance?.[nivelKey] || objeto?.balance?.[nivelKey] == 0) ? (objeto?.balance?.[nivelKey]) : 
        (objeto?.campos?.[nivelKey] || objeto?.campos?.[nivelKey] == 0) ? (objeto?.campos?.[nivelKey]) : ("");
      return valor;
    }
    return '';
  }

  obtenerTotalSeccion(nivelKey: string, objeto: any): number | string {
    if(nivelKey && objeto?.campos && Object.keys(objeto?.campos).length > 0){
      let suma = 0;
      for (const key in objeto?.campos) {
        if(this.contratoResultados?.[key] && Object.keys(this.contratoResultados?.[key]).length > 0 && 
          (this.contratoResultados?.[key]?.type === 'number' || this.contratoResultados?.[key]?.type === 'decimal') && 
          this.contratoResultados?.[key]?.nivel1 === nivelKey){
          suma += Number(objeto?.campos[key]);
        }
      }
      return suma;
    }
    return '';
  }

  obtenerTotalSubSeccion(nivelKey: string, nivel2Key: string, objeto: any): number | string {
    if(nivelKey && objeto?.campos && Object.keys(objeto?.campos).length > 0){
      let suma = 0;
      for (const key in objeto?.campos) {
        if(this.contratoResultados?.[key] && Object.keys(this.contratoResultados?.[key]).length > 0 && 
          (this.contratoResultados?.[key]?.type === 'number' || this.contratoResultados?.[key]?.type === 'decimal') && 
          this.contratoResultados?.[key]?.nivel1 === nivelKey && 
          this.contratoResultados?.[key]?.nivel2 === nivel2Key){
          suma += Number(objeto?.campos[key]);
        }
      }
      return suma;
    }
    return '';
  }

  obtenerTotalSeccion3(nivelKey: string, nivel2Key: string, nivel3Key: string, objeto: any): number | string {
    if(nivelKey && objeto?.campos && Object.keys(objeto?.campos).length > 0){
      let suma = 0;
      for (const key in objeto?.campos) {
        if(this.contratoResultados?.[key] && Object.keys(this.contratoResultados?.[key]).length > 0 && 
          (this.contratoResultados?.[key]?.type === 'number' || this.contratoResultados?.[key]?.type === 'decimal') && 
          this.contratoResultados?.[key]?.nivel1 === nivelKey && 
          this.contratoResultados?.[key]?.nivel2 === nivel2Key && this.contratoResultados?.[key]?.nivel3 === nivel3Key){
          suma += Number(objeto?.campos[key]);
        }
      }
      return suma;
    }
    return '';
  }

  validaFilaTotal(nivel1: any): boolean {
    // Verificar si el valor total de la fila es diferente de vacío
    if (this.valores.some((valor: any) => this.obtenerTotalSeccion(nivel1, valor) !== null && this.obtenerTotalSeccion(nivel1, valor) !== undefined && this.obtenerTotalSeccion(nivel1, valor) !== "")) {
      return true;
    }
    return false;
  }

}
