<div class="container mt-5">
    <!-- Back Button -->
    <div class="d-flex justify-content-start mb-3">
      <button class="btn btn-primary" (click)="goBack()">Volver</button>
    </div>
  
    <!-- Main Section -->
    <div class="card p-4">
      <!-- Title -->
      <h3 class="text-center mb-4">Procesos Ejecutados</h3>
  
      <!-- Cartera Details Table -->
        <!-- Card Table Info -->
    <div class="row mt-5" *ngIf="detail">
      <div class="col-12 d-flex justify-content-center">
        <div class="table-responsive" style="max-width: 316px;">
          <table class="table table-bordered bg-white">
            <tbody>
              <tr>
                <th class="bg-primary">ID Cartera</th>
                <td>{{detail.id}}</td>
              </tr>
              <tr>
                <th class="bg-primary">Nombre Cartera</th>
                <td>{{detail.name}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
      <!-- Search Form -->
      <div class="accordion mb-5 mt-5" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
              Listado Procesos Ejecutados
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <form class="row mb-3 mt-5">
                <div class="col-md-4">
                  <label for="modelo" class="form-label">Modelo</label>
                  <select class="form-select form-control" id="modelo" [(ngModel)]="selectedModel" (ngModelChange)="onModelChange($event)" name="modelo">
                    <option *ngFor="let model of modelos" [value]="model.batchTemplate?.tag">{{ model.batchTemplate?.tag }}</option>
                  </select>
                </div>
  
                <div class="col-md-4">
                  <label for="fechaInicio" class="form-label">Fecha Inicio</label>
                  <input type="date" class="form-control" id="fechaInicio" [(ngModel)]="fechaInicio" (ngModelChange)="onDateChange('fechaInicio', $event)" name="fechaInicio">
                </div>
  
                <div class="col-md-4">
                  <label for="fechaFin" class="form-label">Fecha Fin</label>
                  <input type="date" class="form-control" id="fechaFin" [(ngModel)]="fechaFin" (ngModelChange)="onDateChange('fechaFin', $event)" name="fechaFin">
                </div>
              </form>
  
              <div class="row">
                <div class="col-md-12 text-end">
                  <button class="btn btn-primary" [disabled]="selectedModel == ''" (click)="buscar()">Buscar</button>
                </div>
              </div>
  
              <!-- Table -->
              <table class="table table-striped mt-5">
                <thead class="table-primary">
                  <tr>
                    <th>Fecha Ejecución</th>
                    <th>ID Proceso</th>
                    <th>Modelo</th>
                    <th>Estado</th>
                    <th>Frecuencia</th>
                    <!-- <th>Origen</th> -->
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let proceso of paginatedData">
                    <td>{{ proceso.creationDate | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
                    <td>{{ proceso.id }}</td>
                    <td>{{ proceso.batchTemplate?.name ? proceso.batchTemplate?.name : proceso.batchTemplate?.tag }}</td>
                    <td>{{ proceso.status }}</td>
                    <td>{{ proceso.frequency?.description ? proceso.frequency?.description : proceso.frequency?.name  }}</td>
                    <td>
                      <div class="row">
                        <!-- <div class="col" style="cursor: pointer;" *ngIf="proceso.status === 'COMPLETED'" (click)="downloadExec(proce)">
                          <i class="fa fa-download" aria-hidden="true"></i>
                        </div> -->
                        <div class="col" style="cursor: pointer;" (click)="viewExecDetail(proceso.id, proceso.batchConfigId)">
                          <i class="fa fa-search-plus" aria-hidden="true"></i>
                        </div>
                      </div>
                    </td>
                    <!-- <td>
                      <a href="#" class="text-success me-3"><i class="bi bi-cloud-download"></i></a>
                      <a href="#" class="text-primary"><i class="bi bi-search"></i></a>
                    </td> -->
                  </tr>
                </tbody>
              </table>
  
              <!-- Pagination -->
              <nav aria-label="Table pagination">
                <ul class="pagination justify-content-end">
                  <li class="page-item" [class.disabled]="currentPage === 1">
                    <a class="page-link" (click)="previousPage()" aria-label="Previous">
                      <span aria-hidden="true">&laquo; Previous</span>
                    </a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">{{ currentPage }}</a></li>
                  <li class="page-item" [class.disabled]="currentPage === totalPages">
                    <a class="page-link" (click)="nextPage()" aria-label="Next">
                      <span aria-hidden="true">Next &raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  