<!-- <div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div> -->

<ng-container>
    
    <ng-container>
        <div class="row ml-2">

            <p class="text-primary my-4" style="cursor: pointer; text-decoration: underline; float: left;" (click)="back()">Volver</p>
        </div>
    
        <div class="card text-center my-4">
    

            <div class="card-header">
    
                <div class="my-0 row d-flex px-4">
    
                    <span class="font-size">Carga de archivo</span>
                    
                </div>
    
            </div>
    
            <form [formGroup]="formularioArchivo">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group ml-2 my-5">
                
                            <div class="col">
                                <label class="uploadbutton">
                                    <input class="input-file" formControlName="file" type="file" name="file" accept=".csv"
                                        (change)="handleUpload($event)" id="file-csv-carga-automatica"/>
                                    <!-- <div class="button btn">Nueva Carga</div> -->
                                    <!-- <div class='input-file-text' [ngClass]="{'text-success': formularioArchivo.controls.file.value}">
                                        {{formularioArchivo.controls.file.value || 'Subir Archivo'}}
                                    </div> -->
                                </label>
                                <div (click)="submitArchivo()" class="upload-file btn bg-warning" *ngIf="formularioArchivo.controls.file.value">
                                    <span class="material-icons">upload</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <a 
                            id="descargaejemplo"
                            class="text-muted"
                            title="Descargar archivo"
                            href="../assets/files/ejemploCargaMasivaOfertas.csv"
                            download
                        >
                            Descargar archivo de ejemplo
                            <i class="fa fa-download text-success align-middle mx-2" 
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                    <div class="col-12">
                        <span 
                            class="d-inline-block mb-3 text-muted cursor-pointer cursor-pointer-info" 
                            (click)="mostrarModal()"
                            title="Presione para obtener mas información"
                            style="cursor:pointer;"
                            >
                            Archivo de carga debe ser csv con separador ; y tener un máximo de 100 registros
                            <i class="fa fa-exclamation-circle text-warning" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>

            </form>
        </div>
    
    </ng-container>
    
</ng-container>    


<!-- Tabla de resultados -->

<div class="container mt-4" *ngIf="showResultTable">
    <h2 class="mt-5">Ofertas Creadas</h2>
    <table class="table table-striped table-bordered">
      <thead class="thead-info">
        <tr>
            <th>Título</th>
            <th>Tipo</th>
            <th>Monto</th>
            <th>Expiración</th>
            <th>Descripción (intruducción)</th>
            <th>Descripción (detalle)<th>
            <th>Vencimiento</th>
            <th>Interés</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let offer of createdOffers">
            <td>{{ offer.title }}</td>
            <td>{{ offer.type }}</td>
            <td>{{ displayAmount(offer.amount) }}</td>
            <td>{{ getFormattedDate(offer.clientExpTime) }}</td>
            <td>{{ offer.description.introduction || '' }}</td>
            <td>{{ offer.description.body || '' }}<td>
            <td>{{ offer.dueDate }} (meses)</td>
            <td>{{ offer.rate }} (%)</td>
        </tr>
      </tbody>
    </table>
  </div>