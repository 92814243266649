<!-- Article Header -->
<!-- For an image header add the class 'content-header-media' and an image as in the following example -->
<div class="content-header content-header-media">
    <div class="header-section">
        <h1>PROXIMAMENTE<br><small>www.informax.cl</small></h1>
    </div>
</div>
<!-- Article Content -->
<div class="row">
    <div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
        <!-- Article Block -->
        <div class="block block-alt-noborder">
            <!-- Article Content -->
            <article>
                <h3 class="sub-header text-center"><strong>{{title}}</strong></h3>
                <p *ngFor="let content of body">{{content}}</p>
                <div *ngIf="hasImage" class="row push-top-bottom">
                    <div class="col-xs-12">
                        <p>
                            <a href="{{imageSrc}}" data-toggle="lightbox-image">
                                <img src="{{imageSrc}}" alt="image" class="img-responsive">
                            </a>
                        </p>
                    </div>
                </div>
            </article>
            <!-- END Article Content -->
        </div>
        <!-- END Article Block -->


    </div>
</div>