import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { rutValidate } from 'rut-helpers';
import { IDetailsReportParams, ISearchReportParams, ReporteService } from 'src/app/shared/services/reporte.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { AlertService } from 'src/app/components/_alert';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { RUT } from 'src/app/shared/utils/rut';
import { NgxSpinnerService } from 'ngx-spinner';
import { PdfGeneratorService } from 'src/app/shared/services/pdf-generator.service';
import { environment as env } from 'src/environments/environment';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { BasicReportResponse } from 'src/app/models/reporte-buscar/reporte-buscar.model';
import { ResultSearchModel } from 'src/app/models/reportes/reportes.model';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

interface BasicReportFormValues {
  rut: string;
}

@Component({
  selector: 'app-reporte-buscar',
  templateUrl: './reporte-buscar.component.html',
  styleUrls: ['./reporte-buscar.component.scss']
})
export class ReporteBuscarComponent implements OnInit {
  public title = 'Buscar'
  private access: string[] = [ESystemAccess.REPORTEBASICO_BUSCAR, ESystemProfileList.WEB];
  public hasUserAccess = false;

  public searchReportForm: UntypedFormGroup;
  public activateRutHelper = false;
  public today: string = '';

  public searchReportDataSource: BasicReportResponse[] = [];
  public listaReportesFiltrado: any[] = [];
  public showReport = false;
  public reportDataSource: any;

  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public resultSearch: ResultSearchModel = new ResultSearchModel();

  public inputText = '';
  public order = '';
  public campoOrder = '';
  public mapeoEstados: any = {
    "IN-PROGRESS": "En Progreso",
    "COMPLETED": "Completado",
    "ERROR": "Error"
  }

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private _sessionService: SessionService,
    private _reporteService: ReporteService,
    private _generatePDFService: PdfGeneratorService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private utilsService: UtilsService) {
    this.searchReportForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
    }) as FormGroupTyped<BasicReportFormValues>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
    this.setToday();
  }

  onChanges(): void {
    this.searchReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  filtrarObjeto(): void {
    this.currentPage = 0;
    this.listaReportesFiltrado = this.inputText != '' ? this.searchReportDataSource.filter((item: any) => 
    item?.id_transaccion?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.tipo_reporte?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.EstadoTransaccionMapeada?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.FechaFormatted?.toUpperCase().includes(this.inputText.toUpperCase())) : this.searchReportDataSource;
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      if(campo === 'fecha_request'){
        this.listaReportesFiltrado.sort((a, b) => {
          const fechaA: any = new Date(a.fecha_request);
          const fechaB: any = new Date(b.fecha_request);
          return (this.utilsService.dateIsValid(fechaA) ? fechaA : new Date("1000")) - (this.utilsService.dateIsValid(fechaB) ? fechaB : new Date("1000"));
        });
      } else {
        this.listaReportesFiltrado.sort((a, b) => {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return textA.localeCompare(textB);
        });
      }
    } else {
      if(campo === 'fecha_request'){
        this.ordenarDescFecha();
      } else {
        this.listaReportesFiltrado.sort((a, b) => {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return (-1 * textA.localeCompare(textB));
        });
      }
    }
  }

  ordenarDescFecha(): void {
    this.listaReportesFiltrado.sort((a, b) => {
      const fechaA: any = new Date(a.fecha_request);
      const fechaB: any = new Date(b.fecha_request); 
      return (this.utilsService.dateIsValid(fechaB) ? fechaB : new Date("1000")) - (this.utilsService.dateIsValid(fechaA) ? fechaA : new Date("1000"));
    }); 
  }

  public buscarReporte(): void {
    const { rut } = this.searchReportForm.value;
    const searchReportParams: ISearchReportParams = {
      rut: RUT.applyBackendFormat(this.searchReportForm.value.rut),
      idOrganizacion: this._sessionService.getOrganization()
    };

    if (this.searchReportForm.valid) {

      this.spinner.show();
      this._reporteService.searchReport(searchReportParams)
        .subscribe(
          (data: any) => {

            this.searchReportDataSource = data
              .filter((e: any) => e['transaction_status'] === 'COMPLETED')
              .map((item: any) => {
                const fecha = item.fecha_request;
                const newItem = {
                  ...item,
                  //fecha_response: this.utilsService.formatDate(fecha),
                  FechaFormatted: this.utilsService.formatDateHMS(item['fecha_request']),
                  EstadoTransaccionMapeada: item['transaction_status'] ? this.mapeoEstados[item['transaction_status']] : item['transaction_status'],
                }
                return newItem
              });
                            

            if (this.searchReportDataSource.length === 0) {
              this.alertService.warn("Rut sin Información");
            } else {

              this.filtrarObjeto();
              this.ordenarDescFecha();

              const razon = this.utilsService.getNested(
                this.searchReportDataSource[0], 'response', 'InformacionBasica', 'NombreORazonSocial') || '';
              this.resultSearch = new ResultSearchModel(razon, rut);
            }

            // this.clearForm();
            this.spinner.hide();
          },
          ({ error }) => {
            this.alertService.error(error.message);
            this.spinner.hide();
          }
        )
    } else {
      this.alertService.warn("Rut no valido");
    }
  }

  setToday() {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }


  public displayReport(idTransaccion: string): void {
    const detailReportParams: IDetailsReportParams = {
      rut: RUT.applyBackendFormat(this.searchReportForm.value.rut),
      idOrganizacion: this._sessionService.getOrganization(),
      idTransaccion: idTransaccion
    };
    this.spinner.show();
    this._reporteService.detailsReport(detailReportParams).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.reportDataSource = data;
        this.showReport = true;
      },
      ({ error }) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      });
  }

  public closeReport(): void {
    this.reportDataSource = null;
    this.showReport = false;
  }

  public backToSearchForm(): void {
    this.searchReportDataSource = [];
  }

  public clearForm(): void {
    this.searchReportForm.reset();
    this.activateRutHelper = false;
  }

  public async downloadReportSelected(): Promise<void> {
    const transactionId = this.reportDataSource?.InformacionBasica?.IDTransaccion || '';
    const tipoReporte = "basicReport";

    const contentPDF = await this._generatePDFService.downloadReportSelected(transactionId, tipoReporte);
    if (!contentPDF) {
      this.alertService.warn("No se pudo descargar el archivo PDF");
      this.spinner.hide();
      return;
    }

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', contentPDF.URLReporte);
    link.setAttribute('download', `file.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
