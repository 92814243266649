import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserInfoCognito } from 'src/app/shared/services/cognito.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfertasApiService {

  constructor(
    private _http: HttpClient,
    private _sessionService: SessionService  
  ) { }

  private getHeaders(idOrganizacion: string, idUsuario: string, requestOrigin: string): HttpHeaders {
    const headers = new HttpHeaders()
      .set("IDOrganizacion", idOrganizacion)
      .set("IDUsuario", idUsuario)
      .set("requestOrigin", requestOrigin)

    return headers
  }
  public getOfertasList(): Observable<any> {

    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()

    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      .set('idUsuario', idUsuario)
      .set('idOrganizacion', idOrganizacion)
      // .set('idOrganization', idOrganizacion)
      .set('requestOrigin', 'WEB')
    
    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.get<any>(environment.backend.ofertasFinancieras.url + 'listarOfertasCreadas', { params: params, headers: headers  });
  }

  obtenerContratoVerticales() {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()

    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      .set('idUsuario', idUsuario)
      .set('idOrganizacion', idOrganizacion)
      .set('requestOrigin', 'WEB')
    
    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    // Make the HTTP GET request with the parameters
    return this._http.get<any>(environment.backend.ofertasFinancieras.url + 'obtenerVerticalesCliente', { params: params, headers: headers  });
  }

  getUsuariosSincronizados() {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()

    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      .set('idUsuario', idUsuario)
      .set('idOrganizacion', idOrganizacion)
      .set('requestOrigin', 'WEB')

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');
    
      return this._http.get<any>(environment.backend.ofertasFinancieras.url + 'listarUsuariosSincronizados', { params: params, headers: headers  });
  }

  crearOferta(body: any) {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()

    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      .set('idUsuario', idUsuario)
      .set('idOrganizacion', idOrganizacion)
      .set('requestOrigin', 'WEB')

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.post<any>(environment.backend.ofertasFinancieras.url + 'crearEditarOferta', body, { params: params, headers: headers  });
  }

  getOfertaById(id: string) {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()

    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      .set('idUsuario', idUsuario)
      .set('idOrganizacionCliente', idOrganizacion)
      .set('requestOrigin', 'WEB')
      .set('idOferta', id)

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.get<any>(environment.backend.ofertasFinancieras.url + 'obtenerOferta', { params: params, headers: headers  });
  }

  deleteOffer(idOffer: string, idVertical: string) {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()

    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      .set('idUsuario', idUsuario)
      .set('idOrganizacion', idOrganizacion)
      .set('requestOrigin', 'WEB')

    const body = {
      idOferta: idOffer,
      idVertical: idVertical,
      idOrganizacion: idOrganizacion,
      idUsuario: idUsuario,
      requestOrigin: 'WEB'
    }

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.post<any>(environment.backend.ofertasFinancieras.url + 'borrarOferta', body, { params: params, headers: headers  });
  }

  altaMasivaOfertas(idUsuario: string, idOrganizacion: string, csv: string) {
    const body = {
      "idUsuario": idUsuario,
      "idOrganizacion": idOrganizacion,
      "requestOrigin": "WEB",
      "csv": csv
    }
    
    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.post<any>(environment.backend.ofertasFinancieras.url + 'cargaMasiva', body, { headers: headers  });    
  }
}
