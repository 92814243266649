<div class="modal-content">
    <div class="modal-header">
        <h4>{{confirmData.title || 'Confirm'}}</h4>
    </div>
    <form (ngSubmit)="submit()" [formGroup]="modalPassForm">
    <div class="modal-body" [ngClass]="confirmData.dynamicClass? confirmData.dynamicClass: ''">
        <p>
           Ingrese Nueva Contraseña
        </p>
            <div class="form-group">
                <input formControlName="newPassword" id="password" type="password" class="form-control" required>
                <app-error [control]="modalPassForm.get('newPassword')"></app-error>
                <span class="mt-1"> <!--showSpanNewPass()-->
                    <p class="mb-0" [ngClass]="validaNewPass() ? 'color-success' : 'color-error'" >La contraseña debe tener:</p>
                    <p class="mb-0" [ngClass]="validaLargo() ? 'color-success' : 'color-error'" >Largo Mínimo 15, Largo Máximo 17</p>
                    <p class="mb-0" [ngClass]="validaCaracteres() ? 'color-success' : 'color-error'" >Se requieren caracteres especiales (+,-;.$?¿¡)</p>
                    <p class="mb-0" [ngClass]="validaMayus() ? 'color-success' : 'color-error'" >Se requieren letras mayúsculas</p>
                    <p class="mb-0" [ngClass]="validaMin() ? 'color-success' : 'color-error'" >Se requieren letras minúsculas</p>
                    <p [ngClass]="validaNum() ? 'color-success' : 'color-error'" >Se requieren números</p>
                </span>
            </div>
        <p>
            Repetir Nueva Contraseña
        </p>
            <div class="form-group">
                <input formControlName="confirmPassword" id="confirmPassword" type="password" class="form-control" required>
                <span *ngIf="showSpanConfirmPass()" class="text-danger">
                    Las contraseñas deben ser iguales
                </span>
                <app-error [control]="modalPassForm.get('confirmPassword')">
                </app-error>
            </div>
       
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="closeModal()" *ngIf="!confirmData.hideCancelmButtom">
            {{confirmData.textBoton && confirmData.textBoton.length > 0? confirmData.textBoton[1]: 'Cancel'}}
        </button>
        <button type="submit" class="btn btn-primary" [disabled]="!validaPass()">
            {{confirmData.textBoton && confirmData.textBoton.length > 0? confirmData.textBoton[0]: 'Continuar'}}
        </button>
    </div>
    </form>
</div>
