<div class="card mt-3">
    <h5 class="card-header list-group-item cuadro-color">
        Detalle del negocio
    </h5>
    <div class="card-body">
        <ng-container *ngIf="fieldsReady">

            <form [formGroup]="condicionesVentasForm" (ngSubmit)="onSubmit()">
                <div class="row">

                    <ng-container *ngFor="let field of camposPersonalizados">

                        <div class="col-sm-6" *ngIf="searchDoubleFields(field).length !== 3">
                            <ng-container *ngIf="validarIfExist(field)">

                                <div class="row" *ngIf="searchDoubleFields(field).length !== 3">
                                    <div class="col-sm-4">
                                        <ng-template #montoConintereses>
                                            <ng-template #textoConInteres>
                                                Monto Total con Intereses
                                            </ng-template>
                                            
                                            <ng-container *ngIf="filterByControl(field)?.nameAttribute !== 'Monto Total Interes'; else textoConInteres">
                                                {{filterByControl(field)?.nameAttribute}}
                                            </ng-container>
                                        </ng-template>
                                        <ng-container
                                            *ngIf="tipoSolicitud !== 'ventaNueva'; else montoConintereses">
                                            {{filterByControl(field)?.nameAttribute}}
                                        </ng-container>
                                    </div>

                                    <ng-container [ngSwitch]="searchDoubleFields(field).length">
                                        <ng-template [ngSwitchCase]="0">
                                            <div class="col-sm-8">
                                                <ng-template #selectTypeFieldFirst>
                                                    <ng-container [ngSwitch]="filterByControl(field)?.typeAttibute">
                                                        <ng-template [ngSwitchCase]="'String'">
                                                            <input type="text" class="form-control"
                                                                [formControlName]="field">
                                                        </ng-template>
                                                        <ng-template ngSwitchDefault>
                                                            <ng-template #TasaMensual>
                                                                <div class="input-group min-colunm">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text">%</span>
                                                                    </div>
                                                                    <input type="text" class="form-control"
                                                                        mask="separator.2" thousandSeparator=""
                                                                        separatorLimit="100" [formControlName]="field">
                                                                </div>
                                                            </ng-template>

                                                            <ng-container
                                                                *ngIf="field !== 'TasaMensual'; else TasaMensual">
                                                                <input type="text" class="form-control" mask="separator"
                                                                    thousandSeparator="." [formControlName]="field">
                                                            </ng-container>

                                                        </ng-template>
                                                    </ng-container>
                                                </ng-template>

                                                <ng-container
                                                    *ngIf="objectKeys(filterByControl(field)?.allowedValues).length > 0; else selectTypeFieldFirst">
                                                    <select class="form-control text-capitalize"
                                                        [formControlName]="field">
                                                        <ng-template #ventaNuevaFirst>
                                                            <ng-container [ngSwitch]="field">
                                                                <ng-template [ngSwitchCase]="'TipoSeguro'">
                                                                    <ng-container
                                                                        *ngFor="let item of objectKeys(filterByControl(field)?.allowedValues); let i = index">
                                                                        <option [value]="item" *ngIf="item !== '1'">
                                                                            {{filterByControl(field)?.allowedValues[item]}}
                                                                        </option>
                                                                    </ng-container>
                                                                </ng-template>
                                                                <ng-template ngSwitchDefault>
                                                                    <ng-container
                                                                        *ngFor="let item of objectKeys(filterByControl(field)?.allowedValues); let i = index">
                                                                        <option [value]="item">
                                                                            {{filterByControl(field)?.allowedValues[item]}}
                                                                        </option>
                                                                    </ng-container>
                                                                </ng-template>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngFor="let item of objectKeys(filterByControl(field)?.allowedValues); let i = index">
                                                                <option [value]="item" *ngIf="">
                                                                    {{filterByControl(field)?.allowedValues[item]}}
                                                                </option>
                                                            </ng-container>
                                                        </ng-template>

                                                        <ng-container
                                                            *ngIf="tipoSolicitud !== 'ventaNueva'; else ventaNuevaFirst">
                                                            <option
                                                                *ngFor="let item of objectKeys(filterByControl(field)?.allowedValues); let i = index"
                                                                [value]="item">
                                                                {{filterByControl(field)?.allowedValues[item]}}</option>
                                                        </ng-container>


                                                    </select>
                                                </ng-container>

                                                <app-error [control]="condicionesVentasForm.get(field)">
                                                </app-error>
                                            </div>
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="2">
                                            <div class="col-sm-4">
                                                <select class="form-control text-capitalize"
                                                    [formControlName]="filterByControl(searchDoubleFields(field)[0])?.nameAttribute">
                                                    <option value=""
                                                        *ngFor="let item of objectKeys(filterByControl(searchDoubleFields(field)[0])?.allowedValues); let i = index"
                                                        [value]="item">
                                                        {{item}}</option>
                                                </select>
                                                <app-error
                                                    [control]="condicionesVentasForm.get(filterByControl(searchDoubleFields(field)[0])?.nameAttribute)">
                                                </app-error>
                                            </div>

                                            <div class="col-sm-4">
                                                <div class="text-uppercase form-control" disabled>
                                                    {{findTextCamposPersonalizados(field)}}
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="4">
                                            <div class="col-sm-4">
                                                <div class="input-group min-colunm">
                                                    <div class="input-group-prepend">
                                                        <span
                                                            class="input-group-text">{{searchDoubleFields(field)[0]}}</span>
                                                    </div>
                                                    <ng-template #selectTypeFieldSecond>
                                                        <ng-container
                                                            [ngSwitch]="filterByControl(searchDoubleFields(field)[1])?.typeAttibute">
                                                            <ng-template [ngSwitchCase]="'String'">
                                                                <input type="text" class="form-control"
                                                                    [formControlName]="searchDoubleFields(field)[1]">
                                                            </ng-template>
                                                            <ng-template ngSwitchDefault>
                                                                <input type="text" class="form-control" mask="separator"
                                                                    thousandSeparator="."
                                                                    [formControlName]="searchDoubleFields(field)[1]">
                                                            </ng-template>
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-container
                                                        *ngIf="objectKeys(filterByControl(searchDoubleFields(field)[1])?.allowedValues).length > 0; else selectTypeFieldSecond">
                                                        <select class="form-control text-capitalize"
                                                            [formControlName]="searchDoubleFields(field)[1]">
                                                            <option value="" class="text-capitalize"
                                                                *ngFor="let item of objectKeys(filterByControl(searchDoubleFields(field)[1])?.allowedValues); let i = index"
                                                                [value]="item">
                                                                {{filterByControl(searchDoubleFields(field)[1])?.allowedValues[item]}}
                                                            </option>
                                                        </select>
                                                    </ng-container>
                                                </div>
                                                <app-error
                                                    [control]="condicionesVentasForm.get(searchDoubleFields(field)[1])">
                                                </app-error>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="input-group min-colunm">
                                                    <div class="input-group-prepend">
                                                        <span
                                                            class="input-group-text">{{searchDoubleFields(field)[2]}}</span>
                                                    </div>
                                                    <ng-template #selectTypeFieldThird>
                                                        <ng-container
                                                            [ngSwitch]="filterByControl(searchDoubleFields(field)[3])?.typeAttibute">
                                                            <ng-template [ngSwitchCase]="'String'">
                                                                <input type="text" class="form-control"
                                                                    [formControlName]="searchDoubleFields(field)[3]">
                                                            </ng-template>
                                                            <ng-template ngSwitchDefault>
                                                                <input type="text" class="form-control" mask="separator"
                                                                    thousandSeparator="."
                                                                    [formControlName]="searchDoubleFields(field)[3]">
                                                            </ng-template>
                                                        </ng-container>
                                                    </ng-template>

                                                    <ng-container
                                                        *ngIf="objectKeys(filterByControl(searchDoubleFields(field)[3])?.allowedValues).length > 0; else selectTypeFieldThird">
                                                        <select class="form-control text-capitalize"
                                                            [formControlName]="searchDoubleFields(field)[3]">
                                                            <option value="" class="text-capitalize"
                                                                *ngFor="let item of objectKeys(filterByControl(searchDoubleFields(field)[3])?.allowedValues); let i = index"
                                                                [value]="item">
                                                                {{filterByControl(searchDoubleFields(field)[3])?.allowedValues[item]}}
                                                            </option>
                                                        </select>
                                                    </ng-container>
                                                </div>
                                                <app-error
                                                    [control]="condicionesVentasForm.get(searchDoubleFields(field)[3])">
                                                </app-error>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <div class="col-12 mt-5">
                    <div class="form-group form-actions container__actions text-center">
                        <button type="submit" class="btn btn-sm" [ngClass]="[!this.condicionVenta? 'btn-primary': 'btn-warning']">
                            {{!this.condicionVenta? 'Continuar': 'Guardar Cambios'}}
                        </button>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>