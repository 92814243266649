<div class="row">
    <div class="col-12 my-3">

        <button class="btn" [ngClass]="verModulo ? 'btn-primary' : 'btn-outline-primary'" (click)="cambiarVerModulo()">
            Documentos <i class="fa fa-folder-open" aria-hidden="true"></i>
        </button>

        <div class="mt-4" [ngClass]="{'d-none': !verModulo}">

            <app-alert-message *ngFor="let message of messages; let i = index"
                [message]="message.text"
                [isError]="message.isError"
                [isSuccess]="message.isSuccess"
                (close)="removeMessage(i)"
            ></app-alert-message>

            <div class="row" *ngIf="!closed && listaArchivos.length < 10">
                <div class="col-sm-11 mx-auto">
                    <form [formGroup]="formulario">
                        <div class="form-group" *ngIf="!fileName; else fileUpload1">
        
                            <label class="uploadbutton">
                                <input class="input-file" formControlName="file" type="file" name="file" accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .pdf, application/pdf, .xls, application/vnd.ms-excel, .csv, text/csv, .txt, text/plain, .png, image/png, .jpeg, image/jpeg, .jpg, image/jpeg"
                                    (change)="handleUpload($event)" />
                                <div class="button btn">Cargar Archivo</div>
                                <div class='input-file-text' [ngClass]="{'text-success': formulario.controls.file.value}">
                                    {{formulario.controls.file.value || 'Subir Archivo'}}
                                </div>
                            </label>
                            <div (click)="submit()" class="upload-file btn bg-warning" *ngIf="formulario.controls.file.value">
                                <span class="material-icons">upload</span>
                            </div>
                        </div>
                        <ng-template class="file-upload" #fileUpload1>
                            Su archivo fue subido con exito
                        </ng-template>
                    </form>
                </div>
            </div>

            <div class="row" *ngIf="listaArchivosDelete.length > 0 && !closed">
                <div class="col-sm-12 col-md-8 text-right mx-auto">
                    <button class="btn btn-outline-danger" (click)="borrarListado()">
                        <i class="fa fa-trash mr-2" aria-hidden="true"></i> Borrar Selección
                    </button>
                </div>
            </div>

            <div class="justify-content-center row">
                <div class="col-sm-12 col-md-8 col-lg-6 mx-auto mt-5 mb-5" *ngIf="listaArchivos.length > 0">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th class="bg-azul text-center" colspan="5">
                                        Archivos Guardados
                                    </th>
                                </tr>
                                <tr>
                                    <th class="bg-azul" *ngIf="!closed"></th>
                                    <th class="bg-azul">
                                        Nombre Archivo
                                    </th>
                                    <th class="bg-azul"></th>
                                </tr>
                            <tbody>
                                <ng-container *ngFor="let item of listaArchivos">
                                    <tr>
                                        <td class="text-center" *ngIf="!closed">
                                            <input class="pointer-cursor" type="checkbox" (click)="agregarBorrarArchivo($event, item?.fileName)">
                                        </td>
                                        <td>
                                            {{item?.fileName || ''}}
                                        </td>
                                        <td class="text-center">
                                            <ng-container *ngIf="item?.fileUrl">
                                                <a [href]="item.fileUrl" target="_blank">
                                                    <i class="fa fa-download text-success" aria-hidden="true"></i>
                                                </a>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
