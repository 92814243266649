<div class="panel panel-default border-0 not-print">
    <div class="panel-body">
        <div class="btn-option">
            <button *ngIf="showReportes && stepper === 5" class="btn btn-primary float-right" (click)="descargarPdf()">Descargar PDF</button>
            <a *ngIf="showReportes && stepper === 5" class="d-none" target="_blank" id="button-download"></a>
        </div>
    </div>
  </div>
  
  <div class="not-print">
  
    <ul class="nav nav-tabs d-flex justify-content-center border-0 mt-3">
      <li><button id="button-balance-automaticos" (click)="scroll('balance-automaticos', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
          <i class="fa fa-bar-chart" aria-hidden="true"></i>
          Balance Automáticos
        </button>
      </li>
    </ul>
  
  </div>
  
  <div class="col-sm-12" id="print-section">
    <div class="row p-3">
      <div class="col-sm-12">
          <div class="row">
              <h3 class="col-12 my-2 mb-4 cuadro-title p-3 rounded-header">
                  Ficha Comité de Crédito
              </h3>
              <form [formGroup]="servicioFichaForm">
  
                <ng-template #fichaCabecera>
  
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Nombre</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.nombreComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Fecha</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.fechaComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Actividad Económica</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.giroComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Rut</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.rutComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Fecha inicio Act SII</label>
                        <div class="col-12">
                        <span>{{servicioFichaForm?.controls?.inicioSiiComite?.value || ''}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Tipo Moneda</label>
                        <div class="col-12">
                        <span>
                          {{ servicioFichaForm?.controls?.moneda?.value || '' }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group row">
                        <label class="col-12 col-form-label">Monto Solicitado</label>
                        <div class="col-12">
                        <span>
                          {{( servicioFichaForm?.controls?.montoSolicitado?.value !== '' && servicioFichaForm?.controls?.montoSolicitado?.value !== '-')? 
                            (servicioFichaForm?.controls?.montoSolicitado?.value | number: '1.0-2') : 
                            (servicioFichaForm?.controls?.montoSolicitado?.value) }}
                        </span>
                      </div>
                    </div>
                  </div>
                  
                </ng-template>
  
                <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else fichaCabecera">
  
                  <div class="col-sm-12 col-md-8">
                      <div class="form-group row">
                          <label for="nombre-comite" class="col-12 col-form-label">Nombre</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="nombre-comite" formControlName="nombreComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('nombreComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="fecha-comite" class="col-12 col-form-label">Fecha</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="fecha-comite" formControlName="fechaComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('fechaComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
        
                  <div class="col-sm-12 col-md-8">
                      <div class="form-group row">
                          <label for="giro-comite" class="col-12 col-form-label">Actividad Económica</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="giro-comite" formControlName="giroComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('giroComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
        
                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="rut-comite" class="col-12 col-form-label">Rut</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="rut-comite" formControlName="rutComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('rutComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>

                  <div class="col-sm-12 col-md-4">
                      <div class="form-group row">
                          <label for="inicioSii-comite" class="col-12 col-form-label">Fecha inicio Act SII</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="inicioSii-comite" formControlName="inicioSiiComite">
                            <div class="w-100">
                              <app-error [control]="servicioFichaForm.get('inicioSiiComite')"></app-error>
                            </div>
                          </div>
                      </div>
                  </div>

                  <div class="col-sm-12 col-md-4">
                    <div class="form-group row">
                        <label for="moneda-comite" class="col-12 col-form-label">Tipo Moneda</label>
                        <div class="col-12">
                          <input type="text" class="form-control" id="moneda-comite" formControlName="moneda">
                          <div class="w-100">
                            <app-error [control]="servicioFichaForm.get('moneda')"></app-error>
                          </div>
                        </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-8">
                    <div class="form-group row">
                        <label for="montoSolicitado-comite" class="col-12 col-form-label">Monto Solicitado</label>
                        <div class="col-12">
                          <input type="text" class="form-control" id="montoSolicitado-comite" formControlName="montoSolicitado"
                            [value]="(servicioFichaForm.get('montoSolicitado')?.value !== '' && servicioFichaForm.get('montoSolicitado')?.value !== '-')? 
                            (servicioFichaForm.get('montoSolicitado')?.value | number: '1.0-2') : 
                            (servicioFichaForm.get('montoSolicitado')?.value)"
                          >
                          <div class="w-100">
                            <app-error [control]="servicioFichaForm.get('montoSolicitado')"></app-error>
                          </div>
                        </div>
                    </div>
                  </div>
  
                </ng-container>
  
              </form>
          </div>
      </div>
    </div>

    <div class="row p-3">

      <div class="col-sm-6 col-md-4">
        <div class="table-responsive" *ngIf="showReportes && informacionFinanciera?.valorDolar && informacionFinanciera?.fechaValorDolar">
          <table class="table table-hover table-bordered">
            <tbody>
              <tr>
                  <th><strong>Valor del Dólar</strong></th>
                  <td>{{ informacionFinanciera?.valorDolar | number: '1.0-2' || "" }}</td>
              </tr>
              <tr>
                <th><strong>Fecha de Valor del Dólar</strong></th>
                <td>{{informacionFinanciera?.fechaValorDolar}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
      <div class="col-sm-12">
        <div class="table-responsive" *ngIf="showReportes">
          <table class="table table-hover table-bordered">
              <thead>
                  <tr>
                      <th scope="col" class="py-1 bg-blue font-head-size" colspan="1">Periodo:</th>
                      <th scope="col" class="py-1 font-head-size" colspan="3">{{ informacionFinanciera?.periodo || "" }}</th>
                  </tr>
                  <tr>
                      <th scope="col" class="py-1 bg-blue font-head-size" colspan="2">Parametros</th>
                      <th scope="col" class="py-1 bg-blue font-head-size">Monto MUSD</th>
                      <th scope="col" width="10%" class="py-1 bg-blue font-head-size">Cumple Si/No</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <th>Ingresos</th>
                      <td>> Solicitud</td>
                      <td>{{ informacionFinanciera?.ingresos?.monto | number: '1.0-2' || "" }}</td>
                      <td class="text-center print-cell">
                        <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.ingresos?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.ingresos?.cumple === 'No' }">
                          {{ informacionFinanciera?.ingresos?.cumple || "" }}
                        </span>
                      </td>
                  </tr>
                  <tr>
                      <th>Patrimonio Positivo</th>
                      <td>> Solicitud</td>
                      <td>{{ informacionFinanciera?.patrimonio?.monto | number: '1.0-2' || "" }}</td>
                      <td class="text-center print-cell">
                        <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.patrimonio?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.patrimonio?.cumple === 'No' }">
                          {{ informacionFinanciera?.patrimonio?.cumple || "" }}
                        </span>
                      </td>
                  </tr>
                  <tr>
                      <th>Capital Social</th>
                      <td>Informativo</td>
                      <td>{{ informacionFinanciera?.capital?.monto | number: '1.0-2' || "" }}</td>
                      <td class="text-center print-cell">
                        <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.capital?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.capital?.cumple === 'No' }">
                          {{ informacionFinanciera?.capital?.cumple || "" }}
                        </span>
                      </td>
                  </tr>
                  <tr>
                      <th>Margen de Explotación</th>
                      <td>Positivo</td>
                      <td>{{ informacionFinanciera?.margen?.monto | number: '1.0-2' || "" }}</td>
                      <td class="text-center print-cell">
                        <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.margen?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.margen?.cumple === 'No' }">
                          {{ informacionFinanciera?.margen?.cumple || "" }}
                        </span>
                      </td>
                  </tr>
                  <tr>
                      <th>EBITDA</th>
                      <td>Positivo</td>
                      <td>{{ informacionFinanciera?.ebitda?.monto | number: '1.0-2' || "" }}</td>
                      <td class="text-center print-cell">
                        <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.ebitda?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.ebitda?.cumple === 'No' }">
                          {{ informacionFinanciera?.ebitda?.cumple || "" }}
                        </span>
                      </td>
                  </tr>
                  <tr>
                      <th>Fondo de Maniobra</th>
                      <td>Positivo</td>
                      <td>{{ informacionFinanciera?.maniobra?.monto | number: '1.0-2' || "" }}</td>
                      <td class="text-center print-cell">
                        <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.maniobra?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.maniobra?.cumple === 'No' }">
                          {{ informacionFinanciera?.maniobra?.cumple || "" }}
                        </span>
                      </td>
                  </tr>
                  <tr>
                      <th scope="col" class="py-1 bg-blue font-head-size"></th>
                      <th scope="col" class="py-1 bg-blue font-head-size"></th>
                      <th scope="col" class="py-1 bg-blue font-head-size">Ratios</th>
                      <th scope="col" class="py-1 bg-blue font-head-size"></th>
                  </tr>

                  <ng-container *ngIf="!informacionFinanciera.construccion; else elseConstruccion">

                      <tr>
                          <th>Liquidez Corriente</th>
                          <td> > 1 </td>
                          <td>{{ informacionFinanciera?.liquidez?.monto | number: '1.0-2' || "" }}</td>
                          <td class="text-center print-cell">
                            <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.liquidez?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.liquidez?.cumple === 'No' }">
                              {{ informacionFinanciera?.liquidez?.cumple || "" }}
                            </span>
                          </td>
                      </tr>
                      <tr>
                          <th>Endeudamiento (Deuda/Activo Total)</th>
                          <td> < 60% </td>
                          <td>{{ informacionFinanciera?.endeudamiento?.monto ? ((informacionFinanciera?.endeudamiento?.monto | number: '1.0-2') + '%') : "" }}</td>
                          <td class="text-center print-cell">
                            <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.endeudamiento?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.endeudamiento?.cumple === 'No' }">
                              {{ informacionFinanciera?.endeudamiento?.cumple || "" }}
                            </span>
                          </td>
                      </tr>
                      <tr>
                          <th>DFN / EBITDA</th>
                          <td> < 3,5 </td>
                          <td>{{ informacionFinanciera?.dfnEbitda?.monto | number: '1.0-2' || "" }}</td>
                          <td class="text-center print-cell">
                            <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.dfnEbitda?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.dfnEbitda?.cumple === 'No' }">
                              {{ informacionFinanciera?.dfnEbitda?.cumple || "" }}
                            </span>
                          </td>
                      </tr>
                      <tr>
                          <th>Ebitda/gastos financieros</th>
                          <td> > 1</td>
                          <td>{{ informacionFinanciera?.ebitdaGastos?.monto | number: '1.0-2' || "" }}</td>
                          <td class="text-center print-cell">
                            <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.ebitdaGastos?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.ebitdaGastos?.cumple === 'No' }">
                              {{ informacionFinanciera?.ebitdaGastos?.cumple || "" }}
                            </span>
                          </td>
                      </tr>
                      <tr>
                          <th>DFN / Patrimonio Neto</th>
                          <td> < 100% </td>
                          <td>{{ informacionFinanciera?.dfn?.monto ? ((informacionFinanciera?.dfn?.monto | number: '1.0-2') + '%') : "" }}</td>
                          <td class="text-center print-cell">
                            <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.dfn?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.dfn?.cumple === 'No' }">
                              {{ informacionFinanciera?.dfn?.cumple || "" }}
                            </span>
                          </td>
                      </tr>
                      <tr>
                          <th>Total pasivos/Patrimonio</th>
                          <td> < 1,5 </td>
                          <td>{{ informacionFinanciera?.total?.monto | number: '1.0-2' || "" }}</td>
                          <td class="text-center print-cell">
                            <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.total?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.total?.cumple === 'No' }">
                              {{ informacionFinanciera?.total?.cumple || "" }}
                            </span>
                          </td>
                      </tr>
                      
                  </ng-container>

                  <ng-template #elseConstruccion>
                      
                      <tr>
                          <th>Liquidez Corriente</th>
                          <td> > 1 </td>
                          <td>{{ informacionFinanciera?.liquidez?.monto | number: '1.0-2' || "" }}</td>
                          <td class="text-center print-cell">
                            <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.liquidez?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.liquidez?.cumple === 'No' }">
                              {{ informacionFinanciera?.liquidez?.cumple || "" }}
                            </span>
                          </td>
                      </tr>
                      <tr>
                          <th>Endeudamiento (Deuda/Activo Total)</th>
                          <td> < 40% </td>
                          <td>{{ informacionFinanciera?.endeudamiento?.monto ? ((informacionFinanciera?.endeudamiento?.monto | number: '1.0-2') + '%') : "" }}</td>
                          <td class="text-center print-cell">
                            <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.endeudamiento?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.endeudamiento?.cumple === 'No' }">
                              {{ informacionFinanciera?.endeudamiento?.cumple || "" }}
                            </span>
                          </td>
                      </tr>
                      <tr>
                          <th>DFN / EBITDA</th>
                          <td> < 3 </td>
                          <td>{{ informacionFinanciera?.dfnEbitda?.monto | number: '1.0-2' || "" }}</td>
                          <td class="text-center print-cell">
                            <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.dfnEbitda?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.dfnEbitda?.cumple === 'No' }">
                              {{ informacionFinanciera?.dfnEbitda?.cumple || "" }}
                            </span>
                          </td>
                      </tr>
                      <tr>
                          <th>Ebitda/gastos financieros</th>
                          <td> > 1,5 </td>
                          <td>{{ informacionFinanciera?.ebitdaGastos?.monto | number: '1.0-2' || "" }}</td>
                          <td class="text-center print-cell">
                            <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.ebitdaGastos?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.ebitdaGastos?.cumple === 'No' }">
                              {{ informacionFinanciera?.ebitdaGastos?.cumple || "" }}
                            </span>
                          </td>
                      </tr>
                      <tr>
                          <th>DFN / Patrimonio Neto</th>
                          <td> < 80% </td>
                          <td>{{ informacionFinanciera?.dfn?.monto ? ((informacionFinanciera?.dfn?.monto | number: '1.0-2') + '%') : "" }}</td>
                          <td class="text-center print-cell">
                            <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.dfn?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.dfn?.cumple === 'No' }">
                              {{ informacionFinanciera?.dfn?.cumple || "" }}
                            </span>
                          </td>
                      </tr>
                      <tr>
                          <th>Total pasivos/Patrimonio</th>
                          <td> < 1,5 </td>
                          <td>{{ informacionFinanciera?.total?.monto | number: '1.0-2' || "" }}</td>
                          <td class="text-center print-cell">
                            <span class="rounded-circle px-3 py-2" [ngClass]="{'cumple-cell': informacionFinanciera?.total?.cumple === 'Si', 'no-cumple-cell' : informacionFinanciera?.total?.cumple === 'No' }">
                              {{ informacionFinanciera?.total?.cumple || "" }}
                            </span>
                          </td>
                      </tr>

                  </ng-template>
                  
                  
              </tbody>
          </table>
        </div>
      </div>

      <div class="col-sm-12 mt-5" *ngIf="showReportes && totalesInformacionFinanciera && informacionFinancieraTable.length > 0">
        <div class="table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
              <tr>
                <th scope="col" class="text-center bg-blue font-head-size">Año</th>
                <ng-container *ngIf="totalesInformacionFinanciera.totalAnterior.anio">
                  <th scope="col" class="text-center bg-blue font-head-size" colspan="5">{{totalesInformacionFinanciera.totalAnterior.anio}}</th>
                  <th scope="col" class="text-center bg-blue font-head-size" rowspan="2">Posterga</th>
                </ng-container>
                <ng-container *ngIf="totalesInformacionFinanciera.totalActual.anio">
                  <th scope="col" class="text-center bg-blue font-head-size" colspan="5">{{totalesInformacionFinanciera.totalActual.anio}}</th>
                  <th scope="col" class="text-center bg-blue font-head-size" rowspan="2">Posterga</th>
                </ng-container>
              </tr>
              <tr>
                <th scope="col" class="text-center bg-blue font-head-size">Meses</th>
                <ng-container *ngIf="totalesInformacionFinanciera.totalAnterior.anio">
                  <th scope="col" class="text-center bg-blue font-head-size">Ventas (M$)</th>
                  <th scope="col" class="text-center bg-blue font-head-size">Compras (M$)</th>
                  <th scope="col" class="text-center bg-blue font-head-size">Diferencia (M$)</th>
                  <th scope="col" class="text-center bg-blue font-head-size">Fecha Pago</th>
                  <th scope="col" class="text-center bg-blue font-head-size">Tipo Declaración</th>
                </ng-container>
                
                <ng-container *ngIf="totalesInformacionFinanciera.totalActual.anio">
                  <th scope="col" class="text-center bg-blue font-head-size">Ventas (M$)</th>
                  <th scope="col" class="text-center bg-blue font-head-size">Compras (M$)</th>
                  <th scope="col" class="text-center bg-blue font-head-size">Diferencia (M$)</th>
                  <th scope="col" class="text-center bg-blue font-head-size">Fecha Pago</th>
                  <th scope="col" class="text-center bg-blue font-head-size">Tipo Declaración</th>               
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of informacionFinancieraTable; let i = index">
                <tr>
                  <td>{{item?.anterior?.mes || item?.actual?.mes || ''}}</td>
                  <td>{{item?.anterior?.ventas | number: '1.0-2'}}</td>
                  <td>{{item?.anterior?.compras | number: '1.0-2'}}</td>
                  <td>{{item?.anterior?.diferencia | number: '1.0-2'}}</td>
                  <td>{{item?.anterior?.fechaPago || ''}}</td>
                  <td>{{item?.anterior?.tipoDeclaracion || ''}}</td>
                  <td>{{item?.anterior?.posterga | number: '1.0-2'}}</td>
                  <td>{{item?.actual?.ventas | number: '1.0-2'}}</td>
                  <td>{{item?.actual?.compras | number: '1.0-2'}}</td>
                  <td>{{item?.actual?.diferencia | number: '1.0-2'}}</td>
                  <td>{{item?.actual?.fechaPago || ''}}</td>
                  <td>{{item?.actual?.tipoDeclaracion || ''}}</td>
                  <td>{{item?.actual?.posterga | number: '1.0-2'}}</td>
                </tr>

              </ng-container>
              <tr>
                <th class="bg-blue">Total Año</th>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.ventas | number: '1.0-2'}}</td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.compras | number: '1.0-2'}}</td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.dif | number: '1.0-2'}}</td>
                <td class="bg-blue"></td>
                <td class="bg-blue"></td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.posterga | number: '1.0-2'}}</td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.ventas | number: '1.0-2'}}</td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.compras | number: '1.0-2'}}</td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.dif | number: '1.0-2'}}</td>
                <td class="bg-blue"></td>
                <td class="bg-blue"></td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.posterga | number: '1.0-2'}}</td>
              </tr>
              <tr>
                <th class="bg-blue">Promedio</th>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.ventas | number: '1.0-2'}}</td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.compras | number: '1.0-2'}}</td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.dif | number: '1.0-2'}}</td>
                <td class="bg-blue"></td>
                <td class="bg-blue"></td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.posterga | number: '1.0-2'}}</td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.ventas | number: '1.0-2'}}</td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.compras | number: '1.0-2'}}</td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.dif | number: '1.0-2'}}</td>
                <td class="bg-blue"></td>
                <td class="bg-blue"></td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.posterga | number: '1.0-2'}}</td>
              </tr>
              <tr>
                <th class="bg-blue">Margen %</th>
                <td class="bg-blue"></td>
                <td class="bg-blue"></td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.margen | number: '1.0-2'}}</td>
                <td class="bg-blue"></td>
                <td class="bg-blue"></td>
                <td class="bg-blue"></td>
                <td class="bg-blue"></td>
                <td class="bg-blue"></td>
                <td class="bg-blue">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.margen | number: '1.0-2'}}</td>
                <td class="bg-blue"></td>
                <td class="bg-blue"></td>
                <td class="bg-blue"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
      
    
    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak" (click)="show('informacion-sociedad')">
      Información Sociedad
    </h3>
    <div class="container-desplegables" id="informacion-sociedad">
      <div class="row p-5">

        <div class="col-sm-12">
          <div class="table-responsive" *ngIf="showReportes">
            <table class="table table-hover table-bordered border">
                <thead>
                  <tr>
                    <th scope="col" colspan="6" class="text-center py-1 bg-blue font-head-size">Informe Comercial Rut Titular</th>
                  </tr>
                  <tr>
                    <th scope="col" class="py-1 bg-blue font-head-size">N°</th>
                    <th scope="col" class="py-1 bg-blue font-head-size">Monto M$</th>
                    <th scope="col" class="py-1 bg-blue font-head-size">Motivo</th>
                    <th scope="col" class="py-1 bg-blue font-head-size">Morosidad SBIF</th>
                    <th scope="col" class="py-1 bg-blue font-head-size">Deuda Total</th>
                    <th scope="col" class="py-1 bg-blue font-head-size">Mes Año</th>
                  </tr>
                </thead>
                <tbody>
    
                <ng-template #sinDatosTitularInforme>
                  <tr class="height-row">
                    <td colspan="6">Sin información</td>
                  </tr>
                </ng-template>
  
                <ng-container *ngIf="titularInforme.length > 0 else sinDatosTitularInforme">
  
                  <ng-container *ngFor="let item of titularInforme; let i = index">
                    <tr class="height-row pointer-cursor" (click)="abrirModalTitular()">
                      <td>{{titularInforme[i].numero | number: '1.0-0'}}</td>
                      <td>{{titularInforme[i].monto | number: '1.0-2'}}</td>
                      <td>{{titularInforme[i].motivo}}</td>
                      <td>{{titularInforme[i].morosidad || 0 | number: '1.0-2'}}</td>
                      <td>{{titularInforme[i].deudaTotal || 0 | number: '1.0-2'}}</td>
                      <td>{{titularInforme[i].mesAnio || ''}}</td>
                    </tr>
                  </ng-container>
                  
                </ng-container>
  
              </tbody>
            </table>
          </div>
        </div>


        <div class="col-sm-12 col-lg-8 table-lg-excel">
          <div class="table-responsive" *ngIf="showReportes">
            <table class="table table-hover table-bordered border">
                <thead>
                  <tr>
                    <th scope="col" colspan="5" class="text-center py-1 bg-blue font-head-size">Composición de la sociedad</th>
                  </tr>
                  <tr>
                    <th scope="col" class="py-1 bg-blue font-head-size">Socio</th>
                    <th scope="col" class="py-1 bg-blue font-head-size">Rut</th>
                    <th scope="col" class="py-1 bg-blue font-head-size">%</th>
                    <th scope="col" class="py-1 bg-blue font-head-size">FS</th>
                  </tr>
                </thead>
                <tbody>
  
                  <ng-template #sociosNoEditable>
  
                    <ng-container *ngFor="let item of sociosComposicion; let i = index">
                      <tr class="height-row pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">
                        <td>{{sociosComposicion[i].socio}}</td>
                        <td>{{sociosComposicion[i].rut | rutFormat}}</td>
                        <td>{{stringToFloat(sociosComposicion[i].porcentaje) | number: '1.0-2'}}</td>
                        <td>{{sociosComposicion[i].fs}}</td>
                      </tr>
                    </ng-container>
  
                  </ng-template>
  
                  <ng-template #sinDatosSocios>
                    <tr class="height-row">
                      <td colspan="5">Sin información</td>
                    </tr>
                  </ng-template>
  
                  <ng-container *ngIf="sociosComposicion.length > 0 else sinDatosSocios">
                    
                    <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else sociosNoEditable">
                      
                      <ng-container *ngFor="let item of sociosComposicion; let i = index">
                        <tr class="height-row">
                          <td class="pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">{{sociosComposicion[i].socio}}</td>
                          <td class="pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">{{sociosComposicion[i].rut | rutFormat}}</td>
                          <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='socio-porcentaje{{i}}' name='socio-porcentaje{{i}}' [(ngModel)]="sociosComposicion[i].porcentaje"></td>
                          <td>
                            <select class="form-control" id='socio-fs{{i}}' name='socio-fs{{i}}' [(ngModel)]="sociosComposicion[i].fs">
                              <option value="" selected>Seleccione una opción</option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                            </select>
                          </td>
                        </tr>
                      </ng-container>
  
                    </ng-container>
  
  
                  </ng-container>
                  
                </tbody>
            </table>
          </div>
      </div>
      
      <div class="col-sm-12 col-lg-4 table-sm-excel">
        <div class="table-responsive" *ngIf="showReportes">
          <table class="table table-hover table-bordered border">
              <thead>
                <tr>
                  <th scope="col" colspan="6" class="text-center py-1 bg-blue font-head-size">Informes Comerciales</th>
                </tr>
                <tr>
                  <th scope="col" class="py-1 bg-blue font-head-size">N°</th>
                  <th scope="col" class="py-1 bg-blue font-head-size">Monto M$</th>
                  <th scope="col" class="py-1 bg-blue font-head-size">Motivo</th>
                  <th scope="col" class="py-1 bg-blue font-head-size">Morosidad SBIF</th>
                  <th scope="col" class="py-1 bg-blue font-head-size">Deuda Total</th>
                  <th scope="col" class="py-1 bg-blue font-head-size">Mes Año</th>
                </tr>
              </thead>
              <tbody>
  
                <ng-template #sinDatosSociosInforme>
                  <tr class="height-row">
                    <td colspan="6">Sin información</td>
                  </tr>
                </ng-template>
  
                <ng-container *ngIf="sociosInformes.length > 0 else sinDatosSociosInforme">
  
                  <ng-container *ngFor="let item of sociosInformes; let i = index">
                    <tr class="height-row pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">
                      <td>{{sociosInformes[i].numero | number: '1.0-0'}}</td>
                      <td>{{sociosInformes[i].monto | number: '1.0-2'}}</td>
                      <td>{{sociosInformes[i].motivo}}</td>
                      <td>{{sociosInformes[i].morosidad || 0 | number: '1.0-2'}}</td>
                      <td>{{sociosInformes[i].deudaTotal || 0 | number: '1.0-2'}}</td>
                      <td>{{sociosInformes[i].mesAnio || ''}}</td>
                    </tr>
                  </ng-container>
                  
                </ng-container>
  
              </tbody>
            </table>
          </div>
        </div>
  
        <div class="col-sm-12 col-lg-8 table-lg-excel">
          <div class="table-responsive" *ngIf="showReportes">
            <table class="table table-hover table-bordered border">
              <thead>
                <tr>
                  <th scope="col" class="py-1 bg-blue font-head-size">Empresas Relacionadas</th>
                  <th scope="col" class="py-1 bg-blue font-head-size">Rut</th>
                  <th scope="col" class="py-1 bg-blue font-head-size">%</th>
                  <th scope="col" class="py-1 bg-blue font-head-size">FS</th>
                </tr>
              </thead>
              <tbody>
  
                <ng-template #empresasRelacionadasComposicionNoEditable>
  
                  <ng-container *ngFor="let item of empresasRelacionadasComposicion; let i = index">
                    <tr class="height-row pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">
                      <td>{{empresasRelacionadasComposicion[i].socio}}</td>
                      <td>{{empresasRelacionadasComposicion[i].rut | rutFormat}}</td>
                      <td>{{stringToFloat(empresasRelacionadasComposicion[i].porcentaje) | number: '1.0-2'}}</td>
                      <td>{{empresasRelacionadasComposicion[i].fs}}</td>
                    </tr>
                  </ng-container>
  
                </ng-template>
  
                <ng-template #sinDatosEmpresas>
                  <tr class="height-row">
                    <td colspan="5">Sin información</td>
                  </tr>
                </ng-template>
  
                <ng-container *ngIf="empresasRelacionadasComposicion.length > 0 else sinDatosEmpresas">
  
                  <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else empresasRelacionadasComposicionNoEditable">
                    
                    <ng-container *ngFor="let item of empresasRelacionadasComposicion; let i = index">
                      <tr class="height-row">
                        <td class="pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">{{empresasRelacionadasComposicion[i].socio}}</td>
                        <td class="pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">{{empresasRelacionadasComposicion[i].rut | rutFormat}}</td>
                        <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='empresa-porcentaje{{i}}' name='empresa-porcentaje{{i}}' [(ngModel)]="empresasRelacionadasComposicion[i].porcentaje"></td>
                        <td>
                          <select class="form-control" id='empresa-fs{{i}}' name='empresa-fs{{i}}' [(ngModel)]="empresasRelacionadasComposicion[i].fs">
                            <option value="" selected>Seleccione una opción</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                          </select>
                        </td>
                      </tr>
                    </ng-container>
  
                  </ng-container>
                  
                </ng-container>
  
              </tbody>
            </table>
          </div>
        </div>
  
        <div class="col-sm-12 col-lg-4 table-sm-excel">
          <div class="table-responsive" *ngIf="showReportes">
              <table class="table table-hover table-bordered border">
                <thead>
                  <tr>
                    <th scope="col" class="py-1 bg-blue font-head-size">N°</th>
                    <th scope="col" class="py-1 bg-blue font-head-size">Monto M$</th>
                    <th scope="col" class="py-1 bg-blue font-head-size">Motivo</th>
                    <th scope="col" class="py-1 bg-blue font-head-size">Morosidad SBIF</th>
                    <th scope="col" class="py-1 bg-blue font-head-size">Deuda Total</th>
                    <th scope="col" class="py-1 bg-blue font-head-size">Mes Año</th>
                  </tr>
                </thead>
                <tbody>
  
                  <ng-template #sinDatosEmpresasInforme>
                    <tr class="height-row">
                      <td colspan="6">Sin información</td>
                    </tr>
                  </ng-template>
  
                  <ng-container *ngIf="empresasRelacionadasInformes.length > 0 else sinDatosEmpresasInforme">
                    <ng-container *ngFor="let item of empresasRelacionadasInformes; let i = index">
                      <tr class="height-row pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">
                        <td>{{empresasRelacionadasInformes[i].numero | number: '1.0-0'}}</td>
                        <td>{{empresasRelacionadasInformes[i].monto | number: '1.0-2'}}</td>
                        <td>{{empresasRelacionadasInformes[i].motivo}}</td>
                        <td>{{empresasRelacionadasInformes[i].morosidad || 0 | number: '1.0-2'}}</td>
                        <td>{{empresasRelacionadasInformes[i].deudaTotal || 0 | number: '1.0-2'}}</td>
                        <td>{{empresasRelacionadasInformes[i].mesAnio || ''}}</td>
                      </tr>
                    </ng-container>
                  </ng-container>
  
                </tbody>
              </table>
            </div>
          </div>
      </div>
    </div>
  
    <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('balance-automaticos', 'button-balance-automaticos')"
      [ngClass]="{'pagebreak': dataCargada}"
    >
      Balance Automatico
    </h3>
    <div class="container-desplegables" id="balance-automaticos">
      <div class="row p-5">
        <div class="col-12">
          <app-balance-automatico-demo-workflow *ngIf="dataCargada" #appinformef22view [reporteFullContent]="dataFiltrada" [dataF29]="dataF29" [rut]="rut"></app-balance-automatico-demo-workflow>
          <span *ngIf="!dataCargada">
            <h4>No se encuentran datos en los servicios consultados.</h4>
          </span>
        </div>
      </div>
    </div>

    <div class="col-12 my-2 not-print" *ngIf="showArchivos">
      <app-modulo-archivos [idTransaccion]="idTransaccion" [rut]="rut" [closed]="closed"></app-modulo-archivos>
    </div>

  </div>
  
  <ng-container *ngIf="plantillaNoEditable === false">
    <ng-template #botonFinalizar>
      <div class="not-print">
        <ng-container *ngIf="validaUsuarioAdmin()">
          <div class="col-12 d-flex justify-content-center pt-4">
            <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="volverEtapaPrevia()">
              Devolver Etapa Previa
            </button>
          </div>
        </ng-container>
      </div>
    </ng-template>
    
    <ng-container *ngIf="stepper === 4 else botonFinalizar">
    
      <div class="col-12 d-flex justify-content-center pt-4">
        <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="guardarFicha()">
          Guardar
        </button>
        <button class="btn btn-sm btn-primary mx-2 rounded-header" [disabled]="objectKeys(fichaGuardada).length > 0 ? null : true" (click)="avanzarFlujo()">
          Continuar
        </button>
      </div>
    
    </ng-container>
  </ng-container>
  
  
  <app-modal-consolidado-workflow [rut]="rutSeleccionado" [idTransaccion]="idTransaccion" [dataServicios]="reportesSeleccionado" [informeComercial]="informeComercial" [default]="modalDefault" [plantilla]="plantillaAntecedentes"></app-modal-consolidado-workflow>
  <app-modal-pdf-viewer [urlPreview]="urlDownloadPreview" [urlPDF]="urlDownload" [idTransaccion]="idTransaccion" [tipoReporte]="'flujoCredito'" [rut]="rut" [today]="today"></app-modal-pdf-viewer>
  