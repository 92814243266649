<div class="container-gtp">
  <img class="banner-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt=""/>
  <h2 class="text-center">REPORTE BASICO</h2>
  <h5 class="text-center"><span class="badge bg-secondary text-center">{{content.InformacionBasica.Rut |
      uppercase | rutFormat}}</span></h5>
  <div class="break-box">
    <div class="row">
      <div class="col-sm-6" style="max-width: 50%">
        <div class="card textos-campo">
          <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-info-circle"
              style="font-size:28px;">&nbsp;</i>INFORMACIÓN BÁSICA</h5>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover">
                <tbody>
                  <tr>
                    <th width="12%" nowrap="nowrap" scope="row">Rut:</th>
                    <td width="88%">{{content.InformacionBasica.Rut | rutFormat}}</td>
                  </tr>
                  <tr>
                    <th nowrap="nowrap" scope="row">Nombre:</th>
                    <td>{{content.InformacionBasica.NombreORazonSocial}}</td>
                  </tr>
                  <tr>
                    <th nowrap="nowrap" scope="row">Fecha:</th>
                    <td>{{content.InformacionBasica.FechaReporte | date: 'dd-MM-yyyy'}}</td>
                  </tr>
                  <tr>
                    <th nowrap="nowrap" scope="row">Id:</th>
                    <td>{{content.InformacionBasica.IDTransaccion}}</td>
                  </tr>
                  <tr>
                    <th nowrap="nowrap" scope="row">Iniciación de Actividades:</th>
                    <td>{{content.InformacionBasica.AnoInicioActividades | date: 'dd-MM-yyyy'}}</td>
                  </tr>
                  <tr *ngIf="(isPersonaNatural && reporteConInformeComercial === true) || !isPersonaNatural">
                    <th nowrap="nowrap" scope="row">Dirección:</th>
                    <td>{{direccion || ''}}</td>
                  </tr>
                  <tr *ngIf="!isPersonaNatural">
                    <th nowrap="nowrap" scope="row">Tipo de Sociedad:</th>
                    <td>{{content.InformacionBasica.TipoSociedad}}</td>
                  </tr>
                  <tr *ngIf="content?.InformacionBasica?.TerminoGiro === true">
                    <th nowrap="nowrap" scope="row">Fecha Termino de Giro:</th>
                    <td>{{content?.InformacionBasica?.FechaTerminoGiro | date: 'dd-MM-yyyy'}}</td>
                  </tr>
                  <ng-container *ngIf="isPersonaNatural; else isNotPersonaNatural">
                    <ng-container *ngIf="reporteConInformeComercial === true">
                      <tr>
                        <th nowrap="nowrap" scope="row">Edad:</th>
                        <td>{{content?.InformacionPersonal?.Edad || ''}}</td>
                      </tr>
                      <tr>
                        <th nowrap="nowrap" scope="row">Fecha Nacimiento:</th>
                        <td>{{content?.InformacionPersonal?.FechaNacimiento | date: 'dd-MM-yyyy'}}</td>
                      </tr>
                      <tr>
                        <th nowrap="nowrap" scope="row">Nacionalidad:</th>
                        <td>{{content?.InformacionPersonal?.Nacionalidad || ''}}</td>
                      </tr>
                      <ng-container *ngIf="telefonos && telefonos.length > 0">
                        <ng-container *ngIf="telefonos.length > 1; else elseTelefonos">
                          <tr>
                            <th nowrap="nowrap" scope="row">Teléfonos:</th>
                            <td></td>
                          </tr>
                          <ng-container *ngFor="let item of telefonos; let i = index;">
                            <tr>
                              <th nowrap="nowrap" scope="row"></th>
                              <td><span class="font-weight-bold">{{ i + 1 }}:</span> {{item?.Telefono || ''}}</td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-template #elseTelefonos>
                          <tr>
                            <th nowrap="nowrap" scope="row">Teléfono:</th>
                            <td>{{telefonos[0]?.Telefono || ''}}</td>
                          </tr>
                        </ng-template>
                      </ng-container>                
                      <tr>
                        <th nowrap="nowrap" scope="row">Email:</th>
                        <td class="text-break">{{content?.InformacionPersonal?.Contactabilidad?.Email?.Email || ''}}</td>
                      </tr>
                      <tr>
                        <th nowrap="nowrap" scope="row">Fecha Email:</th>
                        <td>{{formatFechaEmailPN(content?.InformacionPersonal?.Contactabilidad?.Email?.FechaEmail)}}</td>
                      </tr>
                      <tr>
                        <th nowrap="nowrap" scope="row">Descripcion Estado Marital:</th>
                        <td>{{content?.InformacionPersonal?.Familia?.DescripcionEstadoMarital || ''}}</td>
                      </tr>
                      <tr>
                        <th nowrap="nowrap" scope="row">Rut Conyugue:</th>
                        <td>{{content?.InformacionPersonal?.Familia?.RutConyugue | rutFormat}}</td>
                      </tr>
                      <tr>
                        <th nowrap="nowrap" scope="row">Fecha de Matrimonio:</th>
                        <td>{{content?.InformacionPersonal?.Familia?.FechaDeMatrimonio | date: 'dd-MM-yyyy'}}</td>
                      </tr>
                    </ng-container>
                  </ng-container>
                  <ng-template #isNotPersonaNatural>

                    <ng-container *ngIf="telefonos && telefonos.length > 0">
                      <ng-container *ngIf="telefonos.length > 1; else elseTelefonos">
                        <ng-container *ngFor="let item of telefonos; let i = index;">
                          <tr>
                            <th nowrap="nowrap" scope="row">Teléfono {{ i + 1 }}:</th>
                            <td></td>
                          </tr>
                          <tr>
                            <th nowrap="nowrap" scope="row"></th>
                            <td><span class="font-weight-bold">Número:</span> {{item?.Telefono || ''}}</td>
                          </tr>
                          <tr>
                            <th nowrap="nowrap" scope="row"></th>
                            <td><span class="font-weight-bold">Fecha Teléfono:</span> {{item?.FechaTelefono | date: 'dd-MM-yyyy'}}</td>
                          </tr>
                        </ng-container>
                      </ng-container>
                      <ng-template #elseTelefonos>
                        <tr>
                          <th nowrap="nowrap" scope="row">Teléfono:</th>
                          <td>{{telefonos[0]?.Telefono || ''}}</td>
                        </tr>
                        <tr>
                          <th nowrap="nowrap" scope="row">Fecha Teléfono:</th>
                          <td>{{telefonos[0]?.FechaTelefono | date: 'dd-MM-yyyy'}}</td>
                        </tr>
                      </ng-template>
                    </ng-container>
                    <tr *ngIf="reporteConInformeComercial === true">
                      <th nowrap="nowrap" scope="row">Email:</th>
                      <td>{{content?.ContactabilidadComercial?.Email || ''}}</td>
                    </tr>
                    <tr *ngIf="reporteConInformeComercial === true">
                      <th nowrap="nowrap" scope="row">Sitio Web:</th>
                      <td>{{content?.ContactabilidadComercial?.SitioWeb || ''}}</td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6" style="max-width: 50%">
        <div class="card">
          <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-exclamation-triangle"
              style="font-size:28px;">&nbsp;</i>RIESGO</h5>
          <div class="card-body">
            <table class="table table-hover">
              <tbody>
                <tr>
                  <th width="21%" nowrap="nowrap" scope="row">Actividad Económica:</th>
                  <td width="79%">{{content.Riesgo.ActividadEconomica}}</td>
                </tr>
                <tr>
                  <th nowrap="nowrap" scope="row">Industria:</th>
                  <td>{{content.Riesgo.Industria}}</td>
                </tr>
                <tr>
                  <th nowrap="nowrap" scope="row">Sub sector:</th>
                  <td>{{content.Riesgo.Subsector}}</td>
                </tr>
                <tr>
                  <th nowrap="nowrap" scope="row">Riesgo Industria:</th>
                  <td>{{content.Riesgo.RiesgoIndustria}}</td>
                </tr>
                <tr *ngIf="riesgoIndustriaCalculado">
                  <th colspan="2" nowrap="nowrap" scope="row">
                    <div class="form-group">
                      <div class="d-flex btn-group" role="group" aria-label="Basic radio toggle button group">

                        <input type="radio" class="btn-check" name="inputLogType" id="alto" value="Alto">
                        <label class="btn btn-outline-primary alto" for="alto">Alto
                          <div class="selected" *ngIf="content.Riesgo.RiesgoIndustria === 'Alto'"></div>
                        </label>

                        <input type="radio" class="btn-check" name="inputLogType" id="medioAlto" value="Medio Alto">
                        <label class="btn btn-outline-primary medioAlto" for="medioAlto">Medio Alto
                          <div class="selected" *ngIf="content.Riesgo.RiesgoIndustria === 'Medio Alto'"></div>
                        </label>

                        <input type="radio" class="btn-check" name="inputLogType" id="medio" value="Medio">
                        <label class="btn btn-outline-primary medio" for="medio">Medio
                          <div class="selected" *ngIf="content.Riesgo.RiesgoIndustria === 'Medio'"></div>
                        </label>

                        <input type="radio" class="btn-check" name="inputLogType" id="medioBajo" value="Medio Bajo">
                        <label class="btn btn-outline-primary medioBajo" for="medioBajo">Medio Bajo
                          <div class="selected" *ngIf="content.Riesgo.RiesgoIndustria === 'Medio Bajo'"></div>
                        </label>

                        <input type="radio" class="btn-check" name="inputLogType" id="Bajo" value="Bajo">
                        <label class="btn btn-outline-primary bajo" for="Bajo">Bajo
                          <div class="selected" *ngIf="content.Riesgo.RiesgoIndustria === 'Bajo'"></div>
                        </label>

                      </div>
                    </div>
                  </th>
                </tr>
                <tr *ngIf="content.Riesgo.ClasificacionRiesgo">
                  <th nowrap="nowrap" scope="row">Clasificación Riesgo:</th>
                  <td>
                    <table width="100%" border="0">
                      <tbody>
                        <tr>
                          <td style="padding: 0.75rem 16px;">
                            <div style="width:94%;">
                              <div style="width:22px;text-align: center;"
                                [ngStyle]="{'margin-left': setClasificacionPosition(content.Riesgo.ClasificacionRiesgo) + '%'}">
                                <div style="width:26px;height:12px;text-align: center;font-size: 1.5rem;
                          font-weight: bold;">
                                  {{content.Riesgo.ClasificacionRiesgo}}</div>
                                <div style="width:26px;height:14px;text-align: center;"><i class="arrow down"></i></div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <img src="assets/img/barra.jpg" width="100%" alt="" />
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </td>
                </tr>
                <tr *ngIf="content.Riesgo.Clasificacion">
                  <th nowrap="nowrap" scope="row">Clasificación Comercial:</th>
                  <td>
                    <table width="100%" border="0">
                      <tbody>
                        <tr>
                          <td style="padding: 0.75rem 16px;">
                            <div style="width:94%;">
                              <div style="width:22px;text-align: center;"
                                [ngStyle]="{'margin-left': setClasificacionPosition(content.Riesgo.Clasificacion) + '%'}">
                                <div style="width:26px;height:12px;text-align: center; font-size: 1.5rem;
                          font-weight: bold;">{{content.Riesgo.Clasificacion}}
                                </div>
                                <div style="width:26px;height:14px;text-align: center;"><i class="arrow down"></i></div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2"><img src="assets/img/barra.jpg" width="100%" alt="" /></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr *ngIf="content.Riesgo.Rating">
                  <th nowrap="nowrap" scope="row">Rating:</th>
                  <td>
                    <table width="100%" border="0">
                      <tbody>
                        <tr>
                          <td style="padding: 0.75rem 16px;">
                            <div style="width:94%;">
                              <div style="width:22px;text-align: center;"
                                [ngStyle]="{'margin-left': setClasificacionPosition(content.Riesgo.Rating) + '%'}">
                                <div style="width:26px;height:12px;text-align: center;font-size: 1.5rem;
                          font-weight: bold;">{{content.Riesgo.Rating}}</div>
                                <div style="width:26px;height:14px;text-align: center;"><i class="arrow down"></i></div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2"><img src="assets/img/barra.jpg" width="100%" alt="" /></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6" style="max-width: 50%">
        <div class="card">
          <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-credit-card"
              style="font-size:28px;">&nbsp;</i>ANTECEDENTES FINANCIEROS</h5>
          <div class="card-body">
            <table class="table table-hover">
              <tbody>
                <ng-container *ngIf="!isPersonaNatural">
                  <tr>
                    <th width="20%" nowrap="nowrap" scope="row">Est. Financieros:</th>
                    <td width="80%" colspan="2">{{content.AntecedentesFinancieros.EstadosFinancieros}}</td>
                  </tr>
                  <tr>
                    <th nowrap="nowrap" scope="row">F. E. Financiero:</th>
                    <td colspan="3">{{content.AntecedentesFinancieros.FechaEstadosFinancieros}}</td>
                  </tr>
                  <tr>
                    <th nowrap="nowrap" scope="row">T. Emp. Descripción:</th>
                    <td colspan="3">{{content.AntecedentesFinancieros.TamanoEmpresaDescripcion}}</td>
                  </tr>
                  <tr>
                    <th nowrap="nowrap" scope="row">T. Emp. Rango:</th>
                    <td colspan="3">{{content.AntecedentesFinancieros.TamanoEmpresaRango}}</td>
                  </tr>
                  <tr>
                    <th nowrap="nowrap" scope="row">T. Sub Segmento:</th>
                    <td colspan="3">{{content.AntecedentesFinancieros.TamanoSubsegmento}}</td>
                  </tr>
                  <tr>
                    <th nowrap="nowrap" scope="row">Tramo:</th>
                    <td colspan="3">{{content.AntecedentesFinancieros.TramoSegunVentas}}</td>
                  </tr>
                  <tr>
                    <th nowrap="nowrap" scope="row">Patrimonio:</th>
                    <td colspan="3">{{content.AntecedentesFinancieros.Patrimonio}}</td>
                  </tr>
                  <tr>
                    <th nowrap="nowrap" scope="row">Cant. Empleados:</th>
                    <td colspan="3">{{content.AntecedentesFinancieros.NumeroEmpleados}}</td>
                  </tr>
                </ng-container>
                <tr>
                  <th nowrap="nowrap" scope="row">Número de Vehiculos:</th>
                  <td>{{content.AntecedentesFinancieros.NumeroVehiculos | number: '1.0-0'}}</td>
                  <th nowrap="nowrap" scope="row">Valor de Vehiculos:</th>
                  <td>{{content.AntecedentesFinancieros.ValorVehiculos | number: '1.0-0'}}</td>
                </tr>
                <tr>
                  <th nowrap="nowrap" scope="row">Número de Bienes Raices:</th>
                  <td>{{content.AntecedentesFinancieros.NumeroBienesRaices | number: '1.0-0'}}</td>
                  <th nowrap="nowrap" scope="row">Valor de Bienes Raices:</th>
                  <td>{{content.AntecedentesFinancieros.ValorBienesRaices | number: '1.0-0'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-sm-6" style="max-width: 50%"
        *ngIf="graph && content.Riesgo?.ClasificacionHistorica && content.Riesgo.ClasificacionHistorica.length > 0">
        <div class="card">
          <h5 class="card-header list-group-item cuadro-color text-uppercase"><i class="fa fa-line-chart"
              style="font-size:28px;">&nbsp;</i>Evolución del Predictor Empresarial</h5>
          <div class="card-body">
            <div [chart]="graph" class="graphContent"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>

  <div class="break-box">
    <div class="row" *ngIf="content.InformacionBasica?.TipoReporte !== sinInformeText">
      <div class="col-sm-6" style="max-width: 50%;">
        <div class="card">
          <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-sitemap"
              style="font-size:28px;">&nbsp;</i>ANTECEDENTES SOCIETARIOS</h5>
          <div class="card-body">
            <table class="table table-hover">
              <tbody>
                <tr>
                  <th width="29%" scope="row">Rut:</th>
                  <td width="71%">{{content.InformacionBasica.Rut | rutFormat}}</td>
                </tr>
                <tr>
                  <th scope="row">Nombre:</th>
                  <td>{{content.InformacionBasica.NombreORazonSocial}}</td>
                </tr>
              </tbody>
            </table>
            <div class="tree ">
              <ul>
                <li>
                  <span>
                    <a style="color:#000; text-decoration:none;" data-toggle="collapse" href="#Web" aria-expanded="true"
                      aria-controls="Web"><i class="collapsed"><i class="fas fa-folder"></i></i> <i class="expanded"><i
                          class="fa fa-folder-open"></i></i>
                      {{content.InformacionBasica.NombreORazonSocial}} / {{content.InformacionBasica.Rut |
                      rutFormat}}</a></span>
                  <div id="Web" class="collapse show">
                    <ul>
                      <li *ngFor="let socio of content.AntecedentesSocietarios">
                        <span><i class="fa fa-file"></i> {{socio.nombre}} / {{socio.rut | rutFormat}}</span>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>



      <div class="col-sm-6" style="max-width: 50%;" *ngIf="content.InformacionBasica?.TipoReporte !== sinInformeText">
        <div class="card">
          <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-file-text-o"
              style="font-size:28px;">&nbsp;</i>ANTECEDENTES COMERCIALES</h5>
          <div class="card-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <td align="center" scope="col">&nbsp;</td>
                  <td align="right"><strong>Monto miles</strong></td>
                  <td align="right"><strong>Cantidad</strong></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Protestos</th>
                  <td align="right" scope="row">{{content.AntecedentesComerciales.Resumen.ProtestosMonto |
                    number:'1.0-0'}}</td>
                  <!-- | round | money | remove: '.00' -->
                  <td align="right">{{content.AntecedentesComerciales.Resumen.ProtestoCantidad | number:'1.0-0'}}</td>
                </tr>
                <tr>
                  <th scope="row">Morosidades</th>
                  <td align="right">{{content.AntecedentesComerciales.Resumen.MorosidadesMonto | number:'1.0-0'}}</td>
                  <!-- | round | money | remove: '.00' -->
                  <td align="right">{{content.AntecedentesComerciales.Resumen.MorosidadesCantidad | number:'1.0-0'}}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Infracciones Laborales</th>
                  <td align="right">{{content.AntecedentesComerciales.Resumen.InfraccionesLaboralesMonto |
                    number:'1.0-0'}}</td>
                  <!-- | round | money | remove: '.00' -->
                  <td align="right">{{content.AntecedentesComerciales.Resumen.InfraccionesLaboralesCantidad |
                    number:'1.0-0'}}</td>
                </tr>
                <tr class="table-secondary">
                  <th scope="row">Total</th>
                  <td align="right">{{content.AntecedentesComerciales.Resumen.TotalImpagosMonto | number:'1.0-0'}}</td>
                  <!-- | money | remove: '.00' -->
                  <td align="right">{{content.AntecedentesComerciales.Resumen.TotalImpagosCantidad | number:'1.0-0'}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    
    <div class="card textos-campo" *ngIf="reporteConInformeComercial === true">
      <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-users"
          style="font-size:28px;">&nbsp;</i>ANTECEDENTES SOCIEDADES</h5>
      <div class="card-body">
        <ng-container *ngIf="isPersonaNatural; else notPNAntSociedades">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Rut</th>
                <th>Nombre</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="content?.AntecedentesSociedades && content?.AntecedentesSociedades.length > 0; else aSNoData">
                <tr *ngFor="let itemSociedad of content?.AntecedentesSociedades">
                  <td>{{itemSociedad?.Rut | rutFormat}}</td>
                  <td>{{itemSociedad?.Nombre || ''}}</td>
                </tr>
              </ng-container>
              <ng-template #aSNoData>
                <tr>
                  <td colspan="100%">Sin Información</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </ng-container>
        <ng-template #notPNAntSociedades>
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Rut</th>
                <th>Nombre</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="content?.AntecedentesSociedades && content?.AntecedentesSociedades.length > 0; else notPNASNoData">
                <tr *ngFor="let itemSociedad of content?.AntecedentesSociedades">
                  <td>{{itemSociedad?.Rut | rutFormat}}</td>
                  <td>{{itemSociedad?.Nombre || ''}}</td>
                </tr>
              </ng-container>
              <ng-template #notPNASNoData>
                <tr>
                  <td colspan="100%">Sin Información</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </ng-template>
      </div>
    </div>

    <div class="card textos-campo">
      <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-building"
          style="font-size:28px;">&nbsp;</i>INFORMACION SECTORIAL Y DE MERCADO</h5>
      <div class="card-body">
        <div class="row row-cols-1 row-cols-md-2 g-4">
          <div class="col">
            <div class="card text-dark bg-light mb-3">
              <div class="card-header">
                <h6 class="card-title">Mercado</h6>
              </div>
              <div class="card-body">
                <table class="table table-hover table-borderless">
                  <tbody>
                    <tr>
                      <th width="50%" scope="row">Número empresas:</th>
                      <td width="50%">{{content.InformacionSectorialyDeMercado.TamanoMercadoNumeroEmpresas |
                        currency:'CLP':'':'1.0-0' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Total ventas en UF:</th>
                      <td>{{content.InformacionSectorialyDeMercado.TotalVentasIndustriaEnUF | currency:'CLP':'':'1.0-0'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Participacion relativa:</th>
                      <td>{{content.InformacionSectorialyDeMercado.ParticipacionRelativa}}</td>
                    </tr>
                    <tr>
                      <th scope="row">Tendencia mercado:</th>
                      <td>{{content.InformacionSectorialyDeMercado.TendenciaMercado}}</td>
                    </tr>
                    <tr>
                      <th scope="row">Variacion crecimiento anual:</th>
                      <td>{{content.InformacionSectorialyDeMercado.VariacionVentasCrecimientoAnual}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card text-dark bg-light mb-3">
              <div class="card-header">
                <h6 class="card-title">Comuna</h6>
              </div>
              <div class="card-body">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <th width="50%" scope="row">Número empresas:</th>
                      <td width="50%">{{content.InformacionSectorialyDeMercado.NumeroEmpresasEnComuna |
                        currency:'CLP':'':'1.0-0' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">Total ventas en UF:</th>
                      <td>{{content.InformacionSectorialyDeMercado.TotalVentasComunaEnUF | currency:'CLP':'':'1.0-0' }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Participacion relativa:</th>
                      <td>{{content.InformacionSectorialyDeMercado.ParticipacionRelativaComuna}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row row-cols-1 row-cols-md-2 g-4"> -->
        <div class="col-sm-6" *ngIf="showInformacionSectorialyDeMercado">
          <div class="card text-dark bg-light mb-3">
            <div class="card-header">
              <h6 class="card-title">Ventas</h6>
            </div>
            <div class="card-body">
              <table class="table table-hover table-borderless">
                <thead>
                  <tr>
                    <td width="50%" align="left"><strong>Año</strong></td>
                    <td width="50%" align="right"><strong>Venta en UF</strong></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{{content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano1}}</th>
                    <td align="right">{{content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_1 |
                      currency:'CLP':'':'1.0-0' }}</td>
                  </tr>
                  <tr>
                    <th scope="row">{{content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano2}}</th>
                    <td align="right">{{content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_2 |
                      currency:'CLP':'':'1.0-0' }}</td>
                  </tr>
                  <tr>
                    <th scope="row">{{content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano3}}</th>
                    <td align="right">{{content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_3 |
                      currency:'CLP':'':'1.0-0' }}</td>
                  </tr>
                  <tr>
                    <th scope="row">{{content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano4}}</th>
                    <td align="right">{{content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_4 |
                      currency:'CLP':'':'1.0-0' }}</td>
                  </tr>
                  <tr>
                    <th scope="row">{{content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano5}}</th>
                    <td align="right">{{content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_5 |
                      currency:'CLP':'':'1.0-0' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card text-dark bg-light mb-3">
            <div class="card-header">
              <h6 class="card-title">Gráfico Ventas</h6>
            </div>
            <div class="grafico">
              <div id="chart_div">
                <google-chart [title]="chart.title" [type]="chart.type" [data]="chart.data" [columns]="chart.columns"
                  [options]="chart.options" [width]="chart.width" [height]="chart.height">
                </google-chart>

                <figure class="mt-3">
                  <figcaption class="blockquote-footer">
                    <cite title="Source Title">Ventas expresadas en millones de UF</cite>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>

  <div class="card textos-campo" *ngIf="!isPersonaNatural">
    <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-file-text-o"
        style="font-size:28px;">&nbsp;</i>OBSERVACIONES TRIBUTARIAS</h5>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-12">
          <div class="card text-dark bg-light mb-3">
            <div class="card-header">
              <h6 class="card-title">Documentos Timbrados</h6>
            </div>
            <div class="card-body">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th class="font-weight-bold" width="50%">Documento</th>
                    <th class="font-weight-bold" width="50%">Año último Timbraje</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="content?.ObservacionesTributarias?.DocumentosTimbrados && content?.ObservacionesTributarias?.DocumentosTimbrados.length > 0; else elseDocumentosTimbrados">
                    <tr *ngFor="let documento of content?.ObservacionesTributarias?.DocumentosTimbrados">
                      <td width="50%" scope="row">{{documento?.documento || ''}}</td>
                      <td width="50%">{{documento?.anoUltimoTimbraje || ''}}</td>
                    </tr>
                  </ng-container>
                  <ng-template #elseDocumentosTimbrados>
                    <tr>
                      <td colspan="100%">Sin Información</td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="card text-dark bg-light mb-3">
            <div class="card-header">
              <h6 class="card-title">Observaciones</h6>
            </div>
            <div class="card-body">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th class="font-weight-bold" width="30%">Nombre</th>
                    <th class="font-weight-bold" width="70%">Descripción</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="content?.ObservacionesTributarias?.Observaciones && content?.ObservacionesTributarias?.Observaciones.length > 0; else elseDocumentosTimbrados">
                    <tr *ngFor="let observacion of content?.ObservacionesTributarias?.Observaciones">
                      <td width="30%" scope="row">{{observacion?.nombre || ''}}</td>
                      <td width="70%">{{observacion?.descripcion || ''}}</td>
                    </tr>
                  </ng-container>
                  <ng-template #elseDocumentosTimbrados>
                    <tr>
                      <td colspan="100%">Sin Información</td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br>

  <div class="card textos-campo" *ngIf="content.AntecedentesComerciales?.ConsultasAlRut && 
  content.AntecedentesComerciales.ConsultasAlRut.length > 0">
    <h5 class="card-header list-group-item cuadro-color">
      <table width="100%" border="0">
        <tbody>
          <tr>
            <td class="text-uppercase"><i class="fa fa-area-chart" style="font-size:28px;">&nbsp;</i>
              Consultas al RUT
            </td>
          </tr>
        </tbody>
      </table>
    </h5>
    <div class="card-body">
      <div style="overflow-x:auto;" *ngIf="showConsultaRut">
        <table class="table table-hover">
          <thead>
            <tr>
              <th colspan="3" class="border-0">
                RUT registra {{content.AntecedentesComerciales.ConsultasAlRut.length}} consultas
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <td>Fecha</td>
              <td>Empresa</td>
              <td>Informe </td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of content.AntecedentesComerciales.ConsultasAlRut">
              <td>{{item.FechaConsulta | date: 'dd-MM-YYYY'}}</td>
              <td>{{item.RazonSocial}} </td>
              <td>{{item.InformeConsultado}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="break-box">
    <div class="card textos-campo" *ngIf="content.InformacionBasica?.TipoReporte !== sinInformeText">
      <h5 class="card-header list-group-item cuadro-color">
        <table width="100%" border="0">
          <tbody>
            <tr>
              <td><i class="fa fa-area-chart" style="font-size:28px;">&nbsp;</i>PROTESTOS Y MOROSIDADES</td>
              <td align="right">Total M$: {{(content.AntecedentesComerciales.Resumen.ProtestosMonto +
                content.AntecedentesComerciales.Resumen.MorosidadesMonto) | currency:'CLP':'':'1.0-0'}}</td>
              <!-- TODO: content.AntecedentesComerciales.Resumen.ProtestosMonto | plus: AntecedentesComerciales.Resumen.MorosidadesMonto | money | remove: '.00' -->
            </tr>
          </tbody>
        </table>
      </h5>
      <div class="card-body">
        <div style="overflow-x:auto;" *ngIf="showDetalleMorosidadesYProtestos">
          <table class="table table-hover print-table">
            <thead>
              <tr>
                <th align="center" scope="col">Vencimiento</th>
                <th align="center">Doc.</th>
                <th align="right">Monto</th>
                <th align="center">Localidad </th>
                <th align="center">Librador</th>
                <th align="center">Deuda</th>
                <th align="center">Mercado</th>
                <th align="center">Credito</th>
                <th align="center">N°Cheque</th>
                <th align="center">Publicación</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let detalle of content.AntecedentesComerciales.DetalleMorosidadesYProtestos">
                <td align="center" scope="row">{{detalle.fechaVencimiento | date: 'dd-MM-YYYY' }}</td>
                <td align="center" scope="row">{{detalle.tipoDocumento}}</td>
                <td align="right">{{detalle.moneda}} {{detalle.monto | currency:'CLP':'':'1.0-0' }}</td>
                <td>{{detalle.localidad}}</td>
                <td>{{detalle.librador}} </td>
                <td>{{detalle.tipoDeuda}}</td>
                <td>{{detalle.mercado}}</td>
                <td>{{detalle.tipoCredito}}</td>
                <td align="center">{{detalle.nChequeOperacion}}</td>
                <td align="center">{{detalle.fechaPublicacion | date: 'dd-MM-YYYY' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <br>

  <!-- <div class="pageBreak"></div>
  <br> -->
  <div class="break-box">
    <div class="card textos-campo" *ngIf="content.InformacionBasica?.TipoReporte !== sinInformeText">
      <h5 class="card-header list-group-item cuadro-color">
        <table width="100%" border="0">
          <tbody>
            <tr>
              <td><i class="fa fa-list-alt" style="font-size:28px;">&nbsp;</i>INFORMACION BOLETIN LABORAL</td>
              <td align="right">Total M$: {{ content.AntecedentesComerciales.Resumen.InfraccionesLaboralesMonto |
                currency:'CLP':'':'1.0-0' }}</td>
            </tr>
          </tbody>
        </table>
      </h5>
      <div class="card-body" *ngIf="showInformacionBoletinComercialLaboral">
        <div style="overflow-x:auto;">
          <table class="table table-hover">
            <thead>
              <tr>
                <td width="20%" align="center">Motivo</td>
                <td width="6%" align="center">Meses</td>
                <td width="25%" align="center">Institución</td>
                <td width="6%" align="center">Boletín</td>
                <td width="7%" align="right">Monto</td>
                <td width="5%" align="center">Página</td>
                <td width="8%" align="center">Insp</td>
                <td width="10%" align="center" nowrap="nowrap">Fecha</td>
                <td width="22%" align="right">Tipo infracción</td>
                <td width="5%" align="center">Resolución</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let detalle of content.AntecedentesComerciales.InformacionBoletinComercialLaboral">
                <td align="center">{{detalle.motivo}}</td>
                <td align="center">{{detalle.meses}}</td>
                <td align="center">{{detalle.institucion}}</td>
                <td align="center" scope="row">{{detalle.boletin}}</td>
                <td align="right">{{detalle.monto | number:'1.0-0'}}</td>
                <td align="center">{{detalle.pagina}}</td>
                <td align="center">{{detalle.insp}}</td>
                <td align="center" nowrap="nowrap">{{detalle.fecha | date: 'dd-MM-YYYY' }}</td>
                <td align="right">{{detalle.tipoInfraccion}}</td>
                <td align="center" scope="row">{{detalle.resolutionNumber}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <br><br><br><br>
    <hr>
    <p>La información presentada en estos análisis proviene de fuentes consideradas altamente confiables. Sin embargo,
      dada la posibilidad de error humano o mecánico, no se garantiza la exactitud o integridad de la información y, por
      tanto, no se hace responsable de errores u omisiones, como tampoco de las consecuencias asociadas con el empleo de
      esa información.</p>
    <hr>
    <br>
  </div>
</div>