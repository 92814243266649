import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment as env } from 'src/environments/environment';
//import { SimpleModalService } from "ngx-simple-modal";
import { MatDialog } from '@angular/material/dialog';
import { AccessDialogComponent } from 'src/app/components/access-dialog/access-dialog.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { Title } from '@angular/platform-browser';
import { GruposService } from 'src/app/shared/services/grupos.service';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.scss']
})
export class GruposComponent implements OnInit {
  private access: string[] = [ESystemAccess.CLIENTES_USER_ADMIN, ESystemProfileList.WEB];
  public hasUserAccess: boolean = false;
  
  public showList: boolean = true;
  public showCreate: boolean = false;
  public showEdit: boolean = false;

  // Tabla
  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public inputText: string = '';
  public order = '';
  public campoOrder = ''

  public listadoGrupos: any[] = [];
  public listadoGruposFiltrado: any[] = [];
  public grupoSeleccionado: any = {};

  public groupForm!: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private utilsService: UtilsService,
    private readonly titleService: Title,
    private gruposService: GruposService,
    public alertService: AlertService,
    private _sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
      this.loadListUserDatasource();
    }
  }

  public loadListUserDatasource() {
    this.spinner.show();
    this.gruposService.listarGruposUsuarios()
      .subscribe(
        (data) => {
          if(data && data.length > 0) {
            this.listadoGrupos = data.map((item: any) => {
              return {
                nombre: item.group_name,
                usuariosCant: item?.userList ? item.userList.length : 0,
                usuarios: item.userList
              }
            });
            this.filtrarObjeto();
          } else {
          this.alertService.error('No se han encontrado grupos de usuarios');
          }
          this.spinner.hide();
        },
        ({ error }) => {
          this.alertService.error(error?.message || 'Ha ocurrido un error al obtener el listado de grupos');
          this.spinner.hide();
        }
      )
  }

  filtrarObjeto() {
    this.currentPage = 0;
    this.listadoGruposFiltrado = this.inputText != '' ? this.listadoGrupos.filter((item: any) => 
    item?.nombre?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.usuariosCant?.toString().toUpperCase().includes(this.inputText.toUpperCase())
    ) : this.listadoGrupos;
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }
  
  filtrarColumna(campo: string){ // validar
    if(this.order === '' || this.order === 'asc'){
      this.listadoGruposFiltrado.sort((a, b) => {
        const textA = a[campo].toString();
        const textB = b[campo].toString();
        return textA.localeCompare(textB);
      });
    } else {
      this.listadoGruposFiltrado.sort((a, b) => {
        const textA = a[campo].toString();
        const textB = b[campo].toString();
        return (-1 * textA.localeCompare(textB));
      });
    }
  }

  showUsuarios(usuariosList: any[]) {
    if(usuariosList && usuariosList.length > 0) {
      const dialogRef = this.dialog.open(AccessDialogComponent, {
        data: {
          title: 'Usuarios',
          access: usuariosList
        }
      });
    
      dialogRef.afterClosed().subscribe(resp => { // validar
      });

      /*this.simpleModalService.addModal(AccessDialogComponent, {
        title: 'Usuarios',
        access: usuariosList
      }).subscribe(() => {
  
      });*/
    }
  }

  editarGrupo(grupo: any) {
    if(grupo && Object.keys(grupo).length > 0) {
      this.grupoSeleccionado = grupo;
      this.showList = false;
      this.showCreate = false;
      this.showEdit = true;
    }
  }


  borrarGrupo(nombre: string) {
    if(nombre) {
      this.spinner.show();
      this.gruposService.borrarGrupo(nombre)
      .subscribe(
        (data) => {
          this.spinner.hide();
          this.alertService.success(data?.message || `Se ha eliminado correctamente el grupo.`);
          this.loadListUserDatasource();
        },
        ({ error }) => {
          this.alertService.error(error?.message || 'Ha ocurrido un error al eliminar el grupo');
          this.spinner.hide();
        }
      )
    }
  }

  goToCreate() {
    this.groupForm = this.formBuilder.group({
      groupName: ['', Validators.required]
    }) as FormGroupTyped<any>;

    this.showList = false;
    this.showCreate = true;
    this.showEdit = false;
  }

  goList() {
    this.grupoSeleccionado = {};
    this.showList = true;
    this.showCreate = false;
    this.showEdit = false;
    this.loadListUserDatasource();
  }

  submit() {
    if (!this.groupForm.valid) {
      this.utilsService.validateAllFormFields(this.groupForm);
      return;
    }

    const formTemp = this.groupForm.getRawValue();
    const groupName = formTemp?.groupName;
    this.spinner.show();
    this.gruposService.crearGrupo(groupName)
    .subscribe(
      (resp) => {
        this.goList();
        this.spinner.hide();
        this.alertService.success(resp?.message || `Se ha creado correctamente el grupo ${groupName}.`);
      },
      ({ error }) => {
        this.spinner.hide();
        this.alertService.error(error.message || "Ocurrio un problema al intentar crear el grupo");
      }
    )
  }

}
