<div class="row" *ngIf="resumenSolicitud.length > 0 && informeRiesgo.length > 0">
    <div class="col-sm-6" *ngIf="tipoSolicitud === 'lineaDeCredito'">
        <!-- <app-dynamic-colunm-table [data]='dataUtilidades' [divider]='"extraNormal"' [groupHeader]="'listadoUtilidades'"
            [clasess]="'table-bordered'">
        </app-dynamic-colunm-table> -->
        <div class="table-responsive my-3">
            <table class="table table-condensed table-bordered">
                <tbody class="extra">
                    <tr>
                        <th>LC Solicitada</th>
                        <td>{{lineaCreditoTx.lcSolicitada | number: '1.0-0'}}</td>
                    </tr>
                    <tr>
                        <th>LC aprobada (Total)</th>
                        <td>{{lineaCreditoTx.lcAprobada | number: '1.0-0'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-sm-6 mx-auto">
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-hover" *ngFor="let item of informeRiesgo">
                    <tbody>
                        <tr>
                            <th colspan="1" width="40%">Riesgo Industria:</th>
                            <td colspan="4" width="60%" class="text-center">
                                <div class="form-control" disabled>
                                    {{item.riesgoIndustria}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="1">Protestos internos vigentes:</th>
                            <td colspan="4">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6 pl-0">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" disabled>#</span>
                                                </div>
                                                <div class="form-control border-left-0" disabled>
                                                    {{item.protestosInternosVigentesCantidad}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 pr-0">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" disabled>$</span>
                                                </div>
                                                <div class="form-control border-left-0" disabled>
                                                    {{item.protestosInternosVigentesMonto}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="1">Morosidades internas:</th>
                            <td colspan="4" class="text-center">

                                <div class="form-control" disabled>{{(setNumber(item.morosidadesInternas)/1000) | number: '1.0-0'}}</div>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="1">Morosidades internas +30:</th>
                            <td colspan="4" class="text-center">
                                <div class="form-control" disabled>{{setNumber(item.morosidadesInternasMayores30)/1000 | number: '1.0-0'}}</div>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="5">
                                <div class="mx-auto dynamic-colunm mb-5">
                                    <app-custom-bars [riesgo]="item.score"></app-custom-bars>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th colspan="5">
                                <app-pie-chart [value]="item.score"></app-pie-chart>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="resumenSolicitud.length > 0 && informeRiesgo.length > 0">
    <div class="col-12">
        <div class="form-group form-actions container__actions">
            <button type="submit" class="btn btn-sm btn-warning" (click)="verReporte()"
                [disabled]="!obtenerReporte">Ver Reporte</button>
        </div>
    </div>
</div>

<div class="box-loading" [ngClass]="{'d-none': !initGenerar}">
    <div class="bs-stepper" id="stepper">
        <div class="bs-stepper-header" role="tablist">
          <div class="step" data-target="#step1">
            <button type="button" class="step-trigger" role="tab">
              <span class="bs-stepper-circle">1</span>
              <span class="bs-stepper-label">Recopilar información</span>
            </button>
          </div>
          <div class="line"></div>
          <div class="step" data-target="#step2">
            <button type="button" class="step-trigger" role="tab">
              <span class="bs-stepper-circle">2</span>
              <span class="bs-stepper-label">Realizar calculos</span>
            </button>
          </div>
          <div class="line"></div>
          <div class="step" data-target="#step3">
            <button type="button" class="step-trigger" role="tab">
              <span class="bs-stepper-circle">3</span>
              <span class="bs-stepper-label">Generar Reporte</span>
            </button>
          </div>
        </div>
        <div class="bs-stepper-content p-3">
            <div id="step1" class="content" role="tabpanel">
                <div *ngIf="currentStep === 0">
                    <p class="text-center text-msg animated fadeInUp">
                        Nos encontramos recopilando la información necesaria
                        <span> para mostrarle el reporte que usted solicitó.</span>
                    </p>
                    <img src="assets/img/step1.gif" alt="step1" class="img-fluid mx-auto d-block
                    animated fadeIn">
                </div>
            </div>
            <div id="step2" class="content" role="tabpanel">
                <div *ngIf="currentStep === 1">
                    <p class="text-center text-msg animated fadeInUp">
                        Nos encontramos realizando los calculos necesarios
                        <span> para mostrarle el reporte que usted solicitó.</span>
                    </p>
                    <img src="assets/img/step2.jpg" alt="step2" class="img-fluid mx-auto d-block
                    animated fadeIn">
                    
                </div>
            </div>
            <div id="step3" class="content" role="tabpanel">
                <div *ngIf="currentStep === 2">
                    <p class="text-center text-msg animated fadeInUp">
                        Nos encontramos realizando los ajustes finales
                        <span> para mostrarle el reporte que usted solicitó.</span>
                    </p>
                    <img src="assets/img/step3.jpg" alt="step3" class="img-fluid mx-auto d-block
                    animated fadeIn">              
                </div>
            </div>
        </div>
      </div>
    <p class="text-center text-msg  animated fadeInDown p-3">Este proceso puede demorar unos minutos...</p>
    <div class="cssload-speeding-wheel"></div>
</div>