<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>
<div *ngIf="hasUserAccess && !showReport">
    <div class="container__form">
        <div class="col-md-6">
            <div class="block">
                <form [formGroup]="compraVentaReportForm" (ngSubmit)="searchReport()">
                    <div class="form-horizontal form-bordered">
                        <div class="form-group" [ngClass]="{'has-error': activateRutHelper}">
                            <div class="row">
                                <div class="col-sm-12">
                                    <label class="col-md-3 control-label" for="example-text-input">Rut</label>
                                    <div class="col-md-6">
                                        <input formControlName="rut" type="text" class="form-control" (input)="changeRut()" formatRut required>
                                        <span *ngIf="activateRutHelper" class="help-block">Rut no valido</span>
                                    </div>
                                </div>
                                <ng-container *ngIf="consultacredencialesSinc">
                                    <div class="col-sm-12 mt-3">
                                        <label class="col-md-3 control-label" for="claveSii">{{credencialesSinc ? 'ClaveSii (opcional)' : 'ClaveSii'}}</label>
                                        <div class="col-md-6">
                                            <input formControlName="claveSii" id="claveSii" type="password" class="form-control">
                                            <div class="w-100">
                                                <app-error [control]="compraVentaReportForm.get('claveSii')"></app-error>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label class="col-md-3 control-label" title="Sincronizar credenciales">Sincronizar Credenciales</label>
                                            <div class="col-md-8 px-0">
                                                <div class="col-sm-12">
                                                    <label class="switch switch-primary">
                                                        <input type="checkbox" formControlName="sincronizar">
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <div class="col-sm-12">
                                                    <span 
                                                        class="d-inline-block mb-3 text-muted cursor-pointer cursor-pointer-info"
                                                        >
                                                        Al sincronizar credenciales se da acceso a nivel de organización.
                                                        <i class="fa fa-exclamation-circle text-warning" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </ng-container>
                            </div>
                        </div>
                        <div class="form-group form-actions container__actions">
                            <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i>
                                Crear Reporte</button>
                            <button type="button" (click)="clearForm()" class="btn btn-sm btn-warning"><i class="fa fa-repeat"></i>
                                Limpiar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<ng-container *ngIf="hasUserAccess && showReport">
    <div class="container-gtp no-print">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="btn-option">
                    <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                    <button [printTitle]='"CompraVenta_"+(rut | rutFormat)+"_"+today'
                        [useExistingCss]="true" printSectionId="print-section" ngxPrint
                        class="btn btn-primary">Descargar PDF</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row" id="print-section">
        <div class="col-12">
            <app-reporte-compra-venta-view #appreporteview [rut]="rut" [errores]="errores" [periodoInicial]="actualPeriodo" [hasBackLogic]="true" (backReport)="closeReport()"></app-reporte-compra-venta-view>
        </div>
    </div>
</ng-container>