<div class="card mt-3">
    <h5 class="card-header list-group-item cuadro-color">
        <div class="d-flex">
            <div class="flex-fill">
                <p class="pt-3">
                        Informe Deuda SBIF y Leasing
                    (
                    <ng-template #isText>
                        {{fecha.dato}}
                    </ng-template>

                    <ng-container *ngIf="fecha.tipo === 'fecha'; else isText">
                        al {{fecha.dato | date: 'dd-MM-YYYY'}}
                    </ng-container>)
                </p>

            </div>
            <div class="flex-fill">
                <button class="btn btn-primary float-right" (click)="verInforme()">{{showContent? 'Ocultar' :
                    'Ver'}}</button>
            </div>
        </div>
    </h5>
    <div class="card-body">
        <ng-container *ngIf="!showContent; else contenido">
            <div class="row">
                <div class="col-sm-6">
                    <form>
                        <ng-container *ngFor="let data of dataPersonas">

                            <ng-container *ngFor="let persona of data.empresaSocios; let i = index">
                                <ng-template #socios>
                                    <div class="form-group" *ngIf="persona.data.length > 0 ">
                                        <fieldset>
                                            <legend> Seleccionar {{persona.tipoPersona}}</legend>
                                            <div class="row box-scroll">
                                                <div class="col-12" *ngFor="let item of persona.data; let e = index">
                                                    <div class="checkbox-wrapper">
                                                        <input type="checkbox" [name]="item.Nombre" [id]="'socio'+e"
                                                            (change)="onFirstCheckboxChange(item, $event, 'partner')"
                                                            [disabled]="checkUserBloq(item.Rut)">
                                                        <label class="checkbox-label" [for]="'socio'+e"
                                                            [ngClass]="{'disabled': checkUserBloq(item.Rut)}">
                                                            <span class="material-icons">person_pin</span>
                                                            {{item.Nombre}}
                                                            {{item.Rut}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </ng-template>

                                <ng-container *ngIf="i === 0; else socios">
                                    <div class="form-group" *ngIf="persona.data.length > 0">
                                        <fieldset>
                                            <legend> Seleccionar</legend>
                                            <div class="row box-scroll">
                                                <div class="col-12" *ngFor="let item of persona.data; let e = index">
                                                    <div class="checkbox-wrapper">
                                                        <input type="checkbox" [name]="item.NombreORazonSocial"
                                                            [id]="'empresa'+e"
                                                            (change)="onFirstCheckboxChange(item, $event, 'primary')"
                                                            [disabled]="checkUserBloq(item.Rut)">
                                                        <label class="checkbox-label" [for]="'empresa'+e"
                                                            [ngClass]="{'disabled': checkUserBloq(item.Rut)}">
                                                            <span class="material-icons">corporate_fare</span>
                                                            {{item.NombreORazonSocial}}
                                                            {{item.Rut}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </form>
                </div>

                <div class="col-sm-6">
                    <form (ngSubmit)="onSubmit()">
                        <fieldset>
                            <legend> Obtener Informe para</legend>
                            <ng-container *ngIf="boxCheck.length > 0; else notCheckBox">
                                <div class="box-scroll">
                                    <div class="upload mb-5" *ngFor="let person of boxCheck; let i = index">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group mb-3 group-file">
                                                    <div class="file-box">
                                                        <div class="media">
                                                            <span class="material-icons" [ngClass]="{'success':
                                                            seePersonExist(person, 'sbif').fileName}">folder</span>
                                                            <div class="media-body">
                                                                <p> Deuda Sbif</p>
                                                                <p> {{person.Nombre? person.Nombre: person.NombreORazonSocial}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        
                                                        <ng-container *ngIf="!checkUserIfSend(person, 'sbif')">
                                                        <label class="uploadbutton">
                                                            <input class="input-file" type="file"[id]="'fileInputsbif'+i"
                                                                (change)="handleUpload($event, person, 'sbif', 'fileInputsbif'+i)" accept=".pdf" />
                                                            <div class="button btn">Cargar Nuevo Informe</div>
                                                            <div class='input-file-text'
                                                                [ngClass]="{'text-success': seePersonExist(person, 'sbif')}">
                                                                {{seePersonExist(person, 'sbif').fileName || 'Subir Archivo'}}
                                                            </div>
                                                            <span class="material-icons close-icon pull-right"  (click)="reset('fileInputsbif'+i)"
                                                            *ngIf="seePersonExist(person, 'sbif').fileName">close</span>
                                                        </label>
                                                    </ng-container>
                                                    </div>
                                                    <div [ngClass]="{'success': seePersonExist(person, 'sbif')}" class="file-error">
                                                        <ng-template #sendSbif>
                                                            <p>
                                                                Su archivo fue subido exitosamente
                                                            </p>
                                                        </ng-template>
                                                         <ng-container *ngIf="!checkUserIfSend(person, 'sbif'); else sendSbif">
                                                         {{!seePersonExist(person, 'sbif')?'Archivo es requerido': 'Archivo preparado para subir'}}
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="person.type === 'primary'">
                                            <div class="col-sm-6">
                                                <div class="form-group mb-3 group-file">
                                                    <div class="file-box">

                                                        <div class="media">
                                                            <span class="material-icons" [ngClass]="{'success':
                                                            seePersonExist(person, 'leasing').fileName}">folder</span>
                                                            <div class="media-body">
                                                                <p>  Leasing-SBIF</p>
                                                                <p> {{person.Nombre? person.Nombre: person.NombreORazonSocial}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <ng-container *ngIf="!checkUserIfSend(person, 'leasing')">
                                                            <label class="uploadbutton">
                                                                <input class="input-file" type="file" [id]="'fileInputleasing'+i"
                                                                    (change)="handleUpload($event, person, 'leasing', 'fileInputleasing'+i)" accept=".pdf" />
                                                                <div class="button btn">Cargar Nuevo Informe</div>
                                                                <div class='input-file-text'
                                                                    [ngClass]="{'text-success': seePersonExist(person, 'leasing')}">
                                                                    {{seePersonExist(person, 'leasing').fileName || 'Subir Archivo'}}
                                                                </div>
                                                                <span class="material-icons close-icon pull-right" *ngIf="seePersonExist(person, 'leasing').fileName"
                                                                (click)="reset('fileInputleasing'+i)">close</span>
                                                            </label>
                                                         </ng-container>
                                                    </div>
                                                    <div [ngClass]="{'success': seePersonExist(person, 'leasing')}" class="file-error">
                                                        <ng-template #sendLeasing>
                                                                Su archivo fue subido exitosamente
                                                        </ng-template>
                                                        <ng-container *ngIf="!checkUserIfSend(person, 'leasing'); else sendLeasing">
                                                            {{!seePersonExist(person, 'leasing')?'Archivo es requerido': 'Archivo preparado para subir'}}
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-template #notCheckBox>
                                <p> Sin informacion para obtener informe</p>
                            </ng-template>
                        </fieldset>
                        <div class="col-12 text-center mt-5">
                            <button type="submit" class="btn btn-sm btn-warning">
                                Obtener Informe
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </ng-container>

        <ng-template #contenido>
                 <ng-container *ngIf="informeSbif.length > 0">
                
                <p class="text-center title-deuda">Deuda Sbif</p>
                <div class="row">
                    <ng-container *ngFor="let group of informeSbif">
                        <div class="col-sm-6">
                            <div class="table-responsive">
                                <table class="table  table-bordered">
                                    <tbody *ngFor="let obj of group">
                                        <tr *ngFor="let item of obj">
                                            <th>
                                                {{listadoReport[item[objectKeys(item)[0]].Item]?
                                                    listadoReport[item[objectKeys(item)[0]].Item]:
                                                    item[objectKeys(item)[0]].Item}}
                                            </th>
                                            <td>
                                                <ng-template #fechaReport>
                                                    <span class="text-capitalize"> {{ item[objectKeys(item)[0]].Valor | date: 'LLL'}}</span>
                                                    {{ item[objectKeys(item)[0]].Valor | date: 'YYYY'}}
                                                    
                                                </ng-template>
                                                
                                                <ng-container *ngIf="item[objectKeys(item)[0]].Item !== 'fecha'; else fechaReport">
                                                    {{item[objectKeys(item)[0]].Valor | number: '1.0-0'}}
                                                </ng-container>
                                                
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="informeLeasing.length > 0">
                <p class="text-center title-deuda"> Leasing-SBIF</p>
                <div class="row">
                    <ng-container *ngFor="let group of informeLeasing">
                        <div class="col-sm-6">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <tbody *ngFor="let obj of group">
                                        <tr *ngFor="let item of obj">
                                            <th>
                                                {{listadoReport[item[objectKeys(item)[0]].Item]?
                                                    listadoReport[item[objectKeys(item)[0]].Item]:
                                                    item[objectKeys(item)[0]].Item}}
                                            </th>
                                            <td>
                                                <ng-template #fechaReport>
                                                   <span class="text-capitalize"> {{ item[objectKeys(item)[0]].Valor | date: 'LLL'}}</span>
                                                    {{ item[objectKeys(item)[0]].Valor | date: 'YYYY'}}
                                                    
                                                </ng-template>
                                                
                                                <ng-container *ngIf="item[objectKeys(item)[0]].Item !== 'fecha'; else fechaReport">
                                                    {{item[objectKeys(item)[0]].Valor | number: '1.0-0'}}
                                                </ng-container>
                                                
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-template>

        <div class="form-group mt-5">
            <div class="row" *ngIf="seeContent">
                <div class="col-sm-6 mx-auto text-center">
                    <button type="button" class="btn btn-sm btn-primary" (click)="changeStep()">
                        Continuar
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>