export const BienesRaicesText = {
    'rol_predio': 'Rol Predio',
    'rol_manzana': 'Rol Manzana',
    'cod_comuna': 'Código Comuna',
    'direccion': 'Dirección',
    'comuna': 'Comuna',
    'ciudad': 'Ciudad',
    'region': 'Región',
    'serie': 'Serie',
    'ubicacion': 'Ubicación',
    'destino': 'Destino',
    'avaluo_exento': 'Avalúo Exento ($)',
    'anio_exencion': 'Año Excención',
    'total_a_pagar': 'Total a Pagar ($)',
    'avaluo_total': 'Avalúo Total ($)'
}

export interface DatosSolicitante {
    Organizacion: string;
    Usuario: string;
}

export interface DatosBasicosSolicitud {
    Rut: string;
    DV: string;
    IDTransaccion: string;
    EstadoTransaccion: string;
    SubEstadoTransaccion: string;
    FechaReporte: Date;
    EstadoTransaccionMapeada?: string;
    FechaFormatted?: string;
}

export interface IListBienesRaices {
    datosSolicitante: DatosSolicitante;
    datosBasicosSolicitud: DatosBasicosSolicitud;
}

export interface DatosSolicitante {
    Organizacion: string;
    Usuario: string;
}

export interface DatosBasicosSolicitud {
    Rut: string;
    DV: string;
    IDTransaccion: string;
    EstadoTransaccion: string;
    SubEstadoTransaccion: string;
    FechaReporte: Date;
    EstadoTransaccionMapeada?: string;
    FechaFormatted?: string;
}

export interface ListadoBienesRaice {
    rut: string;
    dv: string;
    nombre: string;
    cod_comuna: string;
    rol_manzana: string;
    rol_predio: string;
    direccion: string;
    comuna: string;
    ciudad: string;
    region: string;
    serie: string;
    ubicacion: string;
    destino: string;
    avaluo_total: string;
    total_a_pagar: string;
    avaluo_exento: string;
    anio_exencion: string;
    latitud: string;
    longitud: string;
}

export interface BienesRaices {
    listadoBienesRaices: ListadoBienesRaice[];
}

export interface BienesraicesReporte {
    datosSolicitante: DatosSolicitante;
    datosBasicosSolicitud: DatosBasicosSolicitud;
    bienesRaices: BienesRaices;
}

export interface IReportBienesRaices {
    bienesraicesReporte: BienesraicesReporte;
}