export const listadoSolicitud = {
    'numSolicitud': 'N° Solicitud',
    'EstadoTransaccion': 'Estatus',
    'Modelo': 'Tipo de Solicitud',
    'date': 'Fecha',
    'search': 'Ver',
    'edit': 'Editar',
    'trash': 'Eliminar'
};

export const listadoSolicitudCteEmpresa = {
    'nombre': 'nombre',
    'rut': 'rut',
    'date': 'Fecha'
};

export const listadoReport = {
    creditoIndirectos: 'Crédito Indirectos',
    'Impagos>=30<3anos': 'Impagos >=30 <3años',
    'Impagos>=3anos': 'Impagos >=3 años',
    creditosDirectos: 'Créditos Directos',
    'AldiaeImp<30dias': 'Al día e Imp. <30días',
    'Impagos>=30=<90dias': 'Impagos >=30 <90días',
    'Impagos>=90<180dias': 'Impagos >=90 <180días',
    'Impagos>=180=<3anos': 'Impagos >=180 <3años',
    CreditosContingentes: 'Créditos Contingentes',
    CreditosdeConsumo: 'Créditos de Consumo',
    CreditosComerciales: 'Créditos Comerciales',
    CreditosVivienda: 'Créditos Vivienda',
    LineasDeCreditoDisponible: 'Línea de Crédito Disponible',
    OperacFinancieras: 'Operac. Financieras',
    VigenteMExt: 'Vigente M.Ext',
    VencidaMExt: 'Vencida M.Ext.',
    NInstconConsumo: 'Nº Inst. con Consumo',
    InstosDeudaAdqs: 'Instos. Deuda Adqs.',
    fecha: 'Fecha',
    DeudaDirectaVigente: 'Deuda Directa Vigente',
    NumerodeAcreedores: 'Numero de Acreedores',
    'PlazoPromedio(Mes)': 'Plazo Promedio (Mes)',
    'Morosa>30Dias': 'Morosa > 30 Dias',
    'Vencida>90Dias': 'Vencida > 90 Dias',
    'Castigada>360Dias': 'Castigada > 360 Dias',
    'TotalDeudaDirecta': 'Total Deuda Directa',
    'DeudaIndirectaVigente': 'Deuda Indirecta Vigente',
    'TotalDeudaIndirecta': 'Total Deuda Indirecta',
    // resultado: 'Línea de Crédito Disponible'
}

export const listadoUtilidades = {
    lcUtilizada: 'LC utilizada (Por línea de negocio)',
    lcAprobada: 'LC aprobada (Total)',
    lcUtilAprobada: 'LC útil/LC Arpobada (Por línea de negocio)',
    lcSolicitada: 'LC Solicitada'
}

export const listadoFacturacionHistorica = {
    date: 'Fecha',
    arriendoD: 'Arriendo Diario',
    arriendoM: 'Arriendo Maquinarias',
    autycam: 'Autycam'
}

export const listadoFlujosFuturos = {
    date: 'Fecha',
    Autycam: 'Autycam',
    Autycam1: 'Autycam1',
    Autycam3: 'Autycam3',
    NuevoNegocio: 'Nuevo negocio',
    Total: 'Total',
}

export const listadoLineaCredito = {
    nTickets: 'N° Tickets',
    NombreORazonSocial: 'Razón social',
    rut: 'Rut'
}

export const listadoLineaCreditoDetalle = {
    lineaDeCredito: 'Línea de Crédito',
    ventaNueva: 'Venta Nueva',
    renegociacion: 'Renegociación',
}

export const deudaSbifLeasing = {
    'periodo': 'Periodo',
    'castigadadirecta': 'Castigada Dir.',
    'castigadaindirecta': 'Castigada Indir.',
    'credcontingente': 'Contingente',
    'diracreedor': 'Dir.Consumo/ACR',
    'dircomercial': 'Dir.Comercial',
    'dirhipotecaria': 'Dir.Hipotecaria',
    'dirinversion': 'Dir.Inv.Financiera',
    'dirmorosa': 'Dir.Morosa',
    'diroperacionpacto': 'Dir.Ope.C/Pacto',
    'dirven180mayor3year': 'Dir. Venc. 180 > 3 años',
    'dirvencida': 'Dir.Vencida',
    'dirvigente': 'Dir.Vigente',
    'indirectaven': 'Indir.Vencida',
    'indirectavig': 'Indir.Vigente',
    'leasingmor': 'Leasing Morosa',
    'leasingvig': 'Leasing Vigente',
    'lineacredito': 'L.Cre.Disp.N.U',
    'monexvencida': 'Monex Vencida',
    'monexvigente': 'Monex Vigente',
}

export const balanceReporteFullInformacionBalance = {
    'rut': 'Rut',
    'nombre': 'Nombre o Razón Social',
    'periodo' : 'Periodo',
    'folio' : 'Folio',
    'fecha' : 'Fecha',
    'nMeses' : 'Número de Meses',
    'actividadEconomica' : 'Actividad Económica',
    'sector' : 'Sector',
    'subSector' : 'SubSector Económico',
    'tamano' : 'Tamaño',
    'rating' : 'Rating',
    'glosa' : 'Glosa',
    'codActividad': 'Cod. Actividad Económica',
}

export const balanceReporteFullResumenFinanciero = {
    'ingresosdelaExplotacion': 'VENTAS NETAS',
    'utilidadneta': 'UTILIDAD NETA',
    'utilidadVentas': 'UTILIDAD NETA / VENTAS',
    'razonCorriente': 'INDICE DE LIQUIDEZ',
    'permanenciaCtasporCobrar': 'PROM. COBRANZA',
    'permanenciadeInventarios': 'PROM. INVENTARIO',
    'totalactivos': 'TOTAL ACTIVOS',
    'totalactivosfijos': 'TOTAL ACTIVO FIJO NETO',
    'totalpasivoexigible': 'TOTAL PASIVO EXIGIBLE',
    'totalpatrimonio': 'PATRIMONIO NETO',
    'leverage': 'INDICE ENDEUDAMIENTO',
    'ebitda': 'EBITDA',
    'ebitdagf': 'EBITDA / GF (veces)',
}

export const balanceReporteFullActivos = {
    'disponible': 'Disponible',
    'valoresNegociables': 'Valores Negociables',
    'cuentasporCobrardelGiro': 'Cuentas por Cobrar del Giro ',
    'inventario': 'Inventario',
    'impuestosporRecuperar': 'Impuestos por Recuperar',
    'deudoresVarios': 'Deudores Varios',
    'cuentasporCobrarEERROperacionalCP': 'Cuentas por Cobrar EERR Operacional C/P',
    'otrosActivosCirculantes': 'Otros Activos Circulantes ',
    'totalactivocirculante': 'TOTAL ACTIVO CIRCULANTE',
    'terrenosyBienesRaíces': 'Terrenos y Bienes Raíces',
    'maquinariasEquiposyVehículos': 'Maquinarias, Equipos y Vehículos',
    'activosenLeasing': 'Activos en Leasing',
    'otrosActivosFijos': 'Otros Activos Fijos',
    'depreciaciónAcumulada': '(Depreciación Acumulada)',
    'totalactivosfijos': 'TOTAL ACTIVOS FIJOS',
    'cuentasporCobrarEERROperacionalLP': 'Cuentas por Cobrar EERR Operacional L/P',
    'inversiónenEERRySociedades': 'Inversión en EERR y Sociedades',
    'intangibles': 'Intangibles',
    'otrosActivos': 'Otros Activos',
    'totalotrosactivospermanentes': 'TOTAL OTROS ACTIVOS PERMANENTES',
    'totalactivos': 'TOTAL ACTIVOS'
}

export const balanceReporteFullPasivos = {
    'bancosCortoPlazo': 'Bancos Corto Plazo',
    'bancosLPporcionCP': 'Bancos LP porción CP',
    'leasingLPporcionCP': 'Leasing LP porción CP',
    'cartasdeCreditosPagaréOtraDFCP': 'Cartas de Créditos, Pagaré, Otra DF CP',
    'documentosyCuentasporPagardeGiro': 'Documentos y Cuentas por Pagar de Giro',
    'variosAcreedores': 'Varios Acreedores',
    'cuentasporPagarEERRySocios': 'Cuentas por Pagar EERR y Socios',
    'provisionesRetencioneseImpuestos': 'Provisiones, Retenciones e Impuestos',
    'otrosPasivosCirculantes': 'Otros Pasivos Circulantes',
    'totalpasivoscirculantes': 'TOTAL PASIVOS CIRCULANTES',
    'bancosLP': 'Bancos LP',
    'leasingLP': 'Leasing LP',
    'acreedoresVariosLP': 'Acreedores Varios L/P',
    'cuentasporPagarEERRySociosLP': 'Cuentas por Pagar EERR y Socios LP',
    'otrosPasivosLargoPlazo': 'Otros Pasivos Largo Plazo',
    'totalpasivolargoplazo': 'TOTAL PASIVO LARGO PLAZO',
    'totalpasivoexigible': 'TOTAL PASIVO EXIGIBLE',
    'interesMinoritario': 'Interés Minoritario',
    'capitalPagado': 'Capital Pagado',
    'reservas': 'Reservas',
    'utilidadesAcumuladas': 'Utilidades Acumuladas',
    'utilidaddelEjercicio': 'Utilidad del Ejercicio',
    'retiros': 'Retiros',
    'totalpatrimonio': 'TOTAL PATRIMONIO',
    'totalpasivos': 'TOTAL PASIVOS'
}

export const balanceReporteFullEstadoResultados = {
    'ingresosdelaExplotacion': 'Ingresos de la Explotación',
    'costodeExplotacion': '(Costo de Explotación)',
    'resultadobruto': 'RESULTADO BRUTO',
    'gastosAdministrativosyVentas': '(Gastos Administrativos y Ventas)',
    'ebitda': 'EBITDA',
    'depreciaciondelEjercicio': '(Depreciación del Ejercicio)',
    'resultadooperacional': 'EBIT (RESULTADO OPERACIONAL)',
    'ingresosFinancieros': 'Ingresos Financieros',
    'ingresosfueradelaExplotación': 'Ingresos fuera de la Explotación',
    'utilPerdInversionenEERR': 'Util.(Perd.) Inversión en EE.RR.',
    'egresosfueradeExplotacion': '(Egresos fuera de Explotación)',
    'gastosFinancieros': '(Gastos Financieros)',
    'diferenciadeCambio': 'Diferencia de Cambio (+/-)',
    'resantescorrecmon': 'RES. ANTES CORREC. MON.',
    'correccionMonetaria': 'Corrección Monetaria (+/-)',
    'interesMinoritario2': '(Interés Minoritario)',
    'utilidadantesdeimpuestos': 'UTILIDAD ANTES DE IMPUESTOS',
    'impuestoRenta': '(Impuesto Renta)',
    'utilidadneta': 'UTILIDAD NETA',
}

export const balanceReporteFullIndicadoresFinancieros = {
    'LiquidezyActividadTitle': 'Liquidez y Actividad', // solo para titulo
    'capitaldeTrabajo': 'Capital de Trabajo (M/MM$)',
    'razonCorriente': 'Razón Corriente (veces)',
    'testAcido': 'Test Ácido (veces)',
    'permanenciaCtasporCobrar': 'Permanencia Ctas. por Cobrar (días)',
    'permanenciadeInventarios': 'Permanencia de Inventarios (días)',
    'permdeCuentasporPagar': 'Perms. de Cuentas por Pagar (días)',
    'cicloOperacionalNeto': 'Ciclo Operacional Neto (días)',
    'variacionenActivos': 'Variación en Activos',
    'variacionenPasivoExigible': 'Variación en Pasivo Exigible',
    'RentabilidadTitle': 'Rentabilidad', // solo para titulo
    'utilidadVentas': 'Utilidad / Ventas',
    'ventaTotalIngresos': 'Venta / Total Ingresos',
    'ebitdaAnualizado': 'EBITDA (M/MM$) Anualizado si M < 12',
    'roe': 'ROE (%)',
    'roa': 'ROA (%)',
    'EndeudamientoyCobertura': 'Endeudamiento y Cobertura', // solo para titulo
    'dfebitda': 'DF / EBITDA (veces)',
    'ebitdagf': 'EBITDA / GF (veces)',
    'leverage': 'Leverage (veces)',
    'dfPatrimonio': 'DF / Patrimonio (veces)',
    'variacionenVentas': 'Variación en Ventas',
    'variacionenPatrimonio': 'Variación en Patrimonio'
}

export const ventasPeriodo = {
    'date': 'Mes',
    'ventasNetas': 'Ventas Netas M$',
    'ventasUF': 'Ventas Netas UF',
    'comprasNetas': 'Compras Netas M$',
    'comprasUF': 'Compras Netas UF',
    'diferencia': 'Margen M$',
    'porcVentas': '% NC / Ventas',
    'fechaPago': 'Fecha Pago',
    'tipoDeclaracion': 'Tipo Declaración',
    'postergado': 'Postergado',
    'var': 'Variación % Ventas',
}