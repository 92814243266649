import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ICarpetaTributariaElectronicaReporteModel, ReportFullFormValue } from 'src/app/models/reporte-full/reporte-full.model';
import { AlertService } from 'src/app/components/_alert';
import { ModalCarpetaService } from 'src/app/shared/services/modal-carpeta.service';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ReporteFullService } from 'src/app/shared/services/reporte-full.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { rutValidate } from 'rut-helpers';


@Component({
  selector: 'app-modal-consultar-carpeta-tributaria',
  templateUrl: './modal-consultar-carpeta-tributaria.component.html',
  styleUrls: ['./modal-consultar-carpeta-tributaria.component.scss']
})
export class ModalConsultarCarpetaTributariaComponent implements OnInit {
  @ViewChild('tabsRef', { static: false }) tabsRef?: TabsetComponent;
  @Input() rut: string | any;
  @Input() alertSuccess: boolean = false;
  @Input() alertError: boolean = false;
  private subscriptions: Subscription[] = [];
  public activateRutHelper = false;
  public reportFullConsultarForm: FormGroup;
  public reportFullFormSend!: FormGroup;
  public valueTab: string | undefined = 'Procesar';
  public tempFile: any;
  public today: string = '';
  public mensajeError: string = 'Se ha producido un error';
  @Output() reporteFullContent = new EventEmitter<ICarpetaTributariaElectronicaReporteModel>();
  @Output() service = new EventEmitter<string>();

  constructor(
    private modalService: ModalCarpetaService,
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private fb: UntypedFormBuilder,
    private reporteFullService: ReporteFullService,
    private utilsService: UtilsService
  ) {
    this.reportFullConsultarForm = this.fb.group({
      rut: ['', [Validators.required, gtpRutValidator()]]
    }) as FormGroupTyped<ReportFullFormValue>;
    this.onChanges();
  }

  ngOnInit() {
    this.initForm();
  }

  close() {
    this.valueTab = 'Procesar';
    this.initForm();
    this.modalService.close();
  }

  validaEstado(): boolean{
    const estado = this.modalService.watch();
    if(!estado){
      this.Limpiar();
    } 
    return estado;
  }

  initForm(): void {
    this.alertSuccess = false;
    this.alertError = false;
    this.mensajeError = 'Se ha producido un error';
    this.reportFullFormSend = this.fb.group({});
    const form = this.reportFullFormSend;

    this.valueTab === 'Procesar' ?
      (form.addControl('pdf', new UntypedFormControl('', [Validators.required])),
        form.addControl('validar', new UntypedFormControl(true, [Validators.required])))
      : this.valueTab === 'Crear' ?
        (form.addControl('email', new UntypedFormControl('', [Validators.required, Validators.email])),
        form.addControl('claveSii', new UntypedFormControl('', [Validators.required]))) :
        (
          form.addControl('codigoCte', new UntypedFormControl('', [Validators.required])),
          form.addControl('claveCte', new UntypedFormControl('', [Validators.required]))
        )
  }

  onSelect(data: TabDirective): void {
    this.valueTab = data.heading;
    this.tempFile = null;
    this.initForm();
  }

  onChanges(): void {
    this.subscriptions.push(this.reportFullConsultarForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    }));
  }
  
  submit() {
    this.alertSuccess = false;
    this.alertError = false;
    this.mensajeError = 'Se ha producido un error';

    if (!this.reportFullFormSend.valid) {
      this.utilsService.validateAllFormFields(this.reportFullFormSend);
      return;
    }
    const form = this.reportFullFormSend.getRawValue();
    const service = this.valueTab === 'Procesar' ? 'procesarReport' :
      this.valueTab === 'Crear' ? 'createReport' : 'descargarReport';

    let validar: boolean = true;
    if (this.valueTab === 'Procesar') {
      form.pdf = this.tempFile;
      validar = form.validar;
    }

    this.spinner.show();
    this.reporteFullService[service](this.rut, form, validar).subscribe(
      (response: any) => {
        this.spinner.hide();
        const trx = response?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.IDTransaccion;
        this.obtenerInformeCompleto(trx);
      },
      (error: any) => {
        this.spinner.hide();
        this.alertSuccess = false;
        this.alertError = true;
        this.mensajeError = (error.error.message || 'Ocurrio un problema');
      }
    );
  }

  obtenerInformeCompleto(trx: string) {
    this.spinner.show();
    this.reporteFullService.calcularVentas(this.rut, trx).subscribe(
      (response: ICarpetaTributariaElectronicaReporteModel) => {
        
        if (Object.keys(response).length > 0) {
          this.alertSuccess = true;
          this.alertError = false;
          this.reporteFullContent.emit(response);
        } else {
          this.alertSuccess = false;
          this.alertError = true;
          this.mensajeError = 'Se han detectado errores en el procesamiento de la Carpeta Tributaria solicitada.';
          this.service.emit('reporteFinanciero');
        }
        this.spinner.hide();
      },
      (error: any) => {
        this.alertSuccess = false;
        this.alertError = true;
        this.spinner.hide();
        this.mensajeError  = (error.error.message || 'Ocurrio un problema');
        this.service.emit('reporteFinanciero');
      }
    );
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.tempFile = reader?.result?.toString().split(',')[1];
    };
  }

  Limpiar(): void {
    this.reportFullFormSend.reset();
    this.reportFullFormSend?.get('validar')?.setValue(true);
    this.activateRutHelper = false;
    this.alertSuccess = false;
    this.alertError = false;
    this.mensajeError = 'Se ha producido un error';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.alertSuccess = false;
    this.alertError = false;
    this.mensajeError = 'Se ha producido un error';
  }

}
