import { Component, OnInit, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AlertService } from '../../_alert';

@Component({
  selector: 'app-resumen-condiciones-venta',
  templateUrl: './resumen-condiciones-venta.component.html',
  styleUrls: ['./resumen-condiciones-venta.component.scss']
})
export class ResumenCondicionesVentaComponent implements OnInit {
  @Input() rut: string = '';
  @Input() idTransaccion: string = '';
  @Input() tipoSolicitud: string = '';
  @Input() stepper!: number;
  @Input() condicionVenta: any;
  @Input() statusTX: string = '';
  public camposPersonalizados: any[] = [];
  public camposPersonalizadosTemp: any[] = [];
  public valuesCamposPersonalizados: any[] = [];
  public objectKeys = Object.keys;
  public condicionesVentasForm!: UntypedFormGroup;
  public fieldsReady: boolean = false;
  public subscriptions: Subscription[] = [];
  public subscriptionForm!: Subscription;
  public pasoActual: number = 0;

  constructor(private solicitudCreditoService: SolicitudCreditoService,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private alertsService: AlertService) { }

  ngOnInit(): void {
    !this.condicionVenta ? this.obtenerFlujo() : this.initForm();
  }

  initForm() {
    this.condicionesVentasForm = this.formBuilder.group({});
    !this.condicionVenta ? this.setControlsFromService() : this.setControlsFromBinding();
  }

  obtenerFlujo() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      const pasosCompleted = resp.stages.filter((e: any) => e.completed);
      const cantidad = pasosCompleted.length;
      this.pasoActual = Number(pasosCompleted[cantidad - 1].stageID) + 2;

      if (this.pasoActual < this.stepper) {
        this.spinner.hide();
        this.alertsService.error(`Debe completar el paso N° ${this.pasoActual} para poder continuar`);
        this.solicitudCreditoService.setStep(this.pasoActual);
      } else {

        if (this.pasoActual >= this.stepper) {
          this.solicitudCreditoService.obtenerCamposPersonalizados(this.rut, this.idTransaccion).subscribe(resp => {
            this.spinner.hide();
            this.camposPersonalizadosTemp = resp.SolicitudDeCredito?.Reportes?.camposPersonalizados?.Atributos;
            this.camposPersonalizados = this.solicitudCreditoService.getFieldsForm(this.tipoSolicitud);
            this.initForm();
          }, error => {
            this.alertsService.error(error.error.message || 'Ocurrio un Error');
            this.solicitudCreditoService.setStep('prev');
            this.spinner.hide();
          });
        }
      }
    }, (error: any) => {
      this.alertsService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    }));
  }

  /**
   * @description
   * Se agregan los campos al formulario segun el tipo de solicitud
   */
  setControlsFromService() {
    this.camposPersonalizados.forEach(control => {
      this.condicionesVentasForm.addControl(control, new UntypedFormControl('', Validators.required));
    });
    this.fieldsReady = true;
    this.eventForm();
    this.initBloqField(this.solicitudCreditoService.getConfigForm(this.tipoSolicitud));
  }

  /**
 * @description
 * Se agregan los campos al formulario segun el tipo de solicitud
 */
  setControlsFromBinding() {
    this.camposPersonalizadosTemp = this.condicionVenta.SolicitudDeCredito?.Reportes?.camposPersonalizados?.Atributos;
    this.camposPersonalizados = this.solicitudCreditoService.getFieldsForm(this.tipoSolicitud);
    this.camposPersonalizados.forEach(control => {
      this.condicionesVentasForm.addControl(control, new UntypedFormControl('', Validators.required));
    });
    this.fieldsReady = true;
    this.eventForm();
    this.setValueFieldFromBiding(this.condicionVenta);
  }

  /**
   * @description
   * Limpia espacios entre las palabras
   * @param control 
   * @returns 
   */
  removeSpacing(control: string): string {
    return control.replace(/\s/g, '');
  }

  /**
   * @description
   * Busca el campo personalizado por el nombre que recibe en la variable control
   * @param control 
   * @returns 
   */
  filterByControl(control: string) {
    return this.camposPersonalizadosTemp.filter(e => this.removeSpacing(e.nameAttribute) === control)[0];
  }

  /**
   * @description
   * Verifica si es un campo simple, doble o cuadruple
   * @param control 
   * @returns 
   */
  searchDoubleFields(control: string) {
    return this.solicitudCreditoService.getDoubleFields(control);
  }

  /**
   * @description
   * Envia el formulario
   * @returns 
   */
  onSubmit() {

    if (!this.condicionesVentasForm.valid) {
      this.solicitudCreditoService.validateAllFormFields(this.condicionesVentasForm);
      return;
    }
    this.spinner.show();
    const formTemp = this.condicionesVentasForm.getRawValue();
    const form: any[] = [];


    Object.keys(formTemp).forEach((control: any) => {
      const item = this.camposPersonalizadosTemp
        .filter(e => this.removeSpacing(e.nameAttribute) === control)[0];

      if (item) {
        form.push({
          nameAttribute: item.nameAttribute,
          value: formTemp[control]
        })
      }
    });

    !this.condicionVenta ? this.updateFieldsByService(form) : this.updateFieldsByBinding(form);
  }

  updateFieldsByService(form: any): void {
    this.solicitudCreditoService.updateCamposPersonalizados(form, this.rut, this.idTransaccion).subscribe(resp => {
      this.nextStep();
    }, error => {
      this.alertsService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    })
  }

  updateFieldsByBinding(form: any): void {
    this.solicitudCreditoService.editCamposPersonalizados(form, this.rut, this.idTransaccion).subscribe((resp) => {
      this.spinner.hide();
      this.solicitudCreditoService.updateReportSubject.next(resp);
    }, error => {
      this.alertsService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    })
  }

  nextStep() {
    this.solicitudCreditoService.setStep('next');
  }

  /**
   * @description
   * Activa la escucha de los cambios del formulario y habilita la seleccion de 
   * calculos segun el tipo de solicitud
   */
  eventForm() {
    const controls = this.condicionesVentasForm.controls;
    this.subscriptionForm = this.condicionesVentasForm.valueChanges.subscribe((form: any) => {
      this.tipoSolicitud === 'renegociacion' ? this.setValorCuotaRenegociacion(form) : this.tipoSolicitud === 'ventaNueva' ? this.setValorCuotaVentaNueva(this.condicionesVentasForm.getRawValue()) : this.setValorLineaCredito(form);
    });
  }

  /**
   * @description
   * Calculos del formulario en tipo de solicitud renegociacion
   * @param form 
   */
  setValorCuotaRenegociacion(form: any) {

    this.subscriptionForm.unsubscribe();
    if (this.validaNumber(form.Pie) && this.validaNumber(form.TotalDeuda)) {
      this.setRenegociacion();
      this.setValorCuotas();
    }

    if (this.validaNumber(form.TotalDeuda)) {
      this.setPercentsRenegociacion();
    }

    this.eventForm();
  }

  /**
 * @description
 * Calculos del formulario en tipo de solicitud venta nueva
 * @param form 
 */
  setValorCuotaVentaNueva(form: any) {
    const controls = this.condicionesVentasForm.controls;
    const setKeyValues = [
      'ValorEquipoIVAincluido',
      'IVAalIVA',
      'Financiamientointerno',
      'Pie',
      'MontoCuotasconInteres',
      'MontoafinanciarSalfa',
      'ValorCuota',
      'MontoCuotasconInteres',
      'MontoTotalInteres'
    ];
    const keyValues = [
      'ValorEquipoNeto',
      'Financiamientoexterno',
      'ValorEquipoIVAincluido',
      'PagoContado',
      'AplicaInteres',
      'TasaMensual',
      'MontoafinanciarSalfa',
      'IVAalIVA',
      'Financiamientointerno',
      'Pie',
      'CantidaddeCuotas',
      'Retoma',
      'AfectoaIVA',
      'TipoSeguro',
      'AprobaciondesegurodecreditoMontoenUF'
    ];

    this.subscriptionForm.unsubscribe();

    /**
     * Calcular ValorEquipoIVAincluido
     * @param setKeyValues[0] = ValorEquipoIVAincluido
     * @param keyValues[0] = ValorEquipoNeto
     * @param keyValues[12] = AfectoaIVA
     */
    if (controls[keyValues[12]].value === '1') {
      if (this.validaNumber(form[keyValues[0]])) {
        const percent = 19;
        const total = (((percent / 100) * Number(controls[keyValues[0]].value))) + Number(controls[keyValues[0]].value);
        controls[setKeyValues[0]].setValue(this.validaNegativoEInfinity(total).toFixed(0));
      }
    } else {
      controls[setKeyValues[0]].setValue(this.validaNegativoEInfinity(controls[keyValues[0]].value));
    }

    /**
     * Calcular IVAalIVA
     * @param setKeyValues[1] = IVAalIVA
     * @param keyValues[0] = ValorEquipoNeto
     */

    /**
     * Calcular Financiamientointerno
     * @param setKeyValues[1] = Financiamientointerno
     * @param keyValues[0] = ValorEquipoNeto
     * @param keyValues[1] = IVAalIVA
     */
    if (this.validaNumber(form[keyValues[0]]) && this.validaNumber(form[keyValues[1]])) {
      controls[setKeyValues[2]].setValue(this.validaNegativoEInfinity(Number(controls[keyValues[2]].value) - Number(controls[keyValues[1]].value)));
    }

    /**
     * Calcular Pie
     * @param setKeyValues[3] = Pie
     * @param keyValues[3] = PagoContado
     */
    if (this.validaNumber(form[keyValues[3]])) {
      controls[setKeyValues[3]].setValue((this.validaNegativoEInfinity(Number(controls[keyValues[3]].value))).toFixed(0));
    }

    /**
     * Calcular MontoafinanciarSalfa
     * @param setKeyValues[5] = MontoafinanciarSalfa
     * @param keyValues[8] = Financiamientointerno
     * @param keyValues[9] = Pie
     * @param keyValues[7] = IVAalIVA
     * @param keyValues[11] = Retoma
     */
    if (this.validaNumber(form[keyValues[8]]) && this.validaNumber(form[keyValues[9]]) && this.validaNumber(form[keyValues[7]]) && this.validaNumber(form[keyValues[11]])) {
      controls[setKeyValues[5]].setValue(this.validaNegativoEInfinity(Number(controls[keyValues[8]].value) - Number(controls[keyValues[9]].value) - Number(controls[keyValues[7]].value) - Number(controls[keyValues[11]].value)));
    }

    /**
    * Calcular ValorCuota
    * @param setKeyValues[6] = ValorCuota
    * @param keyValues[6] = MontoafinanciarSalfa
    * @param keyValues[10] = CantidaddeCuotas
    */
    if (this.validaNumber(form[keyValues[6]]) && this.validaNumber(form[keyValues[10]])) {
      controls[setKeyValues[6]].setValue((this.validaNegativoEInfinity(Number(controls[keyValues[6]].value) / Number(controls[keyValues[10]].value))).toFixed(0));
    }

    if (form[keyValues[4]] === '1') {
      /**
      * Calcular ValorCuota
      * @param setKeyValues[7] = MontoCuotasconInteres
      * @param keyValues[5] = TasaMensual
      * @param keyValues[6] = MontoafinanciarSalfa
      * @param keyValues[10] = CantidaddeCuotas
      */
      if (this.validaNumber(form[keyValues[5]]) && this.validaNumber(form[keyValues[6]]) && this.validaNumber(form[keyValues[10]])) {
        controls[setKeyValues[7]].setValue(this.calcularInteres(Number(controls[keyValues[5]].value), Number(controls[keyValues[6]].value), Number(controls[keyValues[10]].value)));
        this.setMontoTotalInteresVentaNueva()
      }
    }

    if (form[keyValues[4]] === '2') {
      /**
      * Calcular ValorCuota
      * @param setKeyValues[7] = MontoCuotasconInteres
      * @param setKeyValues[8] = MontoTotalInteres
      */
      controls[setKeyValues[7]].setValue(0);
      controls[setKeyValues[8]].setValue(0);
    }

    /**
     * Calcular Tipo Seguro
     * @param keyValues[13] = TipoSeguro
     */
    if (this.validaNumber(form[keyValues[13]])) {
      this.updateTipoSeguro();
    }

    /**
     * Calcular Aprobacion de seguro de credito Monto en UF
     * @param keyValues[14] = AprobaciondesegurodecreditoMontoenUF
     */
    if (this.validaNumber(form[keyValues[14]])) {
      this.updateTipoClienteAsegurado();
    }

    this.setPercentsVentaNueva();

    this.eventForm();

  }

  /**
 * @description
 * Calculos del formulario en tipo de solicitud linea de credito
 * @param form 
 */
  setValorLineaCredito(form: any) {
    this.subscriptionForm.unsubscribe();
    if (form.TipoSeguro) {
      this.updateTipoSeguro();
    }

    if (form.AprobaciondesegurodecreditoMontoenUF) {
      this.updateTipoClienteAsegurado();
    }
    this.eventForm();
  }

  /**
  * @description
  * Calcula el campo renegociacion
  * @param tipoSeguro 
  */
  setRenegociacion(): void {
    const form = this.condicionesVentasForm.controls;
    const KeyValues = ['Renegociacion', 'TotalDeuda', 'Pie'];
    form[KeyValues[0]].setValue((this.validaNegativoEInfinity(Number(form[KeyValues[1]].value) - Number(form[KeyValues[2]].value))).toFixed(0));
    this.setMontoCuotasInteres()
  }

  /**
   * @description
   * Calcula el campo monto cuotas con interes
   * @param tipoSeguro 
   */
  setMontoCuotasInteres() {
    const keyValues = ['TasaMensual', 'Renegociacion', 'CantidaddeCuotas', 'MontoCuotasconInteres'];
    const controls = this.condicionesVentasForm.controls;

    if (this.validaNumber(controls[keyValues[1]].value) && this.validaNumber(controls[keyValues[2]].value) && this.validaNumber(controls[keyValues[0]].value)) {
      // validar si tiene valor y si es un string o number
      const firstValue = controls[keyValues[0]].value && typeof controls[keyValues[0]].value === 'string' ? Number(controls[keyValues[0]].value.replace(',', '.')) : Number(controls[keyValues[0]].value);
      controls[keyValues[3]].setValue(
        this.calcularInteres(
          firstValue,
          Number(controls[keyValues[1]].value),
          Number(controls[keyValues[2]].value)));
      this.setMontoTotalInteresRenegociacion();
    }
  }

  /**
   * @description
   * Calcula el campo valor cuotas
   * @param tipoSeguro 
   */
  setValorCuotas() {
    const keyValues = ['Renegociacion', 'CantidaddeCuotas', 'ValorCuota'];
    const controls = this.condicionesVentasForm.controls;

    if (this.validaNumber(controls[keyValues[0]].value) && this.validaNumber(controls[keyValues[1]].value)) {
      controls[keyValues[2]].setValue((this.validaNegativoEInfinity(Number(controls[keyValues[0]].value) / Number(controls[keyValues[1]].value))).toFixed(0));
    }
  }

  /**
   * @description
   * Calcula el campo monto total interes renegociacion
   * @param tipoSeguro 
   */
  setMontoTotalInteresRenegociacion() {
    const keyValues = ['MontoCuotasconInteres', 'CantidaddeCuotas', 'MontoTotalInteres'];
    const controls = this.condicionesVentasForm.controls;

    if (this.validaNumber(controls[keyValues[0]].value) && this.validaNumber(controls[keyValues[1]].value)) {
      controls[keyValues[2]].setValue((this.validaNegativoEInfinity(Number(controls[keyValues[0]].value) * Number(controls[keyValues[1]].value))).toFixed(0));
    }
  }

  /**
   * @description
   * Calcula el campo monto total interes venta nueva
   * @param tipoSeguro 
   */
  setMontoTotalInteresVentaNueva() {
    const keyValues = ['MontoCuotasconInteres', 'CantidaddeCuotas', 'MontoTotalInteres'];
    const controls = this.condicionesVentasForm.controls;

    if (this.validaNumber(controls[keyValues[0]].value) && this.validaNumber(controls[keyValues[1]].value)) {
      controls[keyValues[2]].setValue((this.validaNegativoEInfinity(Number(controls[keyValues[0]].value) * Number(controls[keyValues[1]].value))).toFixed(0));
    }
  }

  /**
   * @description
   * Calcula todos los porcentajes de los campos porcentuales de la solicitud de tipo renegociacion
   */
  setPercentsRenegociacion() {
    const keyValues = ['TotalDeuda', 'Pie', 'Renegociacion', 'ValorCuota'];
    const keyValuesSet = ['PorcentajeTotalDeuda', 'PorcentajeRenegociacion', 'PorcentajePie', 'PorcentajeValorCuota'];
    const controls = this.condicionesVentasForm.controls;

    if (this.validaNumber(controls[keyValues[0]].value)) {
      controls[keyValuesSet[0]].setValue(100);
    }

    if (this.validaNumber(controls[keyValues[0]].value) && this.validaNumber(controls[keyValues[1]].value)) {
      controls[keyValuesSet[1]].setValue((this.validaNegativoEInfinity((Number(controls[keyValues[1]].value) / Number(controls[keyValues[0]].value)) * 100)).toFixed(0));
    }

    if (this.validaNumber(controls[keyValues[0]].value) && this.validaNumber(controls[keyValues[2]].value)) {
      controls[keyValuesSet[2]].setValue((this.validaNegativoEInfinity((Number(controls[keyValues[2]].value) / Number(controls[keyValues[0]].value)) * 100)).toFixed(0));
    }

    if (this.validaNumber(controls[keyValues[0]].value) && this.validaNumber(controls[keyValues[2]].value)) {
      controls[keyValuesSet[3]].setValue((this.validaNegativoEInfinity((Number(controls[keyValues[3]].value) / Number(controls[keyValues[2]].value)) * 100)).toFixed(0));
    }
  }

  /**
   * @description
   * Calcula todos los porcentajes de los campos porcentuales de la solicitud de tipo renegociacion
   */
  setPercentsVentaNueva() {
    const keyValues = [
      'ValorEquipoIVAincluido',
      'ValorEquipoIVAincluido',
      'Financiamientoexterno',
      'Financiamientointerno',
      'Pie',
      'IVAalIVA',
      'PagoContado',
      'ValorCuota',
      'ValorEquipoNeto',
      'MontoafinanciarSalfa'
    ];
    const keyValuesSet = [
      'PorcentajeValorEquipoIVAincluido',
      'PorcentajeFinanciamientoexterno',
      'PorcentajeFinanciamientointerno',
      'PorcentajePie',
      'PorcentajeIVAalIVA',
      'PorcentajePagoContado',
      'PorcentajeValorCuota',
      'PorcentajeMontoafinanciarSalfa'
    ];
    const controls = this.condicionesVentasForm.controls;

    /**
     * @param keyValuesSet[0] = PorcentajeValorEquipoIVAincluido
     * @param keyValues[0] = ValorEquipoIVAincluido
     */
    if (this.validaNumber(controls[keyValues[0]].value)) {
      controls[keyValuesSet[0]].setValue(100);
    }

    /**
     * @param keyValuesSet[1] = PorcentajeFinanciamientoexterno
     * @param keyValues[1] = ValorEquipoIVAincluido
     * @param keyValues[2] = Financiamientoexterno
     */
    if (this.validaNumber(controls[keyValues[1]].value) && this.validaNumber(controls[keyValues[2]].value)) {
      controls[keyValuesSet[1]].setValue((this.validaNegativoEInfinity((Number(controls[keyValues[2]].value) / Number(controls[keyValues[1]].value)) * 100)).toFixed(0));
    }

    /**
     * @param keyValuesSet[2] = PorcentajeFinanciamientointerno
     * @param keyValues[1] = ValorEquipoIVAincluido
     * @param keyValues[3] = Financiamientointerno
     */
    if (this.validaNumber(controls[keyValues[1]].value) && this.validaNumber(controls[keyValues[3]].value)) {
      controls[keyValuesSet[2]].setValue((this.validaNegativoEInfinity((Number(controls[keyValues[3]].value) / Number(controls[keyValues[1]].value)) * 100)).toFixed(0));
    }

    /**
     * @param keyValuesSet[2] = PorcentajePie
     * @param keyValues[5] = IVAalIVA
     * @param keyValues[8] = ValorEquipoNeto
     * @param keyValues[4] = Pie
     * @param keyValues[3] = Financiamientointerno
     */
    if (controls[keyValues[5]].value > 0) {
      if (this.validaNumber(controls[keyValues[4]].value) && this.validaNumber(controls[keyValues[8]].value)) {
        controls[keyValuesSet[3]].setValue((this.validaNegativoEInfinity((Number(controls[keyValues[4]].value) / Number(controls[keyValues[8]].value)) * 100)).toFixed(0));
      }
    } else {
      if (this.validaNumber(controls[keyValues[4]].value) && this.validaNumber(controls[keyValues[3]].value)) {
        controls[keyValuesSet[3]].setValue((this.validaNegativoEInfinity((Number(controls[keyValues[4]].value) / Number(controls[keyValues[3]].value)) * 100)).toFixed(0));
      }
    }

    /**
     * @param keyValuesSet[4] = PorcentajeIVAalIVA
     * @param keyValues[8] = ValorEquipoNeto
     * @param keyValues[5] = IVAalIVA
     */
    if (this.validaNumber(controls[keyValues[5]].value) && this.validaNumber(controls[keyValues[8]].value)) {
      controls[keyValuesSet[4]].setValue((this.validaNegativoEInfinity((Number(controls[keyValues[5]].value) / Number(controls[keyValues[8]].value)) * 100)).toFixed(0));
    }

    /**
     * @param keyValuesSet[5] = PorcentajePagoContado
     * @param keyValues[1] = ValorEquipoIVAincluido
     * @param keyValues[6] = PagoContado
     * @param keyValues[5] = IVAalIVA
     * @param keyValues[8] = ValorEquipoNeto
     */
    if (controls[keyValues[5]].value > 0) {
      if (this.validaNumber(controls[keyValues[8]].value) && this.validaNumber(controls[keyValues[6]].value)) {
        controls[keyValuesSet[5]].setValue((this.validaNegativoEInfinity((Number(controls[keyValues[6]].value) / Number(controls[keyValues[8]].value)) * 100)).toFixed(0));
      }
    } else {
      if (this.validaNumber(controls[keyValues[9]].value) && this.validaNumber(controls[keyValues[1]].value)) {
        controls[keyValuesSet[5]].setValue((this.validaNegativoEInfinity((Number(controls[keyValues[6]].value) / Number(controls[keyValues[1]].value)) * 100)).toFixed(0));
      }
    }

    /**
     * @param keyValuesSet[6] = PorcentajeValorCuota
     * @param keyValues[0] = ValorEquipoIVAincluido
     * @param keyValues[7] = ValorCuota
     */
    if (this.validaNumber(controls[keyValues[0]].value) && this.validaNumber(controls[keyValues[7]].value)) {
      controls[keyValuesSet[6]].setValue((this.validaNegativoEInfinity((Number(controls[keyValues[7]].value) / Number(controls[keyValues[0]].value)) * 100)).toFixed(0));
    }

    /**
     * @param keyValuesSet[7] = PorcentajeMontoafinanciarSalfa
     * @param keyValues[8] = ValorEquipoNeto
     * @param keyValues[3] = Financiamientointerno
     * @param keyValues[5] = IVAalIVA
     * @param keyValues[9] = MontoafinanciarSalfa
     */
    if (controls[keyValues[5]].value > 0) {
      if (this.validaNumber(controls[keyValues[9]].value) && this.validaNumber(controls[keyValues[8]].value)) {
        controls[keyValuesSet[7]].setValue((this.validaNegativoEInfinity((Number(controls[keyValues[9]].value) / Number(controls[keyValues[8]].value)) * 100)).toFixed(0));
      }
    } else {
      if (this.validaNumber(controls[keyValues[9]].value) && this.validaNumber(controls[keyValues[3]].value)) {
        controls[keyValuesSet[7]].setValue((this.validaNegativoEInfinity((Number(controls[keyValues[9]].value) / Number(controls[keyValues[3]].value)) * 100)).toFixed(0));
      }
    }
  }

  /**
   * @description
   * Bloquea los campos del formulario segun listado de campos que reciba
   * @param fields 
   */
  initBloqField(fields: any[]) {
    const form = this.condicionesVentasForm.controls;
    fields.forEach(element => {
      form[element].disable();
    })
    this.condicionesVentasForm.updateValueAndValidity();
    if (this.pasoActual > this.stepper) {
      this.setValuesForm();
    }
  }

  /**
   * @description
   * Actualizar Tipo de seguro
   * @param tipoSeguro 
   */
  updateTipoSeguro(): void {
    const form = this.condicionesVentasForm.controls;
    const keyValues = ['AprobaciondesegurodecreditoMontoenUF', 'Tipodeclienteasegurado'];
    if (form['TipoSeguro'].value === '3') {
      keyValues.forEach(element => {
        form[element].disable();
        form[element].setValue('');
        form[element].setValidators(null);
      })
    } else {
      keyValues.forEach(element => {
        if (element !== 'Tipodeclienteasegurado') {
          form[element].enable();
          form[element].setValidators([Validators.required]);
        }
      })
    }
    this.condicionesVentasForm.updateValueAndValidity();
  }

  /**
   * @description
   * Actualizar Tipo cliente asegurado
   * @param aprobacion 
   */
  updateTipoClienteAsegurado(): void {
    const form = this.condicionesVentasForm.controls;
    const KeyValue = ['Tipodeclienteasegurado', 'AprobaciondesegurodecreditoMontoenUF', 'TipoSeguro'];
    const value = form[KeyValue[1]].value ? Number(form[KeyValue[1]].value) : '';
    if (form[KeyValue[2]].value === '2') {
      form[KeyValue[0]].setValue(!value ? '' : value <= 1500 ? 'InNominado' : 'Nominado');
    }

    if (form[KeyValue[2]].value === '1') {
      form[KeyValue[0]].setValue(!value ? '' : value <= 600 ? 'InNominado' : 'Nominado');
    }
  }

  /**
   * @description
   * Calcular los interes de la cuota
   * @param tasa 
   * @param monto 
   * @param cuotas 
   * @returns 
   */
  calcularInteres(tasa: number, monto: number, cuotas: number): string {
    tasa = tasa / 100;
    let total = this.validaNegativoEInfinity(monto * ((tasa * Math.pow(1 + tasa, cuotas)) / (Math.pow(1 + tasa, cuotas) - 1)));
    const result = total.toFixed(0);
    return result;
  }

  /**
   * @description
   * Setear los valores del formulario
   */
  setValuesForm(): void {

    this.spinner.show();
    this.solicitudCreditoService.obtenerValorCamposPersonalizados(this.rut, this.idTransaccion).subscribe(resp => {
      this.spinner.hide();
      this.setValueFieldFromService(resp);
    }, error => {
      this.alertsService.error(error.error.message || 'Ocurrio un Error');
      this.solicitudCreditoService.setStep('prev');
      this.spinner.hide();
    }
    )
  }

  setValueFieldFromService(values: any): void {
    const form = this.condicionesVentasForm.controls;
    const keyValue = ['TipoSeguro'];
    this.valuesCamposPersonalizados = values.SolicitudDeCredito?.Reportes?.camposPersonalizados?.Atributos || [];
    if (this.valuesCamposPersonalizados.length > 0) {
      const fieldsTemp = this.valuesCamposPersonalizados;

      fieldsTemp.forEach((control: any) => {
        if (form[this.removeSpacing(control.nameAttribute)]) {
          form[this.removeSpacing(control.nameAttribute)].setValue(
            control.value);
        }
      });
    }
    if (this.tipoSolicitud === 'ventaNueva') {
      form[keyValue[0]].setValue('3')
    }
  }

  setValueFieldFromBiding(values: any): void {
    const form = this.condicionesVentasForm.controls;
    this.valuesCamposPersonalizados = values.SolicitudDeCredito?.Reportes?.camposPersonalizados?.Atributos;
    if (this.valuesCamposPersonalizados.length > 0) {
      const fieldsTemp = this.valuesCamposPersonalizados;

      fieldsTemp.forEach((control: any) => {
        if (form[this.removeSpacing(control.nameAttribute)]) {
          form[this.removeSpacing(control.nameAttribute)].setValue(
            control.value);
        }
      });
    }
  }



  validarIfExist(control: string): boolean {
    if (this.camposPersonalizadosTemp
      .filter(e => this.removeSpacing(e.nameAttribute) === control).length > 0
    ) {
      return true;
    }
    return false;
  }

  findTextCamposPersonalizados(control: string): string {
    const formSociedad = this.condicionesVentasForm.controls[control].value;
    if (formSociedad) {
      const objSociedad = this.camposPersonalizadosTemp
        .find(e => this.removeSpacing(e.nameAttribute) === control);
      return objSociedad.allowedValues[formSociedad];
    }
    return '';
  }

  validaNumber(variable: any): boolean {
    return variable || variable == 0 ? true : false;
  }

  validaNegativoEInfinity(variable: any): number {
    return isNaN(variable) || variable < 0 || variable === Infinity ? 0 : variable;
  }

}