<div class="card mt-3">
    <h5 class="card-header list-group-item cuadro-color">
        Campos Personalizados
    </h5>
    <div class="card-body">

        <!-- Seleccion manual/automatico -->

        <div class="ml-2 mb-3">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label class="btn btn-outline-primary active" (click)="mostrarFormulario = true; initForm(); tempFile = {}; archivoAtributosCargado = false;">
                    <input type="radio" name="options" id="option1" autocomplete="off" checked> 
                    Manual
                </label>
                <label class="btn btn-outline-primary" (click)="mostrarFormulario = false; initFormArchivo(); archivoAtributosCargado = false;">
                    <input type="radio" name="options" id="option2" autocomplete="off"> 
                    Automático
                </label>
            </div>
        </div>

        <!-- Manual -->

        <ng-container *ngIf="fieldsReady && mostrarFormulario && camposPersonalizadosTemp.length > 0">

            <form [formGroup]="camposPersonalizadosForm">
                <ngx-masonry [updateLayout]="updateMasonryLayout">
                    <div ngxMasonryItem 
                    [className]="secciones.length === 1 ? 'card my-2 masonry-item item-card-1 m-2' : secciones.length === 2 ? 'card my-2 masonry-item item-card-2 m-2' : 'card my-2 masonry-item item-card m-2'"
                    *ngFor="let seccion of secciones"
                    >
                        <div class="card-header py-0">
                            <h4 class="my-2">
                                {{seccion}}
                            </h4>
                        </div>
                        <div class="card-body">

                            <div class="row" *ngFor="let campo of camposPersonalizadosTemp">

                                <ng-container *ngIf="seccion===campo.section && campo.creditRequestActive == true && campo.enabled == true">
                                    <div class="container-fluid">

                                        <div class="col-sm-12 col-md-4 my-1">
                                            {{campo.nameAttribute}}
                                        </div>
    
                                        <div class="col-sm-12 col-md-8 my-1" *ngIf="objectKeys(campo.allowedValues).length === 0">
                                            <input [attr.type]="campo.typeAttibute" class="form-control"
                                            [formControlName]="campo.nameAttribute">
                                            <div class="w-100 mb-2">
                                                <app-error [control]="camposPersonalizadosForm.get(campo.nameAttribute)" 
                                                [validarValid]="campo.typeAttibute === 'Number' ? true : false"
                                                [formato]="campo.formatAttribute"
                                                ></app-error>
                                            </div>
                                        </div>
                                        
                                        <ng-container *ngIf="objectKeys(campo.allowedValues).length > 0">
                                            <div class="col-sm-12 col-md-8 my-1">
                                                <select (click)="actualizaLayout()" class="form-control text-capitalize"
                                                    [formControlName]="campo.nameAttribute">
                                                    <option 
                                                    [attr.selected]="campo.defaultValue === '' ? true : null"
                                                    value="">Seleccione...</option>
                                                    <ng-container *ngFor="let item of objectKeys(campo.allowedValues); let i = index">
                                                        <option class="text-capitalize"
                                                            [attr.selected]="campo.defaultValue === item ? true : null"
                                                            [value]="item">
                                                            {{campo.allowedValues[item]}}
                                                        </option>
                                                    </ng-container>
                                                </select>
                                                <div class="w-100">
                                                    <app-error [control]="camposPersonalizadosForm.get(campo.nameAttribute)"></app-error>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ngx-masonry>

            </form>
            
        </ng-container>

        <!-- Automatico -->

        <ng-container *ngIf="!mostrarFormulario">

            <form [formGroup]="formularioArchivo">
                <div class="form-group ml-2 mt-5" *ngIf="!fileName; else fileUpload1">

                    <label class="uploadbutton">
                        <input class="input-file" formControlName="file" type="file" name="file" accept=".csv"
                            (change)="handleUpload($event)" id="file-csv-carga-automatica"/>
                        <div class="button btn">Nueva Carga</div>
                        <div class='input-file-text' [ngClass]="{'text-success': formularioArchivo.controls.file.value}">
                            {{formularioArchivo.controls.file.value || 'Subir Archivo'}}
                        </div>
                    </label>
                </div>
                <ng-template class="file-upload ml-2 mt-5" #fileUpload1>
                    Su archivo fue subido con exito
                </ng-template>
            </form>
            <div class="justify-content-center row">
                <div class="col-sm-6 mx-auto mt-3">
                    <fieldset>
                        <legend> Observaciones</legend>
                        <ng-template #sinAdvertencias>
                            Sin observaciones
                        </ng-template>

                        <div class="pb-5">
                            <ng-container *ngIf="advertenciasAtributos && advertenciasAtributos.length > 0; else sinAdvertencias">
                                Se {{advertenciasAtributos.length > 1? 'encontraron': 'encontro'}}
                                <strong> {{advertenciasAtributos.length}} </strong> {{advertenciasAtributos.length > 1? 'problemas':
                                'problema'}}
                                en el archivo
                            </ng-container>
                        </div>

                        <div class="form-group form-actions container__actions">
                            <button type="button" class="btn btn-sm btn-warning" (click)="showErrors()" 
                            *ngIf="advertenciasAtributos && advertenciasAtributos.length > 0">Ver
                                Errores</button>
                        </div>
                    </fieldset>
                </div>
            </div>
        </ng-container>

        <!-- Carga de archivo -->

        <ng-container *ngIf="cargaMasiva">
            <div class="col-12 mt-5 px-2"  *ngIf="(fieldsReady && mostrarFormulario && camposPersonalizadosTemp.length > 0) || !mostrarFormulario">
                <accordion [isAnimated]="true" class="m-0">
                    <accordion-group [isOpen]="false" panelClass="m-0" #accBalanceManualGroup>
                        <span accordion-heading>
                            <i class="fa" [ngClass]="{'fa-angle-down': accBalanceManualGroup?.isOpen, 'fa-angle-right': !accBalanceManualGroup?.isOpen}"></i>
                            Carga Masiva
                        </span>
    
                        <form [formGroup]="camposCSVForm">
                            <div class="form-group" *ngIf="!fileNameCamposCSV; else fileUploadCamposCSV">
            
                                <label class="uploadbutton">
                                    <input class="input-file" formControlName="file" type="file" name="file" accept=".csv"
                                        (change)="handleUploadCamposCSV($event)" />
                                    <div class="button btn">Nueva Carga</div>
                                    <div class='input-file-text' [ngClass]="{'text-success': camposCSVForm.controls.file.value}">
                                        {{camposCSVForm.controls.file.value || 'Subir Archivo'}}
                                    </div>
                                </label>

                                <button 
                                    class="btn" 
                                    *ngIf="camposCSVForm.controls.file.value"
                                    title="Eliminar archivo de carga masiva seleccionado"
                                    (click)="limpiaArchivoMasivo()"
                                >
                                    <i class="fa fa-trash text-danger"></i>
                                </button>

                            </div>
                            <ng-template class="file-upload" #fileUploadCamposCSV>
                                Su archivo fue subido con exito
                            </ng-template>
                        </form>
                        <div class="col-12">
                            <a 
                                id="descargaejemplo"
                                class="text-muted"
                                title="Descargar archivo"
                                href="../assets/files/CargaMasivaEjemplo.csv"
                                download
                            >
                                Descargar archivo de ejemplo
                                <i class="fa fa-download text-success align-middle mx-2" 
                                    aria-hidden="true"
                                ></i>
                            </a>
                        </div>
                        <div class="col-12">
                            <span 
                                class="d-inline-block mb-3 text-muted"
                                >
                                Archivo de carga debe ser csv con separador , y debe tener un tamaño máximo de 3MB
                                <i class="fa fa-exclamation-circle text-warning" aria-hidden="true"></i>
                            </span>
                        </div>
                        <div class="justify-content-center row">
                            <div class="col-sm-6 mx-auto mt-5 mb-5" *ngIf="objectKeys(listaCamposPersonalizados).length > 0">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <ng-container *ngIf="objectKeys(listaCamposPersonalizados).length > 0">
                                                <tr>
                                                    <th class="bg-azul"></th>
                                                    <th colspan="100%" class="bg-azul text-center">
                                                        Nombre Atributo
                                                    </th>
                                                </tr>
                                            </ng-container>
                                            
                                            <tr>
                                                <th class="bg-azul text-center">Filas</th>
                                                <ng-container *ngFor="let item of objectKeys(listaCamposPersonalizados); let i = index">
                                                    <ng-container *ngIf="listaCamposPersonalizados[item]?.enabled">
                                                        <th class="bg-atributos text-center">
                                                            {{item != 'undefined' ? (item || '') : ('')}}
                                                            <i class="fa fa-info-circle ml-1 text-danger cursor-pointer" title="{{obtenerDetalle(item)}}" aria-hidden="true"  *ngIf="obtenerDetalle(item) !== ''"></i>
                                                        </th>
                                                    </ng-container>
                                                </ng-container>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let number of largoValuesCamposPersonalizados">
                                                <tr>
                                                    <td class="text-center">
                                                        {{number + 1}}
                                                    </td>
                                                    <ng-container *ngFor="let item of objectKeys(listaCamposPersonalizados); let i = index">
                                                        <ng-container *ngIf="listaCamposPersonalizados[item]?.enabled">
                                                            
                                                            <ng-container *ngIf="listaCamposPersonalizados[item]?.values && listaCamposPersonalizados[item]?.values.length > 0; else elseValues">
                                                                <td class="text-center">{{listaCamposPersonalizados[item]?.values[number] || listaCamposPersonalizados[item]?.values[number] == '0' ? (listaCamposPersonalizados[item]?.values[number]) : ('')}}</td>
                                                            </ng-container>
                                                            <ng-template #elseValues>
                                                                <td class="text-center"></td>
                                                            </ng-template>

                                                        </ng-container>
                                                    </ng-container>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
    
                    </accordion-group>
                </accordion>
            </div>
        </ng-container>

        <!-- Botones -->

        <div class="col-12 mt-5" *ngIf="fieldsReady && mostrarFormulario && camposPersonalizadosTemp.length > 0">            
            <div class="form-group form-actions d-flex justify-content-center">
                <button class="btn btn-sm btn-warning mx-2" (click)="onSubmit('MANUAL')">
                    Guardar Cambios
                </button>
                <button class="btn btn-sm btn-primary mx-2" (click)="changeStep()" [disabled]="!stageCompleted">
                    Continuar
                </button>
            </div>
        </div>

        <div class="col-12 mt-5" *ngIf="!mostrarFormulario">
            <div class="form-group form-actions d-flex justify-content-center">
                <button type="submit" class="btn btn-sm btn-warning mx-2"
                    (click)="onSubmit('AUTOMATICA')">Guardar Cambios
                </button>
                <button class="btn btn-sm btn-primary mx-2" (click)="changeStep()" [disabled]="!stageCompleted">
                    Continuar
                </button>
            </div>
        </div>

    </div>