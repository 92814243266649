import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { obtenerPeriodos, IFormularioF29FullReporteModel } from 'src/app/models/formulario-f29/formulario-f29.model';
import { FormularioF29Service } from 'src/app/shared/services/formulario-f29.service';
import { AlertService } from 'src/app/components/_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { RUT } from 'src/app/shared/utils/rut';
import { UtilsService } from 'src/app/shared/services/utils.service';


interface ReportF29FormValue {
  rut: string;
}

@Component({
  selector: 'app-formulario-f29-buscar',
  templateUrl: './formulario-f29-buscar.component.html',
  styleUrls: ['./formulario-f29-buscar.component.scss']
})
export class FormularioF29BuscarComponent implements OnInit {
  public title = 'Buscar'
  public activateRutHelper = false;
  private access: string[] = [ESystemAccess.REPORTE_F29_BUSCAR, ESystemProfileList.WEB];
  public hasUserAccess = false;
  public reporteFullContent!: any | null;
  public currentPage = 0;
  public searchReportDataSource: IFormularioF29FullReporteModel[] = [];
  private subscriptions: Subscription[] = [];
  public showReport = false;
  public currentItem = env.initItemPerPage;
  public today = '';
  public periodosSeleccionados: any[] = [];
  @Input() rut: string | any;
  @Input() data: any;
  @Output() verF29 = new EventEmitter<boolean>();
  @Output() verCrearF29 = new EventEmitter<boolean>();
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef> | undefined;
  public responseApi: any;
  public textoSinInfo: string = ''; 

  public searchF29Form: FormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _sessionService: SessionService,
    public alertService: AlertService,
    private spinner: NgxSpinnerService,
    private formularioF29Service: FormularioF29Service,
    private utilsService: UtilsService
    ) { 
      this.searchF29Form = this.formBuilder.group({
        rut: ['', [Validators.required, gtpRutValidator()]],
      }) as FormGroupTyped<ReportF29FormValue>;
  
      this.onChanges();
    }

  ngOnInit(): void{ 
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
    this.setToday();
    this.searchReport(this.data);
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  public backToSearchForm(): void {
    this.searchReportDataSource = [];
  }

  public searchReport(data: any): void {
    this.spinner.show();
    this.periodosSeleccionados = [];
    this.responseApi = data;
    this.spinner.hide();
    data.length > 0?  (
    this.searchReportDataSource = obtenerPeriodos(data),
    this.showReport = false) : 
    this.textoSinInfo = 'Sin Información.';
    
  }

  onChanges(): void {
    this.searchF29Form.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  public clearForm(): void {
    this.searchF29Form.reset();
    this.activateRutHelper = false;
  }

  public onCheckChange(event: any): void {
    if(event.target.checked){
      this.periodosSeleccionados.push(event.target.value);
    } else {
      this.periodosSeleccionados.splice(this.periodosSeleccionados.indexOf(event.target.value),1)
    }
  }

  public limpiarCheckbox(): void {
    this.periodosSeleccionados = [];
    this.checkboxes?.forEach((element) => {
      element.nativeElement.checked = false;
    });
  }

  public displayReport(): void {
    let periodos: any[] = []
    this.spinner.show();
    if(this.periodosSeleccionados.length > 0){
      periodos = this.periodosSeleccionados;
    } else {
      this.checkboxes?.forEach((element) => {
        periodos.push(element.nativeElement.value);
      });
    }
    this.reporteFullContent = [];
    this.reporteFullContent = this.formularioF29Service.obtenerReporteFiltrado(this.responseApi, periodos);
    this.reporteFullContent = this.formularioF29Service.revisionMeses(this.reporteFullContent);
    if(this.reporteFullContent.length > 0){
      this.spinner.hide();
      this.showReport = true;
      this.verF29.emit(this.showReport); // Le indica al padre que ingresa a ver el reporte
      this.periodosSeleccionados = [];
    } else {
      this.textoSinInfo = 'Sin Información.';
      this.spinner.hide();
    }
  }

  public closeReport(): void {
    this.reporteFullContent = null;
    this.showReport = false;
    this.verF29.emit(this.showReport); // Le indica al padre que ingresa a ver el reporte
    this.utilsService.changeSpanExtra('');
  }

  public crearBoton(): void{
    this.verCrearF29.emit(true); // Le indica al padre que se visualice la creacion de un reporte
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
