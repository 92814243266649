import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/components/_alert';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SessionService } from 'src/app/shared/services/session.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { rutValidate } from 'rut-helpers';
import { environment as env } from 'src/environments/environment';
import { MallaSocietariaService } from 'src/app/shared/services/malla-societaria.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

interface mallaFormValues {
  rut: string;
}

@Component({
  selector: 'app-reporte-malla-societaria-buscar',
  templateUrl: './reporte-malla-societaria-buscar.component.html',
  styleUrls: ['./reporte-malla-societaria-buscar.component.scss']
})
export class ReporteMallaSocietariaBuscarComponent implements OnInit {
  public objectKeys = Object.keys;

  private access: string[] = [ESystemAccess.REPORTE_MALLA_SOCIETARIA_BUSCAR, ESystemProfileList.WEB];
  public hasUserAccess = false;

  public searchMallaForm: UntypedFormGroup;
  public activateRutHelper = false;
  public today: string = '';
  public rut: string = '';
  public fechaReporte: string = '';
  public showReporte: boolean = false;
  public showForm: boolean = true;
  public showList: boolean = false;
  
  private subscriptions: Subscription[] = [];
  
  public listadoReportes: any[] = [];
  public dataOrg: any = {};

  public listaReportesFiltrado: any[] = [];
  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public inputText = '';
  public order = '';
  public campoOrder = '';
  public mapeoEstados: any = {
    "IN-PROGRESS": "En Progreso",
    "COMPLETED": "Completado",
    "ERROR": "Error"
  }

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private _sessionService: SessionService,
    private mallaSocietariaService: MallaSocietariaService,
    private utilsService: UtilsService
  ) {
    this.searchMallaForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
    }) as FormGroupTyped<mallaFormValues>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
    this.setToday();
  }

  setToday() {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  onChanges(): void {
    this.searchMallaForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  filtrarObjeto(): void {
    this.currentPage = 0;
    this.listaReportesFiltrado = this.inputText != '' ? this.listadoReportes.filter((item: any) => 
    item?.IDTransaccion?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.EstadoTransaccionMapeada?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.FechaFormatted?.toUpperCase().includes(this.inputText.toUpperCase())) : this.listadoReportes;
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      if(campo === 'FechaReporte'){
        this.listaReportesFiltrado.sort((a, b) => {
          const fechaA: any = new Date(a.FechaReporte);
          const fechaB: any = new Date(b.FechaReporte);
          return (this.utilsService.dateIsValid(fechaA) ? fechaA : new Date("1000")) - (this.utilsService.dateIsValid(fechaB) ? fechaB : new Date("1000"));
        });
      } else {
        this.listaReportesFiltrado.sort((a, b) => {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return textA.localeCompare(textB);
        });
      }
    } else {
      if(campo === 'FechaReporte'){
        this.ordenarDescFecha();
      } else {
        this.listaReportesFiltrado.sort((a, b) => {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return (-1 * textA.localeCompare(textB));
        });
      }
    }
  }

  ordenarDescFecha(): void {
    this.listaReportesFiltrado.sort((a, b) => {
      const fechaA: any = new Date(a.FechaReporte);
      const fechaB: any = new Date(b.FechaReporte); 
      return (this.utilsService.dateIsValid(fechaB) ? fechaB : new Date("1000")) - (this.utilsService.dateIsValid(fechaA) ? fechaA : new Date("1000"));
    }); 
  }

  public validaListarReportes(): void {
    //listadoReportes
    const { rut } = this.searchMallaForm.value;
    this.rut = rut;
    if (this.searchMallaForm.valid) {
      this.listarReportes(this.rut);
    } else {
      this.alertService.warn("Rut no valido");
      this.backToSearchForm();
    }
  }

  public listarReportes(rut: string): void {
    this.spinner.show();
    this.listadoReportes = [];
    this.subscriptions.push(this.mallaSocietariaService.listarReportes(rut).subscribe(
      (data: any) => {
        this.showReporte = false;
        this.showForm = false;
        this.showList = true;
        if(data.length > 0){
          this.listadoReportes = data.map((elemento: any) => {
            const fecha = elemento?.DatosBasicosSolicitud?.FechaReporte || '';
            const newItem = {
              ...elemento.DatosBasicosSolicitud,
              FechaFormatted: this.utilsService.formatDateHMS(fecha),
              EstadoTransaccionMapeada: elemento?.DatosBasicosSolicitud?.EstadoTransaccion ? this.mapeoEstados[elemento?.DatosBasicosSolicitud?.EstadoTransaccion] : elemento?.DatosBasicosSolicitud?.EstadoTransaccion,
            };
            return newItem;
          });
          this.showReporte = false;
          this.filtrarObjeto();
          this.ordenarDescFecha();
        } else {
          //this.showReporte = false;
          this.backToSearchForm();
          this.alertService.warn("Rut sin Información");
        }
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error?.message || 'Error al buscar reportes del rut indicado')
        //this.showReporte = true;
        this.backToSearchForm();
        this.spinner.hide();
    }));
  }



  public displayReport(rut: string, idx: string): void {
    if(!rut || !idx){
      return;
    }

    this.spinner.show();
    this.subscriptions.push(this.mallaSocietariaService.buscarTransaccion(idx, rut).subscribe(
      (data: any) => {
        this.showReporte = true;
        this.showForm = false;
        this.showList = false;
        this.fechaReporte = data?.MallaSocietariaReporte?.DatosBasicosSolicitud?.FechaReporte || '';
        if(data?.MallaSocietariaReporte?.Reporte?.mallaSocietaria){
          this.dataOrg = data?.MallaSocietariaReporte?.Reporte?.mallaSocietaria || {};
        } else {
          this.alertService.error('No se logró recuperar la información de la malla societaria seleccionada')
          this.backToList();
        }
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error?.message || 'Error al buscar el reporte malla societaria de la transaccion seleccionada')
        //this.showReporte = true;
        this.backToList();
        this.spinner.hide();
      }));
  }

  public clearForm(): void {
    this.searchMallaForm.reset();
    this.activateRutHelper = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public backToSearchForm(): void {
    this.dataOrg = {};
    this.listadoReportes = [];
    this.fechaReporte = '';
    this.showReporte = false;
    this.showForm = true;
    this.showList = false;
  }

  public backToList(): void {
    this.dataOrg = {};
    this.fechaReporte = '';
    this.showReporte = false;
    this.showForm = false;
    this.showList = true;
    this.validaListarReportes();
  }

}
