import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { AdministracionCarteraApiService } from '../services/administracion-cartera-api.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Bag, BagResponse } from '../models/bag';
import { BatchConfigList, BatchConfigListItem } from '../models/batch';

@Component({
  selector: 'app-admin-cartera-edit',
  templateUrl: './admin-cartera-edit.component.html',
  styleUrls: ['./admin-cartera-edit.component.scss']
})
export class AdminCarteraEditComponent {
  private access: string[] = [ESystemAccess.ADMINISTRACION_CARTERA, ESystemProfileList.WEB];
  detail: BagResponse | null = null;

  public formularioArchivo: FormGroup;
  public fileName: boolean = false;
  private tempFile: any = '';

  public nameControl: FormControl = new FormControl('')

  constructor(
    private _formBuilder: FormBuilder,
    private _spinner: NgxSpinnerService,
    private alertsService: AlertService,
    private _apiService: AdministracionCarteraApiService,
    // private location: Location,
    private _router: Router,
    private _session: SessionService,
    private _route: ActivatedRoute
  ) { 
    this.formularioArchivo = this._formBuilder.group({
      file: [null]
    })
  }

  ngOnInit(): void {
    if(!this.checkAccess()) this._router.navigate(['/request-access']);

    this._route.paramMap.subscribe(params => {
      const id = params.get('id'); // Get the 'id' from the route
      if(id) this.getBagData(id);
    });
  }

  getBagData(id: string) {
    this._spinner.show();
    this._apiService.getBagDetail(id).subscribe((response) => {
      this.detail = response;
      this.nameControl.setValue(response.name)
      this._spinner.hide();
    },
    (error) => {
      this.alertsService.error("Error al obtener datos de cartera");
      this._spinner.hide();
    })
  }

  checkAccess() {
    const userAccess = this._session.getUserAccess();
    const getUserProfileList = this._session.getUserProfileList();

    if (this._session.getUserAccess().includes(this.access[0]) && this._session.getUserProfileList().includes(this.access[1])) {
      return true;
    } else {
      return false;
    }
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    if(file.size <= 1000000){
      // falta validacion tipo de dato
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.tempFile = reader?.result?.toString().split(',')[1];
      };
    } else {
      this.alertsService.error('El tamaño maximo del archivo debe ser de 1mb');
      this.tempFile = {};
    }
  }

  submitArchivo() {
    // this.spinner.show();
    if(typeof(this.tempFile) === 'string'){
      const idOrganizacion = this._session.getOrganization();
      const idUsuario = this._session.getUserInfo().username;
      let name = this.nameControl.value;
      if(name == '') {
        name = 'Cartera ' + idOrganizacion + ' ' + new Date().getTime()
      }
      const bag: Bag = {
        name: name,
        description: idOrganizacion + ' ' + idUsuario,
      }
      
      if(this.tempFile != '') bag.base64Content = this.tempFile

      this._spinner.show();
      if(!this.detail) {
        this.alertsService.error("Error al obtener los datos de cartera"); 
      } 
      this._apiService.updateBag(bag, this.detail!.id).subscribe(
        (res) => {
          // Obtener id y redireccionar al detalle
          console.log(res);
          if(!res.bagId) {
            this.alertsService.error("Error al crear cartera")
          } else {
            this._router.navigate(['/administracion-cartera-detalle', res.bagId])
          }
          this._spinner.hide()
        },
        (error) => {
          console.log(error);
          this._spinner.hide()
          // Alert service
          if(error.error.message) {
            this.alertsService.error("Error al crear cartera")
          }
        }
      )
    } else {
      this.alertsService.error('Ocurrio un error con el archivo.');
      this._spinner.hide();
    }
  }

  back() {
    this._router.navigate(['/administracion-cartera-detalle', this.detail?.id])
  }
}
