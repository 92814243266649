import { Component, Input, OnInit } from '@angular/core';
import { Reporte } from 'src/app/models/formulario-f22/formulario-f22.model';
import { balanceReporteFullActivos, balanceReporteFullEstadoResultados, balanceReporteFullIndicadoresFinancieros, balanceReporteFullInformacionBalance, balanceReporteFullPasivos, balanceReporteFullResumenFinanciero, ventasPeriodo } from 'src/app/models/utils/utils.model';
import { UtilsService } from 'src/app/shared/services/utils.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-informe-f22-view',
  templateUrl: './informe-f22-view.component.html',
  styleUrls: ['./informe-f22-view.component.scss']
})
export class InformeF22ViewComponent implements OnInit {
  @Input() reporteFullContent!: any | null;
  @Input() rut!: string | null;

  public informacionBalance: any;
  public balanceReporteFullInformacionBalance: typeof balanceReporteFullInformacionBalance | any = balanceReporteFullInformacionBalance;
  public balanceReporteFullResumenFinanciero: typeof balanceReporteFullResumenFinanciero | any = balanceReporteFullResumenFinanciero;
  public balanceReporteFullActivos: typeof balanceReporteFullActivos | any = balanceReporteFullActivos;
  public balanceReporteFullPasivos: typeof balanceReporteFullPasivos | any = balanceReporteFullPasivos;
  public balanceReporteFullEstadoResultados: typeof balanceReporteFullEstadoResultados | any = balanceReporteFullEstadoResultados;
  public balanceReporteFullIndicadoresFinancieros: typeof balanceReporteFullIndicadoresFinancieros | any = balanceReporteFullIndicadoresFinancieros;
  public ventasPeriodo: typeof ventasPeriodo | any = ventasPeriodo;
  
  constructor(
    private utilsService: UtilsService
  ) { 
  }

  public headerBalance: string[] = [
    'periodo',
    'folio',
    'fecha',
    'nombre',
    'nMeses',
    'actividadEconomica',
    'sector',
    'subSector',
    'codActividad',
    'tamano',
    'rating',
    'glosa'
  ];



  public headerResumenFinanciero: string[] = [
    'ingresosdelaExplotacion',
    'utilidadneta',
    'utilidadVentas',
    'razonCorriente',
    'permanenciaCtasporCobrar',
    'permanenciadeInventarios',
    'totalactivos',
    'totalactivosfijos',
    'totalpasivoexigible',
    'totalpatrimonio',
    'leverage'
  ];

  public headerResumenActivos: string[][] = [
    ['disponible', 'disponibleporcentaje'],
    ['valoresNegociables', 'valoresNegociablesporcentaje'],
    ['cuentasporCobrardelGiro', 'cuentasporCobrardelGiroporcentaje'],
    ['inventario', 'inventarioporcentaje'],
    ['impuestosporRecuperar', 'impuestosporRecuperarporcentaje'],
    ['deudoresVarios', 'deudoresVariosporcentaje'],
    ['cuentasporCobrarEERROperacionalCP', 'cuentasporCobrarEERROperacionalCPporcentaje'],
    ['otrosActivosCirculantes', 'otrosActivosCirculantesporcentaje'],
    ['totalactivocirculante', 'totalactivocirculantEporcentaje'],
    ['terrenosyBienesRaíces', 'terrenosyBienesRaícesporcentaje'],
    ['maquinariasEquiposyVehículos', 'maquinariasEquiposyVehículosporcentaje'],
    ['activosenLeasing', 'activosenLeasingporcentaje'],
    ['otrosActivosFijos', 'otrosActivosFijosporcentaje'],
    ['depreciaciónAcumulada', 'depreciaciónAcumuladaporcentaje'],
    ['totalactivosfijos', 'totalactivosfijoSporcentaje'],
    ['cuentasporCobrarEERROperacionalLP', 'cuentasporCobrarEERROperacionalLPporcentaje'],
    ['inversiónenEERRySociedades', 'inversiónenEERRySociedadesporcentaje'],
    ['intangibles', 'intangiblesporcentaje'],
    ['otrosActivos', 'otrosActivosporcentaje'],
    ['totalotrosactivospermanentes', 'totalotrosactivospermanenteSporcentaje'],
    ['totalactivos', 'totalactivoSporcentaje']
  ];

  public headerResumenPasivos: string[][] = [
    ['bancosCortoPlazo', 'bancosCortoPlazoporcentaje'],
    ['bancosLPporcionCP', 'bancosLPporcionCPporcentaje'],
    ['leasingLPporcionCP', 'leasingLPporcionCPporcentaje'],
    ['cartasdeCreditosPagaréOtraDFCP', 'cartasdeCreditosPagaréOtraDFCPporcentaje'],
    ['documentosyCuentasporPagardeGiro', 'documentosyCuentasporPagardeGiroporcentaje'],
    ['variosAcreedores', 'variosAcreedoresporcentaje'],
    ['cuentasporPagarEERRySocios', 'cuentasporPagarEERRySociosporcentaje'],
    ['provisionesRetencioneseImpuestos', 'provisionesRetencioneseImpuestosporcentaje'],
    ['otrosPasivosCirculantes', 'otrosPasivosCirculantesporcentaje'],
    ['totalpasivoscirculantes', 'totalpasivoscirculanteSporcentaje'],
    ['bancosLP', 'bancosLPporcentaje'],
    ['leasingLP', 'leasingLPporcentaje'],
    ['acreedoresVariosLP', 'acreedoresVariosLPporcentaje'],
    ['cuentasporPagarEERRySociosLP', 'cuentasporPagarEERRySociosLPporcentaje'],
    ['otrosPasivosLargoPlazo', 'otrosPasivosLargoPlazoporcentaje'],
    ['totalpasivolargoplazo', 'totalpasivolargoplazOporcentaje'],
    ['totalpasivoexigible', 'totalpasivoexigiblEporcentaje'],
    ['interesMinoritario', 'interesMinoritarioporcentaje'],
    ['capitalPagado', 'capitalPagadoporcentaje'],
    ['reservas', 'reservasporcentaje'],
    ['utilidadesAcumuladas', 'utilidadesAcumuladasporcentaje'],
    ['utilidaddelEjercicio', 'utilidaddelEjercicioporcentaje'],
    ['retiros', 'retirosporcentaje'],
    ['totalpatrimonio', 'totalpatrimoniOporcentaje'],
    ['totalpasivos', 'totalpasivoSporcentaje']
  ];

  public headerResumenEstadoResultados: string[][] = [
    ['ingresosdelaExplotacion', 'ingresosdelaExplotacionporcentaje'],
    ['costodeExplotacion', 'costodeExplotacionporcentaje'],
    ['resultadobruto', 'resultadobrutOporcentaje'],
    ['gastosAdministrativosyVentas', 'gastosAdministrativosyVentasporcentaje'],
    ['ebitda', 'ebitdAporcentaje'],
    ['depreciaciondelEjercicio', 'depreciaciondelEjercicioporcentaje'],
    ['resultadooperacional', 'resultadooperacionaLporcentaje'],
    ['ingresosFinancieros', 'ingresosFinancierosporcentaje'],
    ['ingresosfueradelaExplotación', 'ingresosfueradelaExplotaciónporcentaje'],
    ['utilPerdInversionenEERR', 'utilPerdInversionenEERRporcentaje'],
    ['egresosfueradeExplotacion', 'egresosfueradeExplotacionporcentaje'],
    ['gastosFinancieros', 'gastosFinancierosporcentaje'],
    ['diferenciadeCambio', 'diferenciadeCambioporcentaje'],
    ['resantescorrecmon', 'resantescorrecmoNporcentaje'],
    ['correccionMonetaria', 'correccionMonetariaporcentaje'],
    ['interesMinoritario2', 'interesMinoritario2porcentaje'],
    ['utilidadantesdeimpuestos', 'utilidadantesdeimpuestoSporcentaje'],
    ['impuestoRenta', 'impuestoRentaporcentaje'],
    ['utilidadneta', 'utilidadnetAporcentaje'],
  ];

  public headerResumenIndicadoresFinancieros: string[] = [
    'LiquidezyActividadTitle', // solo para titulo
    'capitaldeTrabajo',
    'razonCorriente',
    'testAcido',
    'permanenciaCtasporCobrar',
    'permanenciadeInventarios',
    'permdeCuentasporPagar',
    'cicloOperacionalNeto',
    'variacionenActivos',
    'variacionenPasivoExigible',
    'RentabilidadTitle', // solo para titulo
    'utilidadVentas',
    'ventaTotalIngresos',
    'ebitdaAnualizado',
    'roe',
    'roa',
    'EndeudamientoyCobertura', // solo para titulo
    'dfebitda',
    'ebitdagf',
    'leverage',
    'dfPatrimonio',
    'variacionenVentas',
    'variacionenPatrimonio'
  ];

  ngOnInit(): void {
    this.utilsService.changeSpanExtra(' > Balance')
    this.setInformacionBalance();
    //this.setRut();
  }

  /**
   * @default
   * Setea la informacion del balance, ordena por periodo ascendente
   * y solo toma los ultimos 3 periodos
   */
   setInformacionBalance(): void {
    if (this.reporteFullContent.length > 0) {
      this.informacionBalance = this.reporteFullContent.sort((a: Reporte, b: Reporte) => {
        return b.periodo - a.periodo;
      });
    }
  }

  

  getPercents(title: string): boolean {
    const valuesKeys = [
      'razonCorriente',
      'testAcido',
      'variacionenActivos',
      'variacionenPasivoExigible',
      'utilidadVentas',
      'ventaTotalIngresos',
      'roe',
      'roa',
      'dfebitda',
      'ebitdagf',
      'leverage',
      'dfPatrimonio',
      'variacionenVentas',
      'variacionenPatrimonio'
    ];
    return valuesKeys.includes(title);
  }

  /**
   * @description
   * Valida si el titulo existe en el array de titulos 
   * @param title 
   * @returns 
   */
   getStrongTitle(title: string): boolean {
    const valuesKeys = [
      'totalactivocirculante',
      'totalactivosfijos',
      'totalotrosactivospermanentes',
      'totalactivos',
      'totalpasivoscirculantes',
      'totalpasivolargoplazo',
      'totalpasivoexigible',
      'totalpatrimonio',
      'totalpasivos',
      'LiquidezyActividadTitle',
      'RentabilidadTitle',
      'EndeudamientoyCobertura'
    ];
    return valuesKeys.includes(title);
  }

  getCountArray(obj: any[]): number[] {
    // convertir la cantidad de objetos a un array de numeros
    const countArray: number[] = [];
    obj.forEach((element: any, i: number) => {
      countArray.push(i + 1);
    });
    return countArray;
  }

  //Funcion para obtener letra a partir de numero
  getColName(n: number): string {
    const ordA = 'a'.charCodeAt(0);
    const ordZ = 'z'.charCodeAt(0);
    const len = ordZ - ordA + 1;
  
    let s = "";
    while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s;
  }

  getNumberFormatted(dato: number): number{
    return Number(Number(dato).toFixed(2))
  }

  getNumberFormattedInt(dato: number): number{
    return Number(Number(dato).toFixed())
  }

  descargarExcel(): void{
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // Inicio Hoja Vertical

    // Cabecera
    const ws = XLSX.utils.json_to_sheet([
      { A: "RUT" }, { A: "NOMBRE" }, { A: "ACTIVIDAD ECONÓMICA" }, 
      { A: "SECTOR" }, { A: "SUBSECTOR" }, { A: "COD. ACTIVIDAD ECONOMICA" },
      { A: "" }, { A: "TAMAÑO" }, { A: "RATING" }, 
      { A: "GLOSA RATING" }, { A: "PERIODO" }, { A: "FOLIO" },
      { A: "FECHA" }, { A: "NUMERO DE MESES" }, { A: "" }, 
      { A: "Resumen Financiero" }, { A: "VENTAS NETAS" }, { A: "UTILIDAD NETA" },
      { A: "UTILIDAD NETA / VENTAS" }, { A: "INDICE DE LIQUIDEZ" }, { A: "PROM. COBRANZA" }, 
      { A: "PROM. INVENTARIO" }, { A: "TOTAL ACTIVOS" }, { A: "TOTAL ACTIVO FIJO NETO" },
      { A: "TOTAL PASIVO EXIGIBLE" }, { A: "PATRIMONIO NETO" }, { A: "INDICE ENDEUDAMIENTO" }, 
      { A: "Activos" }, { A: "" }, { A: "Disponible" },
      { A: "Valores Negociables" }, { A: "Cuentas por Cobrar del Giro" }, { A: "Inventario" }, 
      { A: "Impuestos por Recuperar" }, { A: "Deudores Varios" }, { A: "Cuentas por Cobrar EERR Operacional C/P" },
      { A: "Otros Activos Circulantes " }, { A: "TOTAL ACTIVO CIRCULANTE" }, { A: "Terrenos y Bienes Raíces" }, 
      { A: "Maquinarias, Equipos y Vehículos" }, { A: "Activos en Leasing" }, { A: "Otros Activos Fijos" },
      { A: "(Depreciación Acumulada)" }, { A: "TOTAL ACTIVOS FIJOS" }, { A: "Cuentas por Cobrar EERR Operacional L/P" }, 
      { A: "Inversión en EERR y Sociedades" }, { A: "Intangibles" }, { A: "Otros Activos" },
      { A: "TOTAL OTROS ACTIVOS" }, { A: "TOTAL ACTIVOS" }, { A: "Pasivos" }, 
      { A: "" }, { A: "Bancos Corto Plazo" }, { A: "Bancos LP porción CP" },
      { A: "Leasing LP porción CP" }, { A: "Cartas de Créditos, Pagaré, Otra DF CP" }, { A: "Documentos y Cuentas por Pagar de Giro" }, 
      { A: "Varios Acreedores" }, { A: "Cuentas por Pagar EERR y Socios" }, { A: "Provisiones, Retenciones e Impuestos" },
      { A: "Otros Pasivos Circulantes" }, { A: "TOTAL PASIVOS CIRCULANTES" }, { A: "Bancos LP" }, 
      { A: "Leasing LP" }, { A: "Acreedores Varios L/P" }, { A: "Cuentas por Pagar EERR y Socios LP" },
      { A: "Otros Pasivos Largo Plazo" }, { A: "TOTAL PASIVO LARGO PLAZO" }, { A: "TOTAL PASIVO EXIGIBLE" }, 
      { A: "Interés Minoritario" }, { A: "Capital Pagado" }, { A: "Reservas" },
      { A: "Utilidades Acumuladas" }, { A: "Utilidad del Ejercicio" }, { A: "Retiros" }, 
      { A: "TOTAL PATRIMONIO" }, { A: "TOTAL PASIVOS" }, { A: "Estado de Resultados" }, 
      { A: "" }, { A: "Ingresos de la Explotación" }, { A: "(Costo de Explotación)" },
      { A: "RESULTADO BRUTO" }, { A: "(Gastos Administrativos y Ventas)" }, { A: "EBITDA" }, 
      { A: "(Depreciación del Ejercicio)" }, { A: "EBIT (RESULTADO OPERACIONAL)" }, { A: "Ingresos Financieros" },
      { A: "Ingresos fuera de la Explotación" }, { A: "Util.(Perd.) Inversión en EE.RR." }, { A: "(Egresos fuera de Explotación)" }, 
      { A: "(Gastos Financieros)" }, { A: "Diferencia de Cambio (+/-)" }, { A: "RES. ANTES CORREC. MON." },
      { A: "Corrección Monetaria (+/-)" }, { A: "(Interés Minoritario)" }, { A: "UTILIDAD ANTES DE IMPUESTOS" }, 
      { A: "(Impuesto Renta)" }, { A: "UTILIDAD NETA" }, { A: "" },
      { A: "Indicadores Financieros" }, { A: "" }, { A: "Liquidez y Actividad" }, 
      { A: "Capital de Trabajo (M/MM$)" }, { A: "Razón Corriente (veces)" }, { A: "Test Ácido (veces)" },
      { A: "Permanencia Ctas. por Cobrar (días)" }, { A: "Permanencia de Inventarios (días)" }, { A: "Perms. de Cuentas por Pagar (días)" }, 
      { A: "Ciclo Operacional Neto (días)" }, { A: "Variación en Activos" }, { A: "Variación en Pasivo Exigible" },
      { A: "" }, { A: "Rentabilidad" }, { A: "Utilidad / Ventas" }, 
      { A: "Venta / Total Ingresos" }, { A: "EBITDA (M/MM$) Anualizado si < 12M" }, { A: "ROE (%)" },
      { A: "ROA (%)" }, { A: "" }, { A: "Endeudamiento y Cobertura" }, 
      { A: "DF / EBITDA (veces)" }, { A: "EBITDA / GF (veces)" }, { A: "Leverage (veces)" }, 
      { A: "DF / Patrimonio (veces)" }, { A: "Variación en Ventas" }, { A: "Variación en Patrimonio" }
      
    ], {header: ["A"], skipHeader: true});

    let numeroLetra: number = 0;
    
    this.reporteFullContent.forEach((periodo: Reporte) => {
      numeroLetra++;
      const primeraLetra = this.getColName(numeroLetra).toUpperCase();
      const segundaLetra = this.getColName(numeroLetra + 1).toUpperCase();
      const content: any = [];
      if (primeraLetra.toUpperCase() === 'B'){
        content.push({ [primeraLetra]: periodo.calculado.rut, [segundaLetra]: '' });
        content.push({ [primeraLetra]: periodo.calculado.nombre, [segundaLetra]: '' });
        content.push({ [primeraLetra]: periodo.calculado.actividadEconomica, [segundaLetra]: '' });
        content.push({ [primeraLetra]: periodo.calculado.sector, [segundaLetra]: '' });
        content.push({ [primeraLetra]: periodo.calculado.subSector, [segundaLetra]: '' });
        content.push({ [primeraLetra]: periodo.calculado.codActividad, [segundaLetra]: '' });
      } else {
        content.push({ [primeraLetra]: '', [segundaLetra]: '' });
        content.push({ [primeraLetra]: '', [segundaLetra]: '' });
        content.push({ [primeraLetra]: '', [segundaLetra]: '' });
        content.push({ [primeraLetra]: '', [segundaLetra]: '' });
        content.push({ [primeraLetra]: '', [segundaLetra]: '' });
        content.push({ [primeraLetra]: '', [segundaLetra]: '' });
      }

      content.push({ [primeraLetra]: '', [segundaLetra]: '' });

      content.push(
        { [primeraLetra]: periodo.calculado.tamano,  [segundaLetra]: ''}, { [primeraLetra]: periodo.calculado.rating,  [segundaLetra]: ''},
        { [primeraLetra]: periodo.calculado.glosa,  [segundaLetra]: ''}, { [primeraLetra]: periodo.calculado.periodo,  [segundaLetra]: ''},
        { [primeraLetra]: periodo.calculado.folio,  [segundaLetra]: ''}, { [primeraLetra]: periodo.calculado.fecha,  [segundaLetra]: ''},
        { [primeraLetra]: periodo.calculado.nMeses,  [segundaLetra]: ''}
      );

      content.push({ [primeraLetra]: '', [segundaLetra]: '' });
      content.push({ [primeraLetra]: '', [segundaLetra]: '' });

      content.push(
        { [primeraLetra]: periodo.calculado.ingresosdelaExplotacion,  [segundaLetra]: ''}, 
        { [primeraLetra]: periodo.calculado.utilidadneta,  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.utilidadVentas),  [segundaLetra]: ''}, 
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.razonCorriente),  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.permanenciaCtasporCobrar),  [segundaLetra]: ''}, 
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.permanenciadeInventarios),  [segundaLetra]: ''},
        { [primeraLetra]: periodo.calculado.totalactivos,  [segundaLetra]: ''}, 
        { [primeraLetra]: periodo.calculado.totalactivosfijos,  [segundaLetra]: ''},
        { [primeraLetra]: periodo.calculado.totalpasivoexigible,  [segundaLetra]: ''}, 
        { [primeraLetra]: periodo.calculado.totalpatrimonio,  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.leverage),  [segundaLetra]: ''}
      );

      content.push({ [primeraLetra]: '', [segundaLetra]: '' });
      if(periodo.calculado.moneda.toUpperCase()==='PESOS')
        content.push({ [primeraLetra]: 'MONTO EN $', [segundaLetra]: '%' });
      if(periodo.calculado.moneda.toUpperCase()==='DOLARES')
        content.push({ [primeraLetra]: 'MONTO EN US $', [segundaLetra]: '%' });

      content.push(
        { [primeraLetra]: periodo.calculado.disponible,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.disponibleporcentaje)},
        { [primeraLetra]: periodo.calculado.valoresNegociables,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.valoresNegociablesporcentaje)},
        { [primeraLetra]: periodo.calculado.cuentasporCobrardelGiro,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.cuentasporCobrardelGiroporcentaje)},
        { [primeraLetra]: periodo.calculado.inventario,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.inventarioporcentaje)},
        { [primeraLetra]: periodo.calculado.impuestosporRecuperar,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.impuestosporRecuperarporcentaje)},
        { [primeraLetra]: periodo.calculado.deudoresVarios,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.deudoresVariosporcentaje)},
        { [primeraLetra]: periodo.calculado.cuentasporCobrarEERROperacionalCP,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.cuentasporCobrarEERROperacionalCPporcentaje)},
        { [primeraLetra]: periodo.calculado.otrosActivosCirculantes,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.otrosActivosCirculantesporcentaje)},
        { [primeraLetra]: periodo.calculado.totalactivocirculante,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.totalactivocirculantEporcentaje)},
        { [primeraLetra]: periodo.calculado.terrenosyBienesRaíces,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.terrenosyBienesRaícesporcentaje)},
        { [primeraLetra]: periodo.calculado.maquinariasEquiposyVehículos,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.maquinariasEquiposyVehículosporcentaje)},
        { [primeraLetra]: periodo.calculado.activosenLeasing,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.activosenLeasingporcentaje)},
        { [primeraLetra]: periodo.calculado.otrosActivosFijos,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.otrosActivosFijosporcentaje)},
        { [primeraLetra]: periodo.calculado.depreciaciónAcumulada,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.depreciaciónAcumuladaporcentaje)},
        { [primeraLetra]: periodo.calculado.totalactivosfijos,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.totalactivosfijoSporcentaje)},
        { [primeraLetra]: periodo.calculado.cuentasporCobrarEERROperacionalLP,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.cuentasporCobrarEERROperacionalLPporcentaje)},
        { [primeraLetra]: periodo.calculado.inversiónenEERRySociedades,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.inversiónenEERRySociedadesporcentaje)},
        { [primeraLetra]: periodo.calculado.intangibles,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.intangiblesporcentaje)},
        { [primeraLetra]: periodo.calculado.otrosActivos,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.otrosActivosporcentaje)},
        { [primeraLetra]: periodo.calculado.totalotrosactivospermanentes,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.totalotrosactivospermanenteSporcentaje)},
        { [primeraLetra]: periodo.calculado.totalactivos,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.totalactivoSporcentaje)}
      );

      content.push({ [primeraLetra]: '', [segundaLetra]: '' });
      if(periodo.calculado.moneda.toUpperCase()==='PESOS')
        content.push({ [primeraLetra]: 'MONTO EN $', [segundaLetra]: '%' });
      if(periodo.calculado.moneda.toUpperCase()==='DOLARES')
        content.push({ [primeraLetra]: 'MONTO EN US $', [segundaLetra]: '%' });

      content.push(
        { [primeraLetra]: periodo.calculado.bancosCortoPlazo,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.bancosCortoPlazoporcentaje)},
        { [primeraLetra]: periodo.calculado.bancosLPporcionCP,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.bancosLPporcionCPporcentaje)},
        { [primeraLetra]: periodo.calculado.leasingLPporcionCP,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.leasingLPporcionCPporcentaje)},
        { [primeraLetra]: periodo.calculado.cartasdeCreditosPagaréOtraDFCP,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.cartasdeCreditosPagaréOtraDFCPporcentaje)},
        { [primeraLetra]: periodo.calculado.documentosyCuentasporPagardeGiro,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.documentosyCuentasporPagardeGiroporcentaje)},
        { [primeraLetra]: periodo.calculado.variosAcreedores,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.variosAcreedoresporcentaje)},
        { [primeraLetra]: periodo.calculado.cuentasporPagarEERRySocios,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.cuentasporPagarEERRySociosporcentaje)},
        { [primeraLetra]: periodo.calculado.provisionesRetencioneseImpuestos,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.provisionesRetencioneseImpuestosporcentaje)},
        { [primeraLetra]: periodo.calculado.otrosPasivosCirculantes,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.otrosPasivosCirculantesporcentaje)},
        { [primeraLetra]: periodo.calculado.totalpasivoscirculantes,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.totalpasivoscirculanteSporcentaje)},
        { [primeraLetra]: periodo.calculado.bancosLP,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.bancosLPporcentaje)},
        { [primeraLetra]: periodo.calculado.leasingLP,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.leasingLPporcentaje)},
        { [primeraLetra]: periodo.calculado.acreedoresVariosLP,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.acreedoresVariosLPporcentaje)},
        { [primeraLetra]: periodo.calculado.cuentasporPagarEERRySociosLP,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.cuentasporPagarEERRySociosLPporcentaje)},
        { [primeraLetra]: periodo.calculado.otrosPasivosLargoPlazo,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.otrosPasivosLargoPlazoporcentaje)},
        { [primeraLetra]: periodo.calculado.totalpasivolargoplazo,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.totalpasivolargoplazOporcentaje)},
        { [primeraLetra]: periodo.calculado.totalpasivoexigible,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.totalpasivoexigiblEporcentaje)},
        { [primeraLetra]: periodo.calculado.interesMinoritario,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.interesMinoritarioporcentaje)},
        { [primeraLetra]: periodo.calculado.capitalPagado,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.capitalPagadoporcentaje)},
        { [primeraLetra]: periodo.calculado.reservas,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.reservasporcentaje)},
        { [primeraLetra]: periodo.calculado.utilidadesAcumuladas,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.utilidadesAcumuladasporcentaje)},
        { [primeraLetra]: periodo.calculado.utilidaddelEjercicio,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.utilidaddelEjercicioporcentaje)},
        { [primeraLetra]: periodo.calculado.retiros,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.retirosporcentaje)},
        { [primeraLetra]: periodo.calculado.totalpatrimonio,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.totalpatrimoniOporcentaje)},
        { [primeraLetra]: periodo.calculado.totalpasivos,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.totalpasivoSporcentaje)}
      );

      content.push({ [primeraLetra]: '', [segundaLetra]: '' });
      if(periodo.calculado.moneda.toUpperCase()==='PESOS')
        content.push({ [primeraLetra]: 'MONTO EN $', [segundaLetra]: '%' });
      if(periodo.calculado.moneda.toUpperCase()==='DOLARES')
        content.push({ [primeraLetra]: 'MONTO EN US $', [segundaLetra]: '%' });

      content.push(
        { [primeraLetra]: periodo.calculado.ingresosdelaExplotacion,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.ingresosdelaExplotacionporcentaje)},
        { [primeraLetra]: periodo.calculado.costodeExplotacion,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.costodeExplotacionporcentaje)},
        { [primeraLetra]: periodo.calculado.resultadobruto,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.resultadobrutOporcentaje)},
        { [primeraLetra]: periodo.calculado.gastosAdministrativosyVentas,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.gastosAdministrativosyVentasporcentaje)},
        { [primeraLetra]: periodo.calculado.ebitda,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.ebitdAporcentaje)},
        { [primeraLetra]: periodo.calculado.depreciaciondelEjercicio,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.depreciaciondelEjercicioporcentaje)},
        { [primeraLetra]: periodo.calculado.resultadooperacional,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.resultadooperacionaLporcentaje)},
        { [primeraLetra]: periodo.calculado.ingresosFinancieros,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.ingresosFinancierosporcentaje)},
        { [primeraLetra]: periodo.calculado.ingresosfueradelaExplotación,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.ingresosfueradelaExplotaciónporcentaje)},
        { [primeraLetra]: periodo.calculado.utilPerdInversionenEERR,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.utilPerdInversionenEERRporcentaje)},
        { [primeraLetra]: periodo.calculado.egresosfueradeExplotacion,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.egresosfueradeExplotacionporcentaje)},
        { [primeraLetra]: periodo.calculado.gastosFinancieros,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.gastosFinancierosporcentaje)},
        { [primeraLetra]: periodo.calculado.diferenciadeCambio,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.diferenciadeCambioporcentaje)},
        { [primeraLetra]: periodo.calculado.resantescorrecmon,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.resantescorrecmoNporcentaje)},
        { [primeraLetra]: periodo.calculado.correccionMonetaria,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.correccionMonetariaporcentaje)},
        { [primeraLetra]: periodo.calculado.interesMinoritario2,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.interesMinoritario2porcentaje)},
        { [primeraLetra]: periodo.calculado.utilidadantesdeimpuestos,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.utilidadantesdeimpuestoSporcentaje)},
        { [primeraLetra]: periodo.calculado.impuestoRenta,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.impuestoRentaporcentaje)},
        { [primeraLetra]: periodo.calculado.utilidadneta,  [segundaLetra]: this.getNumberFormatted(periodo.calculado.utilidadnetAporcentaje)}
      );

      content.push({ [primeraLetra]: '', [segundaLetra]: '' });
      content.push({ [primeraLetra]: '', [segundaLetra]: '' });
      content.push({ [primeraLetra]: '', [segundaLetra]: '' });

      content.push(
        { [primeraLetra]: '',  [segundaLetra]: ''}, 
        { [primeraLetra]: periodo.calculado.capitaldeTrabajo,  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.razonCorriente),  [segundaLetra]: ''}, 
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.testAcido),  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormattedInt(periodo.calculado.permanenciaCtasporCobrar),  [segundaLetra]: ''}, 
        { [primeraLetra]: this.getNumberFormattedInt(periodo.calculado.permanenciadeInventarios),  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormattedInt(periodo.calculado.permdeCuentasporPagar),  [segundaLetra]: ''}, 
        { [primeraLetra]: this.getNumberFormattedInt(periodo.calculado.cicloOperacionalNeto),  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.variacionenActivos),  [segundaLetra]: ''}, 
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.variacionenPasivoExigible),  [segundaLetra]: ''},
        { [primeraLetra]: '',  [segundaLetra]: ''},
        { [primeraLetra]: '',  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.utilidadVentas),  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.ventaTotalIngresos),  [segundaLetra]: ''},
        { [primeraLetra]: periodo.calculado.ebitdaAnualizado,  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.roe),  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.roa),  [segundaLetra]: ''},
        { [primeraLetra]: '',  [segundaLetra]: ''},
        { [primeraLetra]: '',  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.dfebitda),  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.ebitdagf),  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.leverage),  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.dfPatrimonio),  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.variacionenVentas),  [segundaLetra]: ''},
        { [primeraLetra]: this.getNumberFormatted(periodo.calculado.variacionenPatrimonio),  [segundaLetra]: ''}
      );
      
      XLSX.utils.sheet_add_json(ws, content, {header: [primeraLetra, segundaLetra], skipHeader: true, origin: primeraLetra + "1" });
      
      //Formato de numero con separador de miles
      const range = { s: {r:29, c:numeroLetra}, e: {r:97, c:numeroLetra} };
      for(let R = range.s.r; R <= range.e.r; ++R) {
        for(let C = range.s.c; C <= range.e.c; ++C) {
          const cell_address = {c:C, r:R};
          const cell_ref = XLSX.utils.encode_cell(cell_address);
          if(ws[cell_ref]){
            ws[cell_ref].z = '#,##0_-;-#,##0_-;0;General'
          }
        }
      }

      ws[primeraLetra + "17"].z = '#,##0_-;-#,##0_-;0;General'
      ws[primeraLetra + "18"].z = '#,##0_-;-#,##0_-;0;General'
      ws[primeraLetra + "23"].z = '#,##0_-;-#,##0_-;0;General'
      ws[primeraLetra + "24"].z = '#,##0_-;-#,##0_-;0;General'
      ws[primeraLetra + "25"].z = '#,##0_-;-#,##0_-;0;General'
      ws[primeraLetra + "26"].z = '#,##0_-;-#,##0_-;0;General'
      ws[primeraLetra + "103"].z = '#,##0_-;-#,##0_-;0;General'
      ws[primeraLetra + "116"].z = '#,##0_-;-#,##0_-;0;General'
      
      numeroLetra++;

    })

    //Combinacion de celdas para titulo
    const merge = [
      { s: { r: 0, c: 1 }, e: { r: 0, c: 6 } },
      { s: { r: 1, c: 1 }, e: { r: 1, c: 6 } },
      { s: { r: 2, c: 1 }, e: { r: 2, c: 6 } },
      { s: { r: 3, c: 1 }, e: { r: 3, c: 6 } },
      { s: { r: 4, c: 1 }, e: { r: 4, c: 6 } },
      { s: { r: 5, c: 1 }, e: { r: 5, c: 6 } },
    ];
    ws["!merges"] = merge;

    //Ancho de celdas
    const wscols = [
      { width: 41 },
      { width: 20 },
      { width: 10 },
      { width: 20 },
      { width: 10 }
    ];

    ws["!cols"] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, "Vertical");
    // Fin Hoja Vertical

    //          Inicio Hoja Horizontal

    // Cabecera
    const wsh = XLSX.utils.json_to_sheet([
      {A: "DATOS BASICOS"}
    ], {header: ["A"], skipHeader: true});
    XLSX.utils.sheet_add_json(wsh, [{U: "Resumen Financiero"}], {header: ["U"], skipHeader: true, origin: "U1" });
    XLSX.utils.sheet_add_json(wsh, [{AF: "Activos"}], {header: ["AF"], skipHeader: true, origin: "AF1" });
    XLSX.utils.sheet_add_json(wsh, [{BA: "Pasivos"}], {header: ["BA"], skipHeader: true, origin: "BA1" });
    XLSX.utils.sheet_add_json(wsh, [{BZ: "Estado de Resultados"}], {header: ["BZ"], skipHeader: true, origin: "BZ1" });
    XLSX.utils.sheet_add_json(wsh, [{CS: "Liquidez y Actividad"}], {header: ["CS"], skipHeader: true, origin: "CS1" });
    XLSX.utils.sheet_add_json(wsh, [{DB: "Rentabilidad"}], {header: ["DB"], skipHeader: true, origin: "DB1" });
    XLSX.utils.sheet_add_json(wsh, [{DG: "Endeudamiento y Cobertura"}], {header: ["DG"], skipHeader: true, origin: "DG1" });

    const headerH: string[] = []
    const wscolsH: any[] = []

    // Cabecera y ancho
    for(let i = 0; i <= 115; i++){
      headerH.push(this.getColName(i).toUpperCase());
      wscolsH.push({ width: 40 });
    }

    XLSX.utils.sheet_add_json(wsh, [
      { 
        A: "RUT", B: "NOMBRE", C: "RUT REPRESENTANTE LEGAL", D: "DIRECCION", E: "COMUNA", F: "REGION",
        G: "CTA CTE", H: "BANCO CTA CTE", I: "TELEFONO", J: "ACTIVIDAD ECONÓMICA", K: "SECTOR", L: "SUBSECTOR",
        M: "COD. ACTIVIDAD ECONOMICA", N: "TAMAÑO", O: "RATING", P: "GLOSA RATING", Q: "PERIODO", R: "FOLIO",
        S: "FECHA", T: "NUMERO DE MESES", U: "VENTAS NETAS",
        V: "UTILIDAD NETA", W: "UTILIDAD NETA / VENTAS", X: "INDICE DE LIQUIDEZ",
        Y: "PROM. COBRANZA", Z: "PROM. INVENTARIO", AA: "TOTAL ACTIVOS",
        AB: "TOTAL ACTIVO FIJO NETO", AC: "TOTAL PASIVO EXIGIBLE", AD: "PATRIMONIO NETO",
        AE: "INDICE ENDEUDAMIENTO", AF: "Disponible", AG: "Valores Negociables",
        AH: "Cuentas por Cobrar del Giro", AI: "Inventario", AJ: "Impuestos por Recuperar",
        AK: "Deudores Varios", AL: "Cuentas por Cobrar EERR Operacional C/P", AM: "Otros Activos Circulantes",
        AN: "TOTAL ACTIVO CIRCULANTE", AO: "Terrenos y Bienes Raíces", AP: "Maquinarias, Equipos y Vehículos",
        AQ: "Activos en Leasing", AR: "Otros Activos Fijos", AS: "(Depreciación Acumulada)",
        AT: "TOTAL ACTIVOS FIJOS", AU: "Cuentas por Cobrar EERR Operacional L/P", AV: "Inversión en EERR y Sociedades",
        AW: "Intangibles", AX: "Otros Activos", AY: "TOTAL OTROS ACTIVOS",
        AZ: "TOTAL ACTIVOS", BA: "Bancos Corto Plazo", BB: "Bancos LP porción CP",
        BC: "Leasing LP porción CP", BD: "Cartas de Créditos, Pagaré, Otra DF CP", BE: "Documentos y Cuentas por Pagar de Giro",
        BF: "Varios Acreedores", BG: "Cuentas por Pagar EERR y Socios", BH: "Provisiones, Retenciones e Impuestos",
        BI: "Otros Pasivos Circulantes", BJ: "TOTAL PASIVOS CIRCULANTES", BK: "Bancos LP",
        BL: "Leasing LP", BM: "Acreedores Varios L/P", BN: "Cuentas por Pagar EERR y Socios LP",
        BO: "Otros Pasivos Largo Plazo", BP: "TOTAL PASIVO LARGO PLAZO", BQ: "TOTAL PASIVO EXIGIBLE",
        BR: "Interés Minoritario", BS: "Capital Pagado", BT: "Reservas",
        BU: "Utilidades Acumuladas", BV: "Utilidad del Ejercicio", BW: "Retiros",
        BX: "TOTAL PATRIMONIO", BY: "TOTAL PASIVOS", BZ: "Ingresos de la Explotación",
        CA: "(Costo de Explotación)", CB: "RESULTADO BRUTO", CC: "(Gastos Administrativos y Ventas)",
        CD: "EBITDA", CE: "(Depreciación del Ejercicio)", CF: "EBIT (RESULTADO OPERACIONAL)",
        CG: "Ingresos Financieros", CH: "Ingresos fuera de la Explotación", CI: "Util.(Perd.) Inversión en EE.RR.",
        CJ: "(Egresos fuera de Explotación)", CK: "(Gastos Financieros)", CL: "Diferencia de Cambio (+/-)",
        CM: "RES. ANTES CORREC. MON.", CN: "Corrección Monetaria (+/-)", CO: "(Interés Minoritario)",
        CP: "UTILIDAD ANTES DE IMPUESTOS", CQ: "(Impuesto Renta)", CR: "UTILIDAD NETA",
        CS: "Capital de Trabajo (M/MM$)", CT: "Razón Corriente (veces)", CU: "Test Ácido (veces)",
        CV: "Permanencia Ctas. por Cobrar (días)", CW: "Permanencia de Inventarios (días)", CX: "Perms. de Cuentas por Pagar (días)",
        CY: "Ciclo Operacional Neto (días)", CZ: "Variación en Activos", DA: "Variación en Pasivo Exigible",
        DB: "Utilidad / Ventas", DC: "Venta / Total Ingresos", DD: "EBITDA (M/MM$) Anualizado si < 12M",
        DE: "ROE (%)", DF: "ROA (%)", DG: "DF / EBITDA (veces)",
        DH: "EBITDA / GF (veces)", DI: "Leverage (veces)", DJ: "DF / Patrimonio (veces)",
        DK: "Variación en Ventas", DL: "Variación en Patrimonio" 
      }
      
    ], {header: headerH, skipHeader: true, origin: -1});

    // reporte.length como referencia del largo

    this.reporteFullContent.forEach((periodo: Reporte) => {

      

      XLSX.utils.sheet_add_json(wsh, [
        { 
          A: periodo.calculado.rut, B: periodo.calculado.nombre, C: periodo.calculado.rutrepresentantelegal, 
          D: periodo.calculado.domicilio, E: periodo.calculado.comuna, F: periodo.calculado.region,
          G: periodo.calculado.numerOcuenta, H: periodo.calculado.banco, I: periodo.calculado.telefono, 
          J: periodo.calculado.actividadEconomica, K: periodo.calculado.sector, L: periodo.calculado.subSector,
          M: periodo.calculado.codActividad, N: periodo.calculado.tamano, O: periodo.calculado.rating, 
          P: periodo.calculado.glosa, Q: periodo.calculado.periodo, R: periodo.calculado.folio,
          S: periodo.calculado.fecha, T: periodo.calculado.nMeses, 
          
          U: periodo.calculado.ingresosdelaExplotacion, V: periodo.calculado.utilidadneta, 
          W: this.getNumberFormatted(periodo.calculado.utilidadVentas), X: this.getNumberFormatted(periodo.calculado.razonCorriente),
          Y: this.getNumberFormatted(periodo.calculado.permanenciaCtasporCobrar), Z: this.getNumberFormatted(periodo.calculado.permanenciadeInventarios), 
          AA: periodo.calculado.totalactivos, AB: periodo.calculado.totalactivosfijos, AC: periodo.calculado.totalpasivoexigible, 
          AD: periodo.calculado.totalpatrimonio, AE: this.getNumberFormatted(periodo.calculado.leverage), 
          
          AF: periodo.calculado.disponible, AG: periodo.calculado.valoresNegociables,
          AH: periodo.calculado.cuentasporCobrardelGiro, AI: periodo.calculado.inventario, 
          AJ: periodo.calculado.impuestosporRecuperar, AK: periodo.calculado.deudoresVarios, 
          AL: periodo.calculado.cuentasporCobrarEERROperacionalCP, AM: periodo.calculado.otrosActivosCirculantes,
          AN: periodo.calculado.totalactivocirculante, AO: periodo.calculado.terrenosyBienesRaíces, 
          AP: periodo.calculado.maquinariasEquiposyVehículos, AQ: periodo.calculado.activosenLeasing, 
          AR: periodo.calculado.otrosActivosFijos, AS: periodo.calculado.depreciaciónAcumulada,
          AT: periodo.calculado.totalactivosfijos, AU: periodo.calculado.cuentasporCobrarEERROperacionalLP, 
          AV: periodo.calculado.inversiónenEERRySociedades, AW: periodo.calculado.intangibles, 
          AX: periodo.calculado.otrosActivos, AY: periodo.calculado.totalotrosactivospermanentes,
          AZ: periodo.calculado.totalactivos, 
          
          BA: periodo.calculado.bancosCortoPlazo, BB: periodo.calculado.bancosLPporcionCP,
          BC: periodo.calculado.leasingLPporcionCP, BD: periodo.calculado.cartasdeCreditosPagaréOtraDFCP, 
          BE: periodo.calculado.documentosyCuentasporPagardeGiro, BF: periodo.calculado.variosAcreedores, 
          BG: periodo.calculado.cuentasporPagarEERRySocios, BH: periodo.calculado.provisionesRetencioneseImpuestos,
          BI: periodo.calculado.otrosPasivosCirculantes, BJ: periodo.calculado.totalpasivoscirculantes, 
          BK: periodo.calculado.bancosLP, BL: periodo.calculado.leasingLP, 
          BM: periodo.calculado.acreedoresVariosLP, BN: periodo.calculado.cuentasporPagarEERRySociosLP,
          BO: periodo.calculado.otrosPasivosLargoPlazo, BP: periodo.calculado.totalpasivolargoplazo, 
          BQ: periodo.calculado.totalpasivoexigible, BR: periodo.calculado.interesMinoritario, 
          BS: periodo.calculado.capitalPagado, BT: periodo.calculado.reservas,
          BU: periodo.calculado.utilidadesAcumuladas, BV: periodo.calculado.utilidaddelEjercicio, 
          BW: periodo.calculado.retiros, BX: periodo.calculado.totalpatrimonio, 
          BY: periodo.calculado.totalpasivos, 
          
          BZ: periodo.calculado.ingresosdelaExplotacion, CA: periodo.calculado.costodeExplotacion, 
          CB: periodo.calculado.resultadobruto, CC: periodo.calculado.gastosAdministrativosyVentas,
          CD: periodo.calculado.ebitda, CE: periodo.calculado.depreciaciondelEjercicio, 
          CF: periodo.calculado.resultadooperacional, CG: periodo.calculado.ingresosFinancieros, 
          CH: periodo.calculado.ingresosfueradelaExplotación, CI: periodo.calculado.utilPerdInversionenEERR,
          CJ: periodo.calculado.egresosfueradeExplotacion, CK: periodo.calculado.gastosFinancieros, 
          CL: periodo.calculado.diferenciadeCambio, CM: periodo.calculado.resantescorrecmon, 
          CN: periodo.calculado.correccionMonetaria, CO: periodo.calculado.interesMinoritario2,
          CP: periodo.calculado.utilidadantesdeimpuestos, CQ: periodo.calculado.impuestoRenta, 
          CR: periodo.calculado.utilidadneta, 

          CS: periodo.calculado.capitaldeTrabajo, CT: this.getNumberFormatted(periodo.calculado.razonCorriente), 
          CU: this.getNumberFormatted(periodo.calculado.testAcido), CV: this.getNumberFormattedInt(periodo.calculado.permanenciaCtasporCobrar), 
          CW: this.getNumberFormattedInt(periodo.calculado.permanenciadeInventarios), CX: this.getNumberFormattedInt(periodo.calculado.permdeCuentasporPagar),
          CY: this.getNumberFormattedInt(periodo.calculado.cicloOperacionalNeto), CZ: this.getNumberFormatted(periodo.calculado.variacionenActivos), 
          DA: this.getNumberFormatted(periodo.calculado.variacionenPasivoExigible), DB: this.getNumberFormatted(periodo.calculado.utilidadVentas), 
          DC: this.getNumberFormatted(periodo.calculado.ventaTotalIngresos), DD: periodo.calculado.ebitdaAnualizado,
          DE: this.getNumberFormatted(periodo.calculado.roe), DF: this.getNumberFormatted(periodo.calculado.roa), 
          DG: this.getNumberFormatted(periodo.calculado.dfebitda), DH: this.getNumberFormatted(periodo.calculado.ebitdagf), 
          DI: this.getNumberFormatted(periodo.calculado.leverage), DJ: this.getNumberFormatted(periodo.calculado.dfPatrimonio),
          DK: this.getNumberFormatted(periodo.calculado.variacionenVentas), DL: this.getNumberFormatted(periodo.calculado.variacionenPatrimonio)
        }
        
      ], {header: headerH, skipHeader: true, origin: -1});
      

    });

    //Formato de numero con separador de miles
    let range = { s: {r:2, c:31}, e: {r:this.reporteFullContent.length + 2, c:96} };
    for(let R = range.s.r; R <= range.e.r; ++R) {
      for(let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = {c:C, r:R};
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if(wsh[cell_ref]){
          wsh[cell_ref].z = '#,##0_-;-#,##0_-;0;General'
        }
      }
    }

    range = { s: {r:2, c:20}, e: {r:this.reporteFullContent.length + 2, c:21} };
    for(let R = range.s.r; R <= range.e.r; ++R) {
      for(let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = {c:C, r:R};
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if(wsh[cell_ref]){
          wsh[cell_ref].z = '#,##0_-;-#,##0_-;0;General'
        }
      }
    }

    range = { s: {r:2, c:26}, e: {r:this.reporteFullContent.length + 2, c:29} };
    for(let R = range.s.r; R <= range.e.r; ++R) {
      for(let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = {c:C, r:R};
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if(wsh[cell_ref]){
          wsh[cell_ref].z = '#,##0_-;-#,##0_-;0;General'
        }
      }
    }

    range = { s: {r:2, c:107}, e: {r:this.reporteFullContent.length + 2, c:107} };
    for(let R = range.s.r; R <= range.e.r; ++R) {
      for(let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = {c:C, r:R};
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if(wsh[cell_ref]){
          wsh[cell_ref].z = '#,##0_-;-#,##0_-;0;General'
        }
      }
    }


    //Combinacion de celdas para cabeceras
    const mergeh = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 19 } },
      { s: { r: 0, c: 20 }, e: { r: 0, c: 30 } },
      { s: { r: 0, c: 31 }, e: { r: 0, c: 51 } },
      { s: { r: 0, c: 52 }, e: { r: 0, c: 76 } },
      { s: { r: 0, c: 77 }, e: { r: 0, c: 95 } },
      { s: { r: 0, c: 96 }, e: { r: 0, c: 104 } },
      { s: { r: 0, c: 105 }, e: { r: 0, c: 109 } },
      { s: { r: 0, c: 110 }, e: { r: 0, c: 115 } }
    ];
    wsh["!merges"] = mergeh;

    // Ancho de columnas
    wsh["!cols"] = wscolsH;


    XLSX.utils.book_append_sheet(wb, wsh, "Horizontal");
    // Fin Hoja Horizontal
 

    const today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;  

    XLSX.writeFile(wb, "Balance_" + this.rut + "_" + today.toString()  + ".xls");

  }

}
