import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appChangeKeyup]'
})
export class ChangeKeyupDirective {
  @Output() appChangeKeyup = new EventEmitter<Event>();

  @HostListener('change', ['$event'])
  @HostListener('keyup', ['$event'])
  onChangeOrKeyup(event: Event) {
    this.appChangeKeyup.emit(event);
  }
}
