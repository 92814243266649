<div class="container">
  <!-- Table Header -->
  <div class="row">
    <div class="col-12">
      <div class="row align-items-center" style="width: 100%;">
        <!-- Button on the left -->
        <div class="col-auto">
          <button class="btn btn-primary" (click)="back()">Volver</button>
        </div>
    
        <!-- Spacer for centering the h2 -->
        <div class="col text-center">
          <h2>Detalles de la cartera</h2>
        </div>

    </div>
    </div>
  </div>

  <!-- Card Table Info -->
  <div class="row mt-5" *ngIf="detail">
    <div class="col-12 d-flex justify-content-center">
      <div class="table-responsive" style="max-width: 316px;">
        <table class="table table-bordered bg-white">
          <tbody>
            <tr>
              <th class="bg-primary">ID Cartera</th>
              <td>{{detail.id}}</td>
            </tr>
            <tr>
              <th class="bg-primary">Nombre Cartera</th>
              <td>{{detail.name}}</td>
            </tr>
            <tr>
              <th class="bg-primary">Fecha Creación</th>
              <td>{{detail.creationDate | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
            </tr>
            <tr>
              <th class="bg-primary">Fecha Modificación</th>
              <td>{{detail.modificationDate | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
            </tr>
            <tr>
              <th class="bg-primary">Cantidad de Ruts</th>
              <td>{{detail.countOfRuts}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Process Table -->
  <div class="row mt-5">
    <div class="col-12">
      <h3>Procesos Asignados</h3>
      <table class="table table-bordered bg-white table-hover" *ngIf="batchConfigs.length > 0">
        <thead>
          <tr class="bg-primary">
            <th>Modelo</th>
            <th>Frecuencia</th>
            <!-- <th>Query</th> -->
            <th>Destino</th>
            <!-- <th>Fecha de Ejecución</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let process of paginatedProcesses" class="hover" (click)="goToExecutions(process)" style="cursor: pointer;">
            <td>{{ process?.batchTemplate?.name ? process?.batchTemplate?.name : process?.batchTemplate?.tag }}</td>
            <td>{{ process?.frequency?.description ? process?.frequency?.description : process?.frequency?.name }}</td>
            <!-- <td>{{ process?.mapper?.name }}</td> -->
            <td>{{ process?.destination?.type }}</td>
            <!-- <td>{{ process?.creationDate | date: 'yyyy-MM-dd HH:mm:ss' }}</td> -->
          </tr>
        </tbody>
      </table>

      <div *ngIf="batchConfigs.length < 1">
        <p class="text-indo">No se encuentran proceso asociados a esta cartera</p>
      </div>

      <!-- Pagination Controls -->
      <nav aria-label="Process pagination" *ngIf="batchConfigs.length > 0">
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="prevPage()" aria-label="Previous">
              <span aria-hidden="true">&laquo; Previous</span>
            </a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages">
            <a class="page-link" (click)="nextPage()" aria-label="Next">
              <span aria-hidden="true">Next &raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div> 
  </div>
  
  <!-- Action Buttons -->
  <div class="row mt-4" >
    <div class="col-12 text-center">
      <!-- <button class="btn btn-primary">Descargar Cartera</button> -->
      <button *ngIf="isAdmin" class="btn btn-success" (click)="onAdmin()">Administración Procesos</button>

      <!-- <button class="btn btn-success" (click)="onAdmin()">Administración Procesos</button> -->
      <button class="btn btn-warning ml-3 mr-3" (click)="onEdit()">Editar Cartera</button>
      <button class="btn btn-primary" (click)="onExecutions()">Procesos ejecutados</button>
    </div>
  </div>
</div>
