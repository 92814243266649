import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { RUT } from 'src/app/shared/utils/rut';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { SessionService } from 'src/app/shared/services/session.service';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, concatMap, delay, finalize, map, retry, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { FormularioF29Service } from 'src/app/shared/services/formulario-f29.service';
import { FormularioF22Service } from 'src/app/shared/services/formulario-f22.service';
import { ReporteService } from 'src/app/shared/services/reporte.service';
import { ReporteFullService } from 'src/app/shared/services/reporte-full.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';


interface ReportFinancieroFormValue {
  rut: string;
}

interface RespuestaServicios { // agregar sus modelos 
  reporteBasico: any,
  f22: any,
  f29: any,
  carpetaTributaria: any,
  error: any[],
}

@Component({
  selector: 'app-reporte-financiero',
  templateUrl: './reporte-financiero.component.html',
  styleUrls: ['./reporte-financiero.component.scss']
})
export class ReporteFinancieroComponent implements OnInit {
  public showBuscador = true;
  public searchFinancieroForm: UntypedFormGroup;
  public activateRutHelper = false;
  public rut: string = '';
  public today = '';
  public hasUserAccess = false;
  private access: string[] = [
    ESystemProfileList.WEB, ESystemAccess.CARPETA_TRIBUTARIA, ESystemAccess.CARPETA_TRIBUTARIA_BUSCAR, 
    ESystemAccess.REPORTEBASICO, ESystemAccess.REPORTEBASICO_BUSCAR,
    ESystemAccess.REPORTE_F22, ESystemAccess.REPORTE_F22_BUSCAR,
    ESystemAccess.REPORTE_F29, ESystemAccess.REPORTE_F29_BUSCAR
  ];
  private accessManual: string[] = [
    ESystemAccess.BALANCE_MANUAL,
    ESystemAccess.BALANCE_MANUAL_BUSCAR,
    ESystemAccess.BALANCE_MANUAL_ADMIN
  ];
  private subscriptions: Subscription[] = [];
  public verReporteFinancieroConsolidado = false;
  public ocultarReporte = true;
  public visibleReportef29 = false;
  public visibleReportef22 = false;
  public visibleReporteRenta = false;
  public visibleCarpeta = false;
  public crearF29 = false;
  public crearF22 = false;
  public crearRenta = false;
  public crearCarpeta = false;
  public reporteActualizado = false;
  public groupNameConsulting: string[] = ['f29', 'f22', 'carpetaTributaria', 'reporteBasico'];
  public tipoPersona: string = ''; 
  public classModulos: string = 'col-sm-12 col-md-4 my-3';
  public searchReportDataSource: RespuestaServicios | any = <RespuestaServicios> {
    reporteBasico: {},
    f22: {},
    f29: {},
    carpetaTributaria: {},
    error: [],
  };
  public tipoBalanceAutomatico: boolean = false; // balance automatico true o false
  public tipoBalanceManual: boolean = false; // balance manual true o false
  public showSelector: boolean = false; // botones para indicar el tipo de balance

  constructor(
    private formBuilder: UntypedFormBuilder,
    public alertService: AlertService,
    private _sessionService: SessionService,
    private spinner: NgxSpinnerService,
    private formularioF29Service: FormularioF29Service,
    private formularioF22Service: FormularioF22Service,
    private reporteService: ReporteService,
    private reporteFullService: ReporteFullService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {
    this.searchFinancieroForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
    }) as FormGroupTyped<ReportFinancieroFormValue>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    let accessModulo = true;
    for (let i = 1; i < this.access.length; i++) {
      if(!(this._sessionService.getUserAccess().includes(this.access[i])))
        accessModulo = false;
    }
    if (this._sessionService.getUserProfileList().includes(this.access[0]) && accessModulo) {
      this.hasUserAccess = true;
    }
    this.setToday();
  }

  onChanges(): void {
    this.searchFinancieroForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  public clearForm(): void {
    this.searchFinancieroForm.reset();
    this.activateRutHelper = false;
  }

  validaPermisosManual(): boolean {
    return (this._sessionService.getUserAccess().includes(this.accessManual[0]) || this._sessionService.getUserAccess().includes(this.accessManual[1]) || this._sessionService.getUserAccess().includes(this.accessManual[2]))
  }

  public visualizarReporteFinancieroC():void{
    this.verReporteFinancieroConsolidado = true;
  }

  public actualizarF29(entradaRut?: string): void {
    if (entradaRut && entradaRut == this.rut){
      this.ocultarReporte = true;
      this.spinner.show();
      this.subscriptions.push(this.formularioF29Service.buscarReportes(RUT.applyBackendFormat(this.rut), null).subscribe(
        (data: any) => {
          if (data.length > 0){
            this.searchReportDataSource.f29 = data;
          }
          this.crearF29 = false;
          this.crearF22 = false;
          this.crearRenta = false;
          this.crearCarpeta = false;
          this.showBuscador = false;
          this.ocultarReporte = false;
          this.spinner.hide();
        },
        ({ error }) => {
          this.alertService.error(error.message || "Ha ocurrido un error inesperado.");
          this.crearF29 = false;
          this.crearF22 = false;
          this.crearRenta = false;
          this.crearCarpeta = false;
          this.showBuscador = false;
          this.ocultarReporte = false;
          this.spinner.hide();
      }));
    } else {
      this.crearF29 = false;
      this.crearF22 = false;
      this.crearRenta = false;
      this.crearCarpeta = false;
      this.showBuscador = false;
      this.ocultarReporte = false;
    }
  }

  public ingresoRut(): void {
    const { rut } = this.searchFinancieroForm.value;
    this.rut = rut;
    if (this.searchFinancieroForm.valid) {
      this.searchReportDataSource = <RespuestaServicios> {
        reporteBasico: {},
        f22: {},
        f29: {},
        carpetaTributaria: {},
        error: [],
      };
      if(!this.validaPermisosManual()) {
        this.showSelector = false;
        this.buscarReporte();
      } else {
        this.showBalanceSelector();
      }
    } else {
      this.alertService.warn("Rut no valido");
      this.spinner.hide();
    }
  }

  showBalanceSelector(): void {
    this.showBuscador = false;
    this.tipoBalanceAutomatico = false;
    this.tipoBalanceManual = false;
    this.showSelector = true;
    this.spinner.hide();
  }

  balanceManual(): void {
    this.tipoBalanceManual = true;
    this.tipoBalanceAutomatico = false;
    this.ocultarReporte = false;
  }

  public buscarReporte(entradaRut?: string): void {
    if (entradaRut && entradaRut == this.rut){
      this.ocultarReporte = true;
      this.callServices()
      this.crearF29 = false;
      this.crearF22 = false;
      this.crearRenta = false;
      this.crearCarpeta = false;
      this.reporteActualizado = true;
    } else {
      this.tipoBalanceAutomatico = true;
      this.tipoBalanceManual = false;
      this.showBuscador = false;
      this.ocultarReporte = true;
      this.spinner.show();
      this.callServices();
    }
  }

  setError(reporte: string, error: string): void {
    this.searchReportDataSource.error.push({
      id: reporte,
      msg: error
    })
  }

  setResponse(service: string, response: any): void {
    if (service === 'f29')
      this.searchReportDataSource.f29 = response;
    else if (service === 'f22'){
      this.searchReportDataSource.f22 = this.validaAutomaticos(response);
      this.searchReportDataSource.f22 = this.formularioF22Service.calculosVariacion(this.searchReportDataSource.f22);
    }
    else if (service === 'carpetaTributaria'){
      this.searchReportDataSource.carpetaTributaria = response;
    }
    else if (service === 'reporteBasico')
      this.searchReportDataSource.reporteBasico = response;
  }

  validaAutomaticos(f22: any[]): any[] {
    return f22.filter(e => (e.Formulario22Reporte?.Reporte?.tipo !== 'manual' || (e.Formulario22Reporte?.Reporte && !e.Formulario22Reporte?.Reporte?.tipo)));
  }

  getServices(service: string): any {
    const objeto: any = {
      'f29': () => {
        return this.formularioF29Service.buscarReportes(RUT.applyBackendFormat(this.rut), null)
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Error Inesperado en servicio de f29'), of(null))));
      },
      'f22': () => {
        return this.formularioF22Service.listarRenta(RUT.applyBackendFormat(this.rut)).pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Error Inesperado en servicio de f22'), of(null))));
      },
      'carpetaTributaria': () => {
        return this.reporteFullService.buscarReportes(RUT.applyBackendFormat(this.rut)).pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Error Inesperado en servicio de carpeta tributaria'), of(null))));
      },'reporteBasico': () => {
        return new Promise<void>(resolve => {
          this.subscriptions.push(this.reporteService.basicReportLastUpdated(RUT.applyBackendFormat(this.rut), '10').subscribe(
            (data: any) => {
              if(data && Object.keys(data).length <= 0){
                this.subscriptions.push(this.reporteService.createReport(RUT.applyBackendFormat(this.rut), '10').subscribe(
                  (dataCrear: any) => {
                    if (Object.keys(dataCrear).length > 0){
                      this.setResponse(service, dataCrear);
                    }
                    resolve();
                  },
                  ({ error }) => {
                    this.setError("reporteBasicoCrear", error?.error?.message || 'Error Inesperado en servicio de generación de reporte basico')
                    resolve();
                }));
              } else {
                this.searchReportDataSource.reporteBasico = data;
                resolve();
              }
            },
            ({ error }) => {
              this.setError(service, error?.error?.message || 'Error Inesperado en servicio de reporte basico');
              this.subscriptions.push(this.reporteService.createReport(RUT.applyBackendFormat(this.rut), '10').subscribe(
                (dataCrear: any) => {
                  if (dataCrear  && Object.keys(dataCrear).length > 0){
                    this.setResponse(service, dataCrear);
                  }
                  resolve();
                },
                ({ error }) => {
                  this.setError(service, error?.error?.message || 'Error Inesperado en servicio de generación de reporte basico')
                  resolve();
              }));

          }));
        }); 
      }
    };
    return objeto[service]();
  }

  async callServices(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const value of this.groupNameConsulting) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    if (parseInt(this.rut.substring(0, this.rut.length - 1)) >= 50000000){
      this.tipoPersona = 'Persona Juridica';
      this.classModulos = 'col-sm-12 col-md-4 my-3';
    } else {
      this.tipoPersona = 'Personal Natural';
      this.classModulos = 'col-sm-12 col-md-4 col-lg-3 my-3';
    }
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.searchReportDataSource.error.length <= 0 && Object.keys(this.searchReportDataSource.reporteBasico).length > 0){
        this.showBuscador = false;
        this.ocultarReporte = false;
      }
      else{
        let errorReporteBasico = false;
        this.searchReportDataSource.error.forEach((element: any) => {
          if (element.id === 'reporteBasico' || element.id === 'reporteBasicoCrear'){
            errorReporteBasico = true;
          }
        });
        if(Object.keys(this.searchReportDataSource.reporteBasico).length <= 0 && errorReporteBasico){
          this.showBuscador = true;
          this.showSelector = false;
          this.ocultarReporte = true;
          this.searchReportDataSource.error.forEach((element: any) => {
            this.alertService.error(element.msg);
          });
        } else if (Object.keys(this.searchReportDataSource.reporteBasico).length <= 0/* && !errorReporteBasico*/){
          this.showBuscador = true;
          this.showSelector = false;
          this.ocultarReporte = true;
        } else {
          this.showBuscador = false
          this.ocultarReporte = false;
          this.searchReportDataSource.error.forEach((element: any) => {
            this.alertService.error(element.msg);
          });
        }

      }},
      (error) => {
        this.showBuscador = true;
        this.showSelector = false;
        this.ocultarReporte = true;
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    ));
  }

  closeReport(): void{
    this.showBuscador = true;
    this.reporteActualizado = false;
    this.tipoBalanceAutomatico = false;
    this.tipoBalanceManual = false;
    this.showSelector = false;
    this.searchReportDataSource = <RespuestaServicios> {
      reporteBasico: {},
      f22: {},
      f29: {},
      carpetaTributaria: {},
      error: [],
    }; 
  }

}
