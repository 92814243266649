import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ModalCredencialesService } from 'src/app/shared/services/modal-credenciales.service';

@Component({
  selector: 'app-modal-credenciales',
  templateUrl: './modal-credenciales.component.html',
  styleUrls: ['./modal-credenciales.component.scss']
})
export class ModalCredencialesComponent implements OnInit {
  @Input() credencial: any = null;
  @Input() tipoCredencial: string = '';
  @Input() rut: string = '';
  @Input() type: string = '';
  private subscriptions: Subscription[] = [];
  @Output() actualizaVista = new EventEmitter<void>();

  public messages: { text: string, isError: boolean, isSuccess: boolean }[] = [];

  constructor(
    private modalService: ModalCredencialesService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.modalService.close();
    this.credencial = null;
    this.actualizaVista.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  validaEstado(): boolean{
    const estado = this.modalService.watch();
    return estado;
  }

  actualizaMensaje(event: any): void{
    this.messages.push({ text: event.mensaje || '', isError: event.alertE, isSuccess: event.alertS });
  }

  removeMessage(index: number) {
    this.messages.splice(index, 1);
  }

}
