import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SessionService } from './session.service';
import { IListFormularioF22Model, Reporte, IFormularioF22Model } from 'src/app/models/formulario-f22/formulario-f22.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormularioF22Service {
  private servicio: string = environment.backend.formularioF22.servicio;
  private endpoint: string = environment.backend.formularioF22.url;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService
  ) { }

  listarRenta(rut: string): Observable<IListFormularioF22Model[]> {
    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarReportes');
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    params = params.append("calculado", true);
    return this.http.get<IListFormularioF22Model[]>(`${this.endpoint}obtener`, { params });
  }

  buscarRenta(rut: string, periodo: string): Observable<IListFormularioF22Model> {
    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarTX');
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    params = params.append("periodo", periodo);
    return this.http.get<IListFormularioF22Model>(`${this.endpoint}obtener`, { params });
  }

  public calcularFormulario(rut: string, reporte: Reporte, validar: boolean = true): Observable<any> {
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "calcular",
      validar: validar,
      f22: reporte
    }
    return this.http.post<IFormularioF22Model>(this.endpoint + "crear", payload)
  }

  public procesarReport(rut: string, form: any, validar: boolean = true): Observable<any> {
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "crearReporte",
      validar: validar,
      ...form
    }
    return this.http.post<IListFormularioF22Model>(this.endpoint + "crear", payload)
  }

  public createReport(rut: string, form: any): Observable<any> {
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "consultar",
      ...form
    }
    return this.http.post<IListFormularioF22Model>(this.endpoint + "crear", payload)
  }

  public descargarReport(rut: string, form: any): Observable<any> { // NO FUNCIONAL
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "procesarCarpetaReporte",
      ...form
    }
    return this.http.post<IListFormularioF22Model>(this.endpoint + "crear", payload)
  }

  obtenerPlantillaBalanceManual(): Observable<any> {
    let params = new HttpParams();
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'formTemplate/balanceManual');

    return this.http.get<any>(`${this.endpoint}obtener`, { params });
  }

  obtenerReporteFiltrado(responseApi: IListFormularioF22Model[], periodos: string[]): Reporte[] {
    const reporteFinal : Reporte[] = [];
    periodos.forEach(elementPeriodo => {
      responseApi.forEach(elementResponse => {
        if(elementResponse?.Formulario22Reporte?.Reporte?.calculado?.periodo && elementPeriodo.toString() === elementResponse?.Formulario22Reporte?.Reporte?.calculado?.periodo?.toString() || periodos.length === 0){
          reporteFinal.push(elementResponse.Formulario22Reporte.Reporte);
        }
      });
    });
    return reporteFinal;
  }

  obtenerReporteFiltradoRenta(responseApi: IListFormularioF22Model[], periodos: string[]): Reporte[] {
    const reporteFinal : Reporte[] = [];
    periodos.forEach(elementPeriodo => {
      responseApi.forEach(elementResponse => {
        if(elementPeriodo.toString() === elementResponse.Formulario22Reporte.Reporte.calculadoRenta.año.toString() || periodos.length === 0){
          reporteFinal.push(elementResponse.Formulario22Reporte.Reporte);
        }
      });
    });
    return reporteFinal;
  }


  obtenerPeriodos(entrada: IListFormularioF22Model[]): any[]{
    const arrayAllPeriodos: any[] = [];

    entrada.forEach(element => {
      if(element?.Formulario22Reporte?.Reporte?.calculado?.periodo)
        arrayAllPeriodos.push(element.Formulario22Reporte.Reporte.calculado.periodo);
    });
    
    return this.eliminaPeriodosDuplicados(arrayAllPeriodos);

  }

  obtenerPeriodosRenta(entrada: IListFormularioF22Model[]): any[]{
    const arrayAllPeriodos: any[] = [];

    entrada.forEach(element => {
      if(element?.Formulario22Reporte?.Reporte?.calculadoRenta?.año)
        arrayAllPeriodos.push(element.Formulario22Reporte.Reporte.calculadoRenta.año);
    });
    
    return this.eliminaPeriodosDuplicados(arrayAllPeriodos);

  }

  eliminaPeriodosDuplicados(array : any[]): any[]{
    const filteredArray = array.filter(function(ele , pos){
      return array.indexOf(ele) == pos;
    })
    filteredArray.sort().reverse();
    return filteredArray;
  }

  calculosVariacion(entrada: IListFormularioF22Model[]): any{
    entrada = entrada.filter(e => e?.Formulario22Reporte?.Reporte?.calculado);
    entrada.sort((a: any,b: any) => a.Formulario22Reporte.Reporte.calculado.periodo - b.Formulario22Reporte.Reporte.calculado.periodo);
    
    for(let i = 0; i < entrada.length; i++){
      if( i > 0 && Object.keys(entrada[i].Formulario22Reporte.Reporte.calculado).length > 0){
        const variacionenActivos = entrada[i-1].Formulario22Reporte.Reporte.calculado.totalactivos !== 0 ?
          ((entrada[i].Formulario22Reporte.Reporte.calculado.totalactivos - entrada[i-1].Formulario22Reporte.Reporte.calculado.totalactivos) / entrada[i-1].Formulario22Reporte.Reporte.calculado.totalactivos)*100 : 0;
        entrada[i].Formulario22Reporte.Reporte.calculado.variacionenActivos = variacionenActivos;
        
        const variacionenPasivoExigible = entrada[i-1].Formulario22Reporte.Reporte.calculado.totalpasivoexigible !== 0 ?
          ((entrada[i].Formulario22Reporte.Reporte.calculado.totalpasivoexigible - entrada[i-1].Formulario22Reporte.Reporte.calculado.totalpasivoexigible) / entrada[i-1].Formulario22Reporte.Reporte.calculado.totalpasivoexigible)*100 : 0;
        entrada[i].Formulario22Reporte.Reporte.calculado.variacionenPasivoExigible = variacionenPasivoExigible;

        const variacionenVentas = entrada[i-1].Formulario22Reporte.Reporte.calculado.ingresosdelaExplotacion !== 0 ?
          ((entrada[i].Formulario22Reporte.Reporte.calculado.ingresosdelaExplotacion - entrada[i-1].Formulario22Reporte.Reporte.calculado.ingresosdelaExplotacion) / entrada[i-1].Formulario22Reporte.Reporte.calculado.ingresosdelaExplotacion)*100 : 0;
        entrada[i].Formulario22Reporte.Reporte.calculado.variacionenVentas = variacionenVentas;

        const variacionenPatrimonio = entrada[i-1].Formulario22Reporte.Reporte.calculado.totalpatrimonio !== 0 ?
          ((entrada[i].Formulario22Reporte.Reporte.calculado.totalpatrimonio - entrada[i-1].Formulario22Reporte.Reporte.calculado.totalpatrimonio) / entrada[i-1].Formulario22Reporte.Reporte.calculado.totalpatrimonio)*100 : 0;
        entrada[i].Formulario22Reporte.Reporte.calculado.variacionenPatrimonio = variacionenPatrimonio;

      }
    }
    return entrada.reverse();

  }


}
