<div class="col-12 mb-2">

    <p class="text-primary" style="cursor: pointer; text-decoration: underline;" (click)="back()">Volver</p>
    <!-- Alternar entre "Crea" y "Editar" -->
    <h1 class="mb-4 text-muted">{{label}} Oferta</h1>

    <div class="card">
        <div class="card-body">

            <div class="row g-3" [formGroup]="offerFilterForm">
                <!-- FILTROS -->
                <!-- TIPO (COMBO BOX) -->
                <div class="col-12 mt-5">
                    <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                        <label class="form-label">Tipo*</label>
                        <select formControlName="typeCombo" class="form-control" [disabled]="true">
                            <!-- <option>Seleccionar</option> -->
                            <option value="">-</option>
                            <option *ngFor="let vertical of verticalesContract" value="{{vertical.id}}">{{vertical.displayName}}</option>
                        </select>
                    </div>
                </div>
                <!-- TITULO (INPUT)-->
                <div class="col-12 mt-4">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <label class="form-label">Título*</label>
                        <input formControlName="titleInput" type="text" class="form-control"
                        placeholder="Título de la oferta">
                    </div>
                </div>
                <!-- USUARIO (AUTOCOMPLETE)-->
                <div class="col-12 mt-4">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <label class="form-label">Usuario (receptor de la oferta)*</label>
                        <input formControlName="userInput" type="text" class="form-control"
                        placeholder="Buscar por rut del usuario"
                        (blur)="onBlur($event)" [disabled]="disableEdit">
                        <div class="empresa-options-container">
                            <div *ngFor="let org of filteredUsers | async" class="drop-empresas">
                                <div class="empresa-opt" (click)="selectOrg(org)">
                                    {{org}}
                                </div>
                            </div>
                        </div>
                        <small>El usuario se debe especificar  con el RUT (sin dígito verificador), 
                            y debe ser una de las opciones presentadas en este combo.</small>
                    </div>
                </div>

                <!-- EXPIRACION (COMBO BINARIO) -->
                <div class="col-12 mt-4">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <label class="form-label">Monto</label>
                        <div class="row">
                            <div class="col">
                                <input formControlName="amountStartInput" type="number" class="form-control"
                                placeholder="Desde">
                            </div>

                            <div class="col">
                                <input formControlName="amountEndInput" type="number" class="form-control"
                                placeholder="Hasta">
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <label class="form-label">Interés (%)*</label>
                        <input formControlName="rateInput" type="number" class="form-control"
                        placeholder="Tasa de interés de la oferta expresada en porcentaje">
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <label class="form-label">Plazo (meses)*</label>
                        <input formControlName="dueDateInput" type="number" class="form-control"
                        placeholder="Plazo de la oferta expresado en meses">
                    </div>
                </div>
                
                <div class="col-12 mt-4">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <label class="form-label">Fecha de expiración*</label>
                
                            <input type="date" class="form-control" formControlName="expirationInput">
                            
                    </div>
                </div>
    
                <!-- DESCRIPCIÓN -->
                <div class="col-12 mt-4">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <label class="form-label">Descripción (introducción)*</label>
                        <textarea formControlName="descriptionIntroInput" type="text" class="form-control"
                        placeholder="Introducción de la descripción"></textarea>
                    </div>
             
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <label class="form-label">Descripcion (detalle)*</label>
                        <textarea formControlName="descriptionDetailInput" type="text" class="form-control"
                        placeholder="Detalle de la descripción"></textarea>
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <button type="button" class="btn btn-sm btn-primary flex-end" (click)="submit()" [disabled]="offerFilterForm.invalid || offerFilterForm.disabled">Crear</button>
                        <p *ngIf="offerFilterForm.invalid" class="text-muted">Para poder crear la oferta debes completar los campos obligatorios.</p>
                    </div>
                </div>
                      
        </div>
    </div>
</div>

