<div class="row" id="print-section">
    <div class="col-12">
        <div class="container-gtp mb-5">
            <img class="banner-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt=""/>
            <h2 class="text-center pt-3">COBRANZA ONLINE</h2>
            <div class="d-flex justify-content-center">
              <!-- <h4 class="badge badge-secondary">{{rut}}</h4> -->
          </div>
        </div>
    </div>

    <div class="col-12">
        <div *ngIf="!newSelected && !existingSelected" class="card px-5 py-5">
            <h1 style="font-weight: bold; color: rgb(50, 183, 255);">Cuenta no sincronizada</h1>
            <p>No encontramos los datos de su cuenta de Cobranza Online en nuestro sistema, puede crear una nueva cuenta o ingresar desde una cuenta existente.</p>
            
            <div class="row mt-5 selection-container">
                <div (click)="onNewSelected()" class="col selection-card">
                    <h2 style="font-weight: bold;"><i style="font-weight: bold;" class="fa fa-plus-square-o" aria-hidden="true"></i>   Crear una nueva cuenta</h2>
                    <h2>
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </h2>
                </div>
                <div (click)="onExistingSelected()" class="col selection-card">
                    <h2 style="font-weight: bold;"><i style="font-weight: bold;" class="fa fa-user-o" aria-hidden="true"></i>   Usar una cuenta existente</h2>
                    <h2>
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </h2>
                </div>
            </div>
        </div>
        <div *ngIf="newSelected || existingSelected" class="px-5 py-5 d-flex flex-column justify-content-center">

            <div class="row g-4">
                <h3>
                    <i class="fa fa-long-arrow-left text-primary ml-3" aria-hidden="true" style="cursor: pointer; font-size: xx-large;" (click)="unSelect()"></i>
                </h3>
            </div>

            <div class="info-card">
                <i style="font-size: xx-large; margin-right: 15px;" class="fa fa-info-circle" aria-hidden="true"></i>
                <div class="col">
                    <h2 style="font-weight: bold;">Sincronización de cuenta</h2>
                    <p>Ingrese a su cuenta de <strong>CobranzaOnline.com</strong> para sincronizar sus cuentas y poder hacer publicaciones/cobranzas
                        y monitorearlas desde nuestro sistema.</p>
                </div>
            </div>

            <div *ngIf="newSelected" class="new-container">
                <div style="width: 100%;" class="card mt-5 px-5 py-5">
                    <h2 style="font-weight: bold; color: rgb(50, 183, 255);">Registro de Cuenta</h2>
                    <hr>

                    <form [formGroup]="newForm" >

                        <div class="row">
                            <div class="col-md-3 col-sm-12 mt-4">
                                <label for="email">Email</label>
                                <input id="email" class="form-control" formControlName="email" type="email">
                                <div *ngIf="newForm.get('email')!.invalid && newForm.get('email')!.touched" class="error">
                                    Email inválido
                                </div>
                            </div>
                        </div>
                      
                        <div class="row">
                            <div class="col-md-3 col-sm-12 mt-4">
                                <label for="password">Contraseña</label>
                                <input id="password" class="form-control" formControlName="password" type="password">
                                <div *ngIf="newForm.get('password')!.invalid && newForm.get('password')!.touched" class="error">
                                    Contraseña debe ser mayor a 6 caracteres
                                </div>
                            </div>
                            
                            <div class="col-md-3 col-sm-12 mt-4">    
                                <label for="password">Repite contraseña</label>
                                <input id="repeatPassword" class="form-control" formControlName="repeatPassword" type="password">
                                <div *ngIf="newForm.get('repeatPassword')!.touched && (newForm.value.password != newForm.value.repeatPassword || newForm.get('repeatPassword')!.invalid)" class="error">
                                    Las contraseñas no coinciden
                                </div>
                            </div>
                        </div>

                        <hr>
                      
                        <div class="row">
                            <div class="col-md-3 col-sm-12 mt-4">
                                <label for="company_rut">RUT de la empresa</label>
                                <input id="company_rut" class="form-control" formControlName="company_rut" type="text" >
                                <div *ngIf="newForm.get('company_rut')!.invalid && newForm.get('company_rut')!.touched" class="error">
                                    RUT inválido
                                </div>
                            </div>
                        </div>
                      
                        <div class="row">
                            <div class="col-md-3 col-sm-12 mt-4">
                                <label for="company_name">Nombre de la empresa</label>
                                <input id="company_name" class="form-control" formControlName="company_name" type="text">
                                <div *ngIf="newForm.get('company_name')!.invalid && newForm.get('company_name')!.touched" class="error">
                                    Nombre inválido
                                </div>
                            </div>
                        </div>
                      
                        <hr>

                        <div class="row">
                            <div class="col-md-3 col-sm-12 mt-4">
                                <label for="contact_first_name">Nombre de contacto</label>
                                <input id="contact_first_name" class="form-control" formControlName="contact_first_name" type="text">
                                <div *ngIf="newForm.get('contact_first_name')!.invalid && newForm.get('contact_first_name')!.touched" class="error">
                                    Nombre requerido
                                </div>
                            </div>
                                
                            <div class="col-md-3 col-sm-12 mt-4">
                                <label for="contact_last_name">Apellido de contacto</label>
                                <input id="contact_last_name" class="form-control" formControlName="contact_last_name" type="text">
                                <div *ngIf="newForm.get('contact_last_name')!.invalid && newForm.get('contact_last_name')!.touched" class="error">
                                    Apellido requerido
                                </div>
                            </div>
                        </div>
                      
                        <div class="row">
                            <div class="col-md-3 col-sm-12 mt-4">
                                <label for="phone">Teléfono
                                    
                                </label>
                                <input id="phone" class="form-control" formControlName="phone" type="text">
                                <div *ngIf="newForm.get('phone')!.invalid && newForm.get('phone')!.touched" class="error">
                                    Numero inválido
                                </div>
                            </div>
                        </div>
                        
                        <hr>

                        <!-- Seleccion de planes -->

                        <h3 class="mt-5">Seleccione tipo de plan:</h3>
                        <div *ngIf="missingPlan" class="error">
                            Seleccione un plan
                        </div>
                        <div class="row mt-3">
                            <div *ngFor="let plan of planOptions" class="col">
                                <div class="card plan-option">
                                    <div class="card-body">
                                        <input (change)="onPlanChange($event)" style="cursor: pointer;" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios{{plan.id}}" value="{{plan.id}}">
                                        <br>
                                        <!-- <label class="form-check-label" for="exampleRadios{{plan.id}}">
                                        Default radio
                                        </label> -->
                                      <h3 class="card-title" style="font-weight: bold;">{{plan.price}}</h3>
                                      <p class="card-text">{{plan.description}}</p>
                                      <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                        <div class="form-check mt-5">
                            <input type="checkbox" class="form-check-input" id="terms" formControlName="terms">
                            <label class="form-check-label ml-5" for="terms">
                              Acepto los
                              <a href="{{tycPublicacion}}" target="_blank">Términos y condiciones</a>
                            </label>
                            <div *ngIf="newForm.get('terms')!.invalid && newForm.get('terms')!.touched" class="error">
                                Acepta los términos y condiciones
                            </div>
                        </div>

                        <div class="mt-2">
                            <button (click)="onSubmitNew()" style="width:100%" class="btn  btn-primary mt-5">Registrarse</button>
                        </div>
                    </form>
                </div>
            </div>

            <div *ngIf="existingSelected" class="new-container">
                <div class="card new-card" [formGroup]="existingForm">
                    <div class="img-container">
                        <img src="assets/img/logo-cobranzaonline.svg" alt="" style="max-width: 300px;">
                    </div>
                    <div class="mt-5">
                        <div>
                            <label class="form-label">Email*</label>
                            <input type="text" class="form-control" formControlName="email"
                            >
                            <div *ngIf="existingForm.get('email')!.invalid && existingForm.get('email')!.touched" class="error">
                                Email inválido
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <div>
                            <label class="form-label">Contraseña*</label>
                            <input type="password" class="form-control" formControlName="password"
                            >
                        </div>
                    </div>
                    <div class="mt-2">
                        <button (click)="onSubmitExisting()" style="width:100%" class="btn  btn-primary mt-5">Ingresar</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>