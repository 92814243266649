import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { IGetUsersForGroupResponse, UsuariosService } from 'src/app/shared/services/usuarios.service';
//import { SimpleModalService } from "ngx-simple-modal";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AccessDialogComponent } from 'src/app/components/access-dialog/access-dialog.component';
import { AlertService } from 'src/app/components/_alert';
import { SessionService } from 'src/app/shared/services/session.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { Title } from '@angular/platform-browser';
import { environment as env } from 'src/environments/environment';
import { ModalChangePassComponent } from 'src/app/components/modal-change-pass/modal-change-pass.component';
import { GruposService } from 'src/app/shared/services/grupos.service';

export interface IAlertContent {
  message: string;
  success: boolean;
}

@Component({
  selector: 'app-listar-usuario',
  templateUrl: './listar-usuario.component.html',
  styleUrls: ['./listar-usuario.component.scss']
})
export class ListarUsuarioComponent implements OnInit {
  private access: string[] = [ESystemAccess.CLIENTES_USER_ADMIN, ESystemProfileList.WEB];
  public hasUserAccess = false;

  // Tabla
  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public inputText: string = '';
  public order = '';
  public campoOrder = '';

  public usersGroups: IGetUsersForGroupResponse[] = [];
  public usersGroupsFiltrado: any[] = [];

  public showTable = true;
  public listGrupos: {name: string, active: boolean}[] = [];
  public usuarioSeleccionado: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private spinner: NgxSpinnerService,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog,
    private _usuariosService: UsuariosService,
    private _sessionService: SessionService,
    public alertService: AlertService,
    private readonly titleService: Title,
    private gruposService: GruposService) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);

    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;

      this.loadListUserDatasource();

      const alertParam = this.route.snapshot.paramMap.get('alert');
      if (alertParam) {
        const alert: IAlertContent = JSON.parse(alertParam);
        if (alert.success) {
          this.alertService.success(alert.message);
        } else {
          this.alertService.warn(alert.message);
        }
      }

      // TODO: improve remove alert url content
      this.location.replaceState('usuarios/listar')
    }
  }


  public loadListUserDatasource() {
  this.spinner.show()
  this._usuariosService.getUsersForGroup()
    .subscribe(
      (data) => {
        this.usersGroups = data;
        this.filtrarObjeto();
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    )
}

filtrarObjeto(){
  this.currentPage = 0;
  this.usersGroupsFiltrado = this.inputText != '' ? this.usersGroups.filter((item: any) => 
  item?.userName?.toUpperCase().includes(this.inputText.toUpperCase()) 
  || item?.name?.toUpperCase().includes(this.inputText.toUpperCase())
  || item?.givenName?.toUpperCase().includes(this.inputText.toUpperCase())
  || item?.email?.toUpperCase().includes(this.inputText.toUpperCase())
  ) : this.usersGroups;
}

cambioDeCampo(campo: string): void{
  if(campo != this.campoOrder){
    this.order = '';
  }
  if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
    this.order = 'asc';
  } else if((this.order === 'asc') && campo === this.campoOrder){
    this.order = 'desc';
  }
  this.campoOrder = campo;
  this.filtrarColumna(this.campoOrder);
}

filtrarColumna(campo: string){
  if(this.order === '' || this.order === 'asc'){
    this.usersGroupsFiltrado.sort((a, b) => {
      const textA = a[campo].toString();
      const textB = b[campo].toString();
      return textA.localeCompare(textB);
    });
  } else {
    this.usersGroupsFiltrado.sort((a, b) => {
      const textA = a[campo].toString();
      const textB = b[campo].toString();
      return (-1 * textA.localeCompare(textB));
    });
  }
}

  public isActiveChange(userSub: string) {
  const user = this.usersGroups.find((user) => user.email === userSub);

  const userAccess = {
    username: user!.userName,
    availableAccess: user!.availableAccess,
    profilesList: user!.profilesList.includes("WEB") ? [] : ["WEB"],
  };

  this.spinner.show();
  this._usuariosService.updateUserAccess(userAccess)
    .subscribe(
      (_) => {
        this.alertService.success(`Se modifico el estado del usuario ${user!.userName} exitosamente`);
        this.spinner.hide();
      },
      (_) => {
        this.alertService.error("Ocurrio un problema al intentar cambiar el estado del usuario");
        this.spinner.hide();
      }
    )
}

  public showAccess(userSub: string): void {
    const user = this.usersGroups.find((user) => user.email === userSub);

    const dialogRef = this.dialog.open(AccessDialogComponent, {
      data: {
        title: 'Accesos',
        access: user?.availableAccess
      }
    });
  
    dialogRef.afterClosed().subscribe(resp => { // validar
    });

    /*this.simpleModalService.addModal(AccessDialogComponent, {
      title: 'Accesos',
      access: user?.availableAccess
    }).subscribe(() => {

    });*/
  }

  /*public goToGrupos(): void {
    this.router.navigate(['/usuarios/grupos']);
  }*/

  public goToCreate(): void {
    this.router.navigate(['/usuarios/crear']);
  }

  public goToEdit(userSub: string): void {
    this.router.navigate(['/usuarios/editar', userSub]);
  }

  public deleteUser(username: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmación para eliminar usuario',
        message: `El usuario ${username} será eliminado`
      }
    });
  
    dialogRef.afterClosed().subscribe(isConfirmed => { // validar
      if (isConfirmed) {
        this._usuariosService.deleteUser(username)
          .subscribe(
            () => {
              this.alertService.success(`El usuario ${username} fue eliminado exitosamente`);
              this.loadListUserDatasource();
            },
            ({ error }) => {
              this.alertService.error(error.message);
              this.alertService.error("Ocurrio un problema al intentar elminar el usuario");
            }
          )
      }
    });
  /*this.simpleModalService.addModal(ConfirmDialogComponent, {
    title: 'Confirmación para eliminar usuario',
    message: `El usuario ${username} será eliminado`
  })
    .subscribe((isConfirmed) => {
      if (isConfirmed) {
        this._usuariosService.deleteUser(username)
          .subscribe(
            () => {
              this.alertService.success(`El usuario ${username} fue eliminado exitosamente`);
              this.loadListUserDatasource();
            },
            ({ error }) => {
              this.alertService.error(error.message);
              this.alertService.error("Ocurrio un problema al intentar elminar el usuario");
            }
          )
      }
    });*/
}

  resetPass(username: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Restablecer contraseña',
        message: `Al usuario ${username} se le restablecerá la contraseña`,
        textBoton: ['Si', 'Cerrar'],
      }
    });
  
    dialogRef.afterClosed().subscribe(resp => { // validar
      if (resp) { // consulta a reset pass
        this.spinner.show();
        this._usuariosService.resetUserPassword(username)
        .subscribe(
          () => {
            this.alertService.success(`Se ha restablecido correctamente la contraseña del usuario ${username}, verifique su casilla de correo para realizar el cambio.`);
            this.spinner.hide();
            this.loadListUserDatasource();
          },
          ({ error }) => {
            this.alertService.error(error.message);
            this.alertService.error("Ocurrio un problema al intentar restablecer la contraseña del usuario");
            this.spinner.hide();
          }
        )
        
      }
    });

    /*this.simpleModalService.addModal(ConfirmDialogComponent, {
      title: 'Restablecer contraseña',
      message: `Al usuario ${username} se le restablecerá la contraseña`,
      textBoton: ['Si', 'Cerrar'],
    }).subscribe((resp) => {

      if (resp) { // consulta a reset pass
        this.spinner.show();
        this._usuariosService.resetUserPassword(username)
        .subscribe(
          () => {
            this.alertService.success(`Se ha restablecido correctamente la contraseña del usuario ${username}, verifique su casilla de correo para realizar el cambio.`);
            this.spinner.hide();
            this.loadListUserDatasource();
          },
          ({ error }) => {
            this.alertService.error(error.message);
            this.alertService.error("Ocurrio un problema al intentar restablecer la contraseña del usuario");
            this.spinner.hide();
          }
        )
        
      }
    });*/
  }

  changePass(username: string): void {
    const dialogRef = this.dialog.open(ModalChangePassComponent, {
      data: {
        title: 'Cambiar Contraseña',
        message: 'Ingrese contraseña del informe para continuar',
        username: username
      }
    });
  
    dialogRef.afterClosed().subscribe(resp => { // validar
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    });

    /*this.simpleModalService.addModal(ModalChangePassComponent, {
      title: 'Cambiar Contraseña',
      message: 'Ingrese contraseña del informe para continuar',
      username: username
    }).subscribe((resp) => {
      // Abre el modal
    }, (error: any) => {
        this.alertService.error(error.error.message || 'Ocurrio un Error');
        this.spinner.hide();
    });*/
  }

  showGrupos(user: any): void {
    this.listGrupos = [];
    this.gruposService.listarGruposUsuarios()
    .subscribe(
      (data) => {
        if(data && data.length > 0) {
          this.usuarioSeleccionado = user;
          const grupos = data.map((grupo: any) => grupo.group_name);
          for(const grupo of grupos){
            const grupoUser = user.groups.find((group: any) => group === grupo);
            this.listGrupos.push({name: grupo, active: grupoUser ? true : false});
          }
          this.showTable = false;
        } else {
          this.showTable = true;
          this.alertService.error('No se han encontrado grupos');
        }
        this.spinner.hide();
      },
      ({ error }) => {
        this.showTable = true;
        this.alertService.error(error?.message || 'Ha ocurrido un error al eliminar el grupo');
        this.spinner.hide();
      }
    )
  }

  volverList(): void {
    this.listGrupos = [];
    this.usuarioSeleccionado = {};
    this.loadListUserDatasource();
    this.showTable = true;
  }

  checkGrupo(event: any, index: number): void {
    const grupo = this.listGrupos[index];
    if(event?.target?.checked === true && grupo && Object.keys(grupo).length > 0){
      // llamada a servicio para agregar al grupo
      this.spinner.show();
      this.gruposService.asignarUsuarioAGrupo([this.usuarioSeleccionado.userName], grupo?.name)
      .subscribe(
        (resp) => {
          this.spinner.hide();
          this.alertService.success(resp?.message || `Se ha asignado correctamente el grupo ${grupo?.name}.`);
        },
        ({ error }) => {
          grupo.active = false;
          this.spinner.hide();
          this.alertService.error(error.message || "Ocurrio un problema al intentar asignar el grupo");
        }
      )
    } else if(event?.target?.checked === false && grupo && Object.keys(grupo).length > 0){
      // llamada a servicio para eliminar del grupo
      this.spinner.show();
      this.gruposService.borrarUsuarioDeGrupo([this.usuarioSeleccionado.userName], grupo?.name)
      .subscribe(
        (resp) => {
          this.spinner.hide();
          this.alertService.success(resp?.message ||`Se ha borrado correctamente el usuario del grupo ${grupo?.name}.`);
        },
        ({ error }) => {
          grupo.active = true;
          this.spinner.hide();
          this.alertService.error(error.message || "Ocurrio un problema al intentar borrar el usuario del grupo");
        }
      )
    }
  }

}
