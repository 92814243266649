import { Component, Input, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import Stepper from 'bs-stepper';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';
import { AlertService } from '../../_alert';

@Component({
  selector: 'app-generar-reporte',
  templateUrl: './generar-reporte.component.html',
  styleUrls: ['./generar-reporte.component.scss']
})
export class GenerarReporteComponent implements OnInit, OnDestroy {

  @Input() stepper!: number;
  @Input() idTransaccion: string = '';
  @Input() rut: string = '';
  @Input() tipoSolicitud: string = '';
  public dataUtilidades: any[] = [];
  public dataRiesgo: any[] = [];
  public obtenerReporte: boolean = false;
  public resumenSolicitud: any[] = [];
  public lineaCreditoTx: any = {};
  public riesgoTx: any = {};
  public informeRiesgo: any[] = [];
  private subscriptions: Subscription[] = [];
  public stepperAction!: Stepper;
  public currentStep: number = 0;
  public initGenerar: boolean = true;

  constructor(
    private solicitudCreditoService: SolicitudCreditoService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private alertService: AlertService,
    private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.spinner.hide();
    this.initProcess();     
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      const pasosCompleted = resp.stages.filter((e: any) => e.completed);
      const cantidad = pasosCompleted.length;
      const pasoActual = Number(pasosCompleted[cantidad - 1].stageID) + 2;

      if (pasoActual < this.stepper) {
        this.alertService.error(`Debe completar el paso N° ${pasoActual} para poder continuar`);
        this.solicitudCreditoService.setStep(pasoActual);
      } else {
        this.solicitudCreditoService.searchSolicitudesCreditoID(this.idTransaccion, this.rut).subscribe(obj => {
          if (this.stepper === pasoActual) {
            this.obtenerVentasBalances(obj);
          } else {
            this.obtenerReporte = true;
          }
        }, error => {
          this.alertService.error(error.error.message || 'Ocurrio un Error');
          this.solicitudCreditoService.setStep('prev');
        });

      }
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
      this.solicitudCreditoService.setStep('prev');
    }));

  }

  async obtenerVentasBalances(obj: any): Promise<void> {
    
    const idCarpeta = await this.obtenerIDTransaccionCarpeta(obj);
    if (idCarpeta) {
      this.solicitudCreditoService.obtenerVentasBalances(this.idTransaccion, this.rut, idCarpeta).subscribe(resp => {
        this.resumenSolicitud.push(resp?.SolicitudDeCredito?.Reportes);
        this.generarReporte();
      }, (error: any) => {
        this.initGenerar = false;
        this.alertService.error(error.error.message || 'Ocurrio un Error');
        this.solicitudCreditoService.setStep('prev');
      });
    } else {
      this.generarReporte();
    }
  }

  async obtenerIDTransaccionCarpeta(obj: any): Promise<string> {
    return obj?.SolicitudDeCredito?.Reportes?.carpetaTributariaElectronica?.
      CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.IDTransaccion || '';
  }

  obtenerLineasCredito(campos: any) {
     
   const atributos = campos.camposPersonalizados?.Atributos || [];

   if(atributos.length > 0) {
    this.lineaCreditoTx['lcSolicitada'] = atributos.find((e: any) => e.nameAttribute === 'LC Solicitada')?.value || '';
   }
   
  this.lineaCreditoTx['lcAprobada'] = Number(campos?.['rulesEngine-reporte']?.Reporte.find((e: any) => e.name === 'lineaCreditoAprobada')?.value) || '';
  this.riesgoTx = {score: campos.basicReportInformeComercial?.Riesgo?.Clasificacion || '',
  riesgoIndustria: campos.basicReportInformeComercial?.Riesgo?.RiesgoIndustria || ''};
   
  }

  generarReporte(): void {
    this.nextStepper();
    this.solicitudCreditoService.obtenerScore(this.idTransaccion, this.rut).subscribe(async resp => {
      const idRules = resp?.SolicitudDeCredito?.Reportes?.rulesEngine?.id || '';
      if (idRules) {
        this.nextStepper();
       const response = await this.solicitudCreditoService.getReporteRules(this.idTransaccion, this.rut, idRules);
          if (response) {
            this.setInformeRiesgo(response);
            this.obtenerLineasCredito(response?.SolicitudDeCredito?.Reportes);
            this.obtenerReporte = true;
            this.initGenerar = false;
            this.resumenSolicitud.push(resp?.SolicitudDeCredito?.Reportes);
          } else {
            this.alertService.error('Ocurrio un Error al intentar generar el reporte');
            this.solicitudCreditoService.setStep('prev');
          }
      } else {
        this.initGenerar = false;
        this.alertService.error('Ocurrio un Error no se pudo encontrar el identificador rules');
        this.solicitudCreditoService.setStep('prev');
      }
    }, (error: any) => {
      this.initGenerar = false;
      this.alertService.error(error.error.message || 'Ocurrio un Error');
      this.solicitudCreditoService.setStep('prev');
    });
  }

  setInformeRiesgo(informacionCliente: any): void {
    const keyUrl = 'rulesEngine-reporte';
    const keyObj = 'riesgoIndustria';
    const keyFieldsReport = ['score', 'protestosInternosVigentesMonto', 'protestosInternosVigentesCantidad',
      'morosidadesInternas', 'morosidadesInternasMayores30'];
    const objGeneral: any = {};
    const riesgo = informacionCliente?.SolicitudDeCredito?.Reportes?.[keyUrl]?.Reporte || [];

    if (riesgo.length > 0) {
      riesgo.forEach((element: any) => {
        if (keyFieldsReport.includes(element.name)) {
          if (element.name === 'score') {
            objGeneral[element.name] = Number(element.value).toFixed(0) || 0;
          } else {
            objGeneral[element.name] = element.value || '';
          }
        }
      });

      if (Object.keys(objGeneral).length > 0) {
        objGeneral[keyObj] = informacionCliente?.SolicitudDeCredito?.Reportes?.basicReport?.Riesgo?.RiesgoIndustria || '';
      }

      this.informeRiesgo.push(objGeneral);
    }
  }

  verReporte(): void {
    this.router.navigate(['/solicitud-credito/resumen', this.idTransaccion ], { state: {rut: this.rut}});
  }

  setNumber(text: string): number {
    return Number(text);
  }

  initProcess(): void {
    const stepperEl = this.elementRef.nativeElement.querySelector('#stepper');

    stepperEl.addEventListener('show.bs-stepper', (event: any) => {
      this.currentStep = event.detail.to;
    });

    this.stepperAction = new Stepper(stepperEl, {
      linear: true,
      animation: true
    });

  }

  nextStepper() {
    this.stepperAction.next();
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
