<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>
<div *ngIf="hasUserAccess">

    <div class="card w-100 mx-auto border-0 rounded">
        <div class="card-body p-5">
            <div class="row">
                <div class="col-sm-12 my-2">
                    <button (click)="goToCreate()" class="btn btn-primary float-right formulario">Crear</button>
                </div>
                <div class="col-sm-12">
                    <div class="card text-center my-4">
                        <div class="card-header">
                            <div class="my-0 row d-flex px-4">
                                <div class="col-xs-3 col-sm-auto mr-auto p-2">
                                    <app-paginator-by-items title="Cantidad de filas a mostrar en la tabla" [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                                </div>
                                <div class="col-xs-3 col-sm-auto p-2">
                                    <div class="input-group mb-3">
                                        <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                            title="Ingrese texto para filtrar tabla"
                                            placeholder="Filtrar tabla"
                                            [(ngModel)]="inputText" 
                                            (keyup)="filtrarObjeto()"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table id="tableUsuarios" class="table table-hover border my-0">
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-center font-size cursor-pointer min-width-th" (click)="cambioDeCampo('id_organizacion')">
                                            Nombre Organización
                                            <div class="float-right">
                                                <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                            </div>
                                        </th>
                                        <th scope="col" class="text-center font-size cursor-pointer" (click)="cambioDeCampo('description')">
                                            Descripción
                                            <div class="float-right">
                                                <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                            </div>
                                        </th>
                                        <th scope="col" class="text-center font-size cursor-pointer min-width-th" (click)="cambioDeCampo('fecha_formatted')">
                                            Ultima Actualización
                                            <div class="float-right">
                                                <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                            </div>
                                        </th>
                                        <th scope="col" class="text-center font-size">
                                            Accesos
                                        </th>
                                        <th scope="col" class="text-center font-size">
                                            Editar
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of listOrganizationsFiltrada | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                                        <td class="font-size-body align-middle">{{item.id_organizacion}}</td>
                                        <td class="font-size-body align-middle">{{item.description}}</td>
                                        <td class="font-size-body align-middle">{{item.fecha_formatted || (item.update_date | date: 'dd-MM-YYYY')}}</td>
                                        <td class="text-center">
                                            <button (click)="showAccess(item.id_organizacion)" class="btn btn-w-m btn-outline-danger fa fa-search fa-1-4"></button>
                                        </td>
                                        <td class="text-center">
                                            <button type="button" (click)="goToEdit(item.id_organizacion)" class="edit btn btn-w-m btn-outline-danger fa fa-edit fa-1-4"></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer text-muted">
                            <div class="p-2">
                                <div id="pagination">
                                    <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                                        <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
