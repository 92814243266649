import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'angular-google-charts';
import { Chart } from 'angular-highcharts';
import * as moment from 'moment';
import { UtilsService } from 'src/app/shared/services/utils.service';

interface IChart {
  title: string;
  type: ChartType;
  data: any[][];
  columns: string[];
  options: any[];
  width: number;
  height: number;
}

@Component({
  selector: 'app-reporte-viewer',
  templateUrl: './reporte-viewer.component.html',
  styleUrls: ['./reporte-viewer.component.scss']
})
export class ReporteViewerComponent implements OnInit {
  @Input() content: any = null;

  public chart: IChart;

  public riesgoIndustriaCalculado = "";
  public riesgoIndustriaCalculadoValue = 0;
  public clasificacionRiesgoStyle = 7;

  public showAntecedentesSocietarios = false;
  public showAntecedentesComerciales = false;
  public showInformacionSectorialyDeMercado = false;
  public showDetalleMorosidadesYProtestos = false;
  public showInformacionBoletinComercialLaboral = false;
  public showClasificacionRiesgo = false;
  public showConsultaRut: boolean = false;
  public sinInformeText: string = 'Reporte Basico - Sin Informe Comercial';
  public graph: any = {};
  public isPersonaNatural: boolean = false;
  public reporteConInformeComercial: boolean = false;
  public telefonos: any[] = [];
  public direccion: string = '';

  constructor(private utilService: UtilsService) {
    this.chart = {
      title: "Ventas expresadas en millones de UF",
      type: ChartType.ColumnChart,
      data: [],
      columns: ['Topping', 'Ventas'],
      options: [],
      width: 490,
      height: 250
    }
  }

  ngOnInit(): void {
    
    if (this.content?.Riesgo?.ClasificacionHistorica && this.content?.Riesgo?.ClasificacionHistorica.length > 0) {
      this.setGrafico();
    }

    if (this.content.AntecedentesSocietarios && this.content.AntecedentesSocietarios !== "") {
      this.showAntecedentesSocietarios = true;
    }

    if (
      this.content.AntecedentesComerciales
      && this.content.AntecedentesComerciales.Resumen) {
      this.showAntecedentesComerciales = true;
    }

    if (
      this.content.InformacionSectorialyDeMercado
      && this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF
      && this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano1
      && this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano1 !== "") {
      this.showInformacionSectorialyDeMercado = true;
    }

    if (
      this.content.AntecedentesComerciales
      && this.content.AntecedentesComerciales.DetalleMorosidadesYProtestos
      && this.content.AntecedentesComerciales.DetalleMorosidadesYProtestos !== ""
    ) {
      this.showDetalleMorosidadesYProtestos = true;
    }

    if (
      this.content.AntecedentesComerciales
      && this.content.AntecedentesComerciales.ConsultasAlRut
      && this.content.AntecedentesComerciales.ConsultasAlRut.length > 0
    ) {
      this.showConsultaRut = true;
    }

    if (
      this.content.AntecedentesComerciales
      && this.content.AntecedentesComerciales.InformacionBoletinComercialLaboral
      && this.content.AntecedentesComerciales.InformacionBoletinComercialLaboral !== ""
    ) {
      this.showInformacionBoletinComercialLaboral = true;
    }

    this.formatInicioActividadesDate();
    this.setRiesgoIndustrialCalculado();
    this.setChartData();

    if(this.content.InformacionBasica?.TipoReporte !== this.sinInformeText) {
      this.reporteConInformeComercial = true;
    } else {
      this.reporteConInformeComercial = false;
    }

    if (
      this.content.Riesgo
      && this.content.Riesgo.ClasificacionRiesgo
    ) {
      this.showClasificacionRiesgo = true;
      this.clasificacionRiesgoStyle = this.content.Riesgo.ClasificacionRiesgo / 10;
    }

    if(this.content.InformacionBasica.Rut) {
      let rut = this.content.InformacionBasica.Rut;
      if(rut.includes('.')) {
        rut = rut.replace(/\./g, '');
      }
      if(rut.includes('-')) {
        rut = rut.split('-')[0];
      }
      this.isPersonaNatural = Number(rut) > 50000000 ? false : true;
      this.getTelefonos();
      this.getDireccion();
    }

  }

  public getDireccion(): void {
    if(this.isPersonaNatural) {
      if(this.content?.InformacionPersonal?.Contactabilidad?.Direcciones && this.content?.InformacionPersonal?.Contactabilidad?.Direcciones.length > 0) {
        const direccionesParticulares: any[] = this.content?.InformacionPersonal?.Contactabilidad?.Direcciones.filter((direccion: any) => direccion.TipoDireccion === 'PARTICULAR');
        if(direccionesParticulares && direccionesParticulares.length > 0) {
          const direccionPReciente = direccionesParticulares.sort((a: any, b: any) => (a.FechaDireccion < b.FechaDireccion ? 1 : -1))[0];
          if(direccionPReciente) {
            this.direccion = `${direccionPReciente.CalleyNumero}${direccionPReciente.Comuna ? ', ' + direccionPReciente.Comuna : ''}${direccionPReciente.Ciudad ? ', ' + direccionPReciente.Ciudad : ''}`;
            return;
          }
        }
      }
      this.direccion = this.content?.InformacionBasica?.Direccion || '';
    } else {
      if(this.content?.InformacionBasica?.Direccion) {
        this.direccion = this.content?.InformacionBasica?.Direccion || '';
      }
    }
  }

  public getTelefonos(): void {
    if(this.isPersonaNatural) {
      if(this.content?.InformacionPersonal?.Contactabilidad?.Telefonos && this.content?.InformacionPersonal?.Contactabilidad?.Telefonos.length > 0) {
        if(this.content?.InformacionPersonal?.Contactabilidad?.Telefonos.length <= 2) {
          this.telefonos = this.content?.InformacionPersonal?.Contactabilidad?.Telefonos || [];
        } else {
          this.telefonos = this.content?.InformacionPersonal?.Contactabilidad?.Telefonos
          .sort((a: any, b: any) => (new Date(a.FechaTelefono).getTime() < new Date(b.FechaTelefono).getTime() ? 1 : -1)).slice(0, 2);
        }
      }
    } else {
      if(this.content?.ContactabilidadComercial?.Telefonos && this.content?.ContactabilidadComercial?.Telefonos.length > 0) {
        if(this.content?.ContactabilidadComercial?.Telefonos.length <= 2) {
          this.telefonos = this.content?.ContactabilidadComercial?.Telefonos || [];
        } else {
          this.telefonos = this.content?.ContactabilidadComercial?.Telefonos
          .sort((a: any, b: any) => (new Date(a.FechaTelefono).getTime() < new Date(b.FechaTelefono).getTime() ? 1 : -1)).slice(0, 2);
        }
      }
    }
  }

  public formatInicioActividadesDate(): void {
    let fecha = this.content.InformacionBasica.AnoInicioActividades.slice(0, 10);
    fecha = fecha.substr(2, 1) === '-' ? this.utilService.splitDate(fecha) : fecha;
    this.content.InformacionBasica.AnoInicioActividades = fecha;
  }

  public setRiesgoIndustrialCalculado(): void {
    if (!this.content.Riesgo) {
      this.riesgoIndustriaCalculado = "";
      this.riesgoIndustriaCalculadoValue = 100;
      return;
    }

    switch (this.content.Riesgo.RiesgoIndustria) {
      case "Alto":
        this.riesgoIndustriaCalculado = "Alto";
        this.riesgoIndustriaCalculadoValue = 10;
        break;
      case "Medio":
        this.riesgoIndustriaCalculado = "Medio";
        this.riesgoIndustriaCalculadoValue = 30;
        break;
      case "Medio Alto":
        this.riesgoIndustriaCalculado = "Medio Alto";
        this.riesgoIndustriaCalculadoValue = 50;
        break;
      case "Medio Bajo":
        this.riesgoIndustriaCalculado = "Medio Bajo";
        this.riesgoIndustriaCalculadoValue = 70;
        break;
      case "Bajo":
        this.riesgoIndustriaCalculado = "Bajo";
        this.riesgoIndustriaCalculadoValue = 90;
        break;
      default:
        this.riesgoIndustriaCalculado = "";
        this.riesgoIndustriaCalculadoValue = 100;
    }
  }

  public setChartData(): void {
    if (
      !this.content.InformacionSectorialyDeMercado
      || !this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF
      || !this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano1
      || !this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_1
    ) {
      return;
    }

    this.chart.data = [
      [String(this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano1), (this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_1) / 1000000],
      [String(this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano2), (this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_2) / 1000000],
      [String(this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano3), (this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_3) / 1000000],
      [String(this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano4), (this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_4) / 1000000],
      [String(this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano5), (this.content.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_5) / 1000000],
    ]
  }

  setClasificacionPosition(value: string): number {
    return Math.round((Number(value) * 100) / 1000);
  }

  /**
 * @description
 * Setea los valores de la grafica  
 */
  setGrafico(): void {
    const objTemp = this.content?.Riesgo?.ClasificacionHistorica
      .sort((a: any, b: any) => (new Date(a.Fecha).getTime() > new Date(b.Fecha).getTime() ? 1 : -1))
      .slice(-12);

    const categorias = objTemp.map((element: any) => {
      const fechaStr = element.Fecha;
      let fecha;

      fecha = new Date(fechaStr);

      if (isNaN(fecha.getTime())) {
        const [year, month, day] = fechaStr.split('-').map(Number);
        fecha = new Date(Date.UTC(year, month - 1, day));
      }

      if (isNaN(fecha.getTime())) {
        //console.error(`Fecha inválida: ${fechaStr}`);
        return null;
      }

      const mes = fecha.getUTCMonth() + 1;
      return `${fecha.getUTCFullYear()}/${mes}`;
    });

    const values = objTemp.map((element: any) => {
     return Number(element.Valor)
    });

    this.graph = new Chart({
      xAxis: [{
        categories: categorias
      }],
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      series: [{
        name: 'Puntuajes',
        type: 'line',
        data: values,
        color: '#f00'
      }]
    });
  }

  getTest() {
  }

  formatFechaEmailPN(dateNumber: number): string {
    // Convertir el número a una cadena
    if(!dateNumber) {
      return '';
    }
    const dateString = dateNumber.toString();
  
    // Verificar si la cadena tiene exactamente 8 caracteres y solo contiene dígitos
    if (/^\d{8}$/.test(dateString)) { // formato es YYYYMMDD
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6, 8);
      return `${day}-${month}-${year}`;
    } else {
      return '';
    }
  }

}
