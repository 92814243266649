export interface DatosBasicosSolicitud {
    Rut: string;
    DV: string;
    RequestOrigin: string;
    Periodo: string;
    Mes: string
  }

export interface DatosBasicosSolicitudFull {
    IDTransaccion: string;
    EstadoTransaccion: string;
    SubEstadoTransaccion: string;
    FechaReporte: string;
    Rut: string;
    DV: string;
  }

export interface DatosSolicitante {
    Organizacion: string;
    Usuario: string;
  }


export interface Reporte {
    Banco: string;
    Codigos: any[];
    FechaIngreso: Date;
    Folio: string;
    MedioPago: string;
    Mes: string;
    Periodo: string;
    Rut: string;
    TipoDeclaracion: string;
  }

export interface ReporteF29 {
    codigos: any[];
    corrigeFolio: string;
    fechaIngreso: Date;
    folio: string;
    mes: string;
    periodo: string;
    rut: string;
    tipoDeclaracion: string;
  }

export interface ReporteF29Full {
    folio: string;
    rut: string;
    periodo: string;
    mes: number;
    tipoDeclaracion: string;
    banco: string;
    medioPago: string;
    fechaIngreso: Date;
    corrigeFolio: string;
    codigos: any[];
    calculado: any;
  }


export interface IListFormularioF29Model {
    DatosBasicosSolicitud: DatosBasicosSolicitud;
    DatosSolicitante: DatosSolicitante;
    Reporte: Reporte
  }


export interface IFormulario29Reporte {
    DatosBasicosSolicitud: DatosBasicosSolicitud;
    DatosSolicitante: DatosSolicitante;
    Reporte: ReporteF29
  }

export interface IFormulario29FullReporte {
    DatosBasicosSolicitud: DatosBasicosSolicitudFull;
    DatosSolicitante: DatosSolicitante;
    Reporte: ReporteF29Full
  }

export interface IFormularioF29ReporteModel {
    Formulario29Reporte: IFormulario29Reporte;
  }


export interface IFormularioF29FullReporteModel {
    Formulario29Reporte: IFormulario29FullReporte;
  }

export function obtenerPeriodos(entrada: IFormularioF29FullReporteModel[]): any[]{
    const arrayAllPeriodos: any[] = [];

    entrada.forEach(element => {
      if(element?.Formulario29Reporte?.Reporte?.periodo)
        arrayAllPeriodos.push(element.Formulario29Reporte.Reporte.periodo);
    });
    
    return eliminaPeriodosDuplicados(arrayAllPeriodos);

  }

export function eliminaPeriodosDuplicados(array : any[]): any[]{
  const filteredArray = array.filter(function(ele , pos){
    return array.indexOf(ele) == pos;
  })
  filteredArray.sort().reverse();
  return filteredArray;
}

export interface DatosFiltrados {
  periodo: string[];
  datosReporte: any[];
  totalVentasNetasMiles: number;
  totalVentasNetasUF: number;
  totalComprasNetasMiles: number;
  totalComprasNetasUF: number;
  totalMargenMiles: number;
  totalPorcVentasMiles: number;
  totalPostergado: number;
  totalVarVentas: number;
  mesesEnPeriodo: number;
  mesesEnReporte: any[];
  nombreRazonSocial: string;
  rut: string;
}