import { Component, Input } from '@angular/core';
import { IBoletinConcursalCreateReportResponse } from 'src/app/interface/IBoletinConcursalCreateReportResponse';
import { Detalle } from 'src/app/interface/IBoletinConcursalGetReportResponse';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment as env} from 'src/environments/environment';

@Component({
  selector: 'app-boletin-concursal-report-viewer',
  templateUrl: './boletin-concursal-report-viewer.component.html',
  styleUrls: ['./boletin-concursal-report-viewer.component.scss']
})
export class BoletinConcursalReportViewerComponent {
  @Input() content!: IBoletinConcursalCreateReportResponse;
  @Input() showHeader: boolean = true;
  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public showReportDetails = false;
  public listaDetalles: any[] | null = null;
  public inputText = '';
  public order = '';
  public campoOrder = '';

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(
    private utilsService: UtilsService
  ) { }

  public showDetails(): void {
    this.definirDetalles();
    this.showReportDetails = !this.showReportDetails;
  }

  definirDetalles(): void {
    if(!this.content?.boletinConcursalReporte?.principal?.encabezado?.detalles) {
      this.listaDetalles = null;
    } else {
      this.listaDetalles = this.content?.boletinConcursalReporte?.principal?.encabezado?.detalles || [];
      this.inputText = ''
      this.order = ''
      this.campoOrder = ''
    }
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  filtrarColumna(campo: string){
    if(this.listaDetalles) {
      if(this.order === '' || this.order === 'asc'){
        if(campo === 'fchPublicacion'){
          this.listaDetalles.sort((a, b) => {
            const fechaA: any = this.parseFecha(a?.fchPublicacion);
            const fechaB: any = this.parseFecha(b?.fchPublicacion);
            return fechaA - fechaB;
          });
        } else {
          this.listaDetalles.sort((a, b) => {
            const textA = a[campo].toString();
            const textB = b[campo].toString();
            return textA.localeCompare(textB);
          });
        }
      } else {
        if(campo === 'fchPublicacion'){
          this.listaDetalles.sort((a, b) => {
            const fechaA: any = this.parseFecha(a?.fchPublicacion);
            const fechaB: any = this.parseFecha(b?.fchPublicacion);
            return fechaB - fechaA;
          });
        } else {
          this.listaDetalles.sort((a, b) => {
            const textA = a[campo].toString();
            const textB = b[campo].toString();
            return (-1 * textA.localeCompare(textB));
          });
        }
      }
    }
  }

  parseFecha(fechaString: string): any {
    if(fechaString){
      const fecha: any = new Date(fechaString.split('-').reverse().join('-'));
      return isNaN(fecha) ? new Date('0001-01-01') : fecha;
    } else {
      return (new Date('0001-01-01'));
    }
  }

}
