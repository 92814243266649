import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { ESsystemUserProfile, ESystemOrganizacion, ESystemProfileList } from 'src/app/enum/EAccess';
import { AvailableAdminAccess, IGetOrganizationsList, OrganizacionesService } from 'src/app/shared/services/organizaciones.service';
import { SessionService } from 'src/app/shared/services/session.service';

interface OrganizacionEditFormValues {
  organizacion: string;
}

interface Group {
  group: string;
  display: string;
  accesos: AvailableAdminAccess[];
}

@Component({
  selector: 'app-editar-organizacion',
  templateUrl: './editar-organizacion.component.html',
  styleUrls: ['./editar-organizacion.component.scss'],
})
export class EditarOrganizacionComponent implements OnInit {
  private access: string[] = [ESsystemUserProfile.USERUNIQUE, ESystemProfileList.WEB];
  private accessOrg: string[] = ESystemOrganizacion.ORGANIZACION_ACCESS;
  public hasUserAccess = false;

  public accessGroups: Group[] = [];
  public organizacionToEdit: IGetOrganizationsList | undefined = undefined;
  private organizacion = '';
  public isLoaded = false;

  public organizacionCreateForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public alertService: AlertService,
    private _sessionService: SessionService,
    private spinner: NgxSpinnerService,
    private organizacionesService: OrganizacionesService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.organizacionCreateForm = this.formBuilder.group({
      organizacion: [{ value: '', disabled: true }, [Validators.required]],
    }) as FormGroupTyped<OrganizacionEditFormValues>;
  }

  ngOnInit(): void {

    if (this._sessionService.getUserInfo().email.includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])
    && this.accessOrg.includes(this._sessionService.getOrganization())) {
      this.hasUserAccess = true;
    }

    this.organizacion = this.route.snapshot.paramMap.get('id') || '';
    if (this.organizacion === '') {
      this.alertService.error('No organizacion');
    }

    this.loadBasicResources();
  }

  private loadBasicResources() {
    this.spinner.show();
    this.organizacionesService.getAccessForOrganization().subscribe(
      (data) => {
        this.accessGroups = this.getAccessGroups(data);
        this.loadOrganizacionInfo();
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    );
  }

  private getAccessGroups(data: any[]): any[] {
    let result: any = [];
    if(data && data?.length > 0){
      result = Object.values(data.reduce((acc, current) => {
        if (!acc[current.group]) {
          acc[current.group] = {
            group: current.group,
            display: current.display,
            accesos: []
          };
        }
        acc[current.group].accesos.push({
          accessID: current.accessID,
          name: current.name,
          active: current.active,
          type: current.type
        });
        return acc;
      }, {}));
      result.sort((a: any, b: any) => a.display.localeCompare(b.display));
    }
    return result;
  }

  private loadOrganizacionInfo() {
    this.organizacionesService.getOrganizations().subscribe(
      (data: IGetOrganizationsList[]) => {
        this.organizacionToEdit = data.find((organizacion) => organizacion.id_organizacion === this.organizacion);

        this.setOrganizacionData();
        this.activateAccess();

        this.isLoaded = true;
      },
      ({ error }) => {
        this.alertService.error(error.message);
      }
    );
  }

  private activateAccess() {
    if(this.accessGroups?.length > 0){
      this.accessGroups?.forEach((group) => {
        if(group?.accesos?.length > 0){
          group?.accesos?.forEach((access) => {
            const organizacion = this.organizacionToEdit?.available_admin_access.find(e => e.accessID === access.accessID);
            if (organizacion) {
              access.active = organizacion.active;
            }
          });
        }
      });
    }
  }

  public editOrganizacion() {

    const selectedAccessNames: {name: string, active: boolean}[] = this.accessGroups.reduce(
      (acc: any, { accesos }) => {
        return [...acc, ...accesos.map(({ name, active }) => ({name, active}))];
      },
      []
    );

    const organizacionAccess = {
      newOrganization: this.organizacion,
      inputServiceConfigurations: selectedAccessNames
    };

    this.spinner.show();
    this.organizacionesService.updateOrganizacionAccess(organizacionAccess).subscribe(
      async () => {
        this.spinner.hide();
        this.goBack();
      },
      ({ error }) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    );
  }

  public async clearForm() {
    await this.setOrganizacionData();
    this.activateAccess();
  }

  public goBack() {
    this.router.navigate(['/organizacion/listar']);
  }

  private setOrganizacionData() {
    if (this.organizacionToEdit) {      
      this.organizacionCreateForm.patchValue({
        organizacion: this.organizacionToEdit.id_organizacion
      });
    }
  }
}
