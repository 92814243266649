<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<ng-container *ngIf="(showoptions || showContent) && !showContent">
    <div class="container-gtp no-print">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="btn-option">
                    <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                    <button *ngIf="showContent" [printTitle]='"Renta_"+(rut | rutFormat)+"_"+today'
                        [useExistingCss]="true" printSectionId="print-section" ngxPrint
                        class="btn btn-primary">Descargar PDF</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="(showoptions || showContent) && showContent">
    <div class="container-gtp no-print">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="btn-option">
                    <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                    <div>
                        <button *ngIf="showContent" [printTitle]='"Renta_"+(rut | rutFormat)+"_"+today'
                            [useExistingCss]="true" printSectionId="print-section" ngxPrint
                            class="btn btn-primary mr-2">Descargar PDF</button>
                        <button *ngIf="showReport" id="descargarExcel" class="btn btn-primary" (click)="appinformerentaview.descargarExcel()">
                            Descargar Excel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="print-section">
        <app-reporte-renta-view #appinformerentaview [reporteFullContent]="reporteFullContent" [rut]="rut"></app-reporte-renta-view>
    </div>
</ng-container>

<div *ngIf="showoptions">
    <div class="container-gtp">
        <div class="panel panel-default">
            <div class="panel-body box-reporte-full">
                <form (ngSubmit)="submit()" [formGroup]="reportRentaFormSend">
                    <tabset>
                        <tab heading="Folio" (selectTab)="onSelect($event)" class="m-0">
                            <div *ngIf="valueTab === 'Folio'">
                                <div class="d-flex w-100">
                                    <div class="mx-auto col-sm-6">
                                        <div class="form-group mt-5">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Folio</span>
                                                </div>
                                                <input type="number" class="form-control" formControlName="folio"
                                                    autocomplete="off">
                                            </div>
                                            <div class="w-100">
                                                <app-error [control]="reportRentaFormSend.get('folio')"></app-error>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Periodo</span>
                                                </div>
                                                <input type="number" min="1" max="3000" class="form-control" formControlName="periodo"
                                                    autocomplete="off">
                                            </div>
                                            <div class="w-100">
                                                <app-error [control]="reportRentaFormSend.get('periodo')"></app-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab heading="PDF" (selectTab)="onSelect($event)" class="m-0">
                            <div *ngIf="valueTab === 'PDF'">
                                <div class="input-group mt-5 mx-auto col-sm-6">
                                    <div class="w-100">
                                        <app-error [control]="reportRentaFormSend.get('pdf')"></app-error>
                                    </div>
                                    <label class="uploadbutton mx-auto">
                                        <input class="input-file" id="file" type="file" formControlName="pdf"
                                            name="file" accept=".pdf" (change)="handleUpload($event)" />
                                        <div class="button btn">PDF
                                        </div>
                                        <div class='input-file-text'
                                            [ngClass]="{'text-success': reportRentaFormSend?.controls?.pdf?.value}">
                                            {{reportRentaFormSend?.controls?.pdf?.value || 'Subir Archivo'}}
                                        </div>
                                    </label>
                                </div> 
                                <div class="d-flex w-100">
                                    <div class="mx-auto col-sm-6">
                                        <div class="form-group mt-5 px-5">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Validar Sii</span>
                                                </div>
                                                <label class="switch switch-primary ml-3">
                                                    <input type="checkbox" id="validar" formControlName="validar">
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>                           
                            </div>
                        </tab>
                    </tabset>
                    <div class="col-12" *ngIf="valueTab === 'Folio' || valueTab === 'PDF'">
                        <div class="row">
                            <div class="mx-auto">
                                <div class="btn-group mt-5 text-center">
                                    <button type="submit" class="btn btn-primary mr-3" *ngIf="valueTab === 'Folio'">Crear</button>
                                    <button type="submit" class="btn btn-primary mr-3" *ngIf="valueTab === 'PDF'">Procesar</button>
                                    <button type="button" (click)="Limpiar()" class="btn btn-warning">Limpiar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>