import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { IReporteBasico } from 'src/app/models/reporte-buscar/reporte-buscar.model';
import { environment } from '../../../environments/environment';
import { BackendAuthService } from './backend-auth.service';
import { SessionService } from './session.service';

export interface ICreateReportParams {
  rut: string;
  organizacion: string;
  idUsuario: string;
  tipoOperacion: string;
}

export interface ISearchReportParams {
  rut: string,
  idOrganizacion: string
}

export interface IDetailsReportParams {
  rut: string;
  idOrganizacion: string;
  idTransaccion: string;
}

export interface IRequestInformeBasico {
  rut: string;
  tipoOperacion: string;
  idOrganizacion: string;
  idUsuario: string;
  servicio: string;
  uri: string;
  requestOrigin: string;
  informeComercial?: IRequestInformeComercial;
  consultaSii?: boolean;
}

export interface IRequestInformeComercial {
  user: string;
  pass: string;
  vendor: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReporteService {
  private endpoint: string = environment.backend.report.url;
  private servicio: string = environment.backend.report.servicio;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService) { }

  public createReport(rut: string, tipoOperacion: string, consultaSii?: boolean, vendor: string = 'EQUIFAX_COMERCIAL_PLATINUM') {

    const payload: IRequestInformeBasico = {
      rut,
      tipoOperacion,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      servicio: this.servicio,
      uri: 'basicReportInternal',
      requestOrigin: "WEB"
    }

    if(consultaSii === true || consultaSii === false) {
      payload.consultaSii = consultaSii;
    }

    if (tipoOperacion === '11') {
      payload.informeComercial = {
        user: '',
        pass: '',
        vendor: vendor
      }
    }
    return this.http.post(`${this.endpoint}crear`, payload);
  }

  public searchReport(searchReportParams: ISearchReportParams) {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'basicReportSearch');
    params = params.append("rut", searchReportParams.rut);
    params = params.append("idOrganizacion", searchReportParams.idOrganizacion);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    return this.http.get(`${this.endpoint}obtener`, {params});
  }

  public detailsReport(searchReportParams: IDetailsReportParams) {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'basicReportSearchTX');
    params = params.append("rut", searchReportParams.rut);
    params = params.append("idOrganizacion", searchReportParams.idOrganizacion);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    params = params.append("idTransaccion", searchReportParams.idTransaccion);
    return this.http.get(`${this.endpoint}obtener`, {params});
  }

  basicReportLastUpdated(rut: string, tipoOperacion :string): Observable<IReporteBasico> {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'basicReportSearchUpdated');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    params = params.append("tipoOperacion", tipoOperacion);
    //console.log(this.http.get<IReporteBasico>(`${this.endpoint}obtener`, { params }))
    return this.http.get<IReporteBasico>(`${this.endpoint}obtener`, { params });
  }

}
