import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertService } from 'src/app/components/_alert';
import { BalanceManualService } from 'src/app/shared/services/balance-manual.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as XLSXStyle from 'xlsx-js-style'

@Component({
  selector: 'app-reporte-balance-manual-view',
  templateUrl: './reporte-balance-manual-view.component.html',
  styleUrls: ['./reporte-balance-manual-view.component.scss']
})
export class ReporteBalanceManualViewComponent implements OnInit {
  public objectKeys = Object.keys;
  @Input() rut: string = '';
  @Input() data: any[] = [];
  @Input() listaArchivos: any = [];
  @Input() razonSocial: string = '';
  public contratoResultados: any = {};
  public contratoResultadosInput: any = {};
  public secciones: any[] = [];
  public seccionesInput: any[] = [];
  public valores: any[] = [];
  public arrayContrato: any[] = [];
  public arrayContratoInput: any[] = [];
  public calculosWeb: any[] = [];
  public visualizacionExtendida = false;
  public groupNameConsulting: string[] = ['contratoCalculos'];
  public subscriptions: Subscription[] = [];
  public errores: any[] = [];
  private formatosExcel: any = null;

  constructor(
    public alertService: AlertService,
    private balanceManualService: BalanceManualService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    if(this.data.length > 0){
      this.callServices();
    }
  }

  async callServices(): Promise<void> {
    const apiServices: any = [];

    apiServices.push(this.getServices('contratoCalculos'))

    if(this.data.length > 0){
      for await (const [i, reporte] of this.data.entries()){
        const idTransaccion = reporte?.DatosBasicosSolicitud?.IDTransaccion || '';
        if(idTransaccion && this.listaArchivos.includes(reporte?.DatosBasicosSolicitud?.IDTransaccion)){
          apiServices.push(this.getServices('excelCarga',
            reporte?.Reporte?.periodo,
            reporte?.Reporte?.tipo,
            idTransaccion,
            i
          ));
        }
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          if(element.id === 'excelCarga') {
            console.error(element.msg);
          } else {
            this.alertService.error(element.msg);
          }
        });
      }
      this.obtencionDeValores();
      this.spinner.hide();
    },
      (error) => {
        this.alertService.error(error?.message);
        this.obtencionDeValores();
        this.spinner.hide();
      }
    ));
  }

  getServices(service: string, 
    periodo?: string | number, 
    tipo?: string,
    idTransaccion?: string,
    index?: number
  ): any {
    const objeto: any = {
      'contratoCalculos': () => {
        return this.balanceManualService.obtenerContratoCalculos()
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Ha ocurrido un error obtener los calculos a realizar.'), of(null))));
      },
      'excelCarga': () => {
        return this.balanceManualService.obtenerExcelCarga(this.rut, periodo ? periodo : '', tipo ? tipo : '', idTransaccion ? idTransaccion : '')
        .pipe(
          map(resp => {
            this.setResponse(service, 
              resp,
              index
            );
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Ha ocurrido un error al consultar por excel de carga'), of(null))));
      }
    };
    return objeto[service]();
  }

  setResponse(service: string, response: any, index?: number
  ): void {
    if (service === 'contratoCalculos'){
      if(response.length > 0){
        this.calculosWeb = response || [];
      }
    }
    else if (service === 'excelCarga'){
      if(index != undefined && response?.xlsx){
        if(index >= 0 && this.data?.[index]?.Reporte){
          this.data[index].Reporte.xlsx = response?.xlsx;
        }
      }
    }
  }

  setError(reporte: string, error: string): void {
    this.errores.push({
      id: reporte,
      msg: error
    })
  }

  changeVisualizacion(valor: boolean): void {
    this.visualizacionExtendida = valor;
    this.obtencionDeValores();
  }

  obtenerContratoCalculos(): void {
    this.spinner.show();
    this.balanceManualService.obtenerContratoCalculos().subscribe(
      (data: any) => {
        if(data.length > 0){
          this.calculosWeb = data || [];
        }
        this.spinner.hide();
        this.obtencionDeValores();
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error obtener los calculos a realizar.');
        this.spinner.hide();
        this.obtencionDeValores();
    });
  }

  obtencionDeValores(): void {
    this.spinner.show();
    this.valores = [];
    this.secciones = [];

    this.obtenerContrato();
    this.obtenerValores();
    this.obtenerSecciones();
    this.arrayContrato = this.generarContratoFromContrato();
    this.obtenerDatosInput();

    this.spinner.hide();
  }

  obtenerDatosInput(): void {
    //this.valoresInput = [];
    this.seccionesInput = [];

    this.obtenerContratoInput();
    //this.obtenerValoresInput();
    this.obtenerSeccionesInput();
    this.arrayContratoInput = this.generarContratoFromContratoInput();
  }

  obtenerContrato(): void {
    const element = this.data.find(e => e?.Reporte?.tipo === 'balance');
    if(element){
      this.contratoResultados = element?.Reporte?.definicionBalance?.output || {};
    } else {
      this.contratoResultados = this.data[0]?.Reporte?.definicionBalance?.output || {};
    }
  }

  obtenerContratoInput(): void {
    const element = this.data.find(e => e?.Reporte?.tipo === 'balance');
    if(element){
      this.contratoResultadosInput = element?.Reporte?.definicionBalance?.input || {};
    } else {
      this.contratoResultadosInput = this.data[0]?.Reporte?.definicionBalance?.input || {};
    }
  }

  obtenerValores(): void {
    this.data.forEach(element => {
      const objeto: any = element?.Reporte || {};
      if(Object.keys(objeto).length > 0 && objeto?.periodo){
        objeto.IDTransaccion = element?.DatosBasicosSolicitud?.IDTransaccion || '';
        this.valores.push(objeto);
      }
    });

    if(this.valores.length > 0){
      this.valores.sort(function(a, b) {
        // Convertir el campo "periodo" a un número para comparar correctamente
        const periodoA = Number(a.periodo);
        const periodoB = Number(b.periodo);
        
        // Comparar los valores de periodo
        if (periodoA > periodoB) {
          return -1;
        } else if (periodoA < periodoB) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  obtenerPeriodoCabecera(item: any): string {
    if(item?.periodo){
      return (item?.periodo + "/" + (Number(item?.periodo) - 1));
    }
    return '';
  }

  obtenerSecciones(): void {
    const sectionMap = new Map();
  
    for (const key in this.contratoResultados) {
      const nivel1 = this.contratoResultados[key].nivel1;
      const nivel1Name = this.contratoResultados[key].nivel1Name;
      const nivel2 = this.contratoResultados[key].nivel2;
      const nivel2Name = this.contratoResultados[key].nivel2Name;
      const nivel3 = this.contratoResultados[key].nivel3;
      const nivel3Name = this.contratoResultados[key].nivel3Name;
  
      if (!sectionMap.has(nivel1)) {
        sectionMap.set(nivel1, { nivel1, nivel1Name, nivel2: [] });
      }
  
      const sectionObj = sectionMap.get(nivel1);
      const nivel2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
  
      if (!nivel2Obj) {
        sectionObj.nivel2.push({ nivel2, nivel2Name, nivel3: [] });
      } else {
        const section2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
        const nivel3Obj = section2Obj.nivel3.find((subSec: any) => subSec.nivel3 === nivel3);
    
        if (!nivel3Obj) {
          section2Obj.nivel3.push({ nivel3, nivel3Name });
        }
      }
  
    }
  
    this.secciones = Array.from(sectionMap.values());

    if(this.visualizacionExtendida === true)
    this.secciones = this.secciones.filter(e => e.nivel1 === 'liquideseindicadores');

  }

  obtenerSeccionesInput(): void {
    const sectionMap = new Map();
  
    for (const key in this.contratoResultadosInput) {
      const nivel1 = this.contratoResultadosInput[key].nivel1;
      const nivel1Name = this.contratoResultadosInput[key].nivel1Name;
      const nivel2 = this.contratoResultadosInput[key].nivel2;
      const nivel2Name = this.contratoResultadosInput[key].nivel2Name;
      const nivel3 = this.contratoResultadosInput[key].nivel3;
      const nivel3Name = this.contratoResultadosInput[key].nivel3Name;
  
      if (!sectionMap.has(nivel1)) {
        sectionMap.set(nivel1, { nivel1, nivel1Name, nivel2: [] });
      }
  
      const sectionObj = sectionMap.get(nivel1);
      const nivel2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
  
      if (!nivel2Obj) {
        sectionObj.nivel2.push({ nivel2, nivel2Name, nivel3: [] });
      } else {
        const section2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
        const nivel3Obj = section2Obj.nivel3.find((subSec: any) => subSec.nivel3 === nivel3);
    
        if (!nivel3Obj) {
          section2Obj.nivel3.push({ nivel3, nivel3Name });
        }
      }
  
    }
  
    this.seccionesInput = Array.from(sectionMap.values());

  }

  filtrarObjetoNiveles(objeto: any, valor1: any, valor2: any = undefined, valor3: any = undefined): any[] {
    const resultado: any[] = [];
    for (const key in objeto) {
      const lista: any[] = [];
      if (objeto.hasOwnProperty(key) && objeto[key]?.nivel1 === valor1 && objeto[key]?.nivel2 === valor2 && objeto[key]?.nivel3 === valor3) {
        const objetoElemento: any = {
          key: key,
          valor: objeto[key]?.name,
          type: objeto[key]?.type || "String"
        };
        lista.push(objetoElemento);

        this.valores.forEach((element: any) => {
          const objetoElemento: any = {
            valor: (element?.balance[key] || element?.balance[key] == 0) ? element?.balance[key] : (element?.campos[key] || element?.campos[key] == 0) ? element?.campos[key] : "",
            type: objeto[key]?.type
          };
          lista.push(objetoElemento);
        })
        resultado.push(lista);
      }
    }
  
    return resultado;
  }

  filtrarObjetoNivelesInput(objeto: any, valor1: any, valor2: any = undefined, valor3: any = undefined): any[] {
    const resultado: any[] = [];
    for (const key in objeto) {
      const lista: any[] = [];
      if (objeto.hasOwnProperty(key) && objeto[key]?.nivel1 === valor1 && objeto[key]?.nivel2 === valor2 && objeto[key]?.nivel3 === valor3) {
        const objetoElemento: any = {
          key: key,
          valor: objeto[key]?.name,
          type: objeto[key]?.type || "String"
        };
        lista.push(objetoElemento);

        this.valores.forEach((element: any) => {
          const objetoElemento: any = {
            valor: (element?.campos[key] || element?.campos[key] == 0) ? element?.campos[key] : (element?.balance[key] || element?.balance[key] == 0) ? element?.balance[key] : "",
            type: objeto[key]?.type
          };
          lista.push(objetoElemento);
        })
        resultado.push(lista);
      }
    }
  
    return resultado;
  }

  generarContratoFromContrato(): any[] {
    const respuesta: any[] = [];
  
    this.secciones.forEach(element1 => {
      if (!element1?.nivel1) {
        const arrayUndefined: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1) || [];
        const objeto: any = { variables: arrayUndefined };
        respuesta.push(objeto);
      } else {
        const objetoNivel1: any = {
          nivel1Name: element1?.nivel1Name || "",
          nivel1: element1?.nivel1 || "",
          variables: [],
          nivel2: []
        };
  
        if (element1?.nivel2 && element1?.nivel2.length > 0) {
          element1.nivel2.forEach((element2: any) => {
            if (!element2?.nivel2) {
              const arrayNivel2: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1, element2?.nivel2) || [];
              const objeto: any = {
                nivel2Name: "",
                nivel2: "",
                variables: arrayNivel2
              };
              objetoNivel1.nivel2.push(objeto);
            } else {
              const objetoNivel2: any = {
                nivel2Name: element2?.nivel2Name || "",
                nivel2: element2?.nivel2 || "",
                variables: [],
                nivel3: []
              };
  
              if (element2?.nivel3 && element2?.nivel3.length > 0) {
                element2.nivel3.forEach((element3: any) => {
                  if (!element3?.nivel3) {
                    const arrayNivel3: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objeto: any = {
                      nivel3Name: "",
                      nivel3: "",
                      variables: arrayNivel3
                    };
                    objetoNivel2.nivel3.push(objeto);
                  } else {
                    const arrayNivel3: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objetoNivel3: any = {
                      nivel3Name: element3?.nivel3Name || "",
                      nivel3: element3?.nivel3 || "",
                      variables: arrayNivel3 || []
                    };
                    objetoNivel2.nivel3.push(objetoNivel3);
                  }
                });
              } else {
                const arrayNivel2: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1, element2?.nivel2) || [];
                objetoNivel2.variables.push(...arrayNivel2);
              }
  
              objetoNivel1.nivel2.push(objetoNivel2);
            }
          });
        } else {
          const arrayNivel1: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1) || [];
          objetoNivel1.variables.push(...arrayNivel1);
        }
  
        respuesta.push(objetoNivel1);
      }
    });
  
    return respuesta || [];
  }

  generarContratoFromContratoInput(): any[] {
    const respuesta: any[] = [];
  
    this.seccionesInput.forEach(element1 => {
      if (!element1?.nivel1) {
        const arrayUndefined: any = this.filtrarObjetoNivelesInput(this.contratoResultadosInput, element1?.nivel1) || [];
        const objeto: any = { variables: arrayUndefined };
        respuesta.push(objeto);
      } else {
        const objetoNivel1: any = {
          nivel1Name: element1?.nivel1Name || "",
          nivel1: element1?.nivel1 || "",
          variables: [],
          nivel2: []
        };
  
        if (element1?.nivel2 && element1?.nivel2.length > 0) {
          element1.nivel2.forEach((element2: any) => {
            if (!element2?.nivel2) {
              const arrayNivel2: any = this.filtrarObjetoNivelesInput(this.contratoResultadosInput, element1?.nivel1, element2?.nivel2) || [];
              const objeto: any = {
                nivel2Name: "",
                nivel2: "",
                variables: arrayNivel2
              };
              objetoNivel1.nivel2.push(objeto);
            } else {
              const objetoNivel2: any = {
                nivel2Name: element2?.nivel2Name || "",
                nivel2: element2?.nivel2 || "",
                variables: [],
                nivel3: []
              };
  
              if (element2?.nivel3 && element2?.nivel3.length > 0) {
                element2.nivel3.forEach((element3: any) => {
                  if (!element3?.nivel3) {
                    const arrayNivel3: any = this.filtrarObjetoNivelesInput(this.contratoResultadosInput, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objeto: any = {
                      nivel3Name: "",
                      nivel3: "",
                      variables: arrayNivel3
                    };
                    objetoNivel2.nivel3.push(objeto);
                  } else {
                    const arrayNivel3: any = this.filtrarObjetoNivelesInput(this.contratoResultadosInput, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objetoNivel3: any = {
                      nivel3Name: element3?.nivel3Name || "",
                      nivel3: element3?.nivel3 || "",
                      variables: arrayNivel3 || []
                    };
                    objetoNivel2.nivel3.push(objetoNivel3);
                  }
                });
              } else {
                const arrayNivel2: any = this.filtrarObjetoNivelesInput(this.contratoResultadosInput, element1?.nivel1, element2?.nivel2) || [];
                objetoNivel2.variables.push(...arrayNivel2);
              }
  
              objetoNivel1.nivel2.push(objetoNivel2);
            }
          });
        } else {
          const arrayNivel1: any = this.filtrarObjetoNivelesInput(this.contratoResultadosInput, element1?.nivel1) || [];
          objetoNivel1.variables.push(...arrayNivel1);
        }
  
        respuesta.push(objetoNivel1);
      }
    });
  
    return respuesta || [];
  }

  obtenerArrayCabeceraCalculos(keyNivel: string | undefined, contrato: any): string[] {
    const respuesta: string[] = [];

    if(contrato && Object.keys(contrato).length > 0 && contrato?.nivel1 
      && contrato?.nivel1.length > 0 && contrato?.nivel1.includes(keyNivel) 
      && contrato?.exception && (!contrato?.exception.includes(keyNivel) || (contrato?.exception.includes(keyNivel) && contrato?.nivel1.includes(keyNivel)))
    ) {
      const objetos: any = {
        valores: this.valores
      }
      const arrayContract: number[] = this.generateArrayByContract(contrato?.columnsLength); 
      if(arrayContract && arrayContract.length > 0){
        
        arrayContract.forEach(e => {
          let titulo: string = contrato?.titleVars?.title || '';
          contrato?.titleVars?.subtitle?.forEach((element: {objectFrom: string, index: number | string, key: string, value: string}) => {
            if(element?.objectFrom && element?.key && element?.index === ''){
              titulo += this.getValueFromObject(objetos?.[element?.objectFrom], element?.key) || '';
            } else if(element?.objectFrom && element?.key === 'index' && element?.index !== ''){
              titulo += `(${(Number(e) + Number(element?.index)) + 1})`
            } else if(element?.objectFrom && element?.key && element?.index !== ''){
              titulo += this.getValueFromObject(objetos?.[element?.objectFrom]?.[(Number(e) + Number(element?.index))], element?.key) || '';
            } else if(element?.value) {
              titulo += element?.value;
            } else {
              titulo += '';
            }
          });
          respuesta.push(titulo);
        });

      }

    }
    return respuesta;
  }

  validaNivel(elemento: any, nivel1Array: string[]): boolean{
    if(elemento?.campo?.[0]?.key && nivel1Array && nivel1Array.length > 0 && this.contratoResultados?.[elemento?.campo?.[0]?.key] && Object.keys(this.contratoResultados?.[elemento?.campo?.[0]?.key])) {
      const nivel1: string = this.contratoResultados[elemento?.campo?.[0]?.key]?.nivel1 || '';
      if(nivel1Array.includes(nivel1)){
        return true;
      }
    }
    return false;
  }

  validaException(elemento: any, exceptionArray: string[]): boolean{
    if(elemento?.campo?.[0]?.key && exceptionArray && exceptionArray.length >= 0) {
      const key: string = elemento?.campo?.[0]?.key || '';
      if(!exceptionArray.includes(key)){
        return true;
      }
    }
    return false;
  }

  validaHeaderCalculadoNivel(contratoCalculo: any, nivel1: string): boolean{
    if(contratoCalculo?.nivel1 && contratoCalculo?.nivel1.length > 0 && nivel1 && !contratoCalculo?.nivel1.includes(nivel1)) {
      return false;
    }
    return true;
  }

  validaHeaderCalculadoCampo(contratoCalculo: any, key: string): boolean{
    if(contratoCalculo?.exception && contratoCalculo?.exception.length > 0 && key && contratoCalculo?.exception.includes(key)) {
      return false;
    }
    return true;
  }

  validaMeses(index: number, contratoCalculo: any, nivel1: string): boolean {
    if(contratoCalculo?.validarCantMesesVar === true && contratoCalculo?.validacionMesesVar && contratoCalculo?.validacionMesesVar.length > 0){
      const valoresDiff: number[] = [];

      if(contratoCalculo?.exceptionValidarCantMesesVar && contratoCalculo?.exceptionValidarCantMesesVar.length > 0) {
        if(contratoCalculo?.exceptionValidarCantMesesVar.includes(nivel1)){
          return true;
        }
      }

      contratoCalculo?.validacionMesesVar.forEach((element: number) => {
        const indice: number = index + Number(element) - 1;

        const mesInicio: number = Number(this.valores[indice]?.mesInicio) || 0;
        const mesFin: number = Number(this.valores[indice]?.mesFin) || 0;
        
        const diff = mesFin - mesInicio;
        valoresDiff.push(diff);
      });

      // validar que todos los valores sean iguales
      const valoresIguales: boolean = valoresDiff.every((val, i, arr) => val === arr[0]);
      if(!valoresIguales){
        return false;
      }
    }
    return true;
  }

  obtenerValorCalculadoHeader(index: number, key: string, contratoCalculo: any): any {
    let respuesta: any = contratoCalculo?.defaultValue || contratoCalculo?.defaultValue === 0 ? contratoCalculo?.defaultValue : '';

    const objetos: any = {
      valores: this.valores,
    }
    
    const variables: any[] = [];
    if(contratoCalculo?.variables && contratoCalculo?.variables.length > 0){
      contratoCalculo?.variables.forEach((element: ({objectFrom: string, key: string, column: number|string, value: string|number})) => {
        let valor: number = 0;
        let valorResponse: number | string = '';
        const indice: number = index + Number(element?.column) - 1;
        if(this.valores[indice] && Object.keys(this.valores[indice]).length > 0) {
          valorResponse = (this.valores[indice]?.balance[key] || this.valores[indice]?.balance[key] == 0) ? (this.valores[indice]?.balance[key]) : 
          (this.valores[indice]?.campos[key] || this.valores[indice]?.campos[key] == 0 ? this.valores[indice]?.campos[key] : '');
        }
        if(element.objectFrom && element?.key){
          valor = this.getValueFromObject(objetos?.[element?.objectFrom]?.[index], element?.key) || 0;
        } else if((element?.column || element?.column === 0) && (index || index === 0)) {
          const elementValor = Number(valorResponse) || 0;
          valor = Number(elementValor);
        } else if (element?.value || element?.value === 0) {
          valor = Number(element?.value);
        } else {
          valor = 0;
        }

        variables.push(valor);
      });
    }
    const valoresCalculados: any = {};
    contratoCalculo?.formula.forEach((element: ({key: string, operation: string, values: number[], valueError: number})) => {
      let valor: number | null = null;
      element?.values.forEach(e => {
        const valorElemento: number = this.validarTipoNumber(variables[e]) ? (Number(variables[e])) : (valoresCalculados?.[e]);
        if(element?.operation === 'suma') {
          valor = valor == null ? (valorElemento) : (valor + valorElemento);
        } else if(element?.operation === 'resta') {
          valor = valor == null ? (valorElemento) : (valor - valorElemento);
        } else if(element?.operation === 'multiplicacion') {
          valor = valor == null ? (valorElemento) : (valor * valorElemento);
        } else if(element?.operation === 'division') {
          valor = valor == null ? (valorElemento) : (valor / valorElemento);
        } else {
          valor = valorElemento;
        }

      });

      if((element?.valueError || element?.valueError === 0) && !this.esNumeroValido(valor)) {
        valor = element?.valueError;
      }

      valoresCalculados[element.key] = valor;
    });

    if(valoresCalculados.total || valoresCalculados.total === 0){
      respuesta = Number(valoresCalculados.total);
    }

    return respuesta;
  }

  obtenerValorCalculado(index: number, valoresArray: any, contratoCalculo: any): any { //index se mantiene, valores va a this.valores debe ir con key y contrato calculo se mantiene
    let respuesta: any = contratoCalculo?.defaultValue || contratoCalculo?.defaultValue === 0 ? contratoCalculo?.defaultValue : '';

    const objetos: any = {
      valores: this.valores,
    }
    const variables: any[] = [];
    if(contratoCalculo?.variables && contratoCalculo?.variables.length > 0){
      contratoCalculo?.variables.forEach((element: ({objectFrom: string, key: string, column: number|string, value: string|number})) => {
        let valor: number = 0;

        if(element.objectFrom && element?.key){
          valor = this.getValueFromObject(objetos?.[element?.objectFrom]?.[index], element?.key) || 0;
        } else if((element?.column || element?.column === 0) && (index || index === 0) && valoresArray && valoresArray.length > 0) {
          const elementValor = valoresArray?.[index + Number(element?.column)]?.valor || 0;
          valor = Number(elementValor);
        } else if (element?.value || element?.value === 0) {
          valor = Number(element?.value);
        } else {
          valor = 0;
        }

        variables.push(valor);
      });
    }

    const valoresCalculados: any = {};
    contratoCalculo?.formula.forEach((element: ({key: string, operation: string, values: number[], valueError: number})) => {
      let valor: number | null = null;
      element?.values.forEach(e => {
        const valorElemento: number = this.validarTipoNumber(variables[e]) ? (Number(variables[e])) : (valoresCalculados?.[e]);
        if(element?.operation === 'suma') {
          valor = valor == null ? (valorElemento) : (valor + valorElemento);
        } else if(element?.operation === 'resta') {
          valor = valor == null ? (valorElemento) : (valor - valorElemento);
        } else if(element?.operation === 'multiplicacion') {
          valor = valor == null ? (valorElemento) : (valor * valorElemento);
        } else if(element?.operation === 'division') {
          valor = valor == null ? (valorElemento) : (valor / valorElemento);
        } else {
          valor = valorElemento;
        }

      });

      if((element?.valueError || element?.valueError === 0) && !this.esNumeroValido(valor)) {
        valor = element?.valueError;
      }

      valoresCalculados[element.key] = valor;
    });

    if(valoresCalculados.total || valoresCalculados.total === 0){
      respuesta = Number(valoresCalculados.total);
    }

    return respuesta;
  }

  esNumeroValido(n: any): boolean {
    return typeof n === 'number' && !isNaN(n) && isFinite(n);
  }

  esNumeroOStringNumerico(valor: any): boolean {
    if (typeof valor === "number") {
      return true;
    }

    if (typeof valor === "string" && valor.trim() !== "") {
      return /^[0-9]+$/.test(valor);
    }

    return false;
  }

  obtenerVariablesColumnas(arrayVariables: {objectFrom: string, key: string, value: string|number}[], objetos: any): any[] {
    const respuesta: any = [];
    if(arrayVariables && arrayVariables.length > 0){
      arrayVariables.forEach((element: ({objectFrom: string, key: string, value: string|number})) => {
        let valor: number = 0;
        if(element?.key === 'length'){
          valor = Number(objetos?.[element?.objectFrom]?.length || 0);
        } else if(element?.key !== '' && element?.key != 'length') {
          valor = this.getValueFromObject(objetos?.[element?.objectFrom], element?.key) || 0
        } else if (!element?.key && !element?.objectFrom && (element?.value || element?.value === 0)) {
          valor = Number(element?.value || 0);
        } else {
          valor = 0;
        }
        respuesta.push(valor);
      });
    }
    return respuesta;
  }

  generateArrayByContract(contractLength: any): number[] {
    const respuesta: number[] = [];

    if(contractLength && Object.keys(contractLength).length > 0) {
      const objetos: any = {
        valores: this.valores,
      }
      const variables: any[] = this.obtenerVariablesColumnas(contractLength?.variables, objetos);
      const valoresCalculados: any = {};
      contractLength?.formula.forEach((element: ({key: string, operation: string, values: number[]})) => {
        let valor: number | null = null;
        element?.values.forEach(e => {
          const valorElemento: number = this.validarTipoNumber(variables[e]) ? (Number(variables[e])) : (valoresCalculados?.[e] ? Number(valoresCalculados?.[e]) : 0);
          if(element?.operation === 'suma') {
            valor = valor == null ? (valorElemento) : (valor + valorElemento);
          } else if(element?.operation === 'resta') {
            valor = valor == null ? (valorElemento) : (valor - valorElemento);
          } else if(element?.operation === 'multiplicacion') {
            valor = valor == null ? (valorElemento) : (valor * valorElemento);
          } else if(element?.operation === 'division') {
            valor = valor == null ? (valorElemento) : (valor / valorElemento);
          } else {
            valor = valorElemento;
          }
        });
        valoresCalculados[element.key] = valor;
      });

      if(valoresCalculados.total && valoresCalculados.total > 0){
        for (let index = 0; index < valoresCalculados.total; index++) {
          respuesta.push(index);
        }
      }

    }
    return respuesta;
  }

  obtenerValorTotal(nivelKey: string, objeto: any): any {
    if(nivelKey && objeto && Object.keys(objeto).length > 0){
      const valor: any = (objeto?.balance?.[nivelKey] || objeto?.balance?.[nivelKey] == 0) ? (objeto?.balance?.[nivelKey]) : 
        (objeto?.campos?.[nivelKey] || objeto?.campos?.[nivelKey] == 0) ? (objeto?.campos?.[nivelKey]) : ("");
      return valor;
    }
    return '';
  }

  obtenerValorTotalType(nivelKey: string, objeto: any): string {
    if(nivelKey && objeto && Object.keys(objeto).length > 0){
      return objeto?.definicionBalance?.output?.[nivelKey]?.type ||  "";
    }
    return '';
  }

  validaFilaTotal(nivel1: any): boolean {
    let respuesta: boolean = false;
    // Verificar si el valor total de la fila es diferente de vacío
    if (this.valores.some((valor: any) => this.obtenerValorTotal(nivel1, valor) !== null && this.obtenerValorTotal(nivel1, valor) !== undefined && this.obtenerValorTotal(nivel1, valor) !== "")) {
      respuesta = true;
    }
    // Verificar si algun valor total de los calculos es diferente de vacío
    if(this.calculosWeb && this.calculosWeb.length > 0) {
      for(const calculo of this.calculosWeb){
        if(this.validaHeaderCalculadoNivel(calculo, nivel1)) {
          for(const item of this.generateArrayByContract(calculo?.columnsLength)) {
            if(this.validaHeaderCalculadoCampo(calculo, nivel1) && this.validaMeses(item, calculo, nivel1?.nivel1)) {
              if((this.obtenerValorCalculadoHeader(item, nivel1?.nivel1, calculo) || this.obtenerValorCalculadoHeader(item, nivel1?.nivel1, calculo) === 0)){
                respuesta = true;
                break;
              }
            }
          }
        }
      }
    }

    return respuesta;

  }

  validaFilaTotalExtendido(nivel1: any, contratoResultados: any): boolean {
    // Verificar si el valor total de la fila es diferente de vacío
    if (this.valores.some((valor: any) => this.obtenerTotalSeccionExcel(nivel1, valor, contratoResultados) !== null && this.obtenerTotalSeccionExcel(nivel1, valor, contratoResultados) !== undefined && this.obtenerTotalSeccionExcel(nivel1, valor, contratoResultados) !== "")) {
      return true;
    }
    return false;
  }

  validarTipoNumber(valor: any) : boolean {
    // Si es un número (incluyendo formato "123" pero no valores como "123abc")
    if (!isNaN(valor) && typeof valor === 'number') {
      return true;
    }
  
    // Si es un número pero viene en formato string (por ejemplo "123")
    if (!isNaN(valor) && typeof valor === 'string') {
      return true;
    }
  
    // Si es texto
    if (typeof valor === 'string') {
      return false;
    }
  
    // Si no es ninguno de los anteriores
    return false;
  }

  getValueFromObject(obj: any, key: string): any {
    if (!key || typeof key !== 'string' || !obj || typeof obj !== 'object') {
      return undefined;
    }

    const keys = key.split('.');

    for (let i = 0; i < keys.length; i++) {
      if (obj.hasOwnProperty(keys[i])) {
        obj = obj[keys[i]];
      } else {
        return undefined;
      }
    }
    return obj;
  }

  obtenerTitulo(): string {
    const element = this.data.find(e => e?.Reporte?.tipo === 'balance');
    if(element){
      return 'BALANCE';
    } else {
      return 'PRE BALANCE';
    }
  }

  toggleContent(event: any, id: string): void{
    let estadoClosed: boolean | null = null;
    if(event?.target?.tagName && event?.target?.tagName.toUpperCase() === 'TH') {
      if(event?.target?.parentElement) {
        const parentElement: any = event?.target?.parentElement;
        if ( parentElement.classList.contains('closed') ){
          parentElement.classList.remove('closed');
          estadoClosed = false;
        } else {
          parentElement.classList.add('closed');
          estadoClosed = true;
        }
      }
    } else if(event?.target?.tagName && event?.target?.tagName.toUpperCase() === 'TR') {
      const element: any = event?.target;
      if ( element.classList.contains('closed') ){
        element.classList.remove('closed');
        estadoClosed = false;
      } else {
        element.classList.add('closed');
        estadoClosed = true;
      }

    }

    if(estadoClosed != null){
      const elementosHTML: any = document.querySelectorAll(`[id^="${id}"]`);
      for (const elementoHTML of elementosHTML) {
        if(elementoHTML){
          if(estadoClosed === true) {
            elementoHTML.classList.contains('d-none') ? null : elementoHTML.classList.add('d-none');
          } else {
            elementoHTML.classList.contains('d-none') ? elementoHTML.classList.remove('d-none') : null;
            elementoHTML.classList.contains('closed') ? elementoHTML.classList.remove('closed') : null;
          }
        }
      }
    }
  }

  obtenerNombreMes(mes: number): string {
    const meses = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    // Verificar que el número esté entre 1 y 12
    if (mes && mes >= 1 && mes <= 12) {
        return meses[mes - 1].toUpperCase();
    } else {
        return "";
    }
  }

  obtenerTipoString(tipo: string): string {
    if(tipo === 'balance'){
      return 'Balance';
    } else if(tipo === 'prebalance') {
      return 'Pre Balance';
    } else {
      return '';
    }
  }

  validaVariables(variables: any[], nivelKey: string): boolean {
    let respuesta: boolean = true;
    if(variables && variables.length === 1 && nivelKey) {
      const variable = variables[0] || [];
      if(nivelKey === variable?.[0]?.key){
        respuesta = false
      }
    } 
    return respuesta;
  }

  validaCampo(campo: any[]): boolean {
    let respuesta: boolean = true;
    if(campo && campo.length > 0) {
      for (const property in this.contratoResultados) {
        if(this.contratoResultados[property]?.nivel1){
          if(this.contratoResultados[property]?.nivel1 === campo?.[0]?.key) return false;
        }
        if(this.contratoResultados[property]?.nivel2){
          if(this.contratoResultados[property]?.nivel2 === campo?.[0]?.key) return false;
        }
        if(this.contratoResultados[property]?.nivel3){
          if(this.contratoResultados[property]?.nivel3 === campo?.[0]?.key) return false;
        }
      }

      // logica para quitar campos vacios
      if(campo.filter((e, index) => index > 0).every((e, index) => !e?.valor || e?.valor === '' || e?.valor === 0 || e?.valor === '0')) {
        return false;
      }
    } 
    return respuesta;
  }

  obtenerDatosColumna(index: number): string {
    if(index >= 0){
      const tipo: string = this.valores[index]?.tipo || '';
      const periodo: string = this.valores[index]?.periodo.toString() || '';
      const mesInicio: number = this.valores[index]?.mesInicio || '';
      const mesFin: number = this.valores[index]?.mesFin || '';
      return `${tipo} ${periodo} ${this.obtenerNombreMes(mesInicio)} - ${this.obtenerNombreMes(mesFin)}`;
    }
    return '';
  }

  validaXlsx(): boolean {
    return this.valores.some(e => e?.xlsx);
  }

  validaXlsxReporte(reporte: any): boolean {
    if(reporte && Object.keys(reporte).length > 0 && reporte?.xlsx)
      return true;
    
    return false;
  }

  descargarExcel(reporte: any): any {
    if(reporte?.xlsx){
      const downloadLink = document.createElement('a');
      const fileName = `${reporte.tipo}_${reporte?.subTipo}_${this.rut}_${reporte?.periodo}_${reporte?.mesInicio}_${reporte?.mesFin}.xlsx`;
      downloadLink.href = 'data:application/octet-stream;base64,' + reporte?.xlsx;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }

  validaCampoExcel(campo: any[], contratoResultados: any): boolean {
    let respuesta: boolean = true;
    if(campo && campo.length > 0) {
      for (const property in contratoResultados) {
        if(contratoResultados[property]?.nivel1){
          if(contratoResultados[property]?.nivel1 === campo?.[0]?.key) return false;
        }
        if(contratoResultados[property]?.nivel2){
          if(contratoResultados[property]?.nivel2 === campo?.[0]?.key) return false;
        }
        if(contratoResultados[property]?.nivel3){
          if(contratoResultados[property]?.nivel3 === campo?.[0]?.key) return false;
        }
      }

      // logica para quitar campos vacios
      if(campo.filter((e, index) => index > 0).every((e, index) => !e?.valor || e?.valor === '' || e?.valor === 0 || e?.valor === '0')) {
        return false;
      }
    } 
    return respuesta;
  }

  validaCampoExtendidoExcel(campo: any[], cantVariables: number, contratoResultados: any): boolean {
    let respuesta: boolean = true;
    if(campo && campo.length > 0) {
      for (const property in contratoResultados) {
        if(contratoResultados[property]?.nivel1){
          if(contratoResultados[property]?.nivel1 === campo?.[0]?.key && cantVariables === 1) return false;
        }
        if(contratoResultados[property]?.nivel2){
          if(contratoResultados[property]?.nivel2 === campo?.[0]?.key && cantVariables === 1) return false;
        }
        if(contratoResultados[property]?.nivel3){
          if(contratoResultados[property]?.nivel3 === campo?.[0]?.key && cantVariables === 1) return false;
        }
      }
      // logica para quitar campos vacios
      if(campo.filter((e, index) => index > 0).every((e, index) => !e?.valor || e?.valor === '' || e?.valor === 0 || e?.valor === '0')) {
        return false;
      }
    } 
    return respuesta;
  }

  obtenerContratoExcel(): any {
    const element = this.data.find(e => e?.Reporte?.tipo === 'balance');
    if(element){
      return element?.Reporte?.definicionBalance?.output || {};
    } else {
      return this.data[0]?.Reporte?.definicionBalance?.output || {};
    }
  }

  obtenerValoresExcel(): any[] {
    const valores: any[] = [];
    this.data.forEach(element => {
      const objeto: any = element?.Reporte || {};
      if(Object.keys(objeto).length > 0 && objeto?.periodo){
        objeto.IDTransaccion = element?.DatosBasicosSolicitud?.IDTransaccion || '';
        valores.push(objeto);
      }
    });

    if(valores.length > 0){
      valores.sort(function(a, b) {
        // Convertir el campo "periodo" a un número para comparar correctamente
        const periodoA = Number(a.periodo);
        const periodoB = Number(b.periodo);
        
        // Comparar los valores de periodo
        if (periodoA > periodoB) {
          return -1;
        } else if (periodoA < periodoB) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    return valores;
  }

  obtenerSeccionesExcel(contratoResultados: any, visualizacionExtendida: boolean): any[] {
    let secciones: any[] = []; 
    const sectionMap = new Map();
  
    for (const key in contratoResultados) {
      const nivel1 = contratoResultados[key].nivel1;
      const nivel1Name = contratoResultados[key].nivel1Name;
      const nivel2 = contratoResultados[key].nivel2;
      const nivel2Name = contratoResultados[key].nivel2Name;
      const nivel3 = contratoResultados[key].nivel3;
      const nivel3Name = contratoResultados[key].nivel3Name;
  
      if (!sectionMap.has(nivel1)) {
        sectionMap.set(nivel1, { nivel1, nivel1Name, nivel2: [] });
      }
  
      const sectionObj = sectionMap.get(nivel1);
      const nivel2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
  
      if (!nivel2Obj) {
        sectionObj.nivel2.push({ nivel2, nivel2Name, nivel3: [] });
      } else {
        const section2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
        const nivel3Obj = section2Obj.nivel3.find((subSec: any) => subSec.nivel3 === nivel3);
    
        if (!nivel3Obj) {
          section2Obj.nivel3.push({ nivel3, nivel3Name });
        }
      }
  
    }
  
    secciones = Array.from(sectionMap.values());

    if(visualizacionExtendida === true)
    secciones = secciones.filter(e => e.nivel1 === 'liquideseindicadores');

    return secciones;

  }

  generarContratoFromContratoExcel(secciones: any[], contratoResultados: any): any[] {
    const respuesta: any[] = [];
  
    secciones.forEach(element1 => {
      if (!element1?.nivel1) {
        const arrayUndefined: any = this.filtrarObjetoNiveles(contratoResultados, element1?.nivel1) || [];
        const objeto: any = { variables: arrayUndefined };
        respuesta.push(objeto);
      } else {
        const objetoNivel1: any = {
          nivel1Name: element1?.nivel1Name || "",
          nivel1: element1?.nivel1 || "",
          variables: [],
          nivel2: []
        };
  
        if (element1?.nivel2 && element1?.nivel2.length > 0) {
          element1.nivel2.forEach((element2: any) => {
            if (!element2?.nivel2) {
              const arrayNivel2: any = this.filtrarObjetoNiveles(contratoResultados, element1?.nivel1, element2?.nivel2) || [];
              const objeto: any = {
                nivel2Name: "",
                nivel2: "",
                variables: arrayNivel2
              };
              objetoNivel1.nivel2.push(objeto);
            } else {
              const objetoNivel2: any = {
                nivel2Name: element2?.nivel2Name || "",
                nivel2: element2?.nivel2 || "",
                variables: [],
                nivel3: []
              };
  
              if (element2?.nivel3 && element2?.nivel3.length > 0) {
                element2.nivel3.forEach((element3: any) => {
                  if (!element3?.nivel3) {
                    const arrayNivel3: any = this.filtrarObjetoNiveles(contratoResultados, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objeto: any = {
                      nivel3Name: "",
                      nivel3: "",
                      variables: arrayNivel3
                    };
                    objetoNivel2.nivel3.push(objeto);
                  } else {
                    const arrayNivel3: any = this.filtrarObjetoNiveles(contratoResultados, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objetoNivel3: any = {
                      nivel3Name: element3?.nivel3Name || "",
                      nivel3: element3?.nivel3 || "",
                      variables: arrayNivel3 || []
                    };
                    objetoNivel2.nivel3.push(objetoNivel3);
                  }
                });
              } else {
                const arrayNivel2: any = this.filtrarObjetoNiveles(contratoResultados, element1?.nivel1, element2?.nivel2) || [];
                objetoNivel2.variables.push(...arrayNivel2);
              }
  
              objetoNivel1.nivel2.push(objetoNivel2);
            }
          });
        } else {
          const arrayNivel1: any = this.filtrarObjetoNiveles(contratoResultados, element1?.nivel1) || [];
          objetoNivel1.variables.push(...arrayNivel1);
        }
  
        respuesta.push(objetoNivel1);
      }
    });
  
    return respuesta || [];
  }

  obtenerContratoInputExcel(): any {
    const element = this.data.find(e => e?.Reporte?.tipo === 'balance');
    if(element){
      return element?.Reporte?.definicionBalance?.input || {};
    } else {
      return this.data[0]?.Reporte?.definicionBalance?.input || {};
    }
  }

  obtenerSeccionesInputExcel(contratoResultadosInput: any): any[] {
    const sectionMap = new Map();
  
    for (const key in contratoResultadosInput) {
      const nivel1 = contratoResultadosInput[key].nivel1;
      const nivel1Name = contratoResultadosInput[key].nivel1Name;
      const nivel2 = contratoResultadosInput[key].nivel2;
      const nivel2Name = contratoResultadosInput[key].nivel2Name;
      const nivel3 = contratoResultadosInput[key].nivel3;
      const nivel3Name = contratoResultadosInput[key].nivel3Name;
  
      if (!sectionMap.has(nivel1)) {
        sectionMap.set(nivel1, { nivel1, nivel1Name, nivel2: [] });
      }
  
      const sectionObj = sectionMap.get(nivel1);
      const nivel2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
  
      if (!nivel2Obj) {
        sectionObj.nivel2.push({ nivel2, nivel2Name, nivel3: [] });
      } else {
        const section2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
        const nivel3Obj = section2Obj.nivel3.find((subSec: any) => subSec.nivel3 === nivel3);
    
        if (!nivel3Obj) {
          section2Obj.nivel3.push({ nivel3, nivel3Name });
        }
      }
  
    }
  
    return Array.from(sectionMap.values());

  }

  generarContratoFromContratoInputExcel(seccionesInput: any[], contratoResultadosInput: any): any[] {
    const respuesta: any[] = [];
  
    seccionesInput.forEach(element1 => {
      if (!element1?.nivel1) {
        const arrayUndefined: any = this.filtrarObjetoNivelesInput(contratoResultadosInput, element1?.nivel1) || [];
        const objeto: any = { variables: arrayUndefined };
        respuesta.push(objeto);
      } else {
        const objetoNivel1: any = {
          nivel1Name: element1?.nivel1Name || "",
          nivel1: element1?.nivel1 || "",
          variables: [],
          nivel2: []
        };
  
        if (element1?.nivel2 && element1?.nivel2.length > 0) {
          element1.nivel2.forEach((element2: any) => {
            if (!element2?.nivel2) {
              const arrayNivel2: any = this.filtrarObjetoNivelesInput(contratoResultadosInput, element1?.nivel1, element2?.nivel2) || [];
              const objeto: any = {
                nivel2Name: "",
                nivel2: "",
                variables: arrayNivel2
              };
              objetoNivel1.nivel2.push(objeto);
            } else {
              const objetoNivel2: any = {
                nivel2Name: element2?.nivel2Name || "",
                nivel2: element2?.nivel2 || "",
                variables: [],
                nivel3: []
              };
  
              if (element2?.nivel3 && element2?.nivel3.length > 0) {
                element2.nivel3.forEach((element3: any) => {
                  if (!element3?.nivel3) {
                    const arrayNivel3: any = this.filtrarObjetoNivelesInput(contratoResultadosInput, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objeto: any = {
                      nivel3Name: "",
                      nivel3: "",
                      variables: arrayNivel3
                    };
                    objetoNivel2.nivel3.push(objeto);
                  } else {
                    const arrayNivel3: any = this.filtrarObjetoNivelesInput(contratoResultadosInput, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objetoNivel3: any = {
                      nivel3Name: element3?.nivel3Name || "",
                      nivel3: element3?.nivel3 || "",
                      variables: arrayNivel3 || []
                    };
                    objetoNivel2.nivel3.push(objetoNivel3);
                  }
                });
              } else {
                const arrayNivel2: any = this.filtrarObjetoNivelesInput(contratoResultadosInput, element1?.nivel1, element2?.nivel2) || [];
                objetoNivel2.variables.push(...arrayNivel2);
              }
  
              objetoNivel1.nivel2.push(objetoNivel2);
            }
          });
        } else {
          const arrayNivel1: any = this.filtrarObjetoNivelesInput(contratoResultadosInput, element1?.nivel1) || [];
          objetoNivel1.variables.push(...arrayNivel1);
        }
  
        respuesta.push(objetoNivel1);
      }
    });
  
    return respuesta || [];
  }

  obtencionDeValoresExcel(): {contratoResultados: any, secciones: any[], seccionesExtendida: any[], arrayContrato: any[], arrayContratoExtendida: any[], contratoInput: any, seccionesInput: any[], arrayContratoInput: any[]} {
    const contratoResultados: any = this.obtenerContratoExcel();
    const secciones: any[] = this.obtenerSeccionesExcel(contratoResultados, false);
    const seccionesExtendida: any[] = this.obtenerSeccionesExcel(contratoResultados, true);
    const arrayContrato: any[] = this.generarContratoFromContratoExcel(secciones, contratoResultados);
    const arrayContratoExtendida: any[] = this.generarContratoFromContratoExcel(seccionesExtendida, contratoResultados);

    const contratoInput: any = this.obtenerContratoInputExcel();
    const seccionesInput: any[] = this.obtenerSeccionesInputExcel(contratoInput);
    const arrayContratoInput: any[] = this.generarContratoFromContratoInputExcel(seccionesInput, contratoInput);
    
    return {
      contratoResultados,
      secciones,
      seccionesExtendida,
      arrayContrato,
      arrayContratoExtendida,
      contratoInput,
      seccionesInput,
      arrayContratoInput
    };
  }

  //Funcion para obtener letra a partir de numero
  getColName(n: number): string {
    const ordA = 'a'.charCodeAt(0);
    const ordZ = 'z'.charCodeAt(0);
    const len = ordZ - ordA + 1;
  
    let s = "";
    while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s.toUpperCase();
  }

  capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  formatInteger(num: any): string {
    if (num === 0) return '0';
    if(!num) return '';

    const roundedNum = Math.round(num);

    const isNegative = roundedNum < 0;
    const absoluteNum = Math.abs(roundedNum);

    let formattedNumber = absoluteNum.toLocaleString('de-DE');

    if (isNegative) {
        formattedNumber = `-${formattedNumber}`;
    }

    return formattedNumber;
  }

  // Sin uso
  formatDecimal(num: any): string {
    if (num === 0) return '0';
    if(!num) return '';

    const isNegative = num < 0;
    const absoluteNum = Math.abs(num);

    let formattedNumber = absoluteNum.toFixed(2);
    formattedNumber = parseFloat(formattedNumber).toLocaleString('de-DE');

    if (isNegative) {
      formattedNumber = `-${formattedNumber}`;
    }

    return formattedNumber;
  }
   

  getRowValue(campo: any[], sheet: string, numeroFila: number): any[] {
    const obj: any ={};
    for(const [j, item] of campo.entries()) {

      let valor: any = '';
      if(item?.valor || item?.valor == 0) {
        if(this.esNumeroOStringNumerico(item?.valor)) {
          if(item?.type === 'number') {
            valor = Math.round(item?.valor);
            this.pushEstiloExcel('int', sheet, `${this.getColName(j)}${numeroFila}`);
          } else if(item?.type === 'porc-int') {
            valor = item?.valor;
            this.pushEstiloExcel('porcentaje-int', sheet, `${this.getColName(j)}${numeroFila}`);
          } else if(item?.type === 'porc-decimal') {
            valor = item?.valor;
            this.pushEstiloExcel('porcentaje-decimal', sheet, `${this.getColName(j)}${numeroFila}`);
          } else if(item?.type === 'decimal') {
            valor = item?.valor;
            this.pushEstiloExcel('decimal', sheet, `${this.getColName(j)}${numeroFila}`);
          } else {
            valor = item?.valor;
            this.pushEstiloExcel('int', sheet, `${this.getColName(j)}${numeroFila}`);
          }
        } else {
          valor = item?.valor;
        }
      } else {
        valor = '';
      }
      obj[`${this.getColName(j)}`] = valor;

    }
    for(const calculo of this.calculosWeb) {
      if(calculo?.columnsLength && Object.keys(calculo?.columnsLength).length > 0 && this.validaNivel({campo: campo}, calculo?.nivel1)) {
        for(const [j, item] of this.generateArrayByContract(calculo?.columnsLength).entries()) {
          let key = (campo.length + j);
          while(obj[`${this.getColName(key)}`] !== undefined) {
            key = key + 1;
          }
          if(this.validaException({campo: campo}, calculo?.exception) && this.validaMeses(item, calculo, this.contratoResultados[campo?.[0]?.key]?.nivel1 || '')) {            
            if(this.obtenerValorCalculado(item, campo, calculo) || this.obtenerValorCalculado(item, campo, calculo) === 0) {
              obj[`${this.getColName(key)}`] = Math.round(this.obtenerValorCalculado(item, campo, calculo));
              if(calculo?.simbolo) {
                this.pushEstiloExcel('porcentaje-int', sheet, `${this.getColName(key)}${numeroFila}`);
              } else {
                this.pushEstiloExcel('int', sheet, `${this.getColName(key)}${numeroFila}`);
              }
            } else {
              obj[`${this.getColName(key)}`] = "";
            }
          } else {
            obj[`${this.getColName(key)}`] = "";
          }
        }
      }
    }
    return [obj];
  }

  getRowTitle(isNivel1: boolean = false, nivelObj: any, nivelKey: string): any[] {
    const objetoNivel: any = {};

    if(isNivel1) {
      objetoNivel.A = nivelObj?.nivel1Name || '';
    }

    for(const [j, valor] of this.valores.entries()) {
      objetoNivel[`${this.getColName((j + 1))}`] = `${this.capitalize(this.obtenerDatosColumna(j))} (${(j+1)})`;
    }
    for(const [index, calculo] of this.calculosWeb.entries()) {
      for(const [k, item] of this.obtenerArrayCabeceraCalculos(nivelObj?.[nivelKey], calculo).entries()) {
        let key = this.valores.length;
        while(objetoNivel[`${this.getColName(key)}`] !== undefined) {
          key = key + 1;
        }
        objetoNivel[`${this.getColName(key)}`] = `${(item || '')}`;
      }
    }

    return [objetoNivel];
  }

  getTypeExcel(type: string): string {
    switch(type) {
      case 'number':
        return 'int';
      case 'decimal':
        return 'decimal';
      case 'porc-int':
        return 'porcentaje-int';
      case 'porc-decimal':
        return 'porcentaje-decimal';
      default:
        return '';
    }
  }

  getRowTitleTotales(isNivel1: boolean = false, nivelObj: any, nivelKey: string, nivel1: string, sheet: string, numeroFila: number): any[] {
    const objCabecerasTotales: any = {};

    if(isNivel1) {
      objCabecerasTotales.A = 'Total ' + nivelObj?.nivel1Name || '';
    } else {
      objCabecerasTotales.A = nivelObj?.[`${nivelKey}Name`] || '';
    }

    for(const [j, valor] of this.valores.entries()) {
      const valorCelda: any = this.obtenerValorTotal(nivelObj?.[nivelKey], valor);
      const typeCelda: string = this.getTypeExcel(this.obtenerValorTotalType(nivelObj?.[nivelKey], valor));
      
      objCabecerasTotales[`${this.getColName(j+1)}`] = valorCelda;
      if(valorCelda || valorCelda === 0) {
        this.pushEstiloExcel(typeCelda, sheet, `${this.getColName(j+1)}${numeroFila}`);
      }
    }
    for(const [index, calculo] of this.calculosWeb.entries()) {
      if(this.validaHeaderCalculadoNivel(calculo, nivel1)) {
        for(const [k, item] of this.generateArrayByContract(calculo?.columnsLength).entries()) {
          let key = this.valores.length;
          while(objCabecerasTotales[`${this.getColName(key)}`] !== undefined) {
            key = key + 1;
          }
          if(this.validaHeaderCalculadoCampo(calculo, nivelObj?.[nivelKey]) && this.validaMeses(item, calculo, nivel1)) {
            const valorCalculado: any = this.obtenerValorCalculadoHeader(item, nivelObj?.[nivelKey], calculo);
            objCabecerasTotales[`${this.getColName(key)}`] = valorCalculado;

            if(valorCalculado || valorCalculado === 0) {
              if(calculo?.simbolo) {
                this.pushEstiloExcel('porcentaje-int', sheet, `${this.getColName(key)}${numeroFila}`);
              } else {
                this.pushEstiloExcel('int', sheet, `${this.getColName(key)}${numeroFila}`);
              }
            }

          } else {
            objCabecerasTotales[`${this.getColName(key)}`] = "";
          }
        }
      }
    }

    return [objCabecerasTotales];
  }

  getRowValueSinCalculo(campo: any[], sheet: string, numeroFila: number): any[] {
    const obj: any ={};
    for(const [j, item] of campo.entries()) {
      const valor = item?.valor;
      obj[`${this.getColName(j)}`] = valor
      
      if(valor || valor == 0) {

        if(this.esNumeroOStringNumerico(valor)) {
          if(item?.type === 'number') {
            this.pushEstiloExcel('int', sheet, `${this.getColName(j)}${numeroFila}`);
          } else if(item?.type === 'porc-int') {
            this.pushEstiloExcel('porcentaje-int', sheet, `${this.getColName(j)}${numeroFila}`);
          } else if(item?.type === 'porc-decimal') {
            this.pushEstiloExcel('porcentaje-decimal', sheet, `${this.getColName(j)}${numeroFila}`);
          } else if(item?.type === 'decimal') {
            this.pushEstiloExcel('decimal', sheet, `${this.getColName(j)}${numeroFila}`);
          } else {
            this.pushEstiloExcel('int', sheet, `${this.getColName(j)}${numeroFila}`);
          }
        }

        this.pushEstiloExcel('int', 'Hoja1', `${this.getColName(j)}${numeroFila}`);
      }
    }
    return [obj];
  }

  getRowTitleExtendido(isNivel1: boolean = false, nivelObj: any): any[] {
    const objetoNivel: any = {};

    if(isNivel1) {
      objetoNivel.A = nivelObj?.nivel1Name || '';
    }

    for(const [j, valor] of this.valores.entries()) {
      objetoNivel[`${this.getColName((j + 1))}`] = `${this.capitalize(this.obtenerDatosColumna(j))} (${(j+1)})`;
    }

    return [objetoNivel];
  }

  obtenerTotalSeccionExcel(nivelKey: string, objeto: any, contratoResultados: any): number | string {
    if(nivelKey && objeto?.campos && Object.keys(objeto?.campos).length > 0){
      let suma = 0;
      for (const key in objeto?.campos) {
        if(contratoResultados?.[key] && Object.keys(contratoResultados?.[key]).length > 0 && 
          (contratoResultados?.[key]?.type === 'number' || contratoResultados?.[key]?.type === 'decimal') && 
          contratoResultados?.[key]?.nivel1 === nivelKey){
          suma += Number(objeto?.campos[key]);
        }
      }
      return suma;
    }
    return '';
  }

  obtenerTotalSubSeccionExcel(nivelKey: string, nivel2Key: string, objeto: any, contratoResultados: any): number | string {
    if(nivelKey && objeto?.campos && Object.keys(objeto?.campos).length > 0){
      let suma = 0;
      for (const key in objeto?.campos) {
        if(contratoResultados?.[key] && Object.keys(contratoResultados?.[key]).length > 0 && 
          (contratoResultados?.[key]?.type === 'number' || contratoResultados?.[key]?.type === 'decimal') && 
          contratoResultados?.[key]?.nivel1 === nivelKey && 
          contratoResultados?.[key]?.nivel2 === nivel2Key){
          suma += Number(objeto?.campos[key]);
        }
      }
      return suma;
    }
    return '';
  }

  obtenerTotalSeccion3Excel(nivelKey: string, nivel2Key: string, nivel3Key: string, objeto: any, contratoResultados: any): number | string {
    if(nivelKey && objeto?.campos && Object.keys(objeto?.campos).length > 0){
      let suma = 0;
      for (const key in objeto?.campos) {
        if(contratoResultados?.[key] && Object.keys(contratoResultados?.[key]).length > 0 && 
          (contratoResultados?.[key]?.type === 'number' || contratoResultados?.[key]?.type === 'decimal') && 
          contratoResultados?.[key]?.nivel1 === nivelKey && 
          contratoResultados?.[key]?.nivel2 === nivel2Key && contratoResultados?.[key]?.nivel3 === nivel3Key){
          suma += Number(objeto?.campos[key]);
        }
      }
      return suma;
    }
    return '';
  }

  getRowTitleTotalesExtendido(isNivel1: boolean = false, nivelObj: any, nivelKey: string, contratoResultados: any, nivelesKeys: string[], sheet: string, numeroFila: number): any[] {
    const objCabecerasTotales: any = {};

    if(isNivel1) {
      objCabecerasTotales.A = 'Total ' + nivelObj?.nivel1Name || '';
    } else {
      objCabecerasTotales.A = nivelObj?.[`${nivelKey}Name`] || '';
    }

    for(const [j, valor] of this.valores.entries()) {
      switch(nivelesKeys.length) {
        case 1:
          objCabecerasTotales[`${this.getColName(j+1)}`] = this.obtenerTotalSeccionExcel(nivelesKeys[0], valor, contratoResultados);
          this.pushEstiloExcel('int', sheet, `${this.getColName(j+1)}${numeroFila}`);
          break;
        case 2:
          objCabecerasTotales[`${this.getColName(j+1)}`] = this.obtenerTotalSubSeccionExcel(nivelesKeys[0], nivelesKeys[1], valor, contratoResultados);
          this.pushEstiloExcel('int', sheet, `${this.getColName(j+1)}${numeroFila}`);
          break;
        case 3:
          objCabecerasTotales[`${this.getColName(j+1)}`] = this.obtenerTotalSeccion3Excel(nivelesKeys[0], nivelesKeys[1], nivelesKeys[2], valor, contratoResultados);
          this.pushEstiloExcel('int', sheet, `${this.getColName(j+1)}${numeroFila}`);
          break;
        default:
          objCabecerasTotales[`${this.getColName(j+1)}`] = "";
          break;
      }
    }

    return [objCabecerasTotales];
  }

  marcadorEstilosExcel(letra: string, numero: string, nivel: number): any { // letra es la letra de la columna, numero es el numero de fila
    // niveles 0 -> texto "plano", 1 -> titulo principal, 2 -> sub titulo, 3 -> sub titulo 2
    const obj: any = {
      cell: `${letra}${numero}`
    };
    switch(nivel) {
      case 0:
        obj.style = {
            fill: {
            fgColor: { rgb: "ffffff" } // Color de fondo
          },
          font: {
            name: 'Calibri',
            sz: 11,
            bold: false,
          }
        };
        break;
      case 1:
        obj.style = {
            fill: {
            fgColor: { rgb: "007bff" } // Color de fondo
          },
          font: {
            name: 'Calibri',
            sz: 14,
            bold: true,
            color: { rgb: "ffffff" } // Color de fuente
          }
        };
        break;
      case 2:
        obj.style = {
            fill: {
            fgColor: { rgb: "4a8ed1" } // Color de fondo
          },
          font: {
            name: 'Calibri',
            sz: 13,
            bold: true,
            color: { rgb: "ffffff" } // Color de fuente
          }
        };
        break;
      case 3:
        obj.style = {
            fill: {
            fgColor: { rgb: "8cb2e5" } // Color de fondo
          },
          font: {
            name: 'Calibri',
            sz: 12,
            bold: true,
            color: { rgb: "ffffff" } // Color de fuente
          }
        };
        break;
      default:
        break;
    }

    return obj;
  }

  marcadorEstilosExcelObjetos(numero: string, nivel: number, objeto: any): any[] { // numero es el numero de fila
    if(objeto && Object.keys(objeto).length > 0) {
      const estilos: any[] = [];
      for (const property in objeto) {
        estilos.push(this.marcadorEstilosExcel(property, numero, nivel));
      }
      return estilos;
    }
    return [];
  }

  pushEstiloExcel(type: string, sheet: string, ref: string): void {
    if(this.formatosExcel && this.formatosExcel[sheet]) {
      const index = this.formatosExcel[sheet].findIndex((e: any) => e.ref === ref);
      let numFmt: string = '';
      switch(type) {
        case 'int': 
          numFmt = '#,##0';
          break;
        case 'decimal': 
          numFmt = '#,##0.00';
          break;
        case 'porcentaje-int':
          numFmt = '#,##0\\%';
          break;
        case 'porcentaje-decimal':
          numFmt = '#,##0.00\\%';
          break;
      }
      if(index > -1) {
        this.formatosExcel[sheet][index].numFmt = numFmt;
      } else {
        this.formatosExcel[sheet].push({
          ref: ref,
          numFmt: numFmt
        });
      }
    }
  }

  generarExcel(): void {
    this.formatosExcel = {
      wsr: [],
      wse: []
    };
    const data: {contratoResultados: any, 
      secciones: any[], 
      seccionesExtendida: any[], 
      arrayContrato: any[], 
      arrayContratoExtendida: any[], 
      contratoInput: any, 
      seccionesInput: any[], 
      arrayContratoInput: any[]
    } = this.obtencionDeValoresExcel();

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const estilosCeldasResumido: any[] = [];
    let numeroFilaResumido: number = 10; // fila inicial del bucle, sin contar la cabecera con periodo, tipo, etc

    //                                Inicio Hoja Resumido - wsr

    // Cabecera detalle
    const arrayCabeceraDetalle: any[] = [
      {A: `Rut`},
      {A: `Razón Social`},
      {A: `Año`},
      {A: `Tipo de balance (1)`},
      {A: `Tipo de balance (2)`},
      {A: `Auditado`},
      {A: `Moneda`},
      {A: `Desde`},
      {A: `Hasta`},
    ];

    // recorrer valores con index
    arrayCabeceraDetalle[0][`${this.getColName((1))}`] = this.rut || '';
    arrayCabeceraDetalle[1][`${this.getColName((1))}`] = this.razonSocial || '';
    for (const [i, value] of this.valores.entries()) {
      arrayCabeceraDetalle[2][`${this.getColName((i + 1))}`] = value?.periodo ? value?.periodo.toString() : '';
      arrayCabeceraDetalle[3][`${this.getColName((i + 1))}`] = this.obtenerTipoString(value?.tipo) || '';
      arrayCabeceraDetalle[4][`${this.getColName((i + 1))}`] = value?.subTipo || '';
      arrayCabeceraDetalle[5][`${this.getColName((i + 1))}`] = value?.auditado === true ? 'Si' : (value?.auditado === false ? 'No' : '');
      arrayCabeceraDetalle[6][`${this.getColName((i + 1))}`] = value?.moneda || '';
      arrayCabeceraDetalle[7][`${this.getColName((i + 1))}`] = this.obtenerNombreMes(value?.mesInicio) || '';
      arrayCabeceraDetalle[8][`${this.getColName((i + 1))}`] = this.obtenerNombreMes(value?.mesFin) || '';
    }

    estilosCeldasResumido.push(this.marcadorEstilosExcel(this.getColName(0), '1', 2));
    estilosCeldasResumido.push(this.marcadorEstilosExcel(this.getColName(0), '2', 2));
    estilosCeldasResumido.push(this.marcadorEstilosExcel(this.getColName(0), '3', 2));
    estilosCeldasResumido.push(this.marcadorEstilosExcel(this.getColName(0), '4', 2));
    estilosCeldasResumido.push(this.marcadorEstilosExcel(this.getColName(0), '5', 2));
    estilosCeldasResumido.push(this.marcadorEstilosExcel(this.getColName(0), '6', 2));
    estilosCeldasResumido.push(this.marcadorEstilosExcel(this.getColName(0), '7', 2));
    estilosCeldasResumido.push(this.marcadorEstilosExcel(this.getColName(0), '8', 2));
    estilosCeldasResumido.push(this.marcadorEstilosExcel(this.getColName(0), '9', 2));

    // se crea el worksheet
    const wsr = XLSX.utils.json_to_sheet(arrayCabeceraDetalle, {header: [], skipHeader: true});

    // se agrega json a la hoja
    XLSX.utils.sheet_add_json(wsr, [], {header: [], skipHeader: true, origin: -1});
    XLSX.utils.sheet_add_json(wsr, [{A: ""}], {header: [], skipHeader: true, origin: -1});
    numeroFilaResumido++;

    //this.arrayContrato
    for(const [i, nivel1] of data.arrayContrato.entries()) {
      if(!nivel1?.nivel1) {
        if(nivel1?.variables && nivel1?.variables.length > 0) {
          for(const campo of nivel1?.variables) {
            if(campo && campo.length > 0) {
              XLSX.utils.sheet_add_json(wsr, this.getRowValue(campo, 'wsr', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
              numeroFilaResumido++;
            }

          }
        }
      } else {
        // cabeceras titulos -----------------------------------------------------

        XLSX.utils.sheet_add_json(wsr, this.getRowTitle(true, nivel1, 'nivel1'), {header: [], skipHeader: true, origin: -1});
        estilosCeldasResumido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumido.toString(), 1, this.getRowTitle(true, nivel1, 'nivel1')[0]));
        numeroFilaResumido++;

        if(nivel1?.nivel2 && nivel1?.nivel2.length > 0) {
          
          for(const nivel2 of nivel1?.nivel2) {
            if(!nivel2?.nivel2){
              if(nivel2?.variables && nivel2?.variables.length > 0) {
                for(const campo of nivel2?.variables) {
                  if(this.validaCampoExcel(campo, data.contratoResultados)) {
                    XLSX.utils.sheet_add_json(wsr, this.getRowValue(campo, 'wsr', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
                    numeroFilaResumido++;
                  }
                }
              }
            } else {
              if(nivel1?.nivel1 === 'liquideseindicadores') {
                XLSX.utils.sheet_add_json(wsr, this.getRowTitleTotales(false, nivel2, 'nivel2', nivel1?.nivel1, 'wsr', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
                estilosCeldasResumido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumido.toString(), 2, this.getRowTitleTotales(false, nivel2, 'nivel2', nivel1?.nivel1, 'wsr', numeroFilaResumido)[0]));
                numeroFilaResumido++;
              }

              if(nivel2?.nivel3 && nivel2?.nivel3.length > 0) {
                for(const nivel3 of nivel2?.nivel3) {
                  if(!nivel3?.nivel3) {
                    for(const campo of nivel3?.variables) {
                      if(this.validaCampoExcel(campo, data.contratoResultados)) {
                        XLSX.utils.sheet_add_json(wsr, this.getRowValue(campo, 'wsr', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
                        numeroFilaResumido++;
                      }
                    }
                  } else {
                    if(nivel1?.nivel1 === 'liquideseindicadores') {
                      XLSX.utils.sheet_add_json(wsr, this.getRowTitleTotales(false, nivel3, 'nivel3', nivel1?.nivel1, 'wsr', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
                      estilosCeldasResumido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumido.toString(), 3, this.getRowTitleTotales(false, nivel3, 'nivel3', nivel1?.nivel1, 'wsr', numeroFilaResumido)[0]));
                      numeroFilaResumido++;
                    }

                    for(const campo of nivel3?.variables) {
                      if(this.validaCampoExcel(campo, data.contratoResultados)) {
                        XLSX.utils.sheet_add_json(wsr, this.getRowValue(campo, 'wsr', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
                        numeroFilaResumido++;
                      }
                    }

                    if(nivel1?.nivel1 !== 'liquideseindicadores') {
                      XLSX.utils.sheet_add_json(wsr, this.getRowTitleTotales(false, nivel3, 'nivel3', nivel1?.nivel1, 'wsr', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
                      estilosCeldasResumido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumido.toString(), 3, this.getRowTitleTotales(false, nivel3, 'nivel3', nivel1?.nivel1, 'wsr', numeroFilaResumido)[0]));
                      numeroFilaResumido++;
                    }
                  }
                }
              } else {
                for(const campo of nivel2?.variables) {
                  if(this.validaCampoExcel(campo, data.contratoResultados)) {
                    XLSX.utils.sheet_add_json(wsr, this.getRowValue(campo, 'wsr', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
                    numeroFilaResumido++;
                  }
                }
              }

              if(nivel1?.nivel1 !== 'liquideseindicadores') {
                XLSX.utils.sheet_add_json(wsr, this.getRowTitleTotales(false, nivel2, 'nivel2', nivel1?.nivel1, 'wsr', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
                estilosCeldasResumido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumido.toString(), 2, this.getRowTitleTotales(false, nivel2, 'nivel2', nivel1?.nivel1, 'wsr', numeroFilaResumido)[0]));
                numeroFilaResumido++;
              }
            }
          }

        } else {
          if(nivel1?.variables && nivel1?.variables.length > 0) {
            for(const campo of nivel1?.variables) {
              if(this.validaCampoExcel(campo, data.contratoResultados)) {
                XLSX.utils.sheet_add_json(wsr, this.getRowValue(campo, 'wsr', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
                numeroFilaResumido++;
              }
            }
          }
        }

        if(this.validaFilaTotal(nivel1?.nivel1)) {
          // cabeceras valores totales -----------------------------------------------------

          XLSX.utils.sheet_add_json(wsr, this.getRowTitleTotales(true, nivel1, 'nivel1', nivel1?.nivel1, 'wsr', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
          estilosCeldasResumido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumido.toString(), 1, this.getRowTitleTotales(true, nivel1, 'nivel1', nivel1?.nivel1, 'wsr', numeroFilaResumido)[0]));
          numeroFilaResumido++;  
        }

      }

      XLSX.utils.sheet_add_json(wsr, [{A: ""}], {header: [], skipHeader: true, origin: -1}); // espacio para separa cada tabla
      numeroFilaResumido++;
    }
    
    for(const celda of estilosCeldasResumido) {
      wsr[celda.cell].s = celda.style;
    }

    wsr['!cols'] = [
      { wpx: 300 }, 
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
    ];

    if(this.formatosExcel && this.formatosExcel.wsr.length > 0) {
      for(const formato of this.formatosExcel.wsr) {
        if(wsr[formato.ref]){
          wsr[formato.ref].z = formato.numFmt;
        }
      }
    }

    XLSX.utils.book_append_sheet(wb, wsr, "Balance Manual Resumido");


    // Fin Hoja Resumido

    //                            Inicio Hoja Extendido - wse

    // Cabecera detalle
    const estilosCeldasExtendido: any[] = [];
    let numeroFilaResumidoExtendido: number = 10; // fila inicial del bucle, sin contar la cabecera con periodo, tipo, etc

    const arrayCabeceraDetalleE: any[] = [
      {A: `Rut`},
      {A: `Razón Social`},
      {A: `Año`},
      {A: `Tipo de balance (1)`},
      {A: `Tipo de balance (2)`},
      {A: `Auditado`},
      {A: `Moneda`},
      {A: `Desde`},
      {A: `Hasta`},
    ];

    arrayCabeceraDetalleE[0][`${this.getColName((1))}`] = this.rut || '';
    arrayCabeceraDetalleE[1][`${this.getColName((1))}`] = this.razonSocial || '';
    for (const [i, value] of this.valores.entries()) {
      arrayCabeceraDetalleE[2][`${this.getColName((i + 1))}`] = value?.periodo ? value?.periodo.toString() : '';
      arrayCabeceraDetalleE[3][`${this.getColName((i + 1))}`] = this.obtenerTipoString(value?.tipo) || '';
      arrayCabeceraDetalleE[4][`${this.getColName((i + 1))}`] = value?.subTipo || '';
      arrayCabeceraDetalleE[5][`${this.getColName((i + 1))}`] = value?.auditado === true ? 'Si' : (value?.auditado === false ? 'No' : '');
      arrayCabeceraDetalleE[6][`${this.getColName((i + 1))}`] = value?.moneda || '';
      arrayCabeceraDetalleE[7][`${this.getColName((i + 1))}`] = this.obtenerNombreMes(value?.mesInicio) || '';
      arrayCabeceraDetalleE[8][`${this.getColName((i + 1))}`] = this.obtenerNombreMes(value?.mesFin) || '';
    }

    estilosCeldasExtendido.push(this.marcadorEstilosExcel(this.getColName(0), '1', 2));
    estilosCeldasExtendido.push(this.marcadorEstilosExcel(this.getColName(0), '2', 2));
    estilosCeldasExtendido.push(this.marcadorEstilosExcel(this.getColName(0), '3', 2));
    estilosCeldasExtendido.push(this.marcadorEstilosExcel(this.getColName(0), '4', 2));
    estilosCeldasExtendido.push(this.marcadorEstilosExcel(this.getColName(0), '5', 2));
    estilosCeldasExtendido.push(this.marcadorEstilosExcel(this.getColName(0), '6', 2));
    estilosCeldasExtendido.push(this.marcadorEstilosExcel(this.getColName(0), '7', 2));
    estilosCeldasExtendido.push(this.marcadorEstilosExcel(this.getColName(0), '8', 2));
    estilosCeldasExtendido.push(this.marcadorEstilosExcel(this.getColName(0), '9', 2));

    // se crea el worksheet
    const wse = XLSX.utils.json_to_sheet(arrayCabeceraDetalleE, {header: [], skipHeader: true});

    XLSX.utils.sheet_add_json(wse, [], {header: [], skipHeader: true, origin: -1});
    XLSX.utils.sheet_add_json(wse, [{A: ""}], {header: [], skipHeader: true, origin: -1});
    numeroFilaResumidoExtendido++;

    // parte extendida - no se le aplican calculos web
    if(data.arrayContratoInput && data.arrayContratoInput.length > 0) {
      for(const [inivel1, nivel1] of data.arrayContratoInput.entries()) {
        if(!nivel1?.nivel1) {
          if(nivel1?.variables && nivel1?.variables.length > 0) {
            for(const campo of nivel1?.variables) {
              if(campo && campo.length > 0) {
                XLSX.utils.sheet_add_json(wse, this.getRowValueSinCalculo(campo, 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
                numeroFilaResumidoExtendido++;
              }
            }
          }
        } else {
          // cabeceras titulos -----------------------------------------------------

          XLSX.utils.sheet_add_json(wse, this.getRowTitleExtendido(true, nivel1), {header: [], skipHeader: true, origin: -1});
          estilosCeldasExtendido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumidoExtendido.toString(), 1, this.getRowTitleExtendido(true, nivel1)[0]));
          numeroFilaResumidoExtendido++;

          if(nivel1?.nivel2 && nivel1?.nivel2.length > 0) {
            
            for(const [inivel2, nivel2] of nivel1?.nivel2.entries()) {

              if(!nivel2?.nivel2) {
                for(const campo of nivel2?.variables) {
                  if(this.validaCampoExtendidoExcel(campo, nivel2?.variables.length, data.contratoInput)) {
                    XLSX.utils.sheet_add_json(wse, this.getRowValueSinCalculo(campo, 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
                    numeroFilaResumidoExtendido++;
                  }
                }
              } else {
                if(nivel2?.nivel3 && nivel2?.nivel3.length > 0) {

                  for(const [inivel3, nivel3] of nivel2?.nivel3.entries()) {

                    if(!nivel3?.nivel3) {
                      for(const campo of nivel3?.variables) {
                        if(this.validaCampoExtendidoExcel(campo, nivel3?.variables.length, data.contratoInput)) {
                          XLSX.utils.sheet_add_json(wse, this.getRowValueSinCalculo(campo, 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
                          numeroFilaResumidoExtendido++;
                        }
                      }
                    } else {
                      for(const campo of nivel3?.variables) {
                        if(this.validaCampoExtendidoExcel(campo, nivel3?.variables.length, data.contratoInput)) {
                          XLSX.utils.sheet_add_json(wse, this.getRowValueSinCalculo(campo, 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
                          numeroFilaResumidoExtendido++;
                        }
                      }

                      XLSX.utils.sheet_add_json(wse, this.getRowTitleTotalesExtendido(false, nivel3, 'nivel3', data.contratoInput, [(nivel1?.nivel1 || ''), (nivel2?.nivel2 || ''), (nivel3?.nivel3 || '')], 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
                      estilosCeldasExtendido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumidoExtendido.toString(), 3, this.getRowTitleTotalesExtendido(false, nivel3, 'nivel3', data.contratoInput, [(nivel1?.nivel1 || ''), (nivel2?.nivel2 || ''), (nivel3?.nivel3 || '')], 'wse', numeroFilaResumidoExtendido)[0]));
                      numeroFilaResumidoExtendido++;
                    }

                  }

                } else {
                  for(const campo of nivel2?.variables) {
                    if(this.validaCampoExtendidoExcel(campo, nivel2?.variables.length, data.contratoInput)) {
                      XLSX.utils.sheet_add_json(wse, this.getRowValueSinCalculo(campo, 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
                      numeroFilaResumidoExtendido++;
                    }
                  }
                }

                XLSX.utils.sheet_add_json(wse, this.getRowTitleTotalesExtendido(false, nivel2, 'nivel2', data.contratoInput, [(nivel1?.nivel1 || ''), (nivel2?.nivel2 || '')], 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
                estilosCeldasExtendido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumidoExtendido.toString(), 2, this.getRowTitleTotalesExtendido(false, nivel2, 'nivel2', data.contratoInput, [(nivel1?.nivel1 || ''), (nivel2?.nivel2 || '')], 'wse', numeroFilaResumidoExtendido)[0]));
                numeroFilaResumidoExtendido++;
              }

            }

          } else {
            if(nivel1?.variables && nivel1?.variables.length > 0) {
              for(const campo of nivel1?.variables) {
                if(this.validaCampoExtendidoExcel(campo, nivel1?.variables.length, data.contratoInput)) {
                  XLSX.utils.sheet_add_json(wse, this.getRowValueSinCalculo(campo, 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
                  numeroFilaResumidoExtendido++;
                }
              }
            }
          }

          if(this.validaFilaTotalExtendido(nivel1?.nivel1, data.contratoInput)) {
            // cabeceras valores totales -----------------------------------------------------
  
            XLSX.utils.sheet_add_json(wse, this.getRowTitleTotalesExtendido(true, nivel1, 'nivel1', data.contratoInput, [(nivel1?.nivel1 || '')], 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
            estilosCeldasExtendido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumidoExtendido.toString(), 1, this.getRowTitleTotalesExtendido(true, nivel1, 'nivel1', data.contratoInput, [(nivel1?.nivel1 || '')], 'wse', numeroFilaResumidoExtendido)[0]));
            numeroFilaResumidoExtendido++;
          }

        }

        XLSX.utils.sheet_add_json(wse, [{A: ""}], {header: [], skipHeader: true, origin: -1}); // espacio para separa cada tabla
        numeroFilaResumidoExtendido++;
      }
    }

    // parte normal

    for(const [i, nivel1] of data.arrayContratoExtendida.entries()) {
      if(!nivel1?.nivel1) {
        if(nivel1?.variables && nivel1?.variables.length > 0) {
          for(const campo of nivel1?.variables) {
            if(campo && campo.length > 0) {
              XLSX.utils.sheet_add_json(wse, this.getRowValue(campo, 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
              numeroFilaResumidoExtendido++;
            }

          }
        }
      } else {
        // cabeceras titulos -----------------------------------------------------

        XLSX.utils.sheet_add_json(wse, this.getRowTitle(true, nivel1, 'nivel1'), {header: [], skipHeader: true, origin: -1});
        estilosCeldasExtendido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumidoExtendido.toString(), 1, this.getRowTitle(true, nivel1, 'nivel1')[0]));
        numeroFilaResumidoExtendido++;

        if(nivel1?.nivel2 && nivel1?.nivel2.length > 0) {
          
          for(const nivel2 of nivel1?.nivel2) {
            if(!nivel2?.nivel2){
              if(nivel2?.variables && nivel2?.variables.length > 0) {
                for(const campo of nivel2?.variables) {
                  if(this.validaCampoExcel(campo, data.contratoInput)) {
                    XLSX.utils.sheet_add_json(wse, this.getRowValue(campo, 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
                    numeroFilaResumidoExtendido++;
                  }
                }
              }
            } else {
              if(nivel1?.nivel1 === 'liquideseindicadores') {
                XLSX.utils.sheet_add_json(wse, this.getRowTitleTotales(false, nivel2, 'nivel2', nivel1?.nivel1, 'wse', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
                estilosCeldasExtendido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumidoExtendido.toString(), 2, this.getRowTitleTotales(false, nivel2, 'nivel2', nivel1?.nivel1, 'wse', numeroFilaResumido)[0]));
                numeroFilaResumidoExtendido++;
              }

              if(nivel2?.nivel3 && nivel2?.nivel3.length > 0) {
                for(const nivel3 of nivel2?.nivel3) {
                  if(!nivel3?.nivel3) {
                    for(const campo of nivel3?.variables) {
                      if(this.validaCampoExcel(campo, data.contratoInput)) {
                        XLSX.utils.sheet_add_json(wse, this.getRowValue(campo, 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
                        numeroFilaResumidoExtendido++;
                      }
                    }
                  } else {
                    if(nivel1?.nivel1 === 'liquideseindicadores') {
                      XLSX.utils.sheet_add_json(wse, this.getRowTitleTotales(false, nivel3, 'nivel3', nivel1?.nivel1, 'wse', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
                      estilosCeldasExtendido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumidoExtendido.toString(), 3, this.getRowTitleTotales(false, nivel3, 'nivel3', nivel1?.nivel1, 'wse', numeroFilaResumido)[0]));
                      numeroFilaResumidoExtendido++;
                    }

                    for(const campo of nivel3?.variables) {
                      if(this.validaCampoExcel(campo, data.contratoInput)) {
                        XLSX.utils.sheet_add_json(wse, this.getRowValue(campo, 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
                        numeroFilaResumidoExtendido++;
                      }
                    }

                    if(nivel1?.nivel1 !== 'liquideseindicadores') {
                      XLSX.utils.sheet_add_json(wse, this.getRowTitleTotales(false, nivel3, 'nivel3', nivel1?.nivel1, 'wse', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
                      estilosCeldasExtendido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumidoExtendido.toString(), 3, this.getRowTitleTotales(false, nivel3, 'nivel3', nivel1?.nivel1, 'wse', numeroFilaResumido)[0]));
                      numeroFilaResumidoExtendido++;
                    }
                  }
                }
              } else {
                for(const campo of nivel2?.variables) {
                  if(this.validaCampoExcel(campo, data.contratoInput)) {
                    XLSX.utils.sheet_add_json(wse, this.getRowValue(campo, 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
                    numeroFilaResumidoExtendido++;
                  }
                }
              }

              if(nivel1?.nivel1 !== 'liquideseindicadores') {
                XLSX.utils.sheet_add_json(wse, this.getRowTitleTotales(false, nivel2, 'nivel2', nivel1?.nivel1, 'wse', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
                estilosCeldasExtendido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumidoExtendido.toString(), 2, this.getRowTitleTotales(false, nivel2, 'nivel2', nivel1?.nivel1, 'wse', numeroFilaResumido)[0]));
                numeroFilaResumidoExtendido++;
              }
            }
          }

        } else {
          if(nivel1?.variables && nivel1?.variables.length > 0) {
            for(const campo of nivel1?.variables) {
              if(this.validaCampoExcel(campo, data.contratoInput)) {
                XLSX.utils.sheet_add_json(wse, this.getRowValue(campo, 'wse', numeroFilaResumidoExtendido), {header: [], skipHeader: true, origin: -1});
                numeroFilaResumidoExtendido++;
              }
            }
          }
        }

        if(this.validaFilaTotal(nivel1?.nivel1)) {
          // cabeceras valores totales -----------------------------------------------------

          XLSX.utils.sheet_add_json(wse, this.getRowTitleTotales(true, nivel1, 'nivel1', nivel1?.nivel1, 'wse', numeroFilaResumido), {header: [], skipHeader: true, origin: -1});
          estilosCeldasExtendido.push(...this.marcadorEstilosExcelObjetos(numeroFilaResumidoExtendido.toString(), 1, this.getRowTitleTotales(true, nivel1, 'nivel1', nivel1?.nivel1, 'wse', numeroFilaResumido)[0]));
          numeroFilaResumidoExtendido++;
        }

      }

      XLSX.utils.sheet_add_json(wse, [{A: ""}], {header: [], skipHeader: true, origin: -1}); // espacio para separa cada tabla
      numeroFilaResumidoExtendido++;
    }

    for(const celda of estilosCeldasExtendido) {
      wse[celda.cell].s = celda.style;
    }

    wse['!cols'] = [
      { wpx: 300 }, 
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
      { wpx: 300 },
    ];

    if(this.formatosExcel && this.formatosExcel.wse.length > 0) {
      for(const formato of this.formatosExcel.wse) {
        if(wse[formato.ref]){
          wse[formato.ref].z = formato.numFmt;
        }
      }
    }

    // Fin Hoja Extendido

    XLSX.utils.book_append_sheet(wb, wse, "Balance Manual Extendido");

    const today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;  

    XLSXStyle.writeFile(wb, "Balance_Manual_" + this.rut + "_" + today.toString() + ".xlsx");
  }

}
