import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import {
  IGetUsersForGroupResponse,
  UsuariosService,
} from 'src/app/shared/services/usuarios.service';


interface UserEditFormValues {
  username: string;
  email: string;
  firstName: string;
  givenName: string;
  phoneNumber: string;
}

interface Access {
  accessID: string;
  name: string;
  active: boolean;
  type: string;
}

interface Group {
  group: string;
  display: string;
  accesos: Access[];
}

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.scss'],
})
export class EditarUsuarioComponent implements OnInit {
  private access: string[] = [ESystemAccess.CLIENTES_USER_ADMIN, ESystemProfileList.WEB];
  public hasUserAccess = false;

  public accessGroups: Group[] = [];
  public userToEdit: IGetUsersForGroupResponse | null = null;
  private userSub = '';
  public isLoaded = false;

  public userCreateForm: UntypedFormGroup;

  @ViewChildren("checkboxestodos") checkboxestodos: QueryList<ElementRef> | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public alertService: AlertService,
    private _sessionService: SessionService,
    private _usuariosService: UsuariosService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.userCreateForm = this.formBuilder.group({
      username: [
        { value: '', disabled: true },
        [Validators.required, Validators.email],
      ],
      firstName: [{ value: '', disabled: true }, Validators.required],
      givenName: [{ value: '', disabled: true }, Validators.required],
      phoneNumber: [{ value: '', disabled: true }, Validators.required]
    }) as FormGroupTyped<UserEditFormValues>;
  }

  ngOnInit(): void {
    
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }

    this.userSub = this.route.snapshot.paramMap.get('id') || '';
    if (this.userSub === '') {
      this.alertService.error('No userSub');
    }

    this.loadBasicResources();
  }

  private loadBasicResources() {
    this._usuariosService.getSystemAccessList().subscribe(
      (data) => {
        this.accessGroups = this.getAccessGroups(data);
        this.loadUserInfo();
      },
      ({ error }) => {
        this.alertService.error(error.message);
      }
    );
  }

  private getAccessGroups(data: any[]): any[] {
    let result: any = [];
    if(data && data?.length > 0){
      result = Object.values(data.reduce((acc, current) => {
        if (!acc[current.group]) {
          acc[current.group] = {
            group: current.group,
            display: current.display,
            accesos: []
          };
        }
        acc[current.group].accesos.push({
          accessID: current.accessID,
          name: current.name,
          active: current.active,
          type: current.type
        });

        return acc;
      }, {}));

      result.sort((a: any, b: any) => a.display.localeCompare(b.display));
    }
    return result;
  }

  private loadUserInfo() {
    this._usuariosService.getUsersForGroup().subscribe(
      (data) => {
        this.userToEdit = data.filter((user) => user.email === this.userSub)[0];

        this.setUserData();
        this.activateAccess();

        this.isLoaded = true;
      },
      ({ error }) => {
        this.alertService.error(error.message);
      }
    );
  }

  private activateAccess() {
    if(this.accessGroups?.length > 0){
      this.accessGroups?.forEach((group) => {
        if(group?.accesos?.length > 0){
          group?.accesos?.forEach((access) => {
            access.active = (this.userToEdit?.availableAccess.includes(
              access.name
            )) || false;
          });
        }
      });
    }
  }

  public editUser() {

    const userCreateFormvalues: UserEditFormValues = this.userCreateForm.value;

    const selectedAccessNames: string[] = this.accessGroups.reduce(
        (acc: any, { accesos }) => {
          const activeAccesos = accesos.filter(({ active }) => active);
          return [...acc, ...activeAccesos.map(({ name }) => name)];
        },
        []
      );

    const userAccess = {
      userSub: this.userSub,
      username: this.userToEdit!.userName,
      availableAccess: selectedAccessNames,
      phoneNumber: this.userToEdit!.phoneNumber,
      profilesList: this.userToEdit!.profilesList,
    };

    
    if (this.userToEdit?.availableAccess.find(element => element === this.access[0])) {
      userAccess.availableAccess.push(this.access[0]);
    }
    

    this._usuariosService.updateUserAccess(userAccess).subscribe(
      async (data) => {

        await this._usuariosService
          .getAccessForUser(this._sessionService.getUserInfo().email)
          .toPromise()
          .then((data) => {
            this._sessionService.setUserAccess(data.availableAccess);
          })
          .catch(({ error }) => {
            this.alertService.error(error.message);
          });
        this.goBack();
      },
      ({ error }) => {
        this.alertService.error(error.message);
      }
    );
  }

  public async clearForm() {
    await this.setUserData();
    this.activateAccess();

    this.checkboxestodos?.forEach(element => {
      if(element?.nativeElement?.checked === true){
        element.nativeElement.checked = false;
      }
    });
    
  }

  public goBack() {
    this.router.navigate(['/', 'usuarios', 'listar']);
  }

  private setUserData() {
    if (this.userToEdit) {
      this.userCreateForm.patchValue({
        username: this.userToEdit.userName,
        firstName: this.userToEdit.name,
        givenName: this.userToEdit.givenName,
        phoneNumber: this.userToEdit.phoneNumber
      });
    }
  }

  selectAll(event: any, type: string): void {
    const valor: any = event?.target?.checked;
    if(event?.target?.checked === true || event?.target?.checked === false) {
      if(this.accessGroups && this.accessGroups.length > 0) {
        for(const group of this.accessGroups) {
          if(group && group?.accesos && group?.accesos.length > 0) {
            for(const acceso of group?.accesos) {
              if(acceso?.type && acceso?.type === type){
                acceso.active = valor;
              }
            }
          }
        }
      }
    }
  }
}
