<div class="container-gtp mb-5">
    <img class="banner-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt=""/>
    <h2 class="text-center pt-3">VENTAS</h2>
    <h5 class="text-center">
        <span class="badge bg-secondary text-center">
            <span *ngIf="rut">
                {{rut | rutFormat | uppercase}}
            </span>
        </span>
    </h5>
    <div class="break-box">
        <div class="row" *ngFor="let elemento of reporte; let i = index" [attr.data-index]="i" [attr.saltoLinea]="i%2===0 && i!==0 ? true : null">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" (click)="clickHeader(elemento.periodo)" [attr.valueiconl]="'icon-l-f29-' + elemento.periodo" [attr.valueicond]="'icon-d-f29-' + elemento.periodo" [attr.valuetarget]="'collapsable-f29-' + elemento.periodo" >
                        Ventas {{ elemento.periodo }}
                        <i class="fa fa-angle-left pull-right mr-2" id="icon-l-f29-{{ elemento.periodo }}" hidden></i>
                        <i class="fa fa-angle-down pull-right mr-2" id="icon-d-f29-{{ elemento.periodo }}"></i>
                    </h5>
                    <div class="card-body" id="collapsable-f29-{{ elemento.periodo }}">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-bordered" id="table-{{elemento.periodo}}">
                                    <thead>
                                        <tr>
                                            <th class="border-0"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Mes</th>
                                            <th>Ventas Netas M$</th>
                                            <th>Ventas Netas UF</th>
                                            <th>Compras Netas M$</th>
                                            <th>Compras Netas UF</th>
                                            <th>Margen M$</th>
                                            <th>% NC / Ventas</th>
                                            <th>Fecha Pago</th>
                                            <th>Tipo Declaración</th>
                                            <th>Postergado</th>
                                            <th>Variación % Ventas</th>
                                          </tr>
                                          <tr *ngFor="let item of elemento.datosReporte"><th>{{item.nombreMes}}</th>
                                            <td><span>{{ item.ventasNetasMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.ventasNetasUF | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.comprasNetasMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.comprasNetasUf | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.margenMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.porc_notascred_ventas | number:'1.0-2'}}</span></td>
                                            <td class="text-nowrap"><span>{{ item.fechaPago}}</span></td>
                                            <td><span>{{ item.tipoDeclaracion}}</span></td>
                                            <td><span>{{ item.postergado | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.variacion | number:'1.0-2'}}</span></td>
                                        </tr>
                                          <tr><th>Totales</th>
                                            <td><span>{{ elemento.totalVentasNetasMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalVentasNetasUF | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalComprasNetasMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalComprasNetasUF | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalMargenMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalPorcVentasMiles | number:'1.0-2'}}</span></td>
                                            <td></td>
                                            <td></td>
                                            <td><span>{{ elemento.totalPostergado | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalVarVentas | number:'1.0-2'}}</span></td>
                                        </tr>
                                          <tr><th>Promedio</th>
                                            <td><span>{{ (elemento.totalVentasNetasMiles/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalVentasNetasUF/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalComprasNetasMiles/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalComprasNetasUF/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalMargenMiles/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalPorcVentasMiles/elemento.mesesEnPeriodo) | number:'1.0-2'}}</span></td>
                                            <td></td>
                                            <td></td>
                                            <td><span>{{ (elemento.totalPostergado/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalVarVentas/elemento.mesesEnPeriodo) | number:'1.0-2'}}</span></td>
                                        </tr>
                                          <tr><th>Proyección</th>
                                            <td><span>{{ ((elemento.totalVentasNetasMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalVentasNetasUF/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalComprasNetasMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalComprasNetasUF/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalMargenMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalPorcVentasMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-2'}}</span></td>
                                            <td></td>
                                            <td></td>
                                            <td><span>{{ ((elemento.totalPostergado/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalVarVentas/elemento.mesesEnPeriodo)*12) | number:'1.0-2'}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>