<ng-container *ngIf="validaEstado()">
    <section class="overflow-auto d-flex justify-content-end align-items-center" [class.open]="validaEstado() ? 'display' : 'close'"
             (click)="close()">
      <div (click)="$event.stopPropagation()" class="container p-5 my-auto mx-auto">
        <div class="row">

          <button class="close" type="button" (click)="close()"><i class="fa fa-times"></i></button>

          <div class="col-12" *ngIf="descargar === true">
            <div class="row">
              <div class="col-sm-12 mt-3">
                <button class="d-none"[printTitle]='"Consolidado_"+(rut | rutFormat)+"_Flujo_"+idTransaccion'
                  [useExistingCss]="true" printSectionId="print-section-consolidado" ngxPrint id="printButtonModal">Descargar</button>
                <button class="btn btn-primary float-right mr-5" (click)="descargarPDF()">Descargar</button>
              </div>
            </div>
          </div>

          <div class="col-12" id="print-section-consolidado">
            <div class="row">
              <div class="col-sm-12">
                <h2 class="ml-5 mb-5">Reporte Basico Consolidado</h2>
              </div>
            </div>
            <div class="row tamano-container overflow-auto">
                <div class="col-12">
                  
                  <accordion [isAnimated]="true">
                    
                    <ng-template #nonDefault>
                      <ng-container *ngIf="plantilla === 'cesceDemo'">
                        <accordion-group heading="Antecedentes Cliente" [isOpen]="true" panelClass="mb-4">
                          <div class="col-sm-12">
                            <app-ant-consolidado [rut]="rut" [reporteFull]="dataServicios?.consolidadoTitular">
                            </app-ant-consolidado>
                          </div>
                        </accordion-group>
                      </ng-container>
                    </ng-template>
                    
                    <ng-container *ngIf="default === true else nonDefault">
  
                      <accordion-group heading="Antecedentes Cliente" [isOpen]="true" panelClass="mb-4">
                        <div class="col-sm-12">
                          <app-antecedentes-consolidado [rut]="rut"
                              [objReporteFinanciero]="dataServicios?.cteVentasBalancesRentas?.reporte" [objReporteBasico]="dataServicios?.basicReport?.reporte"
                              [informeComercial]="informeComercial" [objReporteDeudaSbif]="dataServicios?.deudaSbif?.reporte"
                              [objReporteJudicial]="dataServicios?.poderJudicial?.reporteMapeado" [objReporteBoletinConcursal]="dataServicios?.boletinConcursal?.reporte"
                              [objReporteEmpresaDia]="dataServicios?.reporteEmpresaDia?.reporte" [updateReportes]="false" #antecedentesConsolidadoComponent>
                          </app-antecedentes-consolidado>
                        </div>
                      </accordion-group>
  
                    </ng-container>

                    <!-- poder judicial -->
                    <ng-container *ngIf="dataServicios?.poderJudicial?.ReporteFull">
                      <accordion-group heading="Poder judicial" [isOpen]="false" panelClass="mb-4">
                          <div class="col-sm-12">
                              <app-poder-judicial-report-viewer [content]="dataServicios?.poderJudicial?.ReporteFull" [showHeader]="false" [fromConsolidado]="true">
                              </app-poder-judicial-report-viewer>
                            </div>
                        </accordion-group>
                    </ng-container>
                
                    <!-- boletin concursal -->
                    <ng-container
                        *ngIf="dataServicios?.boletinConcursal?.reporte && objectKeys(dataServicios?.boletinConcursal?.reporte.boletinConcursalReporte.principal).length > 0">
                        <accordion-group heading="Boletin Concursal" [isOpen]="false" panelClass="mb-4">
                            <div class="col-sm-12">
                                <app-boletin-concursal-report-viewer [content]='dataServicios?.boletinConcursal?.reporte' [showHeader]="false">
                                </app-boletin-concursal-report-viewer>
                            </div>
                        </accordion-group>
                    </ng-container>
                
                    <!-- deuda sbif -->
                    <ng-container *ngIf="dataServicios?.deudaSbif?.reporte">
                        <accordion-group heading="Deuda Sbif" [isOpen]="false" panelClass="mb-4">
                            <div class="col-sm-12">
                                <app-deuda-leasing-sbif-viewer [content]='dataServicios?.deudaSbif?.reporte' [typeTX]="false" [showHeader]="false">
                                </app-deuda-leasing-sbif-viewer>
                            </div>
                        </accordion-group>
                    </ng-container>

                    <!-- reporte financiero -->
                    <ng-container *ngIf="dataServicios?.cteVentasBalancesRentas?.reporte">
                      <accordion-group heading="Reporte Financiero" [isOpen]="false" panelClass="mb-4">
                          <div class="col-sm-12">
                              <app-reporte-full-viewer [reporteFullContent]="dataServicios?.cteVentasBalancesRentas?.reporte" [showHeader]="false"
                                [nombreBasico]="dataServicios?.basicReport?.reporte?.InformacionBasica?.NombreORazonSocial || dataServicios?.consolidadoTitular?.objReporteBasico?.InformacionBasica?.NombreORazonSocial || ''">
                              </app-reporte-full-viewer>
                          </div>
                      </accordion-group>
                    </ng-container>
                
                    <!-- bienes raices -->
                    <ng-container *ngIf="dataServicios?.objReporteBienesRaices">
                        <accordion-group heading="Bienes Raices" [isOpen]="false" panelClass="mb-4">
                            <div class="col-sm-12">
                                <ng-template #bienesSinInfo>
                                    <div class="p-3"> Sin información</div>  
                                </ng-template>
                
                                <ng-container *ngIf="dataServicios?.objReporteBienesRaices?.bienesraicesReporte?.bienesRaices?.listadoBienesRaices &&  dataServicios?.objReporteBienesRaices.bienesraicesReporte.bienesRaices.listadoBienesRaices.length > 0; else bienesSinInfo">
                                <app-bienes-raices-viewer [content]='dataServicios?.objReporteBienesRaices' [showHeader]="false" [fromConsolidado]="true">
                                </app-bienes-raices-viewer>
                                </ng-container>
                                
                            </div>
                        </accordion-group>
                    </ng-container>
                
                    <!-- vehiculos -->
                    <ng-container *ngIf="dataServicios?.objReporteVehiculos">
                        <accordion-group heading="Vehiculos" [isOpen]="false" panelClass="mb-4">
                            <div class="col-sm-12">
                                <ng-template #vehiculosSinInfo>
                                <div class="p-3"> Sin información</div>  
                                </ng-template>
                                
                                <ng-container *ngIf="dataServicios?.objReporteVehiculos?.vehiculoReporte?.vehiculos?.listadoVehiculos && dataServicios?.objReporteVehiculos.vehiculoReporte.vehiculos.listadoVehiculos.length > 0; else vehiculosSinInfo">
                                    <app-vehiculos-viewer [content]='dataServicios?.objReporteVehiculos' [showHeader]="false" [fromConsolidado]="true"></app-vehiculos-viewer>
                                </ng-container>
                                
                            </div>
                        </accordion-group>
                    </ng-container>
                    
                  </accordion>

                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</ng-container>