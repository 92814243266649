<div class="container-gtp">
    <div class="no-print">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="btn-option">
                    <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                    <div>
                        <button *ngIf="showReport" [printTitle]='"ReporteFinancieroConsolidado_"+(rut | rutFormat)+"_"+today'
                             [useExistingCss]="true" printSectionId="print-section" ngxPrint class="mr-2 btn btn-primary">
                             Descargar PDF</button>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card w-100" *ngIf="!showReport">
        <div class="card-body">
            <div class="row">
                <div [className]="tipoPersona==='Personal Natural' ? 'col-sm-12 col-md-4 border-right my-2' : 'col-sm-12 col-md-6 border-right my-2'">
                    <div class="card card-modulo w-100 mx-auto rounded-0 modulo-financiero">
                        <div class="card-header bg-transparent py-1">
                            <div class="row d-flex align-items-center">
                                <h5 class="mr-auto p-2 ml-4 mb-0">Balance</h5>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="mx-5" *ngIf="periodosDisponiblesF22.length > 0">
                                <div class="container lista-tamano overflow-auto">
                                    <div *ngFor="let item of periodosDisponiblesF22" class="form-check mx-2">
                                        <input #checkboxesf22 class="form-check-input" type="checkbox" id="f22-{{item}}" (change)="onCheckChangeF22($event)" value="{{item}}">
                                        <label class="form-check-label mx-5" for="f22-{{item}}">
                                            <span class="text-nowrap">{{item}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="periodosDisponiblesF22.length == 0 && mensajeF22 != ''">
                                <div class="container d-flex justify-content-center align-items-center">
                                    <p>{{mensajeF22}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div [className]="tipoPersona==='Personal Natural' ? 'col-sm-12 col-md-4 border-right my-2' : 'col-sm-12 col-md-6 my-2'">
                    <div class="card card-modulo w-100 mx-auto rounded-0 modulo-financiero">
                        <div class="card-header bg-transparent py-1">
                            <div class="row d-flex align-items-center">
                                <h5 class="mr-auto p-2 ml-4 mb-0">Ventas</h5>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="mx-5" *ngIf="periodosDisponiblesF29.length > 0">
                                <div class="container lista-tamano overflow-auto">
                                    <div *ngFor="let item of periodosDisponiblesF29" class="form-check mx-2">
                                        <input #checkboxesf29 class="form-check-input" type="checkbox" id="f29-{{item}}" (change)="onCheckChangeF29($event)" value="{{item}}">
                                        <label class="form-check-label mx-5" for="f29-{{item}}">
                                            <span class="text-nowrap">{{item}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="periodosDisponiblesF29.length == 0 && mensajeF29 != ''">
                                <div class="container d-flex justify-content-center align-items-center">
                                    <p>{{mensajeF29}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-sm-12 col-md-4 my-2" *ngIf="tipoPersona==='Personal Natural'">
                    <div class="card card-modulo w-100 mx-auto rounded-0 modulo-financiero">
                        <div class="card-header bg-transparent py-1">
                            <div class="row d-flex align-items-center">
                                <h5 class="mr-auto p-2 ml-4 mb-0">Renta</h5>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="mx-5" *ngIf="periodosDisponiblesRenta.length > 0">
                                <div class="container lista-tamano overflow-auto">
                                    <div *ngFor="let item of periodosDisponiblesRenta" class="form-check mx-2">
                                        <input #checkboxesrenta class="form-check-input" type="checkbox" id="renta-{{item}}" (change)="onCheckChangeRenta($event)" value="{{item}}">
                                        <label class="form-check-label mx-5" for="renta-{{item}}">
                                            <span class="text-nowrap">{{item}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="periodosDisponiblesRenta.length == 0 && mensajeRenta != ''">
                                <div class="container d-flex justify-content-center align-items-center">
                                    <p>{{mensajeRenta}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row d-flex justify-content-center my-2">
                <div class="form-group form-actions container__actions container-options-buscar mt-3">
                    <button class="btn btn-sm btn-primary mx-2" (click)="displayReport()"><i class="fa fa-angle-right"></i>
                        Ver Reporte</button>
                    <button type="button" (click)="limpiarAllCheckBoxes()" class="btn btn-sm btn-warning mx-2"><i class="fa fa-repeat"></i>
                        Limpiar</button>
                </div>
            </div>


        </div>
    </div>

    <div id="print-section" *ngIf="showReport">
        <app-reporte-financiero-consolidado-view #appreportefcview [tipoPersona]="tipoPersona" [reporteBasicoContent]="reporteBasico" [reporteFullContentF22]="reporteFullContentF22" [reporteFullContentF29]="reporteFullContentF29" [reporteFullContentRenta]="reporteFullContentRenta" [rut]="rut"></app-reporte-financiero-consolidado-view>
    </div>
</div>

