import { Component, OnInit } from '@angular/core';
import { OfertasApiService } from '../services/ofertas-api.service';
import { OfertaFinanciera, OfferType } from '../models/OfertaFinanciera';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
// import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AlertService } from 'src/app/components/_alert';
import { OfertasHelperService } from '../services/ofertas-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from 'src/app/shared/services/session.service';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';

@Component({
  selector: 'app-list-ofertas',
  templateUrl: './list-ofertas.component.html',
  styleUrls: ['./list-ofertas.component.scss']
})
export class ListOfertasComponent implements OnInit {

  public hasUserAccess = false;
  private access: string[] = [ESystemAccess.OFERTAS_FINANCIERAS, ESystemProfileList.WEB];

  public offers: OfertaFinanciera[] = []
  public offersFiltered: OfertaFinanciera[] = [];
  pageSize = 20;
  pageIndex = 0;

  public verticalesContract: OfferType[] = [];

  offerFilterForm = new FormGroup({
    searchInput: new FormControl(''),
    userInput: new FormControl(''),
    acceptedCombo: new FormControl(''),
    deletedCombo: new FormControl(''),
    expiredCombo: new FormControl(''),
    typeCombo: new FormControl('')
  });

  constructor(
    private _apiService: OfertasApiService,
    private _spinner: NgxSpinnerService,
    private _router: Router,
    // private _simpleModalService: SimpleModalService,
    public dialog: MatDialog,
    public _alertService: AlertService,
    private _helperService: OfertasHelperService,
    private _sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.checkAccess();
  }

  getApiData(): void {
    this._spinner.show();
    this._apiService.getOfertasList().subscribe(
      (ofertas) => {
        console.log(ofertas);
        this.offers = this.mapOffers(ofertas.items);
        this.offers.sort((a:OfertaFinanciera, b: OfertaFinanciera) => {
          const dateA = a.lastModified ? parseInt(a.lastModified) : 0;
          const dateB = b.lastModified ? parseInt(b.lastModified) : 0;
          return dateB - dateA; // Most recent date first
        });
        this.offersFiltered = this.offers;
        this._spinner.hide();
      },
      (error) => {
        this._spinner.hide();
        this._alertService.error('Error al intentar obtener el listado de ofertas');
      }
    )

    this._apiService.obtenerContratoVerticales().subscribe(
      (contract) => {
        this.verticalesContract = contract;
      },
      (error) => {
        this._alertService.error('Error al intentar obtener el listado de verticales financieras');
      }
    )
  }

  checkAccess() {
    const userAccess = this._sessionService.getUserAccess();
    const getUserProfileList = this._sessionService.getUserProfileList();

    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
      this.getApiData();
    }
  }

  mapOffers(ofertas: any[]): OfertaFinanciera[] { 
    const ofertasArray: OfertaFinanciera[] = ofertas.map((oferta) => {
      
      const idVertical = oferta.vertical_id_id_organizacion.split("_")[0];
      const ofertaMapped: OfertaFinanciera = {
        id: oferta.id_oferta,
        amountStart: oferta.amountStart,
        amountEnd: oferta.amountEnd,
        clientExpTime: oferta.clientExpTime,
        idOrganizacionUsuario: oferta.id_organizacion_usuario,
        accepted: oferta.accepted,
        deleted: oferta.deleted,
        idVertical: idVertical,
        lastModified: oferta.last_modified
      }
      return ofertaMapped
    })
    
    return ofertasArray;
  }

  getFormattedDate(clientExpTime: string | undefined): string {
    return this._helperService.getFormattedDate(clientExpTime)
  }

  displayAmount(amount: any): string {
    return this._helperService.displayAmount(amount);
  }

  onPageChange(n: number) {
    this.pageIndex += n;
    console.log(this.pageIndex);
  }

  cleanFilters() {
    this.offerFilterForm.reset();
  }

  search() {
    this.offersFiltered = [];

    let filterResult: OfertaFinanciera[] = this.offers;
    
    //--Busqueda--
    const searchControl = this.offerFilterForm.get('searchInput');
    if(searchControl && searchControl.value && searchControl.value != '') {
      const searchValue = searchControl.value;
      filterResult = filterResult.filter((offer: OfertaFinanciera) => {
        if(offer.id) {
          return offer.id.toLowerCase().includes(searchValue.toLowerCase());
        } else {
          return false;
        }
      })
    }

    //--Usuario--
    const userControl = this.offerFilterForm.get('userInput');
    if(userControl && userControl.value && userControl.value != '') {
      const searchValue = userControl.value;
      filterResult = filterResult.filter((offer: OfertaFinanciera) => {
        if(offer.idOrganizacionUsuario) {
          return offer.idOrganizacionUsuario.toLowerCase().includes(searchValue.toLowerCase());
        } else {
          return false;
        }
      })
    }

    //--Tipo--
    const typeControl = this.offerFilterForm.get('typeCombo');
    if(typeControl && typeControl.value && typeControl.value != '') {
      const searchValue = typeControl.value;
      filterResult = filterResult.filter((offer: OfertaFinanciera) => {
        return offer.idVertical == searchValue;
      })
    }

    //--Expiracion--
    const expiredControl = this.offerFilterForm.get('expiredCombo');
    if(expiredControl && expiredControl.value && expiredControl.value != '') {
      const searchValue = expiredControl.value;
      filterResult = filterResult.filter((offer: OfertaFinanciera) => {
        const expired = this.checkExpiration(offer.clientExpTime);
        if(searchValue == "true") {
          return expired
        } else {
          return !expired
        }
      })
    }
    
    //--Aceptada--
    const acceptedControl = this.offerFilterForm.get('acceptedCombo');
    if(acceptedControl && acceptedControl.value && acceptedControl.value != '') {
      const searchValue = acceptedControl.value;
      filterResult = filterResult.filter((offer: OfertaFinanciera) => {
        if(searchValue == "true") {
          return offer.accepted
        } else {
          return !offer.accepted
        }
      })
    }

    //--Borrada--
    const deletedControl = this.offerFilterForm.get('deletedCombo');
    if(deletedControl && deletedControl.value && deletedControl.value != '') {
      const searchValue = deletedControl.value;
      filterResult = filterResult.filter((offer: OfertaFinanciera) => {
        if(searchValue == "true") {
          return offer.deleted
        } else {
          return !offer.deleted
        }
      })
    }

    this.offersFiltered = filterResult;
  }

  checkExpiration(expTime: string) {

    if(!expTime || expTime=="") return false;

    // Mostrar la expiracion en formato legible
    const unixTimestamp = parseInt(expTime, 10);
    const date = new Date(unixTimestamp * 1000);
    const formattedDate = date.toLocaleDateString();

    // Marcar si la oferta esta expirada
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0 to compare only the date portion
    const dateToCompare = new Date(unixTimestamp * 1000);
    dateToCompare.setHours(0, 0, 0, 0);

    if(dateToCompare < today) {
      return true;
    } else {
      return false;
    }
  }

  displayType(verticalId: string) {
    let res = "";
    const vertical = this.verticalesContract.filter((vertical) => {
      return vertical.id == verticalId
    })
    if(vertical[0]) {
      res = vertical[0].displayName
    }

    return res;
  }

  onEdit(idOferta: string) {
    this._router.navigate([`/ofertas-financieras/oferta/${idOferta}`]);
  }

  onView(idOferta: string) {
    this._router.navigate([`/ofertas-financieras/oferta/${idOferta}`]);
  }

  onCreate() {
    this._router.navigate([`/ofertas-financieras/oferta`])
  }

  onCreateMasiva() {
    this._router.navigate([`/ofertas-financieras/carga-masiva`])
  }

  onDeleteOffer(idOffer: string, idVertical: string) {
    // this._simpleModalService.addModal(ConfirmDialogComponent, {
    //   title: 'Eliminar oferta',
    //   message: `¿Esta seguro que quiere eliminar la oferta ${idOffer}?`,
    //   textBoton: ['Aceptar', 'Cancelar'],
    //   textTimer: 0,
    // }).subscribe((response) => {
    //   if(response) {
    //     this._spinner.show();
    //     this._apiService.deleteOffer(idOffer, idVertical).subscribe(
    //       (response: any) => {
    //         this._spinner.hide();
    //         location.reload();
    //       },
    //       (error) => {
    //         this._spinner.hide();
    //         this._alertService.error('Error al intentar eliminar la oferta');
    //       }
    //     )
    //   }
    // });

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Eliminar oferta',
        message: `¿Esta seguro que quiere eliminar la oferta ${idOffer}?`,
        textBoton: ['Aceptar', 'Cancelar'],
        textTimer: 0,
      }
    });

    dialogRef.afterClosed().subscribe((response) => {
      if(response) {
        this._spinner.show();
        this._apiService.deleteOffer(idOffer, idVertical).subscribe(
          (response: any) => {
            this._spinner.hide();
            location.reload();
          },
          (error) => {
            this._spinner.hide();
            this._alertService.error('Error al intentar eliminar la oferta');
          }
        )
      }
    });
  }
}
