<div class="col-12">

    <div class="row mt-3">

        <div class="col-sm-6 pl-sm-0">
            <div class="card">
                <div class="card-body">
                    <table class="table">
                        <tbody>
                            <tr>
                                <th>
                                    <div class="pt-3">Ventas promedio
                                        {{setNumber(obtenerValorRulesEngine('Penultimoano')).toFixed(0)}}
                                    </div>
                                </th>
                                <td>
                                    <div type="text" class="form-control" disabled>
                                        {{setNumber(obtenerValorRulesEngine('VentaPromedioPenultimoano')) |
                                        number:'1.0-0'}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div class="pt-3">Ventas promedio 
                                        {{setNumber(obtenerValorRulesEngine('Ultimoano')).toFixed(0)}}</div>
                                </th>
                                <td>
                                    <div type="text" class="form-control" disabled>
                                        {{setNumber(obtenerValorRulesEngine('VentaPromedioUltimoano')) |
                                        number:'1.0-0'}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div class="pt-3">Margen promedio
                                        {{setNumber(obtenerValorRulesEngine('Penultimoano')).toFixed(0)}}</div>
                                </th>
                                <td>
                                    <div type="text" class="form-control" disabled>
                                        {{setNumber(obtenerValorRulesEngine('MargenPromedioPenultimoAno')) |
                                        number:'1.0-0'}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div class="pt-3">Margen promedio
                                        {{setNumber(obtenerValorRulesEngine('Ultimoano')).toFixed(0)}}</div>
                                </th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{setNumber(obtenerValorRulesEngine('MargenPromedioUltimoAno')) |
                                        number:'1.0-0'}}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="col-sm-6 pr-sm-0">
            <div class="card">
                <div class="card-body">
                    <table class="table">
                        <tbody>
                            <tr>
                                <th width="50%">
                                    <div class="pt-3">Indicador de liquidez</div>
                                </th>
                                <td>
                                    <div type="text" class="form-control" disabled>
                                        {{setNumber(obtenerValorRulesEngine('IndicadorLiquidez')) | number:'1.0-2'}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div class="pt-3">% Flujo pago (leasing y otros) sobre margen</div>
                                </th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{setNumber(obtenerValorRulesEngine('flujoPagoLeasingOtrosSobreMargen')) |
                                        number:'1.0-2'}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div class="pt-3">% Flujo Salfa + leasing/Margen Carpeta</div>
                                </th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{setNumber(obtenerValorRulesEngine('flujoSalfaLeasingMargenCarpetaTributaria'))
                                        | number:'1.0-2'}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div class="pt-3">% Flujo Salfa + leasing/Ventas Carpeta tributaria o SII promedio
                                    </div>
                                </th>
                                <td>
                                    <div class="form-control" disabled>
                                        {{setNumber(obtenerValorRulesEngine('flujoSalfaLeasingVentasCarpetaTributariaOSiiPromedio'))
                                        | number:'1.0-2'}}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="tributaria">
        <div class="row mt-3">
            <p class="subTitle text-center w-100">
                Carpeta Tributaria M$
            </p>

            <div class="mt-1 w-100" *ngIf="infoCarpetaTributaria.length > 0">
                <app-dynamic-colunm-table [data]='infoCarpetaTributaria' [divider]='"group"' [header]='headerCarpetaTributaria'
                    [clasess]="'table-bordered'" id="info-tributaria">
                </app-dynamic-colunm-table>
            </div>
        </div>
    </div>

    <div class="row mt-3">
        <p class="subTitle text-center w-100">
            Información de Balance/Estado de Resultado M$ 
        </p>

        <ng-container *ngIf="infoBalance.length > 0">
            <div class="col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <th width="50%">
                                        <div class="pt-3">Indicador de liquidez</div>
                                    </th>
                                    <td>
                                        <div type="text" class="form-control" disabled>
                                            {{setNumber(obtenerValorRulesEngine('IndicadorLiquidez')) | number:'1.0-2'}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="pt-3">Periodo de cobro clientes</div>
                                    </th>
                                    <td>
                                        <div type="text" class="form-control" disabled>
                                            {{obtenerValorRulesEngine('PeriododeCobroClientes')}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="pt-3">Pago de IVA en fecha</div>
                                    </th>
                                    <td>
                                        <div type="text" class="form-control" disabled>
                                            0 
                                            <!-- por definir -->
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="pt-3">Gastos intereses/EBITDA</div>
                                    </th>
                                    <td>
                                        <div class="form-control" disabled>
                                            {{setNumber(obtenerValorRulesEngine('GastosInteresesEBITDA')) |
                                            number:'1.0-2'}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <th width="50%">
                                        <div class="pt-3">Indicador de endeudamiento</div>
                                    </th>
                                    <td>
                                        <div type="text" class="form-control" disabled>
                                            {{setNumber(obtenerValorRulesEngine('IndicadordeEndeudamiento')) |
                                            number:'1.0-2'}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="pt-3">EBITDA M$</div>
                                    </th>
                                    <td>
                                        <div type="text" class="form-control" disabled>
                                            {{(setNumber(obtenerValorRulesEngine('ebitda'))/1000) | number:'1.0-0'}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="pt-3">Margen EBITDA %</div>
                                    </th>
                                    <td>
                                        <div type="text" class="form-control" disabled>
                                            {{setNumber(obtenerValorRulesEngine('ebitdAporcentaje')) | number:'1.0-2'}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="pt-3">% Flujo pago (leasing y otros) sobre Venta</div>
                                    </th>
                                    <td>
                                        <div class="form-control" disabled>
                                            {{setNumber(obtenerValorRulesEngine('flujoPagoLeasingOtrosSobreVentas')) |
                                            number:'1.0-2'}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="pt-3">Línea de crédito / sobre Patrimonio(%)</div>
                                    </th>
                                    <td>
                                        <div type="text" class="form-control" disabled>
                                            {{setNumber(obtenerValorRulesEngine('lineaCreditoSobrePatrimonio')) |
                                            number:'1.0-2'}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="row mt-3">

        <p class="subTitle text-center w-100">
            Flujos Futuros Comprometidos M$
        </p>

        <div class="w-100 mb-3" *ngIf="objFlujosFuturos.length > 0">
            <div class="table-responsive">
                <table class="table table-bordered" *ngIf="objFlujosFuturos.length > 0">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th *ngFor="let item of titlesFlujosFuturos">
                                {{item}}
                            </th>
                            <th>Total</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let item of objFlujosFuturos | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                            <td class="text-capitalize">{{ item.Periodo_Vencimiento | date: 'LLL'}}
                                {{ item.Periodo_Vencimiento | date: 'YYYY'}}
                            </td>
                            <td *ngFor="let col of titlesFlujosFuturos">
                                {{item[quitarEspacios(col)] || 0 | number:'1.0-0'}}
                            </td>
                            <td>{{item | sumTd: false: ['Periodo_Vencimiento'] | number:'1.0-0'}}</td>
                        </tr>
                    </tbody>
                </table>
                <div id="pagination">
                    <div class="col-xs-10">
                        <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                    </div>
                    <div class="col-xs-2 text-right">
                        <app-paginator-by-items [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                    </div>
                </div> 
            </div>
        </div>

        <p class="subTitle text-center w-100">
            Facturacion Histórica M$
        </p>

        <div class="w-100" *ngIf="objFlujosHistoricos.length > 0 && titlesFlujosHistoricos.length > 0">
            <app-dynamic-colunm-table [data]='objFlujosHistoricos' [divider]='"divider"' [header]='quitarEspacios(titlesFlujosHistoricos)'
                [groupHeader]="'listadoFacturacionHistorica'" [clasess]="'table-bordered'">
            </app-dynamic-colunm-table>
        </div>
    </div>
</div>