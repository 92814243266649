import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable, of, Subject } from 'rxjs';
import { SessionService } from './session.service';
import { UtilsService } from './utils.service';
import { map } from 'rxjs/operators';
import { CarpetaTriburaiaModel } from 'src/app/models/solicitud-credito/carpeta-tributaria.model';
import { listadoLineaCreditoDetalle } from 'src/app/models/utils/utils.model';
import { AlertService } from 'src/app/components/_alert';
import { SolicitudDeCreditoModel } from 'src/app/models/solicitud-credito/solicitud-credito-request.model';

@Injectable({
  providedIn: 'root'
})
export class SolicitudCreditoService extends UtilsService {
  private endpoint: string = env.backend.SolicitudCredito.url;
  private servicio: string = env.backend.SolicitudCredito.servicio;
  private calculadora: string = env.backend.calculadoraUF.url;
  private tipoSolicitud: string = '';
  public listadoLineaCreditoDetalle: any = listadoLineaCreditoDetalle;
  public ResumenObjSubject: Subject<any> = new Subject();
  public ResumenObjSubjectObs$ = this.ResumenObjSubject.asObservable();
  public resumenObj: any[] = [];

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService,
    private alertService: AlertService) {
    super();
  }

  /**
   * @description
   * Servicio para buscar las solicitudes realizadas
   * @param rut string
   */
  searchSolicitudesCreditoList(rut: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    return this.http.get(`${this.endpoint}obtenerTransacciones`, { params })
      .pipe(map((response: any) => {
        return response.map((item: any, i: number) => {
          item['numSolicitud'] = item.IDTransaccion.substr(item.IDTransaccion.length - 5),
            item['date'] = this.formatDate(item['FechaReporte']),
            item['Modelo'] = this.listadoLineaCreditoDetalle[item['Modelo']];
          return item;
        });
      }));
  }

  /**
  * @description
  * Servicio para buscar la informacion basica de una solicitud
  * @param idTransaccion string
  */
  searchSolicitudesCreditoID(idTransaccion: string, rut: string): Observable<SolicitudDeCreditoModel> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idTransaccion", idTransaccion);
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    return this.http.get<SolicitudDeCreditoModel>(`${this.endpoint}obtenerTX`, { params })
  }

  /**
   * @description
   * Servicio que actualiza la informacion basica de una solicitud
   * @param rut  string
   * @param idTransaccion string 
   */
  updateBasicReport(rut: string, idTransaccion: string): Observable<SolicitudDeCreditoModel> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "1"
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "0",
      requestOrigin: "webClientes",
      model: this.getTipoSolicitud(),
      tipoOperacion: "10"
    };
    return this.http.post<SolicitudDeCreditoModel>(`${this.endpoint}iniciarFlujo`, request);
  }

  /**
   * @description
   * Metodo 0-2 que actualiza la informacion judicial de una solicitud
   * @param rut  string
   * @param idTransaccion string 
   */
  updatePoderJudicial(rut: string, idTransaccion: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "2",
          etapaPoderJudicial: "EnProgreso"
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "0",
      requestOrigin: "webClientes",
      model: this.getTipoSolicitud(),
      tipoOperacion: "10"
    };
    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  /**
   * @description
   * Metodo 0-3 que actualiza la informacion boletin concursal de una solicitudd
   * @param rut  string
   * @param idTransaccion string 
   */
  updateBoletinConcursal(rut: string, idTransaccion: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "3"
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "0",
      requestOrigin: "webClientes",
      model: this.getTipoSolicitud(),
      tipoOperacion: "10"
    };
    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  /**
   * @description
   * Iniciar una solicitud
   * @param rut 
   */
  iniciarFlujo(rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "0",
          etapaPoderJudicial: "EnProgreso"
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      stage: "0",
      requestOrigin: "webClientes",
      model: this.getTipoSolicitud(),
      tipoOperacion: "10"
    };
    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  searchCamposPersonalizados(rut: string, idTransaccion: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "0"
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "1",
      requestOrigin: "WEB",
      model: this.getTipoSolicitud(),
      tipoOperacion: "10"
    };
    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  obtenerCamposPersonalizados(rut: string, idTransaccion: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "2"
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "1",
      requestOrigin: "WEB",
      model: this.getTipoSolicitud()
    };

    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  /**
   * @description
   * Metodo 1-0 para obtener los valores de los campos persnoalizados
   * @param rut 
   * @param idTransaccion 
   * @returns 
   */
  obtenerValorCamposPersonalizados(rut: string, idTransaccion: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "0"
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "1",
      requestOrigin: "WEB",
      model: this.getTipoSolicitud()
    };

    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  /**
   * @description
   * Metodo 1-1 para actualizar la informacion de los campos personalizados
   * @param rut  string
   * @param idTransaccion string
   * @param campos array
   **/
  updateCamposPersonalizados(campos: any[], rut: string, idTransaccion: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "1"
        }
      ],
      CamposPersonalizados: {
        Attributos: campos
      },
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "1",
      requestOrigin: "WEB",
      model: this.getTipoSolicitud()
    };
    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }


  /**
   * @description
   *  Metodo para saber en que paso se encuentra la solicitud
   * @param idTransaccion 
   * @param rut 
   * @returns 
   */
  obtenerFlujo(idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idTransaccion", idTransaccion);
    params = params.append("model", this.getTipoSolicitud());
    params = params.append("rut", rut);
    return this.http.get(`${this.endpoint}obtenerFlujo`, { params })
  }

  /**
   * @description
   * Metodo 2-0 para buscar datos internos
   */
  buscarDatosInternos(rut: string, idTransaccion: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "0"
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "2",
      requestOrigin: "WEB",
      model: this.getTipoSolicitud()
    };
    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  uploadDatosInternos(rut: string, idTransaccion: string, form: string): Observable<any> {

    const file = form.split(',')[1];
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "1"
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "2",
      tipo: "credito",
      requestOrigin: "WEB",
      csv: file,
      model: this.getTipoSolicitud()
    };

    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  /**
   * @description
   * Metodo 3-0 para obtener carpeta tributaria 
   * @param rut 
   * @param idTransaccion 
   * @returns 
   */
  buscarCarpetaTributaria(rut: string, idTransaccion: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "0"
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "3",
      requestOrigin: "WEB",
      model: this.getTipoSolicitud()
    };
    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  /**
   * @description
   * Metodo 3-2 para subir carpeta tributaria
   * @param rut 
   * @param idTransaccion 
   * @param form 
   * @returns 
   */
  uploadCarpetaTributaria(rut: string, idTransaccion: string, form: string): Observable<any> {

    const file = form.split(',')[1];
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "2"
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "3",
      requestOrigin: "WEB",
      pdf: file,
      model: this.getTipoSolicitud()
    };

    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  /**
   * @description
   * Metodo 4-? para buscar el informe comercial
   * @param idTransaccion 
   * @param obj 
   * @returns 
   */
  searchInformeComercial(idTransaccion: string, obj: any[]): Observable<any> {

    const request: any = {
      applicants: [],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      tipoOperacion: "11",
      stage: "4",
      requestOrigin: "WEB",
      model: this.getTipoSolicitud()
    };

    request.applicants = obj.map(e => {
      return {
        rut: e.Rut,
        type: e.type,
        operation: e.operation
      }
    });

    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  /**
   * @description
   * Metodo 4-? que permite actualizar el informe comercial
   * @param idTransaccion 
   * @param obj 
   * @returns 
   */
  updateInformeComercial(idTransaccion: string, obj: any[]): Observable<any> {
    const request: any = {
      applicants: [],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      tipoOperacion: "11",
      stage: "4",
      requestOrigin: "WEB",
      model: this.getTipoSolicitud(),
      informeComercial: {
        user: "",
        pass: "",
        vendor: "EQUIFAX_COMERCIAL_PLATINUM"
      },
    };

    request.applicants = obj.map(e => {
      return {
        rut: e.Rut,
        type: e.type,
        operation: e.operation
      }
    });

    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  /**
 * @description
 * Metodo 4-1 para actualizar socios
 * @param idTransaccion 
 * @param rut 
 * @returns 
 */
  updateSocios(idTransaccion: string, rut: string) {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "1",
          updatePartners: "true"
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      model: this.getTipoSolicitud(),
      stage: "4",
      tipoOperacion: "11",
      informeComercial: {
        user: "",
        pass: "",
        vendor: "EQUIFAX_COMERCIAL_PLATINUM"
      },
      idTransaccion: idTransaccion,
    };
    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  /**
   * @description 
   * Metodo 5-0 que permite obtener los informes Leasing y Sbif
   * de cada aplicante de la solicitud
   * 
   * @param idTransaccion 
   * @param obj 
   * @returns 
   */
  searchInformeLeasingSbif(idTransaccion: string, obj: any[]): Observable<any> {
    const request: any = {
      applicants: [],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "5",
      requestOrigin: "WEB",
      model: this.getTipoSolicitud()
    };

    request.applicants = obj.map(e => {
      return {
        rut: e.Rut,
        type: e.type,
        operation: '0'
      }
    });
    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  calcularUF(fecha: string): Observable<any> {
    const request = {
      fechaInicio: fecha,
      fechaFin: fecha,
      indicador: "UF"
    };
    return this.http.post(`${this.calculadora}`, request);
  }

  /**
 * @description 
 * Metodo 5-1 que permite crear los informes Leasing y Sbif
 * de cada aplicante de la solicitud
 * 
 * @param idTransaccion 
 * @param obj 
 * @returns 
 */
  crearInformeSbifLeasing(idTransaccion: string, obj: any[]): Observable<any> {
    const request: any = {
      applicants: [],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "5",
      requestOrigin: "WEB",
      model: this.getTipoSolicitud()
    };

    request.applicants = obj.map(e => {
      const tempArray: any[] = [];
      if (e.fileLeasing) tempArray.push({ tipoPDF: 'LEASING', pdfBase64: e.fileLeasing });
      if (e.fileSbif) tempArray.push({ tipoPDF: 'SBIF', pdfBase64: e.fileSbif });
      return {
        rut: e.Rut,
        type: e.type,
        operation: '1',
        pdfsProcesar: tempArray
      }
    });

    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  /**
 * @description 
 * Metodo 5-2 que permite obtener los informes Leasing y Sbif
 * de cada aplicante de la solicitud
 * 
 * @param idTransaccion 
 * @param obj 
 * @returns 
 */
  obtenerInformeSbifLeasing(idTransaccion: string, obj: any[]): Observable<any> {
    const request: any = {
      applicants: [],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "5",
      requestOrigin: "WEB",
      model: this.getTipoSolicitud()
    };

    request.applicants = obj.map(e => {
      return {
        rut: e.Rut,
        type: e.type,
        operation: '2',
        id_transaccion: e.idTransaccion
      }
    });
    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  obtenerVentasBalances(idTransaccion: string, rut: string, idTransaccionCarpeta: string): Observable<any> {

    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "0",
          id_transaccion: idTransaccionCarpeta
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      model: this.getTipoSolicitud(),
      stage: "6",
      idTransaccion: idTransaccion,
    };
    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  obtenerScore(idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "1"
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      model: this.getTipoSolicitud(),
      stage: "6",
      idTransaccion: idTransaccion,
    };
    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  obtenerRules(idTransaccion: string, rut: string, idRule: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "2",
          rules_idTransaccion: idRule
        }
      ],
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      model: this.getTipoSolicitud(),
      stage: "6",
      idTransaccion: idTransaccion,
    };
    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  /**
 * @description
 * Metodo 7-0 para editar la informacion de los campos personalizados
 * cuando el informe se encuentre en estado completado
 * @param rut  string
 * @param idTransaccion string
 * @param campos array
 **/
  editCamposPersonalizados(campos: any[], rut: string, idTransaccion: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      applicants: [
        {
          rut: rut,
          type: "primary",
          operation: "0"
        }
      ],
      CamposPersonalizados: {
        Attributos: campos
      },
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      idTransaccion: idTransaccion,
      stage: "7",
      requestOrigin: "WEB",
      model: this.getTipoSolicitud()
    };
    return this.http.post(`${this.endpoint}iniciarFlujo`, request);
  }

  /**
   * @description
   * Metodo que permite cerrar una solicitud
   * @param idTransaccion string  
   * @param rut string 
   * @param obj any[]
   * @returns
   */
  cerrarSolicitud(idTransaccion: string, rut: string, datosCalculados: any[]): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      model: this.getTipoSolicitud(),
      idTransaccion: idTransaccion,
      informesCalculados: {
        datosCalculados,
        TablasInformesCalculadosNuevo: Object.assign({}, ...datosCalculados)
      },
    };
    return this.http.post(`${this.endpoint}cerrarFlujo`, request);
  }

  /**
   * @description
   * Metodo que permite eliminar una solicitud
   * @param idTransaccion string  
   * @param rut string 
   * @param obj any[]
   * @returns
   */
  eliminarSolicitud(idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    const request = {
      rut: rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      idTransaccion: idTransaccion
    };
    return this.http.post(`${this.endpoint}borrarTransaccion`, request);
  }


  public checkInformeStatus(idTransaccion: string, obj: any[]): Observable<any> {
    return this.obtenerInformeSbifLeasing(idTransaccion, obj);
  }

  public async getInforme(idTransaccion: string, obj: any[]): Promise<any> {

    const timesToCheck = 12;
    let timesChecked = 0;

    const sleep = (time: number) => new Promise((resolve, reject) => setTimeout(() => resolve(null), time));

    while (timesChecked < timesToCheck) {
      try {

        await sleep(10000);

        const informeStatus: any = await this.checkInformeStatus(idTransaccion, obj)
          .toPromise();

        if (informeStatus.SolicitudDeCredito?.Reportes?.leasingSbif?.DatosBasicosSolicitud?.EstadoTransaccion === 'COMPLETED') {
          return informeStatus;
        }
      } catch (error: any) {
        this.alertService.error(error?.error?.SolicitudDeCredito?.Reportes?.leasingSbif?.message || 'Ocurrio un Error');
        break;
      }
      timesChecked += 1;
    }
  }

  public async getReporteRules(idTransaccion: string, rut: string, idRule: string): Promise<any> {

    const timesToCheck = 36;
    let timesChecked = 0;
    const keyUrl = 'rulesEngine-reporte';
    const sleep = (time: number) => new Promise((resolve, reject) => setTimeout(() => resolve(null), time));

    while (timesChecked < timesToCheck) {
      try {

        await sleep(5000);

        const informeStatus: any = await this.obtenerRules(idTransaccion, rut, idRule)
          .toPromise();
        if (informeStatus.SolicitudDeCredito?.Reportes?.[keyUrl]?.TransactionStatus === 'DONE') {
          return informeStatus;
        }
      } catch (error: any) {
        this.alertService.error(error.error.message || 'Ocurrio un Error');
      }
      timesChecked += 1;
    }
  }


  getObtenerModelos(): Observable<any> {
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    return this.http.get(`${this.endpoint}obtenerModelos`, { params })
  }

  orderDataCarpetaTributaria(obj: any): Observable<any> {
    const detailsCarpetaTributaria: CarpetaTriburaiaModel = {
      sociosRepresentante: [
        {
          tipoPersona: 'socios',
          data: []
        },
        {
          tipoPersona: 'Representantes Legales',
          data: []
        }
      ],
      ventas: [],
      balances: [],
    };

    detailsCarpetaTributaria.sociosRepresentante[0].data = obj.SolicitudDeCredito?.Socios || [];
    detailsCarpetaTributaria.sociosRepresentante[1].data = obj.SolicitudDeCredito?.Reportes?.carpetaTributariaElectronica?.CarpetaTributariaElectronicaReporte?.Reporte?.representantes || [];
    detailsCarpetaTributaria.balances = this.updateDateBalances(obj.SolicitudDeCredito?.Reportes?.carpetaTributariaElectronica?.CarpetaTributariaElectronicaReporte?.Reporte?.f22) || [];
    detailsCarpetaTributaria.ventas = this.updateDataVentas(obj.SolicitudDeCredito?.Reportes?.carpetaTributariaElectronica?.CarpetaTributariaElectronicaReporte?.Reporte?.f29) || [];

    return of(this.utilRepresentantesSocios(detailsCarpetaTributaria));
  }

  utilRepresentantesSocios(detailsCarpetaTributaria: CarpetaTriburaiaModel): CarpetaTriburaiaModel[] {

    return [detailsCarpetaTributaria].map((item: CarpetaTriburaiaModel) => {
      return {
        ...item,
        sociosRepresentante:
          item.sociosRepresentante.map((element: any) => {
            return {
              ...element,
              data: element.data.map((item: any) => {
                return {
                  ...item,
                  rut: item.hasOwnProperty('rut') ? (item.rut).toString() + '-' + item.dv : item.Rut,
                  date: item.hasOwnProperty('fecha') ? this.formatDate(item.fecha) : this.formatDate(item.Fecha),
                  nombre: item.hasOwnProperty('Nombre') ? item.Nombre : item.nombreRazonSocial
                }
              })
            }
          })
      }
    });
  }


  updateDateBalances(balances: any) {
    if (balances && balances.length > 0) {
      return balances.map((item: any) => {
        return {
          ...item,
          balance: Number(item.periodo) - 1,
          check: 1
        }
      });
    }
    return [];
  }

  updateDataVentas(ventas: any) {
    let result: any[] = [];
    if (ventas && ventas.length > 0) {
      var groups = new Set(ventas.map((item: any) => item.periodo));
      let count = 0;
      groups.forEach((e: any) => {
        const obj = ventas.filter((i: any) => i.periodo === e).map((f: any) => {
          let date = new Date(`${f.periodo}-${f.mes}-3`);
          const nameMonth = f.mes !== 9 ? date.toLocaleString('default', { month: 'short' }).replace('.', '')
            : 'sep'
          return {
            [nameMonth]: f.hasOwnProperty('sinDeclaracion') ? 0 : 1
          };
        });

        const newObj: any = {};
        newObj.ventas = e;
        obj.forEach((element: any) => {
          newObj[Object.keys(element)[0]] = element[Object.keys(element)[0]]
        });
        result.push(newObj);
        count++
      });
    }
    return result;
  }

  orderInformeComercial(obj: any): Observable<any> {
    const detailsInformeComercia: any[] = [];
    const data: any = {
      empresaSocios: [
        {
          tipoPersona: 'empresa',
          data: []
        },
        {
          tipoPersona: 'socios',
          data: []
        }
      ]
    };

    data.empresaSocios[0].data = [obj.SolicitudDeCredito?.Reportes?.basicReport?.InformacionBasica] || [];
    data.empresaSocios[1].data = obj.SolicitudDeCredito?.Socios || [];

    detailsInformeComercia.push(data);
    return of(detailsInformeComercia);

  }

  obtenerEmpresaSocios(obj: any): Observable<any> {
    const sociosEmpresa: any[] = [];

    if (obj.SolicitudDeCredito?.Reportes?.basicReport?.InformacionBasica)
      sociosEmpresa.push(...[obj.SolicitudDeCredito?.Reportes?.basicReport?.InformacionBasica].map((e: any) => {
        return {
          Rut: e.Rut.replace(/\./g, ''),
          type: 'primary',
          operation: 0
        }
      }));

    if (obj.SolicitudDeCredito?.Socios.length > 0)
      sociosEmpresa.push(...obj.SolicitudDeCredito?.Socios.map((e: any) => {
        return {
          Rut: e.Rut.replace(/\./g, ''),
          type: 'partner',
          operation: 0
        }
      }));

    return of(sociosEmpresa)
  }

  getInformeDeudas(obj: any): Observable<any> {
    const basicReportInformeComercial = obj.SolicitudDeCredito?.Reportes?.basicReportInformeComercial;
    const info: any[] = [];
    let representante: any[] = [];

    if (basicReportInformeComercial && Object.keys(basicReportInformeComercial).length > 0) {
      representante = this.getAntecedentesComercialesEmpresa(basicReportInformeComercial?.AntecedentesComerciales?.Resumen,
        basicReportInformeComercial?.InformacionBasica?.NombreORazonSocial + ' ' + basicReportInformeComercial?.InformacionBasica?.Rut,
        basicReportInformeComercial?.Riesgo?.Clasificacion,
        basicReportInformeComercial?.InformacionBasica?.FechaReporte) || [];
    }
    const socios = this.getAntecedentesComercialesSocios(obj.SolicitudDeCredito?.Socios) || []


    if (representante.length > 0) {
      info.push(...representante);
    }

    if (socios.length > 0) {
      socios.forEach(element => {
        info.push(element)
      });
    }

    return of(info);
  }

  getAntecedentesComercialesEmpresa(resumen: any, nombre: string, score: string, fecha: string) {
    const result: any[] = [];

    if (resumen) {
      result.push({
        name: nombre || '',
        fecha: this.formatDate(fecha) || '',
        score: score || 0,
        morosidades: [
          { morosidad: resumen.MorosidadesCantidad || 0 },
          { morosidad: resumen.MorosidadesMonto || 0 }],
        protestos: [
          { protesto: resumen.ProtestoCantidad || 0 },
          { protesto: resumen.ProtestosMonto || 0 }],
        multas: [
          { multa: resumen.InfraccionesLaboralesCantidad || 0 },
          { multa: resumen.InfraccionesLaboralesMonto || 0 }]
      });
    }
    return result
  }

  getAntecedentesComercialesSocios(obj: any[]) {
    const result: any[] = [];
    if (obj) {
      if (obj.length > 0) {
        obj.map((e: any) => {
          if (Object.keys(e.Reportes).length > 0 && e.Reportes?.basicReportInformeComercial && Object.keys(e.Reportes?.basicReportInformeComercial)?.length > 0) {
            const resumenObj = e.Reportes?.basicReportInformeComercial?.AntecedentesComerciales?.Resumen;
            result.push({
              name: e.Nombre + ' ' + e.Rut,
              fecha: this.formatDate(e.Reportes?.basicReportInformeComercial?.InformacionBasica?.FechaReporte) || '',
              score: e.Reportes?.basicReportInformeComercial?.Riesgo?.Clasificacion || 0,
              morosidades: [
                { morosidad: resumenObj?.MorosidadesCantidad || 0 },
                { morosidad: resumenObj?.MorosidadesMonto || 0 }],
              protestos: [
                { protesto: resumenObj?.ProtestoCantidad || 0 },
                { protesto: resumenObj?.ProtestosMonto || 0 }],
              multas: [
                { multa: resumenObj?.InfraccionesLaboralesCantidad || 0 },
                { multa: resumenObj?.InfraccionesLaboralesMonto || 0 }]
            })
          }
        })
      }
    }
    return result
  }

  setInformeSbif(obj: any): any[] {
    let representante: any[] = [];
    if (obj?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Sbif?.Reporte) {
      if (Object.keys(obj?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Sbif?.Reporte).length > 0) {
        representante = this.obtenerUltimoObj({
          nombreRepresentante: [{
            Item: obj?.SolicitudDeCredito?.Reportes?.basicReportInformeComercial?.InformacionBasica?.NombreORazonSocial
              + ' ' + obj?.SolicitudDeCredito?.Reportes?.basicReportInformeComercial?.InformacionBasica?.Rut, Valor: ''
          }],
          fechaReporte: [{ Item: 'fecha', Valor: obj?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Sbif?.Reporte?.Periodo }],
          CreditoDirecto: this.agruparObjetos(
            obj?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Sbif?.Reporte?.TiposDeCredito?.CreditoDirecto),
          CreditoIndirecto: this.agruparObjetos(
            obj?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Sbif?.Reporte?.TiposDeCredito?.CreditoIndirecto),
          DesgloseTotalCreditoIndirecto: this.agruparObjetos(
            obj?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Sbif?.Reporte?.TiposDeCredito?.DesgloseTotalCreditoIndirecto),
          LineasDeCreditoDisponible: obj?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Sbif?.Reporte?.LineasDeCreditoDisponible,
        });
      }
    }

    const socios = obj.SolicitudDeCredito?.Socios;
    const info: any = [];
    if (representante.length > 0) {
      info.push(representante);
    }

    if (socios.length > 0) {
      socios.forEach((element: any) => {
        if (Object.values(element.Reportes)?.length > 0 && (element.Reportes?.leasingSbif?.Reporte?.Sbif && Object.values(element.Reportes?.leasingSbif?.Reporte?.Sbif).length > 0)) {
          info.push(this.obtenerUltimoObj({
            nombreSocio: [{ Item: element.Nombre + ' ' + element.Rut }],
            fechaReporte: [{ Item: 'fecha', Valor: element?.Reportes?.leasingSbif?.Reporte?.Sbif?.Reporte?.Periodo }],
            CreditoDirecto: this.agruparObjetos(
              element?.Reportes?.leasingSbif?.Reporte?.Sbif?.Reporte?.TiposDeCredito?.CreditoDirecto) || [],
            CreditoIndirecto: this.agruparObjetos(
              element?.Reportes?.leasingSbif?.Reporte?.Sbif?.Reporte?.TiposDeCredito?.CreditoIndirecto) || [],
            DesgloseTotalCreditoIndirecto: this.agruparObjetos(
              element?.Reportes?.leasingSbif?.Reporte?.Sbif?.Reporte?.TiposDeCredito?.DesgloseTotalCreditoIndirecto) || [],
            LineasDeCreditoDisponible: element?.Reportes?.leasingSbif?.Reporte?.Sbif?.Reporte?.LineasDeCreditoDisponible || []
          }) || [])
        }
      });
    }
    return info;
  }

  agruparObjetos(obj: any[]) {
    return obj.reduce((result: any, currentValue: any) => {
      if (currentValue['Item'] === undefined) return result;
      (result[currentValue['Item']] = result[currentValue['Item']] || []).push(currentValue);
      return result;
    }, {});
  }

  agruparObjetosByKey(obj: any[], keyValue: string) {
    return obj.reduce(
      (objectsByKeyValue, obj) => ({
        ...objectsByKeyValue,
        [obj[keyValue]]: (objectsByKeyValue[obj[keyValue]] || []).concat(obj)
      }),
      {});
  }


  obtenerUltimoObj(obj: any) {

    if (typeof obj === 'object') {
      if (Object.values(obj).length > 0) {
        return Object.keys(obj).map((e: any) => {
          return (
            Array.isArray(obj[e])
          ) ?

            ([Object.assign({
              [e]: obj[e].length > 0 ? obj[e].reduce((a: any, b: any) => {
                b.Item = e;
                return new Date(a.Periodo) >
                  new Date(b.Periodo) ? a : b;
              }) : 0
            })]) :
            (Object.keys(obj[e]).map((f: any) => {
              {
                return {
                  [f]: obj[e][f].length > 0 ? obj[e][f].reduce((a: any, b: any) => {
                    return new Date(a.Periodo) >
                      new Date(b.Periodo) ? a : b;
                  }) : 0
                }
              }
            })
            )
        });
      }
    }
    return [];
  }

  getEstadoAprobacion(): Observable<any> {
    const info = [
      { text: 'Aprobado', value: 'aprobado' },
      { text: 'Aprobado con condiciones', value: 'aprobadoCondiciones' },
      { text: 'Rechazado', value: 'rechazado' }
    ];
    return of(info);
  }

  getComboAplica(): Observable<any> {
    const info = [{ value: 'no', text: 'No', }, { value: 'si', text: 'SI' }, { value: 'noAplica', text: 'No Aplica' }];
    return of(info);
  }

  getGarantias(): Observable<any> {
    const info = [
      {
        value: 'boletaGarantia',
        text: 'Boleta de Garantía'
      },
      {
        value: 'letra',
        text: 'Letra'
      },
      {
        value: 'prenda',
        text: 'Prenda'
      },
      {
        value: 'pagare',
        text: 'Pagaré'
      },
      {
        value: 'aval',
        text: 'Aval'
      },
      {
        value: 'sinGarantias',
        text: 'Sin Garantías'
      },
      {
        value: 'prendaPagare',
        text: 'Prenda + Pagaré'
      },
      {
        value: 'telecheque',
        text: 'Telecheque'
      },
    ];

    return of(info);
  }

  getDocumentos(): Observable<any> {
    const info = [
      {
        value: 'cheque',
        text: 'Cheque '
      },
      {
        value: 'letra',
        text: 'Letra'
      },
      {
        value: 'sinDocumentos',
        text: 'Sin Documentación'
      },
    ];
    return of(info);
  }

  setTipoSolicitud(tipoSolicitud: string): boolean {
    this.tipoSolicitud = tipoSolicitud;
    return true;
  }

  getTipoSolicitud(): string {
    return this.tipoSolicitud;
  }

  getInformeLeasing(obj: any): any[] {
    const representante: any[] = [];
    if (obj.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Leasing?.Reporte && obj?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Leasing?.Reporte?.Deudas?.length > 0) {
      representante.push(this.obtenerUltimoObj({
        nombreRepresentante: [{
          Item: obj?.SolicitudDeCredito?.Reportes?.basicReportInformeComercial?.InformacionBasica?.NombreORazonSocial
            + ' ' + obj?.SolicitudDeCredito?.Reportes?.basicReportInformeComercial?.InformacionBasica?.Rut, Valor: ''
        }],
        fechaReporte: [{ Item: 'fecha', Valor: obj?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Leasing?.Reporte?.Periodo || '' }],
        deudaLeasing: this.agruparObjetos(obj?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Leasing?.Reporte?.Deudas || [])
      }));
    }
    const socios = obj?.SolicitudDeCredito?.Socios || [];

    const result: any = [
      representante
    ];

    socios.forEach((element: any) => {
      if (element?.Reportes?.leasingSbif?.Reporte?.Leasing?.Reporte && element.Reportes?.leasingSbif?.Reporte?.Leasing?.Reporte?.Deudas.length > 0) {
        result.push(this.obtenerUltimoObj({
          nombreSocio: [{ Item: element.Nombre + ' ' + element.Rut }],
          fechaReporte: [{ Item: 'fecha', Valor: this.getLastDate(element.Reportes?.leasingSbif?.Reporte?.Leasing?.Reporte?.Deudas || []) }],
          deudaLeasing: element.Reportes?.leasingSbif?.Reporte?.Leasing?.Reporte?.Deudas || []
        }));
      }
    });
    return result;
  }

  getLastDate(obj: any): any {
    return new Date(Math.max.apply(null, obj.map(((e: any) => {
      return new Date(e.Periodo);
    }))));
  }

  setObjResumen(obj: any, origin: string): Observable<any> {
    if (obj && origin) {
      if (this.resumenObj.find((e: any) => Object.keys(e)[0] === origin)) {
        this.resumenObj.find((e: any) => Object.keys(e)[0] === origin)[origin] = obj;
        return of(this.resumenObj);
      } else {
        this.resumenObj.push(obj);
        return of(this.resumenObj);
      }
    }
    return of(this.resumenObj);
  }

  setObjResumenNew(obj: any, origin: string): Observable<any> {
    if (obj && origin) {
      const index = this.resumenObj.findIndex((e: any) => Object.keys(e)[0] === origin);
      if (index >= 0) {
        this.resumenObj[index] = obj;
        return of(this.resumenObj);
      } else {
        this.resumenObj.push(obj);
        return of(this.resumenObj);
      }
    }
    return of(this.resumenObj);
  }

  setUpdateEstadoAprobracion(obj: any): Observable<any> {
    if (obj) {
      const index = this.resumenObj.findIndex((e: any) => Object.keys(e)[0] === 'estadoAprobacion');
      if (index > -1) {
        this.resumenObj[index]['estadoAprobacion'] = obj;
      } else {
        this.resumenObj.push({ estadoAprobacion: obj });
      }
      return of(this.resumenObj);
    }
    return of(this.resumenObj);
  }

  getObjResumenValidation(origin: string): Observable<any> {
    return of(this.resumenObj.find((e: any) => Object.keys(e)[0] === origin) ? true : false);
  }

  getObjResumen(): any[] {
    return this.resumenObj;
  }

  obtenerPeriodo(periodo: string): string {
    const periodoArray: string[] = periodo.split("-");
    if(periodoArray.length > 0){
      const date = new Date(Number(periodoArray[0]), (Number(periodoArray[1]) - 1));
      date.setUTCHours(0,0,0,0);
      return date.toJSON();
    } else {
      return '';
    }
  }
}
