import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'angular-google-charts';
import * as moment from 'moment';
import { IReporteVehiculos, vehiculosText } from 'src/app/models/vehiculos/vehiculos.model';
import { UtilsService } from 'src/app/shared/services/utils.service';

interface IChart {
  title: string;
  type: ChartType;
  data: any[][];
  columns: string[];
  options: any[];
  width: number;
  height: number;
}

@Component({
  selector: 'app-vehiculos-viewer',
  templateUrl: './vehiculos-viewer.component.html',
  styleUrls: ['./vehiculos-viewer.component.scss']
})
export class VehiculosViewerComponent implements OnInit {
  @Input() content: IReporteVehiculos | any;
  @Input() showHeader: boolean = true;
  @Input() fromConsolidado: boolean = false;
  public header: string[] = [
    'ppu',
    'marca',
    'modelo',
    'color',
    'tipo_vehiculo',
    'anio_fabricacion',
    'ultima_transferencia',
    'numero_motor',
    'numero_chasis',
    'tasacion'
  ];
  public vehiculosText: any = vehiculosText;

  constructor() {}


  ngOnInit(): void {
  }

  setNumber(num: string): number {
    return Number(num);
  }

}
