<div class="panel panel-default border-0 not-print">
  <div class="panel-body">
      <div class="btn-option">
          <button *ngIf="showReportes && stepper === 5" [printTitle]='"FlujoDeCredito_"+(rut | rutFormat)+"_"+today'
              [useExistingCss]="true" printSectionId="print-section" ngxPrint
              class="btn btn-primary float-right">Descargar PDF</button>
      </div>
  </div>
</div>

<div class="not-print">

  <ul class="nav nav-tabs d-flex justify-content-center border-0 mt-3">
    <li><button id="button-balance-manuales" (click)="scroll('balance-manuales', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
        <i class="fa fa-line-chart" aria-hidden="true"></i>
        Balance Manuales
      </button>
    </li>
    <li><button id="button-balance-automaticos" (click)="scroll('balance-automaticos', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
        <i class="fa fa-bar-chart" aria-hidden="true"></i>
        Balance Automáticos
      </button>
    </li>
    <li><button id="button-deudores" (click)="scroll('deudores', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
        <i class="fa fa-users"></i>
        Deudores
      </button>
    </li>
    <li><button id="button-iva" (click)="scroll('iva', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
        <i class="fa fa-percent"></i>
        IVA
      </button>
    </li>
    <li><button id="button-carga-financiera" (click)="scroll('carga-financiera', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
        <i class="fa fa-file-text-o"></i>
        Carga Financiera
      </button>
    </li>
    <li><button id="button-linea" (click)="scroll('linea', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
        <i class="fa fa-list-alt"></i>
        Línea / Operación Propuesta
      </button>
    </li>
    <li><button id="button-presentacion" (click)="scroll('presentacion', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
        <i class="fa fa-user"></i>
        Presentación Cliente
      </button>
    </li>
  </ul>

</div>


<div class="col-sm-12" id="print-section">
  <div class="row p-3">
    <div class="col-sm-12">
        <div class="row">
            <h3 class="col-12 my-2 mb-4 cuadro-title p-3 rounded-header">
                Ficha Comité de Crédito
            </h3>
            <form [formGroup]="servicioFichaForm">

              <ng-template #fichaCabecera>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group row">
                      <label class="col-12 col-form-label">Nombre</label>
                      <div class="col-12">
                      <span>{{servicioFichaForm?.controls?.nombreComite?.value || ''}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group row">
                      <label class="col-12 col-form-label">Fecha</label>
                      <div class="col-12">
                      <span>{{servicioFichaForm?.controls?.fechaComite?.value || ''}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group row">
                      <label class="col-12 col-form-label">Giro</label>
                      <div class="col-12">
                      <span>{{servicioFichaForm?.controls?.giroComite?.value || ''}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group row">
                      <label class="col-12 col-form-label">Ejecutivo</label>
                      <div class="col-12">
                      <span>{{servicioFichaForm?.controls?.ejecutivoComite?.value || ''}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group row">
                      <label class="col-12 col-form-label">Rut</label>
                      <div class="col-12">
                      <span>{{servicioFichaForm?.controls?.rutComite?.value || ''}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group row">
                      <label class="col-12 col-form-label">Canal Admisión</label>
                      <div class="col-12">
                      <span>{{servicioFichaForm?.controls?.canalAdmisionComite?.value || ''}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group row">
                      <label class="col-12 col-form-label">Sucursal</label>
                      <div class="col-12">
                      <span>{{servicioFichaForm?.controls?.sucursalComite?.value || ''}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group row">
                      <label class="col-12 col-form-label">Pagina Web</label>
                      <div class="col-12">
                      <span>{{servicioFichaForm?.controls?.paginaWebComite?.value || ''}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group row">
                      <label class="col-12 col-form-label">Enrolado</label>
                      <div class="col-12">
                      <span>{{servicioFichaForm?.controls?.enroladoComite?.value || ''}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group row">
                      <label class="col-12 col-form-label">Fecha inicio Act SII</label>
                      <div class="col-12">
                      <span>{{servicioFichaForm?.controls?.inicioSiiComite?.value || ''}}</span>
                    </div>
                  </div>
                </div>
              </ng-template>

              <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else fichaCabecera">

                <div class="col-sm-12 col-md-8">
                    <div class="form-group row">
                        <label for="nombre-comite" class="col-12 col-form-label">Nombre</label>
                        <div class="col-12">
                          <input type="text" class="form-control" id="nombre-comite" formControlName="nombreComite">
                          <div class="w-100">
                            <app-error [control]="servicioFichaForm.get('nombreComite')"></app-error>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="form-group row">
                        <label for="fecha-comite" class="col-12 col-form-label">Fecha</label>
                        <div class="col-12">
                          <input type="text" class="form-control" id="fecha-comite" formControlName="fechaComite">
                          <div class="w-100">
                            <app-error [control]="servicioFichaForm.get('fechaComite')"></app-error>
                          </div>
                        </div>
                    </div>
                </div>
      
                <div class="col-sm-12 col-md-8">
                    <div class="form-group row">
                        <label for="giro-comite" class="col-12 col-form-label">Giro</label>
                        <div class="col-12">
                          <input type="text" class="form-control" id="giro-comite" formControlName="giroComite">
                          <div class="w-100">
                            <app-error [control]="servicioFichaForm.get('giroComite')"></app-error>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="form-group row">
                        <label for="ejecutivo-comite" class="col-12 col-form-label">Ejecutivo</label>
                        <div class="col-12">
                          <input type="text" class="form-control" id="ejecutivo-comite" formControlName="ejecutivoComite">
                          <div class="w-100">
                            <app-error [control]="servicioFichaForm.get('ejecutivoComite')"></app-error>
                          </div>
                        </div>
                    </div>
                </div>
      
                <div class="col-sm-12 col-md-4">
                    <div class="form-group row">
                        <label for="rut-comite" class="col-12 col-form-label">Rut</label>
                        <div class="col-12">
                          <input type="text" class="form-control" id="rut-comite" formControlName="rutComite">
                          <div class="w-100">
                            <app-error [control]="servicioFichaForm.get('rutComite')"></app-error>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="form-group row">
                        <label for="canalAdmision-comite" class="col-12 col-form-label">Canal Admisión</label>
                        <div class="col-12">
                          <input type="text" class="form-control" id="canalAdmision-comite" formControlName="canalAdmisionComite">
                          <div class="w-100">
                            <app-error [control]="servicioFichaForm.get('canalAdmisionComite')"></app-error>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="form-group row">
                        <label for="sucursal-comite" class="col-12 col-form-label">Sucursal</label>
                        <div class="col-12">
                          <input type="text" class="form-control" id="sucursal-comite" formControlName="sucursalComite">
                          <div class="w-100">
                            <app-error [control]="servicioFichaForm.get('sucursalComite')"></app-error>
                          </div>
                        </div>
                    </div>
                </div>
      
                <div class="col-sm-12 col-md-4">
                    <div class="form-group row">
                        <label for="paginaWeb-comite" class="col-12 col-form-label">Pagina Web</label>
                        <div class="col-12">
                          <input type="text" class="form-control" id="paginaWeb-comite" formControlName="paginaWebComite">
                          <div class="w-100">
                            <app-error [control]="servicioFichaForm.get('paginaWebComite')"></app-error>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="form-group row">
                        <label for="enrolado-comite" class="col-12 col-form-label">Enrolado</label>
                        <div class="col-12">
                          <input type="text" class="form-control" id="enrolado-comite" formControlName="enroladoComite">
                          <div class="w-100">
                            <app-error [control]="servicioFichaForm.get('enroladoComite')"></app-error>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="form-group row">
                        <label for="inicioSii-comite" class="col-12 col-form-label">Fecha inicio Act SII</label>
                        <div class="col-12">
                          <input type="text" class="form-control" id="inicioSii-comite" formControlName="inicioSiiComite">
                          <div class="w-100">
                            <app-error [control]="servicioFichaForm.get('inicioSiiComite')"></app-error>
                          </div>
                        </div>
                    </div>
                </div>

              </ng-container>

            </form>
        </div>
    </div>
  </div>
  
  <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak" (click)="show('informacion-sociedad')">
    Información Sociedad
  </h3>
  <div class="container-desplegables" id="informacion-sociedad">
    <div class="row p-5">
      <div class="col-sm-12 col-lg-8">
        <div class="table-responsive" *ngIf="showReportes">
          <table class="table table-hover table-bordered border">
              <thead>
                <tr>
                  <th scope="col" colspan="5" class="text-center py-1 bg-capital font-head-size">Composición de la sociedad</th>
                </tr>
                <tr>
                  <th scope="col" class="py-1 bg-capital font-head-size width-td-name">Socio</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Rut</th>
                  <th scope="col" class="py-1 bg-capital font-head-size width-td-porc">%</th>
                  <th scope="col" class="py-1 bg-capital font-head-size width-td-porc">FS</th>
                </tr>
              </thead>
              <tbody>

                <ng-template #sociosNoEditable>

                  <ng-container *ngFor="let item of sociosComposicion; let i = index">
                    <tr class="height-row pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">
                      <td>{{sociosComposicion[i].socio}}</td>
                      <td class="text-nowrap">{{sociosComposicion[i].rut | rutFormat}}</td>
                      <td>{{stringToFloat(sociosComposicion[i].porcentaje) | number: '1.0-2'}}</td>
                      <td>{{sociosComposicion[i].fs}}</td>
                    </tr>
                  </ng-container>

                </ng-template>

                <ng-template #sinDatosSocios>
                  <tr class="height-row">
                    <td colspan="5">Sin información</td>
                  </tr>
                </ng-template>

                <ng-container *ngIf="sociosComposicion.length > 0 else sinDatosSocios">
                  
                  <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else sociosNoEditable">
                    
                    <ng-container *ngFor="let item of sociosComposicion; let i = index">
                      <tr class="height-row">
                        <td class="pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">{{sociosComposicion[i].socio}}</td>
                        <td class="pointer-cursor text-nowrap" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">{{sociosComposicion[i].rut | rutFormat}}</td>
                        <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='socio-porcentaje{{i}}' name='socio-porcentaje{{i}}' [(ngModel)]="sociosComposicion[i].porcentaje"></td>
                        <td>
                          <select class="form-control" id='socio-fs{{i}}' name='socio-fs{{i}}' [(ngModel)]="sociosComposicion[i].fs" (change)="validaSFElement(i)">
                            <option value="" selected>Seleccione una opción</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                          </select>
                        </td>
                      </tr>
                    </ng-container>

                  </ng-container>


                </ng-container>
                
              </tbody>
          </table>
        </div>
    </div>
    
    <div class="col-sm-12 col-lg-4">
      <div class="table-responsive" *ngIf="showReportes">
        <table class="table table-hover table-bordered border">
            <thead>
              <tr>
                <th scope="col" colspan="4" class="text-center py-1 bg-capital font-head-size">Informes Comerciales</th>
              </tr>
              <tr>
                <th scope="col" class="py-1 bg-capital font-head-size">N°</th>
                <th scope="col" class="py-1 bg-capital font-head-size">Monto M$</th>
                <th scope="col" class="py-1 bg-capital font-head-size">Motivo</th>
              </tr>
            </thead>
            <tbody>

              <ng-template #sinDatosSociosInforme>
                <tr class="height-row">
                  <td colspan="4">Sin información</td>
                </tr>
              </ng-template>

              <ng-container *ngIf="sociosInformes.length > 0 else sinDatosSociosInforme">

                <ng-container *ngFor="let item of sociosInformes; let i = index">
                  <tr class="height-row pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">
                    <td>{{sociosInformes[i].numero | number: '1.0-0'}}</td>
                    <td>{{sociosInformes[i].monto | number: '1.0-2'}}</td>
                    <td>{{sociosInformes[i].motivo}}</td>
                  </tr>
                </ng-container>
                
              </ng-container>

            </tbody>
          </table>
        </div>
      </div>

      <div class="col-sm-12 col-lg-10 mx-auto" *ngIf="validaArraySF()">
        <div class="table-responsive" *ngIf="showReportes">
          <table class="table table-hover table-bordered border">
              <thead>
                <tr>
                  <th scope="col" colspan="4" class="text-center py-1 bg-capital font-head-size">Direcciones Socios</th>
                </tr>
                <tr>
                  <th scope="col" class="py-1 bg-capital font-head-size">Socio</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Rut</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Dirección</th>
                </tr>
              </thead>
              <tbody>

                <ng-template #sociosDireccionNoEditable>
                  <ng-container *ngFor="let item of sociosComposicion; let i = index">
                    <ng-container *ngIf="sociosComposicion[i]?.fs && sociosComposicion[i]?.fs === 'SI'">
                      <tr class="height-row">
                        <td>{{sociosComposicion[i].socio}}</td>
                        <td class="text-nowrap">{{sociosComposicion[i].rut | rutFormat}}</td>
                        <td>{{sociosComposicion[i].direccion}}</td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-template>
  
                <ng-template #sinDatosSociosDireccion>
                  <tr class="height-row">
                    <td colspan="3">Sin información</td>
                  </tr>
                </ng-template>
  
                <ng-container *ngIf="sociosComposicion.length > 0 else sinDatosSociosDireccion">

                  <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else sociosDireccionNoEditable">
                    <ng-container *ngFor="let item of sociosComposicion; let i = index">
                      <ng-container *ngIf="sociosComposicion[i]?.fs && sociosComposicion[i]?.fs === 'SI'">
                        <tr class="height-row">
                          <td>{{sociosComposicion[i].socio}}</td>
                          <td class="text-nowrap">{{sociosComposicion[i].rut | rutFormat}}</td>
                          <td><input type="text" class="form-control" title="Ingrese la dirección del socio" id='socio-direccion{{i}}' name='socio-direccion{{i}}' [(ngModel)]="sociosComposicion[i].direccion"></td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  
                </ng-container>
  
              </tbody>
            </table>
          </div>
        </div>

      <div class="col-sm-12 col-lg-8">
        <div class="table-responsive" *ngIf="showReportes">
          <table class="table table-hover table-bordered border">
            <thead>
              <tr>
                <th scope="col" class="py-1 bg-capital font-head-size width-td-name">Empresas Relacionadas</th>
                <th scope="col" class="py-1 bg-capital font-head-size">Rut</th>
                <th scope="col" class="py-1 bg-capital font-head-size width-td-porc">%</th>
                <th scope="col" class="py-1 bg-capital font-head-size width-td-porc">FS</th>
              </tr>
            </thead>
            <tbody>

              <ng-template #empresasRelacionadasComposicionNoEditable>

                <ng-container *ngFor="let item of empresasRelacionadasComposicion; let i = index">
                  <tr class="height-row pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">
                    <td>{{empresasRelacionadasComposicion[i].socio}}</td>
                    <td class="text-nowrap">{{empresasRelacionadasComposicion[i].rut | rutFormat}}</td>
                    <td>{{stringToFloat(empresasRelacionadasComposicion[i].porcentaje) | number: '1.0-2'}}</td>
                    <td>{{empresasRelacionadasComposicion[i].fs}}</td>
                  </tr>
                </ng-container>

              </ng-template>

              <ng-template #sinDatosEmpresas>
                <tr class="height-row">
                  <td colspan="5">Sin información</td>
                </tr>
              </ng-template>

              <ng-container *ngIf="empresasRelacionadasComposicion.length > 0 else sinDatosEmpresas">

                <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else empresasRelacionadasComposicionNoEditable">
                  
                  <ng-container *ngFor="let item of empresasRelacionadasComposicion; let i = index">
                    <tr class="height-row">
                      <td class="pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">{{empresasRelacionadasComposicion[i].socio}}</td>
                      <td class="pointer-cursor text-nowrap" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">{{empresasRelacionadasComposicion[i].rut | rutFormat}}</td>
                      <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='empresa-porcentaje{{i}}' name='empresa-porcentaje{{i}}' [(ngModel)]="empresasRelacionadasComposicion[i].porcentaje"></td>
                      <td>
                        <select class="form-control" id='empresa-fs{{i}}' name='empresa-fs{{i}}' [(ngModel)]="empresasRelacionadasComposicion[i].fs">
                          <option value="" selected>Seleccione una opción</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </ng-container>

                </ng-container>
                
              </ng-container>

            </tbody>
          </table>
        </div>
      </div>

      <div class="col-sm-12 col-lg-4">
        <div class="table-responsive" *ngIf="showReportes">
            <table class="table table-hover table-bordered border">
              <thead>
                <tr>
                  <th scope="col" class="py-1 bg-capital font-head-size">N°</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Monto M$</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Motivo</th>
                </tr>
              </thead>
              <tbody>

                <ng-template #sinDatosEmpresasInforme>
                  <tr class="height-row">
                    <td colspan="4">Sin información</td>
                  </tr>
                </ng-template>

                <ng-container *ngIf="empresasRelacionadasInformes.length > 0 else sinDatosEmpresasInforme">
                  <ng-container *ngFor="let item of empresasRelacionadasInformes; let i = index">
                    <tr class="height-row pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">
                      <td>{{empresasRelacionadasInformes[i].numero | number: '1.0-0'}}</td>
                      <td>{{empresasRelacionadasInformes[i].monto | number: '1.0-2'}}</td>
                      <td>{{empresasRelacionadasInformes[i].motivo}}</td>
                    </tr>
                  </ng-container>
                </ng-container>

              </tbody>
            </table>
          </div>
        </div>
    </div>
  </div>

  <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak" (click)="show('linea', 'button-linea')">
    Línea / Operación Propuesta
  </h3>

  <div class="container-desplegables" id="linea">
    <div class="row p-5">

      <div class="col-sm-12">
        <div class="table-responsive" *ngIf="showReportes">
          <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col" class="py-1 bg-capital font-head-size" colspan="3">Índices Último Balance</th>
                  <th scope="col" class="py-1 bg-capital font-head-size" colspan="2">Venta Promedio Mensual M$</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" colspan="2" rowspan="2">Morosidades Protestos</th>
                </tr>
                <tr>
                  <th scope="col" class="py-1 bg-capital font-head-size">Patrimonio M$</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Leverage</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Utilidad M$</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Ene-Dic</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Ene a la fecha</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{{informacionFinancieraCabecera.dic || ''}}</th>
                  <th>{{informacionFinancieraCabecera.dic || ''}}</th>
                  <th>{{informacionFinancieraCabecera.dic || ''}}</th>
                  <th>{{informacionFinancieraCabecera.anioAnterior || ''}}</th>
                  <th>{{informacionFinancieraCabecera.anioActual || ''}}</th>
                  <th>N°</th>
                  <th>Monto M$</th>
                </tr>
                <tr>
                  <td>{{ informacionFinancieraCabecera.patrimonio !== ''? ((informacionFinancieraCabecera.patrimonio / 1000) | number: '1.0-0'): informacionFinancieraCabecera.patrimonio}}</td>
                  <td>{{ informacionFinancieraCabecera.leverage | number: '1.0-2'}}</td>
                  <td>{{ informacionFinancieraCabecera.utilidad !== ''? ((informacionFinancieraCabecera.utilidad / 1000) | number: '1.0-0'): informacionFinancieraCabecera.utilidad}}</td>
                  <td>{{ totalesInformacionFinanciera?.totalAnterior?.TotalProm?.ventas || 0 | number: '1.0-2'}}</td>
                  <td>{{ totalesInformacionFinanciera?.totalActual?.TotalProm?.ventas || 0 | number: '1.0-2'}}</td>
                  <td class="pointer-cursor" (click)="abrirModalTitular()">{{ informacionFinancieraCabecera.totalImpagoCant | number: '1.0-0'}}</td>
                  <td class="pointer-cursor" (click)="abrirModalTitular()">{{ informacionFinancieraCabecera.totalImpagoMonto | number: '1.0-2'}}</td>
                </tr>
              </tbody>
          </table>
        </div>
      </div>
      
      <ng-container *ngIf="showReportes && objectKeys(lineaOperacionPropuesta).length > 0">

        <ng-template #lineaNoEditable>

          <div class="col-12">
            <div class="table-responsive">
              <table class="table table-sm table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" class="text-center bg-capital font-head-size py-1" rowspan="2">Producto</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1" colspan="4">Financiamiento Propuesto (M$)</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1" rowspan="2">%</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1" rowspan="2">Plazo (dias)</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1" colspan="3" rowspan="1">Colocacion (M$)</th>
                    </tr>
                    <tr>
                      <th scope="col" class="text-center bg-capital font-head-size py-1">Linea Vigente</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1">Linea Propuesta</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1">Op. Puntual Vigente</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1">Op. Puntual Propuesta</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1">Vigente</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1">Morosa</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1">Cancelada</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Facturas</th>
                      <td>{{lineaOperacionPropuesta.lineaVigente.facturas || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.lineaPropuesta.facturas || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.opPuntualVigente.facturas || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.opPuntualPropuesta.facturas || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.porcentaje.facturas || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.plazoDias.facturas || 0 | number: '1.0-0'}}</td>
                      <td>{{lineaOperacionPropuesta.vigente.facturas || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.propuesta.facturas || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.cancelada.facturas || 0 | number: '1.0-2'}}</td>
                    </tr>
                    <tr>
                      <th>Fache</th>
                      <td>{{lineaOperacionPropuesta.lineaVigente.fache || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.lineaPropuesta.fache || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.opPuntualVigente.fache || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.opPuntualPropuesta.fache || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.porcentaje.fache || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.plazoDias.fache || 0 | number: '1.0-0'}}</td>
                      <td>{{lineaOperacionPropuesta.vigente.fache || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.propuesta.fache || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.cancelada.fache || 0 | number: '1.0-2'}}</td>
                    </tr>
                    <tr>
                      <th>Cheques</th>
                      <td>{{lineaOperacionPropuesta.lineaVigente.cheques || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.lineaPropuesta.cheques || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.opPuntualVigente.cheques || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.opPuntualPropuesta.cheques || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.porcentaje.cheques || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.plazoDias.cheques || 0 | number: '1.0-0'}}</td>
                      <td>{{lineaOperacionPropuesta.vigente.cheques || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.propuesta.cheques || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.cancelada.cheques || 0 | number: '1.0-2'}}</td>
                    </tr>
                    <tr>
                      <th>Confirming</th>
                      <td>{{lineaOperacionPropuesta.lineaVigente.confirming || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.lineaPropuesta.confirming || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.opPuntualVigente.confirming || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.opPuntualPropuesta.confirming || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.porcentaje.confirming || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.plazoDias.confirming || 0 | number: '1.0-0'}}</td>
                      <td>{{lineaOperacionPropuesta.vigente.confirming || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.propuesta.confirming || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.cancelada.confirming || 0 | number: '1.0-2'}}</td>
                    </tr>
                    <tr>
                      <th>OC</th>
                      <td>{{lineaOperacionPropuesta.lineaVigente.oc || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.lineaPropuesta.oc || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.opPuntualVigente.oc || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.opPuntualPropuesta.oc || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.porcentaje.oc || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.plazoDias.oc || 0 | number: '1.0-0'}}</td>
                      <td>{{lineaOperacionPropuesta.vigente.oc || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.propuesta.oc || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.cancelada.oc || 0 | number: '1.0-2'}}</td>
                    </tr>
                    <tr>
                      <th>Credito Directo</th>
                      <td>{{lineaOperacionPropuesta.lineaVigente.creditoDirecto || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.lineaPropuesta.creditoDirecto || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.opPuntualVigente.creditoDirecto || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.opPuntualPropuesta.creditoDirecto || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.porcentaje.creditoDirecto || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.plazoDias.creditoDirecto || 0 | number: '1.0-0'}}</td>
                      <td>{{lineaOperacionPropuesta.vigente.creditoDirecto || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.propuesta.creditoDirecto || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.cancelada.creditoDirecto || 0 | number: '1.0-2'}}</td>
                    </tr>
                    <tr>
                      <th>Pronto Pago</th>
                      <td>{{lineaOperacionPropuesta.lineaVigente.prontoPago || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.lineaPropuesta.prontoPago || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.opPuntualVigente.prontoPago || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.opPuntualPropuesta.prontoPago || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.porcentaje.prontoPago || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.plazoDias.prontoPago || 0 | number: '1.0-0'}}</td>
                      <td>{{lineaOperacionPropuesta.vigente.prontoPago || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.propuesta.prontoPago || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuesta.cancelada.prontoPago || 0 | number: '1.0-2'}}</td>
                    </tr>
                    <tr>
                      <th class="bg-capital">Total Propuesta</th>
                      <td>{{lineaOperacionPropuestaTotales.totalesLineaVigente || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuestaTotales.totalesLineaPropuesta || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuestaTotales.totalesOpPuntualVigente || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuestaTotales.totalesOpPuntualPropuesta || 0 | number: '1.0-2'}}</td>
                      <th class="bg-capital" colspan="2">Total</th>
                      <td>{{lineaOperacionPropuestaTotales.totalesVigente || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuestaTotales.totalesPropuesta || 0 | number: '1.0-2'}}</td>
                      <td>{{lineaOperacionPropuestaTotales.totalesCancelada || 0 | number: '1.0-2'}}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
        
          <div class="col-md-12 col-lg-8">
            <div class="row">
                <div class="col-12 ml-4">
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 border text-center bg-capital font-head-size">
                            <span class="span-linea">Vencimiento</span>
                        </div>
                        <div class="col-sm-6 col-lg-3 border">
                          <span>{{lineaOperacionPropuesta.vencimiento | date: 'dd-MM-yyyy'}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 ml-4">
                    <div class="row d-flex flex-row-reverse">
                        <div class="col-sm-6 col-lg-3 border">
                          <span>{{lineaOperacionPropuesta.opPuntualM | number: '1.0-2'}}</span>
                        </div>
                        <div class="col-sm-6 col-lg-3 border text-center bg-capital font-head-size">
                            <span class="span-linea">Op. Puntual M$</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 ml-4">
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 border text-center bg-capital font-head-size">
                            <span class="span-linea">Total Linea M$</span>
                        </div>
                        <div class="col-sm-6 col-lg-3 border">
                          <span>{{lineaOperacionPropuesta.totalLineaM | number: '1.0-2'}}</span>
                        </div>
                        <div class="col-sm-6 col-lg-3 border text-center bg-capital font-head-size">
                            <span class="span-linea">Riesgo Total M$</span>
                        </div>
                        <div class="col-sm-6 col-lg-3 border">
                          <span>{{lineaOperacionPropuesta.riesgoTotalM | number: '1.0-2'}}</span>
                        </div>
                    </div>
                </div>
              </div>
          </div>
        
          <div class="col-12">
            <div class="row">
                <div class="col-12 ml-4 mt-4 border pl-0">
                    <div class="col-sm-12 col-md-6 col-lg-3 border text-center bg-capital font-head-size">
                        <span class="span-linea">Presentacion Cliente</span>
                    </div>
                    <div class="col-12 pb-4">

                      <div class="mt-5" [innerHTML]="agregarSaltosDeLinea(lineaOperacionPropuesta.presentacionCliente)"></div>
  
                    </div>
                </div>
            </div>
          </div>
          
        </ng-template>
        
        <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else lineaNoEditable">
          
          <div class="col-12">
            <div class="table-responsive">
              <table class="table table-sm table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" class="text-center bg-capital font-head-size py-1" rowspan="2">Producto</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1" colspan="4">Financiamiento Propuesto (M$)</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1" rowspan="2">%</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1" rowspan="2">Plazo (dias)</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1" colspan="3" rowspan="1">Colocacion (M$)</th>
                    </tr>
                    <tr>
                      <th scope="col" class="text-center bg-capital font-head-size py-1">Linea Vigente</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1">Linea Propuesta</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1">Op. Puntual Vigente</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1">Op. Puntual Propuesta</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1">Vigente</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1">Morosa</th>
                      <th scope="col" class="text-center bg-capital font-head-size py-1">Cancelada</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Facturas</th>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigentefacturas0' name='linea-lineaVigentefacturas0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.facturas" (appChangeKeyup)="handleEvent(['calcularTotalLineaVigente', 'cambiarFecha12'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestafacturas0' name='linea-lineaPropuestafacturas0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.facturas" (appChangeKeyup)="handleEvent(['calcularTotalLineaPropuesta', 'cambiarFecha12'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigentefacturas0' name='linea-opPuntualVigentefacturas0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.facturas" (appChangeKeyup)="handleEvent(['calcularTotalOpPuntualVigente', 'cambiarFecha12'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestafacturas0' name='linea-opPuntualPropuestafacturas0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.facturas" (appChangeKeyup)="handleEvent(['calcularTotalOpPuntualPropuesta', 'cambiarFecha12'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajefacturas0' name='linea-porcentajefacturas0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.facturas" (appChangeKeyup)="handleEvent(['cambiarFecha12'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiasfacturas0' name='linea-plazoDiasfacturas0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.facturas" (appChangeKeyup)="handleEvent(['cambiarFecha12'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigentefacturas0' name='linea-vigentefacturas0' [(ngModel)]="lineaOperacionPropuesta.vigente.facturas" (appChangeKeyup)="handleEvent(['calcularTotalVigente', 'cambiarFecha12'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestafacturas0' name='linea-propuestafacturas0' [(ngModel)]="lineaOperacionPropuesta.propuesta.facturas" (appChangeKeyup)="handleEvent(['calcularTotalPropuesta', 'cambiarFecha12'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladafacturas0' name='linea-canceladafacturas0' [(ngModel)]="lineaOperacionPropuesta.cancelada.facturas" (appChangeKeyup)="handleEvent(['calcularTotalCancelada', 'cambiarFecha12'])"></td>
                    </tr>
                    <tr>
                      <th>Fache</th>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigentefache0' name='linea-lineaVigentefache0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.fache" (appChangeKeyup)="handleEvent(['calcularTotalLineaVigente'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestafache0' name='linea-lineaPropuestafache0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.fache" (appChangeKeyup)="handleEvent(['calcularTotalLineaPropuesta'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigentefache0' name='linea-opPuntualVigentefache0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.fache" (appChangeKeyup)="handleEvent(['calcularTotalOpPuntualVigente'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestafache0' name='linea-opPuntualPropuestafache0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.fache" (appChangeKeyup)="handleEvent(['calcularTotalOpPuntualPropuesta'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajefache0' name='linea-porcentajefache0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.fache"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiasfache0' name='linea-plazoDiasfache0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.fache"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigentefache0' name='linea-vigentefache0' [(ngModel)]="lineaOperacionPropuesta.vigente.fache" (appChangeKeyup)="handleEvent(['calcularTotalVigente'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestafache0' name='linea-propuestafache0' [(ngModel)]="lineaOperacionPropuesta.propuesta.fache" (appChangeKeyup)="handleEvent(['calcularTotalPropuesta'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladafache0' name='linea-canceladafache0' [(ngModel)]="lineaOperacionPropuesta.cancelada.fache" (appChangeKeyup)="handleEvent(['calcularTotalCancelada'])"></td>
                    </tr>
                    <tr>
                      <th>Cheques</th>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigentecheques0' name='linea-lineaVigentecheques0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.cheques" (appChangeKeyup)="handleEvent(['calcularTotalLineaVigente'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestacheques0' name='linea-lineaPropuestacheques0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.cheques" (appChangeKeyup)="handleEvent(['calcularTotalLineaPropuesta'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigentecheques0' name='linea-opPuntualVigentecheques0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.cheques" (appChangeKeyup)="handleEvent(['calcularTotalOpPuntualVigente'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestacheques0' name='linea-opPuntualPropuestacheques0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.cheques" (appChangeKeyup)="handleEvent(['calcularTotalOpPuntualPropuesta'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajecheques0' name='linea-porcentajecheques0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.cheques"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiascheques0' name='linea-plazoDiascheques0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.cheques"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigentecheques0' name='linea-vigentecheques0' [(ngModel)]="lineaOperacionPropuesta.vigente.cheques" (appChangeKeyup)="handleEvent(['calcularTotalVigente'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestacheques0' name='linea-propuestacheques0' [(ngModel)]="lineaOperacionPropuesta.propuesta.cheques" (appChangeKeyup)="handleEvent(['calcularTotalPropuesta'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladacheques0' name='linea-canceladacheques0' [(ngModel)]="lineaOperacionPropuesta.cancelada.cheques" (appChangeKeyup)="handleEvent(['calcularTotalCancelada'])"></td>
                    </tr>
                    <tr>
                      <th>Confirming</th>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigenteconfirming0' name='linea-lineaVigenteconfirming0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.confirming" (appChangeKeyup)="handleEvent(['calcularTotalLineaVigente', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestaconfirming0' name='linea-lineaPropuestaconfirming0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.confirming" (appChangeKeyup)="handleEvent(['calcularTotalLineaPropuesta', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigenteconfirming0' name='linea-opPuntualVigenteconfirming0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.confirming" (appChangeKeyup)="handleEvent(['calcularTotalOpPuntualVigente', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestaconfirming0' name='linea-opPuntualPropuestaconfirming0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.confirming" (appChangeKeyup)="handleEvent(['calcularTotalOpPuntualPropuesta', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajeconfirming0' name='linea-porcentajeconfirming0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.confirming" (appChangeKeyup)="handleEvent(['cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiasconfirming0' name='linea-plazoDiasconfirming0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.confirming" (appChangeKeyup)="handleEvent(['cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigenteconfirming0' name='linea-vigenteconfirming0' [(ngModel)]="lineaOperacionPropuesta.vigente.confirming" (appChangeKeyup)="handleEvent(['calcularTotalVigente', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestaconfirming0' name='linea-propuestaconfirming0' [(ngModel)]="lineaOperacionPropuesta.propuesta.confirming" (appChangeKeyup)="handleEvent(['calcularTotalPropuesta', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladaconfirming0' name='linea-canceladaconfirming0' [(ngModel)]="lineaOperacionPropuesta.cancelada.confirming" (appChangeKeyup)="handleEvent(['calcularTotalCancelada', 'cambiarFecha6'])"></td>
                    </tr>
                    <tr>
                      <th>OC</th>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigenteoc0' name='linea-lineaVigenteoc0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.oc" (appChangeKeyup)="handleEvent(['calcularTotalLineaVigente', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestaoc0' name='linea-lineaPropuestaoc0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.oc" (appChangeKeyup)="handleEvent(['calcularTotalLineaPropuesta', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigenteoc0' name='linea-opPuntualVigenteoc0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.oc" (appChangeKeyup)="handleEvent(['calcularTotalOpPuntualVigente', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestaoc0' name='linea-opPuntualPropuestaoc0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.oc" (appChangeKeyup)="handleEvent(['calcularTotalOpPuntualPropuesta', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajeoc0' name='linea-porcentajeoc0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.oc" (appChangeKeyup)="handleEvent(['cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiasoc0' name='linea-plazoDiasoc0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.oc" (appChangeKeyup)="handleEvent(['cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigenteoc0' name='linea-vigenteoc0' [(ngModel)]="lineaOperacionPropuesta.vigente.oc" (appChangeKeyup)="handleEvent(['calcularTotalVigente', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestaoc0' name='linea-propuestaoc0' [(ngModel)]="lineaOperacionPropuesta.propuesta.oc" (appChangeKeyup)="handleEvent(['calcularTotalPropuesta', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladaoc0' name='linea-canceladaoc0' [(ngModel)]="lineaOperacionPropuesta.cancelada.oc" (appChangeKeyup)="handleEvent(['calcularTotalCancelada', 'cambiarFecha6'])"></td>
                    </tr>
                    <tr>
                      <th>Credito Directo</th>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigentecreditoDirecto0' name='linea-lineaVigentecreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.creditoDirecto" (appChangeKeyup)="handleEvent(['calcularTotalLineaVigente', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestacreditoDirecto0' name='linea-lineaPropuestacreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.creditoDirecto" (appChangeKeyup)="handleEvent(['calcularTotalLineaPropuesta', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigentecreditoDirecto0' name='linea-opPuntualVigentecreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.creditoDirecto" (appChangeKeyup)="handleEvent(['calcularTotalOpPuntualVigente', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestacreditoDirecto0' name='linea-opPuntualPropuestacreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.creditoDirecto" (appChangeKeyup)="handleEvent(['calcularTotalOpPuntualPropuesta', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajecreditoDirecto0' name='linea-porcentajecreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.creditoDirecto" (appChangeKeyup)="handleEvent(['cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiascreditoDirecto0' name='linea-plazoDiascreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.creditoDirecto" (appChangeKeyup)="handleEvent(['cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigentecreditoDirecto0' name='linea-vigentecreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.vigente.creditoDirecto" (appChangeKeyup)="handleEvent(['calcularTotalVigente', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestacreditoDirecto0' name='linea-propuestacreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.propuesta.creditoDirecto" (appChangeKeyup)="handleEvent(['calcularTotalPropuesta', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladacreditoDirecto0' name='linea-canceladacreditoDirecto0' [(ngModel)]="lineaOperacionPropuesta.cancelada.creditoDirecto" (appChangeKeyup)="handleEvent(['calcularTotalCancelada', 'cambiarFecha6'])"></td>
                    </tr>
                    <tr>
                      <th>Pronto Pago</th>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaVigenteprontoPago0' name='linea-lineaVigenteprontoPago0' [(ngModel)]="lineaOperacionPropuesta.lineaVigente.prontoPago" (appChangeKeyup)="handleEvent(['calcularTotalLineaVigente', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-lineaPropuestaprontoPago0' name='linea-lineaPropuestaprontoPago0' [(ngModel)]="lineaOperacionPropuesta.lineaPropuesta.prontoPago" (appChangeKeyup)="handleEvent(['calcularTotalLineaPropuesta', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualVigenteprontoPago0' name='linea-opPuntualVigenteprontoPago0' [(ngModel)]="lineaOperacionPropuesta.opPuntualVigente.prontoPago" (appChangeKeyup)="handleEvent(['calcularTotalOpPuntualVigente', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-opPuntualPropuestaprontoPago0' name='linea-opPuntualPropuestaprontoPago0' [(ngModel)]="lineaOperacionPropuesta.opPuntualPropuesta.prontoPago" (appChangeKeyup)="handleEvent(['calcularTotalOpPuntualPropuesta', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-porcentajeprontoPago0' name='linea-porcentajeprontoPago0' [(ngModel)]="lineaOperacionPropuesta.porcentaje.prontoPago" (appChangeKeyup)="handleEvent(['cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero" id='linea-plazoDiasprontoPago0' name='linea-plazoDiasprontoPago0' [(ngModel)]="lineaOperacionPropuesta.plazoDias.prontoPago" (appChangeKeyup)="handleEvent(['cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-vigenteprontoPago0' name='linea-vigenteprontoPago0' [(ngModel)]="lineaOperacionPropuesta.vigente.prontoPago" (appChangeKeyup)="handleEvent(['calcularTotalVigente', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-propuestaprontoPago0' name='linea-propuestaprontoPago0' [(ngModel)]="lineaOperacionPropuesta.propuesta.prontoPago" (appChangeKeyup)="handleEvent(['calcularTotalPropuesta', 'cambiarFecha6'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-canceladaprontoPago0' name='linea-canceladaprontoPago0' [(ngModel)]="lineaOperacionPropuesta.cancelada.prontoPago" (appChangeKeyup)="handleEvent(['calcularTotalCancelada', 'cambiarFecha6'])"></td>
                    </tr>
                    <tr>
                      <th class="bg-capital">Total Propuesta</th>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-totalesLineaVigente0' name='linea-totalesLineaVigente0' [(ngModel)]="lineaOperacionPropuestaTotales.totalesLineaVigente" (appChangeKeyup)="handleEvent(['calcularTotalLineaM', 'limpiaFecha'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-totalesLineaPropuesta0' name='linea-totalesLineaPropuesta0' [(ngModel)]="lineaOperacionPropuestaTotales.totalesLineaPropuesta" (appChangeKeyup)="handleEvent(['calcularTotalLineaM', 'limpiaFecha'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-totalesOpPuntualVigente0' name='linea-totalesOpPuntualVigente0' [(ngModel)]="lineaOperacionPropuestaTotales.totalesOpPuntualVigente" (appChangeKeyup)="handleEvent(['calcularOpPuntualM', 'limpiaFecha'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-totalesOpPuntualPropuesta0' name='linea-totalesOpPuntualPropuesta0' [(ngModel)]="lineaOperacionPropuestaTotales.totalesOpPuntualPropuesta" (appChangeKeyup)="handleEvent(['calcularOpPuntualM', 'limpiaFecha'])"></td>
                      <th class="bg-capital" colspan="2">Total</th>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-totalesVigente0' name='linea-totalesVigente0' [(ngModel)]="lineaOperacionPropuestaTotales.totalesVigente" (appChangeKeyup)="handleEvent(['limpiaFecha'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-totalesPropuesta0' name='linea-totalesPropuesta0' [(ngModel)]="lineaOperacionPropuestaTotales.totalesPropuesta" (appChangeKeyup)="handleEvent(['limpiaFecha'])"></td>
                      <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='linea-totalesCancelada0' name='linea-totalesCancelada0' [(ngModel)]="lineaOperacionPropuestaTotales.totalesCancelada" (appChangeKeyup)="handleEvent(['limpiaFecha'])"></td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
        
          <div class="col-md-12 col-lg-8">
            <div class="row"> 
                <div class="col-12 ml-4">
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 border text-center bg-capital font-head-size">
                            <span class="span-linea">Vencimiento</span>
                        </div>
                        <div class="col-sm-6 col-lg-3 border">
                          <input type="date" class="form-control form-control-sm p-0" id='linea-vencimiento0' name='linea-vencimiento0' [(ngModel)]="lineaOperacionPropuesta.vencimiento">
                        </div>
                    </div>
                </div>
                <div class="col-12 ml-4">
                  <div class="row d-flex flex-row-reverse">
                      <div class="col-sm-6 col-lg-3 border">
                        <input type="number" class="form-control form-control-sm p-0" title="Ingrese un numero entero o decimal" id='linea-opPuntualM0' name='linea-opPuntualM0' [(ngModel)]="lineaOperacionPropuesta.opPuntualM">
                      </div>
                      <div class="col-sm-6 col-lg-3 border text-center bg-capital font-head-size">
                          <span class="span-linea">Op. Puntual M$</span>
                      </div>
                    </div>
                </div>
                <div class="col-12 ml-4">
                    <div class="row">
                        <div class="col-sm-6 col-lg-3 border text-center bg-capital font-head-size">
                            <span class="span-linea">Total Linea M$</span>
                        </div>
                        <div class="col-sm-6 col-lg-3 border">
                          <input type="number" class="form-control form-control-sm p-0" title="Ingrese un numero entero o decimal" id='linea-totalLineaM0' name='linea-totalLineaM0' [(ngModel)]="lineaOperacionPropuesta.totalLineaM">
                        </div>
                        <div class="col-sm-6 col-lg-3 border text-center bg-capital font-head-size">
                          <span class="span-linea">Riesgo Total M$</span>
                        </div>
                        <div class="col-sm-6 col-lg-3 border">
                          <input type="number" class="form-control form-control-sm p-0" title="Ingrese un numero entero o decimal" id='linea-riesgoTotalM0' name='linea-riesgoTotalM0' [(ngModel)]="lineaOperacionPropuesta.riesgoTotalM">
                        </div>
                    </div>
                </div>
              </div>
          </div>
        
          <div class="col-12">
            <div class="row">
                <div class="col-12 ml-4 mt-4 border pl-0">
                    <div class="col-sm-12 col-md-6 col-lg-3 border text-center bg-capital font-head-size">
                        <span class="span-linea">Presentacion Cliente</span>
                    </div>
                    <div class="col-12 pb-4">
                      <textarea class="form-control mt-4" id="linea-presentacionCliente0" name="linea-presentacionCliente0" [(ngModel)]="lineaOperacionPropuesta.presentacionCliente" maxlength="500"></textarea>
                    </div>
                </div>
            </div>
          </div>

        </ng-container>

      </ng-container>
    </div>
  </div>

  <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak" (click)="show('iva', 'button-iva')">
    Información Financiera
  </h3>
  <div class="container-desplegables" id="iva">
    <div class="row p-5">
      <div class="col-sm-12">
        <div class="table-responsive" *ngIf="showReportes">
          <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col" class="py-1 bg-capital font-head-size" colspan="3">Índices Último Balance</th>
                  <th scope="col" class="py-1 bg-capital font-head-size" colspan="2">Venta Promedio Mensual M$</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" colspan="2" rowspan="2">Morosidades Protestos</th>
                </tr>
                <tr>
                  <th scope="col" class="py-1 bg-capital font-head-size">Patrimonio M$</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Leverage</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Utilidad M$</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Ene-Dic</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Ene a la fecha</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{{informacionFinancieraCabecera.dic || ''}}</th>
                  <th>{{informacionFinancieraCabecera.dic || ''}}</th>
                  <th>{{informacionFinancieraCabecera.dic || ''}}</th>
                  <th>{{informacionFinancieraCabecera.anioAnterior || ''}}</th>
                  <th>{{informacionFinancieraCabecera.anioActual || ''}}</th>
                  <th>N°</th>
                  <th>Monto M$</th>
                </tr>
                <tr>
                  <td>{{ informacionFinancieraCabecera.patrimonio !== ''? ((informacionFinancieraCabecera.patrimonio / 1000) | number: '1.0-0'): informacionFinancieraCabecera.patrimonio}}</td>
                  <td>{{ informacionFinancieraCabecera.leverage | number: '1.0-2'}}</td>
                  <td>{{ informacionFinancieraCabecera.utilidad !== ''? ((informacionFinancieraCabecera.utilidad / 1000) | number: '1.0-0'): informacionFinancieraCabecera.utilidad}}</td>
                  <td>{{ totalesInformacionFinanciera?.totalAnterior?.TotalProm?.ventas || 0 | number: '1.0-2'}}</td>
                  <td>{{ totalesInformacionFinanciera?.totalActual?.TotalProm?.ventas || 0 | number: '1.0-2'}}</td>
                  <td class="pointer-cursor" (click)="abrirModalTitular()">{{ informacionFinancieraCabecera.totalImpagoCant | number: '1.0-0'}}</td>
                  <td class="pointer-cursor" (click)="abrirModalTitular()">{{ informacionFinancieraCabecera.totalImpagoMonto | number: '1.0-2'}}</td>
                </tr>
              </tbody>
          </table>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="table-responsive" *ngIf="showReportes && totalesInformacionFinanciera && informacionFinancieraTable.length > 0">
          <table class="table table-hover table-bordered">
            <thead>
              <tr>
                <th scope="col" class="text-center bg-capital font-head-size">Año</th>
                <ng-container *ngIf="totalesInformacionFinanciera.totalAnterior.anio">
                  <th scope="col" class="text-center bg-capital font-head-size" colspan="5">{{totalesInformacionFinanciera.totalAnterior.anio}}</th>
                  <th scope="col" class="text-center bg-capital font-head-size" rowspan="2">Posterga</th>
                </ng-container>
                <ng-container *ngIf="totalesInformacionFinanciera.totalActual.anio">
                  <th scope="col" class="text-center bg-capital font-head-size" colspan="5">{{totalesInformacionFinanciera.totalActual.anio}}</th>
                  <th scope="col" class="text-center bg-capital font-head-size" rowspan="2">Posterga</th>
                </ng-container>
              </tr>
              <tr>
                <th scope="col" class="text-center bg-capital font-head-size">Meses</th>
                <ng-container *ngIf="totalesInformacionFinanciera.totalAnterior.anio">
                  <th scope="col" class="text-center bg-capital font-head-size">Ventas (M$)</th>
                  <th scope="col" class="text-center bg-capital font-head-size">Compras (M$)</th>
                  <th scope="col" class="text-center bg-capital font-head-size">Diferencia (M$)</th>
                  <th scope="col" class="text-center bg-capital font-head-size">Fecha Pago</th>
                  <th scope="col" class="text-center bg-capital font-head-size">Tipo Declaración</th>
                </ng-container>
                
                <ng-container *ngIf="totalesInformacionFinanciera.totalActual.anio">
                  <th scope="col" class="text-center bg-capital font-head-size">Ventas (M$)</th>
                  <th scope="col" class="text-center bg-capital font-head-size">Compras (M$)</th>
                  <th scope="col" class="text-center bg-capital font-head-size">Diferencia (M$)</th>
                  <th scope="col" class="text-center bg-capital font-head-size">Fecha Pago</th>
                  <th scope="col" class="text-center bg-capital font-head-size">Tipo Declaración</th>               
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of informacionFinancieraTable; let i = index">
                <tr>
                  <td>{{item?.anterior?.mes || item?.actual?.mes || ''}}</td>
                  <td>{{(item?.anterior?.ventas || item?.anterior?.ventas == 0) ? (item?.anterior?.ventas | number: '1.0-0') : ('')}}</td>
                  <td>{{(item?.anterior?.compras || item?.anterior?.compras == 0) ? (item?.anterior?.compras | number: '1.0-0') : ('')}}</td>
                  <td>{{(item?.anterior?.diferencia || item?.anterior?.diferencia == 0) ? (item?.anterior?.diferencia | number: '1.0-0') : ('')}}</td>
                  <td>{{item?.anterior?.fechaPago || ''}}</td>
                  <td>{{item?.anterior?.tipoDeclaracion || ''}}</td>
                  <td>{{item?.anterior?.posterga || '' | number: '1.0-0'}}</td>
                  <td>{{(item?.actual?.ventas || item?.actual?.ventas == 0) ? (item?.actual?.ventas | number: '1.0-0') : ('')}}</td>
                  <td>{{(item?.actual?.compras || item?.actual?.compras == 0) ? (item?.actual?.compras | number: '1.0-0') : ('')}}</td>
                  <td>{{(item?.actual?.diferencia || item?.actual?.diferencia == 0) ? (item?.actual?.diferencia | number: '1.0-0') : ('')}}</td>
                  <td>{{item?.actual?.fechaPago || ''}}</td>
                  <td>{{item?.actual?.tipoDeclaracion || ''}}</td>
                  <td>{{item?.actual?.posterga || '' | number: '1.0-0'}}</td>
                </tr>
                <ng-container *ngIf="i === 5">
                  <tr>
                    <th class="bg-capital">Total Sem.</th>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem1?.ventas || 0 | number: '1.0-0'}}</td>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem1?.compras || 0 | number: '1.0-0'}}</td>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem1?.dif || 0 | number: '1.0-0'}}</td>
                    <td class="bg-capital"></td>
                    <td class="bg-capital"></td>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem1?.posterga || '' | number: '1.0-0'}}</td>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem1?.ventas || 0 | number: '1.0-0'}}</td>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem1?.compras || 0 | number: '1.0-0'}}</td>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem1?.dif || 0 | number: '1.0-0'}}</td>
                    <td class="bg-capital"></td>
                    <td class="bg-capital"></td>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem1?.posterga || '' | number: '1.0-0'}}</td>
                  </tr>
                </ng-container>

              </ng-container>
              <tr>
                <th class="bg-capital">Total Sem.</th>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem2?.ventas || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem2?.compras || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem2?.dif || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem2?.posterga || '' | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem2?.ventas || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem2?.compras || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem2?.dif || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem2?.posterga || '' | number: '1.0-0'}}</td>
              </tr>
              <tr>
                <th class="bg-capital">Total Año</th>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.ventas || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.compras || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.dif || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.posterga || '' | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.ventas || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.compras || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.dif || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.posterga || '' | number: '1.0-0'}}</td>
              </tr>
              <tr>
                <th class="bg-capital">Promedio</th>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.ventas || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.compras || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.dif || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.posterga || '' | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.ventas || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.compras || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.dif || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.posterga || '' | number: '1.0-0'}}</td>
              </tr>
              <tr>
                <th class="bg-capital">Margen %</th>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.margen || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.margen || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    
  </div>

  <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('balance-manuales', 'button-balance-manuales')"
    [ngClass]="{'pagebreak': dataManualCargada}"
  >
    Balance Manual
  </h3>
  <div class="container-desplegables" id="balance-manuales">
    <div class="row p-5">
      <div class="col-12">
        <app-balance-automatico-capital-express-workflow *ngIf="dataManualCargada" #appinformef22viewManual [reporteFullContent]="balanceManual" [rut]="rut"></app-balance-automatico-capital-express-workflow>
        <span *ngIf="!dataManualCargada">
          <h4>No se encuentran datos en los servicios consultados.</h4>
        </span>
      </div>
    </div>
  </div>

  <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('balance-automaticos', 'button-balance-automaticos')"
    [ngClass]="{'pagebreak': dataCargada}"
  >
    Balance Automatico
  </h3>
  <div class="container-desplegables" id="balance-automaticos">
    <div class="row p-5">
      <div class="col-12">
        <app-balance-automatico-capital-express-workflow *ngIf="dataCargada" #appinformef22view [reporteFullContent]="dataFiltrada" [rut]="rut"></app-balance-automatico-capital-express-workflow>
        <span *ngIf="!dataCargada">
          <h4>No se encuentran datos en los servicios consultados.</h4>
        </span>
      </div>
    </div>
  </div>

  <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('deudores', 'button-deudores')">
    Deudores
  </h3>
  <div class="container-desplegables" id="deudores">
      <div class="row p-5">
        <div class="col-sm-12">
          <form [formGroup]="servicioDeudoresForm">

            <ng-template #fichaCabeceraDeudores>

              <div class="col-sm-12">
                <div class="form-group row">
                    <label class="col-4 col-form-label">Cliente</label>
                    <div class="col-8">
                      <span>{{servicioDeudoresForm?.controls?.clienteComite?.value || ''}}</span>
                    </div>
                  </div>
              </div>

              <div class="col-sm-12">
                <div class="form-group row">
                    <label class="col-4 col-form-label">Rut</label>
                    <div class="col-8">
                      <span>{{servicioDeudoresForm?.controls?.rutClienteComite?.value || ''}}</span>
                    </div>
                  </div>
              </div>

              <div class="col-sm-12">
                <div class="form-group row">
                    <label class="col-4 col-form-label">Fecha</label>
                    <div class="col-8">
                      <span>{{servicioDeudoresForm?.controls?.fechaClienteComite?.value || ''}}</span>
                    </div>
                  </div>
              </div>

            </ng-template>
            

            <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else fichaCabeceraDeudores">

              <div class="col-sm-12">
                <div class="form-group row">
                    <label for="cliente-comite" class="col-4 col-form-label">Cliente</label>
                    <div class="col-8">
                      <input type="text" class="form-control" id="cliente-comite" formControlName="clienteComite">
                      <div class="w-100">
                        <app-error [control]="servicioDeudoresForm.get('clienteComite')"></app-error>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="col-sm-12">
                  <div class="form-group row">
                      <label for="rut-cliente-comite" class="col-4 col-form-label">Rut</label>
                      <div class="col-8">
                        <input type="text" class="form-control" id="rut-cliente-comite" formControlName="rutClienteComite">
                        <div class="w-100">
                          <app-error [control]="servicioDeudoresForm.get('rutClienteComite')"></app-error>
                        </div>
                      </div>
                  </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group row">
                    <label for="fecha-cliente-comite" class="col-4 col-form-label">Fecha</label>
                    <div class="col-8">
                      <input type="text" class="form-control" id="fecha-cliente-comite" formControlName="fechaClienteComite">
                      <div class="w-100">
                        <app-error [control]="servicioDeudoresForm.get('fechaClienteComite')"></app-error>
                      </div>
                    </div>
                  </div>
              </div>

            </ng-container>

          </form>
        </div>

        <div class="col-sm-12 my-3">
          <div class="table-responsive" *ngIf="showReportes">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" rowspan="2">N</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" rowspan="2">Nombre</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" rowspan="2">Rut</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" colspan="3">Monto (M$)</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" colspan="2">Informes Comerciales</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size">D</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" rowspan="2">Rango SII</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" rowspan="2">Web</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" rowspan="2">Historico Cancelado con Capex MM$</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" rowspan="2">Dias promedio de mora</th>
                </tr>
                <tr>
                  <th scope="col" class="py-1 bg-capital font-head-size">Facturas</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Factura a Financiar</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Cheques</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">N°</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Monto M$</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">M$</th>
                </tr>
              </thead>
              <tbody>

                <ng-template #deudoresNoEditable>

                  <ng-container *ngFor="let item of deudores; let i = index">
                    <tr class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">
                      <td>{{(i + 1)}}</td>
                      <td>{{deudores[i].nombre}}</td>
                      <td class="text-nowrap">{{deudores[i].rut | rutFormat}}</td>
                      <td>{{deudores[i].facturas !== ''? ((deudores[i].facturas / 1000) | number: '1.0-0'): deudores[i].facturas}}</td>
                      <td>{{stringToFloat(deudores[i].facturaFinanciar) | number: '1.0-2'}}</td>
                      <td>{{stringToFloat(deudores[i].cheques) | number: '1.0-2'}}</td>
                      <td>{{deudores[i].numero | number: '1.0-0'}}</td>
                      <td>{{deudores[i].monto | number: '1.0-2'}}</td>
                      <td>{{deudores[i].miles !== '-'? ( deudores[i].miles | number: '1.0-2'): deudores[i].miles}}</td>
                      <td>{{deudores[i].rangoSii}}</td>
                      <td>{{deudores[i].web}}</td>
                      <td>{{stringToFloat(deudores[i].histCancelado) | number: '1.0-2'}}</td>
                      <td>{{stringToFloat(deudores[i].diasPromedio) | number: '1.0-2'}}</td>
                    </tr>
                  </ng-container>

                </ng-template>

                <ng-template #sinDatosDeudores>
                  <tr class="height-row">
                    <td colspan="13">Sin información</td>
                  </tr>
                </ng-template>
                  
                <ng-container *ngIf="deudores.length > 0 else sinDatosDeudores">

                  <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else deudoresNoEditable">
                    
                    <ng-container *ngFor="let item of deudores; let i = index">
                      <tr>
                        <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{(i + 1)}}</td>
                        <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].nombre}}</td>
                        <td class="pointer-cursor text-nowrap" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].rut | rutFormat}}</td>
                        <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].facturas !== ''? ((deudores[i].facturas / 1000) | number: '1.0-0'): deudores[i].facturas}}</td>
                        <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='deudor-facturaFinanciar{{i}}' name='deudor-facturaFinanciar{{i}}' [(ngModel)]="deudores[i].facturaFinanciar"></td>
                        <td><input type="text" class="form-control" title="Ingrese un numero entero o decicmal" id='deudor-cheques{{i}}' name='deudor-cheques{{i}}' [(ngModel)]="deudores[i].cheques"></td>
                        <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].numero | number: '1.0-0'}}</td>
                        <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].monto | number: '1.0-2'}}</td>
                        <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].miles !== '-'? ( deudores[i].miles | number: '1.0-2'): deudores[i].miles}}</td>
                        <td class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">{{deudores[i].rangoSii}}</td>
                        <td><input type="text" class="form-control" id='deudor-web{{i}}' name='deudor-web{{i}}' [(ngModel)]="deudores[i].web"></td>
                        <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='deudor-histCancelado{{i}}' name='deudor-histCancelado{{i}}' [(ngModel)]="deudores[i].histCancelado"></td>
                        <td><input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='deudor-diasPromedio{{i}}' name='deudor-diasPromedio{{i}}' [(ngModel)]="deudores[i].diasPromedio"></td>
                      </tr>
                    </ng-container>

                  </ng-container>

                </ng-container>

              </tbody>
            </table>
          </div>
        </div>

        <div class="col-sm-12 my-3">
          <div class="table-responsive" *ngIf="showReportes">
            <table class="table table-hover table-bordered border">
              <thead>
                <tr>
                  <th scope="col" colspan="5" class="text-center py-1 bg-capital font-head-size">Factoring con que opera</th>
                </tr>
                <tr>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" rowspan="2">Rut</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" rowspan="2">Nombre</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" rowspan="1" colspan="3">Monto Cesiones en M$</th>
                </tr>
                <tr>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" rowspan="1">3 Meses</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" rowspan="1">6 Meses</th>
                  <th scope="col" class="text-center py-1 bg-capital font-head-size" rowspan="1">12 Meses</th>
                </tr>
              </thead>
              <tbody>
                <ng-template #sinDatosFactoring>
                  <tr class="height-row">
                    <td colspan="5">Sin información</td>
                  </tr>
                </ng-template>

                <ng-container *ngIf="factoring.length > 0 else sinDatosFactoring">
                  <ng-container *ngFor="let item of factoring; let i = index">
                    <tr>
                      <td>{{item.CESIONARIO | rutFormat}}</td>
                      <td>{{item.rz_cesionario}}</td>
                      <td>{{item.monto3Meses !== ''? ((item.monto3Meses / 1000) | number: '1.0-0'): item.monto3Meses}}</td>
                      <td>{{item.monto6Meses !== ''? ((item.monto6Meses / 1000) | number: '1.0-0'): item.monto6Meses}}</td>
                      <td>{{item.Monto12Meses !== ''? ((item.Monto12Meses / 1000) | number: '1.0-0'): item.Monto12Meses}}</td>
                    </tr>
                  </ng-container>
                </ng-container>

              </tbody>
            </table>
          </div>
        </div>

        <div class="col-sm-12 my-3">
          <div class="table-responsive" *ngIf="showReportes">
            <table class="table table-hover border">
              <thead>
                <tr>
                  <th scope="col" colspan="5" class="text-center py-1 bg-capital font-head-size">Observaciones</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ng-template #observacionesNoEditable>

                      <div [innerHTML]="agregarSaltosDeLinea(observaciones.text)"></div>

                    </ng-template>

                    <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else observacionesNoEditable">

                      <textarea class="form-control" id="deudores-observaciones" name="deudores-observaciones" [(ngModel)]="observaciones.text" maxlength="500"></textarea>
                    
                    </ng-container>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-sm-12 my-3">
          <div class="table-responsive" *ngIf="showReportes && tablasCompraVenta?.hasData === true">
            <table class="table table-hover table-bordered border">
              <thead>
                <tr>
                  <th scope="col" colspan="4" class="text-center py-1 bg-capital font-head-size">Libro Compra Venta 
                    <i class="fa fa-info-circle pointer-cursor ml-1 not-print" aria-hidden="true" (click)="showDetalleFechas()" *ngIf="fechasCompraVenta.length > 0" title="Periodos Reporte Libro Compra Venta"></i>
                  </th>
                </tr>
                <tr>
                  <th scope="col" colspan="2" width="50%" class="text-center py-1 bg-capital font-head-size">Montos Totales</th>
                  <th scope="col" colspan="2" width="50%" class="text-center py-1 bg-capital font-head-size">Registros Totales</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Compras</td>
                  <td>{{tablasCompraVenta?.totalCompras | number: '1.0-0'}}</td>
                  <td>Compras</td>
                  <td>{{tablasCompraVenta?.totalRegistrosCompras | number: '1.0-0'}}</td>
                </tr>
                <tr>
                  <td>Ventas</td>
                  <td>{{tablasCompraVenta?.totalVentas | number: '1.0-0'}}</td>
                  <td>Ventas</td>
                  <td>{{tablasCompraVenta?.totalRegistrosVentas | number: '1.0-0'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
      </div>
  </div>

  <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak" (click)="show('carga-financiera', 'button-carga-financiera')">
    Carga Financiera
  </h3>
  <div class="container-desplegables" id="carga-financiera">
    <div class="row p-5">
      <div class="col-sm-12">
        <div class="table-responsive" *ngIf="showReportes">

          <ng-template #cargaFinancieraNoEditable>

            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col" class="py-1 bg-capital font-head-size" colspan="1">Nombre Cliente</th>
                  <th scope="col" class="py-1 font-head-size" colspan="3">
                    {{nombreClienteCargaFinanciera}}
                  </th>
                </tr>
                <tr>
                  <th scope="col" class="py-1 bg-capital font-head-size">Fecha de Curse</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Institucion</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Tipo de Credito</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Credito Original M$</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Deuda Actual M$</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Cuota M$</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">N° Cuotas por pagar</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">N° Cuotas Morosas</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Ultimo Vencimiento</th>
                  <th scope="col" class="py-1 bg-capital font-head-size">Garantias Vigentes</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of cargaFinanciera; let i = index">
                  <tr>
                    <td>{{cargaFinanciera[i].fechaCurse | date: 'dd-MM-yyyy'}}</td>
                    <td>{{cargaFinanciera[i].institucion}}</td>
                    <td>{{cargaFinanciera[i].tipoCredito}}</td>
                    <td>{{stringToFloat(cargaFinanciera[i].creditoOriginal) | number: '1.0-2'}}</td>
                    <td>{{stringToFloat(cargaFinanciera[i].deudaActual) | number: '1.0-2'}}</td>
                    <td>{{stringToFloat(cargaFinanciera[i].cuota) | number: '1.0-2'}}</td>
                    <td>{{cargaFinanciera[i].nCuotasPorPagar | number: '1.0-0'}}</td>
                    <td>{{cargaFinanciera[i].nCuotasMorosas | number: '1.0-0'}}</td>
                    <td>{{cargaFinanciera[i].ultimoVencimiento | date: 'dd-MM-yyyy'}}</td>
                    <td>{{cargaFinanciera[i].garantiasVigentes}}</td>
                  </tr>
                </ng-container>
                <tr>
                  <th colspan="3">TOTAL</th>
                  <td>{{calcularTotalCreditoOriginal() || 0 | number: '1.0-2'}}</td>
                  <td>{{calcularTotalDeudaActual() || 0 | number: '1.0-2'}}</td>
                  <td>{{calcularTotalCuota() || 0 | number: '1.0-2'}}</td>
                  <td>{{calcularTotalNCuotasPorPagar() || 0 | number: '1.0-0'}}</td>
                  <td>{{calcularTotalNCuotasMorosas() || 0 | number: '1.0-0'}}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

          </ng-template>

          <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else cargaFinancieraNoEditable">

            <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col" class="py-1 bg-capital font-head-size" colspan="2">Nombre Cliente</th>
                    <th scope="col" class="py-1 font-head-size" colspan="3">
                      <input type="text" class="form-control" id='carga-nombreClienteCargaFinanciera' name='carga-nombreClienteCargaFinanciera' [(ngModel)]="nombreClienteCargaFinanciera">
                    </th>
                  </tr>
                  <tr>
                    <th scope="col" class="py-1 bg-capital font-head-size text-center">
                      <button type="button" class="btn btn-success btn-mas" (click)="addRowCargaFinanciera()" title="Agregar Fila">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </th>
                    <th scope="col" class="py-1 bg-capital font-head-size">Fecha de Curse</th>
                    <th scope="col" class="py-1 bg-capital font-head-size">Institucion</th>
                    <th scope="col" class="py-1 bg-capital font-head-size">Tipo de Credito</th>
                    <th scope="col" class="py-1 bg-capital font-head-size">Credito Original M$</th>
                    <th scope="col" class="py-1 bg-capital font-head-size">Deuda Actual M$</th>
                    <th scope="col" class="py-1 bg-capital font-head-size">Cuota M$</th>
                    <th scope="col" class="py-1 bg-capital font-head-size">N° Cuotas por pagar</th>
                    <th scope="col" class="py-1 bg-capital font-head-size">N° Cuotas Morosas</th>
                    <th scope="col" class="py-1 bg-capital font-head-size">Ultimo Vencimiento</th>
                    <th scope="col" class="py-1 bg-capital font-head-size">Garantias Vigentes</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of cargaFinanciera; let i = index">
                    <tr>
                    <td class="text-center">
                      <button type="button" class="btn btn-danger btn-mas" (click)="deleteRowCargaFinanciera(i)" title="Quitar Fila">
                      <i class="fa fa-minus" aria-hidden="true"></i>
                      </button>
                    </td>
                    <td><input type="date" class="form-control form-control-xs" id='carga-fechaCurse{{i}}' name='carga-fechaCurse{{i}}' [(ngModel)]="cargaFinanciera[i].fechaCurse"></td>
                    <td><input type="text" class="form-control" id='carga-institucion{{i}}' name='carga-institucion{{i}}' [(ngModel)]="cargaFinanciera[i].institucion"></td>
                    <td><input type="text" class="form-control" id='carga-tipoCredito{{i}}' name='carga-tipoCredito{{i}}' [(ngModel)]="cargaFinanciera[i].tipoCredito"></td>
                    <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='carga-creditoOriginal{{i}}' name='carga-creditoOriginal{{i}}' [(ngModel)]="cargaFinanciera[i].creditoOriginal"></td>
                    <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='carga-deudaActual{{i}}' name='carga-deudaActual{{i}}' [(ngModel)]="cargaFinanciera[i].deudaActual"></td>
                    <td><input type="number" class="form-control" title="Ingrese un numero entero o decimal" id='carga-cuota{{i}}' name='carga-cuota{{i}}' [(ngModel)]="cargaFinanciera[i].cuota"></td>
                    <td><input type="number" class="form-control" title="Ingrese un numero entero" id='carga-nCuotasPorPagar{{i}}' name='carga-nCuotasPorPagar{{i}}' [(ngModel)]="cargaFinanciera[i].nCuotasPorPagar"></td>
                    <td><input type="number" class="form-control" title="Ingrese un numero entero" id='carga-nCuotasMorosas{{i}}' name='carga-nCuotasMorosas{{i}}' [(ngModel)]="cargaFinanciera[i].nCuotasMorosas"></td>
                    <td><input type="date" class="form-control form-control-xs" id='carga-ultimoVencimiento{{i}}' name='carga-ultimoVencimiento{{i}}' [(ngModel)]="cargaFinanciera[i].ultimoVencimiento"></td>
                    <td><input type="text" class="form-control" id='carga-garantiasVigentes{{i}}' name='carga-garantiasVigentes{{i}}' [(ngModel)]="cargaFinanciera[i].garantiasVigentes"></td>
                    </tr>
                  </ng-container>
                  <tr>
                    <th colspan="4">TOTAL</th>
                    <td>{{calcularTotalCreditoOriginal() || 0 | number: '1.0-2'}}</td>
                    <td>{{calcularTotalDeudaActual() || 0 | number: '1.0-2'}}</td>
                    <td>{{calcularTotalCuota() || 0 | number: '1.0-2'}}</td>
                    <td>{{calcularTotalNCuotasPorPagar() || 0 | number: '1.0-0'}}</td>
                    <td>{{calcularTotalNCuotasMorosas() || 0 | number: '1.0-0'}}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
            </table>

          </ng-container>

        </div>
      </div>

      <ng-container *ngIf="showReportes">

        <div class="col-sm-12">
          <table class="table table-hover table-bordered">
            <tbody>
              <tr>
                <td><b>Fecha de curse: </b>Es la fecha en que fue tomado el crédito con el banco.</td>
              </tr>
              <tr>
                <td><b>Tipo de Credito: </b>Aquí se debe decir si es un crédito en cuotas (mensuales o annuales), crédito a plazo fijo, Boleta de garantía o carta de crédito, línea de capital de trabajo, Leasing, etc.</td>
              <tr>
                <td><b>Credito Original: </b>Es el monto por el cual fue tomado el crédito.</td>
              </tr>
              <tr>
                <td><b>Deuda Actual: </b>Es lo que queda por pagar del crédito.</td>
              </tr>
              <tr>
                <td><b>Cuota: </b>Es lo que paga mensual o anualmente según el tipo de crédito.</td>
              </tr>
              <tr>
                <td><b>Cuotas Faltantes: </b>Son las cuotas que quedan por pagar.</td>
              </tr>
              <tr>
                <td><b>Vencimiento: </b>Es la fecha en que el crédito se termina, es cuando se paga la última cuota.</td>
              </tr>
              <tr>
                <td><b>Garantias Vigentes: </b>Se debe indicar si el crédito fue tomado solo con avales o con alguna prenda o hipoteca, de ser una de las dos ultimas indicar el valor en M$.</td>
              </tr>
            </tbody>
          </table>
        </div>

      </ng-container>
      
    </div>
  </div>

  <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak no-print" (click)="show('presentacion', 'button-presentacion')">
    Presentación Cliente
  </h3>
  <div class="container-desplegables no-print" id="presentacion">

    <div class="row px-5" *ngIf="stepper !== 4 && showReportes">
      <div class="col-sm-12">
        <div class="btn-option">
          <button [printTitle]='"PresentacionCliente_FlujoDeCredito_"+(rut | rutFormat)+"_"+today'
            [useExistingCss]="true" printSectionId="print-presentacion" ngxPrint
            class="btn btn-primary float-right">
            Descargar Presentación
          </button>
        </div>
      </div>
    </div>

    <div class="row p-5" id="print-presentacion">

      <div class="col-sm-12 col-md-10 mx-auto my-4 text-center" *ngIf="showReportes">
        <h2>Razón Social: {{servicioFichaForm?.controls?.nombreComite?.value || ''}}</h2>
        <h3>Empresa Constituida: {{servicioFichaForm?.controls?.inicioSiiComite?.value || ''}}</h3>
        <h3>Periodo: {{informacionFinancieraCabecera.anioActualAbreviado || ''}}</h3>
      </div>

      <div class="col-sm-12 my-3" *ngIf="showReportes">

        <div class="row">

          <div class="col-md-2 col-sm-4 mx-auto mb-3">
            <div class="card border-0 size-cuadros mx-auto">
              <div class="card-body bg-capital rounded shadow text-center">
                <h3>
                  {{ dividirValor(totalesInformacionFinanciera?.totalActual?.TotalProm?.ventas, 1000) ? 
                    ('$' + (dividirValor(totalesInformacionFinanciera?.totalActual?.TotalProm?.ventas, 1000) | number: '1.0-2') + 'M') : '' }}
                </h3>
                <h4>Venta Promedio Mensual</h4>
              </div>
            </div>
          </div>

          <div class="col-md-2 col-sm-4 mx-auto mb-3">
            <div class="card border-0 size-cuadros mx-auto">
              <div class="card-body bg-capital rounded shadow text-center">
                <h3>
                  {{ dividirValor(informacionFinancieraCabecera.patrimonio, 1000000) ? 
                  ('$' + (dividirValor(informacionFinancieraCabecera.patrimonio, 1000000) | number: '1.0-2') + 'MM') : '' }}
                </h3>
                <h4>Patrimonio</h4>
              </div>
            </div>
          </div>

          <div class="col-md-2 col-sm-4 mx-auto mb-3">
            <div class="card border-0 size-cuadros mx-auto">
              <div class="card-body bg-capital rounded shadow text-center">
                <h3>{{ informacionFinancieraCabecera.leverage }}</h3>
                <h4>Leverage</h4>
              </div>
            </div>
          </div>

          <div class="col-md-2 col-sm-4 mx-auto mb-3">
            <div class="card border-0 size-cuadros mx-auto">
              <div class="card-body bg-capital rounded shadow text-center pointer-cursor" (click)="abrirModalTitular()">
                <h3>
                  {{ dividirValor(informacionFinancieraCabecera.totalImpagoMonto, 1000) ? 
                    ('$' + (dividirValor(informacionFinancieraCabecera.totalImpagoMonto, 1000) | number: '1.0-2') + 'M') : '' }}
                  </h3>
                <h4>Dicom</h4>
              </div>
            </div>
          </div>

          <div class="col-md-2 col-sm-4 mx-auto mb-3">
            <div class="card border-0 size-cuadros mx-auto">
              <div class="card-body bg-capital rounded shadow text-center">
                <h3>
                  {{ dividirValor(informacionFinancieraCabecera.utilidad, 1000000) ? 
                    ('$' + (dividirValor(informacionFinancieraCabecera.utilidad, 1000000) | number: '1.0-2') + 'MM') : '' }}
                </h3>
                <h4>Utilidad {{informacionFinancieraCabecera.anioActualAbreviado || ''}}</h4>
              </div>
            </div>
          </div>
        
        </div>

      </div>

      <div class="col-sm-12 col-md-6" *ngIf="showReportes">
        <div class="table-responsive">
          <table class="table table-hover table-bordered border">
              <thead>
                <tr>
                  <th scope="col" colspan="5" class="text-center py-1 bg-capital font-head-size">Malla Societaria</th>
                </tr>
                <tr>
                  <th scope="col" class="py-1 bg-capital font-head-size width-td-name-resumen">Socio</th>
                  <th scope="col" class="py-1 bg-capital font-head-size width-td-porc-resumen">Rut</th>
                  <th scope="col" class="py-1 bg-capital font-head-size width-td-porc-resumen">%</th>
                  <th scope="col" class="py-1 bg-capital font-head-size width-td-porc-resumen">FS</th>
                  <th scope="col" class="py-1 bg-capital font-head-size width-td-porc-resumen">Dicom M$</th>
                </tr>
              </thead>
              <tbody>

                <ng-template #sinDatosSocios>
                  <tr class="height-row">
                    <td colspan="6">Sin información</td>
                  </tr>
                </ng-template>

                <ng-container *ngIf="sociosComposicion.length > 0 else sinDatosSocios">
                  
                  <ng-container *ngFor="let item of sociosComposicion; let i = index">
                    <tr class="height-row pointer-cursor" (click)="abrirModal(sociosInformes[i].entidad, sociosComposicion[i].rut)">
                      <td>{{sociosComposicion[i].socio}}</td>
                      <td class="text-nowrap">{{sociosComposicion[i].rut | rutFormat}}</td>
                      <td>{{stringToFloat(sociosComposicion[i].porcentaje) | number: '1.0-2'}}</td>
                      <td>{{sociosComposicion[i].fs}}</td>
                      <td>{{sociosInformes[i].monto | number: '1.0-2'}}</td>
                    </tr>
                    <tr class="height-row" *ngIf="sociosInformes[i].direccion">
                      <td>{{sociosComposicion[i].direccion}}</td>
                    </tr>
                  </ng-container>

                </ng-container>
                
              </tbody>
              <thead>
                <tr>
                  <th scope="col" class="py-1 bg-capital font-head-size width-td-name-resumen">Empresas Relacionadas</th>
                  <th scope="col" class="py-1 bg-capital font-head-size width-td-porc-resumen">Rut</th>
                  <th scope="col" class="py-1 bg-capital font-head-size width-td-porc-resumen">%</th>
                  <th scope="col" class="py-1 bg-capital font-head-size width-td-porc-resumen">FS</th>
                  <th scope="col" class="py-1 bg-capital font-head-size width-td-porc-resumen">Dicom M$</th>
                </tr>
              </thead>
              <tbody>

                <ng-template #sinDatosEmpRelacionadas>
                  <tr class="height-row">
                    <td colspan="6">Sin información</td>
                  </tr>
                </ng-template>

                <ng-container *ngIf="empresasRelacionadasComposicion.length > 0 else sinDatosEmpRelacionadas">
                  
                  <ng-container *ngFor="let item of empresasRelacionadasComposicion; let i = index">
                    <tr class="height-row pointer-cursor" (click)="abrirModal(empresasRelacionadasInformes[i].entidad, empresasRelacionadasComposicion[i].rut)">
                      <td>{{empresasRelacionadasComposicion[i].socio}}</td>
                      <td class="text-nowrap">{{empresasRelacionadasComposicion[i].rut | rutFormat}}</td>
                      <td>{{stringToFloat(empresasRelacionadasComposicion[i].porcentaje) | number: '1.0-2'}}</td>
                      <td>{{empresasRelacionadasComposicion[i].fs}}</td>
                      <td>{{empresasRelacionadasInformes[i].monto | number: '1.0-2'}}</td>
                    </tr>
                  </ng-container>

                </ng-container>
                
              </tbody>
          </table>
        </div>
      </div>

      <ng-container *ngIf="showReportes && objectKeys(lineaOperacionPropuesta).length > 0">
        <div class="col-sm-12 col-md-6">
          
          <div class="table-responsive">
            <table class="table table-sm table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col" colspan="8" class="text-center py-1 bg-capital font-head-size">Historial Capital Express</th>
                  </tr>
                  <tr>
                    <th scope="col" class="text-center bg-capital font-head-size py-1">Producto</th>
                    <th scope="col" class="text-center bg-capital font-head-size py-1">Vigente</th>
                    <th scope="col" class="text-center bg-capital font-head-size py-1">Morosa</th>
                    <th scope="col" class="text-center bg-capital font-head-size py-1">Cancelada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Facturas</th>
                    <td>{{lineaOperacionPropuesta.vigente.facturas || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.propuesta.facturas || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.cancelada.facturas || 0 | number: '1.0-2'}}</td>
                  </tr>
                  <tr>
                    <th>Fache</th>
                    <td>{{lineaOperacionPropuesta.vigente.fache || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.propuesta.fache || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.cancelada.fache || 0 | number: '1.0-2'}}</td>
                  </tr>
                  <tr>
                    <th>Cheques</th>
                    <td>{{lineaOperacionPropuesta.vigente.cheques || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.propuesta.cheques || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.cancelada.cheques || 0 | number: '1.0-2'}}</td>
                  </tr>
                  <tr>
                    <th>Confirming</th>
                    <td>{{lineaOperacionPropuesta.vigente.confirming || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.propuesta.confirming || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.cancelada.confirming || 0 | number: '1.0-2'}}</td>
                  </tr>
                  <tr>
                    <th>OC</th>
                    <td>{{lineaOperacionPropuesta.vigente.oc || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.propuesta.oc || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.cancelada.oc || 0 | number: '1.0-2'}}</td>
                  </tr>
                  <tr>
                    <th>Credito Directo</th>
                    <td>{{lineaOperacionPropuesta.vigente.creditoDirecto || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.propuesta.creditoDirecto || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.cancelada.creditoDirecto || 0 | number: '1.0-2'}}</td>
                  </tr>
                  <tr>
                    <th>Pronto Pago</th>
                    <td>{{lineaOperacionPropuesta.vigente.prontoPago || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.propuesta.prontoPago || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.cancelada.prontoPago || 0 | number: '1.0-2'}}</td>
                  </tr>
                </tbody>
              </table>
          </div>
              
        </div>
      </ng-container>

      <ng-container *ngIf="showReportes && objectKeys(lineaOperacionPropuesta).length > 0">
  
        <div class="col-sm-12 col-md-11 mx-auto">
          <div class="table-responsive">
            <table class="table table-sm table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col" colspan="7" class="text-center py-1 bg-capital font-head-size">Monto Solicitado</th>
                  </tr>
                  <tr>
                    <th scope="col" class="text-center bg-capital font-head-size py-1">Producto</th>
                    <th scope="col" class="text-center bg-capital font-head-size py-1">Linea Vigente</th>
                    <th scope="col" class="text-center bg-capital font-head-size py-1">Linea Propuesta</th>
                    <th scope="col" class="text-center bg-capital font-head-size py-1">Op. Puntual Vigente</th>
                    <th scope="col" class="text-center bg-capital font-head-size py-1">Op. Puntual Propuesta</th>
                    <th scope="col" class="text-center bg-capital font-head-size py-1">%</th>
                    <th scope="col" class="text-center bg-capital font-head-size py-1">Plazo (dias)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Facturas</th>
                    <td>{{lineaOperacionPropuesta.lineaVigente.facturas || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.lineaPropuesta.facturas || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.opPuntualVigente.facturas || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.opPuntualPropuesta.facturas || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.porcentaje.facturas || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.plazoDias.facturas || 0 | number: '1.0-0'}}</td>
                  </tr>
                  <tr>
                    <th>Fache</th>
                    <td>{{lineaOperacionPropuesta.lineaVigente.fache || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.lineaPropuesta.fache || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.opPuntualVigente.fache || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.opPuntualPropuesta.fache || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.porcentaje.fache || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.plazoDias.fache || 0 | number: '1.0-0'}}</td>
                  </tr>
                  <tr>
                    <th>Cheques</th>
                    <td>{{lineaOperacionPropuesta.lineaVigente.cheques || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.lineaPropuesta.cheques || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.opPuntualVigente.cheques || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.opPuntualPropuesta.cheques || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.porcentaje.cheques || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.plazoDias.cheques || 0 | number: '1.0-0'}}</td>
                  </tr>
                  <tr>
                    <th>Confirming</th>
                    <td>{{lineaOperacionPropuesta.lineaVigente.confirming || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.lineaPropuesta.confirming || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.opPuntualVigente.confirming || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.opPuntualPropuesta.confirming || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.porcentaje.confirming || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.plazoDias.confirming || 0 | number: '1.0-0'}}</td>
                  </tr>
                  <tr>
                    <th>OC</th>
                    <td>{{lineaOperacionPropuesta.lineaVigente.oc || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.lineaPropuesta.oc || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.opPuntualVigente.oc || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.opPuntualPropuesta.oc || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.porcentaje.oc || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.plazoDias.oc || 0 | number: '1.0-0'}}</td>
                  </tr>
                  <tr>
                    <th>Credito Directo</th>
                    <td>{{lineaOperacionPropuesta.lineaVigente.creditoDirecto || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.lineaPropuesta.creditoDirecto || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.opPuntualVigente.creditoDirecto || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.opPuntualPropuesta.creditoDirecto || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.porcentaje.creditoDirecto || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.plazoDias.creditoDirecto || 0 | number: '1.0-0'}}</td>
                  </tr>
                  <tr>
                    <th>Pronto Pago</th>
                    <td>{{lineaOperacionPropuesta.lineaVigente.prontoPago || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.lineaPropuesta.prontoPago || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.opPuntualVigente.prontoPago || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.opPuntualPropuesta.prontoPago || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.porcentaje.prontoPago || 0 | number: '1.0-2'}}</td>
                    <td>{{lineaOperacionPropuesta.plazoDias.prontoPago || 0 | number: '1.0-0'}}</td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
      
        <div class="col-sm-12 col-md-11 col-lg-8 mx-auto">
          <div class="col-sm-6 col-lg-3 border text-center bg-capital font-head-size">
              <span class="span-linea">Total Linea M$</span>
          </div>
          <div class="col-sm-6 col-lg-3 border">
            <span>{{lineaOperacionPropuesta.totalLineaM | number: '1.0-2'}}</span>
          </div>
          <div class="col-sm-6 col-lg-3 border text-center bg-capital font-head-size">
              <span class="span-linea">Vencimiento</span>
          </div>
          <div class="col-sm-6 col-lg-3 border min-height-input">
            <span>{{lineaOperacionPropuesta.vencimiento | date: 'dd-MM-yyyy'}}</span>
          </div>
        </div>
      
        <div class="col-sm-12 col-md-11 mx-auto">
          <div class="col-12 mt-4 border pl-0">
              <div class="col-sm-12 col-md-6 col-lg-3 border text-center bg-capital font-head-size">
                  <span class="span-linea">Presentacion Cliente</span>
              </div>
              <div class="col-12 pb-4">
                <div class="mt-5" [innerHTML]="agregarSaltosDeLinea(lineaOperacionPropuesta.presentacionCliente)"></div>
              </div>
          </div>
        </div>
  
      </ng-container>

      <div class="col-sm-12 col-md-11 mx-auto mt-4" *ngIf="showReportes && totalesInformacionFinanciera && informacionFinancieraTable.length > 0">
        <div class="table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
              <tr>
                <th scope="col" colspan="100%" class="text-center py-1 bg-capital font-head-size">
                  Libro Compra/Venta $M
                  <i class="fa fa-info-circle pointer-cursor ml-1 not-print" aria-hidden="true" (click)="showDetalleFechas()" *ngIf="fechasCompraVenta.length > 0" title="Periodos Reporte Libro Compra Venta"></i>
                </th>
              </tr>
              <tr>
                <th scope="col" class="text-center bg-capital font-head-size">Año</th>
                <ng-container *ngIf="totalesInformacionFinanciera.totalAnterior.anio">
                  <th scope="col" class="text-center bg-capital font-head-size" colspan="3">{{totalesInformacionFinanciera.totalAnterior.anio}}</th>
                  <th scope="col" class="text-center bg-capital font-head-size" rowspan="2">Posterga</th>
                </ng-container>
                <ng-container *ngIf="totalesInformacionFinanciera.totalActual.anio">
                  <th scope="col" class="text-center bg-capital font-head-size" colspan="3">{{totalesInformacionFinanciera.totalActual.anio}}</th>
                  <th scope="col" class="text-center bg-capital font-head-size" rowspan="2">Posterga</th>
                </ng-container>
              </tr>
              <tr>
                <th scope="col" class="text-center bg-capital font-head-size">Meses</th>
                <ng-container *ngIf="totalesInformacionFinanciera.totalAnterior.anio">
                  <th scope="col" class="text-center bg-capital font-head-size">Ventas (M$)</th>
                  <th scope="col" class="text-center bg-capital font-head-size">Compras (M$)</th>
                  <th scope="col" class="text-center bg-capital font-head-size">Diferencia (M$)</th>
                </ng-container>
                
                <ng-container *ngIf="totalesInformacionFinanciera.totalActual.anio">
                  <th scope="col" class="text-center bg-capital font-head-size">Ventas (M$)</th>
                  <th scope="col" class="text-center bg-capital font-head-size">Compras (M$)</th>
                  <th scope="col" class="text-center bg-capital font-head-size">Diferencia (M$)</th>              
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of informacionFinancieraTable; let i = index">
                <tr>
                  <td>{{item?.anterior?.mes || item?.actual?.mes || ''}}</td>
                  <td>{{(item?.anterior?.ventas || item?.anterior?.ventas == 0) ? (item?.anterior?.ventas | number: '1.0-0') : ('')}}</td>
                  <td>{{(item?.anterior?.compras || item?.anterior?.compras == 0) ? (item?.anterior?.compras | number: '1.0-0') : ('')}}</td>
                  <td>{{(item?.anterior?.diferencia || item?.anterior?.diferencia == 0) ? (item?.anterior?.diferencia | number: '1.0-0') : ('')}}</td>
                  <td>{{item?.anterior?.posterga ? 'Si' : 'No'}}</td>
                  <td>{{(item?.actual?.ventas || item?.actual?.ventas == 0) ? (item?.actual?.ventas | number: '1.0-0') : ('')}}</td>
                  <td>{{(item?.actual?.compras || item?.actual?.compras == 0) ? (item?.actual?.compras | number: '1.0-0') : ('')}}</td>
                  <td>{{(item?.actual?.diferencia || item?.actual?.diferencia == 0) ? (item?.actual?.diferencia | number: '1.0-0') : ('')}}</td>
                  <td>{{item?.actual?.posterga ? 'Si' : 'No'}}</td>
                </tr>
                <ng-container *ngIf="i === 5">
                  <tr>
                    <th class="bg-capital">Total Sem.</th>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem1?.ventas || 0 | number: '1.0-0'}}</td>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem1?.compras || 0 | number: '1.0-0'}}</td>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem1?.dif || 0 | number: '1.0-0'}}</td>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem1?.posterga ? 'Si' : 'No'}}</td>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem1?.ventas || 0 | number: '1.0-0'}}</td>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem1?.compras || 0 | number: '1.0-0'}}</td>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem1?.dif || 0 | number: '1.0-0'}}</td>
                    <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem1?.posterga ? 'Si' : 'No'}}</td>
                  </tr>
                </ng-container>

              </ng-container>
              <tr>
                <th class="bg-capital">Total Sem.</th>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem2?.ventas || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem2?.compras || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem2?.dif || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalSem2?.posterga ? 'Si' : 'No'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem2?.ventas || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem2?.compras || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem2?.dif || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalSem2?.posterga ? 'Si' : 'No'}}</td>
              </tr>
              <tr>
                <th class="bg-capital">Total Año</th>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.ventas || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.compras || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.dif || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalAnio?.posterga ? 'Si' : 'No'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.ventas || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.compras || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.dif || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalAnio?.posterga ? 'Si' : 'No'}}</td>
              </tr>
              <tr>
                <th class="bg-capital">Promedio</th>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.ventas || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.compras || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.dif || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.posterga ? 'Si' : 'No'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.ventas || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.compras || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.dif || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.posterga ? 'Si' : 'No'}}</td>
              </tr>
              <tr>
                <th class="bg-capital">Margen %</th>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalAnterior?.TotalProm?.margen || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital"></td>
                <td class="bg-capital">{{totalesInformacionFinanciera?.totalActual?.TotalProm?.margen || 0 | number: '1.0-0'}}</td>
                <td class="bg-capital"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-sm-12 col-md-11 mx-auto my-3" *ngIf="showReportes">
        <div class="table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
              <tr>
                <th scope="col" colspan="100%" class="text-center py-1 bg-capital font-head-size">Deudores Propuestos</th>
              </tr>
              <tr>
                <th scope="col" class="text-center py-1 bg-capital font-head-size">Nombre</th>
                <th scope="col" class="text-center py-1 bg-capital font-head-size">Rut</th>
                <th scope="col" class="py-1 bg-capital font-head-size">Sublímite Deudor</th>
                <th scope="col" class="py-1 bg-capital font-head-size">Dicom M$</th>
                <th scope="col" class="text-center py-1 bg-capital font-head-size">Rango SII</th>
                <th scope="col" class="text-center py-1 bg-capital font-head-size">Historico Cancelado con Capex MM$</th>
                <th scope="col" class="text-center py-1 bg-capital font-head-size">% NC</th>
                <th scope="col" class="text-center py-1 bg-capital font-head-size">% Reclamos</th>
              </tr>
            </thead>
            <tbody>

              <ng-template #sinDatosDeudores>
                <tr class="height-row">
                  <td colspan="100%">Sin información</td>
                </tr>
              </ng-template>
                
              <ng-container *ngIf="deudores.length > 0 else sinDatosDeudores">

                <ng-container *ngFor="let item of deudores; let i = index">
                  <tr class="pointer-cursor" (click)="abrirModal((deudores[i].entidad || 'deudores'), deudores[i].rut)">
                    <td>{{deudores[i].nombre}}</td>
                    <td class="text-nowrap">{{deudores[i].rut | rutFormat}}</td>
                    <td>{{stringToFloat(deudores[i].facturaFinanciar) | number: '1.0-2'}}</td>
                    <td>{{deudores[i].monto | number: '1.0-2'}}</td>
                    <td>{{deudores[i].rangoSii}}</td>
                    <td width="15%">{{stringToFloat(deudores[i].histCancelado) | number: '1.0-2'}}</td>
                    <td>{{(deudores[i].porcentajeNcSobreVentas || deudores[i].porcentajeNcSobreVentas == 0) ? (deudores[i].porcentajeNcSobreVentas | number: '1.0-2') : ''}}</td>
                    <td>{{(deudores[i].procentajeReclamos || deudores[i].procentajeReclamos == 0) ? (deudores[i].procentajeReclamos | number: '1.0-2') : ''}}</td>
                  </tr>
                </ng-container>

              </ng-container>

            </tbody>
          </table>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 my-3" *ngIf="showReportes && top10Clientes && top10Clientes.length > 0">
        <div class="table-responsive">
          <table class="table table-hover table-bordered border">
            <thead>
              <tr>
                <th scope="col" colspan="100%" class="text-center py-1 bg-capital font-head-size">
                  Top 10 Deudores
                  <i class="fa fa-info-circle pointer-cursor ml-1 not-print" aria-hidden="true" (click)="showDetalleFechas()" *ngIf="fechasCompraVenta.length > 0" title="Periodos Reporte Libro Compra Venta"></i>
                </th>
              </tr>
              <tr>
                <th scope="col" class="text-center py-1 bg-capital font-head-size">N°</th>
                <th scope="col" class="text-center py-1 bg-capital font-head-size">Razón Social</th>
                <th scope="col" class="text-center py-1 bg-capital font-head-size">Ventas $M</th>
                <th scope="col" class="text-center py-1 bg-capital font-head-size">Tramo SII</th>
              </tr>
            </thead>
            <tbody>
              <ng-template #sinDatosTop10>
                <tr class="height-row">
                  <td colspan="3">Sin información</td>
                </tr>
              </ng-template>

              <ng-container *ngIf="top10Clientes.length > 0 else sinDatosTop10">
                <ng-container *ngFor="let item of top10Clientes; let i = index">
                  <tr>
                    <td class="text-center">{{i + 1}}</td>
                    <td>{{item.nombre || ''}}</td>
                    <td>{{item.montoM || 0 | number: '1.0-0'}}</td>
                    <td class="text-center">{{item.rangoSii || ''}}</td>
                  </tr>
                </ng-container>
              </ng-container>

            </tbody>
          </table>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 my-3" *ngIf="showReportes">
        <div class="table-responsive">
          <table class="table table-hover table-bordered border">
            <thead>
              <tr>
                <th scope="col" colspan="5" class="text-center py-1 bg-capital font-head-size">Factorización (M)</th>
              </tr>
              <tr>
                <th scope="col" class="text-center py-1 bg-capital font-head-size">Nombre</th>
                <th scope="col" class="text-center py-1 bg-capital font-head-size">3 Meses</th>
                <th scope="col" class="text-center py-1 bg-capital font-head-size">6 Meses</th>
                <th scope="col" class="text-center py-1 bg-capital font-head-size">12 Meses</th>
              </tr>
            </thead>
            <tbody>
              <ng-template #sinDatosFactoring>
                <tr class="height-row">
                  <td colspan="5">Sin información</td>
                </tr>
              </ng-template>

              <ng-container *ngIf="factoring.length > 0 else sinDatosFactoring">
                <ng-container *ngFor="let item of factoring; let i = index">
                  <tr>
                    <td>{{item.rz_cesionario}}</td>
                    <td>{{item.monto3Meses !== ''? ((item.monto3Meses / 1000) | number: '1.0-0'): item.monto3Meses}}</td>
                    <td>{{item.monto6Meses !== ''? ((item.monto6Meses / 1000) | number: '1.0-0'): item.monto6Meses}}</td>
                    <td>{{item.Monto12Meses !== ''? ((item.Monto12Meses / 1000) | number: '1.0-0'): item.Monto12Meses}}</td>
                  </tr>
                </ng-container>
              </ng-container>

            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>

  <div class="col-12 my-2 not-print" *ngIf="showArchivos">
    <app-modulo-archivos [idTransaccion]="idTransaccion" [rut]="rut" [closed]="closed"></app-modulo-archivos>
  </div>

</div>

<ng-container *ngIf="plantillaNoEditable === false">
  <ng-template #botonFinalizar>
    <div class="not-print">
      <ng-container *ngIf="validaUsuarioAdmin()">
        <div class="col-12 d-flex justify-content-center pt-4">
          <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="volverEtapaPrevia()">
            Devolver Etapa Previa
          </button>
          <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="continuar()">
            Continuar
          </button>
        </div>
      </ng-container>
    </div>
  </ng-template>
  
  <ng-container *ngIf="stepper === 4 else botonFinalizar">
  
    <div class="col-12 d-flex justify-content-center pt-4">
      <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="guardarFicha()">
        Guardar
      </button>
      <button class="btn btn-sm btn-primary mx-2 rounded-header" [disabled]="objectKeys(fichaGuardada).length > 0 ? null : true" (click)="avanzarFlujo()">
        Continuar
      </button>
    </div>
  
  </ng-container>
</ng-container>


<app-modal-consolidado-workflow [rut]="rutSeleccionado" [idTransaccion]="idTransaccion" [dataServicios]="reportesSeleccionado" [informeComercial]="informeComercial" [default]="modalDefault" [plantilla]="plantillaAntecedentes"></app-modal-consolidado-workflow>
