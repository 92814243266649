<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<ng-container *ngIf="hasUserAccess">
    <div class="card w-100 mx-auto border-0 rounded" *ngIf="showTable">
        <div class="card-body p-5">
            <button type="button" class="btn btn-primary" (click)="goBack()" *ngIf="rutInput == ''">Volver</button>
    
            <div class="row mt-5">
                <div class="col-12"><!--col-sm-12 col-md-4 col-lg-3-->
                    <h3 class="text-center">
                        Solicitudes Enviadas
                    </h3>
                    <!--<div class="row">-->
                        <!--<div class="col-sm-12 my-2">
                            <div class="container rounded-pill p-3 pill-option border cursor-pointer" [ngClass]="{'pill-option-active': seeRecibidos}" (click)="seeRecibidos = true">
                                <i class="fa fa-inbox ml-4" aria-hidden="true"></i>
                                <span class="ml-2">
                                    Recibidas
                                </span>
                            </div>
                        </div>-->
                        <!--<div class="col-sm-12 my-2 pt-3">
                            <div class="container rounded-pill p-3 pill-option border cursor-pointer" [ngClass]="{'pill-option-active': !seeRecibidos}" (click)="seeRecibidos = false">
                                <i class="fa fa-paper-plane ml-4" aria-hidden="true"></i>
                                <span class="ml-2">
                                    Enviadas
                                </span>
                            </div>
                        </div>-->
                    <!--</div>-->
                </div>
    
                <div class="col-sm-12"><!--col-sm-12 col-md-8 col-lg-9-->
                    <div class="card text-center my-4">
        
                        <div class="card-header">
                            <div class="my-0 row d-flex px-4">
    
                                <div class="col-xs-3 col-sm-auto mr-auto p-2">
                                    <app-paginator-by-items title="Cantidad de filas a mostrar en la tabla" 
                                        [itemsPerPageInput]="currentItem" 
                                        (itemsPerPage)="currentItem = $event">
                                    </app-paginator-by-items>
                                </div>
    
                                <div class="col-xs-3 col-sm-auto p-2">
                                    <div class="input-group mb-3">
                                        <input id="table-complete-search" type="text" class="form-control" name="searchTermServicio"
                                            title="Ingrese texto para filtrar tabla"
                                            placeholder="Filtrar tabla"
                                            [(ngModel)]="inputText" 
                                            (keyup)="filtrarObjeto()"
                                        >
                                    </div>
                                </div>
    
                                <div class="col-xs-3 col-sm-auto p-2">
                                    <button 
                                            type="button" 
                                            class="btn btn-info float-right refresh-button" 
                                            (click)="obtenerListado()"
                                            title="Actualizar tabla"
                                        >
                                            <i class="fa fa-refresh" aria-hidden="true"></i>
                                    </button>
                                </div>
                                
                            </div>
                        </div>
    
                        <div class="table-responsive">
                            <table class="table table-hover border my-0">
                                <thead>
                                  <tr>
                                    <th scope="col" class="text-center font-size cursor-pointer"
                                        (click)="cambioDeCampo('rut')"
                                    >
                                        Rut
                                        <div class="float-right">
                                            <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                            <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                        </div>
                                    </th>
                                    <th scope="col" class="text-center font-size cursor-pointer min-width-th"
                                        (click)="cambioDeCampo('servicio')"
                                    >
                                        Servicio
                                        <div class="float-right">
                                            <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                            <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                        </div>
                                    </th>
                                    <th scope="col" class="text-center font-size cursor-pointer min-width-th-lg"
                                        (click)="cambioDeCampo('estado')"
                                    >
                                        Estado
                                        <div class="float-right">
                                            <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                            <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                        </div>
                                    </th>
                                    <th scope="col" class="text-center font-size cursor-pointer min-width-th-lg"
                                        (click)="cambioDeCampo('fecha')"
                                    >
                                        Fecha Solicitud
                                        <div class="float-right">
                                            <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                            <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                        </div>
                                    </th>
                                    <th scope="col" class="text-center font-size cursor-pointer min-width-th-lg"
                                        (click)="cambioDeCampo('fechaM')"
                                    >
                                        Fecha Modificacion
                                        <div class="float-right">
                                            <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                            <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                        </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let solicitud of tablaSolicitudesFiltrada | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                                        <tr>
                                            <td class="font-size cursor-pointer" (click)="revisarSolicitud(solicitud)">
                                                {{ solicitud.rut || '' | rutFormat }}
                                            </td>
                                            <td class="font-size cursor-pointer" (click)="revisarSolicitud(solicitud)">
                                                {{ solicitud.servicio || '' }}
                                            </td>
                                            <td class="font-size cursor-pointer" (click)="revisarSolicitud(solicitud)">
                                                <ng-container *ngIf="solicitud.estado === 'COMPLETED'; else elseCompleted">
                                                    <span class="badge badge-success">{{ solicitud.estado || '' }}</span>
                                                </ng-container>
                                                <ng-template #elseCompleted>
                                                    <ng-container *ngIf="solicitud.estado === 'IN-PROGRESS'; else elseProgress">
                                                        <span class="badge badge-warning text-white">{{ solicitud.estado || '' }}</span>
                                                    </ng-container>
                                                    <ng-template #elseProgress>
                                                        <span class="badge badge-danger">{{ solicitud.estado || '' }}</span>
                                                    </ng-template>
                                                </ng-template>
                                            </td>
                                            <td class="font-size cursor-pointer" (click)="revisarSolicitud(solicitud)">
                                                {{ solicitud.fechaFormatted || '' }}<!-- | date: 'dd-MM-yyyy'-->
                                            </td>
                                            <td class="font-size cursor-pointer" (click)="revisarSolicitud(solicitud)">
                                                {{ solicitud.fechaFormattedM || '' }}<!-- | date: 'dd-MM-yyyy'-->
                                            </td>
                                            <!--<td class="font-size cursor-pointer" (click)="revisarSolicitud(solicitud)">
                                                {{ solicitud.email || '' }}
                                            </td>-->
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
    
                        <div class="card-footer text-muted">
                            <div class="p-2">
                                <div id="pagination">
                                    <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                                        <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
    
            </div>
    
        </div>
    </div>
    
    
    <div class="card w-100 mx-auto border-0 rounded" *ngIf="showDetails">
        <div class="card-body p-5">
    
            <div class="row mt-5">
                <div class="col-12">
                    <h3 class="text-center my-5">
                        Detalles de la Solicitud
                    </h3>
                </div>
    
                <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
                    <div class="table-responsive">
                        <table class="table table-hover border my-0">
                            <tbody>
                                <tr>
                                    <td width="30%" class="bg-primary">
                                        ID Transacción
                                    </td>
                                    <td width="70%">
                                        {{ solicitudSeleccionada?.id || '' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="30%" class="bg-primary">
                                        Rut
                                    </td>
                                    <td width="70%">
                                        {{ solicitudSeleccionada?.rut ? (solicitudSeleccionada?.rut | rutFormat) : '' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="30%" class="bg-primary">
                                        Servicio
                                    </td>
                                    <td width="70%">
                                        {{ solicitudSeleccionada?.servicio || '' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="30%" class="bg-primary">
                                        Fecha Solicitud
                                    </td>
                                    <td width="70%">
                                        {{ solicitudSeleccionada?.fechaFormatted || '' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="30%" class="bg-primary">
                                        Fecha Modificación
                                    </td>
                                    <td width="70%">
                                        {{ solicitudSeleccionada?.fechaFormattedM || '' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="30%" class="bg-primary">
                                        Estado
                                    </td>
                                    <td width="70%">
                                        {{ solicitudSeleccionada?.estado || '' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="30%" class="bg-primary">
                                        Sub Estado
                                    </td>
                                    <td width="70%">
                                        {{ solicitudSeleccionada?.subEstado || '' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="30%" class="bg-primary">
                                        Intentos de carga
                                    </td>
                                    <td width="70%">
                                        {{ (solicitudSeleccionada?.intentoCarga || solicitudSeleccionada?.intentoCarga == 0) ? solicitudSeleccionada?.intentoCarga : '' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td width="30%" class="bg-primary">
                                        Intentos de sincronización
                                    </td>
                                    <td width="70%">
                                        {{ (solicitudSeleccionada?.intentoSync || solicitudSeleccionada?.intentoSync == 0) ? solicitudSeleccionada?.intentoSync : '' || '' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
    
                <div class="col-sm-12 my-5 d-flex justify-content-center">
                    <button type="button" class="btn btn-info mx-auto" (click)="volverTabla()">Ver Listado</button>
                </div>
    
            </div>
    
        </div>
    </div>
</ng-container>