import { Component, Input, OnInit } from '@angular/core';
import { Reporte as ReporteF22 } from 'src/app/models/formulario-f22/formulario-f22.model';
import { balanceReporteFullActivos, balanceReporteFullEstadoResultados, balanceReporteFullIndicadoresFinancieros, balanceReporteFullInformacionBalance, balanceReporteFullPasivos, balanceReporteFullResumenFinanciero, ventasPeriodo } from 'src/app/models/utils/utils.model';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-reporte-financiero-consolidado-view',
  templateUrl: './reporte-financiero-consolidado-view.component.html',
  styleUrls: ['./reporte-financiero-consolidado-view.component.scss']
})
export class ReporteFinancieroConsolidadoViewComponent implements OnInit {
  @Input() reporteFullContentF22!: any | null;
  @Input() reporteFullContentF29!: any | null;
  @Input() reporteFullContentRenta!: any | null;
  @Input() reporteBasicoContent: any;
  @Input() rut!: string | null;
  @Input() tipoPersona!: string | null;
  public isNaN = Number.isNaN;
  public reporte: any;
  public reporteRenta: any;
  public reporteBasico: any = {};
  public informacionBalance: any[] = [];
  public balanceReporteFullInformacionBalance: typeof balanceReporteFullInformacionBalance | any = balanceReporteFullInformacionBalance;
  public balanceReporteFullResumenFinanciero: typeof balanceReporteFullResumenFinanciero | any = balanceReporteFullResumenFinanciero;
  public balanceReporteFullActivos: typeof balanceReporteFullActivos | any = balanceReporteFullActivos;
  public balanceReporteFullPasivos: typeof balanceReporteFullPasivos | any = balanceReporteFullPasivos;
  public balanceReporteFullEstadoResultados: typeof balanceReporteFullEstadoResultados | any = balanceReporteFullEstadoResultados;
  public balanceReporteFullIndicadoresFinancieros: typeof balanceReporteFullIndicadoresFinancieros | any = balanceReporteFullIndicadoresFinancieros;
  public ventasPeriodo: typeof ventasPeriodo | any = ventasPeriodo;
  public rentabilidadGraph: any = {};
  public liquidezGraph: any = {};
  public coberturaGraph: any = {};
  public eficienciaGraph: any = {};
  public estructuraFinancieraGraph: any = {};
  public ventasNetasGraph: any = {};
  public comprasNetasGraph: any = {};
  public comprasVentasNetasGraph: any = {};
  
  constructor(
  ) { 
  }

  public headerBalance: string[] = [
    'periodo',
    'folio',
    'fecha',
    'nMeses',
    'actividadEconomica',
    'sector',
    'subSector',
    'codActividad',
    'tamano',
    'rating',
    'glosa'
  ];



  public headerResumenFinanciero: string[] = [
    'ingresosdelaExplotacion',
    'utilidadneta',
    'utilidadVentas',
    'razonCorriente',
    'permanenciaCtasporCobrar',
    'permanenciadeInventarios',
    'totalactivos',
    'totalactivosfijos',
    'totalpasivoexigible',
    'totalpatrimonio',
    'leverage'
  ];

  public headerResumenActivos: string[][] = [
    ['disponible', 'disponibleporcentaje'],
    ['valoresNegociables', 'valoresNegociablesporcentaje'],
    ['cuentasporCobrardelGiro', 'cuentasporCobrardelGiroporcentaje'],
    ['inventario', 'inventarioporcentaje'],
    ['impuestosporRecuperar', 'impuestosporRecuperarporcentaje'],
    ['deudoresVarios', 'deudoresVariosporcentaje'],
    ['cuentasporCobrarEERROperacionalCP', 'cuentasporCobrarEERROperacionalCPporcentaje'],
    ['otrosActivosCirculantes', 'otrosActivosCirculantesporcentaje'],
    ['totalactivocirculante', 'totalactivocirculantEporcentaje'],
    ['terrenosyBienesRaíces', 'terrenosyBienesRaícesporcentaje'],
    ['maquinariasEquiposyVehículos', 'maquinariasEquiposyVehículosporcentaje'],
    ['activosenLeasing', 'activosenLeasingporcentaje'],
    ['otrosActivosFijos', 'otrosActivosFijosporcentaje'],
    ['depreciaciónAcumulada', 'depreciaciónAcumuladaporcentaje'],
    ['totalactivosfijos', 'totalactivosfijoSporcentaje'],
    ['cuentasporCobrarEERROperacionalLP', 'cuentasporCobrarEERROperacionalLPporcentaje'],
    ['inversiónenEERRySociedades', 'inversiónenEERRySociedadesporcentaje'],
    ['intangibles', 'intangiblesporcentaje'],
    ['otrosActivos', 'otrosActivosporcentaje'],
    ['totalotrosactivospermanentes', 'totalotrosactivospermanenteSporcentaje'],
    ['totalactivos', 'totalactivoSporcentaje']
  ];

  public headerResumenPasivos: string[][] = [
    ['bancosCortoPlazo', 'bancosCortoPlazoporcentaje'],
    ['bancosLPporcionCP', 'bancosLPporcionCPporcentaje'],
    ['leasingLPporcionCP', 'leasingLPporcionCPporcentaje'],
    ['cartasdeCreditosPagaréOtraDFCP', 'cartasdeCreditosPagaréOtraDFCPporcentaje'],
    ['documentosyCuentasporPagardeGiro', 'documentosyCuentasporPagardeGiroporcentaje'],
    ['variosAcreedores', 'variosAcreedoresporcentaje'],
    ['cuentasporPagarEERRySocios', 'cuentasporPagarEERRySociosporcentaje'],
    ['provisionesRetencioneseImpuestos', 'provisionesRetencioneseImpuestosporcentaje'],
    ['otrosPasivosCirculantes', 'otrosPasivosCirculantesporcentaje'],
    ['totalpasivoscirculantes', 'totalpasivoscirculanteSporcentaje'],
    ['bancosLP', 'bancosLPporcentaje'],
    ['leasingLP', 'leasingLPporcentaje'],
    ['acreedoresVariosLP', 'acreedoresVariosLPporcentaje'],
    ['cuentasporPagarEERRySociosLP', 'cuentasporPagarEERRySociosLPporcentaje'],
    ['otrosPasivosLargoPlazo', 'otrosPasivosLargoPlazoporcentaje'],
    ['totalpasivolargoplazo', 'totalpasivolargoplazOporcentaje'],
    ['totalpasivoexigible', 'totalpasivoexigiblEporcentaje'],
    ['interesMinoritario', 'interesMinoritarioporcentaje'],
    ['capitalPagado', 'capitalPagadoporcentaje'],
    ['reservas', 'reservasporcentaje'],
    ['utilidadesAcumuladas', 'utilidadesAcumuladasporcentaje'],
    ['utilidaddelEjercicio', 'utilidaddelEjercicioporcentaje'],
    ['retiros', 'retirosporcentaje'],
    ['totalpatrimonio', 'totalpatrimoniOporcentaje'],
    ['totalpasivos', 'totalpasivoSporcentaje']
  ];

  public headerResumenEstadoResultados: string[][] = [
    ['ingresosdelaExplotacion', 'ingresosdelaExplotacionporcentaje'],
    ['costodeExplotacion', 'costodeExplotacionporcentaje'],
    ['resultadobruto', 'resultadobrutOporcentaje'],
    ['gastosAdministrativosyVentas', 'gastosAdministrativosyVentasporcentaje'],
    ['ebitda', 'ebitdAporcentaje'],
    ['depreciaciondelEjercicio', 'depreciaciondelEjercicioporcentaje'],
    ['resultadooperacional', 'resultadooperacionaLporcentaje'],
    ['ingresosFinancieros', 'ingresosFinancierosporcentaje'],
    ['ingresosfueradelaExplotación', 'ingresosfueradelaExplotaciónporcentaje'],
    ['utilPerdInversionenEERR', 'utilPerdInversionenEERRporcentaje'],
    ['egresosfueradeExplotacion', 'egresosfueradeExplotacionporcentaje'],
    ['gastosFinancieros', 'gastosFinancierosporcentaje'],
    ['diferenciadeCambio', 'diferenciadeCambioporcentaje'],
    ['resantescorrecmon', 'resantescorrecmoNporcentaje'],
    ['correccionMonetaria', 'correccionMonetariaporcentaje'],
    ['interesMinoritario2', 'interesMinoritario2porcentaje'],
    ['utilidadantesdeimpuestos', 'utilidadantesdeimpuestoSporcentaje'],
    ['impuestoRenta', 'impuestoRentaporcentaje'],
    ['utilidadneta', 'utilidadnetAporcentaje'],
  ];

  public headerResumenIndicadoresFinancieros: string[] = [
    'LiquidezyActividadTitle', // solo para titulo
    'capitaldeTrabajo',
    'razonCorriente',
    'testAcido',
    'permanenciaCtasporCobrar',
    'permanenciadeInventarios',
    'permdeCuentasporPagar',
    'cicloOperacionalNeto',
    'variacionenActivos',
    'variacionenPasivoExigible',
    'RentabilidadTitle', // solo para titulo
    'utilidadVentas',
    'ventaTotalIngresos',
    'ebitdaAnualizado',
    'roe',
    'roa',
    'EndeudamientoyCobertura', // solo para titulo
    'dfebitda',
    'ebitdagf',
    'leverage',
    'dfPatrimonio',
    'variacionenVentas',
    'variacionenPatrimonio'
  ];

  public headerInformacionRenta: any[] = [
    {text: 'Periodo', value: 'año'},
    {text: 'Folio', value: 'folio'},
    {text: 'Fecha', value: 'fecha'},
    {text: 'Nombre o Razón Social', value: 'nombre'},
    {text: 'Numero de Meses', value: 'numeroMeses'},
    {text: 'Actividad Económica', value: 'actividadEconomica'},
    {text: 'Cod. Actividad Económica', value: 'codActividad'},
  ];

  public headerRentaFija: any[] = [
    {text: 'Sueldos, Pensiones,etc.', value: 'sueldosPensiones'},
    {text: 'Crédito por Impto unico de 2ª Categoria', value: 'creditoOmpuestoUnicoSegundaCategoria'},
    {text: 'Total', value: 'totalRentaFija'}
  ];

  public headerRentaVariable: any[] = [
    {text: 'Honorarios y Rem . Directores S.A', value: 'honorariosRemuneracionesDirectoresSa'}
  ];

  public headerRentaParticipacion: any[] = [
    {text: 'Retiros', value: 'retiros'},
    {text: 'Dividendos distribuidos por S.A y C.P.A', value: 'dividendosDistribuidosSaCpa'},
    {text: 'Otras Rentas por Participación en Sociedades', value: 'otrasRentasporParticipacionenSociedades'},
    {text: 'Gastos Rechazados , Art 33 Nª 1', value: 'gastosRechazadosArticulo33'},
    {text: 'Impto. Primera categoria pagado el año anterior', value: 'impuestoPrimeraCategoriaPagadoAnioAnterior'},
    {text: 'Impuesto territorial pagado el año anterior', value: 'impuestoTerritorialPagadoAnioAnterior'},
    {text: 'Total', value: 'totalRentasParticipacionSociedades'}
  ];

  public headerIngresosPersibidos: any[] = [
    {text: 'Rentas por Capitales Mobiliarios', value: 'rentaCapitalesMobiliarios'},
    {text: 'Perdida en Operaciones de capitales mobiliarios', value: 'perdidaOperacionesCapitalesMobiliarios'},
    {text: 'Rentas determinadas según contabilidad simplificada, planillas, contratos y otras rentas', value: 'rentasSegunContabilidadSimplificada'},
    {text: 'Rentas Exentas', value: 'rentasExentas'},
    {text: 'Cotizaciones previsionales correspondientes al empresario o socio', value: 'cotizacionesPrevisionalesEmpresarioSocio'},
    {text: 'Devolución de Impuestos', value: 'devolucionImpuestos'},
    {text: 'Impto. Pagado', value: 'impuestoPagado'},
    {text: 'Total', value: 'totalOtrosIngresosPercibidos'}
  ];

  public headerTotalRentaLiq: any[] = [
    {text: 'Total Renta Liquida Anual', value: 'totalRentaLiquidaAnual'}
  ];

  public headerTotalRenta: any[] = [
    {text: 'Renta Fija Mensual', value: 'rentaFijaMensual', porc: 'rentaFijaPorcentualSobreRentaLiquida'},
    {text: 'Renta Variable Mensual', value: 'rentaVariableMensual', porc: 'rentaVariableSobreRentaLiquidaMensual'},
    {text: 'Total Renta liquida mensual', value: 'totalRentaLiquidaMensual', porc: 'totalRentaLiquidaSobreRentaLiquidaMensual'}
  ];

  ngOnInit(): void {
    this.reporteBasico = this.reporteBasicoContent;
    this.setInformacionBalance();
    this.reporte = this.reporteFullContentF29;
    this.reporteRenta = this.reporteFullContentRenta;
    if (this.reporte.length > 0) {
      this.generateGraphsF29();
    }
  }

  /**
   * @default
   * Setea la informacion del balance, ordena por periodo ascendente
   * y solo toma los ultimos 3 periodos
   */
   setInformacionBalance(): void {
    if (this.reporteFullContentF22.length > 0) {
      this.informacionBalance = this.reporteFullContentF22.sort((a: ReporteF22, b: ReporteF22) => {
        return b.periodo - a.periodo;
      });
    }
    if (this.informacionBalance.length > 0) {
      this.generateGraphs();
    }
  }

  getPercents(title: string): boolean {
    const valuesKeys = [
      'razonCorriente',
      'testAcido',
      'variacionenActivos',
      'variacionenPasivoExigible',
      'utilidadVentas',
      'ventaTotalIngresos',
      'roe',
      'roa',
      'dfebitda',
      'ebitdagf',
      'leverage',
      'dfPatrimonio',
      'variacionenVentas',
      'variacionenPatrimonio'
    ];
    return valuesKeys.includes(title);
  }

  /**
   * @description
   * Valida si el titulo existe en el array de titulos 
   * @param title 
   * @returns 
   */
   getStrongTitle(title: string): boolean {
    const valuesKeys = [
      'totalactivocirculante',
      'totalactivosfijos',
      'totalotrosactivospermanentes',
      'totalactivos',
      'totalpasivoscirculantes',
      'totalpasivolargoplazo',
      'totalpasivoexigible',
      'totalpatrimonio',
      'totalpasivos',
      'LiquidezyActividadTitle',
      'RentabilidadTitle',
      'EndeudamientoyCobertura'
    ];
    return valuesKeys.includes(title);
  }

  clickHeader(event: any): void{
    const elementoHTML = document.getElementById("collapsable-f29-" + event);
    const botonlHTML = document.getElementById("icon-l-f29-" + event);
    const botondHTML = document.getElementById("icon-d-f29-" + event);
    if (elementoHTML && botonlHTML && botondHTML){
      elementoHTML.hidden = !elementoHTML.hidden;
      botondHTML.hidden = !botondHTML.hidden;
      botonlHTML.hidden = !botonlHTML.hidden;

    }
  }


  generateGraphs(): void {
    this.setGraficoRentabilidad();
    this.setGraficoLiquidez();
    this.setGraficoCobertura();
    this.setGraficoEficienciaGraph();
    this.setGraficoEstructuraFinancieraGraph();
  }

  generateGraphsF29(): void {
    this.setGraficoVentasNetasGraph();
    this.setGraficoComprasNetasGraph();
    this.setGraficoComprasVentasNetasGraph();
  }

  /**
   * @description
   * Setea los valores de la grafica de rentabilidad  
   */
   setGraficoRentabilidad(): void {
    const VariableColors: string[] = ['#3c64ac', '#4d8cb5', '#62993f', '#a5a5a5'];
    const VariableLabels: any[] = [
      { variable: 'utilidadVentas', percent: true },
      { variable: 'roe', percent: true },
      { variable: 'roa', percent: true }];

    this.rentabilidadGraph = {
      title: 'Evolución Indicadores de Rentabilidad',
      categorias: [{ categories: ['Utilidad / Ventas', 'ROE (%)', 'ROA (%)'] }],
      serie: this.setSerieToGraph(VariableColors, VariableLabels),
      plotOptions: {},
      yAxix: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }]
    };
  }

  /**
 * @description
 * Setea los valores de la grafica de liquidez  
 */
  setGraficoLiquidez(): void {
    const VariableColors: string[] = ['#eb7d30', '#569cd8', '#a5a5a5', '#3c64ac'];
    const VariableLabels: any[] = [
      { variable: 'razonCorriente', percent: true },
      { variable: 'testAcido', percent: true },
      { variable: 'permanenciaCtasporCobrar', percent: true },
      { variable: 'permdeCuentasporPagar', percent: true },
    ];

    this.liquidezGraph = {
      title: 'Evolución Indicadores de Liquidez',
      categorias: [{
        categories: ['Razón Corriente (veces)',
          'Test Ácido (veces)',
          'Permanecia Cuentas por Cobrar (días)',
          'Perms de Cuentas por Pagar (días)',
        ]
      }],
      serie: this.setSerieToGraph(VariableColors, VariableLabels),
      plotOptions: {},
      yAxix: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }]
    };
  }

  /**
 * @description
 * Setea los valores de la grafica de liquidez  
 */
  setGraficoCobertura(): void {
    const VariableColors: string[] = ['#4b91c4', '#e26627', '#efa689', '#3c64ac'];
    const VariableLabels: any[] = [
      { variable: 'ebitdagf', percent: true },
      { variable: 'leverage', percent: true },
      { variable: 'ingresosdelaExplotacion', variable2: 'totalpasivoexigible', percent: true },
      { variable: 'ingresosdelaExplotacion', variable2: 'totalpatrimonio', percent: true },
    ];
    
    this.coberturaGraph = {
      title: 'Evolución Indicadores de Endeudamiento y Cobertura',
      categorias: [{
        categories: ['EBITDA / GF (veces)',
          'Leverage (veces)',
          'Endeudamiento / Ventas (veces)',
          'Ventas / Patrimonio (veces)',
        ]
      }],
      serie: this.setSerieToGraph(VariableColors, VariableLabels),
      plotOptions: {},
      yAxix: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }]
    };
  }

  /**
 * @description
 * Setea los valores de la grafica de liquidez  
 */
  setGraficoEficienciaGraph(): void {
    const VariableColors: string[] = ['#4182bc', '#6faf3f', '#335da9', '#eb7d30'];
    const VariableLabels: any[] = [
      { variable: 'ingresosdelaExplotacion', percent: false },
      { variable: 'ebitda', percent: false },
      { variable: 'gastosFinancieros', percent: false },
      { variable: 'utilidadneta', percent: false }
    ];

    this.eficienciaGraph = {
      title: 'Evolución Indicadores de eficiencia',
      categorias: [{
        categories: ['Ingresos de la Explotación',
          'EBITDA',
          '(Gastos Financieros)',
          'Utilidad Neta',
        ]
      }],
      serie: this.setSerieToGraph(VariableColors, VariableLabels),
      plotOptions: {},
      yAxix: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }]
    };
  }

  /**
 * @description
 * Setea los valores de la grafica de Estructura Financiera  
 */
  setGraficoEstructuraFinancieraGraph(): void {
    const VariableColors: string[] = ['#deab11', '#bcc1ba', '#3c64ac', '#efa689'];
    const VariableLabels: any[] = [
      { variable: 'totalactivocirculante', percent: false },
      { variable: 'totalactivosfijos', percent: false },
      { variable: 'totalotrosactivospermanentes', percent: false },
      { variable: 'totalpasivoscirculantes', percent: false },
      { variable: 'totalpatrimonio', percent: false }
    ];

    this.estructuraFinancieraGraph = {
      title: 'Estructura Financiera',
      categorias: [{
        categories: [
          'TOTAL ACTIVO CIRCULANTE',
          'TOTAL ACTIVOS FIJOS',
          'TOTAL OTROS ACTIVOS',
          'TOTAL PASIVOS CIRCULANTE',
          'TOTAL PATRIMONIO'
        ]
      }],
      serie: this.setSerieToGraph(VariableColors, VariableLabels),
      plotOptions: {},
      yAxix: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }]
    };
  }

  /**
 * @description
 * Setea los valores de la grafica de Ventas Netas  
 */
  setGraficoVentasNetasGraph(): void {
    const VariableColors: string[] = ['#4364b3', '#8abaeb', '#5a8430', '#deab11'];
    const VariableLabels: any = { variable: 'ventasNetasMiles', percent: false };
    let chart = new Chart({
      xAxis: [{
        categories: [
          'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
          'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ]
      }],
      title: {
        text: 'Ventas Netas M$'
      },
      yAxis: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      series: this.setSerieToGraphF29(this.reporte, VariableColors, VariableLabels)
    });
    this.ventasNetasGraph = chart;
  }

  /**
 * @description
 * Setea los valores de la grafica de Comprar Netas  
 */
  setGraficoComprasNetasGraph(): void {
    const VariableColors: string[] = ['#a8a8a8', '#e5c03d', '#4363c8', '#5a8430'];
    const VariableLabels: any = { variable: 'comprasNetasMiles', percent: false };
    let chart = new Chart({
      xAxis: [{
        categories: [
          'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
          'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ]
      }],
      title: {
        text: 'Compras Netas M$'
      },
      yAxis: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      series: this.setSerieToGraphF29(this.reporte, VariableColors, VariableLabels)
    });
    this.comprasNetasGraph = chart;
  }

  /**
 * @description
 * Setea los valores de la grafica de Comprar y Ventas Netas  
 */
  setGraficoComprasVentasNetasGraph(): void {
    const VariableColors: string[] = ['#4364b3', '#959595'];
    const VariableLabels: any = [
      { variable: 'ventasNetasMiles', percent: false },
      { variable: 'comprasNetasMiles', percent: false }
    ];

    let chart = new Chart({
      xAxis: [{
        categories: [
          'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
          'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ]
      }],
      title: {
        text: 'Ventas Netas M$ / Compras Netas M$'
      },
      yAxis: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      series: this.setSerieToGraphComprasVentasNetas(this.reporte, VariableColors, VariableLabels)
    });
    this.comprasVentasNetasGraph = chart;    
  }

  /**
   * @description
   * Setea los datos de la serie para el grafico F22
   * @param colorsSerie 
   * @param labels 
   * @returns 
   */
  setSerieToGraph(colorsSerie: string[], labels: any[], type: string = 'column'): any[] {
    const tempSeries: any = [];
    this.informacionBalance.forEach((element: any, i: number) => {
      tempSeries.push({
        name: element.periodo - 1,
        type: type,
        color: colorsSerie[i],
        data: this.calculateElements(labels, element)
      });
    });
    return tempSeries;
  }

  /**
   * @description
   * Setea los datos de la serie para el grafico F29
   * @param colorsSerie 
   * @param labels 
   * @returns 
   */
  setSerieToGraphF29(informacionVentas: any, colorsSerie: string[], labels: any[]): any[] {
    const tempSeries: any = [];
    informacionVentas.forEach((element: any, i: number) => {
      tempSeries.push({
        name: element.periodo,
        color: colorsSerie[i],
        data: this.calculateElementsF29(labels, element.datosReporte)
      });
    });
    return tempSeries;
  }

  /**
   * @description
   * Setea los datos de la serie para el grafico F29
   * @param colorsSerie 
   * @param labels 
   * @returns 
   */
  // REVISAR
  setSerieToGraphComprasVentasNetas(informacionVentas: any, colorsSerie: string[], labels: any[]): any[] {
    const tempSeries: any = [];

    labels.forEach((value: any, e: number) => {
      [informacionVentas[0]].forEach((element: any, i: number) => {
        tempSeries.push({
          name: element.periodo,
          color: colorsSerie[e],
          data: this.calculateElementsF29(value, element.datosReporte)
        });
      });
    });
    return tempSeries;
  }

  /**
   * @description
   * Calcula los valores de los indicadores recibidos
   * @param labels 
   * @param element 
   * @returns 
   */
  calculateElements(labels: any[], element: any): any[] {
    const tempData: any[] = [];
    labels.forEach((e: any) => {
      e.variable2? 
      tempData.push(element?.calculado?.[e.variable] && !isNaN(element?.calculado?.[e.variable]) ? (
        !e.percent ?
          element.calculado[e.variable] : Number((element.calculado[e.variable] / element.calculado[e.variable2]).toFixed(2))) : 0)
          :  
      tempData.push(element?.calculado?.[e.variable] && !isNaN(element?.calculado?.[e.variable]) ? (
        !e.percent ?
          element.calculado[e.variable] : Number(element.calculado[e.variable].toFixed(2))) : 0)
    });
    return tempData;
  }

  /**
   * @description
   * Calcula los valores de los indicadores recibidos
   * @param labels 
   * @param element 
   * @returns 
   */
  calculateElementsF29(labels: any, element: any): any[] {
    const tempData: any[] = [];
    element.forEach((e: any) => {
      tempData.push(e?.[labels.variable] ? (
        !labels.percent ?
          e[labels.variable] : Number(e[labels.variable].toFixed(2))) : '')
    });
    return tempData;
  }

}
