import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'angular-google-charts';
import * as moment from 'moment';
import { BienesRaicesText } from 'src/app/models/bienes-raices/bienes-raices.model';
import { UtilsService } from 'src/app/shared/services/utils.service';

interface IChart {
  title: string;
  type: ChartType;
  data: any[][];
  columns: string[];
  options: any[];
  width: number;
  height: number;
}

@Component({
  selector: 'app-bienes-raices-viewer',
  templateUrl: './bienes-raices-viewer.component.html',
  styleUrls: ['./bienes-raices-viewer.component.scss']
})
export class BienesRaicesViewerComponent implements OnInit {
  @Input() content: any = null;
  @Input() showHeader: boolean = true;
  @Input() fromConsolidado: boolean = false;
  public header: string[] = [
    'rol_predio',
    'rol_manzana',
    'cod_comuna',
    'direccion',
    'comuna',
    'ciudad',
    'region',
    'serie',
    'ubicacion',
    'destino',
    'avaluo_exento',
    'anio_exencion',
    'total_a_pagar',
    'avaluo_total'
  ];

  public BienesRaicesText: any = BienesRaicesText;

  constructor() {


  }


  ngOnInit(): void {
  }

  transformNumber(title: string): boolean {
    const valuesKey = ['avaluo_exento', 'avaluo_total', 'total_a_pagar'];
    return valuesKey.includes(title);
  }

  setNumber(num: string): number {
    return Number(num);
  }

}
