import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/components/_alert';
import { rutValidate } from 'rut-helpers';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { RUT } from 'src/app/shared/utils/rut';
import { IPoderJudicialCreateReportResponse } from 'src/app/interface/IPoderJudicialCreateReportResponse';
import { PdfGeneratorService } from 'src/app/shared/services/pdf-generator.service';
import { ReporteJudicialService } from 'src/app/shared/services/reporte-judicial.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

interface PoderJudicialReportFormValues {
  rut: string;
}

@Component({
  selector: 'app-reporte-judicial-consultar',
  templateUrl: './reporte-judicial-consultar.component.html',
  styleUrls: ['./reporte-judicial-consultar.component.scss']
})
export class ReporteJudicialConsultarComponent implements OnInit {
  public activateRutHelper = false;
  // TODO: I need to update the system access for this view
  private access: string[] = [ESystemAccess.REPORTE_PODERJUDICIAL, ESystemProfileList.WEB];
  public hasUserAccess = false;

  public poderJudicialReportForm: FormGroup;
  public poderJudicialReportDatasource: IPoderJudicialCreateReportResponse | null = null;
  public showReport = false;

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private _poderJudicialService: ReporteJudicialService,
    private _generatePDFService: PdfGeneratorService,
    private _sessionService: SessionService,
    private formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {
    this.poderJudicialReportForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
      tipo: [false, Validators.requiredTrue],
    }) as FormGroupTyped<PoderJudicialReportFormValues>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
  }

  onChanges(): void {
    this.poderJudicialReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  public createReport(): void {
    const { rut, tipo} = this.poderJudicialReportForm.value;

    if (!rutValidate(rut)) {
      return;
    }

    this.spinner.show();
    this._poderJudicialService.createReport(RUT.applyBackendFormat(rut), tipo)
      .subscribe(
        (data) => {
          this.spinner.hide();
          this.poderJudicialReportDatasource = data;
          this.clearForm();
          this.showReport = true;
        },
        ({ error }) => {
          this.alertService.error(error.message);
          this.spinner.hide();
        }
      )
  }

  public closeReport(): void {
    this.poderJudicialReportDatasource = null;
    this.showReport = true;
  }

  public clearForm(): void {
    this.poderJudicialReportForm.reset();
    this.activateRutHelper = false;
  }

  public async downloadReportSelected(): Promise<void> {
    const transactionId = this.poderJudicialReportDatasource?.poderJudicialReporte.datosBasicosSolicitud.IDTransaccion || '';
    const tipoReporte = "poderJudicial";

    const contentPDF = await this._generatePDFService.downloadReportSelected(transactionId, tipoReporte);
    if (!contentPDF) {
      this.alertService.warn("No se pudo descargar el archivo PDF");
      this.spinner.hide();
      return;
    }

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', contentPDF.URLReporte);
    link.setAttribute('download', `file.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

}
