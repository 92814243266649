import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { IFormularioF29FullReporteModel } from 'src/app/models/formulario-f29/formulario-f29.model';
import { AlertService } from 'src/app/components/_alert';
import { SessionService } from 'src/app/shared/services/session.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormularioF22Service } from 'src/app/shared/services/formulario-f22.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

interface ReportF22FormValue {
  rut: string;
}

@Component({
  selector: 'app-reporte-renta-buscar',
  templateUrl: './reporte-renta-buscar.component.html',
  styleUrls: ['./reporte-renta-buscar.component.scss']
})
export class ReporteRentaBuscarComponent implements OnInit {
  public activateRutHelper = false;
  private access: string[] = [ESystemAccess.REPORTE_F22_BUSCAR, ESystemProfileList.WEB];
  @Input() rut: string | any;
  @Input() data: any;
  @Output() verRenta = new EventEmitter<boolean>();
  @Output() verCrearRenta = new EventEmitter<boolean>();
  public today = '';
  public hasUserAccess = false;
  public periodosSeleccionados: string[] = [];
  private subscriptions: Subscription[] = [];
  public responseApi: any;
  public showReport = false;
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef> | undefined;
  public reporteFullContent!: any | null;
  public searchReportDataSource: IFormularioF29FullReporteModel[] = [];
  public textoSinInfo: string = ''; 
  public personaNatural: boolean = false;

  constructor(
    public alertService: AlertService,
    private _sessionService: SessionService,
    private spinner: NgxSpinnerService,
    private formularioF22Service: FormularioF22Service,
    private utilsService: UtilsService

  ) {

  }

  ngOnInit(): void {
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
    this.setToday();
    this.searchReport(this.data);
  }

  public searchReport(data: any): void {
    if (parseInt(this.rut.substring(0, this.rut.length - 1)) >= 50000000){
      this.textoSinInfo = 'La Renta solo aplica para persona natural';
      this.personaNatural = false;
    } else {
      this.spinner.show();
      this.periodosSeleccionados = [];
      this.responseApi = data;
      this.personaNatural = true;
      this.spinner.hide();
      data.length > 0?  (
        this.searchReportDataSource = this.formularioF22Service.obtenerPeriodosRenta(data),
        this.showReport = false
      ) : 
      this.textoSinInfo = 'Sin Información.';
    }
  }

  public displayReport(): void {
    let periodos: any[] = []
    this.spinner.show();
    if(this.periodosSeleccionados.length > 0){
      periodos = this.periodosSeleccionados;
    } else {
      if (this.checkboxes){
        for (const element of this.checkboxes) {
          periodos.push(element.nativeElement.value);
        if (periodos.length >= 3)
          break
        }
      }
    }
    this.reporteFullContent = [];
    this.reporteFullContent = this.formularioF22Service.obtenerReporteFiltradoRenta(this.responseApi, periodos);
    if(this.reporteFullContent.length > 0){
      this.spinner.hide();
      this.showReport = true;
      this.verRenta.emit(this.showReport); // Le indica al padre que ingresa a ver el reporte
      this.periodosSeleccionados = [];
    } else {
      this.textoSinInfo = 'Sin Información.';
      this.spinner.hide();
    }
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  public limpiarCheckbox(): void {
    this.periodosSeleccionados = [];
    this.checkboxes?.forEach((element) => {
      element.nativeElement.checked = false;
      element.nativeElement.disabled = false;
    });
  }

  public onCheckChange(event: any): void {
    if(event.target.checked){
      this.periodosSeleccionados.push(event.target.value);
    } else {
      this.periodosSeleccionados.splice(this.periodosSeleccionados.indexOf(event.target.value),1)
    }
    if(this.periodosSeleccionados.length >= 3){
      this.checkboxes?.forEach((element) => {
        if(!element.nativeElement.checked){
          element.nativeElement.disabled = true;
        }
      });
    } else {
      this.checkboxes?.forEach((element) => {
        if(element.nativeElement.disabled){
          element.nativeElement.disabled = false;
        }
      });
      
    }
  }

  public backToSearchForm(): void {
    this.searchReportDataSource = [];
  }

  public closeReport(): void {
    this.reporteFullContent = null;
    this.showReport = false;
    this.verRenta.emit(this.showReport); // Le indica al padre que ingresa a ver el reporte
    this.utilsService.changeSpanExtra('');
  }

  public crearBoton(): void{
    this.verCrearRenta.emit(true); // Le indica al padre que se visualice la creacion de un reporte
  }

}
