import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SessionService } from './session.service';
import { environment } from '../../../environments/environment';
import { ILibroCompraVentaReporte, IListLibroCompraVentaReporte, IResponseCalculos, ICompra, IVenta, IListLibroCompraVentaReporteListarReportes } from 'src/app/models/compra-venta/compra-venta.model';
import { Observable } from 'rxjs';

interface IElementRut {
  rutDoc: number | string;
  dvDoc: string | number;
  razonSocial: string;
}

@Injectable({
  providedIn: 'root'
})
export class CompraVentaService {
  private servicio: string = environment.backend.compraVenta.servicio;
  private endpoint: string = environment.backend.compraVenta.url;
  
  constructor(
    private http: HttpClient,
    private _sessionService: SessionService
  ) { }

  listarLibros(rut: string, periodo: string): Observable<IListLibroCompraVentaReporte[]> {
    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarReportes');
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    params = params.append("periodo", periodo);

    return this.http.get<IListLibroCompraVentaReporte[]>(`${this.endpoint}obtener`, { params });
  }

  buscarLibro(rut: string, periodo: string, mes: string): Observable<ILibroCompraVentaReporte> {
    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarTX');
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    params = params.append("periodo", periodo);
    params = params.append("mes", mes);

    return this.http.get<ILibroCompraVentaReporte>(`${this.endpoint}obtener`, { params });
  }

  procesarPeriodo(rut: string, periodo: number | number[], claveSii: string): Observable<IListLibroCompraVentaReporteListarReportes[]> {
    const payload: any = {
      rut: rut,
      periodo: periodo,
      claveSii: claveSii,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "crearReporte",
    }
    return this.http.post<IListLibroCompraVentaReporteListarReportes[]>(this.endpoint + "crear", payload);
  }

  obtenerTx(rut: string, periodo: number | number[]/*idTx: string*/): Observable<IListLibroCompraVentaReporteListarReportes[]> {
    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'listarReportes');
    params = params.append("periodo", Array.isArray(periodo) ? periodo.toString() : periodo);

    return this.http.get<IListLibroCompraVentaReporteListarReportes[]>(this.endpoint + "obtener", { params });
  }

  obtenerPagina(rut: string, pagina: number, cantidadElementos: number, tipo: string, meses: string, rutDoc: string = '', periodo: any = null, esTipoDocNC: boolean | null = null): Observable<ICompra[] | IVenta[]> {
    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarRegistros');
    params = params.append("meses", meses);
    params = params.append("operacion", tipo);
    params = params.append("pagina", pagina);
    params = params.append("registrosPagina", cantidadElementos);

    if(rutDoc){
      params = params.append("rutDoc", rutDoc);
    }

    if(periodo){
      params = params.append("periodo", periodo);
    }

    if(esTipoDocNC !== null){
      params = params.append("esTipoDocNC", esTipoDocNC);
    }

    return this.http.get<ICompra[] | IVenta[]>(this.endpoint + "obtener", { params });
  }

  obtenerCalculos(rut: string, tipo: string, meses: string, rutDoc: string = '', periodo: any = null, esTipoDocNC: boolean | null = null): Observable<IResponseCalculos> {
    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'calcularRegistros');
    params = params.append("meses", meses);
    params = params.append("operacion", tipo);

    if(rutDoc){
      params = params.append("rutDoc", rutDoc);
    }

    if(periodo){
      params = params.append("periodo", periodo);
    }

    if(esTipoDocNC !== null){
      params = params.append("esTipoDocNC", esTipoDocNC);
    }

    return this.http.get<IResponseCalculos>(this.endpoint + "obtener", { params });
  }

  descargarRegistros(rut: string, pagina: number, cantidadElementos: number, tipo: string, meses: string, rutDoc: string = '', periodo: any = null, esTipoDocNC: boolean | null = null): Observable<any> {
    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'descargarRegistros');
    params = params.append("meses", meses);
    params = params.append("operacion", tipo);
    params = params.append("pagina", pagina);
    params = params.append("registrosPagina", cantidadElementos);

    if(rutDoc){
      params = params.append("rutDoc", rutDoc);
    }

    if(periodo){
      params = params.append("periodo", periodo);
    }

    if(esTipoDocNC !== null){
      params = params.append("esTipoDocNC", esTipoDocNC);
    }

    return this.http.get<any>(this.endpoint + "obtener", { params });
  }

  obtenerListaRuts(rut: string, tipo: string, meses: string, periodo: string, esTipoDocNC: boolean | null = null): Observable<IElementRut[]> {
    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'listarRutDoc');
    params = params.append("periodo", periodo);
    params = params.append("meses", meses);
    params = params.append("operacion", tipo);

    if(esTipoDocNC !== null){
      params = params.append("esTipoDocNC", esTipoDocNC);
    }

    return this.http.get<IElementRut[]>(this.endpoint + "obtener", { params });

  }

  getTipoDocumento(codigo: number | string): string {
    const tipoDocumento = [
      { codigo: 29, descripcion: 'Factura de Inicio' },
      { codigo: 30, descripcion: 'Factura' },
      { codigo: 32, descripcion: 'Factura de ventas y servicios no afectos o exentos de IVA' },
      { codigo: 33, descripcion: 'Factura Electrónica' },
      { codigo: 34, descripcion: 'Factura no Afecta o Exenta Electrónica' },
      { codigo: 35, descripcion: 'Boleta de Ventas y Servicios (afecta)' },
      { codigo: 38, descripcion: 'Boleta de Ventas y Servicios no afectos o exentos de IVA' },
      { codigo: 39, descripcion: 'Boleta electrónica (afecta)' },
      { codigo: 40, descripcion: 'Liquidación factura' },
      { codigo: 41, descripcion: 'Boleta no afecta o exenta electrónica' },
      { codigo: 43, descripcion: 'Liquidación Factura Electrónica' },
      { codigo: 45, descripcion: 'Factura de Compra' },
      { codigo: 46, descripcion: 'Factura de Compra electrónica' },
      { codigo: 48, descripcion: 'Comprobante de pago electrónico' },
      { codigo: 55, descripcion: 'Nota de débito' },
      { codigo: 56, descripcion: 'Nota de débito electrónica' },
      { codigo: 60, descripcion: 'Nota de Crédito' },
      { codigo: 61, descripcion: 'Nota de crédito electrónica' },
      { codigo: 101, descripcion: 'Factura de Exportación' },
      { codigo: 102, descripcion: 'Factura de venta exenta a zona franca primaria (Res. Ex. N° 601, del 07.05.82)' },
      { codigo: 103, descripcion: 'Liquidación' },
      { codigo: 104, descripcion: 'Nota de débito de Exportación' },
      { codigo: 105, descripcion: 'Boleta liquidación (Res. Ex. SII N° 1423 del 1976)' },
      { codigo: 106, descripcion: 'Nota de Crédito de Exportación' },
      { codigo: 108, descripcion: 'SRF Solicitud Registro de Factura'},
      { codigo: 109, descripcion: 'Factura Turista (Res. Ex. SII N° 67 del 2009)' },
      { codigo: 110, descripcion: 'Factura de Exportación Electrónica' },
      { codigo: 111, descripcion: 'Nota de Débito de Exportación Electrónica' },
      { codigo: 112, descripcion: 'Nota de Crédito de Exportación Electrónica' },
      { codigo: 901, descripcion: 'Factura de ventas a empresas del territorio preferencial (Res. Ex. N°1057, del 25.04.85)' },
      { codigo: 902, descripcion: 'Conocimiento de Embarque (Marítimo o aéreo)' },
      { codigo: 903, descripcion: 'Documento único de Salida (DUS)' },
      { codigo: 911, descripcion: 'Declaración de Ingreso a Zona Franca Primaria' },
      { codigo: 914, descripcion: 'Declaración de Ingreso (DIN)' },
      { codigo: 919, descripcion: 'Resumen ventas de nacionales pasajes sin Factura' },
      { codigo: 920, descripcion: 'Otros registros no documentados Aumenta débito' },
      { codigo: 922, descripcion: 'Otros registros. Disminuye débito' },
      { codigo: 924, descripcion: 'Resumen ventas de internacionales pasajes sin Factura' }
    ];
    const element = tipoDocumento.find((element) => element.codigo === Number(codigo));
    if(element && element?.descripcion){
      return element?.descripcion;
    }
    return 'Sin Información';

  }

}

