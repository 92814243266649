import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
//import { SimpleModalComponent } from "ngx-simple-modal";
import { rutValidate } from 'rut-helpers';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';
import { take, map } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmModel {
  title: string;
  message?: string;
  textBoton?: string[];
  textTimer: number;
  dynamicClass?: string;
  hideConfirmButtom?: boolean;
  hideCancelmButtom?: boolean;
}

@Component({
  selector: 'app-modal-rut',
  templateUrl: './modal-rut.component.html',
  styleUrls: ['./modal-rut.component.scss']
})

export class ModalRutComponent implements  AfterViewInit {

  @Input() title: string = "";
  @Input() message: string = "";
  @Input() textBoton: string[] = [];
  @Input() textTimer: number = 0;
  @Input() dynamicClass: string = '';
  @Input() hideConfirmButtom: boolean = false;
  @Input() hideCancelmButtom: boolean = false;
  public modalRutForm!: FormGroup;

  confirmData: ConfirmModel = {
    title: "",
    message: "",
    textBoton: [],
    textTimer: 0,
    dynamicClass: "",
    hideConfirmButtom: false,
    hideCancelmButtom: false,
  };

  /*constructor(
    private formBuilder: UntypedFormBuilder,
    private solicitudCreditoService: SolicitudCreditoService) {
    super();

    this.modalRutForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]]
    });
  }*/
  public counter$: Observable<number> = new Observable;

  public counter = 0;

  constructor(public dialogRef: MatDialogRef<ModalRutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel, private formBuilder: UntypedFormBuilder,
    private solicitudCreditoService: SolicitudCreditoService) {
      this.confirmData = data;
      this.modalRutForm = this.formBuilder.group({
        rut: ['', [Validators.required, gtpRutValidator()]]
      });

      timer(0, 1000).pipe(
        // Take values until the specified duration is reached
        take(this.confirmData.textTimer + 1),
        // Subtract the emitted values from the duration to get the countdown
        map((value) => this.confirmData.textTimer - value)
      ).subscribe((counter) => {
        this.counter = counter;

        // Close the dialog when the countdown reaches zero
        if (counter === 0) {
          // this.close();
        }
      });
  }

  submit() {
    const { rut } = this.modalRutForm.value;
    if (!rutValidate(rut)) {
      this.solicitudCreditoService.validateAllFormFields(this.modalRutForm);
      return;
    }

    /*this.result = rut;
    this.close();*/
    this.dialogRef.close(rut); // validar
  }

  closeModal() {
    this.dialogRef.close();
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.confirmData.textTimer > 0) {
      this.counter$ = await timer(0, 1000).pipe(
        take(this.textTimer),
        map(() => {
          return --this.textTimer
        }));
    }
  }

}
