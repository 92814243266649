import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { NgxSpinnerService } from "ngx-spinner";
import { rutValidate } from 'rut-helpers';
import { RUT } from 'src/app/shared/utils/rut';
import { AlertService } from 'src/app/components/_alert';
import { ICarpetaTributariaElectronicaReporteModel, IListReporteModel, ReportFullFormValue } from 'src/app/models/reporte-full/reporte-full.model';
import { environment as env } from 'src/environments/environment';
import { ResultSearchModel } from 'src/app/models/reportes/reportes.model';
import { ReporteFullService } from 'src/app/shared/services/reporte-full.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-reporte-full-buscar',
  templateUrl: './reporte-full-buscar.component.html',
  styleUrls: ['./reporte-full-buscar.component.scss']
})
export class ReporteFullBuscarComponent implements OnInit, OnDestroy {
  public title = 'Buscar'
  private access: string[] = [ESystemAccess.CARPETA_TRIBUTARIA_BUSCAR, ESystemProfileList.WEB];
  public hasUserAccess = false;
  public searchReportFullForm: FormGroup;
  public activateRutHelper: boolean = false;
  public searchReportDataSource: IListReporteModel[] = [];
  public showReport = false;
  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public resultSearch: ResultSearchModel = new ResultSearchModel();
  public reporteFullContent!: ICarpetaTributariaElectronicaReporteModel | null;
  @Input() rut: string | any;
  @Input() nombreBasico: string = '';
  @Input() data: any;
  @Output() verCrearCarpeta = new EventEmitter<boolean>();
  @Output() verCarpeta = new EventEmitter<boolean>();
  public today: string = '';
  private subscriptions: Subscription[] = [];
  public textoSinInfo: string = ''; 
  public pdfDescargaCTE: string = ''; 
  public informacionErrores: any[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _sessionService: SessionService,
    public alertService: AlertService,
    private spinner: NgxSpinnerService,
    private reporteFullService: ReporteFullService,
    private utilsService: UtilsService) {

    this.searchReportFullForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
    }) as FormGroupTyped<ReportFullFormValue>;

    this.onChanges();
  }

  ngOnInit(): void {
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
    this.setToday();
    this.searchReport(this.data);
  }

  setToday() {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  onChanges(): void {
    this.searchReportFullForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  /*
  public buscarReporte(): void {
    const { rut } = this.searchReportFullForm.value;
    this.rut = rut;
    if (this.searchReportFullForm.valid) {
      this.searchReport();

    } else {
      this.alertService.warn("Rut no valido");
    }
  } */

  public backToSearchForm(): void {
    this.searchReportDataSource = [];
  }

  public searchReport(data: any): void {
    this.spinner.hide();
    data.length > 0?  (        
    this.searchReportDataSource = data.map((item: any) => {
      const newItem = {
        ...item.DatosBasicosSolicitud,
        FechaReporte: this.utilsService.formatDate(item.DatosBasicosSolicitud['FechaReporte'])
      }
      return newItem 
    }),
    this.showReport = false) : 
    this.textoSinInfo = 'Sin Información.';
      
  }

  setDetalleErrores(response: ICarpetaTributariaElectronicaReporteModel): void{
    const errores: any[] = [];

    response?.CarpetaTributariaElectronicaReporte?.Reporte?.f22.forEach(elementof22 => {
      if(elementof22.error || elementof22.message){
        if(!elementof22.tipo){
          elementof22.tipo = 'F22'
        }
        errores.push(elementof22);
      }
    });
  
    response?.CarpetaTributariaElectronicaReporte?.Reporte?.f29.forEach(elementof29 => {
      if(elementof29.error || elementof29.message){
        if(!elementof29.tipo){
          elementof29.tipo = 'F29'
        }
        errores.push(elementof29);
      }
    });

    this.informacionErrores = errores;
  }

  public displayReport(idTransaccion: string) {
    this.spinner.show();
    this.pdfDescargaCTE = '';
    this.subscriptions.push(this.reporteFullService.obtenerReporte(RUT.applyBackendFormat(this.rut), idTransaccion)
    .subscribe(
      (data) => {
        this.spinner.hide();
        if (Object.keys(data.CarpetaTributariaElectronicaReporte.Reporte).length === 0) {
          this.textoSinInfo = 'Sin Información.';
        } else {
          // validar que el pdf exista y sea un string
          this.pdfDescargaCTE = data?.pdf && typeof data?.pdf === 'string' ? data?.pdf : (
            data?.CarpetaTributariaElectronicaReporte?.Reporte?.pdf && typeof data?.CarpetaTributariaElectronicaReporte?.Reporte?.pdf === 'string' ? data?.CarpetaTributariaElectronicaReporte?.Reporte?.pdf : (
              ""
            )
          );
          this.setDetalleErrores(data);
          this.reporteFullContent = data;
          this.showReport = true;
          this.verCarpeta.emit(this.showReport); // Le indica al padre que ingresa a ver el reporte
        }
      },
      ({error}) => {
        this.alertService.error(error.message || "Ha ocurrido un error inesperado.");
        this.spinner.hide();
      }
    ));
  }


  public closeReport(): void {
    this.reporteFullContent = null;
    this.showReport = false;
    this.verCarpeta.emit(this.showReport); // Le indica al padre que ingresa a ver el reporte
    this.utilsService.changeSpanExtra('');
  }

  public clearForm(): void {
    this.searchReportFullForm.reset();
    this.activateRutHelper = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public crearBoton(): void{
    this.verCrearCarpeta.emit(true); // Le indica al padre que se visualice la creacion de un reporte
  }

}
