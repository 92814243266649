import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
//import { rutValidate, RutValidator } from 'ng9-rut';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { IBoletinConcursalCreateReportResponse } from 'src/app/interface/IBoletinConcursalCreateReportResponse';
import { DatosBasicosSolicitud } from 'src/app/interface/IBoletinConcursalSearchReportResponse';
import { BoletinConcursalService } from 'src/app/shared/services/boletin-concursal.service';
import { PdfGeneratorService } from 'src/app/shared/services/pdf-generator.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { RUT } from 'src/app/shared/utils/rut';
import { environment as env } from 'src/environments/environment';
import { ResultSearchModel } from 'src/app/models/reportes/reportes.model';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

interface BoletinConcursalReportFormValues {
  rut: string;
  informeComercial: boolean;
}

@Component({
  selector: 'app-boletin-concursal-buscar',
  templateUrl: './boletin-concursal-buscar.component.html',
  styleUrls: ['./boletin-concursal-buscar.component.scss']
})
export class BoletinConcursalBuscarComponent implements OnInit {
  public activateRutHelper = false;
  private access: string[] = [ESystemAccess.REPORTE_BOLETINCONCURSAL_BUSCAR, ESystemProfileList.WEB];
  public hasUserAccess = false;

  public boletinConcursalReportForm: FormGroup;
  public searchReportDataSource: DatosBasicosSolicitud[] = [];
  public listaReportesFiltrado: any[] = [];
  public boletinConcursalReportDataSource: IBoletinConcursalCreateReportResponse | null = null;
  public showReport = false;

  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public resultSearch: ResultSearchModel = new ResultSearchModel();

  public inputText = '';
  public order = '';
  public campoOrder = '';
  public mapeoEstados: any = {
    "IN-PROGRESS": "En Progreso",
    "COMPLETED": "Completado",
    "ERROR": "Error"
  }

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private _boletinConcursalService: BoletinConcursalService,
    private _sessionService: SessionService,
    //private rutValidator: RutValidator,
    private formBuilder: UntypedFormBuilder,
    private _generatePDFService: PdfGeneratorService,
    private utilsService: UtilsService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {
    this.boletinConcursalReportForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
      informeComercial: [false, Validators.requiredTrue]
    }) as FormGroupTyped<BoletinConcursalReportFormValues>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
  }

  onChanges(): void {
    this.boletinConcursalReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  filtrarObjeto(): void {
    this.currentPage = 0;
    this.listaReportesFiltrado = this.inputText != '' ? this.searchReportDataSource.filter((item: any) => 
    item?.IDTransaccion?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.EstadoTransaccionMapeada?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.FechaFormatted?.toUpperCase().includes(this.inputText.toUpperCase())) : this.searchReportDataSource;
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      if(campo === 'FechaReporte'){
        this.listaReportesFiltrado.sort((a, b) => {
          const fechaA: any = new Date(a.FechaReporte);
          const fechaB: any = new Date(b.FechaReporte);
          return (this.utilsService.dateIsValid(fechaA) ? fechaA : new Date("1000")) - (this.utilsService.dateIsValid(fechaB) ? fechaB : new Date("1000"));
        });
      } else {
        this.listaReportesFiltrado.sort((a, b) => {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return textA.localeCompare(textB);
        });
      }
    } else {
      if(campo === 'FechaReporte'){
        this.ordenarDescFecha();
      } else {
        this.listaReportesFiltrado.sort((a, b) => {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return (-1 * textA.localeCompare(textB));
        });
      }
    }
  }

  ordenarDescFecha(): void {
    this.listaReportesFiltrado.sort((a, b) => {
      const fechaA: any = new Date(a.FechaReporte);
      const fechaB: any = new Date(b.FechaReporte); 
      return (this.utilsService.dateIsValid(fechaB) ? fechaB : new Date("1000")) - (this.utilsService.dateIsValid(fechaA) ? fechaA : new Date("1000"));
    }); 
  }
  
  actualizarTabla(): void {
    if(this.resultSearch.rut){
      this.consultaBuscarReportes(this.resultSearch.rut);
    }
  }

  consultaBuscarReportes(rut: string): void {
    this.spinner.show();
    this._boletinConcursalService.searchReportsList(RUT.applyBackendFormat(rut))
    .subscribe(
      (data) => {
        this.searchReportDataSource = data.map(e => e['datosBasicosSolicitud'])
        .map(item => {

          const newItem = {
            ...item,
            FechaFormatted: this.utilsService.formatDateHMS(item['FechaReporte']),
            EstadoTransaccionMapeada: item['EstadoTransaccion'] ? this.mapeoEstados[item['EstadoTransaccion']] : item['EstadoTransaccion'],
          }
          return newItem
        })
        .filter((f) => f.EstadoTransaccion === 'COMPLETED');
        
        if(this.searchReportDataSource.length === 0) {
          this.alertService.warn("Rut sin Información");
        } else {

          this.filtrarObjeto();
          this.ordenarDescFecha();

          let razon = '';

          for (const objKey of Object.keys(data[0])) {
            const valor = this.utilsService.getNested(data[0], objKey, 'NombreORazonSocial');
            razon = valor ? valor : razon;
          }

          this.resultSearch = new ResultSearchModel(razon, rut);
        }
        
        this.clearForm();
        this.spinner.hide();
      },
      ({error}) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    )
  }

  public searchReport() {
    const { rut } = this.boletinConcursalReportForm.value;

    if (!rutValidate(rut)) {
      return;
    }

    this.consultaBuscarReportes(rut);
    
  }

  public displayReport(idTransaccion: string) {
    this.spinner.show();
    this._boletinConcursalService.getReport(idTransaccion)
    .subscribe(
      (data) => {
        this.spinner.hide();
        if (Object.keys(data.boletinConcursalReporte.principal).length === 0) {
          this.alertService.warn('Cliente sin Información en Boletin Concursal');
        } else {
          this.boletinConcursalReportDataSource = data;
          this.showReport = true;
        }
      },
      ({error}) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    )
  }

  public async downloadReport(idTransaccion: string) {
    const tipoReporte = 'boletinConcursal';

    const contentPDF = await this._generatePDFService.downloadReportSelected(idTransaccion, tipoReporte);
    if (!contentPDF) {
      this.alertService.warn("No se pudo descargar el archivo PDF");
      this.spinner.hide();
      return;
    }

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', contentPDF.URLReporte);
    link.setAttribute('download', `file.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public backToSearchForm() {
    this.searchReportDataSource = [];
  }

  public closeReport() {
    this.boletinConcursalReportDataSource = null;
    this.showReport = false;
  }

  public clearForm() {
    this.boletinConcursalReportForm.reset();
    this.activateRutHelper = false;
  }

}
