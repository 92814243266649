<div class="modal-content-confirm">
    <div class="modal-header">
        <h4>{{ confirmData.title || 'Confirm'}}</h4>
    </div>
    <div class="modal-body">
        <p>{{ confirmData.message || 'Are you sure?'}}</p>
    </div>

    <!-- ASI ESTABA ANTES -->
    <!-- <div class="modal-body" [ngClass]="dynamicClass? dynamicClass: ''">
        <p>{{message || 'Are you sure?'}}</p>
    </div> -->
    
    <div class="modal-footer">
        <p class="custom-timer" *ngIf="counter$ | async as counter">
            {{(counter < 10 && counter !== 0? '0': '') + counter}}s
        </p>
        <button type="button" class="btn btn-outline-danger" (click)="close()" *ngIf="confirmData.hideCancelmButtom !== true">
            {{confirmData.textBoton && confirmData.textBoton.length > 0?  confirmData.textBoton[1]: 'Cancel'}}
        </button>
        <button type="button" class="btn btn-primary" (click)="confirm()" *ngIf="confirmData.hideConfirmButtom !== true">
            {{confirmData.textBoton && confirmData.textBoton.length > 0? confirmData.textBoton[0]: 'OK'}}
        </button>
    </div>
</div>