<div class="break-box">
    <div class="row">
        <!-- TABLA Informacion Balance -->
        <div class="col-sm-12 mb-4">
            <div class="card textos-campo">
                <h5 class="card-header list-group-item cuadro-color">Información Balance</h5>
                <div class="card-body">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table" id="table-balance">
                                <tbody>
                                    <tr>
                                        <th>Rut</th>
                                        <td colspan="2">
                                            <span *ngIf="rut">
                                                {{rut | rutFormat | uppercase}}
                                            </span>
                                        </td>
                                    </tr>
                                    <ng-container *ngFor="let item of headerBalance">
                                        <tr>
                                            <th width="30%">{{balanceReporteFullInformacionBalance[item]}}</th>
                                            <td *ngFor="let data of informacionBalance">
                                                <span *ngIf="data.calculado">
                                                    {{data.calculado[item]}}
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- TABLA Resumen Financiero -->

        <div class="col-sm-12 mb-4">
            <div class="card textos-campo">
                <h5 class="card-header list-group-item cuadro-color">Resumen Financiero</h5>
                <div class="card-body">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table" id="table-financiero">
                                <thead>
                                    <tr>
                                        <th class="border-0 py-1 text-center"></th>
                                        <ng-container *ngFor="let data of informacionBalance">
                                            <ng-container *ngIf="data.calculado && data?.calculado?.fecha !== ''">
                                                <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                            </ng-container>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let item of headerResumenFinanciero">
                                        <tr>
                                            <th width="30%">{{balanceReporteFullResumenFinanciero[item]}}</th>
                                            <td *ngFor="let data of informacionBalance"
                                                [attr.width]="70/informacionBalance.length + '%'">
                                                <span *ngIf="data.calculado">
                                                    <ng-template #percent>
                                                        {{data.calculado[item] | number: '1.0-2'}}
                                                    </ng-template>

                                                    <ng-container *ngIf="!getPercents(item); else percent">
                                                        {{data.calculado[item] | number: '1.0-0'}}
                                                    </ng-container>
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="break-box">
    <div class="row">
        <!-- TABLA Activos -->
        <div class="col-sm-12 mb-4">
            <div class="card textos-campo">
                <h5 class="card-header list-group-item cuadro-color">Activos</h5>
                <div class="card-body">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table" id="table-activos">
                                <thead>
                                    <tr>
                                        <th class="border-0 py-1 text-center"></th>
                                        <ng-container *ngFor="let data of informacionBalance">
                                            <ng-container *ngIf="data.calculado && data?.calculado?.fecha !== ''">
                                                <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                                <th class="border-0 py-1 fw-bolder"></th>
                                            </ng-container>
                                            
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <th class="border-0"></th>
                                        <ng-container *ngFor="let item of informacionBalance">
                                            <ng-template #montoPesos>
                                                <th class="border-0 fw-bolder">Monto en $</th>
                                            </ng-template>
                                            <th class="border-0 fw-bolder" *ngIf="item?.calculado?.moneda?.toUpperCase() === 'DOLARES' else montoPesos">Monto en US $</th>
                                            <th class="border-0 fw-bolder">Valor %</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let item of headerResumenActivos">
                                        <tr>
                                            <th width="30%"><span
                                                    [ngClass]="{'fw-bolder text-uppercase': getStrongTitle(item[0])}">{{balanceReporteFullActivos[item[0]]}}</span>
                                            </th>
                                            <ng-container *ngFor="let data of informacionBalance">
                                                <td>
                                                    <span *ngIf="data.calculado">
                                                        {{data.calculado[item[0]] | number: '1.0-0'}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span *ngIf="data.calculado">
                                                        {{data.calculado[item[1]] | number: '1.0-2'}}
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- TABLA Pasivos -->

        <div class="col-sm-12 mb-4">
            <div class="card textos-campo">
                <h5 class="card-header list-group-item cuadro-color">Pasivos</h5>
                <div class="card-body">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table" id="table-pasivos">
                                <thead>
                                    <tr>
                                        <th class="border-0 py-1 text-center"></th>
                                        <ng-container *ngFor="let data of informacionBalance">
                                            <ng-container *ngIf="data.calculado && data?.calculado?.fecha !== ''">
                                                <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                                <th class="border-0 py-1 fw-bolder"></th>
                                            </ng-container>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <th class="border-0"></th>
                                        <ng-container *ngFor="let item of informacionBalance">
                                            <ng-template #montoPesos>
                                                <th class="border-0 fw-bolder">Monto en $</th>
                                            </ng-template>
                                            <th class="border-0 fw-bolder" *ngIf="item?.calculado?.moneda?.toUpperCase() === 'DOLARES' else montoPesos">Monto en US $</th>
                                            <th class="border-0 fw-bolder">Valor %</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let item of headerResumenPasivos">
                                        <tr>
                                            <th width="30%"><span
                                                    [ngClass]="{'fw-bolder text-uppercase': getStrongTitle(item[0])}">{{balanceReporteFullPasivos[item[0]]}}</span>
                                            </th>
                                            <ng-container *ngFor="let data of informacionBalance">
                                                <td>
                                                    <span *ngIf="data.calculado">
                                                        {{data.calculado[item[0]] | number: '1.0-0'}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span *ngIf="data.calculado">
                                                        {{data.calculado[item[1]] | number: '1.0-2'}}
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="break-box">
    <div class="row">
        <!-- TABLA Estado de Resultados -->
        <div class="col-sm-12 mb-4">
            <div class="card textos-campo">
                <h5 class="card-header list-group-item cuadro-color">Estado de Resultados</h5>
                <div class="card-body">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table" id="table-estados">
                                <thead>
                                    <tr>
                                        <th class="border-0 py-1 text-center"></th>
                                        <ng-container *ngFor="let data of informacionBalance">
                                            <ng-container *ngIf="data.calculado && data?.calculado?.fecha !== ''">
                                                <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                                <th class="border-0 py-1 fw-bolder"></th>
                                            </ng-container>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <th class="border-0"></th>
                                        <ng-container *ngFor="let item of informacionBalance">
                                            <ng-template #montoPesos>
                                                <th class="border-0 fw-bolder">Monto en $</th>
                                            </ng-template>
                                            <th class="border-0 fw-bolder" *ngIf="item?.calculado?.moneda?.toUpperCase() === 'DOLARES' else montoPesos">Monto en US $</th>
                                            <th class="border-0 fw-bolder">Valor %</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let item of headerResumenEstadoResultados">
                                        <tr>
                                            <th width="30%">{{balanceReporteFullEstadoResultados[item[0]]}}</th>
                                            <ng-container *ngFor="let data of informacionBalance">
                                                <td>
                                                    <span *ngIf="data.calculado">
                                                        {{data.calculado[item[0]] | number: '1.0-0'}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span *ngIf="data.calculado">
                                                        {{data.calculado[item[1]] | number: '1.0-2'}}
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- TABLA Indicadores Financieros -->

        <div class="col-sm-12 mb-4">
            <div class="card textos-campo">
                <h5 class="card-header list-group-item cuadro-color">Indicadores Financieros</h5>
                <div class="card-body">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table" id="table-indicadores">
                                <thead>
                                    <tr>
                                        <th class="border-0 py-1 text-center"></th>
                                        <ng-container *ngFor="let data of informacionBalance">
                                            <ng-container *ngIf="data.calculado && data?.calculado?.fecha !== ''">
                                                <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                            </ng-container>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let item of headerResumenIndicadoresFinancieros">
                                        <tr>
                                            <th width="30%">
                                                <span
                                                    [ngClass]="{'fw-bolder text-uppercase': getStrongTitle(item)}">
                                                    {{balanceReporteFullIndicadoresFinancieros[item]}}
                                                </span>
                                            </th>
                                            <td *ngFor="let data of informacionBalance"
                                                [attr.width]="70/informacionBalance.length + '%'">

                                                <ng-template #noNumber>
                                                    {{data.calculado[item]}}
                                                </ng-template>

                                                <span *ngIf="data.calculado">

                                                    <ng-container *ngIf="isNumber(data.calculado[item]); else noNumber">

                                                        <ng-template #percent>
                                                            {{data.calculado[item] | number: '1.0-2'}}
                                                        </ng-template>
    
                                                        <ng-container *ngIf="!getPercents(item); else percent">
                                                            {{data.calculado[item] | number: '1.0-0'}}
                                                        </ng-container>

                                                    </ng-container>
                                                    

                                                </span>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
