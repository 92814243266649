<div class="container__form">
    <div class="col-md-6">
        <div class="block">
            <div class="block-title">
                <h2>Seleccione la organizacion</h2>
            </div>
            <form [formGroup]="organizationForm" (ngSubmit)="selectOrganization()">
                <div class="form-horizontal form-bordered">
                    <div class="form-group">
                        <label class="col-md-3 control-label" for="example-select">Organizacion</label>
                        <div class="col-md-9">
                            <select class="form-control" (change)="changeOrganization($event)"
                                formControlName="organization">
                                <option value="" disabled>Elige la organización</option>
                                <option *ngFor="let organization of organizations" [ngValue]="organization">
                                    {{organization}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group form-actions container__actions">
                        <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i>Seleccionar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>