import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { AlertService } from 'src/app/components/_alert';
import { environment as env } from '../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MallaSocietariaService {
  private endpoint: string = env.backend.MallaSocietaria.url;
  private servicio: string = env.backend.MallaSocietaria.servicio;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService,
    private alertService: AlertService
  ) { }

  buscarTransaccion(idTransaccion: string, rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("id_transaccion", idTransaccion);
    params = params.append("requestOrigin", "WEB");
    params = params.append("rut", rut);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "buscarReporte");
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    return this.http.get<any>(`${this.endpoint}obtener`, { params })
  }

  crearReporte(rut: string, usuario: string = '', password: string = ''): Observable<any> {
    if(!rut.includes("-")) {
      const lastChar = rut[rut.length - 1];
      const rutCut = rut.substring(0, rut.length - 1);
      rut = rutCut + '-' + lastChar;
    }
    const request = {
      rut: rut,
      usuario: usuario,
      password: password,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "crearReporte"
    };
    return this.http.post<any>(`${this.endpoint}crear`, request);
  }

  buscarActualizado(rut: string): Observable<any> {
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("rut", rut);
    params = params.append("requestOrigin", "WEB");
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "buscarActualizado");
    
    return this.http.get<any>(`${this.endpoint}obtener`, { params })
  }

  listarReportes(rut: string): Observable<any> { 
    const lastChar = rut[rut.length - 1];
    const rutCut = rut.substring(0, rut.length - 1);
    rut = rutCut + '-' + lastChar;
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("rut", rut);
    params = params.append("requestOrigin", "WEB");
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "listarReportes");
    
    return this.http.get<any>(`${this.endpoint}obtener`, { params })
  }

}
