import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
//import { SimpleModalService } from 'ngx-simple-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CarpetaTriburaiaModel } from 'src/app/models/solicitud-credito/carpeta-tributaria.model';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';
import { ListErrorsDialogComponent } from '../../list-errors-dialog/list-errors-dialog.component';
import { AlertService } from '../../_alert';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-informe',
  templateUrl: './upload-informe.component.html',
  styleUrls: ['./upload-informe.component.scss']
})
export class UploadInformeComponent implements OnInit, OnDestroy {
  public now = Date.now();
  @Input() step: boolean = false;
  @Input() idTransaccion: string = '';
  @Input() rut: string = '';
  @Input() stepper!: number;
  public fileName: boolean = false;
  public seeContent: boolean = false;
  public formulario!: UntypedFormGroup;
  public informeTributario: CarpetaTriburaiaModel[] = [];
  private tempFile!: any;
  private subscriptions: Subscription[] = [];
  public advertencias: any[] = [];
  public fecha: any = {
    tipo: '',
    dato: ''
  };

  constructor(
    public alertService: AlertService,
    private solicitudCreditoService: SolicitudCreditoService,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.obtenerFlujo();
    this.initForm()
  }

  obtenerFlujo() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.spinner.hide();
      const pasosCompleted = resp.stages.filter((e: any) => e.completed);
      const cantidad = pasosCompleted.length;
      const pasoActual = Number(pasosCompleted[cantidad - 1].stageID) + 2;
      
      if (pasoActual < this.stepper) {
        this.alertService.error(`Debe completar el paso N° ${pasoActual} para poder continuar`);
        this.solicitudCreditoService.setStep(pasoActual);
      } else {
        
        if (pasoActual > this.stepper) {
          this.spinner.show();
          this.solicitudCreditoService.searchSolicitudesCreditoID(this.idTransaccion, this.rut).subscribe(resp => {
            this.spinner.hide();
            !this.step ? this.getDateForTypeInforme(resp, 'datosInternos') : this.getDateForTypeInforme(resp, 'carpetaTributaria');
          }, (error: any) => {
            this.alertService.error(error.error.message || 'Ocurrio un Error');
            this.spinner.hide();
          });
        } else {
          this.step ? this.getCarpetaTributaria() : this.getDatosInternos();
        }
      }
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    }));
  }

  getDatosInternos(): void {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.buscarDatosInternos(this.rut, this.idTransaccion).subscribe(resp => {
      this.spinner.hide();
      this.getDateForTypeInforme(resp, 'datosInternos');
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    }));
  }

  getCarpetaTributaria(): void {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.buscarCarpetaTributaria(this.rut, this.idTransaccion).subscribe(resp => {
      this.spinner.hide();
      this.getDateForTypeInforme(resp, 'carpetaTributaria');
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    }));
  }

  initForm(): void {
    this.formulario = this.formBuilder.group({
      file: [null]
    })
  }

  getDateForTypeInforme(obj: any, type: string) {
    if (type === 'datosInternos') {
      const automatic = obj.SolicitudDeCredito?.Reportes?.camposPersonalizadosAutomatic;
      const fecha = automatic?.DatosBasicosSolicitud?.EstadoTransaccion === 'COMPLETED'? automatic?.DatosBasicosSolicitud?.FechaReporte: false;
      fecha ? (this.fecha.dato = this.solicitudCreditoService.formatDate(fecha), this.fecha.tipo = 'fecha') : (this.fecha.dato = 'Sin información', this.fecha.tipo = 'text');
      this.advertencias = automatic.Advertencias;

    } else {
      const fecha = obj.SolicitudDeCredito?.Reportes?.carpetaTributariaElectronica?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.FechaReporte;
      fecha ? (this.fecha.dato = this.solicitudCreditoService.formatDate(fecha), this.fecha.tipo = 'fecha') : (this.fecha.dato = 'Sin información', this.fecha.tipo = 'text');
      if (this.fecha.tipo !== 'text') {
        this.solicitudCreditoService.orderDataCarpetaTributaria(obj).subscribe(resp => {
          this.informeTributario = resp;          
        });
      }
    }
  }

  submit() {
    this.spinner.show();
    const service = !this.step ? 'uploadDatosInternos' : 'uploadCarpetaTributaria';
    this.solicitudCreditoService[service](this.rut, this.idTransaccion, this.tempFile.toString())
      .subscribe(resp => {
        !this.step ? this.getDateForTypeInforme(resp, 'datosInternos') : this.getDateForTypeInforme(resp, 'carpetaTributaria');
        this.spinner.hide();
        this.fileName = true;
      }, (error: any) => {
        this.spinner.hide();
        const msg = error.error.message ? error.error.message : (error.error.SolicitudDeCredito?.Reportes?.carpetaTributariaElectronica?.message || 'Ocurrio un Error');
        this.alertService.error(msg);
      })
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.tempFile = reader.result;
    };
  }

  changeStep() {
    this.solicitudCreditoService.setStep('next');
  }

  showContent() {    
    if (this.informeTributario.length > 0) {
      this.seeContent = !this.seeContent;
    } else {
      this.alertService.error('Debe procesar primero un archivo');
    }
  }

  showErrors():void {
    const dialogRef = this.dialog.open(ListErrorsDialogComponent, {
      data: { // validar
        title: 'Solicitud de Crédito',
        errors: this.advertencias
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
        if (resp.confirm) {
          this.tempFile = resp.file;
          this.submit();
        }
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
    })

   /*const resp = this.simpleModalService.addModal(ListErrorsDialogComponent, {
      title: 'Solicitud de Crédito',
      errors: this.advertencias
    }).subscribe((resp) => {
      if (resp.confirm) {
        this.tempFile = resp.file;
        this.submit();
      }
    });*/
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
