import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UsuariosService } from 'src/app/shared/services/usuarios.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { AlertService } from 'src/app/components/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { AfterViewInit, Component, Inject, Input } from '@angular/core';
//import { SimpleModalComponent } from "ngx-simple-modal";
import { take, map } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmModel {
  title: string;
  message?: string;
  username?: string;
  textBoton?: string[];
  dynamicClass?: string;
  hideConfirmButtom?: boolean;
  hideCancelmButtom?: boolean;
  textTimer: number;
}

@Component({
  selector: 'app-modal-change-pass',
  templateUrl: './modal-change-pass.component.html',
  styleUrls: ['./modal-change-pass.component.scss']
})
export class ModalChangePassComponent implements AfterViewInit {
  @Input() title: string = "";
  @Input() message: string = "";
  @Input() username: string = "";
  @Input() textBoton: string[] = [];
  @Input() textTimer: number = 0;
  @Input() dynamicClass: string = '';
  @Input() hideConfirmButtom: boolean = false;
  @Input() hideCancelmButtom: boolean = false;
  public modalPassForm!: FormGroup;
  public passError = false;
  public confirmPassError = false;

  confirmData: ConfirmModel = {
    title: "",
    message: "",
    username: "",
    textBoton: [],
    textTimer: 0,
    dynamicClass: "",
    hideConfirmButtom:  false,
    hideCancelmButtom:  false
  };

  /*constructor(
    private formBuilder: UntypedFormBuilder,
    private _usuariosService: UsuariosService,
    private utilsService: UtilsService,
    public alertService: AlertService,
    private spinner: NgxSpinnerService,
  ) {
    super();

    this.modalPassForm = this.formBuilder.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });
  }*/

  // timer: Observable<number>;
  public counter$: Observable<number> = new Observable;

  public counter = 0;

  constructor(public dialogRef: MatDialogRef<ModalChangePassComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel,
    private formBuilder: UntypedFormBuilder,
    private _usuariosService: UsuariosService,
    private utilsService: UtilsService,
    public alertService: AlertService,
    private spinner: NgxSpinnerService,) {
      this.confirmData = data;
      this.modalPassForm = this.formBuilder.group({
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]]
      });

      timer(0, 1000).pipe(
        // Take values until the specified duration is reached
        take(this.confirmData.textTimer + 1),
        // Subtract the emitted values from the duration to get the countdown
        map((value) => this.confirmData.textTimer - value)
      ).subscribe((counter) => {
        this.counter = counter;

        // Close the dialog when the countdown reaches zero
        if (counter === 0) {
          // this.close();
        }
      });
  }

  validaNewPass(): boolean {
    const valorPass = this.modalPassForm.get('newPassword')?.value;
    if(valorPass){
      return (/^(?=.*[+\-;,.$?¿¡])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{15,17}$/.test(valorPass))
    }
    return false;
  }
  validaLargo(): boolean {
    const valorPass = this.modalPassForm.get('newPassword')?.value;
    if(valorPass){
      return (/^.{15,17}$/.test(valorPass))
    }
    return false;

  }
  validaCaracteres(): boolean {
    const valorPass = this.modalPassForm.get('newPassword')?.value;
    if(valorPass){
      return (/^(?=.*[+\-;.,$?¿¡]).*$/.test(valorPass))
    }
    return false;

  }
  validaMayus(): boolean {
    const valorPass = this.modalPassForm.get('newPassword')?.value;
    if(valorPass){
      return (/^(?=.*[A-Z]).*$/.test(valorPass))
    }
    return false;

  }
  validaMin(): boolean {
    const valorPass = this.modalPassForm.get('newPassword')?.value;
    if(valorPass){
      return (/^(?=.*[a-z]).*$/.test(valorPass))
    }
    return false;

  }
  validaNum(): boolean {
    const valorPass = this.modalPassForm.get('newPassword')?.value;
    if(valorPass){
      return (/^(?=.*[0-9]).*$/.test(valorPass))
    }
    return false;

  }

  showSpanNewPass(): boolean {
    const valorPass = this.modalPassForm.get('newPassword')?.value;
    if(valorPass && !this.validaNewPass()){
      return true;
    }
    return false;
  }

  showSpanConfirmPass(): boolean {
    const valorPass = this.modalPassForm.get('newPassword')?.value;
    const valorConfirmPass = this.modalPassForm.get('confirmPassword')?.value;
    if(valorConfirmPass && valorPass !== valorConfirmPass){
      return true;
    }
    return false;
  }

  validaPass(): boolean {
    const valorPass = this.modalPassForm.get('newPassword')?.value;
    const valorPassConfirm = this.modalPassForm.get('confirmPassword')?.value;
    if(valorPass && valorPassConfirm){

      const inputPass = document.getElementById('password');
      const inputConfirm = document.getElementById('confirmPassword');

      if (/^(?=.*[+\-;,.$?¿¡])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{15,17}$/.test(valorPass)) {
        this.passError = false;
        if(inputPass && inputPass.classList.contains('input-error')){
          inputPass.classList.remove('input-error');
        }
      } else {
        this.passError = true;
        if(inputPass && !inputPass.classList.contains('input-error')){
          inputPass.classList.add('input-error');
        }
        return false;
      }

      if (valorPass === valorPassConfirm) {
        this.confirmPassError = false;
        if(inputConfirm && inputConfirm.classList.contains('input-error')){
          inputConfirm.classList.remove('input-error');
        }
      } else {
          this.confirmPassError = true;
          if(inputConfirm && !inputConfirm.classList.contains('input-error')){
            inputConfirm.classList.add('input-error');
          }
          return false;
      }

      return true;
      
    } else {
      return false;
    }
  }

  submit() {
    //console.log(this.modalPassForm)

    if (!this.modalPassForm.valid) {
      this.utilsService.validateAllFormFields(this.modalPassForm);
      return;
    }

    if(this.passError || this.confirmPassError){
      return;
    }

    if(!this.validaPass()){
      return;
    }

    const username: string = this.confirmData?.username || '';
    if(!username){
      console.error("No se ha recibido el nombre de usuario");
      return;
    }

    const formTemp = this.modalPassForm.getRawValue();

    this.spinner.show();
    this._usuariosService.changeUserPassword(username, formTemp?.newPassword)
    .subscribe(
      () => {
        this.closeModal();
        this.spinner.hide();
        this.alertService.success(`Se ha actualizado la contraseña del usuario ${username}.`);
      },
      ({ error }) => {
        this.closeModal();
        this.spinner.hide();
        this.alertService.error(error.message || "Ocurrio un problema al intentar cambiar la contraseña del usuario");
      }
    )
  }

  closeModal() {
    this.dialogRef.close(); // validar
    //this.close();
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.confirmData.textTimer > 0) {
      this.counter$ = await timer(0, 1000).pipe(
        take(this.textTimer),
        map(() => {
          return --this.textTimer
        }));
    }
  }

}
