<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>
<div *ngIf="hasUserAccess" afterPrint [title]='titlePrint'> 
    <div class="container__form solicitud-credito-box" id="print-section" *ngIf="informacionCliente">
        <div class="panel panel-default not-print">
            <div class="p-3">
                <div class="d-flex justify-content-between">
                    <div>
                        <button class="btn btn-primary" [routerLink]="['/solicitud-credito/consultar']">Volver</button>
                    </div>
                    <div>
                        <button class="btn btn-primary" (click)="printPdf()">Descargar PDF</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel panel-default border-0">
            <div class="panel-body">
                <div class="container-gtp">
                    <img src="assets/img/logo_salfa.png" class="img-fluid d-block mx-auto" alt="logo salfa" />
                </div>
                <h2 class="text-center mt-3">Solicitud de credito</h2>
                <h5 class="text-center"><span
                        class="badge bg-secondary text-center">{{informacionCliente.SolicitudDeCredito?.DatosBasicosSolicitud?.Rut
                        |
                        rutFormat}}</span>
                </h5>
                <h3 class="text-center mt-3 text-capitalize">
                    {{listadoLineaCreditoDetalle[informacionCliente.SolicitudDeCredito?.DatosBasicosSolicitud?.Modelo]}}
                    - N° Solicitud {{getLastDigitosTx()}}
                </h3>

                <div class="break-box">
                    <div class="card mb-5">
                        <h5 class="card-header list-group-item cuadro-title">
                            FICHA ANÁLISIS DE CRÉDITO CLIENTES
                        </h5>
                    </div>
                    <app-ficha-analisis [informacionCliente]="informacionCliente" [tipoSolicitud]="tipoSolicitud"
                    [idTransaccion]="idTransaccion" [rut]="rut" [statusTX]="statusTX" [validEdition]="validEdition"
                     (closeSolicitud)="closeSolicitud($event)">
                    </app-ficha-analisis>
                </div>
                <div class="break-box">
                    <div class="card mb-5 mt-3">
                        <h5 class="card-header list-group-item cuadro-title">
                            Estados Financieros M$
                        </h5>
                    </div>
                    <app-estados-financieros [informacionCliente]="informacionCliente" [tipoSolicitud]="tipoSolicitud">
                    </app-estados-financieros>
                </div>
                <div class="break-box">
                    <div class="card mb-3">
                        <h5 class="card-header list-group-item cuadro-title">
                            Malla Societaria
                        </h5>
                    </div>
                    <app-malla-societaria [resumen]="true" [informe]="informacionCliente"
                    [statusTX]="statusTX"></app-malla-societaria>
                </div>

                <ng-container *ngIf="showMalla">
                    <div class="break-box">
                        <app-reporte-malla-societaria [informacionMalla]="informacionMalla" [informacionGrafico]="graficaMalla" [statusTX]="statusTX" [informacionCliente]="informacionCliente"></app-reporte-malla-societaria>
                    </div>
                </ng-container>
                
                <div>
                    <div class="card mb-5">
                        <h5 class="card-header list-group-item cuadro-title">
                            Anexo
                        </h5>
                    </div>
                    <app-anexo [informacionCliente]="informacionCliente"></app-anexo>
                </div>
            </div>
        </div>
    </div>
</div>