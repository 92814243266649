<div *ngIf="hasUserAccess" class="container-gtp no-print">
    <div class="panel panel-default">
        <div class="panel-body">
            <div class="btn-option">
                <button (click)="back()" class="btn btn-primary">Volver</button>
                <!-- <button
                    [useExistingCss]="true" printSectionId="print-section" ngxPrint
                    class="btn btn-primary">Descargar PDF</button> -->
            </div>
        </div>
    </div>
</div>

<div *ngIf="noToken" class="card text-center mb-5">
    <div class="card-header">
      Credenciales
    </div>
    <div class="card-body">
      <h5 class="card-title">Usted no posee credenciales de Cobranza Online</h5>
      <p class="card-text">Debe ir a la configuración de credenciales para crearlas.</p>
      <a (click)="goTocredenciales()" class="btn btn-primary">Configurar credenciales</a>
    </div>
</div>

<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<div *ngIf="hasUserAccess && !noToken" class="row" id="print-section">
    <div class="col-12">
        <div class="container-gtp mb-5">
            <img class="banner-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt=""/>
            <h2 class="text-center pt-3">PUBLICAR MOROSIDAD</h2>
            <div class="d-flex justify-content-center">
              <!-- <h4 class="badge badge-secondary">{{rut}}</h4> -->
            </div>

            <div *ngIf="show" class="card">
                <div class="card-body">
        
                    <div class="row g-3" [formGroup]="publishForm">

                        <div class="col-12 mt-4 text-primary">
                            <h5>Datos de la empresa</h5>
                            <hr>
                        </div>

                        <!-- company_rut (INPUT)-->
                        <div class="col-md-3 col-sm-12 mt-4">
                            <div>
                                <label class="form-label">Rut receptor (empresa a publicar)*</label>
                                <input formControlName="company_rut" type="text" class="form-control" [ngClass]="{'is-invalid': isInvalid('company_rut')}"
                                >
                            </div>
                        </div>

                        <!-- company_name (INPUT)-->
                        <div class="col-md-3 col-sm-12 mt-4">
                            <div>
                                <label class="form-label">Nombre Empresa*</label>
                                <input formControlName="company_name" type="text" class="form-control" [ngClass]="{'is-invalid': isInvalid('company_name')}"
                                >
                            </div>
                        </div>

                        <div class="col-12 mt-5 text-primary">
                            <h5>Datos contacto</h5>
                            <hr>
                        </div>
                        <!-- first_name (INPUT)-->
                        <div class="col-md-3 col-sm-12 mt-4">
                            <div>
                                <label class="form-label">Nombre*</label>
                                <input formControlName="first_name" type="text" class="form-control" [ngClass]="{'is-invalid': isInvalid('first_name')}"
                                >
                            </div>
                        </div>

                        <!-- last_name (INPUT)-->
                        <div class="col-md-3 col-sm-12 mt-4">
                            <div>
                                <label class="form-label">Apellido*</label>
                                <input formControlName="last_name" type="text" class="form-control" [ngClass]="{'is-invalid': isInvalid('last_name')}"
                                >
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-12 mt-4">
                            <div>
                                <label class="form-label">Telefono*</label>
                                <input formControlName="phone" type="text" class="form-control" [ngClass]="{'is-invalid': isInvalid('phone')}"
                                >
                            </div>
                        </div>

                        <!-- email (INPUT)-->
                        <div class="col-md-6 col-sm-12 mt-4">
                            <div>
                                <label class="form-label">Email*</label>
                                <input formControlName="email" type="text" class="form-control" [ngClass]="{'is-invalid': isInvalid('email')}"
                                >
                            </div>
                        </div>
                        <!-- extra mails -->
                        <div class="col-12">
                            <div formArrayName="emails">
                                <div class="col-6 mt-4" *ngFor="let email of emails.controls; let i=index">
                                  <label class="form-label" for="email{{i}}">Email {{i+2}}:</label>
                                  <input class="form-control" [formControlName]="i" id="email{{i}}">
                                </div>
                                <button *ngIf="emails.controls.length < 3" class="mt-4 btn btn-link" (click)="addEmail()">Agregar Email</button>
                            </div>
                        </div>


                        <div class="col-12 mt-5 text-primary">
                            <h5>Datos del documento</h5>
                            <hr>
                        </div>

                        <!-- amount (INPUT)-->
                        <div class="col-md-3 col-sm-12 mt-4">
                            <div>
                                <label class="form-label">Monto*</label>
                                <input formControlName="amount" type="number" (keypress)="validateNumber($event)" class="form-control" [ngClass]="{'is-invalid': isInvalid('amount')}"
                                >
                            </div>
                        </div>

                        <!-- ndoc (INPUT)-->
                        <div class="col-md-3 col-sm-12 mt-4">
                            <div>
                                <label class="form-label">Numero de Documento*</label>
                                <input formControlName="ndoc" type="text" class="form-control" [ngClass]="{'is-invalid': isInvalid('ndoc')}"
                                >
                            </div>
                        </div>

                        <!-- creditor_rut (INPUT)-->
                        <div class="col-md-3 col-sm-12 mt-4">
                            <div>
                                <label class="form-label">Rut Emisor*</label>
                                <input formControlName="creditor_rut" type="text" class="form-control" [ngClass]="{'is-invalid': isInvalid('creditor_rut')}"
                                >
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-12 mt-4">
                            <label class="form-label">Tipo de documento*</label>
                            <select formControlName="doc_type" class="form-control form-control-sm" [ngClass]="{'is-invalid': isInvalid('doc_type')}">
                                <option value="Factura">Factura</option>
                            </select>
                        </div>

                        <div class="col-md-3 col-sm-12 mt-4">
                            <label class="form-label">DTE*</label>
                            <select formControlName="dte_type" class="form-control form-control-sm" [ngClass]="{'is-invalid': isInvalid('doc_type')}">
                                <option value="33">33</option>
                                <option value="34">34</option>
                                <option value="43">43</option>
                                <option value="110">110</option>
                            </select>
                        </div>

                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mt-4">
                            <label class="form-label">Descripción*</label>
                            <textarea formControlName="description" type="text" class="form-control" [ngClass]="{'is-invalid': isInvalid('description')}"
                            placeholder="Introducción de la descripción"></textarea>
                        </div>

                        <div class="col-12"></div>
                        <!-- emission_date -->
                        <div class="col-md-3 col-sm-12 mt-4">
                            <div>
                              <label class="form-label">Fecha de emisión*</label>
                              <input type="date" class="form-control" formControlName="emission_date" 
                                [ngClass]="{'is-invalid': isInvalid('emission_date')}" 
                                [max]="maxStartDate"
                                (change)="onStartDateChange($event)">
                            </div>
                          </div>
                        
                          <div class="col-md-3 col-sm-12 mt-4">
                            <div>
                              <label class="form-label">Fecha de vencimiento*</label>
                              <input type="date" class="form-control" formControlName="due_date" 
                                [ngClass]="{'is-invalid': isInvalid('due_date')}" 
                                [min]="minEndDate"
                                (change)="onEndDateChange($event)">
                            </div>
                          </div>

                        <!-- <h1>DOCUMENTO CHEQUEAR COMO SUBIR</h1> -->

                        <div class="col-12 mt-4">
                            <div formArrayName="cosigners">
                                <div class="col-6 mt-4" *ngFor="let cosigner of cosigners.controls; let i=index">
                                    <label class="form-label" for="cosigner{{i}}">Cofirmante {{i+1}}:</label>
                                    <input class="form-control" [formControlName]="i" id="cosigner{{i}}">
                                </div>
                                <!-- <button *ngIf="cosigners.controls.length < 3" class="mt-4 btn btn-link" (click)="addCosigner()">Agregar Cofirmantes</button> -->
                            </div>
                        </div>
                
                        <div class="col-12 mt-4 ml-4">
                            <div class="row">
                                <label class="form-label">Imagen del Documento (PDF)*</label>
                            </div>
                            <div class="row px-4 py-4 bg-light" style="max-width: 500px;">
                                <input class="input-file" id="file" type="file" formControlName="pdf"
                                name="file" accept=".pdf" (change)="handleUpload($event)" />
                            </div>
                        </div>

                <hr>
                <div class="col-12 mt-4 ml-4">
                    <button (click)="onSubmit()" class="btn  btn-primary mt-5">Publicar</button>
                </div>    
            </div>
        </div>
    </div>
</div>
