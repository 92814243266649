import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { IListBienesRaices, IReportBienesRaices } from 'src/app/models/bienes-raices/bienes-raices.model';
import { environment } from '../../../environments/environment';
import { BackendAuthService } from './backend-auth.service';
import { SessionService } from './session.service';

export interface ICreateReportBienesRaicesParams {
  rut: string;
  idOrganizacion: string;
  idUsuario: string;
  servicio: string;
  uri: string;
  requestOrigin: string;
}

@Injectable({
  providedIn: 'root'
})
export class BienesRaicesService {
  private endpoint: string = environment.backend.bienesRaices.url;
  private servicio: string = environment.backend.bienesRaices.servicio;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService) { }

  public createReport(rut: string) {

    const payload: ICreateReportBienesRaicesParams = {
      rut,
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      servicio: this.servicio,
      uri: 'bienesRaices',
      requestOrigin: "WEB"
    }

  return this.http.post(`${this.endpoint}crear`, payload);
  }
  
  buscarReportes(rut: string): Observable<IListBienesRaices[]> {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarBienesRaices');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    return this.http.get<IListBienesRaices[]>(`${this.endpoint}obtener`, { params });
  }

  obtenerReporte(rut: string, idTransaccion: string): Observable<IReportBienesRaices> {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarBienesRaices');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    params = params.append("id_transaccion", idTransaccion);
    return this.http.get<IReportBienesRaices>(`${this.endpoint}obtener`, { params });
  }

  obtenerLastUpdate(rut: string): Observable<IReportBienesRaices> {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'bienesRaicesSearchUpdated');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    return this.http.get<IReportBienesRaices>(`${this.endpoint}obtener`, { params });
  }

}
