import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class CredencialesService {
  private servicio: string = environment.backend.secretInteraction.servicio;
  private endpoint: string = environment.backend.secretInteraction.url;

  private endpointInformeComercial: string = environment.backend.secretInformeComercial.url;
  private servicioInformeComercial: string = environment.backend.secretInformeComercial.servicio;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService
  ) { }

  public inputRut: string[] = [
    'SII',
    'CLAVE_UNICA'
  ]

  listarSecretosOrgyUsuarios(pagina?: number, nElementos?: number, rut?: string): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "listarSecretosOrgyUsuarios");

    if(pagina || pagina === 0) {
      params = params.append("pagina", pagina);
    }

    if(nElementos || nElementos === 0) {
      params = params.append("nElementos", nElementos);
    }

    if(rut) {
      params = params.append("rut", rut);
    }

    return this.http.get<any>(`${this.endpoint}obtener`, { params });
  }

  obtenerCantidadElementos(): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "nElementosUsuarios");
    return this.http.get<any>(`${this.endpoint}obtener`, { params });
  }

  crearActualizarCredenciales(rut: string, servicios: any): Observable<any> {
    const payload: any = {
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      rut: rut,
      servicio: this.servicio,
      uri: "crearActualizarSecreto",
      services: servicios
    }
    return this.http.post<any>(`${this.endpoint}crear`, payload);
  }

  obtenerContrato(): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "obtenerContrato");
    return this.http.get<any>(`${this.endpoint}obtener`, { params });
  }

  validarCredenciales(username: string, password: string, service: string): Observable<any> {
    const payload: any = {
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "validarCredenciales",
      user: username,
      password: password,
      service: service
    }
    return this.http.post<any>(`${this.endpoint}crear`, payload);
  }

  validarCredencialesDinamico(request: any, service: string): Observable<any> {
    const payload: any = {
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "validarCredenciales",
      service: service
    }
    if(request && Object.keys(request).length > 0) {
      for (const key in request) {
        payload[key] = request[key];
      }
    }

    return this.http.post<any>(`${this.endpoint}crear`, payload);
  }

  borrarCredenciales(rut: string, service: string): Observable<any> {
    const payload: any = {
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      rut: rut,
      service: service,
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "borrarSecreto",
    }
    return this.http.post<any>(`${this.endpoint}crear`, payload);
  }

  listarCargasMasivas(): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "listarCargasMasivas");
    return this.http.get<any>(`${this.endpoint}obtener`, { params });
  }

  obtenerArchivoCargaMasiva(idTransaccion: string): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("idTransaccion", idTransaccion);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "obtenerArchivoCargaMasiva");
    return this.http.get<any>(`${this.endpoint}obtener`, { params });
  }

  iniciarCargaMasivaCreds(csv: string): Observable<any> {
    const payload: any = {
      idOrganizacion: this._sessionService.getOrganization(),
      idUsuario: this._sessionService.getUserInfo().username,
      requestOrigin: "WEB",
      csv: csv,
      servicio: this.servicio,
      uri: "iniciarCargaMasivaCreds"
    }
    return this.http.post<any>(`${this.endpoint}crear`, payload);
  }

  buscarSecretos(rut: string = ''): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "buscarSecretos");
    params = params.append("rut", rut);
    return this.http.get<any>(`${this.endpoint}obtener`, { params });
  }

  buscarSecretosInformeComercial(rut: string = ""): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", "WEB");
    params = params.append("rut", rut);
    return this.http.get<any>(`${this.endpointInformeComercial}buscarSecretos`, { params });
  }

}
