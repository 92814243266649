<div [ngClass]="{'container-gtp': showHeader}">
    <ng-container *ngIf="showHeader">
    <img class="banner-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt=""/>
    <h2 class="text-center">CERTIFICADO DE ANOTACIONES</h2>
    <h5 class="text-center">
        <span class="badge bg-secondary text-center">{{content.EmpresaEnUnDiaReporte.DatosBasicosSolicitud.Rut | number : '1.0-0'}}-{{content.EmpresaEnUnDiaReporte.DatosBasicosSolicitud.DV | uppercase}}</span>
    </h5>
    </ng-container>

    <div class="row">
        <div class="col-sm-12">
            <div class="card textos-campo">
                <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-file-text-o"
                        style="font-size:28px;">&nbsp;</i>CERTIFICADO DE ANOTACIONES</h5>
                <div class="card-body">
                    <table class="table table-hover table-borderless">
                        <tbody>
                            <tr>
                                <th width="12%" nowrap="nowrap" scope="row">Rut Empresa:</th>
                                <td width="88%">{{content.EmpresaEnUnDiaReporte.DatosBasicosSolicitud.Rut | number : '1.0-0'}}-{{content.EmpresaEnUnDiaReporte.DatosBasicosSolicitud.DV | uppercase}}</td>
                            </tr>
                            <tr>
                                <th nowrap="nowrap" scope="row">Nombre Empresa:</th>
                                <td>{{certificado.RazonSocial}}</td>
                            </tr>
                            <tr>
                                <th nowrap="nowrap" scope="row">Fecha Constitución:</th>
                                <td>{{certificado.FechaConstitucion}}
                                </td>
                            </tr>
                            <tr>
                                <th nowrap="nowrap" scope="row">Fecha del Certificado:</th>
                                <td>{{certificado.FechaEmisionCertificado}}
                                </td>
                            </tr>
                            <tr>
                                <th nowrap="nowrap" scope="row">Anotaciones</th>
                                <td>{{certificado.Declaracion}}</td>
                            </tr>
                            <tr>
                                <th nowrap="nowrap" scope="row">CVE</th>
                                <td>{{certificado.CodigoVerificacion}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <hr>
        <p>La información presentada en estos análisis proviene de fuentes consideradas altamente confiables. Sin
            embargo, dada la posibilidad de error humano o mecánico, no se garantiza la exactitud o integridad de la
            información y, por tanto, no se hace responsable de errores u omisiones, como tampoco de las consecuencias
            asociadas con el empleo de esa información.</p>
        <hr>
        <!--<table width="100%" border="0">
            <tbody>
                <tr>
                    <td align="center">
                        <div class="container-gtp">
                            <img src="https://chart.googleapis.com/chart?chs=99x100&cht=qr&chl=https://www.gestionatupyme.cl"
                                width="99" height="100" alt="" />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>-->
        <br>
    </div>