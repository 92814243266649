export const vehiculosText = {
    'ppu': 'Patente',
    'marca': 'Marca',
    'modelo': 'Modelo',
    'color': 'Color',
    'tipo_vehiculo': 'Tipo Vehículo',
    'anio_fabricacion': 'Año Fabricación',
    'ultima_transferencia': 'Ultima Transferencia',
    'numero_motor': 'Número Motor',
    'numero_chasis': 'Número Chasis',
    'tasacion': 'Tasación($)'
}

export interface DatosSolicitante {
    Organizacion: string;
    Usuario: string;
}

export interface DatosBasicosSolicitud {
    Rut: string;
    DV: string;
    IDTransaccion: string;
    EstadoTransaccion: string;
    SubEstadoTransaccion: string;
    FechaReporte: Date;
    EstadoTransaccionMapeada?: string;
    FechaFormatted?: string;
}

export interface IVehiculosModel {
    datosSolicitante: DatosSolicitante;
    datosBasicosSolicitud: DatosBasicosSolicitud;
}

export interface DatosSolicitante {
    Organizacion: string;
    Usuario: string;
}

export interface DatosBasicosSolicitud {
    Rut: string;
    DV: string;
    IDTransaccion: string;
    EstadoTransaccion: string;
    SubEstadoTransaccion: string;
    FechaReporte: Date;
    EstadoTransaccionMapeada?: string;
    FechaFormatted?: string;
}

export interface ListadoVehiculo {
    rut: string;
    dv: string;
    propietario: string;
    ppu: string;
    dv_ppu: string;
    marca: string;
    modelo: string;
    color: string;
    tipo_vehiculo: string;
    anio_fabricacion: string;
    ultima_transferencia: string;
    numero_motor: string;
    numero_chasis: string;
    tasacion: string;
}

export interface Vehiculos {
    listadoVehiculos: ListadoVehiculo[];
}

export interface VehiculoReporte {
    datosSolicitante: DatosSolicitante;
    datosBasicosSolicitud: DatosBasicosSolicitud;
    vehiculos: Vehiculos;
}

export interface IReporteVehiculos {
    vehiculoReporte: VehiculoReporte;
}