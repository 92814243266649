import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { IUserInfoCognito } from 'src/app/shared/services/cognito.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CobranzaOnlineApiService {

  constructor(private _http: HttpClient, private _sessionService: SessionService) { }
  
  private getHeaders(idOrganizacion: string, idUsuario: string, requestOrigin: string): HttpHeaders {
    const headers = new HttpHeaders()
      .set("IDOrganizacion", idOrganizacion)
      .set("IDUsuario", idUsuario)
      .set("requestOrigin", requestOrigin)

    return headers
  }

  public checkToken(idOrganizacion: string, idUsuario: string) { 
    const params = new HttpParams()
      .set('idUsuario', idUsuario)
      .set('idOrganizacion', idOrganizacion)
      .set('requestOrigin', "WEB")
    
    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');
   
    return this._http.get<any>(environment.backend.cobranzaOnline.url + 'obtenerToken', { params, headers  });  
  }

  public createToken(body: any) {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();
    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');
    return this._http.post<any>(environment.backend.cobranzaOnline.url + 'generarToken', body, { headers: headers  });
  }

  public getRut(idOrganizacion: string, idUsuario: string) { 
    const params = new HttpParams()
      .set('idUsuario', idUsuario)
      .set('idOrganizacion', idOrganizacion)
      .set('requestOrigin', "WEB")
   
      const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');
    return this._http.get<any>(environment.backend.cobranzaOnline.url + 'rut', { params, headers });  
  }

  public postMorosidad(body: any) {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();
    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.post<any>(environment.backend.cobranzaOnline.url + 'morosidad', body, { headers: headers });
  }
  
  
  public getMorosidades(idOrganizacion: string, idUsuario: string, rut: string | undefined, page: number = 1, searchObj: any = undefined) {
    if(!rut) return of([]);

    let params = new HttpParams()
      .set('idUsuario', idUsuario)
      .set('idOrganizacion', idOrganizacion)
      .set('requestOrigin', "WEB")
      .set('rut', rut)
      .set('page', page);

    if(searchObj) params = this.createMorosidadesHttpParams(params, searchObj);
    
    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');
    
    return this._http.get<any>(environment.backend.cobranzaOnline.url + 'listarMorosidades', { params, headers });  
  }

  private createMorosidadesHttpParams(params: HttpParams, searchObj: any): HttpParams {
  
    // Iterate over the object keys
    for (const key in searchObj) {
      if (searchObj.hasOwnProperty(key) && searchObj[key] !== '' && searchObj[key] !== null && searchObj[key] !== undefined) {
        params = params.set(key, searchObj[key]);
      }
    }
  
    return params;
  }

  public aclararMorosidad(idOrganizacion: string, idUsuario: string, rut: string | undefined, debtId: number) { 

    const body = {
      "rut": rut,
      "idOrganizacion": idOrganizacion,
      "idUsuario": idUsuario,
      "requestOrigin": "WEB",
      "debtId": debtId
    }
    
    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.post<any>(environment.backend.cobranzaOnline.url + 'aclararMorosidad', body, { headers: headers });  
  }


}
