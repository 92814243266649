import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AlertService } from 'src/app/components/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionService } from 'src/app/shared/services/session.service';
import { MallaSocietariaService } from 'src/app/shared/services/malla-societaria.service';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { Subscription } from 'rxjs';
import { OrgChart } from 'd3-org-chart';
import * as d3 from 'd3';

@Component({
  selector: 'app-reporte-malla-societaria-view',
  templateUrl: './reporte-malla-societaria-view.component.html',
  styleUrls: ['./reporte-malla-societaria-view.component.scss']
})
export class ReporteMallaSocietariaViewComponent implements OnInit {
  @Input() reporteMalla: any = {};
  @Input() rut: string = '';
  @Input() buscar: boolean = false;
  public objectKeys = Object.keys;
  private access: string[] = [ESystemAccess.REPORTE_MALLA_SOCIETARIA, ESystemAccess.REPORTE_MALLA_SOCIETARIA_BUSCAR, ESystemProfileList.WEB];
  public hasUserAccess = false;
  private subscriptions: Subscription[] = [];
  public fechaReporte: string = '';
  public showReporte: boolean = false;
  public dataOrg: any = {};
  public dataOrgPrint: any = {};
  nivel: number = 2;
  public chart: any;
  @ViewChild("chartContainer") chartContainer!: ElementRef;

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private _sessionService: SessionService,
    private mallaSocietariaService: MallaSocietariaService,
    private renderer: Renderer2,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    if(this.reporteMalla?.respuestaMalla){
      this.mapeoPrincipal(this.reporteMalla?.respuestaMalla);
      this.mapeoGrafico(true);
    }
  }

  ngAfterViewInit() {
    if (!this.chart) {
      this.chart = new OrgChart();
    }
    this.updateChart();
    this.eventClick();
  }

  validaPermisoCrear(): boolean{
    if (this._sessionService.getUserAccess().includes(this.access[0]) && !this.buscar){
      return true
    }
    return false
  }

  validaPermisoBuscar(): boolean{
    if (this._sessionService.getUserAccess().includes(this.access[1]) && !this.buscar){
      return true
    }
    return false
  }

  selectNode(nodeData: {name: string, tipo: string, rut: string, rutDisplay: string, nivel: number, children: any[] | null, searched: boolean}, id: string): void {
    if(nodeData?.nivel < 5){
      if(nodeData.searched === true){
        this.crearReport(nodeData?.rut, false, nodeData, id);
      } else {
        this.searchReport(nodeData?.rut, false, nodeData, id);
        if(this.validaPermisoCrear()){
          nodeData.searched = true;
        }
      }
    }
  }

  public crearReport(rut: string, principal: boolean = true, nodeData?: any, id?: string): void {
    this.spinner.show();
    this.subscriptions.push(this.mallaSocietariaService.crearReporte(rut).subscribe(
      (data: any) => {
        if(principal){
          this.fechaReporte = data?.MallaSocietariaReporte?.DatosBasicosSolicitud?.FechaReporte || '';
          if(data?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla){
            this.mapeoPrincipal(data?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla);
          } else {
            this.alertService.error('No se logró recuperar la información de socios y sociedades')
          }
        } else {
          this.mapeoSecundario(data?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla, nodeData)
        }
        this.mapeoGrafico(false, id);
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error?.message || 'Error al actualizar el reporte del rut indicado')
        this.spinner.hide();
      }));
  }

  public searchReport(rut: string, principal: boolean = true, nodeData?: any, id?: string): void {
    this.spinner.show();
    this.subscriptions.push(this.mallaSocietariaService.buscarActualizado(rut).subscribe(
      (data: any) => {
        if(principal){
          this.showReporte = true;
          this.fechaReporte = data?.MallaSocietariaReporte?.DatosBasicosSolicitud?.FechaReporte || '';
          if(data?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla){
            this.mapeoPrincipal(data?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla);
          } else {
            this.alertService.error('No se logró recuperar la información de socios y sociedades')
          }
        } else {
          this.mapeoSecundario(data?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla, nodeData)
        }
        this.mapeoGrafico(false, id);
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error?.message || 'Error al buscar el reporte del rut indicado')
        this.showReporte = true;
        this.spinner.hide();
      }));
  }

  mapeoPrincipal(data: any): void {
    this.dataOrgPrint = {
      id: 'Principal' + (data?.detalleRutConsultado?.rut + data?.detalleRutConsultado?.dv) + 1,
      name: data?.detalleRutConsultado?.nombreRazonSocial || '',
      tipo: 'Principal',
      rut: (data?.detalleRutConsultado?.rut + data?.detalleRutConsultado?.dv),
      rutDisplay: this.formatearRUT(data?.detalleRutConsultado?.rut, data?.detalleRutConsultado?.dv),
      nivel: 1,
      children: null
    };
    
    if(data?.listadoSocios?.length > 0){
      if(this.dataOrgPrint.children === null){
        this.dataOrgPrint.children = []
      }
      data?.listadoSocios?.forEach((element: any, index: number) => {
        if(index < 10){
          this.dataOrgPrint.children.push(
            {
              id: 'Socio' + (element?.socio?.rut + element?.socio?.dv) + 2 + (data?.detalleRutConsultado?.rut + data?.detalleRutConsultado?.dv),
              name: element?.socio?.nombreRazonSocial || '',
              tipo: 'Socio',
              rut: (element?.socio?.rut + element?.socio?.dv),
              rutDisplay: this.formatearRUT(element?.socio?.rut, element?.socio?.dv),
              nivel: 2,
              children: null,
              searched: (this.validaPermisoBuscar() === false) ? (true) : (false)
            }
          )
        }
      });
    }

    if(data?.listadoParticipaciones?.length > 0){
      if(this.dataOrgPrint.children === null){
        this.dataOrgPrint.children = []
      }
      data?.listadoParticipaciones?.forEach((element: any, index: number) => {
        if(index < 10){
          this.dataOrgPrint.children.push(
            {
              id: 'Sociedad' + (element?.participacion?.rut + element?.participacion?.dv) + 2 + (data?.detalleRutConsultado?.rut + data?.detalleRutConsultado?.dv),
              name: element?.participacion?.nombreRazonSocial || '',
              tipo: 'Sociedad',
              rut: (element?.participacion?.rut + element?.participacion?.dv),
              rutDisplay: this.formatearRUT(element?.participacion?.rut, element?.participacion?.dv),
              nivel: 2,
              children: null,
              searched: (this.validaPermisoBuscar() === false) ? (true) : (false)
            }
          )
        }
      });
    }

  }

  mapeoGrafico(primeraCarga = false, id?: string): void {
    if(this.dataOrgPrint && Object.keys(this.dataOrgPrint).length > 0) {
      this.dataOrg = [{
        id: this.dataOrgPrint.id,
        parentNodeId: null,
        template: this.templateMapper(this.dataOrgPrint),
        expanded:false
      }];

      if(this.dataOrg.length > 0 && this.dataOrgPrint?.children && this.dataOrgPrint?.children.length > 0){
        this.mapeoChildren(this.dataOrgPrint.id, this.dataOrgPrint?.children, true, id);
      } else {
        this.updateChart(primeraCarga, id);
      }
    }
  }

  async mapeoChildren(idPadre: string, arrayData: any[], inicial = false, id?: string): Promise<void> {
    if(arrayData && arrayData.length > 0) {
      for await (const element of arrayData) {
        const nodedata = {
          id: element.id,
          parentNodeId: idPadre,
          template: this.templateMapper(element),
          expanded:true
        };

        if(nodedata && Object.keys(nodedata).length > 0) {
          this.dataOrg.push(nodedata);
          if(element.children && element.children.length > 0){
            this.mapeoChildren(element.id, element.children);
          }
        }
      }

      if(inicial){
        this.updateChart(false, id);
      }

    }
  }

  mapeoSecundario(data: any, nodeData: any): void {
    nodeData.children = [];
    if(data?.listadoSocios?.length > 0){
      data?.listadoSocios?.forEach((element: any, index: number) => {
        if(index < 10){
          nodeData.children.push(
            {
              id: 'Socio' + (element?.socio?.rut + element?.socio?.dv) + (nodeData.nivel + 1) + (nodeData?.rut),
              name: element?.socio?.nombreRazonSocial || '',
              tipo: 'Socio',
              rut: (element?.socio?.rut + element?.socio?.dv),
              rutDisplay: this.formatearRUT(element?.socio?.rut, element?.socio?.dv),
              nivel: nodeData.nivel + 1,
              children: null,
              searched: (this.validaPermisoBuscar() === false) ? (true) : (false)
            }
          )
        }
      });
    }
    if(data?.listadoParticipaciones?.length > 0){
      data?.listadoParticipaciones?.forEach((element: any, index: number) => {
        if(index < 10){
          this.nivel = nodeData.nivel + 1;
          nodeData.children.push(
            {
              id: 'Sociedad' + (element?.participacion?.rut + element?.participacion?.dv) + (nodeData.nivel + 1) + (nodeData?.rut),
              name: element?.participacion?.nombreRazonSocial || '',
              tipo: 'Sociedad',
              rut: (element?.participacion?.rut + element?.participacion?.dv),
              rutDisplay: this.formatearRUT(element?.participacion?.rut, element?.participacion?.dv),
              nivel: nodeData.nivel + 1,
              children: null,
              searched: (this.validaPermisoBuscar() === false) ? (true) : (false)
            }
          )
        }
      });
    }
  }

  public formatearRUT(rut: string, dv: string) : string {
    let rutFormateado = '';
    // Agregar puntos al RUT
    if(rut !== '' && dv !== ''){
      rutFormateado = rut?.replace(/^(\d{1,3})(\d{3})(\d{3})$/, '$1.$2.$3') || '';
      // Agregar el dígito verificador al RUT formateado
      rutFormateado += "-" + (dv || '');
    }
  
    return rutFormateado;
  }

  public templateMapper(data: any): string {
    const templateButtonBuscar = data.searched === false && this.validaPermisoBuscar() === true ? `<i 
        class="oc-toggle-btn fa fa-search float-right cursor-pointer mx-2 btn-action" 
        title="Buscar socios y sociedades de ${data.name}" 
        id="${data.id}"
      ></i>` : '';

    const templateButtonCrear = data.searched === true && this.validaPermisoCrear() === true ? `<i 
        class="oc-toggle-btn fa fa-refresh float-right cursor-pointer mx-2 btn-action" 
        title="Crear/actualizar socios y sociedades de ${data.name}" 
        id="${data.id}"
      ></i>` : '';

    if(data.tipo === 'Principal'){
      return `<div class="row">
        <div class="col-12">
          <div class="card card-malla shadow-lg tipo-${data.tipo} text-center" title="Rut Principal: ${data.name}">
              <div class="">
              <div class="card-header card-header-${data.tipo} col-12 font-italic font-weight-bold text-center">
                  ${data.name}
              </div>
              <div class="col-12 pt-3 pb-3 text-center">
                  ${data.rutDisplay}
              </div>
              </div>
          </div>
        </div>
        <div class="col-12 pt-2 pb-1">
          ${templateButtonBuscar}
          ${templateButtonCrear}
        </div>
      </div>`;
    }
    return `<div class="row">
      <div class="col-12">
        <div class="card card-malla shadow-lg tipo-${data.tipo} text-center" title="${data.tipo} : ${data.name}">
          <div class="">
            <div class="card-header card-header-${data.tipo} col-12 font-weight-bold text-center">
              ${data.tipo}
            </div>
            <div class="col-12 font-italic pt-3 pb-2 font-weight-bold text-center">
              ${data.name}
            </div>
            <div class="col-12 pb-3 text-center">
              ${data.rutDisplay}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 pt-2 pb-1">
        ${templateButtonBuscar}
        ${templateButtonCrear}
      </div>
    </div>`;
  } 


  updateChart(primeraCarga = false, id?: string) {
    if(this.chartContainer){
      this.chart
        .container(this.chartContainer.nativeElement)
        .nodeHeight((d: any) => 90)
        .nodeWidth((d: any) => 220)
        .data(this.dataOrg)
        .childrenMargin((d: any) => 40)
        .svgWidth(220)
        .compactMarginBetween((d: any) => 35)
        .compactMarginPair((d: any) => 30)
        .neighbourMargin((a: any, b: any) => 20)
        .initialZoom(1)
        .linkUpdate(function (d: any, i: any, arr: any) {
          // @ts-ignore //no reconoce el this como un elemento de d3
          d3.select(this)
            .attr('stroke', '#4D7BA0')
            .attr('stroke-width', 2);
        })
        .nodeContent(function (d: any) {
          return d.data.template;
        })
        .render();

      if(primeraCarga == true){
        this.chart.expandAll().compact(true).fit().render();
      } else {
        if(id) {
          this.chart.expandAll().setCentered(id).render();
        }
      }
    }
    
  }

  /*eventClick(): void { // Manejando eventos a traves del DOM
    document.body.addEventListener('click', (event: any) => {
      if(event?.target?.classList && event?.target?.classList.contains('btn-action')) {
        const id = event?.target?.id;
        if(id){
          if(this.dataOrgPrint.id === event?.target?.id) {
            this.selectNode(this.dataOrgPrint, this.dataOrgPrint.id)
          } else if (this.dataOrgPrint.children && this.dataOrgPrint.children.length > 0){
            this.findNode(this.dataOrgPrint.children, id);
          }
        }
      }
    });
  }*/

  eventClick(): void {
    this.renderer.listen(this.el.nativeElement, 'click', (event: any) => {
      if(event?.target?.classList && event?.target?.classList.contains('btn-action')) {
        const id = event?.target?.id;
        if(id){
          if(this.dataOrgPrint.id === event?.target?.id) {
            this.selectNode(this.dataOrgPrint, this.dataOrgPrint.id)
          } else if (this.dataOrgPrint.children && this.dataOrgPrint.children.length > 0){
            this.findNode(this.dataOrgPrint.children, id);
          }
        }
      }
    });
  }

  findNode(children: any[], id: string): void {
    for(const element of children){
      if(element.id === id){
        this.selectNode(element, element.id);
      } else if(element.children && element.children.length > 0){
        this.findNode(element.children, id);
      }
    }
  }

}
