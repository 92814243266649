import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { interval, Subscription } from 'rxjs';
import { ESsystemUserProfile, ESystemOrganizacion } from 'src/app/enum/EAccess';
import { CognitoService, IUserInfoCognito } from 'src/app/shared/services/cognito.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  public username = '';
  public organization = '';
  public userInfo: IUserInfoCognito | null = null;
  public hasOrganizationSelected = false;
  public hasManyOrganization = false;
  public isUser: boolean = false;
  public organizacionValid: boolean = false;
  private access: string = ESsystemUserProfile.USERUNIQUE;
  private accessOrg: string[] = ESystemOrganizacion.ORGANIZACION_ACCESS;
  private organizacionValidaFlujoCredito: string[] = ['DESARROLLO', 'salfa300008', 'SOPORTE'];
  private intervalSubscription!: Subscription;

  constructor(
    private _cognitoService: CognitoService,
    private _sessionService: SessionService,
    private router: Router,
    public authenticator: AuthenticatorService,
  ) {
  }

  resetComponent(): void {
    this.userInfo = null;
    this.hasManyOrganization = false;
    this.isUser = false;
    this.organizacionValid = false;
    this.organization = '';
    this.hasOrganizationSelected = false;
  }

  getOrganizationSequence(): void {
    this.resetComponent();

    this.userInfo = this._sessionService.getUserInfo();
    this.organization = this._sessionService.getOrganization();
    this.hasManyOrganization = this._sessionService.hasManyOrganizations();
    
    if (this.organization && this.organization !== '') {
      this.hasOrganizationSelected = true;
    } else {
      this.hasOrganizationSelected = false;
    }

    if (this.userInfo && this.userInfo.email.includes(this.access) && this.accessOrg.includes(this._sessionService.getOrganization())) {
      this.isUser = true;
    } else {
      this.isUser = false;
    }

    if (this.accessOrg[0] === this._sessionService.getOrganization()) {      
      this.organizacionValid = true;
    } else {
      this.organizacionValid = false
    }
  }

  handleStorageEvent = (event: any) => {
    this.getOrganizationSequence();
  }

  ngOnInit(): void {
    if(this.authenticator.authStatus === 'authenticated') {
      this.getOrganizationSequence();  
      window.addEventListener('storage', this.handleStorageEvent);
    }
  }

  startInterval() {
    this.intervalSubscription = interval(1000).subscribe(() => {
      this.getOrganizationSequence();
    });
  }

  public swithOrganization() {
    this._sessionService.removeSelectedOrganization();
    this.router.navigate(['/organizacion']);
  }

  public logout() {
    this._cognitoService.logout();
  }

  public validaOrganizacionFlujoCredito(): boolean {
    return this.organizacionValidaFlujoCredito.includes(this.organization); 
  }

  ngOnDestroy(): void {
    if(this.handleStorageEvent){
      window.removeEventListener('storage', this.handleStorageEvent);
    }

  }

}
