import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { rutValidate } from 'rut-helpers';

export function gtpRutValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!rutValidate(value)) {
      return { rutValidate: true };
    }
    return null;
  };
}