<div class="panel panel-default border-0 no-print">
    <div class="panel-body">
        <div class="btn-option">
            <button *ngIf="showReportes" [printTitle]='"FlujoDeCredito_"+(rut | rutFormat)+"_"+today'
                [useExistingCss]="true" printSectionId="print-section" ngxPrint
                class="btn btn-primary float-right">Descargar PDF</button>
        </div>
    </div>
</div>

<div class="break-box">

    <div class="row p-4">
    
        <div class="col-sm-12">
            <div class="block full min-height-block">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="table-responsive table-entidad">
                            <table class="table table-hover table-vcenter table-condensed table-bordered">
                                <thead>
                                    <tr>
                                        <th class="bg-cabecera text-center" colspan="4">ANTECEDENTES DEL CLIENTE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center bg-blue" width="25%">Razón Social</td>
                                        <td class="text-center bg-gris" width="25%">{{antecedentesCliente?.razonSocial}}</td>
                                        <td class="text-center bg-blue" width="25%">Segmento</td>
                                        <td class="text-center bg-gris" width="25%">{{antecedentesCliente?.segmento}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" width="25%">RUT</td>
                                        <td class="text-center bg-gris" width="25%">{{(antecedentesCliente && antecedentesCliente?.rut) ? (antecedentesCliente.rut | rutFormat) : ''}}</td>
                                        <td class="text-center bg-blue" width="25%">Grupo</td>
                                        <td class="text-center bg-gris" width="25%">{{antecedentesCliente?.grupo}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" width="25%">Antigüedad en CMT</td>
                                        <td class="text-center bg-gris" width="25%">{{antecedentesCliente?.antiguedadCMT ? (antecedentesCliente?.antiguedadCMT | date: 'dd-MM-yyyy') : ''}}</td>
                                        <td class="text-center bg-blue" width="25%">Responsable Deudor</td>
                                        <td class="text-center bg-gris" width="25%">{{antecedentesCliente?.responsableDeudor}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" width="25%">Inicio de Actividades</td>
                                        <td class="text-center bg-gris" width="25%">{{antecedentesCliente?.inicioActividades ? (antecedentesCliente?.inicioActividades | date: 'dd-MM-yyyy') : ''}}</td>
                                        <td class="text-center bg-blue" width="25%">Clase de Riesgo</td>
                                        <td class="text-center bg-gris" width="25%">{{antecedentesCliente?.claseRiesgo}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" width="25%">Impagos DICOM M$</td>
                                        <td class="text-center bg-gris pointer-cursor" (click)="abrirModalTitular()" width="25%">{{(antecedentesCliente?.impagosDicomM || antecedentesCliente?.impagosDicomM == 0) ? (antecedentesCliente?.impagosDicomM | number: '1.0-2') : ''}}</td>
                                        <td class="text-center bg-blue" width="25%"></td>
                                        <td class="text-center bg-gris" width="25%"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="block full min-height-block">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="table-responsive table-entidad">
                            <table class="table table-hover table-vcenter table-condensed table-bordered">
                                <thead>
                                    <tr>
                                        <th class="bg-cabecera text-center" colspan="4">ANTECEDENTES SOCIETARIOS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="bg-subcabecera" colspan="4">SOCIOS</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" width="10%">N°</td>
                                        <td class="text-center bg-blue" width="30%">Nombre o Razón Social</td>
                                        <td class="text-center bg-blue" width="30%">RUT</td>
                                        <td class="text-center bg-blue" width="30%">DICOM M$</td>
                                    </tr>
                                    <ng-container *ngIf="validateArray(antecedentesSocietarios?.socios); else elseSocios">
                                        <ng-container *ngFor="let item of antecedentesSocietarios?.socios; let i = index">
                                            <tr class="pointer-cursor" (click)="abrirModal(item?.rut, 'socios')">
                                                <td class="text-center bg-gris" width="10%">{{i + 1}}</td>
                                                <td class="text-center bg-gris" width="30%">{{item?.nombre}}</td>
                                                <td class="text-center bg-gris" width="30%">{{item?.rut ? (item.rut | rutFormat): ''}}</td>
                                                <td class="text-center bg-gris" width="30%">{{(item?.dicomM || item?.dicomM == 0) ? (item?.dicomM | number: '1.0-2') : ''}}</td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #elseSocios>
                                        <tr>
                                            <td colspan="4">Sin información</td>
                                        </tr>
                                    </ng-template>
                                    <tr>
                                        <td class="bg-subcabecera" colspan="4">SOCIEDADES</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" width="10%">N°</td>
                                        <td class="text-center bg-blue" width="30%">Nombre o Razón Social</td>
                                        <td class="text-center bg-blue" width="30%">RUT</td>
                                        <td class="text-center bg-blue" width="30%">DICOM M$</td>
                                    </tr>
                                    <ng-container *ngIf="validateArray(antecedentesSocietarios?.sociedades); else elseSociedad">
                                        <ng-container *ngFor="let item of antecedentesSocietarios?.sociedades; let i = index">
                                            <tr class="pointer-cursor" (click)="abrirModal(item?.rut, 'sociedades')">
                                                <td class="text-center bg-gris" width="10%">{{i + 1}}</td>
                                                <td class="text-center bg-gris" width="30%">{{item?.nombre}}</td>
                                                <td class="text-center bg-gris" width="30%">{{item?.rut ? (item.rut | rutFormat): ''}}</td>
                                                <td class="text-center bg-gris" width="30%">{{(item?.dicomM || item?.dicomM == 0) ? (item?.dicomM | number: '1.0-2') : ''}}</td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #elseSociedad>
                                        <tr>
                                            <td colspan="4">Sin información</td>
                                        </tr>
                                    </ng-template>                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="block full min-height-block">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="table-responsive table-entidad">
                            <table class="table table-hover table-vcenter table-condensed table-bordered">
                                <thead>
                                    <tr>
                                        <th class="bg-cabecera text-center" colspan="9">SEGURO DE CREDITO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center bg-blue">Cia de Seguro</td>
                                        <td class="text-center bg-blue">Vigencia Desde</td>
                                        <td class="text-center bg-blue">Vigencia Hasta</td>
                                        <td class="text-center bg-blue">Fecha Resolución</td>
                                        <td class="text-center bg-blue">Monto Solicitado UF</td>
                                        <td class="text-center bg-blue">Monto Aprobado UF</td>
                                        <td class="text-center bg-blue">Estado</td>
                                        <td class="text-center bg-blue">Condición de Venta</td>
                                        <td class="text-center bg-blue">Obs</td>
                                    </tr>
                                    <ng-container *ngIf="validateArray(seguroCredito); else elseSeguro">
                                        <ng-container *ngFor="let item of seguroCredito">
                                            <tr>
                                                <td class="text-center bg-gris">{{item?.ciaSeguro}}</td>
                                                <td class="text-center bg-gris">{{(item?.vigenciaDesde) ? (item?.vigenciaDesde | date: 'dd-MM-yyyy') : ''}}</td>
                                                <td class="text-center bg-gris">{{(item?.vigenciaHasta) ? (item?.vigenciaHasta | date: 'dd-MM-yyyy') : ''}}</td>
                                                <td class="text-center bg-gris">{{(item?.fechaResolucion) ? (item?.fechaResolucion | date: 'dd-MM-yyyy') : ''}}</td>
                                                <td class="text-center bg-gris">{{(item?.montoSolicitadoUF || item?.montoSolicitadoUF == 0) ? (item?.montoSolicitadoUF | number: '1.0-2') : ''}}</td>
                                                <td class="text-center bg-gris">{{(item?.montoAprobadoUF || item?.montoAprobadoUF == 0) ? (item?.montoAprobadoUF | number: '1.0-2') : ''}}</td>
                                                <td class="text-center bg-gris">{{item?.estado}}</td>
                                                <td class="text-center bg-gris">{{item?.condicionVenta}}</td>
                                                <td class="text-center bg-gris">{{item?.obs}}</td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #elseSeguro>
                                        <tr>
                                            <td colspan="9">Sin información</td>
                                        </tr>
                                    </ng-template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="block full min-height-block">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="table-responsive table-entidad">
                            <table class="table table-hover table-vcenter table-condensed table-bordered">
                                <thead>
                                    <tr>
                                        <th class="bg-cabecera text-center" colspan="4">ANTECEDENTES INTERNOS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="bg-subcabecera" colspan="4">LINEAS DE CREDITO</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" colspan="2" width="50%">Línea de Crédito Asegurada M$</td>
                                        <td class="text-center bg-gris" colspan="2" width="50%">{{(antecedentesInternos?.lineasCredito?.lineaCreditoAseguradaM || antecedentesInternos?.lineasCredito?.lineaCreditoAseguradaM == 0) ? (antecedentesInternos?.lineasCredito?.lineaCreditoAseguradaM | number: '1.0-2') : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" colspan="2">Línea de Crédito Temporal M$</td>
                                        <td class="text-center bg-gris" colspan="2">{{(antecedentesInternos?.lineasCredito?.lineaCreditoTemporalM || antecedentesInternos?.lineasCredito?.lineaCreditoTemporalM == 0) ? (antecedentesInternos?.lineasCredito?.lineaCreditoTemporalM | number: '1.0-2') : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" colspan="2">Total Asegurado M$</td>
                                        <td class="text-center bg-gris" colspan="2">{{(antecedentesInternos?.lineasCredito?.totalAseguradoM || antecedentesInternos?.lineasCredito?.totalAseguradoM == 0) ? (antecedentesInternos?.lineasCredito?.totalAseguradoM | number: '1.0-2') : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" colspan="2">Línea de Crédito CMT M$</td>
                                        <td class="text-center bg-gris" colspan="2">{{(antecedentesInternos?.lineasCredito?.lineaCreditoCMTM || antecedentesInternos?.lineasCredito?.lineaCreditoCMTM == 0) ? (antecedentesInternos?.lineasCredito?.lineaCreditoCMTM | number: '1.0-2') : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" colspan="2">Línea de Crédito Puntual M$</td>
                                        <td class="text-center bg-gris" colspan="2">{{(antecedentesInternos?.lineasCredito?.lineaCreditoPuntualM || antecedentesInternos?.lineasCredito?.lineaCreditoPuntualM == 0) ? (antecedentesInternos?.lineasCredito?.lineaCreditoPuntualM | number: '1.0-2') : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" colspan="2">Fecha expiración Temporal</td>
                                        <td class="text-center bg-gris" colspan="2">{{antecedentesInternos?.lineasCredito?.fechaExpiracionTemporal ? (antecedentesInternos?.lineasCredito?.fechaExpiracionTemporal) : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" colspan="2">Fecha de expiración Puntual</td>
                                        <td class="text-center bg-gris" colspan="2">{{antecedentesInternos?.lineasCredito?.fechaExpiracionPuntual ? (antecedentesInternos?.lineasCredito?.fechaExpiracionPuntual) : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" colspan="2">Fecha de Expiración Línea de Crédito CMT</td>
                                        <td class="text-center bg-gris" colspan="2">{{antecedentesInternos?.lineasCredito?.fechaExpiracionLineaCreditoCMT ? (antecedentesInternos?.lineasCredito?.fechaExpiracionLineaCreditoCMT | date: 'dd-MM-yyyy') : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" colspan="2">DSO (Días)</td>
                                        <td class="text-center bg-gris" colspan="2">{{(antecedentesInternos?.lineasCredito?.dso || antecedentesInternos?.lineasCredito?.dso == 0) ? (antecedentesInternos?.lineasCredito?.dso | number: '1.0-0') : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" colspan="2">PPP 3 Meses (Días)</td>
                                        <td class="text-center bg-gris" colspan="2">{{(antecedentesInternos?.lineasCredito?.ppp3Meses || antecedentesInternos?.lineasCredito?.ppp3Meses == 0) ? (antecedentesInternos?.lineasCredito?.ppp3Meses | number: '1.0-0') : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-blue" colspan="2">Condición de Pago (Días)</td>
                                        <td class="text-center bg-gris" colspan="2">{{antecedentesInternos?.lineasCredito?.condicionPago || ''}}</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td class="bg-subcabecera" colspan="4">DEUDA FACTURADA</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-dgris" colspan="2">TRAMO EN DIAS</td>
                                        <td class="text-center bg-dgris">VENCIDO M$</td>
                                        <td class="text-center bg-dgris">POR VENCER M$</td>
                                    </tr>
                                    <ng-container *ngFor="let item of antecedentesInternos?.deudaFacturada?.tramo">
                                        <tr>
                                            <td class="text-center bg-blue" width="25%">{{item?.limInf}}</td>
                                            <td class="text-center bg-blue" width="25%">{{item?.limSup}}</td>
                                            <td class="text-center bg-gris" width="25%">{{(item?.vencidoM || item?.vencidoM == 0) ? (item?.vencidoM | number: '1.0-2') : ''}}</td>
                                            <td class="text-center bg-gris" width="25%">{{(item?.porVencerM || item?.porVencerM == 0) ? (item?.porVencerM | number: '1.0-2') : ''}}</td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td class="text-center bg-dgris" colspan="2">TOTAL M$</td>
                                        <td class="text-center bg-dgris">{{(antecedentesInternos?.deudaFacturada?.totalM?.vencidoM || antecedentesInternos?.deudaFacturada?.totalM?.vencidoM == 0) ? (antecedentesInternos?.deudaFacturada?.totalM?.vencidoM | number: '1.0-2') : ''}}</td>
                                        <td class="text-center bg-dgris">{{(antecedentesInternos?.deudaFacturada?.totalM?.porVencerM || antecedentesInternos?.deudaFacturada?.totalM?.porVencerM == 0) ? (antecedentesInternos?.deudaFacturada?.totalM?.porVencerM | number: '1.0-2') : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-dgris" colspan="2">TOTAL FACTURADO M$</td>
                                        <td class="text-center bg-dgris" colspan="2">{{(antecedentesInternos?.deudaFacturada?.totalFacturadoM || antecedentesInternos?.deudaFacturada?.totalFacturadoM == 0) ? (antecedentesInternos?.deudaFacturada?.totalFacturadoM | number: '1.0-2') : ''}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row p-4 pagebreak">

        <div class="col-sm-12">
            <div class="block full min-height-block">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="table-responsive table-entidad">
                            <table class="table table-hover table-vcenter table-condensed table-bordered">
                                <thead>
                                    <tr>
                                        <th class="bg-cabecera text-center" colspan="4">ANTECEDENTES INTERNOS CMT</th>
                                    </tr>
                                    <tr>
                                        <th class="text-center bg-dgris" width="25%">Periodo</th>
                                        <th class="text-center bg-dgris" width="25%">Ventas M$</th>
                                        <th class="text-center bg-dgris" width="25%">Cuentas por cobrar M$</th>
                                        <th class="text-center bg-dgris" width="25%">Pagos M$</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let item of antecedentesInternosCmt?.body">
                                        <tr>
                                            <td class="text-center bg-dgris">{{item?.periodo || ''}}</td>
                                            <td class="text-center bg-gris">{{(item?.ventasM || item?.ventasM == 0) ? (item?.ventasM | number: '1.0-2') : ''}}</td>
                                            <td class="text-center bg-gris">{{(item?.cuentasPorCobrarM || item?.cuentasPorCobrarM == 0) ? (item?.cuentasPorCobrarM | number: '1.0-2') : ''}}</td>
                                            <td class="text-center bg-gris">{{(item?.pagosM || item?.pagosM == 0) ? (item?.pagosM | number: '1.0-2') : ''}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td class="text-center bg-dgris">Acumulado últimos 12 m</td>
                                        <td class="text-center bg-dgris">{{(antecedentesInternosCmt?.acumulado?.ventasM || antecedentesInternosCmt?.acumulado?.ventasM == 0) ? (antecedentesInternosCmt?.acumulado?.ventasM | number: '1.0-2') : ''}}</td>
                                        <td class="text-center bg-dgris">{{(antecedentesInternosCmt?.acumulado?.cuentasPorCobrarM || antecedentesInternosCmt?.acumulado?.cuentasPorCobrarM == 0) ? (antecedentesInternosCmt?.acumulado?.cuentasPorCobrarM | number: '1.0-2') : ''}}</td>
                                        <td class="text-center bg-dgris">{{(antecedentesInternosCmt?.acumulado?.pagosM || antecedentesInternosCmt?.acumulado?.pagosM == 0) ? (antecedentesInternosCmt?.acumulado?.pagosM | number: '1.0-2') : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-dgris">Promedio últimos 12 m</td>
                                        <td class="text-center bg-dgris">{{(antecedentesInternosCmt?.promedio?.ventasM || antecedentesInternosCmt?.promedio?.ventasM == 0) ? (antecedentesInternosCmt?.promedio?.ventasM | number: '1.0-2') : ''}}</td>
                                        <td class="text-center bg-dgris">{{(antecedentesInternosCmt?.promedio?.cuentasPorCobrarM || antecedentesInternosCmt?.promedio?.cuentasPorCobrarM == 0) ? (antecedentesInternosCmt?.promedio?.cuentasPorCobrarM | number: '1.0-2') : ''}}</td>
                                        <td class="text-center bg-dgris">{{(antecedentesInternosCmt?.promedio?.pagosM || antecedentesInternosCmt?.promedio?.pagosM == 0) ? (antecedentesInternosCmt?.promedio?.pagosM | number: '1.0-2') : ''}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="block full min-height-block">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="table-responsive table-entidad">
                            <table class="table table-hover table-vcenter table-condensed table-bordered">
                                <thead>
                                    <tr>
                                        <th class="bg-cabecera text-center" colspan="4">ANTECEDENTES FINANCIEROS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center bg-subcabecera" width="25%">AÑO</td>
                                        <ng-container *ngIf="validateArray(antecedentesFinancieros?.anio); else elseAniosAnt">
                                            <ng-container *ngFor="let item of antecedentesFinancieros?.anio">
                                                <td class="text-center bg-subcabecera pointer-cursor" (click)="abrirModalTitular()">{{item}}</td>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #elseAniosAnt>
                                            <td class="text-center bg-subcabecera pointer-cursor"></td>
                                        </ng-template>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-subcabecera" width="25%">TIPO</td>
                                        <ng-container *ngIf="validateArray(antecedentesFinancieros?.tipo); else elseTipoAnt">
                                            <ng-container *ngFor="let item of antecedentesFinancieros?.tipo">
                                                <td class="text-center bg-subcabecera">{{item}}</td>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #elseTipoAnt>
                                            <td class="text-center bg-subcabecera" colspan="100%"></td>
                                        </ng-template>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-subcabecera">PERIODO INICIAL</td>
                                        <ng-container *ngIf="validateArray(antecedentesFinancieros?.periodoInicial); else elsePeriodoInicial">
                                            <ng-container *ngFor="let item of antecedentesFinancieros?.periodoInicial">
                                                <td class="text-center bg-subcabecera">{{getAbbreviatedMonthName(item)}}</td>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #elsePeriodoInicial>
                                            <td class="text-center bg-subcabecera"></td>
                                        </ng-template>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-subcabecera">PERIODO FINAL</td>
                                        <ng-container *ngIf="validateArray(antecedentesFinancieros?.periodoFinal); else elsePeriodoFinal">
                                            <ng-container *ngFor="let item of antecedentesFinancieros?.periodoFinal">
                                                <td class="text-center bg-subcabecera">{{getAbbreviatedMonthName(item)}}</td>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #elsePeriodoFinal>
                                            <td class="text-center bg-subcabecera"></td>
                                        </ng-template>
                                    </tr>
                                    <tr>
                                        <td class="text-center bg-subcabecera">MONEDA</td>
                                        <ng-container *ngIf="validateArray(antecedentesFinancieros?.moneda); else elsePeriodoFinal">
                                            <ng-container *ngFor="let item of antecedentesFinancieros?.moneda">
                                                <td class="text-center bg-subcabecera">{{item || ''}}</td>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #elsePeriodoFinal>
                                            <td class="text-center bg-subcabecera"></td>
                                        </ng-template>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <ng-container *ngFor="let item of antecedentesFinancieros?.body">
                                        <tr>
                                            <td class="text-center bg-blue">{{item?.text}}</td>
                                            <ng-container *ngIf="validateArray(item?.values); else elseBodyAnt">
                                                <ng-container *ngFor="let value of item?.values">
                                                    <td class="text-center bg-gris">{{(value || value == 0) ? (value | number: '1.0-2') : ''}}</td>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #elseBodyAnt>
                                                <td class="text-center bg-gris"></td>
                                            </ng-template>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 my-2 no-print" *ngIf="showArchivos">
            <app-modulo-archivos [idTransaccion]="idTransaccion" [rut]="rut" [closed]="closed"></app-modulo-archivos>
        </div>

        <ng-container *ngIf="plantillaNoEditable === false">
            <ng-template #botonFinalizar>
                <ng-container *ngIf="validaUsuarioAdmin()">
                  <div class="col-12 d-flex justify-content-center pt-4 no-print">
                    <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="volverEtapaPrevia()">
                      Devolver Etapa Previa
                    </button>
                  </div>
                </ng-container>
            </ng-template>
            
            <ng-container *ngIf="stepper === 4 else botonFinalizar">
            
              <div class="col-12 d-flex justify-content-center pt-4 no-print">
                <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="guardarFicha()">
                  Guardar
                </button>
                <button class="btn btn-sm btn-primary mx-2 rounded-header" [disabled]="objectKeys(fichaGuardada).length > 0 ? null : true" (click)="avanzarFlujo()">
                  Continuar
                </button>
              </div>
            
            </ng-container>
        </ng-container>

    </div>

</div>

<app-modal-consolidado-workflow [rut]="rutSeleccionado" [idTransaccion]="idTransaccion" [dataServicios]="reportesSeleccionado" [informeComercial]="informeComercial" [default]="modalDefault" [plantilla]="plantillaAntecedentes"></app-modal-consolidado-workflow>