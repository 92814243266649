<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>N° Tickets</th>
                <th>Razón Social</th>
                <th>Rut</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{obtenerValorCamposPersonalizados('Numero Tickets')}}</td>
                <td>{{obtenerInformacionBasica('NombreORazonSocial')}}</td>
                <td>{{obtenerInformacionBasica('Rut')}}</td>
            </tr>
        </tbody>
    </table>
</div>

<div class="table-responsive" *ngIf="tipoSolicitud === 'lineaDeCredito'">


    <table class="table table-bordered" *ngIf="objetoLineaCredito.length > 0">
        <thead>
            <tr>
                <th width="200px"></th>
                <th *ngFor="let item of objetoLineaCredito">
                    {{item.UnidadNegocio}}
                </th>
                <th></th>
            </tr>
        </thead>
        <thead>
            <tr>
                <th class="headRight">Líneas de Negocio</th>
                <th *ngFor="let item of objetoLineaCredito">
                    {{item.ACC}}
                </th>
                <th>Total</th>
            </tr>
        </thead>
        <thead>
            <!-- <tr>
                <td class="headRight">Monto de Línea de Crédito Solicitada</td>
                <td *ngFor="let item of objetoLineaCredito">
                    {{item.lcSolicitada | number: '1.0-0'}}
                </td>
                <td>
                    {{objetoLineaCredito | sumTd: 'lcSolicitada' | number : '1.0-0'}}
                </td>
            </tr> -->
            <tr>
                <th class="headRight">
                    <div class="input-group"> Monto de Línea de Crédito Solicitada </div>
                </th>
                <th *ngFor="let item of objetoLineaCredito">
                    <div class="input-group">
                        <ng-template #onlyText>
                            <div class="form-control" disabled>{{item.lcSolicitada}}</div>
                        </ng-template>

                        <ng-container *ngIf="statusTX !== 'CLOSED'; else onlyText">
                            <input type="text" class="form-control border-right-0" #ref (input)="setRowLineaAprobada(ref.value, item, 'valueAcc');
                                    setAddRow(ref.value, item, 'lcSolicitada')" mask="separator"
                                    thousandSeparator="." [readonly]="montoAprobado === 0" (click)="showAlert()">
                        </ng-container>
                    </div>
                </th>
                <th>
                    <div class="input-group">
                        <div class="form-control" disabled>
                            {{objetoLineaCredito | sumTd: 'lcSolicitada' | number : '1.0-0'}}
                        </div>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="headRight">% de utilización</td>
                <td *ngFor="let item of objetoLineaCredito">
                    {{item.valueAcc | number: '1.0-2'}} %
                </td>
                <td>
                    {{objetoLineaCredito | sumTd: 'valueAcc' | number : '1.0-2'}} %
                </td>
            </tr>
            <tr>
                <td class="headRight">Monto de Línea de Crédito Actual</td>
                <td *ngFor="let item of objetoLineaCredito">
                    {{item.ImporteML | number: '1.0-0'}}
                </td>
                <td>
                    {{objetoLineaCredito | sumTd: 'ImporteML' | number : '1.0-0'}}
                </td>
            </tr>
            <tr>
                <td class="headRight">Condición de pago (Acuerdo 30 días)</td>
                <td *ngFor="let item of objetoLineaCredito">
                    {{item.acuerdo30 }}
                </td>
                <td></td>
            </tr>
            <tr>
                <td class="headRight">LC Utilizada (por línea de negocio)</td>
                <td *ngFor="let item of objetoLineaCredito">
                    {{item.lcActual | number : '1.0-0'}}
                </td>
                <td>{{objetoLineaCredito | sumTd: 'lcActual' | number : '1.0-0'}}</td>
            </tr>
            <tr>
                <td class="headRight">LC útil/LC Aprobada: dividir LC Utilizada (Por línea de negocio)</td>
                <td *ngFor="let item of objetoLineaCredito">
                    {{item.lcAprobada | number : '1.0-2'}}
                    <div class="pl-3 pull-right">%</div>
                </td>
                <td>
                    {{objetoLineaCredito | sumTd: 'lcAprobada' | number : '1.0-2'}}
                    <div class="pl-3 pull-right">%</div>
                </td>
            </tr>
        </tbody>
    </table>
</div>