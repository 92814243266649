import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import Stepper from 'bs-stepper';
//import { SimpleModalService } from 'ngx-simple-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ESystemAccess } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { AlertService } from '../../_alert';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-solicitud-credito-viewer',
  templateUrl: './solicitud-credito-viewer.component.html',
  styleUrls: ['./solicitud-credito-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SolicitudCreditoViewerComponent implements OnInit, OnDestroy {
  @Output() closeSolicitud = new EventEmitter<boolean>();
  @Input() rut: string = '';
  private access: string[] = [ESystemAccess.SOLICITUD_CREDITO_BUSCAR];
  public hasUserAccess = false;
  public currentStep: number = 0;
  public stepper!: Stepper;
  public stepContent: boolean = false;
  public header: any[] = ['numSolicitud', 'EstadoTransaccion', 'Modelo', 'date', 'search', 'edit', 'trash'];
  public dataTable: any[] = [];
  public tipoSolicitud = 'lineaDeCredito';
  public tipoSolicitudContent: boolean = false;
  public tipoSolicitudSel: any[] = [];
  public showNumSol: boolean = false;
  public informacionBasica: any = {};
  public informacionBasicaJudicial: any = {};
  public idTransaccion!: string;
  public numeroSolicitud!: number;
  public subscriptions: Subscription[] = [];
  public tipoEvento: string = '';

  constructor(
    private readonly elementRef: ElementRef,
    private solicitudCreditoService: SolicitudCreditoService,
    private spinner: NgxSpinnerService,
    private _sessionService: SessionService,
    private alertService: AlertService,
    private router: Router,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog,) { }

  ngOnInit() {

    const storeTable = [];
    storeTable.push({ rut: this.rut });

    if (this._sessionService.getUserAccess().includes(this.access[0])) {
      this.hasUserAccess = true;
    }
    const stepperEl = this.elementRef.nativeElement.querySelector('#stepper1');


    stepperEl.addEventListener('show.bs-stepper', (event: any) => {
      this.currentStep = event.detail.to;
    });

    this.stepper = new Stepper(stepperEl, {
      linear: false,
      animation: true
    });

    this.subscriptions.push(this.solicitudCreditoService.refreshStep$.subscribe((resp: any) => {

      if (resp === 'next') {
        this.stepper.next();
      } else if (resp === 'prev') {
        this.stepper.previous();
      } else {
        this.stepper.to(resp);
      }
    }));

    this.getData();
  }

  counter(i: number) {
    return new Array(i);
  }

  showLits() {

    if (this.stepContent) {
      this.stepper.to(0);
    } else {
      this.closeSolicitud.emit();
    }
    this.stepContent = false;
    this.showNumSol = false;

    if (this.tipoEvento === 'edit') {
      this.tipoSolicitudContent = false;
    }
  }

  getData(): void {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.searchSolicitudesCreditoList(this.rut).subscribe(resp => {
      this.spinner.hide();
      this.dataTable = resp;
      this.getModelos();
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    }));
  }

  getModelos(): void {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.getObtenerModelos().subscribe(resp => {
      this.spinner.hide();
      this.tipoSolicitudSel = resp;
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    }));
  }

  procesarEvent(event: any) {
    this.idTransaccion = event.obj.IDTransaccion;
    this.numeroSolicitud = event.obj.numSolicitud;
    this.tipoSolicitudContent = true;

    if (event.type === 'edit') {
      this.getStepSolicitud();
      this.tipoEvento = 'edit';
    }

    if (event.type === 'search') {
      this.getSearchReporte(this.idTransaccion);
      this.tipoEvento = 'search';
    }

    if (event.type === 'trash') {
      this.deleteSolicitud(this.idTransaccion);
      this.tipoSolicitudContent = false;
    }
  }

  getStepSolicitud() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.spinner.hide();
      if (resp) {
        if (resp.workflowModel) {
          this.tipoSolicitud = resp.workflowModel;
          if (this.solicitudCreditoService.setTipoSolicitud(this.tipoSolicitud)) {
            this.stepContent = true;
            this.showNumSol = true;
          }
        }
      }
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    }));
  }

  getSearchReporte(idTransaccion: string): void {
    this.router.navigate(['/solicitud-credito/resumen', idTransaccion], { state: { rut: this.rut } });
  }

  deleteSolicitud(idTransaccion: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Solicitud de Crédito',
        message: `Esta seguro que desea eliminar la solicitud de crédito N° ${idTransaccion.substring(idTransaccion.length - 5)}?`,
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
        if (resp) {
          this.spinner.show();
          this.subscriptions.push(this.solicitudCreditoService.eliminarSolicitud(idTransaccion, this.rut).subscribe(resp => {
            this.getData();
          }, (error: any) => {
            this.spinner.hide();
            this.alertService.error(error.error.message || 'Ocurrio un Error');
          }));
        }
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
    })
    /*this.simpleModalService.addModal(ConfirmDialogComponent, {
      title: 'Solicitud de Crédito',
      message: `Esta seguro que desea eliminar la solicitud de crédito N° ${idTransaccion.substring(idTransaccion.length - 5)}?`,
    }).subscribe((resp) => {
      if (resp) {
        this.spinner.show();
        this.subscriptions.push(this.solicitudCreditoService.eliminarSolicitud(idTransaccion, this.rut).subscribe(resp => {
          this.getData();
        }, (error: any) => {
          this.spinner.hide();
          this.alertService.error(error.error.message || 'Ocurrio un Error');
        }));
      }
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
    });*/
  }


  showTipoSolicitud() {
    this.tipoSolicitudContent = true;
  }

  setTipoSolicitud() {
    if (this.solicitudCreditoService.setTipoSolicitud(this.tipoSolicitud)) {
      this.spinner.show();
      this.solicitudCreditoService.iniciarFlujo(this.rut).subscribe(resp => {
        if (resp) {
          const datosBasicos = resp.SolicitudDeCredito.DatosBasicosSolicitud;
          this.idTransaccion = datosBasicos.IDTransaccion;
        }
        this.tipoSolicitud = this.solicitudCreditoService.getTipoSolicitud();
        this.stepContent = true;
      }, (error: any) => {
        this.alertService.error(error.error.message || 'Ocurrio un Error');
        this.spinner.hide();
      });
    }
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
