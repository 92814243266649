import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { rutValidate } from 'rut-helpers';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { RUT } from 'src/app/shared/utils/rut';
import { VehiculosService } from 'src/app/shared/services/vehiculos.service';
import { Subscription } from 'rxjs';
import { IReporteVehiculos } from 'src/app/models/vehiculos/vehiculos.model';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
interface vehiculosFormValues {
  rut: string;
}

@Component({
  selector: 'app-vehiculos',
  templateUrl: './vehiculos-consultar.component.html',
  styleUrls: ['./vehiculos-consultar.component.scss']
})
export class VehiculosConsultarComponent implements OnInit {
  public activateRutHelper: boolean = false;
  private access: string[] = [
    ESystemAccess.REPORTE_VEHICULOS,
    ESystemProfileList.WEB];
  public hasUserAccess: boolean = false;
  public hasInformeAccess: boolean = false;

  public vehiculoReportForm: UntypedFormGroup;
  public showReport: boolean = false;
  public vehiculoReportDataSource!: IReporteVehiculos | any;
  public rut: string = '';
  public today: string = '';
  private subscriptions: Subscription[] = [];

  constructor(
    public alertService: AlertService,
    private spinner: NgxSpinnerService,
    private vehiculosService: VehiculosService,
    private _sessionService: SessionService,
    private formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title) {

    this.vehiculoReportForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]]
    }) as FormGroupTyped<vehiculosFormValues>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
     
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
    if (this._sessionService.getUserAccess().includes(this.access[1])) {
      this.hasInformeAccess = true;
    }
    this.setToday();
  }

  onChanges(): void {
    this.vehiculoReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  public requestReport() {
    const { rut } = this.vehiculoReportForm.value;
    this.rut = rut;

    if (!rutValidate(rut)) {
      this.alertService.warn("Rut no valido");
      return;
    }

    const rutformat = RUT.applyBackendFormat(rut);

    this.spinner.show();
    this.subscriptions.push(this.vehiculosService.createReport(rutformat)
      .subscribe(
        (data) => {
          this.vehiculoReportDataSource = data;

          this.showReport = true;
          this.clearForm();
          this.spinner.hide();
        },
        ({ error }) => {
          this.alertService.error(error.message);
          this.spinner.hide();
        }
      ));
  }

  public closeReport(): void {
    this.vehiculoReportDataSource = null;
    this.showReport = false;
  }

  public clearForm(): void {
    this.vehiculoReportForm.reset();
    this.activateRutHelper = false;
  }

  
  setToday() {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) :
      new Date().getMonth() + 1)}${new Date().getDate()}`;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
