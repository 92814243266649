export class Alert {
    id: string = "default-alert";
    type: AlertType = AlertType.Info;
    message: string = "";
    autoClose: boolean = false;
    keepAfterRouteChange?: boolean = false;
    fade: boolean = false;
    buttonRedirect: boolean = false;
    componente: string = '';
    arguments: any = {};


    constructor(init?:Partial<Alert>) {
        Object.assign(this, init);
    }
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}