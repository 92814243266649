import { Component, Input, OnInit } from '@angular/core';
import { IPoderJudicialCreateReportResponse, IPoderJudicialCivil, IPoderJudicialLaboral, IPoderJudicialCobranza } from 'src/app/interface/IPoderJudicialCreateReportResponse';
import { EIndexPoderJudicial } from 'src/app/enum/EIndexPoderJudicial';

@Component({
  selector: 'app-poder-judicial-report-viewer',
  templateUrl: './poder-judicial-report-viewer.component.html',
  styleUrls: ['./poder-judicial-report-viewer.component.scss']
})
export class PoderJudicialReportViewerComponent implements OnInit {
  @Input() content!: IPoderJudicialCreateReportResponse;
  @Input() public showHeader: boolean = true;
  @Input() fromConsolidado: boolean = false;
  public civilCaseSelected: IPoderJudicialCivil | null = null;
  public laboralCaseSelected: IPoderJudicialLaboral | null = null;
  public cobranzaCaseSelected: IPoderJudicialCobranza | null = null;

  // Validate if the data source contains sections
  public hasCivil = false;
  public hasLaboral = false;
  public hasCobranza = false;

  // NavTabs details sections
  public navTabsCivil: any = [];
  public navTabsLaboral: any = [];
  public navTabsCobranza: any = [];
  public selectedCivilIndex = 0;
  public selectedLaboralIndex = 0;
  public selectedCobranzaIndex = 0;

  // counters on the report top
  public totalCivil = 0;
  public totalLaboral = 0;
  public totalCobranza = 0;
  // By default is Zero, and green
  public totalTributario = 0;
  public contentShow = 'civiles';

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  ngOnInit(): void {
    this.validateReportSections();
    this.loadNavTabsCivil();
    this.loadNavTabsLaboral();
    this.loadNavTabsCobranza();
    this.setTotales();
  }

  setTotales(): void {
    if(this.content?.poderJudicialReporte?.poderJudicial?.Totales){
      const { nCausasCivil, nCausasLaboral, nCausasCobranza } = this.content.poderJudicialReporte.poderJudicial.Totales;
      
      this.totalCivil = nCausasCivil || 0;
      this.totalLaboral = nCausasLaboral || 0;
      this.totalCobranza = nCausasCobranza || 0;
    }
  }

  public validateReportSections(): void {
    const { Civil, Laboral, Cobranza } = this.content.poderJudicialReporte.poderJudicial;

    this.totalCivil = Civil.length;
    this.totalLaboral = Laboral.length;
    this.totalCobranza = Cobranza.length;

    this.hasCivil = this.totalCivil > 0;
    this.hasLaboral = this.totalLaboral > 0;
    this.hasCobranza = this.totalCobranza > 0;
  }

  public selectCivilCase(rol: string): void {
    const civilFound = this.content.poderJudicialReporte.poderJudicial.Civil.find((causa) => causa.rol === rol);
    if (civilFound) {
      this.civilCaseSelected = this.civilCaseSelected && this.civilCaseSelected === civilFound? null: civilFound;
    }
  }

  public selectLaboralCase(rit: string): void {
    const laboralFound = this.content.poderJudicialReporte.poderJudicial.Laboral.find((causa) => causa.rit === rit);
    if (laboralFound) {
      this.laboralCaseSelected = this.laboralCaseSelected && this.laboralCaseSelected === laboralFound? null: laboralFound;
    }
  }

  public selectCobranzaCase(rit: string): void {
    const cobranzaFound = this.content.poderJudicialReporte.poderJudicial.Cobranza.find((causa) => causa.rit === rit);
    if (cobranzaFound) {
      this.cobranzaCaseSelected = this.cobranzaCaseSelected && this.cobranzaCaseSelected === cobranzaFound? null: cobranzaFound;
    }
  }

  public setIndex(type: string, index: number): void {
    switch(type) {
      case EIndexPoderJudicial.CIVIL:
        this.selectedCivilIndex = index;
        return;
      case EIndexPoderJudicial.LABORAL:
        this.selectedLaboralIndex = index;
        return;
      case EIndexPoderJudicial.COBRANZA:
        this.selectedCobranzaIndex = index;
        return;
      default:
        return;
    }
  }

  public loadNavTabsCivil(): void {
    this.navTabsCivil = [
      {
          title: "Historia",
          total: 0,
          dataTargetTag: 'nav-historia'
      },
      {
          title: "Litigantes",
          total: 0,
          dataTargetTag: 'nav-litigantes'
      },
      {
          title: "Notificaciones",
          total: 0,
          dataTargetTag: 'nav-notificaciones'
      },
      {
          title: "Escritos por Resolver",
          total: 0,
          dataTargetTag: 'nav-escritos'
      },
      {
          title: "Exhortos",
          total: 0,
          dataTargetTag: 'nav-exhortos'
      }
    ];
  }

  public loadNavTabsLaboral(): void {
    this.navTabsLaboral = [
      {
          title: "Movimientos",
          total: 0,
          dataTargetTag: 'nav-movimientos'
      },
      {
          title: "Litigantes",
          total: 0,
          dataTargetTag: 'nav-litigantes2'
      },
      {
          title: "Notificaciones",
          total: 0,
          dataTargetTag: 'nav-notificaciones2'
      },
      {
          title: "Diligencias",
          total: 0,
          dataTargetTag: 'nav-diligencias'
      },
      {
          title: "Liquidación",
          total: 0,
          dataTargetTag: 'nav-liquidacion'
      },
      {
          title: "Materias",
          total: 0,
          dataTargetTag: 'nav-materias'
      },
      {
          title: "Escritos Pendientes",
          total: 0,
          dataTargetTag: 'nav-escritos-pendientes'
      },
    ];
  }

  public loadNavTabsCobranza(): void {
    this.navTabsCobranza = [
      {
          title: "Historia",
          total: 0,
          dataTargetTag: 'nav-historia3'
      },
      {
          title: "Litigantes",
          total: 0,
          dataTargetTag: 'nav-litigantes3'
      },
      {
          title: "Notificaciones",
          total: 0,
          dataTargetTag: 'nav-notificaciones3'
      },
      {
          title: "Diligencias",
          total: 0,
          dataTargetTag: 'nav-diligencias3'
      },
      {
          title: "Liquidación",
          total: 0,
          dataTargetTag: 'nav-liquidacion3'
      },
      {
          title: "Deuda Actual",
          total: 0,
          dataTargetTag: 'nav-deuda-actual'
      }
    ];
  }

}
