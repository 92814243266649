<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<ng-container *ngIf="hasUserAccess">

    <div *ngIf="showForm && !showReporte && !showList">
        <div class="container__form">
            <div class="col-md-6">
                <div class="block">
                    <form [formGroup]="searchMallaForm" (ngSubmit)="validaListarReportes()">
                        <div class="form-horizontal form-bordered">
                            <div class="form-group" [ngClass]="{'has-error': activateRutHelper}">
                                <label class="col-md-3 control-label" for="example-text-input">Rut</label>
                                <div class="col-md-6">
                                    <input formControlName="rut" type="text" class="form-control" formatRut required>
                                    <span *ngIf="activateRutHelper" class="help-block">Rut no valido</span>
                                </div>
                            </div>
                            <div class="form-group form-actions container__actions">
                                <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i>
                                    Buscar Reporte</button>
                                <button type="button" (click)="clearForm()" class="btn btn-sm btn-warning"><i class="fa fa-repeat"></i>
                                    Limpiar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>


    <ng-container *ngIf="!showForm && !showReporte && showList">
        
        <div class="row">
            <div class="col-md-9 mx-auto my-4">
                <div class="col-12">
    
                    <div class="row container__back-button">
                        <button (click)="backToSearchForm()" class="btn btn-primary ml-4">Volver</button>
                    </div>
    
                    <h2>
                        <strong>Resultados de la Busqueda para: </strong>
                        rut <span class="text-capitalize">{{rut | rutFormat}}</span>
                    </h2>
    
                    <div class="card text-center my-4">
    
                        <div class="card-header">
                            <div class="my-0 row d-flex px-4">
    
                                <div class="col-xs-3 col-sm-auto mr-auto p-2">
                                    <app-paginator-by-items [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                                </div>
    
                                <div class="col-xs-3 col-sm-auto p-2">
    
                                    <div class="row">
    
                                        <div class="col-10 py-0">
                                            <div class="input-group mb-3">
                                                <input id="table-complete-search" type="text" 
                                                    class="form-control" name="searchTerm" [(ngModel)]="inputText" 
                                                    (keyup)="filtrarObjeto()" placeholder="Filtrar tabla"
                                                >
                                            </div>
                                        </div>
    
                                        <div class="col-2">
                                            <button 
                                                type="button" 
                                                class="btn btn-info float-right refresh-button" 
                                                (click)="validaListarReportes()"
                                                title="Actualizar tabla"
                                            >
                                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                            </button>
                                        </div>
    
                                    </div>
                                    
                                </div>
    
                            </div>
                        </div>
    
                        <div class="col-xs-3 col-sm-auto">
                            <div class="table-responsive">
                                <table id="example-datatable" class="table table-bordered table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="text-center font-size cursor-pointer"
                                            (click)="cambioDeCampo('IDTransaccion')">
                                                Id Transaccion
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                            <th scope="col" class="text-center font-size cursor-pointer"
                                            (click)="cambioDeCampo('EstadoTransaccionMapeada')">
                                                Estado
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                            <th scope="col" class="text-center font-size cursor-pointer"
                                            (click)="cambioDeCampo('FechaReporte')">
                                                Fecha
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let report of listaReportesFiltrado | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                                            <td class="text-center cursor-pointer" (click)="displayReport(report?.Rut + '-' + report?.DV, report?.IDTransaccion)"> <!---->
                                            {{report?.IDTransaccion}}
                                            </td>
                                            <td class="text-center cursor-pointer" (click)="displayReport(report?.Rut + '-' + report?.DV, report?.IDTransaccion)"> <!---->
                                                <span class="label label-success"
                                                [ngClass]="{'label-danger' : report?.EstadoTransaccion === 'ERROR', 'label-success' : report?.EstadoTransaccion === 'COMPLETED', 'label-warning' : report?.EstadoTransaccion === 'IN-PROGRESS'}"
                                                >{{report?.EstadoTransaccionMapeada}}</span>
                                            </td>
                                            <td class="text-center cursor-pointer" (click)="displayReport(report?.Rut + '-' + report?.DV, report?.IDTransaccion)"> <!---->
                                                {{report?.FechaFormatted}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
    
                        <div class="card-footer text-muted">
                            <div class="p-2">
                                <div id="pagination">
                                    <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                                        <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    
    </ng-container>

    
    <div class="mb-5" *ngIf="showReporte && !showForm && !showList">
        <div class="row">
            <div class="container-gtp mb-5">
                <img class="banner-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt=""/>
                <h2 class="text-center pt-3">Malla Societaria</h2>
                <h5 class="text-center">
                    <span class="badge bg-secondary text-center">
                        <span *ngIf="rut">
                            {{rut | rutFormat | uppercase}}
                        </span>
                    </span>
                </h5>
            </div>
    
            <div class="col-12">
                <div class="w-95 p-5 bg-white">
                    <button type="button" class="btn btn-primary" (click)="backToList()">Volver</button>
                        
                    <span class="badge badge-pill badge-success float-right mt-3 mr-3" *ngIf="fechaReporte">{{(fechaReporte) ? (fechaReporte | date: 'dd-MM-yyyy H:mm:ss') : '' }}</span>
    
                    <app-reporte-malla-societaria-view [reporteMalla]="dataOrg" [rut]="rut" [buscar]="true"></app-reporte-malla-societaria-view>

                </div>
            </div>
            
        </div>
    
    </div>

</ng-container>
