import { Component, Input, OnInit } from '@angular/core';
import { obtenerPeriodos as obtenerPeriodosF29 } from 'src/app/models/formulario-f29/formulario-f29.model';
import { environment as env } from 'src/environments/environment';
import { FormularioF22Service } from 'src/app/shared/services/formulario-f22.service';

@Component({
  selector: 'app-f22-f29-informativo-view',
  templateUrl: './f22-f29-informativo-view.component.html',
  styleUrls: ['./f22-f29-informativo-view.component.scss']
})
export class F22F29InformativoViewComponent implements OnInit {
  public objectKeys = Object.keys;
  @Input() periodos: string[] = [];
  @Input() objectF29: any = {};
  @Input() objectF22: any = {};
  public periodosF22: any = {};
  public periodosF29: any = {};
  public meses: {valor: number, nombre: string}[] = [
    {valor: 1, nombre: "Enero"},
    {valor: 2, nombre: "Febrero"},
    {valor: 3, nombre: "Marzo"},
    {valor: 4, nombre: "Abril"},
    {valor: 5, nombre: "Mayo"},
    {valor: 6, nombre: "Junio"},
    {valor: 7, nombre: "Julio"},
    {valor: 8, nombre: "Agosto"},
    {valor: 9, nombre: "Septiembre"},
    {valor: 10, nombre: "Octubre"},
    {valor: 11, nombre: "Noviembre"},
    {valor: 12, nombre: "Diciembre"}
  ];

  public currentPage = 0;
  public currentPageF22 = 0;
  public currentItem = env.initItemPerPage;

  constructor(
    private formularioF22Service: FormularioF22Service,
  ) { }

  ngOnInit(): void {
    this.periodos = this.ordenarPeriodos(this.periodos);
    this.generarPeriodosF22();
    this.generarPeriodosF29();
  }

  generarPeriodosF29(): void {
    const periodosElegidos: string[] = this.periodos;
    for (const property in this.objectF29) {
      //debugger
      if(this.objectF29[property] && this.objectF29[property].length > 0){
        const periodos = obtenerPeriodosF29(this.objectF29[property]);
        const periodosFiltrados = periodos.filter(function(item) {
          return periodosElegidos.includes(item.toString());
        }).map(function(item) {
          return item.toString();
        });

        const f29Filtrados: any[] = [];
        this.objectF29[property].forEach((elementF29: any) => {
          //debugger
          if(elementF29?.Formulario29Reporte?.Reporte?.periodo){
            const periodoF29 = elementF29?.Formulario29Reporte?.Reporte?.periodo.toString() || '';
            if(periodosFiltrados.includes(periodoF29)) {
              f29Filtrados.push({
                mes: elementF29?.Formulario29Reporte?.Reporte?.mes,
                periodo: periodoF29
              });
            }
          }
        });

        this.periodosF29[property] = f29Filtrados.sort(function(a, b) {
          if(a.periodo === b.periodo) {
              return a.mes - b.mes;   // menor a mayor
          } else {
              return b.periodo - a.periodo;   // mayor a menor
          }
        });
      } else {
        this.periodosF29[property] = [];
      }
    }
  }

  generarPeriodosF22(): void {
    const periodosElegidos: string[] = this.periodos;
    for (const property in this.objectF22) {
      if(this.objectF22[property] && this.objectF22[property].length > 0){
        const periodos = this.formularioF22Service.obtenerPeriodos(this.objectF22[property]);
        const periodosFiltrados = periodos.filter(function(item) {
          return periodosElegidos.includes(item.toString());
        }).map(function(item) {
          return item.toString();
        });
        this.periodosF22[property] = this.ordenarPeriodos(periodosFiltrados);
      } else {
        this.periodosF22[property] = [];
      }
    }
  }

  ordenarPeriodos(array: string[]): string[] {
    return array.sort(function(a, b) {
      return Number(b) - Number(a);
    });
  }

  validaMesPeriodo(periodo: string, mes: number, array: {mes: number, periodo: string}[]): boolean {
    if(array && array.length > 0){
      const elemento = array.find(e => e.mes === mes && e.periodo === periodo);
      if(elemento) return true;
    }
    return false;
  }

}
