import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-informe-f29-view',
  templateUrl: './informe-f29-view.component.html',
  styleUrls: ['./informe-f29-view.component.scss']
})
export class InformeF29ViewComponent implements OnInit {
  @Input() reporteFullContent!: any | null;
  @Input() rut!: string | null;
  reporte: any;

  constructor(
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void { 
    this.reporte = this.reporteFullContent
    this.utilsService.changeSpanExtra(' > Ventas')
  }

  clickHeader(event: any): void{
    const elementoHTML = document.getElementById("collapsable-f29-" + event);
    const botonlHTML = document.getElementById("icon-l-f29-" + event);
    const botondHTML = document.getElementById("icon-d-f29-" + event);
    if (elementoHTML && botonlHTML && botondHTML){
      elementoHTML.hidden = !elementoHTML.hidden;
      botondHTML.hidden = !botondHTML.hidden;
      botonlHTML.hidden = !botonlHTML.hidden;

    }
  }

  descargarExcel(): void{
    // Cabecera
    const header =
    {
      A: "Mes",	
      B: "Ventas Netas M$", 
      C: "Ventas Netas UF",
      D: "Compras Netas M$", 
      E: "Compras Netas UF", 
      F: "Margen M$",	
      G: "% NC / Ventas", 
      H: "Fecha Pago", 
      I: "Tipo Declaracion",	
      J: "Postergado",	
      K: "Variacion % Ventas" 
    };

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    
    this.reporte.forEach((element: {
      mesesEnPeriodo: any;
      totalMargenMiles?: any;
      totalComprasNetasUF?: any;
      totalComprasNetasMiles?: any;
      totalVentasNetasUF?: any;
      totalVentasNetasMiles?: any; 
      totalPorcVentasMiles?: any; 
      totalPostergado?: any;
      totalVarVentas?: any;
      periodo?: string | any , 
      datosReporte?: any[],
      rut: string,
      nombreRazonSocial: string,
    }) => {
      const ws = XLSX.utils.json_to_sheet([
        { A: "Rut", 
          B: element.rut, 
        }, 
        {
          A: "Nombre",
          B: element.nombreRazonSocial
        },
        {
          A: "",
          B: ""
        }
      ], {header: ["A", "B"], skipHeader: true});

      XLSX.utils.sheet_add_json(ws, [
        header
      ], {header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"], skipHeader: true, origin: -1});


      element.datosReporte?.forEach((dato: any) => {
        
        XLSX.utils.sheet_add_json(ws, [
          { A: dato.nombreMes, 
            B: dato.ventasNetasMiles, 
            C: dato.ventasNetasUF, 
            D: dato.comprasNetasMiles, 
            E: dato.comprasNetasUf, 
            F: dato.margenMiles, 
            G: dato.porc_notascred_ventas != '' ? Number(Number(dato.porc_notascred_ventas).toFixed(2)): dato.porc_notascred_ventas,
            H: dato.fechaPago,
            I: dato.tipoDeclaracion,
            J: dato.postergado,
            K: dato.variacion != '' ? Number(Number(dato.variacion).toFixed(2)) : dato.variacion
          }
        ], {header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"], skipHeader: true, origin: -1});
      })

      XLSX.utils.sheet_add_json(ws, [
        { A: "Total", 
          B: element.totalVentasNetasMiles, 
          C: element.totalVentasNetasUF, 
          D: element.totalComprasNetasMiles, 
          E: element.totalComprasNetasUF, 
          F: element.totalMargenMiles, 
          G: element.totalPorcVentasMiles || element.totalPorcVentasMiles == 0 ? Number(Number(element.totalPorcVentasMiles).toFixed(2)): element.totalPorcVentasMiles, 
          H: "",
          I: "",
          J: element.totalPostergado,
          K: element.totalVarVentas || element.totalVarVentas == 0 ? Number(Number(element.totalVarVentas).toFixed(2)): element.totalVarVentas
        }
      ], {header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"], skipHeader: true, origin: -1});

      XLSX.utils.sheet_add_json(ws, [
        { A: "Promedio", 
          B: (element.totalVentasNetasMiles / element.mesesEnPeriodo) % 1 != 0 ? parseInt((element.totalVentasNetasMiles / element.mesesEnPeriodo).toFixed()) : (element.totalVentasNetasMiles / element.mesesEnPeriodo), 
          C: (element.totalVentasNetasUF / element.mesesEnPeriodo) % 1 != 0 ? parseInt((element.totalVentasNetasUF / element.mesesEnPeriodo).toFixed()) : (element.totalVentasNetasUF / element.mesesEnPeriodo),
          D: (element.totalComprasNetasMiles / element.mesesEnPeriodo) % 1 != 0 ? parseInt((element.totalComprasNetasMiles / element.mesesEnPeriodo).toFixed()) : (element.totalComprasNetasMiles / element.mesesEnPeriodo), 
          E: (element.totalComprasNetasUF / element.mesesEnPeriodo) % 1 != 0 ? parseInt((element.totalComprasNetasUF / element.mesesEnPeriodo).toFixed()) : (element.totalComprasNetasUF / element.mesesEnPeriodo), 
          F: (element.totalMargenMiles / element.mesesEnPeriodo) % 1 != 0 ? parseInt((element.totalMargenMiles / element.mesesEnPeriodo).toFixed()) : (element.totalMargenMiles / element.mesesEnPeriodo),
          G: (element.totalPorcVentasMiles / element.mesesEnPeriodo) % 1 != 0 ? Number((element.totalPorcVentasMiles / element.mesesEnPeriodo).toFixed(2)) : (element.totalPorcVentasMiles / element.mesesEnPeriodo),
          H: "",
          I: "",
          J: (element.totalPostergado / element.mesesEnPeriodo) % 1 != 0 ? parseInt((element.totalPostergado / element.mesesEnPeriodo).toFixed()) : (element.totalPostergado / element.mesesEnPeriodo),
          K: (element.totalVarVentas / element.mesesEnPeriodo) % 1 != 0 ? Number((element.totalVarVentas / element.mesesEnPeriodo).toFixed(2)) : (element.totalVarVentas / element.mesesEnPeriodo)
        }
      ], {header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"], skipHeader: true, origin: -1});
      
      XLSX.utils.sheet_add_json(ws, [
        { A: "Proyeccion", 
          B: ((element.totalVentasNetasMiles / element.mesesEnPeriodo) * 12) % 1 != 0 ? parseInt(((element.totalVentasNetasMiles / element.mesesEnPeriodo) * 12).toFixed()) : ((element.totalVentasNetasMiles / element.mesesEnPeriodo) * 12),
          C: ((element.totalVentasNetasUF / element.mesesEnPeriodo) * 12) % 1 != 0 ? parseInt(((element.totalVentasNetasUF / element.mesesEnPeriodo) * 12).toFixed()) : ((element.totalVentasNetasUF / element.mesesEnPeriodo) * 12),
          D: ((element.totalComprasNetasMiles / element.mesesEnPeriodo) * 12) % 1 != 0 ? parseInt(((element.totalComprasNetasMiles / element.mesesEnPeriodo) * 12).toFixed()) : ((element.totalComprasNetasMiles / element.mesesEnPeriodo) * 12),
          E: ((element.totalComprasNetasUF / element.mesesEnPeriodo) * 12) % 1 != 0 ? parseInt(((element.totalComprasNetasUF / element.mesesEnPeriodo) * 12).toFixed()) : ((element.totalComprasNetasUF / element.mesesEnPeriodo) * 12),
          F: ((element.totalMargenMiles / element.mesesEnPeriodo) * 12) % 1 != 0 ? parseInt(((element.totalMargenMiles / element.mesesEnPeriodo) * 12).toFixed()) : ((element.totalMargenMiles / element.mesesEnPeriodo) * 12),
          G: ((element.totalPorcVentasMiles / element.mesesEnPeriodo) * 12) % 1 != 0 ? Number(((element.totalPorcVentasMiles / element.mesesEnPeriodo) * 12).toFixed(2)) : ((element.totalPorcVentasMiles / element.mesesEnPeriodo) * 12),
          H: "",
          I: "",
          J: ((element.totalPostergado / element.mesesEnPeriodo) * 12) % 1 != 0 ? parseInt(((element.totalPostergado / element.mesesEnPeriodo) * 12).toFixed()) : ((element.totalPostergado / element.mesesEnPeriodo) * 12),
          K: ((element.totalVarVentas / element.mesesEnPeriodo) * 12) % 1 != 0 ? Number(((element.totalVarVentas / element.mesesEnPeriodo) * 12).toFixed(2)) : ((element.totalVarVentas / element.mesesEnPeriodo) * 12)
        }
      ], {header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"], skipHeader: true, origin: -1});

      // Ancho de las columnas
      const wscols = [
        { width: 11 },
        { width: header.B.length + 2 },
        { width: header.C.length + 2 },
        { width: header.D.length + 2 }, 
        { width: header.E.length + 2 },
        { width: header.F.length + 2 }, 
        { width: header.G.length + 2 }, 
        { width: header.H.length + 2 }, 
        { width: 25 },
        { width: header.J.length + 2 },
        { width: header.K.length + 2 }
      ];

      ws["!cols"] = wscols;

      //Formato de numero con separador de miles
      const range = { s: {r:4, c:1}, e: {r:18, c:5} };
      for(let R = range.s.r; R <= range.e.r; ++R) {
        for(let C = range.s.c; C <= range.e.c; ++C) {
          const cell_address = {c:C, r:R};
          const cell_ref = XLSX.utils.encode_cell(cell_address);
          if(ws[cell_ref]){
            ws[cell_ref].z = '#,##0_-;-#,##0_-;0;General'
          }
        }
      }

      //Formato de numero con separador de miles para Postergado
      const rango = { s: {r:4, c:9}, e: {r:18, c:9} };
      for(let R = rango.s.r; R <= rango.e.r; ++R) {
        for(let C = rango.s.c; C <= rango.e.c; ++C) {
          const cell_address = {c:C, r:R};
          const cell_ref = XLSX.utils.encode_cell(cell_address);
          if(ws[cell_ref]){
            ws[cell_ref].z = '#,##0_-;-#,##0_-;0;General'
          }
        }
      }

      XLSX.utils.book_append_sheet(wb, ws, element.periodo.toString());
    })
      
    /* save to file */
    const today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;  

    XLSX.writeFile(wb, "Ventas_" + this.rut + "_" + today.toString()  + ".xls");
  }

}
