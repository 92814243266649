<div class="row p-4">
    <fieldset class="col-sm-12" style="margin-bottom: 20px;">
        <form [formGroup]="filtroFormV">
            <div class="form-group col-sm-12">
                <div class="col-sm-12 col-lg-4">
                    <label class="col-sm-12 control-label" for="entidadRutV">
                        Cliente
                    </label>
                    <ng-container *ngIf="!showSpinnerRutV">
                        <select id="entidadRutV" name="entidadRutV" class="form-control" formControlName="entidadRutV"
                            style="width: 100%;">
                            <option value="">Todos</option>
                            <option *ngFor="let element of listaRutsV" [ngValue]="element.rut">
                                {{ element.nombre }}
                            </option>
                        </select>
                    </ng-container>
                    <ng-container *ngIf="showSpinnerRutV">
                        <div class="spinner-border text-primary mt-2 mx-auto no-print" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </ng-container>
                </div>

                <div class="col-sm-12 col-lg-3">
                    <label class="col-sm-12 control-label" for="selectPeriodosV">Rango Fechas</label>
                    <select class="form-control" id="selectPeriodosV" formControlName="selectPeriodosV">
                        <option value="">Seleccionar</option>
                        <option value="1">1 mes</option>
                        <option value="3">3 meses</option>
                        <option value="6">6 meses</option>
                        <option value="12">Ultimo año</option>
                        <option value="24">Ultimos dos años</option>
                        <option value="36">Ultimos tres años</option>
                    </select>
                </div>
            </div>
        </form>
    </fieldset>
</div>

<!--tabla ventas-->
<div class="block full break-box">
    <div class="block-title">
        <h2><strong>Tabla</strong> Notas de Crédito Ventas</h2>
    </div>

    <div class="table-responsive table-entidad">
        <div class="d-flex justify-content-between mb-2">
            <select class="form-control no-print pagination-custom" [(ngModel)]="currentItemV" (ngModelChange)="cambioPaginacion('VENTA')">
                <option *ngFor="let element of paginationOptionsV" [ngValue]="element">
                    {{ element }}
                </option>
            </select>

            <button type="button" class="btn btn-success no-print" (click)="descargarRegistros('VENTA')">Descargar Registros</button>
        </div>
        
        
        <table id="example-datatable" class="table table-vcenter table-condensed table-bordered">
            <thead>
                <tr>
                    <th class="text-center bg-primary" style="color:#fff; font-size: 13px;">Tipo Venta</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Tipo Documento</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">RUT Cliente</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Razón Social</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Folio</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Documento</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Recepción</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Documento Referencia</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Tipo Doc. Referencia</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Total</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="paginaActualV.length > 0; else noPageVentas">
                    <tr *ngFor="let report of paginaActualV | paginate:{id: 'pagination-ventas', itemsPerPage: currentItemV, currentPage: currentPageV, totalItems: cantidadRegistrosV}">
                        <td class="text-center">{{report?.TipoVenta}}</td>
                        <td>{{report?.tipoDocumentoMap || ''}}</td>
                        <td>{{report?.Rutcliente ? (report?.Rutcliente | rutFormat) : ''}}</td>
                        <td>{{report?.RazonSocial}}</td>
                        <td>{{report?.Folio	|| ''}}</td>
                        <td>{{(report?.FechaDocto) ? (esFormatoFecha(report?.FechaDocto) ? (cambiarFormatoFecha(report?.FechaDocto)) : (report?.FechaDocto | date: 'dd-MM-yyyy')) : '' }}</td>
                        <td>{{(report?.FechaRecepcion) ? (esFormatoFechaHora(report?.FechaRecepcion) ? (cambiarFormatoFechaHora(report?.FechaRecepcion)) : (report?.FechaRecepcion | date: 'dd-MM-yyyy H:mm:ss')) : '' }}</td>
                        <td>{{report?.FolioDoctoReferencia}}</td>
                        <td>{{report?.tipoDocumentoRefMap || ''}}</td>
                        <td>${{(report?.Montototal || report?.Montototal == 0) ? (report?.Montototal | currency:'CLP':'':'1.0-0') : '' }}</td>
                    </tr>
                </ng-container>
                <ng-template #noPageVentas>
                    <tr>
                        <td colspan="100%">
                            Sin información
                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <div class="col-12 p-2 mb-3 no-print">
        <div id="pagination">
            <div class="col-sm-12 col-md-7 float-right">
                <pagination-controls id="pagination-ventas" (pageChange)="getPage($event, 'VENTA')"></pagination-controls>
            </div>
        </div>
    </div>
</div>

<div class="row p-4"><!--Se debe definir como otros input aparte de los primeros-->

    <fieldset class="col-sm-12" style="margin-bottom: 20px;">
        <form [formGroup]="filtroFormC">
            <div class="form-group col-sm-12">
                <div class="col-sm-12 col-lg-4">
                    <label class="col-sm-12 control-label" for="entidadRutC">
                        Proveedor
                    </label>
                    <ng-container *ngIf="!showSpinnerRutC">
                        <select id="entidadRutC" name="entidadRutC" class="form-control" formControlName="entidadRutC"
                            style="width: 100%;">
                            <option value="">Todos</option>
                            <option *ngFor="let element of listaRutsC" [ngValue]="element.rut">
                                {{ element.nombre }}
                            </option>
                        </select>
                    </ng-container>
                    <ng-container *ngIf="showSpinnerRutC">
                        <div class="spinner-border text-primary mt-2 mx-auto no-print" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </ng-container>
                </div>

                <div class="col-sm-12 col-lg-3">
                    <label class="col-sm-12 control-label" for="selectPeriodosC">Rango Fechas</label>
                    <select class="form-control" id="selectPeriodosC" formControlName="selectPeriodosC">
                        <option value="">Seleccionar</option>
                        <option value="1">1 mes</option>
                        <option value="3">3 meses</option>
                        <option value="6">6 meses</option>
                        <option value="12">Ultimo año</option>
                        <option value="24">Ultimos dos años</option>
                        <option value="36">Ultimos tres años</option>
                    </select>
                </div>
            </div>
        </form>
    </fieldset>
</div>

<!--tabla compras-->
<div class="block full break-box">
    <div class="block-title">
        <h2><strong>Tabla</strong> Notas de Crédito Compras</h2>
    </div>

    <div class="table-responsive table-entidad">
        <div class="d-flex justify-content-between mb-2">
            <select class="form-control no-print pagination-custom" [(ngModel)]="currentItemC" (ngModelChange)="cambioPaginacion('COMPRA')">
                <option *ngFor="let element of paginationOptionsC" [ngValue]="element">
                    {{ element }}
                </option>
            </select>

            <button type="button" class="btn btn-success no-print" (click)="descargarRegistros('COMPRA')">Descargar Registros</button>
        </div>
        
        <table id="example-datatable" class="table table-vcenter table-condensed table-bordered">
            <thead>
                <tr>
                    <th class="text-center bg-primary" style="color:#fff; font-size: 13px;">Tipo Compra</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Tipo Documento</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">RUT Proveedor</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Razón Social</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Folio</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Documento</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Fecha Recepción</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Documento Referencia</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Tipo Doc. Referencia</th>
                    <th class="bg-primary" style="color:#fff; font-size: 13px;">Monto Total</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="paginaActualC.length > 0; else noPageCompras">
                    <tr *ngFor="let report of paginaActualC | paginate:{id: 'pagination-compras',itemsPerPage: currentItemC, currentPage: currentPageC, totalItems: cantidadRegistrosC}"><!--definir mapeos correctos-->
                        <td class="text-center">{{report?.TipoCompra}}</td>
                        <td>{{report?.tipoDocumentoMap || ''}}</td>
                        <td>{{report?.RUTProveedor ? (report?.RUTProveedor | rutFormat) : ''}}</td>
                        <td>{{report?.RazonSocial}}</td>
                        <td>{{report?.Folio	|| ''}}</td>
                        <td>{{(report?.FechaDocto) ? (esFormatoFecha(report?.FechaDocto) ? (cambiarFormatoFecha(report?.FechaDocto)) : (report?.FechaDocto | date: 'dd-MM-yyyy')) : '' }}</td>
                        <td>{{(report?.FechaRecepcion) ? (esFormatoFechaHora(report?.FechaRecepcion) ? (cambiarFormatoFechaHora(report?.FechaRecepcion)) : (report?.FechaRecepcion | date: 'dd-MM-yyyy H:mm:ss')) : '' }}</td>
                        <td>{{report?.detFolioDocRef}}</td>
                        <td>{{report?.tipoDocumentoRefMap || ''}}</td>
                        <td>${{(report?.MontoTotal || report?.MontoTotal == 0) ? (report?.MontoTotal | currency:'CLP':'':'1.0-0') : '' }}</td>
                    </tr>
                </ng-container>
                <ng-template #noPageCompras>
                    <tr>
                        <td colspan="100%">
                            Sin información
                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <div class="col-12 p-2 mb-3 no-print">
        <div id="pagination">
            <div class="col-sm-12 col-md-7 float-right">
                <pagination-controls id="pagination-compras" (pageChange)="getPage($event, 'COMPRA')"></pagination-controls>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="errores.length > 0">
    <div class="row no-print">
        <div class="col-md-12">
            <div class="block full">
                <div class="block-title">
                    <h2>Detalle Errores</h2>
                </div>
                <div class="size-error">
                    <div class="col-12">
                        <ul class="list-group list-group-light">
                            <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center my-2 list-group-error" *ngFor="let item of errores">
                              <div>
                                <div class="text-muted">{{ item.msg || '' }}</div>
                                <div class="text-muted"><b>Mes:</b> {{ item.mes }} <b>Periodo:</b> {{item.periodo}}</div>
                              </div>
                              <span class="badge rounded-pill badge-danger">Error</span>
                            </li>
                          </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
