<div class="form-group box-custom-bars">
    <div class="d-flex-custom btn-group" role="group" aria-label="Basic radio toggle button group">
        <ng-container *ngFor="let item of chartUtils">
            <input type="radio" class="btn-check" name="inputBars" [id]="item.id" [value]="item.value">
            <label class="btn btn-outline-primary" [for]="item.id" [ngClass]="item.id">
                {{item.name}}
                <div class="selected" *ngIf="textRiegos === item.value"></div>
                <div class="divider" [attr.data-title]='item.range.min + " - " + item.range.max'></div>
            </label>
        </ng-container>
    </div>
</div>