import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription, forkJoin, from, of } from 'rxjs';
import { ESystemAccess } from 'src/app/enum/EAccess';
import { AlertService } from 'src/app/components/_alert';
import { SolicitudCreditoServiceWorkflow } from '../../../../shared/services/solicitud-credito-workflow.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Chart } from 'angular-highcharts';
import Highcharts from 'highcharts';
import { catchError, filter, map, mergeMap, toArray } from 'rxjs/operators';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ModalBasicoConsolidadoService } from 'src/app/shared/services/modal-basico-consolidado.service';
//import { SimpleModalService } from 'ngx-simple-modal';
import { ListDatesComponent } from '../../../list-dates/list-dates.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

interface TotalesCategorias {
  anio: string | number,
  TotalAnio: Totales,
  TotalProm: Totales,
}

interface Totales {
  ventas: number,
  compras: number,
  posterga: number,
  meses?: number,
}

@Component({
  selector: 'app-plantilla-panel-riesgo',
  templateUrl: './plantilla-panel-riesgo.component.html',
  styleUrls: ['./plantilla-panel-riesgo.component.scss']
})
export class PlantillaPanelRiesgoComponent implements OnInit {
  @Input() plantillaNoEditable: boolean = false;
  @Input() objEntradaFicha: any = {};
  @Input() objEntradaMotor: any = {};

  @Output() operacionNuevo = new EventEmitter<boolean>();
  @Output() finalizarFlujo = new EventEmitter<void>();
  @Output() bloqueaPasos5 = new EventEmitter<boolean>();
  @Output() bloqueaPasos6 = new EventEmitter<boolean>();
  @Input() idTransaccion: string = '';
  @Input() stepper!: number;
  @Input() rut: string = '';
  @Input() closed: boolean = true;

  public objectKeys = Object.keys;
  private access: string[] = [
    ESystemAccess.FLUJO_CREDITO_BUSCAR, 
    ESystemAccess.FLUJO_CREDITO_BUSCAR_ADMIN,
    ESystemAccess.FLUJO_CREDITO,
    ESystemAccess.FLUJO_CREDITO_ADMIN
  ];

  public subscriptions: Subscription[] = [];
  public data: any;
  public today = '';
  public bodyModelos: any = {};
  public graphTipoDeudores: any = {};
  
  public groupNameConsulting: string[] = ['buscarReportePrincipal', 'buscarReportesDerivados'];
  public groupNameConsultingFicha: string[] = ['buscarFichaDeCredito', 'buscarReportesDerivados', 'buscarReportePrincipal'];
  public groupNameConsultingScore: string[] = ['buscarCalculoScore', 'buscarReportesDerivados', 'buscarReportePrincipal'];

  public errores: any[] = [];
  public contratoServicios: any[] = [];
  public atributos: any[] = [];
  public listaReportesPrincipales: any[] = [];
  public listaReportesDerivados: any = {};
  public entidadesDerivadas: any = {};
  public reportesPrincipalesObj: any = {};
  public reportesDerivadosObj: any = {};

  public datosCabecera: any = {};
  public tablaVentasCompras: any = {};
  public informesComerciales: any = {};
  public tablaBalance: any = {};
  public tablaSocios: any[] = [];
  public tablaVentaCruzada: any[] = [];
  public tablaIvaSobreVentas: any = {};
  public tablaDeudaMorosaTGR: any[] = [];
  public fechasCompraVenta: any[] = [];
  public graphDsCliente: any[] | null = null;

  public consolidadoTitular: any = {};
  public plantillaAntecedentes = 'cesceDemo';
  public modalDefault = true;
  public reportesSeleccionado: any = {};
  public rutSeleccionado: string = '';
  public informeComercial: string = '';
  public fechaTx: string = '';
  //public libroCompraVenta: any = [];

  public showReportes: boolean = false;
  public showArchivos: boolean = false;

  constructor(
    public alertService: AlertService,
    private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private spinner: NgxSpinnerService,
    private utilsService: UtilsService,
    private modalService: ModalBasicoConsolidadoService,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.operacionNuevo.emit(false);
    this.setToday();
    if(this.plantillaNoEditable === false){
      this.obtenerFlujo();
    }
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  // Logica de revision de paso y llamada a servicios

  obtenerFlujo(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      let paso: any;
      let pasoAnterior: any;
      const pasosCompleted = resp.stages.filter((e: any) => e.completed);
      const cantidad = pasosCompleted.length;
      const ultimoPasoCompletado = Number(pasosCompleted[cantidad - 1].stageID);
      this.closed = (resp.closed === null || resp.closed === undefined) ? true : resp.closed;
      this.showArchivos = true;

      if(this.stepper === 5){
        resp.stages.forEach((e: any) => {
          if(e.stageID === '10'){
            paso = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(paso?.stageID === '10' && (paso?.completed === false && paso?.optional === false)){ //pasoAnterior && !pasoAnterior?.completed
          let pasoAnteriorCompleted = ultimoPasoCompletado;
          if(ultimoPasoCompletado === 9)
            pasoAnteriorCompleted = 8;

          this.redireccionPasoAnterior(pasoAnteriorCompleted);
        } else {
          if (paso?.stageID === '10' && paso?.completed === true) { // Se realizo el calculo del score
          } else {
            this.redireccionPasoAnterior(ultimoPasoCompletado);
          }
        }
      } else {
        resp.stages.forEach((e: any) => {
          if(e.stageID === '9'){
            paso = e;
          } else if (e.stageID === '8'){
            pasoAnterior = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(pasoAnterior && (!pasoAnterior?.completed && pasoAnterior?.optional === false)){
          this.redireccionPasoAnterior(ultimoPasoCompletado);
        } else {
          if (paso?.stageID === '9' && paso?.completed === false) { // primera vez en esta pantalla -> cambiar para pruebas
            this.callServices();
          }
          else if(paso?.stageID === '9' && paso?.completed === true){ // ya se habia llegado antes a esta pantalla -> cambiar para pruebas
            this.callServices();
          }
        }
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));

  }

  async callServices(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const value of this.groupNameConsulting) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServices");
      }
    },
      (error) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    ));
  }

  setError(reporte: string, error: string): void {
    this.errores.push({
      id: reporte,
      msg: error
    })
  }

  setResponse(service: string, response: any): void {
    if (service === 'buscarReportePrincipal'){

      this.contratoServicios = response?.Stage3Response?.ContratoServicios || [];
      this.atributos = response?.Stage2Response?.Atributos || [];
      this.listaReportesPrincipales = response?.ReportKeys || [];
      this.fechaTx = response?.DatosBasicosSolicitud?.FechaReporte || '';

    }
    else if (service === 'buscarReportesDerivados'){

      this.listaReportesDerivados = response?.ReportKeys || {};
      this.entidadesDerivadas = response?.Stage5Response?.Entidades;

    } else if (service === 'buscarFichaDeCredito'){

    } else if (service === 'buscarCalculoScore'){

    }
  }

  //Definicion llamada a servicios asincronos por primera vez en la vista

  getServices(service: string): any {
    const objeto: any = {
      'buscarReportePrincipal': () => {
        return this.solicitudCreditoService.buscarReportePrincipal(this.idTransaccion, this.rut)
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes principales'), of(null))));
      },
      'buscarReportesDerivados': () => {
        return this.solicitudCreditoService.buscarReportesDerivados(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes derivados'), of(null))));
      },
      'buscarFichaDeCredito': () => {
        return this.solicitudCreditoService.buscarFichaDeCredito(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha'), of(null))));
      },
      'buscarCalculoScore': () => {
        return this.solicitudCreditoService.buscarCalculoScore(this.rut, this.idTransaccion)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha con Score'), of(null))));
      }
    };
    return objeto[service]();
  }

  buscarReportes(etapa: string): void {
    const apiServicesReportes: any = this.utilsService.obtenerServicesReportes(this.listaReportesPrincipales, this.listaReportesDerivados) || [];
    let count = 0;

    this.spinner.show();
    from(apiServicesReportes).pipe( 
      mergeMap(
        (service: any) => 
          this.solicitudCreditoService.buscarReportes(
            this.idTransaccion, 
            this.rut, 
            service.principal, 
            service.reportKey, 
            service.entidad, 
            service.rutDerivado
          )
          .pipe(
            catchError(error => {
              //console.error(`Error en la consulta del servicio: ${service.principal}, ${service.reportKey}, ${service.entidad}, ${service.rutDerivado}`);
              return of(null); // Retorna un observable que emite un valor nulo
            }),
            map(response => {
              if (response !== null) {
                count++;
              }
              return {
                service: service,
                response: response
              };
            }),
          ),
          20
        ), 
        filter(response => response !== null),
      toArray()
    ).subscribe(responses => {
      if (count === 0) { // Si no hubo respuestas válidas
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      } else if (count < apiServicesReportes.length) { // Si hubo respuestas válidas pero no para todos los servicios
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      }
      if(count > 0){
        this.reportesPrincipalesObj = this.utilsService.mapeoReportesPrincipales(responses);
        this.reportesDerivadosObj = this.utilsService.mapeoReportesDerivados(responses);
        if (etapa === 'callServices'){
          this.mapeoReportes();
          this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      }
    }, error => {
      if (count > 0) {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      } else {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      }
    });
  }

  redireccionPasoAnterior(ultimoPasoCompletado: number): void {
    let step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado + 1));
    if (!step[0]?.step){
      step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado));
    }
    this.alertService.error(`Solicitud de Crédito - Debe completar el paso N° ${Number(step[0]?.step)} para poder continuar`);
    this.solicitudCreditoService.setStep(Number(step[0]?.step));
  }

  obtenerDatosF22(): any[] {
    const carpetaF22 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f22 || [];
    const historicoF22 = this.reportesPrincipalesObj?.f22?.reporte?.ListadoF22Reportes || [];
    const calculadoF22 = this.reportesPrincipalesObj?.f22BalancesRentas?.reporte?.Formulario22Reporte || {};
    const f22Manual = this.reportesPrincipalesObj?.balanceManual?.reporte?.ListadoF22Reportes || [];

    const F22: any[] = [];
    carpetaF22.forEach((element: any) => {
      if(element) {
        element.FechaReporte = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(element);
      }
    });
    historicoF22.forEach((element: any) => {
      if(element) {
        const reporte: any = element?.Formulario22Reporte?.Reporte;
        reporte.FechaReporte = element?.Formulario22Reporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(reporte);
      }
    });
    if(calculadoF22 && Object.keys(calculadoF22).length > 0){
      const elemento: any = calculadoF22?.Reporte;
      if(elemento) {
        elemento.FechaReporte = calculadoF22?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(elemento);
      }
    }
    f22Manual.forEach((element: any) => {
      if(element) {
        const reporte: any = element?.Formulario22Reporte?.Reporte;
        reporte.FechaReporte = element?.Formulario22Reporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(reporte);
      }
    });

    const ordenadoF22 = F22.sort((a: any,b: any) => Number(b?.calculado?.periodo) - Number(a?.calculado?.periodo)); // mayor a menor en año
    const F22Limpio: any[] = [];

    ordenadoF22.forEach((element: any) => {
      if(element?.calculado?.periodo){
        const indexRepetido = F22Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo));
        if(indexRepetido >= 0){
          if(F22Limpio[indexRepetido]?.FechaReporte < element.FechaReporte){
            F22Limpio[indexRepetido] = element;
          }
        } else {
          F22Limpio.push(element);
        }
      }
    });
    return F22Limpio;
  }

  obtenerDatosF29(): any[] {
    const carpetaF29 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f29 || [];
    const historicoF29 = this.reportesPrincipalesObj?.f29?.reporte?.ListadoF29Reportes || [];
    const calculadoF29 = this.reportesPrincipalesObj?.f29Ventas?.reporte?.Formulario29Reporte?.Reporte || {};
    
    const F29: any[] = [];
    carpetaF29.forEach((element: any) => {
      if(!element?.message){
        F29.push(element);
      }
    });
    historicoF29.forEach((element: any) => {
      if(!element?.Formulario29Reporte?.Reporte.message){
        F29.push(element?.Formulario29Reporte?.Reporte);
      }
    });
    if(calculadoF29 && Object.keys(calculadoF29).length > 0 && !calculadoF29?.message){
      F29.push(calculadoF29);
    }

    const ordenadoF29 = F29.sort((a: any,b: any) => { // mayor a menor en año y menor a mayor en mes
      if (!a?.calculado) return 1;
      if (!b?.calculado) return -1;
      const periodoDiff = Number(b.calculado.periodo) - Number(a.calculado.periodo);
      if (periodoDiff !== 0) return periodoDiff;
      return Number(a.calculado.mes) - Number(b.calculado.mes);
    });
    const F29Limpio: any[] = [];

    ordenadoF29.forEach((element: any) => {
      const indexRepetido = F29Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo) && Number(e?.calculado?.mes) === Number(element?.calculado?.mes));
      if(indexRepetido >= 0){
        if(F29Limpio[indexRepetido]?.fecha_ingreso < element.fecha_ingreso){
          F29Limpio[indexRepetido] = element;
        }
      } else {
        F29Limpio.push(element);
      }
    })

    return F29Limpio;
  }

  formateoFecha(fechaEntrada: string): string {
     if (fechaEntrada.includes("T")) {
      const fecha = new Date(fechaEntrada);
      const dia = fecha.getDate().toString().padStart(2, '0');
      const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
      const anio = fecha.getFullYear().toString();
      return `${dia}-${mes}-${anio}`;
    } else  {
      return fechaEntrada;
    }
  }

  mapeoReportes(): void {
    const F22Limpio = this.obtenerDatosF22();
    const F29Limpio = this.obtenerDatosF29();

    const fechaActual = new Date();

    const fechaInicioActividades = this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades || '';
    const partesFecha: any = fechaInicioActividades.split("-");
    const fechaComparacion = new Date(partesFecha[0], partesFecha[1] - 1, partesFecha[2]);

    const anio = fechaActual.getFullYear();

    const diferenciaMilisegundos = fechaActual.getTime() - fechaComparacion.getTime();
    const diferenciaAnios = diferenciaMilisegundos / (1000 * 60 * 60 * 24 * 365.25);

    let aniosF29 = [];
    if (F29Limpio.length === 0) {
      aniosF29 = [anio, anio - 1];
    } else if (F29Limpio.length === 1) {
      const anioUnico = F29Limpio[0].periodo ? F29Limpio[0].periodo : anio;
      aniosF29 = [anioUnico, anioUnico - 1];
    } else {
      const aniosUnicos = [...new Set(F29Limpio.map(obj => obj.periodo))];
      aniosUnicos.sort((a, b) => b - a);

      const anioActual: any = aniosUnicos[0] ? aniosUnicos[0] : (aniosUnicos[1] ? (aniosUnicos[1] + 1) : anio);
      const anioAnterior: any = aniosUnicos[1] ? aniosUnicos[1] : (aniosUnicos[0] ? (aniosUnicos[0] - 1) : anio - 1);

      aniosF29 = [anioActual, anioAnterior];
    }

    let aniosF22 = [];
    if (F22Limpio.length === 0) {
      aniosF22 = [anio, anio - 1];
    } else if (F22Limpio.length === 1) {
      const anioUnicoF22 = F22Limpio[0]?.calculado?.periodo;
      if(anioUnicoF22){
        aniosF22 = [anioUnicoF22, anioUnicoF22 - 1];
      } else {
        aniosF22 = [anio, anio - 1];
      }
    } else {
      const aniosUnicosF22 = [...new Set(F22Limpio.map(obj => obj?.calculado?.periodo))];
      aniosUnicosF22.sort((a, b) => b - a);
      aniosF22 = [aniosUnicosF22[0], aniosUnicosF22[1]];
    }

    this.consolidadoTitular = this.utilsService.crearConsolidadoTitular(F22Limpio, F29Limpio, this.reportesPrincipalesObj);
    this.tablaVentasCompras = this.crearTablaVentasCompras(aniosF29[0], aniosF29[1], F29Limpio);
    this.tablaBalance = this.crearTablaBalance(aniosF22[0], aniosF22[1], F22Limpio);
    this.tablaIvaSobreVentas = this.crearTablaIvaSobreVentas(aniosF29[0], aniosF29[1], F29Limpio);
    this.tablaDeudaMorosaTGR = this.utilsService.crearTablaDeudaMorosaTGR(this.reportesPrincipalesObj?.tesoreria?.reporte?.reporteTesoreria);

    const indexSocios = this.entidadesDerivadas.findIndex((e: any) => e?.entidad === 'socios');
    if(indexSocios >= 0){
      this.tablaSocios = this.crearTablaSocios(this.entidadesDerivadas[indexSocios]?.items || [], this.reportesPrincipalesObj?.basicReport?.reporte?.AntecedentesSocietarios || [], 'socios');
    }

    const compraVenta = this.entidadesDerivadas.find((e: any) => e?.entidad === 'cyv');
    if(compraVenta){
      this.tablaVentaCruzada = this.crearTablaVentaCruzada(compraVenta.items || []);
    }

    const nCausasCivil = Number(this.reportesPrincipalesObj?.poderJudicial?.reporte?.["Totalizadores Poder Judicial"]?.nCausasCivil) || 0;
    const nCausasCobranza = Number(this.reportesPrincipalesObj?.poderJudicial?.reporte?.["Totalizadores Poder Judicial"]?.nCausasCobranza) || 0;
    const nCausasLaboral = Number(this.reportesPrincipalesObj?.poderJudicial?.reporte?.["Totalizadores Poder Judicial"]?.nCausasLaboral) || 0;

    const demandas = nCausasCivil + nCausasCobranza + nCausasLaboral;

    this.datosCabecera = {
      fechaReporte: this.formateoFecha(this.fechaTx) || '',
      antiguedadEmpresa: diferenciaAnios || '',
      direccion: this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.Direccion || '',
      actividad: this.reportesPrincipalesObj?.basicReport?.reporte?.Riesgo?.ActividadEconomica || '',
      indRiesgo: this.reportesPrincipalesObj?.basicReport?.reporte?.Riesgo?.Clasificacion || '',
      demandas: (demandas > 0) ? ('Si') : ('No') || '',
    };

    this.agregarVentasCabecera(F29Limpio);

    this.informesComerciales = {
      protestos: (Number(this.reportesPrincipalesObj?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.ProtestosMonto)),
      morosidad: (Number(this.reportesPrincipalesObj?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.MorosidadesMonto)),
      infraccionesLaborales: (Number(this.reportesPrincipalesObj?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesMonto))
    }

    this.agregarFinancierasInformes(this.reportesPrincipalesObj.deudaSbif?.reporte?.Sbif?.rowsMeses?.item || []);
    //this.libroCompraVenta = this.reportesPrincipalesObj?.libroCompraVenta?.reporte?.Libros || [];

    this.graphDsCliente = this.validaDsCliente(this.listaReportesDerivados);
    if(this.graphDsCliente !== null) {
      const arrayDatosGraphDsCliente = this.obtenerDatosDsCliente(this.reportesDerivadosObj);
      if(arrayDatosGraphDsCliente.length > 0){
        this.graphDsCliente = this.crearGraphDsCliente(arrayDatosGraphDsCliente);
        if(this.graphDsCliente.length > 0){
          this.generarGraficos();
        }
      }
    }
  
    this.showReportes = true;
  }

  addRangoSiiTop10(top10: any[], entidad: string): any[] {
    const resultado: any[] = top10 || []
    resultado.forEach(e => {
      e.rangoSii = this.utilsService.transformacionRangoSii(this.reportesDerivadosObj?.[entidad]?.[e.rut]?.basicReport?.reporte?.AntecedentesFinancieros?.TamanoSubsegmento || '') || '-';
    })
    return resultado;
  }

  obtenerTop10Clientes(reporte: any[]): any[] {
    const datos: any[] = JSON.parse(JSON.stringify(reporte));  
    const datosVentasDistribucion: any[] = [];

    datos.forEach(item => {
      if(item?.atributos && item?.atributos.length > 0) {
        const monto = item?.atributos.find((e: any) => e?.name === 'montoTotalVenta');
        const nombre = item?.atributos.find((e: any) => e?.name === 'nombre');
        if(monto && nombre) {
          datosVentasDistribucion.push(
            {
              rut: item?.rut, 
              nombre: nombre?.value, 
              monto: monto?.value, 
              montoM: Number(this.utilsService.divisionNumber(monto?.value, 1000).toFixed(2)) 
            }
          );
        }
      }
    });
  
    let datosVentasDistribucionTop10: any[] = JSON.parse(JSON.stringify(datosVentasDistribucion));
    datosVentasDistribucionTop10.sort((a, b) => parseFloat(b.monto) - parseFloat(a.monto));
    datosVentasDistribucionTop10 = datosVentasDistribucionTop10.slice(0, 10);
    
    return datosVentasDistribucionTop10 || [];
  }

  showDetalleFechas():void {
    const dialogRef = this.dialog.open(ListDatesComponent, {
      data: {
        title: 'Periodos Reporte Libro Compra Venta',
        fechas: this.fechasCompraVenta
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp.confirm) {
        //this.submitArchivo();
      }
    });
    /*const resp = this.simpleModalService.addModal(ListDatesComponent, {
      title: 'Periodos Reporte Libro Compra Venta',
      fechas: this.fechasCompraVenta
    }).subscribe((resp) => {
      if (resp.confirm) {
        //this.submitArchivo();
      }
    });*/
  }

  crearGraphDsCliente(arrayDatos: any[]): any[] {
    let arrayRespuesta: any[] = [];
    const total = arrayDatos.length || 0;
    const T4 = ['T4', 'T5', 'T6', 'T7', 'T8'];
    const T9 = ['T9', 'T10', 'T11', 'T12', 'T13'];
    let totalA = 0;
    let totalB = 0;
    let totalC = 0;
    let totalT4 = 0;
    let totalT9 = 0;
    let totalE = 0;
    let totalSC = 0;
    if(arrayDatos.length > 0){
      arrayDatos.forEach(element => {
        const clasificacion: string = (element?.clasificacion) ? (element?.clasificacion.toUpperCase()) : ('');
        if(clasificacion) {
          if(clasificacion.includes('A')) {
            totalA += 1;
          } else if(clasificacion.includes('B')) {
            totalB += 1;
          } else if(clasificacion.includes('C')) {
            totalC += 1;
          } else if(T4.includes(clasificacion)) {
            totalT4 += 1;
          } else if(T9.includes(clasificacion)) {
            totalT9 += 1;
          } else if(clasificacion.includes('E')) {
            totalE += 1;
          } else {
            totalSC += 1;
          }
        } else {
          totalSC += 1;
        }
      });
    }
    const data: any[] = [];

    if(Number(totalA) > 0) {  data.push(
      {
        name: 'A',
        y: (Number(total) > 0) ? ((Number(totalA) / Number(total)) * 100) : (0)
      }
    )}
    if(Number(totalB) > 0) {  data.push(
      {
        name: 'B',
        y: (Number(total) > 0) ? ((Number(totalB) / Number(total)) * 100) : (0)
      }
    )}
    if(Number(totalC) > 0) {  data.push(
      {
        name: 'C',
        y: (Number(total) > 0) ? ((Number(totalC) / Number(total)) * 100) : (0)
      }
    )}
    if(Number(totalT4) > 0) { data.push(
      {
        name: 'T4 a T8',
        y: (Number(total) > 0) ? ((Number(totalT4) / Number(total)) * 100) : (0)
      }
    )}
    if(Number(totalT9) > 0) { data.push(
      {
        name: 'T9 a T13',
        y: (Number(total) > 0) ? ((Number(totalT9) / Number(total)) * 100) : (0)
      }
    )}
    if(Number(totalE) > 0) {  data.push(
      {
        name: 'E',
        y: (Number(total) > 0) ? ((Number(totalE) / Number(total)) * 100) : (0)
      }
    )}
    if(Number(totalSC) > 0) { data.push(
      {
        name: 'Sin Clasificar',
        y: (Number(total) > 0) ? ((Number(totalSC) / Number(total)) * 100) : (0)
      }
    )}

    arrayRespuesta = [
      {
        name: 'Porcentaje',
        data: data,
        type: 'pie',
        //innerSize: '50%',
      }
    ];
      
      
    return arrayRespuesta || [];
  }

  generarGraficos(): void {
    this.graphTipoDeudores = new Chart({
      chart: {
        type: 'pie'
      },
      title: {
        text: undefined
      },
      legend: {
        floating: false,
        title: {
          //text: 'Test<br>browser usage',
          style: {
            fontWeight: 'bold'
          }
        },
        //layout: 'vertical',
        //align: 'right',
        //verticalAlign: 'top',
        //width: 80
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: 'bold',
              color: 'white'
            }
          },
          startAngle: -90,
          endAngle: -180,
          center: ['50%', '50%'],
          size: '90%',
          showInLegend: true
        }
      },
      series: this.graphDsCliente || []
    });
  }

  obtenerDatosDsCliente(reportesDerivadosObj: any): any[] {
    const arrayRespuesta: any[] = [];
    if(reportesDerivadosObj?.cyv && Object.keys(reportesDerivadosObj?.cyv).length > 0){
      for (const [rut, valor] of Object.entries(reportesDerivadosObj?.cyv)) {
        const objeto = reportesDerivadosObj?.cyv?.[rut]?.['dsCliente'];
        if(objeto && Object.keys(objeto).length > 0){
          if(objeto?.reporte?.['Clasificacion Deudor'] && Object.keys(objeto?.reporte?.['Clasificacion Deudor']).length > 0){
            arrayRespuesta.push(objeto?.reporte?.['Clasificacion Deudor']);
          }
        }
      }
    }
    return arrayRespuesta || [];
  }


  validaDsCliente(listaReportesDerivados: any): null | any[] {
    if(Object.keys(listaReportesDerivados).length > 0){
      for (const [entidad, valorEntidad] of Object.entries(listaReportesDerivados)) {
        if(Object.keys(listaReportesDerivados[entidad]).length > 0){
          for (const [rut, valor] of Object.entries(listaReportesDerivados[entidad])) {
            if(listaReportesDerivados[entidad][rut].length > 0){
              const dsCliente = listaReportesDerivados[entidad][rut].find((e: string) => e === 'dsCliente');
              if(dsCliente) {
                return [];
              }
            }
          }
        }
      }
    }
    return null;
  }

  agregarVentasCabecera(listaF29: any[]): void {
    const listaF29Ord: any[] = [...listaF29];
    
    listaF29Ord.sort((a: any,b: any) => { // mayor a menor en año y en mes
      if (!a?.calculado) return 1;
      if (!b?.calculado) return -1;
      const periodoDiff = Number(b?.calculado?.periodo) - Number(a?.calculado?.periodo);
      if (periodoDiff !== 0) return periodoDiff;
      return Number(b?.calculado?.mes) - Number(a?.calculado?.mes);
    });

    let periodo: number = 0;
    let mes: number = 0;
    
    if(listaF29Ord.length > 0 && listaF29Ord[0] && Object.keys(listaF29Ord[0]).length > 0){
      periodo = Number(listaF29Ord[0]?.calculado?.periodo) || 0;
      mes = Number(listaF29Ord[0]?.calculado?.mes) || 0;
    }

    let contador: number = 0;
    let sumaVentas: number = 0;
    let sumaCompras: number = 0;

    for (let i = 0; i < 12; i++) {
      if(contador < 12) {
        let periodoEntrada = periodo;
        let mesEntrada = mes - i;
        
        if (mesEntrada <= 0) {
          mesEntrada += 12;
          periodoEntrada--;
        }

        const elemento = listaF29Ord.find((obj: any) => obj?.calculado?.periodo == periodoEntrada && obj?.calculado?.mes == mesEntrada);
        if(elemento){
          sumaVentas = sumaVentas + (Number(elemento?.calculado?.ventasNetasMilesPesos) || 0);
          sumaCompras = sumaCompras + (Number(elemento?.calculado?.comprasNetasMilesPesos) || 0);
        }
        
      }

    }

    let promVentas: number = 0;
    if(sumaVentas != 0){
      promVentas = sumaVentas / 12;
    }

    let comprasVentas: number = 0;
    if(sumaVentas != 0){
      comprasVentas = (Number(sumaCompras) / Number(sumaVentas)) * 100;
    }

    this.datosCabecera.ventasPromedios12m = promVentas;
    this.datosCabecera.comprasSobreVentas12m = comprasVentas;
  }

  agregarFinancierasInformes(mesesDeuda: any[]): void {
    mesesDeuda.sort(function (a, b) {
      // Obtener el valor de periodo para a y b
      const periodoA = a?.periodo;
      const periodoB = b?.periodo;
  
      // Si ambos son null/undefined, son iguales
      if (!periodoA && !periodoB) return 0;
      
      // Si periodoA es null/undefined, a debería ir después de b
      if (!periodoA) return 1;
      
      // Si periodoB es null/undefined, b debería ir después de a
      if (!periodoB) return -1;
  
      // Convertir a formato uniforme para comparar
      const aa = periodoA.split('-').map((num: any) => num.padStart(2, '0')).reverse().join('');
      const bb = periodoB.split('-').map((num: any) => num.padStart(2, '0')).reverse().join('');
  
      return aa < bb ? 1 : aa > bb ? -1 : 0;
    });
    const deudaReciente: any = mesesDeuda[0];

    let morosidad: any = 0;
    let deudaTotal: any = 0;
    if(deudaReciente){
      morosidad = Number(deudaReciente?.dirvencida) + Number(deudaReciente?.dirven180mayor3year) + Number(deudaReciente?.dirmorosa);
      deudaTotal = Number(deudaReciente?.dirvigente);
    }
    else{
      morosidad = 0;
      deudaTotal = 0;
    }
    this.informesComerciales.financieraVigente = (Number(deudaTotal));
    this.informesComerciales.financieraDeteriorada = (Number(morosidad));
  }

  obtenerMesAbreviado(numeroMes: number, anio: number): string {
    const meses = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];
    const anioAbreviado = anio.toString().slice(-2);
    return meses[numeroMes - 1] + '-' + anioAbreviado;
  }

  crearTablaIvaSobreVentas(anioActual: number, anioAnterior: number, listaF29: any[]): any {
    let respuesta: any = {};
    const listaF29Ord: any[] = [...listaF29];

    listaF29Ord.sort((a: any,b: any) => { // mayor a menor en año y en mes
      if (!a?.calculado) return 1;
      if (!b?.calculado) return -1;
      const periodoDiff = Number(b?.calculado?.periodo) - Number(a?.calculado?.periodo);
      if (periodoDiff !== 0) return periodoDiff;
      return Number(b?.calculado?.mes) - Number(a?.calculado?.mes);
    });

    if(anioActual || anioAnterior){
      const ultimoElemento = listaF29Ord[0];

      respuesta = {
        afectasMeses12: 0,
        meses12: 0,
        afectasMeses6: 0,
        meses6: 0,
        listaMeses: [],
        ivaMeses12: 0,
        ivaMeses6: 0,

        sumaNotas12: 0,
        sumaNotas6: 0,
        sumaVentas12: 0,
        sumaVentas6: 0,
        
        notasMeses12: 0,
        notasMeses6: 0
      }
      
      let contador: number = 0;
      if(ultimoElemento){
        for (let i = 0; i < 12; i++) {
          if(contador < 12) {
            let periodo = ultimoElemento?.calculado?.periodo || 0;
            let mes = ultimoElemento?.calculado?.mes - i || 0;
            
            if (mes <= 0) {
              mes += 12;
              periodo--;
            }
  
            const objeto: any = {
              mes: this.obtenerMesAbreviado(mes, periodo),
              valueIva: 0,
              valueNotas: 0
            };
  
            const elemento = listaF29Ord.find((obj: any) => obj?.calculado?.periodo == periodo && obj?.calculado?.mes == mes);
            if(elemento){
              const elementoF29 = elemento;
  
              let valueCodigos: number = 0;
              let codigo89: number = 0;
              let codigo755: number = 0;
              if(elementoF29?.codigos?.['89']) {
                codigo89 = Number(elementoF29?.codigos?.['89']);
              } 
              if (elementoF29?.codigos?.['755']) {
                codigo755 = Number(elementoF29?.codigos?.['755']);
              }
  
              valueCodigos = ((Number(codigo89) + Number(codigo755)) / 1000);
  
              const ventasNetasAfectas = Number(elementoF29?.calculado?.ventasNetasMilesPesos);
             
              const valorNotas = elementoF29?.calculado?.notasCredito || 0;
              const valorVentas = elementoF29?.calculado?.ventasNetas || 0;
  
              if(contador < 6){
                objeto.mes = this.obtenerMesAbreviado(mes, periodo);
                objeto.valueIva = (Number(valueCodigos) / Number(ventasNetasAfectas)) * 100;
                objeto.valueNotas = elementoF29?.calculado?.porc_notascred_ventas || 0;
  
                respuesta.afectasMeses6 = Number(respuesta.afectasMeses6) + Number(ventasNetasAfectas);
                respuesta.meses6 = Number(respuesta.meses6) + Number(valueCodigos);
                respuesta.sumaNotas6 = Number(respuesta.sumaNotas6) + Number(valorNotas);
                respuesta.sumaVentas6 = Number(respuesta.sumaVentas6) + Number(valorVentas);
              }
              respuesta.afectasMeses12 = Number(respuesta.afectasMeses12) + Number(ventasNetasAfectas);
              respuesta.meses12 = Number(respuesta.meses12) + Number(valueCodigos);
              respuesta.sumaNotas12 = Number(respuesta.sumaNotas12) + Number(valorNotas);
              respuesta.sumaVentas12 = Number(respuesta.sumaVentas12) + Number(valorVentas);
            }
  
            if(contador < 6){
              respuesta.listaMeses.push(objeto);
              contador++;
            }
          }
        }
  
        respuesta.ivaMeses12 = (Number(respuesta.meses12) / Number(respuesta.afectasMeses12)) * 100;
        respuesta.ivaMeses6 = (Number(respuesta.meses6) / Number(respuesta.afectasMeses6)) * 100;
        respuesta.notasMeses12 = (Number(respuesta.sumaNotas12) / Number(respuesta.sumaVentas12) * 100); // validar * 100
        respuesta.notasMeses6 = (Number(respuesta.sumaNotas6) / Number(respuesta.sumaVentas6) * 100); // validar * 100
      }
    }

    return respuesta;
  }

  crearTablaSocios(entidadSocios: any[], antecedentesSocietarios: any[], entidad: string): any[] {
    const tablaSocios: any[] = [];

    if(Object.keys(entidadSocios).length > 0) {
      entidadSocios.forEach(element => {
        const objeto: any = {
          rut: element?.rut || '',
          fechaVinculacion: '',
          nombre: '',
          entidad: entidad || ''
        };

        const indexAntecedentes = antecedentesSocietarios.findIndex(e => e.rut === element?.rut);
        if(indexAntecedentes >= 0){
          objeto.fechaVinculacion = antecedentesSocietarios[indexAntecedentes]?.fechaVinculacion || '';
        }

        if(element?.atributos && element?.atributos.length > 0) {
          const indexAtributo = element?.atributos.findIndex((e: any) => e.name === 'nombre');
          if(indexAtributo >= 0){
            objeto.nombre = element?.atributos[indexAtributo]?.value || '';
          }
        }

        tablaSocios.push(objeto);
      })
    }

    return tablaSocios;
  }

  crearTablaVentaCruzada(compraVenta: any[]): any {
    const ventaCruzada: any = [];
    const datos: any[] = JSON.parse(JSON.stringify(compraVenta));  

    datos.forEach(item => {
      if(item?.atributos && item?.atributos.length > 0) {
        const montoVenta = item?.atributos.find((e: any) => e?.name === 'montoTotalVenta');
        const montoCompra = item?.atributos.find((e: any) => e?.name === 'montoTotalCompra');
        const nombre = item?.atributos.find((e: any) => e?.name === 'nombre');

        if(montoVenta && montoCompra && nombre) {
          const ventas = Number(montoVenta?.value);
          const compras = Number(montoCompra?.value);

          if(ventas > 0 && compras > 0) {
            ventaCruzada.push({
              ventas: (ventas / 1000),
              compras: (compras / 1000),
              rut: item?.rut,
              nombre: nombre?.value
            });
          }

        }
      }
    });

    const compraVentaMontosFiltrado = ventaCruzada.filter((e: any) => (e.compras > 5000 || e.ventas > 5000));
    compraVentaMontosFiltrado.sort((a: any, b: any) => {
      if (a.ventas < b.ventas) return 1;
      if (a.ventas > b.ventas) return -1;
      return 0;
    });

    return compraVentaMontosFiltrado;
  }

  crearTablaBalance(anioActual: number, anioAnterior: number, listaF22: any[]): any {
    const tablaBalance: any = {};

    listaF22.forEach(e => {
      if(anioAnterior){
        if(e?.calculado?.periodo == anioAnterior){
          tablaBalance.anioAnterior = {
            anio: anioAnterior,
            ventas: Number(e?.calculado?.ingresosdelaExplotacion) !== 0 ? (Number(e?.calculado?.ingresosdelaExplotacion) / 1000) : (e?.calculado?.ingresosdelaExplotacion),
            utilidad: Number(e?.calculado?.utilidadneta) !== 0 ? (Number(e?.calculado?.utilidadneta) / 1000) : (e?.calculado?.utilidadneta),
            patrimonio: Number(e?.calculado?.totalpatrimonio) !== 0 ? (Number(e?.calculado?.totalpatrimonio) / 1000) : (e?.calculado?.totalpatrimonio),
            actFijo: Number(e?.calculado?.totalactivosfijos) !== 0 ? (Number(e?.calculado?.totalactivosfijos) / 1000) : (e?.calculado?.totalactivosfijos),
          }
        }
      }
      if(anioActual){
        if(e?.calculado?.periodo == anioActual){
          tablaBalance.anioActual = {
            anio: anioActual,
            ventas: Number(e?.calculado?.ingresosdelaExplotacion) !== 0 ? (Number(e?.calculado?.ingresosdelaExplotacion) / 1000) : (e?.calculado?.ingresosdelaExplotacion),
            utilidad: Number(e?.calculado?.utilidadneta) !== 0 ? (Number(e?.calculado?.utilidadneta) / 1000) : (e?.calculado?.utilidadneta),
            patrimonio: Number(e?.calculado?.totalpatrimonio) !== 0 ? (Number(e?.calculado?.totalpatrimonio) / 1000) : (e?.calculado?.totalpatrimonio),
            actFijo: Number(e?.calculado?.totalactivosfijos) !== 0 ? (Number(e?.calculado?.totalactivosfijos) / 1000) : (e?.calculado?.totalactivosfijos),
          }
        }
      }
    });

    return tablaBalance;
  }

  crearTablaVentasCompras(anioActual: number, anioAnterior: number, listaF29: any[]): any {
    const totalesInformacionFinanciera: any = {};
    let informacionFinancieraTable: any = {};

    if(anioActual || anioAnterior){
      const objTemp: any[] = [];
      let objTempActual: any[] = [];
      let objTempAnterior: any[] = [];

      if (anioAnterior || anioActual) {
        listaF29
          .map((item: any) => {
            if (anioAnterior && (String(anioAnterior) === (String(item?.calculado?.periodo)))) {
              objTempAnterior.push({
                mes: this.utilsService.obtenerMes(Number(item?.calculado?.mes)),
                date: item?.calculado?.periodo + '-' + ((item?.calculado?.mes) < 10 ? '0' + (item?.calculado?.mes) : (item?.calculado?.mes)) + '-' + '02',
                ventas: (item.calculado?.ventasNetasMilesPesos || item.calculado?.ventasNetasMilesPesos == 0) ? (item.calculado?.ventasNetasMilesPesos) : '',
                compras: (item.calculado?.comprasNetasMilesPesos || item.calculado?.comprasNetasMilesPesos == 0) ? (item.calculado?.comprasNetasMilesPesos) : '',
                diferencia: (item.calculado?.margenMiles || item.calculado?.margenMiles == 0) ? (item.calculado?.margenMiles) : '',
                fechaPago: item.calculado?.fechaPago || '',
                tipoDeclaracion: item.calculado?.tipoDeclaracion || '',
                posterga: (item.calculado?.postergacionIVA || item.calculado?.postergacionIVA == 0) ? (Number(item.calculado?.postergacionIVA) / 1000) : '',
                autoGenerado: false,
              });
            } else if (anioActual && (String(anioActual) === (String(item?.calculado?.periodo)))) {
              objTempActual.push({
                mes: this.utilsService.obtenerMes(Number(item?.calculado?.mes)),
                date: item?.calculado?.periodo + '-' + ((item?.calculado?.mes) < 10 ? '0' + (item?.calculado?.mes) : (item?.calculado?.mes)) + '-' + '02',
                ventas: (item.calculado?.ventasNetasMilesPesos || item.calculado?.ventasNetasMilesPesos == 0) ? (item.calculado?.ventasNetasMilesPesos) : '',
                compras: (item.calculado?.comprasNetasMilesPesos || item.calculado?.comprasNetasMilesPesos == 0) ? (item.calculado?.comprasNetasMilesPesos) : '',
                diferencia: (item.calculado?.margenMiles || item.calculado?.margenMiles == 0) ? (item.calculado?.margenMiles) : '',
                fechaPago: item.calculado?.fechaPago || '',
                tipoDeclaracion: item.calculado?.tipoDeclaracion || '',
                posterga: (item.calculado?.postergacionIVA || item.calculado?.postergacionIVA == 0) ? (Number(item.calculado?.postergacionIVA) / 1000) : '',
                autoGenerado: false,
              });
            }
          });
        
          if(anioAnterior){
            for (let i = 1; i <= 12; i++) {
              if (objTempAnterior.find((e: any) => e.date === anioAnterior + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02') === undefined) {
                objTempAnterior.push({
                  mes: this.utilsService.obtenerMes(Number(i)),
                  date: anioAnterior + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02',
                  ventas: '',
                  compras: '',
                  diferencia: '',
                  fechaPago: '',
                  tipoDeclaracion: '',
                  posterga: '',
                  autoGenerado: true,
                });
              }
            }
          }

          if(anioActual){
            for (let i = 1; i <= 12; i++) {
              if (objTempActual.find((e: any) => e.date === anioActual + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02') === undefined) {
                objTempActual.push({
                  mes: this.utilsService.obtenerMes(Number(i)),
                  date: anioActual + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02',
                  ventas: '',
                  compras: '',
                  diferencia: '',
                  fechaPago: '',
                  tipoDeclaracion: '',
                  posterga: '',
                  autoGenerado: true,
                });
              }
            }
          }

          const totalesAnterior: TotalesCategorias = {
            anio: anioAnterior,
            TotalAnio: {
                ventas: 0,
                compras: 0,
                posterga: 0,
                meses: 0,
            },
              TotalProm: {
                ventas: 0,
                compras: 0,
                posterga: 0,
                meses: 0,
            },
          };
          const totalesActual: TotalesCategorias = {
            anio: anioActual,
            TotalAnio: {
              ventas: 0,
              compras: 0,
              posterga: 0,
              meses: 0,
            },
            TotalProm: {
              ventas: 0,
              compras: 0,
              posterga: 0,
              meses: 0,
            },
          };

          objTempAnterior = objTempAnterior.sort((a, b) => (a.date > b.date ? 1 : -1));
          objTempActual = objTempActual.sort((a, b) => (a.date > b.date ? 1 : -1));

          objTempAnterior.forEach((element: any, index: number) => {
            totalesAnterior.TotalAnio.ventas += (Number(element?.ventas) || 0)
            totalesAnterior.TotalAnio.compras += (Number(element?.compras) || 0)
            totalesAnterior.TotalAnio.posterga += (Number(element?.posterga) || 0)
            if(totalesAnterior.TotalAnio.meses != undefined && element.autoGenerado == false)
              totalesAnterior.TotalAnio.meses += 1
          });

          objTempActual.forEach((element: any, index: number) => {
            totalesActual.TotalAnio.ventas += (Number(element?.ventas) || 0)
            totalesActual.TotalAnio.compras += (Number(element?.compras) || 0)
            totalesActual.TotalAnio.posterga += (Number(element?.posterga) || 0)
            if(totalesActual.TotalAnio.meses != undefined && element.autoGenerado == false)
              totalesActual.TotalAnio.meses += 1
          });

          if(totalesAnterior.TotalAnio.meses){
            totalesAnterior.TotalProm.ventas = Math.round((totalesAnterior.TotalAnio.ventas/totalesAnterior.TotalAnio.meses))
            totalesAnterior.TotalProm.compras = Math.round((totalesAnterior.TotalAnio.compras/totalesAnterior.TotalAnio.meses))
            totalesAnterior.TotalProm.posterga = Math.round((totalesAnterior.TotalAnio.posterga/totalesAnterior.TotalAnio.meses))
          }

          if(totalesActual.TotalAnio.meses){
            totalesActual.TotalProm.ventas = Math.round((totalesActual.TotalAnio.ventas/totalesActual.TotalAnio.meses))
            totalesActual.TotalProm.compras = Math.round((totalesActual.TotalAnio.compras/totalesActual.TotalAnio.meses))
            totalesActual.TotalProm.posterga = Math.round((totalesActual.TotalAnio.posterga/totalesActual.TotalAnio.meses))
          }

        totalesInformacionFinanciera.totalAnterior = totalesAnterior;
        totalesInformacionFinanciera.totalActual = totalesActual;

        for (let i = 0; i <= 11; i++) {
          objTemp.push({
            anterior: objTempAnterior[i] ? objTempAnterior[i] : null,
            actual: objTempActual[i] ? objTempActual[i]: null,
          })
        }

        informacionFinancieraTable = objTemp;
      }
    }
    
    return {
      totalesInformacionFinanciera: totalesInformacionFinanciera,
      informacionFinancieraTable: informacionFinancieraTable
    }
  }

  validarValor(valor: any): boolean {
    if(valor || valor == 0){
      return true;
    }
    return false;
  }

  mapeoSbif(): void {
    if(this.reportesSeleccionado?.deudaSbif?.reporte){
      /*if(this.reportesSeleccionado?.deudaSbif?.reporte?.DeudaSistemaFinancieroReporte?.Documentos?.SbifOnline?.Reporte?.rowsMeses?.item){

      } else */
      if (this.reportesSeleccionado?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item) {
        this.reportesSeleccionado.deudaSbif.reporte = {
          Documentos: {
            SbifOnline: {
              Reporte: {
                rowsMeses: this.reportesSeleccionado?.deudaSbif?.reporte?.Sbif?.rowsMeses
              }
            }
          }
        }
      }
    }

  }

  mapeoPoderJudicial(): void{
    if(this.reportesSeleccionado?.poderJudicial?.reporte){
      const datos = this.reportesSeleccionado?.poderJudicial?.reporte["Totalizadores Poder Judicial"];
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: {
            Totales: datos
          }
        }
      };
      this.reportesSeleccionado.poderJudicial.reporteMapeado = reporte;
    }
    if(this.reportesSeleccionado?.poderJudicial?.reporte?.Reporte){
      const datos = this.reportesSeleccionado?.poderJudicial?.reporte?.Reporte;
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: datos
        }
      };

      this.reportesSeleccionado.poderJudicial.ReporteFull = reporte;
    }
  }

  mapeoReporteTitular(): void {
    this.reportesSeleccionado = {
      deudaSbif: this.reportesPrincipalesObj?.deudaSbif,
      poderJudicial: this.reportesPrincipalesObj?.poderJudicial,
      boletinConcursal: this.reportesPrincipalesObj?.boletinConcursal,
      objReporteVehiculos: this.reportesPrincipalesObj?.vehiculos?.reporte,
      objReporteBienesRaices: this.reportesPrincipalesObj?.bienesRaices?.reporte
    } 

  }

  validaObjetos(): void{
    if(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte && Object.keys(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte).length === 0){
      this.reportesSeleccionado.cteVentasBalancesRentas.reporte = null;
    }
    if(this.reportesSeleccionado?.basicReport?.reporte && Object.keys(this.reportesSeleccionado?.basicReport?.reporte).length === 0){
      this.reportesSeleccionado.basicReport.reporte = null;
    }
    if(this.reportesSeleccionado?.deudaSbif?.reporte && Object.keys(this.reportesSeleccionado?.deudaSbif?.reporte).length === 0){
      this.reportesSeleccionado.deudaSbif.reporte = null;
    }
    if(this.reportesSeleccionado?.boletinConcursal?.reporte && Object.keys(this.reportesSeleccionado?.boletinConcursal?.reporte).length === 0){
      this.reportesSeleccionado.boletinConcursal.reporte = null;
    }
    if(this.reportesSeleccionado?.reporteEmpresaDia?.reporte && Object.keys(this.reportesSeleccionado?.reporteEmpresaDia?.reporte).length === 0){
      this.reportesSeleccionado.reporteEmpresaDia.reporte = null;
    }
  }

  mapeoVehiculos(): void{
    if(this.reportesSeleccionado?.vehiculos?.reporte){
      this.reportesSeleccionado.objReporteVehiculos = this.reportesSeleccionado?.vehiculos?.reporte;
    }
  }

  mapeoBienesRaices(): void{
    if(this.reportesSeleccionado?.bienesRaices?.reporte){
      this.reportesSeleccionado.objReporteBienesRaices = this.reportesSeleccionado?.bienesRaices?.reporte;
    }
  }

  obtenerSocios(): any[] {
    if(this.reportesPrincipalesObj?.cte?.reporte?.fechaReporte && this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.fechaReporte) {
      if(this.reportesPrincipalesObj?.cte?.reporte?.fechaReporte >= this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.fechaReporte) {
        return this.reportesPrincipalesObj?.cte?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.socios || [];
      } else {
        return this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.socios || [];
      }
    } else {
      if(this.reportesPrincipalesObj?.cte?.reporte?.fechaReporte) {
        return this.reportesPrincipalesObj?.cte?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.socios || [];
      } else if (this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.fechaReporte) {
        return this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.socios || [];
      }
    }
    return [];
  }

  mapeoBalance(): void {
    const datosF22: any[] = this.obtenerDatosF22();
    const datosF29: any[] = this.obtenerDatosF29();
    if(datosF22.length > 0 || datosF29.length > 0){
      const respuesta: any = {CarpetaTributariaElectronicaReporte: {
        DatosBasicosSolicitud: {Rut: this.rut.slice(0, -1), DV: this.rut.slice(-1)},
        DatosSolicitante: {},
        Reporte: {
          f22: datosF22 || [],
          f29: datosF29 || [],
          socios: this.obtenerSocios || [],
          representantes: []
        }
      }};

      this.reportesSeleccionado.cteVentasBalancesRentas = {reporte: respuesta};
    }
  }

  abrirModalTitular(): void{
    if(this.reportesPrincipalesObj){
      this.mapeoReporteTitular();
      this.mapeoBalance();
      this.mapeoPoderJudicial();
      this.mapeoSbif();
      this.validaObjetos();
    }

    this.reportesSeleccionado.consolidadoTitular = this.consolidadoTitular;
  
    this.rutSeleccionado = this.rut;
    this.plantillaAntecedentes = 'cesceDemo';
    this.modalDefault = false;

    this.modalService.open();
  }

  // Funcion para abrir modal con datos basicos en tabla de socios

  abrirModal(entidad: string, rut: string): void{
    if(rut && entidad){
      if(this.reportesDerivadosObj[entidad]?.[rut]){
        this.reportesSeleccionado = this.reportesDerivadosObj[entidad][rut];
        this.rutSeleccionado = rut;
        this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
        this.mapeoPoderJudicial();
        this.mapeoVehiculos();
        this.mapeoBienesRaices();
        this.mapeoSbif();
        this.validaObjetos();
        this.plantillaAntecedentes = '';
        this.modalDefault = true;
  
        this.modalService.open();
      }
    }
    
  }

  abrirModalCompraVenta(): void {
    const url = this.router.createUrlTree([`compra-venta/buscar`], { queryParams: {rut: this.rut} }).toString();
    window.open(url, '_blank');
  }

}
