<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>
<div *ngIf="hasUserAccess">

    <ng-container *ngIf="showList">
        <div class="card w-100 mx-auto border-0 rounded">
            <div class="card-body p-5">
                <div class="row">
                    <div class="col-sm-12 my-2">
                        <button (click)="goToCreate()" class="btn btn-success float-right formulario">Crear</button>
                    </div>
                    <div class="col-sm-12">
                        <div class="card text-center my-4">
                            <div class="card-header">
                                <div class="my-0 row d-flex px-4">
                                    <div class="col-xs-3 col-sm-auto mr-auto p-2">
                                        <app-paginator-by-items title="Cantidad de filas a mostrar en la tabla" [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                                    </div>
                                    <div class="col-xs-3 col-sm-auto p-2">
                                        <div class="input-group mb-3">
                                            <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                                title="Ingrese texto para filtrar tabla"
                                                placeholder="Filtrar tabla"
                                                [(ngModel)]="inputText" 
                                                (keyup)="filtrarObjeto()"
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table id="tableUsuarios" class="table table-hover border my-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="text-center font-size cursor-pointer" (click)="cambioDeCampo('nombre')">
                                                Nombre Grupo
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                            <th scope="col" class="text-center font-size cursor-pointer min-width-th" (click)="cambioDeCampo('usuariosCant')">
                                                Usuarios
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                            <th scope="col" class="text-center font-size">Editar</th>
                                            <th scope="col" class="text-center font-size">Borrar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of listadoGruposFiltrado | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                                            <td class="font-size-body align-middle">{{item.nombre}}</td>
                                            <td class="font-size-body align-middle">
                                                <span class="badge badge-success cursor-pointer p-2 font-size-body" (click)="showUsuarios(item.usuarios)">{{item.usuariosCant}}</span>
                                            </td>
                                            <td class="font-size-body align-middle">
                                                <button type="button" class="btn btn-outline-success" (click)="editarGrupo(item)">
                                                    Asignar Usuarios
                                                </button>
                                            </td>
                                            <td class="font-size-body align-middle">
                                                <button type="button" class="btn btn-w-m btn-outline-danger fa fa-trash fa-1-4" (click)="borrarGrupo(item.nombre)"></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer text-muted">
                                <div class="p-2">
                                    <div id="pagination">
                                        <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                                            <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showCreate">
        <div class="card w-100 mx-auto border-0 rounded">
            <div class="card-body p-5">
                <div class="row">
                    <div class="col-sm-12 my-2">
                        <button (click)="goList()" class="btn btn-primary formulario">Volver</button>
                    </div>
                    <div class="col-sm-12">
                        <div class="card text-center my-4">
                            <div class="card-header">
                                <h3>Creación de Grupo</h3>
                            </div>
                            <div class="row p-5">
                                <div class="col-sm-12">
                                    <form [formGroup]="groupForm" (ngSubmit)="submit()">

                                        <div class="form-group">
                                            <label class="col-md-3 control-label">Nombre:</label>
                                            <div class="col-md-9">
                                                <input formControlName="groupName" type="text" class="form-control">
                                            </div>
                                            <app-error [control]="groupForm.get('groupName')"></app-error>
                                        </div>

                                        <button type="submit" class="btn btn-primary mt-5">
                                            Guardar
                                        </button>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="showEdit">
        <div class="card w-100 mx-auto border-0 rounded">
            <div class="card-body p-5">
                <div class="row">
                    <div class="col-sm-12 my-2">
                        <button (click)="goList()" class="btn btn-primary formulario">Volver</button>
                    </div>

                    <div class="col-12 text-center">
                        <h3>Asignación de usuarios <strong>{{grupoSeleccionado?.nombre || ''}}</strong></h3>
                    </div>

                    <div class="col-12">
                        <app-grupos-asignacion-page [grupo]="grupoSeleccionado"></app-grupos-asignacion-page>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    
</div>
