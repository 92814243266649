<ng-container *ngIf="hasUserAccess; else noAccess">
    <div class="container-gtp no-print" *ngIf="showReport">
        <div class="panel panel-default">
            <div class="panel-body">
                <button [printTitle]='"CesionFacturas_"+(rut | rutFormat)+"_"+today'
                    [useExistingCss]="true" printSectionId="print-section" ngxPrint
                    class="btn btn-primary float-right">Descargar PDF</button>
                <div class="btn-option"></div>
            </div>
        </div>
    </div>
    <div class="row" id="print-section">
        <div class="col-12">
            <div class="row">
                <div class="col-sm-12 col-md-11 col-lg-10 mx-auto mb-5">
                    <img class="width-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt="" />
                    <h2 class="text-center pt-3">Cesión de Facturas</h2>
                    <h5 class="text-center">
                        <span class="badge bg-secondary text-center">
                            <span *ngIf="rut">
                                {{rut | rutFormat | uppercase}}
                            </span>
                        </span>
                    </h5>
                
                    <div class="row px-5">
                        <span class="font-size-filter">Filtros</span>

                        <div class="col-sm-12 bg-white py-3 d-none font-size-filter">
                            <div class="row">
                                <div class="col-6" *ngIf="fechaInicio">
                                    <div class="form-group">
                                        <label class="font-size-filter" for="fechaInicio">Fecha documento desde: </label>
                                        <span class="font-size-filter">{{fechaInicio | date: 'dd-MM-yyyy'}}</span>
                                    </div>
                                </div>
                                <div class="col-6" *ngIf="fechaFin">
                                    <div class="form-group">
                                        <label class="font-size-filter" for="fechaFin">Fecha documento hasta: </label>
                                        <span class="font-size-filter">{{fechaFin | date: 'dd-MM-yyyy'}}</span>
                                    </div>
                                </div>
                                <div class="col-6" *ngIf="rutCedente">
                                    <div class="form-group">
                                        <label class="font-size-filter" for="rutCedente">Rut Cedente: </label>
                                        <span class="font-size-filter">{{rutCedente | rutFormat}}</span>
                                    </div>
                                </div>
                                <div class="col-6" *ngIf="rutDeudor">
                                    <div class="form-group">
                                        <label class="font-size-filter" for="rutDeudor">Rut Deudor: </label>
                                        <span class="font-size-filter">{{rutDeudor | rutFormat}}</span>
                                    </div>
                                </div>
                                <div class="col-6" *ngIf="rutVendedor">
                                    <div class="form-group">
                                        <label class="font-size-filter" for="rutVendedor">Rut Vendedor: </label>
                                        <span class="font-size-filter">{{rutVendedor | rutFormat}}</span>
                                    </div>
                                </div>
                                <div class="col-6" *ngIf="estadoCesion">
                                    <div class="form-group">
                                        <label class="font-size-filter" for="estadoCesion">Estado Cesión: </label>
                                        <span class="font-size-filter">{{getEstadoCesion(estadoCesion) || ''}}</span>
                                    </div>
                                </div>
                                <div class="col-6" *ngIf="estadoDocumento">
                                    <div class="form-group">
                                        <label class="font-size-filter" for="estadoDocumento">Estado Documento: </label>
                                        <span class="font-size-filter">{{getEstadoDocumento(estadoDocumento) || ''}}</span>
                                    </div>
                                </div>
                                <div class="col-6" *ngIf="estadoDocumentoNc">
                                    <div class="form-group">
                                        <label class="font-size-filter" for="estadoDocumentoNc">Contiene Nota Credito: </label>
                                        <span class="font-size-filter">{{getEstadoNotaCredito(estadoDocumentoNc) || ''}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 shadow-sm bg-white py-3 rounded-50 no-print">
                            <div class="row">
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <label for="fechaInicio">Fecha documento desde</label>
                                        <input type="date" class="form-control" id="fechaInicio" [(ngModel)]="fechaInicio">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <label for="fechaFin">Fecha documento hasta</label>
                                        <input type="date" class="form-control" id="fechaFin" [(ngModel)]="fechaFin">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <label for="rutCedente">Rut Cedente</label>
                                        <input type="string" class="form-control" id="rutCedente" formatRut [(ngModel)]="rutCedente">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <label for="rutDeudor">Rut Deudor</label>
                                        <input type="string" class="form-control" id="rutDeudor" formatRut [(ngModel)]="rutDeudor">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <label for="rutVendedor">Rut Vendedor</label>
                                        <input type="string" class="form-control" id="rutVendedor" formatRut [(ngModel)]="rutVendedor">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <label for="estadoCesion">Estado Cesión</label>
                                        <ng-container *ngIf="!showSpinnerEstados">
                                            <select id="estadoCesion" class="form-control" [(ngModel)]="estadoCesion">
                                                <option *ngFor="let element of estadoCesionOptions" [ngValue]="element.name">
                                                    {{ element.displayName }}
                                                </option>
                                            </select>
                                        </ng-container>
                                        <ng-container *ngIf="showSpinnerEstados">
                                            <div class="col-12">
                                                <div class="spinner-border text-primary mt-2 mx-auto no-print" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <label for="estadoDocumento">Estado Documento</label>
                                        <ng-container *ngIf="!showSpinnerEstados">
                                            <select id="estadoDocumento" class="form-control" [(ngModel)]="estadoDocumento">
                                                <option *ngFor="let element of estadoDocumentoOptions" [ngValue]="element.name">
                                                    {{ element.displayName }}
                                                </option>
                                            </select>
                                        </ng-container>
                                        <ng-container *ngIf="showSpinnerEstados">
                                            <div class="col-12">
                                                <div class="spinner-border text-primary mt-2 mx-auto no-print" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <div class="form-group">
                                        <label for="estadoDocumentoNc">Contiene Nota Credito</label>
                                        <ng-container *ngIf="!showSpinnerEstados">
                                            <select id="estadoDocumentoNc" class="form-control" [(ngModel)]="estadoDocumentoNc">
                                                <option *ngFor="let element of estadoNotaCreditoOptions" [ngValue]="element.name">
                                                    {{ element.displayName }}
                                                </option>
                                            </select>
                                        </ng-container>
                                        <ng-container *ngIf="showSpinnerEstados">
                                            <div class="col-12">
                                                <div class="spinner-border text-primary mt-2 mx-auto no-print" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
        
                                <div class="col-sm-12 my-auto no-print" *ngIf="showReport">
                                    <button type="button" class="btn btn-primary float-right" (click)="buscar()">Buscar</button>
                                    <button type="button" class="btn btn-warning text-white float-right mr-3" (click)="limpiarFiltros()">Limpiar</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-4 mb-3 d-flex flex-row-reverse no-print" *ngIf="showReport">
                            <button type="button" class="btn btn-success ml-3" (click)="configurarCreds()" *ngIf="credsNoConfiguradas">Configurar Credenciales</button>
                            <button type="button" class="btn btn-light" (click)="actualizar()"><i class="fa fa-refresh text-primary" aria-hidden="true"></i></button>
                            <span class="text-primary mr-3 d-flex align-items-center cursor-pointer" (click)="actualizar()"><u>Actualizar</u></span>
                        </div>
        
                        <div class="col-sm-12" *ngIf="cantidadRegistros">
                            <span class="font-size" *ngIf="cantidadRegistros">
                                {{registrosFiltrados | number : '1.0-0'}} de {{cantidadRegistros | number : '1.0-0'}} resultados
                            </span>
                        </div>

                        <div class="col-sm-12 shadow-sm bg-white p-0 rounded-50">
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="text-center font-size bg-table">
                                                Rut Vendedor
                                            </th>
                                            <th scope="col" class="text-center font-size bg-table">
                                                Folio
                                            </th>
                                            <th scope="col" class="text-center font-size bg-table">
                                                Fecha Documento
                                            </th>
                                            <th scope="col" class="text-center font-size bg-table">
                                                Acuse
                                            </th>
                                            <th scope="col" class="text-center font-size bg-table">
                                                Estado
                                            </th>
                                            <th scope="col" class="text-center font-size bg-table">
                                                Nota de Crédito
                                            </th>
                                            <th scope="col" class="text-center font-size bg-table">
                                                Fecha Cesión
                                            </th>
                                            <th scope="col" class="text-center font-size bg-table">
                                                Monto Total
                                            </th>
                                            <th scope="col" class="text-center font-size bg-table">
                                                Rut Deudor
                                            </th>
                                            <th scope="col" class="text-center font-size bg-table">
                                                Rut Cedente
                                            </th>
                                            <th scope="col" class="text-center font-size bg-table">
                                                Nombre Cedente
                                            </th>
                                            <th scope="col" class="text-center font-size bg-table"></th>
                                            <th scope="col" class="text-center font-size bg-table">
                                                Estado Pago
                                            </th>
                                            <th scope="col" class="text-center font-size bg-table no-print" *ngIf="validarEstadoActualizado()"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="paginaActual.length > 0; else noPage">
                                            <tr *ngFor="let report of paginaActual | paginate:{itemsPerPage: currentItem, currentPage: currentPage, totalItems: cantidadRegistros}; let i = index">
                                                <td class="text-center text-nowrap">{{report?.VENDEDOR ? (report?.VENDEDOR | rutFormat) : ''}}</td>
                                                <td class="text-center">{{report?.FOLIO_DOC || ''}}</td>
                                                <td class="text-center text-nowrap">{{report?.FCH_EMIS_DTE ? (report?.FCH_EMIS_DTE | date: 'dd-MM-yyyy') : ''}}</td>
                                                <td class="text-center">{{report?.tieneAcuse == true ? 'Si' : 'No'}}</td>
                                                <td class="text-center">{{getEstadoDocumento(report?.estado) || ''}}</td>
                                                <td class="text-center">{{getEstadoNotaCredito(report?.estadoNotaCredito) || ''}}</td>
                                                <td class="text-center text-nowrap">{{report?.FCH_CESION ? (report?.FCH_CESION | date: 'dd-MM-yyyy') : ''}}</td>
                                                <td class="text-center text-nowrap">{{report?.MNT_TOTAL || report?.MNT_TOTAL == 0 ? (report?.MNT_TOTAL | number:'1.0-0') : ''}}</td>
                                                <td class="text-center text-nowrap">{{report?.DEUDOR ? (report?.DEUDOR | rutFormat) : ''}}</td>
                                                <td class="text-center text-nowrap">{{report?.CEDENTE ? (report?.CEDENTE | rutFormat) : ''}}</td>
                                                <td class="text-center">{{report?.RZ_CEDENTE || ''}}</td>
                                                <td><div class="circulo" [ngClass]="validarEstado(report)"></div></td>
                                                <td class="text-center px-3">
                                                    <i class="fa fa-check-circle-o text-success icon-size" title="Pagado" aria-hidden="true" *ngIf="report?.estaPagado == 1"></i>
                                                    <div class="row d-flex justify-content-between" *ngIf="report?.estaPagado != 1">
                                                        <i class="fa fa-times-circle-o text-danger icon-size" title="No Pagado" aria-hidden="true"></i>
                                                        <i class="fa fa-exchange text-warning icon-size-pagar circle-icon cursor-pointer no-print" title="Presione para marcar como pagado" (click)="actualizarPagado(report, i)" aria-hidden="true"></i>
                                                    </div>
                                                </td>
                                                <td class="text-center no-print" *ngIf="validarEstadoActualizado()">
                                                    <button 
                                                        type="button" 
                                                        title="No se actualizo correctamente, presione aqui para volver a actualizar" 
                                                        class="btn btn-danger"
                                                        *ngIf="validarEstadoActualizadoElement(report)"
                                                        (click)="actualizarRegistro(report, i)"
                                                    >
                                                        <i class="fa fa-refresh text-white" aria-hidden="true"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-template #noPage>
                                            <tr>
                                                <td colspan="100%">
                                                    Sin información
                                                </td>
                                            </tr>
                                        </ng-template>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
        
                        <div class="col-sm-12 no-print" *ngIf="paginaActual.length > 0 && paginaActual[0]">
                            <div id="pagination">
                                <div class="col-sm-12 col-md-8 col-lg-4 float-right my-3">
                                    <pagination-controls (pageChange)="getPage($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="showModal">
        <app-modal-credenciales [credencial]="itemCredencialSelected" [tipoCredencial]="itemCredencialSelectedType" (actualizaVista)="volverModalCredenciales()"></app-modal-credenciales>
    </ng-container>
</ng-container>
<ng-template #noAccess>
    <app-request-access-view></app-request-access-view>
</ng-template>