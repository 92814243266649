import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { SimpleModalService } from "ngx-simple-modal";
import { MatDialog } from '@angular/material/dialog';
import { AccessDialogComponent } from 'src/app/components/access-dialog/access-dialog.component';
import { AlertService } from 'src/app/components/_alert';
import { SessionService } from 'src/app/shared/services/session.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ESsystemUserProfile, ESystemOrganizacion, ESystemProfileList } from 'src/app/enum/EAccess';
import { IGetOrganizationsList, OrganizacionesService } from 'src/app/shared/services/organizaciones.service';
import { Title } from '@angular/platform-browser';
import { environment as env } from 'src/environments/environment';

export interface IAlertContent {
  message: string;
  success: boolean;
}

@Component({
  selector: 'app-listar-organizaciones',
  templateUrl: './listar-organizaciones.component.html',
  styleUrls: ['./listar-organizaciones.component.scss']
})
export class ListaOrganizacionesComponent implements OnInit {
  private access: string[] = [ESsystemUserProfile.USERUNIQUE, ESystemProfileList.WEB];
  private accessOrg: string[] = ESystemOrganizacion.ORGANIZACION_ACCESS;
  public hasUserAccess = false;

  // Tabla
  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public inputText: string = '';
  public order = '';
  public campoOrder = '';

  public listOrganizations: IGetOrganizationsList[] = [];
  public listOrganizationsFiltrada: any[] = [];

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog,
    private organizacionesService: OrganizacionesService,
    private _sessionService: SessionService,
    public alertService: AlertService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);

    if (this._sessionService.getUserInfo().email.includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])
    && this.accessOrg.includes(this._sessionService.getOrganization())) {
      this.hasUserAccess = true;
      this.loadListOrganizations();
    }
  }

  public showAccess(idOrganizacion: string): void {
    const tempList = this.listOrganizations.find((e: IGetOrganizationsList) => e.id_organizacion === idOrganizacion)
    ?.available_admin_access.filter(e => e.active).map((organizacion: any) => {return organizacion.name});
    
    this.dialog.open(AccessDialogComponent, {
      data: {
        title: 'Accesos',
        access: tempList
      }
    });

    /*this.simpleModalService.addModal(AccessDialogComponent, {
      title: 'Accesos',
      access: tempList
    });*/
  }

  public loadListOrganizations() {
  this.spinner.show()
  this.organizacionesService.getOrganizations()
    .subscribe(
      (data) => {
        this.listOrganizations = data;
        this.mapeoFechas();
        this.filtrarObjeto();
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    )
  }

  mapeoFechas(): void {
    this.listOrganizations.forEach(element => {
      if(element?.update_date){
        const dateString = element.update_date.toString();
        const year = dateString.substr(0,4);
        const month = dateString.substr(5,2);
        const day = dateString.substr(8,2);
        const hour = dateString.substr(11,2);
        const minute = dateString.substr(14,2);

        element.fecha_formatted = ["" + day, "" + month, "" + year].join('-') + [" " + hour, "" + minute].join(':');
      }
    });
  }

  filtrarObjeto(){
    this.currentPage = 0;
    this.listOrganizationsFiltrada = this.inputText != '' ? this.listOrganizations.filter((item: any) => 
    item?.id_organizacion?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.description?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.update_date?.toUpperCase().includes(this.inputText.toUpperCase())
    ) : this.listOrganizations;
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      this.listOrganizationsFiltrada.sort((a, b) => {
        if(campo === 'fecha_formatted'){
          let newDateA: any = null;
          let newDateB: any = null;

          if(a?.fecha_formatted){
            const dateFullA = a?.fecha_formatted?.split(" ");
            const dateA = dateFullA?.[0]?.split("-");
            const hoursA = dateFullA?.[1]?.split(":");
            newDateA = new Date(dateA[2], dateA[1]-1, dateA[0], hoursA[0], hoursA[1]);
          } else {
            newDateA = new Date("1000");
          }

          if(b?.fecha_formatted){
            const dateFullB = b?.fecha_formatted?.split(" ");
            const dateB = dateFullB?.[0]?.split("-");
            const hoursB = dateFullB?.[1]?.split(":");
            newDateB = new Date(dateB[2], dateB[1]-1, dateB[0], hoursB[0], hoursB[1]);
          } else {
            newDateB = new Date("1000");
          }

          return newDateA - newDateB;
        } else {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return textA.localeCompare(textB);
        }
      });
    } else {
      this.listOrganizationsFiltrada.sort((a, b) => {
        if(campo === 'fecha_formatted'){
          let newDateA: any = null;
          let newDateB: any = null;

          if(a?.fecha_formatted){
            const dateFullA = a?.fecha_formatted?.split(" ");
            const dateA = dateFullA?.[0]?.split("-");
            const hoursA = dateFullA?.[1]?.split(":");
            newDateA = new Date(dateA[2], dateA[1]-1, dateA[0], hoursA[0], hoursA[1]);
          } else {
            newDateA = new Date("1000");
          }

          if(b?.fecha_formatted){
            const dateFullB = b?.fecha_formatted?.split(" ");
            const dateB = dateFullB?.[0]?.split("-");
            const hoursB = dateFullB?.[1]?.split(":");
            newDateB = new Date(dateB[2], dateB[1]-1, dateB[0], hoursB[0], hoursB[1]);
          } else {
            newDateB = new Date("1000");
          }

          return newDateB - newDateA;
        } else {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return (-1 * textA.localeCompare(textB));
        }
      });
    }
  }

  public goToCreate(): void {
  this.router.navigate(['/organizacion/crear']);
  }

  public goToEdit(idOrganizacion: string): void {
  this.router.navigate(['/organizacion/editar', idOrganizacion]);
  }

}
