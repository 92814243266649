import { Component, Input, AfterViewInit, Inject } from '@angular/core';
//import { SimpleModalComponent } from "ngx-simple-modal";
import { BalanceManualService } from 'src/app/shared/services/balance-manual.service';
import { environment as env } from 'src/environments/environment';
import { RUT } from 'src/app/shared/utils/rut';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';


export interface InputModel {
  rut: string;
  tipo: string;
  periodo: string;
  tx: string;
  textTimer: number;
}

@Component({
  selector: 'app-modal-balance-manual-log',
  templateUrl: './modal-balance-manual-log.component.html',
  styleUrls: ['./modal-balance-manual-log.component.scss']
})
export class ModalBalanceManualLogComponent implements AfterViewInit {
  /*public rut: string = '';
  public tipo: string = '';
  public periodo: string = '';
  public tx: string = '';*/
  public textTimer: number = 0;

  inputModel: InputModel = {
    rut: "",
    tipo: "",
    periodo: "",
    tx: "",
    textTimer: 0
  };
  
  public showSpinner: boolean = false;
  public logData: any[] = [];
  public currentPage = 0;
  public inputText: string = '';
  public arrayFiltrado: any[] = [];
  public currentItem = env.initItemPerPage;
  public messages: { text: string, isError: boolean, isSuccess: boolean }[] = [];

  public counter$: Observable<number> = new Observable;

  public counter = 0;
  
  /*constructor(
    private balanceManualService: BalanceManualService
  ) {
    super();
  }*/

  constructor(public dialogRef: MatDialogRef<ModalBalanceManualLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputModel, private balanceManualService: BalanceManualService) {
      this.inputModel = data;
      if(this.inputModel?.rut){
        this.obtenerLogBalanceManual();
      }

      timer(0, 1000).pipe(
        // Take values until the specified duration is reached
        take(this.inputModel.textTimer + 1),
        // Subtract the emitted values from the duration to get the countdown
        map((value) => this.inputModel.textTimer - value)
      ).subscribe((counter) => {
        this.counter = counter;

        // Close the dialog when the countdown reaches zero
        if (counter === 0) {
          // this.close();
        }
      });
  }

  /*ngOnInit(): void {
    if(this.rut){
      this.obtenerLogBalanceManual();
    }
  }*/

  transformData(data: any[]) {
    return data.reduce((acc, item) => {
      const fechaParts = item.fecha.split('T');
      const fecha = fechaParts[0] ? fechaParts[0].split('-').reverse().join('-') : '';
      const hora = fechaParts[1] ? fechaParts[1] : '';
  
      if (!item?.diff || !item?.diff?.original || !item?.diff?.original?.campos || !item?.diff?.nuevo || !item?.diff?.nuevo?.campos) {
        acc.push({
          ...item,
          fecha,
          hora,
          campo: "",
          original: "",
          nuevo: "",
          diff: undefined
        });
        return acc;
      }
  
      Object.entries(item.diff.original.campos).forEach(([campo, original]) => {
        acc.push({
          ...item,
          fecha,
          hora,
          campo,
          original,
          nuevo: item.diff.nuevo.campos[campo],
          diff: undefined
        });
      });
  
      return acc;
    }, []);
  }

  obtenerLogBalanceManual(): void {
    this.logData = [];
    if(!this.inputModel?.tx){
      this.showSpinner = true;
      this.balanceManualService.obtenerLog(this.inputModel?.rut).subscribe(
        (data: any) => {
          if(data && data.length > 0){
            this.logData = data.map((item: any) => {
              return {
                ...item,
                fecha: item?.fecha ? item.fecha.split('T')[0].split('-').reverse().join('-') : item.fecha,
                hora: item?.fecha ? item.fecha.split('T')[1] : item.fecha
              }
            }) || [];
            this.filtrarObjeto();
          }
          this.showSpinner = false;
        },
        (error: any) => {
          this.addMessage(error?.error?.message || 'Ha ocurrido un error al obtener los registros', true, false);
          this.showSpinner = false;
        }
      );
    } else {
      this.showSpinner = true;
      this.balanceManualService.obtenerLog(this.inputModel?.rut, this.inputModel?.tipo, this.inputModel?.periodo, this.inputModel?.tx).subscribe(
        (data: any) => {
          if(data && data.length > 0){
            this.logData = this.transformData(data) || [];
            this.filtrarObjeto();
          }
          this.showSpinner = false;
        },
        (error: any) => {
          this.addMessage(error?.error?.message || 'Ha ocurrido un error al obtener los registros', true, false);
          this.showSpinner = false;
        }
      );
    }
  }

  filtrarObjeto(){
    this.currentPage = 0;
    this.arrayFiltrado = this.inputText != '' ? this.logData.filter((item: any) => 
    item?.rut.includes(this.inputText.replace(/[^a-zA-Z0-9]/g, ''))
    || item?.rut.includes(RUT.applyBackendFormat(this.inputText.replace(/[^a-zA-Z0-9]/g, '')))
    || item?.fecha?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.hora?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.usuario?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.accion?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.periodo?.toString()?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.campo?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.original?.toString()?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.nuevo?.toString()?.toUpperCase().includes(this.inputText.toUpperCase())) : this.logData;
  }

  addMessage(mensaje: string, isError: boolean, isSuccess: boolean) : void {
    this.messages.push({ text: mensaje, isError: isError, isSuccess: isSuccess });
  }

  removeMessage(index: number) {
    this.messages.splice(index, 1);
  }

  //confirm
  confirm() {
    // we set modal result as true on click on confirm button,
    // then we can get modal result from caller code
    // this.result = true;
    // this.close();
    this.dialogRef.close(true);
  }

  //cancel
  close() {
    this.dialogRef.close(false);
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.inputModel.textTimer > 0) {
      this.counter$ = await timer(0, 1000).pipe(
        take(this.textTimer),
        map(() => {
          return --this.textTimer
        }));
    }
  }

}
