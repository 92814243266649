<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<div *ngIf="hasUserAccess && showBuscador">
    <div class="container__form">
        <div class="col-md-6">
            <div class="block">
                <form [formGroup]="searchFinancieroForm" (ngSubmit)="ingresoRut()">
                    <div class="form-horizontal form-bordered">
                        <div class="form-group" [ngClass]="{'has-error': activateRutHelper}">
                            <label class="col-md-3 control-label" for="example-text-input">Rut</label>
                            <div class="col-md-6">
                                <input formControlName="rut" type="text" class="form-control" formatRut required>
                                <span *ngIf="activateRutHelper" class="help-block">Rut no valido</span>
                            </div>
                        </div>
                        <div class="form-group form-actions container__actions">
                            <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i>
                                Buscar Reporte</button>
                            <button type="button" (click)="clearForm()" class="btn btn-sm btn-warning"><i class="fa fa-repeat"></i>
                                Limpiar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-reporte-financiero-consolidado *ngIf="verReporteFinancieroConsolidado" [reporteBasico]="searchReportDataSource.reporteBasico.InformacionBasica" [tipoPersona]="tipoPersona" [dataf29]="this.searchReportDataSource.f29" [dataf22]="this.searchReportDataSource.f22" [rut]="rut" (verFinancieroConsolidado)="verReporteFinancieroConsolidado = $event"></app-reporte-financiero-consolidado>

<app-formulario-f22-consultar *ngIf="crearF22" [rut]="rut" (actualizarReporte)="buscarReporte($event)"></app-formulario-f22-consultar>

<app-reporte-full-consultar *ngIf="crearCarpeta" [rut]="rut" (actualizarReporte)="buscarReporte($event)" [nombreBasico]="searchReportDataSource?.reporteBasico?.InformacionBasica?.NombreORazonSocial || ''"></app-reporte-full-consultar>

<app-formulario-f29-consultar *ngIf="crearF29" [rut]="rut" (actualizarReporte)="actualizarF29($event)"></app-formulario-f29-consultar>

<app-reporte-renta-consultar *ngIf="crearRenta" [rut]="rut" (actualizarReporte)="buscarReporte($event)"></app-reporte-renta-consultar>

<ng-container *ngIf="!crearF29 && !crearF22 && !crearCarpeta && !crearRenta && !verReporteFinancieroConsolidado">

    <div class="row" *ngIf="hasUserAccess && showSelector && !tipoBalanceManual && !tipoBalanceAutomatico">
        <div class="col-sm-6 col-md-5 my-5 mx-auto text-center">
            <div class="panel panel-default w-100 mx-4">
                <div class="panel-body">
                    <span>
                        Seleccione una modalidad...
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-3 not-print">
        <div class="col-sm-12 col-md-7 mb-2 mx-auto" *ngIf="hasUserAccess && showSelector">
            <div class="container">
                <div class="row">
                    <span 
                        class="mx-auto text-center cursor-pointer span-selector p-2" 
                        [ngClass]="{'span-selector-active': tipoBalanceManual}" 
                        (click)="balanceManual()" *ngIf="validaPermisosManual()">
                            Manual
                    </span>
                    <span 
                        class="mx-auto text-center cursor-pointer span-selector p-2" 
                        [ngClass]="{'span-selector-active': tipoBalanceAutomatico}" 
                        (click)="buscarReporte()">
                            Automatico
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="hasUserAccess && !showBuscador && !ocultarReporte && tipoBalanceManual"> 
        <app-ingreso-balance-manual class="w-100" [rut]="rut" (cerrarReporte)="closeReport()"></app-ingreso-balance-manual>
    </div>


    <div class="container-gtp mb-5" *ngIf="hasUserAccess && !showBuscador && !ocultarReporte && tipoBalanceAutomatico">
        <div class="break-box">
            <div class="row" *ngIf="!visibleReportef29 && !visibleReportef22 && !visibleCarpeta && !visibleReporteRenta">
                <div class="panel panel-default w-100 mx-4">
                    <div class="panel-body">
                        <div class="btn-option">
                            <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TABLA Sujeto Credito -->
                <div class="col-sm-12">
                    <div class="card textos-campo">
                        <h5 class="card-header cuadro-color">Sujeto Credito 
                            <span class="badge badge-primary mx-2" *ngIf="searchReportDataSource.f22.length > 0 && reporteActualizado"> 
                                al {{searchReportDataSource.f22[0].Formulario22Reporte.DatosBasicosSolicitud.FechaReporte | date: 'dd-MM-yyyy' }}
                            </span>
                        </h5>
                        <div class="card-body">
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table" id="table-sujeto-credito">
                                        <tbody>
                                            <tr>
                                                <th>Rut</th>
                                                <td colspan="2">
                                                    <span *ngIf="rut">
                                                        {{rut | rutFormat | uppercase}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Nombre/Razon Social</th>
                                                <td>
                                                    <div class="row mr-0">
                                                        <span class="col-12">
                                                            {{ searchReportDataSource.reporteBasico.InformacionBasica.NombreORazonSocial }}
                                                        </span>
                                                        <span class="col-12 campo-cursiva">
                                                            {{ tipoPersona }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th width="30%">Fecha Inicio Actividad</th>
                                                <td>
                                                    <span>
                                                        {{ searchReportDataSource.reporteBasico.InformacionBasica.AnoInicioActividades | date: 'dd-MM-yyyy' }}
                                                    </span>
                                                </td>
                                            </tr>
                                            
                                            <ng-container *ngIf="searchReportDataSource.f22.length > 0 && searchReportDataSource.f22[0]">
                                                <tr>
                                                    <th width="30%">Actividad Economica</th>
                                                    <td>
                                                        <span *ngIf="searchReportDataSource.f22[0]?.Formulario22Reporte?.Reporte?.calculado?.actividadEconomica">
                                                            {{ searchReportDataSource.f22[0]?.Formulario22Reporte?.Reporte?.calculado?.actividadEconomica }}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="30%">Sector</th>
                                                    <td>
                                                        <span *ngIf="searchReportDataSource.f22[0]?.Formulario22Reporte?.Reporte?.calculado?.sector">
                                                            {{ searchReportDataSource.f22[0]?.Formulario22Reporte?.Reporte?.calculado?.sector }}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="30%">SubSector</th>
                                                    <td>
                                                        <span *ngIf="searchReportDataSource.f22[0]?.Formulario22Reporte?.Reporte?.calculado?.subSector">
                                                            {{ searchReportDataSource.f22[0]?.Formulario22Reporte?.Reporte?.calculado?.subSector }}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="30%">Tamaño</th>
                                                    <td>
                                                        <span *ngIf="searchReportDataSource.f22[0]?.Formulario22Reporte?.Reporte?.calculado?.tamano">
                                                            {{ searchReportDataSource.f22[0]?.Formulario22Reporte?.Reporte?.calculado?.tamano }}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="30%">Rating</th>
                                                    <td>
                                                        <span *ngIf="searchReportDataSource.f22[0]?.Formulario22Reporte?.Reporte?.calculado?.rating || searchReportDataSource.f22[0]?.Formulario22Reporte?.Reporte?.calculado?.periodo">
                                                            {{ searchReportDataSource.f22[0]?.Formulario22Reporte?.Reporte?.calculado?.rating }} (al {{ searchReportDataSource.f22[0]?.Formulario22Reporte?.Reporte?.calculado?.periodo }})
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="30%">Glosa Rating</th>
                                                    <td>
                                                        <span *ngIf="searchReportDataSource.f22[0]?.Formulario22Reporte?.Reporte?.calculado?.glosa">
                                                            {{ searchReportDataSource.f22[0]?.Formulario22Reporte?.Reporte?.calculado?.glosa }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-12" *ngIf="searchReportDataSource.f22.length > 0 || searchReportDataSource.f29.length > 0">
                                <div class="float-right">
                                    <button class="btn btn-sm btn-primary" (click)="visualizarReporteFinancieroC()"><i class="fa fa-angle-right"></i>
                                        Generar Reporte
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div [className]="!visibleReportef22 ? classModulos : 'col-12'" *ngIf="!visibleReportef29 && !visibleCarpeta && !visibleReporteRenta">
                    <app-formulario-f22-buscar [data]="this.searchReportDataSource.f22" [rut]="rut" (verF22)="visibleReportef22 = $event" (verCrearF22)="crearF22 = $event"></app-formulario-f22-buscar>
                </div>
                <div [className]="!visibleCarpeta ? classModulos : 'col-12'" *ngIf="!visibleReportef29 && !visibleReportef22 && !visibleReporteRenta">
                    <app-reporte-full-buscar [data]="this.searchReportDataSource.carpetaTributaria" [rut]="rut" (verCarpeta)="visibleCarpeta = $event" (verCrearCarpeta)="crearCarpeta = $event" [nombreBasico]="searchReportDataSource?.reporteBasico?.InformacionBasica?.NombreORazonSocial || ''"></app-reporte-full-buscar>
                </div>
                <div [className]="!visibleReportef29 ? classModulos : 'col-12'" *ngIf="!visibleReportef22 && !visibleCarpeta && !visibleReporteRenta">
                    <app-formulario-f29-buscar [data]="this.searchReportDataSource.f29" [rut]="rut" (verF29)="visibleReportef29 = $event" (verCrearF29)="crearF29 = $event"></app-formulario-f29-buscar>
                </div>
                <div [className]="!visibleReporteRenta ? classModulos : 'col-12'" *ngIf="!visibleReportef29 && !visibleCarpeta && !visibleReportef22 && tipoPersona==='Personal Natural'">
                    <app-reporte-renta-buscar [data]="this.searchReportDataSource.f22" [rut]="rut" (verRenta)="visibleReporteRenta = $event" (verCrearRenta)="crearRenta = $event"></app-reporte-renta-buscar>
                </div>
    
            </div>
        </div>
    </div>

</ng-container>

