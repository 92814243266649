<ng-container *ngIf="informeTributario.length > 0">

    <ng-container *ngFor="let carpetaSociosRepresentante of informeTributario">
        <div class="mb-5">
            <ng-container *ngFor="let item of carpetaSociosRepresentante.sociosRepresentante">
                    <app-dynamic-colunm-table [data]='item.data' [header]='header' [divider]='"normal"'
                        [groupHeader]="'listadoCTE'" [extraHeader]="item.tipoPersona">
                    </app-dynamic-colunm-table>
            </ng-container>
        </div>

        <div class="mb-5">
            <app-dynamic-colunm-table [data]='carpetaSociosRepresentante.ventas' [header]='headerVentas' [divider]='"normal"' [icon]='true'
                [iconExcluir]="'ventas'" [paginationExcluir]="true">
            </app-dynamic-colunm-table>
        </div>

        <div class="row">
            <div class="mb-5 col-sm-4">
                <app-dynamic-colunm-table [data]='carpetaSociosRepresentante.balances' [header]='["balance", "check"]' [divider]='"normal"'
                    [icon]='true' [iconExcluir]="'balance'" [paginationExcluir]="true">
                </app-dynamic-colunm-table>
            </div>
        </div>
    </ng-container>
</ng-container>