import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { IReporteBasico } from 'src/app/models/reporte-buscar/reporte-buscar.model';
import { ICarpetaTributariaElectronicaReporteModel, ICreateReporModel, IListReporteModel } from 'src/app/models/reporte-full/reporte-full.model';
import { environment } from '../../../environments/environment';
import { SessionService } from './session.service';


@Injectable({
  providedIn: 'root'
})
export class ReporteFullService {
  private endpoint: string = environment.backend.carpetaTributaria.url;
  private servicio: string = environment.backend.carpetaTributaria.servicio;
  public reporteBasicoUpdateObjSubject: Subject<any> = new Subject();
  public reporteBasicoUpdateObs$ = this.reporteBasicoUpdateObjSubject.asObservable();

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService) { }


  public createReport(rut: string, form: any): Observable<any> {
    const payload: ICreateReporModel = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      email: this._sessionService.getUserInfo().email,
      servicio: this.servicio,
      uri: "crearCarpetaReporte",
      ...form
    }
    return this.http.post<ICarpetaTributariaElectronicaReporteModel>(this.endpoint + "crear", payload)
  }

  public descargarReport(rut: string, form: any): Observable<any> {
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "descargarCarpetaReporte",
      ...form
    }
    return this.http.post<ICarpetaTributariaElectronicaReporteModel>(this.endpoint + "crear", payload)
  }

  public procesarReport(rut: string, form: any, validar: boolean = true): Observable<any> {
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "procesarCarpetaReporte",
      validar: validar,
      ...form
    }
    return this.http.post<ICarpetaTributariaElectronicaReporteModel>(this.endpoint + "crear", payload)
  }

  public calcularVentas(rut: string, idTrx: string): Observable<any> {
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "calcularVentasBalances",
      id_transaccion: idTrx
    }
    return this.http.post<ICarpetaTributariaElectronicaReporteModel>(this.endpoint + "crear", payload)
  }

  buscarReportes(rut: string): Observable<IListReporteModel> {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarReportes');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    params = params.append("statusTransaccion", 'COMPLETED');
    params = params.append("subStatusTransaccion", 'COMPLETED-CALCULATED');
    return this.http.get<IListReporteModel>(`${this.endpoint}obtener`, { params });
  }

  obtenerReporte(rut: string, idTransaccion: string): Observable<ICarpetaTributariaElectronicaReporteModel> {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarTX');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    params = params.append("id_transaccion", idTransaccion);
    return this.http.get<ICarpetaTributariaElectronicaReporteModel>(`${this.endpoint}obtener`, { params });
  }

  obtenerLastUpdate(rut: string): Observable<ICarpetaTributariaElectronicaReporteModel> {
    let params = new HttpParams();
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarCarpeta');
    params = params.append("rut", rut);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    return this.http.get<ICarpetaTributariaElectronicaReporteModel>(`${this.endpoint}obtener`, { params });
  }


  getTest() {
    return {

    }
  }

}
