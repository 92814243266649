import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment as env } from 'src/environments/environment';
import { AlertService } from 'src/app/components/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { RUT } from 'src/app/shared/utils/rut';
import { CredencialesExternasService } from 'src/app/shared/services/credenciales-externas.service';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-listado-solicitudes',
  templateUrl: './listado-solicitudes.component.html',
  styleUrls: ['./listado-solicitudes.component.scss']
})
export class ListadoSolicitudesComponent implements OnInit {
  @Input() rutInput: string = '';
  public seeRecibidos: boolean = false;
  private access: string[] = [ESystemAccess.SINCRONIZAR_CREDENCIALES_BUSCAR, ESystemProfileList.WEB];
  public hasUserAccess = false;

  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public inputText: string = '';
  public order = '';
  public campoOrder = '';
  public servicioSeleccionado: any = {};
  public tablaSolicitudesFiltrada: any[] = [];
  public solicitudes: any[] = [];
  public showTable: boolean = true;
  public showDetails: boolean = false;
  public solicitudSeleccionada: any = {};

  constructor(
    private router: Router,
    public alertService: AlertService,
    private credencialesExternasService: CredencialesExternasService,
    private utilsService: UtilsService,
    private spinner: NgxSpinnerService,
    private _sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
      this.obtenerListado();
    }
  }
  
  public goBack(): void {
    this.router.navigate(['/credenciales/usuarios']);
  }
  
  obtenerListado(): void {
    this.solicitudes = [];
    this.spinner.show();
    this.credencialesExternasService.listarSolicitudes(this.rutInput).subscribe(
      (data: any) => {
        if(data && data.length > 0){
          this.solicitudes = data.map((e: any) => {
            return {
              rut: `${e?.DatosBasicosSolicitud.Rut}-${e?.DatosBasicosSolicitud.DV}`,
              id: e?.DatosBasicosSolicitud.IDTransaccion || '',
              servicio: e?.DatosBasicosSolicitud.Servicio || '',
              estado: e?.DatosBasicosSolicitud.EstadoTransaccion || '',
              fechaFormatted: this.utilsService.formatDateHMS(e?.DatosBasicosSolicitud.FechaSolicitud),
              fecha: e?.DatosBasicosSolicitud.FechaSolicitud || '',
              fechaFormattedM: this.utilsService.formatDateHMS(e?.DatosBasicosSolicitud.FechaModificacion),
              fechaM: e?.DatosBasicosSolicitud.FechaModificacion || '',
              subEstado: e?.DatosBasicosSolicitud.SubEstadoTransaccion || '',
              intentoCarga: e?.DatosBasicosSolicitud.IntentoCarga,
              intentoSync: e?.DatosBasicosSolicitud.IntentoSync,
            }
          });
          this.filtrarObjeto();
          this.campoOrder = 'fechaM';
          this.order = 'asc';
          this.cambioDeCampo('fechaM');
          this.spinner.hide();
        } else {
          this.alertService.warn('No se han encontrado elementos.');
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.alertService.error(error?.error?.message || 'Ha ocurrido un error al obtener el listado de solicitudes.');
        this.spinner.hide();
    });

  }

  filtrarObjeto(){
    this.currentPage = 0;
    this.tablaSolicitudesFiltrada = this.inputText != '' ? this.solicitudes.filter((item: any) => 
    item?.rut.includes(this.inputText.replace(/[^a-zA-Z0-9]/g, ''))
    || item?.rut.includes(RUT.applyBackendFormat(this.inputText.replace(/[^a-zA-Z0-9]/g, '')))
    || item?.servicio?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.estado?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.fechaFormatted?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.fechaFormattedM?.toUpperCase().includes(this.inputText.toUpperCase())) : (this.solicitudes || []);
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      this.tablaSolicitudesFiltrada.sort((a, b) => {
        if(campo.includes('fecha')){
          /*const fechaA = new Date(a[campo]);
          const fechaB = new Date(b[campo]);
          return fechaA > fechaB ? 1 : -1;*/
          const fechaA: any = new Date(a[campo]);
          const fechaB: any = new Date(b[campo]);
          return (this.utilsService.dateIsValid(fechaA) ? fechaA : new Date("1000")) - (this.utilsService.dateIsValid(fechaB) ? fechaB : new Date("1000"));
        } else {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return textA.localeCompare(textB);
        }
      });
    } else {
      this.tablaSolicitudesFiltrada.sort((a, b) => {
        if(campo.includes('fecha')){
          /*const fechaA = new Date(a[campo]);
          const fechaB = new Date(b[campo]);
          return fechaA < fechaB ? 1 : -1;*/
          const fechaA: any = new Date(a[campo]);
          const fechaB: any = new Date(b[campo]); 
          return (this.utilsService.dateIsValid(fechaB) ? fechaB : new Date("1000")) - (this.utilsService.dateIsValid(fechaA) ? fechaA : new Date("1000"));
        } else {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return (-1 * textA.localeCompare(textB));
        }
      });
    }
  }

  revisarSolicitud(solicitud: any): void {
    this.solicitudSeleccionada = solicitud;
    this.showTable = false;
    this.showDetails = true;
  }

  volverTabla(): void {
    this.solicitudSeleccionada = {};
    this.showTable = true;
    this.showDetails = false;
  }

}
