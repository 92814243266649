import { Component, Input, OnInit } from '@angular/core';
import { deudaSbifLeasing } from 'src/app/models/utils/utils.model';
import { IGlobalDocumentos, IDeudaSistemaFinancieroReporteModel, IItem } from 'src/app/shared/services/sbif-leasing.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-deuda-leasing-sbif-viewer',
  templateUrl: './deuda-leasing-sbif-viewer.component.html',
  styleUrls: ['../boletin-concursal/boletin-concursal-report-viewer/boletin-concursal-report-viewer.component.scss']
})
export class DeudaLeasingSbifViewerComponent implements OnInit {
  @Input() content: any | null = null;
  @Input() typeTX: boolean = false; 
  @Input() showHeader: boolean = true; 
  public dataSource: any[] = [];
  public headRow: any = [
    'periodo',
    'dirvigente', 
    'dirmorosa', 
    'dirvencida', 
    'diracreedor', 
    'dirhipotecaria', 
    'dirinversion', 
    'diroperacionpacto', 
    'dircomercial', 
    'credcontingente', 
    'monexvigente',
    'monexvencida', 
    'indirectavig', 
    'indirectaven', 
    'castigadadirecta', 
    'castigadaindirecta', 
    'leasingvig', 
    'leasingmor', 
    'dirven180mayor3year', 
    'lineacredito', 
  ];
  public headNames: any = deudaSbifLeasing;

  constructor() { }

  ngOnInit(): void {
    this.showDetails();
  }

  ngOnChanges(): void {
    this.showDetails();
  }

  showDetails(): void {
    this.dataSource = this.typeTX? 
    this.getResponse(this.content?.DeudaSistemaFinancieroReporte?.Documentos?.SbifOnline?.Reporte?.rowsMeses?.item) || []
    : this.getResponse(this.content?.Documentos?.SbifOnline?.Reporte?.rowsMeses?.item) || []
  }

  getResponse(response: IItem[]): IItem[] {    
    if (response && response.length > 0) {
    return response.map((e: any) => {
      return {
        castigadadirecta: e.castigadadirecta,
        castigadaindirecta: e.castigadaindirecta,
        credcontingente: e.credcontingente,
        diracreedor: [e.dirconsumo, e.diracreedor],
        dircomercial: e.dircomercial,
        dirhipotecaria: e.dirhipotecaria,
        dirinversion: e.dirinversion,
        dirmorosa: e.dirmorosa,
        diroperacionpacto: e.diroperacionpacto,
        dirven180mayor3year: e.dirven180mayor3year,
        dirvencida: e.dirvencida,
        dirvigente: e.dirvigente,
        indirectaven: e.indirectaven,
        indirectavig: e.indirectavig,
        leasingmor: e.leasingmor,
        leasingvig: e.leasingvig,
        lineacredito: e.lineacredito,
        monexvencida: e.monexvencida,
        monexvigente: e.monexvigente,
        periodo: this.formatDate(e.periodo)
      }
    }).sort((a,b)=> new Date(b.periodo).getTime() - new Date(a.periodo).getTime()) || []
  }
  return [];
  }

  formatDate(date: string): string {
    return date.split('-').reverse().join('-');
  }

}
