import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SessionService } from './session.service';


@Injectable({
  providedIn: 'root'
})
export class CredencialesExternasService {
  private servicio: string = environment.backend.secretInteraction.servicio; // validar
  private endpoint: string = environment.backend.credencialesExternas.url;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService
  ) { }

  validacionLink(idOrganizacion: string = '', idTransaccion: string = ''): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("idTransaccionEncrypted", idTransaccion);
    params = params.append("idOrganizacionEncrypted", idOrganizacion);
    
    return this.http.get<any>(`${this.endpoint}iniciarSincronizacion`, { params })
  }

  solicitarCredencial(rut: string, email: string, servicio: string): Observable<any> {
    const payload: any = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      emailDestino: email,
      servicioSolicitado: servicio
    }

    return this.http.post<any>(`${this.endpoint}solicitarSincronizacionCredenciales`, payload);
  }

  listarSolicitudes(rut: string = ''): Observable<any> {
    let params = new HttpParams(); 
    params = params.append("idOrganizacion", this._sessionService.getOrganization());    
    params = params.append("rut", rut ? rut : '');

    return this.http.get<any>(`${this.endpoint}listarSincronizacionesSolicitadas`, { params })
  }

  iniciarSincronizacion(
    rut: string, 
    idOrganizacionEncrypted: string, 
    idTransaccionEncrypted: string, 
    emailDestino: string, 
    servicioSolicitado: string,
    aceptaTerminos: boolean,
    credsObject: any
  ): Observable<any> {

    const payload: any = {
      rut: rut,
      idOrganizacionEncrypted: idOrganizacionEncrypted,
      idTransaccionEncrypted: idTransaccionEncrypted,
      requestOrigin: "WEB",
      emailDestino: emailDestino,
      servicioSolicitado: servicioSolicitado,
      aceptaTerminos: aceptaTerminos,
      credsObject: credsObject,
    }

    return this.http.post<any>(`${this.endpoint}sincronizarCredencialesEtapa1`, payload);
  }

  iniciarSincronizacionEtapa2(
    rut: string, 
    idOrganizacionEncrypted: string, 
    idTransaccionEncrypted: string, 
    emailDestino: string, 
    servicioSolicitado: string,
    aceptaTerminos: boolean,
    credsObject: any
  ): Observable<any> {

    const payload: any = {
      rut: rut,
      idOrganizacionEncrypted: idOrganizacionEncrypted,
      idTransaccionEncrypted: idTransaccionEncrypted,
      requestOrigin: "WEB",
      emailDestino: emailDestino,
      servicioSolicitado: servicioSolicitado,
      aceptaTerminos: aceptaTerminos,
      credsObject: credsObject
    }
    
    return this.http.post<any>(`${this.endpoint}sincronizarCredencialesEtapa2`, payload);
  }

}
