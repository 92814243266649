import { Injectable } from '@angular/core';
import { KEYUTIL, KJUR } from 'jsrsasign';
import { environment } from '../../../environments/environment';
import { JWTToken } from '../utils/jwt-token';
import { Encrypt } from '../utils/encrypt';
import { Request } from '../utils/request';
import { SessionService } from './session.service';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';

interface Keys {
  keys: Key[];
}

interface Key {
  alg: string;
  e: string;
  kid: string;
  kty: string;
  n: string;
  use: string;
}

interface ITokenResponse {
  access_token: string;
  refresh_token: string;
  id_token: string;
  token_type: string;
  expires_in: number;
}

export interface IUserInfoCognito {
  organizations: string[];
  username: string;
  email: string;
  userSub: string;
  given_name: string;
  phoneNumber: string;
  name: string;
}

export interface IUserInfoResponse {
  sub: number;
  name: string;
  given_name: string;
  family_name: string;
  preferred_username: string;
  email: string;
}


@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  private region: string = '';
  private appClientId: string = '';
  private userPoolId: string = '';
  private baseAmazonCognitoURI: string = '';
  private redirectCallbackURI: string = '';
  private redirectLogoutURI: string = '';


  constructor(
    private _sessionService: SessionService,
    private _router: Router,
    private _userIdleService: UserIdleService,
  ) {
    const { domain, region, appClientId, userPoolId } = environment.cognitoConfig;
    this.region = region;
    this.appClientId = appClientId;
    this.userPoolId = userPoolId;
    this.baseAmazonCognitoURI = `https://${domain}.auth.${this.region}.amazoncognito.com`;
    this.redirectLogoutURI = environment.cognitoConfig.baseRedirectURI + '/logout';
    this.redirectCallbackURI = environment.cognitoConfig.baseRedirectURI + '/callback';
  }

  public async logout() {
    try {
      await signOut();
      //Limpiar store, stop watching en el userIdle
      this.removeHandShakeKeys();
      await this._userIdleService.stopTimer();
      await this._userIdleService.stopWatching();
      this._sessionService.clearSession();
    } catch (error) {
      console.error('error signing out: ', error);
    }
  }

  public removeHandShakeKeys() {
    localStorage.removeItem("code_verifier");
    localStorage.removeItem("pkce_state");
    localStorage.removeItem("code_challenge");
    window.dispatchEvent(new Event('storage'));
  }

  public async register(code: string | null, state: string | null, codeVerifier: string | null): Promise<boolean> {
    codeVerifier = localStorage.getItem("code_verifier");

   
    if ([code, state, codeVerifier].includes('')) {
      this._sessionService.clearSession();
      this.authorize();
    }

    if (localStorage.getItem("pkce_state") != state) {
      this._sessionService.clearSession();
      this.authorize();
    }

    const fetchOAuth2TokensURL = `${this.baseAmazonCognitoURI}/oauth2/token?grant_type=authorization_code&client_id=${this.appClientId}&code_verifier=${codeVerifier}&redirect_uri=${this.redirectCallbackURI}&code=${code}`;

    const { id_token, access_token } = await Request.request<ITokenResponse>(fetchOAuth2TokensURL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    await this.removeHandShakeKeys();

    const idVerified = await this._verifyToken(id_token);    
    if (idVerified.localeCompare("verified")) {
    return false;
    }
    
    localStorage.setItem('idToken', id_token);
    window.dispatchEvent(new Event('storage'));

    const idToken = JWTToken.parseJWTPayload(id_token);
    

    localStorage.setItem('userInfo', JSON.stringify(<IUserInfoCognito>{
      organizations: idToken["cognito:groups"],
      username: idToken["email"],
      email: idToken["email"],
      userSub: idToken["sub"],
      given_name: idToken["given_name"],
      name: idToken["name"],
      phoneNumber: idToken["phone_number"]
    }))

    localStorage.setItem('accessToken', access_token);
    window.dispatchEvent(new Event('storage'));

    return true;
  }

  public async authorize() {
    // Create random "state"
    const state = Encrypt.getRandomString();
    localStorage.setItem("pkce_state", state);
    window.dispatchEvent(new Event('storage'));

    // Create PKCE code verifier
    const code_verifier = Encrypt.getRandomString();
    localStorage.setItem("code_verifier", code_verifier);
    window.dispatchEvent(new Event('storage'));

    // Create code challenge
    const arrayHash = await Encrypt.encryptStringWithSHA256(code_verifier);
    const code_challenge = Encrypt.hashToBase64url(arrayHash);
    localStorage.setItem("code_challenge", code_challenge)
    window.dispatchEvent(new Event('storage'));
  }

  /**
  * @description
  * Verifica si el token expiro
  * @returns boolean 
  */
  public async tokenValidator(): Promise<boolean> {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    if (idToken) {     
      const expiry = (JSON.parse(atob(idToken.toString().split('.')[1]))).exp;
      return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }
    return false;
  }

  private async _verifyToken(token: string) {
    //get Cognito keys
    const keysURL = `https://cognito-idp.${this.region}.amazonaws.com/${this.userPoolId}/.well-known/jwks.json`
    
    const keysResponse = await Request.request<Keys>(keysURL);
    const keys = keysResponse['keys'];

    //Get the kid (key id)
    const tokenHeader = JWTToken.parseJWTHeader(token);
    const key_id = tokenHeader.kid;

    //search for the kid key id in the Cognito Keys
    const key = keys.find((key: any) => key.kid === key_id);
    if (key === undefined) {
      return "Public key not found in Cognito jwks.json";
    }

    //verify JWT Signature
    const keyObj = await KEYUTIL.getKey(key);
    const sleep = (time: number) => new Promise((resolve, reject) => setTimeout(() => resolve(null), time));
    await sleep(5000);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Unreachable code error
    const isValid = KJUR.jws.JWS.verifyJWT(token, keyObj, { alg: ["RS256"] });

    if (isValid) {
    } else {
      return ("Signature verification failed");
    }

    //verify token has not expired
    const tokenPayload = JWTToken.parseJWTPayload(token);
    if (Date.now() >= tokenPayload.exp * 1000) {
      return ("Token expired");
    }

    //verify app_client_id
    const n = tokenPayload.aud.localeCompare(this.appClientId)
    if (n != 0) {
      return ("Token was not issued for this audience");
    }

    return ("verified");
  }

}
