<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<div class="row" *ngIf="hasUserAccess">
    <div class="col-sm-12">
        <div class="card w-100 mx-auto rounded-0 modulo-financiero">
            <div class="card-header py-1">
                <div class="row">
                    <div class="col-sm-12">
                        <h3 class="my-3">Panel de Riesgo</h3>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12">
                        <iframe class="frame-reporte" [src]="url | safe" frameborder="0"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>