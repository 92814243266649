import { Component, OnInit, Input, ViewChild, HostListener, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { SolicitudCreditoServiceWorkflow } from 'src/app/shared/services/solicitud-credito-workflow.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AlertService } from '../../_alert';
import { NgxMasonryComponent } from 'ngx-masonry';
//import { SimpleModalService } from 'ngx-simple-modal';
import { ListErrorsDialogWorkflowComponent } from '../../list-errors-dialog-workflow/list-errors-dialog-workflow.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-campos-manuales-workflow',
  templateUrl: './campos-manuales-workflow.component.html',
  styleUrls: ['./campos-manuales-workflow.component.scss']
})
export class CamposManualesWorkflowComponent implements OnInit {
  @Input() rut: string = '';
  @Input() idTransaccion: string = '';
  @Input() tipoSolicitud: string = '';
  @Input() operacionNuevo: boolean = true;
  @Input() stepper!: number;
  @Input() statusTX: string = '';
  @Input() cargaMasiva: boolean = false;
  @Output() idTransaccionNueva = new EventEmitter<string>();
  public camposPersonalizadosTemp: any[] = [];
  public camposPersonalizadosMasivos: any[] = [];
  public valuesCamposPersonalizados: any[] = [];
  public objectKeys = Object.keys;
  public camposPersonalizadosForm!: UntypedFormGroup;
  public formularioArchivo!: UntypedFormGroup;
  public camposCSVForm!: UntypedFormGroup;
  public advertencias: any[] = [];
  public advertenciasMasivas: any[] = [];
  public advertenciasAtributos: any[] = [];
  public tempFile!: any;
  private tempFileCamposCSV!: any;
  public fieldsReady: boolean = false;
  public mostrarFormulario: boolean = true;
  public subscriptions: Subscription[] = [];
  public subscriptionForm!: Subscription;
  public fileName: boolean = false;
  public fileNameCamposCSV: boolean = false;
  public updateMasonryLayout: boolean = false;
  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent | any;
  @HostListener('document:click', ['$event'])
  @HostListener('window:keyup', ['$event'])
  clickout(event: any) {
    this.actualizaLayout();
  }

  public secciones: any[] = [];
  public archivoMasivoCargado: boolean = false;
  public archivoAtributosCargado: boolean = false;
  public stageCompleted: boolean = false;
  public listaCamposPersonalizados: any = {};
  public largoValuesCamposPersonalizados: any[] = [];
  public archivoMasivoValido: boolean = true;

  constructor(private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private alertsService: AlertService,
    private eRef: ElementRef,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerFlujo(); 
  }

  initForm() {
    this.camposPersonalizadosForm = this.formBuilder.group({});
    this.setControlsFromService();
  }

  // Si tiene los datos traidos desde el componente principal va a obtener transaccion, si no va a obtener contrato y se genera la transaccion.
  obtenerFlujo() {
    this.spinner.show();
    if (!this.operacionNuevo && this.idTransaccion !== ''){
      this.subscriptions.push(this.solicitudCreditoService.obtenerTransaccion(this.rut, this.idTransaccion).subscribe(resp => {
        if (resp) {
          if(resp.SolicitudDeCredito?.Stage1Response?.errors?.hassError === true){
            const datosBasicos = resp.SolicitudDeCredito.DatosBasicosSolicitud;
            this.idTransaccion = datosBasicos.IDTransaccion;
            this.alertsService.error(resp.SolicitudDeCredito?.Stage1Response?.errors?.details?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar datos del flujo de credito');
            this.spinner.hide();
          } else {
            if(resp.SolicitudDeCredito?.Stage2Response?.Atributos){
              this.camposPersonalizadosTemp = resp.SolicitudDeCredito?.Stage2Response?.Atributos;
            } else {
              this.camposPersonalizadosTemp = resp.SolicitudDeCredito?.Stage1Response?.ContratoAtributos;
            }
            if(resp.SolicitudDeCredito?.Stage2Response?.AtributosCargaMasiva){
              this.camposPersonalizadosMasivos = resp.SolicitudDeCredito?.Stage2Response?.AtributosCargaMasiva || [];
              this.listaCamposPersonalizados = this.obtenerListaCamposPersonalizados(this.camposPersonalizadosMasivos);
              this.largoValuesCamposPersonalizados = this.obtenerLargoMaximoCamposP(this.camposPersonalizadosMasivos);
            } else {
              this.camposPersonalizadosMasivos = resp.SolicitudDeCredito?.Stage1Response?.ContratoAtributosCargaMasiva || [];
              this.listaCamposPersonalizados = this.obtenerListaCamposPersonalizados(this.camposPersonalizadosMasivos);
              this.largoValuesCamposPersonalizados = this.obtenerLargoMaximoCamposP(this.camposPersonalizadosMasivos);
            }
            if(resp?.SolicitudDeCredito?.Stage2Response?.Advertencias?.length > 0){
              this.advertenciasMasivas = this.obtenerAdvertenciasByKey(resp?.SolicitudDeCredito?.Stage2Response?.Advertencias, 'SolicitudCredito');
              this.advertenciasAtributos = this.obtenerAdvertenciasByKey(resp?.SolicitudDeCredito?.Stage2Response?.Advertencias, 'Atributo');
              this.fileName = false;
            }
            this.secciones = this.obtenerSecciones()
            this.initForm();
            this.initFormCamposCSV();
            this.spinner.hide();
            this.consultaObtenerFlujo();

          }
        }
      }, (error: any) => {
        this.alertsService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error con el servicio');
        this.spinner.hide();
      }));
    } else {
      this.solicitudCreditoService.obtenerContrato(this.rut).subscribe(resp => {
        if (resp) {
          if(resp.SolicitudDeCredito?.Stage1Response?.errors?.hassError === true){
            const datosBasicos = resp.SolicitudDeCredito.DatosBasicosSolicitud;
            this.idTransaccion = datosBasicos.IDTransaccion;
            this.idTransaccionNueva.emit(this.idTransaccion);
            this.alertsService.error(resp.SolicitudDeCredito?.Stage1Response?.errors?.details?.message || 'Solicitud de Crédito - Ocurrio un error al obtener el servicio');
            this.spinner.hide();
          } else {
            const datosBasicos = resp.SolicitudDeCredito.DatosBasicosSolicitud;
            this.idTransaccion = datosBasicos.IDTransaccion;
            this.idTransaccionNueva.emit(this.idTransaccion);
            if(resp.SolicitudDeCredito?.Stage2Response?.Atributos){
              this.camposPersonalizadosTemp = resp.SolicitudDeCredito?.Stage2Response?.Atributos;
            } else {
              this.camposPersonalizadosTemp = resp.SolicitudDeCredito?.Stage1Response?.ContratoAtributos;
            }
            if(resp.SolicitudDeCredito?.Stage2Response?.AtributosCargaMasiva){
              this.camposPersonalizadosMasivos = resp.SolicitudDeCredito?.Stage2Response?.AtributosCargaMasiva || [];
              this.listaCamposPersonalizados = this.obtenerListaCamposPersonalizados(this.camposPersonalizadosMasivos);
              this.largoValuesCamposPersonalizados = this.obtenerLargoMaximoCamposP(this.camposPersonalizadosMasivos);
            } else {
              this.camposPersonalizadosMasivos = resp.SolicitudDeCredito?.Stage1Response?.ContratoAtributosCargaMasiva || [];
              this.listaCamposPersonalizados = this.obtenerListaCamposPersonalizados(this.camposPersonalizadosMasivos);
              this.largoValuesCamposPersonalizados = this.obtenerLargoMaximoCamposP(this.camposPersonalizadosMasivos);
            }
            this.secciones = this.obtenerSecciones()
            this.initForm();
            this.initFormCamposCSV();
            this.spinner.hide();
            this.consultaObtenerFlujo();
          }
        }
        
      }, error => {
        this.alertsService.error(error?.error?.SolicitudDeCredito?.Stage1Response?.errors?.details?.message || error?.error?.message || 'Solicitud de Crédito - Ocurrio un error con el servicio');
        this.spinner.hide();
      });
    }
  }

  consultaObtenerFlujo(): void {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      (resp?.cargaMasiva === true) ? (this.cargaMasiva = true) : (this.cargaMasiva = false);
      if(resp?.stages[1]?.completed){
        this.stageCompleted = true;
      } else {
        this.stageCompleted = false;
      }
      this.spinner.hide();
    }, (error: any) => {
      this.alertsService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar datos del flujo de credito');
      this.spinner.hide();
    }));
  }

  obtenerSecciones(): any[] {
    const seccionesFiltradas: any[] = [];

    this.camposPersonalizadosTemp.forEach(element => {
      if(element.creditRequestActive == true && element.enabled == true && !seccionesFiltradas.includes(element.section)){
        seccionesFiltradas.push(element.section)
      }
    });
    
   return seccionesFiltradas;
  }

  /**
   * @description
   * Se agregan los campos al formulario segun el tipo de solicitud
   */
  setControlsFromService() {
    this.camposPersonalizadosTemp.forEach(control => {
      if(control.creditRequestActive && control.enabled){
        if(control.formatAttribute === '0' && control.typeAttibute === 'Number' && this.objectKeys(control.allowedValues).length === 0){
          this.camposPersonalizadosForm.addControl(control.nameAttribute, new UntypedFormControl(control.value || control.defaultValue, [Validators.required, Validators.pattern("^[0-9]*$")]));
        } else if(this.objectKeys(control.allowedValues).length > 0){
          const key = Object.keys(control.allowedValues)[Object.values(control.allowedValues).indexOf(control.value)];
          if (key)
            this.camposPersonalizadosForm.addControl(control.nameAttribute, new UntypedFormControl(key || control.defaultValue, [Validators.required, Validators.pattern("^[0-9]*$")]));
          else
            this.camposPersonalizadosForm.addControl(control.nameAttribute, new UntypedFormControl(control.value || control.defaultValue, [Validators.required, Validators.pattern("^[0-9]*$")]));
        } else if(control.typeAttibute === 'Date'){
          const valor = control.value ? this.getDateToForm(control.value, control.formatAttribute) : (control.defaultValue ? this.getDateToForm(control.defaultValue, control.formatAttribute) : '');
          
          this.camposPersonalizadosForm.addControl(control.nameAttribute, new UntypedFormControl(valor, Validators.required));
        } else {
          this.camposPersonalizadosForm.addControl(control.nameAttribute, new UntypedFormControl(control.value || control.defaultValue, Validators.required));
        }
      }
    });
    this.fieldsReady = true;
    this.eventForm();
  }

  /*format = 
  'DD-MM-YYYY'
  'DD-MM-YY'
  'MM-DD-YY'
  'MM-DD-YYYY'
  'YYYY-MM-DD'*/
  getDateToForm(dateInput: string, format: string): string {

    if(!dateInput) {
      return dateInput;
    }

    if (format === 'DD-MM-YYYY') {
      const [day, month, year] = dateInput?.split("-");
      return `${year}-${month}-${day}`;
    } else if (format === 'DD-MM-YY') {
      const [day, month, year] = dateInput?.split("-");
      const fourDigitYear = "20" + year;
      return `${fourDigitYear}-${month}-${day}`;
    } else if (format === 'MM-DD-YY') {
      const [month, day, year] = dateInput?.split("-");
      const fourDigitYear = "20" + year;
      return `${fourDigitYear}-${month}-${day}`;
    } else if (format === 'MM-DD-YYYY') {
      const [month, day, year] = dateInput?.split("-");
      return `${year}-${month}-${day}`;
    } else if (format === 'YYYY-MM-DD') {
      const [year, month, day] = dateInput?.split("-");
      return `${year}-${month}-${day}`;
    } else {
      const date = new Date(dateInput);
      if (date && !isNaN(date.getTime()) && format) {
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        return `${date.getFullYear().toString()}-${month}-${day}`;
      }
      return dateInput;
    }
  }

  getDateByFormat(dateInput: string, format: string): string {
    /*format = 
    'DD-MM-YYYY'
    'DD-MM-YY'
    'MM-DD-YY'
    'MM-DD-YYYY'
    'YYYY-MM-DD'*/
    if (dateInput && format) {
      const [year, month, day] = dateInput?.split("-");
      if (format === 'DD-MM-YYYY') {
        return `${day}-${month}-${year}`;
      } else if (format === 'DD-MM-YY') {
        return `${day}-${month}-${year.substr(-2)}`;
      } else if (format === 'MM-DD-YY') {
        return `${month}-${day}-${year.substr(-2)}`;
      } else if (format === 'MM-DD-YYYY') {
        return `${month}-${day}-${year}`;
      } else if (format === 'YYYY-MM-DD') {
        return `${year}-${month}-${day}`;
      }
    }
    return dateInput;
  }

  /**
   * @description
   * Envia el formulario
   * @returns 
   */
  onSubmit(tipo: string) { // tipo = MANUAL o AUTOMATICA
    const form: any[] = [];
    let archivoAtributos: string = "";
    let camposCSV: string = "";
    if(tipo === 'MANUAL'){
      if (!this.camposPersonalizadosForm.valid) {
        this.solicitudCreditoService.validateAllFormFields(this.camposPersonalizadosForm);
        this.actualizaLayout();
        return;
      }
      const formTemp = this.camposPersonalizadosForm.getRawValue();
  
      Object.keys(formTemp).forEach((control: any) => {
        const item = this.camposPersonalizadosTemp
          .filter(e => e.nameAttribute === control)[0];
  
        if (item) {
          if(item.typeAttibute.toUpperCase() === 'DATE'){
            formTemp[control] = this.getDateByFormat(formTemp[control], item.formatAttribute);
          }
          form.push({
            nameAttribute: item.nameAttribute,
            value: formTemp[control]
          })
        }
      });
    } else {
      if(typeof(this.tempFile) === 'string' && this.tempFile?.length > 0){
        archivoAtributos = this.tempFile.toString();
      } else {
        return;
      }
    }

    if(typeof(this.tempFileCamposCSV) === 'string' && this.tempFileCamposCSV?.length > 0){
      camposCSV = this.tempFileCamposCSV.toString();
    }

    if(!this.archivoMasivoValido) {
      this.alertsService.error('Solicitud de Crédito - El tamaño maximo del archivo es de 3mb');
      return;
    }

    this.updateFieldsByService(tipo, form, archivoAtributos, camposCSV);
  }

  updateFieldsByService(tipo: string, form: any, archivoAtributos: string, camposCSV: string): void {
    this.spinner.show();
    this.solicitudCreditoService.updateCamposPersonalizados(form, this.rut, this.idTransaccion, archivoAtributos, camposCSV).subscribe(resp => {
      if(resp?.SolicitudDeCredito?.Stage2Response?.errors?.hassError){
        this.alertsService.error(resp?.SolicitudDeCredito?.Stage2Response?.errors?.details?.message || 'Solicitud de Crédito - Ocurrio un error con la respuesta del servicio');
        this.spinner.hide();
      } else {
        this.advertenciasAtributos = [];
        this.advertenciasMasivas = [];
        this.camposPersonalizadosTemp = [];
        this.camposPersonalizadosMasivos = [];
        this.listaCamposPersonalizados = {};
        this.largoValuesCamposPersonalizados = [];

        if(tipo === 'MANUAL'){
          this.fileName = false;
        } else {
          this.fileName = true;
        }
        
        if(resp?.SolicitudDeCredito?.Stage2Response?.Advertencias?.length > 0){
          this.advertenciasAtributos = this.obtenerAdvertenciasByKey(resp?.SolicitudDeCredito?.Stage2Response?.Advertencias, 'Atributo');
          this.advertenciasMasivas = this.obtenerAdvertenciasByKey(resp?.SolicitudDeCredito?.Stage2Response?.Advertencias, 'SolicitudCredito');
        }
        if(resp.SolicitudDeCredito?.Stage2Response?.Atributos){
          this.camposPersonalizadosTemp = resp.SolicitudDeCredito?.Stage2Response?.Atributos;
        }
        if(resp.SolicitudDeCredito?.Stage2Response?.AtributosCargaMasiva){
          this.camposPersonalizadosMasivos = resp.SolicitudDeCredito?.Stage2Response?.AtributosCargaMasiva || [];
          this.listaCamposPersonalizados = this.obtenerListaCamposPersonalizados(this.camposPersonalizadosMasivos);
          this.largoValuesCamposPersonalizados = this.obtenerLargoMaximoCamposP(this.camposPersonalizadosMasivos);
        }
        if(archivoAtributos.length > 0) {
          this.archivoAtributosCargado = true;
        }
        if(camposCSV.length > 0) {
          this.archivoMasivoCargado = true;
        }
        this.spinner.hide();
        this.consultaObtenerFlujo();
      }
    }, error => {
      const msg = (error?.error?.SolicitudDeCredito?.Stage2Response?.errors?.details?.message || error?.error?.message || ('Solicitud de Crédito - Ocurrio un error al guardar los cambios'));
      this.alertsService.error(msg);
      this.spinner.hide();
    })
  }

  nextStep() {
    this.solicitudCreditoService.setStep('next');
  }

  /**
   * @description
   * Activa la escucha de los cambios del formulario
   */
  eventForm() {
    const controls = this.camposPersonalizadosForm.controls;
    this.subscriptionForm = this.camposPersonalizadosForm.valueChanges.subscribe(()=>{
      this.actualizaLayout();
    });
  }

  actualizaLayout(){
    if(this.masonry){
      this.masonry.reloadItems();
      this.masonry.layout();
    }
  }

  obtenerAdvertenciasByKey(array: any[], key: string): any[] {
    return array.filter(e => e?.Seccion === key);
  }

  showErrors():void {
    const dialogRef = this.dialog.open(ListErrorsDialogWorkflowComponent, {
      data: {
        title: 'Solicitud de Crédito',
        errors: this.advertenciasAtributos
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp.confirm) {
        this.tempFile = resp.file;
      }
    });

    /*const resp = this.simpleModalService.addModal(ListErrorsDialogWorkflowComponent, {
       title: 'Solicitud de Crédito',
       errors: this.advertenciasAtributos
     }).subscribe((resp) => {
       if (resp.confirm) {
         this.tempFile = resp.file;
       }
     });*/
   }

  changeStep() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      if(resp?.stages[1]?.completed){
        this.solicitudCreditoService.setStep('next');
      } else {
        this.spinner.hide();
        this.alertsService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
      }
    }, (error: any) => {
      this.alertsService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar datos del flujo de credito');
      this.spinner.hide();
    }));
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    if(file.size <= 10000000){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.tempFile = reader?.result?.toString().split(',')[1];
      };
      this.archivoAtributosCargado = false;
    } else {
      this.alertsService.error('Solicitud de Crédito - El tamaño maximo del archivo debe ser de 10mb');
      this.tempFile = {};
    }
  }

  initFormArchivo(): void {
    this.formularioArchivo = this.formBuilder.group({
      file: [null]
    })
  }

  obtenerDetalle(nameAttribute: string): string {
    let valor = ''
    if(nameAttribute){
      const elemento = this.advertenciasMasivas.find(e => e?.Seccion === 'SolicitudCredito' && e?.Campo === nameAttribute);
      if(elemento) {
        valor = elemento?.Detalle || '';
      }
    }
    return valor;
  }

  initFormCamposCSV(): void {
    this.camposCSVForm = this.formBuilder.group({
      file: [null]
    })
  }

  handleUploadCamposCSV(event: any) {
    const file = event.target.files[0];
    if(file.size <= 3000000){
      this.archivoMasivoValido = true;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.tempFileCamposCSV = reader?.result?.toString().split(',')[1];
      };
      this.archivoMasivoCargado = false;
    } else {
      this.alertsService.error('Solicitud de Crédito - El tamaño maximo del archivo es de 3mb');
      this.tempFileCamposCSV = {};
      this.archivoMasivoValido = false;
    }
  }

  limpiaArchivoMasivo(): void {
    this.tempFileCamposCSV = {};
    this.archivoMasivoCargado = false;
    this.camposCSVForm.reset();
    this.archivoMasivoValido = true;
  }

  obtenerListaCamposPersonalizados(array: any[]): any {
    const newObj = array
      .filter(item => item && item?.enabled)
      .reduce((result, {nameAttribute, values, enabled}) => {
        result[nameAttribute] = {values, enabled};
        return result;
    }, {});
    return newObj || {};
  }

  obtenerLargoMaximoCamposP(array: any[]): any[] {
    const validValues = array
      .filter(item => item && item?.values && item?.enabled)
      .map(item => item?.values?.length);

    const maxLength = Math.max(...validValues);
    const numberList = [];

    for (let i = 0; i < maxLength; i++) {
      numberList.push(i);
    }

    return numberList || [];
  }

}