import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-anexo',
  templateUrl: './anexo.component.html',
  styleUrls: ['./anexo.component.scss']
})
export class AnexoComponent implements OnInit {
  @Input() informacionCliente: any;
  public title1: string = 'Facturación/Saldo';
  public colunmTitles1: string[] = ['Fact', 'Saldo'];
  public categoria1: any[] = [];
  public serie1: any[] = [];

  public yAxix1 = [{
    labels: {
      format: '{value}',
      style: {
        color: '#000'
      }
    },
    title: {
      text: 'Miles',
      style: {
        color: '#000'
      }
    }
  }, {
    title: {
      text: 'Miles',
      style: {
        color: '#000'
      }
    },
    labels: {
      format: '{value}',
      style: {
        color: '#000'
      }
    },
    opposite: true
  }];

  public plotOptions1 = {
    series: {
      marker: {
        enabled: false
      }
    }
  };

  public title2: string = 'Facturación y Pagos';
  public colunmTitles2: string[] = ['Facturacion', 'Pago', 'Dias mora'];
  public categoria2: any[] = [{
    categories: ['jul', 'ago', 'sep', 'oct', 'nov', 'dic',
      'ene', 'feb', 'mar', 'abr', 'may', 'jun'],
    crosshair: true
  }];

  public serie2 = [{
    type: 'column',
    name: this.colunmTitles2[0],
    color: '#2e6703',
    data: [10, 5, 15, 30, 40, 34, 23, 12, 32, 10, 11, 21]
  },
  {
    type: 'column',
    name: this.colunmTitles2[1],
    color: '#eb7d30',
    data: [23, 12, 32, 10, 11, 21, 10, 5, 15, 30, 40, 34]
  },
  {
    type: 'spline',
    name: this.colunmTitles2[2],
    yAxis: 1,
    color: '#7f7f7f',
    data: [-4, -27, -20, -10, -9, 2, 6, 3, 0, 8, 1, 5],
  },
  ];

  public yAxix2 = [{
    labels: {
      format: '{value}',
      style: {
        color: '#000'
      }
    },
    title: {
      text: 'Miles',
      style: {
        color: '#000'
      }
    }
  }, {
    title: {
      text: 'Dias',
      style: {
        color: '#000'
      }
    },
    labels: {
      format: '{value}',
      style: {
        color: '#000'
      }
    },
    opposite: true
  }];


  public title3: string = 'Utilizacion de la linea';
  public colunmTitles3: string[] = ['Utilizacion', 'LoC'];
  public categoria3: any[] = [{
    categories: ['jul', 'ago', 'sep', 'oct', 'nov', 'dic',
      'ene', 'feb', 'mar', 'abr', 'may', 'jun'],
    crosshair: true
  }];

  public serie3 = [{
    type: 'column',
    name: this.colunmTitles3[0],
    color: '#7f7f7f',
    data: [5000000, 8000000, 15000000, 20000000, 10000000, 27000000, 23000000, 12000000, 2000000, 10000000, 11000000, 21000000]
  },
  {
    type: 'spline',
    name: this.colunmTitles3[1],
    yAxis: 1,
    color: '#ec8944',
    data: [400000, 2000000, 200000, 100000, 4000000, 2000000, 5000000, 300000, 100000, 2000000, 100000, 500000],
  },
  ];

  public yAxix3 = [{
    labels: {
      format: '{value}',
      style: {
        color: '#000'
      }
    },
    title: {
      text: '',
      style: {
        color: '#000'
      }
    }
  }, {
    title: {
      text: '',
      style: {
        color: '#000'
      }
    },
    labels: {
      format: '',
      style: {
        color: '#fff'
      }
    },
    opposite: true
  }];

  constructor() { }

  ngOnInit(): void {
    this.setFacturacionSaldo();
  }

  setFacturacionSaldo(): void {

    let objetoFlujoFuturoReduce: any[] = [];
    const objTemp: any[] = [];
    const objTemp2: any[] = [];
    const objResponse = this.obtenerValorCamposPersonalizadosAutomatic('item');
    const keyValue = ['Saldo_Acum', 'Fact'];
    const keysValues = ['Importe ML', 'Periodo'];
    const objImporte = this.convertirMiles(this.obtenerValorCamposPersonalizadosAutomatic(keysValues[0]));
    const objPeriodo = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[1]);
    const keysObjs = [objImporte, objPeriodo];

    if (objResponse.length > 0) {
      //Obtener posicion de la key Saldo Actual

      let idx = objResponse.indexOf(keyValue[0]);
      while (idx != -1) {
        objTemp.push({ position: idx });
        idx = objResponse.indexOf(keyValue[0], idx + 1);
      }

      let idx2 = objResponse.indexOf(keyValue[1]);
      while (idx2 != -1) {
        objTemp2.push({ position: idx2 });
        idx2 = objResponse.indexOf(keyValue[1], idx2 + 1);
      }

      //Obtener valores de la key Saldo Acumulado
      if (objTemp.length > 0) {
        keysValues.forEach((e: any, i: number) => {
          objTemp.forEach((f: any) => {
            const name = e === 'Importe ML' ? 'Saldo_Acum' : e;
            f[name.replace(' ', '')] = keysObjs[i][f.position] || '';
          });
        });
      }

      if (objTemp2.length > 0) {
        keysValues.forEach((e: any, i: number) => {
          objTemp2.forEach((f: any) => {
            const name = e === 'Importe ML' ? 'Fact' : e;
            f[name.replace(' ', '')] = keysObjs[i][f.position] || '';
          });
        });
      }

      objetoFlujoFuturoReduce = Object.values(objTemp.concat(objTemp2).reduce((acc, curr) => {
        acc[curr.Periodo] = acc[curr.Periodo] || {
          Periodo: curr.Periodo,
          Fact: 0,
          Saldo_Acum: 0,
        };

        if (acc[curr.Periodo]) {
          acc[curr.Periodo].Fact += +Number(curr.Fact) || 0;
          acc[curr.Periodo].Saldo_Acum += +Number(curr.Saldo_Acum) || 0;
        } else {
          acc[curr.Periodo].Fact = 0;
          acc[curr.Periodo].Saldo_Acum = 0;
          acc[curr.Periodo].Fact += +Number(curr.Fact);
          acc[curr.Periodo].Saldo_Acum += +Number(curr.Saldo_Acum);
        }

        return acc;
      }, {}));
    }
    this.setGrafica1(objetoFlujoFuturoReduce);
  }

  obtenerValorCamposPersonalizadosAutomatic(key: string): any[] {
    if (this.informacionCliente?.SolicitudDeCredito?.Reportes?.camposPersonalizadosAutomatic) {
      if (this.informacionCliente?.SolicitudDeCredito?.Reportes?.camposPersonalizadosAutomatic?.SolicitudCredito?.length > 0) {
        return this.informacionCliente?.SolicitudDeCredito?.Reportes?.camposPersonalizadosAutomatic?.SolicitudCredito
          .find((e: any) => e.nameAttribute === key)?.values || [];
      }
      return [];
    }
    return [];
  }

  convertirMiles(obj: number[]): number[] {
    if (obj.length > 0) {
      return obj.map((e: number) => {
        return e / 1000;
      });
    }
    return [];
  }

  setGrafica1(obj: any) {
    const objTemp: any = [];
    const valuesKeys = ['Periodo', 'Fact', 'Saldo_Acum'];
    valuesKeys.forEach((e: any) => {
      objTemp[e] = obj.map((f: any) => {
        return e !== 'Periodo'? Number(f[e].toFixed(0)) : f[e];
      });
    });

    this.categoria1.push({
      categories: objTemp.Periodo,
      crosshair: true
    });

    this.serie1 = [{
      type: 'column',
      name: this.colunmTitles1[0],
      color: '#4271c3',
      data: objTemp.Fact
    },
    {
      type: 'spline',
      name: this.colunmTitles1[1],
      yAxis: 1,
      color: '#ec8944',
      data: objTemp.Saldo_Acum,
    },
    ]
  }


}
