import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BackendAuthService } from './backend-auth.service';
import { SessionService } from './session.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ESystemAccess } from 'src/app/enum/EAccess';

interface ICreateUser {
  username: string;
  firstName: string;
  givenName: string;
  phoneNumber: string;
  systemUserAccessList: string[];
}

export interface IUpdateOrganizacionAccess {
  newOrganization: string;
  inputServiceConfigurations: AvailableAdminAccessUpdate[];
  description?: string;
  rutOrganization?: string;
  orgLogoImage?: string;
  displayName?: string;
}

export interface AvailableAdminAccessUpdate {
  accessID?: string;
  name: string;
  active: boolean;
}

export interface availableAccessResponse {
  accessID: string;
  accessName: string;
}

export interface IGetAccessForUserResponse {
  userID: string;
  userSub: string;
  organization: string;
  profilesList: string[];
  availableAccess: string[];
}

export interface IGetUsersForGroupResponse {
  userName: string;
  name: string;
  givenName: string;
  email: string;
  userSub: string;
  phoneNumber: string
  creationDate: string;
  lastModifiedDate: string;
  organization: string;
  profilesList: string[];
  availableAccess: string[];
}

export interface IGetOrganizationsList {
  id_organizacion: string;
  available_admin_access: AvailableAdminAccess[];
  update_date: Date;
  fecha_formatted?: string;
  description: string;
}

export interface IGetOrganizationExistsResponse {
  existOrgID: string;
}

export interface AvailableAdminAccess {
  accessID?: string;
  name: string;
  active: boolean;
  type: string;
  display: string;
  group: string;
}

export interface IAccesOrganizacion {
  availableAdminAccess: AvailableAdminAccess[];
}

enum EUsuarioServiceAcctions {
  CREATE_USER = "createUser",
  UPDATE_USER_BASIC_INFORMATION = "updateUserBasicInformation",
  DELETE_USER = "deleteUser",
  GET_ACCESS_USER = "getAccessForUser",
  UPDATE_USER_ACCESS = "updateUserAccess",
}

enum EUOrganizacionServiceAcctions {
  CREATE_ORGANIZATION = "createOrganization",
  GET_AVAILABLE_ACCESS_SYSTEM = "getMasterAccessList",
  SEARCH_ORGANIZATION = "searchOrganization",
  UPDATE_ORGANIZATION = "configureOrganizationAccess",
  GET_AVAILABLE_ORGANIZATIONS = "getOrganizationsConfigurations"
}

@Injectable({
  providedIn: 'root'
})
export class OrganizacionesService {
  private endpoint: string = environment.backend.organizations;
  private userPoolID: string = environment.cognitoConfig.userPoolId;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService,
    private _backendAuthService: BackendAuthService) { }

  /**
   * Crea un nuevo usuario en el sistema (no administradores, solo usuarios básicos que no pueden crear otros usuarios)
   * @param {string} user
   * @returns 
   */
  public createUser(organizacionAccess: IUpdateOrganizacionAccess): Observable<any> {
    const payload: any = {
      "newOrganization": organizacionAccess.newOrganization,
      "userPoolID": this.userPoolID,
      "organization": this._sessionService.getOrganization(),
      "inputServiceConfigurations": organizacionAccess.inputServiceConfigurations,
      "userName": this._sessionService.getUserInfo().email,
      "operation": EUOrganizacionServiceAcctions.CREATE_ORGANIZATION,
      "description": organizacionAccess.description,
      "rutOrganization": organizacionAccess.rutOrganization,
      "displayName": organizacionAccess.displayName
    }

    if(organizacionAccess.orgLogoImage) {
      payload["orgLogoImage"] = organizacionAccess.orgLogoImage;
    }

    return this.http.post(this.endpoint, payload);
  }

  /**
   * Obtiene accesos de una organizacion
   */
  public getAccessForOrganization(): Observable<AvailableAdminAccess[]> {

    const payload = {
      "userName": this._sessionService.getUserInfo().email,
      "organization": this._sessionService.getOrganization(),
      "operation": EUOrganizacionServiceAcctions.GET_AVAILABLE_ACCESS_SYSTEM
    }
    return this.http.post<IAccesOrganizacion>(this.endpoint, payload)
    .pipe(map((response: IAccesOrganizacion) => response.availableAdminAccess));
  }

  /**
   * @description
   * Actualiza los acceso de una organizacion
   */
  public updateOrganizacionAccess(organizacionAccess: IUpdateOrganizacionAccess): Observable<AvailableAdminAccess> {

    const payload = {
      "newOrganization": organizacionAccess.newOrganization,
      "organization": this._sessionService.getOrganization(),
      "inputServiceConfigurations": organizacionAccess.inputServiceConfigurations,
      "operation": EUOrganizacionServiceAcctions.UPDATE_ORGANIZATION,
      "userName": this._sessionService.getUserInfo().email
    }

    return this.http.post<AvailableAdminAccess>(this.endpoint, payload);
  }

  /**
   * @description
   * Obtiene todos las organizaciones del sistema
   */
  public getOrganizations(): Observable<IGetOrganizationsList[]> {

    const payload = {
      "userPoolID": this.userPoolID,
      "organization": this._sessionService.getOrganization(),
      "operation": EUOrganizacionServiceAcctions.GET_AVAILABLE_ORGANIZATIONS,
      "userName":  this._sessionService.getUserInfo().email
    };

    return this.http.post<IGetOrganizationsList[]>(this.endpoint, payload);
  }

  /**
   * @description
   * Verifica si la organizacion existe
   */
  public getsearchOrganizacionExists(organizacion: string): Observable<IGetOrganizationExistsResponse> {

    const payload = {
      "userPoolID": this.userPoolID,
      "newOrganization": organizacion,
      "organization": this._sessionService.getOrganization(),
      "operation": EUOrganizacionServiceAcctions.SEARCH_ORGANIZATION,
      "userName":  this._sessionService.getUserInfo().email
    }; 

    return this.http.post<IGetOrganizationExistsResponse>(this.endpoint, payload);
  }

}
