 
 
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="executionModalLabel">Detalle Proceso {{processId}}</h5>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        </div>
        <div class="modal-body text-center">
          <div class="mb-3">
            <span class="fw-bold">Ejecutadas Correctamente: </span> 
            {{ correctExecutions }} 
            <span class="text-success ms-2">&#10004;</span> <!-- Checkmark Icon -->
          </div>
          <div class="mb-3">
            <span class="fw-bold">Ejecutadas Erroneamente: </span> 
              {{ errorExecutions }}
            <span class="text-danger ms-2">&#10060;</span> <!-- Cross Icon -->
          </div>
          <button *ngIf="downloadFiles.length > 0 && !showDownloads" class="btn download-btn btn-success" (click)="downloadAll()">Descargar Detalle</button>
          <div *ngIf="showDownloads">
            <p *ngFor="let file of downloadFiles; index as i" class="btn btn-link" (click)="downloadFile(file)">Archivo {{i + 1}}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="onClose()">Cerrar</button>
        </div>
      </div>

  