import { Component, Input, OnInit } from '@angular/core';
import { Reporte } from 'src/app/models/formulario-f22/formulario-f22.model';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { RUT } from 'src/app/shared/utils/rut';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-reporte-renta-view',
  templateUrl: './reporte-renta-view.component.html',
  styleUrls: ['./reporte-renta-view.component.scss']
})
export class ReporteRentaViewComponent implements OnInit {
  @Input() reporteFullContent!: any | null;
  @Input() rut!: string | null;
  reporte: any;

  constructor(
    private utilsService: UtilsService
  ) { }

  public headerInformacionRenta: any[] = [
    {text: 'Periodo', value: 'año'},
    {text: 'Folio', value: 'folio'},
    {text: 'Fecha', value: 'fecha'},
    {text: 'Nombre o Razón Social', value: 'nombre'},
    {text: 'Numero de Meses', value: 'numeroMeses'},
    {text: 'Actividad Económica', value: 'actividadEconomica'},
    {text: 'Cod. Actividad Económica', value: 'codActividad'},
  ];

  public headerRentaFija: any[] = [
    {text: 'Sueldos, Pensiones,etc.', value: 'sueldosPensiones'},
    {text: 'Crédito por Impto unico de 2ª Categoria', value: 'creditoOmpuestoUnicoSegundaCategoria'},
    {text: 'Total', value: 'totalRentaFija'}
  ];

  public headerRentaVariable: any[] = [
    {text: 'Honorarios y Rem . Directores S.A', value: 'honorariosRemuneracionesDirectoresSa'}
  ];

  public headerRentaParticipacion: any[] = [
    {text: 'Retiros', value: 'retiros'},
    {text: 'Dividendos distribuidos por S.A y C.P.A', value: 'dividendosDistribuidosSaCpa'},
    {text: 'Otras Rentas por Participación en Sociedades', value: 'otrasRentasporParticipacionenSociedades'},
    {text: 'Gastos Rechazados , Art 33 Nª 1', value: 'gastosRechazadosArticulo33'},
    {text: 'Impto. Primera categoria pagado el año anterior', value: 'impuestoPrimeraCategoriaPagadoAnioAnterior'},
    {text: 'Impuesto territorial pagado el año anterior', value: 'impuestoTerritorialPagadoAnioAnterior'},
    {text: 'Total', value: 'totalRentasParticipacionSociedades'}
  ];

  public headerIngresosPersibidos: any[] = [
    {text: 'Rentas por Capitales Mobiliarios', value: 'rentaCapitalesMobiliarios'},
    {text: 'Perdida en Operaciones de capitales mobiliarios', value: 'perdidaOperacionesCapitalesMobiliarios'},
    {text: 'Rentas determinadas según contabilidad simplificada, planillas, contratos y otras rentas', value: 'rentasSegunContabilidadSimplificada'},
    {text: 'Rentas Exentas', value: 'rentasExentas'},
    {text: 'Cotizaciones previsionales correspondientes al empresario o socio', value: 'cotizacionesPrevisionalesEmpresarioSocio'},
    {text: 'Devolución de Impuestos', value: 'devolucionImpuestos'},
    {text: 'Impto. Pagado', value: 'impuestoPagado'},
    {text: 'Total', value: 'totalOtrosIngresosPercibidos'}
  ];

  public headerTotalRentaLiq: any[] = [
    {text: 'Total Renta Liquida Anual', value: 'totalRentaLiquidaAnual'}
  ];

  public headerTotalRenta: any[] = [
    {text: 'Renta Fija Mensual', value: 'rentaFijaMensual', porc: 'rentaFijaPorcentualSobreRentaLiquida'},
    {text: 'Renta Variable Mensual', value: 'rentaVariableMensual', porc: 'rentaVariableSobreRentaLiquidaMensual'},
    {text: 'Total Renta liquida mensual', value: 'totalRentaLiquidaMensual', porc: 'totalRentaLiquidaSobreRentaLiquidaMensual'}
  ];

  ngOnInit(): void { 
    this.reporte = this.reporteFullContent
    this.utilsService.changeSpanExtra(' > Renta')
  }

  //Funcion para obtener letra a partir de numero
  getColName(n: number): string {
    const ordA = 'a'.charCodeAt(0);
    const ordZ = 'z'.charCodeAt(0);
    const len = ordZ - ordA + 1;
  
    let s = "";
    while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s;
  }

  getNumberFormatted(dato: number): number{
    return Number(Number(dato).toFixed(2))
  }

  getNumberFormattedInt(dato: number): number{
    return Number(Number(dato).toFixed())
  }


  descargarExcel(): void{
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    // Cabecera
    const ws = XLSX.utils.json_to_sheet([
      { A: "Rut" }, 
      { A: "Periodo" }, 
      { A: "Folio" }, 
      { A: "Fecha" }, 
      { A: "Nombre o Razón Social" }, 
      { A: "Numero Meses" }, 
      { A: "Actividad Económica" }, 
      { A: "Cod. Actividad Económica" }, 
      { A: "" }, 
      { A: "Renta Fija" }, 
      { A: "Sueldos y Salarios", B: "" }, //, C: "Monto" 
      { A: "", B: "Sueldos, Pensiones,etc." }, 
      { A: "", B: "Crédito por Impto unico de 2ª Categoria" }, 
      { A: "Total" },
      { A: "" },
      { A: "Renta Variable" },
      { A: "Honorarios" },
      { A: "", B: "Honorarios y Rem . Directores S.A" },
      { A: ""},
      { A: "Rentas por participación en sociedades"},
      { A: "", B: "Retiros" }, 
      { A: "", B: "Dividendos distribuidos por S.A y C.P.A" }, 
      { A: "", B: "Otras Rentas por Participación en Sociedades" }, 
      { A: "", B: "Gastos Rechazados , Art 33 Nª 1" }, 
      { A: "", B: "Impto. Primera categoria pagado el año anterior" }, 
      { A: "", B: "Impuesto territorial pagado el año anterior" }, 
      { A: "Total" },
      { A: "" },
      { A: "Otros Ingresos Persibidos" },
      { A: "", B: "Rentas por Capitales Mobiliarios" }, 
      { A: "", B: "Perdida en Operaciones de capitales mobiliarios" }, 
      { A: "", B: "Rentas determinadas según contabilidad simplificada, planillas, contratos y otras rentas" }, 
      { A: "", B: "Rentas Exentas" }, 
      { A: "", B: "Cotizaciones previsionales correspondientes al empresario o socio" }, 
      { A: "", B: "Devolución de Impuestos" }, 
      { A: "", B: "Impto. Pagado" },
      { A: "Total" },
      { A: "" },
      { A: "", B: "Total Renta Liquida Anual" }, 
      { A: "" },
      { A: "", B: "" }, //, C: "% Sobre Renta Liquida Mensual" 
      { A: "", B: "Renta Fija Mensual" }, 
      { A: "", B: "Renta Variable Mensual" }, 
      { A: "", B: "Total Renta liquida mensual" }      
    ], {header: ["A", "B"], skipHeader: true});

    let numeroLetra: number = 1;
    
    this.reporte.forEach((periodo: Reporte) => {
      numeroLetra++;
      const primeraLetra = this.getColName(numeroLetra).toUpperCase();
      const segundaLetra = this.getColName(numeroLetra + 1).toUpperCase();
      const content: any = [];

      content.push({ [primeraLetra]: typeof(this.rut) === 'string' ? RUT.applyBackendFormat(this.rut) : this.rut , [segundaLetra]: '' });

      this.headerInformacionRenta.forEach(element => {
        content.push({ [primeraLetra]: periodo.calculadoRenta[element.value], [segundaLetra]: '' });
      });

      content.push({ [primeraLetra]: '', [segundaLetra]: '' });
      content.push({ [primeraLetra]: '', [segundaLetra]: '' });
      content.push({ [primeraLetra]: 'Monto', [segundaLetra]: '' });

      this.headerRentaFija.forEach(element => {
        content.push({ [primeraLetra]: this.getNumberFormattedInt(periodo.calculadoRenta[element.value]), [segundaLetra]: '' });
      });

      content.push({ [primeraLetra]: '', [segundaLetra]: '' });
      content.push({ [primeraLetra]: '', [segundaLetra]: '' });
      content.push({ [primeraLetra]: '', [segundaLetra]: '' });

      this.headerRentaVariable.forEach(element => {
        content.push({ [primeraLetra]: this.getNumberFormattedInt(periodo.calculadoRenta[element.value]), [segundaLetra]: '' });
      });

      content.push({ [primeraLetra]: '', [segundaLetra]: '' });
      content.push({ [primeraLetra]: '', [segundaLetra]: '' });

      this.headerRentaParticipacion.forEach(element => {
        content.push({ [primeraLetra]: this.getNumberFormattedInt(periodo.calculadoRenta[element.value]), [segundaLetra]: '' });
      });

      content.push({ [primeraLetra]: '', [segundaLetra]: '' });
      content.push({ [primeraLetra]: '', [segundaLetra]: '' });

      this.headerIngresosPersibidos.forEach(element => {
        content.push({ [primeraLetra]: this.getNumberFormattedInt(periodo.calculadoRenta[element.value]), [segundaLetra]: '' });
      });

      content.push({ [primeraLetra]: '', [segundaLetra]: '' });

      this.headerTotalRentaLiq.forEach(element => {
        content.push({ [primeraLetra]: this.getNumberFormattedInt(periodo.calculadoRenta[element.value]), [segundaLetra]: '' });
      });

      content.push({ [primeraLetra]: '', [segundaLetra]: '' });
      content.push({ [primeraLetra]: 'Monto', [segundaLetra]: '%' });

      this.headerTotalRenta.forEach(element => {
        content.push({ [primeraLetra]: this.getNumberFormattedInt(periodo.calculadoRenta[element.value]), [segundaLetra]: this.getNumberFormattedInt(periodo.calculadoRenta[element.porc] * 100) });
      });


      
      XLSX.utils.sheet_add_json(ws, content, {header: [primeraLetra, segundaLetra], skipHeader: true, origin: primeraLetra + "1" });
            
      //Formato de numero con separador de miles
      const range = { s: {r:10, c:numeroLetra}, e: {r:45, c:numeroLetra} };
      for(let R = range.s.r; R <= range.e.r; ++R) {
        for(let C = range.s.c; C <= range.e.c; ++C) {
          const cell_address = {c:C, r:R};
          const cell_ref = XLSX.utils.encode_cell(cell_address);
          if(ws[cell_ref]){
            ws[cell_ref].z = '#,##0_-;-#,##0_-;0;General'
          }
        }
      }
      
      numeroLetra++;

    })

    //Combinacion de celdas para titulo
    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } },
      { s: { r: 3, c: 0 }, e: { r: 3, c: 1 } },
      { s: { r: 4, c: 0 }, e: { r: 4, c: 1 } },
      { s: { r: 5, c: 0 }, e: { r: 5, c: 1 } },
      { s: { r: 6, c: 0 }, e: { r: 6, c: 1 } },
      { s: { r: 7, c: 0 }, e: { r: 7, c: 1 } },
      { s: { r: 9, c: 0 }, e: { r: 9, c: 1 } },
      { s: { r: 10, c: 0 }, e: { r: 10, c: 1 } },
      { s: { r: 15, c: 0 }, e: { r: 15, c: 1 } },
      { s: { r: 16, c: 0 }, e: { r: 16, c: 1 } },
      { s: { r: 19, c: 0 }, e: { r: 19, c: 1 } },
      { s: { r: 26, c: 0 }, e: { r: 26, c: 1 } },
      { s: { r: 28, c: 0 }, e: { r: 28, c: 1 } },
      { s: { r: 36, c: 0 }, e: { r: 36, c: 1 } }
    ];
    ws["!merges"] = merge;

    //Ancho de celdas
    const wscols = [
      { width: 20 },
      { width: 42 },
      { width: 25 },
      { width: 10 },
      { width: 25 },
      { width: 10 },
      { width: 25 },
      { width: 10 },
    ];

    ws["!cols"] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, "Reporte");

    const today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;  

    XLSX.writeFile(wb, "Renta_" + this.rut + "_" + today.toString()  + ".xls");

  }

}
