import { Component, Input, OnInit } from '@angular/core';
//import { SimpleModalService } from 'ngx-simple-modal';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-linea-credito',
  templateUrl: './linea-credito.component.html',
  styleUrls: ['./linea-credito.component.scss']
})
export class LineaCreditoComponent implements OnInit {

  @Input() informe: any;
  @Input() tipoSolicitud: string = '';
  @Input() statusTX: string = '';
  @Input() montoAprobado: number = 0;
  public data: any[] = [];
  public header: string[] = ['nTickets', 'NombreORazonSocial', 'rut'];
  public lcSolicitada: number = 0;
  public totalRow: number = 0;
  public addRowSum: any[] = [];
  public objetoLineaCredito: any[] = [];
  public objetoAcuerdo30: number = 0;
  public objetoLC: any[] = [];
  public showModal: boolean = false;

  constructor(
    private solicitudCreditoService: SolicitudCreditoService,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.generarObjetoLineaCredito();
  }

  obtenerValorCamposPersonalizados(key: string) {
    return this.solicitudCreditoService.valorCampoPersonalizado(this.informe, key);
  }

  obtenerInformacionBasica(key: string): string {
    return this.solicitudCreditoService.getNested(this.informe.SolicitudDeCredito, 'Reportes', 'basicReport', 'InformacionBasica', key) || '';
  }

  setNumber(num: string): number {
    return Number(num);
  }

  setAddRow(value: any, item: any, key: string): void {
    const temp = value.replace(/\./g, '');  
    const valueKey = Number(temp) || 0;
    item[key] = valueKey,
      this.solicitudCreditoService.ResumenObjSubject.next({ data: { lineaCredito: this.objetoLineaCredito }, origin: 'lineaCredito' });
  }

  setRowLineaAprobada(value: any, item: any, key: string): void {
    const temp = value.replace(/\./g, '');    
    const valueKey = Number(temp) || 0;   
         
    item[key] = ((valueKey * 100) / this.montoAprobado).toFixed(2);
    
    this.solicitudCreditoService.ResumenObjSubject.next({ data: { lineaCredito: this.objetoLineaCredito }, origin: 'lineaCredito' });
  }

  totalAcc(): number {
    return this.objetoLineaCredito.reduce((acc, cur) => acc + cur.valueAcc, 0);
  }

  findRowSum(name: string): number {
    if (this.addRowSum.length > 0) {
      const resp = this.addRowSum.find((e: any) => e.name === name)?.value || 0;
      return resp;
    }
    return 0;
  }

  obtenerValorRulesEngine(key: string): string {
    if (this.informe?.SolicitudDeCredito?.Reportes?.['rulesEngine-reporte']?.Reporte) {
      if (Object.keys(this.informe?.SolicitudDeCredito?.Reportes?.['rulesEngine-reporte']?.Reporte).length > 0) {
        return this.informe?.SolicitudDeCredito?.Reportes?.['rulesEngine-reporte']?.Reporte
          .find((e: any) => e.name === key)?.value?.trim() || '';
      }
      return '';
    }
    return '';
  }

  obtenerValorCamposPersonalizadosAutomatic(key: string): any[] {
    if (this.informe?.SolicitudDeCredito?.Reportes?.camposPersonalizadosAutomatic) {
      if (this.informe?.SolicitudDeCredito?.Reportes?.camposPersonalizadosAutomatic?.SolicitudCredito?.length > 0) {
        return this.informe?.SolicitudDeCredito?.Reportes?.camposPersonalizadosAutomatic?.SolicitudCredito
          .find((e: any) => e.nameAttribute === key)?.values || [];
      }
      return [];
    }
    return [];
  }

  findValorCamposPersonalizadosAutomatic(key: string, key2: string, keyValue: string): any[] {
    const result: string[] = [];
    const objResponse = this.obtenerValorCamposPersonalizadosAutomatic(key);
    const objResponse2 = this.obtenerValorCamposPersonalizadosAutomatic(key2);

    if (objResponse.length > 0) {
      //Obtener posicion de la key
      const objTemp = [];
      var idx = objResponse.indexOf(keyValue);
      while (idx != -1) {
        objTemp.push(idx);
        idx = objResponse.indexOf(keyValue, idx + 1);
      }
      //Obtener valores de la key
      if (objTemp.length > 0 && objResponse2.length > 0) {
        //encontrar valor de la posicion en el array y evitar duplicados
        objTemp.forEach(e => {
          if (objResponse2[e] && (result.indexOf(objResponse2[e]) === -1 && objResponse2[e] !== '')) {
            result.push(objResponse2[e]);
          }
        });
      }
      return result;
    }
    return result;
  }

  async generarObjetoLineaCredito(): Promise<void> {
    await this.obtenerLc();
    await this.otenerAcuerdo30();
    this.obtenerLineaCreditoGeneral();
  }

  async otenerAcuerdo30(): Promise<void> {
    let objetoAcuerdo30Reduce: any[] = [];
    const resultAcuerdo30: any[] = [];
    let lastValue = 0;
    const objResponse = this.obtenerValorCamposPersonalizadosAutomatic('item');
    const keyValue = 'Saldo_Actual';
    const keysValues = ['C_Pago', 'ACC'];
    const objCme = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[0]);
    const objAcc = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[1]);
    const keysObjs = [objCme, objAcc];

    if (objResponse.length > 0) {
      //Obtener posicion de la key by key C_Pago
      let idx = objResponse.indexOf(keyValue);
      while (idx != -1) {
        resultAcuerdo30.push({ position: idx });
        idx = objResponse.indexOf(keyValue, idx + 1);
      }
      //Obtener valores de la key
      if (resultAcuerdo30.length > 0) {
        keysValues.forEach((e: any, i: number) => {
          resultAcuerdo30.forEach((f: any) => {
            f[e] = keysObjs[i][f.position] || '';
          });
        });
      }

      if (resultAcuerdo30.length > 0) {
        objetoAcuerdo30Reduce = Object.values(resultAcuerdo30.reduce((acc, curr) => {
          acc[curr.ACC] = acc[curr.ACC] || {
            ACC: curr.ACC,
            C_Pago: curr.C_Pago
          };
          acc[curr.ACC].ImporteML += +curr.ImporteML;
          return acc;
        }, {}));
      }
      //Obtener valor unico
      lastValue = objetoAcuerdo30Reduce.reduce((acc, curr) => {
        if (acc.C_Pago < curr.C_Pago) {
          return acc.C_Pago;
        }
        return curr.C_Pago;
      }, 0);

    }
    this.objetoAcuerdo30 = lastValue;
  }

  async obtenerLc(): Promise<void> {
    let objetoLcReduce: any[] = [];
    const resultLc: any[] = [];
    const objResponse = this.obtenerValorCamposPersonalizadosAutomatic('item');
    const keyValue = 'Saldo_Actual';
    const keysValues = ['Importe ML', 'ACC'];
    const objImporte = this.convertirMiles(this.obtenerValorCamposPersonalizadosAutomatic(keysValues[0]));
    const objAcc = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[1]);
    const keysObjs = [objImporte, objAcc];

    if (objResponse.length > 0) {
      //Obtener posicion de la key by key LineaDeCredito
      let idx = objResponse.indexOf(keyValue);
      while (idx != -1) {
        resultLc.push({ position: idx });
        idx = objResponse.indexOf(keyValue, idx + 1);
      }

      //Obtener valores de la key
      if (resultLc.length > 0) {
        keysValues.forEach((e: any, i: number) => {
          resultLc.forEach((f: any) => {
            f[e.replace(' ', '')] = keysObjs[i][f.position] || '';
          });
        });
      }

      if (resultLc.length > 0) {

        objetoLcReduce = Object.values(resultLc.reduce((acc, curr) => {
          acc[curr.ACC] = acc[curr.ACC] || {
            ACC: curr.ACC,
            ImporteML: 0
          };
          acc[curr.ACC].ImporteML += +curr.ImporteML;
          return acc;
        }, {}));
      }
    }

    this.objetoLC = objetoLcReduce;
  }

  obtenerLineaCreditoGeneral(): void {
    if (this.statusTX !== 'CLOSED') {
      let objetoLineaCreditoReduce: any[] = [];
      const resultSaldoActual: any[] = [];
      const objResponse = this.obtenerValorCamposPersonalizadosAutomatic('item');
      const keyValue = 'Linea de Credito';
      const keysValues = ['Importe ML', 'ACC', 'Unidad Negocio'];
      const keysValuesNegocio = ['CR01', 'CR10', 'CR20', 'CR21', 'CR80'];
      const objImporte = this.convertirMiles(this.obtenerValorCamposPersonalizadosAutomatic(keysValues[0]));
      const objAcc = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[1]);
      const objUnidadNegocio = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[2]);
      const keysObjs = [objImporte, objAcc, objUnidadNegocio];

      if (objResponse.length > 0) {
        //Obtener posicion de la key by key Linea de Credito
        let idx = objResponse.indexOf(keyValue);
        while (idx != -1) {
          resultSaldoActual.push({ position: idx });
          idx = objResponse.indexOf(keyValue, idx + 1);
        }
        //Obtener valores de la key
        if (resultSaldoActual.length > 0) {
          keysValues.forEach((e: any, i: number) => {
            resultSaldoActual.forEach((f: any) => {
              f[e.replace(' ', '')] = keysObjs[i][f.position] || '';
            });
          });
        }

        objetoLineaCreditoReduce = Object.values(resultSaldoActual.filter(({ ACC }) => keysValuesNegocio.includes(ACC)).reduce((acc, curr) => {
          acc[curr.ACC] = acc[curr.ACC] || {
            ACC: curr.ACC,
            UnidadNegocio: curr.UnidadNegocio,
            ImporteML: 0,
            valueAcc: 0,
            lcSolicitada: 0,
            lcAprobada: 0,
            acuerdo30: 0,
            lcActual: 0
          };
          acc[curr.ACC].ImporteML += +curr.ImporteML;
          return acc;
        }, {}));
      }

      if (this.objetoAcuerdo30) {
        objetoLineaCreditoReduce.forEach((e: any) => {
          e.acuerdo30 = this.objetoAcuerdo30 || 0;
        });
      }

      if (this.objetoLC.length > 0) {
        objetoLineaCreditoReduce.forEach((e: any) => {
          this.objetoLC.forEach((f: any) => {

            if (e.ACC === f.ACC) {
              e.lcActual = f.ImporteML;
              e.lcAprobada = ((e.lcActual / e.ImporteML) * 100);
            }
          });
        });
      }

      this.objetoLineaCredito = objetoLineaCreditoReduce;

    } else {
      const objetoLineaCreditoReduce = this.informe?.SolicitudDeCredito?.TablasInformesCalculados?.datosCalculados || [];
      this.objetoLineaCredito = objetoLineaCreditoReduce.length > 0 ? objetoLineaCreditoReduce.find((e: any) => Object.keys(e)[0] === 'lineaCredito')?.lineaCredito?.lineaCredito || [] : [];
    }
  }

  convertirMiles(obj: number[]): number[] {
    if (obj.length > 0) {
      return obj.map((e: number) => {
        return e / 1000;
      });
    }
    return [];
  }

  sumTotalRow(): number {
    let suma = 0;
    this.addRowSum.forEach(e => {
      suma = suma + ((!isNaN(Number(e.value)) ? Number(e.value) : 0) *
        this.setNumber(this.obtenerValorRulesEngine('lineaCreditoAprobada')));
    });
    return suma;
  }

  showAlert(): void {
    if (this.montoAprobado === 0) {
      /*this.simpleModalService.addModal(ConfirmDialogComponent, {
        title: 'Solicitud de Crédito',
        message: `Debe llenar el campo monto aprobado en la sección de estado de la propuesta 
      para poder realizar esta acción.`,
        hideCancelmButtom: true
      });*/
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Solicitud de Crédito',
          message: `Debe llenar el campo monto aprobado en la sección de estado de la propuesta 
          para poder realizar esta acción.`,
          hideCancelmButtom: true
        }
      });
    }
  }
}
