<div class="modal-content">
    <div class="modal-body">

        <div class="row">
            <button class="close" type="button" (click)="close()" [attr.disabled]="showSpinner ? true : null" ><i class="fa fa-times"></i></button>

            <div class="col-12">
                <app-alert-message *ngFor="let message of messages; let i = index"
                    [message]="message.text"
                    [isError]="message.isError"
                    [isSuccess]="message.isSuccess"
                    (close)="removeMessage(i)"
                ></app-alert-message>
              </div>

            <div class="col-sm-12">
                <h3>Registro de Cambios</h3>
                <ul class="mt-4" *ngIf="inputModel?.rut || inputModel?.tx">
                    <li *ngIf="inputModel?.rut"><h4>Rut: {{inputModel?.rut || ''}}</h4></li>
                    <li *ngIf="inputModel?.periodo"><h4>Periodo: {{inputModel?.periodo || ''}}</h4></li>
                    <li *ngIf="inputModel?.tx"><h4>{{inputModel?.tipo == 'balance' ? 'Balance' : 'PreBalance'}}: {{inputModel?.tx || ''}}</h4></li>
                </ul> 
            </div>

            <ng-container *ngIf="showSpinner; else elseSpinner">
                <div class="col-sm-12 p-5">
                    <div class="row">
                        <div class="col-sm-12 d-flex justify-content-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="col-sm-12 text-center mt-1">
                            <span>Cargando...</span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #elseSpinner>
                <div class="col-sm-12">
                    <ng-container *ngIf="logData && logData.length > 0; else noDataTemplate">
                        <div class="card text-center my-4">
                            <div class="card-header">
                                <div class="my-0 row d-flex px-4">
        
                                    <div class="col-xs-3 col-sm-auto mr-auto p-2">
                                        <app-paginator-by-items [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                                    </div>
        
                                    <div class="col-xs-3 col-sm-auto p-2">
                                        <div class="input-group mb-3">
                                            <input id="table-complete-search" type="text" class="form-control" name="searchTerm" [(ngModel)]="inputText" (keyup)="filtrarObjeto()">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-primary btn-input-add" type="button">
                                                    <i class="fa fa-search mx-2" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xs-3 col-sm-auto p-2">
                                        <button class="btn btn-success float-right" (click)="obtenerLogBalanceManual()"><i class="fa fa-refresh" aria-hidden="true"></i></button>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-xs-3 col-sm-auto">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered table-hover">
                                        <thead>
                                        <tr>
                                            <th class="bg-primary" scope="col">Fecha</th>
                                            <th class="bg-primary" scope="col">Hora</th>
                                            <th class="bg-primary" scope="col">Rut</th>
                                            <th class="bg-primary" scope="col">Usuario</th>
                                            <th class="bg-primary" scope="col">Tipo Acción</th>
                                            <th class="bg-primary" scope="col">Periodo</th>
                                            <th class="bg-primary" scope="col" *ngIf="inputModel?.tx">Dato Nuevo</th>
                                            <th class="bg-primary" scope="col" *ngIf="inputModel?.tx">Dato Anterior</th>
                                            <th class="bg-primary" scope="col" *ngIf="inputModel?.tx">Monto Nuevo</th>
                                            <th class="bg-primary" scope="col" *ngIf="inputModel?.tx">Monto Anterior</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let item of arrayFiltrado | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                                                <tr>
                                                    <td>{{item?.fecha || ''}}</td>
                                                    <td>{{item?.hora || ''}}</td>
                                                    <td>{{item?.rut || '' | rutFormat}}</td>
                                                    <td>{{item?.usuario || ''}}</td>
                                                    <td>{{item?.accion || '' |  titlecase}}</td>
                                                    <td>{{item?.periodo || ''}}</td>
                                                    <td *ngIf="inputModel?.tx">{{item?.campo || ''}}</td>
                                                    <td *ngIf="inputModel?.tx">{{item?.campo || ''}}</td>
                                                    <td *ngIf="inputModel?.tx">{{item?.nuevo}}</td>
                                                    <td *ngIf="inputModel?.tx">{{item?.original}}</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="card-footer text-muted">
                                <div class="p-2">
                                    <div id="pagination">
                                        <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                                            <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noDataTemplate>
                        <div class="card text-center my-4 border-0">
                            <div class="col-xs-3 col-sm-auto">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered table-hover m-0">
                                        <thead>
                                        <tr>
                                            <th class="bg-primary" scope="col">Fecha</th>
                                            <th class="bg-primary" scope="col">Hora</th>
                                            <th class="bg-primary" scope="col">Rut</th>
                                            <th class="bg-primary" scope="col">Usuario</th>
                                            <th class="bg-primary" scope="col">Tipo Acción</th>
                                            <th class="bg-primary" scope="col" *ngIf="inputModel?.tx">Dato Nuevo</th>
                                            <th class="bg-primary" scope="col" *ngIf="inputModel?.tx">Dato Anterior</th>
                                            <th class="bg-primary" scope="col" *ngIf="inputModel?.tx">Monto Nuevo</th>
                                            <th class="bg-primary" scope="col" *ngIf="inputModel?.tx">Monto Anterior</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colspan="9">No se han encontrado registros</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
                
        </div>

    </div>
</div>
