import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { SimpleModalService } from 'ngx-simple-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { listadoLineaCreditoDetalle } from 'src/app/models/utils/utils.model';
import { SessionService } from 'src/app/shared/services/session.service';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';
import { AlertService } from '../../_alert';
import { ModalRutComponent } from './modal-rut/modal-rut.component';
import { RUT } from 'src/app/shared/utils/rut';
import { MallaSocietariaService } from 'src/app/shared/services/malla-societaria.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-resumen-solicitud-credito',
    templateUrl: './resumen-solicitud-credito.component.html',
    styleUrls: ['./resumen-solicitud-credito.component.scss']
})
export class ResumenSolicitudCreditoComponent implements OnInit {

    private access: string[] = [ESystemAccess.SOLICITUD_CREDITO_BUSCAR, ESystemProfileList.WEB, ESystemAccess.REPORTE_MALLA_SOCIETARIA];
    public rut: string = '';
    public hasUserAccess = false;
    public tipoSolicitud: string = '';
    public statusTX: string = '';
    public informacionCliente: any;
    public showNumSol: string = 'false';
    public idTransaccion: string = '';
    public showResume: boolean = false;
    public validEdition: boolean = false;
    public listadoLineaCreditoDetalle: any = listadoLineaCreditoDetalle;
    public objectKeys = Object.keys;
    public today: string = '';
    public titlePrint: string = '';
    private subscriptions: Subscription[] = [];
    public graficaMalla: any;

    public informacionMalla: any;
    public showMalla: boolean = false;

    constructor(
        private _sessionService: SessionService,
        private route: ActivatedRoute,
        private solicitudCreditoService: SolicitudCreditoService,
        private mallaSocietariaService: MallaSocietariaService,
        private alertsService: AlertService,
        private spinner: NgxSpinnerService,
        //private simpleModalService: SimpleModalService,
        public dialog: MatDialog,
        private router: Router) {
        this.solicitudCreditoService.ResumenObjSubjectObs$.subscribe(data => {
            this.solicitudCreditoService.setObjResumen(data.data, data.origin).subscribe(resp => {
            });
        });

        this.subscriptions.push(this.solicitudCreditoService.updateReportSubjectObs$.subscribe(resp => {
            this.informacionCliente = resp;
        }));
    }

    ngOnInit(): void {
        if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
            this.hasUserAccess = true;
            if (history.state.rut) {
                this.searchTX(history.state.rut);
                this.rut = history.state.rut;
            } else {
                /*this.simpleModalService.addModal(ModalRutComponent, {
                    title: 'Solicitud de Crédito',
                    message: 'Ingrese contraseña del informe para continuar'
                }).subscribe((resp) => {
                    if (resp) {
                        this.searchTX(resp);
                    } else {
                        this.router.navigate(['/solicitud-credito/consultar']);
                    }
                }, (error: any) => {
                    this.alertsService.error(error.error.message || 'Ocurrio un Error');
                    this.spinner.hide();
                });*/
                const dialogRef = this.dialog.open(ModalRutComponent, {
                    data: {
                        title: 'Solicitud de Crédito',
                        message: 'Ingrese contraseña del informe para continuar'
                    }
                });

                dialogRef.afterClosed().subscribe(resp => {
                    if (resp) {
                        this.searchTX(resp);
                    } else {
                        this.router.navigate(['/solicitud-credito/consultar']);
                    }
                }, (error: any) => {
                    this.alertsService.error(error.error.message || 'Ocurrio un Error');
                    this.spinner.hide();
                })
            }
        }
    }

    printPdf() {
        window.print();
    }

    searchTX(rut: string) {
        this.rut = rut;
        const id = this.route.snapshot.params['id'];
        this.spinner.show();
        this.subscriptions.push(this.solicitudCreditoService.searchSolicitudesCreditoID(id, rut).subscribe(resp => {
            this.spinner.hide();
            if (resp) {
                this.informacionCliente = resp;
                this.tipoSolicitud = this.informacionCliente.SolicitudDeCredito?.DatosBasicosSolicitud?.Modelo || '';
                this.idTransaccion = this.informacionCliente.SolicitudDeCredito?.DatosBasicosSolicitud?.IDTransaccion || '';
                this.solicitudCreditoService.setTipoSolicitud(this.tipoSolicitud);
                this.statusTX = this.informacionCliente.SolicitudDeCredito?.DatosBasicosSolicitud?.EstadoTransaccion || '';

                if (this._sessionService.getUserAccess().includes(this.access[2])) {
                    this.graficaMalla = this.informacionCliente.SolicitudDeCredito?.TablasInformesCalculados?.TablasInformesCalculadosNuevo?.datosGraficos || null;
                    if(this.graficaMalla) {this.showMalla = true}
                }

                this.obtenerFlujo();

            } else {
                this.alertsService.error('La contraseña ingresada es incorrecta');
                setTimeout(() => {
                    this.router.navigate(['/solicitud-credito/consultar']);
                }, 3000);
            }
        }, (error: any) => {
            this.alertsService.error(error.error.message || 'Ocurrio un Error');
            this.spinner.hide();
        }));
    }

    obtenerFlujo() {
        this.spinner.show();
        this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
            this.showResume = true;
            this.setToday();
            // validamos si cumple con los pasos necesarios para poder editar las condiciones de ventas
            if (resp && resp.stages.length === 8) this.validEdition = true;
            this.spinner.hide();
            if (this.statusTX !== 'CLOSED' && !this.graficaMalla) {
                if (this._sessionService.getUserAccess().includes(this.access[2])) {
                    this.crearMallaSocietaria();
                }
            }
        }, (error: any) => {
            this.alertsService.error(error.error.message || 'Ocurrio un Error');
            this.spinner.hide();
        }));
    }

    crearMallaSocietaria() {
        this.spinner.show();
        this.subscriptions.push(this.mallaSocietariaService.crearReporte(this.rut).subscribe(resp => {
            this.informacionMalla = resp?.MallaSocietariaReporte?.Reporte?.mallaSocietaria;
            this.showMalla = true;
            this.spinner.hide();
        }, (error: any) => {
            this.alertsService.error(error?.error?.message || 'Ocurrio un Error');
            this.spinner.hide();
        }));
    }

    getLastDigitosTx() {
        return this.idTransaccion.substr(this.idTransaccion.length - 5);
    }

    closeSolicitud(status: string): void {
        this.statusTX = status;
    }

    setToday() {
        const today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) :
            new Date().getMonth() + 1)}${new Date().getDate()}`;

        const rut = this.informacionCliente.SolicitudDeCredito?.DatosBasicosSolicitud?.Rut;
        
        this.titlePrint = `SolicitudCredito_${rut}_${today}`;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    getTest() {
    }

}
