import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';
import { AlertService } from '../../_alert';

@Component({
  selector: 'app-informe-empresa',
  templateUrl: './informe-empresa.component.html',
  styleUrls: ['./informe-empresa.component.scss']
})
export class InformeEmpresaComponent implements OnInit, OnDestroy {
  @Input() informeNuevo: boolean = false;
  @Input() idTransaccion: string = '';
  @Input() stepper!: number;
  @Input() rut: string = '';
  public now = Date.now();
  public dataPersonas: any[] = [];
  public boxCheck: any[] = [];
  public seeContent: boolean = false;
  public showContent: boolean = false;
  public listFiles: boolean[] = [];
  public dataTable: any[] = [];
  public files: any[] = [];
  public informe: any[] = [];
  public subscriptions: Subscription[] = [];
  public bloqUser: string[] = [];
  public fecha: any = {
    tipo: '',
    dato: ''
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private solicitudCreditoService: SolicitudCreditoService) { }

  ngOnInit(): void {
    this.obtenerFlujo();
  }

  obtenerFlujo() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.spinner.hide();
      const pasosCompleted = resp.stages.filter((e: any) => e.completed);
      const cantidad = pasosCompleted.length;
      const pasoActual = Number(pasosCompleted[cantidad - 1].stageID) + 2;

      if (pasoActual < this.stepper) {
        this.alertService.error(`Debe completar el paso N° ${pasoActual} para poder continuar`);
        this.solicitudCreditoService.setStep(pasoActual);
      } else {

        if (pasoActual >= this.stepper) {
          this.spinner.show();
          this.solicitudCreditoService.searchSolicitudesCreditoID(this.idTransaccion, this.rut).subscribe(resp => {
            this.spinner.hide();
            this.getInformeComercial(resp);
          }, (error: any) => {
            this.alertService.error(error.error.message || 'Ocurrio un Error');
            this.spinner.hide();
          });
        }
      }
    }));
  }

  setFecha(obj: any) {
    const fecha = obj.SolicitudDeCredito?.Reportes?.basicReportInformeComercial?.InformacionBasica?.FechaReporte;
    fecha ? (this.fecha.dato = this.solicitudCreditoService.formatDate(fecha), this.fecha.tipo = 'fecha') : (this.fecha.dato = 'Sin información', this.fecha.tipo = 'text');
  }

  formularioGroup = this.formBuilder.group({
    personas: new UntypedFormArray([])
  });

  get f() { return this.formularioGroup.controls; }
  get t() { return this.f.personas as UntypedFormArray; }
  get personFormGroups() { return this.t.controls as UntypedFormGroup[]; }

  getInformeComercial(obj: any, respuesta: boolean = false) {
    this.informe = obj;
    this.setFecha(obj)
    this.spinner.show();
    this.solicitudCreditoService.orderInformeComercial(obj).subscribe(resp => {
      this.spinner.hide();
      this.dataPersonas = resp;
      if (respuesta) {
        this.bloquearSeleccionados();
      } else {
        this.spinner.show();
        this.solicitudCreditoService.obtenerEmpresaSocios(obj).subscribe(response => {
          this.solicitudCreditoService.searchInformeComercial(this.idTransaccion, response).subscribe(resp => {
            this.seeContent = true;
            this.spinner.hide();
            this.getInformeComercial(resp, true);
          }, (error: any) => {
            this.alertService.error(error.error.message || 'Ocurrio un Error');
            this.spinner.hide();
          });
        }, (error: any) => {
          this.alertService.error(error.error.message || 'Ocurrio un Error');
          this.spinner.hide();
        });
      }
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    });
  }

  obtenerInforme() {
    if (!this.informeNuevo) {
      if (this.boxCheck.length === 0) {
        this.alertService.error('Debe Seleccionar uno o varios items de la columna izquierda para obtener un informe');
      } else {
        this.seeContent = true;
      }
    } else {
      if (this.listFiles.length === 0) {
        this.alertService.error('Debe Subir uno o varios archivos para poder obtener un informe');
      } else {
        this.seeContent = true;
      }
    }
  }


  verInforme() {
    if (this.fecha.tipo === 'text') {
      this.alertService.error('Debe obtener un informe primero');
    } else {
      this.showContent = !this.showContent;
      this.seeContent = true;
    }
  }

  onCheckboxChange(item: any, e: any) {
    if (e.target.checked) {
      this.boxCheck.push(item);
      this.t.push(this.formBuilder.group({
        file: ['', Validators.required],
        name: ['']
      }));
    } else {
      this.boxCheck = this.boxCheck.filter(f => f.Rut !== item.Rut.replace(/\./g, ''));
      this.t.removeAt(item.id - 1);
    }
  }

  onFirstCheckboxChange(item: any, e: any, tipo: string) {

    if (e.target.checked) {
      this.boxCheck.push({
        Rut: item.Rut.replace(/\./g, ''),
        "type": tipo,
        operation: "1",
        Nombre: item.Nombre ? item.Nombre : item.NombreORazonSocial
      });
      this.t.push(this.formBuilder.group({
        file: ['', Validators.required],
        name: ['']
      }));

    } else {
      this.boxCheck = this.boxCheck.filter(f => f.Rut !== item.Rut.replace(/\./g, ''));
      this.t.removeAt(item.id - 1);
    }
  }

  onChange(event: Event, i: number): void {

    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }

    const fileName = input.files[0];
    this.personFormGroups[i].controls['name'].setValue(fileName.name);

  }

  changeStep() {
    this.solicitudCreditoService.setStep('next');
  }

  onSubmit() {

    if (this.informeNuevo) {
      if (this.formularioGroup.invalid) {
        this.alertService.error('Debe Subir uno o varios archivos para poder obtener un informe');
        return
      }
      this.seeContent = true;
    } else {
      if (this.boxCheck.length === 0) {
        this.alertService.error('Debe Seleccionar uno o varios items de la columna izquierda para obtener un informe');
      } else {
        this.spinner.show();
        this.solicitudCreditoService.updateInformeComercial(this.idTransaccion, this.boxCheck).subscribe(resp => {
          this.seeContent = true;
          this.spinner.hide();
          this.getInformeComercial(resp, true);
        }, (error: any) => {
          this.alertService.error(error.error.message || 'Ocurrio un Error');
          this.spinner.hide();
        });
      }
    }
  }


  bloquearSeleccionados() {
    this.bloqUser = this.boxCheck.map(e => {
      return e.Rut
    });
    this.boxCheck = [];
  }

  checkUserBloq(rut: any) {
    if (this.bloqUser.length > 0) {
      if (this.bloqUser.includes(rut.replace(/\./g, ''))) {
        return true
      }
    }

    return false
  }

  updateSocios() {
    const rutEmpresa = this.dataPersonas?.[0].empresaSocios?.filter((e: any) => e.tipoPersona === 'empresa')?.[0]
      .data?.[0]?.Rut.replace(/\./g, '') || '';
    this.spinner.show();
    this.solicitudCreditoService.updateSocios(this.idTransaccion, rutEmpresa).subscribe(resp => {
      this.spinner.hide();
      this.solicitudCreditoService.orderInformeComercial(resp).subscribe(response => {
        this.dataPersonas = response;
        this.boxCheck = [];
      });
    }, (error: any) => {
      this.alertService.error(error.error.message || 'Ocurrio un Error');
      this.spinner.hide();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
