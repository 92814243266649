<div class="px-5 py-5">

    <h2>ID Query: {{data.id}}</h2>
    
    <textarea *ngIf="textValue != ''" [(ngModel)]="textValue" style="width: 100%; min-height: 200px;" name="" id=""></textarea>
    
    <div *ngIf="textValue == ''">No hay datos para este ID</div>

    <div class="col-12 d-flex justify-content-center mt-3">
        <button class="btn btn-primary" (click)="onClose()">cerrar</button>
    </div>
</div>
