import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ESystemAccess } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { SolicitudCreditoServiceWorkflow } from 'src/app/shared/services/solicitud-credito-workflow.service';
import { AlertService } from '../../_alert';

interface EstadoSolicitud {
  EstadoSolicitudEtapaActual: string,
  NombreEtapaActual?: string,
  Fecha?: string,
  TipoDecision?: string,
  TotalMontos: number,
  Montos: any[] | any,
  Decision: string,
}

@Component({
  selector: 'app-aprobacion-workflow',
  templateUrl: './aprobacion-workflow.component.html',
  styleUrls: ['./aprobacion-workflow.component.scss']
})
export class AprobacionWorkflowComponent implements OnInit {
  @Input() step: boolean = false;
  @Input() idTransaccion: string = '';
  @Input() rut: string = '';
  @Input() tipoSolicitud: string = '';
  @Input() tipoSolicitudNombre: string = '';
  @Input() numeroSolicitud: number | string = '';
  @Input() stepper!: number;
  @Input() permisos: any[] = [];
  @Output() operacionNuevo = new EventEmitter<boolean>();
  @Output() bloqueaPasos6 = new EventEmitter<boolean>();
  public organization: string = '';

  public objectKeys = Object.keys;
  public subscriptions: Subscription[] = [];
  public showFicha: boolean = false;

  private access: string[] = [
    ESystemAccess.FLUJO_CREDITO_BUSCAR, 
    ESystemAccess.FLUJO_CREDITO_BUSCAR_ADMIN,
    ESystemAccess.FLUJO_CREDITO,
    ESystemAccess.FLUJO_CREDITO_ADMIN
  ];
  public editable: boolean = false; // Primero debe ser admin, y luego se debe validar si en la etapa actual es aprobador
  public fichaDeCredito: any = {};
  public tablaProducto: any = {};
  public totalProducto: any = {};
  public arrayTypeDate: any[] = [];
  public motorDeCalculos: any = {};
  public servicioAprobacional: any = {};
  public pasoSeleccionado: number | null = null;
  public itemSeleccionado: any = {};
  public estadoSolicitud: EstadoSolicitud | null = null;
  public valoresEtapaForm!: UntypedFormGroup;
  public comentarios: string = '';
  public estadoTransaccion: string = '';
  public fechaReporte: string = '';

  public arrayComentarios: any[] = [];
  public automatico: boolean = false;
  public completado: boolean = false;
  public decision: string = '';
  public calculo: boolean = false;
  public today = '';
  public autorizado: boolean = false;
  public closed: boolean = true;
  public tablaProductosMapeados: any[] = [];
  public tablaProductosMapeadosDate: any[] = [];

  constructor(
    private _sessionService: SessionService,
    public alertService: AlertService,
    private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.organization = this._sessionService.getOrganization();
    this.bloqueaPasos6.emit(true);
    this.setToday();
    this.operacionNuevo.emit(false);
    this.obtenerFlujo();
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  obtenerFlujo(): void {

    this.spinner.show();
    if (this._sessionService.getUserAccess().includes(this.access[3])) {
      this.editable = true;
    }

    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      let paso: any;
      let pasoAnterior: any;
      this.closed = (resp.closed === null || resp.closed === undefined) ? true : resp.closed;
      resp.stages.forEach((e: any) => {
        if(e.stageID === '11'){
          paso = e;
        } else if (e.stageID === '10'){
          pasoAnterior = e;
        }
      });
      if((pasoAnterior && !pasoAnterior?.completed) || (!paso || (paso?.completed === false && paso?.inProgress === false))){
        const pasosCompleted = resp.stages.filter((e: any) => e.completed);
        const cantidad = pasosCompleted.length;
        const ultimoPasoCompletado = Number(pasosCompleted[cantidad - 1].stageID);
        let step: any = {};
        if(ultimoPasoCompletado === 10){
          step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado));
        } else {
          step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado + 1));
        }
        if (!step[0]?.step){
          step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado));
        }
        this.alertService.error(`Solicitud de Crédito - Debe completar el paso N° ${Number(step[0]?.step)} para poder continuar`);
        this.solicitudCreditoService.setStep(Number(step[0]?.step));
        this.bloqueaPasos6.emit(false);
      } else {
        if(paso && (paso?.completed || paso?.inProgress)){ // Validar estados
          this.subscriptions.push(this.solicitudCreditoService.obtenerFlujoAprobacional(this.idTransaccion, this.rut).subscribe(resp => {
            this.automatico = resp?.DatosBasicosSolicitud?.Automatico;
            this.completado = resp?.DatosBasicosSolicitud?.Decision === '' ? false : true;
            this.decision = resp?.DatosBasicosSolicitud?.Decision;
            this.calculo = resp?.DatosBasicosSolicitud?.Calculo;
            this.fechaReporte = resp?.DatosBasicosSolicitud?.FechaReporte ? resp?.DatosBasicosSolicitud?.FechaReporte : this.fechaReporte;
            this.autorizado = resp?.Autorizado || false;

            if(this.automatico === true){ // Caso automatico
              if(this.calculo === true){
                this.fichaDeCredito = resp?.FichaDeCredito || {};
                this.tablaProducto = resp?.FichaDeCredito?.ficha?.lineaOperacionPropuesta || {};
                this.totalProducto = resp?.FichaDeCredito?.ficha?.lineaOperacionPropuestaTotales || {};
                this.arrayTypeDate = resp?.FichaDeCredito?.ficha?.lineaOperacionPropuestaTipoFecha || [];
                this.motorDeCalculos = resp?.MotorDeCalculos || {};
                this.estadoTransaccion = resp?.DatosBasicosSolicitud?.EstadoTransaccion || '';
                this.tablaProductosMapeados = [];
                this.tablaProductosMapeadosDate = [];
                this.actualizarClosed();
                this.mapeoEstadoSolicitudAutomatico();
              } else {
                this.alertService.error('Solicitud de Crédito - Ocurrio un error al recuperar los montos calculados');
                this.spinner.hide();
              }

            } else if (this.automatico === false) { // Caso manual
              this.fichaDeCredito = resp?.FichaDeCredito || {};
              this.tablaProducto = resp?.FichaDeCredito?.ficha?.lineaOperacionPropuesta || {};
              this.totalProducto = resp?.FichaDeCredito?.ficha?.lineaOperacionPropuestaTotales || {};
              this.arrayTypeDate = resp?.FichaDeCredito?.ficha?.lineaOperacionPropuestaTipoFecha || [];
              this.motorDeCalculos = resp?.MotorDeCalculos || {};
              this.servicioAprobacional = resp?.ServicioAprobacional?.ResponseAprobacional || {};
              this.estadoTransaccion = resp?.DatosBasicosSolicitud?.EstadoTransaccion || '';
              this.tablaProductosMapeados = [];
              this.tablaProductosMapeadosDate = [];
              this.actualizarClosed();
              this.mapeoEstadoSolicitud();
            }

            const error = resp?.ServicioAprobacional?.errors || null;
            if(error){
              if(error?.hassError === true){
                this.alertService.error(error?.details?.message || 'Solicitud de Crédito - Ocurrio un error al actualizar el flujo');
              }
            }
            resp?.FichaDeCredito ? this.showFicha = true : this.showFicha = false;
            this.ultimoPasoPrint(); // Contenido para print
            this.spinner.hide();
          }, (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar informacion del flujo de credito');
            this.spinner.hide();
          }));
        }
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar datos del flujo de credito');
      this.showFicha = false;
      this.spinner.hide();
    }));

  }

  actualizarClosed(): void {
    this.closed = (this.estadoTransaccion === 'CLOSED') ? true : this.closed;
  }

  mapeoArrayComentario(paso: number): void { // no va a ser cero el paso porque se valida cuando se llama
    this.arrayComentarios = [];
    for(let i = paso - 1; i >= 0; i--){
      const fecha = this.servicioAprobacional?.definicionAprobaciones?.flujoAprobaciones[i]?.fechaActualizacion;
      const usuario = this.servicioAprobacional?.definicionAprobaciones?.flujoAprobaciones[i]?.valores?.usuarioApruebaRechaza;
      const id = this.servicioAprobacional?.definicionAprobaciones?.flujoAprobaciones[i]?.id;
      const etapa = this.servicioAprobacional?.definicionAprobaciones?.flujoAprobaciones[i]?.nombre;
      const comentario = this.servicioAprobacional?.definicionAprobaciones?.flujoAprobaciones[i]?.valores?.comentarios;
      const clase = this.getClassStatus(this.servicioAprobacional?.definicionAprobaciones?.flujoAprobaciones[i]) || '';

      this.arrayComentarios.push({
        fecha: fecha,
        usuario: usuario,
        id: id,
        etapa: etapa,
        comentario: comentario,
        class: clase
      });
    }
    this.arrayComentarios = this.arrayComentarios.reverse();
  }

  obtenerTotalValores(valores: any[]): number | null {
    let suma = 0;
    if(valores){
      valores.forEach((element: any) => {
        if(this.arrayTypeDate.includes(element.nombre) === false){
          suma += Number(element.valor);
        }
      })
    }
    return suma;
  }

  obtenerNombre(key: string): string {
    const producto = this.tablaProducto?.producto?.find((element: any) => element.id === key);
    if(producto){
      return producto?.texto || key;
    }
    return key;
  }

  mapeoEstadoSolicitudAutomatico(): void {
    this.estadoSolicitud = {
      EstadoSolicitudEtapaActual: this.estadoTransaccion !== 'CLOSED' ? 'Solicitud en Progreso' : 'Solicitud Finalizada',
      TotalMontos: this.obtenerTotalValoresMotor(this.motorDeCalculos?.ResponseReglas?.resultado) || 0,
      Montos: this.motorDeCalculos?.ResponseReglas?.resultado || {},
      Decision: this.decision,
      Fecha: this.motorDeCalculos.ResponseHistorico?.Fecha || '',
      TipoDecision: this.automatico === true ? 'Automática': 'Manual',
    }
  }

  obtenerTotalValoresMotor(objeto: any): number {
    let suma = 0;
    for(const item of this.objectKeys(objeto)){
      if(item !== 'decision' && item !== 'automatico' && this.validarTypeDate(item) === false){
        suma += Number(objeto[item]);
      }
    }
    return suma;
  }

  mapeoEstadoSolicitud(): void {
    const index = this.servicioAprobacional?.definicionAprobaciones?.flujoAprobaciones.findIndex((element: any) => element.id === this.servicioAprobacional?.definicionAprobaciones?.etapaActual);
    // si el indice es cero o el flujo esta terminado se toma el mismo item del index - si no se toma el anterior
    if(index === 0 || this.completado === true){
      const item = this.servicioAprobacional?.definicionAprobaciones?.flujoAprobaciones[index];
      if (item) {
        this.estadoSolicitud = {
          EstadoSolicitudEtapaActual: this.estadoTransaccion !== 'CLOSED' ? 'Solicitud en Progreso' : 'Solicitud Finalizada', // validar
          NombreEtapaActual: item?.nombre || 'Etapa ' + this.servicioAprobacional?.definicionAprobaciones?.etapaActual || '-',
          TotalMontos: this.obtenerTotalValores(item?.valores?.montos) || 0,
          Montos: item?.valores?.montos || [],
          Decision: this.decision,
          Fecha: item?.fechaActualizacion || '',
          TipoDecision: this.automatico === true ? 'Automática': 'Manual',
    
        }
      }

    } else if(index > 0 && this.completado === false) {
      const item = this.servicioAprobacional?.definicionAprobaciones?.flujoAprobaciones[index - 1];
      if (item) {
        const itemActual = this.servicioAprobacional?.definicionAprobaciones?.flujoAprobaciones.find((element: any) => element.id === this.servicioAprobacional?.definicionAprobaciones?.etapaActual);

        this.estadoSolicitud = {
          EstadoSolicitudEtapaActual: 'Solicitud en Progreso',
          NombreEtapaActual: itemActual?.nombre || 'Etapa ' + this.servicioAprobacional?.definicionAprobaciones?.etapaActual || '-',
          TotalMontos: this.obtenerTotalValores(item?.valores?.montos) || 0,
          Montos: item?.valores?.montos || [],
          Decision: '',
          Fecha: item?.fechaActualizacion || '',
          TipoDecision: this.automatico === true ? 'Automática': 'Manual',
        }
      }

    }
  }

  getClassStatus(item: any): string {
    let clase: string = '';
    if(this.servicioAprobacional?.definicionAprobaciones?.etapaActual === item.id){
      clase = 'dot-actual'
    }
    if(item.valores.aprobado === true || item.valores.aprobado === 'true' && !(clase === 'dot-actual' && this.completado !== true)){
      clase = 'dot-ok'
    }
    if(item.valores.aprobado === false || item.valores.aprobado === 'false' && !(clase === 'dot-actual' && this.completado !== true)){
      clase = 'dot-denied'
    }
    if(clase !== 'dot-actual' && (clase !== 'dot-ok' && clase !== 'dot-denied')){
      clase = 'dot-wait'
    }
    if(clase === 'dot-wait' && this.validaRechazo() === true){
      clase = 'dot-disabled'
    }
    return clase;
  }

  validaRechazo(): boolean {
    const rechazado = this.servicioAprobacional?.definicionAprobaciones?.flujoAprobaciones.find((e: any) => e?.valores?.aprobado === false || e?.valores?.aprobado === 'false'); // validar si agregar el puedeCerrarFlujo
    if(rechazado){
      return true
    }
    return false;
  }

  validaOpcionesActualAdmin(item: any): boolean {
    //const aprobadores = item?.configuracion?.personasAprobadoras || [];
    if(this.editable === true && this.autorizado === true &&
      this.servicioAprobacional?.definicionAprobaciones?.etapaActual === item.id){
        return true;
    }

    return false;
  }


  // Muestra u oculta seccion dependiendo de seleccion del usuario

  show(el: string, buttonId?: string) {
    const elementHTML = document.getElementById(el) as HTMLElement;
    elementHTML.classList.toggle('container-desplegables');
    if(buttonId){
      const button = document.getElementById(buttonId) as HTMLElement;
      if ( !elementHTML.classList.contains('container-desplegables') ){
        button.classList.add('button-selected');
        button.classList.remove('border');
        button.classList.remove('border-buttons');
        button.classList.add('border-0'); 
      } else {
        button.classList.remove('button-selected');
        button.classList.add('border');
        button.classList.add('border-buttons');
        button.classList.remove('border-0'); 
      }
    }
  }


  validaClave(plantilla: string): boolean {
    if(this.permisos && this.permisos.length > 0) {
      const permisos = this.permisos.find((e: any) => e.plantilla == plantilla);
      if(permisos && permisos?.modelos && permisos.modelos.length > 0){
        return (permisos.modelos.indexOf(this.tipoSolicitud) > -1);
      }
    }
    
    return false;
  }

  obtenerAprobadores(item: any): string {
    let respuesta = ``;
    if(item?.configuracion?.personasAprobadoras){
      item?.configuracion?.personasAprobadoras.forEach((element: any) => {
        respuesta += `${element?.id || ''}\n`;
      });
    }
    return respuesta;
  }

  obtenerGrupoAprobadores(item: any): string {
    let respuesta = ``;
    if(item?.configuracion?.gruposAprobadores){
      item?.configuracion?.gruposAprobadores.forEach((element: any) => {
        respuesta += `${element?.id || ''}\n`;
      });
    }
    return respuesta;
  }

  generarTablaProductosMapeados(tablaProducto: any, montos: any[]): any[] {
    const respuesta: any = [];
    if(tablaProducto?.lineaPropuesta && montos && tablaProducto?.producto && Object.keys(tablaProducto?.producto).length > 0 && Object.keys(tablaProducto?.lineaPropuesta).length > 0 && montos.length > 0){

      montos.forEach((element: any) => {
        if(!this.arrayTypeDate.includes(element.nombre)){
          const objeto: any = {};
          const producto = tablaProducto?.producto.find((e: any) => e.id === element.nombre);
  
          objeto.nombre = producto?.texto || element.nombre;
          objeto.id = element.nombre || '';
          objeto.lineaPropuesta = (tablaProducto?.lineaPropuesta?.[element.nombre] || tablaProducto?.lineaPropuesta?.[element.nombre] == 0) ? tablaProducto?.lineaPropuesta?.[element.nombre] : '';
  
          respuesta.push(objeto);
        }
      })

    }
    return respuesta;
  }

  generarTablaProductosMapeadosDate(tablaProducto: any, montos: any[]): any[] {
    const respuesta: any = [];
    if(tablaProducto?.lineaPropuesta && montos && tablaProducto?.producto && Object.keys(tablaProducto?.producto).length > 0 && Object.keys(tablaProducto?.lineaPropuesta).length > 0 && montos.length > 0){

      montos.forEach((element: any) => {
        if(this.arrayTypeDate.includes(element.nombre)){
          const objeto: any = {};
          const producto = tablaProducto?.producto.find((e: any) => e.id === element.nombre);
  
          objeto.nombre = producto?.texto || element.nombre;
          objeto.id = element.nombre || '';
          objeto.lineaPropuesta = (tablaProducto?.lineaPropuesta?.[element.nombre] || tablaProducto?.lineaPropuesta?.[element.nombre] == 0) ? tablaProducto?.lineaPropuesta?.[element.nombre] : '';
  
          respuesta.push(objeto);
        }
      })

    }
    return respuesta;
  }

  dateToYYYYMMDD(valor: any): string {
    if(valor && valor.includes('-')) 
      return valor;

    const fecha = new Date(Number(valor));
    const anio = fecha.getFullYear();
    let mes: any = fecha.getMonth() + 1; // Los meses en JavaScript empiezan desde 0
    let dia: any = fecha.getDate();

    // Asegurándose de que el mes y el día sean de dos dígitos
    if (mes < 10) mes = '0' + mes;
    if (dia < 10) dia = '0' + dia;

    return `${anio}-${mes}-${dia}`;
  }

  seleccionarPaso(item: any, paso: number): void {
    this.tablaProductosMapeados = this.generarTablaProductosMapeados(this.tablaProducto, item?.valores?.montos);
    this.tablaProductosMapeadosDate = this.generarTablaProductosMapeadosDate(this.tablaProducto, item?.valores?.montos);
    this.arrayComentarios = [];
    this.valoresEtapaForm = this.formBuilder.group({});
    if(this.validaOpcionesActualAdmin(item)){
      // Si tiene permisos se crea el formulario y comentarios
      if(paso > 0){
        const itemAnterior = this.servicioAprobacional?.definicionAprobaciones?.flujoAprobaciones[paso - 1];
        this.comentarios = item?.valores?.comentarios || '';
        this.mapeoArrayComentario(paso);
        if(itemAnterior?.valores?.montos){ // Se valida con respecto a la tablaProducto
          itemAnterior?.valores?.montos.forEach((element: any) => {
            if(this.arrayTypeDate.includes(element.nombre) === false){
              this.valoresEtapaForm.addControl(element.nombre, new UntypedFormControl(Number(element.valor), Validators.required));
            } else {
              this.valoresEtapaForm.addControl(element.nombre, new UntypedFormControl(element.valor ? this.dateToYYYYMMDD(element.valor) : '', Validators.required));
            }
          });
        }
      } else {
        this.comentarios = item?.valores?.comentarios || '';
        if(item?.valores?.montos){
          item?.valores?.montos.forEach((element: any) => {
            if(this.calculo === true){
              if(this.arrayTypeDate.includes(element.nombre) === false){
                this.valoresEtapaForm.addControl(element.nombre, new UntypedFormControl(Number(this.motorDeCalculos?.ResponseReglas?.resultado[element.nombre] || 0), Validators.required));
              } else {
                this.valoresEtapaForm.addControl(element.nombre, new UntypedFormControl(this.motorDeCalculos?.ResponseReglas?.resultado[element.nombre] ? this.dateToYYYYMMDD(this.motorDeCalculos?.ResponseReglas?.resultado[element.nombre]) : '', Validators.required));
              }
            } else {
              if(this.arrayTypeDate.includes(element.nombre) === false){
                this.valoresEtapaForm.addControl(element.nombre, new UntypedFormControl(Number(this.tablaProducto?.lineaPropuesta?.[element.nombre] || 0), Validators.required));
              } else {
                this.valoresEtapaForm.addControl(element.nombre, new UntypedFormControl(this.tablaProducto?.lineaPropuesta?.[element.nombre] ? this.dateToYYYYMMDD(this.tablaProducto?.lineaPropuesta?.[element.nombre]) : '', Validators.required));
              }
            }
          });
        }
      }
    } else {
      if(paso > 0){
        this.mapeoArrayComentario(paso);
      }
    }
    this.pasoSeleccionado = item?.id || null;
    this.itemSeleccionado = item;
  }

  quitarEspaciosBlancos(texto: string): string{
    return texto.replace(/ /g, "");
  }

  guardarValores(item: any, etapa: number | string, aprobar: boolean | null): void {
    if (!this.valoresEtapaForm.valid) {
      this.solicitudCreditoService.validateAllFormFields(this.valoresEtapaForm);
      this.alertService.error('Solicitud de Crédito - Debe completar los datos necesarios para guardar correctamente');
      return;
    }
    if(this.comentarios === ''){
      this.alertService.error('Solicitud de Crédito - Debe ingresar un comentario para poder guardar correctamente');
      return;
    }
    this.spinner.show();

    const formTemp = this.valoresEtapaForm.getRawValue();
    const result = Object.entries(formTemp).reduce((acc: any, curr: any) => {
      const [key, val] = curr;
      
      if(this.arrayTypeDate.includes(key)){
        const [year, month, day] = val?.split("-");
        const date = new Date(Number(year), Number(month) - 1, Number(day)).getTime();
        acc.push({
          nombre: key,
          valor: date
        });
        
      } else {
        acc.push({
          nombre: key,
          valor: val
        });
      }
      return acc;
    }, []);
      
    const itemActualizado = JSON.parse(JSON.stringify(item));
    itemActualizado.montos = result;
    itemActualizado.comentarios = this.comentarios;

    if(aprobar === true || aprobar === false){
      // consulta el de aprobar o rechazar
      itemActualizado.aprobado = aprobar;
    }
    // consulta el de actualizar sin indicar que se aprueba o rechaza
    this.subscriptions.push(this.solicitudCreditoService.actualizarFlujoAprobacional(this.idTransaccion, this.rut, itemActualizado, etapa).subscribe(resp => {
      this.completado = resp?.DatosBasicosSolicitud?.Decision === '' ? false : true;
      this.decision = resp?.DatosBasicosSolicitud?.Decision;
      this.fechaReporte = resp?.DatosBasicosSolicitud?.FechaReporte ? resp?.DatosBasicosSolicitud?.FechaReporte : this.fechaReporte;
      this.autorizado = resp?.Autorizado || false;

      this.pasoSeleccionado = null;
      this.itemSeleccionado = {};
      this.servicioAprobacional = resp?.ServicioAprobacional?.ResponseAprobacional || {};
      this.estadoTransaccion = resp?.DatosBasicosSolicitud?.EstadoTransaccion || '';
      this.actualizarClosed();
      
      const error = resp?.ServicioAprobacional?.errors || null;
      if(error){
        if(error?.hassError === true){
          this.alertService.error(error?.details?.message || 'Solicitud de Crédito - Ocurrio un error al actualizar el flujo');
        }
      }
      this.mapeoEstadoSolicitud();
      this.ultimoPasoPrint();
      this.spinner.hide();
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al actualizar el flujo');
      this.spinner.hide();
    }));
  

  }

  obtenerTotal(): number {
    const formTemp = this.valoresEtapaForm.getRawValue();
    return Object.keys(formTemp).reduce((sum,key)=> sum + ((!this.arrayTypeDate.includes(key)) ? (parseInt(formTemp[key]||0)) : 0),0)
  }

  obtenerTotalNoAdmin(array: any[]): number {
    if(array) {
      let arrayFiltrado = array.filter((element: any) => this.arrayTypeDate.includes(element?.nombre) === false);
      arrayFiltrado = arrayFiltrado ? arrayFiltrado : [];
      return Object.keys(arrayFiltrado).reduce((sum: any,key: any)=> sum + parseInt(arrayFiltrado[key]?.valor||0),0)
    }
    return 0
  }

  obtenerMontoNoAdmin(array: any[], id: string): string {
    if(array && id && this.arrayTypeDate.includes(id) === false){
      const valor = array.find((element:any) => element.nombre === id);
      if(valor){
        return valor.valor;
      }
    }
    return '';
  }

  obtenerMontoNoAdminDate(array: any[], id: string): string {
    if(array && id && this.arrayTypeDate.includes(id)){
      const valor = array.find((element:any) => element.nombre === id);
      if(valor){
        const date = valor.valor ? this.dateToYYYYMMDD(valor.valor) : '';
        return date;
      }
    }
    return '';
  }

  agregarSaltosDeLinea(texto: string): string {
    if(texto)
      return texto.replace(new RegExp('\n', 'g'), '<br />');
    return '';
  }

  // Consulta a la api de cerrar solicitud que cambia el estado a closed y devuelve todo el objeto
  cerrarSolicitud(): void {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.cerrarFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.completado = resp?.DatosBasicosSolicitud?.Decision === '' ? false : true;
      this.decision = resp?.DatosBasicosSolicitud?.Decision;
      this.fechaReporte = resp?.DatosBasicosSolicitud?.FechaReporte ? resp?.DatosBasicosSolicitud?.FechaReporte : this.fechaReporte;
      this.estadoTransaccion = resp?.DatosBasicosSolicitud?.EstadoTransaccion || '';
      this.actualizarClosed();
      if(this.estadoSolicitud){
        this.estadoSolicitud.EstadoSolicitudEtapaActual = this.estadoTransaccion !== 'CLOSED' ? 'Solicitud en Progreso' : 'Solicitud Finalizada';
        this.estadoSolicitud.Decision = this.decision;
      }
      this.spinner.hide();
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al cerrar la solicitud');
      this.spinner.hide();
    }));

  }

  // Consulta a la api de iniciar flujo que cambia los estados de automatico a false y decision a ""
  revisionManual(): void {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.iniciarFlujoAprobacional(this.idTransaccion, this.rut).subscribe(resp => {
      const error = resp?.ServicioAprobacional?.errors || null;
      this.autorizado = resp?.Autorizado || false;
      if(error?.hassError === true){
        this.alertService.error(error?.details?.message || 'Solicitud de Crédito - Ocurrio un error al actualizar el flujo');
      } else {
        this.automatico = resp?.DatosBasicosSolicitud?.Automatico;
        this.completado = resp?.DatosBasicosSolicitud?.Decision === '' ? false : true;
        this.decision = resp?.DatosBasicosSolicitud?.Decision;
        this.calculo = resp?.DatosBasicosSolicitud?.Calculo;
        this.fechaReporte = resp?.DatosBasicosSolicitud?.FechaReporte ? resp?.DatosBasicosSolicitud?.FechaReporte : this.fechaReporte;
  
        if(this.automatico === true){ // Caso automatico
          if(this.calculo === true){
            this.fichaDeCredito = resp?.FichaDeCredito || {};
            this.tablaProducto = resp?.FichaDeCredito?.ficha?.lineaOperacionPropuesta || {};
            this.totalProducto = resp?.FichaDeCredito?.ficha?.lineaOperacionPropuestaTotales || {};
            this.arrayTypeDate = resp?.FichaDeCredito?.ficha?.lineaOperacionPropuestaTipoFecha || [];
            this.motorDeCalculos = resp?.MotorDeCalculos || {};
            this.estadoTransaccion = resp?.DatosBasicosSolicitud?.EstadoTransaccion || '';
            this.tablaProductosMapeados = [];
            this.tablaProductosMapeadosDate = [];
            this.actualizarClosed();
            this.mapeoEstadoSolicitudAutomatico();
          } else {
            this.alertService.error('Solicitud de Crédito - Ocurrio un error al recuperar los montos calculados');
            this.spinner.hide();
          }
  
        } else if (this.automatico === false) { // Caso manual
          this.fichaDeCredito = resp?.FichaDeCredito || {};
          this.tablaProducto = resp?.FichaDeCredito?.ficha?.lineaOperacionPropuesta || {};
          this.totalProducto = resp?.FichaDeCredito?.ficha?.lineaOperacionPropuestaTotales || {};
          this.arrayTypeDate = resp?.FichaDeCredito?.ficha?.lineaOperacionPropuestaTipoFecha || [];
          this.motorDeCalculos = resp?.MotorDeCalculos || {};
          this.servicioAprobacional = resp?.ServicioAprobacional?.ResponseAprobacional || {};
          this.estadoTransaccion = resp?.DatosBasicosSolicitud?.EstadoTransaccion || '';
          this.tablaProductosMapeados = [];
          this.tablaProductosMapeadosDate = [];
          this.actualizarClosed();
          this.mapeoEstadoSolicitud();
        }
  
        resp?.FichaDeCredito ? this.showFicha = true : this.showFicha = false;
        this.ultimoPasoPrint(); // Contenido para print
      }

      this.spinner.hide();
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al cerrar la solicitud');
      this.spinner.hide();
    }));
  }

  obtenerNombreProductoAutomatico(key: string): string {
    let resultado: string = key || "";
    
    if(this.tablaProducto?.producto && this.tablaProducto?.producto.length > 0) {
      const producto: {id: string, texto: string} = this.tablaProducto?.producto.find((e: {id: string, texto: string}) => e.id === key);
      if(producto?.texto){
        resultado = producto?.texto || resultado;
      }
    }

    return resultado;
  }

  ultimoPasoPrint(): void {
    const paso: number = this.servicioAprobacional?.definicionAprobaciones?.etapaActual - 1 || 0;
    const item: any = this.servicioAprobacional?.definicionAprobaciones?.flujoAprobaciones[paso];

    if(item && Object.keys(item).length > 0){
      this.tablaProductosMapeados = this.generarTablaProductosMapeados(this.tablaProducto, item?.valores?.montos);
      this.tablaProductosMapeadosDate = this.generarTablaProductosMapeadosDate(this.tablaProducto, item?.valores?.montos);
      this.arrayComentarios = [];
      if(paso > 0){
        this.mapeoArrayComentario(paso);
      }
      this.pasoSeleccionado = null; // si el paso es null se mantiene oculto
      this.itemSeleccionado = item;
    }
  }

  validarTypeDate(id: string): boolean {
    return this.arrayTypeDate.includes(id);
  }

}
