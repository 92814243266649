import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-coming-soon-view',
  templateUrl: './coming-soon-view.component.html',
  styleUrls: ['./coming-soon-view.component.scss']
})
export class ComingSoonViewComponent implements OnInit {
  @Input() title = ''
  @Input() body: string[] = [''];
  @Input() hasImage: boolean = false;
  @Input() imageSrc: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
