<div class="row px-3 py-5">
    <div class="col-9"></div>
    <div class="col-3">
        <button class="close" type="button" (click)="close()"><i class="fa fa-times"></i></button>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 mt-3">
      <button class="d-none"[printTitle]='"Detalle_"+(rut | rutFormat)+"_Flujo"'
        [useExistingCss]="true" printSectionId="print-section-tesoreria" ngxPrint id="printButtonModal">Descargar</button>
      <button class="btn btn-primary float-right mr-5" (click)="descargarPDF()">Descargar</button>
    </div>
  </div>
<div mat-dialog-content>
    <div *ngIf="reporte && rut" id="print-section-tesoreria">
        <div *ngIf="reportType == 'tesoreria'">
            <app-reporte-tesoreria-view [rut]="rut" [reporteFullContent]="reporte"></app-reporte-tesoreria-view>
        </div>
        <div *ngIf="reportType == 'lcv'" class="col-12">
            <app-reporte-compra-venta-view #appreporteview [rut]="rut" ></app-reporte-compra-venta-view>
        </div>
    </div>
</div>