<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>
<div *ngIf="hasUserAccess && !poderJudicialReportDatasource">
    <div class="container__form">
        <div class="col-md-6">
            <div class="block">
                <form [formGroup]="poderJudicialReportForm" (ngSubmit)="createReport()">
                    <div class="form-horizontal form-bordered">
                        <div class="form-group" [ngClass]="{'has-error': activateRutHelper}">
                            <label class="col-md-3 control-label">Rut</label>
                            <div class="col-md-6">
                                <input formControlName="rut" type="text" class="form-control" formatRut required>
                                <span *ngIf="activateRutHelper" class="help-block">Rut no valido</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-md-3 control-label">Incluir DTE</label>
                            <div class="col-md-8">
                                <label class="switch switch-primary">
                                    <input type="checkbox" formControlName="tipo">
                                    <span></span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group form-actions container__actions">
                            <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i>
                                Crear Reporte</button>
                            <button (click)="clearForm()" class="btn btn-sm btn-warning"><i class="fa fa-repeat"></i>
                                Limpiar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="poderJudicialReportDatasource">
    <div class="container-gtp">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="btn-option">
                    <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                    <button (click)="downloadReportSelected()" class="btn btn-primary">Descargar PDF</button>
                </div>
            </div>
        </div>
    </div>
    <app-poder-judicial-report-viewer [content]="poderJudicialReportDatasource" ></app-poder-judicial-report-viewer>
</div>