import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalCredencialesService {

  public display: boolean = false;

  watch(): boolean {
    return this.display;
  }

  open() {
    const body: any = document.getElementsByTagName('body')[0];
    if(body){
      body.style['overflow-y']= "hidden";
    }
    this.display = true;
  }

  close() {
    const body: any = document.getElementsByTagName('body')[0];
    if(body){
      body.style['overflow-y']= "auto";
    }
    this.display = false;
  }
  
  //constructor() { }
}
