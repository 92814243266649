<div class="row mt-3 anexoBox">
    <div class="col-sm-6 mb-5">
        <div class="box-graph">
            <app-line-bars [serie]='serie1' [title]='title1' [categorias]='categoria1' [yAxix]='yAxix1'
                [plotOptions]='plotOptions1'></app-line-bars>
        </div>
    </div>

    <!-- <div class="col-sm-6 mb-5">
        <div class="box-graph">
            <app-line-bars [serie]='serie2' [title]='title2' [categorias]='categoria2' [yAxix]='yAxix2'></app-line-bars>
        </div>
    </div>

    <div class="col-sm-8 mt-3 mb-5">
        <div class="box-graph">
            <app-line-bars [serie]='serie3' [title]='title3' [categorias]='categoria3' [yAxix]='yAxix3'></app-line-bars>
        </div>
    </div> -->
</div>