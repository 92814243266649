import { Directive, HostListener, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Directive({
    selector: 'afterPrint, [afterPrint]'
})
export class AfterPrintDirective {
  @Input() title: string = '';
  
    constructor(private titleService: Title) { }

    @HostListener('window:beforeprint') 
    setTilte() {      
     this.titleService.setTitle(this.title);
    }

    @HostListener('window:afterprint') 
    originalTitle() {
     this.titleService.setTitle('Informax');
    }

}