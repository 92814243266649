<div class="panel panel-default border-0 not-print">
    <div class="panel-body">
        <div class="btn-option">
            <button *ngIf="showReportes && stepper === 5" [printTitle]='"FlujoDeCredito_"+(rut | rutFormat)+"_"+today'
                [useExistingCss]="true" printSectionId="print-section" ngxPrint
                class="btn btn-primary float-right">Descargar PDF</button>
        </div>
    </div>
</div>

<div class="not-print">
    <ul class="nav nav-tabs d-flex justify-content-center border-0 mt-3">
      <li><button id="button-descripcion-negocio" (click)="scroll('descripcion-negocio', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
          <i class="fa fa-line-chart" aria-hidden="true"></i>
          Descripción del Negocio
        </button>
      </li>

      <li><button id="button-riesgo" (click)="scroll('riesgo', $event)" class="border border-buttons shadow p-3 rounded-pill mx-4">
        <i class="fa fa-line-chart" aria-hidden="true"></i>
        Riesgo
      </button>
    </li>
    </ul>
</div>

<div class="col-sm-12 mt-5" id="print-section" style="background-color: white;">
    <div class="row p-3">
      <div class="col-sm-12">
          <div class="row">
            <h3 class="col-12 my-2 mb-4 cuadro-title p-3 rounded-header">
                Ficha Puente Financiero
            </h3>

            <form [formGroup]="servicioFichaForm" class="w-100">
  
                <!-- <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else fichaCabecera"> -->
                <ng-container>
                
                  <div class="row container">
                    <div class="col">
                      <div class="form-group row">
                            <div class="col-12">
                              <label for="nombre-comite" class="col-12 col-form-label">Nombre</label>
                              <div class="col-12">
                                <input  type="text" class="form-control" id="nombre-comite" formControlName="nombreComite">
                                <div class="w-100">
                                  <app-error [control]=""></app-error>
                                </div>
                              </div>
                          </div>
                      </div>
  
                      <div class="form-group row">
                          <div class="col-12">
                            <label for="giro-comite" class="col-12 col-form-label">Giro</label>
                            <div class="col-12">
                              <input type="text" class="form-control" id="giro-comite" formControlName="giroComite">
                              <div class="w-100">
                                <app-error [control]=""></app-error>
                              </div>
                            </div>
                        </div>
                      </div>
  
                      <div class="form-group row">
                          <div class="col-12">
                            <label for="ejecutivo-comite" class="col-12 col-form-label">Ejecutivo/Broker</label>
                            <div class="col-12">
                              <input type="text" class="form-control" id="ejecutivo-broker" formControlName="ejecutivoComite">
                              <div class="w-100">
                                <app-error [control]=""></app-error>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col">
                      <div class="form-group row">
                            <div class="col-12">
                              <label for="rut-comite" class="col-12 col-form-label">Rut</label>
                              <div class="col-12">
                                <input type="text" class="form-control" id="rut-comite" formControlName="rutComite">
                                <div class="w-100">
                                  <app-error [control]=""></app-error>
                                </div>
                              </div>
                          </div>
                      </div>
                      <div class="form-group row">
                          <div class="col-12">
                            <label for="rut-comite" class="col-12 col-form-label">Dirección</label>
                            <div class="col-12">
                              <input type="text" class="form-control" id="direccion" formControlName="direccion">
                              <div class="w-100">
                                <app-error [control]=""></app-error>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-12">
                          <label for="fecha-comite" class="col-12 col-form-label">Fecha</label>
                          <div class="col-12">
                            <input type="text" class="form-control" id="fecha-comite" formControlName="fechaComite">
                            <div class="w-100">
                              <app-error [control]=""></app-error>
                            </div>
                          </div>
                      </div>
                  </div>
                    </div>
                  </div>
                </ng-container>
  
            </form>

            <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('desc-negocio', 'button-desc-negocio')">
              Descripción del Negocio
            </h3>
            <div class="container-desplegables" id="desc-negocio" style="width: 100%;">
                <div class="row p-5">
                  <div class="col-sm-12">
                    <form [formGroup]="deudoresForm">

                        <div class="table-responsive" formArrayName="elements">
                          <table class="table table-hover table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" class="text-center py-1 bg-primary font-head-size">Monto solicitado</th>
                                <th scope="col" class="text-center py-1 bg-primary font-head-size">Plazo</th>
                                <th scope="col" class="text-center py-1 bg-primary font-head-size">Cuotas</th>
                                <th scope="col" class="text-center py-1 bg-primary font-head-size">Deudor</th>
                                <th scope="col" class="text-center py-1 bg-primary font-head-size">Rut</th>
                                <th scope="col" class="text-center py-1 bg-primary font-head-size">Tipo</th>
                                <th scope="col" class="text-center py-1 bg-primary font-head-size">Motivo</th>
                              </tr>
                            </thead>
                            <tr *ngFor="let element of deudoresFormArray.controls; let i = index" [formGroupName]="i">
                              <td><input class="no-border" id="montoSolicitado-{{i}}" formControlName="montoSolicitado" type="text" /></td>
                              <td><input class="no-border" id="plazo-{{i}}" formControlName="plazo" type="text" /></td>
                              <td><input class="no-border" id="cuotas-{{i}}" formControlName="cuotas" type="text" /></td>
                              <td>{{element.get('deudor')?.value}}</td>
                              <td>{{element.get('rut')?.value}}</td>
                              <td>
                                <select formControlName="tipo">
                                  <option *ngFor="let tipoOption of tipoOptions" [value]="tipoOption">{{ tipoOption }}</option>
                                </select>
                              </td>
                              <td>
                                <select formControlName="motivo">
                                  <option *ngFor="let motivoOption of motivoOptions" [value]="motivoOption">{{ motivoOption }}</option>
                                </select>
                              </td>
                            </tr>
                          </table>
                        </div>
                    </form>

                    <!-- Detalle Socios -- Relación Cliente-Ejecutivo -->
                    <div class="row mt-3">

                      <div class="col">
                        <h3 class="">Detalle Socios</h3>
                        <form [formGroup]="sociosForm">
                          <div class="table-responsive" formArrayName="elements">
                            <table class="table table-hover table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col" class="text-center py-1 bg-primary font-head-size">Nombre</th>
                                  <th scope="col" class="text-center py-1 bg-primary font-head-size">Rut</th>
                                  <th scope="col" class="text-center py-1 bg-primary font-head-size">%Participación</th>
                              </thead>
                              <tr *ngFor="let element of sociosFormArray.controls; let i = index" [formGroupName]="i">
                                <td>{{element.get('nombre')?.value}}</td>
                                <td>{{element.get('rut')?.value}}</td>
                                <td><input class="no-border" type="text" formControlName="participacion"/></td>
                              </tr>
                            </table>
                          </div>
                        </form>
                      </div>
  
                      
                    </div>
                    
                    <form [formGroup]="descripcionesForm">
                      <div class="col">
                        <div class="form-group">
                          <label for="exampleTextarea">Relación Cliente-Ejecutivo</label>
                          <textarea formControlName="relCliEjec"  class="form-control " id="exampleTextarea" rows="3"></textarea>
                          <div class="">
                          </div>
                        </div>
                      </div>
  
                      <!-- Detalle Del Negocio -->
                      <div class="col mt-3">
                        <div class="form-group">
                          <label for="exampleTextarea">Detalle del Negocio</label>
                          <textarea formControlName="detalleNegocio" class="form-control" id="exampleTextarea" rows="3"></textarea>
                          <div class="">
                          </div>
                        </div>
                      </div>
  
                      <div class="col mt-3">
                        <div class="form-group">
                          <label for="exampleTextarea">Experiencia del Cliente</label>
                          <textarea formControlName="expCliente" class="form-control" id="exampleTextarea" rows="3"></textarea>
                          <div class="">
                          </div>
                        </div>
                      </div>
                    </form>

                    <!-- Experiencia del Cliente -->

                  </div>
                </div>
            </div>


            <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('riesgo', 'button-desc-negocio')">
              Riesgo
            </h3>
            <div class="container-desplegables" id="riesgo" style="width: 100%;">

              <div class="row" [formGroup]="datosRiesgoForm">
                <div class="col">
                  <table style="cursor: pointer;" class="table table-hover table-bordered" (click)="abrirModalTitular()">
                    <tr>
                      <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Inicio Actividades</td> <td>{{datosRiesgoForm.get('inicioActividades')?.value}}</td>
                      <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Meses</td> <td>{{datosRiesgoForm.get('anosMeses')?.value}}</td>
                    </tr>
                    <tr>
                      <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Promedio de Ventas 12M M$</td> <td>{{datosRiesgoForm.get('promedioVentas12M')?.value | number:'1.0-0'}}</td>
                      <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Margen Mensual M$</td> <td>{{datosRiesgoForm.get('margenMensual')?.value | number:'1.0-0'}}</td>
                    </tr>
                    <tr>
                      <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Lagunas o no declarados</td> <td>{{datosRiesgoForm.get('lagunas')?.value}}</td>
                      <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Alza o Baja ventas</td> <td>{{datosRiesgoForm.get('alzaBaja')?.value}}</td>
                    </tr>
                    <tr>
                      <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Vehículos ($)</td> <td>{{datosRiesgoForm.get('vehiculos')?.value | number:'1.0-2'}}</td>
                      <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Propiedades ($)</td> <td>{{datosRiesgoForm.get('propiedades')?.value | number:'1.0-2'}}</td>
                    </tr>
                  </table>
                </div>

                <div class="col">
                  <table style="cursor:pointer;" class="table table-hover table-bordered" (click)="openDialogTesoreriaPrincipal()">
                    <tr>
                      <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Morosidad en TGR M$</td> <td>{{datosRiesgoForm.get('morosidad')?.value | number:'1.0-0'}}</td>
                    </tr>
                    <tr>
                      <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Notificación/Embargo</td> <td>{{datosRiesgoForm.get('notificacionEmbargo')?.value}}</td>
                    </tr>
                    <tr>
                      <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Deuda en Convenio M$</td> <td>{{datosRiesgoForm.get('deudaConvenio')?.value | number:'1.0-0' }}</td>
                    </tr>
                    <tr>
                      <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Cuotas Morosas</td> <td>{{datosRiesgoForm.get('cuotasMorosas')?.value}}</td>
                    </tr>
                  </table>
                </div>
              </div>


                <!-- DICOM -->
                <div *ngIf="type == 'ct'" class="table-responsive mt-5">
                  <!-- <div class="table-responsive"> -->
                    <table style="cursor:pointer" class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" colspan="6">DICOM</th>
                        </tr>
                        <tr>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" >Tipo</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" >Nombre</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" >Rut</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" >Número</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" >Monto M$</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" >Motivo</th>
                        </tr>
                      </thead>
                      <tr (click)="abrirModalTitular()">
                        <th scope="col" class="text-center py-1 bg-primary font-head-size">Empresa</th>
                        <td>{{dicomEmpresa.nombre}}</td>
                        <td>{{dicomEmpresa.rut}}</td>
                        <td>{{dicomEmpresa.numero}}</td>
                        <td>{{dicomEmpresa.monto | number:'1.0-2'}}</td>
                        <td>{{dicomEmpresa.motivo}}</td>
                      </tr>
                      <tr *ngFor="let socio of dicomSocios" (click)="abrirModalSocio(socio.rut)">
                        <th scope="col" class="text-center py-1 bg-primary font-head-size">Socio</th>
                        <td>{{socio.nombre}}</td>
                        <td>{{socio.rut}}</td>
                        <td>{{socio.numero}}</td>
                        <td>{{socio.monto}}</td>
                        <td>{{socio.motivo}}</td>
                      </tr>
                      <tr *ngFor="let deudor of dicomDeudores" (click)="abrirModalDeudor(deudor.rut)">
                        <th scope="col" class="text-center py-1 bg-primary font-head-size" >Deudor</th>
                        <td>{{deudor.nombre}}</td>
                        <td>{{deudor.rut}}</td>
                        <td>{{deudor.numero}}</td>
                        <td>{{deudor.monto}}</td>
                        <td>{{deudor.motivo}}</td>
                      </tr>
                    </table>
                  <!-- </div> -->
                </div>

                <div class="row mt-5">
                  <form [formGroup]="operadoEnPuente">
                    <div class="col">
                      <div class="table-responsive">
                        <table class="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <th scope="col" class="text-center py-1 bg-primary font-head-size" colspan="4">Operado en Puente</th>
                            </tr>
                          </thead>
                          <tr>
                            <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Factoring Tradicional ($)</td>
                            <td style="width: 25%;"> <input class="no-border" formControlName="factoringTradicional" type="text" /> </td>
                            <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Pagado ($)</td>
                            <td style="width: 25%;" > <input class="no-border" formControlName="pagadoFact" type="text" /> </td>
                          </tr>
                          <tr>
                            <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">KT ($)</td>
                            <td style="width: 25%;"> <input class="no-border" formControlName="kt" type="text" /> </td>
                            <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Pagado ($)</td>
                            <td style="width: 25%;"> <input class="no-border" formControlName="pagadoKt" type="text" /> </td>
                          </tr>
                          <tr>
                            <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Morosidad interna ($)</td>
                            <td style="width: 25%;"> <input class="no-border" formControlName="morosidadInterna" type="text" /> </td>
                            <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Días</td>
                            <td style="width: 25%;"> <input class="no-border" formControlName="dias" type="text" /> </td>
                          </tr>
                          <tr>
                            <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Reclamos total Factorizado ($)</td>
                            <td style="width: 25%;"> <input class="no-border" formControlName="reclamos" type="text" /> </td>
                            <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Promedio mora</td>
                            <td style="width: 25%;"> <input class="no-border" formControlName="promedioMora" type="text" /> </td>
                          </tr>
                          <tr>
                            <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Notas de Créditos Totales</td>
                            <td style="width: 25%;"> <input class="no-border" formControlName="ncTotales" type="text" /> </td>
                            <td style="width: 25%;" class="text-center py-1 bg-primary font-head-size">Mora Máxima</td>
                            <td style="width: 25%;"> <input class="no-border" formControlName="moraMaxima" type="text" /> </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </form>
                  <div class="col">
                    <div class="table-responsive">
                      <table style="cursor: pointer;" class="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size" colspan="5">Deuda SBIF M$</th>
                          </tr>
                          <tr>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size">Cliente/Socios</th>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size">Vigente</th>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size">Morosa</th>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size">Indirecta</th>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size">LC</th>
                          </tr>
                        </thead>
                        <tr (click)="abrirModalTitular()">
                          <td>{{sbifEmpresa.nombre}}</td>
                          <td>{{sbifEmpresa.vigente | number:'1.0'}}</td>
                          <td>{{sbifEmpresa.morosa | number:'1.0'}}</td>
                          <td>{{sbifEmpresa.indirecta | number:'1.0'}}</td>
                          <td>{{sbifEmpresa.lc | number:'1.0'}}</td>
                        </tr>
                        <tr *ngFor="let socio of sbifSocios" (click)="abrirModalSocio(socio.rut)">
                          <td>{{socio.nombre}}</td>
                          <td>{{socio.vigente | number:'1.0'}}</td>
                          <td>{{socio.morosa | number:'1.0'}}</td>
                          <td>{{socio.indirecta | number:'1.0'}}</td>
                          <td>{{socio.lc | number:'1.0'}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                <div *ngIf="type == 'pre-factoring'" class="row mt-5 d-flex align-items-center justify-content-center"  >
                  <div class="col" style="max-width: 500px;">
                    <div class="row">
                      <div class="col">
                        <a class="widget widget-hover-effect1 no-print">
                          <div class="widget-extra" style="background-color: #4C93C2;">
                              <h4 class="widget-content-light text-center">Causas Judiciales Socios</h4>
                          </div>
                          <div class="widget-simple overflow-auto">
                              <h1 class="widget-content text-right animation-pullDown d-flex align-items-center justify-content-center" style="font-size: 20px;">
                                  <strong style="color: #4C93C2;">{{juiciosSocios}}</strong><br>
                              </h1>
                          </div>
                        </a>
                      </div>
                      <div class="col">
                        <a class="widget widget-hover-effect1 no-print">
                          <div class="widget-extra" style="background-color: #4C93C2;">
                              <h4 class="widget-content-light text-center">Causas Judiciales Empresa</h4>
                          </div>
                          <div class="widget-simple overflow-auto">
                              <h1 class="widget-content text-right animation-pullDown d-flex align-items-center justify-content-center" style="font-size: 20px;">
                                  <strong style="color: #4C93C2;">{{juiciosEmpresa}}</strong><br>
                              </h1>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <table style="cursor:pointer" class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" colspan="6">DICOM</th>
                        </tr>
                        <tr>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" >Tipo</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" >Nombre</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" >Rut</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" >Número</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" >Monto M$</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" >Motivo</th>
                        </tr>
                      </thead>
                      <tr (click)="abrirModalTitular()">
                        <th scope="col" class="text-center py-1 bg-primary font-head-size">Empresa</th>
                        <td>{{dicomEmpresa.nombre}}</td>
                        <td>{{dicomEmpresa.rut}}</td>
                        <td>{{dicomEmpresa.numero}}</td>
                        <td>{{dicomEmpresa.monto | number:'1.0-2'}}</td>
                        <td>{{dicomEmpresa.motivo}}</td>
                      </tr>
                      <tr *ngFor="let socio of dicomSocios" (click)="abrirModalSocio(socio.rut)">
                        <th scope="col" class="text-center py-1 bg-primary font-head-size">Socio</th>
                        <td>{{socio.nombre}}</td>
                        <td>{{socio.rut}}</td>
                        <td>{{socio.numero}}</td>
                        <td>{{socio.monto}}</td>
                        <td>{{socio.motivo}}</td>
                      </tr>
                      <tr *ngFor="let deudor of dicomDeudores" (click)="abrirModalDeudor(deudor.rut)">
                        <th scope="col" class="text-center py-1 bg-primary font-head-size" >Deudor</th>
                        <td>{{deudor.nombre}}</td>
                        <td>{{deudor.rut}}</td>
                        <td>{{deudor.numero}}</td>
                        <td>{{deudor.monto}}</td>
                        <td>{{deudor.motivo}}</td>
                      </tr>
                    </table>
                  </div>
                </div>

                <h3 class="mt-5">Detalle deuda SBIF M$</h3>
                <div style="cursor: pointer;" class="table-responsive">
                  <table class="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" class="text-center py-1 bg-primary font-head-size">Nombre</th>
                        <th *ngFor="let periodo of periodosSbifDetalle" scope="col" class="text-center py-1 bg-primary font-head-size">{{periodo}}</th>
                      </tr>
                    </thead>
                    <tr (click)="abrirModalTitular()">
                      <!-- Nombre -->
                      <td *ngFor="let value of valoresSbifDetalleEmpresa;let i = index "><span *ngIf="i == 0">{{value}}</span> <span *ngIf="i > 0">{{value | number: '1.0-0'}}</span></td>
                    </tr>
                    <tr *ngFor="let socioRow of sbifDetalleSocios" (click)="abrirModalSocio(socioRow[0].rut)">
                      <td *ngFor="let item of socioRow;let i = index "> <span *ngIf="i == 0">{{item.name}}</span> <span *ngIf="i > 0">{{item | number: '1.0-0'}}</span></td>

                      <!-- <td *ngFor="let value of sbifDetalleSocios[socio]">{{value}}</td> -->
                    </tr>
                  </table>
                  <!-- <div [chart]="graphEvLinea" class="graphContent"></div> -->

                  <!-- <h1>ACA VA UN Gráfico</h1> -->
                </div>

                <!-- Factoring con que opera -->
                <div *ngIf="type == 'pre-factoring'" class="mt-5">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" colspan="9">Factoring con que opera <i class="fa fa-info-circle pointer-cursor ml-1 not-print" aria-hidden="true"></i></th>
                        </tr>
                        <tr>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Rut</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Nombre</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Número de Documentos</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Principal Deudor</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size" colspan="3">Monto en M$</th>
                        </tr>
                        
                        <tr>
                          <!-- <th scope="col" class="text-center py-1 bg-primary font-head-size" colspan="2"></th> -->
                          <th scope="col" class="text-center py-1 bg-primary font-head-size">3 Meses</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size">6 Meses</th>
                          <th scope="col" class="text-center py-1 bg-primary font-head-size">12 Meses</th>
                        </tr>
                      </thead>
                      <ng-container *ngFor="let item of factoring; let i = index">
                        <tr>
                          <td>{{item.CESIONARIO | rutFormat}}</td>
                          <td>{{item.rz_cesionario}}</td>
                          <td>{{item.nDocs}}</td>
                          <td>{{item.ppalDeudor}}</td>
                          <td>{{item.monto3Meses !== ''? (item.monto3Meses | number: '1.0-0'): item.monto3Meses}}</td>
                          <td>{{item.monto6Meses !== ''? (item.monto6Meses | number: '1.0-0'): item.monto6Meses}}</td>
                          <td>{{item.Monto12Meses !== ''? (item.Monto12Meses | number: '1.0-0'): item.Monto12Meses}}</td>
                        </tr>
                      </ng-container>
                    </table>
                  </div>
                </div>

                <div *ngIf="type == 'pre-factoring'" class="mt-5">
                  <form [formGroup]="clasificacionDeudoresForm">
                    <div class="table-responsive" formArrayName="elements">
                      <table class="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th style="cursor: pointer;" scope="col" class="text-center py-1 bg-primary font-head-size" colspan="10" (click)="openDialogLcvPrincipal()">Clasificación de Deudor</th>
                          </tr>
                        </thead>
                        <tr>
                          <td style="width: 25%;" scope="col" class="text-center py-1 bg-primary font-head-size">Nombre deudor</td>
                          <td style="width: 25%;" scope="col" class="text-center py-1 bg-primary font-head-size">Clasificación acordada</td>
                          <td style="width: 25%;" scope="col" class="text-center py-1 bg-primary font-head-size">Relación Dupla superior a 12M</td>
                          <td style="width: 25%;" scope="col" class="text-center py-1 bg-primary font-head-size">Monto Relación 12M</td>
                          <td style="width: 25%;" scope="col" class="text-center py-1 bg-primary font-head-size">Morosidad Puente Deudor</td>
                          <td style="width: 25%;" scope="col" class="text-center py-1 bg-primary font-head-size">Morosidad días</td>
                          <td style="width: 25%;" scope="col" class="text-center py-1 bg-primary font-head-size">% de Facturación dupla</td>
                          <td style="width: 25%;" scope="col" class="text-center py-1 bg-primary font-head-size">% NC dupla</td>
                          <td style="width: 25%;" scope="col" class="text-center py-1 bg-primary font-head-size">#Facturas</td>
                          <td style="width: 25%;" scope="col" class="text-center py-1 bg-primary font-head-size">Monto operado</td>
                        </tr>
                        <tr *ngFor="let element of calsificacionDeudoresFormArray.controls; let i = index" [formGroupName]="i">
                          <td>{{element.get('nombreDeudor')?.value}}</td>
                          <td><input type="text" formControlName="clasificacionAcordada"></td>
                          <td>{{element.get('dupla12M')?.value}}</td>
                          <td>{{element.get('monto12M')?.value | number:'1.0-0'}}</td>
                          <td><input type="text" formControlName="morosidadPD"></td>
                          <td><input type="text" formControlName="morosidadDias"></td>
                          <td>{{element.get('facturacionDupla')?.value | percent: '1.2-2'}}</td>
                          <td>{{element.get('ncDupla')?.value | number: '1.2-2'}} %</td>
                          <td>{{element.get('nFacturas')?.value}}</td>
                          <td><input type="text" formControlName="montoOperado"></td>
                        </tr>
                      </table>
                    </div>
                  </form>
                </div>

                <div *ngIf="type == 'ct'">
                  <h3 class="mt-5">Detalle Deuda Actual</h3>
                  <form [formGroup]="detalleDeudaArray">
                    <div class="table-responsive" formArrayName="myFormArray">
                      <table class="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size">Entidad</th>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size">CXP</th>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size">Valor Cuota Mensual</th>
                            <th scope="col" class="text-center py-1 bg-primary font-head-size">Valor Cuota Anual</th>
                            <th *ngIf="detalleDeudaArray.enabled" scope="col" class="text-center py-1 bg-primary font-head-size"></th>
                          </tr>
                        </thead>
                        <tr *ngFor="let group of myFormArray.controls; let i = index" [formGroupName]="i">
                          <td>
                            <input formControlName="entidad" placeholder="Entidad">
                          </td>
                          <td>
                            <input formControlName="cxp" placeholder="CXP">
                          </td>
                          <td>
                            <input formControlName="vcm" placeholder="VCM">
                          </td>
                          <td>
                            <input formControlName="vca" placeholder="VCA">
                          </td>
                          <td *ngIf="detalleDeudaArray.enabled" class="text-primary" style="font-size: 25px; cursor: pointer; max-width: 55px;"> 
                            <i *ngIf="i == myFormArray.controls.length - 1 && i != 0" style="font-size: 17px;" class="fa fa-trash ml-3" aria-hidden="true" (click)="removeFormGroup(i)"></i> 
                            <i style="font-size: 17px;" class="fa fa-plus ml-3" aria-hidden="true" (click)="addFormGroup()"></i>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </form>
                </div>

                <div *ngIf="type == 'ct'" class="row mt-5">
                  <div class="col-6">
                    <!-- <h3>Proyección Próximos Meses</h3> -->
                     <form [formGroup]="proyeccionForm">
                       <div class="table-responsive">
                         <table class="table table-hover table-bordered">
                           <thead>
                             <tr>
                               <th scope="col" class="text-center py-1 bg-primary font-head-size" colspan="6">Proyección Próximos Meses</th>
                             </tr>
                             <tr>
                               <th scope="col" class="text-center py-1 bg-primary font-head-size"></th>
                               <th scope="col" class="text-center py-1 bg-primary font-head-size">1</th>
                               <th scope="col" class="text-center py-1 bg-primary font-head-size">2</th>
                               <th scope="col" class="text-center py-1 bg-primary font-head-size">3</th>
                               <th scope="col" class="text-center py-1 bg-primary font-head-size">4</th>
                               <th scope="col" class="text-center py-1 bg-primary font-head-size">5</th>
                             </tr>
                           </thead>
                           <tr>
                             <td scope="col" class="text-center py-1 bg-primary font-head-size">Total deuda Bancaria</td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="totalDeudaB1" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="totalDeudaB2" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="totalDeudaB3" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="totalDeudaB4" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="totalDeudaB5" type="text" /></td>
                           </tr>
                           <tr>
                             <td scope="col" class="text-center py-1 bg-primary font-head-size">Total deuda Leasing</td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="totalDeudaL1" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="totalDeudaL2" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="totalDeudaL3" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="totalDeudaL4" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="totalDeudaL5" type="text" /></td>
                           </tr>
                           <tr>
                             <td scope="col" class="text-center py-1 bg-primary font-head-size">Margen antes cuota Puente</td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="margen1" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="margen2" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="margen3" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="margen4" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="margen5" type="text" /></td>
                           </tr>
                           <tr>
                             <td scope="col" class="text-center py-1 bg-primary font-head-size">Cuota Puente</td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="cuota1" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="cuota2" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="cuota3" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="cuota4" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="cuota5" type="text" /></td>
                           </tr>
                           <tr>
                             <td scope="col" class="text-center py-1 bg-primary font-head-size">Flujo libre operacional</td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="flujo1" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="flujo2" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="flujo3" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="flujo4" type="text" /></td>
                             <td><input style="max-width: 35px;" class="no-border" formControlName="flujo5" type="text" /></td>
                           </tr>
                         </table>
                       </div>
                     </form>
                  </div>
                  <div class="col">
                    <form [formGroup]="operacionKtForm">
                      <div class="table-responsive">
                        <table class="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <th scope="col" class="text-center py-1 bg-primary font-head-size" colspan="2">Operación de KT</th>
                            </tr>
                          </thead>
                          <tr>
                            <td scope="col" class="text-center py-1 bg-primary font-head-size">Valor a financiar</td>
                            <td><input class="no-border" formControlName="valor" type="text" /></td>
                          </tr>
                          <tr>
                            <td scope="col" class="text-center py-1 bg-primary font-head-size">Tasa</td>
                            <td><input class="no-border" formControlName="tasa" type="text" /></td>
                          </tr>
                          <tr>
                            <td scope="col" class="text-center py-1 bg-primary font-head-size">Cuotas</td>
                            <td><input class="no-border" formControlName="cuotas" type="text" /></td>
                          </tr>
                          <tr>
                            <td scope="col" class="text-center py-1 font-head-size">Cuota</td>
                            <td><input class="no-border" formControlName="cuota" type="text" /></td>
                          </tr>
                        </table>
                      </div>
                    </form>
                  </div>
                </div>
              
                <div class="col mt-5">
                  <div class="form-group">
                    <label for="exampleTextarea">Resolución</label>
                    <textarea [formControl]="resolucionControl" class="form-control " id="exampleTextarea" rows="3"></textarea>
                    <div class="">
                    </div>
                  </div>
                </div>
              </div>
            </div>

      </div>
    </div>
</div>

<ng-container *ngIf="plantillaNoEditable === false">

  <ng-template #botonFinalizar>
    <div class="not-print">
      <ng-container *ngIf="validaUsuarioAdmin()">
        <div class="col-12 d-flex justify-content-center pt-4">
          <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="volverEtapaPrevia()">
            Devolver Etapa Previa
          </button>
          <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="continuar()">
            Continuar
          </button>
        </div>
      </ng-container>
    </div>
  </ng-template>
  
  <ng-container *ngIf="stepper === 4 else botonFinalizar">
  
    <div class="col-12 d-flex justify-content-center pt-4">
      <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="guardarFicha()">
        Guardar
      </button>
      <button class="btn btn-sm btn-primary mx-2 rounded-header" [disabled]="objectKeys(fichaGuardada).length > 0 ? null : true" (click)="avanzarFlujo()">
        Continuar
      </button>
    </div>
  
  </ng-container>
</ng-container>

<app-modal-consolidado-workflow [descargar]="true" [rut]="rutSeleccionado" [idTransaccion]="idTransaccion" [dataServicios]="reportesSeleccionado" [informeComercial]="informeComercial" [default]="modalDefault" [plantilla]="plantillaAntecedentes"></app-modal-consolidado-workflow>
