<div class="container-gtp mb-5">
    <img class="banner-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt=""/>
    <h2 class="text-center pt-3">REPORTE FINANCIERO CONSOLIDADO</h2>
    <h5 class="text-center">
        <span class="badge bg-secondary text-center">
            <span *ngIf="rut">
                {{rut | rutFormat | uppercase}}
            </span>
        </span>
    </h5>

    <div class="row" *ngIf="informacionBalance.length == 0 && reporteBasico.length != {}">
        <div class="col-sm-12">
            <div class="card textos-campo">
                <h5 class="card-header list-group-item cuadro-color">Sujeto Credito</h5>
                <div class="card-body">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table" id="table-sujeto-credito">
                                <tbody>
                                    <tr>
                                        <th>Rut</th>
                                        <td colspan="2">
                                            <span *ngIf="rut">
                                                {{rut | rutFormat | uppercase}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="30%">Nombre/Razon Social</th>
                                        <td>
                                            <div class="row mr-0">
                                                <span class="col-12">
                                                    {{ reporteBasico.NombreORazonSocial }}
                                                </span>
                                                <span class="col-12 campo-cursiva">
                                                    {{ tipoPersona }}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="30%">Fecha Inicio Actividad</th>
                                        <td>
                                            <span>
                                                {{ reporteBasico.AnoInicioActividades | date: 'dd-MM-yyyy' }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="break-box" *ngIf="informacionBalance.length > 0">
        <div class="row">
            <!-- TABLA Informacion Balance -->
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="card-header list-group-item cuadro-color">Información Balance</h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table" id="table-balance">
                                    <tbody>
                                        <tr>
                                            <th>Rut</th>
                                            <td [attr.colspan]="informacionBalance.length">
                                                <span *ngIf="rut">
                                                    {{rut | rutFormat | uppercase}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th width="30%">Nombre/Razon Social</th>
                                            <td [attr.colspan]="informacionBalance.length">
                                                <div class="row mr-0">
                                                    <span class="col-12">
                                                        {{ reporteBasico.NombreORazonSocial }}
                                                    </span>
                                                    <span class="col-12 campo-cursiva">
                                                        {{ tipoPersona }}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th width="30%">Fecha Inicio Actividad</th>
                                            <td [attr.colspan]="informacionBalance.length">
                                                <span>
                                                    {{ reporteBasico.AnoInicioActividades | date: 'dd-MM-yyyy' }}
                                                </span>
                                            </td>
                                        </tr>
                                        <ng-container *ngFor="let item of headerBalance">
                                            <tr>
                                                <th width="30%">{{balanceReporteFullInformacionBalance[item]}}</th>
                                                <td *ngFor="let data of informacionBalance">
                                                    <span *ngIf="data.calculado">
                                                        {{data.calculado[item]}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- TABLA Resumen Financiero -->

            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="card-header list-group-item cuadro-color">Resumen Financiero</h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table" id="table-financiero">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of informacionBalance">
                                                <ng-container *ngIf="data.calculado">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of headerResumenFinanciero">
                                            <tr>
                                                <th width="30%">{{balanceReporteFullResumenFinanciero[item]}}</th>
                                                <td *ngFor="let data of informacionBalance"
                                                    [attr.width]="70/informacionBalance.length + '%'">
                                                    <span *ngIf="data.calculado">
                                                        <ng-template #percent>
                                                            {{data.calculado[item] | number: '1.0-2'}}
                                                        </ng-template>

                                                        <ng-container *ngIf="!getPercents(item); else percent">
                                                            {{data.calculado[item] | number: '1.0-0'}}
                                                        </ng-container>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="break-box" *ngIf="informacionBalance.length > 0">
        <div class="row">
            <!-- TABLA Activos -->
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="card-header list-group-item cuadro-color">Activos</h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table" id="table-activos">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of informacionBalance">
                                                <ng-container *ngIf="data.calculado">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                                    <th class="border-0 py-1 fw-bolder"></th>
                                                </ng-container>
                                                
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0"></th>
                                            <ng-container *ngFor="let item of informacionBalance">
                                                <th class="border-0 fw-bolder" *ngIf="item.calculado.moneda.toUpperCase() === 'PESOS'">Monto en $</th>
                                                <th class="border-0 fw-bolder" *ngIf="item.calculado.moneda.toUpperCase() === 'DOLARES'">Monto en US $</th>
                                                <th class="border-0 fw-bolder">Valor %</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of headerResumenActivos">
                                            <tr>
                                                <th width="30%"><span
                                                        [ngClass]="{'fw-bolder text-uppercase': getStrongTitle(item[0])}">{{balanceReporteFullActivos[item[0]]}}</span>
                                                </th>
                                                <ng-container *ngFor="let data of informacionBalance">
                                                    <td>
                                                        <span *ngIf="data.calculado">
                                                            {{data.calculado[item[0]] | number: '1.0-0'}}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="data.calculado">
                                                            {{data.calculado[item[1]] | number: '1.0-2'}}
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- TABLA Pasivos -->

            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="card-header list-group-item cuadro-color">Pasivos</h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table" id="table-pasivos">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of informacionBalance">
                                                <ng-container *ngIf="data.calculado">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                                    <th class="border-0 py-1 fw-bolder"></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0"></th>
                                            <ng-container *ngFor="let item of informacionBalance">
                                                <th class="border-0 fw-bolder" *ngIf="item.calculado.moneda.toUpperCase() === 'PESOS'">Monto en $</th>
                                                <th class="border-0 fw-bolder" *ngIf="item.calculado.moneda.toUpperCase() === 'DOLARES'">Monto en US $</th>
                                                <th class="border-0 fw-bolder">Valor %</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of headerResumenPasivos">
                                            <tr>
                                                <th width="30%"><span
                                                        [ngClass]="{'fw-bolder text-uppercase': getStrongTitle(item[0])}">{{balanceReporteFullPasivos[item[0]]}}</span>
                                                </th>
                                                <ng-container *ngFor="let data of informacionBalance">
                                                    <td>
                                                        <span *ngIf="data.calculado">
                                                            {{data.calculado[item[0]] | number: '1.0-0'}}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="data.calculado">
                                                            {{data.calculado[item[1]] | number: '1.0-2'}}
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="break-box" *ngIf="informacionBalance.length > 0">
        <div class="row">
            <!-- TABLA Estado de Resultados -->
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="card-header list-group-item cuadro-color">Estado de Resultados</h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table" id="table-estados">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of informacionBalance">
                                                <ng-container *ngIf="data.calculado">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                                    <th class="border-0 py-1 fw-bolder"></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0"></th>
                                            <ng-container *ngFor="let item of informacionBalance">
                                                <th class="border-0 fw-bolder" *ngIf="item.calculado.moneda.toUpperCase() === 'PESOS'">Monto en $</th>
                                                <th class="border-0 fw-bolder" *ngIf="item.calculado.moneda.toUpperCase() === 'DOLARES'">Monto en US $</th>
                                                <th class="border-0 fw-bolder">Valor %</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of headerResumenEstadoResultados">
                                            <tr>
                                                <th width="30%">{{balanceReporteFullEstadoResultados[item[0]]}}</th>
                                                <ng-container *ngFor="let data of informacionBalance">
                                                    <td>
                                                        <span *ngIf="data.calculado">
                                                            {{data.calculado[item[0]] | number: '1.0-0'}}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="data.calculado">
                                                            {{data.calculado[item[1]] | number: '1.0-2'}}
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- TABLA Indicadores Financieros -->

            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="card-header list-group-item cuadro-color">Indicadores Financieros</h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table" id="table-indicadores">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of informacionBalance">
                                                <ng-container *ngIf="data.calculado">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculado.fecha || ''}}</span></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of headerResumenIndicadoresFinancieros">
                                            <tr>
                                                <th width="30%">
                                                    <span
                                                        [ngClass]="{'fw-bolder text-uppercase': getStrongTitle(item)}">
                                                        {{balanceReporteFullIndicadoresFinancieros[item]}}
                                                    </span>
                                                </th>
                                                <td *ngFor="let data of informacionBalance"
                                                    [attr.width]="70/informacionBalance.length + '%'">
                                                    <span *ngIf="data.calculado">
                                                        <ng-template #percent>
                                                            {{ (!isNaN(data.calculado[item]) ? data.calculado[item] : '') | number: '1.0-2'}}
                                                        </ng-template>

                                                        <ng-container *ngIf="!getPercents(item); else percent">
                                                            {{data.calculado[item] | number: '1.0-0'}}
                                                        </ng-container>

                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="break-box" *ngIf="reporte.length > 0">
        <div class="row" *ngFor="let elemento of reporte; let i = index" [attr.data-index]="i" [attr.saltoLinea]="i%2===0 && i!==0 ? true : null">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" (click)="clickHeader(elemento.periodo)" [attr.valueiconl]="'icon-l-f29-' + elemento.periodo" [attr.valueicond]="'icon-d-f29-' + elemento.periodo" [attr.valuetarget]="'collapsable-f29-' + elemento.periodo" >
                        Ventas {{ elemento.periodo }}
                        <i class="fa fa-angle-left pull-right mr-2" id="icon-l-f29-{{ elemento.periodo }}" hidden></i>
                        <i class="fa fa-angle-down pull-right mr-2" id="icon-d-f29-{{ elemento.periodo }}"></i>
                    </h5>
                    <div class="card-body" id="collapsable-f29-{{ elemento.periodo }}">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-bordered" id="table-{{elemento.periodo}}">
                                    <thead>
                                        <tr>
                                            <th class="border-0"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Mes</th>
                                            <th>Ventas Netas M$</th>
                                            <th>Ventas Netas UF</th>
                                            <th>Compras Netas M$</th>
                                            <th>Compras Netas UF</th>
                                            <th>Margen M$</th>
                                            <th>% NC / Ventas</th>
                                            <th>Fecha Pago</th>
                                            <th>Tipo Declaración</th>
                                            <th>Postergado</th>
                                            <th>Variación % Ventas</th>
                                          </tr>
                                          <tr *ngFor="let item of elemento.datosReporte"><th>{{item.nombreMes}}</th>
                                            <td><span>{{ item.ventasNetasMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.ventasNetasUF | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.comprasNetasMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.comprasNetasUf | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.margenMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.porc_notascred_ventas | number:'1.0-2'}}</span></td>
                                            <td class="text-nowrap"><span>{{ item.fechaPago}}</span></td>
                                            <td><span>{{ item.tipoDeclaracion}}</span></td>
                                            <td><span>{{ item.postergado | number:'1.0-1'}}</span></td>
                                            <td><span>{{ item.variacion | number:'1.0-2'}}</span></td>
                                        </tr>
                                          <tr><th>Totales</th>
                                            <td><span>{{ elemento.totalVentasNetasMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalVentasNetasUF | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalComprasNetasMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalComprasNetasUF | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalMargenMiles | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalPorcVentasMiles | number:'1.0-2'}}</span></td>
                                            <td></td>
                                            <td></td>
                                            <td><span>{{ elemento.totalPostergado | number:'1.0-1'}}</span></td>
                                            <td><span>{{ elemento.totalVarVentas | number:'1.0-2'}}</span></td>
                                        </tr>
                                          <tr><th>Promedio</th>
                                            <td><span>{{ (elemento.totalVentasNetasMiles/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalVentasNetasUF/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalComprasNetasMiles/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalComprasNetasUF/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalMargenMiles/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalPorcVentasMiles/elemento.mesesEnPeriodo) | number:'1.0-2'}}</span></td>
                                            <td></td>
                                            <td></td>
                                            <td><span>{{ (elemento.totalPostergado/elemento.mesesEnPeriodo) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ (elemento.totalVarVentas/elemento.mesesEnPeriodo) | number:'1.0-2'}}</span></td>
                                        </tr>
                                          <tr><th>Proyección</th>
                                            <td><span>{{ ((elemento.totalVentasNetasMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalVentasNetasUF/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalComprasNetasMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalComprasNetasUF/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalMargenMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalPorcVentasMiles/elemento.mesesEnPeriodo)*12) | number:'1.0-2'}}</span></td>
                                            <td></td>
                                            <td></td>
                                            <td><span>{{ ((elemento.totalPostergado/elemento.mesesEnPeriodo)*12) | number:'1.0-0'}}</span></td>
                                            <td><span>{{ ((elemento.totalVarVentas/elemento.mesesEnPeriodo)*12) | number:'1.0-2'}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="break-box" *ngIf="reporteRenta.length > 0">
        <div class="row">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Renta Fija
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of reporteRenta">
                                                <ng-container *ngIf="data.calculadoRenta">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculadoRenta.fecha || ''}}</span></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0 fw-bolder">Sueldos y Salarios</th>
                                            <th class="border-0 fw-bolder" *ngFor="let item of reporteRenta">Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemHeader of headerRentaFija">
                                            <tr>
                                                <th width="30%">{{itemHeader.text}}</th>
                                                <td *ngFor="let item of reporteRenta"
                                                    [attr.width]="70/reporteRenta.length + '%'">
                                                    <span *ngIf="item.calculadoRenta">
                                                        {{item.calculadoRenta[itemHeader.value] | number: '1.0-0'}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Renta Variable
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of reporteRenta">
                                                <ng-container *ngIf="data.calculadoRenta">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculadoRenta.fecha || ''}}</span></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0 fw-bolder">Honorarios</th>
                                            <th class="border-0 fw-bolder" *ngFor="let item of reporteRenta">Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemHeader of headerRentaVariable">
                                            <tr>
                                                <th width="30%">{{itemHeader.text}}</th>
                                                <td *ngFor="let item of reporteRenta"
                                                    [attr.width]="70/reporteRenta.length + '%'">
                                                    <span *ngIf="item.calculadoRenta">
                                                        {{item.calculadoRenta[itemHeader.value] | number: '1.0-0'}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Rentas por participación en sociedades
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of reporteRenta">
                                                <ng-container *ngIf="data.calculadoRenta">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculadoRenta.fecha || ''}}</span></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0 fw-bolder"></th>
                                            <th class="border-0 fw-bolder" *ngFor="let item of reporteRenta">Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemHeader of headerRentaParticipacion">
                                            <tr>
                                                <th width="30%">{{itemHeader.text}}</th>
                                                <td *ngFor="let item of reporteRenta"
                                                    [attr.width]="70/reporteRenta.length + '%'">
                                                    <span *ngIf="item.calculadoRenta">
                                                        {{item.calculadoRenta[itemHeader.value] | number: '1.0-0'}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Otros Ingresos Persibidos
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of reporteRenta">
                                                <ng-container *ngIf="data.calculadoRenta">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculadoRenta.fecha || ''}}</span></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0 fw-bolder"></th>
                                            <th class="border-0 fw-bolder" *ngFor="let item of reporteRenta">Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemHeader of headerIngresosPersibidos">
                                            <tr>
                                                <th width="30%">{{itemHeader.text}}</th>
                                                <td *ngFor="let item of reporteRenta"
                                                    [attr.width]="70/reporteRenta.length + '%'">
                                                    <span *ngIf="item.calculadoRenta">
                                                        {{item.calculadoRenta[itemHeader.value] | number: '1.0-0'}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Total Renta
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of reporteRenta">
                                                <ng-container *ngIf="data.calculadoRenta">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculadoRenta.fecha || ''}}</span></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0 fw-bolder"></th>
                                            <ng-container *ngFor="let item of reporteRenta">
                                                <th class="border-0 fw-bolder">Monto</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemHeader of headerTotalRentaLiq">
                                            <tr>
                                                <th width="30%">{{itemHeader.text}}</th>
                                                <td *ngFor="let item of reporteRenta"
                                                    [attr.width]="70/reporteRenta.length + '%'">
                                                    <span *ngIf="item.calculadoRenta">
                                                        {{item.calculadoRenta[itemHeader.value] | number: '1.0-0'}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="border-0 py-1 text-center"></th>
                                            <ng-container *ngFor="let data of reporteRenta">
                                                <ng-container *ngIf="data.calculadoRenta">
                                                    <th class="border-0 py-1 fw-bolder"><span class="span-fecha">{{data.calculadoRenta.fecha || ''}}</span></th>
                                                    <th class="border-0 py-1 text-center"></th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <th class="border-0 fw-bolder"></th>
                                            <ng-container *ngFor="let item of reporteRenta">
                                                <th class="border-0 fw-bolder">Monto</th>
                                                <th class="border-0 fw-bolder">%</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemHeader of headerTotalRenta">
                                            <tr>
                                                <th width="30%">{{itemHeader.text}}</th>

                                                <ng-container *ngFor="let item of reporteRenta">
                                                    <td>
                                                        <span *ngIf="item.calculadoRenta">
                                                            {{item.calculadoRenta[itemHeader.value] | number: '1.0-0'}}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="item.calculadoRenta">
                                                            {{ item.calculadoRenta[itemHeader.porc] * 100 | number: '1.0-0'}}
                                                        </span>
                                                    </td>
                                                </ng-container>

                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- GRAFICOS -->
    <div class="break-box">
        <div class="row mb-5" *ngIf="informacionBalance.length > 0">
            <!-- Evolución Indicadores de Rentabilidad -->
            <div class="col-md-6" *ngIf="rentabilidadGraph">
                <app-line-bars [serie]='rentabilidadGraph.serie' [title]='rentabilidadGraph.title'
                    [yAxix]="rentabilidadGraph.yAxix" [categorias]='rentabilidadGraph.categorias'
                    [plotOptions]="rentabilidadGraph.plotOptions"></app-line-bars>
            </div>
            <!-- Evolución Indicadores de Liquidez -->
            <div class="col-md-6" *ngIf="liquidezGraph">
                <app-line-bars [serie]='liquidezGraph.serie' [title]='liquidezGraph.title' [yAxix]="liquidezGraph.yAxix"
                    [categorias]='liquidezGraph.categorias' [plotOptions]="liquidezGraph.plotOptions"></app-line-bars>
            </div>
        </div>

        <div class="row mb-5" *ngIf="informacionBalance.length > 0">
            <!-- Evolución Indicadores de Endeudamiento y Cobertura -->
            <div class="col-md-6" *ngIf="coberturaGraph">
                <app-line-bars [serie]='coberturaGraph.serie' [title]='coberturaGraph.title'
                    [yAxix]="coberturaGraph.yAxix" [categorias]='coberturaGraph.categorias'
                    [plotOptions]="coberturaGraph.plotOptions"></app-line-bars>
            </div>
            <!-- Evolución Indicadores de eficiencia -->
            <div class="col-md-6" *ngIf="eficienciaGraph">
                <app-line-bars [serie]='eficienciaGraph.serie' [title]='eficienciaGraph.title'
                    [yAxix]="eficienciaGraph.yAxix" [categorias]='eficienciaGraph.categorias'
                    [plotOptions]="eficienciaGraph.plotOptions"></app-line-bars>
            </div>
        </div>
        <div class="row mb-5" *ngIf="informacionBalance.length > 0">
            <!-- Estructura Financiera -->
            <div class="col-12" *ngIf="estructuraFinancieraGraph">
                <div class="graph-estructura-financiera">
                    <app-line-bars [serie]='estructuraFinancieraGraph.serie' [title]='estructuraFinancieraGraph.title'
                        [yAxix]="estructuraFinancieraGraph.yAxix" [categorias]='estructuraFinancieraGraph.categorias'
                        [plotOptions]="estructuraFinancieraGraph.plotOptions"></app-line-bars>
                </div>
            </div>
        </div>

        <div class="row mb-5" *ngIf="reporte.length > 0">
            <!-- Ventas Netas -->
            <div class="col-md-6" *ngIf="ventasNetasGraph">
                <div class="container-graph">
                    <div [chart]="ventasNetasGraph"></div>
                </div>
            </div>
            <!-- Compras Netas -->
            <div class="col-md-6" *ngIf="comprasNetasGraph">
                <div class="container-graph">
                    <div [chart]="comprasNetasGraph"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="break-box graph-compra-venta">
        <div class="row mb-5">
            <!-- Compras Netas y Ventas Netas -->
            <div class="col-12" *ngIf="comprasVentasNetasGraph && reporte.length > 0">
                <div [chart]="comprasVentasNetasGraph"></div>
            </div>
        </div>
    </div>


</div>