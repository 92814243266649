import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListOfertasComponent } from './list-ofertas/list-ofertas.component';
import { NewEditOfertaComponent } from './new-edit-oferta/new-edit-oferta.component';
import { CargaMasivaOfertasComponent } from './carga-masiva-ofertas/carga-masiva-ofertas.component';

const routes: Routes = [
  { path: '', component: ListOfertasComponent, data: {title: 'gtp-principal'} },
  { path: 'oferta', component: NewEditOfertaComponent, data: {title: 'gtp-principal'} },
  { path: 'oferta/:id', component: NewEditOfertaComponent, data: {title: 'gtp-principal'} },
  { path: 'carga-masiva', component: CargaMasivaOfertasComponent, data: {title: 'gtp-principal'} }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfertasFinancierasRoutingModule { }
