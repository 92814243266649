import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { IFormularioF29FullReporteModel, obtenerPeriodos } from 'src/app/models/formulario-f29/formulario-f29.model';
import { FormularioF22Service } from 'src/app/shared/services/formulario-f22.service';
import { FormularioF29Service } from 'src/app/shared/services/formulario-f29.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-reporte-financiero-consolidado',
  templateUrl: './reporte-financiero-consolidado.component.html',
  styleUrls: ['./reporte-financiero-consolidado.component.scss']
})
export class ReporteFinancieroConsolidadoComponent implements OnInit {
  @Input() rut: string | any;
  @Input() dataf22: any;
  @Input() dataf29: any;
  @Input() reporteBasico: any;
  @Input() tipoPersona: string | any;
  @Output() verFinancieroConsolidado = new EventEmitter<boolean>();
  public showReport = false;
  public today = '';
  public mensajeF22: string = '';
  public mensajeF29: string = '';
  public mensajeRenta: string = '';
  public periodosSeleccionadosF22: string[] = [];
  public periodosSeleccionadosF29: string[] = [];
  public periodosSeleccionadosRenta: string[] = [];
  public periodosDisponiblesF22: IFormularioF29FullReporteModel[] = [];
  public periodosDisponiblesF29: IFormularioF29FullReporteModel[] = [];
  public periodosDisponiblesRenta: IFormularioF29FullReporteModel[] = [];
  @ViewChildren("checkboxesf22") checkboxesf22: QueryList<ElementRef> | undefined;
  @ViewChildren("checkboxesf29") checkboxesf29: QueryList<ElementRef> | undefined;
  @ViewChildren("checkboxesrenta") checkboxesrenta: QueryList<ElementRef> | undefined;
  public reporteFullContentF22!: any | null;
  public reporteFullContentF29!: any | null;
  public reporteFullContentRenta!: any | null;

  constructor(
    private utilsService: UtilsService,
    private spinner: NgxSpinnerService,
    private formularioF22Service: FormularioF22Service,
    private formularioF29Service: FormularioF29Service,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.spinner.show();
    this.setToday();
    this.generarVista();
    this.utilsService.changeSpanExtra(' > Reporte Financiero Consolidado');
    this.spinner.hide();
  }

  public generarVista(): void {
    this.periodosSeleccionadosF22 = [];
    this.periodosSeleccionadosF29 = [];
    this.periodosSeleccionadosRenta = [];

    this.periodosDisponiblesF22 = [];
    this.periodosDisponiblesF29 = [];
    this.periodosDisponiblesRenta = [];

    this.generarF22();
    this.generarF29();
    this.generarRenta();
  }

  public generarF22(): void {
    if(this.dataf22.length > 0){
      this.periodosDisponiblesF22 = this.formularioF22Service.obtenerPeriodos(this.dataf22);
    } else {
      this.mensajeF22 = 'Sin Información.';
    }

  }

  public generarF29(): void {
    if(this.dataf29.length > 0){
      this.periodosDisponiblesF29 = obtenerPeriodos(this.dataf29);
    } else {
      this.mensajeF29 = 'Sin Información.';
    }
  }

  public generarRenta(): void {
    if(this.tipoPersona === 'Persona Juridica'){
      this.mensajeRenta = 'La Renta solo aplica para persona natural';
    } 
    else if(this.dataf22.length > 0 && this.tipoPersona === 'Personal Natural'){
      this.periodosDisponiblesRenta = this.formularioF22Service.obtenerPeriodosRenta(this.dataf22);
    }

  }

  public displayReport(): void {
    const periodosf22: any[] = this.obtenerPeriodosSeleccionados(this.periodosSeleccionadosF22);
    const periodosf29: any[] = this.obtenerPeriodosSeleccionados(this.periodosSeleccionadosF29);
    const periodosRenta: any[] = this.obtenerPeriodosSeleccionados(this.periodosSeleccionadosRenta);

    this.spinner.show();
    
    this.reporteFullContentF22 = [];
    this.reporteFullContentF29 = [];
    this.reporteFullContentRenta = [];

    //F22
    if(this.dataf22.length > 0)
      this.reporteFullContentF22 = this.formularioF22Service.obtenerReporteFiltrado(this.dataf22, periodosf22);

    //F29
    if(this.dataf29.length > 0){
      this.reporteFullContentF29 = this.formularioF29Service.obtenerReporteFiltrado(this.dataf29, periodosf29);
      this.reporteFullContentF29 = this.formularioF29Service.revisionMeses(this.reporteFullContentF29);
    }

    //Renta
    if(this.dataf22.length > 0)
      this.reporteFullContentRenta = this.formularioF22Service.obtenerReporteFiltradoRenta(this.dataf22, periodosRenta);

    if(this.reporteFullContentF22.length > 0 || this.reporteFullContentF29.length > 0 || this.reporteFullContentRenta.length > 0){
      this.limpiarAllCheckBoxes();
      this.spinner.hide();
      this.showReport = true;
    } else {
      this.showReport = false;
      this.spinner.hide();
    }
  }

  public obtenerPeriodosSeleccionados(periodoSeleccionado: string[]): any[]{
    let periodos: any[] = [];
    if(periodoSeleccionado.length > 0){
      periodos = periodoSeleccionado;
    }
    return periodos;
  }

  public closeReport(): void {
    this.periodosSeleccionadosF22 = [];
    this.periodosSeleccionadosF29 = [];
    this.periodosSeleccionadosRenta = [];

    if(this.showReport){ //Volver a seleccion de periodos
      this.limpiarAllCheckBoxes();
      this.showReport = false;
    } else { //Volver a pantalla principal de reporte financiero
      this.periodosDisponiblesF22 = [];
      this.periodosDisponiblesF29 = [];
      this.periodosDisponiblesRenta = [];
      this.verFinancieroConsolidado.emit(this.showReport);
      this.utilsService.changeSpanExtra('');
    }
  }

  public limpiarAllCheckBoxes(): void{
    this.limpiarCheckboxF22();
    this.limpiarCheckboxF29();
    this.limpiarCheckboxRenta();
  }

  public limpiarCheckboxF22(): void {
    this.periodosSeleccionadosF22 = [];
    this.checkboxesf22?.forEach((element) => {
      element.nativeElement.checked = false;
      element.nativeElement.disabled = false;
    });
  }

  public limpiarCheckboxF29(): void {
    this.periodosSeleccionadosF29 = [];
    this.checkboxesf29?.forEach((element) => {
      element.nativeElement.checked = false;
      element.nativeElement.disabled = false;
    });
  }

  public limpiarCheckboxRenta(): void {
    this.periodosSeleccionadosRenta = [];
    this.checkboxesrenta?.forEach((element) => {
      element.nativeElement.checked = false;
      element.nativeElement.disabled = false;
    });
  }


  public onCheckChangeF22(event: any): void {
    if(event.target.checked){
      this.periodosSeleccionadosF22.push(event.target.value);
    } else {
      this.periodosSeleccionadosF22.splice(this.periodosSeleccionadosF22.indexOf(event.target.value),1)
    }
    if(this.periodosSeleccionadosF22.length >= 4){
      this.checkboxesf22?.forEach((element) => {
        if(!element.nativeElement.checked){
          element.nativeElement.disabled = true;
        }
      });
    } else {
      this.checkboxesf22?.forEach((element) => {
        if(element.nativeElement.disabled){
          element.nativeElement.disabled = false;
        }
      });
      
    }
  }

  public onCheckChangeF29(event: any): void {
    if(event.target.checked){
      this.periodosSeleccionadosF29.push(event.target.value);
    } else {
      this.periodosSeleccionadosF29.splice(this.periodosSeleccionadosF29.indexOf(event.target.value),1)
    }
    if(this.periodosSeleccionadosF29.length >= 4){
      this.checkboxesf29?.forEach((element) => {
        if(!element.nativeElement.checked){
          element.nativeElement.disabled = true;
        }
      });
    } else {
      this.checkboxesf29?.forEach((element) => {
        if(element.nativeElement.disabled){
          element.nativeElement.disabled = false;
        }
      });
      
    }
  }

  public onCheckChangeRenta(event: any): void {
    if(event.target.checked){
      this.periodosSeleccionadosRenta.push(event.target.value);
    } else {
      this.periodosSeleccionadosRenta.splice(this.periodosSeleccionadosRenta.indexOf(event.target.value),1)
    }
    if(this.periodosSeleccionadosRenta.length >= 4){
      this.checkboxesrenta?.forEach((element) => {
        if(!element.nativeElement.checked){
          element.nativeElement.disabled = true;
        }
      });
    } else {
      this.checkboxesrenta?.forEach((element) => {
        if(element.nativeElement.disabled){
          element.nativeElement.disabled = false;
        }
      });
      
    }
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

}
