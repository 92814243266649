<div class="modal-content">
    <div class="modal-header">
        <h4>{{confirmData.title || 'Confirm'}}</h4>
    </div>
    <div class="modal-body">
        <accordion [isAnimated]="true" [closeOthers]="true">
            <ng-container *ngIf="confirmData.errors && confirmData.errors.length > 0">
                <accordion-group *ngFor="let item of confirmData.errors">
                    <button class="btn btn-link btn-block justify-content-between d-flex w-100  shadow-none"
                        accordion-heading type="button">
                        <span class="pull-left float-left">
                         <div class="close-btn"></div>   
                         <div class="text-field">{{item.Campo}}</div>                       
                         </span>
                         <div class="seeMore">Ver Detalles</div>
                         <div class="seeMinus">Ver Menos</div>
                    </button>
                    <ul class="list-unstyled">
                        <li class="pb-3">Seccion: {{item.Seccion}} </li>
                        <li class="pb-3">Campo: {{item.Campo}} </li>
                        <li>Detalle: {{item.Detalle}} </li>
                    </ul>
                </accordion-group>
            </ng-container>
        </accordion>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="cerrar()">
            Cancel
        </button>
        <!-- <button type="button" class="btn btn-primary" (click)="confirm()">
            
        </button> -->
        <form [formGroup]="formulario" (ngSubmit)="submit()" class="m-0">
            <div class="form-group m-0">

                <label class="uploadbutton">
                    <input class="input-file" formControlName="file" type="file" name="file" accept=".csv"
                        (change)="handleUpload($event)" />
                    <div class="button btn">Nueva Carga</div>
                    <div class='input-file-text' [ngClass]="{'text-success': formulario.controls.file.value}">
                        {{formulario.controls.file.value || 'Subir Archivo'}}
                    </div>
                </label>
                <div (click)="submit()" class="upload-file btn bg-warning" *ngIf="formulario.controls.file.value">
                    <span class="material-icons">upload</span>
                </div>
            </div>
        </form>
    </div>
</div>