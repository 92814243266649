<ng-container *ngIf="!hasError">
    <accordion [isAnimated]="true">
        <ng-container *ngIf="reporteConsolidado.length > 0">
            <accordion-group heading="Antecedentes Cliente" [isOpen]="false" panelClass="mb-4 div-print">
                <div class="col-sm-12">
                    <app-antecedentes-consolidado [rut]="rut" (actualizarOtherReports)="obtenerActualizaciones()"
                        [objReporteFinanciero]="objReporteFinanciero" [objReporteBasico]="objReporteBasico"
                        [informeComercial]="informeComercial" [objReporteDeudaSbif]="objReporteDeudaSbif"
                        [objReporteJudicial]="objReporteJudicial" [objReporteBoletinConcursal]="objReporteBoletinConcursal"
                        [objReporteEmpresaDia]="objReporteEmpresaDia" #antecedentesConsolidadoComponent>
                    </app-antecedentes-consolidado>
                </div>
            </accordion-group>
        </ng-container>
        <!-- poder judicial -->
        <ng-container *ngIf="objReporteJudicial">
            <accordion-group heading="Poder judicial" [isOpen]="false" panelClass="mb-4 div-print">
                <div class="col-sm-12">
                    <app-poder-judicial-report-viewer [content]="objReporteJudicial" [showHeader]="false" [fromConsolidado]="true">
                    </app-poder-judicial-report-viewer>
                </div>
            </accordion-group>
        </ng-container>
    
        <!-- boletin concursal -->
        <ng-container
            *ngIf="objReporteBoletinConcursal && objectKeys(objReporteBoletinConcursal.boletinConcursalReporte.principal).length > 0">
            <accordion-group heading="Boletin Concursal" [isOpen]="false" panelClass="mb-4 div-print">
                <div class="col-sm-12">
                    <app-boletin-concursal-report-viewer [content]='objReporteBoletinConcursal' [showHeader]="false">
                    </app-boletin-concursal-report-viewer>
                </div>
            </accordion-group>
        </ng-container>
    
        <!-- deuda sbif -->
        <ng-container *ngIf="objReporteDeudaSbif">
            <accordion-group heading="Deuda Sbif" [isOpen]="false" panelClass="mb-4 div-print">
                <div class="col-sm-12">
                    <app-deuda-leasing-sbif-viewer [content]='objReporteDeudaSbif' [typeTX]="false" [showHeader]="false">
                    </app-deuda-leasing-sbif-viewer>
                </div>
            </accordion-group>
        </ng-container>
    
        <!-- reporte financiero -->
        <ng-container *ngIf="objReporteFinanciero">
            <accordion-group heading="Reporte Financiero" [isOpen]="false" panelClass="mb-4 div-print">
                <div class="col-sm-12">
                    <app-reporte-full-viewer [reporteFullContent]="objReporteFinanciero" [showHeader]="false" 
                        [nombreBasico]="objReporteBasico?.InformacionBasica?.NombreORazonSocial || ''">
                    </app-reporte-full-viewer>
                </div>
            </accordion-group>
        </ng-container>
    
        <!-- bienes raices -->
        <ng-container *ngIf="objReporteBienesRaices">
            <accordion-group heading="Bienes Raices" [isOpen]="false" panelClass="mb-4 div-print">
                <div class="col-sm-12">
                    <ng-template #bienesSinInfo>
                        <div class="p-3"> Sin información</div>  
                    </ng-template>
    
                    <ng-container *ngIf="objReporteBienesRaices?.bienesraicesReporte?.bienesRaices?.listadoBienesRaices &&  objReporteBienesRaices.bienesraicesReporte.bienesRaices.listadoBienesRaices.length > 0; else bienesSinInfo">
                    <app-bienes-raices-viewer [content]='objReporteBienesRaices' [showHeader]="false" [fromConsolidado]="true">
                    </app-bienes-raices-viewer>
                    </ng-container>
                    
                </div>
            </accordion-group>
        </ng-container>
    
        <!-- vehiculos -->
        <ng-container *ngIf="objReporteVehiculos">
            <accordion-group heading="Vehiculos" [isOpen]="false" panelClass="mb-4 div-print">
                <div class="col-sm-12">
                    <ng-template #vehiculosSinInfo>
                     <div class="p-3"> Sin información</div>  
                    </ng-template>
                    
                    <ng-container *ngIf="objReporteVehiculos?.vehiculoReporte?.vehiculos?.listadoVehiculos && objReporteVehiculos.vehiculoReporte.vehiculos.listadoVehiculos.length > 0; else vehiculosSinInfo">
                        <app-vehiculos-viewer [content]='objReporteVehiculos' [showHeader]="false" [fromConsolidado]="true"></app-vehiculos-viewer>
                    </ng-container>
                    
                </div>
            </accordion-group>
        </ng-container>
    
        <!-- empresa en un dia -->
        <ng-container *ngIf="objReporteEmpresaDia">
            <accordion-group heading="Empresa en un día" [isOpen]="false" panelClass="mb-4 div-print">
                <div class="col-sm-12">
                    <div *ngIf="objReporteEmpresaDia">
                        <app-anotaciones-viewer [content]='objReporteEmpresaDia' [showHeader]="false"></app-anotaciones-viewer>
    
                        <app-vigencia-viewer [content]='objReporteEmpresaDia' [showHeader]="false"></app-vigencia-viewer>
    
                        <app-estatutos-viewer [content]='objReporteEmpresaDia' [showHeader]="false"></app-estatutos-viewer>
                    </div>
                </div>
            </accordion-group>
        </ng-container>
    </accordion>
</ng-container>