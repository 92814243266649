<div class="container bg-white py-5 px-5" style="border-radius: 10px;">
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h2 class="">Listado de Carteras</h2>
            <button class="btn btn-primary" (click)="createBag()">Crear</button>
        </div>
        <div class="input-group mb-3" style="width: 50%;">
            <input
              type="text"
              class="form-control"
              placeholder="Buscar por ID o Nombre"
              [(ngModel)]="searchTerm"
              (input)="searchCarteras()"
            />
          </div>
        <div class="table-responsive" style="font-size: 1.5rem !important;">
          <table class="table table-hover table-bordered">
            <thead>
              <tr>
                <th scope="col" class="text-center py-1 bg-primary font-head-size">ID</th>
                <th scope="col" class="text-center py-1 bg-primary font-head-size">Nombre</th>
                <th scope="col" class="text-center py-1 bg-primary font-head-size"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cartera of pagedCarteras" style="font-size: 2rem; cursor: pointer">
                <td (click)="openBag(cartera.id)" class="text-center">{{ cartera.id }}</td>
                <td (click)="openBag(cartera.id)" class="text-center">{{ cartera.name }}</td>
                <td class="text-center">
                  <!-- <button class="btn btn-danger" (click)="removeCartera(cartera.id)"> -->
                    <i *ngIf="isAdmin" class="fa fa-trash text-danger" style="cursor: pointer;" (click)="removeCartera(cartera.id)"></i>
                <!-- </button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <!-- Pagination Controls -->
        <div class="d-flex justify-content-between align-items-center mt-5">
          <div>
            <label for="itemsPerPage">Mostrar:</label>
            <select [(ngModel)]="itemsPerPage" id="itemsPerPage" (change)="changeItemsPerPage($event)" class="form-select d-inline-block w-auto">
              <option *ngFor="let option of pageSizeOptions" [value]="option">{{ option }}</option>
            </select>
          </div>
  
          <div class="pagination-controls">
            <button class="btn btn-secondary" (click)="previousPage()" [disabled]="currentPage === 1"><< Previous</button>
            <span class="mx-2">{{ currentPage }} of {{ totalPages }}</span>
            <button class="btn btn-secondary" (click)="nextPage()" [disabled]="currentPage === totalPages">Next >></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  