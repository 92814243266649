import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { environment as env } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { getHeaders } from '../utils/headersUtils';

@Injectable({
  providedIn: 'root'
})
export class MercadoPublicoService {
  private endpoint: string = env.backend.mercadoPublico.url;
  //private servicio: string = env.backend.mercadoPublico.servicio;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService,
  ) { }

  obtenerLicitaciones(fecha: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("fecha", fecha);

    const headers = getHeaders(this._sessionService.getOrganization(), this._sessionService.getUserInfo().username, 'WEB');

    return this.http.get<any>(`${this.endpoint}licitaciones`, { params, headers });
  }

  obtenerLicitacion(licitacion: string): Observable<any> {
    const payload: any = {
      licitacion: licitacion
    }

    const headers = getHeaders(this._sessionService.getOrganization(), this._sessionService.getUserInfo().username, 'WEB');

    return this.http.post<any>(`${this.endpoint}transaccion/licitacion`, payload, { headers: headers });
  }

}
