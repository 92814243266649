<ng-container *ngIf="validaEstado()">
    <section class="overflow-auto" [class.open]="validaEstado() ? 'display' : 'close'"
             (click)="close()">
      <div (click)="$event.stopPropagation()" class="container p-5">
        <div class="row overflow-auto">

          <button class="close" type="button" (click)="close()"><i class="fa fa-times"></i></button>

          <div class="col-12">
            <h3 class="ml-5 mb-3">Actualizar Carpeta Tributaria</h3>
          </div>
          <div class="col-12">
            <div class="container-gtp">
                <div class="panel panel-default">
                    <div class="panel-body box-reporte-full">
                      <div class="col-12" *ngIf="alertSuccess && !alertError">
                        <div class="alert alert-success text-center" role="alert">
                          Se ha actualizado correctamente la carpeta tributaria 
                          <i class="fa fa-check-circle" aria-hidden="true"></i>
                        </div>
                      </div>
                      <div class="col-12" *ngIf="!alertSuccess && alertError">
                        <div class="alert alert-danger text-center" role="alert">
                          {{mensajeError}} <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        </div>
                      </div>
                        <form (ngSubmit)="submit()" [formGroup]="reportFullFormSend">
                            <tabset>
                                <tab heading="Procesar" (selectTab)="onSelect($event)" class="m-0">
                                    <div *ngIf="valueTab === 'Procesar'">
                                        <div class="input-group mt-5 mx-auto col-sm-6">
                                            <label class="uploadbutton mx-auto">
                                                <input class="input-file" id="file" type="file" formControlName="pdf"
                                                    name="file" accept=".pdf" (change)="handleUpload($event)" />
                                                <div class="button btn">PDF Carpeta
                                                </div>
                                                <div class='input-file-text'
                                                    [ngClass]="{'text-success': reportFullFormSend?.controls?.pdf?.value}">
                                                    {{reportFullFormSend?.controls?.pdf?.value || 'Subir Archivo'}}
                                                </div>
                                            </label>
                                            <div class="w-100">
                                                <app-error [control]="reportFullFormSend.get('pdf')"></app-error>
                                            </div>
                                        </div>
                                        <div class="d-flex w-100">
                                            <div class="mx-auto col-sm-6">
                                                <div class="form-group mt-5 px-5">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">Validar Sii</span>
                                                        </div>
                                                        <label class="switch switch-primary ml-3">
                                                            <input type="checkbox" id="validar" formControlName="validar">
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tab>
                                <tab heading="Crear" (selectTab)="onSelect($event)">
                                    <div *ngIf="valueTab === 'Crear'">
        
                                        <div class="d-flex w-100">
                                            <div class="mx-auto col-sm-6">
                                                <div class="form-group mt-5">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">Email</span>
                                                        </div>
                                                        <input type="email" class="form-control ml-1" formControlName="email"
                                                            autocomplete="off">
                                                    </div>
                                                    <div class="w-100">
                                                        <app-error [control]="reportFullFormSend.get('email')" [email]="true"></app-error>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">Clave SII</span>
                                                        </div>
                                                        <input type="password" class="form-control" formControlName="claveSii"
                                                            ="off">
                                                    </div>
                                                    <div class="w-100">
                                                        <app-error [control]="reportFullFormSend.get('claveSii')"></app-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tab>
                                <tab heading="Descargar" (selectTab)="onSelect($event)">
                                    <div *ngIf="valueTab === 'Descargar'">
                                        <div class="d-flex w-100">
                                            <div class="mx-auto col-sm-6">
                                                <div class="form-group mt-5">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">Codigo</span>
                                                        </div>
                                                        <input type="text" class="form-control" formControlName="codigoCte"
                                                            autocomplete="off">
                                                    </div>
                                                    <div class="w-100">
                                                        <app-error [control]="reportFullFormSend.get('codigoCte')"></app-error>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">Clave</span>
                                                        </div>
                                                        <input type="password" class="form-control" formControlName="claveCte"
                                                            autocomplete="off">
                                                    </div>
                                                    <div class="w-100">
                                                        <app-error [control]="reportFullFormSend.get('claveCte')"></app-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </tab>
                            </tabset>
                            <div class="col-12" *ngIf="valueTab === 'Procesar' || valueTab === 'Crear' || valueTab === 'Descargar'">
                                <div class="row">
                                    <div class="mx-auto">
                                        <div class="btn-group mt-5 text-center">
                                            <button type="submit" *ngIf="!(alertSuccess && !alertError)" class="btn btn-primary mr-3">{{valueTab}}</button>
                                            <button type="button" *ngIf="!(alertSuccess && !alertError)" (click)="Limpiar()" class="btn btn-warning">Limpiar</button>
                                            <button type="button" *ngIf="(alertSuccess && !alertError)" (click)="close()" class="btn btn-primary mr-3"> Continuar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-container>