<div class="col-12 shadow">
    <div #chartContainer class="myChart" id="chartContainer"></div>
</div>

<div id="print-section-malla">
    <div class="nested-table-container">
        <div class="nested-table-row print-table-header">
            <div class="rs-nested-table-cl">Razon Social</div>
            
            <div class="type-and-rut">
                <div>Tipo</div>
                <div>RUT</div>
            </div>
        </div>
    
        <br>
    
        <app-print-nested-table [dataSource]="dataOrgPrint"></app-print-nested-table>
    </div>
</div>
