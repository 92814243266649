<ng-container *ngIf="validaEstado()">
    <section class="overflow-auto d-flex justify-content-end align-items-center" [class.open]="validaEstado() ? 'display' : 'close'"
             (click)="close()">
      <div (click)="$event.stopPropagation()" class="container p-5 my-auto mx-auto">
        <div class="row">

          <button class="close" type="button" (click)="close()"><i class="fa fa-times"></i></button>

          <div class="col-12">
            <div class="row tamano-container overflow-auto">

                <!-- Mensajes -->

                <div class="col-12">
                  <app-alert-message *ngFor="let message of messages; let i = index"
                      [message]="message.text"
                      [isError]="message.isError"
                      [isSuccess]="message.isSuccess"
                      (close)="removeMessage(i)"
                  ></app-alert-message>
                </div>

                <!-- Credenciales -->

                <div class="col-12">

                  <ng-container *ngIf="credencial">

                    <ng-container *ngIf="tipoCredencial === 'RUT'"> <!-- Usuario -->
                      <app-credenciales-usuario [rutEntrada]="rut" [credsNameEntrada]="credencial" (actualizaMensaje)="actualizaMensaje($event)"></app-credenciales-usuario>
                    </ng-container>

                    <ng-container *ngIf="tipoCredencial === 'ORG'"> <!-- Organizacion -->
                      <app-credenciales-organizacion [credsNameEntrada]="credencial" (actualizaMensaje)="actualizaMensaje($event)"></app-credenciales-organizacion>
                    </ng-container>
                    
                  </ng-container>

                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</ng-container>