import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { CesionFacturasService } from 'src/app/shared/services/cesion-facturas.service';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RUT } from 'src/app/shared/utils/rut';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { rutValidate } from 'rut-helpers';

@Component({
  selector: 'app-cesion-section',
  templateUrl: './cesion-section.component.html',
  styleUrls: ['./cesion-section.component.scss']
})
export class CesionSectionComponent {
  @Input() errores: any[] = [];
  @Input() rut = '';
  @Input() hasBackLogic: boolean = false;
  @Output() backReport = new EventEmitter<void>();

  private access: string[] = [
    ESystemProfileList.WEB,
    ESystemAccess.REPORTE_CESION_FACTURAS,
    ESystemAccess.REPORTE_CESION_FACTURAS_BUSCAR
  ];

  public filtroForm: FormGroup;

  public showSpinnerRutDeudor: boolean = false;
  public showSpinnerRutCesionario: boolean = false;

  public listaRutDeudor: any [] = [];
  public listaRutCesionario: any [] = [];

  public currentItemCesion = 10;
  public currentItemFactoring = 10;
  public currentItemFactDeudor = 10;
  public currentItemFactFactoring = 10;
  public currentItemCesionDetalle = 10;

  public paginationOptions: number[] = [10, 25, 50, 100];

  public paginaActualCesion: any[] = [];
  public paginaActualFactoring: any[] = [];
  public paginaActualFactDeudor: any[] = [];
  public paginaActualFactFactoring: any[] = [];
  public paginaActualCesionDetalle: any[] = [];
  
  public currentPageCesion = 0;
  public currentPageFactoring = 0;
  public currentPageFactDeudor = 0;
  public currentPageFactFactoring = 0;
  public currentPageCesionDetalle = 0;

  public cantidadRegistrosCesion: number = 0;
  public cantidadRegistrosFactoring: number = 0;
  public cantidadRegistrosFactDeudor: number = 0;
  public cantidadRegistrosFactFactoring: number = 0
  public cantidadRegistrosCesionDetalle: number = 0;

  private subscriptions: any[] = [];

  public cantidadPaginasCesion: number = 0;
  public cantidadPaginasFactoring: number = 0;
  public cantidadPaginasFactDeudor: number = 0;
  public cantidadPaginasFactFactoring: number = 0;
  public cantidadPaginasCesionDetalle: number = 0;

  public paginasActualesErroresCesion: {pagina: number, error: string}[] = []
  public paginasActualesErroresFactoring: {pagina: number, error: string}[] = []
  public paginasActualesErroresFactDeudor: {pagina: number, error: string}[] = []
  public paginasActualesErroresFactFactoring: {pagina: number, error: string}[] = []
  public paginasActualesErroresCesionDetalle: {pagina: number, error: string}[] = []

  public paginasActualesCesion: {pagina: number, response: any}[] = [];
  public paginasActualesFactoring: {pagina: number, response: any}[] = [];
  public paginasActualesFactDeudor: {pagina: number, response: any}[] = [];
  public paginasActualesFactFactoring: {pagina: number, response: any}[] = [];
  public paginasActualesCesionDetalle: {pagina: number, response: any}[] = [];

  public min = '';
  public max = '';

  public rutDetalle: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private cesionFacturasService: CesionFacturasService,
    public alertService: AlertService,
    private _sessionService: SessionService
  ) {
    this.filtroForm = this.formBuilder.group({
      fechaInicio: [''],
      fechaFin: [''],
      rutDeudor: [''],
      rutCesionario: ['']
    }) as FormGroupTyped<any>;
  }

  ngOnInit(): void {
    if (this._sessionService.getUserProfileList().includes(this.access[0]) && this._sessionService.getUserAccess().includes(this.access[1])
      && this._sessionService.getUserAccess().includes(this.access[2])) {
        this.setMaxMinDate();
        this.filtroForm.get('fechaInicio')?.setValue(this.min);
        this.filtroForm.get('fechaFin')?.setValue(this.max);
        this.llamadaPagina(true, '', 1, true, true);
        this.onChanges();
    } else {
      this.alertService.error('No tiene permisos para acceder a esta funcionalidad');
      this.spinner.hide();
      this.logicaRetorno();
    }
  }

  setMaxMinDate(): void { // max debe ser la fecha actual y min debe ser 12 meses atras
    const today = this.getToday();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    const monthFormatted = (month + 1 < 10) ? `0${month + 1}` : `${month + 1}`;
    const dayFormatted = (day < 10) ? `0${day}` : `${day}`;
    this.min = `${year - 1}-${monthFormatted}-${dayFormatted}`; 
    this.max = `${year}-${monthFormatted}-${dayFormatted}`;
  }

  getToday(): Date {
    const fechaActual = new Date();
    return new Date(fechaActual.getFullYear(), fechaActual.getMonth(), fechaActual.getDate());
  }

  onChanges(): void {
    this.subscriptions.push(this.filtroForm.get('fechaInicio')?.valueChanges.subscribe(value => {
      const fechaInicio = this.filtroForm.get('fechaInicio')?.value || '';
      const fechaFin = this.filtroForm.get('fechaFin')?.value || '';

      if(!fechaInicio || !fechaFin){
        return;
      }

      const fechaInicioDate = new Date(fechaInicio);
      const fechaFinDate = new Date(fechaFin);
      
      if(fechaInicioDate > fechaFinDate) {
        return;
      }

      const fechaLimite = new Date(this.min);
      if (fechaInicioDate < fechaLimite || fechaFinDate < fechaLimite) {
        return;
      }

      const fechaActual = new Date(this.max);
      if (fechaInicioDate > fechaActual || fechaFinDate > fechaActual) {
        return;
      }

      this.resetRuts();
      this.llamadaPagina(true, '', 1, true, true);
    }));

    this.subscriptions.push(this.filtroForm.get('fechaFin')?.valueChanges.subscribe(value => {
      const fechaInicio = this.filtroForm.get('fechaInicio')?.value || '';
      const fechaFin = this.filtroForm.get('fechaFin')?.value || '';

      if(!fechaInicio || !fechaFin){
        return;
      }

      const fechaInicioDate = new Date(fechaInicio);
      const fechaFinDate = new Date(fechaFin);
      
      if(fechaInicioDate > fechaFinDate) {
        return;
      }

      const fechaLimite = new Date(this.min);
      if (fechaInicioDate < fechaLimite || fechaFinDate < fechaLimite) {
        return;
      }

      const fechaActual = new Date(this.max);
      if (fechaInicioDate > fechaActual || fechaFinDate > fechaActual) {
        return;
      }

      this.resetRuts();
      this.llamadaPagina(true, '', 1, true, true);
    }));

    this.subscriptions.push(this.filtroForm.get('rutDeudor')?.valueChanges.subscribe(value => {
      const fechaInicio = this.filtroForm.get('fechaInicio')?.value || '';
      const fechaFin = this.filtroForm.get('fechaFin')?.value || '';

      if(!fechaInicio || !fechaFin){
        return;
      }

      const rutDeudor = this.filtroForm.get('rutDeudor')?.value || '';

      if(rutDeudor && !this.listaRutDeudor.find(e => e.rut === rutDeudor)) {
        this.alertService.error('El rut seleccionado no se encuentra en la lista de ruts de deudor');
        return;
      }

      this.llamadaPagina(true, '', 1, false, false);

    }));

    this.subscriptions.push(this.filtroForm.get('rutCesionario')?.valueChanges.subscribe(value => {
      const fechaInicio = this.filtroForm.get('fechaInicio')?.value || '';
      const fechaFin = this.filtroForm.get('fechaFin')?.value || '';

      if(!fechaInicio || !fechaFin){
        return;
      }

      const rutCesionario = this.filtroForm.get('rutCesionario')?.value || '';

      if(rutCesionario && !this.listaRutCesionario.find(e => e.rut === rutCesionario)) {
        this.alertService.error('El rut seleccionado no se encuentra en la lista de ruts de factoring');
        return;
      }

      this.llamadaPagina(true, '', 1, false, false);
    }))
  }

  resetRuts(): void {
    for(const subcription of this.subscriptions) {
      subcription.unsubscribe();
    }
    this.subscriptions = [];
    this.filtroForm.get('rutDeudor')?.setValue('');
    this.filtroForm.get('rutCesionario')?.setValue('');
    this.onChanges();
  }

  llamadaPaginaDetalle(pagina: number): void {
    const apiServices: any = [];

    this.paginasActualesCesionDetalle = [];
    this.paginasActualesErroresCesionDetalle = [];
    this.paginaActualCesionDetalle = [];

    apiServices.push(this.getServicePagina(pagina, this.currentItemCesion, 'CESION-DETALLE'));

    if(apiServices.length > 0){
      this.spinner.show();
      forkJoin(apiServices).subscribe(
        resp => {
          if(this.cantidadRegistrosCesionDetalle <= 0) {
            this.alertService.error('No se encontraron registros para los filtros seleccionados');
            this.spinner.hide();
          } else {

            if(this.paginasActualesErroresCesionDetalle.length > 0) {
              const mensajesMostrados: Set<string> = new Set();
          
              this.paginasActualesErroresCesionDetalle.forEach(e => {
                const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas de cesiones consultadas';
                if (!mensajesMostrados.has(mensaje)) {
                  this.alertService.error(mensaje);
                  mensajesMostrados.add(mensaje);
                }
              });
            }

            const elemento: any = this.paginasActualesCesionDetalle.find(e => e.pagina == (pagina))
            this.paginaActualCesionDetalle = elemento?.response || [undefined];

            this.spinner.hide();
          }
        },
        error => {
          this.alertService.error(error.message || 'Ha ocurrido un error al obtener los registros');
          this.spinner.hide();
          this.logicaRetorno();
        }
      );
    }
  }

  llamadaPagina(validaCantidad: boolean = false, tipo: string = '', pagina: number = 1, consultarRutsDeudor: boolean = false, consultarRutsFactoring: boolean = false): void { // se usa al inicio y cuando se cambian las fechas
    const apiServices: any = [];

    if(tipo === 'CESION' || tipo === '') {
      this.paginasActualesCesion = [];
      this.paginasActualesErroresCesion = [];
      this.paginaActualCesion = [];

      apiServices.push(this.getServicePagina(1, this.currentItemCesion, 'CESION'));
    }
    if((tipo === 'FACTORING' || tipo === '') && (this.paginaActualFactoring.length <= 0 || !this.paginaActualFactoring[0])) {
      this.paginasActualesFactoring = [];
      this.paginasActualesErroresFactoring = [];
      this.paginaActualFactoring = [];

      apiServices.push(this.getServicePagina(1, this.currentItemFactoring, 'FACTORING'));
    }
    if(tipo === 'FACTORIZACION-DEUDOR' || tipo === '') {
      this.paginasActualesFactDeudor = [];
      this.paginasActualesErroresFactDeudor = [];
      this.paginaActualFactDeudor = [];

      apiServices.push(this.getServicePagina(1, this.currentItemFactDeudor, 'FACTORIZACION-DEUDOR'));
    }
    if(tipo === 'FACTORIZACION-FACTORING' || tipo === '') {
      this.paginasActualesFactFactoring = [];
      this.paginasActualesErroresFactFactoring = [];
      this.paginaActualFactFactoring = [];

      apiServices.push(this.getServicePagina(1, this.currentItemFactFactoring, 'FACTORIZACION-FACTORING'));
    }

    // datos para los filtros de factoring y deudor
    if(consultarRutsDeudor) {
      apiServices.push(this.getServicePagina(0, 0,'LIST-DEUDOR'));
    }
    if(consultarRutsFactoring) {
      apiServices.push(this.getServicePagina(0, 0,'LIST-FACTORING'));
    }

    if(apiServices.length > 0){
      this.spinner.show();
      if(consultarRutsDeudor) {
        this.showSpinnerRutDeudor = true;
      }
      if(consultarRutsFactoring) {
        this.showSpinnerRutCesionario = true;
      }
      forkJoin(apiServices).subscribe(
        resp => {
          if(this.paginasActualesErroresCesion.length > 0 && this.paginasActualesErroresFactoring.length > 0 
            && this.paginasActualesErroresFactDeudor.length > 0 && this.paginasActualesErroresFactFactoring.length > 0
          ) {
            this.alertService.error('Ha ocurrido un error al obtener los registros');
            this.spinner.hide();
            this.logicaRetorno();
          } else {

            if(validaCantidad) {
              if(this.cantidadRegistrosCesion <= 0 && this.cantidadRegistrosFactoring <= 0 && this.cantidadRegistrosFactDeudor <= 0 && this.cantidadRegistrosFactFactoring <= 0) {
                this.alertService.error('No se encontraron registros para los filtros seleccionados');
                this.spinner.hide();
                this.logicaRetorno();
              } else {

                if(this.paginasActualesErroresCesion.length > 0) {
                  const mensajesMostrados: Set<string> = new Set();
              
                  this.paginasActualesErroresCesion.forEach(e => {
                    const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas de cesiones consultadas';
                    if (!mensajesMostrados.has(mensaje)) {
                      this.alertService.error(mensaje);
                      mensajesMostrados.add(mensaje);
                    }
                  });
                }

                if(this.paginasActualesErroresFactoring.length > 0) {
                  const mensajesMostrados: Set<string> = new Set();
              
                  this.paginasActualesErroresFactoring.forEach(e => {
                    const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas de factoring consultadas';
                    if (!mensajesMostrados.has(mensaje)) {
                      this.alertService.error(mensaje);
                      mensajesMostrados.add(mensaje);
                    }
                  });
                }

                if(this.paginasActualesErroresFactDeudor.length > 0) {
                  const mensajesMostrados: Set<string> = new Set();
              
                  this.paginasActualesErroresFactDeudor.forEach(e => {
                    const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas de factorización de deudor consultadas';
                    if (!mensajesMostrados.has(mensaje)) {
                      this.alertService.error(mensaje);
                      mensajesMostrados.add(mensaje);
                    }
                  });
                }

                if(this.paginasActualesErroresFactFactoring.length > 0) {
                  const mensajesMostrados: Set<string> = new Set();
              
                  this.paginasActualesErroresFactFactoring.forEach(e => {
                    const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas de factorización de factoring consultadas';
                    if (!mensajesMostrados.has(mensaje)) {
                      this.alertService.error(mensaje);
                      mensajesMostrados.add(mensaje);
                    }
                  });
                }

                if(tipo === 'CESION' || tipo === '') {
                  const elemento: any = this.paginasActualesCesion.find(e => e.pagina == (pagina))
                  this.paginaActualCesion = elemento?.response || [undefined];
                }
        
                if(tipo === 'FACTORING' || tipo === '') {
                  const elemento: any = this.paginasActualesFactoring.find(e => e.pagina == (pagina))
                  this.paginaActualFactoring = elemento?.response || [undefined];
                }

                if(tipo === 'FACTORIZACION-DEUDOR' || tipo === '') {
                  const elemento: any = this.paginasActualesFactDeudor.find(e => e.pagina == (pagina))
                  this.paginaActualFactDeudor = elemento?.response || [undefined];
                }

                if(tipo === 'FACTORIZACION-FACTORING' || tipo === '') {
                  const elemento: any = this.paginasActualesFactFactoring.find(e => e.pagina == (pagina))
                  this.paginaActualFactFactoring = elemento?.response || [undefined];
                }
                this.spinner.hide();
              }
            }
            
          }
        },
        error => {
          this.alertService.error(error.message || 'Ha ocurrido un error al obtener los registros');
          this.spinner.hide();
          this.logicaRetorno();
        }
      );
    }
  }

  actualizarCantidadPaginas(total: number, tipo: string): void {
    if(tipo === 'CESION') {
      if(total || total == 0) {
        this.cantidadRegistrosCesion = total;
      }
      if(this.cantidadRegistrosCesion > 0) {
        this.cantidadPaginasCesion = Math.ceil(this.cantidadRegistrosCesion / this.currentItemCesion);
      }
    } else if(tipo === 'FACTORING') {
      if(total || total == 0) {
        this.cantidadRegistrosFactoring = total;
      }
      if(this.cantidadRegistrosFactoring > 0) {
        this.cantidadPaginasFactoring = Math.ceil(this.cantidadRegistrosFactoring / this.currentItemFactoring);
      }
    } else if(tipo === 'FACTORIZACION-DEUDOR') {
      if(total || total == 0) {
        this.cantidadRegistrosFactDeudor = total;
      }
      if(this.cantidadRegistrosFactDeudor > 0) {
        this.cantidadPaginasFactDeudor = Math.ceil(this.cantidadRegistrosFactDeudor / this.currentItemFactDeudor);
      }
    } else if(tipo === 'FACTORIZACION-FACTORING') {
      if(total || total == 0) {
        this.cantidadRegistrosFactFactoring = total;
      }
      if(this.cantidadRegistrosFactFactoring > 0) {
        this.cantidadPaginasFactFactoring = Math.ceil(this.cantidadRegistrosFactFactoring / this.currentItemFactFactoring);
      }
    } else if(tipo === 'CESION-DETALLE') {
      if(total || total == 0) {
        this.cantidadRegistrosCesionDetalle = total;
      }
      if(this.cantidadRegistrosCesionDetalle > 0) {
        this.cantidadPaginasCesionDetalle = Math.ceil(this.cantidadRegistrosCesionDetalle / this.currentItemCesionDetalle);
      }
    }
  }

  obtenerCantidadPaginas(forzar: boolean = false, tipo: string = ''): void {    
    if(this.cantidadRegistrosCesion > 0 && (tipo === 'CESION' || tipo === '')) {
      this.cantidadPaginasCesion = Math.ceil(this.cantidadRegistrosCesion / this.currentItemCesion);
    }
    
    if(this.cantidadRegistrosFactoring > 0 && (tipo === 'FACTORING' || tipo === '')) {
      this.cantidadPaginasFactoring = Math.ceil(this.cantidadRegistrosFactoring / this.currentItemFactoring);
    }

    if(this.cantidadRegistrosFactDeudor > 0 && (tipo === 'FACTORIZACION-DEUDOR' || tipo === '')) {
      this.cantidadPaginasFactDeudor = Math.ceil(this.cantidadRegistrosFactDeudor / this.currentItemFactDeudor);
    }

    if(this.cantidadRegistrosFactFactoring > 0 && (tipo === 'FACTORIZACION-FACTORING' || tipo === '')) {
      this.cantidadPaginasFactFactoring = Math.ceil(this.cantidadRegistrosFactFactoring / this.currentItemFactFactoring);
    }

    if(this.cantidadRegistrosCesionDetalle > 0 && (tipo === 'CESION-DETALLE')) {
      this.cantidadPaginasCesionDetalle = Math.ceil(this.cantidadRegistrosCesionDetalle / this.currentItemCesionDetalle);
    }

    if((this.cantidadPaginasCesion <= 0 || this.cantidadRegistrosCesion <= 0) && (tipo === 'CESION' || tipo === '')) {
      this.alertService.error('No se han encontrado registros de cesiones para los filtros seleccionados');
      this.paginaActualCesion = [];
      this.paginasActualesCesion = [];
    }

    if((this.cantidadPaginasFactoring <= 0 || this.cantidadRegistrosFactoring <= 0) && (tipo === 'FACTORING' || tipo === '')) {
      this.alertService.error('No se han encontrado registros de factoring para los filtros seleccionados');
      this.paginaActualFactoring = [];
      this.paginasActualesFactoring = [];
    }

    if((this.cantidadPaginasFactDeudor <= 0 || this.cantidadRegistrosFactDeudor <= 0) && (tipo === 'FACTORIZACION-DEUDOR' || tipo === '')) {
      this.alertService.error('No se han encontrado registros de factorización de deudor para los filtros seleccionados');
      this.paginaActualFactDeudor = [];
      this.paginasActualesFactDeudor = [];
    }

    if((this.cantidadPaginasFactFactoring <= 0 || this.cantidadRegistrosFactFactoring <= 0) && (tipo === 'FACTORIZACION-FACTORING' || tipo === '')) {
      this.alertService.error('No se han encontrado registros de factorización de factoring para los filtros seleccionados');
      this.paginaActualFactFactoring = [];
      this.paginasActualesFactFactoring = [];
    }

    if((this.cantidadPaginasCesionDetalle <= 0 || this.cantidadRegistrosCesionDetalle <= 0) && (tipo === 'CESION-DETALLE')) {
      this.alertService.error('No se han encontrado registros de cesiones para los filtros seleccionados');
      this.paginaActualCesionDetalle = [];
      this.paginasActualesCesionDetalle = [];
    }

    if((this.cantidadPaginasCesion > 0 && this.cantidadRegistrosCesion > 0 && (tipo === 'CESION' || tipo === '')) 
      || (this.cantidadPaginasFactoring > 0 && this.cantidadRegistrosFactoring > 0 && (tipo === 'FACTORING' || tipo === ''))
      || (this.cantidadPaginasFactDeudor > 0 && this.cantidadRegistrosFactDeudor > 0 && (tipo === 'FACTORIZACION-DEUDOR' || tipo === ''))
      || (this.cantidadPaginasFactFactoring > 0 && this.cantidadRegistrosFactFactoring > 0 && (tipo === 'FACTORIZACION-FACTORING' || tipo === ''))
      || (this.cantidadPaginasCesionDetalle > 0 && this.cantidadRegistrosCesionDetalle > 0 && (tipo === 'CESION-DETALLE'))){
      this.getPage(1, tipo, forzar);
    } else {
      this.spinner.hide();
    }
  }

  getPage(event: number, tipo: string = '', forzar: boolean = false): void {
    this.paginasActualesErroresCesion = [];
    this.paginasActualesErroresFactoring = [];
    this.paginasActualesErroresFactDeudor = [];
    this.paginasActualesErroresFactFactoring = [];
    this.paginasActualesErroresCesionDetalle = [];
    let paginasConsultarCesion: number[] = [];
    let paginasConsultarFactoring: number[] = [];
    let paginasConsultarFactDeudor: number[] = [];
    let paginasConsultarFactFactoring: number[] = [];
    let paginasConsultarCesionDetalle: number[] = [];

    if((tipo === 'CESION' || tipo === '') && this.cantidadRegistrosCesion > 0) {
      const elemento: any = this.paginasActualesCesion.find(e => e.pagina == (event));
      if(elemento == undefined || forzar){
        if(forzar){
          this.paginasActualesCesion = [];
        }

        paginasConsultarCesion = this.getArrayPaginas(event, 'CESION');
      } else {
        this.paginaActualCesion = elemento?.response || [undefined];
      }
    }
    if((tipo === 'FACTORING' || tipo === '') && this.cantidadRegistrosFactoring > 0) {
      const elemento: any = this.paginasActualesFactoring.find(e => e.pagina == (event));
      if(elemento == undefined || forzar){
        if(forzar){
          this.paginasActualesFactoring = [];
        }

        paginasConsultarFactoring = this.getArrayPaginas(event, 'FACTORING');

      } else {
        this.paginaActualFactoring = elemento?.response || [undefined];
      }
    }
    if((tipo === 'FACTORIZACION-DEUDOR' || tipo === '') && this.cantidadRegistrosFactDeudor > 0) {
      const elemento: any = this.paginasActualesFactDeudor.find(e => e.pagina == (event));
      if(elemento == undefined || forzar){
        if(forzar){
          this.paginasActualesFactDeudor = [];
        }

        paginasConsultarFactDeudor = this.getArrayPaginas(event, 'FACTORIZACION-DEUDOR');

      } else {
        this.paginaActualFactDeudor = elemento?.response || [undefined];
      }
    }
    if((tipo === 'FACTORIZACION-FACTORING' || tipo === '') && this.cantidadRegistrosFactFactoring > 0) {
      const elemento: any = this.paginasActualesFactFactoring.find(e => e.pagina == (event));
      if(elemento == undefined || forzar){
        if(forzar){
          this.paginasActualesFactFactoring = [];
        }

        paginasConsultarFactFactoring = this.getArrayPaginas(event, 'FACTORIZACION-FACTORING');

      } else {
        this.paginaActualFactFactoring = elemento?.response || [undefined];
      }
    }
    if((tipo === 'CESION-DETALLE') && this.cantidadRegistrosCesionDetalle > 0) {
      const elemento: any = this.paginasActualesCesionDetalle.find(e => e.pagina == (event));
      if(elemento == undefined || forzar){
        if(forzar){
          this.paginasActualesCesionDetalle = [];
        }

        paginasConsultarCesionDetalle = this.getArrayPaginas(event, 'CESION-DETALLE');
      } else {
        this.paginaActualCesionDetalle = elemento?.response || [undefined];
      }
    }

    const paginaActualCesion: number | undefined = tipo === 'CESION' || tipo === '' ? event : undefined;
    const paginaActualFactoring: number | undefined = tipo === 'FACTORING' || tipo === '' ? event : undefined;
    const paginaActualFactDeudor: number | undefined = tipo === 'FACTORIZACION-DEUDOR' || tipo === '' ? event : undefined;
    const paginaActualFactFactoring: number | undefined = tipo === 'FACTORIZACION-FACTORING' || tipo === '' ? event : undefined;
    const paginaActualCesionDetalle: number | undefined = tipo === 'CESION-DETALLE' ? event : undefined;
    this.obtenerPaginas(
      {paginas: paginasConsultarCesion, paginaActual: paginaActualCesion}, 
      {paginas: paginasConsultarFactoring, paginaActual: paginaActualFactoring},
      {paginas: paginasConsultarFactDeudor, paginaActual: paginaActualFactDeudor},
      {paginas: paginasConsultarFactFactoring, paginaActual: paginaActualFactFactoring},
      {paginas: paginasConsultarCesionDetalle, paginaActual: paginaActualCesionDetalle}
    );

    if((tipo === 'CESION' || tipo === '')){
      this.currentPageCesion = event;
    }
    if((tipo === 'FACTORING' || tipo === '')){
      this.currentPageFactoring = event;
    }
    if((tipo === 'FACTORIZACION-DEUDOR' || tipo === '')){
      this.currentPageFactDeudor = event;
    }
    if((tipo === 'FACTORIZACION-FACTORING' || tipo === '')){
      this.currentPageFactFactoring = event;
    }
    if((tipo === 'CESION-DETALLE' || tipo === '')){
      this.currentPageCesionDetalle = event;
    }

  }

  setResponse(response: any, pagina: number, tipo: string): void {
    if(tipo === 'CESION'){
      const obj: any = {
        pagina: pagina,
        response: response?.registros || null
      };
      this.paginasActualesCesion.push(obj)
      this.actualizarCantidadPaginas(response?.totalRegistros, tipo); // actualiza la cantidad de paginas por si hay cambios
    } else if(tipo === 'FACTORING'){
      const obj: any = {
        pagina: pagina,
        response: response?.resumen || null
      };
      this.paginasActualesFactoring.push(obj)
      this.actualizarCantidadPaginas(response?.totalRegistros, tipo);
    } else if(tipo === 'FACTORIZACION-DEUDOR'){
      const obj: any = {
        pagina: pagina,
        response: response?.datos || null
      };
      this.paginasActualesFactDeudor.push(obj)
      this.actualizarCantidadPaginas(response?.totalRegistros, tipo);
    } else if(tipo === 'FACTORIZACION-FACTORING'){
      const obj: any = {
        pagina: pagina,
        response: response?.datos || null
      };
      this.paginasActualesFactFactoring.push(obj)
      this.actualizarCantidadPaginas(response?.totalRegistros, tipo);
    } else if(tipo === 'LIST-DEUDOR'){
      if(response && response?.length > 0) {
        this.listaRutDeudor = response || [];
      } else {
        this.listaRutDeudor = [];
      }
      this.showSpinnerRutDeudor = false;
    } else if(tipo === 'LIST-FACTORING'){
      if(response && response?.length > 0) {
        this.listaRutCesionario = response || [];
      } else {
        this.listaRutCesionario = [];
      }
      this.showSpinnerRutCesionario = false;
    } else if(tipo === 'CESION-DETALLE'){
      const obj: any = {
        pagina: pagina,
        response: response?.registros || null
      };
      this.paginasActualesCesionDetalle.push(obj)
      this.actualizarCantidadPaginas(response?.totalRegistros, tipo);
    }
  }

  setError(pagina: number, tipo: string, error: string): void {
    const obj: any = {
      pagina: pagina,
      error: error
    };
    if(tipo === 'CESION'){
      this.paginasActualesErroresCesion.push(obj)
    } else if(tipo === 'FACTORING'){
      this.paginasActualesErroresFactoring.push(obj)
    } else if(tipo === 'FACTORIZACION-DEUDOR'){
      this.paginasActualesErroresFactDeudor.push(obj)
    } else if(tipo === 'FACTORIZACION-FACTORING'){
      this.paginasActualesErroresFactFactoring.push(obj)
    } else if(tipo === 'LIST-DEUDOR'){
      this.listaRutDeudor = [];
      this.showSpinnerRutDeudor = false;
    } else if(tipo === 'LIST-FACTORING'){
      this.listaRutCesionario = [];
      this.showSpinnerRutCesionario = false;
    } else if(tipo === 'CESION-DETALLE') {
      this.paginasActualesErroresCesionDetalle.push(obj)
    }
  }

  formatRut(rut: string): string {
    if(!rut) {
      return '';
    }
    if(rut.includes(".")) {
      rut = rut.replace(/\./g, '');
    }
    if(!rut.includes("-")) {
      rut = RUT.applyBackendFormat(rut);
    }
    return rut;
  }

  getServicePagina(pagina: number, cantidadElementos: number, tipo: string): any {
    if(tipo === 'CESION') {

      const filtros: any = {
        rutCedente: (this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut)),
        fechaInicio: this.filtroForm.get('fechaInicio')?.value || '',
        fechaFin: this.filtroForm.get('fechaFin')?.value || ''
      }
      if(this.filtroForm.get('rutDeudor')?.value) {
        filtros.rutDeudor = this.formatRut(this.filtroForm.get('rutDeudor')?.value);
      }
      if(this.filtroForm.get('rutCesionario')?.value) {
        filtros.rutCesionario = this.formatRut(this.filtroForm.get('rutCesionario')?.value);
      }
      return this.cesionFacturasService.obtenerPagina((this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut)), pagina, cantidadElementos, filtros)
        .pipe(
          map(resp => {
            this.setResponse(resp, pagina, tipo);
          })
        )
        .pipe(
          catchError((error) => (this.setError(pagina, tipo, error?.error?.message || 'Error Inesperado en servicio de obtencion de cesiones'), of(null))));

    } else if(tipo === 'FACTORING') {

      return this.cesionFacturasService.obtenerResumen((this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut)), pagina, cantidadElementos)
        .pipe(
          map(resp => {
            this.setResponse(resp, pagina, tipo);
          })
        )
        .pipe(
          catchError((error) => (this.setError(pagina, tipo, error?.error?.message || 'Error Inesperado en servicio de obtecion de factoring'), of(null))));

    } else if(tipo === 'FACTORIZACION-DEUDOR') {

      const filtros: any = {
        fechaInicio: this.filtroForm.get('fechaInicio')?.value || '',
        fechaFin: this.filtroForm.get('fechaFin')?.value || ''
      }
      if(this.filtroForm.get('rutDeudor')?.value) {
        filtros.rutDeudor = this.formatRut(this.filtroForm.get('rutDeudor')?.value);
      }
      if(this.filtroForm.get('rutCesionario')?.value) {
        filtros.rutCesionario = this.formatRut(this.filtroForm.get('rutCesionario')?.value);
      }
      return this.cesionFacturasService.obtenerFactorizacionDeudor((this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut)), pagina, cantidadElementos, filtros)
        .pipe(
          map(resp => {
            this.setResponse(resp, pagina, tipo);
          })
        )
        .pipe(
          catchError((error) => (this.setError(pagina, tipo, error?.error?.message || 'Error Inesperado en servicio de obtecion de factorizacion de deudor'), of(null))));

    } else if(tipo === 'FACTORIZACION-FACTORING') {

      const filtros: any = {
        fechaInicio: this.filtroForm.get('fechaInicio')?.value || '',
        fechaFin: this.filtroForm.get('fechaFin')?.value || ''
      }
      if(this.filtroForm.get('rutDeudor')?.value) {
        filtros.rutDeudor = this.formatRut(this.filtroForm.get('rutDeudor')?.value);
      }
      if(this.filtroForm.get('rutCesionario')?.value) {
        filtros.rutCesionario = this.formatRut(this.filtroForm.get('rutCesionario')?.value);
      }
      return this.cesionFacturasService.obtenerFactorizacionFactoring((this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut)), pagina, cantidadElementos, filtros)
        .pipe(
          map(resp => {
            this.setResponse(resp, pagina, tipo);
          })
        )
        .pipe(
          catchError((error) => (this.setError(pagina, tipo, error?.error?.message || 'Error Inesperado en servicio de obtecion de factorizacion de factoring'), of(null))));

    } else if(tipo === 'LIST-DEUDOR') {

      const filtros: any = {
        fechaInicio: this.filtroForm.get('fechaInicio')?.value || '',
        fechaFin: this.filtroForm.get('fechaFin')?.value || ''
      }
      return this.cesionFacturasService.obtenerListDeudor((this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut)), filtros)
        .pipe(
          map(resp => {
            this.setResponse(resp, pagina, tipo);
          })
        )
        .pipe(
          catchError((error) => (this.setError(pagina, tipo, error?.error?.message || 'Error Inesperado en servicio de obtecion del listado de deudores'), of(null))));

    } else if(tipo === 'LIST-FACTORING') {

      const filtros: any = {
        fechaInicio: this.filtroForm.get('fechaInicio')?.value || '',
        fechaFin: this.filtroForm.get('fechaFin')?.value || ''
      }
      return this.cesionFacturasService.obtenerListFactoring((this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut)), filtros)
        .pipe(
          map(resp => {
            this.setResponse(resp, pagina, tipo);
          })
        )
        .pipe(
          catchError((error) => (this.setError(pagina, tipo, error?.error?.message || 'Error Inesperado en servicio de obtecion del listado de factoring'), of(null))));

    } else if(tipo === 'CESION-DETALLE') {

      const filtros: any = {
        rutCedente: (this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut)),
        fechaInicio: this.min || '',
        fechaFin: this.max || '',
        rutCesionario: this.rutDetalle
      }
      return this.cesionFacturasService.obtenerPagina((this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut)), pagina, cantidadElementos, filtros)
        .pipe(
          map(resp => {
            this.setResponse(resp, pagina, tipo);
          })
        )
        .pipe(
          catchError((error) => (this.setError(pagina, tipo, error?.error?.message || 'Error Inesperado en servicio de obtencion de cesiones'), of(null))));

    }
  }

  // se usa al cambiar de pagina en alguna de las tablas, desde la tabla se envia el tipo de tabla que se esta consultando
  obtenerPaginas(
    datosCesion: {paginas: number[], paginaActual: number | undefined}, 
    datosFactoring: {paginas: number[], paginaActual: number | undefined},
    datosFactDeudor: {paginas: number[], paginaActual: number | undefined},
    datosFactFactoring: {paginas: number[], paginaActual: number | undefined},
    datosCesionDetalle: {paginas: number[], paginaActual: number | undefined}
  ): void {
    const apiServices: any = [];

    if(datosCesion?.paginas && datosCesion?.paginas.length > 0 && datosCesion?.paginaActual) {
      const paginas = datosCesion?.paginas || [];
      const tipo = 'CESION';
      if(this.cantidadPaginasCesion > 0 && paginas.length > 0 && tipo) {
        paginas.forEach(pagina => {
          if(pagina > 0 && pagina <= this.cantidadPaginasCesion && !this.paginasActualesCesion.some(e => e?.pagina == pagina)) {
  
            apiServices.push(
              {
                service: this.getServicePagina(pagina, this.currentItemCesion, tipo),
                pagina: pagina
              }
            );
  
          }
        });
  
        // se quitan elementos que no se desean consultar
        this.paginasActualesCesion = this.paginasActualesCesion.filter(item =>
          paginas.includes(item.pagina) && 
          item.pagina > 0 && 
          item.pagina <= this.cantidadPaginasCesion
        );
  
      }
    }
    if(datosFactoring?.paginas && datosFactoring?.paginas.length > 0 && datosFactoring?.paginaActual) {
      const paginas = datosFactoring?.paginas || [];
      const tipo = 'FACTORING';
      if(this.cantidadPaginasFactoring > 0 && paginas.length > 0 && tipo) {
        paginas.forEach(pagina => {
          if(pagina > 0 && pagina <= this.cantidadPaginasFactoring && !this.paginasActualesFactoring.some(e => e?.pagina == pagina)) {
  
            apiServices.push(
              {
                service: this.getServicePagina(pagina, this.currentItemFactoring, tipo),
                pagina: pagina
              }
            );
  
          }
        });
  
        this.paginasActualesFactoring = this.paginasActualesFactoring.filter(item =>
          paginas.includes(item.pagina) && 
          item.pagina > 0 && 
          item.pagina <= this.cantidadPaginasFactoring
        );
  
      }
    }
    if(datosFactDeudor?.paginas && datosFactDeudor?.paginas.length > 0 && datosFactDeudor?.paginaActual) {
      const paginas = datosFactDeudor?.paginas || [];
      const tipo = 'FACTORIZACION-DEUDOR';
      if(this.cantidadPaginasFactDeudor > 0 && paginas.length > 0 && tipo) {
        paginas.forEach(pagina => {
          if(pagina > 0 && pagina <= this.cantidadPaginasFactDeudor && !this.paginasActualesFactDeudor.some(e => e?.pagina == pagina)) {
  
            apiServices.push(
              {
                service: this.getServicePagina(pagina, this.currentItemFactDeudor, tipo),
                pagina: pagina
              }
            );
  
          }
        });
  
        this.paginasActualesFactDeudor = this.paginasActualesFactDeudor.filter(item =>
          paginas.includes(item.pagina) && 
          item.pagina > 0 && 
          item.pagina <= this.cantidadPaginasFactDeudor
        );
  
      }
    }
    if(datosFactFactoring?.paginas && datosFactFactoring?.paginas.length > 0 && datosFactFactoring?.paginaActual) {
      const paginas = datosFactFactoring?.paginas || [];
      const tipo = 'FACTORIZACION-FACTORING';
      if(this.cantidadPaginasFactFactoring > 0 && paginas.length > 0 && tipo) {
        paginas.forEach(pagina => {
          if(pagina > 0 && pagina <= this.cantidadPaginasFactFactoring && !this.paginasActualesFactFactoring.some(e => e?.pagina == pagina)) {
  
            apiServices.push(
              {
                service: this.getServicePagina(pagina, this.currentItemFactFactoring, tipo),
                pagina: pagina
              }
            );
  
          }
        });
  
        this.paginasActualesFactFactoring = this.paginasActualesFactFactoring.filter(item =>
          paginas.includes(item.pagina) && 
          item.pagina > 0 && 
          item.pagina <= this.cantidadPaginasFactFactoring
        );
  
      }
    }
    if(datosCesionDetalle?.paginas && datosCesionDetalle?.paginas.length > 0 && datosCesionDetalle?.paginaActual) {
      const paginas = datosCesionDetalle?.paginas || [];
      const tipo = 'CESION-DETALLE';
      if(this.cantidadPaginasCesionDetalle > 0 && paginas.length > 0 && tipo) {
        paginas.forEach(pagina => {
          if(pagina > 0 && pagina <= this.cantidadPaginasCesionDetalle && !this.paginasActualesCesionDetalle.some(e => e?.pagina == pagina)) {
  
            apiServices.push(
              {
                service: this.getServicePagina(pagina, this.currentItemCesionDetalle, tipo),
                pagina: pagina
              }
            );
  
          }
        });
  
        this.paginasActualesCesionDetalle = this.paginasActualesCesionDetalle.filter(item =>
          paginas.includes(item.pagina) && 
          item.pagina > 0 && 
          item.pagina <= this.cantidadPaginasCesionDetalle
        );
  
      }
    }

    if(apiServices && apiServices.length > 0 && (datosCesion?.paginaActual || datosFactoring?.paginaActual || datosFactDeudor?.paginaActual || datosFactFactoring?.paginaActual
      || datosCesionDetalle?.paginaActual
    )) {
      this.llamadaObtenerPaginas(
        apiServices, 
        datosCesion?.paginaActual,
        datosFactoring?.paginaActual, 
        datosFactDeudor?.paginaActual, 
        datosFactFactoring?.paginaActual,
        datosCesionDetalle?.paginaActual
      );
    }
  }

  llamadaObtenerPaginas(
    apiServices: any[], 
    paginaActualCesion: number | undefined, 
    paginaActualFactoring: number | undefined, 
    paginaActualFactDeudor: number | undefined,
    paginaActualFactFactoring: number | undefined,
    paginaActualCesionDetalle: number | undefined
  ): void {
    if(apiServices.length == 0) {
      this.alertService.error('No se han encontrado servicios para consultar');
      this.spinner.hide();
      return;
    }
    const services = apiServices.map(e => e?.service);

    if(services.length == 0) {
      this.alertService.error('No se han encontrado servicios para consultar');
      this.spinner.hide();
      return;
    }

    this.spinner.show();
    forkJoin(services).subscribe(
      resp => {
        if(this.paginasActualesErroresCesion.length > 0) {
          const mensajesMostrados: Set<string> = new Set();
      
          this.paginasActualesErroresCesion.forEach(e => {
            const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas de cesiones consultadas';
            if (!mensajesMostrados.has(mensaje)) {
              this.alertService.error(mensaje);
              mensajesMostrados.add(mensaje);
            }
          });
        }

        if(this.paginasActualesErroresFactoring.length > 0) {
          const mensajesMostrados: Set<string> = new Set();
      
          this.paginasActualesErroresFactoring.forEach(e => {
            const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas de factoring consultadas';
            if (!mensajesMostrados.has(mensaje)) {
              this.alertService.error(mensaje);
              mensajesMostrados.add(mensaje);
            }
          });
        }

        if(this.paginasActualesErroresFactDeudor.length > 0) {
          const mensajesMostrados: Set<string> = new Set();
      
          this.paginasActualesErroresFactDeudor.forEach(e => {
            const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas de factorización de deudor consultadas';
            if (!mensajesMostrados.has(mensaje)) {
              this.alertService.error(mensaje);
              mensajesMostrados.add(mensaje);
            }
          });
        }

        if(this.paginasActualesErroresFactFactoring.length > 0) {
          const mensajesMostrados: Set<string> = new Set();
      
          this.paginasActualesErroresFactFactoring.forEach(e => {
            const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas de factorización de factoring consultadas';
            if (!mensajesMostrados.has(mensaje)) {
              this.alertService.error(mensaje);
              mensajesMostrados.add(mensaje);
            }
          });
        }

        if(this.paginasActualesErroresCesionDetalle.length > 0) {
          const mensajesMostrados: Set<string> = new Set();
      
          this.paginasActualesErroresCesionDetalle.forEach(e => {
            const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas de cesiones consultadas';
            if (!mensajesMostrados.has(mensaje)) {
              this.alertService.error(mensaje);
              mensajesMostrados.add(mensaje);
            }
          });
        }

        if(paginaActualCesion) {
          const elemento: any = this.paginasActualesCesion.find(e => e.pagina == (paginaActualCesion))
          this.paginaActualCesion = elemento?.response || [undefined];
        }

        if(paginaActualFactoring) {
          const elemento: any = this.paginasActualesFactoring.find(e => e.pagina == (paginaActualFactoring))
          this.paginaActualFactoring = elemento?.response || [undefined];
        }

        if(paginaActualFactDeudor) {
          const elemento: any = this.paginasActualesFactDeudor.find(e => e.pagina == (paginaActualFactDeudor))
          this.paginaActualFactDeudor = elemento?.response || [undefined];
        }

        if(paginaActualFactFactoring) {
          const elemento: any = this.paginasActualesFactFactoring.find(e => e.pagina == (paginaActualFactFactoring))
          this.paginaActualFactFactoring = elemento?.response || [undefined];
        }

        if(paginaActualCesionDetalle) {
          const elemento: any = this.paginasActualesCesionDetalle.find(e => e.pagina == (paginaActualCesionDetalle))
          this.paginaActualCesionDetalle = elemento?.response || [undefined];
        }

        this.spinner.hide();
      },
      error => {
        this.alertService.error(error.message || 'Ocurrió un error al consultar por las paginas');
        this.spinner.hide();
      }
    );
  }

  getArrayPaginas(event: number, tipo: string): number[] {
    let response: any[] = [];
    let cantidadPaginas: number = 0;
    if(tipo === 'CESION'){
      cantidadPaginas = this.cantidadPaginasCesion;
    } else if(tipo === 'FACTORING'){
      cantidadPaginas = this.cantidadPaginasFactoring;
    } else if(tipo === 'FACTORIZACION-DEUDOR'){
      cantidadPaginas = this.cantidadPaginasFactDeudor;
    } else if(tipo === 'FACTORIZACION-FACTORING'){
      cantidadPaginas = this.cantidadPaginasFactFactoring;
    } else if(tipo === 'CESION-DETALLE'){
      cantidadPaginas = this.cantidadPaginasCesionDetalle;
    }

    if(event == 1){
      for(let i = 0; i < 3; i++){
        const pagina = event + i;
        if(pagina > 0 && pagina <= cantidadPaginas){
          response.push(pagina);
        }
      }
    } else if(event > 1 && event <= cantidadPaginas) {
      const pagina: number = event;
      response = [pagina - 1, pagina];
      if(event + 1 <= cantidadPaginas)
        response.push(pagina + 1);
    }
    return response;
  }

  cambioPaginacion(tipo: string): void {
    this.obtenerCantidadPaginas(true, tipo);
  }

  logicaRetorno(): void {
    if(this.hasBackLogic) {
      this.backReport.emit();
    }
  }

  seeDetails(rut: string) {
    if(rut && rutValidate(this.formatRut(rut))) {
      this.rutDetalle = this.formatRut(rut);
      this.llamadaPaginaDetalle(1);
    }
  }

}
