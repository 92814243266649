<div id="layout-a" class="theme-blue">

    <!-- main body area -->
    <div class="main body auth-div6 gradient-bg-gtp">
          
          <!-- Body: Body -->
          <div class="body col-sm-12 col-md-12 col-lg-12 col-xl-9">

            <div class="auth-container mr-5">
                <div class="left-column">
                    <img src="../../../assets/img/logo_informax_h_blanco.png" class="mb-2 gtp-image-1" style="width: 100%; max-width: 300px;">

                    <img src="../../../assets/img/logo_informax_h.png" class="mb-3 gtp-image-2" style="width: 100%; max-width: 300px;">

                    <amplify-authenticator class="authenticator-gtp mt-4" [hideSignUp]="true">
                      <ng-template
                        amplifySlot="authenticated"
                        let-user="user"
                        let-signOut="signOut"
                      >
                        <button (click)="signOut()">Sign Out</button>
                      </ng-template>
                    </amplify-authenticator>
                </div>
            </div>

              <div class="login-text bg-none d-none d-md-flex">
                  <div>
                      <h2 class="bg-text fw-bold">Gestión centralizada.</h2>
                      <p class="lead mt-3">Modulo de evaluación crediticio comercial.</p>
                  </div>
                  <svg class="svg-1" version="1.1" viewBox="0 0 146.6 134.7">
                      <circle class="st0" cx="7.3" cy="129.6" r="2.9"/>
                      <circle class="st0" cx="26.2" cy="129.6" r="2.9"/>
                      <circle class="st0" cx="45" cy="129.6" r="2.9"/>
                      <circle class="st0" cx="63.9" cy="129.6" r="2.9"/>
                      <circle class="st0" cx="82.7" cy="129.6" r="2.9"/>
                      <circle class="st0" cx="101.6" cy="129.6" r="2.9"/>
                      <circle class="st0" cx="120.5" cy="129.6" r="2.9"/>
                      <circle class="st0" cx="139.3" cy="129.6" r="2.9"/>
                      <circle class="st0" cx="7.3" cy="111.8" r="2.9"/>
                      <circle class="st0" cx="26.2" cy="111.8" r="2.9"/>
                      <circle class="st0" cx="45" cy="111.8" r="2.9"/>
                      <circle class="st0" cx="63.9" cy="111.8" r="2.9"/>
                      <circle class="st0" cx="82.7" cy="111.8" r="2.9"/>
                      <circle class="st0" cx="101.6" cy="111.8" r="2.9"/>
                      <circle class="st0" cx="120.5" cy="111.8" r="2.9"/>
                      <circle class="st0" cx="139.3" cy="111.8" r="2.9"/>
                      <circle class="st0" cx="7.3" cy="94" r="2.9"/>
                      <circle class="st0" cx="26.2" cy="94" r="2.9"/>
                      <circle class="st0" cx="45" cy="94" r="2.9"/>
                      <circle class="st0" cx="63.9" cy="94" r="2.9"/>
                      <circle class="st0" cx="82.7" cy="94" r="2.9"/>
                      <circle class="st0" cx="101.6" cy="94" r="2.9"/>
                      <circle class="st0" cx="120.5" cy="94" r="2.9"/>
                      <circle class="st0" cx="139.3" cy="94" r="2.9"/>
                      <circle class="st0" cx="7.3" cy="76.3" r="2.9"/>
                      <circle class="st0" cx="26.2" cy="76.3" r="2.9"/>
                      <circle class="st0" cx="45" cy="76.3" r="2.9"/>
                      <circle class="st0" cx="63.9" cy="76.3" r="2.9"/>
                      <circle class="st0" cx="82.7" cy="76.3" r="2.9"/>
                      <circle class="st0" cx="101.6" cy="76.3" r="2.9"/>
                      <circle class="st0" cx="120.5" cy="76.3" r="2.9"/>
                      <circle class="st0" cx="139.3" cy="76.3" r="2.9"/>
                      <circle class="st0" cx="7.3" cy="58.5" r="2.9"/>
                      <circle class="st0" cx="26.2" cy="58.5" r="2.9"/>
                      <circle class="st0" cx="45" cy="58.5" r="2.9"/>
                      <circle class="st0" cx="63.9" cy="58.5" r="2.9"/>
                      <circle class="st0" cx="82.7" cy="58.5" r="2.9"/>
                      <circle class="st0" cx="101.6" cy="58.5" r="2.9"/>
                      <circle class="st0" cx="120.5" cy="58.5" r="2.9"/>
                      <circle class="st0" cx="139.3" cy="58.5" r="2.9"/>
                      <circle class="st0" cx="7.3" cy="40.7" r="2.9"/>
                      <circle class="st0" cx="26.2" cy="40.7" r="2.9"/>
                      <circle class="st0" cx="45" cy="40.7" r="2.9"/>
                      <circle class="st0" cx="63.9" cy="40.7" r="2.9"/>
                      <circle class="st0" cx="82.7" cy="40.7" r="2.9"/>
                      <circle class="st0" cx="101.6" cy="40.7" r="2.9"/>
                      <circle class="st0" cx="120.5" cy="40.7" r="2.9"/>
                      <circle class="st0" cx="139.3" cy="40.7" r="2.9"/>
                      <circle class="st0" cx="7.3" cy="22.9" r="2.9"/>
                      <circle class="st0" cx="26.2" cy="22.9" r="2.9"/>
                      <circle class="st0" cx="45" cy="22.9" r="2.9"/>
                      <circle class="st0" cx="63.9" cy="22.9" r="2.9"/>
                      <circle class="st0" cx="82.7" cy="22.9" r="2.9"/>
                      <circle class="st0" cx="101.6" cy="22.9" r="2.9"/>
                      <circle class="st0" cx="120.5" cy="22.9" r="2.9"/>
                      <circle class="st0" cx="139.3" cy="22.9" r="2.9"/>
                      <circle class="st0" cx="7.3" cy="5.1" r="2.9"/>
                      <circle class="st0" cx="26.2" cy="5.1" r="2.9"/>
                      <circle class="st0" cx="45" cy="5.1" r="2.9"/>
                      <circle class="st0" cx="63.9" cy="5.1" r="2.9"/>
                      <circle class="st0" cx="82.7" cy="5.1" r="2.9"/>
                      <circle class="st0" cx="101.6" cy="5.1" r="2.9"/>
                      <circle class="st0" cx="120.5" cy="5.1" r="2.9"/>
                      <circle class="st0" cx="139.3" cy="5.1" r="2.9"/>
                  </svg>
                  <svg class="svg-2" version="1.1" viewBox="0 0 58 58">
                      <path d="M29,57.1c-0.1,0-0.1,0-0.2-0.1L1,29.2c-0.1-0.1-0.1-0.3,0-0.4L28.8,1c0.1-0.1,0.3-0.1,0.4,0    L57,28.8c0.1,0.1,0.1,0.3,0,0.4L29.2,57C29.1,57.1,29.1,57.1,29,57.1z M1.6,29L29,56.4L56.4,29L29,1.6L1.6,29z"/>
                      <path d="M29,47.7c-0.1,0-0.1,0-0.2-0.1L10.4,29.2c-0.1-0.1-0.1-0.3,0-0.4l18.4-18.4    c0.1-0.1,0.3-0.1,0.4,0l18.4,18.4c0.1,0.1,0.1,0.3,0,0.4L29.2,47.6C29.1,47.6,29.1,47.7,29,47.7z M11,29l18,18l18-18L29,11L11,29z"/>
                      <path d="M29,38.3c-0.1,0-0.1,0-0.2-0.1l-9-9c-0.1-0.1-0.1-0.3,0-0.4l9-9c0.1-0.1,0.3-0.1,0.4,0l9,9    c0.1,0.1,0.1,0.1,0.1,0.2s0,0.1-0.1,0.2l-9,9C29.1,38.2,29.1,38.3,29,38.3z M20.4,29l8.6,8.6l8.6-8.6L29,20.4L20.4,29z"/>
                  </svg>
              </div>
          </div>
  
      </div>
  </div>
