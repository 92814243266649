import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
//import { SimpleModalService } from 'ngx-simple-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { AlertService } from 'src/app/components/_alert';
import { IEmpresaEnUnDiaCreateReportResponse } from 'src/app/interface/IEmpresaEnUnDiaCreateReportResponse';
import { IEmpresaEnUnDiaGetReportResponse } from 'src/app/interface/IEmpresaEnUnDiaGetReportResponse';
import { IEmpresaEnUnDiaGetReportStatusResponse } from 'src/app/interface/IEmpresaEnUnDiaGetReportStatusResponse';
import { IEmpresaEnUnDiaSearchReportResponse } from 'src/app/interface/IEmpresaEnUnDiaSearchReportResponse';
import { environment } from 'src/environments/environment';
import { BackendAuthService } from './backend-auth.service';
import { SessionService } from './session.service';

interface ICreateReport {
  rut: string;
  idUsuario: string;
  idOrganizacion: string;
  requestOrigin: string;
  servicio: string;
  uri: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmpresaEnUnDiaService {
  private endpoint: string = environment.backend.empresaEnUnDia.url;
  private servicio: string = environment.backend.empresaEnUnDia.servicio;
  private reportReady: boolean = true;
  private refreshDataSubject: Subject<IEmpresaEnUnDiaGetReportStatusResponse> = new Subject();
  public refreshDataObj$ = this.refreshDataSubject.asObservable();

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService,
    private _backendAuthService: BackendAuthService,
    public alertService: AlertService,
    //private simpleModalService: SimpleModalService,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) { }

  public createReport(rut: string): Observable<IEmpresaEnUnDiaCreateReportResponse> {

    this.reportReady = false;

    const payload: ICreateReport = {
      rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: 'crearReporte'
    }

    return this.http.post<IEmpresaEnUnDiaCreateReportResponse>(this.endpoint + "crear", payload)

  }

  public getStatusReport(idTransaccion: string): Observable<IEmpresaEnUnDiaGetReportStatusResponse> {

    let params = new HttpParams();
    params = params.append("id_transaccion", idTransaccion);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'obtenerReporte');
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');

    return this.http.get<IEmpresaEnUnDiaGetReportStatusResponse>(this.endpoint + "obtener", { params });
  }

  public getReport(idTransaccion: string): Observable<IEmpresaEnUnDiaGetReportResponse> {

    let params = new HttpParams();
    params = params.append("id_transaccion", idTransaccion);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarTX');
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');

    return this.http.get<IEmpresaEnUnDiaGetReportResponse>(this.endpoint + "obtener", { params });
  }

  public searchReportsList(rut: string, type: string): Observable<IEmpresaEnUnDiaSearchReportResponse[]> {

    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("type", type);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarReportes');
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');

    return this.http.get<IEmpresaEnUnDiaSearchReportResponse[]>(this.endpoint + "obtener", { params });
  }

  public empresaEnUnDiaReportLastUpdated(rut: string, type: string): Observable<IEmpresaEnUnDiaGetReportResponse> {

    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("type", type);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarUltimo');
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');

    return this.http.get<IEmpresaEnUnDiaGetReportResponse>(this.endpoint + "obtener", { params });
  }

  public getReportReady() {
    return this.reportReady;
  }

}
