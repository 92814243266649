<!--<div class="modal-content p-5">
    <div class="modal-header">
        <span class="font-weight-bold font-size mb-4">{{title || 'Confirm'}}</span>
    </div>
    <div class="modal-body">

        <ul class="list-group">
            <ng-container *ngIf="fechas.length > 0">
                <ng-container *ngFor="let item of fechas">
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        {{item.MesNombre}} - {{item.Periodo}}
                    </li>
                </ng-container>
            </ng-container>
        </ul>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="cerrar()">
            Cerrar
        </button>
    </div>
</div>-->


<div class="modal-content p-5">
    <div class="modal-header">
        <span class="font-weight-bold font-size mb-4">{{confirmData.title || 'Confirm'}}</span>
    </div>
    <div class="modal-body">

        <ul class="list-group">
            <ng-container *ngIf="confirmData.fechas && confirmData.fechas.length > 0">
                <ng-container *ngFor="let item of confirmData.fechas">
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        {{item.MesNombre}} - {{item.Periodo}}
                    </li>
                </ng-container>
            </ng-container>
        </ul>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="cerrar()">
            Cerrar
        </button>
    </div>
</div>