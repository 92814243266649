<div class="px-5 py-5">

    <h2>{{data.name}}</h2>

    <p class="mt-5">Tag: {{data.tag}}</p>
    <p class="mt-1">Nombre: {{data.name}}</p>
    <p class="mt-1">Descripción: {{data.description}}</p>    

    <div class="col-12 d-flex justify-content-center mt-3">
        <button class="btn btn-primary" (click)="onClose()">cerrar</button>
    </div>
</div>
