<ng-container *ngIf="validaEstado()">
    <section class="overflow-auto" [class.open]="validaEstado() ? 'display' : 'close'"
             (click)="close()">
      <div (click)="$event.stopPropagation()" class="container p-5">
        <div class="row">

          <button class="close" type="button" (click)="close()"><i class="fa fa-times"></i></button>

          <div class="col-12">
            <h2 class="ml-5 mb-3">Agregar/Eliminar Entidades Derivadas</h2>
            <div class="row tamano-container overflow-auto">

              <!-- Mensajes -->

              <div class="col-12" *ngIf="alertSuccess && !alertError">
                <div class="alert alert-success text-center" role="alert">
                  Se han actualizado correctamente las entidades
                  <i class="fa fa-check-circle" aria-hidden="true"></i>
                </div>
              </div>
              <div class="col-12" *ngIf="!alertSuccess && alertError">
                <div class="alert alert-danger text-center" role="alert">
                  {{mensajeError}} <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                </div>
              </div>

              <!-- Botones de seleccion -->

              <div class="col-12 text-center">
                <div class="ml-2 mb-3">
                  <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-outline-primary" [ngClass]="{'active': mostrarFormulario}" (click)="mostrarFormulario = true; resetModal(false);">
                        <input type="radio" name="options" id="option1" autocomplete="off" checked> 
                        Agregar
                    </label>
                    <label class="btn btn-outline-primary" [ngClass]="{'active': !mostrarFormulario}" (click)="mostrarFormulario = false; resetModal(false);">
                        <input type="radio" name="options" id="option2" autocomplete="off"> 
                        Eliminar
                    </label>
                  </div>
                </div>
              </div>

              <!-- Agregar entidades -->
              <ng-container *ngIf="mostrarFormulario">
                <div class="col-sm-12 col-lg-6">

                  <div class="row px-5">
                    <h4 class="mt-3 ml-4">
                      Seleccione entidad a agregar
                    </h4>
                    <div class="col-11">
                      <select class="form-control text-capitalize" [(ngModel)]="entidadSeleccionada">
                        <option value="">Seleccione...</option>
                        <ng-container *ngFor="let entidad of contrato;">
                            <option value="" class="text-capitalize"
                                [value]="entidad.key">
                                {{entidad.name}}
                            </option>
                        </ng-container>
                      </select>
                    </div>
                    <div class="col-1">
                      <button type="button" class="btn btn-success btn-mas" (click)="agregarEntidadForm()">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>

                  <!-- Formulario para agregar entidad -->
                </div>
                <div class="col-12" *ngIf="entidadesAIngresar.length > 0">
                  <div class="row">
                    <ng-container *ngFor="let entidad of entidadesAIngresar; let indexEntidad = index">
                      <div class="col-sm-12 col-md-6">
                        <h3 class="mt-5 text-center">
                          {{entidad.name}}
                        </h3>

                        <div class="container-fluid border-radius my-4 p-3" *ngFor="let item of entidad.items; let i = index">

                          <div class="col-sm-12">
                            <button type="button" class="btn btn-danger btn-mas float-right mb-2" (click)="eliminarFormulario(indexEntidad, i)">
                              <i class="fa fa-minus" aria-hidden="true"></i>
                            </button>
                          </div>

                          <ng-container *ngFor="let atributo of item.atributosForm, let j = index">

                            <div class="form-group col-sm-12" *ngIf="atributo.name.toUpperCase() === 'RUT'" [ngClass]="{'has-error': validarRut(indexEntidad, i, j)}">
                              <label>{{atributo.displayName}}</label>
                              <input type="text" class="form-control" [value]="atributo.value" 
                                [attr.minlength]="atributo.minlength" [attr.maxlength]="atributo.maxlength"
                                formatRut required (keyup)="actualizaValue(indexEntidad, i, j, $event)">
                              <div class="w-100">
                                <span *ngIf="validarRut(indexEntidad, i, j)" class="help-block">Rut no valido</span>
                              </div>
                            </div>

                            <div class="form-group col-sm-12" *ngIf="atributo.name.toUpperCase() !== 'RUT' && (atributo.typeAttribute === 'String' || atributo.typeAttribute === 'Number' || atributo.typeAttribute === 'Password')" [ngClass]="{'has-error': validaRequired(indexEntidad, i, j)}">
                              <label>{{atributo.displayName}}</label>
                              <input type="{{atributo.typeAttribute === 'String' ? 'text' : atributo.typeAttribute}}" class="form-control" [value]="atributo.value"
                                [attr.minlength]="atributo.minlength" [attr.maxlength]="atributo.maxlength"
                                (keyup)="actualizaValue(indexEntidad, i, j, $event)" required>
                              <div class="w-100">
                                <span *ngIf="validaRequired(indexEntidad, i, j)" class="help-block">Ingrese información valida</span>
                              </div>
                            </div>

                            <div class="form-group col-sm-12" *ngIf="atributo.name.toUpperCase() !== 'RUT' && atributo.typeAttribute === 'Date'" [ngClass]="{'has-error': validaRequired(indexEntidad, i, j)}">
                              <label>{{atributo.displayName}}</label>
                              <input type="date" class="form-control" [value]="atributo.value"
                                (keyup)="actualizaValue(indexEntidad, i, j, $event)" required>
                              <div class="w-100">
                                <span *ngIf="validaRequired(indexEntidad, i, j)" class="help-block">Ingrese información valida</span>
                              </div>
                            </div>

                          </ng-container>

                        </div>
                        
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>

              <!-- Eliminar entidades -->
              <ng-container *ngIf="!mostrarFormulario">
                <div class="col-12">
                  <div class="row px-5 d-flex justify-content-center">
                    <div class="col-sm-12 col-md-8 my-3">
                      <ng-container *ngFor="let entidad of entidadesValidadas">
                        
                        <div class="card">
                          <div class="card-header">
                            <h2 class="mx-4 p-2">{{entidad.name}}</h2>
                          </div>
                          <div class="card-body">
                            <ul class="list-group">
                              <ng-container *ngFor="let item of entidad.items">
                                  <li class="list-group-item list-group-item-action">
                                      <div class="row">
                                        <div class="col-sm-2 d-flex align-items-center">
                                          <span class="material-icons" *ngIf="personaNatural(item.rut)">person_pin</span>
                                          <span class="material-icons" *ngIf="!personaNatural(item.rut)">corporate_fare</span>
                                        </div>
                                        <div class="col-sm-8">
                                          <div class="row">
                                            <div class="col-sm-12 d-flex">
                                                <h5 class="mr-auto p-2 my-0 font-weight-bold">
                                                    Rut: {{item.rut}}
                                                </h5>
                                            </div>
                                            <div class="col-sm-12 d-flex" *ngFor="let atributo of item.atributos">
                                                <p class="mr-auto font-atributo p-2 my-0">
                                                    {{atributo.displayName}}: {{atributo.value}}
                                                </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-sm-2">
                                          <label class="switch switch-primary float-right mr-3">
                                              <input class="p-2" type="checkbox" id="checkbox-{{item.rut}}"
                                                (click)='eliminarEntidad(entidad.entidad, item.rut, $event);'
                                              >
                                              <span></span>
                                          </label>
                                      </div>
                                    </div>
                                  </li>
                                </ng-container>
                            </ul>
                          </div>
                        </div>
                        
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

            <!-- Botones de Guardado -->
            <div class="row">
              <div class="col-12 text-center mt-3">
                <button type="button" class="btn btn-primary" (click)="eliminar()" *ngIf="entidadesAEliminar.length > 0">Guardar</button>
                <button type="button" class="btn btn-primary" (click)="agregar()" *ngIf="entidadesAIngresar.length > 0">Guardar</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </ng-container>