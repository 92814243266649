import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { rutValidate } from 'rut-helpers';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { EmpresaEnUnDiaService } from 'src/app/shared/services/empresa-en-un-dia.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { RUT } from 'src/app/shared/utils/rut';
import { IEmpresaEnUnDiaCreateReportResponse } from 'src/app/interface/IEmpresaEnUnDiaCreateReportResponse';
import { Subscription } from 'rxjs';
import { PdfGeneratorService } from 'src/app/shared/services/pdf-generator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsText as utils } from 'src/app/shared/utils/utils';
import { Title } from '@angular/platform-browser';
import { AsincronoService } from 'src/app/shared/services/asincrono.service';

interface EmpresaEnUnDiaReportFormValues {
  rut: string;
  informeComercial: boolean;
}

@Component({
  selector: 'app-empresa-en-un-dia-consultar',
  templateUrl: './empresa-en-un-dia-consultar.component.html',
  styleUrls: ['./empresa-en-un-dia-consultar.component.scss']
})
export class EmpresaEnUnDiaConsultarComponent implements OnInit, OnDestroy {
  public activateRutHelper = false;
  private access: string[] = [ESystemAccess.REPORTE_EMPRESAENUNDIA, ESystemProfileList.WEB];
  public hasUserAccess = false;

  public empresaEnUnDiaReportForm: UntypedFormGroup;
  public empresaEnUnDiaReportDataSource: IEmpresaEnUnDiaCreateReportResponse | null = null;

  public hasCertificadoVigencia = false;
  public hasCertificadoEstatutos = false;
  public hasCertificadoAnotaciones = false;

  public showCertificadoVigencia = false;
  public showCertificadoEstatutos = false;
  public showCertificadoAnotaciones = true;
  public allReportReady: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private _empresaEnUnDiaService: EmpresaEnUnDiaService,
    private _sessionService: SessionService,
    private _generatePDFService: PdfGeneratorService,
    private formBuilder: UntypedFormBuilder,
    private asyncService: AsincronoService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private router: Router) {

    this.empresaEnUnDiaReportForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
      informeComercial: [false, Validators.requiredTrue]
    }) as FormGroupTyped<EmpresaEnUnDiaReportFormValues>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }

    if (history.state.EmpresaEnUnDiaReporte) {
      this.empresaEnUnDiaReportDataSource = history.state;
      this._validateIfContainsCertificados();
    }

    this.subscriptions.push(this._empresaEnUnDiaService.refreshDataObj$.subscribe(empresaEnUnDiaReporte => {
      this.empresaEnUnDiaReportDataSource = empresaEnUnDiaReporte;
      this._validateIfContainsCertificados();
    }));


  }

  onChanges(): void {
    this.empresaEnUnDiaReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  public createReport() {
    const { rut } = this.empresaEnUnDiaReportForm.value;

    if (this.validarTipoRut(rut)) {


      if (!rutValidate(rut)) {
        return;
      }

      this.spinner.show();
      this._empresaEnUnDiaService.createReport(RUT.applyBackendFormat(rut))
        .subscribe(async data => {
          this.spinner.hide();
          this.empresaEnUnDiaReportDataSource = data;
          await this._validateIfContainsCertificados();
          
          if(!this.hasCertificadoVigencia || !this.hasCertificadoAnotaciones || !this.hasCertificadoEstatutos) {
            const variables: any = {
              rut: rut
            }
            this.router.navigate(['/empresa-en-un-dia/buscar'], { queryParams: variables });
            this.alertService.warn('Se esta ejecutando la creación del reporte, cuando este finalizado se le notificará.', {autoClose: true, buttonRedirect: true, componente: '/empresa-en-un-dia/buscar', arguments: variables});
            this.asyncService.llamadaEmpresaEnUnDia(data?.EmpresaEnUnDiaReporte?.DatosBasicosSolicitud?.IDTransaccion, rut);
          }

        },
          ({ error }: any) => {
            if (error) {
              const msg = error?.message;
              const response = msg.includes(utils['EmpresaDiaReq']) || msg.includes(utils['EmpresaDiaReq']) ? utils['textEmpresaDiaResp'] : msg
              this.alertService.error(response);
            }
            this.spinner.hide();
          });
    } else {
      this.alertService.error(
        'Empresa en un dia - Rut ingresado corresponde a persona natural, favor ingrese un rut de empresa'
      );
    }

  }

  public showAnotacionesReport() {
    this.showCertificadoAnotaciones = true;
    this.showCertificadoVigencia = false;
    this.showCertificadoEstatutos = false;
  }

  public showVigenciaReport() {
    this.showCertificadoAnotaciones = false;
    this.showCertificadoVigencia = true;
    this.showCertificadoEstatutos = false;
  }

  public showEstatutosReport() {
    this.showCertificadoAnotaciones = false;
    this.showCertificadoVigencia = false;
    this.showCertificadoEstatutos = true;
  }

  public async downloadReportSelected(): Promise<void> {
    const transactionId = this.empresaEnUnDiaReportDataSource!.EmpresaEnUnDiaReporte.DatosBasicosSolicitud.IDTransaccion;

    let tipoReporte = '';
    if (this.showCertificadoAnotaciones) {
      tipoReporte = 'empresaEnUnDia-anotaciones';
    } else if (this.showCertificadoEstatutos) {
      tipoReporte = 'empresaEnUnDia-estatutos';
    } else if (this.showCertificadoVigencia) {
      tipoReporte = 'empresaEnUnDia-vigencia';
    }

    const contentPDF = await this._generatePDFService.downloadReportSelected(transactionId, tipoReporte);
    if (!contentPDF) {
      this.alertService.warn("No se pudo descargar el archivo PDF");
      return;
    }

    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', contentPDF.URLReporte);
    downloadLink.setAttribute('target', '_blank');
    downloadLink.setAttribute('download', `file.pdf`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    // link.remove();
  }

  private _validateIfContainsCertificados() {
    if (this.empresaEnUnDiaReportDataSource && this.empresaEnUnDiaReportDataSource?.EmpresaEnUnDiaReporte?.Certificados && this.empresaEnUnDiaReportDataSource?.EmpresaEnUnDiaReporte?.Certificados.length > 0) {
      for (const certificado of this.empresaEnUnDiaReportDataSource.EmpresaEnUnDiaReporte.Certificados) {
        // TODO: I need to add an enum
        if ("CertificadoDeVigencia" in certificado) {
          this.hasCertificadoVigencia = true;
        }
        if ("CertificadoDeAnotaciones" in certificado) {
          this.hasCertificadoAnotaciones = true;
        }
        if ("CertificadoDeEstatutos" in certificado) {
          this.hasCertificadoEstatutos = true;
        }
      }
      this.verifiyAllReport();
    }
  }

  public closeReport() {
    this.empresaEnUnDiaReportDataSource = null;
    this.hasCertificadoVigencia = false;
    this.hasCertificadoAnotaciones = false;
    this.hasCertificadoEstatutos = false;
    this.allReportReady = false;
  }

  public clearForm() {
    this.empresaEnUnDiaReportForm.reset();
    this.activateRutHelper = false;
  }

  private validarTipoRut(rut: string) {
    const body = rut.slice(0, -1);
    if (Number(body) > 50000000) {
      return true;
    }
    return false;
  }

  private verifiyAllReport() {
    this.allReportReady = this.hasCertificadoVigencia && this.hasCertificadoAnotaciones && this.hasCertificadoEstatutos?
    true: false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
  }
}
