import { Component, Input, OnInit } from '@angular/core';
import { CertificadoDeAnotaciones, IEmpresaEnUnDiaCreateReportResponse } from 'src/app/interface/IEmpresaEnUnDiaCreateReportResponse';


@Component({
  selector: 'app-anotaciones-viewer',
  templateUrl: './anotaciones-viewer.component.html',
  styleUrls: ['./anotaciones-viewer.component.scss']
})
export class AnotacionesViewerComponent implements OnInit {
  @Input() content!: IEmpresaEnUnDiaCreateReportResponse;
  @Input() showHeader: boolean = true; 
  public certificado: CertificadoDeAnotaciones = {
    Declaracion: '',
    RutSociedad: '',
    RazonSocial: '',
    FechaConstitucion: '',
    FechaEmisionCertificado: '',
    CodigoVerificacion: '',
  };

  constructor() { }

  ngOnInit(): void {
    // TODO: this.content is null -> alert.warn
    if (this.content) {
      for (const certificado of this.content.EmpresaEnUnDiaReporte.Certificados) {
        if ("CertificadoDeAnotaciones" in certificado) {
          this.certificado = certificado.CertificadoDeAnotaciones!;
        }
      }
    }
  }

}
