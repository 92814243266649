import { AfterViewInit, Component, Inject, Input } from '@angular/core';
//import { SimpleModalComponent } from "ngx-simple-modal";
import { take, map } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


export interface ConfirmModel {
  title: string;
  errors: AdvertenciaModel[];
  textTimer: number;
}

export interface AdvertenciaModel {
  campo: string;
  detalle: string;
}

export interface AdvertenciaResponseModel {
  confirm: boolean;
}

@Component({
  selector: 'app-list-errors-simple-dialog',
  templateUrl: './list-errors-simple-dialog.component.html',
  styleUrls: ['./list-errors-simple-dialog.component.scss']
})
export class ListErrorsSimpleDialogComponent implements AfterViewInit {
  @Input() title: string = "";
  @Input() errors: AdvertenciaModel[] = [];
  @Input() textTimer: number = 0;

  confirmData: ConfirmModel = {
    title: "",
    errors: [],
    textTimer: 0
  };

  // timer: Observable<number>;
  public counter$: Observable<number> = new Observable;

  public counter = 0;


  /*constructor() {
    super();
    //this.initForm();
  }*/

  constructor(public dialogRef: MatDialogRef<ListErrorsSimpleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel,) {
      this.confirmData = data;

      timer(0, 1000).pipe(
        // Take values until the specified duration is reached
        take(this.confirmData.textTimer + 1),
        // Subtract the emitted values from the duration to get the countdown
        map((value) => this.confirmData.textTimer - value)
      ).subscribe((counter) => {
        this.counter = counter;

        // Close the dialog when the countdown reaches zero
        if (counter === 0) {
          // this.close();
        }
      });
  }

  cerrar() {
    //this.result = { confirm: false };
    this.dialogRef.close({ confirm: false }); // validar
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.confirmData.textTimer > 0) {
      this.counter$ = await timer(0, 1000).pipe(
        take(this.textTimer),
        map(() => {
          return --this.textTimer
        }));
    }
  }

}
