<div class="modal-content">
    <div class="modal-header">
        <h4>{{confirmData.title || 'Confirm'}}</h4>
    </div>
    <div class="modal-body">

        <ng-container *ngIf="confirmData.urlImage">
            <img class="img-fluid mb-2" [src]="confirmData.urlImage" alt="Ejemplo visualización archivo">
        </ng-container>

        <p *ngIf="confirmData.subtitle">{{ confirmData.subtitle }}</p>

        <ul>
            <li *ngFor="let oneAccess of confirmData.access">{{ oneAccess}}</li>

            <ng-container *ngFor="let elemento of confirmData.lista">
                <li>{{ elemento.value }}</li>
                <ng-container *ngIf="elemento?.lista?.length > 0">
                    <ul>
                        <li *ngFor="let subElemento of elemento?.lista">{{ subElemento }}</li>
                    </ul>
                </ng-container>
            </ng-container>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="close()">Cerrar</button>
    </div>
</div>