<ng-container *ngIf="entidadesValidadas.length > 0 && contratoServicios.length > 0">
    <div class="row">
        <div class="col-sm-12">
            <button type="button" class="btn btn-sm btn-primary float-right mx-4" (click)="abrirModal()"
                title="Agregar o eliminar ruts a las entidades">
                Agregar/Eliminar
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="entidadesValidadas.length > 0 && contratoServicios.length > 0">
    <ng-container *ngFor="let entidad of entidadesValidadas; let i = index;">
        <div class="container-fluid my-3">
            <accordion [isAnimated]="true" class="m-0">
                <accordion-group [isOpen]="i === 0 ? true : false" panelClass="m-0" #accGroupEntidad>
                    <span class="font-weight-bold" accordion-heading>
                        <i class="fa" [ngClass]="{'fa-angle-down': accGroupEntidad?.isOpen, 'fa-angle-right': !accGroupEntidad?.isOpen}"></i>
                        {{entidad.name}}
                    </span>
    
                    <div class="row d-flex justify-content-end my-2">
                        <div class="col-sm-6 col-md-2">
                            <div class="row">
                                <div class="col-sm-12 d-flex justify-content-center">
                                    Buscar Todos
                                    <label class="switch switch-primary switch-sm ml-3">
                                        <input #checkboxesbuscarentidad class="checkbox-buscar" type="checkbox" id="checkboxbuscar-{{entidad.entidad}}"
                                            (click)="buscarTodosEntidad($event, entidad.entidad)"
                                        >
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-2">
                            <div class="row">
                                <div class="col-sm-12 d-flex justify-content-center">
                                    Actualizar Todos
                                    <label class="switch switch-primary switch-sm ml-3">
                                        <input #checkboxesactualizarentidad class="checkbox-actualizar" type="checkbox" id="checkboxactualizar-{{entidad.entidad}}"
                                            (click)="actualizarTodosEntidad($event, entidad.entidad)"
                                        >
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="row table-height">
                        <div class="col-sm-12">
    
                            <div class="table-responsive py-3">
                                <table class="table table-hover table-bordered border">
                                    <thead>
                                        <tr>

                                            <ng-container *ngIf="entidad?.items?.length > 0">
                                                <th scope="col" rowspan="2" class="bg-primary th-head-size">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="col-sm-12 text-center mb-1">
                                                                            Habilitar/Deshabilitar
                                                                        </div>
                                                                        <div class="col-sm-12 d-flex justify-content-center my-4">
                                                                            <label class="switch switch-primary switch-sm">
                                                                                <input #checkboxesfilatodos class="checkbox-fila" type="checkbox" id="checkboxfilatodos-{{entidad.entidad}}"
                                                                                    (click)="habilitaFilaTodos($event, entidad.entidad)">
                                                                                <span></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </th>
                                            </ng-container>

                                            <th scope="col" rowspan="2" class="font-weight-bold bg-primary text-center align-middle th-atributo" (click)="cambioDeCampo(entidad.entidad, 'rut')">
                                                <span>Rut</span>
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>

                                            <ng-container *ngFor="let contrato of contratoEntidades">
                                                
                                                <ng-container *ngIf="contrato.key === entidad.entidad">

                                                    <ng-container *ngFor="let variable of contrato.outputVars">

                                                        <ng-container *ngIf="(variable.name !== 'rut' && variable.name !== 'Rut') && variable?.display !== false">

                                                            <th scope="col" rowspan="2" class="font-weight-bold bg-primary text-center align-middle th-atributo" (click)="cambioDeCampo(entidad.entidad, variable.name)">
                                                                <span>{{ variable.displayName }}</span>
                                                                <div class="float-right">
                                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                                </div>
                                                            </th>

                                                        </ng-container>

                                                    </ng-container>
                                                    
                                                </ng-container>
                                                
                                            </ng-container>
            
                                            <ng-container *ngFor="let servicio of contratoServicios; let servicioiIndex = index">
                                                <ng-container *ngIf="servicio.display">
                                                    <th scope="col" class="text-center font-weight-bold bg-primary">{{servicio.name}}</th>
                                                </ng-container>
                                            </ng-container>

                                        </tr>
                                        <tr>
                                            <ng-container *ngFor="let servicio of contratoServicios; let servicioiIndex = index">
                                                <ng-container *ngIf="servicio.display">
                                                    <th scope="col" class="th-btn-head">
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6">
                                                                <div class="row" *ngIf="validarTipoServicio(servicio?.values, 'buscarHistorico')">
                                                                    <div class="col-sm-12 text-center">
                                                                        Buscar Todos
                                                                    </div>
                                                                    <div class="col-sm-12 d-flex justify-content-center">
                                                                        <label class="switch switch-primary switch-sm">
                                                                            <input #checkboxesbuscarreporte class="checkbox-buscar" type="checkbox" id="checkboxbuscar-{{servicio.key}}-{{entidad.entidad}}"
                                                                                (click)="buscarTodosReporte($event, entidad.entidad, servicio.key)"
                                                                            >
                                                                            <span></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6">
                                                                <div class="row" *ngIf="validarTipoServicio(servicio?.values, 'crear')">
                                                                    <div class="col-sm-12 text-center">
                                                                        Actualizar Todos
                                                                    </div>
                                                                    <div class="col-sm-12 d-flex justify-content-center">
                                                                        <label class="switch switch-primary switch-sm">
                                                                            <input #checkboxesactualizarreporte class="checkbox-actualizar" type="checkbox" id="checkboxactualizar-{{servicio.key}}-{{entidad.entidad}}"
                                                                                (click)="actualizarTodosReporte($event, entidad.entidad, servicio.key)"
                                                                            >
                                                                            <span></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-template #sinRuts>
                                            <tr>
                                                <td colspan="100%">
                                                    Sin ruts en la entidad
                                                </td>
                                            </tr>
                                        </ng-template>
            
                                        <ng-container *ngIf="entidad?.items?.length > 0 else sinRuts">
            
                                            <ng-container *ngFor="let item of entidad.items">
                                                <tr>

                                                    <td class="td-btn-head">
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                                <div class="row">
                                                                    <div class="col-sm-12">
                                                                        <div class="row">
                                                                            <div class="col-sm-12 d-flex justify-content-center">
                                                                                <label class="switch switch-primary switch-sm" title="Seleccionar para habilitar fila">
                                                                                    <input #checkboxesfila class="checkbox-fila" type="checkbox" id="checkboxfila-{{item.rut}}-{{entidad.entidad}}"
                                                                                        (click)="habilitaFila($event, entidad.entidad, item.rut)">
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    
                                                    <th class="text-nowrap" scope="row"
                                                        [ngClass]="validaAnyResponse(entidad.entidad, item.rut) ? 'bg-consulted' : ''">
                                                        {{item.rut || '' | rutFormat}}
                                                    </th>

                                                        
                                                    <ng-container *ngFor="let atributo of item.atributos">


                                                        <ng-template #notNumber>

                                                            <td [ngClass]="validaAnyResponse(entidad.entidad, item.rut) ? 'bg-consulted' : ''">
                                                                {{ atributo.value }}
                                                            </td>

                                                        </ng-template>
                                                        

                                                        <ng-container *ngIf="atributo?.typeAttribute === 'Number' || isNumber(atributo?.value) === true else notNumber">

                                                            <td [ngClass]="validaAnyResponse(entidad.entidad, item.rut) ? 'bg-consulted' : ''">
                                                                {{ atributo.value | number: '1.0-2' }}
                                                            </td>

                                                        </ng-container>


                                                    </ng-container>
                                                    
                                                    <ng-container *ngFor="let servicio of contratoServicios; let servicioiIndex = index">
                                                        <ng-container *ngIf="servicio.display">
                                                            <td [ngClass]="validaResponse(entidad.entidad, item.rut, servicio.key) ? 'bg-consulted' : ''">
                                                                <div class="row">
                                                                    <div [ngClass]="validaResponse(entidad.entidad, item.rut, servicio.key) ? 'col-md-5' : 'col-sm-12'">
                                                                        <div class="row">
                                                                            <div [ngClass]="validaResponse(entidad.entidad, item.rut, servicio.key) ? 'col-sm-12' : 'col-sm-12 col-md-6'">
                                                                                <div class="row" *ngIf="validarTipoServicio(servicio?.values, 'buscarHistorico')">
                                                                                    <div class="col-sm-12 text-center text-nowrap">
                                                                                        Buscar
                                                                                    </div>
                                                                                    <div class="col-sm-12 d-flex justify-content-center">
                                                                                        <label class="switch switch-primary switch-sm">
                                                                                            <input #checkboxesbuscar class="checkbox-buscar" type="checkbox" id="checkboxbuscar-{{item.rut}}-{{servicio.key}}-{{entidad.entidad}}"
                                                                                                (click)="buscarServicio($event, entidad.entidad, servicio.key, servicioiIndex, item.rut)" disabled
                                                                                            >
                                                                                            <span></span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div [ngClass]="validaResponse(entidad.entidad, item.rut, servicio.key) ? 'col-sm-12' : 'col-sm-12 col-md-6'">
                                                                                <div class="row" *ngIf="validarTipoServicio(servicio?.values, 'crear')">
                                                                                    <div class="col-sm-12 text-center text-nowrap">
                                                                                        Actualizar
                                                                                    </div>
                                                                                    <div class="col-sm-12 d-flex justify-content-center">
                                                                                        <label class="switch switch-primary switch-sm">
                                                                                            <input #checkboxesactualizar class="checkbox-actualizar" type="checkbox" id="checkboxactualizar-{{item.rut}}-{{servicio.key}}-{{entidad.entidad}}"
                                                                                                (click)="actualizarServicio($event, entidad.entidad, servicio.key, servicioiIndex, item.rut)" disabled
                                                                                            >
                                                                                            <span></span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                
                                                                    <div class="col-md-7" *ngIf="validaResponse(entidad.entidad, item.rut, servicio.key)">
                                                                        <div class="container-fluid">
                                                                            <div class="row text-center pt-3">
                                                                                <div class="col-sm-12">
                                                                                    <span class="badge badge-pill badge-warning" 
                                                                                        *ngIf="responseConsulta[entidad.entidad][item.rut][servicio.key]?.errors?.hassError === false && (!responseConsulta[entidad.entidad][item.rut][servicio.key]?.fechaReporte || responseConsulta[entidad.entidad][item.rut][servicio.key]?.fechaReporte === 'Sin Información')"
                                                                                        title="Sin información histórica disponible">
                                                                                        <i class="fa fa-exclamation" aria-hidden="true"></i>
                                                                                    </span>
                                                                                    <span class="badge badge-pill badge-success" *ngIf="responseConsulta[entidad.entidad][item.rut][servicio.key].errors.hassError === false && (responseConsulta[entidad.entidad][item.rut][servicio.key]?.fechaReporte && responseConsulta[entidad.entidad][item.rut][servicio.key]?.fechaReporte !== 'Sin Información')">
                                                                                        <i class="fa fa-check" aria-hidden="true"></i>
                                                                                    </span>
                                                                                    <span class="badge badge-pill badge-danger" 
                                                                                        *ngIf="responseConsulta[entidad.entidad][item.rut][servicio.key].errors.hassError === true"
                                                                                        title="{{responseConsulta[entidad.entidad][item.rut][servicio.key]?.errors?.details?.message || 'Ocurrio un Error'}}">
                                                                                        <i class="fa fa-times" aria-hidden="true"></i>
                                                                                    </span>
                                                                                </div>
                                                                                <div class="col-sm-12" *ngIf="responseConsulta[entidad.entidad][item.rut][servicio.key].errors.hassError === false && obtieneFecha(responseConsulta[entidad.entidad][item.rut][servicio.key]?.fechaReporte) !== ''">
                                                                                    <span class="badge badge-info">
                                                                                        {{obtieneFecha(responseConsulta[entidad.entidad][item.rut][servicio.key]?.fechaReporte) | date: 'dd-MM-YYYY'}}
                                                                                    </span>
                                                                                </div>
                                                                                <div class="col-sm-12" *ngIf="responseConsulta[entidad.entidad][item.rut][servicio.key].errors.hassError === true">
                                                                                    <button class="btn btn-primary btn-sm button-reconsultar mt-2"
                                                                                        (click)="reconsultar(entidad.entidad, item.rut, servicio.key)"
                                                                                        title="Reconsultar"
                                                                                    >
                                                                                        <i class="fa fa-refresh" aria-hidden="true"></i>
                                                                                    </button>
                                                                                </div>
                                                                                <div class="col-sm-12">
                                                                                    <button class="btn" (click)="borrarServicio(entidad.entidad, item.rut, servicio.key)"><i class="fa fa-trash text-danger"></i></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                    </ng-container>

                                                </tr>
                                            </ng-container>
            
                                        </ng-container>
                                        
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                    </div>
        
    
                    <div class="row">
                        <div class="col-12 my-3 p-5 text-center">
                            <button class="btn btn-primary"
                                (click)="consultar([entidad.entidad])"
                            >
                                Consultar
                            </button>
                        </div>
                    </div>
            
                </accordion-group>
            </accordion>
        </div>
    </ng-container>
</ng-container>


<div class="row mt-5">
    <div class="col-sm-12 text-center">
        <button type="button" class="btn btn-sm btn-primary" (click)="avanzarFlujo()">
            Continuar
        </button>
    </div>
</div>



<app-modal-entidades-workflow 
    [contrato]="contratoEntidades" 
    [rut]="rut" 
    [idTransaccion]="idTransaccion" 
    [entidades]="entidades" 
    [entidadesValidadas]="entidadesValidadas" 
    (entidadesAct)="entidades = $event" 
    (contratoAct)="contratoEntidades = $event" 
    (entidadesValidadasAct)="entidadesValidadas = $event" 
    (entidadesRutAct)="entidadRutList = $event"
    (responseConsultaAct)="responseConsulta = $event"
    (entidadesDerivadasAConsultarAct)="entidadesDerivadasAConsultar = $event"
    (requestOriginalAct)="requestOriginal = $event"
></app-modal-entidades-workflow>
