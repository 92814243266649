import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';

import { OfertasFinancierasRoutingModule } from './ofertas-financieras-routing.module';
import { ListOfertasComponent } from './list-ofertas/list-ofertas.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginatorByItems } from 'src/app/components/paginator-by-items/paginator-by-items.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NewEditOfertaComponent } from './new-edit-oferta/new-edit-oferta.component';
import { CargaMasivaOfertasComponent } from './carga-masiva-ofertas/carga-masiva-ofertas.component';
import { OfertasHelperService } from './services/ofertas-helper.service';
// import { DatePickerModule }


@NgModule({
  declarations: [
    ListOfertasComponent,
    NewEditOfertaComponent,
    CargaMasivaOfertasComponent
  ],
  imports: [
    CommonModule,
    OfertasFinancierasRoutingModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    ],
  providers: [
    DecimalPipe,
    DatePipe,
    OfertasHelperService 
  ]
})
export class OfertasFinancierasModule { }
