import { Component, Input, OnInit } from '@angular/core';
import { balanceReporteFullActivos, balanceReporteFullEstadoResultados, balanceReporteFullIndicadoresFinancieros, balanceReporteFullInformacionBalance, balanceReporteFullPasivos, balanceReporteFullResumenFinanciero, ventasPeriodo } from 'src/app/models/utils/utils.model';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ICarpetaTributariaElectronicaReporteModel, F22, F29 } from 'src/app/models/reporte-full/reporte-full.model';
import { Chart } from 'angular-highcharts';
import { AlertService } from 'src/app/components/_alert';

@Component({
  selector: 'app-reporte-full-viewer',
  templateUrl: './reporte-full-viewer.component.html',
  styleUrls: ['./reporte-full-viewer.component.scss']
})
export class ReporteFullViewerComponent implements OnInit {
  @Input() reporteFullContent!: ICarpetaTributariaElectronicaReporteModel | null;
  @Input() showHeader: boolean = true;
  public isNaN = Number.isNaN;
  @Input() informacionErrores: any[] = [];
  @Input() nombreBasico: string = '';
  public rut: string = '';
  public nombre: string = '';
  public informacionBalance: any;
  public informacionVentas: any[] = [];
  public balanceReporteFullInformacionBalance: typeof balanceReporteFullInformacionBalance | any = balanceReporteFullInformacionBalance;
  public balanceReporteFullResumenFinanciero: typeof balanceReporteFullResumenFinanciero | any = balanceReporteFullResumenFinanciero;
  public balanceReporteFullActivos: typeof balanceReporteFullActivos | any = balanceReporteFullActivos;
  public balanceReporteFullPasivos: typeof balanceReporteFullPasivos | any = balanceReporteFullPasivos;
  public balanceReporteFullEstadoResultados: typeof balanceReporteFullEstadoResultados | any = balanceReporteFullEstadoResultados;
  public balanceReporteFullIndicadoresFinancieros: typeof balanceReporteFullIndicadoresFinancieros | any = balanceReporteFullIndicadoresFinancieros;
  public ventasPeriodo: typeof ventasPeriodo | any = ventasPeriodo;
  public rentabilidadGraph: any = {};
  public liquidezGraph: any = {};
  public coberturaGraph: any = {};
  public eficienciaGraph: any = {};
  public estructuraFinancieraGraph: any = {};
  public ventasNetasGraph: any = {};
  public comprasNetasGraph: any = {};
  public comprasVentasNetasGraph: any = {};
  public headerBalance: string[] = [
    'nombre',
    'actividadEconomica',
    'sector',
    'subSector',
    'codActividad'
  ];

  public headerBalance2: string[] = [
    'tamano',
    'rating',
    'glosa',
    'periodo',
    'folio',
    'fecha',
    'nMeses'
  ];

  public headerResumenFinanciero: string[] = [
    'ingresosdelaExplotacion',
    'utilidadneta',
    'utilidadVentas',
    'razonCorriente',
    'permanenciaCtasporCobrar',
    'permanenciadeInventarios',
    'totalactivos',
    'totalactivosfijos',
    'totalpasivoexigible',
    'totalpatrimonio',
    'leverage'
  ];

  public headerResumenActivos: string[][] = [
    ['disponible', 'disponibleporcentaje'],
    ['valoresNegociables', 'valoresNegociablesporcentaje'],
    ['cuentasporCobrardelGiro', 'cuentasporCobrardelGiroporcentaje'],
    ['inventario', 'inventarioporcentaje'],
    ['impuestosporRecuperar', 'impuestosporRecuperarporcentaje'],
    ['deudoresVarios', 'deudoresVariosporcentaje'],
    ['cuentasporCobrarEERROperacionalCP', 'cuentasporCobrarEERROperacionalCPporcentaje'],
    ['otrosActivosCirculantes', 'otrosActivosCirculantesporcentaje'],
    ['totalactivocirculante', 'totalactivocirculantEporcentaje'],
    ['terrenosyBienesRaíces', 'terrenosyBienesRaícesporcentaje'],
    ['maquinariasEquiposyVehículos', 'maquinariasEquiposyVehículosporcentaje'],
    ['activosenLeasing', 'activosenLeasingporcentaje'],
    ['otrosActivosFijos', 'otrosActivosFijosporcentaje'],
    ['depreciaciónAcumulada', 'depreciaciónAcumuladaporcentaje'],
    ['totalactivosfijos', 'totalactivosfijoSporcentaje'],
    ['depreciaciónAcumulada', 'depreciaciónAcumuladaporcentaje'],
    ['totalactivosfijos', 'totalactivosfijoSporcentaje'],
    ['cuentasporCobrarEERROperacionalLP', 'cuentasporCobrarEERROperacionalLPporcentaje'],
    ['inversiónenEERRySociedades', 'inversiónenEERRySociedadesporcentaje'],
    ['intangibles', 'intangiblesporcentaje'],
    ['otrosActivos', 'otrosActivosporcentaje'],
    ['totalotrosactivospermanentes', 'totalotrosactivospermanenteSporcentaje'],
    ['totalactivos', 'totalactivoSporcentaje']
  ];

  public headerResumenPasivos: string[][] = [
    ['bancosCortoPlazo', 'bancosCortoPlazoporcentaje'],
    ['bancosLPporcionCP', 'bancosLPporcionCPporcentaje'],
    ['leasingLPporcionCP', 'leasingLPporcionCPporcentaje'],
    ['cartasdeCreditosPagaréOtraDFCP', 'cartasdeCreditosPagaréOtraDFCPporcentaje'],
    ['documentosyCuentasporPagardeGiro', 'documentosyCuentasporPagardeGiroporcentaje'],
    ['variosAcreedores', 'variosAcreedoresporcentaje'],
    ['cuentasporPagarEERRySocios', 'cuentasporPagarEERRySociosporcentaje'],
    ['provisionesRetencioneseImpuestos', 'provisionesRetencioneseImpuestosporcentaje'],
    ['otrosPasivosCirculantes', 'otrosPasivosCirculantesporcentaje'],
    ['totalpasivoscirculantes', 'totalpasivoscirculanteSporcentaje'],
    ['bancosLP', 'bancosLPporcentaje'],
    ['leasingLP', 'leasingLPporcentaje'],
    ['acreedoresVariosLP', 'acreedoresVariosLPporcentaje'],
    ['cuentasporPagarEERRySociosLP', 'cuentasporPagarEERRySociosLPporcentaje'],
    ['otrosPasivosLargoPlazo', 'otrosPasivosLargoPlazoporcentaje'],
    ['totalpasivolargoplazo', 'totalpasivolargoplazOporcentaje'],
    ['totalpasivoexigible', 'totalpasivoexigiblEporcentaje'],
    ['interesMinoritario', 'interesMinoritarioporcentaje'],
    ['capitalPagado', 'capitalPagadoporcentaje'],
    ['reservas', 'reservasporcentaje'],
    ['utilidadesAcumuladas', 'utilidadesAcumuladasporcentaje'],
    ['utilidaddelEjercicio', 'utilidaddelEjercicioporcentaje'],
    ['retiros', 'retirosporcentaje'],
    ['totalpatrimonio', 'totalpatrimoniOporcentaje'],
    ['totalpasivos', 'totalpasivoSporcentaje']
  ];

  public headerResumenEstadoResultados: string[][] = [
    ['ingresosdelaExplotacion', 'ingresosdelaExplotacionporcentaje'],
    ['costodeExplotacion', 'costodeExplotacionporcentaje'],
    ['resultadobruto', 'resultadobrutOporcentaje'],
    ['gastosAdministrativosyVentas', 'gastosAdministrativosyVentasporcentaje'],
    ['ebitda', 'ebitdAporcentaje'],
    ['depreciaciondelEjercicio', 'depreciaciondelEjercicioporcentaje'],
    ['resultadooperacional', 'resultadooperacionaLporcentaje'],
    ['ingresosFinancieros', 'ingresosFinancierosporcentaje'],
    ['ingresosfueradelaExplotación', 'ingresosfueradelaExplotaciónporcentaje'],
    ['utilPerdInversionenEERR', 'utilPerdInversionenEERRporcentaje'],
    ['egresosfueradeExplotacion', 'egresosfueradeExplotacionporcentaje'],
    ['gastosFinancieros', 'gastosFinancierosporcentaje'],
    ['diferenciadeCambio', 'diferenciadeCambioporcentaje'],
    ['resantescorrecmon', 'resantescorrecmoNporcentaje'],
    ['correccionMonetaria', 'correccionMonetariaporcentaje'],
    ['interesMinoritario2', 'interesMinoritario2porcentaje'],
    ['utilidadantesdeimpuestos', 'utilidadantesdeimpuestoSporcentaje'],
    ['impuestoRenta', 'impuestoRentaporcentaje'],
    ['utilidadneta', 'utilidadnetAporcentaje'],
  ];

  public headerResumenIndicadoresFinancieros: string[] = [
    'LiquidezyActividadTitle', // solo para titulo
    'capitaldeTrabajo',
    'razonCorriente',
    'testAcido',
    'permanenciaCtasporCobrar',
    'permanenciadeInventarios',
    'permdeCuentasporPagar',
    'cicloOperacionalNeto',
    'variacionenActivos',
    'variacionenPasivoExigible',
    'RentabilidadTitle', // solo para titulo
    'utilidadVentas',
    'ventaTotalIngresos',
    'ebitdaAnualizado',
    'roe',
    'roa',
    'EndeudamientoyCobertura', // solo para titulo
    'dfebitda',
    'ebitdagf',
    'leverage',
    'dfPatrimonio',
    'variacionenVentas',
    'variacionenPatrimonio'
  ];

  public headerCarpetaTributaria: string[] = [
    'date',
    'ventasNetas',
    'ventasUF',
    'comprasNetas',
    'comprasUF',
    'diferencia',
    'porcVentas',
    'fechaPago',
    'tipoDeclaracion',
    'postergado',
    'var'];

  constructor(
    private utilsService: UtilsService,
    public alertService: AlertService
    ) { }

  ngOnInit(): void {
    this.utilsService.changeSpanExtra(' > Carpeta Tributaria')
    this.setInformacionBalance();
    this.setPeriodosVenta();
    this.setRut();
    this.setNombre();
  }

  clickHeaderError(): void{
    const elementoHTML = document.getElementById("collapsable-error");
    const botonlHTML = document.getElementById("icon-l-error");
    const botondHTML = document.getElementById("icon-d-error");
    if (elementoHTML && botonlHTML && botondHTML){
      elementoHTML.hidden = !elementoHTML.hidden;
      botondHTML.hidden = !botondHTML.hidden;
      botonlHTML.hidden = !botonlHTML.hidden;

    }
  }

  setNombre(){
    if(this.reporteFullContent?.CarpetaTributariaElectronicaReporte?.Reporte?.nombre){
      this.nombre = this.reporteFullContent?.CarpetaTributariaElectronicaReporte?.Reporte?.nombre;
    } else if (this.nombreBasico) {
      this.nombre = this.nombreBasico;
    }
  }

  setRut() {
    const inter = this.reporteFullContent?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.Rut;
    const dv = this.reporteFullContent?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.DV;
    this.rut = inter + '' + dv;
  }

  /**
   * @default
   * Setea la informacion del balance, ordena por periodo ascendente
   * y solo toma los ultimos 3 periodos
   */
  setInformacionBalance(): void {
    const f22 = this.reporteFullContent?.CarpetaTributariaElectronicaReporte
      ?.Reporte?.f22 || [];

    const objF22 = f22.filter(e => ((e?.tipo !== 'manual' || !e?.tipo) && e?.calculado));

    if (objF22.length > 0) {
      this.informacionBalance = objF22.sort((a: F22, b: F22) => {
        return b.periodo - a.periodo;
      }).slice(0, 3);
    }

    if (this.validarInformacionBalance()) {
      this.generateGraphs();
    }
  }

  /**
   * @description
   * Valida si el titulo existe en el array de titulos 
   * @param title 
   * @returns 
   */
  getStrongTitle(title: string): boolean {
    const valuesKeys = [
      'totalactivocirculante',
      'totalactivosfijos',
      'totalotrosactivospermanentes',
      'totalactivos',
      'totalpasivoscirculantes',
      'totalpasivolargoplazo',
      'totalpasivoexigible',
      'totalpatrimonio',
      'totalpasivos',
      'LiquidezyActividadTitle',
      'RentabilidadTitle',
      'EndeudamientoyCobertura'
    ];
    return valuesKeys.includes(title);
  }

  getPercents(title: string): boolean {
    const valuesKeys = [
      'razonCorriente',
      'testAcido',
      'variacionenActivos',
      'variacionenPasivoExigible',
      'utilidadVentas',
      'ventaTotalIngresos',
      'roe',
      'roa',
      'dfebitda',
      'ebitdagf',
      'leverage',
      'dfPatrimonio',
      'variacionenVentas',
      'variacionenPatrimonio'
    ];
    return valuesKeys.includes(title);
  }


  async setPeriodosVenta(): Promise<void> {
    let fechasKeys: number[] = [];
    const objF29 = this.reporteFullContent?.CarpetaTributariaElectronicaReporte
      ?.Reporte?.f29 || [];

    await objF29.forEach((item: F29) => {
      if (!fechasKeys.includes(item.periodo) && item.periodo) {
        fechasKeys.push((item.periodo));
      }
    });

    fechasKeys = await fechasKeys.sort((a: number, b: number) => (b - a)).slice(0, 3);
    this.setVentasPeriodo(fechasKeys, objF29);
  }

  async setVentasPeriodo(fechasKeys: number[], objF29: F29[]): Promise<void> {
    // Arreglar fechas
    const objTemp: any[] = [];

    if (objF29.length > 0) {

      if (fechasKeys.length > 0) {
        objF29.map((item: any) => {
          if (fechasKeys.includes(item.periodo) && item.calculado && Object.keys(item.calculado).length > 0) {

            objTemp.push({
              date: item.periodo + '-' + ((item.mes) < 10 ? '0' + (item.mes) : (item.mes)) + '-' + '02',
              ventasNetas: item.calculado?.ventasNetasMilesPesos || 0,
              comprasNetas: item.calculado?.comprasNetasMilesPesos || 0,
              ventasUF: item.calculado?.ventasNetasUf || 0,
              comprasUF: item.calculado?.comprasNetasUf || 0,
              diferencia: item.calculado?.margenMiles || 0,
              porcVentas: item.calculado?.porc_notascred_ventas || 0,
              tipoDeclaracion: item.calculado?.tipoDeclaracion || '',
              postergado: item.calculado?.postergacionIVA || 0,
              var: item.calculado?.variacion || 0,
              fechaPago: this.utilsService.formatDate(item.calculado?.fechaPago) || '',
            });
          }
        });

        fechasKeys.map((item: any) => {
          for (let i = 1; i <= 12; i++) {
            if (objTemp.find((e: any) => e.date === item + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02') === undefined) {
              objTemp.push({
                date: item + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02',
                ventasNetas: '',
                comprasNetas: '',
                ventasUF: '',
                comprasUF: '',
                diferencia: '',
                porcVentas: '',
                tipoDeclaracion: '',
                postergado: '',
                var: '',
                fechaPago: '',
              });
            }
          }
        });

        if (objTemp.length > 0) {
          const variacionObj = this.calcularVariacion(objTemp.sort((a, b) => (a.date > b.date ? 1 : -1)));
          this.informacionVentas = this.dividerInGroup(variacionObj.sort((a, b) => (a.date > b.date ? 1 : -1)));
        }
      }
    }

    if (this.informacionVentas.length > 0) {
      this.generateGraphsF29();
    }
  }

  calcularVariacion(obj: any[]) {
    let ultimaVenta = 0;
    // Calculo variacion
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].var !== ''){
        if (i===0){ //Enero
          const variacion = ultimaVenta !== 0 ?
            ((obj[i].ventasNetas - ultimaVenta) / ultimaVenta) * 100 : 0;
          obj[i].var = variacion;
        } else {
          const variacion = obj[i - 1].ventasNetas !== 0 && obj[i - 1].ventasNetas !== '' && obj[i].ventasNetas !== '' ?
            ((obj[i].ventasNetas - obj[i - 1].ventasNetas) / obj[i - 1].ventasNetas) * 100 : 0;
          obj[i].var = variacion;
        }
      }

      if (i === 11){ //Diciembre
        ultimaVenta = obj[i].ventasNetas;
      }
    }

    return obj;
  }

  dividerInGroup(data: any[]) {
    const result: any[] = [];

    var groups = new Set(data.map(item => item.date.slice(0, 4)).sort((a: number, b: number) => (b - a)));

    groups.forEach(g =>
      result.push({
        date: g.slice(0, 4),
        group: data.filter(i => i.date.slice(0, 4) === g.slice(0, 4)),
        total: this.createNewObject(data.filter(i => i.date.slice(0, 4) === g.slice(0, 4)), 'total'),
        promedio: this.createNewObject(data.filter(i => i.date.slice(0, 4) === g.slice(0, 4)), 'promedio'),
        proyeccion: this.createNewObject(data.filter(i => i.date.slice(0, 4) === g.slice(0, 4)), 'proyeccion'),
      }
      ));
    return result;
  }

  sumProperties(items: any, prop: string) {
    const sum = items.reduce((a: any, b: any) => {
      return !isNaN(Number(b[prop])) ? (!isNaN(Number(a)) ? a : 0) + Number(b[prop]) : a;
    }, 0);
    return sum;
  }

  createNewObject(obj: any[], type: string) {

    const ValuesNotCaclulated = [
      'date',
      'tipoDeclaracion',
      'fechaPago',
    ];

    const newObj: any[] = [];
    if (obj.length > 0) {
      for (const keyObj of Object.keys(obj[0])) {
        if (!ValuesNotCaclulated.includes(keyObj)) {
          if (type === 'total' || type === 'proyeccion') {

            type === 'total' ?

              newObj.push({ [keyObj]: this.sumProperties(obj, keyObj) }) : (
                newObj.push({
                  [keyObj]: this.obtenerPromedio(obj, keyObj) * 12
                }));

          } else {
            if (!ValuesNotCaclulated.includes(keyObj)) {
              newObj.push({
                [keyObj]: this.obtenerPromedio(obj, keyObj)
              });
            }
          }
        }
      }
      return newObj;
    }
    return [];
  }

  dividerGroup(obj: any[]) {
    const groups: any = [{ group1: [], group2: [], total: [] }];
    let count: number = 1;
    obj.forEach((element: any, i: number) => {
      groups[0]['group' + count].push(element);
      if (i === 5) {
        count++;
      }
    });

    groups[0]['total'].push({
      item1: this.createNewObject(groups[0].group1, 'total'),
      item2: this.createNewObject(groups[0].group2, 'total')
    });

    return groups;

  }

  obtenerPromedio(obj: any[], keyObj: string) {
    const valuesKey = [
      'ventasNetas',
      'ventasUF',
      'comprasNetas',
      'comprasUF',
      'diferencia',
      'porcVentas',
    ];

    const result = this.sumProperties(obj, keyObj) / obj.filter(e =>
      e[valuesKey[0]] !== '' ||
      e[valuesKey[1]] !== '' ||
      e[valuesKey[2]] !== '' ||
      e[valuesKey[3]] !== '' ||
      e[valuesKey[4]] !== '' ||
      e[valuesKey[5]] !== '').length

    return result;
  }

  getCountArray(obj: any[]): number[] {
    // convertir la cantidad de objetos a un array de numeros
    const countArray: number[] = [];
    obj.forEach((element: any, i: number) => {
      countArray.push(i + 1);
    });
    return countArray;
  }

  generateGraphs(): void {
    this.setGraficoRentabilidad();
    this.setGraficoLiquidez();
    this.setGraficoCobertura();
    this.setGraficoEficienciaGraph();
    this.setGraficoEstructuraFinancieraGraph();
  }

  generateGraphsF29(): void {
    this.setGraficoVentasNetasGraph();
    this.setGraficoComprasNetasGraph();
    this.setGraficoComprasVentasNetasGraph();
  }

  /**
   * @description
   * Setea los valores de la grafica de rentabilidad  
   */
  setGraficoRentabilidad(): void {
    const VariableColors: string[] = ['#3c64ac', '#4d8cb5', '#62993f'];
    const VariableLabels: any[] = [
      { variable: 'utilidadVentas', percent: true },
      { variable: 'roe', percent: true },
      { variable: 'roa', percent: true }];

    this.rentabilidadGraph = {
      title: 'Evolución Indicadores de Rentabilidad',
      categorias: [{ categories: ['Utilidad / Ventas', 'ROE (%)', 'ROA (%)'] }],
      serie: this.setSerieToGraph(VariableColors, VariableLabels),
      plotOptions: {},
      yAxix: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }]
    };
  }

  /**
 * @description
 * Setea los valores de la grafica de liquidez  
 */
  setGraficoLiquidez(): void {
    const VariableColors: string[] = ['#eb7d30', '#569cd8', '#a5a5a5'];
    const VariableLabels: any[] = [
      { variable: 'razonCorriente', percent: true },
      { variable: 'testAcido', percent: true },
      { variable: 'permanenciaCtasporCobrar', percent: true },
      { variable: 'permdeCuentasporPagar', percent: true },
    ];

    this.liquidezGraph = {
      title: 'Evolución Indicadores de Liquidez',
      categorias: [{
        categories: ['Razón Corriente (veces)',
          'Test Ácido (veces)',
          'Permanecia Cuentas por Cobrar (días)',
          'Perms de Cuentas por Pagar (días)',
        ]
      }],
      serie: this.setSerieToGraph(VariableColors, VariableLabels),
      plotOptions: {},
      yAxix: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }]
    };
  }

  /**
 * @description
 * Setea los valores de la grafica de liquidez  
 */
  setGraficoCobertura(): void {
    const VariableColors: string[] = ['#4b91c4', '#e26627', '#efa689'];
    const VariableLabels: any[] = [
      { variable: 'ebitdagf', percent: true },
      { variable: 'leverage', percent: true },
      { variable: 'ingresosdelaExplotacion', variable2: 'totalpasivoexigible', percent: true },
      { variable: 'ingresosdelaExplotacion', variable2: 'totalpatrimonio', percent: true },
    ];
    
    this.coberturaGraph = {
      title: 'Evolución Indicadores de Endeudamiento y Cobertura',
      categorias: [{
        categories: ['EBITDA / GF (veces)',
          'Leverage (veces)',
          'Endeudamiento / Ventas (veces)',
          'Ventas / Patrimonio (veces)',
        ]
      }],
      serie: this.setSerieToGraph(VariableColors, VariableLabels),
      plotOptions: {},
      yAxix: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }]
    };
  }

  /**
 * @description
 * Setea los valores de la grafica de liquidez  
 */
  setGraficoEficienciaGraph(): void {
    const VariableColors: string[] = ['#4182bc', '#6faf3f', '#335da9'];
    const VariableLabels: any[] = [
      { variable: 'ingresosdelaExplotacion', percent: false },
      { variable: 'ebitda', percent: false },
      { variable: 'gastosFinancieros', percent: false },
      { variable: 'utilidadneta', percent: false }
    ];

    this.eficienciaGraph = {
      title: 'Evolución Indicadores de eficiencia',
      categorias: [{
        categories: ['Ingresos de la Explotación',
          'EBITDA',
          '(Gastos Financieros)',
          'Utilidad Neta',
        ]
      }],
      serie: this.setSerieToGraph(VariableColors, VariableLabels),
      plotOptions: {},
      yAxix: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }]
    };
  }

  /**
 * @description
 * Setea los valores de la grafica de Estructura Financiera  
 */
  setGraficoEstructuraFinancieraGraph(): void {
    const VariableColors: string[] = ['#deab11', '#bcc1ba', '#3c64ac'];
    const VariableLabels: any[] = [
      { variable: 'totalactivocirculante', percent: false },
      { variable: 'totalactivosfijos', percent: false },
      { variable: 'totalotrosactivospermanentes', percent: false },
      { variable: 'totalpasivoscirculantes', percent: false },
      { variable: 'totalpatrimonio', percent: false }
    ];

    this.estructuraFinancieraGraph = {
      title: 'Estructura Financiera',
      categorias: [{
        categories: [
          'TOTAL ACTIVO CIRCULANTE',
          'TOTAL ACTIVOS FIJOS',
          'TOTAL OTROS ACTIVOS',
          'TOTAL PASIVOS CIRCULANTE',
          'TOTAL PATRIMONIO'
        ]
      }],
      serie: this.setSerieToGraph(VariableColors, VariableLabels),
      plotOptions: {},
      yAxix: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }]
    };
  }

  /**
 * @description
 * Setea los valores de la grafica de Ventas Netas  
 */
  setGraficoVentasNetasGraph(): void {
    const VariableColors: string[] = ['#4364b3', '#8abaeb', '#5a8430'];
    const VariableLabels: any = { variable: 'ventasNetas', percent: false };
    let chart = new Chart({
      xAxis: [{
        categories: [
          'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
          'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ]
      }],
      title: {
        text: 'Ventas Netas M$'
      },
      yAxis: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      series: this.setSerieToGraphF29(this.informacionVentas, VariableColors, VariableLabels)
    });
    this.ventasNetasGraph = chart;
  }

  /**
 * @description
 * Setea los valores de la grafica de Comprar Netas  
 */
  setGraficoComprasNetasGraph(): void {
    const VariableColors: string[] = ['#a8a8a8', '#e5c03d', '#4363c8'];
    const VariableLabels: any = { variable: 'comprasNetas', percent: false };
    let chart = new Chart({
      xAxis: [{
        categories: [
          'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
          'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ]
      }],
      title: {
        text: 'Compras Netas M$'
      },
      yAxis: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      series: this.setSerieToGraphF29(this.informacionVentas, VariableColors, VariableLabels)
    });
    this.comprasNetasGraph = chart;
  }

  /**
 * @description
 * Setea los valores de la grafica de Comprar y Ventas Netas  
 */
  setGraficoComprasVentasNetasGraph(): void {
    const VariableColors: string[] = ['#4364b3', '#959595'];
    const VariableLabels: any = [
      { variable: 'ventasNetas', percent: false },
      { variable: 'comprasNetas', percent: false }
    ];

    let chart = new Chart({
      xAxis: [{
        categories: [
          'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
          'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ]
      }],
      title: {
        text: 'Ventas Netas M$ / Compras Netas M$'
      },
      yAxis: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      series: this.setSerieToGraphComprasVentasNetas(this.informacionVentas, VariableColors, VariableLabels)
    });
    this.comprasVentasNetasGraph = chart;    
  }

  /**
   * @description
   * Setea los datos de la serie para el grafico F22
   * @param colorsSerie 
   * @param labels 
   * @returns 
   */
  setSerieToGraph(colorsSerie: string[], labels: any[], type: string = 'column'): any[] {
    const tempSeries: any = [];
    this.informacionBalance.forEach((element: any, i: number) => {
      tempSeries.push({
        name: element.periodo - 1,
        type: type,
        color: colorsSerie[i],
        data: this.calculateElements(labels, element)
      });
    });
    return tempSeries;
  }

  /**
   * @description
   * Setea los datos de la serie para el grafico F29
   * @param colorsSerie 
   * @param labels 
   * @returns 
   */
  setSerieToGraphF29(informacionVentas: any, colorsSerie: string[], labels: any[]): any[] {
    const tempSeries: any = [];
    informacionVentas.forEach((element: any, i: number) => {
      tempSeries.push({
        name: element.date,
        color: colorsSerie[i],
        data: this.calculateElementsF29(labels, element.group)
      });
    });
    return tempSeries;
  }

  /**
   * @description
   * Setea los datos de la serie para el grafico F29
   * @param colorsSerie 
   * @param labels 
   * @returns 
   */
  setSerieToGraphComprasVentasNetas(informacionVentas: any, colorsSerie: string[], labels: any[]): any[] {
    const tempSeries: any = [];

    labels.forEach((value: any, e: number) => {
      [informacionVentas[0]].forEach((element: any, i: number) => {
        tempSeries.push({
          name: element.date,
          color: colorsSerie[e],
          data: this.calculateElementsF29(value, element.group)
        });
      });
    });
    return tempSeries;
  }

  /**
   * @description
   * Calcula los valores de los indicadores recibidos
   * @param labels 
   * @param element 
   * @returns 
   */
  calculateElements(labels: any[], element: any): any[] {
    const tempData: any[] = [];
    labels.forEach((e: any) => {
      e.variable2? 
      tempData.push(element?.calculado?.[e.variable] && !isNaN(element?.calculado?.[e.variable]) ? (
        !e.percent ?
          element.calculado[e.variable] : Number((element.calculado[e.variable] / element.calculado[e.variable2]).toFixed(2))) : 0)
          :  
      tempData.push(element?.calculado?.[e.variable] && !isNaN(element?.calculado?.[e.variable]) ? (
        !e.percent ?
          element.calculado[e.variable] : Number(element.calculado[e.variable].toFixed(2))) : 0)
    });
    return tempData;
  }

  /**
   * @description
   * Calcula los valores de los indicadores recibidos
   * @param labels 
   * @param element 
   * @returns 
   */
  calculateElementsF29(labels: any, element: any): any[] {
    const tempData: any[] = [];
    element.forEach((e: any) => {
      tempData.push(e?.[labels.variable] ? (
        !labels.percent ?
          e[labels.variable] : Number(e[labels.variable].toFixed(2))) : '')
    });
    return tempData;
  }

  validaNombre(): boolean{
    if(this.reporteFullContent?.CarpetaTributariaElectronicaReporte?.Reporte?.nombre && !this.validarInformacionBalance()){
      return true;
    }
    if(this.nombreBasico  && !this.reporteFullContent?.CarpetaTributariaElectronicaReporte?.Reporte?.nombre && !this.validarInformacionBalance()){
      return true;
    }
    return false;
  }

  validarInformacionBalance(): boolean {
    if(this.informacionBalance && this.informacionBalance.length > 0) {
      const datos = this.informacionBalance.filter((e: any) => e && ((e?.error !== undefined && e?.error !== null && e?.error !== true) || e?.calculado));
      if(datos.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

}
