import { Component, Input, OnInit } from '@angular/core';
import { Reporte } from 'src/app/models/formulario-f22/formulario-f22.model';
import { balanceReporteFullActivos, balanceReporteFullEstadoResultados, balanceReporteFullIndicadoresFinancieros, balanceReporteFullInformacionBalance, balanceReporteFullPasivos, balanceReporteFullResumenFinanciero, ventasPeriodo } from 'src/app/models/utils/utils.model';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { Chart } from 'angular-highcharts';
import { F29 } from 'src/app/models/reporte-full/reporte-full.model';

@Component({
  selector: 'app-balance-automatico-demo-workflow',
  templateUrl: './balance-automatico.component.html',
  styleUrls: ['./balance-automatico.component.scss']
})
export class BalanceAutomaticoDemoComponent implements OnInit {
  @Input() reporteFullContent!: any | null;
  @Input() dataF29!: any[] | null;
  @Input() rut!: string | null;

  public informacionBalance: any;
  public balanceReporteFullInformacionBalance: typeof balanceReporteFullInformacionBalance | any = balanceReporteFullInformacionBalance;
  public balanceReporteFullResumenFinanciero: typeof balanceReporteFullResumenFinanciero | any = balanceReporteFullResumenFinanciero;
  public balanceReporteFullActivos: typeof balanceReporteFullActivos | any = balanceReporteFullActivos;
  public balanceReporteFullPasivos: typeof balanceReporteFullPasivos | any = balanceReporteFullPasivos;
  public balanceReporteFullEstadoResultados: typeof balanceReporteFullEstadoResultados | any = balanceReporteFullEstadoResultados;
  public balanceReporteFullIndicadoresFinancieros: typeof balanceReporteFullIndicadoresFinancieros | any = balanceReporteFullIndicadoresFinancieros;
  public ventasPeriodo: typeof ventasPeriodo | any = ventasPeriodo;
  public ventasNetasGraph: any = {};
  public informacionVentas: any[] = [];
  
  constructor(
    private utilsService: UtilsService
  ) { 
  }

  public headerBalance: string[] = [
    'periodo',
    'folio',
    'fecha',
    'nombre',
    'nMeses',
    'actividadEconomica',
    'sector',
    'subSector',
    'codActividad',
    'tamano',
    'rating',
    'glosa'
  ];



  public headerResumenFinanciero: string[] = [
    'ingresosdelaExplotacion',
    'utilidadneta',
    'utilidadVentas',
    'razonCorriente',
    'permanenciaCtasporCobrar',
    'permanenciadeInventarios',
    'totalactivos',
    'totalactivosfijos',
    'totalpasivoexigible',
    'totalpatrimonio',
    'leverage'
  ];

  public headerResumenActivos: string[][] = [
    ['disponible', 'disponibleporcentaje'],
    ['valoresNegociables', 'valoresNegociablesporcentaje'],
    ['cuentasporCobrardelGiro', 'cuentasporCobrardelGiroporcentaje'],
    ['inventario', 'inventarioporcentaje'],
    ['impuestosporRecuperar', 'impuestosporRecuperarporcentaje'],
    ['deudoresVarios', 'deudoresVariosporcentaje'],
    ['cuentasporCobrarEERROperacionalCP', 'cuentasporCobrarEERROperacionalCPporcentaje'],
    ['otrosActivosCirculantes', 'otrosActivosCirculantesporcentaje'],
    ['totalactivocirculante', 'totalactivocirculantEporcentaje'],
    ['terrenosyBienesRaíces', 'terrenosyBienesRaícesporcentaje'],
    ['maquinariasEquiposyVehículos', 'maquinariasEquiposyVehículosporcentaje'],
    ['activosenLeasing', 'activosenLeasingporcentaje'],
    ['otrosActivosFijos', 'otrosActivosFijosporcentaje'],
    ['depreciaciónAcumulada', 'depreciaciónAcumuladaporcentaje'],
    ['totalactivosfijos', 'totalactivosfijoSporcentaje'],
    ['cuentasporCobrarEERROperacionalLP', 'cuentasporCobrarEERROperacionalLPporcentaje'],
    ['inversiónenEERRySociedades', 'inversiónenEERRySociedadesporcentaje'],
    ['intangibles', 'intangiblesporcentaje'],
    ['otrosActivos', 'otrosActivosporcentaje'],
    ['totalotrosactivospermanentes', 'totalotrosactivospermanenteSporcentaje'],
    ['totalactivos', 'totalactivoSporcentaje']
  ];

  public headerResumenPasivos: string[][] = [
    ['bancosCortoPlazo', 'bancosCortoPlazoporcentaje'],
    ['bancosLPporcionCP', 'bancosLPporcionCPporcentaje'],
    ['leasingLPporcionCP', 'leasingLPporcionCPporcentaje'],
    ['cartasdeCreditosPagaréOtraDFCP', 'cartasdeCreditosPagaréOtraDFCPporcentaje'],
    ['documentosyCuentasporPagardeGiro', 'documentosyCuentasporPagardeGiroporcentaje'],
    ['variosAcreedores', 'variosAcreedoresporcentaje'],
    ['cuentasporPagarEERRySocios', 'cuentasporPagarEERRySociosporcentaje'],
    ['provisionesRetencioneseImpuestos', 'provisionesRetencioneseImpuestosporcentaje'],
    ['otrosPasivosCirculantes', 'otrosPasivosCirculantesporcentaje'],
    ['totalpasivoscirculantes', 'totalpasivoscirculanteSporcentaje'],
    ['bancosLP', 'bancosLPporcentaje'],
    ['leasingLP', 'leasingLPporcentaje'],
    ['acreedoresVariosLP', 'acreedoresVariosLPporcentaje'],
    ['cuentasporPagarEERRySociosLP', 'cuentasporPagarEERRySociosLPporcentaje'],
    ['otrosPasivosLargoPlazo', 'otrosPasivosLargoPlazoporcentaje'],
    ['totalpasivolargoplazo', 'totalpasivolargoplazOporcentaje'],
    ['totalpasivoexigible', 'totalpasivoexigiblEporcentaje'],
    ['interesMinoritario', 'interesMinoritarioporcentaje'],
    ['capitalPagado', 'capitalPagadoporcentaje'],
    ['reservas', 'reservasporcentaje'],
    ['utilidadesAcumuladas', 'utilidadesAcumuladasporcentaje'],
    ['utilidaddelEjercicio', 'utilidaddelEjercicioporcentaje'],
    ['retiros', 'retirosporcentaje'],
    ['totalpatrimonio', 'totalpatrimoniOporcentaje'],
    ['totalpasivos', 'totalpasivoSporcentaje']
  ];

  public headerResumenEstadoResultados: string[][] = [
    ['ingresosdelaExplotacion', 'ingresosdelaExplotacionporcentaje'],
    ['costodeExplotacion', 'costodeExplotacionporcentaje'],
    ['resultadobruto', 'resultadobrutOporcentaje'],
    ['gastosAdministrativosyVentas', 'gastosAdministrativosyVentasporcentaje'],
    ['ebitda', 'ebitdAporcentaje'],
    ['depreciaciondelEjercicio', 'depreciaciondelEjercicioporcentaje'],
    ['resultadooperacional', 'resultadooperacionaLporcentaje'],
    ['ingresosFinancieros', 'ingresosFinancierosporcentaje'],
    ['ingresosfueradelaExplotación', 'ingresosfueradelaExplotaciónporcentaje'],
    ['utilPerdInversionenEERR', 'utilPerdInversionenEERRporcentaje'],
    ['egresosfueradeExplotacion', 'egresosfueradeExplotacionporcentaje'],
    ['gastosFinancieros', 'gastosFinancierosporcentaje'],
    ['diferenciadeCambio', 'diferenciadeCambioporcentaje'],
    ['resantescorrecmon', 'resantescorrecmoNporcentaje'],
    ['correccionMonetaria', 'correccionMonetariaporcentaje'],
    ['interesMinoritario2', 'interesMinoritario2porcentaje'],
    ['utilidadantesdeimpuestos', 'utilidadantesdeimpuestoSporcentaje'],
    ['impuestoRenta', 'impuestoRentaporcentaje'],
    ['utilidadneta', 'utilidadnetAporcentaje'],
  ];

  public headerResumenIndicadoresFinancieros: string[] = [
    'LiquidezyActividadTitle', // solo para titulo
    'capitaldeTrabajo',
    'razonCorriente',
    'testAcido',
    'permanenciaCtasporCobrar',
    'permanenciadeInventarios',
    'permdeCuentasporPagar',
    'cicloOperacionalNeto',
    'variacionenActivos',
    'variacionenPasivoExigible',
    'RentabilidadTitle', // solo para titulo
    'utilidadVentas',
    'ventaTotalIngresos',
    'ebitdaAnualizado',
    'roe',
    'roa',
    'EndeudamientoyCobertura', // solo para titulo
    'dfebitda',
    'ebitdagf',
    'leverage',
    'dfPatrimonio',
    'variacionenVentas',
    'variacionenPatrimonio'
  ];

  ngOnInit(): void {
    this.utilsService.changeSpanExtra(' > Balance')
    this.setInformacionBalance();
    //this.setRut();
    if (this.dataF29 && this.dataF29.length > 0) {
      this.setPeriodosVenta();
    }
  }

  generateGraphsF29(): void {
    this.setGraficoVentasNetasGraph();
    //this.setGraficoComprasNetasGraph();
    //this.setGraficoComprasVentasNetasGraph();
  }

  async setPeriodosVenta(): Promise<void> {
    let fechasKeys: number[] = [];
    const objF29 = JSON.parse(JSON.stringify(this.dataF29));

    await objF29.forEach((item: F29) => {
      if (!fechasKeys.includes(item.periodo) && item.periodo) {
        fechasKeys.push((item.periodo));
      }
    });

    fechasKeys = await fechasKeys.sort((a: number, b: number) => (b - a)).slice(0, 3);
    this.setVentasPeriodo(fechasKeys, objF29);
  }

  async setVentasPeriodo(fechasKeys: number[], objF29: F29[]): Promise<void> {
    // Arreglar fechas
    const objTemp: any[] = [];

    if (objF29.length > 0) {

      if (fechasKeys.length > 0) {
        objF29.map((item: any) => {
          if (fechasKeys.includes(item.periodo)) {

            objTemp.push({
              date: item.periodo + '-' + ((item.mes) < 10 ? '0' + (item.mes) : (item.mes)) + '-' + '02',
              ventasNetas: item.calculado?.ventasNetasMilesPesos || 0,
              comprasNetas: item.calculado?.comprasNetasMilesPesos || 0,
              ventasUF: item.calculado?.ventasNetasUf || 0,
              comprasUF: item.calculado?.comprasNetasUf || 0,
              diferencia: item.calculado?.margenMiles || 0,
              porcVentas: item.calculado?.porc_notascred_ventas || 0,
              tipoDeclaracion: item.calculado?.tipoDeclaracion || '',
              postergado: item.calculado?.postergacionIVA || 0,
              var: item.calculado?.variacion || 0,
              fechaPago: this.utilsService.formatDate(item.calculado?.fechaPago) || '',
            });
          }
        });

        fechasKeys.map((item: any) => {
          for (let i = 1; i <= 12; i++) {
            if (objTemp.find((e: any) => e.date === item + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02') === undefined) {
              objTemp.push({
                date: item + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02',
                ventasNetas: 0,
                comprasNetas: 0,
                ventasUF: 0,
                comprasUF: 0,
                diferencia: 0,
                porcVentas: 0,
                tipoDeclaracion: '',
                postergado: 0,
                var: 0,
                fechaPago: '',
              });
            }
          }
        });

        if (objTemp.length > 0) {
          const variacionObj = this.calcularVariacion(objTemp.sort((a, b) => (a.date > b.date ? 1 : -1)));
          this.informacionVentas = this.dividerInGroup(variacionObj.sort((a, b) => (a.date > b.date ? 1 : -1)));
        }
      }
    }

    if (this.informacionVentas.length > 0) {
      this.generateGraphsF29();
    }
  }

  calcularVariacion(obj: any[]) {
    let ultimaVenta = 0;
    // Calculo variacion
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].var !== ''){
        if (i===0){ //Enero
          const variacion = ultimaVenta !== 0 ?
            ((obj[i].ventasNetas - ultimaVenta) / ultimaVenta) * 100 : 0;
          obj[i].var = variacion;
        } else {
          const variacion = obj[i - 1].ventasNetas !== 0 && obj[i - 1].ventasNetas !== '' ?
            ((obj[i].ventasNetas - obj[i - 1].ventasNetas) / obj[i - 1].ventasNetas) * 100 : 0;
          obj[i].var = variacion;
        }
      }

      if (i === 11){ //Diciembre
        ultimaVenta = obj[i].ventasNetas;
      }
    }

    return obj;
  }

  dividerInGroup(data: any[]) {
    const result: any[] = [];

    var groups = new Set(data.map(item => item.date.slice(0, 4)).sort((a: number, b: number) => (b - a)));

    groups.forEach(g =>
      result.push({
        date: g.slice(0, 4),
        group: data.filter(i => i.date.slice(0, 4) === g.slice(0, 4)),
        total: this.createNewObject(data.filter(i => i.date.slice(0, 4) === g.slice(0, 4)), 'total'),
        promedio: this.createNewObject(data.filter(i => i.date.slice(0, 4) === g.slice(0, 4)), 'promedio'),
        proyeccion: this.createNewObject(data.filter(i => i.date.slice(0, 4) === g.slice(0, 4)), 'proyeccion'),
      }
      ));
    return result;
  }

  /**
 * @description
 * Setea los valores de la grafica de Ventas Netas  
 */
  setGraficoVentasNetasGraph(): void {
    const VariableColors: string[] = ['#4364b3', '#8abaeb', '#5a8430'];
    const VariableLabels: any = { variable: 'ventasNetas', percent: false };
    let chart = new Chart({
      xAxis: [{
        categories: [
          'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
          'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ]
      }],
      title: {
        text: 'Ventas Netas M$'
      },
      yAxis: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      series: this.setSerieToGraphF29(this.informacionVentas, VariableColors, VariableLabels)
    });
    this.ventasNetasGraph = chart;
  }

  /**
   * @description
   * Setea los datos de la serie para el grafico F29
   * @param colorsSerie 
   * @param labels 
   * @returns 
   */
  setSerieToGraphF29(informacionVentas: any, colorsSerie: string[], labels: any[]): any[] {
    const tempSeries: any = [];
    informacionVentas.forEach((element: any, i: number) => {
      tempSeries.push({
        name: element.date,
        color: colorsSerie[i],
        data: this.calculateElementsF29(labels, element.group)
      });
    });
    return tempSeries;
  }

  /**
   * @description
   * Calcula los valores de los indicadores recibidos
   * @param labels 
   * @param element 
   * @returns 
   */
  calculateElementsF29(labels: any, element: any): any[] {
    const tempData: any[] = [];
    element.forEach((e: any) => {
      tempData.push(e?.[labels.variable] ? (
        !labels.percent ?
          e[labels.variable] : Number(e[labels.variable].toFixed(2))) : '')
    });
    return tempData;
  }

  /**
   * @default
   * Setea la informacion del balance, ordena por periodo ascendente
   * y solo toma los ultimos 3 periodos
   */
   setInformacionBalance(): void {
    if (this.reporteFullContent.length > 0) {
      this.informacionBalance = this.reporteFullContent.sort((a: Reporte, b: Reporte) => {
        return b.periodo - a.periodo;
      });
    }
  }

  

  getPercents(title: string): boolean {
    const valuesKeys = [
      'razonCorriente',
      'testAcido',
      'variacionenActivos',
      'variacionenPasivoExigible',
      'utilidadVentas',
      'ventaTotalIngresos',
      'roe',
      'roa',
      'dfebitda',
      'ebitdagf',
      'leverage',
      'dfPatrimonio',
      'variacionenVentas',
      'variacionenPatrimonio'
    ];
    return valuesKeys.includes(title);
  }

  /**
   * @description
   * Valida si el titulo existe en el array de titulos 
   * @param title 
   * @returns 
   */
   getStrongTitle(title: string): boolean {
    const valuesKeys = [
      'totalactivocirculante',
      'totalactivosfijos',
      'totalotrosactivospermanentes',
      'totalactivos',
      'totalpasivoscirculantes',
      'totalpasivolargoplazo',
      'totalpasivoexigible',
      'totalpatrimonio',
      'totalpasivos',
      'LiquidezyActividadTitle',
      'RentabilidadTitle',
      'EndeudamientoyCobertura'
    ];
    return valuesKeys.includes(title);
  }

  getCountArray(obj: any[]): number[] {
    // convertir la cantidad de objetos a un array de numeros
    const countArray: number[] = [];
    obj.forEach((element: any, i: number) => {
      countArray.push(i + 1);
    });
    return countArray;
  }

  //Funcion para obtener letra a partir de numero
  getColName(n: number): string {
    const ordA = 'a'.charCodeAt(0);
    const ordZ = 'z'.charCodeAt(0);
    const len = ordZ - ordA + 1;
  
    let s = "";
    while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s;
  }

  getNumberFormatted(dato: number): number{
    return Number(Number(dato).toFixed(2))
  }

  getNumberFormattedInt(dato: number): number{
    return Number(Number(dato).toFixed())
  }

  isNumber(val: any): boolean { return typeof val === 'number'; }

  createNewObject(obj: any[], type: string) {

    const ValuesNotCaclulated = [
      'date',
      'tipoDeclaracion',
      'fechaPago',
    ];

    const newObj: any[] = [];
    if (obj.length > 0) {
      for (const keyObj of Object.keys(obj[0])) {
        if (!ValuesNotCaclulated.includes(keyObj)) {
          if (type === 'total' || type === 'proyeccion') {

            type === 'total' ?

              newObj.push({ [keyObj]: this.sumProperties(obj, keyObj) }) : (
                newObj.push({
                  [keyObj]: this.obtenerPromedio(obj, keyObj) * 12
                }));

          } else {
            if (!ValuesNotCaclulated.includes(keyObj)) {
              newObj.push({
                [keyObj]: this.obtenerPromedio(obj, keyObj)
              });
            }
          }
        }
      }
      return newObj;
    }
    return [];
  }

  sumProperties(items: any, prop: string) {
    const sum = items.reduce((a: any, b: any) => {
      return !isNaN(Number(b[prop])) ? (!isNaN(Number(a)) ? a : 0) + Number(b[prop]) : a;
    }, 0);
    return sum;
  }

  obtenerPromedio(obj: any[], keyObj: string) {
    const valuesKey = [
      'ventasNetas',
      'ventasUF',
      'comprasNetas',
      'comprasUF',
      'diferencia',
      'porcVentas',
    ];

    const result = this.sumProperties(obj, keyObj) / obj.filter(e =>
      e[valuesKey[0]] !== 0 ||
      e[valuesKey[1]] !== 0 ||
      e[valuesKey[2]] !== 0 ||
      e[valuesKey[3]] !== 0 ||
      e[valuesKey[4]] !== 0 ||
      e[valuesKey[5]] !== 0).length

    return result;
  }

}
