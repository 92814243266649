import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CognitoService } from '../shared/services/cognito.service';
import { InactivityService } from '../shared/services/inactivity.service';
import { SessionService } from '../shared/services/session.service';
import { fetchAuthSession } from 'aws-amplify/auth';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private inactivityService: InactivityService,
    private _router: Router) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree>  {

      try {
        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
        if(idToken) return true
        this.inactivityService.onStopWatching();
        this._router.navigate(['/login'])
      } catch (err) {
        console.log(err);
        this.inactivityService.onStopWatching();
        this._router.navigate(['/login'])
      }
      return false;

    }
  }