import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin, from, of, Subscription } from 'rxjs';
import { AlertService } from 'src/app/components/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { SolicitudCreditoServiceWorkflow } from '../../../../shared/services/solicitud-credito-workflow.service';
import { catchError, filter, map, mergeMap, toArray } from 'rxjs/operators';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { ModalBasicoConsolidadoService } from '../../../../shared/services/modal-basico-consolidado.service';
import { ESystemAccess } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

interface CargaFinanciera {
  fechaCurse: string,
  institucion: string,
  tipoCredito: string,
  creditoOriginal: number | string,
  deudaActual: number | string,
  cuota: number | string,
  nCuotasPorPagar: number | string,
  nCuotasMorosas: number | string,
  ultimoVencimiento: string,
  garantiasVigentes: string
}

@Component({
  selector: 'app-plantilla-demo',
  templateUrl: './plantilla-demo.component.html',
  styleUrls: ['./plantilla-demo.component.scss']
})
export class PlantillaDemoComponent implements OnInit {
  //Informacion para plantilla no editable
  @Input() plantillaNoEditable: boolean = false;
  @Input() objEntradaFicha: any = {};
  @Input() objEntradaMotor: any = {};

  @Output() operacionNuevo = new EventEmitter<boolean>();
  @Output() finalizarFlujo = new EventEmitter<void>();
  @Output() bloqueaPasos5 = new EventEmitter<boolean>();
  @Output() bloqueaPasos6 = new EventEmitter<boolean>();
  @Input() idTransaccion: string = '';
  @Input() stepper!: number;
  @Input() rut: string = '';
  @Input() closed: boolean = true;
  public objectKeys = Object.keys;
  public servicioFichaForm!: UntypedFormGroup;
  public servicioDeudoresForm!: UntypedFormGroup;

  private access: string[] = [
    ESystemAccess.FLUJO_CREDITO_BUSCAR, 
    ESystemAccess.FLUJO_CREDITO_BUSCAR_ADMIN,
    ESystemAccess.FLUJO_CREDITO,
    ESystemAccess.FLUJO_CREDITO_ADMIN
  ];

  public activateRutComiteHelper: boolean = false;
  public activateRutClienteComiteHelper: boolean = false;

  public subscriptions: Subscription[] = [];
  public data: any;
  public today = '';
  public dataFiltrada: any;
  public dataCargada: boolean = false;
  public groupNameConsulting: string[] = ['buscarReportePrincipal', 'buscarReportesDerivados'];
  public groupNameConsultingFicha: string[] = ['buscarFichaDeCredito', 'buscarReportesDerivados', 'buscarReportePrincipal'];
  public groupNameConsultingScore: string[] = ['buscarCalculoScore', 'buscarReportesDerivados', 'buscarReportePrincipal'];
  public groupNameConsultingAprobacion: string[] = ['buscarReportesDerivados', 'buscarReportePrincipal'];
  public contratoServicios: any[] = [];
  public atributos: any[] = [];
  public reportesPrincipalesObj: any = {};
  public reportesDerivadosObj: any = {};
  public reportesPrincipales: any = {};
  public reportesDerivados: any = {};
  public entidadesDerivadas: any = {}; 
  public errores: any[] = [];
  public bodyModelos: any = {};
  public fichaGuardada: any = {}; // es para validar la existencia de una ficha guardada y habilitar el boton continuar
  public fichaComiteCredito: any = {};
  public cabeceraDeudores: any = {};
  public totalesInformacionFinanciera: any = {};
  public informacionFinancieraCabecera: any = {};
  public informacionFinancieraTable: any[] = [];
  public deudores: any[] = [];
  public sociosComposicion: any[] = [];
  public sociosInformes: any[] = [];
  public empresasRelacionadasComposicion: any[] = [];
  public empresasRelacionadasInformes: any[] = [];
  public showReportes: boolean = false;
  public showArchivos: boolean = false;
  public consolidadoTitular: any = {};
  public plantillaAntecedentes = 'cesceDemo';
  public modalDefault = true; 
  public observaciones: any = {
    text: ''
  };
  public servicioFichaFormSaved: any = {};
  public servicioDeudoresFormSaved: any = {};
  public cargaFinanciera: CargaFinanciera[] = [];
  public nombreClienteCargaFinanciera: string = '';

  public reportesSeleccionado: any = {};
  public rutSeleccionado: string = '';
  public informeComercial: string = '';
  public totalesCargaFinanciera: any = {
    creditoOriginal: 0,
    deudaActual: 0,
    cuota: 0,
    nCuotasPorPagar: 0,
    nCuotasMorosas: 0,
  }
  public factoring: any[] = [];
  public lineaOperacionPropuesta: any = {};
  public lineaOperacionPropuestaTotales: any = {};
  public motorCalculos: any;
  public motorConDatos: boolean = false;
  public balanceManual: any[] = [];
  public dataManualCargada: boolean = false;
  public tablasCompraVenta: any = {}; 

  public listaReportesPrincipales: any[] = [];
  public listaReportesDerivados: any = {};

  public formatos: any = {
    // deudoress
    porcentaje: 'decimal',
    facturaFinanciar: 'decimal',
    cheques: 'decimal',
    histCancelado: 'decimal',
    diasPromedio: 'decimal',
    fs: 'si-no',
    web: 'text',
    // carga financiera
    fechaCurse: 'text',
    institucion: 'text',
    tipoCredito: 'text',
    ultimoVencimiento: 'text',
    garantiasVigentes: 'text',
    creditoOriginal: 'decimal',
    deudaActual: 'decimal',
    cuota: 'decimal',
    nCuotasPorPagar: 'numero',
    nCuotasMorosas: 'numero',
  }

  public formatosLinea: any = {
    // linea / operacion propuesta
    lineaVigentefacturas: 'decimal',
    lineaPropuestafacturas: 'decimal',
    opPuntualVigentefacturas: 'decimal',
    opPuntualPropuestafacturas: 'decimal',

    lineaVigentecheques: 'decimal',
    lineaPropuestacheques: 'decimal',
    opPuntualVigentecheques: 'decimal',
    opPuntualPropuestacheques: 'decimal',

    lineaVigenteconfirming: 'decimal',
    lineaPropuestaconfirming: 'decimal',
    opPuntualVigenteconfirming: 'decimal',
    opPuntualPropuestaconfirming: 'decimal',

    lineaVigentecontratoServiu: 'decimal',
    lineaPropuestacontratoServiu: 'decimal',
    opPuntualVigentecontratoServiu: 'decimal',
    opPuntualPropuestacontratoServiu: 'decimal',

    lineaVigentecreditoDirecto: 'decimal',
    lineaPropuestacreditoDirecto: 'decimal',
    opPuntualVigentecreditoDirecto: 'decimal',
    opPuntualPropuestacreditoDirecto: 'decimal',

    vigentefacturas: 'decimal',
    propuestafacturas: 'decimal',
    canceladafacturas: 'decimal',

    vigentecheques: 'decimal',
    propuestacheques: 'decimal',
    canceladacheques: 'decimal',

    vigenteconfirming: 'decimal',
    propuestaconfirming: 'decimal',
    canceladaconfirming: 'decimal',

    vigentecontratoServiu: 'decimal',
    propuestacontratoServiu: 'decimal',
    canceladacontratoServiu: 'decimal',

    vigentecreditoDirecto: 'decimal',
    propuestacreditoDirecto: 'decimal',
    canceladacreditoDirecto: 'decimal',

    porcentajefacturas: 'decimal',
    porcentajecheques: 'decimal',
    porcentajeconfirming: 'decimal',
    porcentajecontratoServiu: 'decimal',
    porcentajecreditoDirecto: 'decimal',

    plazoDiasfacturas: 'numero',
    plazoDiascheques: 'numero',
    plazoDiasconfirming: 'numero',
    plazoDiascontratoServiu: 'numero',
    plazoDiascreditoDirecto: 'numero',

    vencimiento: 'text',
    opPuntualM: 'decimal',
    riesgoTotalM: 'decimal',
    totalLineaM: 'decimal',
    presentacionCliente: 'text'
  }

  constructor(
    public alertService: AlertService,
    private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalBasicoConsolidadoService,
    private _sessionService: SessionService,
    private utilsService: UtilsService,
  ) { }

  ngOnInit(): void {
    this.operacionNuevo.emit(false);
    this.setToday();
    this.initForm();
    if(this.plantillaNoEditable === false){
      this.obtenerFlujo();
    } else {
      this.mapeoNoEditable();
      this.showArchivos = true;
    }
    this.onChanges();
  }

  redireccionPasoAnterior(ultimoPasoCompletado: number): void {
    let step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado + 1));
    if (!step[0]?.step){
      step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado));
    }
    this.alertService.error(`Solicitud de Crédito - Debe completar el paso N° ${Number(step[0]?.step)} para poder continuar`);
    this.solicitudCreditoService.setStep(Number(step[0]?.step));
  }

  mapeoNoEditable(): void {
    this.callServicesFichaAprobacion();    
  }

  // Logica de revision de paso y llamada a servicios

  obtenerFlujo(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      let paso: any;
      let pasoAnterior: any;
      const pasosCompleted = resp.stages.filter((e: any) => e.completed);
      const cantidad = pasosCompleted.length;
      const ultimoPasoCompletado = Number(pasosCompleted[cantidad - 1].stageID);
      this.closed = (resp.closed === null || resp.closed === undefined) ? true : resp.closed;
      this.showArchivos = true;

      if(this.stepper === 5){
        resp.stages.forEach((e: any) => {
          if(e.stageID === '10'){
            paso = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(paso?.stageID === '10' && (paso?.completed === false && paso?.optional === false)){ //pasoAnterior && !pasoAnterior?.completed
          let pasoAnteriorCompleted = ultimoPasoCompletado;
          if(ultimoPasoCompletado === 9)
            pasoAnteriorCompleted = 8;

          this.redireccionPasoAnterior(pasoAnteriorCompleted);
        } else {
          if (paso?.stageID === '10' && paso?.completed === true) { // Se realizo el calculo del score
            this.callServicesFichaScore();
          } else {
            this.redireccionPasoAnterior(ultimoPasoCompletado);
          }
        }
      } else {
        resp.stages.forEach((e: any) => {
          if(e.stageID === '9'){
            paso = e;
          } else if (e.stageID === '8'){
            pasoAnterior = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(pasoAnterior && (!pasoAnterior?.completed && pasoAnterior?.optional === false)){
          this.redireccionPasoAnterior(ultimoPasoCompletado);
        } else {
          if (paso?.stageID === '9' && paso?.completed === false) { // primera vez en esta pantalla -> cambiar para pruebas
            this.callServices();
          }
          else if(paso?.stageID === '9' && paso?.completed === true){ // ya se habia llegado antes a esta pantalla -> cambiar para pruebas
            this.callServicesFicha();
          }
        }
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));

  }

  // Llamada a servicios para obtener ficha con score guardada

  async callServicesFichaAprobacion(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsultingAprobacion.length > 0) {
      for await (const value of this.groupNameConsultingAprobacion) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFichaAprobacion");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  // Llamada a servicios para obtener ficha con score guardada

  async callServicesFichaScore(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsultingScore.length > 0) {
      for await (const value of this.groupNameConsultingScore) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFichaScore");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  // Llamada a servicios para obtener ficha guardada

  async callServicesFicha(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const value of this.groupNameConsultingFicha) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFicha");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  obtenerBalanceAutomatico(): void {
    const datos = this.obtenerDatosF22();

    if(datos.length > 0){
      this.data = this.utilsService.calculosVariacion(datos);
      let periodos = this.utilsService.obtenerPeriodos(this.data);
      periodos = periodos.slice(0, 2);
      this.dataFiltrada = this.utilsService.obtenerReporteFiltrado(this.data, periodos);
      this.dataCargada = true;
    } else {
      this.dataCargada = false;
    }
  }

  // FIN Balances Automaticos

  // Mapeo de error en consultas asincronas

  setError(reporte: string, error: string): void {
    this.errores.push({
      id: reporte,
      msg: error
    })
  }

  // Mapeo de respuestas correctas en consultas asincronas

  setResponse(service: string, response: any): void {
    if (service === 'buscarReportePrincipal'){
      this.contratoServicios = response?.Stage3Response?.ContratoServicios || [];
      this.atributos = response?.Stage2Response?.Atributos || [];
      this.listaReportesPrincipales = response?.ReportKeys || [];
    }
    else if (service === 'buscarReportesDerivados'){

      this.listaReportesDerivados = response?.ReportKeys || {};
      this.entidadesDerivadas = response?.Stage5Response?.Entidades;

    } else if (service === 'buscarFichaDeCredito'){
      this.fichaGuardada = response?.FichaDeCredito;
      const ficha = response?.FichaDeCredito?.ficha;
      this.servicioFichaFormSaved = ficha?.servicioFichaForm;
      this.servicioDeudoresFormSaved = ficha?.servicioDeudoresForm;
      this.sociosComposicion = ficha?.sociosComposicion || [];
      this.empresasRelacionadasComposicion = ficha?.empresasRelacionadasComposicion || [];
      this.sociosInformes = ficha?.sociosInformes || [];
      this.empresasRelacionadasInformes = ficha?.empresasRelacionadasInformes || [];
      this.deudores = ficha?.deudores || [];
      this.informacionFinancieraCabecera = ficha?.informacionFinancieraCabecera || {};
      this.totalesInformacionFinanciera = ficha?.totalesInformacionFinanciera || {};
      this.informacionFinancieraTable = ficha?.informacionFinancieraTable || [];
      this.dataFiltrada = ficha?.dataFiltrada || {};
      this.rut = ficha?.rut || '';
      this.consolidadoTitular = ficha?.consolidadoTitular || {};
      this.observaciones = ficha?.observaciones || {
        text: ''
      };
      this.totalesCargaFinanciera = ficha?.totalesCargaFinanciera || {
        creditoOriginal: 0,
        deudaActual: 0,
        cuota: 0,
        nCuotasPorPagar: 0,
        nCuotasMorosas: 0,
      };
      this.cargaFinanciera = ficha?.cargaFinanciera || [];
      this.nombreClienteCargaFinanciera = ficha?.nombreClienteCargaFinanciera || '';
      this.factoring = ficha?.factoring || [];
      this.lineaOperacionPropuesta = ficha?.lineaOperacionPropuesta || {};
      this.lineaOperacionPropuestaTotales = ficha?.lineaOperacionPropuestaTotales || {};

      this.balanceManual = ficha?.balanceManual || [];
      (this.balanceManual.length > 0) ? this.dataManualCargada = true : this.dataManualCargada = false;

    } else if (service === 'buscarCalculoScore'){
      this.fichaGuardada = response?.FichaDeCredito;
      const ficha = response?.FichaDeCredito?.ficha;
      this.servicioFichaFormSaved = ficha?.servicioFichaForm;
      this.servicioDeudoresFormSaved = ficha?.servicioDeudoresForm;
      this.sociosComposicion = ficha?.sociosComposicion || [];
      this.empresasRelacionadasComposicion = ficha?.empresasRelacionadasComposicion || [];
      this.sociosInformes = ficha?.sociosInformes || [];
      this.empresasRelacionadasInformes = ficha?.empresasRelacionadasInformes || [];
      this.deudores = ficha?.deudores || [];
      this.informacionFinancieraCabecera = ficha?.informacionFinancieraCabecera || {};
      this.totalesInformacionFinanciera = ficha?.totalesInformacionFinanciera || {};
      this.informacionFinancieraTable = ficha?.informacionFinancieraTable || [];
      this.dataFiltrada = ficha?.dataFiltrada || {};
      this.rut = ficha?.rut || '';
      this.consolidadoTitular = ficha?.consolidadoTitular || {};
      this.observaciones = ficha?.observaciones || {
        text: ''
      };
      this.totalesCargaFinanciera = ficha?.totalesCargaFinanciera || {
        creditoOriginal: 0,
        deudaActual: 0,
        cuota: 0,
        nCuotasPorPagar: 0,
        nCuotasMorosas: 0,
      };
      this.cargaFinanciera = ficha?.cargaFinanciera || [];
      this.nombreClienteCargaFinanciera = ficha?.nombreClienteCargaFinanciera || '';
      this.factoring = ficha?.factoring || [];
      this.lineaOperacionPropuesta = ficha?.lineaOperacionPropuesta || {};
      this.lineaOperacionPropuestaTotales = ficha?.lineaOperacionPropuestaTotales || {};
      this.balanceManual = ficha?.balanceManual || [];

      this.motorCalculos = response?.MotorDeCalculos;

      if(this.motorCalculos?.ResponseReglas?.message){
        this.motorConDatos = false;
      } else {
        this.motorConDatos = true;
      }

      (this.balanceManual.length > 0) ? this.dataManualCargada = true : this.dataManualCargada = false;
      (ficha && Object.keys(ficha).length > 0) ? this.showReportes = true : this.showReportes = false;
      (this.dataFiltrada && Object.keys(this.dataFiltrada).length > 0) ? this.dataCargada = true : this.dataCargada = false;
    }
  }

  //Definicion llamada a servicios asincronos por primera vez en la vista

  getServices(service: string): any {
    const objeto: any = {
      'buscarReportePrincipal': () => {
        return this.solicitudCreditoService.buscarReportePrincipal(this.idTransaccion, this.rut)
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes principales'), of(null))));
      },
      'buscarReportesDerivados': () => {
        return this.solicitudCreditoService.buscarReportesDerivados(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes derivados'), of(null))));
      },
      'buscarFichaDeCredito': () => {
        return this.solicitudCreditoService.buscarFichaDeCredito(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha'), of(null))));
      },
      'buscarCalculoScore': () => {
        return this.solicitudCreditoService.buscarCalculoScore(this.rut, this.idTransaccion)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha con Score'), of(null))));
      }
    };
    return objeto[service]();
  }

  // Llamada a servicios asincronos por primera vez en la vista

  async callServices(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const value of this.groupNameConsulting) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServices");
      }
    },
      (error) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    ));
  }

  buscarReportes(etapa: string): void {
    const apiServicesReportes: any = this.utilsService.obtenerServicesReportes(this.listaReportesPrincipales, this.listaReportesDerivados) || [];
    let count = 0;

    this.spinner.show();
    from(apiServicesReportes).pipe( 
      mergeMap(
        (service: any) => 
          this.solicitudCreditoService.buscarReportes(
            this.idTransaccion, 
            this.rut, 
            service.principal, 
            service.reportKey, 
            service.entidad, 
            service.rutDerivado
          )
          .pipe(
            catchError(error => {
              //console.error(`Error en la consulta del servicio: ${service.principal}, ${service.reportKey}, ${service.entidad}, ${service.rutDerivado}`);
              return of(null); // Retorna un observable que emite un valor nulo
            }),
            map(response => {
              if (response !== null) {
                count++;
              }
              return {
                service: service,
                response: response
              };
            }),
          ),
          20
        ), 
        filter(response => response !== null),
      toArray()
    ).subscribe(responses => {
      if (count === 0) { // Si no hubo respuestas válidas
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      } else if (count < apiServicesReportes.length) { // Si hubo respuestas válidas pero no para todos los servicios
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      }
      if(count > 0){
        this.reportesPrincipalesObj = this.utilsService.mapeoReportesPrincipales(responses);
        this.reportesDerivadosObj = this.utilsService.mapeoReportesDerivados(responses);
        if (etapa === 'callServices'){
          this.obtenerBalanceAutomatico();
          this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
          this.mapeoReportes();
          this.initForm();
          this.spinner.hide();
        } else if (etapa === 'callServicesFicha'){
          this.initForm();
          this.showReportes = true;
          this.dataFiltrada && Object.keys(this.dataFiltrada).length > 0 ? this.dataCargada = true : this.dataCargada = false;
          this.spinner.hide();
        } else if (etapa === 'callServicesFichaAprobacion'){
          this.initForm();
          this.mapeoFichaGuardada();
          this.spinner.hide();
        } else {
          this.initForm();
          this.spinner.hide();
        }
      }
    }, error => {
      if (count > 0) {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      } else {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      }
    });
  }

  mapeoFichaGuardada(): void {
    this.fichaGuardada = this.objEntradaFicha;
    const ficha = this.objEntradaFicha?.ficha;
    this.servicioFichaFormSaved = ficha?.servicioFichaForm;
    this.servicioDeudoresFormSaved = ficha?.servicioDeudoresForm;
    this.sociosComposicion = ficha?.sociosComposicion || [];
    this.empresasRelacionadasComposicion = ficha?.empresasRelacionadasComposicion || [];
    this.sociosInformes = ficha?.sociosInformes || [];
    this.empresasRelacionadasInformes = ficha?.empresasRelacionadasInformes || [];
    this.deudores = ficha?.deudores || [];
    this.informacionFinancieraCabecera = ficha?.informacionFinancieraCabecera || {};
    this.totalesInformacionFinanciera = ficha?.totalesInformacionFinanciera || {};
    this.informacionFinancieraTable = ficha?.informacionFinancieraTable || [];
    this.dataFiltrada = ficha?.dataFiltrada || {};
    this.rut = ficha?.rut || '';
    this.observaciones = ficha?.observaciones || {
      text: ''
    };
    this.totalesCargaFinanciera = ficha?.totalesCargaFinanciera || {
      creditoOriginal: 0,
      deudaActual: 0,
      cuota: 0,
      nCuotasPorPagar: 0,
      nCuotasMorosas: 0,
    };
    this.cargaFinanciera = ficha?.cargaFinanciera || [];
    this.tablasCompraVenta = ficha?.tablasCompraVenta || {};
    this.nombreClienteCargaFinanciera = ficha?.nombreClienteCargaFinanciera || '';
    this.factoring = ficha?.factoring || [];
    this.lineaOperacionPropuesta = ficha?.lineaOperacionPropuesta || {};
    this.lineaOperacionPropuestaTotales = ficha?.lineaOperacionPropuestaTotales || {};
    this.balanceManual = ficha?.balanceManual || [];
    this.consolidadoTitular = ficha?.consolidadoTitular || {};

    this.motorCalculos = this.objEntradaMotor;
    if(this.motorCalculos?.ResponseReglas?.message){
      this.motorConDatos = false;
    } else {
      this.motorConDatos = true;
    }

    (this.balanceManual.length > 0) ? this.dataManualCargada = true : this.dataManualCargada = false;
    (ficha && Object.keys(ficha).length > 0) ? this.showReportes = true : this.showReportes = false;
    (this.dataFiltrada && Object.keys(this.dataFiltrada).length > 0) ? this.dataCargada = true : this.dataCargada = false;
    this.initForm();
  }

  // Objeto de cabecera de la ficha y deudores

  crearFichaComiteCredito(today: string, fechaInicio: string): void {
    const atributos = this.atributos || [];
    let [ejecutivo, canal, sucursal] = '';
    atributos.forEach((element: any) => {
      if(element?.nameAttribute.toUpperCase() === 'SUCURSAL CONTRATANTE' && !isNaN(Number(element?.value))){
        sucursal = element?.allowedValues[Number(element?.value)] || '';
      }
      if(element?.nameAttribute.toUpperCase() === 'EJECUTIVO ATENCION' ){
        ejecutivo = element?.allowedValues[Number(element?.value)] || '';
      }
      if(element?.nameAttribute.toUpperCase() === 'CANAL ENTRADA' ){
        canal = element?.allowedValues[Number(element?.value)] || '';
      }
    });
    this.fichaComiteCredito = {
      nombre: this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.NombreORazonSocial || '',
      fecha: today, // fecha de hoy
      giro: this.reportesPrincipalesObj?.basicReport?.reporte?.Riesgo?.ActividadEconomica || '',
      ejecutivo: ejecutivo, // Stage 2
      rut: this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.Rut || '',
      canal: canal, // Stage 2
      sucursal: sucursal, // Stage 2
      pagina: '', // dato manual
      enrolado: this.utilsService.obtenerEnrolado(this.contratoServicios), // libro compra venta
      fechaInicio: this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades? fechaInicio : ''
    }

    this.cabeceraDeudores = {
      nombre: this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.NombreORazonSocial || '',
      rut: this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.Rut || '',
      fecha: today,
    }
  }

  // Funcion para generar datos de tablas de composicion de la sociedad e informes comerciales

  crearSociosComposicionInforme(entidad: string, isEntidadSocio: boolean): void { 
    const indexEntidadSocios = this.entidadesDerivadas?.findIndex((elemento: any) => elemento.entidad === entidad);
    let socios: any[] = [];
    if(indexEntidadSocios >= 0){
      socios = this.entidadesDerivadas[indexEntidadSocios].items;
    }

    if(this.reportesDerivadosObj[entidad]){
      for (const [key, value] of Object.entries(this.reportesDerivadosObj[entidad])) {
        const objeto: any = value;
        let nombreSocio = ''; 
        const indexSocio = socios.findIndex((elemento: any) => elemento.rut === key);
        if(indexSocio >= 0){
          const indexAtributo = socios[indexSocio].atributos.findIndex((elemento: any) => elemento.name.toUpperCase() === 'NOMBRE');
          if(indexAtributo >= 0){
            nombreSocio = socios[indexSocio].atributos[indexAtributo].value;
          }
        }
        const objetoComposicion = {
          socio: nombreSocio || '', // regla
          rut: key || '', // regla
          porcentaje: '', // ingreso amnual
          fs: '', // ingreso amnual
        }
        let motivo = '';
        if(objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.ProtestosMonto > 0){
          motivo += 'Protesto';
        }
        if(objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.MorosidadesMonto > 0){
          if(motivo.length > 0)
            motivo += ', '; 
          motivo += 'Morosidad';
        }
        if(objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesMonto > 0){
          if(motivo.length > 0)
            motivo += ', ';
          motivo += 'Infraciones Laborales';
        }
        const numero = objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad;
        const monto = objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto;
        const objetoInformes = {
          numero: numero || numero == 0 ? numero : '',
          monto: monto || monto == 0 ? monto : '',
          motivo: motivo || '',
          entidad: entidad // cambiar si corresponse
        }
        if(isEntidadSocio){ // Si isEntidadSocio es true corresponde a la tabla de socios sino de empresas relacionadas
          this.sociosComposicion.push(objetoComposicion)
          this.sociosInformes.push(objetoInformes)
        } else {
          this.empresasRelacionadasComposicion.push(objetoComposicion)
          this.empresasRelacionadasInformes.push(objetoInformes)
        }
      }

    }
    
  }

  // Crea el array de deudores para la tabla Deudores

  crearDeudores(entidad: string): void {
    const indexEntidad = this.entidadesDerivadas?.findIndex((elemento: any) => elemento.entidad === entidad);
    let deudores: any[] = [];
    if(indexEntidad >= 0){
      deudores = this.entidadesDerivadas[indexEntidad].items;
    }

    const deudoresObj = this.reportesDerivadosObj[entidad];
    if(deudoresObj){
      for (const [key, value] of Object.entries(deudoresObj)) {
        const rut = key;
  
        let nombreSocio = '';
        let montoTotal = 0;
        const indexSocio = deudores.findIndex((elemento: any) => elemento.rut === rut);
        if(indexSocio >= 0){
          const indexAtributoNombre = deudores[indexSocio].atributos.findIndex((elemento: any) => elemento.name.toUpperCase() === 'NOMBRE');
          if(indexAtributoNombre >= 0){
            nombreSocio = deudores[indexSocio].atributos[indexAtributoNombre].value;
          }
          const indexAtributoMonto = deudores[indexSocio].atributos.findIndex((elemento: any) => elemento.name.toUpperCase() === 'MONTOTOTAL');
          if(indexAtributoMonto >= 0){
            montoTotal = Number(deudores[indexSocio].atributos[indexAtributoMonto].value);
          }
        }
  
  
        const objeto: any = value;
        const elementoSbif: any[] = objeto?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item || [];
        const ultimoElementoSbif = elementoSbif[elementoSbif.length - 1];
        const objetoDeudores = {
          nombre: nombreSocio || '', // libro compra venta
          rut: rut || '', // libro compra venta
          facturas: montoTotal || '', // libro compra venta -> ordenar por este valor de mayor a menor
          facturaFinanciar: 0, // manual
          cheques: 0, // manual
          numero: objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad || '',
          monto: objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto || '',
          miles: Number(ultimoElementoSbif?.dirvigente) + Number(ultimoElementoSbif?.dirvencida) + Number(ultimoElementoSbif?.dirven180mayor3year) + Number(ultimoElementoSbif?.dirmorosa) || 0, // deuda sbif
          rangoSii: objeto?.basicReport?.reporte?.AntecedentesFinancieros?.TamanoEmpresaRango || '',
          web: '0', // manual
          histCancelado: 0, // manual / base capex
          diasPromedio: 0,  // manual / base capex
          entidad: entidad,
        }
        this.deudores.push(objetoDeudores)
  
      }
      
      this.deudores.sort((a, b) => parseFloat(b.facturas) - parseFloat(a.facturas)); // ordena de mayor a menor
    }

  }

  // Crea los objetos para la seccion de Linea / Operacion Propuesta

  crearLineaOperacionPropuesta(): void{
    this.lineaOperacionPropuesta = {
      producto:[{
          id: 'facturas',
          texto: 'Facturas'
        },
        {
          id: 'cheques',
          texto: 'Cheques'
        },
        {
          id: 'confirming',
          texto: 'Confirming'
        },
        {
          id: 'contratoServiu',
          texto: 'Contrato Serviu'
        },
        {
          id: 'creditoDirecto',
          texto: 'Credito Directo'
        },
      ],
      lineaVigente: {
        facturas: 0,
        cheques: 0,
        confirming: 0,
        contratoServiu: 0,
        creditoDirecto: 0,
      },
      lineaPropuesta: {
        facturas: 0,
        cheques: 0,
        confirming: 0,
        contratoServiu: 0,
        creditoDirecto: 0,
      },
      opPuntualVigente: {
        facturas: 0,
        cheques: 0,
        confirming: 0,
        contratoServiu: 0,
        creditoDirecto: 0,
      },
      opPuntualPropuesta: {
        facturas: 0,
        cheques: 0,
        confirming: 0,
        contratoServiu: 0,
        creditoDirecto: 0,
      },
      porcentaje: {
        facturas: 0,
        cheques: 0,
        confirming: 0,
        contratoServiu: 0,
        creditoDirecto: 0,
      },
      plazoDias: {
        facturas: 0,
        cheques: 0,
        confirming: 0,
        contratoServiu: 0,
        creditoDirecto: 0,
      },
      vigente: {
        facturas: 0,
        cheques: 0,
        confirming: 0,
        contratoServiu: 0,
        creditoDirecto: 0,
      },
      propuesta: {
        facturas: 0,
        cheques: 0,
        confirming: 0,
        contratoServiu: 0,
        creditoDirecto: 0,
      },
      cancelada: {
        facturas: 0,
        cheques: 0,
        confirming: 0,
        contratoServiu: 0,
        creditoDirecto: 0,
      },
      vencimiento: '',
      opPuntualM: '',
      riesgoTotalM: '',
      totalLineaM: '',
      presentacionCliente: ''
    };

    this.lineaOperacionPropuestaTotales = {
      totalesLineaVigente: '',
      totalesLineaPropuesta: '',
      totalesPpPuntualVigente: '',
      totalesOpPuntualPropuesta: '',
      totalesPorcentaje: '',
      totalesPlazoDias: '',
      totalesVigente: '',
      totalesPropuesta: '',
      totalesCancelada: '',
    }
  }

  // Se obtienen los f22 mas actualizado de entre los reportes disponibles

  obtenerDatosF22(): any[] {
    const carpetaF22 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f22 || [];
    const historicoF22 = this.reportesPrincipalesObj?.f22?.reporte?.ListadoF22Reportes || [];
    const calculadoF22 = this.reportesPrincipalesObj?.f22BalancesRentas?.reporte?.Formulario22Reporte || {};

    const F22: any[] = [];
    carpetaF22.forEach((element: any) => {
      if(element) {
        element.FechaReporte = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(element);
      }
    });
    historicoF22.forEach((element: any) => {
      if(element) {
        const reporte: any = element?.Formulario22Reporte?.Reporte;
        reporte.FechaReporte = element?.Formulario22Reporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(reporte);
      }
    });
    if(calculadoF22 && Object.keys(calculadoF22).length > 0){
      const elemento: any = calculadoF22?.Reporte;
      if(elemento){
        elemento.FechaReporte = calculadoF22?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(elemento);
      }
    }

    const ordenadoF22 = F22.sort((a: any,b: any) => Number(b?.calculado?.periodo) - Number(a?.calculado?.periodo)); // mayor a menor en año
    const F22Limpio: any[] = [];

    ordenadoF22.forEach((element: any) => {
      if((element?.tipo !== 'manual' || !element?.tipo) && element?.calculado?.periodo){
        const indexRepetido = F22Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo));
        if(indexRepetido >= 0){
          if(F22Limpio[indexRepetido]?.FechaReporte < element.FechaReporte){
            F22Limpio[indexRepetido] = element;
          }
        } else {
          F22Limpio.push(element);
        }
      }
    });
    return F22Limpio;
  }

  // Mapeo y generacion de objetos para tablas

  mapeoReportes(): void {
    const date = new Date();

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    let today = '';
    
    if(month < 10){
      today = day < 10 ? `0${day}-0${month}-${year}` : `${day}-0${month}-${year}`;
    }else{
      today = day < 10 ? `0${day}-${month}-${year}` : `${day}-${month}-${year}`;
    }
    
    const dateInicio = new Date(this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades);
    const fechaInicio = `${dateInicio.getDate() < 10? '0'+(dateInicio.getDate()) : dateInicio.getDate()}-${(dateInicio.getMonth() + 1 < 10?  '0'+(dateInicio.getMonth() + 1) : 
    dateInicio.getMonth() + 1)}-${dateInicio.getFullYear()}`;

    /* INICIO Logica de obtencion de datos de F22 y F29 */

    const F22Limpio = this.obtenerDatosF22(); // Funcion donde hace la logica

    const carpetaF29 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f29 || [];
    const historicoF29 = this.reportesPrincipalesObj?.f29?.reporte?.ListadoF29Reportes || [];
    const calculadoF29 = this.reportesPrincipalesObj?.f29Ventas?.reporte?.Formulario29Reporte?.Reporte || {};
    
    const F29: any[] = [];
    carpetaF29.forEach((element: any) => {
      if(!element?.message){
        F29.push(element);
      }
    });
    historicoF29.forEach((element: any) => {
      if(!element?.Formulario29Reporte?.Reporte.message){
        F29.push(element?.Formulario29Reporte?.Reporte);
      }
    });
    if(calculadoF29 && Object.keys(calculadoF29).length > 0 && !calculadoF29?.message){
      F29.push(calculadoF29);
    }

    const ordenadoF29 = F29.sort((a: any,b: any) => { // mayor a menor en año y menor a mayor en mes
      if (!a?.calculado) return 1;
      if (!b?.calculado) return -1;
      const periodoDiff = Number(b.calculado.periodo) - Number(a.calculado.periodo);
      if (periodoDiff !== 0) return periodoDiff;
      return Number(a.calculado.mes) - Number(b.calculado.mes);
    });
    const F29Limpio: any[] = [];

    ordenadoF29.forEach((element: any) => {
      const indexRepetido = F29Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo) && Number(e?.calculado?.mes) === Number(element?.calculado?.mes));
      if(indexRepetido >= 0){
        if(F29Limpio[indexRepetido]?.fecha_ingreso < element.fecha_ingreso){
          F29Limpio[indexRepetido] = element;
        }
      } else {
        F29Limpio.push(element);
      }
    })

    /* FIN Logica de obtencion de datos de F22 y F29 */

    let anioActual = new Date().getFullYear();
    let anioAnterior = 0;
    
    if(F22Limpio.length > 1){
      anioActual = Number(F22Limpio[0]?.calculado?.periodo);
      anioAnterior = Number(F22Limpio[1]?.calculado?.periodo);
    } else {
      if (F22Limpio.length > 0){
        anioActual = Number(F22Limpio[0]?.calculado?.periodo)
      }
      else {
        anioActual = new Date().getFullYear();
      }
      anioAnterior = anioActual - 1;
    }
    
    const anioActualVentas = new Date().getFullYear();
    const anioAnteriorVentas = Number(anioActualVentas - 1);


    this.crearFichaComiteCredito(today, fechaInicio); // crear cabecera ficha y de deudores
    const { totalesInformacionFinanciera, informacionFinancieraTable } = this.utilsService.crearInformacionFinancieraTable(anioActualVentas, anioAnteriorVentas, F29Limpio);
    this.totalesInformacionFinanciera = totalesInformacionFinanciera;
    this.informacionFinancieraTable = informacionFinancieraTable;
    
    const f22ActualIndex = F22Limpio?.findIndex((elemento: any) => Number(elemento?.calculado?.periodo) === anioActual);
    let f22Actual: any = null;

    if(f22ActualIndex >= 0){
      f22Actual = F22Limpio[f22ActualIndex] || null;
    }

    this.informacionFinancieraCabecera = this.utilsService.crearInformacionFinancieraCabecera(anioActual, anioAnterior, anioActualVentas, anioAnteriorVentas, f22Actual, this.totalesInformacionFinanciera, this.reportesPrincipalesObj?.basicReport); // crear la cabecera de la tabla financiera
    this.crearSociosComposicionInforme('socios', true); // crea las tablas composicion de sociedad para socios e informes comerciales para socios
    this.crearSociosComposicionInforme('empresasRelacionadas', false); // crea las tablas composicion de sociedad para empresa e informes comerciales para empresa -> se debe cambiar socios por el que corresponda
    this.crearDeudores('deudores');
    const { cargaFinanciera, nombreClienteCargaFinanciera } = this.utilsService.crearCargaFinanciera(this.reportesPrincipalesObj?.basicReport);
    this.cargaFinanciera = cargaFinanciera;
    this.nombreClienteCargaFinanciera = nombreClienteCargaFinanciera;
    this.factoring = this.utilsService.crearFactoring(this.reportesPrincipalesObj?.cesionFacturas);
    this.consolidadoTitular = this.utilsService.crearConsolidadoTitular(F22Limpio, F29Limpio, this.reportesPrincipalesObj);
    this.crearLineaOperacionPropuesta();

    // Validar
    if(this.reportesPrincipalesObj?.balanceManual?.reporte?.ListadoF22Reportes){
      this.reportesPrincipalesObj?.balanceManual?.reporte?.ListadoF22Reportes.forEach((element: any) => {
        if(element?.Formulario22Reporte?.Reporte)
          this.balanceManual.push(element?.Formulario22Reporte?.Reporte);
      });
      this.dataManualCargada = true;
    }

    this.showReportes = true;
  }

  // Logicas de Formulario y funciones

  // Se inicializa el formulario de los datos de las cabeceras con datos a ingresar

  initForm(): void {
    if(this.stepper === 5 || this.plantillaNoEditable === true){
      this.servicioFichaForm = this.formBuilder.group({
        nombreComite: [{value: this.servicioFichaFormSaved?.nombreComite || this.fichaComiteCredito?.nombre || '', disabled: true}, [Validators.required]],
        fechaComite: [{value: this.servicioFichaFormSaved?.fechaComite || this.fichaComiteCredito?.fecha || '', disabled: true}, [Validators.required]],
        giroComite: [{value: this.servicioFichaFormSaved?.giroComite || this.fichaComiteCredito?.giro || '', disabled: true}, [Validators.required]],
        ejecutivoComite: [{value: this.servicioFichaFormSaved?.ejecutivoComite || this.fichaComiteCredito?.ejecutivo || '', disabled: true}, [Validators.required]],
        rutComite: [{value: this.servicioFichaFormSaved?.rutComite || this.fichaComiteCredito?.rut, disabled: true}, [Validators.required, gtpRutValidator()]],
        canalEntradaComite: [{value: this.servicioFichaFormSaved?.canalEntradaComite || this.fichaComiteCredito?.canal || '', disabled: true}, [Validators.required]],
        sucursalComite: [{value: this.servicioFichaFormSaved?.sucursalComite || this.fichaComiteCredito?.sucursal || '', disabled: true}, [Validators.required]],
        paginaWebComite: [{value: this.servicioFichaFormSaved?.paginaWebComite || this.fichaComiteCredito?.pagina || '', disabled: true}, [Validators.required]],
        enroladoComite: [{value: this.servicioFichaFormSaved?.enroladoComite || this.fichaComiteCredito?.enrolado || '', disabled: true}, [Validators.required]],
        inicioSiiComite: [{value: this.servicioFichaFormSaved?.inicioSiiComite || this.fichaComiteCredito?.fechaInicio || '', disabled: true}, [Validators.required]]
      });
      
      this.servicioDeudoresForm = this.formBuilder.group({
        clienteComite: [{value: this.servicioDeudoresFormSaved?.clienteComite || this.cabeceraDeudores?.nombre || '', disabled: true}, [Validators.required]],
        rutClienteComite: [{value: this.servicioDeudoresFormSaved?.rutClienteComite || this.cabeceraDeudores?.rut || '', disabled: true}, [Validators.required, gtpRutValidator()]],
        fechaClienteComite: [{value: this.servicioDeudoresFormSaved?.fechaClienteComite || this.cabeceraDeudores?.fecha || '', disabled: true}, [Validators.required]],
      });

    } else {
      this.servicioFichaForm = this.formBuilder.group({
        nombreComite: [{value: this.servicioFichaFormSaved?.nombreComite || this.fichaComiteCredito?.nombre || '', disabled: true}, [Validators.required]],
        fechaComite: [{value: this.servicioFichaFormSaved?.fechaComite || this.fichaComiteCredito?.fecha || '', disabled: true}, [Validators.required]],
        giroComite: [{value: this.servicioFichaFormSaved?.giroComite || this.fichaComiteCredito?.giro || '', disabled: true}, [Validators.required]],
        ejecutivoComite: [{value: this.servicioFichaFormSaved?.ejecutivoComite || this.fichaComiteCredito?.ejecutivo || '', disabled: true}, [Validators.required]],
        rutComite: [{value: this.servicioFichaFormSaved?.rutComite || this.fichaComiteCredito?.rut, disabled: true}, [Validators.required, gtpRutValidator()]],
        canalEntradaComite: [{value: this.servicioFichaFormSaved?.canalEntradaComite || this.fichaComiteCredito?.canal || '', disabled: true}, [Validators.required]],
        sucursalComite: [{value: this.servicioFichaFormSaved?.sucursalComite || this.fichaComiteCredito?.sucursal || '', disabled: true}, [Validators.required]],
        paginaWebComite: [ this.servicioFichaFormSaved?.paginaWebComite || this.fichaComiteCredito?.pagina || '', [Validators.required]],
        enroladoComite: [{value: this.servicioFichaFormSaved?.enroladoComite || this.fichaComiteCredito?.enrolado || '', disabled: true}, [Validators.required]],
        inicioSiiComite: [{value: this.servicioFichaFormSaved?.inicioSiiComite || this.fichaComiteCredito?.fechaInicio || '', disabled: true}, [Validators.required]]
      });
      
      this.servicioDeudoresForm = this.formBuilder.group({
        clienteComite: [{value: this.servicioDeudoresFormSaved?.clienteComite || this.cabeceraDeudores?.nombre || '', disabled: true}, [Validators.required]],
        rutClienteComite: [{value: this.servicioDeudoresFormSaved?.rutClienteComite || this.cabeceraDeudores?.rut || '', disabled: true}, [Validators.required, gtpRutValidator()]],
        fechaClienteComite: [{value: this.servicioDeudoresFormSaved?.fechaClienteComite || this.cabeceraDeudores?.fecha || '', disabled: true}, [Validators.required]],
      });
    }
  }

  onChanges(): void {
    this.servicioFichaForm.valueChanges.subscribe(val => {
      this.activateRutComiteHelper = rutValidate(val.rutComite) ? false : true;
    });
    this.servicioDeudoresForm.valueChanges.subscribe(val => {
      this.activateRutClienteComiteHelper = rutValidate(val.rutClienteComite) ? false : true;
    });
  }

  // Valida el tipo de dato si es correcto segun los formatos establecidos, si lo es devuelve el valor sino devuelve nulo

  validaFormatoElemento(value: any, key: string, elementoHTML: any, indexHTML: string): number | string | null {
    let formatos: any = {}
    if(indexHTML !== 'linea-'){
      formatos = this.formatos;
    } else {
      formatos = this.formatosLinea;
    }
    if(formatos[key]){
      if (formatos[key] === 'decimal') {
        if(typeof(value) === 'string'){
          if(!value.includes(',') && !value.includes('.')){ // valores enteros incluye el ,0 al final
            let element: any = parseFloat(value);
            element = element.toFixed(1); // lo deja como string para numeros como 2 -> '2.0' y no 2.0
            if(!(typeof(element) === 'number') && isNaN(element)){
              if(elementoHTML){
                elementoHTML.className = elementoHTML.className + " error-input";
                elementoHTML.title = 'Error de Formato - Debe ser un número entero o decimal';
              }
              return null;
            } else {
              element = element.toString().replace('.', ',');
              return element;
            }
          } else {
            if(!value.includes('.')){ // valores con formato x,xx
              let element: any = parseFloat(value.replace(',', '.'));              
              if(!(this.utilsService.isFloat(element))){
                if (typeof(element) === 'number'){
                  element = element.toFixed(1);
                  element = element.toString().replace('.', ',');
                  return element;
                } else {
                  if(elementoHTML){
                    elementoHTML.className = elementoHTML.className + " error-input";
                    elementoHTML.title = 'Error de Formato - Debe ser un número entero o decimal';
                  }
                  return null;
                }
              } else {
                element = element.toString().replace('.', ',');
                return element;
              }
            } else { // valores con formato x.xx
              let element: any = parseFloat(value);
              if(isNaN(element)){
                if(elementoHTML){
                  elementoHTML.className = elementoHTML.className + " error-input";
                  elementoHTML.title = 'Error de Formato - Debe ser un número entero o decimal';
                }
                return null;
              } else {
                element = value.toString().replace('.', ',');
                return element;
              }
            }
          }
        } 

        if(value === null) {
          if(elementoHTML){
            elementoHTML.className = elementoHTML.className + " error-input";
            elementoHTML.title = 'Error de Formato - Debe ser un número entero o decimal';
          }
        }
      }
      if(formatos[key] === 'si-no'){
        const element = String(value).toUpperCase().trim();
        if(!(element === 'SI' || element === 'NO')){
          if(elementoHTML){
            elementoHTML.className = elementoHTML.className + " error-input";
            elementoHTML.title = 'Error de Formato - Debe ser un valor Si o No';
          }
          return null;
        } else {
          return element;
        }
      }
      if (formatos[key] === 'numero') {
        if(value !== null){
          if(typeof(value) === 'string'){
            let element: any = parseInt(value);
            element = Math.round(element);
            if(!(typeof(element) === 'number') && isNaN(element)){
              if(elementoHTML){
                elementoHTML.className = elementoHTML.className + " error-input";
                elementoHTML.title = 'Error de Formato - Debe ser un número entero';
              }
              return null;
            } else {
              return element;
            }
          } else if (typeof(value) === 'number'){
            const element = Math.round(value);
            if(!(typeof(element) === 'number')){
              if(elementoHTML){
                elementoHTML.className = elementoHTML.className + " error-input";
                elementoHTML.title = 'Error de Formato - Debe ser un número entero';
              }
              return null;
            } else {
              return element;
            }
          } else {
            if(elementoHTML){
              elementoHTML.className = elementoHTML.className + " error-input";
              elementoHTML.title = 'Error de Formato - Debe ser un número entero';
            }
            return null;
          }
        } else {
          if(elementoHTML){
            elementoHTML.className = elementoHTML.className + " error-input";
            elementoHTML.title = 'Error de Formato - Debe ser un número entero';
          }
          return null;
        }
      }
      if(formatos[key] === 'text' && (value === '' || value === null)){
        if(elementoHTML){
          elementoHTML.className = elementoHTML.className + " error-input";
          elementoHTML.title = 'Error de Formato - No debe ser un valor vacio';
        }
        return null;
      }
    }
    return value;
  }

  // Valida objeto de entrada y si tiene un error en un campo lo marca en la vista

  validaObjeto(obj: any, idElement: string, index: number, htmlKey: string = ''): boolean {
    if (typeof(obj) === 'object' && !Array.isArray(obj)) {
      let hasData = false;
      let hasEmptyData = false;
      let hasFormatDataError = false;
      const arrayInputsVacios: string[] = [];
      for(const key in obj) {
        if (obj[key] !== null && typeof(obj[key]) === 'object' && !Array.isArray(obj[key])) {
          if(!this.validaObjeto(obj[key], idElement, index, key)){
            hasFormatDataError = true;
            hasData = true;
          }
        } else {
          const elementoHTML = document.getElementById(idElement + htmlKey + key + index);
          if(elementoHTML){
            elementoHTML.className = elementoHTML.className.replace(" error-input", "");
            elementoHTML.title = '';
          }
          if(String(obj[key]) === "" && elementoHTML !== null) { // Dato vacio
            hasEmptyData = true;
            if(elementoHTML){
              elementoHTML.className = elementoHTML.className + " error-input";
              elementoHTML.title = 'Error de Formato - No debe ser un valor vacio';
            }
          }
          if(String(obj[key]) !== "") { // Dato no vacio
            hasData = true;
            const formato = this.validaFormatoElemento(obj[key], htmlKey + key, elementoHTML, idElement) // Validacion de formato
            if(formato === null){ // Si hay un error de formato lo marca en la vista
              hasFormatDataError = true;
            } else { // Si no hay error se quita la posible marca en la vista
              obj[key] = formato;
              if(elementoHTML){
                elementoHTML.className = elementoHTML.className.replace(" error-input", "");
                elementoHTML.title = '';
              }
            }
          }
        }
      }
      if(hasEmptyData && hasData){ // Faltan datos por llenar
        arrayInputsVacios.forEach((key: string) => {
          const elementoHTML = document.getElementById(idElement + htmlKey + key + index);
          if(elementoHTML){
            elementoHTML.className = elementoHTML.className + " error-input";
            elementoHTML.title = 'Error de Formato - Completar dato';
          }
        });
        return false
      }
      if(hasData && hasFormatDataError){ // Tiene un error de formato del dato ingresado
        return false
      }
      return true;
    } else {
      return false;
    }
  }

  // Valida el objeto de la ficha dependiendo del tipo de dato

  validaObjetoFicha(obj: any, idElement: string): boolean {
    if (typeof(obj) === 'object' && Array.isArray(obj)){
      let valido = true;
      obj.forEach((element: any, index: number) => {
        if(!this.validaObjeto(element, idElement, index)){
          valido = false;
        }
      });
      return valido;
    } else if (typeof(obj) === 'object' && !Array.isArray(obj)) {
      return this.validaObjeto(obj, idElement, 0);
    } else {
      return false;
    }
  }

  // Funcion que valida el llenado de los datos y guarda los datos de la ficha

  guardarFicha(): void{

    if (!this.servicioFichaForm.valid) { // Valida que la cabecera inicial tenga todos los datos
      this.solicitudCreditoService.validateAllFormFields(this.servicioFichaForm);
      this.alertService.error('Solicitud de Crédito - Debe completar los datos necesarios para guardar correctamente.');
      return;
    }
    
    // Valida los datos de las tablas con datos de ingreso manual
    if(!this.validaObjetoFicha(this.sociosComposicion, 'socio-') || !this.validaObjetoFicha(this.empresasRelacionadasComposicion, 'empresa-')
      || !this.validaObjetoFicha(this.deudores, 'deudor-') || 
      !this.validaObjetoFicha(this.lineaOperacionPropuesta, 'linea-')
    ){
      this.alertService.error('Solicitud de Crédito - Debe completar correctamente los datos en las filas de las tablas con información ingresada.');
      return;
    }

    
    const elementoHTML = document.getElementById('carga-nombreClienteCargaFinanciera');
    if(elementoHTML){
      elementoHTML.className = elementoHTML.className.replace(" error-input", "");
      elementoHTML.title = '';
    }

    if(!this.validaObjetoFicha(this.cargaFinanciera, 'carga-')){
      this.alertService.error('Solicitud de Crédito - Debe completar correctamente los datos en las filas de las tablas con información ingresada.');
      return;
    } else if (this.validaObjetoFicha(this.cargaFinanciera, 'carga-') && this.nombreClienteCargaFinanciera === '') {
      this.alertService.error('Solicitud de Crédito - Debe completar correctamente el nombre del cliente en la tabla de Carga Financiera.');
      if(elementoHTML){
        elementoHTML.className = elementoHTML.className + " error-input";
        elementoHTML.title = 'Completar dato';
      }
      return;
    }

    if(this.rut === ''){
      this.alertService.error('Solicitud de Crédito - Ocurrio un error al recuperar el rut.');
      return;
    }

    const ficha: any = {
      ficha: {
        servicioFichaForm: this.servicioFichaForm.getRawValue(), // se valida con archivo service
        servicioDeudoresForm: this.servicioDeudoresForm.getRawValue(), // se valida con archivo service
        sociosComposicion: this.sociosComposicion,
        empresasRelacionadasComposicion: this.empresasRelacionadasComposicion,
        sociosInformes: this.sociosInformes, // no se debe validar porque no se modifica
        empresasRelacionadasInformes: this.empresasRelacionadasInformes, // no se debe validar porque no se modifica
        deudores: this.deudores, // validar -> agregar a validacion cuando sea validado
        informacionFinancieraCabecera: this.informacionFinancieraCabecera, // no se debe validar porque no se modifica
        totalesInformacionFinanciera: this.totalesInformacionFinanciera, // no se debe validar porque no se modifica
        informacionFinancieraTable: this.informacionFinancieraTable, // no se debe validar porque no se modifica
        dataFiltrada: this.dataFiltrada, // no se debe validar porque no se modifica
        observaciones: this.observaciones,
        totalesCargaFinanciera: this.totalesCargaFinanciera,
        cargaFinanciera: this.cargaFinanciera,
        nombreClienteCargaFinanciera: this.nombreClienteCargaFinanciera,
        lineaOperacionPropuesta: this.lineaOperacionPropuesta,
        lineaOperacionPropuestaTotales: this.lineaOperacionPropuestaTotales,
        factoring: this.factoring,
        balanceManual: this.balanceManual,
        rut: this.rut,
        consolidadoTitular: this.consolidadoTitular,
      }
    }

    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.guardarFichaDeCredito(this.rut, this.idTransaccion, ficha).subscribe(resp => {
      this.fichaGuardada = ficha;
      this.alertService.success(resp.message)
      this.spinner.hide();
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error con el servicio de guardado de la ficha');
      this.spinner.hide();
    }));
  }

  // Boton Continuar Primera etapa

  avanzarFlujo(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      if(this.bodyModelos?.stages[8]?.optional === false){
        if(this.bodyModelos?.stages[8]?.completed){

          this.subscriptions.push(this.solicitudCreditoService.calcularScore(this.idTransaccion, this.rut).subscribe(resp => {
            if(resp?.MotorDeCalculos?.errors?.hassError === false){
              if(resp?.DatosBasicosSolicitud?.Automatico === true){

                this.bloqueaPasos6.emit(true);
                this.solicitudCreditoService.setStep(6);
                this.spinner.hide();

              } else {
                
                this.solicitudCreditoService.setStep('next');
                // bloquea pasos 5
                if(!this.validaUsuarioAdmin()){
                  this.bloqueaPasos5.emit(true);
                }
                this.spinner.hide();
              }
            } else {
              this.alertService.error(resp?.MotorDeCalculos?.details?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del motor de calculos');
              this.spinner.hide();
            }
          }, (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar servicio del motor de calculos');
            this.spinner.hide();
          }));

        } else {
          this.spinner.hide();
          this.alertService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
        }
      } else {
        this.solicitudCreditoService.setStep('next');
        this.spinner.hide();
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));
  }

  // Funciones para navegacion

  mapeoSbif(): void {
    if(this.reportesSeleccionado?.deudaSbif?.reporte){
      if (this.reportesSeleccionado?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item) {
        this.reportesSeleccionado.deudaSbif.reporte = {
          Documentos: {
            SbifOnline: {
              Reporte: {
                rowsMeses: this.reportesSeleccionado?.deudaSbif?.reporte?.Sbif?.rowsMeses
              }
            }
          }
        }
      }
    }

  }

  // Mapea datos para abrir el modal

  mapeoPoderJudicial(): void{
    if(this.reportesSeleccionado?.poderJudicial?.reporte){
      const datos = this.reportesSeleccionado?.poderJudicial?.reporte["Totalizadores Poder Judicial"];
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: {
            Totales: datos
          }
        }
      };
      this.reportesSeleccionado.poderJudicial.reporteMapeado = reporte;
    }
    if(this.reportesSeleccionado?.poderJudicial?.reporte?.Reporte){
      const datos = this.reportesSeleccionado?.poderJudicial?.reporte?.Reporte;
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: datos
        }
      };

      this.reportesSeleccionado.poderJudicial.ReporteFull = reporte;
    }
  }

  mapeoVehiculos(): void{ // validar
    if(this.reportesSeleccionado?.vehiculos?.reporte){
      this.reportesSeleccionado.objReporteVehiculos = this.reportesSeleccionado?.vehiculos?.reporte;
    }
  }

  mapeoBienesRaices(): void{ // validar
    if(this.reportesSeleccionado?.bienesRaices?.reporte){
      this.reportesSeleccionado.objReporteBienesRaices = this.reportesSeleccionado?.bienesRaices?.reporte;
    }
  }

  // Valida los objetos para abrir el modal

  validaObjetos(): void{
    if(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte && Object.keys(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte).length === 0){
      this.reportesSeleccionado.cteVentasBalancesRentas.reporte = null;
    }
    if(this.reportesSeleccionado?.basicReport?.reporte && Object.keys(this.reportesSeleccionado?.basicReport?.reporte).length === 0){
      this.reportesSeleccionado.basicReport.reporte = null;
    }
    if(this.reportesSeleccionado?.deudaSbif?.reporte && Object.keys(this.reportesSeleccionado?.deudaSbif?.reporte).length === 0){
      this.reportesSeleccionado.deudaSbif.reporte = null;
    }
    if(this.reportesSeleccionado?.boletinConcursal?.reporte && Object.keys(this.reportesSeleccionado?.boletinConcursal?.reporte).length === 0){
      this.reportesSeleccionado.boletinConcursal.reporte = null;
    }
    if(this.reportesSeleccionado?.reporteEmpresaDia?.reporte && Object.keys(this.reportesSeleccionado?.reporteEmpresaDia?.reporte).length === 0){
      this.reportesSeleccionado.reporteEmpresaDia.reporte = null;
    }
  }

  // Funcion para abrir modal con datos basicos en tabla de informes comerciales

  abrirModal(entidad: string, rut: string): void{
    if(rut && entidad){
      if(this.reportesDerivadosObj[entidad]?.[rut]){
        this.reportesSeleccionado = this.reportesDerivadosObj[entidad][rut];
        this.rutSeleccionado = rut;
        this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
        this.mapeoPoderJudicial();
        this.mapeoVehiculos();
        this.mapeoBienesRaices();
        this.mapeoSbif();
        this.validaObjetos();
        this.plantillaAntecedentes = '';
        this.modalDefault = true;
  
        this.modalService.open();
      }
    }
    
  }

  abrirModalTitular(): void{
    if(this.reportesPrincipalesObj){
      this.mapeoReporteTitular();
      this.mapeoPoderJudicial();
      this.mapeoSbif();
      this.validaObjetos();
    }

    this.reportesSeleccionado.consolidadoTitular = this.consolidadoTitular;
  
    this.rutSeleccionado = this.rut;
    this.plantillaAntecedentes = 'cesceDemo';
    this.modalDefault = false;

    console.log(this.reportesSeleccionado, this.informeComercial)
    this.modalService.open();
  }

  mapeoReporteTitular(): void {
    this.reportesSeleccionado = {
      deudaSbif: this.reportesPrincipalesObj?.deudaSbif,
      poderJudicial: this.reportesPrincipalesObj?.poderJudicial,
      boletinConcursal: this.reportesPrincipalesObj?.boletinConcursal,
      objReporteVehiculos: this.reportesPrincipalesObj?.vehiculos?.reporte,
      objReporteBienesRaices: this.reportesPrincipalesObj?.bienesRaices?.reporte
    } 

  }

  // Scrollea automaticamente la vista a la seccion seleccionada

  scroll(el: string, event: any) {
    let button = event.target as HTMLElement | null;
    const elementHTML = document.getElementById(el) as HTMLElement | null;
    if(button?.classList.contains('fa')){
      button = button?.parentElement as HTMLElement | null;
    }
    elementHTML?.classList.toggle('container-desplegables');
    if ( !elementHTML?.classList.contains('container-desplegables') ){
      elementHTML?.scrollIntoView(true);
      button?.classList.add('button-selected');
      button?.classList.remove('border');
      button?.classList.remove('border-buttons');
      button?.classList.add('border-0'); 

    } else {
      button?.classList.remove('button-selected');
      button?.classList.add('border');
      button?.classList.add('border-buttons');
      button?.classList.remove('border-0'); 
    }
  }

  // Muestra u oculta seccion dependiendo de seleccion del usuario

  show(el: string, buttonId?: string) {
    const elementHTML = document.getElementById(el) as HTMLElement;
    elementHTML.classList.toggle('container-desplegables');
    if(buttonId){
      const button = document.getElementById(buttonId) as HTMLElement;
      if ( !elementHTML.classList.contains('container-desplegables') ){
        button.classList.add('button-selected');
        button.classList.remove('border');
        button.classList.remove('border-buttons');
        button.classList.add('border-0'); 
      } else {
        button.classList.remove('button-selected');
        button.classList.add('border');
        button.classList.add('border-buttons');
        button.classList.remove('border-0'); 
      }
    }
  }

  // Agrega Fila en Carga financiera

  addRowCargaFinanciera(): void{
    if(this.cargaFinanciera.length < 10){
      this.cargaFinanciera.push(
        {
          fechaCurse: '',
          institucion: '',
          tipoCredito: '',
          creditoOriginal: '',
          deudaActual: '',
          cuota: '',
          nCuotasPorPagar: '',
          nCuotasMorosas: '',
          ultimoVencimiento: '',
          garantiasVigentes: ''
        }
      )
    }
  }

  // Elimina Fila en Carga financiera

  deleteRowCargaFinanciera(index: number): void{
    if(this.cargaFinanciera.length > 0){
      this.cargaFinanciera.splice(index, 1);
    }
  }

  // Calculos Totales Tabla Carga Financiera

  calcularTotalCreditoOriginal(): number {
    let suma = 0;
    this.cargaFinanciera.forEach((element: any) => {
      if(typeof(element.creditoOriginal)==='number')
        suma += element.creditoOriginal;
    })
    this.totalesCargaFinanciera.creditoOriginal = suma;
    return suma
  }

  calcularTotalDeudaActual(): number {
    let suma = 0;
    this.cargaFinanciera.forEach((element: any) => {
      if(typeof(element.deudaActual)==='number')
        suma += element.deudaActual;
    })
    this.totalesCargaFinanciera.deudaActual = suma;
    return suma
  }

  calcularTotalCuota(): number {
    let suma = 0;
    this.cargaFinanciera.forEach((element: any) => {
      if(typeof(element.cuota)==='number')
        suma += element.cuota;
    })
    this.totalesCargaFinanciera.cuota = suma;
    return suma
  }

  calcularTotalNCuotasPorPagar(): number {
    let suma = 0;
    this.cargaFinanciera.forEach((element: any) => {
      if(typeof(element.nCuotasPorPagar)==='number')
        suma += element.nCuotasPorPagar;
    })
    this.totalesCargaFinanciera.nCuotasPorPagar = suma;
    return suma
  }

  calcularTotalNCuotasMorosas(): number {
    let suma = 0;
    this.cargaFinanciera.forEach((element: any) => {
      if(typeof(element.nCuotasMorosas)==='number')
        suma += element.nCuotasMorosas;
    })
    this.totalesCargaFinanciera.nCuotasMorosas = suma;
    return suma
  }


  // Calculos Totales Tabla Línea / Operación Propuesta

  calcularTotalLineaVigente(): number {
    let suma = 0;

    typeof(this.lineaOperacionPropuesta.lineaVigente.facturas)==='number' ? suma += this.lineaOperacionPropuesta.lineaVigente.facturas : null;
    typeof(this.lineaOperacionPropuesta.lineaVigente.cheques)==='number' ? suma += this.lineaOperacionPropuesta.lineaVigente.cheques : null;
    typeof(this.lineaOperacionPropuesta.lineaVigente.confirming)==='number' ? suma += this.lineaOperacionPropuesta.lineaVigente.confirming : null;
    typeof(this.lineaOperacionPropuesta.lineaVigente.contratoServiu)==='number' ? suma += this.lineaOperacionPropuesta.lineaVigente.contratoServiu : null;
    typeof(this.lineaOperacionPropuesta.lineaVigente.creditoDirecto)==='number' ? suma += this.lineaOperacionPropuesta.lineaVigente.creditoDirecto : null;

    this.lineaOperacionPropuestaTotales.totalesLineaVigente = suma;

    return suma;
  }

  calcularTotalLineaPropuesta(): number {
    let suma = 0;

    typeof(this.lineaOperacionPropuesta.lineaPropuesta.facturas)==='number' ? suma += this.lineaOperacionPropuesta.lineaPropuesta.facturas : null;
    typeof(this.lineaOperacionPropuesta.lineaPropuesta.cheques)==='number' ? suma += this.lineaOperacionPropuesta.lineaPropuesta.cheques : null;
    typeof(this.lineaOperacionPropuesta.lineaPropuesta.confirming)==='number' ? suma += this.lineaOperacionPropuesta.lineaPropuesta.confirming : null;
    typeof(this.lineaOperacionPropuesta.lineaPropuesta.contratoServiu)==='number' ? suma += this.lineaOperacionPropuesta.lineaPropuesta.contratoServiu : null;
    typeof(this.lineaOperacionPropuesta.lineaPropuesta.creditoDirecto)==='number' ? suma += this.lineaOperacionPropuesta.lineaPropuesta.creditoDirecto : null;

    this.lineaOperacionPropuestaTotales.totalesLineaPropuesta = suma;

    return suma;
  }

  calcularTotalOpPuntualVigente(): number {
    let suma = 0;

    typeof(this.lineaOperacionPropuesta.opPuntualVigente.facturas)==='number' ? suma += this.lineaOperacionPropuesta.opPuntualVigente.facturas : null;
    typeof(this.lineaOperacionPropuesta.opPuntualVigente.cheques)==='number' ? suma += this.lineaOperacionPropuesta.opPuntualVigente.cheques : null;
    typeof(this.lineaOperacionPropuesta.opPuntualVigente.confirming)==='number' ? suma += this.lineaOperacionPropuesta.opPuntualVigente.confirming : null;
    typeof(this.lineaOperacionPropuesta.opPuntualVigente.contratoServiu)==='number' ? suma += this.lineaOperacionPropuesta.opPuntualVigente.contratoServiu : null;
    typeof(this.lineaOperacionPropuesta.opPuntualVigente.creditoDirecto)==='number' ? suma += this.lineaOperacionPropuesta.opPuntualVigente.creditoDirecto : null;

    this.lineaOperacionPropuestaTotales.totalesOpPuntualVigente = suma;

    return suma;
  }

  calcularTotalOpPuntualPropuesta(): number {
    let suma = 0;

    typeof(this.lineaOperacionPropuesta.opPuntualPropuesta.facturas)==='number' ? suma += this.lineaOperacionPropuesta.opPuntualPropuesta.facturas : null;
    typeof(this.lineaOperacionPropuesta.opPuntualPropuesta.cheques)==='number' ? suma += this.lineaOperacionPropuesta.opPuntualPropuesta.cheques : null;
    typeof(this.lineaOperacionPropuesta.opPuntualPropuesta.confirming)==='number' ? suma += this.lineaOperacionPropuesta.opPuntualPropuesta.confirming : null;
    typeof(this.lineaOperacionPropuesta.opPuntualPropuesta.contratoServiu)==='number' ? suma += this.lineaOperacionPropuesta.opPuntualPropuesta.contratoServiu : null;
    typeof(this.lineaOperacionPropuesta.opPuntualPropuesta.creditoDirecto)==='number' ? suma += this.lineaOperacionPropuesta.opPuntualPropuesta.creditoDirecto : null;

    this.lineaOperacionPropuestaTotales.totalesOpPuntualPropuesta = suma;

    return suma;
  }

  calcularTotalVigente(): number {
    let suma = 0;

    typeof(this.lineaOperacionPropuesta.vigente.facturas)==='number' ? suma += this.lineaOperacionPropuesta.vigente.facturas : null;
    typeof(this.lineaOperacionPropuesta.vigente.cheques)==='number' ? suma += this.lineaOperacionPropuesta.vigente.cheques : null;
    typeof(this.lineaOperacionPropuesta.vigente.confirming)==='number' ? suma += this.lineaOperacionPropuesta.vigente.confirming : null;
    typeof(this.lineaOperacionPropuesta.vigente.contratoServiu)==='number' ? suma += this.lineaOperacionPropuesta.vigente.contratoServiu : null;
    typeof(this.lineaOperacionPropuesta.vigente.creditoDirecto)==='number' ? suma += this.lineaOperacionPropuesta.vigente.creditoDirecto : null;

    this.lineaOperacionPropuestaTotales.totalesVigente = suma;

    return suma;
  }

  calcularTotalPropuesta(): number {
    let suma = 0;

    typeof(this.lineaOperacionPropuesta.propuesta.facturas)==='number' ? suma += this.lineaOperacionPropuesta.propuesta.facturas : null;
    typeof(this.lineaOperacionPropuesta.propuesta.cheques)==='number' ? suma += this.lineaOperacionPropuesta.propuesta.cheques : null;
    typeof(this.lineaOperacionPropuesta.propuesta.confirming)==='number' ? suma += this.lineaOperacionPropuesta.propuesta.confirming : null;
    typeof(this.lineaOperacionPropuesta.propuesta.contratoServiu)==='number' ? suma += this.lineaOperacionPropuesta.propuesta.contratoServiu : null;
    typeof(this.lineaOperacionPropuesta.propuesta.creditoDirecto)==='number' ? suma += this.lineaOperacionPropuesta.propuesta.creditoDirecto : null;

    this.lineaOperacionPropuestaTotales.totalesPropuesta = suma;

    return suma;
  }

  calcularTotalCancelada(): number {
    let suma = 0;

    typeof(this.lineaOperacionPropuesta.cancelada.facturas)==='number' ? suma += this.lineaOperacionPropuesta.cancelada.facturas : null;
    typeof(this.lineaOperacionPropuesta.cancelada.cheques)==='number' ? suma += this.lineaOperacionPropuesta.cancelada.cheques : null;
    typeof(this.lineaOperacionPropuesta.cancelada.confirming)==='number' ? suma += this.lineaOperacionPropuesta.cancelada.confirming : null;
    typeof(this.lineaOperacionPropuesta.cancelada.contratoServiu)==='number' ? suma += this.lineaOperacionPropuesta.cancelada.contratoServiu : null;
    typeof(this.lineaOperacionPropuesta.cancelada.creditoDirecto)==='number' ? suma += this.lineaOperacionPropuesta.cancelada.creditoDirecto : null;

    this.lineaOperacionPropuestaTotales.totalesCancelada = suma;

    return suma;
  }

  calcularOpPuntualM(): number {
    let suma = 0;

    suma = Number(this.lineaOperacionPropuestaTotales.totalesOpPuntualVigente) + Number(this.lineaOperacionPropuestaTotales.totalesOpPuntualPropuesta) || 0;
    this.lineaOperacionPropuesta.opPuntualM = suma;

    return suma;
  }

  calcularTotalLineaM(): number {
    if(Number(this.lineaOperacionPropuestaTotales.totalesLineaPropuesta)>0){
      this.lineaOperacionPropuesta.totalLineaM = Number(this.lineaOperacionPropuestaTotales.totalesLineaPropuesta);
      return Number(this.lineaOperacionPropuestaTotales.totalesLineaPropuesta);
    } else {
      this.lineaOperacionPropuesta.totalLineaM = Number(this.lineaOperacionPropuestaTotales.totalesLineaVigente) || 0;
      return Number(this.lineaOperacionPropuestaTotales.totalesLineaVigente) || 0;
    }
  }

  calcularRiesgoTotalM(): number {
    let suma = 0;

    suma = Number(this.lineaOperacionPropuesta.opPuntualM) + Number(this.lineaOperacionPropuesta.totalLineaM) || 0;
    this.lineaOperacionPropuesta.riesgoTotalM = suma;

    return suma;
  }

  stringToFloat(number: any): number {
    return parseFloat(number.toString().replace(',', '.')) || 0;
  }

  finalizar(): void {
    this.finalizarFlujo.emit();
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  agregarSaltosDeLinea(texto: string): string {
    return texto.replace(new RegExp('\n', 'g'), '<br />');
  }

  validaUsuarioAdmin(): boolean {
    return this._sessionService.getUserAccess().includes(this.access[3]);
  }

  volverEtapaPrevia(): void {
    this.bloqueaPasos5.emit(false);
    this.solicitudCreditoService.setStep(Number(this.stepper - 1));
    this.spinner.hide();
  }

  // Boton continuar etapa final

  continuar(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      if(this.bodyModelos?.stages[9]?.optional === false){
        if(this.bodyModelos?.stages[9]?.completed){
          
          // Llamada a servicio para inciar aprobacion
          this.subscriptions.push(this.solicitudCreditoService.iniciarFlujoAprobacional(this.idTransaccion, this.rut).subscribe(resp => {
            const error = resp?.ServicioAprobacional?.errors || null;
            if(error?.hassError === true){
              this.alertService.error(error?.details?.message || 'Solicitud de Crédito - Ocurrio un error al actualizar el flujo');
              this.spinner.hide();
            } else {
              this.solicitudCreditoService.setStep('next');
              this.bloqueaPasos6.emit(true);
              this.spinner.hide();
            }
          }, (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar con el servicio para iniciar el flujo aprobacional');
            this.spinner.hide();
          }));

        } else {
          this.spinner.hide();
          this.alertService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
        }
      } else {
        // Llamada a servicio para inciar aprobacion
        this.subscriptions.push(this.solicitudCreditoService.iniciarFlujoAprobacional(this.idTransaccion, this.rut).subscribe(resp => {
          const error = resp?.ServicioAprobacional?.errors || null;
          if(error?.hassError === true){
            this.alertService.error(error?.details?.message || 'Solicitud de Crédito - Ocurrio un error al actualizar el flujo');
            this.spinner.hide();
          } else {
            this.solicitudCreditoService.setStep('next');
            this.bloqueaPasos6.emit(true);
            this.spinner.hide();
          }
        }, (error: any) => {
          this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar con el servicio para iniciar el flujo aprobacional');
          this.spinner.hide();
        }));
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));
  }


}
