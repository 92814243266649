import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
// import { SimpleModalService } from 'ngx-simple-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { SessionService } from 'src/app/shared/services/session.service';
import { environment as env } from 'src/environments/environment';
import { OfertasApiService } from '../services/ofertas-api.service';
import { AccessDialogComponent } from 'src/app/components/access-dialog/access-dialog.component';
import { OfertasHelperService } from '../services/ofertas-helper.service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-carga-masiva-ofertas',
  templateUrl: './carga-masiva-ofertas.component.html',
  styleUrls: ['./carga-masiva-ofertas.component.scss']
})
export class CargaMasivaOfertasComponent implements OnInit {
  // private access: string[] = [ESystemAccess.CLIENTES_CREDS_ADMIN, ESystemProfileList.WEB];
  public hasUserAccess = true;

  public formularioArchivo: FormGroup;
  public fileName: boolean = false;
  private tempFile!: any;

  public seeTable: boolean = true; 
  public seeUpload: boolean = true; 

  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public inputText: string = '';
  public order = '';
  public campoOrder = '';
  public tablaFiltrado: any[] = [];
  public tabla: any[] = [];

  public showResultTable = false;
  public createdOffers: any[] = [];

  constructor(
    private _sessionService: SessionService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private alertsService: AlertService,
    // private credencialesService: CredencialesService,
    // private simpleModalService: SimpleModalService,
    private _apiService: OfertasApiService,
    private _helperService: OfertasHelperService,
    private location: Location,
    public dialog: MatDialog
  ) { 
    this.formularioArchivo = this.formBuilder.group({
      file: [null]
    })
  }

  ngOnInit(): void {
    // TO DO
  }

  obtenerLista(): void {
    this.initFormArchivo()
  }

  mapeoFechas(): void {
    this.tabla.forEach(element => {
      if(element?.FechaReporte){
        const dateString = element.FechaReporte;
        const year = dateString.substr(0,4);
        const month = dateString.substr(5,2);
        const day = dateString.substr(8,2);
        const hour = dateString.substr(11,2);
        const minute = dateString.substr(14,2);

        element.FechaReporteFormatted = ["" + day, "" + month, "" + year].join('-') + [" " + hour, "" + minute].join(':');
      }
    });
  }

  mapeoEstados(): void {
    this.tabla.forEach(element => {
      if(element.EstadoTransaccion === 'ERROR'){
        if(element.SubEstadoTransaccion === 'ERROR'){
          element.mensaje = 'Error'
        }
        if(element.SubEstadoTransaccion === 'VALIDATIONS'){
          element.mensaje = 'Errores de validaciones'
        }
      }
      if(element.EstadoTransaccion === 'COMPLETED' && element.SubEstadoTransaccion === 'ERROR'){
        element.mensaje = 'Terminado con errores'
      }
      if(element.EstadoTransaccion === 'COMPLETED' && element.SubEstadoTransaccion === 'COMPLETED'){
        element.mensaje = 'Completado'
      }
      if(element.EstadoTransaccion === 'PROCESSING'){
        element.mensaje = 'Procesando'
      }
    });
  }


  filtrarObjeto(){
    this.currentPage = 0;
    this.tablaFiltrado = this.inputText != '' ? this.tabla.filter((item: any) => 
    item?.IDTransaccion?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.mensaje?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.FechaReporteFormatted?.toUpperCase().includes(this.inputText.toUpperCase())) : this.tabla;
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  // Ordena las columnas en caso de null o undefined se asigna -1 para dejarlos primeros
  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      this.tablaFiltrado.sort((a, b) => {
        if(campo === 'FechaReporteFormatted'){
          let newDateA: any = null;
          let newDateB: any = null;

          if(a?.FechaReporteFormatted){
            const dateFullA = a?.FechaReporteFormatted?.split(" ");
            const dateA = dateFullA?.[0]?.split("-");
            const hoursA = dateFullA?.[1]?.split(":");
            newDateA = new Date(dateA[2], dateA[1]-1, dateA[0], hoursA[0], hoursA[1]);
          } else {
            newDateA = new Date("1000");
          }

          if(b?.FechaReporteFormatted){
            const dateFullB = b?.FechaReporteFormatted?.split(" ");
            const dateB = dateFullB?.[0]?.split("-");
            const hoursB = dateFullB?.[1]?.split(":");
            newDateB = new Date(dateB[2], dateB[1]-1, dateB[0], hoursB[0], hoursB[1]);
          } else {
            newDateB = new Date("1000");
          }

          return newDateA - newDateB;
          
        } else {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return textA.localeCompare(textB);
        }
      });
    } else {
      this.tablaFiltrado.sort((a, b) => {
        if(campo === 'FechaReporteFormatted'){

          let newDateA: any = null;
          let newDateB: any = null;

          if(a?.FechaReporteFormatted){
            const dateFullA = a?.FechaReporteFormatted?.split(" ");
            const dateA = dateFullA?.[0]?.split("-");
            const hoursA = dateFullA?.[1]?.split(":");
            newDateA = new Date(dateA[2], dateA[1]-1, dateA[0], hoursA[0], hoursA[1]);
          } else {
            newDateA = new Date("1000");
          }

          if(b?.FechaReporteFormatted){
            const dateFullB = b?.FechaReporteFormatted?.split(" ");
            const dateB = dateFullB?.[0]?.split("-");
            const hoursB = dateFullB?.[1]?.split(":");
            newDateB = new Date(dateB[2], dateB[1]-1, dateB[0], hoursB[0], hoursB[1]);
          } else {
            newDateB = new Date("1000");
          }

          return newDateB - newDateA;
          
        } else {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return (-1 * textA.localeCompare(textB));
        }
      });
    }
  }

  descargarArchivo(idTransaccion: string, base64String: string): void {
    const button: any = document.getElementById(idTransaccion);
    if(button){
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      button.href = url;
      button.download = idTransaccion + '.csv';
      button.click();
      URL.revokeObjectURL(url);
    }
  }

  // Consulta para descargar archivo
  obtenerArchivo(elemento: any): void {
    const idTransaccion = elemento?.IDTransaccion;
    if(idTransaccion) {
      this.spinner.show();
      // this.credencialesService.obtenerArchivoCargaMasiva(idTransaccion).subscribe(
      //   (data: any) => {
      //     if(data?.ArchivoResultado){
      //       this.descargarArchivo(idTransaccion, data?.ArchivoResultado);
      //     } else {
      //       this.alertsService.error('Ha ocurrido un error intentar rescatar el archivo.');
      //     }
      //     this.spinner.hide();
      //   },
      //   ({ error }) => {
      //     this.alertsService.error(error.message || 'Ha ocurrido un error intentar descargar el archivo.');
      //     this.spinner.hide();
      // });

    }
  }

  verCargar(): void {
    this.seeTable = false;
    this.seeUpload = true;
    this.initFormArchivo()
  }

  volverTabla(): void {
    this.seeTable = true;
    this.seeUpload = false;
    this.formularioArchivo.reset();
    this.obtenerLista();
  }

  initFormArchivo(): void {
    this.formularioArchivo = this.formBuilder.group({
      file: [null]
    })
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    if(file.size <= 1000000){
      // falta validacion tipo de dato
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.tempFile = reader?.result?.toString().split(',')[1];
      };
    } else {
      this.alertsService.error('El tamaño maximo del archivo debe ser de 1mb');
      this.tempFile = {};
    }
  }

  submitArchivo() {
    // this.spinner.show();
    if(typeof(this.tempFile) === 'string' && this.tempFile?.length > 0){
      const idOrganizacion = this._sessionService.getOrganization();
      const idUsuario = this._sessionService.getUserInfo().username;

      this.spinner.show();
      this._apiService.altaMasivaOfertas(idUsuario, idOrganizacion, this.tempFile).subscribe(
        (res) => {
          console.log(res);
          this.spinner.hide()
          this.showResultTable = true;
          if(res.createdOffers) {
            this.createdOffers = res.createdOffers;
          }
        },
        (error) => {
          console.log(error);
          this.spinner.hide()
          // Alert service
          if(error.error.message) {
            this.alertsService.error(error.error.message)
          }
        }
      )
    } else {
      this.alertsService.error('Solicitud de Crédito - Ocurrio un error con el archivo.');
      this.spinner.hide();
    }
  }

  actualizarTabla(): void {
    this.obtenerLista();
  }

  mostrarModal(): void {

    const lista: any[] = [
      // titulo
      { value: 'titulo : Es el título/nombre de la oferta' },
      // descripcionIntroduccion
      // descripcionDetalle
      { value: 'la descripción se conforma de 2 partes:', 
        lista: [
          'descripcionIntroduccion : Es la introducción de la descripción de la oferta',
          'descripcionDetalle : Es el detalle de la descripción de la oferta'
        ]
      },
      // tipo
      { value: 'tipo : Es el tipo de vertical financiera de la oferta, los valores posibles son: factoring, cuentaCorriente, tarjetaCredito, creditoInversion, leasing, capitalTrabajo' },
      // monoInicio
      { value: 'monoInicio : Este campo determina el monto mínimo de la oferta, en caso de no completarlo, se asumirá que la oferta no tiene definido un monto' },
      // montoFin
      { value: 'montoFin : Este campo determina el monto máximo de la oferta, en caso de no completarlo, se tomará el monto de inicio como monto fijo de la oferta' },
      // plazo
      { value: 'plazo : Es el plazo de pago de la oferta, expresado en meses' },
      // tasa
      { value: 'plazo : Es la tasa de interés de la oferta, expresada en porcentaje' },
      // organizacionUsuario
      { value: 'organizacionUsuario : Es el rut de la organización a la que se le quiere oferecer la oferta' },
      // expiracion
      { value: 'expiracion: es la fecha de expiración de la oferta en formato UNIX, en caso de que no se especifique, se generará automáticamente con una vigencia de un año'
      },
      { value: 'Todos los campos deben tener menos de 200 caracteres' },
    ];
    // this.simpleModalService.addModal(AccessDialogComponent, {
    //   title: 'Ejemplo archivo de carga',
    //   urlImage: '../assets/img/carga-masiva-ofertas.png', // Cambiar imagen
    //   subtitle: 'Las columnas del archivo son:',
    //   lista: lista
    // }).subscribe(() => {
  
    // });

    this.dialog.open(AccessDialogComponent, {
      data: {
        title: 'Ejemplo archivo de carga',
        urlImage: '../assets/img/image-carga-masiva.png',
        subtitle: 'Las columnas del archivo son:',
        lista: lista,
        textTimer: env.inactivity.countdown + 1
      }
    });
  }

  displayAmount(amount: any):string {
    return this._helperService.displayAmount(amount);
  }

  getFormattedDate(clientExpTime: string | undefined): string {
    return this._helperService.getFormattedDate(clientExpTime);
  }

  back() {
    this.location.back();
  }
}