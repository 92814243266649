<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>
<div *ngIf="hasUserAccess">

    <div class="card w-100 mx-auto border-0 rounded">
        <div class="card-body p-5">
            <div class="row">
                <div class="col-sm-12 my-2">
                    <button (click)="goToCreate()" class="btn btn-primary float-right formulario" *ngIf="showTable == true">Crear</button>
                    <button (click)="volverList()" class="btn btn-primary formulario" *ngIf="showTable == false">Volver</button>
                </div>
                <div class="col-sm-12" *ngIf="showTable == true">
                    <div class="card text-center my-4">
                        <div class="card-header">
                            <div class="my-0 row d-flex px-4">
                                <div class="col-xs-3 col-sm-auto mr-auto p-2">
                                    <app-paginator-by-items title="Cantidad de filas a mostrar en la tabla" [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                                </div>
                                <div class="col-xs-3 col-sm-auto p-2">
                                    <div class="input-group mb-3">
                                        <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                            title="Ingrese texto para filtrar tabla"
                                            placeholder="Filtrar tabla"
                                            [(ngModel)]="inputText" 
                                            (keyup)="filtrarObjeto()"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table id="tableUsuarios" class="table table-hover border my-0">
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-center font-size cursor-pointer" style="display:none;">userSub</th>
                                        <th scope="col" class="text-center font-size cursor-pointer" (click)="cambioDeCampo('userName')">
                                            Usuario
                                            <div class="float-right">
                                                <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                            </div>
                                        </th>
                                        <th scope="col" class="text-center font-size cursor-pointer min-width-th" (click)="cambioDeCampo('name')">
                                            Nombre
                                            <div class="float-right">
                                                <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                            </div>
                                        </th>
                                        <th scope="col" class="text-center font-size cursor-pointer min-width-th" (click)="cambioDeCampo('givenName')">
                                            Apellido
                                            <div class="float-right">
                                                <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                            </div>
                                        </th>
                                        <th scope="col" class="text-center font-size cursor-pointer" (click)="cambioDeCampo('email')">
                                            Email
                                            <div class="float-right">
                                                <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                            </div>
                                        </th>
                                        <!--<th scope="col" class="text-center font-size">Reset Pass</th>-->
                                        <th scope="col" class="text-center font-size">Cambiar Pass</th>
                                        <th scope="col" class="text-center font-size">Activo</th>
                                        <th scope="col" class="text-center font-size">Accesos</th>
                                        <th scope="col" class="text-center font-size">Grupos</th>
                                        <th scope="col" class="text-center font-size">Editar</th>
                                        <th scope="col" class="text-center font-size">Borrar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of usersGroupsFiltrado | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                                        <td style="display:none;">{{user.userSub}}</td>
                                        <td class="font-size-body align-middle">{{user.userName}}</td>
                                        <td class="font-size-body align-middle">{{user.name}}</td>
                                        <td class="font-size-body align-middle">{{user.givenName}}</td>
                                        <td class="font-size-body align-middle">{{user.email}}</td>
                                        <!--<td class="font-size-body align-middle">
                                            <button type="button" class="btn btn-outline-warning" (click)="resetPass(user.userName)">
                                                Restablecer Contraseña
                                            </button>
                                        </td>-->
                                        <td class="font-size-body align-middle">
                                            <button type="button" class="btn btn-outline-success" (click)="changePass(user.userName)">
                                                Cambiar Contraseña
                                            </button>
                                        </td>
                                        <td class="text-center">
                                            <label class="switch switch-primary">
                                                <input (click)="isActiveChange(user.userName)" type="checkbox"
                                                    [attr.checked]="user.profilesList.includes('WEB') ? 'checked' : null">
                                                <span></span>
                                            </label>
                                        </td>
                                        <td class="text-center">
                                            <button (click)="showAccess(user.userName)" class="btn btn-w-m btn-outline-danger fa fa-search fa-1-4"></button>
                                        </td>
                                        <td class="text-center">
                                            <button (click)="showGrupos(user)" class="btn btn-w-m btn-outline-danger fa fa-edit fa-1-4"></button>
                                        </td>
                                        <td class="text-center">
                                            <button type="button" (click)="goToEdit(user.userName)" class="edit btn btn-w-m btn-outline-danger fa fa-edit fa-1-4"></button>
                                        </td>
                                        <td class="text-center">
                                            <button type="button" (click)="deleteUser(user.userName)" class="btn btn-w-m btn-outline-danger fa fa-trash fa-1-4"></button>
                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer text-muted">
                            <div class="p-2">
                                <div id="pagination">
                                    <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                                        <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12" *ngIf="showTable == false">
                    <div class="card text-center border-0 my-4">
                        <h2>
                            Asignación de grupos usuario <strong>{{usuarioSeleccionado.userName || ''}}</strong> 
                        </h2>
                        <div class="col-sm-12 col-lg-8 mt-5 mx-auto max-height overflow-auto">
                            <div class="table-resposive px-3">
                                <table class="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th class="font-size text-center bg-primary">Grupo</th>
                                            <th class="font-size text-center bg-primary">Asignado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let groupItem of listGrupos; let i = index">
                                            <td class="font-size">{{groupItem.name}}</td>
                                            <td class="font-size text-center align-middle">
                                                <input type="checkbox" class="cursor-pointer" [(ngModel)]="listGrupos[i].active" (click)="checkGrupo($event, i)">
                                            </td>                                  
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
