import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { UsuariosService } from 'src/app/shared/services/usuarios.service';
import { IAlertContent } from '../listar-usuario/listar-usuario.component';
import { debounceTime, map, distinctUntilChanged, filter } from "rxjs/operators";
import { fromEvent } from 'rxjs';
//import { SimpleModalService } from 'ngx-simple-modal';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

interface UserCreateFormValues {
  username: string;
  firstName: string;
  givenName: string;
  phoneNumber: string;
}

interface Access {
  accessID: string;
  name: string;
  active: boolean;
  type: string;
  display: string;
  group: string;
}

interface Group {
  group: string;
  display: string;
  accesos: Access[];
}

@Component({
  selector: 'app-crear-usuario',
  templateUrl: './crear-usuario.component.html',
  styleUrls: ['./crear-usuario.component.scss']
})
export class CrearUsuarioComponent implements OnInit, AfterViewInit {
  @ViewChild('usernameSearchInput') usernameSearchInput!: ElementRef;
  
  private access: string[] = [ESystemAccess.CLIENTES_USER_ADMIN, ESystemProfileList.WEB];
  public hasUserAccess = false;

  public accessGroups: Group[] = [];
  public isLoaded = false;
  private alertContent: IAlertContent = { success: false, message: "" };

  public userCreateForm: UntypedFormGroup;
  public submitValid: boolean = false;
  public typeService: boolean = true;

  @ViewChildren("checkboxestodos") checkboxestodos: QueryList<ElementRef> | undefined;

  constructor(
    private router: Router,
    public alertService: AlertService,
    private _usuariosService: UsuariosService,
    private _sessionService: SessionService,
    private formBuilder: UntypedFormBuilder,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog) {
    this.userCreateForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      firstName: [{value:'', disabled: true}, Validators.required],
      givenName: [{value:'', disabled: true}, Validators.required],
      phoneNumber: [{value:'', disabled: true}]
    }) as FormGroupTyped<UserCreateFormValues>;
  }

  ngOnInit(): void {
    
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }

    this._usuariosService.getSystemAccessList()
      .subscribe(
        (data) => {
          this.accessGroups = this.getAccessGroups(data);
          this.isLoaded = true;
        },
        ({error}) => {
          this.alertService.error(error.message);
        }
      )
  }

  private getAccessGroups(data: any[]): any[] {
    let result: any = [];
    if(data && data?.length > 0){
      result = Object.values(data.reduce((acc, current) => {
        if (!acc[current.group]) {
          acc[current.group] = {
            group: current.group,
            display: current.display,
            accesos: []
          };
        }
        acc[current.group].accesos.push({
          accessID: current.accessID,
          name: current.name,
          active: false,
          type: current.type
        });

        
        return acc;
      }, {}));

      result.sort((a: any, b: any) => a.display.localeCompare(b.display));
    }
    return result;
  }

  public createUser() {
    const variableService = this.typeService? 'createUser' : 'setAssignUser';
    const userCreateFormvalues: UserCreateFormValues = this.userCreateForm.value;
    const msg = variableService === 'createUser'? 
    `El usuario ${userCreateFormvalues.username} fue creado exitosamente`:
    `El usuario ${userCreateFormvalues.username} fue agregado exitosamente`;
    const msg2 = variableService === 'createUser'? 
    `¿Esta seguro que desea crear el usuario?`:
    `¿Esta seguro de asignar el usuario a la organización?`;
    const phone = userCreateFormvalues?.phoneNumber?.includes('+56')?
    userCreateFormvalues?.phoneNumber : '+56' + userCreateFormvalues?.phoneNumber;
    
    const selectedAccessNames: string[] = this.accessGroups.reduce(
      (acc: any, { accesos }) => {
        const activeAccesos = accesos.filter(({ active }) => active);
        return [...acc, ...activeAccesos.map(({ name }) => name)];
      },
      []
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Administración de usuarios',
        message: msg2,
        textBoton: ['Si', 'Cerrar'],
      }
    });

    dialogRef.afterClosed().subscribe(resp => { // validar
      if (resp) {
        if (!userCreateFormvalues?.phoneNumber?.includes('+56')) {
          
        }

        this._usuariosService[variableService]({
          username: userCreateFormvalues.username,
          firstName: userCreateFormvalues.firstName,
          givenName: userCreateFormvalues.givenName,
          phoneNumber: phone,
          systemUserAccessList: selectedAccessNames,
        }
        ).subscribe(
          () => {
            this.alertContent = {
              success: true,
              message: msg
            }
            this.goBack();
          },
          ({error}) => {
            this.alertService.error(error.message);
          }
        )
      }
    });

      /*this.simpleModalService.addModal(ConfirmDialogComponent, {
        title: 'Administración de usuarios',
        message: msg2,
        textBoton: ['Si', 'Cerrar'],
      }).subscribe((resp) => {

        if (resp) {
          if (!userCreateFormvalues?.phoneNumber?.includes('+56')) {
            
          }

          this._usuariosService[variableService]({
            username: userCreateFormvalues.username,
            firstName: userCreateFormvalues.firstName,
            givenName: userCreateFormvalues.givenName,
            phoneNumber: phone,
            systemUserAccessList: selectedAccessNames,
          }
          ).subscribe(
            () => {
              this.alertContent = {
                success: true,
                message: msg
              }
              this.goBack();
            },
            ({error}) => {
              this.alertService.error(error.message);
            }
          )
        }
      });*/

  }

  public clearForm() {
    this.userCreateForm.reset();
    this.accessGroups = this.limpiarListaAccesos(this.accessGroups);
    this.checkboxestodos?.forEach(element => {
      if(element?.nativeElement?.checked === true){
        element.nativeElement.checked = false;
      }
    });
  }

  public goBack() {
    this.router.navigate(['/usuarios/listar', { alert: JSON.stringify(this.alertContent) }]);
  }

  ngAfterViewInit() {
    fromEvent(this.usernameSearchInput.nativeElement, 'keyup').pipe(

      map((event: any) => {
        return event.target.value;
      })
      ,filter(res => res.length > 4)
      ,debounceTime(1000) 
      ,distinctUntilChanged()

      ).subscribe((text: string) => {
        
        if (this.userCreateForm.controls.username.status === 'VALID') {
          this._usuariosService.getsearchUserExists(text).subscribe(resp => {
            
            if (!resp.currentOrg && !resp.otherOrg) {
              this.habilitarBloquear('enable');
              this.submitValid = true;
              this.typeService = true;
            }

            if (resp.currentOrg && !resp.otherOrg) {
              this.habilitarBloquear('disable');
              this.clearInput();
              this.submitValid = false;
              this.alertService.error('No es posible crear el usuario ya que existe en la organización actual');
            }

            if (!resp.currentOrg && resp.otherOrg) {
              this.habilitarBloquear('disable');
              this.submitValid = true;
              this.typeService = false;
            }

          },
          ({error}) => {
            this.alertService.error(error.message);
          })
        }
        
      });
  }

  private habilitarBloquear(valor: string): void {
    const form = this.userCreateForm;
    const variableKey = ['firstName', 'givenName', 'phoneNumber'];
    const value = valor === 'disable'? 'disable':'enable';

    for(const item of variableKey) {
      form.controls[item][value]();
    }
  }

  private limpiarListaAccesos(array: any[]): any[] {
    let result = [];
    if(array.length > 0){
      result = array?.map(item => {
        if (item?.accesos.some((acceso: Access) => acceso.active === true)) {
          const accesos: any = item.accesos.map((acceso: Access) => {
            if (acceso.active === true) {
              return {...acceso, active: false};
            }
            return acceso;
          });
          return {...item, accesos};
        }
        return item;
      });
    }
    return result;
  }

  private clearInput(): void {
    const form = this.userCreateForm;
    const variableKey = ['firstName', 'givenName', 'phoneNumber'];

    for(const item of variableKey) {
      form.controls[item].setValue('');
    }

    this.accessGroups = this.limpiarListaAccesos(this.accessGroups);
  }

  selectAll(event: any, type: string): void {
    const valor: any = event?.target?.checked;
    if(event?.target?.checked === true || event?.target?.checked === false) {
      if(this.accessGroups && this.accessGroups.length > 0) {
        for(const group of this.accessGroups) {
          if(group && group?.accesos && group?.accesos.length > 0) {
            for(const acceso of group?.accesos) {
              if(acceso?.type && acceso?.type === type){
                acceso.active = valor;
              }
            }
          }
        }
      }
    }
  }

}
