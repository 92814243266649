import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SessionService } from './session.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IBalanceManual } from 'src/app/models/balance-manual/balance-manual.model';

@Injectable({
  providedIn: 'root'
})
export class BalanceManualService {
  private servicioF22: string = environment.backend.formularioF22.servicio;
  private servicioBalance: string = environment.backend.balanceManual.servicio;
  private servicioPreBalance: string = environment.backend.preBalanceManual.servicio;
  private endpointBalance: string = environment.backend.balanceManual.url;
  private endpointPreBalance: string = environment.backend.preBalanceManual.url;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService
  ) { }

  listarBalance(rut: string, periodos?: string[]): Observable<IBalanceManual[]> {
    let params = new HttpParams();
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("rut", rut);
    params = params.append("servicio", this.servicioBalance);
    params = params.append("uri", 'balance/listar');
    if(periodos && periodos?.length > 0){
      params = params.append("periodo", periodos.toString());
    }

    return this.http.get<IBalanceManual[]>(`${this.endpointBalance}obtener`, { params });
  }

  listarPreBalance(rut: string, periodos?: string[]): Observable<IBalanceManual[]> {
    let params = new HttpParams();
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("rut", rut);
    params = params.append("servicio", this.servicioPreBalance);
    params = params.append("uri", 'prebalance/listar');
    if(periodos && periodos?.length > 0){
      params = params.append("periodo", periodos.toString());
    }

    return this.http.get<any[]>(`${this.endpointPreBalance}obtener`, { params });
  }

  crearPreBalance(rut: string, periodo: number | string, mesInicio: number | string, mesFin: number | string, formulario: any = null, idTransaccion: string = '', subTipo: string, auditado: boolean, moneda: string, camposXlsx: string | null = null): Observable<any> {
    const payload: any = {
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicioPreBalance,
      uri: "prebalance/crear",
      rut: rut,
      periodo: periodo,
      mesInicio: Number(mesInicio),
      mesFin: Number(mesFin),
      subTipo: subTipo,
      moneda: moneda,
      auditado: auditado
    }
    if(idTransaccion) payload.id_transaccion = idTransaccion;
    if(formulario != null) payload.campos = formulario;
    if(camposXlsx != null) payload.camposXlsx = camposXlsx;

    return this.http.post<any>(this.endpointPreBalance + "crear", payload);
  }

  crearBalance(rut: string, periodo: number | string, mesInicio: number | string, mesFin: number | string, formulario: any = null, estado: string, subTipo: string, auditado: boolean, moneda: string, camposXlsx: string | null = null): Observable<IBalanceManual> {
    const payload: any = {
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicioBalance,
      uri: "balance/crear",
      rut: rut,
      periodo: periodo,
      mesInicio: Number(mesInicio),
      mesFin: Number(mesFin),
      subTipo: subTipo,
      moneda: moneda,
      auditado: auditado,
      //campos: formulario,
      estado: estado
    }
    if(formulario != null) payload.campos = formulario;
    if(camposXlsx != null) payload.camposXlsx = camposXlsx;

    return this.http.post<IBalanceManual>(this.endpointBalance + "crear", payload);
  }

  borrarPrebalance(rut: string, idTransaccion: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicioPreBalance);
    params = params.append("uri", 'prebalance/borrar');
    params = params.append("rut", rut);
    params = params.append("id_transaccion", idTransaccion);

    return this.http.get<any>(`${this.endpointPreBalance}obtener`, { params });
  }

  borrarBalance(rut: string, periodo: number): Observable<any> {
    let params = new HttpParams();
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicioBalance);
    params = params.append("uri", 'balance/borrar');
    params = params.append("rut", rut);
    params = params.append("periodo", periodo);
    
    return this.http.get<any>(`${this.endpointPreBalance}obtener`, { params });
  }

  obtenerContratoBalanceManual(): Observable<any> {
    let params = new HttpParams();
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicioBalance);
    params = params.append("uri", 'estructuraBalanceManual');

    return this.http.get<any>(`${this.endpointBalance}obtener`, { params });
  }

  obtenerContratoCalculos(): Observable<any> {
    let params = new HttpParams();
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicioBalance);
    params = params.append("uri", 'contratoCalculosWeb');

    return this.http.get<any>(`${this.endpointBalance}obtener`, { params });
  }

  obtenerExcelCarga(rut: string, periodo: number | string, tipo: string, idTransaccion: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicioF22);
    params = params.append("uri", 'formTemplate/cargaXlsx');
    params = params.append("rut", rut);
    params = params.append("periodo", periodo);
    params = params.append("tipo", tipo);
    params = params.append("id_transaccion", idTransaccion);

    return this.http.get<any>(`${this.endpointBalance}obtener`, { params });
  }

  obtenerListaArchivos(rut: string, periodo: string, tipo: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicioF22);
    params = params.append("uri", 'formTemplate/listarCargaXlsx');
    params = params.append("rut", rut);
    params = params.append("periodo", periodo);
    params = params.append("tipo", tipo);

    return this.http.get<any[]>(`${this.endpointBalance}obtener`, { params });
  }

  obtenerLog(rut: string,  tipo: string = '', periodo: string = '', idTrx: string = ''): Observable<any> {
    let params = new HttpParams();
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("requestOrigin", 'WEB');
    params = params.append("servicio", this.servicioBalance);
    params = params.append("uri", 'eventLog/list');
    params = params.append("rut", rut);
    if(tipo) params = params.append("tipo", tipo);
    if(periodo) params = params.append("periodo", periodo);
    if(idTrx) params = params.append("id_transaccion", idTrx);

    return this.http.get<any[]>(`${this.endpointBalance}obtener`, { params });
  }

}
