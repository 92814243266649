import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SessionService } from './session.service';

export interface ICreateReporModel {
  rut: string;
  idUsuario: string;
  idOrganizacion: string;
  requestOrigin: string;
  Sbif: ISbifModel;
  servicio: string;
  uri: string;
}

export interface ISbifModel {
  user: string;
  pass: string;
  vendor: string;
}

export interface IDatosBasicosSolicitud {
  IDTransaccion: string;
  EstadoTransaccion: string;
  SubEstadoTransaccion: string;
  FechaReporte: string;
  Rut: string;
  DV: string;
}

export interface IDatosSolicitante {
  Organizacion: string;
  Usuario: string;
}

export interface ILeasing {
  DatosBasicosSolicitud: string;
  Reporte: string;
}

export interface ISbif {
  DatosBasicosSolicitud: string;
  Reporte: string;
}

export interface IItem {
  castigadadirecta: string;
  castigadaindirecta: string;
  credcontingente: string;
  diracreedor: string[];
  dircomercial: string;
  dirconsumo?: string;
  dirhipotecaria: string;
  dirinversion: string;
  dirmorosa: string;
  diroperacionpacto: string;
  dirven180mayor3year: string;
  dirvencida: string;
  dirvigente: string;
  indirectaven: string;
  indirectavig: string;
  leasingmor: string;
  leasingvig: string;
  lineacredito: string;
  monexvencida: string;
  monexvigente: string;
  periodo: string;
}

export interface IRowsMeses {
  item: IItem[];
}

export interface IReporte {
  estadresp: string;
  estadcomet: string;
  rutsujeto: string;
  nomsujeto: string;
  rowsMeses: IRowsMeses;
}

export interface ISbifOnline {
  DatosBasicosSolicitud: IDatosBasicosSolicitud;
  Reporte: IReporte;
}

export interface IDocumentos {
  Leasing: ILeasing;
  Sbif: ISbif;
  SbifOnline: ISbifOnline;
}

export interface IDeudaSistemaFinancieroReporte {
  DatosBasicosSolicitud: IDatosBasicosSolicitud;
  DatosSolicitante: IDatosSolicitante;
  PDFProcesados: any[];
  Documentos: IDocumentos;
}

export interface IGlobalDocumentos {
  Documentos: IDocumentos;
}

export interface IDeudaSistemaFinancieroReporteModel {
  DeudaSistemaFinancieroReporte: IDeudaSistemaFinancieroReporte;
}

export type unionType = IDeudaSistemaFinancieroReporteModel | IGlobalDocumentos;



@Injectable({
  providedIn: 'root'
})
export class SbifLeasingService {
  private endpoint: string = environment.backend.SbifLeasing.url;
  private servicio: string = environment.backend.SbifLeasing.servicio;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService) { }


  public createReport(rut: string): Observable<IDeudaSistemaFinancieroReporteModel> {

    const payload: ICreateReporModel = {
      rut: rut,
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      Sbif: {
        user: "",
        pass: "",
        vendor: "SERINCO"
      },
      servicio: this.servicio,
      uri: 'crearReporteSBIFOnline'
    }
    return this.http.post<IDeudaSistemaFinancieroReporteModel>(this.endpoint + "crear", payload)
  }

  public searchReport(rut: string): Observable<IGlobalDocumentos | IDeudaSistemaFinancieroReporteModel> {
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("rut", rut);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'buscarReporteActualizado');
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');
    return this.http.get<IGlobalDocumentos | IDeudaSistemaFinancieroReporteModel>(`${this.endpoint}obtener`, { params });
  }

}
