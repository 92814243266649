import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OfertasHelperService {

  constructor(private _decimalPipe: DecimalPipe,) { }

  displayAmount(amount: any): string {
    const result = "";
    if(!amount.start) {
      return result
    }
    if(!amount.end) {
      return "$" + result + this._decimalPipe.transform(amount.start, '1.0-2');
    }
    if(amount.start && amount.end){
      return "$" + this._decimalPipe.transform(amount.start, '1.0-2') + " - " + "$" + this._decimalPipe.transform(amount.end, '1.0-2')
    }
    return result;
  }

  getFormattedDate(clientExpTime: string | undefined): string {
    if(clientExpTime) {
      const unixTimestamp = parseInt(clientExpTime, 10);
      const date = new Date(unixTimestamp * 1000);
      const formattedDate = date.toLocaleDateString();
      return formattedDate;
    }
    return "";
  }
}
