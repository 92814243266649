import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { CredencialesService } from 'src/app/shared/services/credenciales.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { AlertService } from 'src/app/components/_alert';
import { environment as env } from 'src/environments/environment';
import { RUT } from 'src/app/shared/utils/rut';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription, forkJoin, of } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CredencialesExternasService } from 'src/app/shared/services/credenciales-externas.service';
import { catchError, map } from 'rxjs/operators';


interface RutFormValue {
  rut: string;
}

@Component({
  selector: 'app-credenciales-usuario',
  templateUrl: './credenciales-usuario.component.html',
  styleUrls: ['./credenciales-usuario.component.scss']
})
export class CredencialesUsuarioComponent implements OnInit {
  @ViewChildren("validacredenciales") validaCredencialesButton: QueryList<ElementRef> | undefined;

  @Input() rutEntrada: string = '';
  @Input() credsNameEntrada: string[] = [];
  @Output() actualizaMensaje = new EventEmitter<any>();
  
  private access: string[] = [ESystemAccess.CLIENTES_CREDS_ADMIN, ESystemProfileList.WEB, ESystemAccess.CLIENTES_USER_ADMIN];
  private accessSolicitudes: string[] = [ESystemAccess.SINCRONIZAR_CREDENCIALES_BUSCAR, ESystemAccess.SINCRONIZAR_CREDENCIALES];
  public hasUserAccess = false;

  public showGuardar: boolean = false;
  public serviciosHeader: any[] = [];

  public listaUsuario: any[] = [];
  public seeTableUsuarios: boolean = true;
  public seeTableServicios: boolean = false;
  public seeForm: boolean = false;
  public seeAgregaRut: boolean = false;
  public seeCargaMasiva: boolean = false;

  public inputTextUsuario: string = '';
  public usuarioSeleccionado: any = {};

  public currentPageServicio = 0;
  public currentItemServicio = env.initItemPerPage;
  public inputTextServicio: string = '';
  public orderServicio = '';
  public campoOrderServicio = '';
  public servicioSeleccionado: any = {};
  public tablaServiciosFiltrado: any[] = [];

  public configurarForm!: UntypedFormGroup;
  public agregarRutForm!: UntypedFormGroup;
  public subscriptionForm!: Subscription;
  public subscriptionRutForm!: Subscription;
  public activateRutHelper = false;
  public activateRutHelperService: any = {};

  public ultimosDatos: any[] = [];
  public classButtonValidation: string = 'btn-light';

  public seeSolicitudesRut: boolean = false;
  public seeSolicitud: boolean = false;
  public solicitudForm!: UntypedFormGroup;
  public servicios: any[] = [];


  // logica paginacion

  public paginaActual: any[] = [];
  public currentPage = 0;
  public currentItem = 5;
  public cantidadRegistros = 0;
  public cantidadPaginas = 0;
  public paginasActualesErrores: {pagina: number, error: string}[] = []
  public paginasActuales: {pagina: number, response: any}[] = [];
  public paginationOptions: number[] = [5, 10, 25, 50, 100];

  constructor(
    private _sessionService: SessionService,
    private spinner: NgxSpinnerService,
    private credencialesService: CredencialesService,
    private formBuilder: UntypedFormBuilder,
    public alertService: AlertService,
    private utilsService: UtilsService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private cd: ChangeDetectorRef,
    private router: Router,
    private credencialesExternasService: CredencialesExternasService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);

    if ((this._sessionService.getUserAccess().includes(this.access[0]) || this._sessionService.getUserAccess().includes(this.access[2])) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;

      if(this.rutEntrada !== '' && this.credsNameEntrada.length > 0){
        this.seeTableUsuarios = false;
        this.seeTableServicios = false;
        this.seeAgregaRut = false;
        this.seeCargaMasiva = false;
        this.seeForm = false;
        this.obtenerFromModal();
      } else {
        this.obtenerCantidadElementos();
      }
      
    }

  }

  obtenerCantidadElementos(): void {
    this.ultimosDatos = [];
    this.classButtonValidation = 'btn-light';
    this.spinner.show();
    this.credencialesService.obtenerCantidadElementos().subscribe(
      (data: any) => {
        this.cantidadRegistros = data?.NElementos || 0;
        if(this.cantidadRegistros > 0){
          this.obtenerCantidadPaginasFlujo();
        } else {
          this.alertService.error('No se encontraron registros');
          this.seeTableUsuarios = true;
          this.seeTableServicios = false;
          this.seeForm = false;
          this.seeAgregaRut = false;
          this.seeCargaMasiva = false;
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.alertService.error(error?.message || error?.error?.message || 'Ha ocurrido un error al obtener los registros');
        this.seeTableUsuarios = true;
        this.seeTableServicios = false;
        this.seeForm = false;
        this.seeAgregaRut = false;
        this.seeCargaMasiva = false;
        this.spinner.hide();
    });
  }

  obtenerCantidadPaginas(cantidadRegistros: number): void {
    if(cantidadRegistros || cantidadRegistros == 0) {
      this.cantidadRegistros = cantidadRegistros;
    }
    if(this.cantidadRegistros > 0) {
      this.cantidadPaginas = Math.ceil(this.cantidadRegistros / this.currentItem);
    }
  }

  obtenerCantidadPaginasFlujo(): void {
    if(this.cantidadRegistros > 0) {
      this.cantidadPaginas = Math.ceil(this.cantidadRegistros / this.currentItem);
    }
    if(this.cantidadPaginas > 0) {
      this.getPage(1);
    } else {
      this.seeTableUsuarios = true;
      this.seeTableServicios = false;
      this.seeForm = false;
      this.seeAgregaRut = false;
      this.seeCargaMasiva = false;
      this.spinner.hide();
    }

  }

  getPage(event: number, forzar: boolean = true): void {
    this.paginasActualesErrores = [];

    if(this.cantidadRegistros > 0) {
      const elemento: any = this.paginasActuales.find(e => e.pagina == (event))

      if(forzar || !elemento){
        if(forzar)
          this.paginasActuales = [];
  
        if(event == 1){
          const paginas: number[] = [];
          for(let i = 0; i < 3; i++){
            const pagina = event + i;
            if(pagina > 0 && pagina <= this.cantidadPaginas){
              paginas.push(pagina);
            }
          }
          this.obtenerPaginas(paginas, event);
        } else if(event > 1 && event <= this.cantidadPaginas) {
          const pagina: number = event;
          const paginas: number[] = [pagina - 1, pagina];
          if(event + 1 <= this.cantidadPaginas)
            paginas.push(pagina + 1);
          this.obtenerPaginas(paginas, event);
        }
      } else {
        this.paginaActual = elemento?.response || [undefined];
      }
    } else {
      this.seeTableUsuarios = true;
      this.seeTableServicios = false;
      this.seeForm = false;
      this.seeAgregaRut = false;
      this.seeCargaMasiva = false;
      this.spinner.hide();
    }

    this.currentPage = event; // se actualiza la pagina actual
  }

  obtenerPaginas(paginas:  number[], paginaActual: number): void {
    const apiServices: any = [];
    if(this.cantidadPaginas > 0 && paginas.length > 0) {
      paginas.forEach(pagina => {
        if(pagina > 0 && pagina <= this.cantidadPaginas && !this.paginasActuales.some(e => e?.pagina == pagina)) {

          apiServices.push(
            {
              service: this.getServicePagina(pagina, this.currentItem),
              pagina: pagina
            }
          );

        }
      });

      // se quitan elementos que no se desean consultar
      this.paginasActuales = this.paginasActuales.filter(item =>
        paginas.includes(item.pagina) && 
        item.pagina > 0 && 
        item.pagina <= this.cantidadPaginas
      );

    }
    if(apiServices.length > 0) {
      this.spinner.show();
      this.llamadaObtenerPaginas(apiServices, paginaActual);
    } else {
      this.seeTableUsuarios = true;
      this.seeTableServicios = false;
      this.seeForm = false;
      this.seeAgregaRut = false;
      this.seeCargaMasiva = false;
      this.spinner.hide();
    }
  }

  setError(pagina: number, error: string): void {
    this.paginasActualesErrores.push({
      pagina: pagina,
      error: error
    })
  }

  setResponse(response: any, pagina: number): void {
    this.paginasActuales.push({
      pagina: pagina,
      response: this.agregaDisplayRutReturn(response?.UserList) || null
    });
    this.obtenerListaServiciosHeaderByArrray(response?.UserList);
    this.obtenerCantidadPaginas(response?.UserListTotalElements); // actualiza la cantidad de paginas por si hay cambios
  }

  agregaDisplayRutReturn(listaUsuario: any[]): any[] {
    listaUsuario.forEach((element: any) => {
      element.displayRut = (element.rut + "-" + element.dv) || '';
    });
    return listaUsuario;
  }

  obtenerListaServiciosHeaderByArrray(listaUsuario: any[]): void {
    if(listaUsuario && listaUsuario.length > 0) {
      listaUsuario.forEach(usuario => {
        usuario?.services?.services?.forEach((servicio: any) => {
          if(servicio?.display?.includes('usuarios')){
            if(!(this.serviciosHeader.some(e => e.name === servicio?.name))){
              this.serviciosHeader.push({name: servicio?.name, displayName: servicio?.displayName})
            }
          }
        });
      });
    }
  }

  getServicePagina(pagina: number, cantidadElementos: number): any {
    return this.credencialesService.listarSecretosOrgyUsuarios(pagina, cantidadElementos)
      .pipe(
        map(resp => {
          this.setResponse(resp, pagina);
        })
      )
      .pipe(
        catchError((error) => (this.setError(pagina, error?.error?.message || 'Error Inesperado en servicio'), of(null))));
  }

  llamadaObtenerPaginas(apiServices: any[], paginaActual: number): void {
    const services = apiServices.map(e => e?.service);

    forkJoin(services).subscribe(
      resp => {
        if(this.paginasActualesErrores.length > 0) { // valida si hay errores y si estan repetidos para mostrarlos
          const mensajesMostrados: Set<string> = new Set();
      
          this.paginasActualesErrores.forEach(e => {
            const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas consultadas';
            if (!mensajesMostrados.has(mensaje)) {
              this.alertService.error(mensaje);
              mensajesMostrados.add(mensaje);
            }
          });
        }

        const elemento: any = this.paginasActuales.find(e => e.pagina == (paginaActual))
        this.paginaActual = elemento?.response || [undefined];
        this.seeTableUsuarios = true;
        this.seeTableServicios = false;
        this.seeForm = false;
        this.seeAgregaRut = false;
        this.seeCargaMasiva = false;
        this.spinner.hide();
      },
      error => {
        this.alertService.error(error.message || 'Ocurrió un error al consultar por las paginas');
        this.seeTableUsuarios = true;
        this.seeTableServicios = false;
        this.seeForm = false;
        this.seeAgregaRut = false;
        this.seeCargaMasiva = false;
        this.spinner.hide();
      }
    );
  }

  cambioPaginacion(): void {
    this.obtenerCantidadPaginasFlujo();
  }

  obtenerElementByRut(): void {
    this.spinner.show();
    this.credencialesService.listarSecretosOrgyUsuarios(undefined, undefined, RUT.applyBackendFormat(this.inputTextUsuario)).subscribe(
      (data: any) => {
        const listaUsuario = this.agregaDisplayRutReturn(data?.UserList || []);
        const rutEncontrado = listaUsuario.find(e => e.displayRut === RUT.applyBackendFormat(this.inputTextUsuario.replace("-", "")))
        if(rutEncontrado){
          this.seleccionarUsuario(rutEncontrado);
          this.spinner.hide();
        } else {
          this.alertService.error('No se ha encontrado el rut indicado.');
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al buscar el rut indicado.');
        this.spinner.hide();
    });
  }

  buscarByRut(): void {
    // validar que sea rut
    if(this.inputTextUsuario && rutValidate(this.inputTextUsuario)){
      this.obtenerElementByRut();
    } else {
      this.alertService.error('Debe ingresar un rut válido');
    }
  }

  validaPermisosAdmin(): boolean {
    if(this._sessionService.getUserAccess().includes(this.access[0]))
      return true
      
    return false
  }

  validaPermisosSolicitudes(): boolean {
    if(this._sessionService.getUserAccess().includes(this.accessSolicitudes[0]))
      return true

    return false
  }

  validaPermisosSolicitarCred(): boolean {
    if(this._sessionService.getUserAccess().includes(this.accessSolicitudes[1]))
      return true

    return false
  }

  // Funcion para usar vista desde modal
  obtenerFromModal(): void {
    this.ultimosDatos = [];
    this.classButtonValidation = 'btn-light';

    this.spinner.show();
    this.credencialesService.listarSecretosOrgyUsuarios(undefined, undefined, RUT.applyBackendFormat(this.rutEntrada)).subscribe(
      (data: any) => {
        this.listaUsuario = data?.UserList || [];
        this.agregaDisplayRut();
        const rutEncontrado = this.listaUsuario.find(e => e.displayRut === RUT.applyBackendFormat(this.rutEntrada.replace("-", "")))
        if(rutEncontrado){ // validar
          this.usuarioSeleccionado = rutEncontrado;
          const serviciosEncontrados = this.usuarioSeleccionado?.services?.services.filter((e: any) => this.credsNameEntrada?.includes(e.name));
          if(serviciosEncontrados.length > 0){ // validar

            if(serviciosEncontrados.length === 1) { // validar
              const servicioEncontrado = serviciosEncontrados[0];
              this.servicioSeleccionado = servicioEncontrado;
              this.activateRutHelperService = {};
              this.activateRutHelper = false;
              this.initForm();
              this.generarUltimosDatos();
              this.seeTableUsuarios = false;
              this.seeTableServicios = false;
              this.seeAgregaRut = false;
              this.seeCargaMasiva = false;
              this.seeForm = true;
              this.spinner.hide();
            } else {
              this.usuarioSeleccionado.services.services = serviciosEncontrados;
              this.seeTableUsuarios = false;
              this.seeForm = false;
              this.seeAgregaRut = false;
              this.seeCargaMasiva = false;
              this.validaDisplay();
              this.filtrarObjetoServicio();
              this.seeTableServicios = true;
              this.spinner.hide();
            }

          } else {
            this.actualizaMensaje.emit({alertS: false, alertE: true, mensaje: 'No se ha encontrado el servicio indicado.'});
            this.seeTableUsuarios = false;
            this.seeTableServicios = false;
            this.seeForm = false;
            this.seeAgregaRut = false;
            this.seeCargaMasiva = false;
            this.spinner.hide();
          }
        } else {
          this.initFormRut();
          this.seeTableUsuarios = false;
          this.seeTableServicios = false;
          this.seeAgregaRut = true;
          this.seeCargaMasiva = false;
          this.seeForm = false;
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.actualizaMensaje.emit({alertS: false, alertE: true, mensaje: (error.message || 'Ha ocurrido un error al obtener el listado.')});
        this.seeTableUsuarios = false;
        this.seeTableServicios = false;
        this.seeForm = false;
        this.seeAgregaRut = false;
        this.seeCargaMasiva = false;
        this.spinner.hide();
    });
  }

  agregaDisplayRut(): void {
    this.listaUsuario.forEach((element: any) => {
      element.displayRut = (element.rut + "-" + element.dv) || '';
    });
  }

  filtrarObjetoServicio(){
    this.currentPageServicio = 0;
    this.tablaServiciosFiltrado = this.inputTextServicio != '' ? this.usuarioSeleccionado?.services?.services?.filter((item: any) => 
    item?.displayName?.toUpperCase().includes(this.inputTextServicio.toUpperCase()) 
    || item?.name?.toUpperCase().includes(this.inputTextServicio.toUpperCase())) : (this.usuarioSeleccionado?.services?.services || []);
  }

  seleccionarUsuario(usuario: any): void {
    this.usuarioSeleccionado = usuario;
    this.getServiciosSolicitud(JSON.parse(JSON.stringify(this.usuarioSeleccionado)));
    this.validaDisplay();
    this.filtrarObjetoServicio();
    this.seeTableUsuarios = false;
    this.seeTableServicios = true;
    this.seeForm = false;
  }

  normalizaNombre(nombre: string): string {
    let nombreNormalizado = nombre?.trim() || '';
    nombreNormalizado = nombreNormalizado.replace(/_/g, " ")
    nombreNormalizado = nombreNormalizado.toLocaleLowerCase();
    nombreNormalizado = nombreNormalizado.charAt(0).toUpperCase() + nombreNormalizado.slice(1);
    return nombreNormalizado;
  }

  validaDisplay(): void {
    if(this.usuarioSeleccionado?.services?.services){
      this.usuarioSeleccionado.services.services = this.usuarioSeleccionado?.services?.services?.filter((element: any) => element?.display?.indexOf("usuarios") > -1);
    }
  }

  obtenerOrgAccess(atributos: any[]): boolean | '' {
    const orgAccess = atributos?.find(e => e.name === 'orgAccess');
    if(orgAccess)
      return orgAccess.value || '';
    else 
      return '';
  }

  cambioDeCampoServicio(campo: string): void{
    if(campo != this.campoOrderServicio){
      this.orderServicio = '';
    }
    if((this.orderServicio === '' || this.orderServicio === 'desc') && campo === this.campoOrderServicio){
      this.orderServicio = 'asc';
    } else if((this.orderServicio === 'asc') && campo === this.campoOrderServicio){
      this.orderServicio = 'desc';
    }
    this.campoOrderServicio = campo;
    this.filtrarColumna(this.campoOrderServicio);
  }

  filtrarColumna(campo: string){
    if(this.orderServicio === '' || this.orderServicio === 'asc'){
      this.tablaServiciosFiltrado.sort((a, b) => {
        if(campo === 'orgAccess'){
          const orgAccessA = this.obtenerOrgAccess(a?.attributes).toString();
          const orgAccessB = this.obtenerOrgAccess(b?.attributes).toString();
          return orgAccessA.localeCompare(orgAccessB)
        } else {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return textA.localeCompare(textB);
        }
      });
    } else {
      this.tablaServiciosFiltrado.sort((a, b) => {
        if(campo === 'orgAccess'){
          const orgAccessA = this.obtenerOrgAccess(a?.attributes).toString();
          const orgAccessB = this.obtenerOrgAccess(b?.attributes).toString();
          return (-1 * orgAccessA.localeCompare(orgAccessB));
        } else {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return (-1 * textA.localeCompare(textB));
        }
      });
    }
  }

  configurarServicio(servicio: any): void {
    this.servicioSeleccionado = servicio;
    this.activateRutHelperService = {};
    this.showGuardar = false;
    this.initForm();
    this.generarUltimosDatos();
    this.seeTableUsuarios = false;
    this.seeTableServicios = false;
    this.seeForm = true;
  }

  initForm(): void {
    this.configurarForm = this.formBuilder.group({});
    const atributos: any[] = this.servicioSeleccionado?.attributes || [];

    for(const [index, atributo] of atributos.entries()){
      if(atributo?.display === true) {
        const validatorsArray: any[] = [Validators.required];
        if(atributo.typeFormat === 'RUT'){
          validatorsArray.push(gtpRutValidator());
          atributo.value = this.usuarioSeleccionado.rut + "-" + this.usuarioSeleccionado.dv;
          this.configurarForm.addControl(atributo?.name, new UntypedFormControl({value: this.usuarioSeleccionado.rut + "-" + this.usuarioSeleccionado.dv, disabled: true}, validatorsArray));
        } else {
          this.configurarForm.addControl(atributo?.name, new UntypedFormControl(atributo?.value || '', validatorsArray));
        }
      }
    }
  
    this.eventForm();
  }

  eventForm() {
    const atributos: any[] = this.servicioSeleccionado?.attributes || [];
    for(const [index, atributo] of atributos.entries()){
      if(atributo?.display === true && atributo.typeFormat === 'RUT') {
        this.configurarForm.get(atributo?.name)?.valueChanges.subscribe(val => {
          this.activateRutHelperService[index] = rutValidate(val) ? false : true;
        });
      }
    }
  }

  onSubmit(): void{
    if (!this.configurarForm.valid) {
      this.utilsService.validateAllFormFields(this.configurarForm);
      if(this.rutEntrada === '' && this.credsNameEntrada.length === 0) {
        this.alertService.error('Complete los campos para crear/actualizar el servicio.');
      }
      return;
    }

    const formTemp = this.configurarForm.getRawValue();

    this.servicioSeleccionado?.attributes?.forEach((element: any) => {
      if(element?.display === true){
        if(element?.typeFormat === 'BOOLEAN'){
          element.value = formTemp?.[element.name] == 'true' ? true : formTemp?.[element.name] == 'false' ? false : formTemp?.[element.name];
        } else {
          element.value = formTemp?.[element.name] || element.value;
        }
      }
    });

    const request = JSON.parse(JSON.stringify(this.servicioSeleccionado))
    if(request){
      delete request.configurationStatus;
      delete request.displayName;
    }

    for(const element of request?.attributes) {
      if(element?.display === true && element.typeFormat === 'RUT' && element.value){
        if(element.value.replace(/\./g, '').replace(/\-/g, '') !== this.usuarioSeleccionado.rut + this.usuarioSeleccionado.dv){
          this.alertService.error('Se ha detectado un rut diferente en el formulario.');
          return;
        }
      }
    }

    this.spinner.show();
    this.credencialesService.crearActualizarCredenciales(this.usuarioSeleccionado?.displayRut, [request]).subscribe(
      (data: any) => {
        if(this.rutEntrada === '' && this.credsNameEntrada.length === 0) {
          this.alertService.success(data.message || 'Se ha creado/actualizado correctamente el servicio.')
          this.spinner.hide();
          this.volverTableServicio();
        } else {
          if(this.credsNameEntrada.length > 1){
            // Funcion para usar vista desde modal
            this.actualizaMensaje.emit({alertS: true, alertE: false, mensaje: (data.message || 'Se ha creado/actualizado correctamente el servicio.')});
            this.spinner.hide();
            setTimeout(() => {
              this.actualizaMensaje.emit({alertS: false, alertE: false, mensaje: ''});
            }, 3000);
            this.volverTableServicioModalR();
          } else {
            this.actualizaMensaje.emit({alertS: true, alertE: false, mensaje: (data.message || 'Se ha creado/actualizado correctamente el servicio.')});
            this.spinner.hide();
          }
        }
      },
      ({ error }) => {
        if(this.rutEntrada === '' && this.credsNameEntrada.length === 0) {
          this.alertService.error(error.message || 'Ha ocurrido un error al consultar crear/actualizar servicio.');
          this.spinner.hide();
        } else {
          // Funcion para usar vista desde modal
          this.actualizaMensaje.emit({alertS: false, alertE: true, mensaje: (error.message || 'Ha ocurrido un error al consultar crear/actualizar servicio.')});
          this.spinner.hide();
        }
    });

  }

  obtenerListaRut(): void {
    this.spinner.show();
    this.credencialesService.listarSecretosOrgyUsuarios(undefined, undefined, this.usuarioSeleccionado?.rut + "-" + this.usuarioSeleccionado?.dv).subscribe(
      (data: any) => {
        this.ultimosDatos = [];
        this.classButtonValidation = 'btn-light';

        this.listaUsuario = data?.UserList || [];
        
        this.agregaDisplayRut();
        const dvSeleccionado = this.usuarioSeleccionado?.dv;
        const rutSeleccionado = this.usuarioSeleccionado?.rut;

        const newUsuarioSeleccionado = this.listaUsuario.find(e => e?.dv === dvSeleccionado && e?.rut === rutSeleccionado);

        if(newUsuarioSeleccionado) {
          this.usuarioSeleccionado = newUsuarioSeleccionado;
          this.getServiciosSolicitud(JSON.parse(JSON.stringify(this.usuarioSeleccionado)));
          
          this.validaDisplay();
  
          this.filtrarObjetoServicio();
  
          this.seeTableUsuarios = false;
          this.seeForm = false;
          this.seeAgregaRut = false;
          this.seeCargaMasiva = false;
          this.seeTableServicios = true;
          this.seeSolicitudesRut = false;
          this.seeSolicitud = false;
          this.spinner.hide();
        } else {
          this.seeTableUsuarios = true;
          this.seeTableServicios = false;
          this.seeForm = false;
          this.seeAgregaRut = false;
          this.seeCargaMasiva = false;
          this.seeSolicitudesRut = false;
          this.seeSolicitud = false;
          this.usuarioSeleccionado = {};
          
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al obtener el listado.');
        this.seeTableUsuarios = false;
        this.seeTableServicios = true;
        this.seeForm = false;
        this.seeAgregaRut = false;
        this.seeCargaMasiva = false;
        this.seeSolicitudesRut = false;
        this.seeSolicitud = false;
        this.spinner.hide();
    });
  }

  // Implica una recarga de los datos
  volverTableServicioModalR(): void {
    this.seeTableUsuarios = false;
    this.seeTableServicios = false;
    this.seeAgregaRut = false;
    this.seeCargaMasiva = false;
    this.seeForm = false;
    this.obtenerFromModal();
  }

  volverTableServicioModal(): void {
    this.actualizaMensaje.emit({alertS: false, alertE: false, mensaje: ''});
    this.seeTableUsuarios = false;
    this.seeAgregaRut = false;
    this.seeCargaMasiva = false;
    this.seeForm = false;
    this.seeTableServicios = true;
    this.servicioSeleccionado = {}
  }

  volverTableServicio(): void {
    this.obtenerListaRut();
    this.servicioSeleccionado = {}
  }

  volverTableUsuario(): void {
    this.obtenerCantidadElementos();
    this.usuarioSeleccionado = {};
  }

  agregarRut(): void {
    this.initFormRut();
    this.seeTableUsuarios = false;
    this.seeTableServicios = false;
    this.seeForm = false;
    this.seeAgregaRut = true;
    this.seeCargaMasiva = false;
  }

  cargaMasiva(): void {
    this.seeTableUsuarios = false;
    this.seeTableServicios = false;
    this.seeForm = false;
    this.seeAgregaRut = false;
    this.seeCargaMasiva = true;
  }

  volverAgregarRut(): void {
    this.clearFormRut();
    this.obtenerCantidadElementos();
  }

  initFormRut(): void {
    this.activateRutHelper = false; // validar
    this.agregarRutForm = this.formBuilder.group({rut: [ this.rutEntrada || '', [Validators.required, gtpRutValidator()]],
    }) as FormGroupTyped<RutFormValue>;
    this.eventFormRut();
  }

  eventFormRut() {
    this.agregarRutForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  clearFormRut(): void {
    this.agregarRutForm.reset();
    this.activateRutHelper = false;
  }

  casoRutNoDuplicado(rut: string | number, rutValue: string, dv: string | number): void {
    this.spinner.show();
    this.credencialesService.obtenerContrato().subscribe(
      (data: any) => {
        if(data?.services.length > 0){
          this.usuarioSeleccionado = {
            "idOrganizacion": this._sessionService.getOrganization(),
            "rut": rut,
            "displayRut": rutValue,
            "dv": dv,
            "services": data || {}
          }

          if(this.usuarioSeleccionado?.services && Object.keys(this.usuarioSeleccionado?.services).length > 0){
            this.usuarioSeleccionado.services.idUsuario = this._sessionService.getUserInfo().username;
            this.usuarioSeleccionado.services.idOrganizacion = this._sessionService.getOrganization();
            this.usuarioSeleccionado.services.rut = rut;
            this.usuarioSeleccionado.services.requestOrigin = "WEB";
          }

          this.getServiciosSolicitud(JSON.parse(JSON.stringify(this.usuarioSeleccionado)));
          
          if(this.rutEntrada === '' && this.credsNameEntrada.length === 0){
            this.seeTableUsuarios = false;
            this.seeForm = false;
            this.seeAgregaRut = false;
            this.seeCargaMasiva = false;
            this.validaDisplay();
            this.filtrarObjetoServicio();
            this.seeTableServicios = true;
            this.spinner.hide();
          } else {
            // Funcion para usar vista desde modal
            const serviciosEncontrados = this.usuarioSeleccionado?.services?.services.filter((e: any) => this.credsNameEntrada?.includes(e.name));
            if(serviciosEncontrados.length > 0){

              if(serviciosEncontrados.length === 1) {
                const servicioEncontrado = serviciosEncontrados[0];
                this.servicioSeleccionado = servicioEncontrado;
                this.initForm();
                this.generarUltimosDatos();
                this.seeTableUsuarios = false;
                this.seeTableServicios = false;
                this.seeAgregaRut = false;
                this.seeCargaMasiva = false;
                this.seeForm = true;
                this.spinner.hide();
              } else {
                this.usuarioSeleccionado.services.services = serviciosEncontrados;
                this.seeTableUsuarios = false;
                this.seeForm = false;
                this.seeAgregaRut = false;
                this.seeCargaMasiva = false;
                this.validaDisplay();
                this.filtrarObjetoServicio();
                this.seeTableServicios = true;
                this.spinner.hide();
              }

            } else {
              if(this.rutEntrada === '' && this.credsNameEntrada.length === 0) {
                this.alertService.error('No se ha encontrado el servicio indicado.');
              } else {
                // Funcion para usar vista desde modal
                this.actualizaMensaje.emit({alertS: false, alertE: true, mensaje: ('No se ha encontrado el servicio indicado.')});
              }
              this.spinner.hide();
            }
          }
        } else {
          if(this.rutEntrada === '' && this.credsNameEntrada.length === 0) {
            this.alertService.error('Se ha obtenido una lista vacia en el contrato');
          } else {
            // Funcion para usar vista desde modal
            this.actualizaMensaje.emit({alertS: false, alertE: true, mensaje: ('Se ha obtenido una lista vacia en el contrato')});
          }
          this.spinner.hide();
        }
      },
      ({ error }) => {
        if(this.rutEntrada === '' && this.credsNameEntrada.length === 0) {
          this.alertService.error(error.message || 'Ha ocurrido un error al obtener el listado.');
        } else {
          // Funcion para usar vista desde modal
          this.actualizaMensaje.emit({alertS: false, alertE: true, mensaje: (error.message || 'Ha ocurrido un error al obtener el listado.')});
        }
        this.spinner.hide();
    });

  }

  casoRutDuplicado(): void {
    if(this.rutEntrada === '' && this.credsNameEntrada.length === 0) {
      this.alertService.error('El rut ingresado ya se encuentra en la lista de usuarios.');
    } else {
      // Funcion para usar vista desde modal
      this.actualizaMensaje.emit({alertS: false, alertE: true, mensaje: 'El rut ingresado ya se encuentra en la lista de usuarios.'});
    }
  }

  onSubmitRut(): void {
    if (!this.agregarRutForm.valid) {
      this.utilsService.validateAllFormFields(this.agregarRutForm);
      if(this.rutEntrada === '' && this.credsNameEntrada.length === 0) {
        this.alertService.error('Ingrese un rut valido.');
      }
      return;
    }

    if(this.agregarRutForm?.value?.rut){
      const rutValue = RUT.applyBackendFormat(this.agregarRutForm?.value?.rut);
      const rutArray = rutValue.split('-');
      const rut = rutArray[0] || '';
      const dv = rutArray [1] || '';

      this.spinner.show();
      this.credencialesService.listarSecretosOrgyUsuarios(undefined, undefined, rutValue).subscribe(
        (data: any) => {
          if(data?.UserList && data?.UserList.length > 0){
            this.spinner.hide();
            this.casoRutDuplicado();
          } else {
            this.spinner.hide();
            this.casoRutNoDuplicado(rut, rutValue, dv);
          }
        },
        ({ error }) => {
          this.alertService.error(error.message || 'No se ha logrado consultar por rut indicado');
          this.spinner.hide();
      });

    }

  }


  validaCredenciales(): void {
    const button = this.validaCredencialesButton?.first?.nativeElement;
    
    if(button){

      if(this.ultimosDatos.length === 0){
        this.alertService.error('No se logra conseguir los campos para validar.');
        return;
      }

      if(!this.servicioSeleccionado?.requestValidationCreds && !(Object.keys(this.servicioSeleccionado?.requestValidationCreds).length > 0)){
        this.alertService.error('No se logra conseguir los campos para validar.');
        return;
      }

      if (!this.configurarForm.valid) {
        this.utilsService.validateAllFormFields(this.configurarForm);
        if(this.credsNameEntrada.length === 0) {
          this.alertService.error('Complete los campos para crear/actualizar el servicio.');
        }
        return;
      }

      const request: any = {};
      const formTemp: any = this.configurarForm.getRawValue();

      for(const [key, value] of Object.entries(this.servicioSeleccionado?.requestValidationCreds)){
        request[key] = "";
        const element = this.servicioSeleccionado?.attributes?.find((e: any) => e.name === value);
        if(value && element?.typeFormat === 'RUT' && formTemp?.[value as string]){
          // quitar . y - del rut
          const rut = formTemp?.[value as string].replace(/\./g, '').replace(/\-/g, '');
          if(rut !== (this.usuarioSeleccionado.rut + this.usuarioSeleccionado.dv)) {
            this.alertService.error('Se ha detectado un rut diferente en el formulario.');
            return;
          }
        }
      }

      const requestOriginal = JSON.parse(JSON.stringify(request));

      for(const [key, value] of Object.entries(this.servicioSeleccionado?.requestValidationCreds)){
        request[key] = "";
        requestOriginal[key] = "";
        const element = this.servicioSeleccionado?.attributes?.find((e: any) => e.name === value);
        if(value && formTemp?.[value as string]) {
          if(element?.typeFormat === 'BOOLEAN'){
            request[key] = formTemp?.[value as string] == 'true' ? true : formTemp?.[value as string] == 'false' ? false : formTemp?.[value as string];
          } else {
            request[key] = formTemp?.[value as string];
          }
          requestOriginal[key] = formTemp?.[value as string];
        }
      }

      let validacionRequest = true;
      for(const [key, value] of Object.entries(request)){
        if(value === '' || value === null || value === undefined){
          validacionRequest = false;
        }
      }
      const service = this.servicioSeleccionado?.name;

      if(validacionRequest){
        this.actualizaUtimosDatos(requestOriginal);
        this.spinner.show();
        this.credencialesService.validarCredencialesDinamico(request, service).subscribe(
          (data: any) => {
            if ( button?.classList?.contains('btn-light') === true ){
              button.classList.remove('btn-light');
              button.classList.add('btn-success');
              button.disabled = true;
              button.title = (data.message || 'Se ha creado/actualizado correctamente el servicio.');
              this.classButtonValidation = 'btn-success';
              this.showGuardar = true;
            }
            
            this.spinner.hide();
          },
          ({ error }) => {
            const mensaje: string = error.message ? error.message : error && typeof(error) == 'string' ? error : 'Ha ocurrido un error al consultar la validacion de credenciales.'; 
            if(button) {
              if ( button?.classList?.contains('btn-light') === true ){
                button.classList.remove('btn-light');
                button.classList.add('btn-danger');
                this.classButtonValidation = 'btn-danger';
                button.disabled = true;
                button.title = (mensaje);
              }
            } else {
              if(this.credsNameEntrada.length === 0) {
                this.alertService.error(mensaje);
              } else {
                this.actualizaMensaje.emit({alertS: false, alertE: true, mensaje: (mensaje)});
              }
            }
  
            this.spinner.hide();
        });        
      }


    }
  }

  validaDisabled(): boolean {
    const button = this.validaCredencialesButton?.first?.nativeElement;
    const formTemp = this.configurarForm.getRawValue();
    let response = true;

    for(const element of this.ultimosDatos) {
      const elementForm = this.servicioSeleccionado?.requestValidationCreds[element.name];
      if(elementForm){
        if(element.value !== formTemp?.[elementForm]){
          response = false;
        }
      }
    }

    if(response === false){

      this.showGuardar = false;

      if ( button?.classList?.contains('btn-danger') === true ){
        button.classList.remove('btn-danger');
        button.classList.add('btn-light');
      }
      if ( button?.classList?.contains('btn-success') === true ){
        button.classList.remove('btn-success');
        button.classList.add('btn-light');
      }
      return response;
    } else if (response === true) {
      if ( button?.classList?.contains('btn-danger') === true )
        button.classList.remove('btn-danger');
      if ( button?.classList?.contains('btn-success') === true )
        button.classList.remove('btn-success');
      if ( button?.classList?.contains('btn-light') === true )
        button.classList.remove('btn-light');
      
      if(button?.classList)
        button.classList.add(this.classButtonValidation);

      if(this.classButtonValidation === 'btn-success'){
        this.showGuardar = true;
      }

      return response;
    }

    return response;
  }

  validaInputRut(servicioName: string): boolean {
    if(this.credencialesService.inputRut.includes(servicioName)){
      return true;
    }
    return false;
  }

  validaUserAdmin(): boolean {
    if (this._sessionService.getUserAccess().includes(this.access[2])){
      return true
    }
    return false
  }

  borrarServicio(servicio: any): void {
    if(servicio?.name && this.usuarioSeleccionado?.displayRut){
      this.spinner.show();
      this.credencialesService.borrarCredenciales(this.usuarioSeleccionado?.displayRut, servicio?.name).subscribe(
        (data: any) => {
          this.alertService.success(data.message || 'Secreto eliminado exitosamente.');
          this.spinner.hide();
          this.volverTableServicio();
        },
        ({ error }) => {
          this.alertService.error(error.message || 'Ha ocurrido un error al borrar las credenciales.');
          this.spinner.hide();
      });
    }
  }

  goToSolicitudes(): void {
    this.router.navigate(['/solicitudes-credenciales']);
  }

  verSolicitud(): void {
    this.seeTableServicios = false;
    this.seeSolicitud = true;
    this.initFormSolicitud();
  }

  initFormSolicitud(): void {
    this.solicitudForm = this.formBuilder.group({});
    // Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)    ---> viejo validador de email
    this.solicitudForm.addControl("email", new UntypedFormControl('', [Validators.required, Validators.email]));
    this.solicitudForm.addControl("servicioSolicitud", new UntypedFormControl('', Validators.required));

    const dvSeleccionado = this.usuarioSeleccionado?.dv;
    const rutSeleccionado = this.usuarioSeleccionado?.rut;
    const newUsuarioSeleccionado = this.listaUsuario.find(e => e?.dv === dvSeleccionado && e?.rut === rutSeleccionado);

    if(this.servicios.length == 0 && newUsuarioSeleccionado?.services?.services && newUsuarioSeleccionado?.services?.services.length > 0){
      for(const servicio of newUsuarioSeleccionado?.services?.services){        
        if(servicio?.name && servicio?.display && servicio?.display?.includes('usuarios-ext-sync')){
          this.servicios.push({value: servicio?.name, text: servicio?.displayName || servicio?.name})
        }
      }
    }

    this.eventFormSolicitud();
  }

  eventFormSolicitud() {
    this.solicitudForm.valueChanges.subscribe();
  }

  clearFormSolicitud(): void {
    this.solicitudForm.reset();
  }

  onSubmitSolicitud(): void {
    // const emailValid = this.solicitudForm.get('email')?.valid;
    // const servicioValid = this.solicitudForm.get('servicioSolicitud')?.valid;

    // console.log(emailValid, servicioValid);
    if (!this.solicitudForm.valid) {
      this.alertService.error('Formulario de envío inválido.');
      return;
    }
    const formTemp = this.solicitudForm.getRawValue();

    // consulta servicio

    this.spinner.show();
    this.credencialesExternasService.solicitarCredencial(`${this.usuarioSeleccionado.rut}-${this.usuarioSeleccionado.dv}`, formTemp?.email, formTemp?.servicioSolicitud).subscribe(
      (data: any) => {
        if(data?.DatosBasicosSolicitud?.EstadoTransaccion && (data?.DatosBasicosSolicitud?.EstadoTransaccion === 'IN-PROGRESS' || 
            data?.DatosBasicosSolicitud?.EstadoTransaccion === 'COMPLETED')) {

          this.alertService.success("Solicitud enviada con exito.");
          this.spinner.hide();

          if(this.validaPermisosSolicitudes()) {
            setTimeout(() => {
              this.goToSolicitudes();
            }, 1000);
          }

        } else {

          this.alertService.error('Ha ocurrido un error al solicitar las credenciales.');
          this.spinner.hide();

        }
      },
      ({ error }) => {
        this.alertService.error(error?.error?.message || 'Ha ocurrido un error al solicitar las credenciales.');
        this.spinner.hide();
    });

  }

  verSolicitudes(): void {
    this.seeTableServicios = false;
    this.seeSolicitudesRut = true;
  }

  getServiciosSolicitud(usuario: any): void {
    this.servicios = [];
    if(usuario?.services?.services && usuario?.services?.services.length > 0){
      for(const servicio of usuario?.services?.services){        
        if(servicio?.name && servicio?.display && servicio?.display?.includes('usuarios-ext-sync')){
          this.servicios.push({value: servicio?.name, text: servicio?.displayName || servicio?.name})
        }
      }
    }
  }

  typeTraduccion(type: string): string {
    switch(type){
      case 'STRING':
        return 'text';
      case 'BOOLEAN':
        return 'boolean';
      case 'PASSWORD':
        return 'password';
      case 'RUT':
        return 'text';
      case 'DATE':
        return 'date';
      case 'NUMBER':
        return 'number';
      default:
        return 'text';
    }
  }

  toggleTypePassword(id: string): void {
    const element = document.getElementById(id);
    if(element){
      if (element.getAttribute('type') === 'password') {
        element.setAttribute('type', 'text');
      } else {
        element.setAttribute('type', 'password');
      }
    }
  }

  generarUltimosDatos(): void {
    if(this.servicioSeleccionado?.onlineValidationCreds === true && this.servicioSeleccionado?.requestValidationCreds && Object.keys(this.servicioSeleccionado?.requestValidationCreds).length > 0){
      
      if(!this.servicioSeleccionado?.attributes && !(this.servicioSeleccionado?.attributes.length >= 0) ){
        return
      }
      const requestValidationCreds = this.servicioSeleccionado?.requestValidationCreds;
      const atributos: any[] = this.servicioSeleccionado?.attributes || [];

      for(const [key, value] of Object.entries(requestValidationCreds)){
        const atributo = atributos.find(e => e.name === value);
        if(atributo){
          this.ultimosDatos.push({name: key, value: atributo?.value});
        }
      }

    }
  }

  actualizaUtimosDatos(request: any): void {
    if(request && Object.keys(request).length > 0){
      for(const [key, value] of Object.entries(request)){
        const ultimoDato = this.ultimosDatos.find(e => e.name === key);
        if(ultimoDato){
          ultimoDato.value = value;
        }
      }
    }
  }

  validaShowGuardar(): boolean {
    if(this.showGuardar === true || this.servicioSeleccionado?.onlineValidationCreds === false)
      return true;
    
    return false;
  }

}
