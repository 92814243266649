import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdministracionCarteraApiService } from '../../services/administracion-cartera-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BatchExecution } from '../../models/batch';

@Component({
  selector: 'app-exec-detail-modal',
  templateUrl: './exec-detail-modal.component.html',
  styleUrls: ['./exec-detail-modal.component.scss']
})
export class ExecDetailModalComponent {
  
  processId: string = '';
  correctExecutions = 0;
  errorExecutions = 0;
  downloadFiles: string[] = []


  constructor(
    public dialogRef: MatDialogRef<ExecDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string, configId: string },
    private _apiService: AdministracionCarteraApiService,
    private _spinner: NgxSpinnerService,
  ) {
    this.getDetail(data.id, data.configId);
  }

  getDetail(id: string, configId: string) {
    this._spinner.show();
    this._apiService.getExecutionDetail(id, configId).subscribe(
      (res: any) => {
        this._spinner.hide();
        const execution: BatchExecution = res as BatchExecution;

        this.processId = execution.id;
        this.correctExecutions = execution.amountOfSucessBatchLines;
        this.errorExecutions = execution.amountOfBatchLinesWithError;

        if(execution?.result?.successSignedUrls) {
          this.downloadFiles = this.downloadFiles.concat(execution?.result?.successSignedUrls);
        }
        if(execution?.result?.errorSignedUrls) {
          this.downloadFiles = this.downloadFiles.concat(execution?.result?.errorSignedUrls);
        }

        console.log(this.downloadFiles);
      },
      (error) => {
        this._spinner.hide();
        console.log(error);
      }
    );
  }

  showDownloads = false;
  async downloadAll() {
    this.showDownloads = true;
    
  }

  downloadFile(file:string) {
    window.open(file, "_blank");
  }

  onClose() {
    this.dialogRef.close();
  }
}

