<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<ng-container *ngIf="hasUserAccess">

    <div *ngIf="!showReporte">
        <div class="container__form">
            <div class="col-md-6">
                <div class="block">
                    <form [formGroup]="searchMallaForm" (ngSubmit)="buscarReporte()">
                        <div class="form-horizontal form-bordered">
                            <div class="form-group" [ngClass]="{'has-error': activateRutHelper}">
                                <label class="col-md-3 control-label" for="example-text-input">Rut</label>
                                <div class="col-md-6">
                                    <input formControlName="rut" type="text" class="form-control" formatRut required>
                                    <span *ngIf="activateRutHelper" class="help-block">Rut no valido</span>
                                </div>
                            </div>
                            <div class="form-group form-actions container__actions">
                                <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i>
                                    Buscar Reporte</button>
                                <button type="button" (click)="clearForm()" class="btn btn-sm btn-warning"><i class="fa fa-repeat"></i>
                                    Limpiar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    
    <div class="mb-5" *ngIf="showReporte">
    
        <div class="row">
            <div class="container-gtp mb-5">
                <img class="banner-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt=""/>
                <h2 class="text-center pt-3">Malla Societaria</h2>
                <h5 class="text-center">
                    <span class="badge bg-secondary text-center">
                        <span *ngIf="rut">
                            {{rut | rutFormat | uppercase}}
                        </span>
                    </span>
                </h5>
            </div>
    
            <div class="col-12">
                <div class="w-95 p-5 bg-white">
                    <button type="button" class="btn btn-primary" (click)="backToSearchForm()">Volver</button>
    
                    <button type="button" *ngIf="validaPermisoCrear()" title="Actualizar/crear socios y sociedades del rut principal" class="btn btn-info float-right" (click)="crearReport(rut)">Actualizar</button>
                    
                    <button [printTitle]='"MallaSocietaria"'
                    [useExistingCss]="true" printSectionId="print-section-malla" ngxPrint
                    class="btn btn-primary float-right mr-3">Descargar PDF</button>

                    <span class="badge badge-pill badge-success float-right mt-3 mr-3" *ngIf="fechaReporte">{{(fechaReporte) ? (fechaReporte | date: 'dd-MM-yyyy H:mm:ss') : '' }}</span>
    
                    <app-reporte-malla-societaria-view [reporteMalla]="dataOrg" [rut]="rut" [buscar]="false" *ngIf="seeMalla"></app-reporte-malla-societaria-view>

                </div>
            </div>
            
        </div>
    
    </div>

</ng-container>
