import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { rutValidate } from 'rut-helpers';
import { SessionService } from 'src/app/shared/services/session.service';
import { AlertService } from 'src/app/components/_alert';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { RUT } from 'src/app/shared/utils/rut';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment as env } from 'src/environments/environment';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ResultSearchModel } from 'src/app/models/reportes/reportes.model';
import { Subscription } from 'rxjs';
import { VehiculosService } from 'src/app/shared/services/vehiculos.service';
import { IReporteVehiculos, IVehiculosModel } from 'src/app/models/vehiculos/vehiculos.model';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

interface vehiculosFormValues {
  rut: string;
}

@Component({
  selector: 'app-vehiculos-buscar',
  templateUrl: './vehiculos-buscar.component.html',
  styleUrls: ['./vehiculos-buscar.component.scss']
})
export class VehiculosBuscarComponent implements OnInit {
  public title = 'Buscar'
  private access: string[] = [ESystemAccess.REPORTE_VEHICULOS_BUSCAR, ESystemProfileList.WEB];
  public hasUserAccess = false;

  public searchVehiculosForm: UntypedFormGroup;
  public activateRutHelper = false;

  public searchVehiculosDataSource: IVehiculosModel[] = [];
  public listaReportesFiltrado: any[] = [];
  public showReport = false;
  public vehiculosContent!: IReporteVehiculos | null;

  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public resultSearch: ResultSearchModel = new ResultSearchModel();
  private subscriptions: Subscription[] = [];
  public rut: string = '';
  public today: string = '';

  public inputText = '';
  public order = '';
  public campoOrder = '';
  public mapeoEstados: any = {
    "IN-PROGRESS": "En Progreso",
    "COMPLETED": "Completado",
    "ERROR": "Error"
  }
  
  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private _sessionService: SessionService,
    private vehiculosService: VehiculosService,
    private formBuilder: UntypedFormBuilder,
    private utilsService: UtilsService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title) {
    this.searchVehiculosForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
    }) as FormGroupTyped<vehiculosFormValues>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
    this.setToday();
  }

  setToday() {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  onChanges(): void {
    this.searchVehiculosForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  filtrarObjeto(): void {
    this.currentPage = 0;
    this.listaReportesFiltrado = this.inputText != '' ? this.searchVehiculosDataSource.filter((item: any) => 
    item?.IDTransaccion?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.EstadoTransaccionMapeada?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.FechaFormatted?.toUpperCase().includes(this.inputText.toUpperCase())) : this.searchVehiculosDataSource;
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      if(campo === 'FechaReporte'){
        this.listaReportesFiltrado.sort((a, b) => {
          const fechaA: any = new Date(a.FechaReporte);
          const fechaB: any = new Date(b.FechaReporte);
          return (this.utilsService.dateIsValid(fechaA) ? fechaA : new Date("1000")) - (this.utilsService.dateIsValid(fechaB) ? fechaB : new Date("1000"));
        });
      } else {
        this.listaReportesFiltrado.sort((a, b) => {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return textA.localeCompare(textB);
        });
      }
    } else {
      if(campo === 'FechaReporte'){
        this.ordenarDescFecha();
      } else {
        this.listaReportesFiltrado.sort((a, b) => {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return (-1 * textA.localeCompare(textB));
        });
      }
    }
  }

  ordenarDescFecha(): void {
    this.listaReportesFiltrado.sort((a, b) => {
      const fechaA: any = new Date(a.FechaReporte);
      const fechaB: any = new Date(b.FechaReporte); 
      return (this.utilsService.dateIsValid(fechaB) ? fechaB : new Date("1000")) - (this.utilsService.dateIsValid(fechaA) ? fechaA : new Date("1000"));
    }); 
  }
  
  actualizarTabla(): void {
    if(this.rut){
      this.searchReport(this.rut);
    }
  }

  public buscarReporte(): void {
    const { rut } = this.searchVehiculosForm.value;
    this.rut = rut;
    if (this.searchVehiculosForm.valid) {
      this.searchReport(this.rut);

    } else {
      this.alertService.warn("Rut no valido");
    }
  }

  public backToSearchForm(): void {
    this.searchVehiculosDataSource = [];
  }

  public searchReport(rut: string): void {
    this.spinner.show();
    this.subscriptions.push(this.vehiculosService.buscarReportes(RUT.applyBackendFormat(rut)).subscribe(
      (data: any) => {
        this.spinner.hide();
        data.length > 0?  (        
        this.searchVehiculosDataSource = data.map((item: any) => {
          const newItem = {
            ...item.datosBasicosSolicitud,
            FechaFormatted: this.utilsService.formatDateHMS(item.datosBasicosSolicitud['FechaReporte']),
            EstadoTransaccionMapeada: item.datosBasicosSolicitud['EstadoTransaccion'] ? this.mapeoEstados[item.datosBasicosSolicitud['EstadoTransaccion']] : item.datosBasicosSolicitud['EstadoTransaccion'],
          }
          return newItem 
        }),
        this.filtrarObjeto(),
        this.ordenarDescFecha(),
        this.showReport = false) : 
        this.alertService.warn('Transacción sin Información en Reporte Financiero');
      },
      ({ error }) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }));
  }

  public displayReport(idTransaccion: string) {
    this.spinner.show();
    this.subscriptions.push(this.vehiculosService.obtenerReporte(RUT.applyBackendFormat(this.rut), idTransaccion)
    .subscribe(
      (data) => {
        this.spinner.hide();        
        if (data.vehiculoReporte.vehiculos.listadoVehiculos.length === 0) {
          this.alertService.warn('Cliente sin Información en Vehiculos');
        } else {
          this.vehiculosContent = data;
          this.showReport = true;
        }
      },
      ({error}) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    ));
  }


  public closeReport(): void {
    this.vehiculosContent = null;
    this.showReport = false;
  }

  public clearForm(): void {
    this.searchVehiculosForm.reset();
    this.activateRutHelper = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
