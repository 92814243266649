import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { AlertService } from 'src/app/components/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { BackendAuthService } from './backend-auth.service';
import { catchError, delay, retry, retryWhen, scan, shareReplay, take, tap } from 'rxjs/operators';

interface IGeneratePDFResponse {
  TipoDeReporte: string;
  IDTransaccionReporte: string;
  PDFSolicitudCreacion: boolean;
  PDFCreado: string;
  FechaSolicitudCreacion: string;
  URLReporte: string;
  URLPreview: string;
}

export interface IGetPDFResponse {
  TipoDeReporte: string;
  PDFCreado: boolean;
  IDTransaccionReporte: string;
  URLReporte: string;
  FechaSolicitudCreacion: string;
  PDFSolicitudCreacion: boolean;
  URLPreview: string;
}


@Injectable({
  providedIn: 'root'
})
export class PdfGeneratorService {
  private endpoint: string = env.backend.pdfGenerator;

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private _backendAuthService: BackendAuthService,
  ) { }

  public _generatePDF(idTransaccion: string, tipoReporte: string, subTipoReporte: string = '', idOrganizacion: string = ''): Observable<IGeneratePDFResponse> {

    const payload: any = { tipoReporte, idTransaccion }

    if(subTipoReporte && idOrganizacion) {
      payload.subTipoReporte = subTipoReporte;
      payload.idOrganizacion = idOrganizacion;
    }

    return this.http.post<IGeneratePDFResponse>(this.endpoint + "/crearPDF", payload);
  }

  public _getPDF(idTransaccion: string, tipoReporte: string): Observable<IGetPDFResponse> {

    let params = new HttpParams();
    params = params.append("tipoReporte", tipoReporte);
    params = params.append("idTransaccion", idTransaccion);


    return this.http.get<IGetPDFResponse>(this.endpoint + "/obtenerPDF", { params }).pipe(
      tap((respuesta) => {
        if (!respuesta.PDFCreado || !respuesta.PDFSolicitudCreacion) {
          throw new Error('Pdf no recuperado');
        }
      }),
      retryWhen((errors) =>
        errors.pipe(
          scan((retryCount, err) => {
            if (retryCount >= 20) {
              throw err;
            }
            return retryCount + 1;
          }, 0),
          delay(env.waitTimes.downloadPdf)
        )
      ),
      catchError((err) => {
        console.error(err);
        return throwError('Todos los intentos han fallado');
      })
    );
  }

  public async downloadReportSelected(transactionId: string, tipoReporte: string, sleepTime = 1000, timesToCheck = 20): Promise<IGetPDFResponse | null> {
    this.spinner.show();

    try {
      await this._generatePDF(transactionId, tipoReporte).toPromise()
    } catch (err) {
      console.error(err);
      this.alertService.error("Hubo un error al generar el PDF");
      this.spinner.hide();
      return null;
    }

    try {
      const contentPDF = await this._getPDF(transactionId, tipoReporte).toPromise();
      if (contentPDF.PDFCreado && contentPDF.PDFSolicitudCreacion) {
        this.spinner.hide();
        return contentPDF;
      }
    } catch (err) {
      console.error(err);
      this.alertService.error("Hubo un error al generar el PDF");
      this.spinner.hide();
      return null;
    }
    return null;
  }
}


