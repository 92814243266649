import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { AlertService } from 'src/app/components/_alert';
import { ESsystemUserProfile, ESystemOrganizacion, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { debounceTime, map, distinctUntilChanged, filter } from "rxjs/operators";
import { fromEvent } from 'rxjs';
//import { SimpleModalService } from 'ngx-simple-modal';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AvailableAdminAccess, OrganizacionesService } from 'src/app/shared/services/organizaciones.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { RUT } from 'src/app/shared/utils/rut';

interface IAlertContent {
  message: string;
  success: boolean;
}

interface organizacionCreateFormValues {
  newOrganization: string;
}

interface Group {
  group: string;
  display: string;
  accesos: AvailableAdminAccess[];
}

@Component({
  selector: 'app-crear-organizacion',
  templateUrl: './crear-organizacion.component.html',
  styleUrls: ['./crear-organizacion.component.scss']
})
export class CrearOrganizacionComponent implements OnInit, AfterViewInit {
  @ViewChild('organizacionSearchInput') organizacionSearchInput!: ElementRef;
  
  private access: string[] = [ESsystemUserProfile.USERUNIQUE, ESystemProfileList.WEB];
  private accessOrg: string[] = ESystemOrganizacion.ORGANIZACION_ACCESS;
  public hasUserAccess = false;

  public accessGroups: Group[] = [];
  public isLoaded = false;
  private alertContent: IAlertContent = { success: false, message: "" };

  public organizacionCreateForm: UntypedFormGroup;
  public submitValid: boolean = false;
  public textFileMessage: string = '';
  public tempFile!: any;

  constructor(
    private router: Router,
    public alertService: AlertService,
    private utilsService: UtilsService,
    private _sessionService: SessionService,
    private organizacionesService: OrganizacionesService,
    private formBuilder: UntypedFormBuilder,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService) {

    this.organizacionCreateForm = this.formBuilder.group({
      newOrganization: ['', [Validators.required, Validators.maxLength(120)]],
      description: ['', [Validators.required, Validators.maxLength(200)]],
      rutOrganization: ['', [Validators.required, gtpRutValidator()]],
      displayName: ['', [Validators.required, Validators.maxLength(120)]],
      orgLogoImage: ['']
    }) as FormGroupTyped<organizacionCreateFormValues>;

  }

  ngOnInit(): void {
    
    if (this._sessionService.getUserInfo().email.includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])
    && this.accessOrg.includes(this._sessionService.getOrganization())) {
      this.hasUserAccess = true;
    }
    this.loadBasicResources();
  }

  private loadBasicResources() {
    this.spinner.show();
    this.organizacionesService.getAccessForOrganization().subscribe(
      (data) => {
        this.accessGroups = this.getAccessGroups(data);
        this.spinner.hide();
        this.isLoaded = true;
      },
      ({ error }) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    );
  }

  private getAccessGroups(data: any[]): any[] {
    let result: any = [];
    if(data && data?.length > 0){
      result = Object.values(data.reduce((acc, current) => {
        if (!acc[current.group]) {
          acc[current.group] = {
            group: current.group,
            display: current.display,
            accesos: []
          };
        }
        acc[current.group].accesos.push({
          accessID: current.accessID,
          name: current.name,
          active: current.name === 'CLIENTES-USER-ADMIN' ? true : current.active,
          type: current.type
        });
        
        return acc;
      }, {}));
      result.sort((a: any, b: any) => a.display.localeCompare(b.display));
    }
    return result;
  }

  public createOrganizacion() {
    const organizacionCreateForm = this.organizacionCreateForm.value;
    const msg2 = `¿Esta seguro que desea crear la organización?`;

    if(organizacionCreateForm.rutOrganization && !rutValidate(organizacionCreateForm.rutOrganization)) {
      this.alertService.error('El rut ingresado no es válido');
      return;
    }

    if(organizacionCreateForm.orgLogoImage){
      if(!this.tempFile || Object.keys(this.tempFile).length <= 0) {
        this.alertService.error('No se ha seleccionado un archivo válido');
        return;
      }
    }
    
    if (!this.organizacionCreateForm.valid) {
      this.utilsService.validateAllFormFields(this.organizacionCreateForm);
      return;
    }

    const selectedAccessNames: {name: string, active: boolean}[] = this.accessGroups.reduce(
      (acc: any, { accesos }) => {
        return [...acc, ...accesos.map(({ name, active }) => ({name, active}))];
      },
      []
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Administración de organizaciones',
        message: msg2,
        textBoton: ['Si', 'Cerrar'],
      }
    });

    const request = Object.assign({}, organizacionCreateForm);
    if(organizacionCreateForm.orgLogoImage){
      request.orgLogoImage = this.tempFile;
    } else {
      delete request.orgLogoImage;
    }
    
    const rutForm = this.soloNumerosLetrasString(request.rutOrganization).toUpperCase();
    request.rutOrganization = RUT.applyBackendFormat(rutForm);    

    dialogRef.afterClosed().subscribe(resp => { // validar
      if (resp) {         
        this.spinner.show(); 
        this.organizacionesService.createUser({
          ...request,
          inputServiceConfigurations: selectedAccessNames
        }).subscribe(
          (response) => {
            this.spinner.hide();
            this.alertContent = {
              success: true,
              message: response.message
            }
            this.goBack();
          },
          ({error}) => {
            this.spinner.hide();
            this.alertService.error(error.message);
          }
        )
      }
    });

      /*this.simpleModalService.addModal(ConfirmDialogComponent, {
        title: 'Administración de organizaciones',
        message: msg2,
        textBoton: ['Si', 'Cerrar'],
      }).subscribe((resp) => {
        if (resp) {         
          this.spinner.show(); 
          this.organizacionesService.createUser({
            ...organizacionCreateForm,
            inputServiceConfigurations: selectedAccessNames
          }).subscribe(
            (response) => {
              this.spinner.hide();
              this.alertContent = {
                success: true,
                message: response.message
              }
              this.goBack();
            },
            ({error}) => {
              this.spinner.hide();
              this.alertService.error(error.message);
            }
          )
        }
      });*/

  }

  public clearForm() {
    this.organizacionCreateForm.reset();
    this.tempFile = {};
    this.accessGroups = this.limpiarListaAccesos(this.accessGroups);
  }

  private limpiarListaAccesos(array: any[]): any[] {
    let result = [];
    if(array.length > 0){
      result = array?.map(item => {
        if (item?.accesos.some((acceso: AvailableAdminAccess) => acceso.active === true || acceso.name === 'CLIENTES-USER-ADMIN')) {
          const accesos: any = item.accesos.map((acceso: AvailableAdminAccess) => {
            if (acceso.active === true || acceso.name === 'CLIENTES-USER-ADMIN') {
              return {...acceso, active: acceso.name === 'CLIENTES-USER-ADMIN' ? true : false};
            }
            return acceso;
          });
          return {...item, accesos};
        }
        return item;
      });
    }
    return result;
  }

  public goBack() {
    this.router.navigate(['/organizacion/listar', { alert: JSON.stringify(this.alertContent) }]);
  }

  ngAfterViewInit() {
    fromEvent(this.organizacionSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      ,filter(res => res.length > 3)
      ,debounceTime(1000) 
      ,distinctUntilChanged()

      ).subscribe((text: string) => {
        if (this.organizacionCreateForm.controls.newOrganization.status !== 'VALID') {
          this.utilsService.validateAllFormFields(this.organizacionCreateForm);
          return;
        }
          this.organizacionesService.getsearchOrganizacionExists(text).subscribe(resp => {
            if (resp.existOrgID === 'false') {              
              this.habilitarBloquear('enable');
              this.submitValid = true;
            }

            if (resp.existOrgID === 'true') {
              this.habilitarBloquear('disable');
              this.clearInput();
              this.submitValid = false;
              this.alertService.error('La organización ya existe');
            }

          },
          ({error}) => {
            this.alertService.error(error.message);
          })
        
      });
  }

  private habilitarBloquear(valor: string): void {
    const form = this.organizacionCreateForm;
    const variableKey = ['description'];
    const value = valor === 'disable'? 'disable':'enable';

    for(const item of variableKey) {
      form.controls[item][value]();
    }
  }

  private clearInput(): void {
    const form = this.organizacionCreateForm;
    const variableKey = ['newOrganization', 'description'];

    for(const item of variableKey) {
      form.controls[item].setValue('');
    }
    this.loadBasicResources();
  }

  handleUpload(event: any) {
    this.textFileMessage = '';
    this.tempFile = {};
    const file = event.target.files[0];
    if(!file && !file?.type && !file?.name) {
      return;
    }

    if(!this.validarTipoArchivo(file?.type, file?.name)) {
      this.textFileMessage = 'La extensión del archivo es inválida';
      return;
    }

    if(file.size <= 9000000){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.tempFile = reader?.result?.toString().split(',')[1];
      };
      //this.archivoAtributosCargado = false;
    } else {
      this.textFileMessage = 'El tamaño maximo del archivo debe ser de 9mb';
      this.tempFile = {};
    }
  }

  validarTipoArchivo(type: string, name: string): boolean {
    if (type && name) {
      const extension = name?.split('.')?.pop()?.toLowerCase() || '';
      switch (extension) {
        case 'png':
          if (type !== 'image/png') {
            return false;
          } else {
            return true;
          }
        case 'jpeg':
        case 'jpg':
          if (type !== 'image/jpeg') {
            return false;
          } else {
            return true;
          }
        default:
          return false;
      }
    }
    return false;
  }

  soloNumerosLetrasString(str: string): string {
    return str.replace(/[^a-zA-Z0-9]/g, '');
  }

}
