
<div class="row-container nivel-{{dataSource.nivel}}">
    <div class="nested-table-row">
        <div class="rs-nested-table-cl">{{dataSource.name}}</div>
        
        <div class="type-and-rut">
            <div class="type-rut-column">
                <div class="chip-type chip-color-{{dataSource.tipo}}">
                    {{dataSource.tipo}}
                </div>
            </div>
          <div class="type-rut-column">{{dataSource.rutDisplay}}</div>
        </div>
    
    </div>
    
    <div *ngIf="dataSource.children">
        <div *ngFor="let child of dataSource.children" class="node-child">
            <app-print-nested-table [dataSource]="child"></app-print-nested-table>
        </div>
    </div>
</div>