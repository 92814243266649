import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserInfoCognito } from 'src/app/shared/services/cognito.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { environment } from 'src/environments/environment';
import { Bag } from '../models/bag';
import { BatchProcessRequest, BatchTemplate, Destination, Frequency, Mapper, OrgBatchTemplate } from '../models/batch';

@Injectable({
  providedIn: 'root'
})
export class AdministracionCarteraApiService {

  constructor(
    private _http: HttpClient,
    private _sessionService: SessionService  
  ) { }

  private getHeaders(idOrganizacion: string, idUsuario: string, requestOrigin: string): HttpHeaders {
    const headers = new HttpHeaders()
      .set("IDOrganizacion", idOrganizacion)
      .set("IDUsuario", idUsuario)
      .set("requestOrigin", requestOrigin)

    return headers
  }

  createHttpParams(params: { [key: string]: any }): HttpParams {
    let httpParams = new HttpParams();
  
    Object.keys(params).forEach((key) => {
      if (params[key] !== undefined && params[key] !== null) {
        httpParams = httpParams.set(key, params[key]);
      }
    });
  
    return httpParams;
  }

  public getBagList(): Observable<any> {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      .set('organization', idOrganizacion)

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');
    return this._http.get<any>(environment.backend.administracionCartera.url + 'bags', { params: params, headers: headers  });
  }

  public deleteBag(id: string): Observable<any> {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      .set('organization', idOrganizacion)
      .set('bagId', id);

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.delete<any>(environment.backend.administracionCartera.url + 'bag', { params: params, headers: headers  })
  }

  public createBag(bag: Bag) {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    const body = {
      organization: idOrganizacion,
      requestOrigin: 'WEB',
      file: bag
    }

    return this._http.post<any>(environment.backend.administracionCartera.url + 'bag', body, { headers: headers  });
  }

  public getBagDetail(id: string): Observable<any> {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      .set('organization', idOrganizacion)
      .set('bagId', id);

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.get<any>(environment.backend.administracionCartera.url + 'bag', { params: params, headers: headers  });
  }

  public getBagProcesses(id: string): Observable<any> {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      .set('organization', idOrganizacion)
      .set('bagId', id);

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.get<any>(environment.backend.administracionCartera.url + 'bag/batch-configs', { params: params, headers: headers  });
  }

  public updateBag(bag: Bag, bagId: string) {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    const body = {
      bagId: bagId,
      organization: idOrganizacion,
      requestOrigin: 'WEB',
      file: bag
    }

    return this._http.put<any>(environment.backend.administracionCartera.url + 'bag', body, { headers: headers  });
  }

  public asignBatchConfig(
    bagId: string, 
    frequency: Frequency | undefined, 
    selectedTemplate: OrgBatchTemplate | undefined, 
    mapper: Mapper | undefined, 
    destination: Destination | undefined) {
      const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
      const idUsuario = userInfo.email;
      const idOrganizacion = this._sessionService.getOrganization();

      const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');
      let batchTemplate: BatchTemplate = { tag: selectedTemplate?.batchTemplate?.tag, id: selectedTemplate!.id, description: selectedTemplate?.batchTemplate.description, name: selectedTemplate?.batchTemplate.name}

      const body: BatchProcessRequest = {
        bagId: bagId,
        organization: idOrganizacion,
        user: idUsuario,
        requestOrigin: 'WEB',
        frequency: frequency,
        batchTemplate: batchTemplate,
        mapper: mapper,
        destination: destination
      }

    return this._http.post<any>(environment.backend.administracionCartera.url + 'bag/batch-config', body, { headers: headers  });
  }

  removeBatchConfig(batchConfigId: string, bagId: string) {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    const body = {
      batchConfigId: batchConfigId,
      bagId: bagId,
      organization: idOrganizacion,
    }

    return this._http.delete<any>(environment.backend.administracionCartera.url + 'bag/batch-config', { body: body, headers: headers  })
  }

  listOrgMappers() {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      .set('organization', idOrganizacion)

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.get<any>(environment.backend.batchProcessorExtractConf.url + 'mappers', { params: params, headers: headers  })
  }

  listOrgBatchTemplates() {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      .set('organization', idOrganizacion)

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.get<any>(environment.backend.batchProcessor.url + 'batch-templates/organization', { params: params, headers: headers  })
  }

  listBatchExecutions(filterParams: { [key: string]: any }) {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    filterParams.organization = idOrganizacion;
    const params = this.createHttpParams(filterParams);


    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.get<any>(environment.backend.administracionCartera.url + 'batch-processes', { params: params, headers: headers  })
  }

  getQueryDetail(mapperId: string) {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      .set('organization', idOrganizacion)
      .set('mapperId', mapperId);

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.get<any>(environment.backend.administracionCartera.url + 'mapper', { params: params, headers: headers  })
  }

  getMapperDetail(id: string) {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      .set('organization', idOrganizacion)
      .set('mapperId', id);

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.get<any>(environment.backend.batchProcessorExtractConf.url + 'mapper', { params: params, headers: headers  })
  }

  getExecutionDetail(id: string, configId: string) {
    const userInfo: IUserInfoCognito = this._sessionService.getUserInfo()
    const idUsuario = userInfo.email;
    const idOrganizacion = this._sessionService.getOrganization();

    const params = new HttpParams()
      // .set('organization', idOrganizacion)
      .set('batchProcessId', id)
      .set('batchConfigId', configId);

    const headers = this.getHeaders(idOrganizacion, idUsuario, 'WEB');

    return this._http.get<any>(environment.backend.batchProcessor.url + 'batch-process', { params: params, headers: headers  })
  }
}
