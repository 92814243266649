<div class="row" *ngIf="informacionBasicaJudicial.length > 0 && informacionBasicaJudicial[0].basicReport?.InformacionBasica">
    <ng-container *ngFor="let item of informacionBasicaJudicial">

        <div class="col-sm-6">
            <div class="card">
                <h5 class="card-header list-group-item cuadro-color">
                    Información Basica ( 
                    <ng-template #isEmpty>
                      Sin Información
                    </ng-template>

                    <ng-container *ngIf="item.fechaInformacionBasica; else isEmpty">
                        al {{item.fechaInformacionBasica | date: 'dd-MM-YYYY'}}
                    </ng-container>)
                   
                </h5>
                <div class="card-body">
                    <form>
                        <table class="table">
                            <tbody>
                                <tr>
                                    <th colspan="1" width="40%">Razón Social:</th>
                                    <td colspan="4" width="60%">
                                        <div class="form-control" disabled>
                                            {{item.basicReport.InformacionBasica.NombreORazonSocial}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="1">
                                        <div class="pt-3">
                                            Rut:
                                        </div>
                                    </th>
                                    <td colspan="4">
                                        <div class="form-control" disabled>
                                            {{item.basicReport.InformacionBasica.Rut}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="1">Dirección:</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled>
                                            {{item.basicReport.InformacionBasica.Direccion}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="1">Actividad Economica:</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled>
                                            {{item.basicReport.Riesgo.ActividadEconomica}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="1">Iniciación de Actividades:</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled>
                                            {{item.inicioActividades | date: 'dd-MM-YYYY'}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="1">Cant. Empleados:</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled>
                                            {{item.basicReport.AntecedentesFinancieros.NumeroEmpleados}}
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="!proceso">
                                    <th colspan="1">Tipo de Sociedad:</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="!proceso">
                                    <th colspan="1">Malla Societaria:</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled></div>
                                    </td>
                                </tr>
                                <tr *ngIf="!proceso">
                                    <th colspan="1">Representante Legal:</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled></div>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="1">Tamaño de la empresa:</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled>
                                            {{item.basicReport.AntecedentesFinancieros.TamanoEmpresaDescripcion}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="1">Subsegmento:</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled>
                                            {{item.basicReport.AntecedentesFinancieros.TamanoSubsegmento}}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="1">Rango Facturación:</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled>
                                            {{item.basicReport.AntecedentesFinancieros.TamanoEmpresaRango}}
                                        </div>
                                    </td>
                                </tr>



                                <tr>
                                    <th>

                                        <button type="button" (click)="updateBasicReport()" class="btn btn-sm btn-warning">Actualizar</button>

                                    </th>
                                </tr>

                                <tr *ngIf="!proceso">
                                    <th colspan="1">Report al (Mes - año):</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled></div>
                                    </td>
                                </tr>
                                <tr *ngIf="!proceso">
                                    <th colspan="1">Deuda vigente Bancarios y leasing:</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled></div>
                                    </td>
                                </tr>
                                <tr *ngIf="!proceso">
                                    <th colspan="1">Deuda morosa Bancarios y leasing:</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled></div>
                                    </td>
                                </tr>
                                <tr *ngIf="!proceso">
                                    <th colspan="1">Morosidades empresa (Dicom):</th>
                                    <td colspan="4">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-6 pl-0">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" disabled>$</span>
                                                        </div>
                                                        <div class="form-control border-left-0" disabled></div>
                                                    </div>
                                                </div>
                                                <div class="col-6 pr-0">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" disabled>$</span>
                                                        </div>
                                                        <div class="form-control border-left-0" disabled></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="!proceso">
                                    <th colspan="1">Morosidades Socios (Dicom):</th>
                                    <td colspan="4">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-6" [ngClass]="i === 0? 'pl-0': 'pr-0'"
                                                    *ngFor="let row of item.morosidadSocios; let i = index">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" disabled>$</span>
                                                        </div>
                                                        <div class="form-control border-left-0" disabled>
                                                            {{row.morosidad}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="!proceso">
                                    <th colspan="1">LC bancarias disponibles:</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled></div>
                                    </td>
                                </tr>
                                <tr *ngIf="!proceso">
                                    <th colspan="1">Crédito contingentes:</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled></div>
                                    </td>
                                </tr>
                                <tr *ngIf="!proceso">
                                    <th colspan="1">LC utilizada (Por línea de negocio):</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled></div>
                                    </td>
                                </tr>
                                <tr *ngIf="!proceso">
                                    <th colspan="1">LC aprobada (Total):</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled></div>
                                    </td>
                                </tr>
                                <tr *ngIf="!proceso">
                                    <th colspan="1">LC útil/LC Aprobada (Por línea de negocio):</th>
                                    <td colspan="4">
                                        <div class="form-control" disabled></div>
                                    </td>
                                </tr>
                                <tr *ngIf="!proceso">
                                    <th colspan="1">
                                        <div class="pt-3">
                                            LC Solicitada:
                                        </div>
                                    </th>
                                    <td colspan="4">
                                        <input type="text" class="form-control">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-sm-6">
            <div class="card">
                <h5 class="card-header list-group-item cuadro-color">
                    Información Judicial ( 
                    <ng-template #isEmpty2>
                        Sin Información
                    </ng-template>
                    <ng-container *ngIf="item.fechaJudcial; else isEmpty2">
                        al {{item.fechaJudcial | date: 'dd-MM-YYYY'}}
                    </ng-container>)

                </h5>
                <div class="card-body">
                    <table class="table">
                        <tbody>
                            <tr>
                                <th colspan="1" width="40%">Causas judiciales
                                    (Cantidad de causas laborales, civiles o cobranza) Activas:
                                </th>
                                <td colspan="1" width="60%">
                                    <div class="form-control" disabled>
                                        {{getTotal((item.poderJudicial?.Totales?.nCausasCivil) || 0, 
                                            (item.poderJudicial?.Totales?.nCausasCobranza) || 0,
                                            (item.poderJudicial?.Totales?.nCausasLaboral) || 0)}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">
                                    <div class="col-12">
                                    </div>
                                    <div class="col-12 my-3 p-0">
                                        <button type="button" class="btn btn-sm btn-warning" (click)="updatePoderJudicial()">
                                            Actualizar</button>
                                    </div>

                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card mt-5">
                <h5 class="card-header list-group-item cuadro-color">
                    Información Boletín Concursal ( 
                    <ng-template #isEmpty3>
                        Sin Información
                    </ng-template>
                    <ng-container *ngIf="item.fechaBoletinConcursal; else isEmpty3">
                        al {{item.fechaBoletinConcursal | date: 'dd-MM-YYYY'}}
                    </ng-container>)

                </h5>
                <div class="card-body">
                    <table class="table">
                        <tbody>
                            <tr>
                                <th colspan="1" width="40%">Esta en Boletín?</th>
                                <td colspan="1" width="60%">
                                    <div class="form-control" disabled>
                                        {{informacionBoletinConcursal? 'Si': 'No'}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">
                                    <div class="col-12">
                                    </div>
                                    <div class="col-12 my-3 p-0">
                                        <button type="button" class="btn btn-sm btn-warning" (click)="updateBoletinConcursal()">
                                            Actualizar</button>
                                    </div>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="col-12 mt-5">
            <div class="form-group form-actions container__actions">
                <button type="submit" class="btn btn-sm btn-primary" (click)="continuar()">Continuar</button>
            </div>
        </div>
    </ng-container>
</div>