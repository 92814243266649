import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/components/_alert';
import { rutValidate } from 'rut-helpers';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { IPoderJudicialCreateReportResponse } from 'src/app/interface/IPoderJudicialCreateReportResponse';
import { DatosBasicosSolicitud } from 'src/app/interface/IPoderJudicialSearchReportResponse';
import { RUT } from 'src/app/shared/utils/rut';
import { ReporteJudicialService } from 'src/app/shared/services/reporte-judicial.service';
import { PdfGeneratorService } from 'src/app/shared/services/pdf-generator.service';
import { environment as env } from 'src/environments/environment';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ResultSearchModel } from 'src/app/models/reportes/reportes.model';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';



interface PoderJudicialReportFormValues {
  rut: string;
}

@Component({
  selector: 'app-reporte-judicial-buscar',
  templateUrl: './reporte-judicial-buscar.component.html',
  styleUrls: ['./reporte-judicial-buscar.component.scss']
})
export class ReporteJudicialBuscarComponent implements OnInit {
  public activateRutHelper = false;
  private access: string[] = [ESystemAccess.REPORTE_PODERJUDICIAL_BUSCAR, ESystemProfileList.WEB];
  public hasUserAccess = false;

  public poderJudicialReportForm: FormGroup;
  public showReport = false;
  public searchReportDataSource: DatosBasicosSolicitud[] = [];
  public listaReportesFiltrado: any[] = [];

  public poderJudicialReportDataSource: IPoderJudicialCreateReportResponse | null = null;

  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public resultSearch: ResultSearchModel = new ResultSearchModel();

  public inputText = '';
  public order = '';
  public campoOrder = '';
  public mapeoEstados: any = {
    "IN-PROGRESS": "En Progreso",
    "COMPLETED": "Completado",
    "ERROR": "Error"
  }

  constructor(
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private _poderJudicialService: ReporteJudicialService,
    private _generatePDFService: PdfGeneratorService,
    private _sessionService: SessionService,
    private formBuilder: UntypedFormBuilder,
    private utilsService: UtilsService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {
    this.poderJudicialReportForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]]
    }) as FormGroupTyped<PoderJudicialReportFormValues>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }
  }

  onChanges(): void {
    this.poderJudicialReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  filtrarObjeto(): void {
    this.currentPage = 0;
    this.listaReportesFiltrado = this.inputText != '' ? this.searchReportDataSource.filter((item: any) => 
    item?.IDTransaccion?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.EstadoTransaccionMapeada?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.FechaFormatted?.toUpperCase().includes(this.inputText.toUpperCase())) : this.searchReportDataSource;
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      if(campo === 'FechaReporte'){
        this.listaReportesFiltrado.sort((a, b) => {
          const fechaA: any = new Date(a.FechaReporte);
          const fechaB: any = new Date(b.FechaReporte);
          return (this.utilsService.dateIsValid(fechaA) ? fechaA : new Date("1000")) - (this.utilsService.dateIsValid(fechaB) ? fechaB : new Date("1000"));
        });
      } else {
        this.listaReportesFiltrado.sort((a, b) => {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return textA.localeCompare(textB);
        });
      }
    } else {
      if(campo === 'FechaReporte'){
        this.ordenarDescFecha();
      } else {
        this.listaReportesFiltrado.sort((a, b) => {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return (-1 * textA.localeCompare(textB));
        });
      }
    }
  }

  ordenarDescFecha(): void {
    this.listaReportesFiltrado.sort((a, b) => {
      const fechaA: any = new Date(a.FechaReporte);
      const fechaB: any = new Date(b.FechaReporte); 
      return (this.utilsService.dateIsValid(fechaB) ? fechaB : new Date("1000")) - (this.utilsService.dateIsValid(fechaA) ? fechaA : new Date("1000"));
    }); 
  }

  actualizarTabla(): void {
    if(this.resultSearch.rut){
      this.consultaBuscarReportes(this.resultSearch.rut);
    }
  }

  consultaBuscarReportes(rut: string): void {
    this.spinner.show();
    this._poderJudicialService.searchReportsList(RUT.applyBackendFormat(rut))
    .subscribe(
      (data) => {

        this.searchReportDataSource = data.map(e => e['datosBasicosSolicitud'])
          .map(item => {

            const newItem = {
              ...item,
              FechaFormatted: this.utilsService.formatDateHMS(item['FechaReporte']),
              EstadoTransaccionMapeada: item['EstadoTransaccion'] ? this.mapeoEstados[item['EstadoTransaccion']] : item['EstadoTransaccion'],
            }
            return newItem
          })
          .filter((f) => f.EstadoTransaccion === 'COMPLETED');


        if (!data || data.length === 0) {
          this.alertService.warn("Rut sin Información");
        } else {

          this.filtrarObjeto();
          this.ordenarDescFecha();

          let razon = '';

          for (const objKey of Object.keys(data[0])) {
            const valor = this.utilsService.getNested(data[0], objKey, 'NombreORazonSocial');
            razon = valor ? valor : razon;
          }

          this.resultSearch = new ResultSearchModel(razon, rut);
        }


        this.clearForm();
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    )
  }

  public searchReport(): void {
    const { rut } = this.poderJudicialReportForm.value;

    if (!rutValidate(rut)) {
      this.alertService.warn("El rut no es valido");
      return;
    }

    this.consultaBuscarReportes(rut);
  }

  public displayReport(idTransaccion: string): void {
    this.spinner.show();
    this._poderJudicialService.getReport(idTransaccion)
      .subscribe(
        (data) => {
          this.poderJudicialReportDataSource = data;
          this.showReport = true;
          this.spinner.hide();
        },
        ({ error }) => {
          this.alertService.error(error.message);
          this.spinner.hide();
        }
      )
  }

  public async downloadReportSelected(): Promise<void> {
    const transactionId = this.poderJudicialReportDataSource?.poderJudicialReporte.datosBasicosSolicitud.IDTransaccion || '';
    const tipoReporte = "poderJudicial";

    const contentPDF = await this._generatePDFService.downloadReportSelected(transactionId, tipoReporte);
    if (!contentPDF) {
      this.alertService.warn("No se pudo descargar el archivo PDF");
      this.spinner.hide();
      return;
    }

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', contentPDF.URLReporte);
    link.setAttribute('download', `file.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public backToSearchForm(): void {
    this.searchReportDataSource = [];
  }

  public closeReport(): void {
    this.poderJudicialReportDataSource = null;
    this.showReport = false;
  }

  public clearForm(): void {
    this.poderJudicialReportForm.reset();
    this.activateRutHelper = false;
  }

}