<div class="card mt-3">
    <h5 class="card-header list-group-item cuadro-color">
        <div class="d-flex">
            <div class="flex-fill">
                <p class="pt-3">
                    Informe Comercial Deudas
                    (
                    <ng-template #isText>
                        {{fecha.dato}}
                    </ng-template>

                    <ng-container *ngIf="fecha.tipo === 'fecha'; else isText">
                        al {{fecha.dato | date: 'dd-MM-YYYY'}}
                    </ng-container>)
                </p>

            </div>
            <div class="flex-fill">
                <button class="btn btn-primary float-right" (click)="verInforme()">{{showContent? 'Ocultar' :
                    'Ver'}}</button>
            </div>
        </div>
    </h5>
    <div class="card-body">
        <ng-container *ngIf="!showContent; else contenido">
            <div class="row">
                <div class="col-sm-6">
                    <form>
                        <ng-container *ngFor="let data of dataPersonas">

                            <ng-container *ngFor="let persona of data.empresaSocios; let i = index">
                                <ng-template #socios>
                                    <div class="form-group" *ngIf="persona.data.length > 0 ">
                                        <fieldset>
                                            <legend> Seleccionar {{persona.tipoPersona}}</legend>
                                            <div class="row box-scroll">
                                                <div class="col-12" *ngFor="let item of persona.data; let e = index">
                                                    <div class="checkbox-wrapper">
                                                        <input type="checkbox" [name]="item.Nombre" [id]="'socio'+e"
                                                            (change)="onFirstCheckboxChange(item, $event, 'partner')"
                                                            [disabled]="checkUserBloq(item.Rut)">
                                                        <label class="checkbox-label" [for]="'socio'+e"
                                                            [ngClass]="{'disabled': checkUserBloq(item.Rut)}">
                                                            <span class="material-icons">person_pin</span>
                                                            {{item.Nombre}}
                                                            {{item.Rut}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </ng-template>

                                <ng-container *ngIf="i === 0; else socios">
                                    <div class="form-group" *ngIf="persona.data.length > 0">
                                        <fieldset>
                                            <legend> Seleccionar</legend>
                                            <div class="row box-scroll">
                                                <div class="col-12" *ngFor="let item of persona.data; let e = index">
                                                    <div class="checkbox-wrapper">
                                                        <input type="checkbox" [name]="item.NombreORazonSocial"
                                                            [id]="'empresa'+e"
                                                            (change)="onFirstCheckboxChange(item, $event, 'primary')"
                                                            [disabled]="checkUserBloq(item.Rut)">
                                                        <label class="checkbox-label" [for]="'empresa'+e"
                                                            [ngClass]="{'disabled': checkUserBloq(item.Rut)}">
                                                            <span class="material-icons">corporate_fare</span>
                                                            {{item.NombreORazonSocial}}
                                                            {{item.Rut}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </form>
                </div>

                <div class="col-sm-6">
                    <form [formGroup]="formularioGroup" (ngSubmit)="onSubmit()">
                        <fieldset>
                            <legend> Obtener Informe para</legend>
                            <ng-container *ngIf="boxCheck.length > 0; else notCheckBox">
                                <ng-container *ngIf="!informeNuevo; else informeNew">
                                    <div class="box-scroll">
                                        <div *ngFor="let item of boxCheck; let i = index">
                                            <p>
                                                - {{item.Nombre? item.Nombre: item.NombreORazonSocial}}
                                            </p>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-template #informeNew>
                                    <div class="upload mb-5" *ngFor="let person of personFormGroups; let i = index">
                                        <div class="form-group pt-4 mb-3">
                                            <label class="uploadbutton" [formGroup]="person">
                                                <input class="input-file" formControlName="file" type="file"
                                                    (change)="onChange($event, i)" accept=".pdf" />
                                                <div class="button btn">Cargar Nuevo Informe</div>
                                                <div class='input-file-text'
                                                    [ngClass]="{'text-success': person.controls.name.value}">
                                                    {{person.controls.name.value || 'Subir Archivo'}}

                                                </div>
                                                <div *ngIf="person.controls.file.errors" class="text-danger">
                                                    <div *ngIf="person.controls.file.errors.required">
                                                        Archivo es requerido
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>

                            <ng-template #notCheckBox>
                                <p> Sin informacion para obtener informe</p>
                            </ng-template>
                        </fieldset>
                        <div class="col-12 text-center mt-5">
                            <button type="submit" class="btn btn-sm btn-warning mr-2">
                                Obtener Informe
                            </button>
                            <button type="button" class="btn btn-sm btn-primary ml-2" (click)="updateSocios()">
                               Actualizar socios
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </ng-container>

        <ng-template #contenido>
            <ng-container *ngIf="!informeNuevo; else tableInformeNuevo">
                <app-malla-societaria [informe]="informe"></app-malla-societaria>
            </ng-container>
            <ng-template #tableInformeNuevo>
                <div class="row">
                    <ng-container *ngFor="let dataTab of dataTable">
                        <div class="col-sm-6">
                            <app-dynamic-colunm-table [data]='[dataTab]' [divider]='"extraNormal"'
                                [extraHeader]="dataTab.name" [excluir]="['name']" [groupHeader]="'listadoReport'">
                            </app-dynamic-colunm-table>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
        </ng-template>

        <div class="form-group mt-5">
            <div class="row" *ngIf="seeContent">
                <div class="col-sm-6 mx-auto text-center">
                    <button type="button" class="btn btn-sm btn-primary" (click)="changeStep()">
                        Continuar
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>