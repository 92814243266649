import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
//import { rutValidate, RutValidator } from 'ng9-rut';
import { rutValidate } from 'rut-helpers';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SolicitudCreditoReportFormValues } from 'src/app/models/solicitud-credito/solicitud-credito.model';
import { SessionService } from 'src/app/shared/services/session.service';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';


@Component({
  selector: 'app-solicitud-credito-consultar',
  templateUrl: './solicitud-credito-consultar.component.html',
  styleUrls: ['./solicitud-credito-consultar.component.scss']
})
export class SolicitudCreditoConsultarComponent implements OnInit, OnDestroy {
  
  public activateRutHelper = false;
  private access: string[] = [ESystemAccess.SOLICITUD_CREDITO_BUSCAR, ESystemProfileList.WEB];
  public hasUserAccess = false;
  public solicitudCreditoReportForm: UntypedFormGroup;
  public hasInformeAccess: boolean = false;
  public solicitudCredito: boolean = false;
  public rut: string = '';
  private subscriptions: Subscription[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    //private rutValidator: RutValidator,
    private _sessionService: SessionService,
    private fb: UntypedFormBuilder,
    private solicitudCreditoService: SolicitudCreditoService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
    ) {
    this.solicitudCreditoReportForm = this.fb.group({
      rut: ['', [Validators.required, gtpRutValidator()]]
    }) as FormGroupTyped<SolicitudCreditoReportFormValues>;
    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
    }

  }

  onChanges(): void {
    this.subscriptions.push(this.solicitudCreditoReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    }));
  }

  requestReport() {
    const { rut } = this.solicitudCreditoReportForm.value;

    if (!rutValidate(rut)) {
      return;
    }

    this.rut = rut;
    this.solicitudCredito = true;
    this.spinner.show();
  }

  public clearForm(): void {
    this.solicitudCreditoReportForm.reset();
    this.activateRutHelper = false;
  }

  public closeSolicitudCredito(): void {
    this.solicitudCredito = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
