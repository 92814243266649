<div class="row">

    <!--F22-->
    <div class="col-sm-12 col-md-8 mx-auto mt-5">
        <div class="table-responsive">
            <table class="table table-bordered table-hover border my-0">
                <thead>
                    <tr class="bg-nivel1">
                        <th scope="col" colspan="100&" class="text-center align-middle font-size">
                            F22
                        </th>
                    </tr>
                    <tr class="bg-nivel2">
                        <th scope="col" class="text-center align-middle font-size">
                            Rut Empresa
                        </th>
                        <ng-container *ngFor="let item of periodos">
                            <th scope="col" class="text-center align-middle font-size">
                                {{item}}
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="objectKeys(periodosF22).length > 0">
                        <tr *ngFor="let item of objectKeys(periodosF22) | paginate:{id: 'pagination-f22', itemsPerPage: 5, currentPage: currentPageF22}">
                            <td class="font-size align-middle text-nowrap text-center">
                                {{item | rutFormat}}
                            </td>
                            <ng-container *ngFor="let itemPeriodo of periodos">
                                <td class="font-size align-middle text-nowrap text-center">
                                    <ng-container *ngIf="periodosF22[item] && periodosF22[item].includes(itemPeriodo)">
                                        <i class="fa fa-check-square text-success" aria-hidden="true"></i>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>                        
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="p-2">
            <div id="pagination">
                <div class="col-sm-12 col-md-8 col-lg-6 float-right">
                    <pagination-controls id="pagination-f22" (pageChange)="currentPageF22=$event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>

    <!--F29-->
    <div class="col-sm-12 col-md-8 mx-auto mt-5">
        <!--<ng-container *ngIf="objectKeys(periodosF29).length > 0">-->
            <ng-container *ngFor="let rutElement of objectKeys(periodosF29) | paginate:{id: 'pagination-f29', itemsPerPage: 1, currentPage: currentPage}">

                <div class="table-responsive">
                    <table class="table table-bordered table-hover border my-0">
                        <thead>
                            <tr class="bg-nivel1">
                                <th scope="col" colspan="100&" class="text-center align-middle font-size">
                                    F29
                                </th>
                            </tr>
                            <tr class="bg-nivel2">
                                <th scope="col" colspan="100&" class="text-center align-middle font-size">
                                    {{rutElement | rutFormat}}
                                </th>
                            </tr>
                            <tr class="bg-nivel3">
                                <th scope="col" class="text-center align-middle font-size">
                                    Mes/Año
                                </th>
                                <ng-container *ngFor="let item of periodos">
                                    <th scope="col" class="text-center align-middle font-size">
                                        {{item}}
                                    </th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let itemMes of meses">
                                <td class="font-size align-middle text-nowrap">
                                    {{itemMes.nombre}}
                                </td>
                                <ng-container *ngFor="let itemPeriodo of periodos">
                                    <td class="font-size align-middle text-nowrap text-center">
                                        <ng-container *ngIf="validaMesPeriodo(itemPeriodo, itemMes.valor, periodosF29[rutElement])">
                                            <i class="fa fa-check-square text-success" aria-hidden="true"></i>
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </tr>                        
                        </tbody>
                    </table>
                </div>

            </ng-container>
        <!--</ng-container>-->

        <div class="p-2">
            <div id="pagination">
                <div class="col-sm-12 col-md-8 col-lg-6 float-right">
                    <pagination-controls id="pagination-f29" (pageChange)="currentPage=$event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>

</div>
