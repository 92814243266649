import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Location } from '@angular/common';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private location: Location,
    private _sessionService: SessionService
  ) { 
  }

  async ngOnInit(): Promise<void> {
    const ruta = this.location.path();
    this.location.replaceState('/login');
    this.titleService.setTitle(this.route.snapshot.data['title']);
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      if(idToken) {
        const organization = this._sessionService.getOrganization();
        if(organization) {

          if(ruta === '/login') {
            this.router.navigate(['']);
          } else {
            const urlTree = this.router.parseUrl(ruta);
            this.router.navigateByUrl(urlTree);
          }

        } else {
          this.router.navigate(['']);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  
}
