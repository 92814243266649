<div class="row" *ngIf="resumen && informeSocios.length > 0">
    <div class="col-12">
        <div class="col-sm-12 px-0 mb-3">
            <div class="responsive-table">
                <table class="table">
                    <thead>
                        <tr>
                            <th width='30%'>Socios</th>
                            <th width='35%'>Nombre</th>
                            <th width='35%'>Rut</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of informeSocios | paginate:{itemsPerPage: currentItem, currentPage: currentPage}; let i = index">
                            <td>
                                Socio {{i+1}}
                            </td>
                            <td>
                                <div class="form-control" disabled>
                                    {{item.nombre}}
                                </div>
                            </td>
                            <td>
                                <div class="form-control" disabled>
                                    {{item.rut}}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div id="pagination">
                    <div class="col-xs-10">
                        <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                    </div>
                    <div class="col-xs-2 text-right">
                        <app-paginator-by-items [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="resumen">
    <div class="col-12">
        <p class="subTitle text-center"> DICOM M$</p>
    </div>
</div>

<div class="row">
    <div class="col-sm-6" *ngFor="let item of dataEmpresa">
        <div class="responsive-table">
            <table class="table">
                <thead>
                    <tr>
                        <th class="extraHead" width='45%'>{{item.name}}</th>
                        <th class="extraHead" width='55%'>{{item.fecha | date: 'dd-MM-YYYY'}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="1">
                            Score (Historial)
                        </td>
                        <td colspan="4">
                            <div class="form-control" disabled>{{item.score | number: '1.0-0'}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>Morosidades</td>
                        <td colspan="4">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-6" [ngClass]="i === 0? 'pl-0': 'pr-0'"
                                        *ngFor="let morosidades of item.morosidades; let i = index">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" disabled>{{i === 0? '#': 'M$'}}</span>
                                            </div>
                                            <div class="form-control border-left-0" disabled>{{morosidades.morosidad |
                                                number: '1.0-0'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Protestos</td>
                        <td colspan="4">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-6" [ngClass]="i === 0? 'pl-0': 'pr-0'"
                                        *ngFor="let protestos of item.protestos; let i = index">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" disabled>{{i === 0? '#': 'M$'}}</span>
                                            </div>
                                            <div class="form-control border-left-0" disabled>{{protestos.protesto |
                                                number: '1.0-0'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Multas Previsionales</td>
                        <td colspan="4">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-6" [ngClass]="i === 0? 'pl-0': 'pr-0'"
                                        *ngFor="let multas of item.multas; let i = index">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" disabled>{{i === 0? '#': 'M$'}}</span>
                                            </div>
                                            <div class="form-control border-left-0" disabled>{{multas.multa | number:
                                                '1.0-0'}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<ng-container *ngIf="resumen">

    <div class="row">
        <div class="col-12">
            <p class="subTitle text-center"> Report SBIF M$ </p>
        </div>
    </div>

    <div class="row">
        <ng-container *ngFor="let group of informeSbif">
            <div class="col-sm-6">
                <div class="table-responsive">
                    <table class="table  table-bordered">
                        <tbody *ngFor="let obj of group">
                            <tr *ngFor="let item of obj">
                                <th>
                                    {{listadoReport[item[objectKeys(item)[0]].Item]?
                                    listadoReport[item[objectKeys(item)[0]].Item]:
                                    item[objectKeys(item)[0]].Item}}
                                </th>
                                <td>
                                    <ng-template #fechaReport>
                                        <span class="text-capitalize">
                                        {{obtenerFecha() | date:'LLL'}}
                                        {{obtenerFecha() | date:'yyyy'}}
                                    </span>
                                    </ng-template>

                                    <ng-container *ngIf="item[objectKeys(item)[0]].Item !== 'fecha'; else fechaReport">
                                        {{item[objectKeys(item)[0]].Valor | number: '1.0-0'}}
                                    </ng-container>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="row">
        <div class="col-12">
            <p class="subTitle text-center"> Report Leasing M$</p>
        </div>
    </div>

    <ng-container *ngIf="informeLeasing.length > 0">
        <div class="row">
            <ng-container *ngFor="let group of informeLeasing">
                <div class="col-sm-12">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Deuda leasing al (mes - año)</th>
                                    <th *ngFor="let periodo of group.periodos" colspan="2">
                                        <span class="text-capitalize"> {{ periodo | date: 'LLL'}}</span>
                                        {{ periodo | date: 'YYYY'}}
                                    </th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <ng-container *ngFor="let periodo of group.periodos">
                                        <th>UF</th>
                                        <th>CLP M$</th>
                                    </ng-container>

                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let item of objectKeys(group.data)">
                                    <tr>
                                        <td>
                                            {{listadoReport[item] }}
                                        </td>
                                        <ng-container *ngFor="let periodo of group.periodos">
                                            <td>{{filterDataLeasing(group.data, item, periodo)?
                                                (setNumber(filterDataLeasing(group.data, item, periodo)) | number:
                                                '1.0-0'): 0}}</td>
                                            <td>
                                                <ng-template #normalNumers>
                                                    {{filterDataLeasingClp(group.data, item, periodo)?
                                                        (setNumber(filterDataLeasingClp(group.data, item, periodo)) | number:
                                                        '1.0-0'): 0}} 
                                                </ng-template>

                                                <ng-container *ngIf="item !== 'NumerodeAcreedores' && item !== 'PlazoPromedio(Mes)'; else normalNumers">
                                                    {{filterDataLeasingClp(group.data, item, periodo)?
                                                        (setNumber(filterDataLeasingClp(group.data, item, periodo)) / 1000 | number:
                                                        '1.0-0'): 0}} 
                                                </ng-container>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                                <tr>
                                    <td>
                                        Total
                                    </td>
                                    <ng-container *ngFor="let periodo of group.periodos">
                                        <td>{{sumDataLeasing(group.data, periodo, 'Valor') | number:
                                            '1.0-0'}}</td>
                                        <td>{{sumDataLeasing(group.data, periodo, 'valorMiles') / 1000  | number:
                                            '1.0-0'}}</td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-container>