import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error',
  template: `
  <ng-template [ngIf]="!validarValid && control?.errors && control.invalid">
    <div class="w-100 mt-2 error-custom">
      <span class="text-danger font-weight-bolder" *ngIf="control.errors?.required && ((control.dirty || control.touched) || validar) && name">
        * El campo {{name}} es requerido.
      </span>
      <span class="text-danger font-weight-bolder" *ngIf="control.errors?.required && ((control.dirty || control.touched) || validar) && !name">
      * Campo requerido.
      </span>
      <span class="text-danger font-weight-bolder" *ngIf="control.errors?.maxlength">
      * El campo supera el limite de caracteres ({{control.errors?.maxlength?.requiredLength}}).
      </span>

    </div>
  </ng-template>

  <ng-template [ngIf]="validarValid && control?.errors && control.status === 'INVALID'">
    <div class="w-100 mt-2 error-custom">
      <span class="text-danger font-weight-bolder" *ngIf="control.errors?.required && ((control.dirty || control.touched) || validar) && name">
        * El campo {{name}} es requerido.
      </span>
      <span class="text-danger font-weight-bolder" *ngIf="control.errors?.required || (control.dirty || control.touched || control.status === 'INVALID') && !name">
        * Ingrese un valor con el siguiente formato: "{{formato}}".
        </span>
      <span class="text-danger font-weight-bolder" *ngIf="control.errors?.maxlength">
      * El campo supera el limite de caracteres ({{control.errors?.maxlength?.requiredLength}}).
      </span>

  <ng-template [ngIf]="email && control?.errors && control.invalid">
    <div class="w-100 mt-2 error-custom">
      <span class="text-danger font-weight-bolder" *ngIf="!control.errors?.required && control.errors?.email && ((control.dirty || control.touched) || validar) && !name">
      * El campo debe ser una dirección de correo válida.
      </span>
    </div>
  </ng-template>
  `
})
export class ErrorComponent {

  @Input() control: any;
  @Input() name?: string;
  @Input() validar?: boolean;
  @Input() minRequired?: string;
  @Input() validarValid? = false;
  @Input() formato?: string;
  @Input() email?: boolean = false;

  constructor() {
  }

}
