import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';


@Component({
  selector: 'app-linear-gauge-color-level',
  templateUrl: './linear-gauge-color-level.component.html',
  styleUrls: ['./linear-gauge-color-level.component.scss']
})
export class LinearGaugeColorLevelComponent implements OnInit {
  @Input() position = 100;

  public chart: any;

  ngOnInit(): void {
    this.chart = new Chart({
      chart: {
        type: 'lineargauge',
        inverted: true,
        height: 100
      },
      tooltip: {
        enabled: false
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      xAxis: {
        lineColor: '#C0C0C0',
        labels: {
          enabled: false
        },
        tickLength: 0
      },
      yAxis: {
        min: 0,
        max: 100,
        tickLength: 5,
        tickWidth: 1,
        tickColor: '#C0C0C0',
        gridLineColor: '#C0C0C0',
        gridLineWidth: 1,
        minorTickInterval: 5,
        minorTickWidth: 1,
        minorTickLength: 5,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
          format: '{value}%'
        },
        plotBands: [
          {
            label: { text: 'Alto' },
            from: 0,
            to: 20,
            color: '#f44336'
          },
          {
            label: { text: 'Medio' },
            from: 20,
            to: 40,
            color: '#ff9800'
          },
          {
            label: { text: 'Medio Alto' },
            from: 40,
            to: 60,
            color: '#DBD759'
          },
          {
            label: { text: 'Medio Bajo' },
            from: 60,
            to: 80,
            color: '#8FFA7E'
          },
          {
            label: { text: 'Bajo' },
            from: 80,
            to: 100,
            color: '#4CAF50'
          }
        ],
        plotLines: [{
          color: '#FF0000',
          width: 2,
          value: this.position,
          id: 'plotline-1',
          label: {
            text: '▼',
            textAlign: 'center',
            rotation: 0,
            y: -2
          }
        }]
      },
      series: [{
        data: [60],
        type: 'line',
        color: '#000000',
        marker: {
          enabled: false,
          symbol: 'triangle',
          radius: 6,
        },
        dataLabels: {
          enabled: false,
          verticalAlign: 'top',
          align: 'center'
        }
      }]
    });
  }

}
