import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { rutValidate } from 'rut-helpers';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { ReporteService } from 'src/app/shared/services/reporte.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { RUT } from 'src/app/shared/utils/rut';
import { PdfGeneratorService } from 'src/app/shared/services/pdf-generator.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CredencialesService } from 'src/app/shared/services/credenciales.service';
interface BasicReportFormValues {
  rut: string;
  informeComercial: boolean;
}

@Component({
  selector: 'app-reporte-basico',
  templateUrl: './reporte-basico.component.html',
  styleUrls: ['./reporte-basico.component.scss']
})
export class ReporteBasicoComponent implements OnInit {
  public activateRutHelper: boolean = false;
  private access: string[] = [
    ESystemAccess.REPORTEBASICO,
    ESystemAccess.REPORTEBASICO_INFORMECOMERCIAL,
    ESystemProfileList.WEB];
  public hasUserAccess: boolean = false;
  public hasInformeAccess: boolean = false;

  public basicReportForm: UntypedFormGroup;
  public showReport: boolean = false;
  public basicReportDataSource: any = {};
  public rut: string = '';
  public today: string = '';
  public vendor: any = {display: '', key: ''};

  constructor(
    public alertService: AlertService,
    private spinner: NgxSpinnerService,
    private _reporteService: ReporteService,
    private _sessionService: SessionService,
    private _credencialesService: CredencialesService,
    private _generatePDFService: PdfGeneratorService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private formBuilder: UntypedFormBuilder) {
    this.basicReportForm = this.formBuilder.group({
      rut: ['', [Validators.required, gtpRutValidator()]],
      informeComercial: [false, Validators.requiredTrue]
    }) as FormGroupTyped<BasicReportFormValues>;

    this.onChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[2])) {
      this.hasUserAccess = true;
    }
    if (this._sessionService.getUserAccess().includes(this.access[1])) {
      this.hasInformeAccess = true;
    }

    this.setToday();
  }

  onChanges(): void {
    this.basicReportForm.valueChanges.subscribe(val => {
      this.activateRutHelper = rutValidate(val.rut) ? false : true;
    });
  }

  
  setToday() {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  public requestReport() {
    const { rut, informeComercial } = this.basicReportForm.value;
    this.rut = rut;
    const tipoOperacion = informeComercial ? "11" : "10";

    if (!rutValidate(rut)) {
      this.alertService.warn("Rut no valido");
      return;
    }

    if(tipoOperacion === "11" && this.vendor.key === '') {
      this.alertService.warn("No se encontró proveedor de informe comercial");
      return;
    }

    const rutformat = RUT.applyBackendFormat(rut);

    this.spinner.show();
    this._reporteService.createReport(rutformat, tipoOperacion, undefined, this.vendor.key)
      .subscribe(
        (data) => {

          this.basicReportDataSource = data;

          this.showReport = true;
          this.clearForm();
          this.spinner.hide();
        },
        ({ error }) => {
          this.alertService.error(error?.error?.message || error?.message || 'Ha ocurrido un error inesperado al consultar el reporte');
          this.spinner.hide();
        }
      )
  }

  public async downloadReportSelected(): Promise<void> {
    const transactionId = this.basicReportDataSource.InformacionBasica.IDTransaccion;
    const tipoReporte = 'basicReport';

    const contentPDF = await this._generatePDFService.downloadReportSelected(transactionId, tipoReporte);
    if (!contentPDF) {
      this.alertService.warn("No se pudo descargar el archivo PDF");
      return;
    }

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', contentPDF.URLReporte);
    link.setAttribute('download', `file.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public closeReport(): void {
    this.basicReportDataSource = null;
    this.showReport = false;
    this.vendor = {display: '', key: ''};
  }

  public clearForm(): void {
    this.basicReportForm.reset();
    this.activateRutHelper = false;
    this.vendor = {display: '', key: ''};
  }


  informeComercialChange(event: any) {
    const { rut, informeComercial } = this.basicReportForm.value;
    if (!rutValidate(rut)) {
      this.alertService.warn("Rut no valido");
      this.basicReportForm.get('informeComercial')?.setValue(false)
      return;
    }

    if(event.target.checked === true) {
      this.spinner.show();
      this._credencialesService.buscarSecretosInformeComercial()
        .subscribe(
          (data) => {
            if(this.validarTipoRut(rut)){
              this.validacionSecretos('empresa', data?.services || []);
            } else {
              this.validacionSecretos('persona', data?.services || []);
            }
            this.spinner.hide();
          },
          ({ error }) => {
            this.alertService.error(error?.error?.message || error?.message || 'Ha ocurrido un error inesperado al consultar las credenciales configuradas');
            this.basicReportForm.get('informeComercial')?.setValue(false)
            this.spinner.hide();
          }
        )
    } else if (event.target.checked === false) {
      this.vendor = {display: '', key: ''};
    }
  }

  private validarTipoRut(rut: string) { // true es empresa, false es natural
    const body = rut.slice(0, -1);
    if (Number(body) > 50000000) {
      return true;
    }
    return false;
  }

  validacionSecretos(tipo: string, services: any[]) {
    if(services && services.length > 0) {
      const servicesPosibles = ["INFORME_COMERCIAL_TRANSUNION", "MALLA_SOCIETARIA_TRANSUNION", "ICOM_COBRANZA_ONLINE"];
      if(tipo === 'empresa') {
        servicesPosibles.push("ICOM_EQUIFAX");
      } else {
        servicesPosibles.push("PLAT_EQUIFAX");
      }

      const servicesFiltrados = services.filter((service: any) => servicesPosibles.includes(service?.name) && service?.configurationStatus === true);
      if(!(servicesFiltrados && servicesFiltrados.length > 0)){
        this.vendor = {display: '', key: ''};
        this.basicReportForm.get('informeComercial')?.setValue(false)
        this.alertService.error("No posee proveedores de informe comercial definidos, para configurar ir a Configuraciones → Administración de Credenciales → Organizacion, luego de esto se debe desmarcar el checkbox de Informe Comercial");
        return;
      }

      if(servicesFiltrados.length === 1 && servicesFiltrados[0].name === servicesPosibles[3]) {
        this.vendor = {display: servicesFiltrados[0].displayName, key: servicesFiltrados[0].name};

      } else if (servicesFiltrados.length === 2 && servicesFiltrados.some(e => e.name === servicesPosibles[0]) && servicesFiltrados.some(e => e.name === servicesPosibles[1])) { // INFORME_COMERCIAL_TRANSUNION
        const informeComercialTransunion = servicesFiltrados.find((service: any) => service.name === servicesPosibles[0]);
        this.vendor = {display: informeComercialTransunion.displayName, key: informeComercialTransunion.name};

      } else if (servicesFiltrados.length === 2 && servicesFiltrados.some(e => e.name === servicesPosibles[2]) && servicesFiltrados.some(e => e.name === servicesPosibles[1])) { // ICOM_COBRANZA_ONLINE
        const informeComercialTransunion = servicesFiltrados.find((service: any) => service.name === servicesPosibles[2]);
        this.vendor = {display: informeComercialTransunion.displayName, key: informeComercialTransunion.name};

      } else if (servicesFiltrados.length === 2 && 
        (servicesFiltrados.some(e => e.name === servicesPosibles[3])) && 
        (servicesFiltrados.some(e => e.name === servicesPosibles[0] || e.name === servicesPosibles[1] || e.name === servicesPosibles[2]))
      ) {
          
        const informeComercial = servicesFiltrados.find((service: any) => (service.name === servicesPosibles[3]));
        this.vendor = {display: informeComercial.displayName, key: informeComercial.name};

      } else {

        this.vendor = {display: '', key: ''};
        this.basicReportForm.get('informeComercial')?.setValue(false);
        if(servicesFiltrados.some(e => e.name === servicesPosibles[3])){
          this.alertService.error("Posee mas de 1 proveedor configurado, ir a modulo Configuraciones → Administración de Credenciales → Organizacion y dejar solo 1 activo");
        } else if (servicesFiltrados.some(e => e.name === servicesPosibles[0]) && servicesFiltrados.some(e => e.name === servicesPosibles[1]) && servicesFiltrados.some(e => e.name === servicesPosibles[2])) {
          this.alertService.error("Posee mas de 1 proveedor configurado, ir a modulo Configuraciones → Administración de Credenciales → Organizacion y dejar solo 1 activo");
        } else if (servicesFiltrados.some(e => e.name === servicesPosibles[0]) || servicesFiltrados.some(e => e.name === servicesPosibles[1]) || servicesFiltrados.some(e => e.name === servicesPosibles[2])) {
          this.alertService.error("No posee proveedores de informe comercial definidos, para configurar ir a Configuraciones → Administración de Credenciales → Organizacion, luego de esto se debe desmarcar el checkbox de Informe Comercial");
        } else {
          this.alertService.error("Posee mas de 1 proveedor configurado, ir a modulo Configuraciones → Administración de Credenciales → Organizacion y dejar solo 1 activo");
        }
        
      }

    } else {
      this.vendor = {display: '', key: ''};
      this.basicReportForm.get('informeComercial')?.setValue(false)
      this.alertService.error("No posee proveedores de informe comercial definidos, para configurar ir a Configuraciones → Administración de Credenciales → Organizacion, luego de esto se debe desmarcar el checkbox de Informe Comercial");
    }
  }

  limpiarCheckbox(): void {
    if(this.basicReportForm.get('informeComercial')){
      this.basicReportForm.get('informeComercial')?.setValue(false);
      this.vendor = {display: '', key: ''};
    }
  }

}
