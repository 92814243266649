<div class="modal-content">
    <div class="modal-header">
        <h4>{{confirmData.title || 'Confirm'}}</h4>
    </div>
    <div class="modal-body">
        <accordion [isAnimated]="true" [closeOthers]="true">
            <ng-container *ngIf="confirmData.errors && confirmData.errors.length > 0">
                <accordion-group *ngFor="let item of confirmData.errors">
                    <button class="btn btn-link btn-block justify-content-between d-flex w-100  shadow-none"
                        accordion-heading type="button">
                        <span class="pull-left float-left">
                            <div class="close-btn"></div>   
                            <div class="text-field">{{item.campo}}</div>                       
                        </span>
                        <div class="seeMore">Ver Detalles</div>
                        <div class="seeMinus">Ver Menos</div>
                    </button>
                    <ul class="list-unstyled">
                        <li class="pb-3">Campo: {{item.campo}} </li>
                        <li>Detalle: {{item.detalle}} </li>
                    </ul>
                </accordion-group>
            </ng-container>
        </accordion>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="cerrar()">
            Cerrar
        </button>
    </div>
</div>