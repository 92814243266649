import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalEntidadesService } from 'src/app/shared/services/modal-entidades.service';
import { observable, Subscription } from 'rxjs';
//import { rutValidate, RutValidator } from 'ng9-rut';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { SolicitudCreditoServiceWorkflow } from 'src/app/shared/services/solicitud-credito-workflow.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-modal-entidades-workflow',
  templateUrl: './modal-entidades-workflow.component.html',
  styleUrls: ['./modal-entidades-workflow.component.scss']
})
export class ModalEntidadesWorkflowComponent implements OnInit {
  @Input() contrato: any[] = [];
  @Input() entidades: any[] = [];
  @Input() entidadesValidadas: any[] = [];
  @Input() rut: string = '';
  @Input() idTransaccion: string = '';
  private subscriptions: Subscription[] = [];
  public activateRutHelper = false;
  public mostrarFormulario: boolean = true;
  public alertSuccess: boolean = false;
  public alertError: boolean = false;
  public mensajeError: string = 'Se ha producido un error';
  public entidadSeleccionada: string = '';
  public entidadesAIngresar: any[] = [];
  public entidadesAEliminar: any[] = [];
  @Output() contratoAct = new EventEmitter<any>();
  @Output() entidadesAct = new EventEmitter<any>();
  @Output() entidadesValidadasAct = new EventEmitter<any>();
  @Output() entidadesRutAct = new EventEmitter<any>();

  @Output() responseConsultaAct = new EventEmitter<any>();
  @Output() entidadesDerivadasAConsultarAct = new EventEmitter<any>();
  @Output() requestOriginalAct = new EventEmitter<any>();

  constructor(
    private modalService: ModalEntidadesService,
    //private fb: FormBuilder,
    //private rutValidator: RutValidator,
    private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private spinner: NgxSpinnerService,
  ) {
  }


  ngOnInit(): void {
    this.resetModal(true);
  }

  close() {
    this.resetModal(true)
    this.modalService.close();
  }

  agregarEntidadForm(): void{
    if(this.entidadSeleccionada !== ''){
      const indice = this.entidadesAIngresar.findIndex((elemento: any) => elemento.entidad == this.entidadSeleccionada);
      const indiceContrato = this.contrato.findIndex((elemento: any) => elemento.key == this.entidadSeleccionada);

      if(indiceContrato >= 0){
        const atributos: any[] = [];
        const atributosForm: any[] = [];
        const nameEntidad = this.contrato[indiceContrato].name || this.entidadSeleccionada;
        this.contrato[indiceContrato].outputVars.forEach((element: any) => {
          const length = element.length.split("-");
          atributosForm.push({
            ...element,
            value: '',
            minlength: (length[0] || ''),
            maxlength: (length[1] || ''),
            cont: 0
          });

          if(element.name.toUpperCase() !== 'RUT'){
            atributos.push({
              ...element,
              value: '',
            });
          }
        });

        if(indice >= 0){
          this.entidadesAIngresar[indice].items.push({
            rut: '',
            fuente: '',
            atributos: atributos,
            atributosForm: atributosForm
          })
        } else {
          this.entidadesAIngresar.push({
            entidad: this.entidadSeleccionada,
            name: nameEntidad,
            items: [{
              rut: '',
              fuente: '',
              atributos: atributos,
              atributosForm: atributosForm}]
          })
        }

      }
    }
  }

  actualizaValue(indiceEntidad: number, indiceItem: number, indiceAtributo: number, event: any): void{
    this.alertSuccess = false;
    this.alertError = false;
    this.mensajeError = 'Solicitud de Crédito - Se ha producido un error';
    this.entidadesAIngresar[indiceEntidad].items[indiceItem].atributosForm[indiceAtributo].cont = 1;
    this.entidadesAIngresar[indiceEntidad].items[indiceItem].atributosForm[indiceAtributo].value = event.target.value;
  }

  validarRut(indiceEntidad: number, indiceItem: number, indiceAtributo: number): boolean{
    const contador = this.entidadesAIngresar[indiceEntidad]?.items[indiceItem]?.atributosForm[indiceAtributo]?.cont;
    if(contador > 0){
      return rutValidate(this.entidadesAIngresar[indiceEntidad].items[indiceItem].atributosForm[indiceAtributo].value) ? false : true;
    } else {
      return false
    }
  }

  validaRequired(indiceEntidad: number, indiceItem: number, indiceAtributo: number): boolean{
    const contador = this.entidadesAIngresar[indiceEntidad]?.items[indiceItem]?.atributosForm[indiceAtributo]?.cont;
    if(contador > 0){
      return this.entidadesAIngresar[indiceEntidad].items[indiceItem].atributosForm[indiceAtributo].value === '' ? true : false;
    } else {
      return false
    }
  }

  eliminarFormulario(indiceEntidad: number, indiceItem: number): void{
    this.spinner.show();
    this.entidadesAIngresar[indiceEntidad]?.items?.splice(indiceItem, 1);
    if(this.entidadesAIngresar[indiceEntidad]?.items?.length === 0){
      this.entidadesAIngresar?.splice(indiceEntidad, 1);
    }
    this.spinner.hide();
  }

  eliminarEntidad(entidadKey: string, rutEntidad: string, event: any): void{
    if(event.target.checked === true){
      const indice = this.entidadesAEliminar.findIndex((elemento: any) => elemento.entidad === entidadKey);
      const indiceEntidad = this.entidadesValidadas.findIndex((elemento: any) => elemento.entidad === entidadKey);

      if(indiceEntidad >= 0){
        const entidadRut = this.entidadesValidadas[indiceEntidad].items.findIndex((elemento: any) => elemento.rut === rutEntidad);
        if(entidadRut >= 0){
          if(indice >= 0){
            this.entidadesAEliminar[indice].items.push(this.entidadesValidadas[indiceEntidad].items[entidadRut]);
          } else {
            this.entidadesAEliminar.push({
              entidad: entidadKey,
              items: [this.entidadesValidadas[indiceEntidad].items[entidadRut]]
            })
          }
        }


      }
    } else {
      const indice = this.entidadesAEliminar.findIndex((elemento: any) => elemento.entidad === entidadKey);
      if(indice >= 0){
        const indiceEntidad = this.entidadesAEliminar[indice].items.findIndex((elemento: any) => elemento.rut === rutEntidad);
        if(indiceEntidad >= 0){
          this.entidadesAEliminar[indice].items.splice(indiceEntidad, 1);
          if(this.entidadesAEliminar[indice].items.length === 0){
            this.entidadesAEliminar.splice(indice, 1);
          }
        }
      }
    }

  }

  mapeoResponseConsulta(Stage7Response: any): any {
    let respuesta: any = {};
    if(Stage7Response){
      respuesta = JSON.parse(JSON.stringify(Stage7Response));
      if(respuesta.request){
        delete respuesta.request;
      }
    }
    return respuesta;
  }

  eliminar(): void {
    this.spinner.show();
    if(this.entidadesAEliminar.length > 0){
      this.subscriptions.push(this.solicitudCreditoService.agregarBorrarDerivadas(this.rut, this.idTransaccion, false, this.entidadesAEliminar).subscribe(
        resp => {
          this.entidades = resp?.SolicitudDeCredito?.Stage5Response?.Entidades; //listado de entidades
          this.contrato = resp?.SolicitudDeCredito?.Stage5Response?.Contrato;
          this.validacionEntidadesContrato();
          this.entidadesAct.emit(this.entidades);
          this.contratoAct.emit(this.contrato);
          this.entidadesValidadasAct.emit(this.entidadesValidadas);
          this.entidadesRutAct.emit([]);

          const reportesDerivados: any = this.mapeoResponseConsulta(resp?.SolicitudDeCredito?.Stage7Response) || {};
          const entidadesDerivadas: any = resp?.SolicitudDeCredito?.Stage7Response?.request || [];
          const requestOriginal: any = JSON.parse(JSON.stringify(resp?.SolicitudDeCredito?.Stage7Response?.request || []));

          this.responseConsultaAct.emit(reportesDerivados);
          this.entidadesDerivadasAConsultarAct.emit(entidadesDerivadas); // []
          this.requestOriginalAct.emit(requestOriginal); // []

          this.alertSuccess = true;
          this.alertError = false;
          this.spinner.hide();
        }, 
        (error) => {
          this.alertSuccess = false;
          this.alertError = true;
          this.mensajeError = (error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar servicio de eliminar entidades');
          this.spinner.hide();

        }));
    } else {
      this.alertSuccess = false;
      this.alertError = true;
      this.mensajeError = "Solicitud de Crédito - Seleccionar entidades a eliminar";
      this.spinner.hide();
    }
  }

  validaObjectRequired(): boolean{
    let valido = true;
    this.entidadesAIngresar.forEach((entidad: any) => {
      entidad.items.forEach((item: any) => {
        item.atributosForm.forEach((atributo: any) => {
          if(atributo.value === ''){
            valido = false
          } else if (atributo.name.toUpperCase() === 'RUT' && !rutValidate(atributo.value)){
            valido = false;
          } else {
            if(atributo.name.toUpperCase() !== 'RUT'){
              const indice = item.atributos.findIndex((elemento: any) => elemento.name === atributo.name);
              if(indice >= 0){
                item.atributos[indice].value = atributo.value;
              }
            } else {
              if(!atributo.value.includes("-")){
                const lastChar = atributo.value[atributo.value.length - 1];
                const rutCut = atributo.value.substring(0, atributo.value.length - 1);
                item.rut = rutCut + '-' + lastChar;
                const indice = item.atributos.findIndex((elemento: any) => elemento.name === atributo.name);
                if(indice >= 0){
                  item.atributos[indice].value = atributo.value;
                }
              } else {
                item.rut = atributo.value;
              }
            }
          }
        });
      });
    })
    return valido
  }

  agregar(): void{
    this.spinner.show();
    if(this.validaObjectRequired()){
      const obj = JSON.parse(JSON.stringify(this.entidadesAIngresar));

      obj.forEach((entidad: any) => {
        entidad.items.forEach((item: any) => {
          delete item.atributosForm;
        });
      });

      this.subscriptions.push(this.solicitudCreditoService.agregarBorrarDerivadas(this.rut, this.idTransaccion, true, obj).subscribe(
        resp => {
          this.entidades = resp?.SolicitudDeCredito?.Stage5Response?.Entidades; //listado de entidades
          this.contrato = resp?.SolicitudDeCredito?.Stage5Response?.Contrato;
          this.validacionEntidadesContrato();
          this.entidadesAct.emit(this.entidades);
          this.contratoAct.emit(this.contrato);
          this.entidadesValidadasAct.emit(this.entidadesValidadas);
          this.entidadesRutAct.emit([]);

          const reportesDerivados: any = this.mapeoResponseConsulta(resp?.SolicitudDeCredito?.Stage7Response) || {};
          const entidadesDerivadas: any = resp?.SolicitudDeCredito?.Stage7Response?.request || [];
          const requestOriginal: any = JSON.parse(JSON.stringify(resp?.SolicitudDeCredito?.Stage7Response?.request || []));

          this.responseConsultaAct.emit(reportesDerivados);
          this.entidadesDerivadasAConsultarAct.emit(entidadesDerivadas);
          this.requestOriginalAct.emit(requestOriginal);

          this.alertSuccess = true;
          this.alertError = false;
          this.spinner.hide();
        }, 
        (error) => {
          this.alertSuccess = false;
          this.alertError = true;
          this.mensajeError = (error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar servicio de agregar entidades');
          this.spinner.hide();

      }));
    } else {
      this.alertSuccess = false;
      this.alertError = true;
      this.mensajeError = "Solicitud de Crédito - Formulario Invalido";
      this.spinner.hide();
    }
    
  }

  validaEstado(): boolean{
    const estado = this.modalService.watch();
    return estado;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  resetModal(completo: boolean): void{
    if(completo){
      this.mostrarFormulario = true;
    }
    this.alertSuccess = false;
    this.alertError = false;
    this.mensajeError = 'Solicitud de Crédito - Se ha producido un error al actualizar el modal';
    this.entidadSeleccionada = '';
    this.entidadesAIngresar = [];
    this.entidadesAEliminar = [];
  }

  obtenerNombreEntidad(entidad: string): string{
    const indiceContrato = this.contrato.findIndex((elemento: any) => elemento.key == entidad);
    return this.contrato[indiceContrato].name || this.entidadSeleccionada;
  }

  validacionEntidadesContrato(): void {
    const entidadesValidadas: any[] = [];

    this.entidades.forEach((element: any) => {

      const indiceEntidad = this.contrato.findIndex((elemento: any) => elemento.key == element.entidad);
      if(indiceEntidad >= 0){
        const items: any[] = [];
        const entidad = element;
        entidad.name = this.contrato[indiceEntidad]?.name || element.entidad;
        
        element.items.forEach((item: any) => {
          const itemValidado = item;
          const atributos: any[] = [];

          this.contrato[indiceEntidad].outputVars.forEach((atributo: any) => {
            const indiceAtributo = item.atributos.findIndex((elemento: any) => elemento.name == atributo.name);
            if(indiceAtributo >= 0 && atributo?.display !== false ){
              const atributoValidado = item.atributos[indiceAtributo];
              atributoValidado.displayName = atributo?.displayName || item.atributos[indiceAtributo].name;
              atributos.push(atributoValidado);
            } else if(indiceAtributo < 0 && atributo?.name !== 'rut' && atributo?.name !== 'Rut' && atributo?.display !== false ) {
              atributos.push({
                displayName: atributo?.displayName || '',
                name: atributo?.name || '',
                value: ""
              });
            }
          });

          itemValidado.atributos = atributos;

          items.push(itemValidado);
        });
        entidad.items = items;
        entidadesValidadas.push(entidad)
      }
    });

    this.entidadesValidadas = entidadesValidadas;
  }

  personaNatural(rut: string): boolean{
    
    if (parseInt(rut.substring(0, this.rut.length - 1)) >= 50000000){
      return false;
    } else {
      return true;
    }
  }

}
