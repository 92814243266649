<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>
<div *ngIf="hasUserAccess && !allReportReady">
    <div class="container__form">
        <div class="col-md-6">
            <div class="block">
                <form [formGroup]="empresaEnUnDiaReportForm" (ngSubmit)="createReport()">
                    <div class="form-horizontal form-bordered">
                        <div class="form-group" [ngClass]="{'has-error': activateRutHelper}">
                            <label class="col-md-3 control-label">Rut</label>
                            <div class="col-md-6">
                                <input formControlName="rut" type="text" class="form-control" formatRut required>
                                <span *ngIf="activateRutHelper" class="help-block">Rut no valido</span>
                            </div>
                        </div>
                        <div class="form-group form-actions container__actions">
                            <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i>
                                Crear Reporte</button>
                            <button (click)="clearForm()" class="btn btn-sm btn-warning"><i class="fa fa-repeat"></i>
                            Limpiar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="empresaEnUnDiaReportDataSource && allReportReady">

    <div class="container-gtp">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="btn-option">
                    <div>
                        <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                    </div>
                    <div>
                        <div class="btn-group" role="group" aria-label="reports btn group">
                            <button *ngIf="hasCertificadoAnotaciones" (click)="showAnotacionesReport()" type="button" class="btn btn-primary">Anotaciones</button>
                            <button *ngIf="hasCertificadoVigencia" (click)="showVigenciaReport()" type="button" class="btn btn-primary">Vigencia</button>
                            <button *ngIf="hasCertificadoEstatutos" (click)="showEstatutosReport()" type="button" class="btn btn-primary">Estatutos</button>
                        </div>
                    </div>
                    <div>
                        <button *ngIf="(showCertificadoAnotaciones || showCertificadoVigencia || showCertificadoEstatutos)" (click)="downloadReportSelected()" class="btn btn-primary">Descargar PDF</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="empresaEnUnDiaReportDataSource && showCertificadoAnotaciones">
        <app-anotaciones-viewer [content]='empresaEnUnDiaReportDataSource'></app-anotaciones-viewer>
    </div>
    <div *ngIf="empresaEnUnDiaReportDataSource && showCertificadoVigencia">
        <app-vigencia-viewer [content]='empresaEnUnDiaReportDataSource'></app-vigencia-viewer>
    </div>
    <div *ngIf="empresaEnUnDiaReportDataSource && showCertificadoEstatutos">
        <app-estatutos-viewer [content]='empresaEnUnDiaReportDataSource'></app-estatutos-viewer>
    </div>
</div>