import { Component, Input, OnInit } from '@angular/core';
import { Reporte } from 'src/app/models/formulario-f22/formulario-f22.model';
import { balanceReporteFullActivos, balanceReporteFullEstadoResultados, balanceReporteFullIndicadoresFinancieros, balanceReporteFullInformacionBalance, balanceReporteFullPasivos, balanceReporteFullResumenFinanciero, ventasPeriodo } from 'src/app/models/utils/utils.model';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-balance-automatico-capital-express-workflow',
  templateUrl: './balance-automatico.component.html',
  styleUrls: ['./balance-automatico.component.scss']
})
export class BalanceAutomaticoCapitalExpressWorkflowComponent implements OnInit {
  @Input() reporteFullContent!: any | null;
  @Input() rut!: string | null;

  public informacionBalance: any;
  public balanceReporteFullInformacionBalance: typeof balanceReporteFullInformacionBalance | any = balanceReporteFullInformacionBalance;
  public balanceReporteFullResumenFinanciero: typeof balanceReporteFullResumenFinanciero | any = balanceReporteFullResumenFinanciero;
  public balanceReporteFullActivos: typeof balanceReporteFullActivos | any = balanceReporteFullActivos;
  public balanceReporteFullPasivos: typeof balanceReporteFullPasivos | any = balanceReporteFullPasivos;
  public balanceReporteFullEstadoResultados: typeof balanceReporteFullEstadoResultados | any = balanceReporteFullEstadoResultados;
  public balanceReporteFullIndicadoresFinancieros: typeof balanceReporteFullIndicadoresFinancieros | any = balanceReporteFullIndicadoresFinancieros;
  public ventasPeriodo: typeof ventasPeriodo | any = ventasPeriodo;
  
  constructor(
    private utilsService: UtilsService
  ) { 
  }

  public headerBalance: string[] = [
    'periodo',
    'folio',
    'fecha',
    'nombre',
    'nMeses',
    'actividadEconomica',
    'sector',
    'subSector',
    'codActividad',
    'tamano',
    'rating',
    'glosa'
  ];



  public headerResumenFinanciero: string[] = [
    'ingresosdelaExplotacion',
    'utilidadneta',
    'utilidadVentas',
    'razonCorriente',
    'permanenciaCtasporCobrar',
    'permanenciadeInventarios',
    'totalactivos',
    'totalactivosfijos',
    'totalpasivoexigible',
    'totalpatrimonio',
    'leverage'
  ];

  public headerResumenActivos: string[][] = [
    ['disponible', 'disponibleporcentaje'],
    ['valoresNegociables', 'valoresNegociablesporcentaje'],
    ['cuentasporCobrardelGiro', 'cuentasporCobrardelGiroporcentaje'],
    ['inventario', 'inventarioporcentaje'],
    ['impuestosporRecuperar', 'impuestosporRecuperarporcentaje'],
    ['deudoresVarios', 'deudoresVariosporcentaje'],
    ['cuentasporCobrarEERROperacionalCP', 'cuentasporCobrarEERROperacionalCPporcentaje'],
    ['otrosActivosCirculantes', 'otrosActivosCirculantesporcentaje'],
    ['totalactivocirculante', 'totalactivocirculantEporcentaje'],
    ['terrenosyBienesRaíces', 'terrenosyBienesRaícesporcentaje'],
    ['maquinariasEquiposyVehículos', 'maquinariasEquiposyVehículosporcentaje'],
    ['activosenLeasing', 'activosenLeasingporcentaje'],
    ['otrosActivosFijos', 'otrosActivosFijosporcentaje'],
    ['depreciaciónAcumulada', 'depreciaciónAcumuladaporcentaje'],
    ['totalactivosfijos', 'totalactivosfijoSporcentaje'],
    ['cuentasporCobrarEERROperacionalLP', 'cuentasporCobrarEERROperacionalLPporcentaje'],
    ['inversiónenEERRySociedades', 'inversiónenEERRySociedadesporcentaje'],
    ['intangibles', 'intangiblesporcentaje'],
    ['otrosActivos', 'otrosActivosporcentaje'],
    ['totalotrosactivospermanentes', 'totalotrosactivospermanenteSporcentaje'],
    ['totalactivos', 'totalactivoSporcentaje']
  ];

  public headerResumenPasivos: string[][] = [
    ['bancosCortoPlazo', 'bancosCortoPlazoporcentaje'],
    ['bancosLPporcionCP', 'bancosLPporcionCPporcentaje'],
    ['leasingLPporcionCP', 'leasingLPporcionCPporcentaje'],
    ['cartasdeCreditosPagaréOtraDFCP', 'cartasdeCreditosPagaréOtraDFCPporcentaje'],
    ['documentosyCuentasporPagardeGiro', 'documentosyCuentasporPagardeGiroporcentaje'],
    ['variosAcreedores', 'variosAcreedoresporcentaje'],
    ['cuentasporPagarEERRySocios', 'cuentasporPagarEERRySociosporcentaje'],
    ['provisionesRetencioneseImpuestos', 'provisionesRetencioneseImpuestosporcentaje'],
    ['otrosPasivosCirculantes', 'otrosPasivosCirculantesporcentaje'],
    ['totalpasivoscirculantes', 'totalpasivoscirculanteSporcentaje'],
    ['bancosLP', 'bancosLPporcentaje'],
    ['leasingLP', 'leasingLPporcentaje'],
    ['acreedoresVariosLP', 'acreedoresVariosLPporcentaje'],
    ['cuentasporPagarEERRySociosLP', 'cuentasporPagarEERRySociosLPporcentaje'],
    ['otrosPasivosLargoPlazo', 'otrosPasivosLargoPlazoporcentaje'],
    ['totalpasivolargoplazo', 'totalpasivolargoplazOporcentaje'],
    ['totalpasivoexigible', 'totalpasivoexigiblEporcentaje'],
    ['interesMinoritario', 'interesMinoritarioporcentaje'],
    ['capitalPagado', 'capitalPagadoporcentaje'],
    ['reservas', 'reservasporcentaje'],
    ['utilidadesAcumuladas', 'utilidadesAcumuladasporcentaje'],
    ['utilidaddelEjercicio', 'utilidaddelEjercicioporcentaje'],
    ['retiros', 'retirosporcentaje'],
    ['totalpatrimonio', 'totalpatrimoniOporcentaje'],
    ['totalpasivos', 'totalpasivoSporcentaje']
  ];

  public headerResumenEstadoResultados: string[][] = [
    ['ingresosdelaExplotacion', 'ingresosdelaExplotacionporcentaje'],
    ['costodeExplotacion', 'costodeExplotacionporcentaje'],
    ['resultadobruto', 'resultadobrutOporcentaje'],
    ['gastosAdministrativosyVentas', 'gastosAdministrativosyVentasporcentaje'],
    ['ebitda', 'ebitdAporcentaje'],
    ['depreciaciondelEjercicio', 'depreciaciondelEjercicioporcentaje'],
    ['resultadooperacional', 'resultadooperacionaLporcentaje'],
    ['ingresosFinancieros', 'ingresosFinancierosporcentaje'],
    ['ingresosfueradelaExplotación', 'ingresosfueradelaExplotaciónporcentaje'],
    ['utilPerdInversionenEERR', 'utilPerdInversionenEERRporcentaje'],
    ['egresosfueradeExplotacion', 'egresosfueradeExplotacionporcentaje'],
    ['gastosFinancieros', 'gastosFinancierosporcentaje'],
    ['diferenciadeCambio', 'diferenciadeCambioporcentaje'],
    ['resantescorrecmon', 'resantescorrecmoNporcentaje'],
    ['correccionMonetaria', 'correccionMonetariaporcentaje'],
    ['interesMinoritario2', 'interesMinoritario2porcentaje'],
    ['utilidadantesdeimpuestos', 'utilidadantesdeimpuestoSporcentaje'],
    ['impuestoRenta', 'impuestoRentaporcentaje'],
    ['utilidadneta', 'utilidadnetAporcentaje'],
  ];

  public headerResumenIndicadoresFinancieros: string[] = [
    'LiquidezyActividadTitle', // solo para titulo
    'capitaldeTrabajo',
    'razonCorriente',
    'testAcido',
    'permanenciaCtasporCobrar',
    'permanenciadeInventarios',
    'permdeCuentasporPagar',
    'cicloOperacionalNeto',
    'variacionenActivos',
    'variacionenPasivoExigible',
    'RentabilidadTitle', // solo para titulo
    'utilidadVentas',
    'ventaTotalIngresos',
    'ebitdaAnualizado',
    'roe',
    'roa',
    'EndeudamientoyCobertura', // solo para titulo
    'dfebitda',
    'ebitdagf',
    'leverage',
    'dfPatrimonio',
    'variacionenVentas',
    'variacionenPatrimonio'
  ];

  ngOnInit(): void {
    this.utilsService.changeSpanExtra(' > Balance')
    this.setInformacionBalance();
    //this.setRut();
  }

  /**
   * @default
   * Setea la informacion del balance, ordena por periodo ascendente
   * y solo toma los ultimos 3 periodos
   */
   setInformacionBalance(): void {
    if (this.reporteFullContent.length > 0) {
      this.informacionBalance = this.reporteFullContent.sort((a: Reporte, b: Reporte) => {
        return b.periodo - a.periodo;
      });
    }
  }

  

  getPercents(title: string): boolean {
    const valuesKeys = [
      'razonCorriente',
      'testAcido',
      'variacionenActivos',
      'variacionenPasivoExigible',
      'utilidadVentas',
      'ventaTotalIngresos',
      'roe',
      'roa',
      'dfebitda',
      'ebitdagf',
      'leverage',
      'dfPatrimonio',
      'variacionenVentas',
      'variacionenPatrimonio'
    ];
    return valuesKeys.includes(title);
  }

  /**
   * @description
   * Valida si el titulo existe en el array de titulos 
   * @param title 
   * @returns 
   */
   getStrongTitle(title: string): boolean {
    const valuesKeys = [
      'totalactivocirculante',
      'totalactivosfijos',
      'totalotrosactivospermanentes',
      'totalactivos',
      'totalpasivoscirculantes',
      'totalpasivolargoplazo',
      'totalpasivoexigible',
      'totalpatrimonio',
      'totalpasivos',
      'LiquidezyActividadTitle',
      'RentabilidadTitle',
      'EndeudamientoyCobertura'
    ];
    return valuesKeys.includes(title);
  }

  getCountArray(obj: any[]): number[] {
    // convertir la cantidad de objetos a un array de numeros
    const countArray: number[] = [];
    obj.forEach((element: any, i: number) => {
      countArray.push(i + 1);
    });
    return countArray;
  }

  //Funcion para obtener letra a partir de numero
  getColName(n: number): string {
    const ordA = 'a'.charCodeAt(0);
    const ordZ = 'z'.charCodeAt(0);
    const len = ordZ - ordA + 1;
  
    let s = "";
    while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s;
  }

  getNumberFormatted(dato: number): number{
    return Number(Number(dato).toFixed(2))
  }

  getNumberFormattedInt(dato: number): number{
    return Number(Number(dato).toFixed())
  }

  isNumber(val: any): boolean { return typeof val === 'number'; }

}
