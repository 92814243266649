import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalBasicoConsolidadoService } from '../../../shared/services/modal-basico-consolidado.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AccordionComponent } from 'ngx-bootstrap/accordion';

@Component({
  selector: 'app-modal-consolidado-workflow',
  templateUrl: './modal-consolidado-workflow.component.html',
  styleUrls: ['./modal-consolidado-workflow.component.scss']
})
export class ModalConsolidadoWorkflowComponent implements OnInit {
  @Input() rut: string = '';
  @Input() idTransaccion: string = '';
  @Input() dataServicios: any = {};
  @Input() informeComercial: string = '10';
  @Input() default: boolean = true;
  @Input() plantilla: string = '';
  @Input() descargar: boolean = false;
  private subscriptions: Subscription[] = [];
  public objectKeys = Object.keys;

  @ViewChild(AccordionComponent) accordion: AccordionComponent | any;

  constructor(
    private modalService: ModalBasicoConsolidadoService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  validaEstado(): boolean{
    const estado = this.modalService.watch();
    return estado;
  }

  descargarPDF(): void {
    const element = document.getElementById('printButtonModal');
    if(this.accordion?.groups && this.accordion?.groups.length > 0){
      for (const group of this.accordion.groups){
        group.isOpen = true;
      }
    }
    setTimeout(() => {
      if(element){
        element.click();
      } else {
        console.error('Elemento no encontrado');
      }
    }, 500);
  }

}
