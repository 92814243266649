<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>
<div *ngIf="hasUserAccess">
    <div class="container__form" *ngIf="!solicitudCredito">
        <div class="col-md-6">
            <div class="block">
                <form [formGroup]="solicitudCreditoReportForm" (ngSubmit)="requestReport()">
                    <div class="form-horizontal form-bordered">
                        <div class="form-group" [ngClass]="{'has-error': activateRutHelper}">
                            <label class="col-md-3 control-label">Rut</label>
                            <div class="col-md-6">
                                <input formControlName="rut" type="text" class="form-control" formatRut required>
                                <span *ngIf="activateRutHelper" class="help-block">Rut no valido</span>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="hasInformeAccess">
                            <label class="col-md-3 control-label">Informe Comercial</label>
                            <div class="col-md-8">
                                <label class="switch switch-primary">
                                    <input type="checkbox" formControlName="informeComercial">
                                    <span></span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group form-actions container__actions">
                            <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i>
                                Crear Reporte</button>
                            <button type="button" (click)="clearForm()" class="btn btn-sm btn-warning"><i class="fa fa-repeat"></i>
                                Limpiar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div *ngIf="solicitudCredito">
    <app-solicitud-credito-viewer [rut]="rut" (closeSolicitud)="closeSolicitudCredito()"></app-solicitud-credito-viewer>
</div>