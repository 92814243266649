<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<ng-container *ngIf="hasUserAccess">

    <div class="card w-100 mx-auto border-0 rounded">
        <div class="card-body p-5">
            <div class="row">
    
                <div class="col-sm-12 my-2">
                    <h4>
                        Credenciales Usuarios
                    </h4>
                </div>

                <div class="col-md-7 mx-auto" *ngIf="seeAgregaRut">

                    <div class="col-sm-12 mb-3 px-0">

                        <div class="container-fluid p-3">
                            <div class="row">

                                <ng-container *ngIf="rutEntrada === '' && credsNameEntrada.length === 0">
                                    <div class="col-sm-12 mb-3 px-2">
                                        <button type="button" class="btn btn-primary" (click)="volverAgregarRut()">Volver</button>
                                    </div>
                                </ng-container>

                                <div class="col-sm-12 my-1 px-5">
                                    <form [formGroup]="agregarRutForm" (ngSubmit)="onSubmitRut()">
                                        <div class="col-sm-12 my-1 px-5">
                                            <div class="form-row">
                                                <div class="form-group col-md-8 mx-auto" [ngClass]="{'has-error': activateRutHelper}">
                                                    <label class="cursor-pointer" title="Rut de usuario a ingresar" for="rut">Rut</label>
                                                    <input formControlName="rut" type="text" class="form-control" id="rut" name="rut" formatRut required>
                                                    <span *ngIf="activateRutHelper" class="help-block">Rut no valido</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 my-1 text-center">
                                            <button type="submit" class="btn btn-primary">Agregar</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div class="col-md-9 mx-auto" *ngIf="seeCargaMasiva">

                    <div class="col-sm-12 mb-3 px-0">

                        <button type="button" class="btn btn-primary mb-5" (click)="volverTableUsuario()">Volver a Usuarios</button>

                        <app-carga-masiva-cred></app-carga-masiva-cred>

                    </div>

                </div>

                <div class="col-md-7 mx-auto" *ngIf="seeTableUsuarios">

                    <div class="container-fluid px-0">
                        <ng-container *ngIf="validaPermisosAdmin">
                            <button type="button" class="btn btn-success" (click)="cargaMasiva()">Carga Masiva</button>
                        </ng-container>
                        
                        <button type="button" class="btn btn-primary float-right" (click)="agregarRut()">Agregar Rut</button>
                        <button type="button" class="btn btn-outline-primary float-right mr-3" (click)="goToSolicitudes()" *ngIf="validaPermisosSolicitudes()">Solicitudes</button>
                    </div>


                    <div class="card text-center my-4">
    
                        <div class="card-header">
                            <div class="my-0 row d-flex px-4">
    
                                <div class="col-xs-3 col-sm-auto mr-auto p-2">
                                    <select class="form-control no-print pagination-custom" [(ngModel)]="currentItem" (ngModelChange)="cambioPaginacion()">
                                        <option *ngFor="let element of paginationOptions" [ngValue]="element">
                                            {{ element }}
                                        </option>
                                    </select>
                                </div>
    
                                <div class="col-xs-3 col-sm-auto p-2">
                                    <div class="input-group mb-3">
                                        <input id="table-complete-search" type="text" class="form-control" name="searchTermUsuario"
                                            title="Ingrese un rut para obtener usuario"
                                            placeholder="Buscar usuario por rut"
                                            [(ngModel)]="inputTextUsuario"
                                            formatRut
                                        >
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-primary" 
                                                title="Ingrese un rut para obtener usuario"
                                                type="button"
                                                (click)="buscarByRut()"
                                            ><i class="fa fa-search" aria-hidden="true"></i></button>
                                          </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
    
                        <div class="table-responsive">
                            <table class="table table-hover border my-0">
                                <thead>
                                  <tr>
                                    <th scope="col" class="text-center align-middle font-size"
                                        width="30%"
                                        rowspan="2"
                                    >
                                        Rut
                                    </th>
                                    <th scope="col" class="text-center align-middle font-size"
                                        rowspan="1"
                                        colspan="100%"
                                    >
                                        Estado Credenciales
                                    </th>
                                  </tr>
                                  <tr>
                                    <ng-container *ngFor="let servicio of serviciosHeader">

                                        <th scope="col" class="text-center align-middle font-size"
                                            rowspan="1"
                                        >
                                            {{servicio.displayName}}
                                        </th>

                                    </ng-container>
                                  </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="paginaActual.length > 0; else noPage">
                                        <tr *ngFor="let usuario of paginaActual | paginate:{itemsPerPage: currentItem, currentPage: currentPage, totalItems: cantidadRegistros}">
                                            <td class="font-size text-nowrap cursor-pointer" (click)="seleccionarUsuario(usuario)">
                                                {{ usuario.displayRut || '' | rutFormat }}
                                            </td>

                                            <ng-container *ngFor="let servicio of serviciosHeader">

                                                <ng-container *ngFor="let servicioUsuario of usuario?.services?.services">
                                                    
                                                    <ng-container *ngIf="servicio?.name === servicioUsuario?.name">

                                                        <td class="font-size cursor-pointer" (click)="seleccionarUsuario(usuario)">
                                                            <ng-container *ngIf="servicioUsuario.configurationStatus === true; else elseConfigurationTable">
                                                                <i class="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>
                                                            </ng-container>
                                                            <ng-template #elseConfigurationTable>
                                                                <i class="fa fa-times-circle fa-lg text-danger" aria-hidden="true"></i>
                                                            </ng-template>
                                                        </td>

                                                    </ng-container>

                                                </ng-container>
        
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                    <ng-template #noPage>
                                        <tr>
                                            <td colspan="100%">
                                                Sin información
                                            </td>
                                        </tr>
                                    </ng-template>
                                </tbody>
                            </table>
                        </div>
    
                        <div class="card-footer text-muted">
                            <div class="p-2">
                                <div id="pagination">
                                    <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                                        <pagination-controls (pageChange)="getPage($event, false)"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
    
                
                <div class="col-sm-12 my-3" *ngIf="seeTableServicios">
                    
                    <ng-container *ngIf="rutEntrada === '' && credsNameEntrada.length === 0">
                        <div class="col-sm-12 mb-3 px-0">
                            <button type="button" class="btn btn-primary" (click)="volverTableUsuario()">Volver</button>
                            <button type="button" class="btn btn-primary float-right" (click)="verSolicitud()" *ngIf="rutEntrada === '' && credsNameEntrada.length === 0 && validaPermisosSolicitarCred()">Solicitar Credenciales</button>
                            <button type="button" class="btn btn-outline-primary float-right mr-3" (click)="verSolicitudes()" *ngIf="validaPermisosSolicitudes()">Solicitudes</button>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="usuarioSeleccionado?.displayRut">
                        <h4 class="text-center font-weight-bold mb-3">Rut: {{ usuarioSeleccionado?.displayRut || '' | rutFormat }}</h4>
                    </ng-container>
                    

                    <div class="card text-center my-4">
    
                        <div class="card-header">
                            <div class="my-0 row d-flex px-4">
    
                                <div class="col-xs-3 col-sm-auto mr-auto p-2">
                                    <app-paginator-by-items title="Cantidad de filas a mostrar en la tabla" [itemsPerPageInput]="currentItemServicio" (itemsPerPage)="currentItemServicio = $event"></app-paginator-by-items>
                                </div>
    
                                <div class="col-xs-3 col-sm-auto p-2">
                                    <div class="input-group mb-3">
                                        <input id="table-complete-search" type="text" class="form-control" name="searchTermServicio"
                                            title="Ingrese texto para filtrar tabla"
                                            placeholder="Filtrar tabla"
                                            [(ngModel)]="inputTextServicio" 
                                            (keyup)="filtrarObjetoServicio()"
                                        >
                                    </div>
                                </div>
                                
                            </div>
                        </div>
    
                        <div class="table-responsive">
                            <table class="table table-hover border my-0">
                                <thead>
                                  <tr>
                                    <th scope="col" class="text-center font-size cursor-pointer"
                                        (click)="cambioDeCampoServicio('name')"
                                    >
                                        Nombre Servicio
                                        <div class="float-right">
                                            <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                            <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                        </div>
                                    </th>
                                    <th scope="col" class="text-center font-size cursor-pointer min-width-th"
                                        (click)="cambioDeCampoServicio('configurationStatus')"
                                    >
                                        Configurada
                                        <div class="float-right">
                                            <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                            <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                        </div>
                                    </th>
                                    <th scope="col" class="text-center font-size cursor-pointer min-width-th-lg"
                                        (click)="cambioDeCampoServicio('orgAccess')"
                                    >
                                        Acceso a nivel de la organización
                                        <div class="float-right">
                                            <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                            <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                        </div>
                                    </th>
                                    <ng-container *ngIf="validaUserAdmin() && rutEntrada === '' && credsNameEntrada.length === 0">
                                        <th scope="col" class="text-center font-size cursor-pointer"></th>
                                    </ng-container>
                                  </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let servicio of tablaServiciosFiltrado | paginate:{itemsPerPage: currentItemServicio, currentPage: currentPageServicio}">
                                        <tr>
                                            <td class="font-size cursor-pointer" (click)="configurarServicio(servicio)">
                                                {{ servicio.displayName || '' }}
                                            </td>
                                            <td class="text-center font-size cursor-pointer" (click)="configurarServicio(servicio)">
                                                <ng-container *ngIf="servicio.configurationStatus === true; else elseConfigurationTable">
                                                    <i class="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>
                                                </ng-container>
                                                <ng-template #elseConfigurationTable>
                                                    <i class="fa fa-times-circle fa-lg text-danger" aria-hidden="true"></i>
                                                </ng-template>
                                            </td>
                                            <td class="text-center font-size cursor-pointer" (click)="configurarServicio(servicio)">
                                                <ng-container *ngIf="obtenerOrgAccess(servicio.attributes) === true; else elseOrgAccess">
                                                    <i class="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>
                                                </ng-container>
                                                <ng-template #elseOrgAccess>
                                                    <i class="fa fa-times-circle fa-lg text-danger" aria-hidden="true"></i>
                                                </ng-template>
                                            </td>
                                            <ng-container *ngIf="validaUserAdmin() && rutEntrada === '' && credsNameEntrada.length === 0">
                                                <td class="text-center font-size">
                                                    <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                                                        (click)="borrarServicio(servicio)"
                                                    ></i>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
    
                        <div class="card-footer text-muted">
                            <div class="p-2">
                                <div id="pagination">
                                    <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                                        <pagination-controls (pageChange)="currentPageServicio=$event"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>

                <div class="col-sm-12 mb-2" *ngIf="seeSolicitudesRut">
                    <div class="container-fluid p-3">
                        <div class="row">

                            <div class="col-sm-12 mb-3 px-2">
                                <button type="button" class="btn btn-primary" (click)="volverTableServicio()">Volver</button>
                            </div>

                            <div class="col-sm-12 my-1 px-5">
                                <app-listado-solicitudes [rutInput]="usuarioSeleccionado?.displayRut"></app-listado-solicitudes>
                            </div>
                        </div>
                    </div>
                </div>

                <!--seeSolicitud-->
                <div class="col-sm-12 mb-2" *ngIf="seeSolicitud">
                    <div class="container-fluid p-3">
                        <div class="row">

                            <div class="col-sm-12 mb-3 px-2">
                                <button type="button" class="btn btn-primary" (click)="volverTableServicio()">Volver</button>
                            </div>

                            <div class="col-sm-12 my-1 px-5">
                                <form [formGroup]="solicitudForm" (ngSubmit)="onSubmitSolicitud()">
                                    <div class="col-sm-12 my-1 px-5">

                                        <h4 class="text-center font-weight-bold mb-3">Solicitud de Credenciales a Rut: {{ usuarioSeleccionado?.displayRut || '' | rutFormat }}</h4>
                                        
                                        <div class="form-row">

                                            <div class="form-group col-md-8 mx-auto">
                                                <label for="email" title="Nombre de usuario" class="cursor-pointer"
                                                    >Email</label>

                                                <input formControlName="email" type="text" class="form-control" id="email" name="email">
                                                <div class="w-100">
                                                    <app-error [control]="solicitudForm.get('email')"></app-error>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-8 mx-auto">
                                                <label for="servicioSolicitud" title="Servicio a solicitar permiso"
                                                    class="cursor-pointer">
                                                    Servicio a solicitar permiso
                                                </label>
                                                <select class="form-control" id="servicioSolicitud" formControlName="servicioSolicitud">
                                                    <option value="" selected>Seleccione una opcion...</option>
                                                    <ng-container *ngFor="let servicio of servicios">
                                                        <option [value]="servicio.value">
                                                            {{servicio.text}}
                                                        </option>
                                                    </ng-container>
                                                </select>
                                                <div class="w-100 mb-2">
                                                    <app-error [control]="solicitudForm.get('servicioSolicitud')"
                                                    ></app-error>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-sm-12 my-1 text-center">

                                        <button type="submit" class="btn btn-primary">Enviar Solicitud</button>

                                    </div>
                                </form>
                            </div>
                        
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 mb-2" *ngIf="seeForm">
                    <div class="container-fluid p-3">
                        <div class="row">

                            <ng-container *ngIf="rutEntrada === '' && credsNameEntrada.length === 0">
                                <div class="col-sm-12 mb-3 px-2">
                                    <button type="button" class="btn btn-primary" (click)="volverTableServicio()">Volver</button>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="rutEntrada !== '' && credsNameEntrada.length > 1">
                                <div class="col-sm-12 mb-3 px-2">
                                    <button type="button" class="btn btn-primary" (click)="volverTableServicioModal()">Volver</button>
                                </div>
                            </ng-container>

                            <div class="col-sm-12 my-1 px-5">
                                <form [formGroup]="configurarForm" (ngSubmit)="onSubmit()">
                                    <div class="col-sm-12 my-1 px-5">

                                        <ng-container *ngIf="usuarioSeleccionado?.displayRut && servicioSeleccionado.displayName">
                                            <h4 class="text-center font-weight-bold mb-3">Rut: {{ usuarioSeleccionado?.displayRut || '' | rutFormat }} - Servicio: {{ servicioSeleccionado.displayName || '' }}</h4>
                                        </ng-container>

                                        <div class="form-row">

                                            <ng-container *ngFor="let servicio of servicioSeleccionado?.attributes; let i = index">

                                                <ng-container *ngIf="servicio?.display === true">
                                                    <div class="form-group col-md-8 mx-auto" [ngClass]="{'has-error': activateRutHelperService[i] && servicio?.typeFormat === 'RUT'}">
                                                        <label 
                                                            [attr.for]="servicio?.name"
                                                            [attr.title]="servicio?.displayName"
                                                            class="cursor-pointer"
                                                        >
                                                            {{servicio?.displayName || ''}}
                                                        </label>
                                    
                                                        <ng-container [ngSwitch]="typeTraduccion(servicio?.typeFormat)">
                                                            <ng-container *ngSwitchCase="'boolean'">
                                                                <select 
                                                                    class="form-control"
                                                                    [attr.id]="servicio?.name"
                                                                    [formControlName]="servicio?.name"
                                                                    [attr.title]="servicio?.name === 'orgAccess' ? 'Desea que todos los usuarios de la organización puedan utilizar esas credenciales o solo el usuario que las crea' : servicio?.displayName || ''"
                                                                >
                                                                    <option value="">Seleccione una opción</option>
                                                                    <option value="true">Si</option>
                                                                    <option value="false">No</option>
                                                                </select>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'password'">
                                                                <div class="input-group">
                                                                    <input 
                                                                        [attr.type]="'password'"
                                                                        class="form-control border-pass"
                                                                        [attr.id]="servicio.name"
                                                                        [attr.placeholder]="servicio?.displayName || servicio?.name || ''"
                                                                        [formControlName]="servicio.name"
                                                                        [attr.id]="servicio.name"
                                                                        [attr.aria-describedby]="'button-' + servicio.name"
                                                                        [attr.title]="servicio?.name === 'orgAccess' ? 'Desea que todos los usuarios de la organización puedan utilizar esas credenciales o solo el usuario que las crea' : servicio?.displayName || ''"
                                                                    >
                                                                    <button 
                                                                        class="btn btn-outline-primary border" 
                                                                        type="button" 
                                                                        [attr.title]="'Mostrar/Ocultar ' + (servicio?.displayName || servicio?.name || '')" 
                                                                        [attr.id]="'button-' + servicio.name"
                                                                        (click)="toggleTypePassword(servicio.name)"
                                                                    >
                                                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                                                    </button>                                  
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngSwitchDefault>
                                                                <ng-container *ngIf="servicio?.typeFormat === 'RUT'; else elseRutInput">
                                                                    <input 
                                                                        class="form-control"
                                                                        [attr.type]="typeTraduccion(servicio?.typeFormat)"
                                                                        [attr.id]="servicio?.name"
                                                                        [attr.placeholder]="servicio?.displayName || servicio?.name || ''"
                                                                        [formControlName]="servicio?.name"
                                                                        [attr.title]="servicio?.name === 'orgAccess' ? 'Desea que todos los usuarios de la organización puedan utilizar esas credenciales o solo el usuario que las crea' : servicio?.displayName || ''"
                                                                        formatRut required
                                                                        [attr.disabled]="true"
                                                                    >
                                                                </ng-container>
                                                                <ng-template #elseRutInput>
                                                                    <input 
                                                                        class="form-control"
                                                                        [attr.type]="typeTraduccion(servicio?.typeFormat)"
                                                                        [attr.id]="servicio?.name"
                                                                        [attr.placeholder]="servicio?.displayName || servicio?.name || ''"
                                                                        [formControlName]="servicio?.name"
                                                                        [attr.title]="servicio?.name === 'orgAccess' ? 'Desea que todos los usuarios de la organización puedan utilizar esas credenciales o solo el usuario que las crea' : servicio?.displayName || ''"
                                                                    >
                                                                </ng-template>
                                                                
                                                            </ng-container>
                                                        </ng-container>

                                                        <ng-container *ngIf="servicio?.typeFormat === 'RUT'; else elseRut">
                                                            <span *ngIf="activateRutHelperService[i]" class="help-block">Rut no válido</span>
                                                        </ng-container>
                                                        <ng-template #elseRut>
                                                            <div class="w-100 mb-2">
                                                                <app-error [control]="configurarForm.get(servicio?.name)"
                                                                ></app-error>
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>

                                            </ng-container>

                                        </div>
                                        
                                    </div>
                                    <div class="col-sm-12 my-1 text-center">

                                        <button #validacredenciales type='button' class="btn btn-light mx-2" *ngIf="servicioSeleccionado?.onlineValidationCreds === true" 
                                            (click)="validaCredenciales()"
                                            [disabled]="validaDisabled()">
                                            Validar Credenciales
                                        </button>

                                        <button *ngIf="validaShowGuardar()"  type="submit" class="btn btn-primary">Guardar</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
    
        </div>
    </div>

</ng-container>