<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<ng-container *ngIf="(showoptions || showContent) && !showReport">
    <div class="container-gtp no-print">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="btn-option">
                    <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                    <button *ngIf="showContent" [printTitle]='"Ventas_"+(rut | rutFormat)+"_"+today'
                        [useExistingCss]="true" printSectionId="print-section" ngxPrint
                        class="mr-2 btn btn-primary">Descargar PDF</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="(showoptions || showContent) && showReport">
    <div class="container-gtp no-print">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="btn-option">
                    <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                    <div>
                        <button *ngIf="showContent" [printTitle]='"Ventas_"+(rut | rutFormat)+"_"+today'
                            [useExistingCss]="true" printSectionId="print-section" ngxPrint
                            class="mr-2 btn btn-primary">Descargar PDF</button>
                        <button *ngIf="showContent" id="descargarExcel" (click)="appinformef29view.descargarExcel()"
                            class="btn btn-primary" >Descargar Excel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="print-section">
        <app-informe-f29-view #appinformef29view [reporteFullContent]="reporteFullContent" [rut]="rut"></app-informe-f29-view>
    </div>
</ng-container>


<div *ngIf="showoptions">
    <div class="container-gtp">
        <div class="panel panel-default">
            <div class="panel-body box-reporte-full">
                <form (ngSubmit)="submit()" [formGroup]="reportF29FormSend">
                    <tabset>
                        <tab heading="Folio" (selectTab)="onSelect($event)">
                            <div *ngIf="valueTab === 'Folio'">
                                <div class="d-flex w-100">
                                    <div class="mx-auto col-sm-6">
                                        <div class="form-group mt-5">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Folio</span>
                                                </div>
                                                <input type="number" class="form-control" formControlName="folio"
                                                    autocomplete="off">
                                            </div>
                                            <div class="w-100">
                                                <app-error [control]="reportF29FormSend.get('folio')"></app-error>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Periodo</span>
                                                </div>
                                                <input type="number" min="1" max="3000" class="form-control" formControlName="periodo"
                                                    autocomplete="off">
                                            </div>
                                            <div class="w-100">
                                                <app-error [control]="reportF29FormSend.get('periodo')"></app-error>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Mes</span>
                                                </div>
                                                <select class="custom-select" id="mes" aria-label="Example select with button addon" formControlName="mes">
                                                    <option selected value="">Mes</option>
                                                    <option value="1">Enero</option>
                                                    <option value="2">Febrero</option>
                                                    <option value="3">Marzo</option>
                                                    <option value="4">Abril</option>
                                                    <option value="5">Mayo</option>
                                                    <option value="6">Junio</option>
                                                    <option value="7">Julio</option>
                                                    <option value="8">Agosto</option>
                                                    <option value="9">Septiembre</option>
                                                    <option value="10">Octubre</option>
                                                    <option value="11">Noviembre</option>
                                                    <option value="12">Diciembre</option>
                                                  </select>
                                            </div>
                                            <div class="w-100">
                                                <app-error [control]="reportF29FormSend.get('mes')"></app-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab heading="PDF" (selectTab)="onSelect($event)" class="m-0">
                            <div *ngIf="valueTab === 'PDF'">
                                <div class="input-group mt-5 mx-auto col-sm-6">
                                    <div class="w-100">
                                        <app-error [control]="reportF29FormSend.get('pdf')"></app-error>
                                    </div>
                                    <label class="uploadbutton mx-auto">
                                        <input class="input-file" id="file" type="file" formControlName="pdf"
                                            name="file" accept=".pdf" (change)="handleUpload($event)" />
                                        <div class="button btn">PDF
                                        </div>
                                        <div class='input-file-text'
                                            [ngClass]="{'text-success': reportF29FormSend?.controls?.pdf?.value}">
                                            {{reportF29FormSend?.controls?.pdf?.value || 'Subir Archivo'}}
                                        </div>
                                    </label>
                                </div>
                                <div class="d-flex w-100">
                                    <div class="mx-auto col-sm-6">
                                        <div class="form-group mt-5 px-5">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Validar Sii</span>
                                                </div>
                                                <label class="switch switch-primary ml-3">
                                                    <input type="checkbox" id="validar" formControlName="validar">
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>                       
                            </div>
                        </tab>
                    </tabset>
                    <div class="col-12" *ngIf="valueTab === 'PDF' || valueTab === 'Folio'">
                        <div class="row">
                            <div class="mx-auto">
                                <div class="btn-group mt-5 text-center">
                                    <button type="submit" class="btn btn-primary mr-3" *ngIf="valueTab === 'Folio'">Crear</button>
                                    <button type="submit" class="btn btn-primary mr-3" *ngIf="valueTab === 'PDF'">Procesar</button>
                                    <button type="button" (click)="Limpiar()" class="btn btn-warning">Limpiar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


