import { Component, OnInit } from '@angular/core';
import { formatDate, Location } from '@angular/common';
import { CobranzaOnlineApiService } from '../cobranza-online-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionService } from 'src/app/shared/services/session.service';
import { switchMap } from 'rxjs/operators';
import { AlertService } from 'src/app/components/_alert';
import { Router } from '@angular/router';
//import { SimpleModalService } from 'ngx-simple-modal';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutClean } from 'rut-helpers';


export interface MorosidadListado {
  id: number,
  amount: string,
  company_rut: string,
  creditor_rut: string,
  debt_type: string,
  dte_type: string,
  ndoc: string,
  published_at: string,
  status: string
}

@Component({
  selector: 'app-cobranza-online-listado',
  templateUrl: './cobranza-online-listado.component.html',
  styleUrls: ['./cobranza-online-listado.component.scss']
})
export class CobranzaOnlineListadoComponent implements OnInit {

  public hasUserAccess = false;
  private access: string[] = [ESystemAccess.COBRANZA_ONLINE_BUSCAR, ESystemProfileList.WEB];


  public rut: string | undefined = "";
  public morosidades: any[] = [];

  show = false;
  noToken = false;

  paginatedItems: any[] = [];
  totalItems: number = 0;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalPages: number = 1; 
  firstPage = true;
  lastPage = true;

  minEndDate: Date | null = null;
  maxStartDate: Date | null = null;

  public cobranzaFilterForm = new FormGroup({
    ndoc: new FormControl(''),
    emisor_rut: new FormControl('', [gtpRutValidator()]),
    receptor_rut: new FormControl('', [gtpRutValidator()]),
    status: new FormControl(''),
    published_at: new FormControl(''),
    // created_since: new FormControl(''),
    // created_until: new FormControl('')
  });

  constructor(
    private location: Location,
    private _apiService: CobranzaOnlineApiService,
    private _spinner: NgxSpinnerService,
    private _session: SessionService,
    private _alertsService: AlertService,
    private _router: Router,
    private _fb: FormBuilder,
    //private _simpleModalService: SimpleModalService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.checkAccess();

    const controlEmisor = this.cobranzaFilterForm.get('emisor_rut');
    const controlReceptor = this.cobranzaFilterForm.get('receptor_rut');

    // Listen for value changes on the specific control
    controlEmisor?.valueChanges.subscribe(value => {
      // Check if the control is valid
      if (controlEmisor?.valid) {
        setTimeout(()=> {
          this.applyRutTransformation('emisor_rut');
        }, 1000)
      }
    });

    controlReceptor?.valueChanges.subscribe(value => {
      // Check if the control is valid
      if (controlReceptor?.valid) {
        setTimeout(()=> {
          this.applyRutTransformation('receptor_rut');
        }, 1000)
      }
    });

  }

  getApiData() {
    this._spinner.show();
    const idOrganizacion = this._session.getOrganization();
    const idUsuario = this._session.getUserInfo().username;
    
    this._apiService.checkToken(idOrganizacion, idUsuario).subscribe(
      (res) => {
        console.log(res);
        if(res.body && res.body.hasToken) {
          // nada
        } else {
          this.noToken = true;
        }
      },
      (error) => {
        this.noToken = true;
      }
    )

    // Obtener el RUT y morosidades
    this._apiService.getRut(idOrganizacion, idUsuario).pipe(
      switchMap(rutResponse => {
        // Caso token revocado o inexistente
        if(rutResponse.statusCode && rutResponse.statusCode == 420){
          this._spinner.hide();
          this._alertsService.error('Error al obtener tus datos, chequea tener tus credenciales de cobranza online actualizadas');
          this.rut = undefined;
        } else {
          this.rut = rutResponse.body.rut;  
        }
        return this._apiService.getMorosidades(idOrganizacion, idUsuario, this.rut)
      })
    ).subscribe(
      (morosidadesResponse) => {
        this.handleMorosidades(morosidadesResponse)
      },
      (error) => {
        console.log(error);
        this._spinner.hide();
        this._alertsService.error('Error al obtener los datos de la organización.');
    })
  }

  checkAccess() {
    const userAccess = this._session.getUserAccess();
    const getUserProfileList = this._session.getUserProfileList();
    console.log(userAccess);
    console.log(getUserProfileList);

    if (this._session.getUserAccess().includes(this.access[0]) && this._session.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
      this.getApiData();
    }
  }

  goTocredenciales() {
    this._router.navigate(['cobranza-online/onboarding'])
  }

  initializePagination(searchInfo: any) {
    this.totalItems = searchInfo.count;
    this.itemsPerPage = searchInfo.limit_value;
    this.currentPage = searchInfo.current_page;
    this.firstPage = searchInfo.first_page;
    this.lastPage = searchInfo.last_page;
    this.totalPages = searchInfo.total_pages;
    // this.setPage(1);
  }

  setPage(page: number) {
    if (page < 1 || page > this.totalPages) {
      return;
    }
    this.currentPage = page;

    //Llamar a la api con la pagina actual como parametro
    this._spinner.show();
    const idOrganizacion = this._session.getOrganization();
    const idUsuario = this._session.getUserInfo().username;
    this._apiService.getMorosidades(idOrganizacion, idUsuario, this.rut, page).subscribe(
      (response) => {
        this._spinner.hide();
        this.handleMorosidades(response)
      },
      (error) => {
        this._spinner.hide();
        this._alertsService.error('Error al obtener la página del listado de morosidades')
      }
    )

    // const startIndex = (page - 1) * this.itemsPerPage;
    // const endIndex = Math.min(startIndex + this.itemsPerPage, this.totalItems);
    // this.paginatedItems = this.morosidades.slice(startIndex, endIndex);
  }

  back() {
    this.location.back();
  }

  getBadgeFromStatus(status: string) {
    switch (status) {
      case "REPORTE_PENDIENTE":
        return "warning" // Amarillo
        break;
      case "NOTIFICANDO_REPORTE":
        return "warning" // Amarillo
        break;
      case "PENDIENTE":
        return "warning" // Amarillo
        break;
      case "REPORTADO":
        return "info" // Celeste
        break;
      case "BLOQUEADO":
        return "danger" // Rojo
        break;
      case "ANULADO":
        return "danger" // Rojo
        break;
      case "INVALIDO":
        return "danger" // Rojo
        break;
      case "PAGADO":
        return "success" // Celeste
        break;
      default:
        return "secondary" // Gris
        break;
    }
  }

  getTextFromStatus(status: string) {
    switch (status) {
      case "REPORTE_PENDIENTE":
        return "Reporte pendiente" // Amarillo
        break;
      case "PENDIENTE":
        return "Reporte pendiente" // Amarillo
        break;
      case "NOTIFICANDO_REPORTE":
        return "Notificando Reporte" // Amarillo
        break;
      case "REPORTADO":
        return "Reportado" // Celeste
        break;
      case "BLOQUEADO":
        return "Bloqueado" // Rojo
        break;
      case "ANULADO":
        return "Anulado" // Rojo
        break;
      case "PAGADO":
        return "Pagado" // Celeste
        break;
      case "INVALIDO":
        return "Inválido" // Rojo
        break;
      default:
        return "Indefinido" // Gris
        break;
    }
  }

  aclararMorosidad(debtId: number) {
    /*this._simpleModalService.addModal(ConfirmDialogComponent, {
      title: 'Aclarar Morosidad',
      message: `Al confirmar usted estaría enviando una morosidad a ser aclarada en Cobranza Online, ¿Desea continuar?`,
      textBoton: ['Ok', 'Cancelar'],
      textTimer: 0,
    }).subscribe((confirmed: boolean) => {
      if(confirmed) {
        this._spinner.show();
        // Endpoint aclarar morosidad
        const idOrganizacion = this._session.getOrganization();
      const idUsuario = this._session.getUserInfo().username;
        this._apiService.aclararMorosidad(idOrganizacion, idUsuario, this.rut, debtId).subscribe(
          (res) => {
            this._spinner.hide();
            console.log(res);
            // Alert service
            this._alertsService.success(res.debt_info || 'Morosidad actualizada en Cobranza Online')
            // Refresh de tabla
            setTimeout(() => {
              window.location.reload();
            }, 3000)
          },
          (error) => {
            this._spinner.hide();
            // Alert service
            this._alertsService.error('Error actualizar la morosidad')
          }
        )
      } 
    });*/
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Aclarar Morosidad',
        message: `Al confirmar usted estaría enviando una morosidad a ser aclarada en Cobranza Online, ¿Desea continuar?`,
        textBoton: ['Ok', 'Cancelar'],
        textTimer: 0,
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if(resp) {
        this._spinner.show();
        // Endpoint aclarar morosidad
        const idOrganizacion = this._session.getOrganization();
        const idUsuario = this._session.getUserInfo().username;
        this._apiService.aclararMorosidad(idOrganizacion, idUsuario, this.rut, debtId).subscribe(
          (res) => {
            this._spinner.hide();
            console.log(res);
            // Alert service
            this._alertsService.success(res.debt_info || 'Morosidad actualizada en Cobranza Online')
            // Refresh de tabla
            setTimeout(() => {
              window.location.reload();
            }, 3000)
          },
          (error) => {
            this._spinner.hide();
            // Alert service
            this._alertsService.error('Error actualizar la morosidad')
          }
        )
      }
    }, (error: any) => {
      this._alertsService.error('Ocurrio un Error');
    })
  }

  handleMorosidades(morosidadesResponse: any) {
    this._spinner.hide();
    if(morosidadesResponse.errors || !morosidadesResponse.data) {
      this.show = true;
      this.totalItems = 0;
      this.morosidades = [];
      return
    }
    console.log(morosidadesResponse)
    this.morosidades = morosidadesResponse.data.sort((a:any, b:any) => {
      const dateA = a.published_at ? new Date(a.published_at) : null;
      const dateB = b.published_at ? new Date(b.published_at) : null;
    
      if (dateA && dateB) {
        return dateB.getTime() - dateA.getTime();
      } else if (dateA) {
        return -1;
      } else if (dateB) {
        return 1;
      } else {
        return 0;
      }
    });

    if(morosidadesResponse.search_info) {
      this.initializePagination(morosidadesResponse.search_info);
    }
    this.show = true;
  } 


  search() {
    let values = this.cobranzaFilterForm.value;

    if(values.published_at && values.published_at != '') {
      values.published_at = formatDate(values.published_at, 'yyyy-MM-dd', 'es-CL');
    }

    // if(values.created_until && values.created_until != '') {
    //   values.created_until = formatDate(values.created_until, 'yyyy-MM-dd', 'es-CL');
    // }

    const idOrganizacion = this._session.getOrganization();
    const idUsuario = this._session.getUserInfo().username;

    this._spinner.show();

    this._apiService.getMorosidades(idOrganizacion, idUsuario, this.rut, 1, values).subscribe(
      (morosidadesResponse) => {
        this.handleMorosidades(morosidadesResponse)
        this._spinner.hide();
      },
      (error) => {
        console.log(error);
        this._spinner.hide();
        this._alertsService.error('Error al obtener los datos de la organización.');
    })
  }

  cleanFilters() {
    this.cobranzaFilterForm.reset();
  }

  onStartDateChange(event: any) {
    const startDate = event.target.value;
    this.minEndDate = startDate;
    // console.log(this.minEndDate);
    // this.publishForm.get('due_date')?.enable();
  }

  onEndDateChange(event: any) {
    const endDate = event.target.value;
    this.maxStartDate = endDate;
    console.log(this.maxStartDate);
  }

  isInvalid(controlName: string): boolean {
    const control = this.cobranzaFilterForm.get(controlName);
    return control ? control.invalid && (control.touched || control.dirty) : false;
  }

  applyRutTransformation(controlName: string) {
    const control = this.cobranzaFilterForm.get(controlName);
    let value = control?.value;

    value = rutClean(value)
    // value = value.replace(/[.-]/g, '');
    // Ensure value exists and has at least 2 characters to apply the transformation
    if (value && value.length > 1) {
      const secondLastChar = value.charAt(value.length - 2); // Get the second last character

      // If the second last character is not a dash, add a dash
      if (secondLastChar !== '-') {
        value = value.slice(0, -1) + '-' + value.slice(-1); // Insert dash before the last character

        // Update the control value with the transformed value without triggering valueChanges again
        control?.setValue(value, { emitEvent: false });
      }
    }
  }
}


